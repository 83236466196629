import { Component, Input } from '@angular/core';
import { SimpleChanges } from '../../../utils/types';
import { CampaignEstimationPart, Targeting, wooId } from '../../../woo_services.module/shared-types';
import {
  BookingModel,
  BookingStep,
  BookingStore,
  BookingTargetingMetaData,
  ForcedEstimationCorrection,
} from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
<targeting-box
  [targeting]="targeting"
  [metaData]="targetingMetaData"
  [part]="estimationPart"
  [allowDeleteTargeting]="allowDeleteTargeting"
  [forcedCorrection]="forcedCorrection"
  (onRemove)="removeTargeting()"
  (onEditTargeting)="editTargeting()"
  (onEditCreatives)="editDistribution()"
></targeting-box>
`;

@Component({
  selector: 'advanced-booking-targeting-box',
  template: template,
})
export class AdvancedBookingTargetingBox extends SubscriptionHandler {
  @Input() targetingId: wooId;

  targeting: Targeting;
  targetingMetaData: BookingTargetingMetaData;
  estimationPart: CampaignEstimationPart;
  allowDeleteTargeting = false;
  forcedCorrection: ForcedEstimationCorrection;

  constructor(private bookingStore: BookingStore) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(changes: SimpleChanges<AdvancedBookingTargetingBox>): void {
    if (changes.targetingId && this.targetingId) {
      this.initFromStore(this.bookingStore.state);
    }
  }

  initFromStore = (model: BookingModel): void => {
    this.targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    this.targetingMetaData = model.targetingMetaData[this.targetingId];
    this.estimationPart = model.estimation.parts.find((p) => p.targeting_id === this.targetingId);
    this.allowDeleteTargeting = model.campaign.targetings.length > 1;
    this.forcedCorrection = model.forcedEstimationCorrection[this.targetingId];
  };

  removeTargeting = (): void => this.bookingStore.removeTargeting(this.targetingId);
  editTargeting = (): void => this.bookingStore.focusTargeting(this.targetingId);
  editDistribution = (): void => this.bookingStore.setActiveBookingStep(BookingStep.distribution);
}
