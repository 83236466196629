import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetMetaData } from './asset_validator/asset_upload_types';
import { EnvironmentService } from './EnvironmentService';
import { CreativeAsset, CreativeAssetType, wooId } from './shared-types';
import { WooUploadService } from './WooUploadService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CreativeAssetService {
  constructor(private http: HttpClient, private env: EnvironmentService, private uploadService: WooUploadService) {}

  getAll(customerId: wooId): Promise<CreativeAsset[]> {
    return lastValueFrom(this.http.get<CreativeAsset[]>(`${this.env.apiUrl}/customers/${customerId}/creative_assets`));
  }

  create(
    customerId: wooId,
    file: File,
    name: string,
    assetType: CreativeAssetType,
    assetMetaData: AssetMetaData,
  ): Promise<CreativeAsset> {
    return this.uploadService.upload<CreativeAsset>(
      `${this.env.apiUrl}/customers/${customerId}/creative_assets`,
      file,
      { name: name, asset_type: assetType, duration: assetMetaData.duration },
    );
  }

  delete(assetId: wooId): Promise<void> {
    return lastValueFrom(this.http.delete<void>(`${this.env.apiUrl}/creative_assets/${assetId}`));
  }
}
