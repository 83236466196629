import { Injectable } from '@angular/core';
import { CreativeService } from '../CreativeService';
import { CodeErrors } from '../shared-types';
import { MmsCodeService } from './MmsCodeService';

// @Injectable is for some reason required if the only injected item is an Angular service
@Injectable({ providedIn: 'root' })
export class CashCustomerCodeService extends MmsCodeService {
  private superValidate: (code: string) => CodeErrors;

  constructor(creativeService: CreativeService) {
    super(creativeService);
    this.superValidate = this.validate;
    this.validate = this.validateCashCustomerCode;
  }

  private validateCashCustomerCode = (code: string): CodeErrors => {
    const errors = this.superValidate(code);
    errors.filmLength = this.filmLengthError(code, [15], this.lengthIndexes.start, this.lengthIndexes.end)
      ? 'Filmen har inte en giltig längd för den här typen av kampanj'
      : null;
    return this.removeBlankKeys(errors);
  };
}
