import { Component, Input, OnChanges } from '@angular/core';
import { getDays, getYearAndWeekNumber, mergeAdjacentSegments } from '../utils/date';
import { STANDARD_FORMATS } from '../utils/format-constants';
import { SimpleChanges } from '../utils/types';
import { FormatterService } from '../woo_services.module/FormatterService';
import { CommercialWeek, Creative, DateSegment, Segment } from '../woo_services.module/shared-types';

const template = /* html*/ `
  <div class="display--flex" *ngFor="let pair of mergedSegments | keyvalue">
    <div class="width--20">
      {{getCreativeName(pair.key)}}
    </div>
    <div [attr.e2e-id]="pair.key.custom_name">
      <div class="margin-bottom--1x" *ngFor="let segment of pair.value">
        {{segment.start_date | date: DATE_FORMAT}} - {{segment.end_date | date: DATE_FORMAT}}
      </div>
    </div>
  </div>
`;

@Component({
  selector: 'creative-segments-list',
  template: template,
})
export class CreativeSegmentsList implements OnChanges {
  readonly DATE_FORMAT = STANDARD_FORMATS.date;

  @Input() creatives: Creative[];
  @Input() unbookableWeeks: CommercialWeek[];
  mergedSegments: Map<Creative, Segment[]> = new Map();

  getCreativeName: (creative: Creative) => string;

  constructor(private formatterService: FormatterService) {
    this.getCreativeName = this.formatterService.getCreativeName;
  }

  ngOnChanges(_changes: SimpleChanges<CreativeSegmentsList>): void {
    this.mergedSegments.clear();
    this.creatives.reduce(
      (map, creative) =>
        map.set(creative, mergeAdjacentSegments(this.removeUnbookableWeeks(creative.segments, this.unbookableWeeks))),
      this.mergedSegments,
    );
  }

  private removeUnbookableWeeks(segments: Segment[], unbookableWeeks: CommercialWeek[]): Segment[] {
    if (unbookableWeeks?.length) {
      return segments.reduce((new_segments, segment) => {
        const splitSegments = getDays(new Date(segment.start_date), new Date(segment.end_date))
          .filter((date: Date) => {
            const [year, weekNumber] = getYearAndWeekNumber(date);
            const dateIsInUnbookableWeeks = this.unbookableWeeks.some(
              (cWeek: CommercialWeek) => cWeek.year === year && cWeek.week === weekNumber,
            );
            return !dateIsInUnbookableWeeks;
          })
          .reduce<DateSegment[]>((newSegments, day: Date) => {
            if (newSegments.length && newSegments[newSegments.length - 1].end_date.getDate() + 1 === day.getDate()) {
              newSegments[newSegments.length - 1].end_date = day;
            } else {
              newSegments.push({ start_date: day, end_date: day });
            }

            return newSegments;
          }, []);
        return new_segments.concat(splitSegments);
      }, []);
    }

    return segments;
  }
}
