import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../../../utils/types';
import { UserRole } from '../../../../utils/user-roles';
import { AuthService } from '../../../../woo_services.module/AuthService';
import { CampaignService } from '../../../../woo_services.module/CampaignService';
import { Campaign, CampaignStatus } from '../../../../woo_services.module/shared-types';

@Component({
  selector: 'manage-campaign-buttons',
  templateUrl: './manage-campaign-buttons.component.html',
})
export class ManageCampaignButtons implements OnChanges {
  @Input() campaign: Campaign;
  @Input() campaignStartsToday: boolean;

  lowCreditRating = false;

  show = {
    cancel: false,
    close: false,
    edit: false,
    duplicate: false,
    remove: false,
    review: false,
    sendToAdServer: false,
    stop: false,
  };

  disable = {
    cancel: false,
    close: false,
    edit: false,
    duplicate: false,
    remove: false,
    review: false,
    sendToAdServer: false,
    stop: false,
  };

  constructor(private authService: AuthService, private campaignService: CampaignService) {}

  ngOnChanges(changes: SimpleChanges<ManageCampaignButtons>): void {
    if (changes.campaign && this.campaign) {
      if (this.campaign.low_credit_rating !== undefined) {
        this.lowCreditRating = this.campaign.low_credit_rating;

        // Show buttons based on status
        this.hideAllButtons();
        switch (this.campaign.status) {
          case CampaignStatus.unbooked:
            this.show.duplicate = this.authService.allowedToBook();
            this.show.edit = true;
            this.show.remove = true;
            break;

          case CampaignStatus.booked:
            this.show.cancel = this.authService.allowedToBookEarlyWithoutVerifyPeachCode();
            this.show.duplicate = this.authService.allowedToBook();
            this.show.edit = this.authService.adminOrPlanner();
            this.show.sendToAdServer = this.authService.adminOrPlanner();
            if (this.campaign.cash_campaign) {
              this.show.review = this.authService.adminOrPlanner();
            }
            break;

          case CampaignStatus.upcoming:
            this.show.cancel = this.authService.allowedToBookEarlyWithoutVerifyPeachCode();
            this.show.duplicate = this.authService.allowedToBook();
            this.show.edit = this.authService.adminOrPlanner();
            this.show.sendToAdServer = this.authService.adminOrPlanner();
            break;

          case CampaignStatus.ongoing:
            this.show.duplicate = this.authService.allowedToBook();
            this.show.edit = this.authService.adminOrPlanner();
            this.show.stop = this.authService.adminOrPlanner();
            if (this.campaignStartsToday && !this.campaign.enabled) {
              this.show.sendToAdServer = this.authService.adminOrPlanner();
            }
            break;

          case CampaignStatus.ended:
            this.show.close = this.authService.adminPlannerOrAccounting();
            this.show.duplicate = this.authService.allowedToBook();
            this.show.remove = this.authService.adminOrPlanner();
            break;

          case CampaignStatus.cancelled:
          // fallthrough
          case CampaignStatus.stopped:
            this.show.close = this.authService.adminPlannerOrAccounting();
            this.show.duplicate = this.authService.allowedToBook();
            this.show.remove = this.authService.adminOrPlanner();
            break;

          case CampaignStatus.reviewing:
          // fallthrough
          case CampaignStatus.rejected:
          // fallthrough
          case CampaignStatus.closed:
            this.show.duplicate = this.authService.allowedToBook();
            break;

          default:
            this.hideAllButtons();
        }

        // Disable buttons based on state
        this.disable.duplicate = this.campaign.ttv_campaign;
        this.disable.edit =
          this.campaignService.hasAnyStatus(this.campaign, [CampaignStatus.booked, CampaignStatus.upcoming]) &&
          (!this.campaign.editable ||
            (this.campaign.has_sent_invoices &&
              (this.authService.hasAnyRole([UserRole.externalPlanner]) || !this.lowCreditRating)));

        this.disable.sendToAdServer = this.campaign.inventory_only_campaign;
      }
    }
  }

  private hideAllButtons = () => {
    for (const key in this.show) {
      if (this.show.hasOwnProperty(key)) {
        this.show[key] = false;
      }
    }
  };
}
