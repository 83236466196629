import { Injectable } from '@angular/core';
import { DeviceGroupService } from '../../woo_services.module/DeviceGroupService';
import { DeviceGroup } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedDeviceGroupService implements Pick<DeviceGroupService, 'getDeviceGroups'> {
  private listCache: DeviceGroup[] = null;

  constructor(private deviceGroupService: DeviceGroupService) {}

  async getDeviceGroups(): Promise<DeviceGroup[]> {
    if (!this.listCache) {
      this.listCache = await this.deviceGroupService.getDeviceGroups();
    }
    return this.listCache;
  }
}
