import { Component } from '@angular/core';
import { setFormDirty } from '../../utils/form';
import { WooDialogHelper } from './woo-dialog-helper';

const template = /* html */ `
  <woo-dialog>
    <div *ngIf="content">
      <h2>{{content.header}}</h2>
      <p *ngFor="let block of content.textBlocks" [innerHTML]="block"></p>

      <div *ngFor="let warning of content.warningBlocks" e2e-id="confirm-dialog-warnings">
        <h2 class="text-warning"><span class="icon-warning"></span> {{warning.header}}</h2>
        <p *ngFor="let warnBlock of warning.textBlocks" [innerHTML]="warnBlock"></p>
      </div>

      <form #form="ngForm" (ngSubmit)="form.valid ? submit() : setFormDirty(form.control)">
        <div *ngIf="content.comment" class="input__container">
          <label for="comment">{{content.comment.label}}<span *ngIf="content.comment.required"> *</span></label>
          <textarea id="comment" type="text" name="comment" [(ngModel)]="comment" [required]="content.comment.required"></textarea>
        </div>

        <div *ngIf="content.consent" class="margin-bottom--2x">
          <p *ngIf="content.consent.text" class="text--strong"><span class="icon-warning text--warning"></span>  {{content.consent.text}}</p>
          <div>
            <input type="checkbox" class="checkbox" id="consent-box" name="consent" [(ngModel)]="consent" required>
            <label for="consent-box" class="checkbox">{{content.consent.label}}</label>
          </div>
        </div>

        <button class="button primary-1" type="submit" [disabled]="(content.consent && !consent) || (content.comment && content.comment.required && !comment)" e2e-id="confirm">{{content.confirmText}}</button>
        <button class="link primary-1" (click)="abort()" type="button">{{content.cancelText}}</button>
      </form>

    </div>
  </woo-dialog>
`;

@Component({
  selector: 'confirm-dialog',
  template: template,
})
export class ConfirmDialog extends WooDialogHelper {
  readonly setFormDirty = setFormDirty;

  content: ConfirmDialogContent;
  comment: string;
  consent: boolean;

  abort: () => void;
  submit: () => void;

  show = (content: ConfirmDialogContent): Promise<string> => {
    this.content = content;
    this.comment = '';
    this.consent = false;

    return new Promise<string>((resolve, reject) => {
      this.abort = () => {
        this.close();
        reject();
      };
      this.submit = () => {
        this.close();
        resolve(this.comment);
      };
      this.open(true);
    });
  };
}

export interface ConfirmDialogContent {
  header: string;
  textBlocks?: string[]; // This might contain html tags such as <b> or <i>. Dangerous elements are removed by angular: https://angular.io/guide/security#sanitization-example
  confirmText: string;
  cancelText: string;

  comment?: {
    required?: boolean;
    label: string;
  };
  consent?: {
    text?: string;
    label: string;
  };
  warningBlocks?: ConfirmDialogWarningBlock[];
}

export interface ConfirmDialogWarningBlock {
  header: string;
  textBlocks: string[];
}
