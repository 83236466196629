import { animate, AnimationTriggerMetadata, query, state, style, transition, trigger } from '@angular/animations';

export function verticalExpansion(timings = 250): AnimationTriggerMetadata {
  return trigger('verticalExpansion', [
    state('true', style({ height: '*' })),
    transition(':leave', [
      style({ height: '*', overflow: 'hidden', 'padding-top': '*', 'padding-bottom': '*' }),
      animate(timings, style({ height: 0, 'padding-top': 0, 'padding-bottom': 0 })),
    ]),
    transition(':enter', [
      style({ height: 0, overflow: 'hidden', 'padding-top': '0', 'padding-bottom': '0' }),
      animate(timings, style({ height: '*', 'padding-top': '*', 'padding-bottom': '*' })),
    ]),
  ]);
}

export function horizontalSlide(animation = '500ms ease-out'): AnimationTriggerMetadata {
  return trigger('horizontalSlide', [
    transition(':increment', [
      query(
        ':enter',
        [
          style({
            transform: 'translateX(5%)',
            opacity: 0,
          }),
          animate(
            animation,
            style({
              opacity: 1,
              transform: 'translateX(0)',
            }),
          ),
        ],
        { optional: true },
      ),
    ]),
    transition(':decrement', [
      query(
        ':enter',
        [
          style({
            transform: 'translateX(-5%)',
            opacity: 0,
          }),
          animate(
            animation,
            style({
              opacity: 1,
              transform: 'translateX(0%)',
            }),
          ),
        ],
        { optional: true },
      ),
    ]),
  ]);
}
