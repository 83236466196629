import { Directive, EventEmitter, Output } from '@angular/core';
import { AbstractValueAccessor } from '../../utils/AbstractValueAccessor';
import { AssetMetaData } from '../../woo_services.module/asset_validator/asset_upload_types';
import { JPGValidatorService } from '../../woo_services.module/asset_validator/JPGValidatorService';
import { MP4Validator } from '../../woo_services.module/asset_validator/MP4ValidatorService';
import { CreativeAssetService } from '../../woo_services.module/CreativeAssetService';
import { DialogService } from '../../woo_services.module/DialogService';
import { FileService } from '../../woo_services.module/FileService';
import { CreativeAsset, CreativeAssetType, wooId } from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';

@Directive()
export abstract class AssetUpload extends AbstractValueAccessor<{ name: string; url: string; videoCode?: string }> {
  @Output() onUploadProgress = new EventEmitter<UploadProgressEvent>();
  @Output() onUseSelectedVideosCode = new EventEmitter<string>();

  errors: string[] = [];
  loading = false;
  technicalSpec: string;

  abstract customerId: wooId;
  abstract assetType: CreativeAssetType;
  abstract acceptedFileType: string;

  creativeAssetType = CreativeAssetType;

  constructor(
    protected dialogService: DialogService,
    protected creativeAssetService: CreativeAssetService,
    protected translationService: TranslationService,
    protected validationService: MP4Validator | JPGValidatorService,
    protected fileService: FileService,
  ) {
    super();
  }

  async fileSelected(file: File): Promise<void> | null {
    if (!file || !this.customerId) {
      return;
    }

    this.loading = true;
    this.validationService
      .validate(file)
      .then((metaData: AssetMetaData) => {
        this.upload(file, metaData);
      })
      .catch((errors) => {
        this.errors = Object.values(errors);
        console.error('Failed to run validation script:', errors);
        this.loading = false;
      });
  }

  clearSelected(): void {
    this.errors = [];
    this.model = null;
  }

  useSelectedVideosCode(): void {
    this.onUseSelectedVideosCode.emit(this.model.videoCode);
  }

  private upload = (file: File, metaData: AssetMetaData) => {
    this.loading = true;
    this.onUploadProgress.emit({
      status: 'start',
      asset: this.emptyAsset(file),
    });
    this.creativeAssetService
      .create(this.customerId, file, file.name, this.assetType, metaData)
      .then(
        (asset) => {
          this.model = {
            name: asset.name,
            url: asset.public_url,
          };
          this.onUploadProgress.emit({ status: 'done', asset: asset });
        },
        (response) => {
          this.errors = [
            `Filen uppfyller inte kraven${this.translationService.convertMessage(response.error.message)}`,
          ];
          this.onUploadProgress.emit({ status: 'error' });
        },
      )
      .finally(() => {
        this.loading = false;
      });
  };

  private emptyAsset(file: File): CreativeAsset {
    return {
      id: null,
      asset_type: this.assetType,
      created_at: null,
      duration: null,
      etag: null,
      file_size: file.size,
      name: file.name,
      public_url: null,
      video_code: '',
    };
  }
}

export interface UploadProgressEvent {
  status: 'start' | 'done' | 'error';
  asset?: CreativeAsset;
}
