import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { WooComponentsModule } from '../woo_components.module/module';
import { AdvancedCampaignBooking } from './components/advanced-campaign-booking.component';
import { BookButton } from './components/book-button.component';
import { ButtonWithLine } from './components/buttons/button-with-line.component';
import { CreativeDialog } from './components/campaign_creatives/creative-dialog.component';
import { CampaignDetails } from './components/campaign_details/campaign-details.component';
import { NewDiscountDialog } from './components/campaign_details/new-discount-dialog.component';
import { NewDiscountToggle } from './components/campaign_details/new-discount-toggle';
import { CampaignDistribution } from './components/campaign_distribution/campaign-distribution.component';
import { CreativesDistribution } from './components/campaign_distribution/creatives-distribution.component';
import { DistributionTable } from './components/campaign_distribution/distribution-table.component';
import { RegionDistribution } from './components/campaign_distribution/region-distribution.component';
import { RemainingButton } from './components/campaign_distribution/remaining-button.component';
import { RemainingDistribution } from './components/campaign_distribution/remaining-distribution.component';
import { TTVCreativesDistribution } from './components/campaign_distribution/ttv-creatives-distribution.component';
import { AdvancedBookingCampaignSummary } from './components/campaign_summary/advanced-booking-campaign-summary.component';
import { AdvancedBookingTargetingSummary } from './components/campaign_summary/advanced-booking-targeting-summary.component';
import { DownloadSummaryButton } from './components/campaign_summary/download-summary-button.component';
import { GamblingCustomerInformationDialog } from './components/dialogs/info/gambling-customer-information-dialog.component';
import { InvalidAgencyDialog } from './components/dialogs/info/invalid-agency-dialog.component';
import { InvoicesAlreadySentDialog } from './components/dialogs/info/invoices-already-sent-dialog.component';
import { TTVCampaignEditingDialog } from './components/dialogs/info/ttv-campaign-editing-dialog.component';
import { Footer } from './components/footer/footer.component';
import { TargetingBudget } from './components/inputs/targeting-budget.component';
import { AdvanceBookingMessages } from './components/messages/advanced-booking-messages.component';
import { SnakeNav } from './components/navigation/snake-nav.component';
import { SaveButton } from './components/save-button.component';
import { AdvancedBookingSidebar } from './components/sidebar/advanced-booking-sidebar.component';
import { AdvancedBookingTargetingBox } from './components/sidebar/advanced-booking-targeting-box.component';
import { AdvancedTargetGroupTargeting } from './components/targeting/advanced_target_group_targeting/advanced-target-group-targeting.component';
import { CampaignTargetings } from './components/targeting/campaign-targetings.component';
import { CategoryTargeting } from './components/targeting/category_targeting/category-targeting.component';
import { DaypartTargeting } from './components/targeting/daypart_targeting/daypart-targeting.component';
import { DeviceGroupTargeting } from './components/targeting/device_group_targeting/device-group-targeting.component';
import { FrequencyLimitTargeting } from './components/targeting/frequency-limit-targeting.component';
import { GeoTargeting } from './components/targeting/geo-targeting.component';
import { IncludesLinear } from './components/targeting/includes-linear.component';
import { ProductFormatSelect } from './components/targeting/product-format-select.component';
import { ProgramFormatItem } from './components/targeting/program-format-item.component';
import { ProgramFormatTargeting } from './components/targeting/program-format-targeting.component';
import { RBSTargeting } from './components/targeting/rbs-targeting.component';
import { TargetGroupTargeting } from './components/targeting/target-group-targeting.component';
import { TargetingAdminPanel } from './components/targeting/targeting-admin-panel.component';
import { TargetingDetails } from './components/targeting/targeting-details.component';
import { VoucherSelect } from './components/targeting/voucher-select.component';
import { MessageFactory } from './services/MessageFactory';
import { BookingStoreSelectors } from './stores/BookingStoreSelectors';

@NgModule({
  imports: [BrowserModule, FormsModule, NgSelectModule, ReactiveFormsModule, WooComponentsModule],
  declarations: [
    AdvanceBookingMessages,
    AdvancedBookingCampaignSummary,
    AdvancedBookingSidebar,
    AdvancedBookingTargetingBox,
    AdvancedBookingTargetingSummary,
    AdvancedCampaignBooking,
    AdvancedTargetGroupTargeting,
    BookButton,
    ButtonWithLine,
    CampaignDetails,
    CampaignDistribution,
    CampaignTargetings,
    CategoryTargeting,
    CreativeDialog,
    CreativesDistribution,
    TTVCreativesDistribution,
    DaypartTargeting,
    DeviceGroupTargeting,
    DistributionTable,
    DownloadSummaryButton,
    Footer,
    FrequencyLimitTargeting,
    GamblingCustomerInformationDialog,
    GeoTargeting,
    IncludesLinear,
    InvalidAgencyDialog,
    InvoicesAlreadySentDialog,
    NewDiscountDialog,
    NewDiscountToggle,
    ProductFormatSelect,
    ProgramFormatItem,
    ProgramFormatTargeting,
    RBSTargeting,
    RegionDistribution,
    RemainingButton,
    RemainingDistribution,
    SaveButton,
    SnakeNav,
    TargetGroupTargeting,
    TargetingAdminPanel,
    TargetingBudget,
    TargetingDetails,
    TTVCampaignEditingDialog,
    VoucherSelect,
  ],
  providers: [
    BookingStoreSelectors,
    CurrencyPipe,
    InvalidAgencyDialog,
    InvoicesAlreadySentDialog,
    MessageFactory,
    TTVCampaignEditingDialog,
  ],
})
export class AdvancedCampaignBookingModule {}
