import { Component, Input, OnChanges } from '@angular/core';
import { saveFileAs } from '../../utils/files';
import { SimpleChanges } from '../../utils/types';
import {
  EosCampaignRow,
  EosInventoryRow,
  EosService,
  EosTTVCampaignRow,
  EosTTVInventoryRow,
} from '../../woo_services.module/EosService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { wooId } from '../../woo_services.module/shared-types';

@Component({
  selector: 'campaign-row-list',
  templateUrl: './campaign-row-list.component.html',
  styles: [
    `
      .woo-dropdown-small {
        min-width: 80px;
        font-size: 12px;
      }
    `,
  ],
})
export class CampaignRowList implements OnChanges {
  @Input() rows: EosCampaignRow[] | EosTTVCampaignRow[] | EosInventoryRow[] | EosTTVInventoryRow[] = [];
  @Input() campaignId: wooId;
  @Input() campaignName: string;

  convert: any;
  dataFields = [];
  promotedProperties = [];
  editFields = false;
  promotedPropEos = ['date', 'campaign_name', 'product', 'net_cpm', 'creative_lengths', 'invoice_sent'];
  promotedPropTTV = ['date', 'agency', 'customer_crm_id', 'campaign_status', 'ttv_booking_name', 'ttv_target_group'];
  promotedPropEosInventory = ['date', 'ttv_target_group', 'channel_name', 'slot_type', 'estimated', 'withdrawal_limit'];

  constructor(private translationService: TranslationService, private eosService: EosService) {}

  ngOnChanges(changes: SimpleChanges<CampaignRowList>): void {
    if (this.rows.length) {
      if (this.isEosTTVCampaignRow(this.rows[0])) {
        this.convert = this.translationService.convertEosTTVCampaignRow;
        this.promotedProperties = this.promoteProp(this.promotedPropTTV);
      } else if (this.isEosInventoryRow(this.rows[0])) {
        this.convert = this.translationService.convertInventoryRow;
        this.promotedProperties = this.promoteProp(this.promotedPropEosInventory);
      } else {
        this.convert = this.translationService.convertEosCampaignRow;
        this.promotedProperties = this.promoteProp(this.promotedPropEos);
      }
    }

    if (changes.rows && this.rows.length && !this.dataFields.length) {
      this.dataFields = Object.keys(this.rows[0])
        .map((key) => {
          return {
            name: this.convert(key),
            value: key,
          };
        })
        .sort((s1, s2) => 0 - (s1.name < s2.name ? 1 : -1));
    }
  }

  downloadExcel(): void {
    this.eosService.downloadRowsToExcel(this.campaignId, this.isEosTTVCampaignRow(this.rows[0])).then((res) => {
      saveFileAs(res.body, `${this.campaignName}.xlsx`);
    });
  }

  private isEosTTVCampaignRow(object: any): object is EosTTVCampaignRow {
    return 'universe' in object;
  }

  private isEosInventoryRow(object: any): object is EosInventoryRow {
    return 'slot_type' in object;
  }

  private promoteProp(props: string[]) {
    return props.map((key) => {
      return {
        name: this.convert(key),
        value: key,
      };
    });
  }
}
