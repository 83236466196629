import { Component, Input } from '@angular/core';

@Component({
  selector: 'list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItem {
  @Input() number: string;
  @Input() heading: string;
  @Input() text: string;
}
