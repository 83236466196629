import { Component, Input } from '@angular/core';

const template = /* html */ `
  <p class="text--warning text--strong">
    <span class="icon-warning"></span><span class="margin-left--1x">{{warningText}}</span>
    <button [routerLink]="['/import']" class="button margin-left--2x">Importera</button>
  </p>
`;

@Component({
  selector: 'import-warning',
  template: template,
})
export class ImportWarning {
  @Input() warningText = 'Du behöver importera om estimaten för att dina ändringar ska slå igenom';
}
