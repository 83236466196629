<div class="section block">
  <div class="account__card inner-block">
    <h1>Intäktsfiler</h1>

    <table class="table table--woo table--hover table--info centered override-bootstrap">
      <thead >
        <th>År</th>
        <th>Månad</th>
        <th>Ekonomisystem</th>
        <th>Ladda ner</th>
        <th>Skicka</th>
        <th>Skickad</th>
      </thead>
      <tbody *ngFor="let report of availableReports; trackBy: trackById">
        <tr *ngIf="report.has_agresso_files">
          <td [attr.rowspan]="report.has_agresso_files && report.has_telia_files ? '2' : '1'">{{ report.year }}</td>
          <td [attr.rowspan]="report.has_agresso_files && report.has_telia_files ? '2' : '1'">{{ report.month }}</td>
          <td>
            <span>Agresso</span>
          </td>
          <td>
            <span>
              <button (click)="downloadAgressoXlsx(report)" class="link padding-left--none margin-left--none">Excel</button>
              <button (click)="downloadAgressoXml(report)" class="link padding-left--none margin-left--none">XML</button>
            </span>
          </td>
          <td>
            <span> - </span>
          </td>
          <td>
            <span>
              <span *ngIf="!report.sent_to_agresso_at">-</span>
              <span *ngIf="report.sent_to_agresso_at">{{ report.sent_to_agresso_at | date }} ({{report.sent_to_agresso_by.first_name}} {{report.sent_to_agresso_by.last_name}})</span>
            </span>
          </td>
        </tr>
        <tr *ngIf="report.has_telia_files">
          <td *ngIf="!report.has_agresso_files">{{ report.year }}</td>
          <td *ngIf="!report.has_agresso_files">{{ report.month }}</td>
          <td>
            <span>Telia</span>
          </td>
          <td>
            <span>
              <button (click)="downloadTeliaXlsx(report)" class="link padding-left--none margin-left--none">Excel</button>
              <button (click)="downloadTeliaXml(report)" class="link padding-left--none margin-left--none">XML</button>
            </span>
          </td>
          <td>
            <span>
              <button (click)="sendToTelia(report)" class="button margin-left--none" [disabled]="report.sent_to_telia_at">
                <span class="icon-ios margin-right--1x"></span> Skicka till Telia
              </button>
            </span>
          </td>
          <td>
            <span>
              <span *ngIf="!report.sent_to_telia_at">-</span>
              <span *ngIf="report.sent_to_telia_at">{{ report.sent_to_telia_at | date }} ({{report.sent_to_telia_by.first_name}} {{report.sent_to_telia_by.last_name}})</span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
