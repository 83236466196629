import { Component } from '@angular/core';
import { AuthService } from '../../../woo_services.module/AuthService';

@Component({
  selector: 'system-settings',
  templateUrl: './system-settings.component.html',
})
export class SystemSettings {
  readonly isAdmin = this.authService.admin();
  readonly isAdminOrPlanner = this.authService.adminOrPlanner();

  tabVisible: number = this.isAdmin ? 0 : 3;

  constructor(private authService: AuthService) {}

  setTab(tab: number): void {
    this.tabVisible = tab;
  }
}
