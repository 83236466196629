import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { groupBy } from '../../../utils/array';
import {
  add,
  getDays,
  getWeekNumber,
  isDateAfter,
  isDateBefore,
  sameDate,
  subtract,
  TimePeriod,
  WOO_DATE_PATTERN,
} from '../../../utils/date';
import { openAbove } from '../../../utils/dom';
import { STANDARD_FORMATS } from '../../../utils/format-constants';
import { valueAccessorProvider } from '../../../utils/provider-builders';
import { generateId } from '../../../utils/string';
import { SimpleChanges } from '../../../utils/types';
import { Calender, Day, InitialDate, weekNumber } from '../shared/types';

@Component({
  selector: 'date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['../shared/date_selectors.scss'],
  providers: [valueAccessorProvider(DateSelect)],
})
export class DateSelect implements ControlValueAccessor, OnChanges {
  readonly instanceId = generateId();
  readonly DATE_PATTERN = WOO_DATE_PATTERN;
  readonly DATE_FORMAT = STANDARD_FORMATS.date;

  @Input() defaultInitDate = InitialDate.FirstSelectable;
  @Input() disabled = false;
  @Input() firstSelectableDate: Date = new Date();
  @Input() lastSelectableDate: Date;
  @Input() label: string;

  selectedDate: Date;
  hoverDate: Date;
  firstMonth: Calender;
  secondMonth: Calender;

  firstMonthDate: Date;
  get secondMonthDate(): Date {
    return add(this.firstMonthDate, 1, TimePeriod.Month);
  }

  show = {
    calender: false,
    calenderAbove: false,
    pristine: true,
  };

  @ViewChild('element', { static: true }) elementRef: ElementRef;

  private onClickOutsideDateSelect: () => void;

  ngOnChanges(changes: SimpleChanges<DateSelect>): void {
    if (
      changes.firstSelectableDate &&
      this.firstSelectableDate &&
      !sameDate(changes.firstSelectableDate.currentValue, changes.firstSelectableDate.previousValue)
    ) {
      this.updateFirstMonthDate(this.firstSelectableDate);
    }
  }

  setDateIfValid(date: Date): void {
    if (this.isDateValid(date)) {
      this.selectedDate = date;
      this.newSelectedDates();
    }
  }

  showCalender(): void {
    this.show.calenderAbove = openAbove(this.elementRef);
    this.show.calender = true;
    this.updateFirstMonthDate(this.getFirstMonthDate());
    if (!this.onClickOutsideDateSelect) {
      this.setCloseClickListener();
    }
  }

  private getFirstMonthDate(): Date {
    if (this.defaultInitDate === InitialDate.Current) {
      return this.selectedDate || new Date();
    } else {
      return this.selectedDate || this.firstSelectableDate || new Date();
    }
  }

  private updateFirstMonthDate(date: Date): void {
    this.firstMonthDate = date;
    this.updateCalenders();
  }

  private updateCalenders(): void {
    this.firstMonth = this.createWeeksForMonth(this.firstMonthDate);
    this.secondMonth = this.createWeeksForMonth(this.secondMonthDate);
    this.updateMarked();
  }

  private createWeeksForMonth(yearAndMonth: Date): Calender {
    const startDate = new Date(yearAndMonth.getFullYear(), yearAndMonth.getMonth(), 1);
    const endDate = new Date(yearAndMonth.getFullYear(), yearAndMonth.getMonth() + 1, 0);

    const days = getDays(subtract(startDate, 6), add(endDate, 7));

    const isDisabled = (d: Date) => startDate.getMonth() !== d.getMonth();
    const isSelectable = (d: Date) =>
      (isDateBefore(this.firstSelectableDate, d) || sameDate(this.firstSelectableDate, d)) &&
      (!this.lastSelectableDate || isDateAfter(this.lastSelectableDate, d) || sameDate(this.lastSelectableDate, d));

    const weeks: Map<weekNumber, Day[]> = groupBy(
      days.map((date) => ({ date, marked: false, disabled: isDisabled(date), selectable: isSelectable(date) })),
      (day) => getWeekNumber(day.date),
    );

    return Array.from(weeks.keys())
      .sort((l, r) => l - r)
      .reduce((map, key) => (weeks.get(key).length === 7 ? map.set(key, weeks.get(key)) : map), new Map());
  }

  private updateMarked(): Date {
    return this.hoverDate ?? this.selectedDate;
  }

  private setCloseClickListener() {
    this.onClickOutsideDateSelect = () => {
      this.hideCalender();
      this.removeCloseClickListener();
    };
    document.addEventListener('click', this.onClickOutsideDateSelect);
  }

  hideCalender(): void {
    this.hoverDate = null;
    this.show.calender = false;
    this.show.pristine = false;
  }

  private removeCloseClickListener() {
    document.removeEventListener('click', this.onClickOutsideDateSelect);
    this.onClickOutsideDateSelect = null;
  }

  touch(): void {
    this.propagateOnTouch();
  }

  nextMonth(): void {
    this.updateFirstMonthDate(add(this.firstMonthDate, 1, TimePeriod.Month));
  }

  prevMonth(): void {
    this.updateFirstMonthDate(subtract(this.firstMonthDate, 1, TimePeriod.Month));
  }

  selectDay(day: Day): void {
    if (!day.selectable) {
      return;
    }

    this.setDateIfValid(day.date);
    this.newSelectedDates();

    // When a date has been selected, mark that input as NOT pristine
    this.show.pristine = this.show.pristine && !Boolean(this.selectedDate);

    this.hideCalender();
  }

  isSelected(day: Day): boolean {
    return sameDate(day.date, this.selectedDate);
  }

  isChanging(day: Day): boolean {
    return this.isSelected(day) && Boolean(this.hoverDate) && !sameDate(this.hoverDate, day.date);
  }

  mouseoverDay(day: Day): void {
    this.hoverDate = day.date;
    this.updateMarked();
  }

  mouseleaveDay(day: Day): void {
    if (sameDate(this.hoverDate, day.date)) {
      this.hoverDate = null;
    }
    this.updateMarked();
  }

  isDateValid(date: Date): boolean {
    const error = date && this.firstSelectableDate && isDateBefore(date, this.firstSelectableDate);
    return !error;
  }

  writeValue(date: Date | string): void {
    const newSelectedDate = date ? new Date(date) : null;
    const selectedDateChanged = !sameDate(this.selectedDate, newSelectedDate);
    this.selectedDate = newSelectedDate;
    if (newSelectedDate && selectedDateChanged) {
      this.updateFirstMonthDate(newSelectedDate);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouch = fn;
  }

  newSelectedDates(): void {
    this.propagateOnChange(this.selectedDate);
    this.updateMarked();
  }

  private propagateOnChange = (_model: Date) => null;
  private propagateOnTouch = () => null;
}
