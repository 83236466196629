<div class="padding--1x">
  <div class="col-xs-6 padding--none padding-bottom--1x">
    <mms-totals-summary
      [isGrossRating]="useGrossRating"
      [tracking]="tracking"
      [videoCodes]="videoCodes"
    ></mms-totals-summary>
  </div>
  <div class="width--33">
    <woo-dropdown
      [items]="videoCodes"
      [ngModel]="activeVideoCode"
      (ngModelChange)="onVideoCodeChange($event)"
      name="video_codes"
      labelText="Filmkod"
    ></woo-dropdown>
    <span *ngIf="missingDaysForVideoCode" class="padding-bottom--1x">
      <span class="text-warning icon-warning padding-right--1x"></span>Väntar på utfall för {{missingDaysForVideoCode}} dagar.
    </span>
  </div>
  <tv-day-outcome-table
  [headers]="mmsTableHeaders"
  [rows]="videoCodeData"
  wooCopyTableAsNumber
  ></tv-day-outcome-table>
  </div>
