import { Component, Input, OnChanges } from '@angular/core';
import { generateId } from '../../../utils/string';
import {
  ConfirmDialogContent,
  ConfirmDialogWarningBlock,
} from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../woo_services.module/DialogService';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { wooId } from '../../../woo_services.module/shared-types';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'targeting-admin-panel',
  templateUrl: './targeting-admin-panel.component.html',
})
export class TargetingAdminPanel extends SubscriptionHandler implements OnChanges {
  readonly instanceId = generateId();

  calculateFromBudget: boolean;
  additionalBudgetChoice: boolean;
  targetingAdditionalBudget: number;
  targetingAdditionalBudgetedImpressions: number;
  targetingAdditionalBudgetMessage = '';
  includesLinear: boolean;
  noInvoicesChoice: boolean;
  noInvoicesMessage = '';
  salesOrderNumber = '';
  currency: string;
  currencyAcronym: string;

  private isPartOfCombinationCampaign: boolean;
  private campaignIncludesLinear: boolean;

  @Input() targetingId: wooId;

  constructor(
    private bookingStore: BookingStore,
    private formatterService: FormatterService,
    private dialogService: DialogService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    this.campaignIncludesLinear = !model.campaign.targetings.every((t) => !t.includes_linear);
    this.isPartOfCombinationCampaign = model.campaign.targetings.length > 1;
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);

    if (targeting) {
      const metaData = model.targetingMetaData[targeting.id];

      this.calculateFromBudget = targeting.calculate_from_budget;
      this.targetingAdditionalBudget = targeting.additional_budget;
      this.targetingAdditionalBudgetedImpressions = targeting.additional_budgeted_impressions;
      this.targetingAdditionalBudgetMessage = targeting.additional_budget_message;
      this.includesLinear = targeting.includes_linear;
      this.noInvoicesChoice = !targeting.send_invoices;
      this.noInvoicesMessage = targeting.invoice_disable_message;
      this.salesOrderNumber = targeting.sales_order_number;

      this.additionalBudgetChoice = metaData.adminChoices.additionalBudget;
      this.currency = this.formatterService.getCurrencyName(targeting);
      this.currencyAcronym = this.formatterService.getCurrencyName(targeting, false, 'acronym');
    }
  };

  toggleAdditionalBudgetChoice(): void {
    this.bookingStore.setAdditionalBudgetChoice(this.targetingId, this.additionalBudgetChoice);
  }

  additionalBudgetChange(): void {
    this.bookingStore.setAdditionalBudget(
      this.targetingId,
      this.targetingAdditionalBudget,
      this.targetingAdditionalBudgetedImpressions,
      this.targetingAdditionalBudgetMessage,
    );
  }

  toggleNoInvoicesChoice(): void {
    this.noInvoicesChoice = !this.noInvoicesChoice;
    if (!this.isPartOfCombinationCampaign) {
      this.bookingStore.setNoInvoicesChoice(this.noInvoicesChoice);
      return;
    }

    this.dialogService
      .openConfirm(this.changeSendInvoiceForCampaignContent(!this.noInvoicesChoice, this.campaignIncludesLinear))
      .then(() => {
        this.bookingStore.setNoInvoicesChoice(this.noInvoicesChoice);
      })
      .catch(() => (this.noInvoicesChoice = !this.noInvoicesChoice)); // confirmation aborted
  }

  noInvoicesChange(): void {
    this.bookingStore.setNoInvoices(this.targetingId, this.noInvoicesMessage, this.salesOrderNumber);
  }

  private changeSendInvoiceForCampaignContent(turnOnInvoice: boolean, includesLinear: boolean): ConfirmDialogContent {
    return {
      header: `Fakturera${turnOnInvoice ? ' ' : ' inte '}kampanjen från WOO`,
      textBlocks: [
        `Vi godkänner inte att en kampanjs fakturering delas upp.`,
        turnOnInvoice
          ? 'Vill ni starta fakturering för hela kampanjen?'
          : 'Vill ni stoppa fakturering för hela kampanjen?',
      ],
      confirmText: turnOnInvoice ? 'Ja, fakturera kampanj' : 'Stäng fakturering för kampanj',
      cancelText: 'Avbryt',
      warningBlocks: includesLinear && turnOnInvoice ? this.getWarningBlockIncludesLinear() : [],
    };
  }

  private getWarningBlockIncludesLinear(): ConfirmDialogWarningBlock[] {
    return [
      {
        header: 'Varning!',
        textBlocks: [
          'Denna kampanj innehåller Regionalpaketering.',
          'Regionalpaketeringsdelar kommer bli vanliga regionala bokningar.',
        ],
      },
    ];
  }
}
