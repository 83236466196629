import { Component, EventEmitter, Output } from '@angular/core';

const template = /* html */ `
  <button (click)="$event.stopPropagation(); click.emit()" data-toggle="tooltip" title="Lägg resten här">
    <svg class="move-image-into-position" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="resten_icon3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
        <g id="Group" transform="translate(11.000000, 9.609403) scale(-1, 1) translate(-11.000000, -9.609403) translate(4.000000, 1.109403)" fill="#ff3334" stroke="#ff3334">
          <path d="M8,16.8905973 L8,0.890597285 C3.581722,0.890597285 0,4.47231929 0,8.89059729 C0,13.3088753 3.581722,16.8905973 8,16.8905973 Z" id="Oval"></path>
          <path d="M13.2431459,16.8905973 L13.2431459,8.89059729 L8,14.9330623 C9.40411004,16.1524811 11.2374299,16.8905973 13.2431459,16.8905973 Z" id="Oval-Copy" transform="translate(10.621573, 12.890597) scale(-1, 1) translate(-10.621573, -12.890597) "></path>
        </g>
        <g id="Group-2" transform="translate(4.721339, 7.500000) scale(-1, 1) translate(-4.721339, -7.500000) translate(1.221339, 0.000000)" stroke="#ff3334" stroke-linecap="round" stroke-width="1.5">
          <path d="M6.22133911,2.76381881 C3.63200385,4.08829893 1.85949536,6.78245234 1.85949536,9.89059729 C1.85949536,11.2624836 2.20481559,12.553716 2.81327648,13.682115" id="Path" transform="translate(4.040417, 8.222967) scale(-1, 1) translate(-4.040417, -8.222967) "></path>
          <polyline id="Path-7" transform="translate(2.134276, 2.803535) rotate(37.000000) translate(-2.134276, -2.803535) " points="3.03604363 1 1.23250909 2.80353454 3.03604363 4.60706908"></polyline>
        </g>
      </g>
    </svg>
  </button>
`;

@Component({
  selector: 'remaining-button',
  template: template,
  styles: ['.move-image-into-position { transform: translateY(21%); }'],
})
export class RemainingButton {
  @Output() click = new EventEmitter<void>();
}
