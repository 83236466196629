import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../woo_services.module/AuthService';
import { Feedback, FeedbackService } from '../../woo_services.module/FeedbackService';
import { GoogleTagManagerService } from '../../woo_services.module/GoogleTagManagerService';
import { WooDialogHelper } from './woo-dialog-helper';

@Component({
  selector: 'feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
})
export class FeedbackDialog extends WooDialogHelper implements OnInit {
  readonly isLoggedIn = this.authService.isAuthenticated();
  feedbackSent = false;
  feedback = {} as Feedback;
  supportPhone = this.feedbackService.supportPhone;

  constructor(
    private authService: AuthService,
    private feedbackService: FeedbackService,
    private googleTagManagerService: GoogleTagManagerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.googleTagManagerService.openSupport();
  }

  sendFeedback = (feedback: Feedback): void => {
    this.googleTagManagerService.sendSupport();
    this.feedbackService.sendFeedback(feedback).then(() => {
      this.feedbackSent = true;
    });
  };
}
