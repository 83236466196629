<div class="tabbable__body margin--none clearfix shadow-z-1">
  <form (ngSubmit)="filter()" id="inventory-form">
    <div class="row">
      <inventory-weeks-picker (weeksChanged)="weeksChanged($event)"></inventory-weeks-picker>
      <div class="col-xs-2 block">
        <button type="button" *ngIf="authorizedForExport()" (click)="export()" class="button secondary margin-top--3x">Exportera lager</button>
      </div>
      <div class="block pull-right">
      </div>
    </div>
      <p><span class="icon-warning text--info"></span> Om du inte filtrerar publicister motsvarar siffrorna hela WOO:s lager. Alla kombinationer nedan är inte säljbara, se <a class="text--info" href="/#/pricelist" target="_blank">prislistan</a> för möjliga kombinationer.</p>
    <div class="row">
      <dot-loader [visibility]="fetchingFilters" description="Laddar filter"></dot-loader>
      <div class="flex__container">
        <inventory-filter *ngFor="let filterOption of filterOptions"
          class="col-sm-4 col-xs-6"
          [filterOption]="filterOption"
          (onChange)="filters[filterOption.filter_key] = $event.newValue">
        </inventory-filter>
      </div>
    </div>
    <button class="button primary-1" type="submit" [disabled]="fetchingData">Hämta lager</button>
    <button class="link primary-1" type="button" (click)="clearFilters()">Rensa filter</button>
  </form>

  <div id="inventory-result">   
    <div class="block" *ngIf="fetchingData">
      <dot-loader [visibility]="fetchingData" description="Hämtar lager"></dot-loader>
    </div>
    <div class="margin-top--3x" *ngIf="inventoryTree">
      <div *ngIf="showViewCurrencyToggle">
        <button class="link primary-1" type="button" (click)="toggleViewCurrency()">{{toggleViewCurrencyLabel}}</button>
      </div>
      <div class="text--gray pull-right">
        <p class="text--small">
          <span class="icon-refresh"></span> Lagret hämtades {{timeStamp | date:"d MMMM yyyy 'kl.'H.mm"}}
        </p>
      </div>
      <div class="text--gray pull-left">
        <p class="text--small">
          {{breadcrumb}}
        </p>
      </div>
      <div class="clearfix"></div>

      <inventory-visualization
        [inventoryTree]="inventoryTree"
        [filterOptions]="filterOptions"
        [columnNames]="columnNames"
        [showTooltip]="warnForWithdrawalLimit"
        [viewCurrencyLabel]="viewCurrencyLabel(presentationCurrency)">
      </inventory-visualization>
    </div>
  </div>
</div>
