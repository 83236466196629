import { Component, OnInit } from '@angular/core';
import { saveFileAs } from '../../../utils/files';
import { trackById } from '../../../utils/object';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { InventoryReportFileData, InventoryService } from '../../../woo_services.module/InventoryService';
import { wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'inventory-reports',
  templateUrl: './inventory-reports.component.html',
})
export class InventoryReports implements OnInit {
  monthlyReports: InventoryReportFileData[] = [];

  constructor(private inventoryService: InventoryService, private authService: AuthService) {}

  ngOnInit(): void {
    this.getMonthlyInventoryReports();
  }

  downloadReport(report: InventoryReportFileData): void {
    this.inventoryService.downloadReport(report.id).then((result) => {
      saveFileAs(result, `${report.description}.xlsx`);
    });
  }

  trackById(): (_: any, item: { id: wooId }) => wooId {
    return trackById;
  }

  private autherizedRoles: UserRole[] = [
    UserRole.admin,
    UserRole.planner,
    UserRole.accounting,
    UserRole.salesManager,
    UserRole.estimator,
  ];
  private getMonthlyInventoryReports() {
    if (this.authService.hasAnyRole(this.autherizedRoles)) {
      this.inventoryService.getMonthlyInventoryReports().then((reports) => {
        this.monthlyReports = reports;
      });
    }
  }
}
