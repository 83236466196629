import { Component, Input } from '@angular/core';

const template = `<circle-progress
  [percent]="(progress/target)*100"
  [subtitle]="text"
  [radius]="radius"
  [maxPercent]="100"

  [animation]="true"
  [animationDuration]="300"

  [space]="-10"
  [outerStrokeWidth]="10"
  [innerStrokeWidth]="10"
  [outerStrokeColor]="wooColors.mainBlue"
  [innerStrokeColor]="wooColors.borderGray"
  [showInnerStroke]="true"

  [backgroundColor]="wooColors.white"
  [subtitleColor]="wooColors.black"
  [showSubtitle]="true"
  [backgroundOpacity]="0.85"
  [backgroundPadding]="-10"
  [titleFontSize]="42"
  [unitsFontSize]="16"
  [subtitleFontSize]="12"
></circle-progress>`;

@Component({
  selector: 'campaign-progress',
  template: template,
})
export class CampaignProgress {
  @Input() text: string;
  @Input() progress = 0;
  @Input() target = 100;
  @Input() radius = 75;

  wooColors = {
    mainBlue: '#1fbde7',
    white: '#fefefe',
    black: 'black',
    borderGray: '#CFD3D6',
  };
}
