import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserRole } from '../utils/user-roles';
import { EnvironmentService } from './EnvironmentService';
import { FileType, LegalDocument } from './FileService';
import { CompactUser, User, wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getUsers = (): Promise<CompactUser[]> => {
    return lastValueFrom(this.http.get<CompactUser[]>(`${this.env.apiUrl}/users`));
  };

  getUser = (id: any): Promise<User> => {
    return lastValueFrom(this.http.get<User>(`${this.env.apiUrl}/users/${id}`));
  };

  createUser: UpdateMethod = (
    user: UserParams,
    role: UserRole,
    roles: AgencyRole[] = [],
    adOrganisationId: wooId = null,
  ): Promise<User> => {
    return lastValueFrom(
      this.http.post<User>(`${this.env.apiUrl}/users`, {
        user: user,
        role: role,
        roles: roles,
        ad_organisation_id: adOrganisationId,
      }),
    );
  };

  updateUser: UpdateMethod = (
    user: UserParams,
    role: UserRole,
    roles: AgencyRole[] = [],
    adOrganisationId: wooId = null,
  ) => {
    return lastValueFrom(
      this.http.patch<User>(`${this.env.apiUrl}/users/${user.id}`, {
        user: user,
        role: role,
        roles: roles,
        ad_organisation_id: adOrganisationId,
      }),
    );
  };

  saveSignupUser = (params: UserSignupParams): Promise<void> => {
    return lastValueFrom(
      this.http.post<void>(`${this.env.apiUrl}/signups`, { ...params }),
    );
  };

  deactivateUser = (userId: wooId): Promise<void> => {
    return lastValueFrom(this.http.delete<void>(`${this.env.apiUrl}/users/${userId}`));
  };

  reactivateUser = (userId: wooId): Promise<User> => {
    return lastValueFrom(this.http.patch<User>(`${this.env.apiUrl}/users/${userId}/reactivate`, {}));
  };

  /**
   * Force user to reset password next time they log in
   */
  resetPassword = (userId: wooId): Promise<User> => {
    return lastValueFrom(this.http.patch<User>(`${this.env.apiUrl}/users/${userId}/reset_password`, {}));
  };

  acceptLatestFile = (userId: string, fileType: FileType): Promise<LegalDocument> => {
    return lastValueFrom(
      this.http.patch<LegalDocument>(`${this.env.apiUrl}/users/${userId}/accept_latest_file/${fileType}`, {}),
    );
  };

  /**
   * ================ User access handling ================
   */

  grantAccess(userId: wooId, customerId: wooId, agencyId?: wooId): Promise<User> {
    return lastValueFrom(
      this.http.patch<User>( // TODO verify return type
      `${this.env.apiUrl}/users/${userId}/grant_access`, { customer_id: customerId, agency_id: agencyId }),
    );
  }

  revokeAccess(userId: wooId, customerId: wooId): Promise<User> {
    return lastValueFrom(
      this.http.patch<User>( // TODO verify return type
      `${this.env.apiUrl}/users/${userId}/revoke_access`, { customer_id: customerId }),
    );
  }
}

export interface UserParams extends Pick<User, 'first_name' | 'last_name' | 'email'> {
  customer_id: wooId;
  id?: wooId;
}

export interface AgencyRole {
  agency_id: wooId;
  role: UserRole;
}

export interface UserSignupParams {
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  phone: string;
}

type UpdateMethod = (user: UserParams, role: UserRole, roles?: AgencyRole[], adOrganisationId?: wooId) => Promise<User>;
