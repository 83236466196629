import { Component, DoCheck, OnInit } from '@angular/core';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged } from 'rxjs/operators';
import { FileService } from '../../../woo_services.module/FileService';
import { GoogleTagManagerService } from '../../../woo_services.module/GoogleTagManagerService';
import { Targeting, wooId } from '../../../woo_services.module/shared-types';
import { AdvancedCampaignService } from '../../services/AdvancedCampaignService';
import { ScrollContainerService } from '../../services/ScrollContainerService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'campaign-targetings',
  templateUrl: './campaign-targetings.component.html',
  styleUrls: ['./campaign-targetings.component.scss'],
})
export class CampaignTargetings extends SubscriptionHandler implements OnInit, DoCheck {
  readonly scrollTo = this.scrollContainerService.scrollTo;

  targetings: Targeting[] = [];
  userTerms: string;

  private newTargetingId: wooId;
  private previousTargetingId: wooId;
  private previousTargetingAmount: number;
  private waitForTargetingBoxElement: boolean;
  private waitForScroll: boolean;

  constructor(
    private bookingStore: BookingStore,
    private campaignService: AdvancedCampaignService,
    private fileService: FileService,
    private googleTagManagerService: GoogleTagManagerService,
    private scrollContainerService: ScrollContainerService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
    this.addSubscription(
      bookingStore.state$
        .pipe(
          distinctUntilChanged((prev: BookingModel, curr: BookingModel) =>
            isEqual(Object.keys(prev.targetingMetaData).length, Object.keys(curr.targetingMetaData).length),
          ),
        )
        .subscribe((model: BookingModel) => {
          const targetings = Object.keys(model.targetingMetaData);
          if (targetings.length > 1 && targetings.length > this.previousTargetingAmount) {
            this.newTargetingId = targetings[targetings.length - 1];
            this.previousTargetingId = targetings[targetings.length - 2];
            this.waitForTargetingBoxElement = true;
            this.waitForScroll = true;
          }
          this.previousTargetingAmount = targetings.length;
        }),
    );
  }

  ngOnInit(): void {
    this.initFromStore(this.bookingStore.state);
    this.userTerms = this.fileService.getUrlToFile(this.fileService.getTermsFileTypeForUser());
  }

  ngDoCheck(): void {
    if (this.waitForTargetingBoxElement && document.getElementById(this.newTargetingId)) {
      this.waitForTargetingBoxElement = false;
    }

    if (!this.waitForTargetingBoxElement && this.waitForScroll) {
      const animatingTargetings = Object.entries(this.bookingStore.state.targetingMetaData).reduce<
        Record<wooId, boolean>
      >(
        (newObject, [targetingId, targetingMetaData]) => (
          (newObject[targetingId] = targetingMetaData.expandedTargetingIsAnimating), newObject
        ),
        {},
      );
      const anyTargetingsAnimating = Object.values({ ...animatingTargetings }).some((isAnimating) => isAnimating);
      if (!anyTargetingsAnimating) {
        this.waitForScroll = false;
        this.scrollToTargeting(this.newTargetingId);
      }
    }
  }

  initFromStore = (model: BookingModel): void => {
    this.targetings = model.campaign.targetings;
  };

  addTargeting = (): void => {
    this.googleTagManagerService.addTargetingAbf('campaign-targetings');
    const newTargetingId = this.bookingStore.addTargeting();
    this.campaignService.setDefaultPublisherForTargeting(newTargetingId);
  };

  private scrollToTargeting = (id: wooId) => {
    const targetingElement = document.getElementById(id);
    this.scrollTo(targetingElement);
  };

  trackById(index: number, item: Targeting): wooId {
    return item.id;
  }
}
