<div class="advanced-booking-grid--width flex--align justify-content--space-between">
  <div>
    <button class="link" *ngIf="activeBookingStep !== firstStep" (click)="previousStep()">
      <span class="icon-TV4_009_bak"></span><span class="margin-left--1x">Föregående steg</span>
    </button>
  </div>
  <div>
    <button class="button primary-1" *ngIf="activeBookingStep !== lastStep" (click)="nextStep()">{{convertSecondButtonText(activeBookingStep)}}</button>
    <book-button *ngIf="activeBookingStep === lastStep"></book-button>
  </div>
</div>
