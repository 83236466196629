import { Component, Input, OnChanges } from '@angular/core';
import { round } from '../../../utils/math';
import { SimpleChanges } from '../../../utils/types';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { ViewCurrency } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { TargetingTracking } from '../../services/TrackingService';

const template = /* html */ `
<div class="width--50">
  <two-col-table
    bold="left"
    enlarge="right"
    [left]="viewLabelCol"
    [right]="viewValueCol"
    [fullWidth]="true"
  ></two-col-table>
</div>
`;

@Component({
  selector: 'targeting-outcome-summary',
  template: template,
})
export class TargetingOutcomeSummary implements OnChanges {
  @Input() viewCurrency: ViewCurrency;
  @Input() pauseTargeting: boolean;
  @Input() tracking: TargetingTracking;

  constructor(private formatterService: FormatterService, private translationService: TranslationService) {}

  viewLabelCol: string[];
  viewValueCol: Array<string | number>;

  ngOnChanges(changes: SimpleChanges<TargetingOutcomeSummary>): void {
    if (changes.tracking && this.tracking) {
      this.populateTable();
    }
  }

  private populateTable(): void {
    this.viewLabelCol = this.viewLabels;

    this.viewValueCol = this.viewValues;
  }

  private get viewLabels(): string[] {
    const currencyLabels = [this.translationService.convertViewCurrency(this.viewCurrency)];
    if (this.viewCurrency !== ViewCurrency.impressions) {
      currencyLabels.push(this.orderedViewCurrency);
    }
    currencyLabels.push('Levererade impressions');
    currencyLabels.push('Unika impressions');
    currencyLabels.push('Teknisk frekvens');
    if (!this.pauseTargeting) {
      currencyLabels.push('Completion Rate');
    }
    currencyLabels.push('Klick');

    return currencyLabels;
  }

  private get viewValues(): string[] {
    const currencyValues = [this.formatterService.transformNumber(this.tracking.bought_impressions)];

    if (this.viewCurrency !== ViewCurrency.impressions) {
      currencyValues.push(this.orderedViewCurrencyValue);
    }
    currencyValues.push(this.formatterService.transformNumber(this.tracking.impressions));
    currencyValues.push(this.formatterService.transformNumber(this.tracking.unique_impressions));
    currencyValues.push(this.uniqueImpressions());
    if (!this.pauseTargeting) {
      currencyValues.push(this.completionRate(this.tracking.completion_rate));
    }
    currencyValues.push(this.formatterService.transformNumber(this.tracking.click_throughs));

    return currencyValues;
  }

  private get orderedViewCurrency(): string {
    if (this.viewCurrency === ViewCurrency.completedViews) {
      return 'Levererade completed views';
    }
    return 'Levererade beräknade bruttokontakter';
  }

  private get orderedViewCurrencyValue(): string {
    if (this.viewCurrency === ViewCurrency.completedViews) {
      return this.formatterService.transformNumber(this.tracking.completed_views);
    }

    return this.formatterService.transformNumber(this.tracking.calculated_gross_rating_views);
  }

  private uniqueImpressions(): string {
    return this.formatterService.transformNumber(
      round((this.tracking.impressions || 0) / (this.tracking.unique_impressions || 1), 2),
    );
  }

  private completionRate(completion_rate: string | null): string {
    const parsed_completion_rate = parseFloat(completion_rate);
    if (typeof parsed_completion_rate !== 'number' || isNaN(parsed_completion_rate)) {
      return 'Ingen data';
    }

    return this.formatterService.transformPercent(parsed_completion_rate / 100.0);
  }
}
