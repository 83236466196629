import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RoutingService } from '../../woo_services.module/RoutingService';
import { TermsService } from '../../woo_services.module/TermsService';

@Injectable({ providedIn: 'root' })
class TermsGuardService {
  constructor(private routingService: RoutingService, private termsService: TermsService) {}
  canActivate(): boolean {
    if (this.termsService.userNeedsToAcceptTerms()) {
      this.routingService.navigate(['/terms']);
      return false;
    }
    return true;
  }
}

export const TermsGuard: CanActivateFn = (): boolean => {
  return inject(TermsGuardService).canActivate();
};
