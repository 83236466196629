<div class="card card-body bg-light">
  <div class="padding-bottom--4x">
    <h3>Hämta kampanjrader</h3>
    <form #rowForm="ngForm">
      <div class="flex__container">
        <div class="width--50 padding-right--1x display--inline-block">
          <woo-dropdown
            [items]="campaigns"
            [(ngModel)]="campaign"
            bindLabel="name"
            inputId="campaign-filter"
            labelText="Kampanj"
            [searchAttributes]="['name']"
            notFoundText="Inga kampanjer hittades."
            name="campaign-filter-select"
            required
          ></woo-dropdown>
        </div>
        <div class="input__container width--25 display--inline-block">
          <span class="input__optional__text">Frivillig</span>
          <label for="date-filter">Datum (YYYY-MM-DD)</label>
          <input name="date-filter" type="date" id="date-filter" [(ngModel)]="date" />
        </div>
      </div>
      <button
        class="button primary"
        [disabled]="!rowForm.valid"
        (click)="getRows(campaign.id, date)"
      >
        Hämta kampanjrader
      </button>
      <button
        *ngIf="isAdmin"
        class="button primary"
        [disabled]="!rowForm.valid"
        (click)="rebuildRows(campaign.id, campaign.name)"
      >
        Bygg om rader för kampanjen
      </button>
    </form>
  </div>

  <div class="padding-bottom--4x">
    <h3>Hämta TTV kampanjrader</h3>
    <form #rowFormTTV="ngForm">
      <div class="flex__container">
        <div class="width--50 padding-right--1x display--inline-block">
          <woo-dropdown
            [items]="ttvCampaigns"
            [(ngModel)]="ttvCampaign"
            bindLabel="name"
            inputId="ttv-campaign-filter"
            labelText="Kampanj"
            [searchAttributes]="['name']"
            notFoundText="Inga kampanjer hittades."
            name="ttv-campaign-filter-select"
            required
          ></woo-dropdown>
        </div>
        <div class="input__container width--25 display--inline-block">
          <span class="input__optional__text">Frivillig</span>
          <label for="ttv-date-filter">Datum (YYYY-MM-DD)</label>
          <input name="ttv-date-filter" type="date" id="ttv-date-filter" [(ngModel)]="ttvDate" />
        </div>
      </div>
      <button
        class="button primary"
        [disabled]="!rowFormTTV.valid"
        (click)="getTTVRows(ttvCampaign.id, ttvDate)"
      >
        Hämta TTV kampanjrader
      </button>
    </form>
  </div>

  <div class="padding-bottom--4x">
    <h3>Hämta aggregerat utfall (netto)</h3>
    <form #yearSelectForm="ngForm">
      <div class="flex__container">
        <div class="input__container width--25 display--inline-block padding-right--1x">
          <label for="year">År</label>
          <input
            id="year"
            name="year"
            [(ngModel)]="aggregatedRowsYear"
            required
            class="ng-pristine ng-empty ng-touched"
          />
        </div>
      </div>
      <button
        type="button"
        [disabled]="!yearSelectForm.valid"
        class="button primary"
        (click)="getAggregatedRows()"
      >
        Hämta utfall
      </button>
    </form>
  </div>

  <div>
    <h3>Hämta lagerrader</h3>
    <form #inventoryForm="ngForm">
      <div class="input__container width--25 display--inline-block padding-right--1x">
        <label for="start-year">Start År</label>
        <input
          id="start-year"
          name="start-year"
          [(ngModel)]="inventoryRowsFilter.startYear"
          required
          class="ng-pristine ng-empty ng-touched"
        />
      </div>

      <div class="input__container width--25 display--inline-block padding-right--1x">
        <label for="start-week">Start Vecka</label>
        <input
          id="start-week"
          name="start-week"
          [(ngModel)]="inventoryRowsFilter.startWeek"
          required
          class="ng-pristine ng-empty ng-touched"
        />
      </div>

      <div class="input__container width--25 display--inline-block">
        <label for="number-of-weeks">Antal Veckor</label>
        <input
          id="number-of-weeks"
          name="number-of-weeks"
          [(ngModel)]="inventoryRowsFilter.numberOfWeeks"
          required
          class="ng-pristine ng-empty ng-touched"
        />
      </div>
    </form>
    <button
      type="button"
      [disabled]="!inventoryForm.valid"
      class="button primary"
      (click)="getInventoryRows(inventoryRowsFilter)"
    >
      Hämta regions-lager
    </button>
    <button
      type="button"
      [disabled]="!inventoryForm.valid"
      class="button primary"
      (click)="getTTVInventoryRows(inventoryRowsFilter)"
    >
      Hämta TTV-lager
    </button>
  </div>
</div>

<div class="card card-body bg-light" *ngIf="testMode === 1">
  <campaign-row-list
    [rows]="rows"
    [campaignId]="campaign.id"
    [campaignName]="campaign.name"
  ></campaign-row-list>
</div>

<div class="card card-body bg-light" *ngIf="testMode === 2">
  <campaign-row-list
    [rows]="rows"
    [campaignId]="ttvCampaign.id"
    [campaignName]="ttvCampaign.name"
  ></campaign-row-list>
</div>

<div *ngIf="testMode === 3">
  <eos-aggregations [aggregatedRows]="aggregatedRows"></eos-aggregations>
</div>

<div class="card card-body bg-light" *ngIf="testMode === 4">
  <campaign-row-list [rows]="inventoryRows"></campaign-row-list>
</div>
