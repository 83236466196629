import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingModel, BookingTargetingMetaData } from '../advanced_campaign_booking.module/stores/BookingStore';
import { EnvironmentService } from './EnvironmentService';
import {
  GeoTargeting,
  ProductChoice,
  TargetingType,
  Voucher,
  VoucherCondition,
  VoucherConditionType,
  wooId,
} from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VoucherService {
  readonly PRODUCT_CONFIGURATIONS: Record<string, Array<[ProductChoice, GeoTargeting, TargetingType]>> = {
    national_pause: [
      [ProductChoice.rbs, GeoTargeting.national, TargetingType.pause],
      [ProductChoice.category, GeoTargeting.national, TargetingType.pause],
      [ProductChoice.program, GeoTargeting.national, TargetingType.pause],
    ],
    national_rbs: [[ProductChoice.rbs, GeoTargeting.national, TargetingType.instream]],
    national_target_group: [
      [ProductChoice.targetGroup, GeoTargeting.national, TargetingType.instream],
      [ProductChoice.advancedTargetGroup, GeoTargeting.national, TargetingType.instream],
    ],
    national_specific_and_category: [
      [ProductChoice.category, GeoTargeting.national, TargetingType.instream],
      [ProductChoice.daypart, GeoTargeting.national, TargetingType.instream],
      [ProductChoice.device, GeoTargeting.national, TargetingType.instream],
      [ProductChoice.program, GeoTargeting.national, TargetingType.instream],
    ],
    local_pause: [[ProductChoice.rbs, GeoTargeting.regional, TargetingType.pause]],
    local_rbs: [[ProductChoice.rbs, GeoTargeting.regional, TargetingType.instream]],
    local_specific_and_category: [
      [ProductChoice.category, GeoTargeting.regional, TargetingType.instream],
      [ProductChoice.program, GeoTargeting.regional, TargetingType.instream],
    ],
  };

  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getConditions(): Promise<VoucherConditionDefinition[]> {
    return lastValueFrom(this.http.get<VoucherConditionDefinition[]>(`${this.env.apiUrl}/vouchers/conditions`));
  }

  createVoucher(customerId: wooId, newVoucher: VoucherParams): Promise<Voucher> {
    if (newVoucher.agency && !newVoucher.agency.id) {
      newVoucher.agency = null;
    }
    return lastValueFrom(
      this.http.post(`${this.env.apiUrl}/customers/${customerId}/vouchers`, { voucher: newVoucher }),
    ) as Promise<Voucher>;
  }

  deleteVoucher(voucherId: wooId): Promise<Voucher> {
    return lastValueFrom(this.http.delete(`${this.env.apiUrl}/vouchers/${voucherId}`)) as Promise<Voucher>;
  }

  filterVouchers(
    vouchers: Voucher[],
    model: BookingModel,
    targetingId: wooId,
    testSingleTargeting: boolean,
  ): Voucher[] {
    return vouchers.filter((voucher) => this.testVoucher(voucher, model, targetingId, testSingleTargeting));
  }

  testVoucher(voucher: Voucher, model: BookingModel, targetingId: wooId, testSingleTargeting: boolean): boolean {
    return (
      (!voucher.campaign || model.campaign.id === voucher.campaign.id) &&
      ((!voucher.agency && !model.campaign.agency_id) ||
        (voucher.agency && voucher.agency.id === model.campaign.agency_id)) &&
      voucher.conditions.some((condition) => this.testCondition(condition, model, targetingId, testSingleTargeting))
    );
  }

  private testCondition(
    condition: VoucherCondition,
    model: BookingModel,
    targetingId: wooId,
    testSingleTargeting: boolean,
  ): boolean {
    return condition.type === VoucherConditionType.product
      ? this.testProductCondition(condition.values, model, targetingId, testSingleTargeting)
      : false;
  }

  private testProductCondition(
    products: string[],
    model: BookingModel,
    targetingId: wooId,
    testSingleTargeting: boolean,
  ): boolean {
    return products.some((product) => {
      const configurations = this.PRODUCT_CONFIGURATIONS[product] || [];
      return configurations.some(([prod, geo, type]) =>
        this.testConfigurationOnModel(prod, geo, type, model, targetingId, testSingleTargeting),
      );
    });
  }

  private testConfigurationOnModel(
    prod: ProductChoice,
    geo: GeoTargeting,
    type: TargetingType,
    model: BookingModel,
    targetingId: wooId,
    testSingleTargeting: boolean,
  ): boolean {
    if (testSingleTargeting) {
      const targetingData: BookingTargetingMetaData = model.targetingMetaData[targetingId];
      return targetingData && prod && geo && type
        ? targetingData.geoTargeting === geo &&
            targetingData.productChoice === prod &&
            targetingData.targetingType === type
        : false;
    } else {
      const targetingsMetaData: [string, BookingTargetingMetaData][] = Object.entries(model.targetingMetaData).filter(
        (targetingMetaData) => targetingMetaData[0] !== targetingId,
      );
      return targetingsMetaData.some((targetingData) => {
        return targetingData && prod && geo && type
          ? targetingData[1].geoTargeting === geo &&
              targetingData[1].productChoice === prod &&
              targetingData[1].targetingType === type
          : false;
      });
    }
  }
}

export interface VoucherConditionDefinition {
  type: 'product';
  accepted_values: string[];
}

export interface VoucherParams {
  discount: number;
  conditions: Array<{ type: string; values: any[] }>;
  agency?: { id: wooId };
  ad_organisation: { id: wooId };
}
