import { Injectable } from '@angular/core';
import { BookingRulesService } from '../../woo_services.module/BookingRulesService';
import { RuleSelection } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedBookingRulesService implements Pick<BookingRulesService, 'getAndConvertBookingRulesAbf'> {
  private listCache: RuleSelection[][] = null;

  constructor(private bookingRulesService: BookingRulesService) {}

  async getAndConvertBookingRulesAbf(): Promise<RuleSelection[][]> {
    if (!this.listCache) {
      this.listCache = await this.bookingRulesService.getAndConvertBookingRulesAbf();
    }
    return this.listCache;
  }
}
