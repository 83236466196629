<div class="block">
  <div class="account__card">
    <h1>Administrationsersättning</h1>
    <div class="block">
      <p class="text--medium thin">
        Här hittar du kvartalsrapporter avseende era investeringar i WOO via WOO Manager. Rapporten är ert faktureringsunderlag för att nyttja de 2 % administrationsersättning som TV4 medger vid self-service-bokningar i WOO Manager enligt mediebyråavtal. Fakturering av respektive kvartal medges upp till 90 dagar från det att rapporten publicerats i WOO Manager.
      </p>
    </div>
    <div class="display--flex justify-content--space-between align-items--center">
      <div>
        <button
          *ngIf="isAdminOrPlannerOrAccountingOrSalesManager"
          (click)="exportSummary()"
          class="button primary-1"
        >
          Exportera summeringsrapport
        </button>
        <button
          *ngIf="isAdmin"
          (click)="exportCampaignList()"
          class="button secondary"
        >
          Exportera kampanjrapport
        </button>
      </div>

      <woo-dropdown
        [items]="statuses"
        bindLabel="statusValue"
        bindValue="statusKey"
        class="float-right width--20"
        labelText="Filtrera"
        [(ngModel)]="activeFilter"
        (ngModelChange)="filterChange($event)"
        [clearable]="true"
      ></woo-dropdown>
    </div>
    <div *ngFor="let agency of filteredAgencySummaries">
      <h3>{{agency.name}}</h3>
      <span class="text--medium" *ngIf="agency.alias !== agency.name">
        {{agency.alias}}
      </span>
      <p *ngIf="!agency.reports.length">
        Din mediebyrå har tyvärr inte bokat några kampanjer själv under de gångna kvartalen.
      </p>

      <table
        class="table table--woo table--info table--small"
        *ngIf="agency.reports.length"
      >
        <thead>
          <tr>
            <th>År</th>
            <th>Kvartal</th>
            <th>Total administrationsersättning</th>
            <th>Publicerad</th>
            <th>Ladda ned</th>
            <th *ngIf="isAdminOrPlannerOrAccounting">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of agency.reports">
            <td>{{report.year}}</td>
            <td>{{report.quarter}}</td>
            <td>{{report.total | currency : 'kr'}}</td>
            <td>{{report.created_at || report.updated_at | date}}</td>
            <td>
              <button
                *ngIf="report.total !== 0"
                (click)="export(report.id, agency.name, report.year, report.quarter)"
                class="link"
              >
                Exportera
              </button>
              <span *ngIf="report.total === 0">Ingen rapport</span>
            </td>
            <td>
              <woo-dropdown
                [items]="statuses"
                bindLabel="statusValue"
                bindValue="statusKey"
                [(ngModel)]="report.status"
                (ngModelChange)="setStatus(report, $event)"
              ></woo-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="divider block"></div>
    </div>
  </div>
</div>
