import { Component } from '@angular/core';
import { WooDialogHelper } from '../../../woo_components.module/dialogs/woo-dialog-helper';
import { AdHocContractParams } from '../../../woo_services.module/AdHocContractService';
import { AdOrganisation, CompactAgency } from '../../../woo_services.module/shared-types';
import { VoucherParams } from '../../../woo_services.module/VoucherService';

const template = /* html */ `
<woo-dialog>
  <h2>Skapa ny rabatt</h2>
  <new-voucher
    *ngIf="showDiscount === NewDiscountType.voucher"
    (onNewVoucher)="submit($event)"
    (onAbort)="abort()"
    [agencies]="agencies"
    [adOrganisations]="adOrganisations"
  ></new-voucher>
  <new-ad-hoc-contract
    *ngIf="showDiscount === NewDiscountType.adhocContract"
    (onNewAdHocContract)="submit($event)"
    (onAbort)="abort()"
    [agencies]="agencies"
    [adOrganisations]="adOrganisations"
  ></new-ad-hoc-contract>
</woo-dialog>
`;

@Component({
  selector: 'new-discount-dialog',
  template: template,
})
export class NewDiscountDialog extends WooDialogHelper {
  agencies: CompactAgency[] = [];
  adOrganisations: AdOrganisation[] = [];
  showDiscount: NewDiscountType;

  NewDiscountType = NewDiscountType;

  abort: () => void;
  submit: (params) => void;

  showNewVoucher(agencies: CompactAgency[], adOrganisations: AdOrganisation[]): Promise<VoucherParams> {
    return this.showNewDiscount(agencies, adOrganisations, NewDiscountType.voucher);
  }

  showNewAdhocContract(agencies: CompactAgency[], adOrganisations: AdOrganisation[]): Promise<AdHocContractParams> {
    return this.showNewDiscount(agencies, adOrganisations, NewDiscountType.adhocContract);
  }

  private showNewDiscount<T>(
    agencies: CompactAgency[],
    adOrganisations: AdOrganisation[],
    discountType: NewDiscountType,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      this.agencies = agencies;
      this.adOrganisations = adOrganisations;
      this.showDiscount = discountType;
      this.abort = () => {
        this.close();
        this.showDiscount = null;
        reject();
      };
      this.submit = (params: T) => {
        this.close();
        this.showDiscount = null;
        resolve(params);
      };
      this.open(true);
    });
  }
}

export enum NewDiscountType {
  adhocContract = 'adhocContract',
  voucher = 'voucher',
}
