<div class="padding-bottom--2x">
  <input
    class="checkbox"
    id="use-segmentation-{{ instanceId }}"
    type="checkbox"
    [(ngModel)]="useSegmentation"
    (ngModelChange)="useSegmentationChange()"
    e2e-id="use-segmentation"
  />
  <label class="checkbox" for="use-segmentation-{{ instanceId }}"
    >Välj vilka dagar materialet ska visas</label
  >
</div>

<div *ngIf="!singleCreative" class="padding-bottom--2x">
  <form class="padding-bottom--1x">
    <input
      id="creative-share-percent-{{ instanceId }}"
      type="radio"
      name="creative-share-percent-{{ instanceId }}"
      class="radio"
      (ngModelChange)="creativeShareTypeChanged($event)"
      [(ngModel)]="creativeShareType"
      [value]="CreativeShareType.Percent"
    />
    <label for="creative-share-percent-{{ instanceId }}" class="radio">
      Fördela i procent
    </label>

    <input
      id="creative-share-budget-{{ instanceId }}"
      type="radio"
      name="creative-share-budget-{{ instanceId }}"
      class="radio"
      (ngModelChange)="creativeShareTypeChanged($event)"
      [(ngModel)]="creativeShareType"
      [value]="CreativeShareType.Budget"
      [disabled]="!calculateFromBudget"
    />
    <label for="creative-share-budget-{{ instanceId }}" class="radio">
      Fördela i budget
    </label>
    <p *ngIf="!calculateFromBudget">
      För att fördela mellan materialet i pengar behöver kampanjens budget vara
      angiven i pengar.
    </p>
  </form>

  <remaining-distribution
    [totalToDistribute]="totalToDistribute"
    [distributed]="distributed"
    [currencyType]="distributionCurrency"
  ></remaining-distribution>
</div>

<table
  class="table table--with-small-images margin--none centered"
  *ngIf="creatives.length && distributions"
>
  <thead>
    <tr>
      <td *ngIf="!isVideo">Bild</td>
      <td>Namn</td>
      <td *ngIf="isVideo">Leveranssätt</td>
      <td *ngIf="isVideo">Filmkod</td>
      <td *ngIf="isVideo">Längd</td>
      <td>Budget</td>
      <td>{{ currency }}</td>
      <td>Fördelning</td>
      <td></td>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let creative of creatives; trackBy: trackById">
      <tr [attr.e2e-id]="creative.custom_name">
        <td *ngIf="!isVideo">
          <img class="margin-right--1x" *ngIf="creative.asset_url" [src]="creative.asset_url" />
          <ng-container *ngIf="!creative.asset_url"
            >Levereras senare</ng-container
          >
        </td>
        <td>{{ creative.custom_name }}</td>
        <td *ngIf="isVideo">
          {{ translateCreativeType(creative.creative_type) }}
        </td>
        <td *ngIf="isVideo">{{ creative.video_code }}</td>
        <td *ngIf="isVideo">{{ creative.length }} s</td>
        <td>
          <ng-container *ngIf="editBudgetShare">
            <input
              type="text"
              class="input--compact long"
              [(ngModel)]="distributions[creative.id].budget"
              [wooMinValidator]="1"
              (ngModelChange)="updateQuotas()"
              wooNumberFormatter
            />
            <remaining-button
              (click)="setRemaining(creative)"
            ></remaining-button>
          </ng-container>
          <ng-container *ngIf="!editBudgetShare">{{
            distributions[creative.id].budget | currency: "kr" | dashAsDefault
          }}</ng-container>
        </td>
        <td>
          {{ distributions[creative.id].views | number | dashAsDefault }}
        </td>
        <td>
          <ng-container *ngIf="editPercentShare">
            <input
              type="text"
              class="input--compact"
              [wooPercentFormatter]="{
                modelFormat: modelFormat,
                precision: 2
              }"
              [wooMinValidator]="1"
              [wooMaxValidator]="100"
              [(ngModel)]="distributions[creative.id].quota"
              (ngModelChange)="updateQuotas()"
            />
            <remaining-button
              (click)="setRemaining(creative)"
            ></remaining-button>
          </ng-container>
          <ng-container *ngIf="!editPercentShare">{{
            distributions[creative.id].quota / 100 | percent: "1.0-2"
          }}</ng-container>
        </td>

        <td>
          <button (click)="removeCreative(creative)">
            <span class="icon-trash icon__semi-medium text--info"></span>
          </button>
          <button (click)="editCreative(creative)">
            <span
              class="icon-edit icon__semi-medium text--info"
            ></span>
          </button>
        </td>
      </tr>
      <tr *ngIf="useSegmentation">
        <td colspan="8">
          <button class="pull-right" (click)="toggleExpanded(creative.id)">
            <span
              class="chevron large text--info"
              [ngClass]="{ bottom: expanded[creative.id] }"
            >
            </span>
          </button>
          <p class="padding-bottom--2x">
            Välj de dagar som materialet ska gå.
            <br />
            Du kan endast välja mellan de dagar du har valt i styrningssteget.
          </p>
          <segment-select
            *ngIf="expanded[creative.id]"
            [@verticalExpansion]
            [attr.e2e-id]="creative.custom_name"
            class="display--block width--75"
            [periodStart]="targetingPeriod.start"
            [periodEnd]="targetingPeriod.end"
            [(ngModel)]="creative.segments"
            (ngModelChange)="segmentsChange(creative)"
            [unbookableWeeks]="unbookableWeeks"
          ></segment-select>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<button-with-line
  [onClick]="addCreative"
  label="Lägg till material"
></button-with-line>
