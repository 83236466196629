import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { SubscriptionHandler } from '../../advanced_campaign_booking.module/components/subscription-handler';
import { HotkeyService } from '../../woo_services.module/HotkeyService';
declare const $: any;

@Component({
  selector: 'woo-dialog',
  templateUrl: './woo-dialog.component.html',
  styleUrls: ['./woo-dialog.component.scss'],
})
export class WooDialog extends SubscriptionHandler implements OnDestroy, AfterViewInit {
  @Input() focusOnInit = true;
  @Output() onClose = new EventEmitter<void>();
  blocking = false;
  @ViewChild('bootstrapModal', { static: true }) private bootstrapModal: ElementRef;
  @ViewChild('initialFocusTarget', { static: true }) private initialFocusTarget: ElementRef;

  constructor(private hotkeyService: HotkeyService) {
    super();
  }

  open = (blocking = false): void => {
    this.blocking = blocking;
    $(this.bootstrapModal.nativeElement).modal({
      backdrop: blocking ? 'static' : true,
      keyboard: !blocking,
      show: true,
    });

    if (!blocking) {
      this.addSubscription(this.hotkeyService.addShortcut({ keys: 'esc' }).subscribe(this.close));
    }

    $(this.bootstrapModal.nativeElement).on('hidden.bs.modal', () => {
      this.onClose.emit();
    });
  };

  close = (): void => {
    if (this.bootstrapModal && this.bootstrapModal.nativeElement) {
      $(this.bootstrapModal.nativeElement).hide();
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    }
  };

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.close();
  }

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      this.initialFocusTarget.nativeElement.focus();
    }
  }
}
