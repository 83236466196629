import { Component, Input, OnChanges } from '@angular/core';
import { generateId } from '../../../utils/string';
import { ProgramFormatService } from '../../../woo_services.module/ProgramFormatService';
import { Program, TargetingProgram, wooId } from '../../../woo_services.module/shared-types';
import { CachedProgramFormatService } from '../../services/CachedProgramFormatService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';
import { ProgramFormatItemMode } from './program-format-item.component';

@Component({
  selector: 'program-format-targeting',
  templateUrl: './program-format-targeting.component.html',
  styleUrls: ['./program-format-targeting.component.scss'],
})
export class ProgramFormatTargeting extends SubscriptionHandler implements OnChanges {
  readonly ProgramFormatItemMode = ProgramFormatItemMode;
  readonly instanceId = generateId();

  searchString: string;
  selectablePrograms: Program[] = [];
  selectedPrograms: Program[] = [];
  targetingStart: string | Date;
  targetingEnd: string | Date;
  @Input() targetingId: wooId;

  private allPrograms: Program[] = [];

  constructor(
    cachedProgramService: CachedProgramFormatService,
    private programService: ProgramFormatService,
    private bookingStore: BookingStore,
  ) {
    super();
    cachedProgramService.getActiveProgramFormats().then((programs) => {
      this.allPrograms = programs;
      this.initFromStore(bookingStore.state);
    });
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      const selectedIds = targeting.program_formats.map((tp) => tp.program_format_id);
      this.targetingStart = targeting.start_date;
      this.targetingEnd = targeting.end_date;
      this.selectedPrograms = this.getSelectedPrograms(selectedIds);
      this.selectablePrograms = this.getSelectablePrograms(selectedIds);
    }
  };

  filter = (): void => {
    this.initFromStore(this.bookingStore.state);
  };

  addProgram = (program: Program): void => {
    const targetingPrograms = this.selectedPrograms.concat(program).map(this.toTargetingProgram);
    this.bookingStore.setProgramFormats(targetingPrograms, this.targetingId);
  };

  removeProgram = (program: Program): void => {
    const targetingPrograms = this.selectedPrograms.filter((p) => p.id !== program.id).map(this.toTargetingProgram);
    this.bookingStore.setProgramFormats(targetingPrograms, this.targetingId);
  };

  private toTargetingProgram = (program: Program): TargetingProgram => {
    return {
      name: program.name,
      program_format_id: program.id,
      budget: 0,
      impressions: 0,
      publisher_name: program.publisher_name,
      category: {
        id: program.category_id,
        name: program.category_name,
      },
    };
  };

  private searchFilter = (p: Program): boolean => {
    return !this.searchString || p.name.toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase());
  };

  private getSelectedPrograms(selectedIds: string[]): Program[] {
    return this.allPrograms.filter((p) => selectedIds.includes(p.id));
  }

  private getSelectablePrograms(selectedIds: string[]): Program[] {
    if (this.targetingEnd && this.targetingStart) {
      return this.allPrograms
        .filter((p) => !selectedIds.includes(p.id))
        .filter((p) =>
          this.programService.activePeriod(p, {
            start_date: new Date(this.targetingStart),
            end_date: new Date(this.targetingEnd),
          }),
        )
        .filter(this.searchFilter);
    } else {
      return [];
    }
  }
}
