import { Injectable } from '@angular/core';
import { CategoryService } from '../../woo_services.module/CategoryService';
import { Category } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedCategoryService implements Pick<CategoryService, 'getCategories'> {
  private listCache: Category[] = null;

  constructor(private categoryService: CategoryService) {}

  async getCategories(): Promise<Category[]> {
    if (!this.listCache) {
      this.listCache = await this.categoryService.getCategories();
    }
    return this.listCache;
  }
}
