import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { compareWith } from '../../utils/object';
import { UserRole } from '../../utils/user-roles';
import { AccountService } from '../../woo_services.module/AccountService';
import { AgencyService } from '../../woo_services.module/AgencyService';
import { AuthService } from '../../woo_services.module/AuthService';
import { CustomerService } from '../../woo_services.module/CustomerService';
import { RoutingService } from '../../woo_services.module/RoutingService';
import { CompactAgency, CompactCustomer, CompactUser } from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { UserService } from '../../woo_services.module/UserService';

enum Tab {
  AgencyCustomers = 'agency_customers',
  Agencies = 'agencies',
  DirectCustomers = 'customers',
  CashCustomers = 'cash_customers',
  Users = 'users',
}

@Component({
  selector: 'manage-accounts',
  templateUrl: './manage-accounts.component.html',
})
export class ManageAccounts implements OnInit {
  readonly Tab = Tab;
  readonly translateRoles = this.translationService.convertRoles;
  readonly isExternalPlanner = this.authService.hasRole(UserRole.externalPlanner);
  readonly isAdminOrExternalPlanner = this.authService.hasAnyRole([UserRole.admin, UserRole.externalPlanner]);

  tabVisible: Tab = Tab.AgencyCustomers;
  activeUsers: CompactUser[] = [];
  inactiveUsers: CompactUser[] = [];
  agencies: CompactAgency[] = [];
  clients: CompactCustomer[] = [];
  cashCustomers: CompactCustomer[] = [];
  agencyCustomers: CompactCustomer[] = [];
  inactiveAgencyCustomers: CompactCustomer[] = [];

  hasLoaded = {
    agencies: false,
    agencyCustomers: false,
    clients: false,
    cashCustomers: false,
    users: false,
  };

  show = {
    inactiveUsers: false,
    nicknames: this.authService.adminOrPlanner(),
  };

  cashTabVisible = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private agencyService: AgencyService,
    private accountService: AccountService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    if (this.authService.hasRole(UserRole.externalPlanner)) {
      this.tabVisible = Tab.Users;
    } else if (this.authService.hasRole(UserRole.client)) {
      this.tabVisible = Tab.DirectCustomers;
    }

    this.userService.getUsers().then((users) => {
      const compare = compareWith('first_name');
      this.activeUsers = users.filter((user) => user.allowed_to_sign_in).sort(compare);
      this.inactiveUsers = users.filter((user) => !user.allowed_to_sign_in).sort(compare);
      this.hasLoaded.users = true;
    });

    this.agencyService.getAgencies().then((agencies) => {
      this.agencies = agencies.sort(compareWith('name'));
      this.hasLoaded.agencies = true;
    });

    this.accountService.getAgencyCustomers().then((agencyCustomers) => {
      const compare = compareWith('name');
      this.agencyCustomers = agencyCustomers.filter((c) => c.active_customer).sort(compare);
      this.inactiveAgencyCustomers = agencyCustomers.filter((c) => !c.active_customer).sort(compare);
      this.hasLoaded.agencyCustomers = true;
    });

    this.customerService.getClientsForCurrentUser().then((clients) => {
      this.clients = clients.sort(compareWith('name'));
      this.hasLoaded.clients = true;
    });
    this.customerService.getCashCustomersForCurrentUser().then((cashCustomers) => {
      this.cashCustomers = cashCustomers.sort(compareWith('name'));
      if (this.cashCustomers.length !== 0) {
        this.cashTabVisible = true;
      }
      this.hasLoaded.cashCustomers = true;
    });

    if (this.routingService.getParam(this.route, 'tab')) {
      this.setTab(this.routingService.getParam(this.route, 'tab') as Tab);
    }
  }

  hasAccessToTab(tab: Tab): boolean {
    switch (tab) {
      case Tab.AgencyCustomers:
        return this.authService.hasAnyRole([
          UserRole.admin,
          UserRole.planner,
          UserRole.accounting,
          UserRole.agencyAdmin,
          UserRole.sales,
          UserRole.salesManager,
          UserRole.agencyUser,
        ]);
      case Tab.Agencies:
        return this.authService.hasAnyRole([
          UserRole.admin,
          UserRole.planner,
          UserRole.accounting,
          UserRole.agencyAdmin,
          UserRole.sales,
          UserRole.salesManager,
          UserRole.agencyUser,
        ]);
      case Tab.DirectCustomers:
        return this.authService.hasAnyRole([
          UserRole.admin,
          UserRole.planner,
          UserRole.accounting,
          UserRole.sales,
          UserRole.salesManager,
          UserRole.client,
        ]);
      case Tab.CashCustomers:
        return this.authService.hasAnyRole([
          UserRole.admin,
          UserRole.planner,
          UserRole.accounting,
          UserRole.sales,
          UserRole.salesManager,
        ]);
      case Tab.Users:
        return this.authService.hasAnyRole([UserRole.admin, UserRole.planner, UserRole.externalPlanner]);
      default:
        return false;
    }
  }

  setTab(tab: Tab): void {
    this.tabVisible = tab;
  }
}
