import { Component, Input, OnChanges } from '@angular/core';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { ShareType, Targeting, TargetingRegion, wooId } from '../../../woo_services.module/shared-types';
import { BookingStore } from '../../stores/BookingStore';
import { AbstractDistribution, DistributionStruct } from './abstract-distribution.component';
import { Distributable } from './distribution-table.component';

@Component({
  selector: 'region-distribution',
  templateUrl: './abstract-distribution.component.html',
})
export class RegionDistribution extends AbstractDistribution<TargetingRegion> implements OnChanges {
  distributionName = 'Region';
  @Input() disabled = false;
  @Input() targetingId: wooId;

  constructor(protected bookingStore: BookingStore, protected formatterService: FormatterService) {
    super(bookingStore, formatterService);
  }

  getShareType(targeting: Targeting): ShareType {
    return targeting.regional_share_type;
  }

  getDistributionStructs(targeting: Targeting): TargetingRegion[] {
    return targeting.regions;
  }

  updateDistribution(structs: DistributionStruct[]): void {
    this.bookingStore.setRegions(structs.map(this.createTargetingItem), this.targetingId);
  }

  shareTypeChanged = (newShareType: ShareType): void => {
    this.bookingStore.setRegionalShareType(this.targetingId, newShareType);
  };

  createDistributable(region: TargetingRegion, budget: number, completedViews: number, percent: number): Distributable {
    return {
      id: region.region_id,
      name: region.name,
      budget: budget,
      completedViews: completedViews,
      percent: percent,
    };
  }

  createTargetingItem(item: Distributable): TargetingRegion {
    return {
      region_id: item.id,
      name: item.name,
      budget: item.budget,
      impressions: item.completedViews,
    };
  }
}
