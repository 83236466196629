<div class="block">

  <h1>Prislistor</h1>

  <div class="text--medium">
    <p><strong>CPM</strong> (cost per mille) anger kostnaden i kronor för 1000 reklamfilmsvisningar (impressions).</p>
    <p *ngIf="showingCompletedViewsPriceList"><strong>CPCV</strong> (cost per completed view) anger kostnaden i kronor för 1000 färdigspelade
      reklamfilmsvisningar.</p>
    <p *ngIf="showingGrossRatingViewsPriceList"><strong>CPT</strong>
      (cost per thousand) anger kostnaden i kronor för 1000 bruttokontakter.
    </p>
    <p><strong>Prisuträkning:</strong> Pris * Filmindex * Veckoindex</p>
  </div>

  <span *ngFor="let priceList of standardPriceLists" class="margin-right--1x">
    <button
      class="tabbable no-outline"
      (click)="changeVisiblePriceList(priceList)"
      [ngClass]="{active: visiblePriceList === priceList.id}"
    >
      {{getLabel(priceList)}}
    </button>
  </span>

  <div *ngFor="let priceList of standardPriceLists" class="relative">
    <div *ngIf="visiblePriceList === priceList.id">

      <div class="tabbable__panel clearfix">
        <div class="col-xs-12">
        </div>
      </div>

      <div class="tabbable__body margin--none clearfix shadow-z-1">
        <date-span [start]="priceList.valid_from" [end]="priceList.valid_to"></date-span>

        <div class="top-row-container">
          <div class="export-buttons">
            <text-button class="display--block margin-left--1x margin-bottom--1x" icon="download" (click)="downloadExcel(false)">
              Exportera prislista till Excel
            </text-button>
            <text-button class="display--block margin-left--1x margin-bottom--1x" icon="download" (click)="downloadExcel(true)">
              Exportera alla prislistor till Excel
            </text-button>
          </div>
          <div class="filter-row-container">
            <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
              <woo-dropdown
              [items]="publishers"
              [(ngModel)]="selectedPublisher"
              name="selectedPublisher"
              inputId="select-publisher"
              labelText="Välj Publicist"
              notFoundText="Inga publicister hittades."
              [clearable]="true"></woo-dropdown>
            </div>

            <div class="filter-dropdown margin-left--2x">
              <woo-dropdown
              [items]="productFormatOptions"
              [(ngModel)]="selectedProductFormat"
              name="selectedProductFormat"
              inputId="select-productFromat"
              labelText="Välj Placeringspris"
              notFoundText="Inga Placeringspriser hittades."
              [clearable]="true"></woo-dropdown>
            </div>

            <div class="filter-dropdown margin-left--2x">
              <woo-dropdown
              [items]="targetingOptions"
              [(ngModel)]="selectedTargetingType"
              name="selectedTargetingType"
              inputId="select-targeting"
              labelText="Välj Styrningspris"
              notFoundText="Inga styrningspriser hittades."
              [clearable]="true"></woo-dropdown>
            </div>

            <div class="filter-dropdown margin-left--2x">
              <woo-dropdown
              [items]="localityOptions"
              [(ngModel)]="selectedLocalityType"
              name="selectedLocalityType"
              inputId="select-locality"
              labelText="Välj Geografipris"
              notFoundText="Inga geografipriser hittades."
              [clearable]="true"></woo-dropdown>
            </div>
          </div>
        </div>

        <table class="table table-woo table--hover table--info width--100">
          <thead>
            <tr>
              <th *ngFor="let header of tableHeaders" class="capitalize--first-letter">
                {{header}}
              </th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let row of getYearlyTable(visiblePriceList); let index = index;">
              <tr [hidden]="!rowVisible(row)">
                <td class="text--thin vertical-align--middle">
                  {{getPublisherName(row.publisherId)}}
                </td>
                <td class="text--thin vertical-align--middle">
                  {{translateBaseProduct(row.productFormat)}}
                </td>
                <td class="text--thin vertical-align--middle">
                  {{translateTargetingProduct(row.targetingType)}}
                </td>
                <td class="text--thin vertical-align--middle">
                  {{translateTargetingProduct(row.addonTargeting)}}
                </td>
                <td class="text--thin vertical-align--middle">
                  {{translateLocality(row.localityType)}}
                </td>
                <td class="text--thin vertical-align--middle">
                  <p class="display--inline-block text--semi-strong margin--none">{{row.totalPrice}}</p> {{getTypeOfCost(row.productFormat)}}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <div class="col-md-6 border--info">
          <h2>Åldersgrupp priser</h2>
          <h5>(OBS! Gäller enbart Adresserbar TV samt Kombination)</h5>

          <table class="table table--woo table--hover table--info">
            <thead>
              <tr>
                <th>Publicist</th>
                <th>Åldersgrupp</th>
                <th>CPT-påslag</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of buildAgeModifierPriceRows(priceList)">
                <td>
                  {{getPublisherName(row.publisherId)}}
                </td>
                <td>
                  {{translateAgeModifierKey(row.age)}}
                </td>
                <td>
                  {{row.price}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-xs-12">
              <h2>Veckoindex</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 border--info">
            <table class="table table--woo table--hover table--info">
              <thead>
                <tr>
                  <th>Vecka</th>
                  <th>Video</th>
                  <th>Pausreklam</th>
                </tr>
              </thead>
              <tbody *ngIf="!weekInfo.get(priceList).weekIndexesPresent">
                <tr>
                  <td>Alla veckor</td>
                  <td>100</td>
                  <td>100</td>
                </tr>
              </tbody>
              <tbody *ngIf="weekInfo.get(priceList).weekIndexesPresent">
                <tr *ngFor="let week of weekInfo.get(priceList).firstWeeks">
                  <td>{{week}}</td>
                  <td>{{priceList.week_price_indexes[week - 1]}}</td>
                  <td>{{priceList.pause_ad_week_price_indexes[week - 1]}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6 border--info" *ngIf="weekInfo.get(priceList).weekIndexesPresent">
            <table class="table table--woo table--hover table--info">
              <thead>
                <tr>
                  <th>Vecka</th>
                  <th>Video</th>
                  <th>Pausreklam</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let week of weekInfo.get(priceList).secondWeeks">
                  <td>{{week}}</td>
                  <td>{{priceList.week_price_indexes[week - 1]}}</td>
                  <td>{{priceList.pause_ad_week_price_indexes[week - 1]}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 border--info" *ngIf="!isExternalPlanner">
            <h2>Frekvenstaksindex</h2>
            <table class="table table--woo table--hover table--info">
              <thead>
                <tr>
                  <th>Frekvenstak</th>
                  <th>Index</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let frequency_limit_price of getActivePrices(priceList.frequency_limit_prices); trackBy: trackById">
                  <td>{{frequency_limit_price.frequency_limit.name}}</td>
                  <td>{{frequency_limit_price.ratio}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-6 border--info">
            <h2>Filmindex</h2>

            <table class="table table--woo table--hover table--info">
              <thead>
                <tr>
                  <th>Filmlängd</th>
                  <th>Index</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let length of creativeLengths">
                  <td>{{length}}</td>
                  <td>{{priceList.creative_length_price_indexes[length]}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
