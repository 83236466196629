<div class="box-container">
  <button *ngIf="showRemove" class="close-button icon icon-close-x" (click)="onRemove.emit()"></button>
  <div class="relative">
    <targeting-label class="margin-bottom--4px display--block" [label]="targetingLabel"></targeting-label>
    <h3 e2e-id="estimated-views" class="box-header margin-top--2x margin-bottom--1x">
      <span class="value">{{ estimatedViews | number | dashAsDefault }}</span>
      <span e2e-id="view-currency" class="currency padding-left--1x">{{currency}}</span>
    </h3>

    <div class="divider dark margin-top--1x margin-bottom--2x"></div>

    <p *ngFor="let row of targetingRows.concat(estimationRows)" class="targeting-row margin-bottom--4px">
      <span class="text-align--left">{{row.label}}</span>
      <span class="text-align--right">{{row.value | dashAsDefault}}</span>
    </p>

    <regional-indicator *ngIf="includesLinear" title="Regionalpaketering" [subtitle]="salesOrderNumber"></regional-indicator>
    <regional-indicator *ngIf="isRegional && regionalDivision === RegionalDivision.ZipCodeBased" title="Postnummerstyrning" ></regional-indicator>
    <send-invoices-indicator [sendInvoices]="sendInvoices"></send-invoices-indicator>

    <p *ngIf="forcedCorrection?.unbookableWeeks?.length">
      <span class="text-align--left">Fulla veckor: </span>
      <span class="text-align--right" class="text--danger">{{unbookableWeeks | dashAsDefault}}</span>
    </p>
    <edit-button *ngIf="showEditTargeting" (click)="onEditTargeting.emit()"></edit-button>
  </div>

  <div class="relative" *ngIf="creativesRows.length > 0">
    <div class="divider dark margin-top-bottom--1x"></div>

    <p *ngFor="let row of creativesRows" class="margin-bottom--4px ellipsis">
      {{row}}
    </p>

    <edit-button *ngIf="showEditTargeting" (click)="onEditCreatives.emit()"></edit-button>
  </div>
</div>
