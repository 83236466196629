<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera omvandlingsnyckel för bruttokontakter</h3>
</div>

<div #stickyHeader>
  <div class="tabbable__body" >
    <div class="flex__container align-items--left">
      <div class="input__container">
        <label for="factor">Omvandlingsnyckel</label>
        <input
          type="number"
          inputId="gross-rating-factor"
          name="grossRatingFactor"
          step="0.001"
          [(ngModel)]="grossRatingFactor"/>
      </div>
      <div>
        <button class="button primary button margin-left--2x" [disabled]="disableSaveButton()" (click)="save()" >Spara</button>
        <button *ngIf="show.hasHistory && !show.history" class="button primary" (click)="openCard()">Historik</button>
        <button *ngIf="show.hasHistory && show.history" class="button primary" (click)="closeCard()">Stäng historik</button>
      </div>
    </div>
  </div>
</div>

<div [hidden]="!show.history" class="inner-block relative row padding-bottom--2x">
  <div class="col-xs-12">
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Historik</h3>
      </div>
    </div>
    
    <div class="tabbable__body shadow-z-1">
        <table class="table table-woo table--info table--hover width--100">
          <thead #stickyTableHeader>
            <tr>
              <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
                {{header}}
              </th>
            </tr>
          </thead>
    
          <tbody *ngIf="!show.loading">
            <ng-container *ngFor="let historyRow of historyGrossRatingData">
              <tr>
                <td class="text--thin vertical-align--middle">
                  <span>{{historyRow.grossRatingFactor}}</span>
                </td>
                <td class="text--thin vertical-align--middle">
                  <span >{{historyRow.updatedAt | date:'yyyy-MM-dd'}}</span>
                </td>
                <td class="text--thin vertical-align--middle">
                  <span >{{historyRow.updater}}</span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
    
        <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    </div>
  </div>
</div>

