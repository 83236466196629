<div class="status-badge large_icon success" [ngClass]="{ 'small': small }" *ngIf="adServerStatus === AdServerStatus.sent">
  <span class="icon-check"></span>
  <div class="hover-text">Skickad till adservern</div>
</div>
<div class="status-badge large_icon error" [ngClass]="{ 'small': small }" *ngIf="adServerStatus === AdServerStatus.unsent">
  <span class="icon-heads-up-outline"></span>
  <div class="hover-text">Ej skickad</div>
</div>
<div class="status-badge large_icon cta" [ngClass]="{ 'small': small }" *ngIf="adServerStatus === AdServerStatus.dirty">
  <span class="icon-heads-up-outline"></span>
  <div class="hover-text">Uppdaterad</div>
</div>
