import { ElementRef, Injectable } from '@angular/core';
import { smoothScroll, smoothScrollTo } from '../../utils/dom';

@Injectable()
export class ScrollContainerService {
  private readonly smoothScroll = smoothScroll;
  private readonly smoothScrollTo = smoothScrollTo;

  private _scrollContainer: ElementRef<HTMLElement>;

  set scrollContainer(scrollContainer: ElementRef<HTMLElement>) {
    this._scrollContainer = scrollContainer;
  }

  get scrollContainer(): ElementRef<HTMLElement> {
    return this._scrollContainer;
  }

  scrollTo = (element: HTMLElement): void =>
    this._scrollContainer && this.smoothScrollTo(element, this._scrollContainer.nativeElement);
  scrollToTop = (): void => this._scrollContainer && this.smoothScroll(0, false, this._scrollContainer.nativeElement);
}
