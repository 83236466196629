import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService, ProgressCallback } from './JobService';
import { Agency, CompactAgency, CompactCustomer, wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AgencyService {
  constructor(private http: HttpClient, private jobService: JobService, private env: EnvironmentService) {}

  getAgencies(): Promise<CompactAgency[]> {
    return lastValueFrom(this.http.get<CompactAgency[]>(`${this.env.apiUrl}/agencies`));
  }

  getAgency(id: string): Promise<Agency> {
    return lastValueFrom(this.http.get<Agency>(`${this.env.apiUrl}/agencies/${id}`));
  }

  getAllAgencyCustomers(id: string): Promise<CompactCustomer[]> {
    return lastValueFrom(this.http.get<CompactCustomer[]>(`${this.env.apiUrl}/agencies/all_customers/${id}`));
  }

  updateAgency(agency: Agency): Promise<Agency> {
    return lastValueFrom(
      this.http.patch<Agency>(`${this.env.apiUrl}/agencies/${agency.id}`, {
        agency: agency,
      }),
    );
  }

  async getAgencyOverview(
    agencyId: wooId,
    from: Date,
    to: Date,
    customerId: wooId,
    dateType: DateType,
    progressCallback: ProgressCallback,
  ): Promise<AgencyStatistics> {
    const jobId = (
      await lastValueFrom(
        this.http.post<Job>(`${this.env.apiUrl}/agencies/overview`, {
          agency_id: agencyId,
          customer_id: customerId,
          from: from,
          to: to,
          date_field: dateType,
        }),
      )
    ).id;
    return this.jobService.waitForJobCompletion(jobId, progressCallback);
  }
}

export enum DateType {
  StartDate = 'start_date',
  EndDate = 'end_date',
  BookedDate = 'booked_date',
}

export interface AgencyStatistics {
  counts: {
    booked_count: number;
    booked_budget: number;
    booked_discount: number;
    charge_sum: number;
    estimated_budget: number;
    estimated_agency_discount: number;
    saved_count: number;
    cancelled_count: number;
  };
  campaigns: CampaignStatistic[];
  booking_statistics: PersonStatistic[];
}

export interface CampaignStatistic {
  name: string;
  budget: number;
  self_service_compensation: number;
  period_estimate: number;
  estimated_agency_discount: number;
  agency_discount: number;
  booked_by: {
    id: wooId;
    name: string;
    type: string;
  };
}

export interface PersonStatistic {
  id: wooId;
  name: string;
  type: string;
  count: number;
  budget: number;
}
