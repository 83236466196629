import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../woo_services.module/AuthService';

@Injectable({ providedIn: 'root' })
class AuthGuardService {
  constructor(private authService: AuthService) {}
  canActivate(): boolean {
    if (!this.authService.isAuthenticated()) {
      this.authService.notAuthenticated$.next({ authorizationRequest: true, attemptedUrl: window.location.href });
      return false;
    }
    return true;
  }
}

export const AuthGuard: CanActivateFn = (): boolean => {
  return inject(AuthGuardService).canActivate();
};
