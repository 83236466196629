<div *ngIf="campaign" class="campaign" fixedWidth ref="campaign-sidebar">
  <h2>Redigera kampanj</h2>

  <div class="account__card account__card--margin--right account__card--no--animate" *ngIf="show.selectContainer">
    <div class="text-center">
      <h2>Vad vill du göra?</h2>
      <button class="button" (click)="navigateTo('extendCampaign')">Förlänga kampanj</button>
      <button class="button" (click)="navigateTo('updateCampaignBudget')" *ngIf="campaign.status === 'ongoing'">Utöka kampanjbudget</button>
      <button class="button" (click)="navigateTo('boostGoals')" *ngIf="campaign.status === 'ongoing'">Öka eller minska mål</button>
      <button class="button" (click)="navigateTo('addCreative')" *ngIf="campaign.status === 'ongoing' && hasVideoCreatives">Lägga till film</button>
      <button class="button" (click)="navigateTo('updateCampaignName')" *ngIf="campaign.status === 'ongoing'">Ändra namn</button>
    </div>
    <div class="clearfix">
      <button class="link primary-1 pull-right" [routerLink]="['/dashboard', campaign.id]">Avbryt</button>
    </div>
  </div>

  <div *ngIf="show.extendCampaign" class="margin-right--1x">
    <extend-campaign [campaign]="campaign" (campaignUpdated)="campaignExtended($event)" (onAbort)="navigateTo('selectContainer')"></extend-campaign>
  </div>

  <div *ngIf="show.boostGoals" class="margin-right--1x">
    <edit-goals
      [campaign]="campaign"
      (onAbort)="navigateTo('selectContainer')"
      (onSuccess)="loadCampaign(); navigateTo('selectContainer');"
      (campaignNeedsReload)="loadCampaign()"
    ></edit-goals>
  </div>

  <div class="account__card account__card--margin--right account__card--no--animate" *ngIf="show.addCreative">
    <add-creative-to-ongoing-campaign class="row margin--none" (onAbort)="loadCampaign(); navigateTo('selectContainer')" [campaign]="campaign"></add-creative-to-ongoing-campaign>
  </div>

  <div class="account__card account__card--margin--right account__card--no--animate" *ngIf="show.updateCampaignName">
    <update-ongoing-campaign-name
      class="row margin--none"
      (onAbort)="navigateTo('selectContainer')"
      (onSuccess)="loadCampaign(); navigateTo('selectContainer');"
      [campaign]="campaign">
    </update-ongoing-campaign-name>
  </div>

  <div class="account__card account__card--margin--right account__card--no--animate" *ngIf="show.updateCampaignBudget">
    <update-ongoing-campaign-budget
      class="row margin--none"
      (onAbort)="navigateTo('selectContainer')"
      (onSuccess)="loadCampaign(); navigateTo('selectContainer');"
      [campaign]="campaign">
    </update-ongoing-campaign-budget>
  </div>

  

  <div class="campaign-sidebar">
    <dashboard-sidebar [editOngoingCampaign]="true" [campaign]="campaign"></dashboard-sidebar>
  </div>
