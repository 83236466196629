import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from '../../../../utils/dom';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { RegionService } from '../../../../woo_services.module/RegionService';
import { ProductFormat, Publisher, Region, wooId } from '../../../../woo_services.module/shared-types';
import { SystemService } from '../../../../woo_services.module/SystemService';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'regions',
  templateUrl: './manage-regions.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageRegions implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    totalSharesHaveChanged: false,
    loading: true,
    isEditing: false,
  };
  tableHeaders = {};
  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  regionRows: RegionRow[];
  regions: Region[] = [];
  shareOfTotal: number;
  regionSharesHaveChanged: boolean;
  shares: Record<wooId, number> = {};
  translateProductFormat: (args: any) => string;
  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private regionService: RegionService,
    private dialogService: DialogService,
    private systemService: SystemService,
    private translationService: TranslationService,
    private utilityService: ProductsPageUtilityService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.translateProductFormat = this.translationService.convertProductFormat;

    try {
      this.publishers = (await this.publisherService.getPublishers(ExtraPublisherField.enabled)).filter(
        (p) => p.enabled,
      );
      await this.getandBuilsRegionRows();
      this.productFormats = (await this.productFormatService.getProductFormats(ExtraProductFormatField.enabled)).filter(
        (f) => f.enabled,
      );
      this.filterProductFormats();
      this.buildTableHeaders();
      this.shareOfTotal = await this.systemService.getValue('regional_estimate_share_of_total');
    } finally {
      this.show.loading = false;
      this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      scrollTo(this.parentHeader);
    }
  }

  async getandBuilsRegionRows(): Promise<void> {
    this.regions = await this.regionService.getRegions();
    this.buildRegionRows();
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  async cancelEditing(): Promise<void> {
    await this.getandBuilsRegionRows();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    try {
      await this.dialogService.openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      });
      this.show.loading = true;
      await this.regionService.updateRegionsShares(this.regions);
      await this.getandBuilsRegionRows();
      this.show.totalSharesHaveChanged = true;
    } catch {
    } finally {
      this.show.loading = false;
      this.setEditing(false);
      this.dialogService.closeBlocking();
    }
  }

  async updateShareOfTotal(): Promise<void> {
    try {
      await this.systemService.updateShareOfTotal({ regional_estimate_share_of_total: this.shareOfTotal });
      this.show.totalSharesHaveChanged = true;
    } catch (regions) {
      console.error('Error', regions);
      this.dialogService.openError('Uppdateringen misslyckades');
    }
  }

  getRegion(regionId: wooId): Region {
    return this.regions.find((region) => region.id === regionId);
  }

  sumOfProductFormatShares(productFormatId: wooId): number {
    // js Math does not round decimal places
    return Math.round(this.regions.reduce((a, b) => a + (b.shares[productFormatId] || 0), 0) * 100 * 100) / 100;
  }

  rowVisible(regionId: wooId): boolean {
    return this.getRegion(regionId).enabled;
  }

  colVisible(productFormatId: wooId): boolean {
    if (!this.productFormats?.length) return true;

    if (this.selectedProductFormat && productFormatId !== this.selectedProductFormat.id) {
      return false;
    }
    return this.productFormats.some((format) => format.id === productFormatId);
  }

  private buildTableHeaders(): void {
    const tableHeaders = {};
    this.productFormats.map((productFormat) => {
      tableHeaders[productFormat.id] = productFormat.name;
    });
    this.tableHeaders = tableHeaders;
  }

  private buildRegionRows(): void {
    let regionRows: RegionRow[] = [];

    this.regions.map((region) => {
      regionRows = [
        ...regionRows,
        {
          regionName: region.name,
          regionId: region.id,
          productFormatIds: Object.keys(region.shares),
        },
      ];
    });
    this.regionRows = regionRows;
  }

  private filterProductFormats(): void {
    this.productFormats = this.productFormats.filter((format) =>
      this.publishers.some((publisher) => publisher.id === format.publisher_id),
    );
  }
}

interface RegionRow {
  regionName: string;
  regionId: wooId;
  productFormatIds: wooId[];
}
