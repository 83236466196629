import { Injectable } from '@angular/core';
import { DialogService } from './DialogService';
import { ServerEvents } from './StandardResponseService';
import { TranslationService } from './TranslationService';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(private translationService: TranslationService, private dialogService: DialogService) {}

  process(event: { name: string }, data?: { validation_error?: boolean; error?: string; messages?: string[][] }): void {
    this.dialogService.closeBlocking();
    if (!data) {
      return;
    }
    if (data.validation_error) {
      if (event.name === ServerEvents.returnedUserError) {
        if (data.error && data.messages) {
          const translatedErrorMessages = data.messages.map((message) => {
            return message.map((errorPart) => {
              return this.dictionaryLookUpValidationError(errorPart);
            });
          });
          this.dialogService.openError(
            this.dictionaryLookUpValidationError(data.error),
            ...translatedErrorMessages.flat(),
          );
        } else {
          // Send possibly unhandled validation errors to sentry
          console.error('Possibly unhandled validation error:', data);
        }
      }
    } else if (event.name === ServerEvents.serviceUnavailableError) {
      this.dialogService.openError(this.dictionaryLookUp(event.name));
    } else if (data.error) {
      this.dialogService.openError(this.dictionaryLookUp(data.error));
    } else {
      console.error('Incorrectly formatted user error response');
      console.error(data);
    }
  }

  showError(error: string): void {
    this.dialogService.closeBlocking();
    this.dialogService.openError(this.dictionaryLookUp(error));
  }

  private dictionaryLookUp(status: string): string {
    const translatedMessage = this.translationService.convertError(status);
    return translatedMessage[0].toUpperCase() + translatedMessage.replace(/_/g, ' ').slice(1);
  }

  private dictionaryLookUpValidationError(status: string): string {
    return this.translationService.convertValidationError(status);
  }
}
