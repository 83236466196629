import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { setFormDirty } from '../../../../utils/form';
import {
  AdvancedTargetGroupCategory,
  AdvancedTargetGroupParams,
} from '../../../../woo_services.module/AdvancedTargetGroupService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { ProductFormat, Publisher, SlotType, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'advanced-target-group-form',
  templateUrl: './advanced-target-group-form.component.html',
})
export class AdvancedTargetGroupForm implements OnInit {
  readonly setFormDirty = setFormDirty;

  @Input() categories: AdvancedTargetGroupCategory[] = [];
  @Output() onSubmit = new EventEmitter<AdvancedTargetGroupParams>();
  @Output() onAbort = new EventEmitter<void>();

  readonly tableHeaders = [
    'Placering',
    'Publicist',
    'Preroll',
    'Midroll',
    'Postroll',
    'Preroll',
    'Midroll',
    'Postroll',
  ];

  advancedTargetGroup: AdvancedTargetGroupParams = {} as AdvancedTargetGroupParams;
  publishers: Publisher[] = [];
  productFormats: ProductFormat[];
  inputRows: InputRow[] = [];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  loading = true;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private utilityService: ProductsPageUtilityService,
    private dialogService: DialogService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled)
      .then((publishers) => {
        this.publishers = publishers;
      })
      .then(() => {
        this.productFormatService
          .getProductFormats(ExtraProductFormatField.enabled, ExtraProductFormatField.slotTypes)
          .then((productFormats) => {
            this.productFormats = productFormats;
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
            this.advancedTargetGroup = this.getEmpty();
            this.buildInputRows();
          });
      })
      .finally(() => (this.loading = false));
  }

  async saveNewAdvancedTargetGroup(): Promise<void> {
    const textBlocks = ['Är du säker på att du vill lägga till målgrupp?'];
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.onSubmit.emit(this.advancedTargetGroup);
      })
      .finally(() => {
        this.dialogService.closeBlocking();
      });
  }

  rowVisible(obj: InputRow): boolean {
    return obj.publisherEnabled && obj.productFormatEnabled;
  }

  translateProductFormat(product: string): string {
    return this.translationService.convertProductFormat(product);
  }

  private getEmpty(): AdvancedTargetGroupParams {
    return {
      active: true,
      category_id: null,
      tag: null,
      mrm_id: null,
      name: null,
      shares: this.utilityService.buildEmptyProductFormatEstimates(this.publishers, this.productFormats),
      withdrawal_limits: this.utilityService.buildEmptyProductFormatEstimates(this.publishers, this.productFormats),
    };
  }

  private buildInputRows(): void {
    let inputRows: InputRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        inputRows = [
          ...inputRows,
          {
            publisherId: publisher.id,
            publisher: publisher.name,
            publisherEnabled: publisher.enabled,
            productFormatName: productFormat.name,
            productFormatId: productFormat.id,
            productFormatEnabled: productFormat.enabled,
            sharesPreroll: 0,
            sharesMidroll: 0,
            sharesPostroll: 0,
            withdrawalLimitsPreroll: 0,
            withdrawalLimitsMidroll: 0,
            withdrawalLimitsPostroll: 0,
          },
        ];
      });
    });
    inputRows.sort((a, b) => (a.publisher > b.publisher ? 0 : 1));
    this.inputRows = inputRows;
  }
}

interface InputRow {
  publisher: string;
  publisherId?: wooId;
  publisherEnabled: boolean;
  productFormatName: string;
  productFormatId: wooId;
  productFormatEnabled: boolean;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
