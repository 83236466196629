import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ProgramFormatService } from '../../../woo_services.module/ProgramFormatService';
import { Program } from '../../../woo_services.module/shared-types';

const template = /* html */ `
  <div class="flex__container justify-content--space-between">
    <div>
      <div>{{program.name}}</div>
      <div class="text--small">{{period.start | date:DATE_FORMAT }} - {{period.end | date:DATE_FORMAT }}</div>
    </div>
    <div>
      <button *ngIf="mode === ProgramFormatItemMode.Add" class="button primary-1" (click)="onClick.emit(program)" [attr.e2e-id]="program.name">Lägg till</button>
      <button *ngIf="mode === ProgramFormatItemMode.Remove" class="button secondary" (click)="onClick.emit(program)" [attr.e2e-id]="program.name">Ta bort</button>
    </div>
  </div>
`;

@Component({
  selector: 'program-format-item',
  template: template,
})
export class ProgramFormatItem implements OnChanges {
  readonly DATE_FORMAT = 'd MMM yyyy';
  readonly ProgramFormatItemMode = ProgramFormatItemMode;

  @Input() program: Program;
  @Input() targetingStart: string | Date;
  @Input() targetingEnd: string | Date;
  @Input() mode: ProgramFormatItemMode;
  @Output() onClick = new EventEmitter<Program>();

  period = {
    start: null,
    end: null,
  };

  constructor(private programService: ProgramFormatService) {}

  ngOnChanges(): void {
    if (this.program && this.targetingStart && this.targetingEnd) {
      const periods = this.programService.activePeriods(this.program, {
        start_date: new Date(this.targetingStart),
        end_date: new Date(this.targetingEnd),
      });

      if (periods.length > 0) {
        this.period.start = new Date(Math.min(...periods.map((p) => new Date(p.start_date).getTime())));
        this.period.end = new Date(Math.max(...periods.map((p) => new Date(p.end_date).getTime())));
      }
    }
  }
}

export enum ProgramFormatItemMode {
  Add = 'add',
  Remove = 'remove',
}
