import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { GrossRatingFactor, HistoryItem } from './shared-types';

@Injectable({ providedIn: 'root' })
export class GrossRatingService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getGrossRatingFactor(): Promise<GrossRatingFactor> {
    return this.http.get<GrossRatingFactor>(`${this.env.apiUrl}/gross_rating_factor`).toPromise();
  }

  getGrossRatingHistoryData(): Promise<HistoryItem[]> {
    return this.http.get<HistoryItem[]>(`${this.env.apiUrl}/gross_rating_factor/history`).toPromise();
  }

  updateGrossRatingFactor(factor: number): Promise<GrossRatingFactor> {
    return this.http
      .patch<GrossRatingFactor>(`${this.env.apiUrl}/gross_rating_factor`, {
        gross_rating_factor: factor,
      })
      .toPromise();
  }
}
