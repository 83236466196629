<div class="block">

  <h1>Ny TTV-Kampanj</h1>

  <div class="tabbable__body clearfix shadow-z-1 block">
    <p>
      Klistra in JSON formaterad bokningsdata från Spotlight här.
    </p>

    <form (ngSubmit)="form.valid ? createBooking() : setFormDirty(form)" [formGroup]="form" autocomplete="off">
      <div class="input__container padding-bottom--2x">
        <textarea rows="10" class="max-width--100 height--40vh" formControlName="ttvBookingParams" [wooInputErrors]></textarea>
      </div>

      <div>
        <context-message class="display--block margin-bottom--2x" messageType="error" *ngIf="errors">
          <div class="text--danger" *ngFor="let error of errors">
            <p>{{error.error_code}}</p>
            <pre *ngIf="error.parameters && (error.parameters | keyvalue)?.length" class="border--none">{{error.parameters | json}}</pre>
          </div>
        </context-message>

        <context-message class="display--block" messageType="warning" *ngIf="warnings">
          <pre class="border--none text-warning">{{warnings | json}}</pre>
        </context-message>
      </div>

      <button type="submit" class="button primary-1">Skapa</button>
    </form>
  </div>
</div>
