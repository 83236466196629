<div #headerPos class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera avancerade målgrupper</h3>
</div>

<div>
  <div class="tabbable__body">
    <import-warning *ngIf="show.estimatesHaveChanged"></import-warning>
    <form #shareOfTotalForm="ngForm">
      <div class="input__container flex__container align-items--end">
        <div>
          <label>Andel av totallagret som ska vara tillgängligt för bokningar av avancerade målgrupper</label>
          <input name="advancedTargetGroupsShareOfTotal" [(ngModel)]="shareOfTotal" wooPercentFormatter [wooMinValidator]="0" [wooMaxValidator]="1">
        </div>
        <button class="button margin-left--2x" (click)="updateShareOfTotal()" [disabled]="!shareOfTotalForm.valid">Spara</button>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="button-filter-container">
        <div>
          <button class="button primary-1 margin-bottom--1x" (click)="showNewTargetGroupForm(true)" [disabled]="noAvailableCategories">Ny avancerad målgrupp</button>
          <button class="button primary-1 margin-bottom--1x" (click)="showNewCategoryForm(true)">Ny kategori</button>
          <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="advancedTargetGroupValues.invalid">Spara</button>
          <button *ngIf="!show.isEditing" class="button secondary" (click)="setEditing(true)">Redigera avancerade målgrupper</button>
          <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
        </div>
        <div class="display--flex margin-right--2x">
          <div class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="advancedTargetGroups"
            [(ngModel)]="selectedAdvancedTargetGroups"
            name="selectedAdvancedTargetGroups"
            inputId="select-AdvancedTargetGroups"
            labelText="Filtrera på Målgrupp"
            notFoundText="Ingen målgrupp hittades."
            [clearable]="true"></woo-dropdown>
          </div>
            <div *ngIf="productFormats && productFormats.length > 1" class="filter-dropdown margin-left--2x">
              <woo-dropdown
              [items]="productFormats"
              [(ngModel)]="selectedProductFormat"
              name="selectedProductFormat"
              inputId="select-product-format"
              labelText="Filtrera på Placering"
              [labelTransform]="translateProductFormat"
              notFoundText="Inga Placeringar hittades."
              [clearable]="true"></woo-dropdown>
            </div>
          <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="publishers"
            [(ngModel)]="selectedPublisher"
            name="selectedPublisher"
            inputId="select-publisher"
            labelText="Filtrera på Publicist"
            notFoundText="Inga publicister hittades."
            [clearable]="true"></woo-dropdown>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div #stickyHeader>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Tillgängliga avancerade målgrupper</h3>
      </div>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table-woo table--info table--hover width--100" ngForm #advancedTargetGroupValues="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-center" colspan="3">
            Estimatfördelning
          </th>
          <th class="text-center" colspan="3">
            Maxuttag
          </th>
          <th [colSpan]="1"></th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--small capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>

      <tbody *ngIf="!show.loading">
        <ng-container *ngFor="let row of advancedTargetGroupRows; let index = index;">
        <tr [hidden]="!rowVisible(row)">
          <td class="text--thin vertical-align--middle">
            <span>{{row.publisher}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{translateProductFormat(row.productFormat)}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{row.category}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{row.tag}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{row.mrm_id}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{row.advancedTargetGroupName}}</span>
          </td>

          <td class="text--thin vertical-align--middle">
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <toggle
                name="row-{{index}}-active"
                [(ngModel)]="getSpecificAdvancedTargetGroup(row.advancedTargetGroupId).active"
              ></toggle>
            </div>
            <span *ngIf="!show.isEditing">{{getSpecificAdvancedTargetGroup(row.advancedTargetGroupId).active ? 'Ja' : 'Nej'}}</span>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesPreroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPreroll"
                [(ngModel)]="getSpecificAdvancedTargetGroupShares(row.advancedTargetGroupId, row.publisherId, row.productFormatId).preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesMidroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesMidroll"
                [(ngModel)]="getSpecificAdvancedTargetGroupShares(row.advancedTargetGroupId, row.publisherId, row.productFormatId).midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPostroll"
                [(ngModel)]="getSpecificAdvancedTargetGroupShares(row.advancedTargetGroupId, row.publisherId, row.productFormatId).postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPreroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-limitsPreroll"
                [(ngModel)]="getSpecificAdvancedTargetGroupLimits(row.advancedTargetGroupId, row.publisherId, row.productFormatId).preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsMidroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-limitsMidroll"
                [(ngModel)]="getSpecificAdvancedTargetGroupLimits(row.advancedTargetGroupId, row.publisherId, row.productFormatId).midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-limitsPostroll"
                [(ngModel)]="getSpecificAdvancedTargetGroupLimits(row.advancedTargetGroupId, row.publisherId, row.productFormatId).postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>

          <td class="padding-top-bottom--1x">
            <button [routerLink]="['/advanced_target_groups', row.advancedTargetGroupId]" class="status-badge primary float--left">
              <span class="icon-edit icon__semi-medium "></span>
            </button>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>

    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
    <button class="button primary" (click)="save()" [disabled]="advancedTargetGroupValues.invalid">Spara</button>
    <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>

<div #newCategoryFormContainer>
  <div *ngIf="show.newCategoryForm" class="tabbable__body clearfix shadow-z-1">
    <div class="col-xs-12">
      <div class="tabbable__panel clearfix shadow-z-1">
          <h3>Ny avancerad målgruppskategori</h3>
      </div>
      <div class="tabbable__panel clearfix shadow-z-1">
        <advanced-target-group-category-form
          [categories]="categories"
          (onCreated)="setCategories()"
          (onClose)="showNewCategoryForm(false)"></advanced-target-group-category-form>
      </div>
    </div>
  </div>
</div>

<div #newTargetGroupFormContainer>
  <div *ngIf="show.newTargetGroupForm" class="tabbable__body clearfix shadow-z-1">
    <div class="col-xs-12">
      <div class="tabbable__panel clearfix shadow-z-1">
        <div class="col-xs-12">
          <h3>Ny avancerad målgrupp</h3>
        </div>
      </div>
      <div class="tabbable__panel margin--none shadow-z-1">
        <advanced-target-group-form
          [categories]="categories"
          (onAbort)="showNewTargetGroupForm(false, headerPos)"
          (onSubmit)="addAdvancedTargetGroup($event)"></advanced-target-group-form>
      </div>
    </div>
  </div>
</div>
