import { Component } from '@angular/core';
import { WooDialogHelper } from '../../../../woo_components.module/dialogs/woo-dialog-helper';

const template = /* html */ `
  <woo-dialog>
    <h3>Villkor för spelbolag i WOO Manager</h3>
    <p>Att kunden är ett spelbolag innebär tillåtna styrningar för kampanjen.</p>
    Videokampanj:
    <ul>
      <li>RBS</li>
      <li>Kategori sport (max filmlängd 15s)</li>
    </ul>
    Pausreklam:
    <ul>
      <li>RBS</li>
    </ul>
  </woo-dialog>
`;

@Component({
  selector: 'gambling-customer-information-dialog',
  template: template,
})
export class GamblingCustomerInformationDialog extends WooDialogHelper {}
