<div *ngIf="pauseImpressionsActive">
  <heading-with-line [text]="'Paus - Impressions' | wooTitleCase"></heading-with-line>
  <two-col-row left="Budgeterade" [right]="pauseImpressionsBudgeted | number | dashAsDefault" [bothBold]="true"></two-col-row>
  <two-col-row *ngIf="adminOrPlanner() || pauseImpressionsAdditional"
                left="Extra/Komp" [right]="pauseImpressionsAdditional | number | dashAsDefault"
                [bothBold]="true"></two-col-row>
</div>

<div *ngIf="onlineVideoImpressionsActive">
  <heading-with-line [text]="'Online Video - Impressions' | wooTitleCase"></heading-with-line>
  <two-col-row left="Budgeterade" [right]="onlineVideoImpressionsBudgeted | number | dashAsDefault" [bothBold]="true"></two-col-row>
  <two-col-row *ngIf="adminOrPlanner() || onlineVideoImpressionsAdditional"
                left="Extra/Komp" [right]="onlineVideoImpressionsAdditional | number | dashAsDefault"
                [bothBold]="true"></two-col-row>
</div>

<div *ngIf="completedViewsActive">
  <heading-with-line [text]="'Adresserbar TV - Completed views' | wooTitleCase"></heading-with-line>
  <two-col-row left="Budgeterade" [right]="completedViewsBudgeted | number | dashAsDefault" [bothBold]="true"></two-col-row>
  <two-col-row *ngIf="adminOrPlanner() || completedViewsAdditional"
                left="Extra/Komp" [right]="completedViewsAdditional | number | dashAsDefault"
                [bothBold]="true"></two-col-row>
</div>

<div *ngIf="grossRatingViewsActive">
  <heading-with-line text="{{grossRatingViewsHeading | wooTitleCase}}"></heading-with-line>
  <two-col-row left="Budgeterade" [right]="grossRatingViewsBudgeted | number | dashAsDefault" [bothBold]="true"></two-col-row>
  <two-col-row *ngIf="adminOrPlanner() || grossRatingViewsAdditional"
                left="Extra/Komp" [right]="grossRatingViewsAdditional | number | dashAsDefault"
                [bothBold]="true"></two-col-row>
</div>

<div class="margin-top--3x" *ngIf="dualCurrencyActive"></div>
<heading-with-line  *ngIf="dualCurrencyActive" [text]="'Summeringar' | wooTitleCase"></heading-with-line>

<two-col-row *ngIf="additionalAfterStart"
              left="Påbokning" [right]="additionalAfterStart | number | dashAsDefault"
              [bothBold]="true"></two-col-row>
<two-col-row *ngIf="adminOrPlanner()"
              left="Ändrade mål" [right]="updateGoals | number | dashAsDefault" [bothBold]="true"></two-col-row>
<two-col-row *ngIf="adminOrPlanner()"
              left="MMS-stärkning" [right]="mmsGain | number | dashAsDefault" [bothBold]="true"></two-col-row>
<two-col-row *ngIf="totalViews > 0 && pauseImpressionsActive && onlineVideoImpressionsActive"
              [left]="'Totalt beställt i impressions'" [right]="totalImpressions | number | dashAsDefault"
              [bothBold]="true" e2e-id="total-impressions"></two-col-row>
<two-col-row *ngIf="totalViews > 0 && dualCurrencyActive"
              [left]="'Totala ' + viewCurrencyName" [right]="totalViews | number | dashAsDefault"
              [bothBold]="true" e2e-id="total-views"></two-col-row>
<two-col-row *ngIf="adminOrPlanner() && totalInventoryViews > 0"
              [left]="'Totalt lagerförda impressions'" [right]="totalInventoryViews | number | dashAsDefault"
              [bothBold]="true" [marked]="true" e2e-id="total-inventory-views"></two-col-row>