import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { CampaignTracking, TVDayTracking, TotalTVDayTracking } from '../../services/TrackingService';
import { TvDayOutcomeTableRows } from './tv_day_outcome_table/tv-day-outcome-table.component';

@Component({
  selector: 'tv-day-outcome',
  templateUrl: './tv-day-outcome.component.html',
})
export class TvDayOutcome implements OnChanges {
  @Input() tracking: CampaignTracking;
  @Input() isTTVCampaign: boolean;
  @Input() useGrossRating: boolean;

  activeVideoCode: string;
  missingDaysForVideoCode: number;

  videoCodes: string[];
  videoCodeData: TvDayOutcomeTableRows;
  constructor(private formatterService: FormatterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tracking && this.tracking && this.tracking.tv_days) {
      this.videoCodes = this.tracking.tv_days
        .map((tvDay) => tvDay.video_code)
        .filter((value, index, a) => a.indexOf(value) === index);
      this.onVideoCodeChange(this.videoCodes[0]);
    }
  }

  onVideoCodeChange(videoCode: string): void {
    this.activeVideoCode = videoCode;

    const tvDaysByVideoCode = this.tracking.tv_days.filter((tvDay) => tvDay.video_code === this.activeVideoCode);

    if (this.isTTVCampaign) {
      this.videoCodeData = this.videoCodeDataTTVCampaign(tvDaysByVideoCode);
      this.videoCodeData.unshift(this.totalVideoCodeDataTTVCampaign(this.tracking.tv_days_totals));
    } else {
      this.videoCodeData = this.videoCodeDataCampaign(tvDaysByVideoCode);
      this.videoCodeData.unshift(this.totalVideoCodeDataCampaign(this.tracking.tv_days_totals));
    }

    this.missingDaysForVideoCode = this.tracking.tv_days_totals.find(
      (tvDay) => tvDay.video_code === videoCode,
    ).missing_days_for_video_code;
  }

  get mmsTableHeaders(): string[] {
    if (this.isTTVCampaign) {
      return this.useGrossRating ? this.ttvGrossRatingViewsHeaders : this.ttvCompletedViewsHeaders;
    }

    return this.useGrossRating ? this.grossRatingViewsHeaders : this.completedViewsHeaders;
  }

  private videoCodeDataCampaign(tvDaysByVideoCode: TVDayTracking[]): TvDayOutcomeTableRows {
    return tvDaysByVideoCode.map((tvDay) => {
      return {
        cells: [
          { value: tvDay.tv_day },
          { value: tvDay.video_code },
          {
            value: this.formatterService.transformNumber(this.mmsTotalInViews(tvDay)),
            cellClass: this.getMmsTableCellClass(tvDay),
          },
          {
            value: this.formatterService.transformNumber(this.pulseTotalInViews(tvDay)),
          },
          {
            value: this.formatterService.transformPercent(tvDay.pulse_share),
          },
          {
            value: this.formatterService.transformNumber(this.mmsActuals(tvDay)),
          },
          {
            value: this.formatterService.transformNumber(tvDay.mms_actuals_impressions),
          },
          {
            value: this.formatterService.transformPercent(tvDay.mms_actuals_cv / tvDay.mms_actuals_impressions),
          },
        ],
        rowClass: this.getMmsTableRowClass(tvDay),
      };
    });
  }

  private totalVideoCodeDataCampaign(totalTvDays: TotalTVDayTracking[], video_code?: string) {
    const tvDayTotal = totalTvDays.find((tvDay) => tvDay.video_code === (video_code || this.activeVideoCode));

    return {
      cells: [
        { value: 'Total' },
        { value: tvDayTotal.video_code },
        {
          value: this.formatterService.transformNumber(this.mmsTotalInViews(tvDayTotal)),
          cellClass: this.getMmsTableCellClass(tvDayTotal),
        },
        {
          value: this.formatterService.transformNumber(this.pulseTotalInViews(tvDayTotal)),
        },
        {
          value: tvDayTotal.pulse_share,
        },
        {
          value: this.formatterService.transformNumber(this.mmsActuals(tvDayTotal)),
        },
        {
          value: this.formatterService.transformNumber(tvDayTotal.mms_actuals_impressions),
        },
        {
          value: this.formatterService.transformPercent(tvDayTotal.mms_actuals_completion_rate),
        },
      ],
    };
  }

  private videoCodeDataTTVCampaign(tvDaysByVideoCode: TVDayTracking[]): TvDayOutcomeTableRows {
    return tvDaysByVideoCode.map((tvDay) => {
      return {
        cells: [
          { value: tvDay.tv_day },
          { value: tvDay.video_code },
          {
            value: this.formatterService.transformNumber(this.mmsTotalInViews(tvDay)),
            cellClass: this.getMmsTableCellClass(tvDay),
          },
          {
            value: this.formatterService.transformNumber(this.pulseTotalInViews(tvDay)),
          },
          {
            value: this.formatterService.transformPercent(tvDay.pulse_share),
          },
          {
            value: this.formatterService.transformNumber(this.mmsTargetGroupViews(tvDay)),
          },
          {
            value: this.formatterService.transformNumber(this.mmsActuals(tvDay)),
          },
        ],
        rowClass: this.getMmsTableRowClass(tvDay),
      };
    });
  }

  private totalVideoCodeDataTTVCampaign(totalTvDays: TotalTVDayTracking[], video_code?: string) {
    const tvDayTotal = totalTvDays.find((tvDay) => tvDay.video_code === (video_code || this.activeVideoCode));

    return {
      cells: [
        { value: 'Total' },
        { value: tvDayTotal.video_code },
        {
          value: this.formatterService.transformNumber(this.mmsTotalInViews(tvDayTotal)),
          cellClass: this.getMmsTableCellClass(tvDayTotal),
        },
        {
          value: this.formatterService.transformNumber(tvDayTotal.pulse_ads_completed),
        },
        { value: tvDayTotal.pulse_share },
        {
          value: this.formatterService.transformNumber(this.mmsTargetGroupViews(tvDayTotal)),
        },
        {
          value: this.formatterService.transformNumber(this.mmsActuals(tvDayTotal)),
        },
      ],
    };
  }

  private mmsTotalInViews(row: TVDayTracking | TotalTVDayTracking) {
    return this.useGrossRating ? row.mms_total_gross_rating_views : row.mms_total_cv;
  }

  private pulseTotalInViews(row: TVDayTracking | TotalTVDayTracking) {
    return this.useGrossRating ? row.pulse_calculated_gross_rating_views : row.pulse_ads_completed;
  }

  private mmsTargetGroupViews(row: TVDayTracking | TotalTVDayTracking) {
    return this.useGrossRating ? row.mms_target_group_gross_rating_views : row.mms_target_group_cv;
  }

  private mmsActuals(row: TVDayTracking | TotalTVDayTracking): number {
    return this.useGrossRating ? row.mms_actuals_gross_rating_views : row.mms_actuals_cv;
  }

  private getMmsTableCellClass(tvDay: TVDayTracking): string {
    return tvDay.calculated_from_ad_server && !tvDay.mms_total_cv ? 'invalid-data-entry' : '';
  }
  private getMmsTableRowClass(tvDay: TVDayTracking): string {
    return tvDay.calculated_from_ad_server && tvDay.mms_total_cv ? 'invalid-data-entry' : '';
  }

  private readonly ttvCompletedViewsHeaders: string[] = [
    'Mediedygn',
    'Filmkod',
    'Completed views - MMS',
    'Completed views - adserver',
    'Adserverandel',
    'Completed views i köpmålgrupp',
    'MMS actuals CV',
  ];

  private readonly ttvGrossRatingViewsHeaders: string[] = [
    'Mediedygn',
    'Filmkod',
    'Bruttokontakter - MMS',
    'Beräknade Bruttokontakter - adserver',
    'Adserverandel',
    'Bruttokontakter i köpmålgrupp',
    'MMS actuals Bruttokontakter',
  ];

  private readonly completedViewsHeaders: string[] = [
    'Mediedygn',
    'Filmkod',
    'Completed views - MMS',
    'Completed views - adserver',
    'Adserverandel',
    'MMS actuals CV',
    'MMS actuals Imp',
    'MMS CR',
  ];

  private readonly grossRatingViewsHeaders: string[] = [
    'Mediedygn',
    'Filmkod',
    'Bruttokontakter - MMS',
    'Beräknade Bruttokontakter - adserver',
    'Adserverandel',
    'MMS actuals Bruttokontakter',
    'MMS actuals Imp',
    'MMS CR',
  ];
}
