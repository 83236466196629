import { formatDate } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnChanges, SimpleChange } from '@angular/core';
import { AbstractValueAccessor } from '../../utils/AbstractValueAccessor';
import { getYearAndWeekNumber, inPeriod, sameDate } from '../../utils/date';
import { valueAccessorProvider } from '../../utils/provider-builders';
import { generateId } from '../../utils/string';
import { SimpleChanges } from '../../utils/types';
import { CommercialWeek, DateSegment } from '../../woo_services.module/shared-types';

@Component({
  selector: 'segment-select',
  templateUrl: './segment-select.component.html',
  providers: [valueAccessorProvider(SegmentSelect)],
})
export class SegmentSelect extends AbstractValueAccessor<DateSegment[]> implements OnChanges {
  readonly DATE_FORMAT = 'dd/MM';
  readonly DAYS_PER_WEEK = 7;
  readonly INSTANCE_ID = generateId();

  @Input() periodStart: Date;
  @Input() periodEnd: Date;
  @Input() unbookableWeeks: CommercialWeek[] = [];

  weeks: Map<WeekId, Calendar> = new Map();

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  writeValue(segments: DateSegment[]): void {
    super.writeValue(segments);
    this.createWeeks();
  }

  ngOnChanges(changes: SimpleChanges<SegmentSelect>): void {
    if (this.hasDateChanged(changes.periodStart) || this.hasDateChanged(changes.periodEnd)) {
      this.createWeeks();
    }

    if (changes.unbookableWeeks) {
      this.createWeeks();
    }
  }

  dateFieldId(item: CalendarItem): string {
    return `segment-date-${this.INSTANCE_ID}-${formatDate(item.date, this.DATE_FORMAT, this.locale)}`;
  }

  isWeekSelected(week: WeekId): boolean {
    return this.getWeekCalendar(week)
      .filter((item) => item.disabled === false)
      .every((item) => item.selected);
  }

  isWeekForcedDisabled(week: WeekId): boolean {
    return this.getWeekCalendar(week).every((item) => item.forceDisabled);
  }

  toggleWeek(week: WeekId): void {
    const weekSelected = this.isWeekSelected(week);
    this.getWeekCalendar(week)
      .filter((item) => item.disabled === false)
      .forEach((item) => (item.selected = !weekSelected));
    this.updateSegments();
  }

  toggleDay(item: CalendarItem): void {
    item.selected = !item.selected;
    this.updateSegments();
  }

  getWeekNumber(weekId: WeekId): number {
    return Number(weekId.split('-')[1]);
  }

  private getWeekCalendar(week: WeekId): Calendar {
    if (!this.weeks.has(week)) {
      this.weeks.set(week, []);
    }
    return this.weeks.get(week);
  }

  private getFullCalendar(): Calendar {
    return Array.from(this.weeks.values()).reduce<Calendar>((result, items) => result.concat(items), []);
  }

  /**
   * Update segments with data from the calendar
   */
  private updateSegments(): void {
    const enabledCalendar = this.getFullCalendar().filter((item) => !item.disabled);
    this.model = this.createSegments(enabledCalendar);
  }

  /**
   * Recreate the calendar for the entire period
   */
  private createWeeks(): void {
    this.weeks.clear();

    // If start_date, end_date or model is unset the calendar can't be created
    if (!this.periodStart || !this.periodEnd || this.periodStart > this.periodEnd || !this.model) {
      return;
    }

    const lastDayOfCalendar = new Date(
      this.periodEnd.getFullYear(),
      this.periodEnd.getMonth(),
      this.periodEnd.getDate() + (this.DAYS_PER_WEEK - (1 + this.getCorrectDay(this.periodEnd.getDay()))),
    );
    const year = this.periodStart.getFullYear();
    const month = this.periodStart.getMonth();
    let day = this.periodStart.getDate() - this.getCorrectDay(this.periodStart.getDay());

    for (
      let newDate = new Date(year, month, day);
      newDate <= lastDayOfCalendar;
      newDate = new Date(year, month, ++day)
    ) {
      const disabled = newDate < this.periodStart || newDate > this.periodEnd;
      const weekId = this.getWeekId(newDate);
      this.getWeekCalendar(weekId).push({
        date: newDate,
        selected: !disabled && this.matchAnySegment(newDate),
        disabled: disabled,
        weekId: weekId,
        forceDisabled: this.isForcedDisabled(newDate),
      });
    }
  }

  private createSegments(calendar: Calendar): DateSegment[] {
    const selectedArray = calendar.map((item) => item.selected);
    const startIndex = selectedArray.indexOf(true);
    const endIndex = selectedArray.indexOf(false, startIndex);

    if (startIndex === -1) {
      return [];
    } else if (endIndex === -1) {
      return [this.createSegment(calendar[startIndex].date, calendar[calendar.length - 1].date)];
    } else {
      return [this.createSegment(calendar[startIndex].date, calendar[endIndex - 1].date)].concat(
        this.createSegments(calendar.splice(endIndex)),
      );
    }
  }

  /**
   * Convert day index starting with sunday to day index starting with monday
   */
  private getCorrectDay(day: number): number {
    const weekDays = { 0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 };
    return weekDays[day];
  }

  private matchAnySegment = (date: Date): boolean => {
    return this.model.some((segment) => {
      const startDate = new Date(segment.start_date);
      const endDate = new Date(segment.end_date);
      return inPeriod(startDate, endDate, date);
    });
  };

  private hasDateChanged(change?: SimpleChange): boolean {
    if (!change) {
      return false;
    }
    return change.currentValue && change.previousValue
      ? !sameDate(change.currentValue, change.previousValue)
      : change.currentValue !== change.previousValue;
  }

  private createSegment(start: Date, end: Date): DateSegment {
    return {
      start_date: start,
      end_date: end,
    };
  }

  private getWeekId(date: Date): WeekId {
    const [year, weekNumber] = getYearAndWeekNumber(date);
    return `${year}-${weekNumber.toString().padStart(2, '0')}`;
  }

  private isForcedDisabled(date: Date): boolean {
    const [year, weekNumber] = getYearAndWeekNumber(date);
    return this.unbookableWeeks.some((cWeek: CommercialWeek) => cWeek.year === year && cWeek.week === weekNumber);
  }
}

interface CalendarItem {
  date: Date;
  selected: boolean;
  weekId: WeekId;
  disabled: boolean;
  forceDisabled: boolean;
}

type WeekId = string;

export type Calendar = CalendarItem[];
