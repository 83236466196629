<div class="divider margin-bottom--2x"></div>
<form [formGroup]="form" class="goal" autocomplete="off">
  <div class="margin-top-bottom--1x">
    <h4 class="text--normal">{{ name }}</h4>
  </div>
  <div class="margin-top-bottom--1x">
    <h4 class="text--normal">{{ format }}</h4>
  </div>
  <h5>Period</h5>
  <div class="padding-left--1x">
  {{ startDate | wooDate }} - {{ endDate | wooDate }}
  </div>
  <h5 class="capitalize--first-letter">Beställda visningar</h5>
  <div class="padding-left--1x text-align--right">
    <two-col-table
        [left]="[orderViewCurrency]"
        [right]="[orderViews | number | dashAsDefault]"
        [fullWidth]="true"
    ></two-col-table>
  </div>
  <h5 class="capitalize--first-letter">Skickat till adservern</h5>
  <div class="padding-left--1x text-align--right">
    <two-col-table
        [left]="[viewCurrency]"
        [right]="[impressions | number | dashAsDefault]"
        [fullWidth]="true"
    ></two-col-table>
  </div>
  <div *ngIf="!!goal?.additional_budget_impressions">
    <h5>Påbokade {{ viewCurrency }}</h5>
    <div class="padding-left--1x">
      {{ goal?.additional_budget_impressions | number | dashAsDefault }}
    </div>
  </div>
  <div>
    <h5>MMS-stärkning</h5>
    <div class="padding-left--1x">
      <two-col-table
        [left]="mms_gain_view_currencies"
        [right]="mms_gain_values"
        [fullWidth]="true"
      ></two-col-table>
    </div>
  </div>
  <div *ngIf="!!goal.additional_impressions || !disabled">
    <h5>Förändrade {{ viewCurrency }}</h5>
    <div class="padding-left--1x">
      <div class="input__container">
        <input
          id="additional-impressions-{{goal.id}}"
          formControlName="additionalImpressions"
          [wooNumberFormatter]="{ allowEmpty: false, allowLeadingSign: true }"
        />
      </div>
      <div>
        <two-col-table
          *ngIf="viewCurrency != orderViewCurrency && !!goal.additional_impressions"
          [left]="[orderViewCurrency]"
          [right]="[goal.additional_order_views | number | dashAsDefault]"
          [fullWidth]="true"
        ></two-col-table>
      </div>
    </div>
  </div>
  <div>
    <h5>Totalt bokat i lagret</h5>
    <div class="padding-left--1x text-align--right">
      <two-col-table
          [left]="[viewCurrency]"
          [right]="[totalBookedInInventory | number | dashAsDefault]"
          [fullWidth]="true"
      ></two-col-table>
    </div>
  </div>
  <div>
    <h5>Totalt skickat till adservern</h5>
    <div class="padding-left--1x text-align--right">
      <two-col-table
          [left]="[viewCurrency]"
          [right]="[totalImpressionsSentToAdServer() | number | dashAsDefault]"
          [fullWidth]="true"
      ></two-col-table>
    </div>
  </div>
</form>
