export enum UserRole {
  everyone = '*',
  accounting = 'accounting',
  agencyAdmin = 'agency_admin',
  agencyUser = 'agency_user',
  agencyUserAtCustomer = 'agency_user_at_customer',
  agency = 'agency',
  admin = 'admin',
  cashUser = 'cash_user',
  client = 'client_user',
  dataScientist = 'data_scientist',
  externalPlanner = 'external_planner',
  eos = 'eos',
  estimator = 'estimator',
  MeMaApiUser = 'mema_api_user',
  planner = 'planner',
  salesManager = 'sales_manager',
  sales = 'sales',
  statusUser = 'status',
}
