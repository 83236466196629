import { Component, OnInit } from '@angular/core';
import { FileService, FileType } from '../../../woo_services.module/FileService';

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
})
export class Help implements OnInit {
  technicalSpecSv: string;
  technicalSpecEn: string;
  cookiePolicy: string;
  integrityPolicy: string;
  userTerms: string;

  constructor(private fileService: FileService) {}

  ngOnInit(): void {
    this.technicalSpecSv = this.fileService.getUrlToFile(FileType.technicalSpecSv);
    this.technicalSpecEn = this.fileService.getUrlToFile(FileType.technicalSpecEn);
    this.cookiePolicy = this.fileService.getUrlToFile(FileType.cookiePolicy);
    this.integrityPolicy = this.fileService.getUrlToFile(FileType.integrityPolicy);
    this.userTerms = this.fileService.getUrlToFile(this.fileService.getTermsFileTypeForUser());
  }

  async getMaterialGuide(): Promise<void> {
    const materialGuide = await this.fileService.downloadLatestFile(FileType.materialGuide);
    const fileURL = window.URL.createObjectURL(materialGuide);
    const tab = window.open();
    tab.location.href = fileURL;
  }
}
