import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService } from './JobService';
import { SendToAdServerJobResult, wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class AdServerService {
  constructor(private env: EnvironmentService, private http: HttpClient, private jobService: JobService) {}

  async sendCampaign(campaignId: wooId): Promise<SendToAdServerJobResult> {
    return this.jobService.waitForJobCompletion(
      (await lastValueFrom(this.http.patch<Job>(`${this.env.apiUrl}/campaigns/${campaignId}/send_to_ad_server`, null)))
        .id,
    );
  }
}
