import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService } from './JobService';
import { User, wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RevenueReportsService {
  constructor(private http: HttpClient, private env: EnvironmentService, private jobService: JobService) {}

  downloadAgressoXlsx(id: wooId): Promise<Blob> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/revenue_reports/${id}/agresso_xlsx`, {
        responseType: 'blob',
      }),
    );
  }

  downloadAgressoXml(id: wooId): Promise<Blob> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/revenue_reports/${id}/agresso_xml`, {
        responseType: 'blob',
      }),
    );
  }

  downloadTeliaXlsx(id: wooId): Promise<Blob> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/revenue_reports/${id}/telia_xlsx`, {
        responseType: 'blob',
      }),
    );
  }

  downloadTeliaXml(id: wooId): Promise<Blob> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/revenue_reports/${id}/telia_xml`, {
        responseType: 'blob',
      }),
    );
  }

  getAll(): Promise<RevenueReport[]> {
    return lastValueFrom(this.http.get<RevenueReport[]>(`${this.env.apiUrl}/revenue_reports`));
  }

  get(id: wooId): Promise<RevenueReport> {
    return lastValueFrom(this.http.get<RevenueReport>(`${this.env.apiUrl}/revenue_reports/${id}`));
  }

  async sendToTelia(id: wooId): Promise<any> {
    return this.jobService.waitForJobCompletion(
      (await lastValueFrom(this.http.patch<Job>(`${this.env.apiUrl}/revenue_reports/${id}/send_to_telia`, null))).id,
    );
  }
}

export interface RevenueReport {
  id: wooId;
  year: number;
  month: number;
  has_agresso_files: boolean;
  sent_to_agresso_at: string;
  sent_to_agresso_by: Pick<User, 'id' | 'first_name' | 'last_name'>;
  has_telia_files: boolean;
  sent_to_telia_at: string;
  sent_to_telia_by: Pick<User, 'id' | 'first_name' | 'last_name'>;
}
