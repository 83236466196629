import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { isEqual } from 'lodash-es';

interface PublisherGroup {
  id: string;
  name: string;
}

export interface PublisherGroupSelectedEvent {
  error: boolean;
  publisherGroups: PublisherGroup[];
}

export enum PublisherGroupChoices {
  tv4,
  bn,
}

@Component({
  selector: 'publisher-group-select',
  templateUrl: './publisher-group-select.component.html',
  styleUrls: ['./publisher-group-select.component.scss'],
})
export class PublisherGroupSelect implements OnChanges {
  @Input() publisherGroups: PublisherGroup[];
  @Input() model: PublisherGroup[];
  @Output() modelAndValidityChange = new EventEmitter<PublisherGroupSelectedEvent>();

  PublisherChoices = PublisherGroupChoices;
  publisherChoice: PublisherGroupChoices = null;

  private lastEvent = null;

  ngOnChanges(changes: Record<string, any>): void {
    if (changes.publishers && this.publisherGroups) {
      this.notifyChange();
    }
    if (changes.model && this.model) {
      if (this.model.filter(this.tv4Selector).length > 0) {
        this.publisherChoice = PublisherGroupChoices.tv4;
      } else if (this.model.filter(this.bonnierNewsSelector).length > 0) {
        this.publisherChoice = PublisherGroupChoices.bn;
      }
      this.notifyChange();
    }
  }

  notifyChange(): void {
    let publisherGroups = [] as PublisherGroup[];
    if (this.publisherChoice === PublisherGroupChoices.tv4) {
      publisherGroups = this.publisherGroups.filter(this.tv4Selector);
    }
    if (this.publisherChoice === PublisherGroupChoices.bn) {
      publisherGroups = this.publisherGroups.filter(this.bonnierNewsSelector);
    }

    const event = { publisherGroups, error: !this.validState(publisherGroups) };
    if (!isEqual(event, this.lastEvent)) {
      this.lastEvent = event;
      this.modelAndValidityChange.emit(event);
    }
  }

  validState(publisherGroups: PublisherGroup[]): boolean {
    return publisherGroups.length > 0;
  }

  private tv4Selector = (publisherGroup: PublisherGroup) => {
    return publisherGroup.name === 'TV4';
  };

  private bonnierNewsSelector = (publisherGroup: PublisherGroup) => {
    return !this.tv4Selector(publisherGroup);
  };
}
