import { UrlMatchResult, UrlSegment } from '@angular/router';

export function cashBookingPageMatcher(segments: UrlSegment[]): UrlMatchResult {
  if (segments.length > 2 && segments[0].path === 'customer' && segments[2].path === 'campaign') {
    if (segments.length === 3) {
      return {
        consumed: segments,
        posParams: { customerId: segments[1] },
      };
    }
    if (segments.length === 4 && segments[3].path === 'summary') {
      return {
        consumed: segments,
        posParams: { customerId: segments[1] },
      };
    }
    if (segments.length === 4) {
      return {
        consumed: segments,
        posParams: { customerId: segments[1], campaignId: segments[3] },
      };
    }
    return <UrlMatchResult>(null as any);
  }
  return <UrlMatchResult>(null as any);
}
