import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdHocContractParams, AdHocContractService } from '../../../woo_services.module/AdHocContractService';
import { AdOrganisation, CompactAgency, Period } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'new-ad-hoc-contract',
  templateUrl: './new-ad-hoc-contract.component.html',
  styleUrls: ['./new-ad-hoc-contract.component.scss'],
})
export class NewAdHocContract {
  @Output() onNewAdHocContract = new EventEmitter<AdHocContractParams>();
  @Input() agencies: CompactAgency[];
  @Input() adOrganisations: AdOrganisation[];
  @Output() onAbort = new EventEmitter<void>();

  discounts = this.adHocContractService.getDiscountTypes();

  period: Period;

  contract = {
    start_date: null,
    end_date: null,
    ad_organisation_id: null,
    agency_id: null,
    discounts: [],
  };

  convertDiscount = this.translationService.convertDiscount;

  constructor(private translationService: TranslationService, private adHocContractService: AdHocContractService) {}

  newAdHocContract = (): void => {
    this.contract.ad_organisation_id = this.adOrganisations.find((org) => org.name === 'TV4').id;
    this.onNewAdHocContract.emit(this.contract);
  };

  allFieldsValid = (): boolean => {
    const dateFilled = this.contract.start_date !== null && this.contract.end_date !== null;
    const agencyFilled = this.agencies?.length > 0 ? this.contract.agency_id !== null : true;
    return this.allValidDiscountAmounts() && dateFilled && agencyFilled;
  };

  periodChanged = (event: Period): void => {
    this.contract.start_date = event.start;
    this.contract.end_date = event.end;
  };

  private allValidDiscountAmounts = () => {
    if (this.contract.discounts.length < 1) return false;
    return !this.contract.discounts.some((discount) => discount.discount_rate <= 0 || discount.discount_rate >= 1);
  };
}
