import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { isEqual } from 'lodash-es';
import { SimpleChanges } from '../../../utils/types';
import { CampaignMmsGainFormData } from '../../../woo_components.module/campaign_mms_gain_form/campaign-mms-gain-form.component';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { GoalAdditionalImpressionsFormData } from '../../../woo_components.module/goal_additional_impressions_form/goal_additional_impressions_form/goal-additional-impressions-form.component';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import {
  Campaign,
  GoalUpdateBookingError,
  TargetingAdditionalGoalBudgetData,
  UpdateGoalsJobParams,
  UpdateGoalsJobResult,
  UpdateGoalsJobTargetingData,
  wooId,
} from '../../../woo_services.module/shared-types';

@Component({
  selector: 'edit-goals',
  templateUrl: './edit-goals.component.html',
})
export class EditGoals implements OnChanges {
  @Input() campaign: Campaign;
  @Output() onSuccess = new EventEmitter<void>();
  @Output() onAbort = new EventEmitter<void>();
  @Output() campaignNeedsReload = new EventEmitter<void>();

  adserver = '';

  get canSave(): boolean {
    return this.combineFormValidityStates();
  }

  private hasChanges = false;
  private formValues: FormValues;
  private formValidityStates: {
    mmsForm: boolean;
    targetingForms: Record<wooId, boolean>;
    goalForms: Record<wooId, Record<wooId, boolean>>;
  };

  constructor(
    private campaignService: CampaignService,
    private dialogService: DialogService,
    private translationService: TranslationService,
  ) {}

  ngOnChanges(changes: SimpleChanges<EditGoals>): void {
    if (changes.campaign && this.campaign) {
      this.setCampaign(this.campaign);
    }
  }

  setCampaign(campaign: Campaign): void {
    let targetingsData: FormValues['targetingForms'] = {};
    let goalsData: FormValues['goalForms'] = {};

    this.adserver = this.campaign?.ad_server;

    campaign.targetings.map((t) => {
      targetingsData = {
        ...targetingsData,
        [t.id]: {
          additionalGoalBudget: t.additional_goal_budget,
          additionalGoalBudgetMessage: t.additional_goal_budget_message,
        },
      };

      t.goals.map((g) => {
        goalsData = {
          ...goalsData,
          [t.id]: {
            ...goalsData[t.id],
            [g.id]: {
              additionalImpressions: g.additional_impressions,
            },
          },
        };
      });
    });

    this.formValues = {
      mmsForm: {
        mmsGain: campaign.mms_gain,
      },
      targetingForms: targetingsData,
      goalForms: goalsData,
    };

    this.formValidityStates = {
      mmsForm: null,
      targetingForms: {},
      goalForms: {},
    };

    this.hasChanges = false;
  }

  onMmsFormChange(mmsFormData: CampaignMmsGainFormData): void {
    this.hasChanges = this.formValues.mmsForm !== mmsFormData ? true : this.hasChanges;
    this.formValues.mmsForm = mmsFormData;
  }
  onMmsFormValidityChange(valid: boolean): void {
    this.formValidityStates.mmsForm = this.formValues.mmsForm ? valid : false;
  }

  onGoalFormChange(targetingId: wooId, goalId: wooId, goalFormData: GoalAdditionalImpressionsFormData): void {
    if (!targetingId || !goalId) {
      return;
    }
    this.hasChanges = !isEqual(this.formValues.goalForms[targetingId][goalId], goalFormData) ? true : this.hasChanges;
    this.formValues.goalForms[targetingId][goalId] = goalFormData;
  }
  onGoalFormValidityChange(targetingId: wooId, goalId: wooId, valid: boolean): void {
    if (!targetingId || !goalId) {
      return;
    }
    this.formValidityStates.goalForms[targetingId] = {
      ...this.formValidityStates.goalForms[targetingId],
      [goalId]: this.formValues.goalForms[targetingId][goalId] ? valid : false,
    };
  }

  updateCampaign(): void {
    this.dialogService
      .openConfirm({
        header: 'Redigering av startad kampanj',
        textBlocks: [
          'Du försöker nu att uppdatera en startad kampanj i WOO Manager. Detta innebär att att den kommer att skickas till adservern automatiskt om du godkänner nedan.',
          'Vill du genomföra ändringarna på kampanjen?',
        ],
        confirmText: 'Ja, uppdatera!',
        cancelText: 'Nej, jag ångrar mig',
      })
      .then(
        async () => {
          if (!this.canSave) {
            return;
          }

          if (!this.hasChanges) {
            this.dialogService.openSuccess('Det här gick lättare än förväntat, inga ändringar att spara :)');
            this.onSuccess.emit();
          } else {
            this.dialogUpdateGoals();
          }
        },
        () => null,
      );
  }

  abort(): void {
    this.onAbort.emit();
  }

  private dialogUpdateGoals = () => {
    const successMessage = 'WOO har matat in de extra visningarna i sitt lager';
    const updateParams = this.convertFormValuesToParams(this.formValues);
    let resetFormAfterUpdate = true;

    this.updateGoals(updateParams)
      .then(
        () => {
          this.dialogService.openSuccess(`${successMessage}.`);
          this.onSuccess.emit();
        },
        (res) => {
          if (res.booking_error === GoalUpdateBookingError.goalTotalImpressionsBelowZero) {
            resetFormAfterUpdate = false;
          }

          if (!updateParams.force && res.booking_error === GoalUpdateBookingError.insufficientInventory) {
            return this.dialogService.openConfirm(this.getConfirmOverbookingDialogContent()).then(
              () => {
                updateParams.force = true;
                return this.updateGoals(updateParams).then(() => {
                  this.dialogService.openSuccess(`${successMessage}, men är nu överbokat.`);
                  this.onSuccess.emit();
                });
              },
              () => {
                resetFormAfterUpdate = false;
              },
            );
          } else {
            throw res;
          }
        },
      )
      .catch(this.showBookingError)
      .finally(() => {
        if (resetFormAfterUpdate) {
          this.campaignNeedsReload.emit();
        }
      });
  };

  private updateGoals = (updateParams: UpdateGoalsJobParams) => {
    this.dialogService.openBlocking('Uppdaterar WOOs lager');
    return this.campaignService.updateGoals(updateParams).then(
      (response) => {
        this.dialogService.closeBlocking();
        return response;
      },
      (error: UpdateGoalsJobResult) => {
        this.dialogService.closeBlocking();
        throw error;
      },
    );
  };

  private getConfirmOverbookingDialogContent = (): ConfirmDialogContent => ({
    header: 'Varning!',
    textBlocks: [
      `${this.translationService.convertUpdateGoalsError(GoalUpdateBookingError.insufficientInventory)}`,
      'Är du säker på att du vill överboka?',
    ],
    confirmText: 'Överboka lagret',
    cancelText: 'Avbryt',
  });

  private showBookingError = (error: UpdateGoalsJobResult) => {
    if (error.booking_error && !(error instanceof HttpErrorResponse)) {
      this.dialogService.openError(this.translationService.convertUpdateGoalsError(error.booking_error));
    }
  };

  private convertFormValuesToParams(formValues: FormValues, force = false): UpdateGoalsJobParams {
    const targetings: UpdateGoalsJobTargetingData[] = Object.entries(formValues.targetingForms).map(
      ([targetingId, targetingForm]) => ({
        id: targetingId,
        additional_goal_budget: targetingForm.additionalGoalBudget,
        additional_goal_budget_message: targetingForm.additionalGoalBudgetMessage,
        goals: Object.entries(formValues.goalForms[targetingId]).map(([goalId, goalForm]) => ({
          id: goalId,
          additional_impressions: goalForm.additionalImpressions,
        })),
      }),
    );

    return {
      campaign_id: this.campaign.id,
      force: force,
      mms_gain: formValues.mmsForm.mmsGain,
      targetings: targetings,
    };
  }

  private combineFormValidityStates(): boolean {
    const mmsForm = this.formValidityStates.mmsForm ?? true;
    const targetingForms = this.formValidityStates.targetingForms
      ? Object.values(this.formValidityStates.targetingForms).every((t) => t)
      : true;
    const goalForms = this.formValidityStates.goalForms
      ? Object.values(this.formValidityStates.goalForms)
          .flatMap((t) => Object.values(t))
          .every((g) => g)
      : true;
    return mmsForm && targetingForms && goalForms;
  }
}

interface FormValues {
  mmsForm: CampaignMmsGainFormData;
  targetingForms: Record<wooId, TargetingAdditionalGoalBudgetData>;
  goalForms: Record<wooId, Record<wooId, GoalAdditionalImpressionsFormData>>;
}
