import { Injectable } from '@angular/core';
import { DaypartService } from '../../woo_services.module/DaypartService';
import { Daypart } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedDaypartService implements Pick<DaypartService, 'getDayparts'> {
  private listCache: Daypart[] = null;

  constructor(private daypartService: DaypartService) {}

  async getDayparts(): Promise<Daypart[]> {
    if (!this.listCache) {
      this.listCache = await this.daypartService.getDayparts();
    }
    return this.listCache;
  }
}
