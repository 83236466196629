import { Component, EventEmitter, Input, Output } from '@angular/core';

const template = /* html */ `
<div class="display--flex align-items--center checkbox-container" [ngClass]="{'disabled': disabled, 'selected': checked}" (click)="!disabled && onClick.emit($event)">
  <input
    [id]="id"
    [name]="id"
    class="checkbox margin-top--none margin-left--1x"
    type="checkbox"
    [checked]="checked"
    [disabled]="disabled"
  >
  <ng-content></ng-content>
  <div class="margin-left--2x text-container" [ngClass]="{'disabled': disabled}">
    <span *ngFor="let text of texts">{{text}}</span>
  </div>
  </div>
  `;

@Component({
  selector: 'targeting-checkbox',
  template: template,
  styleUrls: ['./targeting-checkbox.component.scss'],
})
export class TargetingCheckbox {
  @Input() texts: string[];
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Output() onClick = new EventEmitter<any>();
  id: string;

  static idCounter = 0;
  constructor() {
    this.id = 'targeting-checkbox-' + TargetingCheckbox.idCounter++;
  }
}
