<div class="targeting-summary-wrapper">
  <expandable-targeting-header
    [(expanded)]="expanded"
    [targeting]="targeting"
    [targetingMetaData]="metaData"
    (expandedChange)="expandedChange.emit($event)"
  >

    <regional-indicator *ngIf="includesLinear" title="Regionalpaketering" [subtitle]="salesOrderNumber"></regional-indicator>
    <regional-indicator *ngIf="isRegional && regionalDivision === RegionalDivision.ZipCodeBased" title="Postnummerstyrning" ></regional-indicator>
    <send-invoices-indicator [message]="noInvoiceMessage" [sendInvoices]="sendInvoices"></send-invoices-indicator>
    <p>
      <span e2e-id="frequency-limit">Frekvenstak: {{frequencyLimit ? frequencyLimit.name : 'Inget'}}</span>
    </p>
    <p *ngIf="!!universe">
      <span e2e-id="universe">Universum: {{universe | number}}</span>
    </p>
    <p *ngIf="!!grossRatingFactor && isAdminOrPlanner">
      <span >Bruttokontaktsfaktor: {{grossRatingFactor | number}}</span>
    </p>


    <div class="row">
      <div class="col-xs-12 col-md-6">
        <heading-with-line text="Budget"></heading-with-line>
        <two-col-row left="Budget (netto)" [right]="netBudget | currency: 'SEK'" [bothBold]="true" e2e-id="net-budget"></two-col-row>
        <two-col-row left="Extra/Komp" [right]="additionalBudget | currency: 'SEK'" [bothBold]="true" *ngIf="authService.adminOrPlanner() || additionalBudget" e2e-id="additional-budget"></two-col-row>
        <two-col-row left="Total budget" [right]="totalBudget | currency: 'SEK'" [bothBold]="true" [marked]="true" *ngIf="authService.adminOrPlanner() || additionalBudget" e2e-id="total-targeting-budget"></two-col-row>
      </div>
      <div class="col-xs-12 col-md-6">
        <heading-with-line text="{{currency | wooTitleCase}}"></heading-with-line>
        <two-col-row left="Budgeterade" [right]="estimatedViews | number" [bothBold]="true"></two-col-row>
        <two-col-row left="Extra/Komp" [right]="additionalViews | number" [bothBold]="true" *ngIf="authService.adminOrPlanner() || additionalViews"></two-col-row>
        <two-col-row left="Totala {{currency}}" [right]="totalEstimatedViews | number" [marked]="true" [bothBold]="true" *ngIf="authService.adminOrPlanner() || additionalViews"></two-col-row>
        <two-col-row left="TRP" [right]="trp" [bothBold]="true" *ngIf="!!trp"></two-col-row>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-6">
        <heading-with-line text="Pris"></heading-with-line>
        <two-col-row [left]="currencyAcronym" [right]="priceGross | currency: 'SEK'" [bothBold]="true"></two-col-row>
        <two-col-row left="{{currencyAcronym}} (netto)" [right]="priceNet | currency: 'SEK'" [bothBold]="true"></two-col-row>
        <two-col-row left="Rabatt" [right]="discount" [bothBold]="true"></two-col-row>
      </div>
      <div class="col-xs-12 col-md-6">
        <heading-with-line text="Styrning"></heading-with-line>
        <targeting-tags [targeting]="targeting" class="margin-top-bottom--1x display--block"></targeting-tags>
      </div>
    </div>

    <div class="margin-top--2x">
      <extended-targeting-budget-view
        [targeting]="targeting">
      </extended-targeting-budget-view>
    </div>

    <heading-with-line text="Material"></heading-with-line>
    <creatives-list [creatives]="creativesFromAgreement" [metaData]="metaData" [targeting]="targeting" [distributions]="creativesDistributions"></creatives-list>

    <ng-container *ngIf="creativesAddedAfterAgreement.length">
      <heading-with-line text="Material tillagt efter kampanjstart"></heading-with-line>
      <creatives-list [creatives]="creativesAddedAfterAgreement" [metaData]="metaData" [targeting]="targeting" [distributions]="creativesDistributions" creativesAddedAfterAgreement="true"></creatives-list>
    </ng-container>

    <heading-with-line [text]="periodInfoHeading"></heading-with-line>
    <creative-segments-list
      [creatives]="targeting?.creatives"
      [unbookableWeeks]="partUnbookableWeeks"
      ></creative-segments-list>

    <ng-container *ngIf="additionalBudgetMessage">
      <heading-with-line text="Meddelanden"></heading-with-line>
      <p>
        <span class="padding-right--1x">Extra/Komp budget:</span> <span class="text--italic"> {{additionalBudgetMessage}} </span>
      </p>
    </ng-container>

  </expandable-targeting-header>

</div>
