import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../../utils/types';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { wooId } from '../../../woo_services.module/shared-types';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
<woo-number-input
    labelText="{{label}}"
    name="targetingBudget-{{targetingId}}"
    inputId="targeting-budget-{{targetingId}}"
    [(ngModel)]="budget"
    (ngModelChange)="budgetChanged()"
></woo-number-input>
`;

@Component({
  selector: 'targeting-budget',
  template: template,
})
export class TargetingBudget extends SubscriptionHandler implements OnChanges {
  @Input() targetingId: wooId;

  calculateFromBudget = true;
  budget = 0;
  label = '';

  constructor(private bookingStore: BookingStore, private formatterService: FormatterService) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(changes: SimpleChanges<TargetingBudget>): void {
    if (changes.targetingId) {
      this.initFromStore(this.bookingStore.state);
    }
  }

  initFromStore = (model: BookingModel): void => {
    const selectedTargeting = model.campaign.targetings.find((targeting) => targeting.id === this.targetingId);
    if (!selectedTargeting) {
      return;
    }
    this.calculateFromBudget = selectedTargeting.calculate_from_budget;

    const currency = this.calculateFromBudget
      ? 'Budget'
      : this.formatterService.getCurrencyName(selectedTargeting, true);
    const unit = this.calculateFromBudget ? 'kr' : 'st';
    this.label = `${currency} (${unit})`;
    this.budget = this.calculateFromBudget ? selectedTargeting.budget : selectedTargeting.budgeted_impressions;
  };

  budgetChanged = (): void => {
    this.calculateFromBudget
      ? this.bookingStore.setBudget(this.targetingId, this.budget)
      : this.bookingStore.setBudgetedImpressions(this.targetingId, this.budget);
  };
}
