// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WOO sets window['WOO_ENV'] in production. These environment variables are for local development
export const environment = {
  api_url: 'http://localhost:3000',
  production: false,
  aws_cloudfront_url: 'https://images.woo.a2d-stage.tv',
  ad_server_break_date: '2023-10-31',
  self_service_break_date: '2024-04-01',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
