<div class="display--inline-block width--100">
  <voucher-select [targetingId]="targetingId"></voucher-select>
  <frequency-limit-targeting [targetingId]="targetingId" *ngIf="frequencyLimitCompatible"></frequency-limit-targeting>
  <div
    class="col-lg-4 col-md-12 padding-left--none padding-right--1x padding-top--1x padding-bottom--none"
    *ngFor="let category of categories; let index = index"
  >
    <targeting-checkbox
      (onClick)="change(category.id)"
      [checked]="selectedCategoryIds[category.id]"
      [texts]="[category.name]"
      [disabled]="disabledForDisneyIf(category.is_disney, regionalIsSelected, pauseAdIsSelected)">
    <img [src]="images[category.name]" alt="{{category.name}} logo" class="category-image margin-left--1x">
    </targeting-checkbox>
  </div>
</div>
