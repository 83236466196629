import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { validateRequired } from '../../../utils/validators';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { Campaign } from '../../../woo_services.module/shared-types';
import { ToFormGroup } from '../../../utils/types';

@Component({
  selector: 'update-ongoing-campaign-name',
  templateUrl: './update-ongoing-campaign-name.component.html',
})
export class UpdateOngoingCampaignName implements OnChanges {
  @Input() campaign: Campaign;
  @Output() onAbort = new EventEmitter<void>();
  @Output() onSuccess = new EventEmitter<void>();

  form: FormGroup<ToFormGroup<FormModel>> = this.fb.group({
    name: new FormControl(null, validateRequired()),
  });

  constructor(
    private fb: FormBuilder,
    private campaignService: CampaignService,
    private dialogService: DialogService,
  ) {}

  ngOnChanges(): void {
    this.setNameValue(this.campaign.name);
  }

  submit = (): void => {
    this.setNameValue(this.form.value.name.trim());
    this.dialogService
      .openConfirm({
        header: 'Redigering av startad kampanj',
        textBlocks: [
          'Du försöker nu att uppdatera en startad kampanj i WOO Manager. Detta innebär att att den kommer att skickas till adservern automatiskt om du godkänner nedan.',
          'Vill du genomföra ändringarna på kampanjen?',
        ],
        confirmText: 'Ja, uppdatera!',
        cancelText: 'Nej, jag ångrar mig',
      })
      .then(() => {
        this.dialogService.openBlocking('Uppdaterar kampanj');
        this.campaignService.updateOngoingCampaignName(this.campaign.id, this.form.value.name).then(
          () => {
            this.campaign.name = this.form.value.name;
            this.onSuccess.emit();
            this.dialogService.openSuccess('Din kampanj är nu uppdaterad.');
          },
          (error) => {
            console.error('Failed updating campaign: ', error);
            this.dialogService.closeBlocking();
          },
        );
      })
      .catch(() => null); // confirmation aborted
  };

  abort = (): void => {
    this.setNameValue(this.campaign.name);
    this.onAbort.emit();
  };

  private setNameValue(value: string) {
    this.form.patchValue({ name: value }, { onlySelf: true, emitEvent: false });
  }
}

interface FormModel {
  name: string;
}
