
<h2>Ogiltiga dagar</h2>
<p>
  Här visas de dagar där utfallet från MMS bedöms som opålitligt. Om möjligt kommer då utfall från adservern att användas istället.
</p>
<div class="padding-bottom--4x">
  <div *ngFor="let pair of days | keyvalue: keyDescOrder">
    <h3>{{pair.key}}</h3>
    <div class="tab--small tab" *ngFor="let day of pair.value">
      {{day}}
    </div>
  </div>
</div>

<div *ngIf="!editing">
  <button class="button" (click)="startEditing()">Redigera</button>
</div>

<div *ngIf="editing">
  <div class="text--center">
    <button class="link" (click)="earlier()">
      <span class="chevron large up text--info"></span>
    </button>
  </div>
  <segment-select [(ngModel)]="segments" [periodStart]="periodStart" [periodEnd]="periodEnd"></segment-select>
  <div class="text--center">
    <button class="link" (click)="later()">
      <span class="chevron large bottom text--info"></span>
    </button>
  </div>

  <p class="margin-top--2x">
    MMS-utfall från de markerade dagarna kommer att behandlas som opålitligt.
  </p>

  <div>
    <button class="button" (click)="submit()">Spara</button>
    <button class="link" (click)="abortEditing()">Avbryt</button>
  </div>
</div>
