<h2 class="margin-bottom--4x">
  <ng-container *ngIf="editing && layoutProfile.heading.visible">Redigera bild</ng-container>
  <ng-container *ngIf="!editing">Lägg till bild</ng-container>
</h2>
<div class="row margin-bottom--1x">
  <div class="col-xs-12">
    <div *ngIf="imageCreatives.length && !editing" class="row padding-bottom--1x">
      <div class="col-xs-6">
        <div class="select__container">
          <label for="duplicate-creative">
            Kopiera bild från tidigare tillagda
          </label>
          <select id="duplicate-creative" (change)="duplicateCreative($event.target.value)">
            <option value=""></option>
            <option *ngFor="let creative of imageCreatives, index as index" [value]="index">
              {{getCreativeName(creative)}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
      <div class="row padding-bottom--2x">
        <div class="col-md-6">
          <div class="input__container">
            <span class="input__optional__text">Frivillig</span>
            <label for="pause-name">Namn</label>
            <input type="text" id="pause-name" formControlName="name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="input__container">
            <span class="input__optional__text">Frivillig</span>
            <label for="destination-url">
              Destinations-URL
              <info-badge gaName="destinations-url-pause">
                Var din pausreklam ska leda när man klickar på den.
              </info-badge>
            </label>
            <input type="url" id="destination-url" formControlName="destinationUrl" wooInputErrors>
          </div>
        </div>
      </div>

      <div class="padding-bottom--2x">
        <radio-button-block
          [choices]="imageChoices"
          formControlName="imageChoice"
        >

        <div [ngSwitch]="form.controls.imageChoice.value">
          <ng-container *ngSwitchCase="PauseImageChoice.One">
            <p>Annonsen är klickbar på desktop och mobiler/tablet om ni adderar en url/landningssida.</p>
            <p><a [href]="fileService.getUrlToFile('technical_spec_sv')" target="_blank">{{TECHNICAL_SPEC_LINK}}</a></p>
            <div class="flex__container align-items--start">
              <div class="image-upload-container padding-right--1x">
                <h4 class="image-title">Bild för alla apparater</h4>
                <image-upload
                  class="display--block"
                  [customerId]="customerId"
                  formControlName="selectedImage"
                ></image-upload>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="PauseImageChoice.Zero">
              <p>Observera att du måste leverera bilden till <strong>reklamplanerare.digitalt&#64;tv4.se</strong> senast fyra
                arbetsdagar före
                kampanjstart för att vi ska hinna få den på plats.</p>
            <p><a [href]="fileService.getUrlToFile('technical_spec_sv')" target="_blank">{{TECHNICAL_SPEC_LINK}}</a></p>
          </ng-container>
        </div>

        </radio-button-block>
      </div>

      <div class="row padding-bottom--2x">
        <div class="col-xs-12">
          <string-array-input
            [optional]="true"
            formControlName="impTrackUrls"
            label="Tracking-URL-impressions"
            [validator]="IMP_TRACK_URLS_VALIDATORS"
            wooInputErrors
          ></string-array-input>
        </div>
      </div>
      <div class="row padding-bottom--2x">
        <div class="col-xs-12">
            <div class="input__container">
              <span class="input__optional__text">Frivillig</span>
              <label for="custom-text">Kommentar</label>
              <textarea id="custom-text" rows="2" formControlName="customText"></textarea>
            </div>
        </div>
      </div>
      <div class="padding-top--2x" *ngIf="layoutProfile.actionButtons.visible">
        <button class="button primary" [disabled]="layoutProfile.actionButtons.disabled">Spara</button>
        <button class="button secondary" type="button" (click)="abort()" [disabled]="layoutProfile.actionButtons.disabled">Avbryt</button>
      </div>
    </form>
  </div>
</div>
