<div>
  <div class="tabbable__panel rounded-top-right">
    <h3 class="margin-left--3x">Här kan du administrera fördelningen för pausreklam</h3>
  </div>

  <div *ngIf="show.estimatesHaveChanged" class="tabbable__body">
    <import-warning></import-warning>
    <dot-loader [visibility]="show.updatingEstimates"></dot-loader>
  </div>
</div>

<div>
  <div #stickyHeader >
    <div class="tabbable__body shadow-z-1">
      <div class="row">
        <div class="col-xs-12">
          <div class="button-filter-container">
            <div>
              <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="pauseAdValues.invalid">Spara</button>
              <button *ngIf="!show.isEditing" class="button primary" (click)="setEditing(true)">Redigera Paus-värden</button>
              <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
            </div>
            <div class="display--flex">
                <div *ngIf="productFormats && productFormats.length > 1" class="filter-dropdown margin-left--2x">
                  <woo-dropdown
                  [items]="productFormats"
                  [(ngModel)]="selectedProductFormat"
                  name="selectedProductFormat"
                  inputId="select-product-format"
                  labelText="Filtrera på Placering"
                  [labelTransform]="translateProductFormat"
                  notFoundText="Inga Placeringar hittades."
                  [clearable]="true"></woo-dropdown>
                </div>
              <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
                <woo-dropdown
                [items]="publishers"
                [(ngModel)]="selectedPublisher"
                name="selectedPublisher"
                inputId="select-publisher"
                labelText="Filtrera på Publicist"
                notFoundText="Inga publicister hittades."
                [clearable]="true"></woo-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Fördelning av pausreklam</h3>
      </div>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table-woo table--info table--hover width--100" ngForm #pauseAdValues="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-center" colspan="1">
            Estimatfördelning
          </th>
          <th class="text-center" colspan="1">
            Maxuttag
          </th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let row of pauseAdRows; let index = index;">
        <tr [hidden]="!rowVisible(row)">
          <td class="text--thin vertical-align--middle">
            <span>{{row.publisher}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{translateProductFormat(row.productFormat)}}</span>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.pauseAdShare | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPauseAdShare"
                [(ngModel)]="getProductFormat(row.productFormatId).pause_ad_share"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]="true">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.pausAdWithdrawalLimit | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPreroll"
                [(ngModel)]="getProductFormat(row.productFormatId).pause_ad_withdrawal_limit"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]="true">
            </div>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>

    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
    <button class="button primary" (click)="save()" [disabled]="pauseAdValues.invalid">Spara</button>
    <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>

