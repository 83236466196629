import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { validateRequired } from '../../../../utils/validators';
import { CategoryService } from '../../../../woo_services.module/CategoryService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { TargetGroupService } from '../../../../woo_services.module/TargetGroupService';
import {
  TargetingMapping,
  TargetingMappingService,
  TargetingMappingType,
} from '../../../../woo_services.module/TargetingMappingService';
import { ToFormGroup } from '../../../../utils/types';

@Component({
  selector: 'EditTtvTargetingMapping',
  templateUrl: './edit-ttv-targeting-mapping.component.html',
})
export class EditTtvTargetingMapping {
  form: FormGroup<ToFormGroup<FormModel>> = this.fb.group({
    name: new FormControl(null, validateRequired()),
    description: new FormControl(null),
  });

  targetingMapping: TargetingMapping = {
    name: null,
    ttv_target_group: null,
    description: null,
    start_date: null,
    factor: null,
    category_ids: [],
    age_target_group_ids: [],
    gender_target_group_ids: [],
    id: null,
    created_at: null,
  };

  targetingType: TargetingMappingType;
  categoryNames: string[] = [];
  targetGroupNames: string[] = [];

  constructor(
    private fb: FormBuilder,
    private ttvService: TargetingMappingService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private categoryService: CategoryService,
    private targetGroupService: TargetGroupService,
  ) {
    ttvService
      .getTargetingMapping(this.routingService.getParam(this.route, 'id'))
      .then((targetingMapping: TargetingMapping) => {
        this.targetingMapping = targetingMapping;
        this.targetingType = this.ttvService.getTargetingType(targetingMapping);
        if (this.targetingType === TargetingMappingType.category) {
          this.categoryService.getCategories().then((categories) => {
            this.categoryNames = categories
              .filter((c) => targetingMapping.category_ids.includes(c.id))
              .map((c) => c.name);
          });
        }

        if (this.targetingType === TargetingMappingType.targetGroup) {
          this.targetGroupService.getGendersAndAges().then(([ages, genders]) => {
            const ageTargetGroups = ages
              .filter((tg) => targetingMapping.age_target_group_ids.includes(tg.id))
              .map((tg) => tg);

            const genderTargetGroups = genders
              .filter((c) => targetingMapping.gender_target_group_ids.includes(c.id))
              .map((tg) => tg);
            this.targetGroupNames = this.targetGroupService.getTargetGroupNames(ageTargetGroups, genderTargetGroups);
          });
        }

        this.form.patchValue(
          { name: targetingMapping.name, description: targetingMapping.description },
          { onlySelf: true, emitEvent: false },
        );
      });
  }

  submit = async (): Promise<void> => {
    const updatedFields = {
      name: this.form.value.name,
      description: this.form.value.description,
    };
    await this.ttvService.updateTargetingMapping(updatedFields, this.targetingMapping.id);
    this.routingService.navigate(['/products', 'ttv']);
  };

  remove = async (): Promise<void> => {
    await this.ttvService.removeTargetingMapping(this.targetingMapping.id);
    try {
      await this.routingService.navigate(['/products', 'ttv']);
    } catch (response) {
      console.error(response.error.message);
    }
  };

  abort = async (): Promise<void> => {
    await this.routingService.navigate(['/products', 'ttv']);
  };
}

interface FormModel {
  name: string;
  description: string;
}
