import { Component, Input, OnInit } from '@angular/core';
import { palePink, spacing1x } from '../../utils/css-constants';

const template = /* html */ `
<div class="display--flex align-items--normal padding-top-bottom--none padding-left-right--none no-scroll--x" [ngClass]="{'marked': marked}">
  <h4
    class="details-table-label text-align--left capitalize--first-letter text--semi-strong align-self--normal"
  >
    {{label}}
  </h4>
  <ng-container *ngIf="!useChildElement">
    <h4
      class="details-table-value text--normal"
      *ngIf="isSingleValue"
    >
      {{value}}
    </h4>
    <div
      class="details-table-value no-scroll--x"
      *ngIf="!isSingleValue"
    >
      <h4
        class="text--normal"
        *ngFor="let item of value"
      >
        {{item}}
      </h4>
    </div>
  </ng-container>
  <div
    class="details-table-value"
    *ngIf="useChildElement">
    <ng-content></ng-content>
  </div>
</div>
`;

@Component({
  selector: 'creative-details-row',
  template: template,
  styles: [
    `
      h4 {
        padding-top: 4px;
        padding-bottom: 4px;

        overflow-wrap: break-word;
        word-wrap: break-word;
        overflow: hidden;
      }
    `,
    `
      div {
        overflow: hidden;
      }
    `,
    `
      .details-table-label {
        min-width: 200px;
        max-width: 50%;
        width: 200px;
      }
    `,
    `
      .details-table-value {
        padding-left: ${spacing1x};
      }
    `,
    `
      .marked {
        background-color: ${palePink};
        border-radius: ${spacing1x};
      }
    `,
  ],
})
export class CreativeDetailsRow implements OnInit {
  @Input() label: string;
  @Input() value: string | string[] = null;
  @Input() marked = false;
  @Input() useChildElement = false;

  isSingleValue = true;

  ngOnInit(): void {
    this.isSingleValue = !this.value || typeof this.value === 'string' || this.value.length === 1;
  }
}
