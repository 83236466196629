import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class MailService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getBookingConfirmation(campaignId: string): Promise<string> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/mails/booking_confirmation/${campaignId}`, {
        responseType: 'text',
      }),
    );
  }

  getCreativeReminder(campaignId: string): Promise<string> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/mails/creative_reminder/${campaignId}`, {
        responseType: 'text',
      }),
    );
  }

  getUserCreated(): Promise<string> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/mails/user_created`, {
        responseType: 'text',
      }),
    );
  }

  getInvoicingDisabled(campaignId: string): Promise<string> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/mails/invoicing_disabled/${campaignId}`, {
        responseType: 'text',
      }),
    );
  }
}
