import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { WooComponentsModule } from '../woo_components.module/module';
import { AgencyRoleSelect } from './components/agency-role-select.component';
import { EditUserCustomerAccess } from './components/edit-user-customer-access.component';
import { ManageAccounts } from './components/manage-accounts.component';
import { NameList } from './components/name_list/name-list.component';
import { NewUser } from './components/new-user.component';
import { UserForm } from './components/user-form.component';
import { UserList } from './components/user_list/user-list.component';

@NgModule({
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, RouterModule, WooComponentsModule],
  providers: [],
  declarations: [AgencyRoleSelect, EditUserCustomerAccess, ManageAccounts, NameList, NewUser, UserForm, UserList],
  exports: [EditUserCustomerAccess, ManageAccounts, NewUser, UserForm],
})
export class WooUserAccountsModule {}
