import { Component, Input, OnChanges } from '@angular/core';
import { clone } from 'lodash-es';
import { SimpleChanges } from '../../../utils/types';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { AgencySelfServiceDetails, Campaign } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'agency-self-service-form',
  templateUrl: './agency-self-service-form.compontent.html',
})
export class AgencySelfServiceForm implements OnChanges {
  @Input() campaign: Campaign;

  updatedDetails: AgencySelfServiceDetails;
  readonly maxCommentLength = 200;

  constructor(private campaignService: CampaignService, private dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges<AgencySelfServiceForm>): void {
    if (changes.campaign && this.campaign) {
      this.updatedDetails = clone(this.campaign.agency_self_service_details);
    }
  }

  async updateAgencySelfServiceCompensation(): Promise<void> {
    await this.campaignService.updateAgencySelfServiceCompensation(this.campaign.id, this.updatedDetails);
    try {
      this.campaign.agency_self_service_details = clone(this.updatedDetails);
      this.dialogService.openSuccess('Administrationsersättningen uppdaterades');
    } catch {
      const msg = 'Ajdå, nånting gick snett. Var god försök igen!';
      this.dialogService.openError(msg);
    }
  }

  get valid(): boolean {
    const commentLength = this.updatedDetails.comment?.length || 0;
    return commentLength > 0 && commentLength <= this.maxCommentLength;
  }
}
