import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { uniq } from '../../../utils/array';
import { scrollTo, scrollToTop } from '../../../utils/dom';
import { UserRole } from '../../../utils/user-roles';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { WooCurrencyValue } from '../../../woo_components.module/pipes/woo-currency.pipe';
import { WooDateFormat } from '../../../woo_components.module/pipes/woo-date.pipe';
import { AccountService } from '../../../woo_services.module/AccountService';
import { AgencyService } from '../../../woo_services.module/AgencyService';
import { AuthService } from '../../../woo_services.module/AuthService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { SessionService } from '../../../woo_services.module/SessionService';
import { Agency, AgencyType, User, wooId } from '../../../woo_services.module/shared-types';
import { SystemService } from '../../../woo_services.module/SystemService';
import { AgencyRole, UserParams, UserService } from '../../../woo_services.module/UserService';
import { EnvironmentService } from '../../../woo_services.module/EnvironmentService';

@Component({
  selector: 'agency',
  templateUrl: './agency.component.html',
})
export class AgencyComponent {
  readonly WooCurrencyValue = WooCurrencyValue;
  readonly WooDateFormat = WooDateFormat;
  readonly isAdminPlannerAccounting = this.authService.hasAnyRole([
    UserRole.admin,
    UserRole.planner,
    UserRole.accounting,
  ]);
  readonly canAddUser = this.authService.hasAnyRole([
    UserRole.admin,
    UserRole.planner,
    UserRole.accounting,
    UserRole.agencyAdmin,
  ]);
  readonly isAgencyAdmin = this.authService.hasAnyRole([UserRole.agencyAdmin]);
  readonly isAdminOrAgencyAdmin = this.authService.hasAnyRole([UserRole.admin, UserRole.agencyAdmin]);
  readonly mayViewCustomersAndUsers = this.authService.hasAnyRole([
    UserRole.admin,
    UserRole.planner,
    UserRole.accounting,
    UserRole.agencyAdmin,
    UserRole.agencyUser,
  ]);

  agency: Agency;
  agencyLoaded = false;
  agencyTypes: AgencyType[] = [];
  availableRoles: { id: string; name: string }[] = [];
  currentUser: User;
  editShow = false;
  newUser = false;
  newUserObj = {
    first_name: null,
    last_name: null,
    email: null,
    role: null,
  };
  role: AgencyRole;
  selfServiceCompensationValue = {
    active: null,
    inactive: '0.0',
  };
  hideSelfService = false;

  @ViewChild('listsContainer', { static: true }) listsContainer: ElementRef;
  @ViewChild('newUserContainer', { static: true }) newUserContainer: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private accountService: AccountService,
    private authService: AuthService,
    private systemService: SystemService,
    private dialogService: DialogService,
    private agencyService: AgencyService,
    private sessionService: SessionService,
    private userService: UserService,
    private env: EnvironmentService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.hideSelfService = this.hideSelfServiceCheck();
    this.agency = await this.agencyService.getAgency(this.routingService.getParam(this.route, 'id'));
    this.agencyTypes = this.getAgencyTypes(this.agency);
    this.agency.partner_agreements = this.agency.partner_agreements.sort((a, b) =>
      a.customer.name.localeCompare(b.customer.name),
    );
    this.agency.inactive_partner_agreements = this.agency.inactive_partner_agreements.sort((a, b) =>
      a.customer.name.localeCompare(b.customer.name),
    );
    this.agency.users = this.agency.users.sort(
      (a, b) => this.sortBoolean(a.agency_admin, b.agency_admin) || a.first_name.localeCompare(b.first_name),
    );
    this.currentUser = this.sessionService.getCurrentUser();
    this.agencyLoaded = true;

    await this.systemService.load();
    this.selfServiceCompensationValue.active = this.systemService.variables.default_self_service_compensation.toString();
    function agencyUsers(role) {
      return role.id === UserRole.agencyUser || role.id === UserRole.agencyAdmin;
    }
    this.availableRoles = this.systemService.variables.available_user_roles.filter(agencyUsers);
  }

  async fetchAgency(): Promise<void> {
    this.agency = await this.agencyService.getAgency(this.routingService.getParam(this.route, 'id'));
  }

  openCard(type: string): void {
    if (type === 'user') {
      this.newUser = true;
      this.editShow = false;
      scrollTo(this.newUserContainer.nativeElement);
    }

    if (type === 'edit') {
      this.newUser = false;
      this.editShow = true;
    }
  }

  async closeCard(type?: string): Promise<void> {
    this.newUser = false;
    this.editShow = false;
    if (type === 'user') {
      scrollToTop(true);
    }
    if (type === 'edit') {
      await this.fetchAgency();
    }
  }

  async addUserWithRole(user: UserParams, agencyId: wooId, role: UserRole): Promise<void> {
    const roles: AgencyRole[] = [{ role: role, agency_id: agencyId }];

    if (role === UserRole.agencyAdmin || role === UserRole.agencyUser) {
      role = UserRole.agency;
    }

    const newUser = await this.userService.createUser(user, role, roles);
    try {
      this.agency.users.push(newUser);
      this.newUserObj = null;
      await this.closeCard();
      await this.routingService.navigate(['/user', newUser.id]);
      this.dialogService.openSuccess(
        'Användaren är nu tillagd i WOO Manager. Tilldela användaren kunder för att denne ska kunna boka kampanjer, genom att kryssa i checkboxarna.',
      );
    } catch {}
  }

  async removeUserFromAgency(user: User, agency: Agency): Promise<void> {
    const content = this.getDialogContent();
    await this.dialogService.openConfirm(content);
    try {
      await this.accountService.removeUserFromAgency(user, agency);
      this.agency = await this.agencyService.getAgency(await this.routingService.getParam(this.route, 'id'));
    } catch {}
  }

  async update(agency: Agency): Promise<void> {
    this.agency = await this.agencyService.updateAgency(agency);
    this.agency.users = this.agency.users.sort(
      (a, b) => this.sortBoolean(a.agency_admin, b.agency_admin) || a.first_name.localeCompare(b.first_name),
    );
    await this.closeCard();
  }

  isAgencyAdminAtAgency(user: User, agencyId: wooId): boolean {
    return user.roles.filter((role) => role.name === UserRole.agencyAdmin && role.resource_id === agencyId).length > 0;
  }

  getAgencyTypes(agency: Agency): AgencyType[] {
    return uniq(agency.partner_agreements.map((agreement) => agreement.agency_type));
  }

  toggleSelfServiceCompensation(): void {
    this.agency.self_service_compensation =
      this.agency.self_service_compensation === this.selfServiceCompensationValue.active
        ? (this.agency.self_service_compensation = this.selfServiceCompensationValue.inactive)
        : (this.agency.self_service_compensation = this.selfServiceCompensationValue.active);
  }

  validListOfEmails(agency: Agency): boolean {
    if (!agency?.woo_contact_email) return true;
    return agency?.woo_contact_email
      ?.split(',')
      ?.every((email) =>
        email.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
      );
  }

  hideSelfServiceCheck(): boolean {
    const current_time = new Date().getTime();
    const break_date = new Date(this.env.selfServiceBreakDate).getTime();
    return current_time > break_date;
  }

  private getDialogContent = (): ConfirmDialogContent => ({
    header: 'Ta bort användare från byrå',
    consent: {
      text: `Detta kommer ta bort användaren från byrån permanent. Är du säker?`,
      label: 'Ja, jag är säker',
    },
    confirmText: 'Ta bort användare',
    cancelText: 'Avbryt',
  });

  private sortBoolean = (a: boolean, b: boolean): number => {
    if (a && !b) {
      return -1;
    } else if (!a && b) {
      return 1;
    } else {
      return 0;
    }
  };
}
