<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera universum för målgrupper</h3>
</div>

<div>
  <div #stickyHeader>
    <div class="tabbable__body clearfix shadow-z-1">
      <div *ngIf="!showUploadForm" class="button-filter-container">
        <button class="button primary-1" (click)="showUploadForm = !showUploadForm">Ladda upp nya universumsiffror</button>
      </div>
      <div *ngIf="showUploadForm">
        <div class="padding-left--1x float--left">
          <label for="file" class="button secondary">{{uploadFileLabel()}}</label>
          <input type="file"
                 id="file"
                 class="hidden"
                 [accept]="acceptedFileTypes"
                 (change)="setFile($event.target.files[0])">
        </div>
        <div class="float--left">
          <button class="button primary-1" (click)="sendFile()" [disabled]="uploadDisabled">Ladda upp</button>
          <button class="button secondary" (click)="cancel()">Avbryt</button>
        </div>
      </div>
    </div>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Aktiva universumsiffror</h3>
      </div>
    </div>
  </div>

  <div class="tabbable__body clearfix shadow-z-1">
    <table class="table table--woo table--info table--hover table--align--middle">
      <thead #stickyTableHeader>
      <tr>
        <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
          {{header}}
        </th>
      </tr>
      </thead>

      <tbody>
        <tr *ngFor="let tableRow of universeData">
          <td *ngFor="let element of tableRow">
            {{element}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
