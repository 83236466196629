import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { WooComponentsModule } from '../woo_components.module/module';
import { ShowMail } from './components/show-mail.component';
import { MailService } from './services/MailService';

@NgModule({
  imports: [BrowserModule, FormsModule, WooComponentsModule],
  providers: [MailService],
  declarations: [ShowMail],
  exports: [ShowMail],
})
export class WooMails {}
