import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { PerIdEstimates, wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BehaviorService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getBehaviors(): Promise<Behavior[]> {
    return lastValueFrom(this.http.get<Behavior[]>(`${this.env.apiUrl}/behaviors`));
  }

  addBehavior(behavior: Omit<Behavior, 'id'>): Promise<Behavior> {
    return lastValueFrom(
      this.http.post<Behavior>(`${this.env.apiUrl}/behaviors`, {
        behavior: behavior,
      }),
    );
  }

  updateBehavior(behavior: Behavior): Promise<Behavior> {
    return lastValueFrom(
      this.http.put<Behavior>(`${this.env.apiUrl}/behaviors/${behavior.id}`, {
        behavior: behavior,
      }),
    );
  }

  updateBehaviors(behaviors: Behavior[]): Promise<Behavior[]> {
    return lastValueFrom(
      this.http.put<Behavior[]>(`${this.env.apiUrl}/behaviors`, {
        behaviors: behaviors,
      }),
    );
  }
}

export interface Behavior {
  id: wooId;
  name: string;
  tag: string;
  active: boolean;
  shares: PerIdEstimates;
  withdrawal_limits: PerIdEstimates;
}
