import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getBannerHeight } from '../../../utils/dom';
import { RoutingService } from '../../../woo_services.module/RoutingService';

@Component({
  selector: 'campaign-confirmation',
  templateUrl: './campaign-confirmation.component.html',
  styleUrls: ['./campaign-confirmation.component.scss'],
})
export class CampaignConfirmation {
  public customerId: string;
  public getBannerHeight = getBannerHeight;

  constructor(protected route: ActivatedRoute, protected routingService: RoutingService) {
    this.customerId = routingService.getParam(route, 'customerId');
  }
}
