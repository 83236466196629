<div class="row">
  <context-message
    class="col-xs-12 col-sm-offset-3 col-sm-6 pointer"
    (click)="feedback()"
    *ngIf="errorMessage"
    [message]="errorMessage"
    messageType="error"
  ></context-message>
</div>

<iframe #iframe scrolling="no" class="iframe-container"></iframe>
