<woo-dialog>
  <name-period-header [name]="campaign.name" [start]="targeting.start_date" [end]="targeting.end_date"></name-period-header>

  <div class="flex__container align-items--start summary-body">
    <div class="video-container">
      <video [src]="campaign.creatives && campaign.creatives[0] ? campaign.creatives[0].asset_url + '#t=0.1' : ''" controls></video>
      <div class="targeting-summary" *ngIf="campaign.status !== CampaignStatus.rejected">
        <simple-targeting-summary [targeting]="targeting"></simple-targeting-summary>
      </div>
    </div>

    <div class="statistic-container" *ngIf="campaign.status !== CampaignStatus.rejected">
      <campaign-progress
        text="Färdiga visningar"
        [progress]="campaign.outcome?.ads_completed"
        [target]="campaign.agreement?.net_impressions"
      ></campaign-progress>

      <div class="margin-top--2x">
        <two-col-row left="Färdiga visningar" [right]="campaign.outcome?.ads_completed || 0 | number"[showLine]="true"></two-col-row>
        <two-col-row left="Påbörjade visningar" [right]="campaign.outcome?.impressions || 0 | number" [showLine]="true"></two-col-row>
        <two-col-row left="Antal klick" [right]="campaign.outcome?.click_throughs || 0 | number"[showLine]="true"></two-col-row>
        <two-col-row left="Köpta visningar" [right]="campaign.agreement?.net_impressions || 0 | number"[showLine]="true"></two-col-row>
        <two-col-row left="Kostnad" [right]="targeting.budget | currency:'SEK'"></two-col-row>
      </div>
    </div>
  </div>

  <div *ngIf="campaign.status === CampaignStatus.rejected" class="summary-body margin-top--2x">
    <h3>Kampanjen är ej godkänd.</h3>
    {{campaign.reject_message}}
  </div>

  <button class="link primary visible-xs-block margin--center" (click)="close()">Stäng</button>

</woo-dialog>
