import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { Device, DeviceService } from '../../../../woo_services.module/DeviceService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-devices',
  templateUrl: './manage-devices.component.html',
})
export class ManageDevices implements OnInit {
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  @Input('headerPos') parentHeader: HTMLElement;
  devices: Device[];

  readonly tableHeaders = ['Namn', 'Aktiv', 'Klickbar', 'AG', 'Paus'];

  constructor(
    private deviceService: DeviceService,
    private dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.devices = await this.deviceService.getDevices();
    } finally {
      scrollTo(this.parentHeader);
      this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
    }
  }

  async updateDevices(): Promise<void> {
    await this.deviceService.updateDevices(this.devices);
    this.dialogService.openSuccess('Enheterna har uppdaterats.');
  }
}
