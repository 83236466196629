<p *ngIf="priceListIsNew()" class="margin-top--1x text--warning text--strong">
  De redigerade priserna går först att redigera när prislistan är skapad.<br>
  Det går dock att rensa det redigerade priserna så de inte skapas på din nya prislista.
</p>
<div class="top-row-container">
<div *ngIf="!editingOverwrittenPrices && !priceListIsNew()">
    <button
        class="button primary-1 margin-top--2x"
        (click)="editingOverwrittenPrices = true"
    >Redigera</button>
</div>
<div *ngIf="editingOverwrittenPrices && !priceListIsNew()">
  <button class="button primary-1 margin-top--2x" (click)="save()">Spara</button>
  <button class="button primary-2 margin-top--2x" (click)="editingOverwrittenPrices = false">Avbryt</button>
</div>
<div *ngIf="priceListIsNew()">
  <button
      class="button primary-1 margin-top--2x"
      (click)="clearOverWritenPrices()"
  >Rensa redigerade priser</button>
</div>
<div class="filter-row-container">
  <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
    <woo-dropdown
    [items]="publishers"
    [(ngModel)]="selectedPublisher"
    name="selectedPublisher"
    inputId="select-publisher"
    labelText="Välj Publicist"
    notFoundText="Inga publicister hittades."
    [clearable]="true"></woo-dropdown>
  </div>

  <div class="filter-dropdown margin-left--2x">
    <woo-dropdown
    [items]="productFormatOptions"
    [(ngModel)]="selectedProductFormat"
    name="selectedProductFormat"
    inputId="select-productFromat"
    labelText="Välj Placeringspris"
    notFoundText="Inga Placeringspriser hittades."
    [clearable]="true"></woo-dropdown>
  </div>

  <div class="filter-dropdown margin-left--2x">
    <woo-dropdown
    [items]="targetingOptions"
    [(ngModel)]="selectedTargetingType"
    name="selectedTargetingType"
    inputId="select-targeting"
    labelText="Välj Styrningspris"
    notFoundText="Inga styrningspriser hittades."
    [clearable]="true"></woo-dropdown>
  </div>

  <div class="filter-dropdown margin-left--2x">
    <woo-dropdown
    [items]="localityOptions"
    [(ngModel)]="selectedLocalityType"
    name="selectedLocalityType"
    inputId="select-locality"
    labelText="Välj Geografipris"
    notFoundText="Inga geografipriser hittades."
    [clearable]="true"></woo-dropdown>
  </div>
</div>
</div>
<ng-container *ngIf="!editingOverwrittenPrices">
  <price-row-container
    [priceListRows]="overWritenPrices()"
    [publishers]="publishers"
    [priceList]="priceList"
    [selectedPublisher]="selectedPublisher?.id"
    [selectedProductFormat]="selectedProductFormat?.key"
    [selectedTargetingType]="selectedTargetingType?.key"
    [selectedLocalityType]="selectedLocalityType?.key"
></price-row-container>
</ng-container>
<ng-container *ngIf="editingOverwrittenPrices">
<edit-overwritten-price-row-container
  [priceListRows]="priceList.valid_prices"
  [publishers]="publishers"
  [priceList]="priceList"
  [selectedPublisher]="selectedPublisher?.id"
  [selectedProductFormat]="selectedProductFormat?.key"
  [selectedTargetingType]="selectedTargetingType?.key"
  [selectedLocalityType]="selectedLocalityType?.key"
  (onOverwrittenPricesUpdated)="overwrittenPricesUpdated()"
></edit-overwritten-price-row-container>
<div *ngIf="editingOverwrittenPrices">
  <button class="button primary-1 margin-top--2x" (click)="save()">Spara</button>
  <button class="button primary-2 margin-top--2x" (click)="editingOverwrittenPrices = false">Avbryt</button>
</div>
</ng-container>
