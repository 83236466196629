import { Component } from '@angular/core';
import { formatWooDate } from '../../../utils/date';
import { saveFileAs } from '../../../utils/files';
import { InitialDate } from '../../../woo_components.module/date_selectors/shared/types';
import { DialogService } from '../../../woo_services.module/DialogService';
import { InvoiceService } from '../../../woo_services.module/InvoiceService';
import { Period } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'invoice-tools',
  templateUrl: './invoice-tools.component.html',
})
export class InvoiceTools {
  readonly InitialDate = InitialDate;

  firstSelectableDate: Date = new Date(2015, 1, 1);
  period: Period;
  startDate: Date = new Date();
  endDate: Date = new Date();

  constructor(private invoiceService: InvoiceService, private dialogService: DialogService) {}

  periodChanged = (event: Period): void => {
    this.startDate = event.start as Date;
    this.endDate = event.end as Date;
  };

  async downloadInvoiceExcel(): Promise<void> {
    const progressCallback = this.dialogService.openBlockingWithProgress('Exporterar fakturor');
    const response = await this.invoiceService.getExcelSheet(this.date_params(), progressCallback);

    try {
      this.dialogService.closeBlocking();
      saveFileAs(response.blob, response.name);
    } catch {
      this.dialogService.closeBlocking();
      this.dialogService.openError('Det gick inte att exportera fakturorna.');
    }
  }

  private date_params = (): Record<string, string> => {
    return {
      start_date: formatWooDate(this.startDate),
      end_date: formatWooDate(this.endDate),
    };
  };
}
