<expandable-header *ngIf="extendedBudgets?.length" backgroundColor="light-red"
    text="Budgetutökningar: {{totalBudget | currency:'SEK'}} (+{{totalAdditionalBudget | currency:'SEK'}}) - {{totalImpressions | number}} {{viewCurrencyShort}}">
    <table class="table table--with-small-images margin--none centered">
      <thead>
        <tr>
          <th>Datum</th>
          <th>Budget</th>
          <th>Extra/Komp budget</th>
          <th>Meddelande</th>
          <th class="capitalize--first-letter">{{viewCurrency}}</th>
          <th *ngIf="adminOrPlanner">Användare</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let extendedBudget of extendedBudgets">
          <tr *ngIf="extendedBudget.budget">
            <td>
              {{extendedBudget.date | date:'yyyy-MM-dd' }}
            </td>
            <td>
              {{extendedBudget.budget | currency:'SEK'}}
            </td>
            <td>
              -
            </td>
            <td>
              {{extendedBudget.message}}
            </td>
            <td>
              {{extendedBudget.impressions | number}}
            </td>
            <td *ngIf="adminOrPlanner">
              {{extendedBudget.extended_by}}
            </td>
          </tr>
          <tr *ngIf="extendedBudget.additional_budget">
            <td>
              {{extendedBudget.date | date:'yyyy-MM-dd' }}
            </td>
            <td>
              -
            </td>
            <td>
              {{extendedBudget.additional_budget | currency:'SEK'}}
            </td>
            <td>
              {{extendedBudget.additional_message}}
            </td>
            <td>
              {{extendedBudget.additional_impressions | number}}
            </td>
            <td *ngIf="adminOrPlanner">
              {{extendedBudget.extended_by}}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
</expandable-header>
