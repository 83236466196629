import { Component, Input } from '@angular/core';
import { AbstractValueAccessor } from '../../utils/AbstractValueAccessor';
import { valueAccessorProvider } from '../../utils/provider-builders';
import { UserRole } from '../../utils/user-roles';
import { CompactAgency } from '../../woo_services.module/shared-types';

@Component({
  selector: 'agency-role-select',
  templateUrl: './agency-role-select.component.html',
  providers: [valueAccessorProvider(AgencyRoleSelect)],
})
export class AgencyRoleSelect extends AbstractValueAccessor<AgencyRole[]> {
  readonly UserRole = UserRole;

  @Input() agencies: CompactAgency[] = [];
  search: string;

  get filteredAgencies(): CompactAgency[] {
    return this.agencies
      .filter((a) => !this.search || a.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
      .filter((a) => !this.model.map((ar) => ar.agency).includes(a));
  }

  addAgencyToUser(agency: CompactAgency): void {
    this.model = this.model.concat({ agency: agency, role: UserRole.agencyUser });
  }

  removeAgencyFromUser(agencyRole: AgencyRole): void {
    this.model = this.model.filter((ar) => ar !== agencyRole);
  }

  toggleRole(agencyRole: AgencyRole): void {
    agencyRole.role = agencyRole.role === UserRole.agencyUser ? UserRole.agencyAdmin : UserRole.agencyUser;
    this.change();
  }
}

export interface AgencyRole {
  agency: CompactAgency;
  role: UserRole;
}
