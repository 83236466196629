<h1 class="margin-bottom--2x info-section-heading">Så här funkar det</h1>

<div class="row visible-sm visible-xs info-section--mobile">

  <div *ngFor="let item of info" class="col-xs-12 col-sm-6 margin-bottom--5x display--inline-block float--none">
    <div class="text-center">
      <img class="info-image" [src]="item.fileUrl" alt="">
      <h2 class="text--info text--strong">{{item.heading}}</h2>
    </div>
    <div class="info-section--description">
      {{item.description}}
    </div>
  </div>

</div>

<div class="hidden-sm hidden-xs info-section--desktop">

  <div class="flex__container info-section-row">
    <div class="info-section-column text--center" *ngFor="let item of info">
      <img class="info-image" [src]="item.fileUrl" alt="">
    </div>
  </div>

  <div class="flex__container info-section-row">
    <div class="info-section-column text--center" *ngFor="let item of info">
      <h2 class="text--info text--strong">{{item.heading}}</h2>
    </div>
  </div>

  <div class="flex__container info-section-row">
    <div class="info-section-column" *ngFor="let item of info">
      <p>{{item.description}}</p>
    </div>
  </div>

</div>


