import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ignore422 } from '../../utils/utils';
import { CampaignService } from '../../woo_services.module/CampaignService';
import { DialogService } from '../../woo_services.module/DialogService';
import { RoutingService } from '../../woo_services.module/RoutingService';
import { SessionService } from '../../woo_services.module/SessionService';
import { CampaignStatus, wooId } from '../../woo_services.module/shared-types';
import { CashCampaign, CashDashboardService } from '../services/CashDashboardService';
import { CashCampaignSummaryDialog } from './dialogs/cash-campaign-summary-dialog.component';

export enum DashboardTab {
  booked,
  active,
  ended,
  unbooked,
}

@Component({
  selector: 'cash-dashboard',
  templateUrl: './cash-dashboard.component.html',
  styleUrls: ['./cash-dashboard.component.scss'],
})
export class CashDashboard implements OnInit {
  readonly campaignTabs = [DashboardTab.booked, DashboardTab.active, DashboardTab.ended, DashboardTab.unbooked];
  readonly DashboardTab = DashboardTab;

  activeTab = this.campaignTabs[0];
  campaigns: Map<DashboardTab, CashCampaign[]>;
  customerId: wooId;
  rejectedCampaigns = 0;
  loadingCampaigns = false;
  dismissedRejectedCampaignsNotification = this.sessionService.getCashboardNotificationDismissed;

  constructor(
    private dialogService: DialogService,
    private routingService: RoutingService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private cashDashboardService: CashDashboardService,
  ) {
    this.loadCampaigns();
  }

  ngOnInit(): void {
    this.customerId = this.routingService.getParam(this.route, 'customerId');
  }

  clearCampaigns = (): void => {
    this.campaigns = this.campaignTabs.reduce((map, tab) => map.set(tab, []), new Map());
  };

  setActiveTab = (tabName: DashboardTab): void => {
    this.activeTab = tabName;
  };

  isTabActive = (tabName: DashboardTab): boolean => {
    return this.activeTab === tabName;
  };

  statusToTab = (status: CampaignStatus): DashboardTab => {
    switch (status) {
      case CampaignStatus.rejected:
      case CampaignStatus.upcoming:
      case CampaignStatus.reviewing:
      case CampaignStatus.booked:
        return DashboardTab.booked;
      case CampaignStatus.cancelled:
      case CampaignStatus.closed:
      case CampaignStatus.ended:
      case CampaignStatus.stopped:
        return DashboardTab.ended;
      case CampaignStatus.ongoing:
        return DashboardTab.active;
      default:
        return DashboardTab.unbooked;
    }
  };

  loadCampaigns = (): void => {
    this.loadingCampaigns = true;
    this.clearCampaigns();
    this.cashDashboardService
      .getCampaigns(this.routingService.getParam(this.route, 'customerId'))
      .then((campaigns: CashCampaign[]) => {
        campaigns.forEach((campaign) => {
          const tab = this.statusToTab(campaign.status);
          const campaignsForTab = this.campaigns.get(tab);
          campaignsForTab.push(campaign);
        });
        this.rejectedCampaigns = this.countRejected(campaigns);
      }, ignore422)
      .then(() => (this.loadingCampaigns = false));
  };

  deleteCampaign = async (campaignId: wooId): Promise<void> => {
    await this.campaignService.removeUnbooked(campaignId);
    this.loadCampaigns();
  };

  rejectionMessage = (): string[] => {
    if (this.rejectedCampaigns === 1) {
      return ['En kampanj uppfyller inte villkoren för att bli godkänd.', 'Åtgärda fel för att granska på nytt.'];
    }

    return [
      this.rejectedCampaigns + ' kampanjer uppfyller inte villkoren för att bli godkända.',
      'Åtgärda fel för att granska på nytt.',
    ];
  };

  dismissRejectedCampaignsNotification = (): void => {
    this.sessionService.setCashboardNotificationDismissed();
  };

  translateTab(tab: DashboardTab): string | DashboardTab {
    const list = {
      [DashboardTab.booked]: 'Bokade',
      [DashboardTab.active]: 'Pågående',
      [DashboardTab.ended]: 'Avslutade',
      [DashboardTab.unbooked]: 'Utkast',
    };
    return list[tab] !== undefined ? list[tab] : tab;
  }

  showSummaryDialog(campaign: CashCampaign): void {
    this.dialogService.create(CashCampaignSummaryDialog).show(campaign);
  }

  private countRejected(campaigns: CashCampaign[]): number {
    return campaigns.reduce((sum, campaign) => sum + (campaign.status === CampaignStatus.rejected ? 1 : 0), 0);
  }
}
