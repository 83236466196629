import { Component, Input } from '@angular/core';

const template = /* html */ `
<div *ngFor="let leftVal of left; let i = index">
  <div
    class="display--flex align-items--center justify-content--space-between padding-top-bottom--none"
    [ngClass]="{'padding-left-right--1x': !fullWidth}"
  >
    <h4 class="text-align--left capitalize--first-letter"
      [ngClass]="{
        'text--medium': bold=='left' || bold=='both',
        'text--large text--thin': enlarge=='left' || enlarge=='both'
      }"
    >
      {{leftVal}}
    </h4>
    <h4
      class="text-align--right capitalize--first-letter padding-left--1x"
      [ngClass]="{
        'text--medium': bold=='right' || bold=='both',
        'text--large text--thin': enlarge=='right' || enlarge=='both'
      }"
    >
      {{right[i]}}
    </h4>
  </div>
</div>
`;

@Component({
  selector: 'two-col-table',
  template: template,
  styles: ['h4 {margin-top: 4px; margin-bottom: 4px;}'],
})
export class TwoColTable {
  @Input() bold: 'left' | 'right' | 'both' | '' = '';
  @Input() enlarge: 'left' | 'right' | 'both' | '' = '';
  @Input() left: string[];
  @Input() right: string[];
  @Input() size: string;
  @Input() fullWidth: boolean;
}
