import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { isEqual } from 'lodash-es';
import { SimpleChanges } from '../../../utils/types';
import { NameIdTuple } from '../../../woo_services.module/shared-types';

const template = `
  <region-select
    [regions]="regions"
    [(ngModel)]="model"
    (ngModelChange)="notifyChange()"
    [ngModelOptions]="{standalone: true}"
  ></region-select>
`;

/**
 * This component works as a decorator for region-select in
 *  order to add validation to each Event that is emitted,
 *  and also trigger events each time input variables are changed.
 */
@Component({
  selector: 'validated-region-select',
  template: template,
})
export class ValidatedRegionSelect implements OnChanges {
  @Input() regions: NameIdTuple[] = [];
  @Input() model: NameIdTuple[] = [];
  @Output() modelAndValidityChange = new EventEmitter<RegionSelectedEvent>();

  private lastEvent = null;

  ngOnChanges(changes: SimpleChanges<ValidatedRegionSelect>): void {
    if (changes.regions && this.regions) {
      this.notifyChange();
    }
    if (changes.model && this.model) {
      this.notifyChange();
    }
  }

  notifyChange(): void {
    const regions = this.model;
    const event = { error: !this.validState(regions), regions };
    if (!isEqual(event, this.lastEvent)) {
      this.lastEvent = event;
      this.modelAndValidityChange.emit(event);
    }
  }

  validState(regions: NameIdTuple[]): boolean {
    return regions.length > 0;
  }
}

export interface RegionSelectedEvent {
  regions: NameIdTuple[];
  error: boolean;
}
