import { Component } from '@angular/core';
import { DialogService } from '../../woo_services.module/DialogService';
import { RoutingService } from '../../woo_services.module/RoutingService';
import { User } from '../../woo_services.module/shared-types';

const template = /* html */ `
<div class="inner-block">
  <div class="account__card inner-block block clearfix shadow-z-1">
    <div class="col-xs-12">
      <h2>Ny WOO-användare</h2>
      <br>
      <user-form (afterSubmit)="afterSubmit($event)" (onAbort)="goBack()"></user-form>
    </div>
  </div>
</div>
`;

@Component({
  selector: 'new-user',
  template: template,
})
export class NewUser {
  constructor(private routingService: RoutingService, private dialogService: DialogService) {}

  afterSubmit(user: User): void {
    this.routingService.navigate(['/user', user.id]);
    const msg = `En användare för ${user.first_name} ${user.last_name} har nu skapats och ett mail med instruktioner för att sätta lösenord har skickats till ${user.email}.`;
    this.dialogService.openSuccess(msg);
  }

  goBack(): void {
    this.routingService.navigate(['/accounts']);
  }
}
