import { Component } from '@angular/core';
import { AuthService } from '../../../woo_services.module/AuthService';
import { FileService, FileType } from '../../../woo_services.module/FileService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { SessionService } from '../../../woo_services.module/SessionService';
import { wooId } from '../../../woo_services.module/shared-types';
import { TermsService } from '../../../woo_services.module/TermsService';
import { UserService } from '../../../woo_services.module/UserService';

@Component({
  selector: 'terms-and-conditions-page',
  templateUrl: './terms-and-conditions-page.component.html',
})
export class TermsAndConditionsPage {
  private readonly termsType = this.fileService.getTermsFileTypeForUser();

  readonly termsUrl = this.fileService.getUrlToFile(this.termsType);
  readonly integrityPolicyUrl = this.fileService.getUrlToFile(FileType.integrityPolicy);
  readonly cookiePolicyUrl = this.fileService.getUrlToFile(FileType.cookiePolicy);

  constructor(
    private authService: AuthService,
    private fileService: FileService,
    private userService: UserService,
    private sessionService: SessionService,
    private routingService: RoutingService,
    private termsService: TermsService,
  ) {}

  async acceptTerms(): Promise<void> {
    const currentUser = this.authService.getUser();

    const promises: Array<Promise<any>> = [
      this.userService.acceptLatestFile(currentUser.id, this.termsType).then(() => {
        this.sessionService.updateUser('accepted_terms_and_conditions', true);
        this.authService.acceptedTermsAndConditions$.next();
      }),
      this.userService.acceptLatestFile(currentUser.id, FileType.integrityPolicy),
      this.userService.acceptLatestFile(currentUser.id, FileType.cookiePolicy),
    ];
    await Promise.all(promises);
    const bookingDraftRedirect = this.termsService.bookingDraftRedirect;
    if (bookingDraftRedirect) {
      await this.redirectToBookingDraft(bookingDraftRedirect);
    } else {
      await this.routingService.goToNewBooking();
    }
  }

  async redirectToBookingDraft(campaignId: wooId): Promise<void> {
    this.termsService.resetBookingDraftRedirect();
    await this.routingService.navigate(['/campaigns', campaignId, 'edit']);
  }

  async rejectTerms(): Promise<void> {
    await this.routingService.goToDashboard();
  }
}
