import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Agency, CompactCustomer, User } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getAgencyCustomers(): Promise<CompactCustomer[]> {
    return lastValueFrom(this.http.get<CompactCustomer[]>(this.env.apiUrl + '/agency_customers'));
  }

  async removeUserFromAgency(user: User, agency: Agency): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.patch<User>(`${this.env.apiUrl}/users/${user.id}/revoke_access_to_agency`, {
          agency_id: agency.id,
        }),
      );
      return true;
    } catch {
      return false;
    }
  }
}
