import { Injectable } from '@angular/core';
import { nullOrEmpty } from '../../utils/object';
import { MessageType } from '../../woo_components.module/feedback/context-message.component';
import { BookingStep, Message, MessageKey } from '../stores/BookingStore';

@Injectable()
export class MessageFactory {
  createValidationMessage<T>(
    item: T,
    mapper: (item: T) => string | string[],
    actionFnCreator: (item: T) => () => void,
    originStep: BookingStep,
    key: MessageKey,
  ): Message {
    return {
      key,
      text: mapper(item),
      type: MessageType.error,
      action: { label: 'Visa', fn: actionFnCreator(item) },
      dismissed: false,
      originStep,
    };
  }

  createValidationMessages<T>(
    items: T[],
    mapper: (item: T) => string | string[],
    actionFnCreator: (item: T) => () => void,
    originStep: BookingStep,
    key: MessageKey,
  ): Message[] {
    return items
      .map((item) => this.createValidationMessage(item, mapper, actionFnCreator, originStep, key))
      .filter((msg) => !nullOrEmpty(msg.text));
  }
}
