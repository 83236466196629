import { wooId } from '../woo_services.module/shared-types';
import * as object from './object';

export function sum(array: any[]): number {
  const initialAccumulation = 0;
  return array.reduce((accumulation, item) => accumulation + parseNumber(item), initialAccumulation);
}

function parseNumber(item: any): number {
  const ZERO_VALUES = [null, undefined, ''];
  return ZERO_VALUES.includes(item) ? 0 : Number(item);
}

export function pluck(array: any[], field: string): any[] {
  return array.map((item) => item[field]);
}

export function sumBy(array: any[], field: string): number {
  return sum(pluck(array, field).map(parseNumber));
}

export function compact<T>(array: T[]): T[] {
  return array.filter((item) => item !== null && item !== undefined);
}

export function forEachTwice(array: any[]): any {
  return array.reduce((curr, item) => curr.concat([item, item]), []);
}

/**
 * Sort an array on the provided property
 */
export function orderBy<T, K extends keyof T>(array: T[], prop: K): T[] {
  const compareFn = object.compareWith(prop);
  return array.sort(compareFn);
}

export function uniq<T>(array: T[]): T[] {
  return array.filter((item, index) => array.indexOf(item) === index);
}

export function duplicateValuesExist<T>(array: T[]): boolean {
  return array.some((item, index) => array.indexOf(item) !== index);
}

export function removeDuplicates<T, U>(array: T[], fn: (arg: T) => U): T[] {
  return array.reduce((prev, current) => {
    if (!prev.map((p) => fn(p)).includes(fn(current))) {
      return [...prev, current];
    }
    return prev;
  }, []);
}

export function getLast<T>(array: T[]): T | null {
  return array.length ? array[array.length - 1] : null;
}

export function groupBy<T, U>(array: T[], fn: (arg: T) => U): Map<U, T[]> {
  return array.reduce((map, item) => {
    const key = fn(item);
    if (!map.has(key)) {
      map.set(key, []);
    }
    map.get(key).push(item);
    return map;
  }, new Map<U, T[]>());
}

type NumberPropertyNames<T> = { [K in keyof T]: T[K] extends number ? K : never }[keyof T];

export function maxBy<T>(array: T[], prop: NumberPropertyNames<T>): number {
  return Math.max(...array.map((item) => (item[prop] as unknown) as number));
}

export function minBy<T>(array: T[], prop: NumberPropertyNames<T>): number {
  return Math.min(...array.map((item) => (item[prop] as unknown) as number));
}

export function mapConditional<T>(items: T[], pred: (item: T) => boolean, trans: (item: T) => T): T[] {
  return items.map((item) => (pred(item) ? trans(item) : item));
}

export function mapConditionalId<T extends { id: wooId }>(items: T[], id: wooId, trans: (item: T) => T): T[] {
  return mapConditional(items, (item) => item.id === id, trans);
}

/**
 * @returns true if the array exists and has at least one item in it
 */
export function atLeastOneItem(array?: any[]): boolean {
  return array && array.length > 0;
}

export function range(count: number, start = 0): number[] {
  return new Array(count).fill(undefined).map((_, i) => start + i);
}
