import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { scrollTo } from '../../../utils/dom';
import formatDistance from '../../../utils/formatDistance';
import { UserRole } from '../../../utils/user-roles';
import { ignore422, ignoreClosedDialog } from '../../../utils/utils';
import { AdHocContract, AdHocContractParams } from '../../../woo_services.module/AdHocContractService';
import { AdOrganisationService } from '../../../woo_services.module/AdOrganisationService';
import { AuthService } from '../../../woo_services.module/AuthService';
import { CustomerService } from '../../../woo_services.module/CustomerService';
import { DiscountsInteractionService } from '../../../woo_services.module/DiscountsInteractionService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { UserParams, UserService } from '../../../woo_services.module/UserService';
import { VoucherParams } from '../../../woo_services.module/VoucherService';
import {
  Brand,
  Customer as CustomerType,
  HistoryItem,
  Voucher,
  wooId,
} from '../../../woo_services.module/shared-types';

@Component({
  selector: 'customer',
  templateUrl: './customer.component.html',
})
export class Customer implements OnInit {
  readonly isAdminOrPlanner: boolean = this.authService.adminOrPlanner();
  readonly isAdminPlannerAccounting: boolean = this.authService.adminPlannerOrAccounting();
  readonly mayBrowseAsCashCustomer: boolean = this.authService.hasAnyRole([
    UserRole.admin,
    UserRole.sales,
    UserRole.salesManager,
  ]);
  readonly mayCreateDiscount: boolean = this.authService.hasAnyRole([
    UserRole.admin,
    UserRole.planner,
    UserRole.externalPlanner,
    UserRole.sales,
    UserRole.salesManager,
  ]);
  readonly mayCreateNewUser: boolean = !this.authService.hasAnyRole([
    UserRole.sales,
    UserRole.salesManager,
    UserRole.client,
  ]);
  readonly mayViewHistory: boolean = this.isAdminOrPlanner;

  customerType: CashOrDirect = CashOrDirect.direct;

  cramUpdated: string;
  cramSynced: string;
  customerHistory: HistoryItem[];
  activeBrands: Brand[];
  inactiveBrands: Brand[];
  forms: any;
  convert = this.translationService.convertDiscount;
  organisations = [];
  newUserObj = {
    first_name: '',
    last_name: '',
    email: '',
  };
  customer: CustomerType;
  show = {
    newUser: false,
    endedDiscounts: false,
    loadingCustomers: true,
    updatingCustomerType: false,
    newAdHocContract: false,
    newVoucher: false,
  };

  @ViewChild('newItemInCustomer', { static: true }) newItemInCustomer: ElementRef;

  constructor(
    private adOrganisationService: AdOrganisationService,
    private authService: AuthService,
    private customerService: CustomerService,
    private discountsService: DiscountsInteractionService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private translationService: TranslationService,
    private userService: UserService,
  ) {}

  async ngOnInit(): Promise<void> {
    const id = this.routingService.getParam(this.route, 'id');
    await this.loadCustomer(id);
    await this.loadHistory(id);
  }

  private async loadHistory(customerId) {
    this.customerHistory = await this.customerService.getCustomerHistory(customerId);
  }

  private async loadCustomer(customerId) {
    this.show.loadingCustomers = true;

    try {
      this.setCustomer(await this.customerService.getCustomer(customerId));
    } finally {
      this.show.loadingCustomers = false;
    }
  }

  setCustomer(customer: CustomerType): CustomerType {
    this.customer = customer;
    this.customerType = customer.cash_customer ? CashOrDirect.cash : CashOrDirect.direct;
    this.cramUpdated = formatDistance(new Date(this.customer.crm_last_modified_at));
    this.cramSynced = formatDistance(new Date(this.customer.crm_last_synced_at));
    this.activeBrands = customer.brands.filter((brand) => brand.active);
    this.inactiveBrands = customer.brands.filter((brand) => !brand.active);
    return customer;
  }

  getCustomerType(): string {
    if (this.customer.cash_customer) return 'Cashkund';
    if (!this.customer.agencies.length) return 'Direktkund';

    return 'Byråkund';
  }

  openNewUser(): void {
    this.show.newUser = true;
    if (this.newItemInCustomer) {
      scrollTo(this.newItemInCustomer.nativeElement);
    }
  }

  closeNewUser(): void {
    this.show.newUser = false;
  }

  async addUser(user: UserParams, id: wooId, cashCustomer: boolean): Promise<void> {
    user.customer_id = id;
    const role = cashCustomer ? UserRole.cashUser : UserRole.client;

    try {
      this.customer.users.push(await this.userService.createUser(user, role));
      this.closeNewUser();
    } catch (reason) {
      // Send error to sentry
      console.error('Failed to add user: ', reason);
    }
  }

  onVoucherDestroy = async (voucher: Voucher): Promise<void> => {
    await this.discountsService.deleteVoucher(voucher.id);
    try {
      this.customer.vouchers = this.customer.vouchers.filter((v) => v.id !== voucher.id);
    } catch (err) {
      ignoreClosedDialog(err);
      ignore422(err);
    }
  };

  onAdHocContractDestroy = async (contract: AdHocContract): Promise<void> => {
    await this.discountsService.deleteAdHocContract(contract.id);
    try {
      this.customer.ad_hoc_contracts = this.customer.ad_hoc_contracts.filter((d) => d.id !== contract.id);
    } catch (err) {
      ignoreClosedDialog(err);
      ignore422(err);
    }
  };

  onNewVoucher = async (voucher: VoucherParams): Promise<void> => {
    const newVoucher = await this.discountsService.createVoucher(voucher, this.customer.id);
    try {
      this.customer.vouchers = this.customer.vouchers.concat([newVoucher]);
      this.show.newVoucher = false;
    } catch (err) {
      ignoreClosedDialog(err);
      ignore422(err);
    }
  };

  onNewAdHocContract = async (contract: AdHocContractParams): Promise<void> => {
    const newAdHocContract = await this.discountsService.createAdHocContract(contract, this.customer.id);
    try {
      this.show.newAdHocContract = false;
      this.customer.ad_hoc_contracts = this.customer.ad_hoc_contracts.concat([newAdHocContract]);
    } catch (err) {
      ignoreClosedDialog(err);
      ignore422(err);
    }
  };

  openNewDiscount = async (discountType: string): Promise<void> => {
    if (!this.organisations.length) {
      this.organisations = await this.adOrganisationService.getAdOrganisations();
      this.show[discountType] = true;
    } else {
      this.show[discountType] = true;
    }
  };
}

enum CashOrDirect {
  cash = 'cash',
  direct = 'direct',
}
