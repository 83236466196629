<div class="padding--1x background-color--white shadow-z-1 margin-bottom--1x clearfix">
  <expandable-header backgroundColor="white" text="Frekvenstak">
    <div class="padding--1x">
      <p class="thin">Här kan du avaktivera/aktivera frekvenstaket för kampanjens videomål.</p>
      <p class="thin">Om kampanjen har andra frekvenstak så har de alltid företräde.</p>
      <div class="col-md-12 padding--none margin-top--3x">
        <toggle
          [(ngModel)]="frequencyLimitActive"
          offLabel="Avaktiverad"
          onLabel="Aktiverad"
          #frequencyToggle="ngModel">
        </toggle>
      </div>
      <div class="col-md-12 padding--none margin-top--3x">
        <button class="button primary-1 margin-top--2x" [disabled]="frequencyToggle.pristine" (click)="updateCampaignDefaultFrequencyLimit()">Spara och skicka till adservern</button>
      </div>
    </div>
  </expandable-header>
</div>
