import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WooComponentsModule } from '../woo_components.module/module';
import { CardBanner } from './components/card/card-banner.component';
import { CashDashboardCardDesktop } from './components/card/cash-dashboard-card-desktop.component';
import { CashDashboardCardMobile } from './components/card/cash-dashboard-card-mobile.component';
import { CashDashboard } from './components/cash-dashboard.component';
import { CashCampaignSummaryDialog } from './components/dialogs/cash-campaign-summary-dialog.component';
import { InfoSection } from './components/info-section/info-section.component';
import { CashCampaignService } from './services/CashCampaignService';
import { CashDashboardService } from './services/CashDashboardService';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, WooComponentsModule],
  declarations: [
    CardBanner,
    CashCampaignSummaryDialog,
    CashDashboard,
    CashDashboardCardDesktop,
    CashDashboardCardMobile,
    InfoSection,
  ],
  providers: [CashCampaignSummaryDialog, CashDashboardService, CashCampaignService],
})
export class CashDashboardModule {}
