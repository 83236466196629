import { Component, OnInit } from '@angular/core';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { GoogleTagManagerService } from '../../../woo_services.module/GoogleTagManagerService';
import {
  Campaign,
  CampaignEstimationPart,
  CampaignStatus,
  Targeting,
  ViewCurrency,
  wooId,
} from '../../../woo_services.module/shared-types';
import { AdvancedCampaignService } from '../../services/AdvancedCampaignService';
import { BookingModel, BookingStep, BookingStore, BookingTargetingMetaData } from '../../stores/BookingStore';
import { BookingStoreSelectors } from '../../stores/BookingStoreSelectors';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'advanced-booking-sidebar',
  templateUrl: './advanced-booking-sidebar.component.html',
  styleUrls: ['./advanced-booking-sidebar.component.scss'],
})
export class AdvancedBookingSidebar extends SubscriptionHandler implements OnInit {
  BookingStep = BookingStep;

  agencyName: string;
  customerName: string;
  campaignName: string;
  totalBudget: number;
  grossRatingViewsCurrency: string;
  completedViewsCurrency: string;
  impressionsCurrency: string;
  totalPeriod: { start?: Date | string; end?: Date | string } = {
    start: null,
    end: null,
  };
  grossRatingViews: number;
  completedViews: number;
  pauseImpressions: number;
  onlineVideoImpressions: number;
  targetings: Targeting[] = [];
  targetingMetaData: Record<wooId, BookingTargetingMetaData> = {};
  activeBookingStep: BookingStep;
  estimatingCampaign = false;
  showSaveButton = false;
  saveButtonText: string;
  showTargetingsBox: boolean;
  campaign: Campaign;

  constructor(
    private bookingStore: BookingStore,
    private campaignService: AdvancedCampaignService,
    private formatterService: FormatterService,
    private googleTagManagerService: GoogleTagManagerService,
    private selectors: BookingStoreSelectors,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnInit(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    this.agencyName = model.campaign.agency_name;
    this.customerName = model.campaign.customer_name;
    this.campaignName = model.campaign.name;

    this.totalBudget = this.selectors.totalBudget(model);
    this.totalPeriod = model.campaignPeriod;

    this.completedViews = this.selectors.totalViews(model, null, ViewCurrency.completedViews) || null;
    this.grossRatingViews = this.selectors.totalViews(model, null, ViewCurrency.grossRatingViews) || null;
    this.pauseImpressions = this.selectors.totalViews(model, null, ViewCurrency.impressions, true) || null;
    this.onlineVideoImpressions = this.selectors.totalViews(model, null, ViewCurrency.impressions, false) || null;
    this.targetings = model.campaign.targetings;
    this.targetingMetaData = model.targetingMetaData;
    this.grossRatingViewsCurrency = this.formatterService.mapCurrencyToName(ViewCurrency.grossRatingViews, true);
    this.completedViewsCurrency = this.formatterService.mapCurrencyToName(ViewCurrency.completedViews, true);
    this.impressionsCurrency = this.formatterService.mapCurrencyToName(ViewCurrency.impressions, true);

    this.activeBookingStep = model.activeBookingStep;
    this.showTargetingsBox =
      this.selectors.visitedSteps(model).includes(BookingStep.targeting) ||
      model.editingCampaign ||
      model.templatingCampaign;

    this.showSaveButton = model.campaign.status === CampaignStatus.unbooked;
    this.estimatingCampaign = model.waitingFor.estimateCampaign;
    this.campaign = model.campaign;
  };

  addTargeting = (): void => {
    this.googleTagManagerService.addTargetingAbf('advanced-booking-sidebar');
    const newTargetingId = this.bookingStore.addTargeting();
    this.campaignService.setDefaultPublisherForTargeting(newTargetingId);
  };

  formatTotalPeriod = (): string => {
    return this.formatterService.formatPeriod(this.totalPeriod.start, this.totalPeriod.end);
  };

  getEstimationPart = (targetingId: wooId): CampaignEstimationPart => {
    return this.bookingStore.state.estimation.parts.find((part) => part.targeting_id === targetingId);
  };
}
