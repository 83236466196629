import { Component } from '@angular/core';
import { slice, trackById } from '../../../utils/object';
import { CustomerAgencyRowData } from '../../../woo_components.module/helpers/customer-agency-row.component';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import {
  Brand,
  CampaignEstimationPart,
  Targeting,
  ViewCurrency,
  wooId,
} from '../../../woo_services.module/shared-types';
import { BookingModel, BookingStep, BookingStore } from '../../stores/BookingStore';
import { BookingStoreSelectors } from '../../stores/BookingStoreSelectors';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'advanced-booking-campaign-summary',
  templateUrl: './advanced-booking-campaign-summary.component.html',
  styleUrls: ['./advanced-booking-campaign-summary.component.scss'],
})
export class AdvancedBookingCampaignSummary extends SubscriptionHandler {
  BookingStep = BookingStep;
  activeBookingStep: BookingStep;

  agencyName: string;
  customerName: string;
  campaignName: string;
  brand: Brand;
  clientInvoiceReference: string;
  referenceNumber: string;
  additionalInfo: string;
  totalBudget: number;
  campaignStart: Date;
  campaignEnd: Date;
  grossRatingViews: number;
  completedViews: number;
  impressions: number;
  additionalViews: number;
  parts: CampaignEstimationPart[];
  ttvCampaign: boolean;
  targetings: Targeting[];
  grossRatingViewsCurrency: string;
  completedViewsCurrency: string;
  impressionsCurrency: string;
  customerAgencyData: CustomerAgencyRowData;

  constructor(
    private bookingStore: BookingStore,
    public formatterService: FormatterService,
    private selectors: BookingStoreSelectors,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  getViewsForTargeting(targeting: Targeting): number | null {
    const part = this.parts.find((p) => p.targeting_id === targeting.id);
    return part ? part.net_views : null;
  }

  initFromStore = (model: BookingModel): void => {
    this.agencyName = model.campaign.agency_name;
    this.customerName = model.campaign.customer_name;
    this.campaignName = model.campaign.name;
    this.brand = model.campaign.brand;
    this.clientInvoiceReference = model.campaign.client_invoice_reference;
    this.referenceNumber = model.campaign.reference_number;
    this.additionalInfo = model.campaign.additional_info;
    this.totalBudget = this.selectors.totalBudget(model);
    this.campaignStart = model.campaignPeriod.start;
    this.campaignEnd = model.campaignPeriod.end;
    this.grossRatingViews = this.selectors.totalViews(model, null, ViewCurrency.grossRatingViews) || null;
    this.completedViews = this.selectors.totalViews(model, null, ViewCurrency.completedViews) || null;
    this.impressions = this.selectors.totalViews(model, null, ViewCurrency.impressions) || null;
    this.additionalViews = model.estimation.additionalViews;
    this.parts = model.estimation.parts;
    this.targetings = model.campaign.targetings;
    this.ttvCampaign = model.campaign.ttv_campaign;
    this.grossRatingViewsCurrency = this.formatterService.mapCurrencyToName(ViewCurrency.grossRatingViews);
    this.completedViewsCurrency = this.formatterService.mapCurrencyToName(ViewCurrency.completedViews);
    this.impressionsCurrency = this.formatterService.mapCurrencyToName(ViewCurrency.impressions);
    this.customerAgencyData = slice(model.campaign, 'agency_name', 'agency_id', 'customer_name', 'customer_id');
  };

  additionalInfoUpdated = (): void => {
    this.bookingStore.setAdditionalInfo(this.additionalInfo);
  };

  trackById(): (_: any, item: { id: wooId }) => wooId {
    return trackById;
  }
}
