import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { addMonths, getISOWeek, getISOWeeksInYear, getISOWeekYear } from 'date-fns';
import { range } from 'lodash-es';
import { SimpleChanges } from '../../../utils/types';
import { InventoryService, InventoryWeeks } from '../../../woo_services.module/InventoryService';

@Component({
  selector: 'inventory-weeks-picker',
  templateUrl: './inventory-weeks-picker.component.html',
})
export class InventoryWeeksPicker implements OnInit, OnChanges {
  @Input() disabled = false;
  @Output() weeksChanged = new EventEmitter<InventoryWeeks>();

  readonly currentDate = new Date();
  weeks: InventoryWeeks = {
    startWeekYear: getISOWeekYear(this.currentDate),
    startWeekNumber: getISOWeek(this.currentDate),
    endWeekYear: getISOWeekYear(this.currentDate),
    endWeekNumber: getISOWeek(this.currentDate),
  };
  years: number[] = range(2015, getISOWeekYear(addMonths(this.currentDate, 6)) + 1);

  constructor(private inventoryService: InventoryService) {}

  ngOnInit(): void {
    this.emitWeeks();
  }

  ngOnChanges(changes: SimpleChanges<InventoryWeeksPicker>): void {
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
      if (!this.disabled) {
        // emit the current value when picker is enabled
        this.emitWeeks();
      }
    }
  }

  modelChanged(fieldName: string): void {
    this.weeks.startWeekNumber = this.correctWeekNr(this.weeks.startWeekYear, this.weeks.startWeekNumber);
    this.weeks.endWeekNumber = this.correctWeekNr(this.weeks.endWeekYear, this.weeks.endWeekNumber);

    this.inventoryService.validateWeekSpan(this.weeks, fieldName);
    this.emitWeeks();
  }

  weeksInSpecifikYear(year: number): number {
    // 28th of december is always in last week of the year
    const december28th = new Date(`${year}-12-28`);
    return getISOWeeksInYear(december28th);
  }

  private correctWeekNr(year, week) {
    const weeksInYear = this.weeksInSpecifikYear(year);
    if (week < 1) {
      return 1;
    } else if (week > weeksInYear) {
      return weeksInYear;
    }

    return week;
  }

  private emitWeeks() {
    this.weeksChanged.emit({ ...this.weeks });
  }
}
