import { Component, EventEmitter, Input, Output } from '@angular/core';
import { generateId } from '../../../utils/string';

@Component({
  selector: 'payment-radio-box',
  templateUrl: './payment-radio-box.component.html',
  styleUrls: ['./payment-radio-box.component.scss'],
})
export class PaymentRadioBox<T> {
  @Input() headline: string;
  @Input() text: string;
  @Input() value: T;
  @Input() model: T;
  @Output() modelChange = new EventEmitter<T>();

  private id = generateId();

  getId = (): string => {
    return this.id;
  };
}
