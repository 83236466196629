<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera inställningar för TTV (Total-TV)</h3>
</div>
<div #stickyHeader>
  <div class="tabbable__body">
    <button class="button primary-1" (click)="showNewTargetingMapping()" *ngIf="authService.admin()">Ny Total-TV-inställning</button>
  </div>
  <div class="tabbable__panel clearfix shadow-z-1">
    <div class="col-xs-12">
      <h3>Tillgängliga Total-TV-inställningar</h3>
    </div>
  </div>

</div>

<targeting-mappings-list [allowEdit]="authService.admin()" [mappings]="ongoingMappings" heading="Aktiva inställningar"></targeting-mappings-list>
<targeting-mappings-list [allowEdit]="authService.admin()" [mappings]="upcomingMappings" heading="Kommande inställningar"></targeting-mappings-list>
<targeting-mappings-list [allowEdit]="authService.admin()" [mappings]="oldMappings" heading="Historik"></targeting-mappings-list>

<div #newTTVMappingFormContainer>
  <div *ngIf="show.newTTVMappingForm" class="tabbable__body clearfix shadow-z-1">
    <div class="col-xs-12">
      <div class="tabbable__panel clearfix shadow-z-1">
          <h3>Ny TTV-inställning</h3>
      </div>
      <div class="tabbable__panel shadow-z-1">
        <new-ttv-targeting-mapping (onSubmit)="hideAndUpdateTargetingMapping($event)" (onAbort)="hideNewTargetingMapping($event)"></new-ttv-targeting-mapping>
      </div>
    </div>
  </div>
</div>
