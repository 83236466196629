import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { scrollTo } from '../../../utils/dom';
import { DURATIONS } from '../../../utils/time-constants';
import { UserRole } from '../../../utils/user-roles';
import { FeedbackDialog } from '../../../woo_components.module/dialogs/feedback-dialog.component';
import { AuthService } from '../../../woo_services.module/AuthService';
import { DashboardService } from '../../../woo_services.module/DashboardService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { EnvironmentService } from '../../../woo_services.module/EnvironmentService';
import { ErrorService } from '../../../woo_services.module/ErrorService';
import { FileService } from '../../../woo_services.module/FileService';
import { GoogleTagManagerService } from '../../../woo_services.module/GoogleTagManagerService';
import { HotkeyService } from '../../../woo_services.module/HotkeyService';
import { PollingService } from '../../../woo_services.module/PollingService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { SessionService } from '../../../woo_services.module/SessionService';
import { ServerEvents, StandardResponseService } from '../../../woo_services.module/StandardResponseService';
import { SystemMessage, SystemMessageService } from '../../../woo_services.module/SystemMessageService';
import { SystemService } from '../../../woo_services.module/SystemService';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html',
})
export class AppRoot extends SubscriptionHandler implements OnInit, OnDestroy {
  isAdmin: boolean;
  isAdminOrAccounting: boolean;
  isAdminOrPlanner: boolean;
  isCashUser: boolean;
  isExternalPlanner: boolean;
  isSalesManager: boolean;
  isAccountingOrSales: boolean;
  maySeeAccounts: boolean;
  maySeeAgencyOverview: boolean;
  maySeeEos: boolean;
  maySeeExternalCampaigns: boolean;
  maySeeImprovementSuggestions: boolean;
  maySeeImportEstimates: boolean;
  maySeeInventory: boolean;
  maySeeProducts: boolean;
  maySeeSelfServiceCompensation: boolean;
  maySeeStatus: boolean;

  currentUser = this.sessionService.getUser() || {};
  isLoggedIn = this.authService.isAuthenticated();
  allowedToBook = this.authService.allowedToBook();
  menuOpened = false;
  UserRole = UserRole;
  show = {
    wooLogo: true,
    nav: true,
  };

  cookieBannerDismissed = this.sessionService.getCookieBannerDismissed();
  getUrlToFile = this.fileService.getUrlToFile;
  systemMessages: SystemMessage[] = [];
  mode = {
    extended: false,
  };
  private requestedUrl: string;
  private currentUrl;

  idOfCurrentUsersCashCustomer = this.routingService.idOfCurrentUsersCashCustomer;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private dialogService: DialogService,
    private env: EnvironmentService,
    private errorService: ErrorService,
    private fileService: FileService,
    private googleTagManagerService: GoogleTagManagerService,
    private hotkeyService: HotkeyService,
    private pollingService: PollingService,
    private routingService: RoutingService,
    private sessionService: SessionService,
    private standardResponseService: StandardResponseService,
    private systemMessageService: SystemMessageService,
    private systemService: SystemService,
    private translationService: TranslationService,
  ) {
    super();
    this.setRolesPermissions();
  }

  ngOnInit(): void {
    if (this.isLoggedIn) {
      this.loadSystemSettings();
      this.loadSystemMessages();
    }

    this.pollingService.startAuthenticatedOnlyPoll(
      'REFRESH_SYSTEM_MESSAGES',
      this.loadSystemMessages,
      DURATIONS.halfHour,
    );
    this.pollingService.startAuthenticatedOnlyPoll(
      'REFRESH_SYSTEM_SETTINGS',
      this.loadSystemSettings,
      DURATIONS.halfHour,
    );
    if (this.env.production) {
      this.pollingService.startAuthenticatedOnlyPoll(
        'CHECK_FOR_NEW_VERSION',
        this.systemService.checkForNewVersion,
        DURATIONS.halfHour,
      );
    }

    this.initSubscriptions();
  }

  showGetStartedButton = (): boolean => {
    return !this.isLoggedIn && this.currentUrl === '/';
  };

  logout = (): void => {
    this.authService.logout();
  };

  gotoAnchor = (): void => {
    scrollTo(document.getElementById('signup'));
  };

  feedback = (): void => this.dialogService.create(FeedbackDialog).open();

  initSubscriptions = (): void => {
    this.addSubscription(
      this.authService.needsToUpdatePassword$.subscribe((event: { token: string }) => {
        this.routingService.navigate(['/update-password'], { queryParams: event });
      }),
    );

    this.addSubscription(
      this.authService.notAuthorized$.subscribe(() => {
        this.dialogService.openError('Rättigheter saknas');
      }),
    );

    this.addSubscription(
      this.authService.notFound$.subscribe(() => {
        this.dialogService.openError('Den url som du försökte gå till hittades tyvärr inte.');
      }),
    );

    this.addSubscription(
      this.authService.needsToAcceptTermsAndConditions$.subscribe(() => {
        this.routingService.navigate(['/terms']);
      }),
    );

    this.addSubscription(
      this.authService.login$.subscribe(() => {
        this.setRolesPermissions();
        this.isLoggedIn = true;
        this.currentUser = this.sessionService.getUser();
        this.loadSystemSettings();
        this.loadSystemMessages();
        if (this.requestedUrl) {
          window.location.href = this.requestedUrl;
        } else {
          this.menuOpened = false;
          this.routingService.goToRolesInitialPage();
        }
        this.googleTagManagerService.userLogin();
      }),
    );

    this.addSubscription(
      this.authService.loginFailed$.subscribe((event: { error: string }) => {
        this.routingService.navigate(['']);
        this.dialogService.openError(null, this.translationService.convertLoginError(event.error));
      }),
    );

    this.addSubscription(
      this.authService.logout$.subscribe(() => {
        this.setRolesPermissions();
        this.isLoggedIn = this.authService.isAuthenticated();
        this.currentUser = null;
        this.menuOpened = !this.isLoggedIn;
        this.routingService.navigate(['']);
      }),
    );

    this.addSubscription(
      this.authService.notAuthenticated$
        .pipe(debounceTime(300))
        .subscribe((args: { authorizationRequest: boolean; attemptedUrl: string }) => {
          this.dialogService.closeBlocking();
          if (args && args.authorizationRequest && args.attemptedUrl) {
            this.dialogService.openError('Attans!', 'Du behöver vara inloggad för att se den här sidan.');
            this.menuOpened = true;
            this.requestedUrl = args.attemptedUrl;
          }
          this.authService.logout();
          this.authService.dropUserLocally();
          this.currentUser = {};
          this.routingService.navigate(['']);
        }),
    );

    this.addSubscription(
      this.authService.acceptedTermsAndConditions$.subscribe(() => {
        this.loadSystemSettings();
        this.loadSystemMessages();
      }),
    );

    this.addSubscription(
      this.standardResponseService.tooManyRequests$.subscribe((data) => {
        this.errorService.process({ name: ServerEvents.tooManyRequests }, data);
      }),
    );

    this.addSubscription(
      this.standardResponseService.internalServerError$.subscribe((data) => {
        this.errorService.process({ name: ServerEvents.internalServerError }, data);
      }),
    );

    this.addSubscription(
      this.standardResponseService.serviceUnavailableError$.subscribe((data) => {
        this.errorService.process({ name: ServerEvents.serviceUnavailableError }, data);
      }),
    );

    this.addSubscription(
      this.dashboardService.dashboardExtended$.subscribe((isExtended) => {
        this.mode.extended = isExtended;
      }),
    );

    // Global HTTP 422 handler
    this.addSubscription(
      this.standardResponseService.returnedUserError$.subscribe((data) => {
        this.errorService.process({ name: ServerEvents.returnedUserError }, data);
      }),
    );

    this.addSubscription(this.routingService.onStart(this.closeMenu));

    this.addSubscription(
      this.routingService.urlOnNavChange((url) => {
        this.currentUrl = url;
      }),
    );

    this.addSubscription(
      this.routingService.onNavChange(() => {
        if (this.systemService.refreshApp) {
          this.systemService.refreshApp = false;
          this.googleTagManagerService.hardReload();
          window.location.reload(); // Hard reload that triggers refresh of js sources
        }
        if (!this.routingService.getState('noScrollToTop')) {
          window.scrollTo(0, 0);
        }
        this.closeMenu();
      }),
    );

    this.addSubscription(this.hotkeyService.addShortcut({ keys: 'esc' }).subscribe(this.closeMenu));
  };

  toggleNav = (): void => {
    this.menuOpened = !this.menuOpened;
  };

  closeMenu = (): void => {
    this.menuOpened = false;
  };

  ignoreSpecificErrors = (err: { status: number }): void => {
    const statusesToIgnore = [401, -1];
    if (!err || !statusesToIgnore.includes(err.status)) {
      throw err;
    }
  };

  loadSystemMessages = async (): Promise<void> => {
    try {
      this.systemMessages = await this.systemMessageService.getAll();
    } catch (error) {
      this.ignoreSpecificErrors(error);
    }
  };

  loadSystemSettings = async (): Promise<void> => {
    await this.systemService.load();
    try {
      this.setFeatureToggles();
    } catch (error) {
      this.ignoreSpecificErrors(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFeatureToggles = (): void => {};

  setRolesPermissions = (): void => {
    this.isAdmin = this.authService.hasRole(UserRole.admin);
    this.isAdminOrAccounting = this.authService.hasAnyRole([UserRole.admin, UserRole.accounting]);
    this.isAdminOrPlanner = this.authService.hasAnyRole([UserRole.admin, UserRole.planner]);
    this.isCashUser = this.authService.hasRole(UserRole.cashUser);
    this.isExternalPlanner = this.authService.hasRole(UserRole.externalPlanner);
    this.isSalesManager = this.authService.hasRole(UserRole.salesManager);
    this.isAccountingOrSales = this.authService.hasAnyRole([
      UserRole.sales,
      UserRole.salesManager,
      UserRole.accounting,
    ]);
    this.maySeeAccounts = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.planner,
      UserRole.externalPlanner,
      UserRole.accounting,
      UserRole.agencyAdmin,
      UserRole.sales,
      UserRole.salesManager,
      UserRole.agencyUser,
      UserRole.client,
    ]);
    this.maySeeAgencyOverview = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.planner,
      UserRole.accounting,
      UserRole.salesManager,
    ]);
    this.maySeeEos = this.authService.hasAnyRole([UserRole.admin, UserRole.eos, UserRole.accounting]);
    this.maySeeExternalCampaigns = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.planner,
      UserRole.salesManager,
    ]);
    this.maySeeImprovementSuggestions = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.accounting,
      UserRole.planner,
      UserRole.sales,
    ]);
    this.maySeeImportEstimates = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.planner,
      UserRole.estimator,
      UserRole.accounting,
    ]);
    this.maySeeInventory = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.planner,
      UserRole.estimator,
      UserRole.sales,
      UserRole.accounting,
      UserRole.externalPlanner,
      UserRole.salesManager,
    ]);
    this.maySeeProducts = this.authService.hasAnyRole([UserRole.admin, UserRole.estimator, UserRole.planner]);
    this.maySeeSelfServiceCompensation = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.accounting,
      UserRole.agencyAdmin,
      UserRole.planner,
      UserRole.salesManager,
    ]);
    this.maySeeStatus = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.accounting,
      UserRole.planner,
      UserRole.sales,
      UserRole.salesManager,
      UserRole.statusUser,
    ]);
    this.allowedToBook = this.authService.allowedToBook();
  };
}
