import { Component, Input } from '@angular/core';
import { valueAccessorProvider } from '../../utils/provider-builders';
import { MP4Validator } from '../../woo_services.module/asset_validator/MP4ValidatorService';
import { CreativeAssetService } from '../../woo_services.module/CreativeAssetService';
import { DialogService } from '../../woo_services.module/DialogService';
import { FileService } from '../../woo_services.module/FileService';
import { CreativeAssetType, wooId } from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { AssetUpload } from './asset-upload.component';

@Component({
  selector: 'video-upload',
  templateUrl: './asset-upload.component.html',
  styleUrls: ['./video-upload.component.scss'],
  providers: [valueAccessorProvider(VideoUpload)],
})
export class VideoUpload extends AssetUpload {
  @Input() customerId: wooId;

  assetType = CreativeAssetType.Video;
  acceptedFileType = 'video/mp4';
  technicalSpec = '16:9, mp4, max 50 MB, max 60s';

  constructor(
    protected dialogService: DialogService,
    protected creativeAssetService: CreativeAssetService,
    protected translationService: TranslationService,
    protected validationService: MP4Validator,
    protected fileService: FileService,
  ) {
    super(dialogService, creativeAssetService, translationService, validationService, fileService);
  }
}
