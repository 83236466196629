import { Component } from '@angular/core';
import { ignore422 } from '../../utils/utils';
import { FileService, FileType } from '../../woo_services.module/FileService';
import { UserService } from '../../woo_services.module/UserService';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class Signup {
  FileType = FileType;

  formSignup = {
    email: '',
    first_name: '',
    last_name: '',
    company: '',
    phone: '',
  };

  formSubmitSuccess = false;

  constructor(private userService: UserService, public fileService: FileService) {}

  submitSignupForm(): void {
    this.userService.saveSignupUser(this.formSignup).then(() => {
      this.formSubmitSuccess = true;
    }, ignore422);
  }
}
