<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera tillgängliga apparater</h3>
</div>

<div>
  <div #stickyHeader>
    <div class="tabbable__body">
      <import-warning *ngIf="show.estimatesHaveChanged"></import-warning>
      <dot-loader [visibility]="show.updatingEstimates"></dot-loader>
      <div class="button-filter-container">
        <div>
          <button class="button primary-1" (click)="showNewDeviceGroup()">Ny apparat</button>
          <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="deviceGroupsForm.invalid">Spara</button>
          <button *ngIf="!show.isEditing" class="button secondary" (click)="setEditing(true)">Redigera apparater</button>
          <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
        </div>
        <div class="display--flex">
          <div class="filter-dropdown">
            <woo-dropdown
            [items]="deviceGroups"
            [(ngModel)]="selectedDeviceGroup"
            name="selectedDeviceGroup"
            inputId="select-deviceGroupr"
            labelText="Filtrera på apparat"
            notFoundText="Inga val hittades."
            [clearable]="true"></woo-dropdown>
          </div>
          <div *ngIf="productFormats && productFormats.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="productFormats"
            [(ngModel)]="selectedProductFormat"
            name="selectedProductFormat"
            inputId="select-product-format"
            labelText="Filtrera på Placering"
            [labelTransform]="translateProductFormat"
            notFoundText="Inga Placeringar hittades."
            [clearable]="true"></woo-dropdown>
          </div>
          <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="publishers"
            [(ngModel)]="selectedPublisher"
            name="selectedPublisher"
            inputId="select-publisher"
            labelText="Filtrera på Publicist"
            notFoundText="Inga publicister hittades."
            [clearable]="true"></woo-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Tillgängliga apparater</h3>
      </div>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table-woo table--info table--hover width--100" ngForm #deviceGroupsForm="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-center" colspan="3">
            Estimatfördelning
          </th>
          <th class="text-center" colspan="3">
            Maxuttag
          </th>
          <th [colSpan]="1"></th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>

        <tbody *ngIf="!show.loading">
          <ng-container *ngFor="let row of deviceRows; let index = index;">
          <tr [hidden]="!rowVisible(row)">
            <td class="text--thin vertical-align--middle">
              <span>{{row.device}}</span>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{translateProductFormat(row.productFormat)}}</span>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{row.publisher}}</span>
            </td>
            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.sharesPreroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-sharesPreroll"
                  [(ngModel)]="getDeviceGroup(row.deviceId).shares[row.publisherId][row.productFormatId].preroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
              </div>
            </td>

            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.sharesMidroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-sharesMidroll"
                  [(ngModel)]="getDeviceGroup(row.deviceId).shares[row.publisherId][row.productFormatId].midroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
              </div>
            </td>

          <td class="vertical-align--middle">
          <span *ngIf="!show.isEditing">{{row.sharesPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
                <input
                  name="row-{{index}}-sharesPostroll"
                  [(ngModel)]="getDeviceGroup(row.deviceId).shares[row.publisherId][row.productFormatId].postroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
              </div>
            </td>

            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPreroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-withdrawalLimitsPreroll"
                  [(ngModel)]="getDeviceGroup(row.deviceId).withdrawal_limits[row.publisherId][row.productFormatId].preroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
              </div>
            </td>

            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.withdrawalLimitsMidroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-withdrawalLimitsMidroll"
                  [(ngModel)]="getDeviceGroup(row.deviceId).withdrawal_limits[row.publisherId][row.productFormatId].midroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
              </div>
            </td>

            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPostroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-withdrawalLimitsPostroll"
                  [(ngModel)]="getDeviceGroup(row.deviceId).withdrawal_limits[row.publisherId][row.productFormatId].postroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
              </div>
            </td>

          <td class="padding-top-bottom--1x">
            <button [routerLink]="['/device_groups', row.deviceId]" class="status-badge primary float--left">
              <span class="icon-edit icon__semi-medium "></span>
            </button>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>

    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
      <button class="button primary" (click)="save()" [disabled]="deviceGroupsForm.invalid">Spara</button>
      <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>

<div #newDeviceGroupFormContainer>
  <div *ngIf="show.newDeviceGroupForm" class="tabbable__body clearfix shadow-z-1">
    <div class="col-xs-12">
      <div class="tabbable__panel clearfix shadow-z-1">
        <div class="col-xs-12">
          <h3>Ny apparat</h3>
        </div>
      </div>
      <div class="tabbable__panel clearfix shadow-z-1">
        <device-group-form
        (onAbort)="hideNewDeviceGroup()"
        (onSubmit)="addDeviceGroup($event)"></device-group-form>
      </div>
    </div>
  </div>
</div>
