import { Component } from '@angular/core';
import { WooDialogHelper } from './woo-dialog-helper';

const template = /* html */ `
<woo-dialog>
  <progress-content
    [message]="message"
    [progress] = "progress"
    [progress_message] = "progress_message"
  ></progress-content>
</woo-dialog>
`;

@Component({
  selector: 'blocking-dialog',
  template: template,
})
export class BlockingDialog extends WooDialogHelper {
  public message = '';
  public progress: number;
  public progress_message = '';

  show = (message?: string, progress?: number, progress_message?: string): void => {
    this.message = message;
    this.progress = progress;
    this.progress_message = progress_message;
    this.open(true);
  };
}
