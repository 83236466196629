import { Component, Input, OnChanges } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { groupBy } from '../../utils/array';
import { SimpleChanges } from '../../utils/types';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { CampaignFrequencyReport } from '../services/campaign-frequency-report.service';

const template = /* html */ `
<div class="chart-wrapper">
  <canvas baseChart
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      chartType="line"
    >
  </canvas>
</div>
`;

@Component({
  selector: 'frequency-limit-per-month-graph',
  template: template,
})
export class FrequencyLimitPerMonthGraph implements OnChanges {
  readonly lineChartLabels: string[] = Array(12)
    .fill(null)
    .map((_, idx) => this.translationService.convertMonthIndex(idx).full);
  readonly lineChartOptions: ChartOptions = {
    responsive: true,
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  @Input() reports: CampaignFrequencyReport[] = [];
  lineChartData: ChartDataset[];

  constructor(private translationService: TranslationService) {}

  ngOnChanges(changes: SimpleChanges<FrequencyLimitPerMonthGraph>): void {
    if (changes.reports && this.reports) {
      this.lineChartData = this.getData(this.reports);
    }
  }

  private getData(reports: CampaignFrequencyReport[]): ChartDataset[] {
    const nullIfAllZero = (list: number[]) => (list.every((i) => i === 0) ? null : list);
    return Array.from(groupBy(reports, (r) => r.year).entries())
      .flatMap(([year, reports]) => [
        {
          label: `${year} adserver`,
          data: nullIfAllZero(reports.map((r) => r.average_adserver_frequency)),
          fill: false,
        },
        {
          label: `${year} mms`,
          data: nullIfAllZero(reports.map((r) => r.average_mms_frequency)),
          fill: false,
        },
      ])
      .filter((set) => set.data);
  }
}
