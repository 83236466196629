import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';
import { dispatchMethod } from '../utils/redux-dev-tools';
import { Store } from '../utils/store';
import { CampaignMmsGainFormData } from '../woo_components.module/campaign_mms_gain_form/campaign-mms-gain-form.component';
import { GoalAdditionalImpressionsFormData } from '../woo_components.module/goal_additional_impressions_form/goal_additional_impressions_form/goal-additional-impressions-form.component';
import { CampaignTracking } from '../woo_dashboard.module/services/TrackingService';
import {
  Campaign,
  Creative,
  DashboardLoad,
  DashboardTab,
  Targeting,
  TargetingAdditionalGoalBudgetData,
  wooId,
} from './shared-types';

@Injectable({ providedIn: 'root' })
export class DashboardStore extends Store<DashboardModel> {
  constructor() {
    super(null);
    this.clear();
  }

  setState(model: DashboardModel): void {
    super.setState({
      ...model,
    });
  }

  @dispatchMethod()
  setActiveCampaign(campaign: Campaign): void {
    this.setState({
      ...this.state,
      activeCampaign: campaign,
      activeCampaignTracking: null,
    });
  }

  @dispatchMethod()
  clear(): void {
    this.setState({
      activeCampaign: null,
      activeTab: null,
      campaignsWithAlerts: [],
      activeCampaignTracking: null,
      dashboardIsExtended: false,
      editCreatives: false,
      editGoals: false,
      isLoading: {
        campaigns: false,
        campaign: false,
        organisations: false,
        search: false,
      },
      notAllowedToEditCreativesReason: null,
      expandedTargetingHeaders: null,
      modifiedCreatives: null,
      modifiedGoals: null,
    });
  }

  @dispatchMethod()
  setExpandedTargetingHeader(tab: DashboardTab, targetingId: wooId, expanded: boolean): void {
    this.setState({
      ...this.state,
      expandedTargetingHeaders: {
        ...this.state.expandedTargetingHeaders,
        [tab]: {
          ...this.state.expandedTargetingHeaders?.[tab],
          [targetingId]: expanded,
        },
      },
    });
  }

  @dispatchMethod()
  setCreativeEditCriteria(reason: string): void {
    this.setState({
      ...this.state,
      notAllowedToEditCreativesReason: reason,
    });
  }

  @dispatchMethod()
  setModifiedCreative(targetingId: wooId, creative: Creative): void {
    this.setState({
      ...this.state,
      modifiedCreatives: {
        ...this.state.modifiedCreatives,
        [targetingId]: {
          ...this.state.modifiedCreatives[targetingId],
          [creative.id]: {
            ...this.state.modifiedCreatives[targetingId][creative.id],
            modified: !isEqual(creative, this.state.modifiedCreatives[targetingId][creative.id].creative)
              ? true
              : this.state.modifiedCreatives[targetingId][creative.id].modified,
            creative: creative,
          },
        },
      },
    });
  }

  @dispatchMethod()
  setCreativeValidity(targetingId: wooId, creativeId: wooId, isValid: boolean): void {
    this.setState({
      ...this.state,
      modifiedCreatives: {
        ...this.state.modifiedCreatives,
        [targetingId]: {
          ...this.state.modifiedCreatives[targetingId],
          [creativeId]: {
            ...this.state.modifiedCreatives[targetingId][creativeId],
            valid: isValid,
          },
        },
      },
    });
  }

  @dispatchMethod()
  setCampaignCreatives(targetingId: wooId, creatives: Creative[]): void {
    this.setState({
      ...this.state,
      activeCampaign: {
        ...this.state.activeCampaign,
        targetings: this.state.activeCampaign.targetings.map((t) => {
          return t.id === targetingId ? { ...t, creatives: creatives } : t;
        }),
      },
    });
  }

  @dispatchMethod()
  setEditCreatives(value: boolean): void {
    let modifiedCreatives;
    if (value) {
      modifiedCreatives = this.state.activeCampaign.targetings.reduce(
        (res, t) => ({
          ...res,
          [t.id]: t.creatives.reduce(
            (perTargetingRes, c) => ({ ...perTargetingRes, [c.id]: { valid: true, modified: false, creative: c } }),
            {},
          ),
        }),
        {},
      );
    } else {
      modifiedCreatives = null;
    }

    this.setState({
      ...this.state,
      editCreatives: value,
      modifiedCreatives: modifiedCreatives,
    });
  }

  @dispatchMethod()
  setDashboardIsExtended(value: boolean): void {
    this.setState({
      ...this.state,
      dashboardIsExtended: value,
      editCreatives: false,
    });
  }

  @dispatchMethod()
  setCampaignsWithAlerts(campaignsWithAlerts: wooId[]): void {
    this.setState({
      ...this.state,
      campaignsWithAlerts,
    });
  }

  @dispatchMethod()
  setIsLoading(key: DashboardLoad, value: boolean): void {
    this.setState({
      ...this.state,
      isLoading: {
        ...this.state.isLoading,
        [key]: value,
      },
    });
  }

  @dispatchMethod()
  setTracking(tracking: CampaignTracking): void {
    this.setState({
      ...this.state,
      activeCampaignTracking: tracking,
    });
  }

  @dispatchMethod()
  setActiveTab(tab: DashboardTab): void {
    this.setState({
      ...this.state,
      activeTab: tab,
    });
  }

  @dispatchMethod()
  setEditGoals(isEditing: boolean): void {
    let modifiedGoals = null;
    if (isEditing) {
      const [targetingForms, goalForms] = this.extractTargetingAndGoalFormDataFromTargetings(
        this.state.activeCampaign.targetings,
      );
      modifiedGoals = {
        mmsForm: {
          valid: this.state.modifiedGoals?.mmsForm.valid ?? true,
          modified: false,
          values: {
            mmsGain: this.state.activeCampaign.mms_gain,
          },
        },
        targetingForms: targetingForms,
        goalForms: goalForms,
      };
    }

    this.setState({
      ...this.state,
      editGoals: isEditing,
      modifiedGoals: modifiedGoals,
    });
  }

  @dispatchMethod()
  setModifiedGoalsMmsFormValues(values: CampaignMmsGainFormData): void {
    if (!this.state.modifiedGoals) {
      return;
    }

    this.setState({
      ...this.state,
      modifiedGoals: {
        ...this.state.modifiedGoals,
        mmsForm: {
          ...this.state.modifiedGoals.mmsForm,
          modified: !isEqual(values, this.state.modifiedGoals.mmsForm.values)
            ? true
            : this.state.modifiedGoals.mmsForm.modified,
          values: values,
        },
      },
    });
  }

  @dispatchMethod()
  setModifiedGoalsMmsFormValidity(isValid: boolean): void {
    if (!this.state.modifiedGoals) {
      return;
    }

    this.setState({
      ...this.state,
      modifiedGoals: {
        ...this.state.modifiedGoals,
        mmsForm: {
          ...this.state.modifiedGoals.mmsForm,
          valid: isValid,
        },
      },
    });
  }

  @dispatchMethod()
  setModifiedGoalsGoalFormValues(targetingId: wooId, goalId: wooId, values: GoalAdditionalImpressionsFormData): void {
    if (!this.state.modifiedGoals) {
      return;
    }

    this.setState({
      ...this.state,
      modifiedGoals: {
        ...this.state.modifiedGoals,
        goalForms: {
          ...this.state.modifiedGoals.goalForms,
          [targetingId]: {
            ...this.state.modifiedGoals.goalForms[targetingId],
            [goalId]: {
              ...this.state.modifiedGoals.goalForms[targetingId][goalId],
              modified: !isEqual(values, this.state.modifiedGoals.goalForms[targetingId][goalId].values)
                ? true
                : this.state.modifiedGoals.goalForms[targetingId][goalId].modified,
              values: values,
            },
          },
        },
      },
    });
  }

  @dispatchMethod()
  setModifiedGoalsGoalFormValidity(targetingId: wooId, goalId: wooId, isValid: boolean): void {
    if (!this.state.modifiedGoals) {
      return;
    }

    this.setState({
      ...this.state,
      modifiedGoals: {
        ...this.state.modifiedGoals,
        goalForms: {
          ...this.state.modifiedGoals.goalForms,
          [targetingId]: {
            ...this.state.modifiedGoals.goalForms[targetingId],
            [goalId]: {
              ...this.state.modifiedGoals.goalForms[targetingId][goalId],
              valid: isValid,
            },
          },
        },
      },
    });
  }

  private extractTargetingAndGoalFormDataFromTargetings = (
    targetings: Targeting[],
  ): [DashboardModel['modifiedGoals']['targetingForms'], DashboardModel['modifiedGoals']['goalForms']] => {
    let targetingFormData: DashboardModel['modifiedGoals']['targetingForms'];
    let goalFormData: DashboardModel['modifiedGoals']['goalForms'];
    targetings.map((t) => {
      targetingFormData = {
        ...targetingFormData,
        [t.id]: {
          valid: this.state.modifiedGoals?.targetingForms?.[t.id]?.valid ?? true,
          modified: false,
          values: {
            additionalGoalBudget: t.additional_goal_budget,
            additionalGoalBudgetMessage: t.additional_goal_budget_message,
          },
        },
      };
      t.goals.map((g) => {
        goalFormData = {
          ...goalFormData,
          [t.id]: {
            ...goalFormData?.[t.id],
            [g.id]: {
              valid: this.state.modifiedGoals?.goalForms?.[t.id]?.[g.id]?.valid ?? true,
              modified: false,
              values: {
                additionalImpressions: g.additional_impressions,
              },
            },
          },
        };
      });
    });

    return [targetingFormData, goalFormData];
  };
}

export interface DashboardModel {
  activeCampaign: Campaign;
  activeTab: DashboardTab;
  campaignsWithAlerts: wooId[];
  activeCampaignTracking: CampaignTracking;
  dashboardIsExtended: boolean;
  editGoals: boolean;
  editCreatives: boolean;
  isLoading: Record<DashboardLoad, boolean>;
  notAllowedToEditCreativesReason: string;
  expandedTargetingHeaders: Record<DashboardTab, Record<wooId, boolean>>;
  modifiedCreatives: Record<
    wooId,
    Record<
      wooId,
      {
        valid: boolean;
        modified: boolean;
        creative: Creative;
      }
    >
  >;
  modifiedGoals: {
    mmsForm: {
      valid: boolean;
      modified: boolean;
      values: CampaignMmsGainFormData;
    };
    targetingForms: Record<
      wooId,
      {
        valid: boolean;
        modified: boolean;
        values: TargetingAdditionalGoalBudgetData;
      }
    >;
    goalForms: Record<
      wooId,
      Record<
        wooId,
        {
          valid: boolean;
          modified: boolean;
          values: GoalAdditionalImpressionsFormData;
        }
      >
    >;
  };
}
