import { Component } from '@angular/core';
import { WooDialogHelper } from '../../../../woo_components.module/dialogs/woo-dialog-helper';

const template = /* html */ `
  <woo-dialog>
    <h3>Skickade fakturor</h3>
    <p>Observera att kampanjen du redigerar har fakturor som redan har skickats.
      <br>Det innebär att du inte får redigera kampanjens start- och slutdatum, i övrigt kan du redigera kampanjen så länge det fakturerade beloppet inte förändras.
    </p>
    <div class="text-center">
      <button (click)="close()" class="button primary-1">Okej, jag förstår!</button>
    </div>
  </woo-dialog>
`;

@Component({
  selector: 'invoices-already-sent-dialog',
  template: template,
})
export class InvoicesAlreadySentDialog extends WooDialogHelper {
  constructor() {
    super();
  }
}
