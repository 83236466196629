import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  supportPhone = '060-608 97 15';

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  sendFeedback(feedback: Feedback): Promise<any> {
    return lastValueFrom(
      this.http.post<any>(`${this.env.apiUrl}/feedback`, {
        feedback: feedback,
      }),
    );
  }
}

export interface Feedback {
  name?: string;
  email?: string;
  problem_description: string;
  problem_area: string;
  expected_outcome: string;
  actual_outcome: string;
}
