import { Injectable } from '@angular/core';
import { CreativeService } from '../CreativeService';
import { CodeErrors, errorMessage } from '../shared-types';

// @Injectable is for some reason required if the only injected item is an Angular service
@Injectable({ providedIn: 'root' })
export class VideoCodeService {
  constructor(protected creativeService: CreativeService) {}

  prefixError = (code: string, prefix: string, secondPrefix: string = null): errorMessage => {
    const codePrefixText = secondPrefix ? `${prefix} eller ${secondPrefix}` : `${prefix}`;
    return !code || code.startsWith(prefix) || code.startsWith(secondPrefix)
      ? null
      : `Koden måste börja med ${codePrefixText}`;
  };

  codeLengthError = (code: string, minLength = 10, maxLength = 10): errorMessage => {
    const codeLengthText = minLength !== maxLength ? `${minLength} till ${maxLength}` : `exakt ${maxLength}`;
    return !code || code.length === minLength || code.length === maxLength
      ? null
      : `Koden måste bestå av ${codeLengthText} tecken`;
  };

  filmLengthError = (code: string, filmLengths: number[], startIndex: number, endIndex: number): errorMessage => {
    return !code || filmLengths.indexOf(this.getVideoCodeFilmLength(code, startIndex, endIndex)) !== -1
      ? null
      : 'Koden innehåller inte en giltig filmlängd';
  };

  whiteSpaceError = (code: string): errorMessage => {
    return !code || !code.match(/\s/) ? null : 'Koden innehåller ett eller fler blanksteg';
  };

  getVideoCodeFilmLength = (code: string, startIndex: number, endIndex: number): number =>
    Number(code.slice(startIndex, endIndex + 1));

  typeCodeError = (code: string, min: number, max: number): errorMessage => {
    return this.testRegexp(code, new RegExp(`^.[${min}-${max}]`))
      ? null
      : `Andra tecknet måste vara en siffra ${min}-${max}`;
  };

  //Only for Peach
  versionError = (code: string): errorMessage => {
    return this.testRegexp(code, /^.{8}[0-9A-Z]{2}/) ? null : 'Versionskoden (två sista tecknen) måste vara 00-ZZ';
  };

  protected testRegexp = (str: string, regExp: RegExp): boolean => !str || regExp.test(str);

  protected removeBlankKeys = (errors: CodeErrors): CodeErrors => {
    return Object.entries(errors).reduce(
      (acc, [key, value]) => (value ? Object.assign(acc, { [key]: value }) : acc),
      {},
    );
  };
}
