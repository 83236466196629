<context-message
  class="negative-margin-left--4px full-width-message"
  *ngFor="let message of visibleMessages"
  [messageType]="message.type"
  (dismiss)="dismissMessage(message)"
  [message]="message.text"
>
  <p class="margin-top--2x" *ngIf="message.action && message.originStep !== activeStep">
    <a class="clickable-link" (click)=" message.action.fn()">{{message.action.label}}</a>
  </p>
</context-message>
