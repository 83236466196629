<div class="wrapper section lighter-gray">

  <div class="padding-top--4x">

    <div class="introduction row content__container section__padding">
      <div class="col-xs-12 col-sm-5 text__column">
        <h1>Boka reklam i TV4:s digitala kanaler</h1>
        <p class="introduction__ingress">
          Få full kontroll genom att snabbt och enkelt skapa, boka och följa upp
           din kampanj i bokningsverktyget <span class="text-nowrap">WOO Manager</span>.
        </p>

        
        <button class="button secondary margin-top--2x margin-left--none" (click)="scrollTo(signup)">Kom igång</button>
      </div>

      <div class="col-xs-12 col-sm-7 image__column hidden-xs">
        <img src="../../../../images/landing/example-new.png" alt="">
      </div>
    </div>

    <div class="instructions content__container section__padding">
      <h2 class="padding-bottom--1x margin--none">Så här går det till</h2>
      <div class="row">
        <div class="col-xs-12 col-sm-7">
          <div class="video__container">
            <div class="video-cover" [ngClass]="{'video-cover-image': show.playButton}" *ngIf="show.playButton || show.replayButton" (click)="playVideo($event)">
              <div class="play-button" *ngIf="show.playButton">
                <span class="icon-play-filled"></span>
                <div class="hidden-xs">
                  <p class="text--strong text--large margin--none">Så fungerar WOO Manager</p>
                  <p class="text--thin text--small margin--none" *ngIf="videoDuration">{{videoDuration}}</p>
                </div>
              </div>
              <div class="replay-button" *ngIf="show.replayButton">
                <span class="icon-replay"></span>
                <div class="hidden-xs">
                  <p class="text--strong text--large margin--none">Spela igen</p>
                </div>
              </div>
            </div>
            <div class="instructions__video">
              <video
                #videoPlayer
                [poster]="videos.instructions.poster"
                [controls]="(!show.playButton && !show.replayButton) || videoIsFullscreen"
                muted
              >
                <source
                  *ngFor="let source of videos.instructions.sources"
                  [src]="source.src"
                  [type]="source.type"
                >
              </video>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-5">

          <list-item
            number="1"
            heading="Skapa din kampanj"
            text="Lägg in din budget och välj när kampanjen ska visas."
          ></list-item>

          <list-item
            number="2"
            heading="Välj din målgrupp"
            text="Välj för vem du vill synas - du betalar endast för färdigspelade visningar!"
          ></list-item>

          <list-item
            number="3"
            heading="Boka och följ upp"
            text="Klicka på boka och sedan är det bara att luta dig tillbaka så sköter vi resten!"
          ></list-item>

        </div>

      </div>
    </div>

    <div class="followup content__container section__padding">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <h2>Följ upp din kampanj i realtid</h2>
          <p class="ingress">
            WOO Manager ger dig full koll över samtliga dina kampanjer -
            avslutade, pågående och framtida - på ett överskådligt sätt.
          </p>
          <p class="ingress">
            Se hur många påbörjade och färdigspelade visningar din
            kampanj har fått, hur många som har klickat på din annons,
            hur du når dina kampanjmål - och mycket mer!
          </p>
        </div>

        <div class="col-xs-12 col-sm-6 statistics">
          <img src="../../../../images/landing/statistik.png" alt="WOO Managers statistikvy">
        </div>

      </div>
    </div>

    <div class="usps clearfix section__padding">
      <div class="content__container">
        <h2 class="text-center">
          3 goda skäl att välja WOO Manager
        </h2>

        <div class="card__container">
          <div class="card"
            (mouseover)="setVisibleImage('control', 'hover')"
            (mouseout)="setVisibleImage('control', 'default')"
          >
            <div class="circle">
              <img [src]="visibleImage.control" class="img" alt="">
            </div>
            <h2>Full koll</h2>
            <p>
              Den inbyggda transparensen i WOO's plattform gör det enkelt för dig som annonsör att följa upp din kampanj
              i realtid.
            </p>
          </div>

          <div class="card"
            (mouseover)="setVisibleImage('specific', 'hover')"
            (mouseout)="setVisibleImage('specific', 'default')"
            >
            <div class="circle">
              <img [src]="visibleImage.specific" class="img" alt="">
            </div>
            <h2>Precis styrning</h2>
            <p>Styr din kampanj mot geografiskt område, kopplat till specifikt innehåll och olika målgrupper.</p>
          </div>

          <div class="card"
            (mouseover)="setVisibleImage('easy', 'hover')"
            (mouseout)="setVisibleImage('easy', 'default')"
            >
            <div class="circle">
              <img [src]="visibleImage.easy" class="img" alt="">
            </div>
            <h2>Enkelt att använda</h2>
            <p>Boka snabbt och smidigt och följ sedan upp i realtid med WOO Manager.</p>
          </div>

        </div>
      </div>
    </div>


    <div id="signup" #signup >
      <signup></signup>
    </div>

    <footer id="footer" class="footer__container relative ">
      
      <div class="footer__cover">
        <div class="footer__info block anchor">
          <div class="content__container clearfix">
            <div class="row">
              <div class="col-12 col-sm-7 col-lg-8">
                <h1>WOO Manager</h1>
                <p>WOO Manager är en plattform för videoannonsering online. Hör av dig direkt till <a
                    href="mailto:info@woo.ad">info&#64;woo.ad</a> om du har några frågor om WOO.</p>
                <p><a href="{{getUrlToFile(FileType.cookiePolicy)}}" target="_blank">Cookiepolicy</a></p>
              </div>
              <address class="col-12 col-sm-5 col-lg-4">
                <h3 class="">Kontakt</h3>
                <p>TV4</p>
                <p>Tegeluddsvägen 3-5 </p>
                <p>115 79 Stockholm </p>
                <p>Telefon 060-608 97 15</p>
                <p>Org.nr. 556242-7152</p>
              </address>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </div>
</div>
