<form [formGroup]="arrayForm">
  <div formArrayName="array"
    class="flex__container align-items--end justify-content--space-between"
    [ngClass]="{'two-buttons': arrayLength > 1 && last, 'margin-bottom--1x': !last }"
    *ngFor="let item of array.controls; first as first; last as last; let i = index"
    >
    <div class="input__container margin--none">
      <span *ngIf="first && optional" class="input__optional__text">Frivillig</span>
      <label *ngIf="first">{{label}}</label>
      <input [formControl]="array.controls[i]" attr.e2e-id="string-array-input-{{i}}" >
    </div>

    <button *ngIf="last" type="button" (click)="add()" class="padding-left--1x" [disabled]="isDisabled" [attr.e2e-id]="'add-imp-tracking-url-' + i">
      <span class="status-badge primary no-outline display--inline-block margin--none">
        <span class="icon-plus"></span>
      </span>
    </button>

    <button *ngIf="arrayLength > 1" type="button" class="padding-left--1x" (click)="remove(i)" [disabled]="isDisabled">
      <span class="status-badge primary no-outline display--inline-block margin--none">
        <span class="icon-trash icon__semi-medium"></span>
      </span>
    </button>
  </div>
</form>
