import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { endOfWeek, startOfWeek } from 'date-fns';
import { formatWooDate } from '../../../../utils/date';
import { scrollTo } from '../../../../utils/dom';
import { DialogService } from '../../../../woo_services.module/DialogService';
import {
  PriceList,
  PriceListCurrency,
  PriceListService,
  PriceListType,
} from '../../../../woo_services.module/PriceListService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { DatePeriod } from '../../../../woo_services.module/shared-types';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-pricelists',
  templateUrl: './manage-pricelists.component.html',
})
export class ManagePriceLists implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('newPriceListPicker', { static: true }) listPickerElemRef: ElementRef;
  @ViewChild('newPriceListPanel', { static: true }) newPriceListContainer: ElementRef;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  readonly DATE_FORMAT = 'yyyy-MM-dd HH:mm';
  readonly getLabel = this.priceListService.getLabel;
  readonly sortPriceLists = this.priceListService.priceListSort;
  readonly translatePriceListType = this.priceListService.translatePriceListType;
  readonly translatePriceListViewCurrency = this.priceListService.translatePriceListCurrency;
  readonly show = {
    newPriceListPicker: false,
    newPriceListForm: false,
  };

  readonly tableHeaders = ['Period', 'Typ', 'Valuta', 'Skapad', 'Uppdaterad', ''];

  priceLists: PriceList[] = [];
  newPriceList: Partial<PriceList> = this.priceListService.getEmpty();

  constructor(
    private priceListService: PriceListService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private utilityService: ProductsPageUtilityService,
  ) {}

  ngOnInit(): void {
    this.priceListService
      .getPriceLists()
      .then((lists: PriceList[]) => (this.priceLists = lists.sort(this.sortPriceLists)))
      .finally(() => {
        scrollTo(this.parentHeader);
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      });
  }

  showNew(): void {
    this.show.newPriceListPicker = true;
    scrollTo(this.listPickerElemRef.nativeElement);
  }

  templatePricelistFrom(
    template: PriceList,
    year: number,
    priceListType: PriceListType,
    priceListCurrency: PriceListCurrency,
  ): void {
    const newPeriod = this.getPeriodForYear(year);
    this.newPriceList = {
      ...template,
      valid_from: formatWooDate(newPeriod.start),
      valid_to: formatWooDate(newPeriod.end),
      price_list_type: priceListType,
      price_list_currency: priceListCurrency,
    };
    delete this.newPriceList.id;
    scrollTo(this.newPriceListContainer.nativeElement);
    this.show.newPriceListForm = true;
  }

  hideNew(): void {
    this.show.newPriceListPicker = false;
    this.show.newPriceListForm = false;
    scrollTo(this.parentHeader);
  }

  createPriceList(list: PriceList): void {
    this.priceListService.addPriceList(list).then(() => {
      const msg = `Prislistan för ${this.getLabel(list)} ${list.price_list_type} har lagts till!`;
      this.dialogService.openSuccess(msg);
      this.hideNew();
      this.routingService.navigateWithReload(['/products', 'price_lists']);
    });
  }

  private getPeriodForYear(year: number): DatePeriod {
    const start = startOfWeek(new Date(year, 0, 1), { weekStartsOn: 1 });
    const end = endOfWeek(new Date(year, 11, 31), { weekStartsOn: 1 });
    return {
      start: start,
      end: end,
    };
  }
}
