<div class="inventory-wrapper" *ngIf="inventory.length > 0">
  <div class="inventory-header">
    <div class="col col1"></div>
    <div class="col col2">{{viewCurrencyLabel}}</div>
      <div class="col"
        *ngFor="let column of columnNames | keyvalue: asIsKeyOrder"
        [ngStyle]="{'width.%': widthLeftForDynamicColumns/numberOfColumns}">
        <div class="text-align--right white-space--pre-line">
          {{column.value}}
        </div>
    </div>
  </div>

  <div *ngFor="let elem of inventory; trackBy: trackElement; let firstElement = first;" [attr.e2e-id]="firstElement ? 'first-inventory-row' : ''">
    <div class="inventory-row {{getFilterClass(elem.filter_key)}}"
    [ngClass]="{'last-row': isLastLevel(elem.level)}"
    [@verticalExpansion]
    *ngIf="getLevelVisibility(elem.level)"
    >
      <div class="col col1">
        <span class="chevron expand-arrow strong" [ngClass]="{rotate: isLastVisibleOrHidden(elem.level)}"
          *ngIf="!isLastLevel(elem.level)" (click)="toggleVisibilityForLevelsBelow(elem.level)">
        </span>
      </div>
      <div class="col col2" [ngStyle]="{'padding-left.px': getIndentation(elem.level)}">
        <span>{{getFilterValueName(elem.filter_key, elem.filter_value_key)}}</span>
        <span class="left-margin text--normal"
          *ngIf="firstElement">
          ({{convertPeriodToDateString(elem.filter_value_key, true)}})
        </span>
        <span *ngIf="elem.filter_key==='week'" class="left-margin thin">
          ({{convertPeriodToDateString(elem.filter_value_key)}})
        </span>
      </div>

      <div class="col text-align--right"
        *ngFor="let attr of columnNames | keyvalue: asIsKeyOrder"
        [ngStyle]="{'width.%': widthLeftForDynamicColumns/numberOfColumns}">
          <span *ngIf="showTooltipCheck({row: elem, column: attr.key})" class="icon-warning text--cta tooltip-container" data-toggle="tooltip" title="Maxuttaget är större än Tillgängligt"></span>
          <span *ngIf="elem[attr.key] !== null" [ngClass]="{'text--danger': elem[attr.key] < 0}" attr.e2e-id="inventory-column-{{attr.key}}" >{{elem[attr.key] | number}}</span>
      </div>
    </div>
  </div>
</div>
