import { Component } from '@angular/core';
import { WooDialogHelper } from '../../../../woo_components.module/dialogs/woo-dialog-helper';

const template = /* html */ `
  <woo-dialog>
    <h3>Redigering av TTV-kampanj</h3>
    <p>Observera att kampanjen du redigerar är en TTV-kampanj, vilket innebär att du bara får redigera filmerna,
     så länge fördelningen mellan filmlängderna inte förändras.
    </p>
    <div class="text-center">
      <button (click)="close()" class="button primary-1">Okej, jag förstår!</button>
    </div>
  </woo-dialog>
`;

@Component({
  selector: 'ttv-campaign-editing-dialog',
  template: template,
})
export class TTVCampaignEditingDialog extends WooDialogHelper {
  constructor() {
    super();
  }
}
