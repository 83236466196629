<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera prislistorna som styr priserna på produkterna i WOO Manager</h3>
</div>

<div>
  <div #stickyHeader>
    <div class="tabbable__body clearfix shadow-z-1">
      <div class="button-filter-container">
        <button class="button primary-1" (click)="showNew()">Ny prislista</button>
      </div>
    </div>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Existerande prislistor</h3>
      </div>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <div class="row">
      <div class="col-xs-12">
        <table class="table table--woo table--info table--hover table--align--middle">
          <thead #stickyTableHeader>
          <tr>
            <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
              {{header}}
            </th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let priceList of priceLists">
            <td class="strong">
              {{ getLabel(priceList) }}
            </td>
            <td>
              {{ translatePriceListType(priceList.price_list_type) }}
            </td>
            <td>
              {{ translatePriceListViewCurrency(priceList.price_list_currency) }}
            </td>
            <td>
              {{ priceList.created_at | date:DATE_FORMAT }}
            </td>
            <td>
              {{ priceList.updated_at | date:DATE_FORMAT }}
            </td>
            <td class="text-center">
              <button [routerLink]="['/products', 'pricelist', priceList.id]" class="button margin--none">
                <span class="icon-edit icon__semi-medium margin-right--1x"></span>
                Redigera
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<div class="row margin-top--3x">
  <div class="col-xs-12">
    <div #newPriceListPicker class="away" [ngClass]="{away: (!show.newPriceListPicker && !show.newPriceListForm)}">
      <div *ngIf="show.newPriceListPicker" class="account__card ng-scope no--animation">
        <h3>Skapa ny prislista</h3>
        <select-pricelist-form
          [priceLists]="priceLists"
          (onSubmit)="templatePricelistFrom($event.priceList, $event.year, $event.priceListType, $event.priceListCurrency)"
          (onAbort)="hideNew()"
        ></select-pricelist-form>
      </div>
    </div>
  </div>
</div>
<div #newPriceListPanel >
  <div *ngIf="show.newPriceListForm">
    <edit-pricelist
    [newPriceList]="newPriceList"
    (onSubmit)="createPriceList($event)"
    ></edit-pricelist>
  </div>
</div>
