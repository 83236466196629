import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { verticalExpansion } from '../../utils/animations';
import {
  targetingAdditionalBudgetWithAgreementPart,
  targetingAdditionalViewsWithAgreementPart,
  targetingNetBudgetViewsWithAgreementPart,
  targetingNetBudgetWithAgreementPart,
} from '../../utils/budget_helpers/targeting_budget_helper';
import { SimpleChanges } from '../../utils/types';
import { calculateTRP } from '../../utils/utils';
import { AuthService } from '../../woo_services.module/AuthService';
import { CreativeDistributions, CreativeService } from '../../woo_services.module/CreativeService';
import { FormatterService } from '../../woo_services.module/FormatterService';
import {
  AgreementPart,
  CampaignEstimationPart,
  CommercialWeek,
  Creative,
  FrequencyLimit,
  GeoTargeting,
  RegionalDivision,
  Targeting,
  TargetingMetaData,
  TargetingType,
} from '../../woo_services.module/shared-types';

const ANIMATION_TIMING = 250;

@Component({
  selector: 'targeting-summary',
  templateUrl: './targeting-summary.component.html',
  styleUrls: ['./targeting-summary.component.scss'],
  animations: [verticalExpansion(ANIMATION_TIMING)],
})
export class TargetingSummary implements OnChanges {
  readonly RegionalDivision = RegionalDivision;
  readonly isAdminOrPlanner = this.authService.adminOrPlanner();

  @Input() targeting: Targeting;
  @Input() metaData: TargetingMetaData;
  @Input() part: CampaignEstimationPart | AgreementPart;
  @Input() expanded = true;
  @Output() expandedChange = new EventEmitter<boolean>();

  includesLinear: boolean;
  isRegional: boolean;
  salesOrderNumber: string;
  regionalDivision: RegionalDivision;
  sendInvoices: boolean;
  periodInfoHeading: string;
  noInvoiceMessage: string;
  additionalBudgetMessage: string;
  currency = '';
  currencyAcronym: string;
  netBudget: number;
  additionalBudget: number;
  totalBudget: number;
  additionalViews: number;
  estimatedViews: number;
  totalEstimatedViews: number;
  priceGross: string;
  priceNet: string;
  discount: string;
  creativesDistributions: CreativeDistributions;
  frequencyLimit: FrequencyLimit = null;
  creativesFromAgreement: Creative[] = [];
  creativesAddedAfterAgreement: Creative[] = [];

  universe: number = null;
  grossRatingFactor: number = null;
  trp: string = null;

  constructor(
    public authService: AuthService,
    private formatterService: FormatterService,
    private creativeService: CreativeService,
  ) {}

  ngOnChanges(_changes: SimpleChanges<TargetingSummary>): void {
    if (!this.targeting || !this.metaData) {
      return;
    }

    this.initFromTargeting();

    if (this.part) {
      this.initFromPart(this.part);
      this.creativesDistributions =
        'share_type' in this.part
          ? this.creativeService.getAgreementDistributions(
              this.targeting.creatives,
              this.part.creatives,
              this.targeting.creative_share_type,
            )
          : this.creativeService.getDistributions(
              this.targeting.creatives,
              this.part.creatives,
              this.targeting.creative_share_type,
            );
      this.creativesFromAgreement = this.targeting.creatives.filter((targetingCreative) =>
        this.part.creatives.map((agreementCreative) => agreementCreative.creative_id).includes(targetingCreative.id),
      );
      this.creativesAddedAfterAgreement = this.targeting.creatives.filter(
        (targetingCreative) =>
          !this.part.creatives.map((agreementCreative) => agreementCreative.creative_id).includes(targetingCreative.id),
      );
    }
  }

  private initFromTargeting() {
    this.frequencyLimit = this.targeting.frequency_limit;
    this.includesLinear = this.targeting.includes_linear;
    this.salesOrderNumber = this.targeting.sales_order_number;
    this.regionalDivision = this.targeting.regional_division;
    this.sendInvoices = this.targeting.send_invoices;
    this.periodInfoHeading = this.metaData.targetingType === TargetingType.instream ? 'Filmperiod' : 'Bildperiod';
    this.isRegional = this.metaData.geoTargeting === GeoTargeting.regional;
    this.noInvoiceMessage = this.targeting.invoice_disable_message;
    this.additionalBudgetMessage = this.targeting.additional_budget_message;
    this.currency = this.formatterService.getCurrencyName(this.targeting);
    this.currencyAcronym = this.formatterService.getCurrencyName(this.targeting, false, 'acronym');
    this.universe = this.targeting.universe;
    this.grossRatingFactor = this.targeting.gross_rating_factor;
  }

  private initFromPart(part: CampaignEstimationPart | AgreementPart) {
    this.netBudget = targetingNetBudgetWithAgreementPart(this.targeting, part);
    this.additionalBudget = targetingAdditionalBudgetWithAgreementPart(this.targeting, part);
    this.totalBudget = this.netBudget + this.additionalBudget;
    this.additionalViews = targetingAdditionalViewsWithAgreementPart(this.targeting, part);
    this.estimatedViews = targetingNetBudgetViewsWithAgreementPart(this.targeting, part);
    this.totalEstimatedViews = this.additionalViews + this.estimatedViews;
    this.priceGross = part.gross_cpm;
    this.priceNet = part.net_cpm;
    this.discount = this.formatterService.transformPercent(part.discount);
    this.trp = this.getTrp();
  }

  private getTrp(): string {
    if (this.totalEstimatedViews && this.universe) {
      return this.formatterService.transformNumber(calculateTRP(this.totalEstimatedViews, this.universe), '1.2-2');
    } else {
      return null;
    }
  }

  get partUnbookableWeeks(): CommercialWeek[] {
    return this.part && 'unbookable_weeks' in this.part ? this.part.unbookable_weeks : [];
  }
}
