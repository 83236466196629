<table class="table table--woo table--hover table--align--middle">
  <tr *ngFor="let item of programsByWeek">
    <td class="min-width--x8">
      <span class="strong">{{formatWeek(item.period)}}</span>
    </td>
    <td>
      <p
        class="tab tab--small min-width--24 pointer"
        [ngClass]="{'success': program._selected}"
        (click)="program._selected = !program._selected"
        *ngFor="let program of item.programs">
          {{program.name}}
      </p>
    </td>
  </tr>
</table>
