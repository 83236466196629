import { Component, EventEmitter, OnInit, Output } from '@angular/core';

const template = `
Din kampanj kommer visas utan styrning på område eller målgrupp.
`;

@Component({
  selector: 'no-targeting-select',
  template: template,
})
export class NoTargetingSelect implements OnInit {
  @Output() noTargetingChange = new EventEmitter<any>();

  ngOnInit(): void {
    this.noTargetingChange.emit({ error: false });
  }
}
