import { Component } from '@angular/core';

const template = /* html */ `
  <default-mms-value-setting></default-mms-value-setting>
  <div class="divider medium margin-top-bottom--2x"></div>
  <invalid-mms-days-admin></invalid-mms-days-admin>
`;

@Component({
  selector: 'mms-admin',
  template: template,
})
export class MmsAdmin {}
