import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import getUnixTime from 'date-fns/getUnixTime';
import isBefore from 'date-fns/isBefore';
import { scrollTo } from '../../../../utils/dom';
import { roundDecimalError } from '../../../../utils/math';
import { CategoryService } from '../../../../woo_services.module/CategoryService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { FormatterService } from '../../../../woo_services.module/FormatterService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ProgramFormatService } from '../../../../woo_services.module/ProgramFormatService';
import { PublisherService } from '../../../../woo_services.module/PublisherService';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import {
  Category,
  ProductFormats,
  Program,
  ProgramSegment,
  ProgramShares,
  Publisher,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-program-formats',
  templateUrl: './manage-program-formats.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageProgramFormats {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('newItemProgramFormat', { static: true }) newItemProgramFormat: ElementRef;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;

  readonly inputFieldIds = {
    prerollLongform: 'preroll-longform',
    prerollShortform: 'preroll-shortform',
    midrollLongform: 'midroll-longform',
    midrollShortform: 'midroll-shortform',
  };

  constructor(
    private categoryService: CategoryService,
    private fb: FormBuilder,
    public formatterService: FormatterService,
    private programFormatService: ProgramFormatService,
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
  ) {}

  show = {
    newProgramFormatForm: false,
    estimatesHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };

  programFormats: Program[] = [];
  publishers: Publisher[] = [];
  categories: Category[] = [];
  selectedCategory: Category;
  cachedPrograms: Program[] = [];
  selectedRow: wooId[] = [];
  selectedRowForEditing: wooId = null;
  showMidroll = false;
  longformId: string;
  shortformId: string;

  async ngOnInit(): Promise<void> {
    const [publishers, categories, programFormats, productFormats] = await Promise.all([
      this.publisherService.getPublishers(),
      this.categoryService.getCategories(),
      this.programFormatService.getProgramFormats(),
      this.productFormatService.getProductFormats(ExtraProductFormatField.enabled, ExtraProductFormatField.format),
    ]);
    try {
      this.publishers = publishers;
      this.categories = categories;
      this.programFormats = programFormats;

      this.cachedPrograms = this.deepCopyProgramFormats(programFormats);

      const availableFormats = productFormats.filter((pf) => pf.enabled);
      this.longformId = availableFormats.find((pf) => pf.format === ProductFormats.longForm).id;
      this.shortformId = availableFormats.find((pf) => pf.format === ProductFormats.shortForm).id;
    } finally {
      this.show.loading = false;
      this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      scrollTo(this.parentHeader);
    }
  }

  autoFill(programFormatShares: ProgramShares[], inputFieldId: string): void {
    if (inputFieldId === this.inputFieldIds.prerollLongform) {
      this.programShareShortform(programFormatShares).preroll = roundDecimalError(
        1 - this.programShareLongform(programFormatShares).preroll,
        3,
      );
    } else if (inputFieldId === this.inputFieldIds.prerollShortform) {
      this.programShareLongform(programFormatShares).preroll = roundDecimalError(
        1 - this.programShareShortform(programFormatShares).preroll,
        3,
      );
    } else if (inputFieldId === this.inputFieldIds.midrollLongform) {
      this.programShareShortform(programFormatShares).midroll = roundDecimalError(
        1 - this.programShareLongform(programFormatShares).midroll,
        3,
      );
    } else if (inputFieldId === this.inputFieldIds.midrollShortform) {
      this.programShareLongform(programFormatShares).midroll = roundDecimalError(
        1 - this.programShareShortform(programFormatShares).midroll,
        3,
      );
    }
  }

  async toggleActiveFormat(format: Program): Promise<void> {
    await this.programFormatService.updateProgramFormat(format, format.product_format_program_share);
  }

  async save(formatId: wooId = null): Promise<void> {
    const filterActive = this.selectedCategory;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    await this.dialogService.openConfirm({
      header: 'Bekräfta ändringar',
      textBlocks: textBlocks,
      confirmText: 'Spara',
      cancelText: 'Avbryt',
    });
    try {
      this.show.loading = true;
      this.show.updatingEstimates = true;
      if (formatId) {
        const programFormat = this.programFormats.find((pf) => pf.id === formatId);
        const shares = programFormat.product_format_program_share;
        this.updateCategoryName(await this.programFormatService.updateProgramFormat(programFormat, shares));
        this.cachedPrograms = this.deepCopyProgramFormats(this.programFormats);
      } else {
        const allShares = this.programFormats.flatMap((program) => program.product_format_program_share);
        const updatedShares = await this.productFormatService.updateProgramFormatShares(allShares);
        try {
          this.programFormats.map((pf) => {
            pf.product_format_program_share = updatedShares.filter((shares) => shares.program_format_id === pf.id);
          });
          this.cachedPrograms = this.deepCopyProgramFormats(this.programFormats);
          this.show.estimatesHaveChanged = true;
        } catch (err) {
          this.programFormats = this.cachedPrograms;
          this.dialogService.openError(this.translationService.convertError(err.error.error));
        }
      }
    } catch {
      return null;
    } finally {
      this.setEditingDistribution(false);
      this.setEditingDetails(false, this.selectedRowForEditing);
      this.selectedRowForEditing = null;
      this.show.loading = false;
      this.show.updatingEstimates = false;
      this.dialogService.closeBlocking();
    }
  }

  rowVisible(obj: Program): boolean {
    const categoryFiltered = this.selectedCategory && obj.category_id !== this.selectedCategory.id;
    return !categoryFiltered;
  }

  setSelected(programId: wooId): void {
    if (this.selectedRow.includes(programId)) {
      this.selectedRow = this.selectedRow.filter((row) => !(row === programId));
    } else {
      this.selectedRow.push(programId);
    }
  }

  openTab(obj: Program): boolean {
    return this.selectedRow.includes(obj.id);
  }

  setEditingDistribution(value: boolean): void {
    this.show.isEditing = value;
    this.selectedRowForEditing = null;
  }

  setEditingDetails(value: boolean, id: wooId): void {
    this.selectedRowForEditing = id;
    this.show.isEditing = false;
  }

  isEditing(formatId: wooId): boolean {
    return formatId === this.selectedRowForEditing;
  }

  cancelEditing(): void {
    this.programFormats = this.deepCopyProgramFormats(this.cachedPrograms);
    this.setEditingDetails(false, this.selectedRowForEditing);
    this.selectedRowForEditing = null;
    this.setEditingDistribution(false);
  }

  openCard(type: string): void {
    if (type === 'program') {
      this.show.newProgramFormatForm = true;
    }
    scrollTo(this.newItemProgramFormat.nativeElement);
  }

  addNewFormat(format: Program): void {
    this.programFormats.push(format);
  }

  closeCard(): void {
    this.show.newProgramFormatForm = false;
    scrollTo(this.parentHeader);
  }

  filterPeriods(periods: ProgramSegment[], compareTime?: Date): ProgramSegment[] {
    const time = compareTime ? compareTime : new Date();
    const filtered = periods.filter((period: ProgramSegment) => {
      return isBefore(time, new Date(period.end_date));
    });

    const lastPeriod = this.maxBy(periods, (period: ProgramSegment) => {
      return getUnixTime(new Date(period.end_date));
    });
    return filtered.length > 0 ? filtered : [lastPeriod];
  }

  maxBy(array: any, callback: any): ProgramSegment {
    const max = Math.max(...array.map(callback));
    return array.find((item) => callback(item) === max);
  }

  programShareLongform(share: ProgramShares[]): ProgramShares {
    return share.find((ps) => ps.product_format_id === this.longformId);
  }

  programShareShortform(share: ProgramShares[]): ProgramShares {
    return share.find((ps) => ps.product_format_id === this.shortformId);
  }

  private updateCategoryName(updatedProgramFormat: Program): void {
    const programFormat = this.programFormats.find((format) => format.id === updatedProgramFormat.id);
    programFormat.category_name = updatedProgramFormat.category_name;
  }
  private deepCopyProgramFormats(programFormats: Program[]): Program[] {
    return JSON.parse(JSON.stringify(programFormats));
  }
}
