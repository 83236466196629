<div class="context__message {{messageType}} shadow-z-1">
  <button class="pull-right" *ngIf="dismiss.observers.length" (click)="dismissMessage()"><span class="icon-close-x icon__medium"></span></button>
  <div class="context__message__container">
    <div class="icon-wrapper hidden-xs hidden-sm">
      <span *ngIf="messageType === 'error'" class="icon-warning-outline {{messageType}}"></span>
      <span *ngIf="messageType === 'warning'" class="icon-warning-outline {{messageType}}"></span>
      <span *ngIf="messageType === 'success'" class="icon-check-filled {{messageType}}"></span>
      <span *ngIf="messageType === 'info'" class="icon-info-outline {{messageType}}"></span>
    </div>
    <div class="content-wrapper">
      <p *ngFor="let line of messageLines">{{line}}</p>
      <ng-content></ng-content>
    </div>
  </div>
</div>
