<table class="table">
  <thead>
    <tr>
      <th>År</th>
      <th>Månad</th>
      <th>Adserver</th>
      <th>MMS</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let report of reports">
      <td>{{report.year}}</td>
      <td>{{convertMonthIndex(report.month - 1).full}}</td>
      <td>{{report.average_adserver_frequency | dashAsDefault}}</td>
      <td>{{report.average_mms_frequency | dashAsDefault}}</td>
    </tr>
  </tbody>
</table>
