import { Component, Input, OnChanges } from '@angular/core';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { SimpleChanges } from '../../../utils/types';
import { GoalAdditionalImpressionsFormData } from '../../../woo_components.module/goal_additional_impressions_form/goal_additional_impressions_form/goal-additional-impressions-form.component';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { Goal, wooId } from '../../../woo_services.module/shared-types';

const template = /* html */ `
<div
  class="col-xs-12 col-md-3"
>
  <goal-additional-impressions-form
    [goal]="goal"
    [disabled]="!editGoals"
    [adserver]="adserver"
    (onValidityChanges)="onGoalFormValidityChange($event)"
    (onValueChanges)="onGoalFormChange($event)"
  ></goal-additional-impressions-form>
</div>
`;

@Component({
  selector: 'edit-goal-form',
  template: template,
})
export class EditGoalForm extends SubscriptionHandler implements OnChanges {
  @Input() goalId: wooId;
  @Input() targetingId: wooId;

  goal: Goal;
  editGoals = false;
  adserver = '';

  constructor(private dashboardStore: DashboardStore) {
    super();
    this.addSubscription(this.dashboardStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(changes: SimpleChanges<EditGoalForm>): void {
    if (changes.targetingId || changes.goalId) {
      this.initFromStore(this.dashboardStore.state);
    }
  }

  initFromStore = (model: DashboardModel): void => {
    this.editGoals = model.editGoals;

    const targeting = this.dashboardStore.state.activeCampaign.targetings.find((t) => t.id === this.targetingId);
    if (!targeting) {
      return;
    }

    this.goal = targeting.goals.find((g) => g.id === this.goalId);
    this.adserver = this.dashboardStore.state.activeCampaign?.ad_server;
  };

  onGoalFormChange(goalFormData: GoalAdditionalImpressionsFormData): void {
    const mergedGoalFormValues = {
      ...this.dashboardStore.state.modifiedGoals?.goalForms[this.targetingId][this.goalId].values,
      ...goalFormData,
    };
    this.dashboardStore.setModifiedGoalsGoalFormValues(this.targetingId, this.goalId, mergedGoalFormValues);
  }

  onGoalFormValidityChange(isValid: boolean): void {
    this.dashboardStore.setModifiedGoalsGoalFormValidity(this.targetingId, this.goalId, isValid);
  }
}
