import { Component } from '@angular/core';
import { AuthService } from '../../../woo_services.module/AuthService';
import { User } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'my-account',
  templateUrl: './my-account.component.html',
})
export class MyAccount {
  show = {
    passwordForm: false,
  };

  currentUser: User = this.authService.getUser();

  constructor(private authService: AuthService) {}

  logout(): void {
    this.authService.logout();
  }
}
