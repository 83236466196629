<div class="tabbable__panel rounded-top-right">
    <h3 class="margin-left--3x">Här kan du redigera standard frekvenstak</h3>
  </div>
  
  <div>
    <div #stickyHeader>
    </div>
  
    <div class="tabbable__body shadow-z-1">
      <table class="table table-woo table--info table--hover table--align--middle" ngForm #frequencyLimits="ngForm">
        <thead #stickyTableHeader>
          <tr>
            <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
              {{header}}
            </th>
          </tr>
        </thead>
  
        <tbody>
          <ng-container *ngFor="let frequencyLimit of defaultFrequencyLimits; let index = index;">
          <tr>
            <td class="text--thin vertical-align--middle">
              <span>{{frequencyLimit.name}}</span>
            </td>
            <td class="vertical-align--middle">
              <span *ngIf="!isEditingRow(frequencyLimit)">{{frequencyLimit.impressions}}</span>
              <div
                *ngIf="isEditingRow(frequencyLimit)"
                class="input__container">
                <input
                  name="row-{{index}}"
                  [(ngModel)]="frequencyLimit.impressions"
                  [wooMinValidator]="1"
                  [disabled]="!frequencyLimit.editable">
              </div>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{translateTimeUnit(frequencyLimit.time_unit)}}</span>
            </td>
            <td class="padding-top-bottom--1x fixed-width-200">
              <div *ngIf="!isEditingRow(frequencyLimit)" class="align-cell-horizontal-center">
                  <button
                    [ngClass]="['status-badge', 'icon-margin-override', frequencyLimit.editable ? 'primary' : 'info']"
                    (click)="setEditingRow(frequencyLimit)"
                    [disabled]="!frequencyLimit.editable">
                    <span class="icon-edit icon__semi-medium "></span>
                    <div *ngIf="!frequencyLimit.editable" class="hover-text">{{frequencyLimit.name}} är ej redigerbar</div>
                </button>
              </div>
              <div *ngIf="isEditingRow(frequencyLimit)" class="align-cell-horizontal-center">
                <button class="status-badge success icon-margin-override" (click)="save(frequencyLimit)">
                  <span class="icon-check icon__semi-medium "></span>
                  <div class="hover-text">Spara</div>
                </button>
                <button class="status-badge info icon-margin-override" (click)="unsetEditingRow()">
                  <span class="icon-close-x icon__semi-medium "></span>
                  <div class="hover-text">Avbryt</div>
                </button>
              </div>
            </td>
          </tr>
          </ng-container>
        </tbody>
      </table>
  
      <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    </div>
  </div>
  