import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { SessionService } from '../../woo_services.module/SessionService';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private sessionService: SessionService) {}

  handleError(error: any): void {
    const userId = this.sessionService.getUser()?.id;
    if (userId) {
      Sentry.withScope((scope) => {
        scope.setUser({ id: userId });
      });
    }
    Sentry.captureException(error.originalError || error.error || error);
    throw error;
  }
}
