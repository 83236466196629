import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { validateRequired, validateTargetGroupSelect } from '../../../../utils/validators';
import { TargetGroupSelected } from '../../../../woo_components.module/inputs/target-group-select/target-group-select.component';
import { CategoryService } from '../../../../woo_services.module/CategoryService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { AgeTargetGroup, Category, GenderTargetGroup } from '../../../../woo_services.module/shared-types';
import { TargetGroupService } from '../../../../woo_services.module/TargetGroupService';
import {
  TargetingMappingParams,
  TargetingMappingService,
  TargetingMappingType,
} from '../../../../woo_services.module/TargetingMappingService';

@Component({
  selector: 'new-ttv-targeting-mapping',
  templateUrl: './new-ttv-targeting-mapping.component.html',
})
export class NewTtvTargetingMapping {
  @Output() onSubmit = new EventEmitter<void>();
  @Output() onAbort = new EventEmitter<void>();

  externalIds: string[] = [];
  categories: Category[] = [];
  ages: AgeTargetGroup[] = [];
  genders: GenderTargetGroup[] = [];
  targetGroups = {};
  targetingType = TargetingMappingType;
  targetingTypes = Object.values(TargetingMappingType);
  today = new Date().toISOString().split('T')[0];

  form: FormGroup<FormModel> = this.fb.group<FormModel>({
    name: new FormControl(null, validateRequired()),
    externalId: new FormControl(null, validateRequired()),
    factor: new FormControl(null, validateRequired()),
    description: new FormControl(null),
    targetingType: new FormControl(null, validateRequired()),
    categories: new FormControl(null),
    targetGroups: new FormControl(null),
    startDate: new FormControl(null, validateRequired()),
  });

  constructor(
    private fb: FormBuilder,
    private ttvService: TargetingMappingService,
    private routingService: RoutingService,
    categoryService: CategoryService,
    targetGroupService: TargetGroupService,
  ) {
    ttvService.getExternalIds().then((ids) => {
      this.externalIds = ids;
    });
    categoryService.getCategories().then((categories) => {
      this.categories = categories;
    });
    targetGroupService.getGendersAndAges().then(([ages, genders]) => {
      this.ages = ages;
      this.genders = genders;
    });
    this.initTargetingTypeDependentValidation();
  }

  isTargeting = (targetingType: TargetingMappingType): boolean => {
    return this.form.get('targetingType').value === targetingType;
  };

  initTargetingTypeDependentValidation(): void {
    const categories = this.form.get('categories');
    const targetGroups = this.form.get('targetGroups');
    this.form.get('targetingType').valueChanges.subscribe((targetingType) => {
      if (targetingType === TargetingMappingType.category) {
        categories.setValidators([validateRequired()]);
        targetGroups.setValidators(null);
        targetGroups.setValue(null);
      } else if (targetingType === TargetingMappingType.targetGroup) {
        targetGroups.setValidators([validateTargetGroupSelect()]);
        categories.setValidators(null);
        categories.setValue(null);
      } else {
        categories.setValidators(null);
        targetGroups.setValidators(null);
        targetGroups.setValue(null);
        categories.setValue(null);
      }
      categories.updateValueAndValidity();
      targetGroups.updateValueAndValidity();
    });
  }

  submit = async (): Promise<void> => {
    const value = this.form.value;
    const newTargetingMapping: TargetingMappingParams = {
      name: value.name,
      ttv_target_group: value.externalId,
      factor: value.factor,
      description: value.description,
      start_date: value.startDate,
      category_ids: value.categories,
      age_target_group_ids: value.targetGroups ? value.targetGroups.ages.map((tg) => tg.id) : null,
      gender_target_group_ids: value.targetGroups ? value.targetGroups.genders.map((tg) => tg.id) : null,
    };
    await this.ttvService.createTargetingMapping(newTargetingMapping);
    try {
      this.onSubmit.emit();
    } catch (response) {
      console.error(response.error.message);
    }
  };
}

interface FormModel {
  name: FormControl<string>;
  externalId: FormControl<string>;
  factor: FormControl<string>;
  description: FormControl<string>;
  targetingType: FormControl<string>;
  categories: FormControl<string[]>;
  targetGroups: FormControl<TargetGroupSelected>;
  startDate: FormControl<string>;
}
