import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { TableRows } from '../../../../woo_components.module/woo_tables/woo-table.component';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { FormatterService } from '../../../../woo_services.module/FormatterService';
import { UniverseService, UniverseSummary } from '../../../../woo_services.module/UniverseService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-universe',
  templateUrl: './manage-universe.component.html',
})
export class ManageUniverse {
  readonly acceptedFileTypes =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  showUploadForm = false;
  universeTableData: TableRows = [];
  newUniverseFile = null as File;

  readonly tableHeaders = ['Åldersspann', 'Kvinnor', 'Män', 'Alla'];
  private fileName: string;

  constructor(
    private dialogService: DialogService,
    private formatterService: FormatterService,
    private universeService: UniverseService,
    private utilityService: ProductsPageUtilityService,
  ) {
    this.loadUniverses();
  }

  get uploadDisabled(): boolean {
    return !this.newUniverseFile;
  }

  get universeData(): TableRows {
    return this.universeTableData;
  }

  cancel(): void {
    this.showUploadForm = false;
    this.fileName = null;
    this.newUniverseFile = null;
  }

  sendFile(): void {
    this.dialogService.openBlocking('Laddar upp nya universum...');
    this.universeService
      .uploadFile(this.newUniverseFile)
      .then(() => {
        this.loadUniverses();
        this.showUploadForm = false;
      })
      .catch((err) => {
        this.dialogService.openError(this.errorMsg(err.error_message));
      })
      .finally(() => this.dialogService.closeBlocking());
  }

  setFile(file: File): void {
    if (file && this.acceptedFileTypes.includes(file.type)) {
      this.newUniverseFile = file;
    } else {
      this.newUniverseFile = null as File;
    }
    this.fileName = file.name;
  }

  uploadFileLabel(): string {
    return !this.fileName ? 'Välj fil att ladda upp' : this.fileName;
  }

  private loadUniverses() {
    this.universeService
      .getUniverses()
      .then((universes) => {
        this.universeTableData = this.buildUniverseRows(universes);
        scrollTo(this.parentHeader);
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      })
      .catch(() => this.dialogService.openError('Gick inte att importera Universumtalen'));
  }

  private buildUniverseRows(universes: UniverseSummary[]): TableRows {
    return universes.map((universe: UniverseSummary) => {
      return [
        universe.age_span,
        this.formatterService.transformNumber(universe.woman_universe),
        this.formatterService.transformNumber(universe.man_universe),
        this.formatterService.transformNumber(universe.all_universe),
      ];
    });
  }

  private errorMsg(error: string) {
    const translations = {
      missing_header_index_target: 'Filen saknar kolumnen "Target" som ska innehålla målgruppen',
      missing_header_index_unisize: 'Filen saknar kolumnen "Target" som ska innehålla målgruppens universums storlek.',
      un_expected_universe_size: 'Alla universum borde vara större än 0',
      un_expected_age_span_length: 'Alla åldersspannen borde vara 5 år',
      unable_to_parse_target_group: 'Det gick inte att läsa ut alla åldersgrupper',
      missing_age_group_women: 'Det saknas åldersgrupp i filen för kvinnor. Förväntas [00-04 .. 95-99]',
      missing_age_group_men: 'Det saknas åldersgrupp i filen för män. Förväntas [00-04 .. 95-99]',
      invalid_universe_cant_save: 'Någon av de nya universumen bryter mot reglerna. Inga nya universum är inlästa.',
      missing_row: 'Det saknas rader i filen',
    };

    return translations[error] !== undefined ? translations[error] : error;
  }
}
