import { formatCurrency, formatPercent } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SystemService } from '../../woo_services.module/SystemService';

@Pipe({
  name: 'wooCurrency',
})
export class WooCurrency implements PipeTransform {
  constructor(private systemService: SystemService) {}
  transform(value: any, wooCurrency: WooCurrencyValue, maxDecimals = 0): any {
    if (wooCurrency === WooCurrencyValue.Budget) {
      return formatCurrency(value, 'sv', 'kr', 'SEK', `1.0-${maxDecimals}`);
    } else if (wooCurrency === WooCurrencyValue.Percent) {
      return formatPercent(value, 'sv', `1.0-${maxDecimals}`);
    } else if (wooCurrency === WooCurrencyValue.Quota) {
      return formatPercent(value / 100, 'sv', `1.0-${maxDecimals}`);
    } else if (wooCurrency === WooCurrencyValue.Impressions) {
      return formatCurrency(value, 'sv', 'impressions', 'SEK', '1.0-0');
    } else if (wooCurrency === WooCurrencyValue.GrossRatingViews) {
      return formatCurrency(value, 'sv', 'bruttokontakter', 'SEK', '1.0-0');
    } else {
      return formatCurrency(value, 'sv', 'completed views', 'SEK', '1.0-0');
    }
  }
}

export enum WooCurrencyValue {
  Budget = 'budget',
  GrossRatingViews = 'grossRatingViews',
  CompletedViews = 'completedViews',
  Impressions = 'impressions',
  Percent = 'percent',
  Quota = 'quota',
}
