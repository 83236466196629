import { Component, Input } from '@angular/core';

const template = `
  <div class="text-center">
  	<div class="progress woo-progress">
  	  <div class="progress-bar" [ngStyle]="{'width': progress + '%'}"></div>
    </div>
    <h3>{{progress}}%</h3>
  </div>
`;

@Component({
  selector: 'progress-bar',
  template: template,
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBar {
  @Input() public progress = 0;
}
