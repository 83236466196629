<div class="col-xs-2 block">
  <woo-dropdown
    labelText="Startår"
    name="startWeekYear"
    [(ngModel)]="weeks.startWeekYear"
    (ngModelChange)="modelChanged('startWeekYear')"
    [items]="years"
    [disabled]="disabled"
    ></woo-dropdown>
</div>
<div class="col-xs-2 block">
  <div class="input__container">
    <label for="startWeek">Startvecka</label>
    <input id="startWeek" name="startWeekNumber" type="number" format="number"
      [(ngModel)]="weeks.startWeekNumber" label min="1" max="{{weeksInSpecifikYear(weeks.startWeekYear)}}"
      (ngModelChange)="modelChanged('startWeekNumber')"
      (focusout)="modelChanged('startWeekNumber')"
      [disabled]="disabled" required>
  </div>
</div>
<div class="col-xs-2 block">
  <woo-dropdown
    labelText="Slutår"
    name="endWeekYear"
    [(ngModel)]="weeks.endWeekYear"
    (ngModelChange)="modelChanged('endWeekYear')"
    [items]="years"
    [disabled]="disabled"
    ></woo-dropdown>
</div>
<div class="col-xs-2 block">
  <div class="input__container">
    <label for="endWeek">Slutvecka</label>
    <input id="endWeek" name="endWeekNumber" type="number" format="number"
      [(ngModel)]="weeks.endWeekNumber" min="1" max="{{weeksInSpecifikYear(weeks.endWeekYear)}}"
      (ngModelChange)="modelChanged('endWeekNumber')"
      (focusout)="modelChanged('endWeekNumber')"
      [disabled]="disabled" required>
  </div>
</div>

