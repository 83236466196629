import { Component, Input, OnChanges } from '@angular/core';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { BookingModel, BookingStore } from '../../../advanced_campaign_booking.module/stores/BookingStore';
import { removeDuplicates } from '../../../utils/array';
import { valueAccessorProvider } from '../../../utils/provider-builders';
import { generateId } from '../../../utils/string';
import { AuthService } from '../../../woo_services.module/AuthService';
import { Region, TargetingRegion, wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'region-select',
  templateUrl: './region-select.component.html',
  styleUrls: ['./region-select.component.scss'],
  providers: [valueAccessorProvider(RegionSelect)],
})
export class RegionSelect extends SubscriptionHandler implements OnChanges {
  readonly instanceId = generateId();
  readonly isAdminOrPlanner = this.authService.adminOrPlanner();

  @Input() regions: Region[] = [];
  @Input() targetingId: wooId;

  selectedRegions = {};
  groupToRegionsMap = {};
  regionsToGroupMap = {};
  regionNameToGroupNameMap = {};

  includesLinear = false;

  allAvailableRegions: TargetingRegion[] = [];
  regionList: TargetingRegion[] = [];
  regionListWithGroups: TargetingRegion[] = [];

  constructor(private bookingStore: BookingStore, private authService: AuthService) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
    this.createRegionAndGroupMaps();
    this.allAvailableRegions = this.createListOfRegions();
    this.regionList = this.allAvailableRegions.filter((reg) => !this.groupToRegionsMap[reg.region_id]);
    this.regionListWithGroups = this.allAvailableRegions
      .filter((reg) => !this.regionsToGroupMap[reg.region_id])
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    this.regions.forEach((region) => {
      this.regionNameToGroupNameMap[region.name] = region.region_group ? region.region_group.name : region.name;
    });
  }

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.selectedRegions = {};
      this.includesLinear = targeting.includes_linear;
      const selectedIds = targeting.regions.map((reg) => reg.region_id);
      targeting.regions.forEach((region) => (this.selectedRegions[region.name] = true));

      if (this.shouldConvertRegionsToGroup(selectedIds)) {
        const regions = targeting.regions.map((region) =>
          this.regionsToGroupMap[region.region_id] ? this.regionsToGroupMap[region.region_id] : region,
        );
        this.sendToStore(removeDuplicates(regions, (region) => region.region_id));
      } else if (this.shouldConvertGroupsToRegions(selectedIds)) {
        const regions = targeting.regions
          .map((region) =>
            this.groupToRegionsMap[region.region_id] ? this.groupToRegionsMap[region.region_id] : region,
          )
          .flatMap((reg) => reg);
        this.sendToStore(removeDuplicates(regions, (region) => region.region_id));
      }
    }
  };

  change(): void {
    const selected = this.allAvailableRegions.filter((region) => this.selectedRegions[region.name]);
    this.bookingStore.setRegions(selected, this.targetingId);
  }

  get ShowRegionGroups(): boolean {
    return this.includesLinear && this.isAdminOrPlanner;
  }

  private sendToStore(regions: TargetingRegion[]): void {
    this.bookingStore.setRegions(regions, this.targetingId);
  }

  private createRegionAndGroupMaps(): void {
    const regionGroups = this.regions.filter((reg) => !!reg.region_group);
    this.groupToRegionsMap = this.groupTargetingRegionsById(regionGroups);
    regionGroups.forEach(
      (reg) => (this.regionsToGroupMap[reg.id] = { name: reg.region_group.name, region_id: reg.region_group.id }),
    );
  }

  private groupTargetingRegionsById(objectArray) {
    return objectArray.reduce((acc, obj) => {
      const key = obj['region_group'].id;
      const curGroup = acc[key] ?? [];
      const targetingRegion = { region_id: obj.id, name: obj.name };

      return { ...acc, [key]: [...curGroup, targetingRegion] };
    }, {});
  }

  private shouldConvertRegionsToGroup(selectedIds: string[]): boolean {
    return this.ShowRegionGroups && selectedIds.some((id) => Object.keys(this.regionsToGroupMap).includes(id));
  }

  private shouldConvertGroupsToRegions(selectedIds: string[]): boolean {
    return !this.ShowRegionGroups && selectedIds.some((id) => Object.keys(this.groupToRegionsMap).includes(id));
  }

  private createListOfRegions(): TargetingRegion[] {
    return this.regions.reduce((prev, current) => {
      const region = {
        region_id: current.id,
        name: current.name,
      };
      if (current.region_group && !prev.find((obj) => obj.region_id === current.region_group.id)) {
        const group = {
          region_id: current.region_group.id,
          name: current.region_group.name,
        };
        return [...prev, region, group];
      }
      return [...prev, region];
    }, []);
  }
}
