import { Component, Input } from '@angular/core';
import { palePink, spacing1x } from '../../utils/css-constants';

const template = /* html */ `
<div class="display--flex align-items--center justify-content--space-between padding-top-bottom--none padding-left-right--1x" [ngClass]="{'marked': marked}">
  <h4 class="text-align--left capitalize--first-letter"
    [ngClass]="{'margin--none': !margin, 'text--medium': bothBold}"
  >
    {{left}}
  </h4>
  <h4
    class="text-align--right text--medium capitalize--first-letter padding-left--1x"
    [ngClass]="{'margin--none': !margin}"
  >
    {{right}}
  </h4>
  <div *ngIf="showLine" class="divider medium"></div>
</div>
`;

@Component({
  selector: 'two-col-row',
  template: template,
  styles: [
    'h4 {margin-top: 4px; margin-bottom: 4px;}',
    `
      .marked {
        background-color: ${palePink};
        border-radius: ${spacing1x};
      }
    `,
  ],
})
export class TwoColRow {
  @Input() left: string;
  @Input() right: string;
  @Input() showLine = false;
  @Input() margin = true;
  @Input() bothBold = false;
  @Input() marked = false;
}
