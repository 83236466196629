  <div class="row">
    <div class="col-xs-12">
        <div *ngIf="newProgramFormat" class="ng-scope">
          <form [formGroup]="form" (ngSubmit)="addProgramFormat(newProgramFormatObj)">
            <div class="input__container">
              <label for="name">Namn</label>
              <input name="name" type="text" formControlName="name">
            </div>
            <woo-dropdown
              [items]="publishers"
              formControlName="publisher_id"
              name="selectedPublisher"
              bindLabel="name"
              bindValue="id"
              inputId="publisher-search"
              labelText="Publicist *"
              [searchAttributes]="['name']"
              notFoundText="Inga publicister hittades."
              placeholder="Välj..."
          ></woo-dropdown>
            <div class="input__container">
              <label for="mrmId">MRM ID</label>
              <input name="mrmId" type="text" formControlName="mrm_id">
            </div>
            <woo-dropdown
              [items]="categories"
              formControlName="category_id"
              name="selectedCategory"
              bindLabel="name"
              bindValue="id"
              inputId="category-search"
              labelText="Kategori"
              [searchAttributes]="['name']"
              notFoundText="Inga kategorier hittades."
              placeholder="Välj..."
            ></woo-dropdown>
            <p>Fördelning Preroll</p>
            <div class="row">
              <div class="input__container col-xs-2">
                <label for="input-longform">Adresserbar TV</label>
                <input
                name="input-longform"
                type="text"
                formControlName="longform"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                (change)="autofillShortform(form.get('longform').value)">
              </div>
              <div class="input__container col-xs-2">
                <label for="input-shortform">Online Video</label>
                <input
                name="input-shortform"
                type="text"
                formControlName="shortform"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                (change)="autofillLongform(form.get('shortform').value)">
              </div>
            </div>
            <div class="margin-top--3x">
              <button type="submit" class="button" [disabled]="!form.valid">Skapa</button>
              <button type="button" class="link" (click)="closeCard()">Avbryt</button>
            </div>
          </form>
        </div>
      </div>
    </div>
