<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <div class="input__container">
        <label for="name">Namn</label>
        <input type="text" id="name" formControlName="name">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <div class="select__container">
        <label for="external-id">Externt ID</label>
        <select formControlName="externalId" id="external-id">
          <option value=""></option>
          <option *ngFor="let item of externalIds" [value]="item">
            {{item}}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-sm-3">
      <div class="input__container">
        <label for="factor">Faktor</label>
        <input type="number" id="factor" formControlName="factor">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <div class="input__container">
        <span class="input__optional__text">Frivillig</span>
        <label for="description">Kommentar (faktorberäkning)</label>
        <textarea type="text" id="description" formControlName="description"></textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <div class="select__container">
        <label for="targeting-type">Styrning</label>
        <select formControlName="targetingType" id="targeting-type">
          <option value=""></option>
          <option *ngFor="let item of targetingTypes" [value]="item">
            {{item}}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isTargeting(targetingType.category)">
    <div class="col-xs-12 col-sm-6">
      <woo-dropdown
        [items]="categories"
        formControlName="categories"
        bindLabel="name"
        bindValue="id"
        inputId="category-search"
        labelText="Kategorier"
        [searchAttributes]="['name']"
        [multiple]="true"
      ></woo-dropdown>
    </div>
  </div>

  <div class="row" *ngIf="isTargeting(targetingType.targetGroup)">
    <div class="col-xs-12 col-sm-6">
      <target-group-select
        [genders]="genders"
        [ages]="ages"
        formControlName="targetGroups"
      >
      </target-group-select>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-sm-3">
      <div class="input__container">
        <label for="start-date">Startdatum</label>
        <input id="start-date" name="start-date" type="date" [min]="today" formControlName="startDate">
      </div>
    </div>
  </div>

  <div class="padding-top--2x">
    <button class="button primary" [disabled]="!form.valid">Spara</button>
    <button class="link" type="button" (click)="onAbort.emit()">Avbryt</button>
  </div>
</form>
