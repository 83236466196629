<h2>{{customerName}}</h2>

<div class="margin-bottom--3x">
  <button class="button" ng-class="{'secondary' : show.uploadVideo, 'primary-1' : !show.uploadVideo}" type="button" (click)="toggleUploadVideo()"><span class="icon-movies"></span> Ny film</button>
  <button class="button" ng-class="{'secondary' : show.uploadImage, 'primary-2' : !show.uploadImage}" type="button" (click)="toggleUploadImage()" *ngIf="allowUploadImage"><span class="icon-TV4_014_placeholder"></span> Ny bild</button>
</div>

<div class="width--50">
  <video-upload [ngClass]="{'display--none': !show.uploadVideo}" (onUploadProgress)="uploadProgress($event)" [customerId]="customerId"></video-upload>
  <image-upload [ngClass]="{'display--none': !show.uploadImage}" [customerId]="customerId"></image-upload>
</div>

<dot-loader [visibility]="show.loader" [large]="true"></dot-loader>

<creative-asset-list *ngIf="!show.loader" [assets]="assets" (onDelete)="delete($event)"></creative-asset-list>
