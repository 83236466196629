<div class="input__container">
  <label for="user-filter">Sök</label>
  <input id="user-filter" type="text" [(ngModel)]="searchString" (ngModelChange)="filter()" >
</div>
<table class="table table--woo table--small table--hover">
  <thead>
    <tr>
      <td>Namn</td>
      <td>E-postadress</td>
      <td>Roll</td>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let user of filteredActiveUsers"
      class="clickable-link"
      [routerLink]="['/user', user.id]"
      [hidden]="user.hidden"
      >
      <td>
        <a>
          {{user.name}}
        </a>
      </td>
      <td>
        <a>
          {{user.email}}
        </a>
      </td>
      <td>
        <span class="tab tab--small" *ngFor="let role of user.roles">
          {{role}}
        </span>
      </td>
    </tr>

    <tr *ngFor="let user of filteredInactiveUsers"
        class="clickable-link danger"
        [routerLink]="['/user', user.id]"
        [hidden]="user.hidden"
        >
        <td>
          <a>
            {{user.name}}
          </a>
        </td>
        <td>
          <a>
            {{user.email}}
          </a>
        </td>
        <td>
          <span class="tab tab--small visibility--hidden">borttagen</span>
        </td>
      </tr>
  </tbody>
</table>
<button
  class="button primary-1 margin-top--2x"
  (click)="showInactiveUsers = !showInactiveUsers; filter()"
  *ngIf="isAdmin"
>
  {{!showInactiveUsers ? 'Visa borttagna' : 'Dölj borttagna'}}
</button>
