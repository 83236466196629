import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { addDays } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { CashCampaignService } from '../../../cash_dashboard.module/services/CashCampaignService';
import { verticalExpansion } from '../../../utils/animations';
import { allValuesFalse, slice } from '../../../utils/object';
import { DebounceService } from '../../../woo_services.module/DebounceService';
import { SystemService } from '../../../woo_services.module/SystemService';
import { StatusCollectionService } from '../../services/status-collection.service';

const ANIMATION_TIMING = 400;
@Component({
  selector: 'simple-booking',
  templateUrl: './simple-booking.component.html',
  styleUrls: ['./simple-booking.component.scss'],
  animations: [verticalExpansion(ANIMATION_TIMING)],
})
export class SimpleBooking {
  static readonly ESTIMATE_IMPRESSIONS_DEBOUNCE_TIME = 1000;
  @ViewChild('footer', { static: true }) footer: ElementRef;
  editingCampaign = false;

  footerHeight = 0;

  unableToSave = {
    name: true,
    budget: true,
    period: true,
    targeting: true,
    destinationUrl: false,
  };
  unableToBook = {
    asset: true,
  };
  loading = {
    estimation: false,
  };
  campaignRequirements = {
    minDate: new Date(),
    minBudget: 1,
  };
  campaignName = '';
  campaignBudget = null;
  destinationUrl = null;
  estimatedCompletedViews = null;

  initialValues = {
    asset: null,
    period: null,
    targeting: null,
  };

  @Output() public nextStep = new EventEmitter<void>();
  @Output() public saveDraft = new EventEmitter<void>();

  get campaign(): any {
    return this.cashCampaignService.campaign;
  }
  get creative(): any {
    return this.cashCampaignService.campaign.creatives[0];
  }
  get targeting(): any {
    return this.cashCampaignService.campaign.targetings[0];
  }
  get creativeSegment(): any {
    return this.creative.segments[0];
  }

  constructor(
    private cashCampaignService: CashCampaignService,
    private cd: ChangeDetectorRef,
    private systemService: SystemService,
    private debounceService: DebounceService,
    public statusService: StatusCollectionService,
  ) {
    statusService.clear();
    this.setCampaignRequirementsMinDate().then();
  }

  /**
   * campaignLoaded initialize the booking with the current campaign in cashCampaignService.
   */
  public campaignLoaded = (): void => {
    this.editingCampaign = true;
    this.initialValues.asset = { url: this.creative.asset_url, name: this.creative.custom_name };
    this.nameChanged((this.campaignName = this.campaign.name));
    this.budgetChanged((this.campaignBudget = this.targeting.budget));
    this.initialValues.period = { start: this.targeting.start_date, end: this.targeting.end_date };
    this.initialValues.targeting = cloneDeep(this.targeting);
  };

  assetChanged = (data: Record<string, any>): void => {
    this.unableToBook.asset = data.error;
    this.creative.asset_url = data.asset.url;
    this.creative.custom_name = data.asset.name;
    this.tryEstimateCampaign();
  };

  periodChanged = (data: Record<string, any>): void => {
    this.unableToSave.period = data.error;

    this.targeting.start_date = data.start;
    this.targeting.end_date = data.end;

    this.creativeSegment.start_date = data.start;
    this.creativeSegment.end_date = data.end;
    this.tryEstimateCampaign();
  };

  nameChanged = (name: string): void => {
    this.unableToSave.name = name.length === 0;
    this.campaign.name = name;
    this.tryEstimateCampaign();
  };

  budgetChanged = (budget: number): void => {
    this.unableToSave.budget = budget < this.campaignRequirements.minBudget || !budget;
    this.cashCampaignService.current.budgetFields.budget = budget;
    this.tryEstimateCampaign();
  };

  destinationUrlChanged = (url: string): void => {
    this.unableToSave.destinationUrl = url && !url.startsWith('https://');
    this.creative.destination_url = url;
    this.tryEstimateCampaign();
  };

  targetingChange = (data: Record<string, any>): void => {
    this.unableToSave.targeting = data.error;
    Object.assign(this.targeting, data.targeting, slice(this.targeting, 'start_date', 'end_date'));
    this.tryEstimateCampaign();
  };

  isCampaignSavable = (): boolean => {
    return allValuesFalse(this.unableToSave);
  };

  isCampaignBookable = (): boolean => {
    return this.isCampaignSavable() && allValuesFalse(this.unableToBook) && !this.statusService.hasError();
  };

  tryEstimateCampaign = (): void => {
    if (this.isCampaignSavable()) {
      this.loading.estimation = true;
      this.debouncedEstimate();
    } else {
      this.loading.estimation = false;
    }
    this.cd.detectChanges();
  };

  setCampaignRequirementsMinDate = async (): Promise<void> => {
    const minDays = await this.systemService.getValue('cash_user_min_days_until_start');
    this.campaignRequirements.minDate = addDays(new Date(), minDays);
  };
  private debouncedEstimate = this.debounceService.debounce(async () => {
    if (!this.isCampaignSavable()) {
      return;
    }
    try {
      const campaignEstimation = await this.cashCampaignService.estimate(this.cashCampaignService.campaign);
      this.estimatedCompletedViews = campaignEstimation.net_impressions;
      this.statusService.clear();
      this.statusService.addStatus(campaignEstimation.status);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading.estimation = false;
    }
  }, SimpleBooking.ESTIMATE_IMPRESSIONS_DEBOUNCE_TIME);
}
