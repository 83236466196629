import { SimpleChange } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

export type SimpleChanges<T> = { [P in keyof T]?: SimpleChange };

export type ToFormGroup<T> = {
  [P in keyof T]: ToFormControl<T[P]>;
};

export type ToFormControl<T> = T extends Array<infer ArrayType>
  ? FormArray<ToFormControl<ArrayType>>
  : T extends object
  ? FormGroup<ToFormGroup<T>>
  : FormControl<T | null>;

export type LayoutProfile = typeof Editable | typeof Hidden | typeof Visible;

export const Editable = Object.freeze({ visible: true, disabled: false });
export const Visible = Object.freeze({ visible: true, disabled: true });
export const Hidden = Object.freeze({ visible: false, disabled: true });
