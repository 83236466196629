import { Component, Input, OnChanges } from '@angular/core';
import { matchString } from '../../../utils/string';
import { SimpleChanges } from '../../../utils/types';

@Component({
  selector: 'name-list',
  templateUrl: './name-list.component.html',
})
export class NameList implements OnChanges {
  @Input() objects: NamedObject[] = [];
  @Input() inactiveObjects: NamedObject[] = [];
  @Input() showNicknames = false;
  @Input() routePath: string;

  searchString = '';
  filteredObjects = [] as ListNamedObject[];
  filteredInactiveObjects = [] as ListNamedObject[];
  showInactiveObjects = false;

  ngOnChanges(changes: SimpleChanges<NameList>): void {
    if (changes.objects && this.objects) {
      this.filteredObjects = this.objects.map(this.createListNamedObject);
      this.filter();
    }

    if (changes.inactiveObjects && this.inactiveObjects) {
      this.filteredInactiveObjects = this.inactiveObjects.map(this.createListNamedObject);
      this.filter();
    }
  }

  /**
   * In order to increase performance we do not filter the lists which would
   *   case element to be added/removed from the DOM. Instead we just set a
   *   flag in the element.
   */
  filter = (): void => {
    const searchFilter = (object: ListNamedObject) => {
      return !this.searchString || matchString(object.search, this.searchString.toLowerCase());
    };
    this.filteredObjects.forEach((object) => (object.hidden = !searchFilter(object)));
    this.filteredInactiveObjects.forEach(
      (object) => (object.hidden = !this.showInactiveObjects || !searchFilter(object)),
    );
  };

  private createListNamedObject = (object: NamedObject): ListNamedObject => {
    return {
      id: object.id,
      name: object.name,
      nickname: object.nickname,
      hidden: false,
      search: `${object.name} ${object.nickname}`.toLowerCase(),
    };
  };
}

interface NamedObject {
  id: string;
  name: string;
  nickname?: string;
}

interface ListNamedObject {
  id: string;
  name: string;
  nickname?: string;
  hidden: boolean;
  search: string;
}
