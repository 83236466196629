import { Component } from '@angular/core';
import { HotkeyService } from '../../woo_services.module/HotkeyService';
import { WooDialogHelper } from './woo-dialog-helper';

const template = /* html */ `
<woo-dialog>
  <div class="display--flex margin-bottom--2x">
    <div class="icon-wrapper text-danger">
      <span class="icon-warning-outline icon__large"></span>
    </div>
    <h3 *ngIf="error" class="display--inline margin--none margin-left--2x">{{error}}</h3>
    <h3 *ngIf="!error" class="display--inline margin--none margin-left--2x">Hoppsan! Något gick fel.</h3>
  </div>
  <div *ngFor="let item of error_data | keyvalue">
    <p>{{item.key}}</p>
    <p *ngFor="let errorInfo of item.value">
      <span class="padding-left--1x">{{errorInfo}}</span>
    </p>
  </div>

  <button class="button primary-1 center display--block margin-top--2x" (click)="close()">Stäng</button>
</woo-dialog>
`;

@Component({
  selector: 'precondition-error-dialog',
  template: template,
})
export class PreconditionErrorDialog extends WooDialogHelper {
  error: string;
  error_data: object;

  constructor(private hotkeyService: HotkeyService) {
    super();
    this.addSubscription(this.hotkeyService.addShortcut({ keys: 'enter' }).subscribe(this.close));
  }

  show(error: string, error_data: object): void {
    this.error = this.translateKnownPreconditionError(error);
    this.error_data = error_data;
    this.open();
  }

  private translateKnownPreconditionError = (error: string): string => {
    const list = {
      invalid_program_period: 'Program perioder har ändrats sedan bokning. Följande perioder saknas.',
      missing_on_ad_server:
        'Följande känns inte igen av adservern. Det beror antingen på felaktigt externt id eller att den inte är skapad på adservern.',
      customer_missing_mrm_id: 'Kunde inte skicka kampanjen till MRM, kunden saknar MRM id.',
    };
    return list[error] !== undefined ? list[error] : error;
  };
}
