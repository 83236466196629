import { Directive, ElementRef, Input } from '@angular/core';
import { SimpleChanges } from '../../utils/types';

@Directive({
  selector: '[wooFocus]',
})
export class WooFocus {
  @Input('wooFocus') focus: boolean;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges<WooFocus>): void {
    if (changes.focus && this.focus !== false) {
      this.el.nativeElement.focus();
    }
  }
}
