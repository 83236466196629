import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ReplaySubject, combineLatest, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { setFormDirty } from '../../utils/form';
import { compareWith } from '../../utils/object';
import { SimpleChanges } from '../../utils/types';
import { UserRole } from '../../utils/user-roles';
import { ignore422 } from '../../utils/utils';
import { validateEmail, validateRequired } from '../../utils/validators';
import { AdOrganisationService } from '../../woo_services.module/AdOrganisationService';
import { AgencyService } from '../../woo_services.module/AgencyService';
import { AuthService } from '../../woo_services.module/AuthService';
import { CustomerService } from '../../woo_services.module/CustomerService';
import { DialogService } from '../../woo_services.module/DialogService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { UserService } from '../../woo_services.module/UserService';
import { AdOrganisation, CompactAgency, CompactCustomer, User } from '../../woo_services.module/shared-types';
import { AgencyRole } from './agency-role-select.component';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styles: ['.cover {left:0; right:0; top:0; bottom:0; background-color:rgba(222, 222, 222, 0.7); border-radius:10px}'],
})
export class UserForm implements OnInit, OnChanges {
  readonly setFormDirty = setFormDirty;
  readonly convertRoles = this.translationService.convertRoles;
  readonly UserRole = UserRole;
  readonly isExternalPlanner = this.authService.hasRole(UserRole.externalPlanner);
  readonly mayEditUserInfo = this.authService.hasAnyRole([UserRole.admin, UserRole.externalPlanner, UserRole.planner]);

  newUserFrom: FormGroup<NewUserFormModel> = this.fb.group<NewUserFormModel>({
    firstName: new FormControl({ value: '', disabled: !this.mayEditUserInfo }, validateRequired()),
    lastName: new FormControl({ value: '', disabled: !this.mayEditUserInfo }, validateRequired()),
    email: new FormControl({ value: '', disabled: !this.mayEditUserInfo }, [validateRequired(), validateEmail()]),
    organisation: new FormControl(null, this.validateAdOrganisation()),
    customer: new FormControl(null, this.requiredIfRoles(UserRole.client, UserRole.cashUser)),
    role: new FormControl(null, validateRequired()),
    agencyRoles: new FormControl([]),
  });

  availableRoles: UserRole[] = [];
  organisations: AdOrganisation[] = [];
  clientCustomers: CompactCustomer[] = [];
  cashCustomers: CompactCustomer[] = [];
  agencies: CompactAgency[] = [];
  loadingCompleted = false;

  @Input() user: User;
  @Output() afterSubmit = new EventEmitter<User>();
  @Output() onAbort = new EventEmitter<void>();

  private userSubject = new ReplaySubject<User>(1);

  constructor(
    private authService: AuthService,
    private translationService: TranslationService,
    private adOrganisationService: AdOrganisationService,
    private customerService: CustomerService,
    private agencyService: AgencyService,
    private userService: UserService,
    private dialogService: DialogService,
    private fb: FormBuilder,
  ) {
    this.role.valueChanges.subscribe(() => {
      this.newUserFrom.controls.customer.patchValue(null);
      this.newUserFrom.controls.organisation.patchValue(null);
      this.newUserFrom.controls.agencyRoles.patchValue([]);
    });
  }

  get role(): FormControl<UserRole> {
    return this.newUserFrom.controls.role;
  }

  get selectAdOrgData(): { organisations: AdOrganisation[] } {
    const role = this.role.value;
    if (role === UserRole.externalPlanner && !this.isExternalPlanner) {
      return { organisations: this.organisations.filter((org) => org.external) };
    } else if (role === UserRole.sales || role === UserRole.salesManager) {
      return { organisations: this.organisations };
    } else {
      return null;
    }
  }

  get selectCustomerData(): { description: string; customers: CompactCustomer[] } {
    const role = this.role && this.role.value;
    switch (role) {
      case UserRole.client:
        return { description: 'Direktkunder behöver knytas till kund.', customers: this.clientCustomers };
      case UserRole.cashUser:
        return { description: 'Cashkunder behöver knytas till kund.', customers: this.cashCustomers };
      default:
        return null;
    }
  }

  get selectAgency(): boolean {
    const role = this.role && this.role.value;
    return role === UserRole.agency;
  }

  get editing(): boolean {
    return Boolean(this.user);
  }

  get submitLabel(): string {
    return this.editing ? 'Uppdatera' : 'Skapa';
  }

  get allowToChangeRole(): boolean {
    return !this.editing || this.authService.hasRole(UserRole.admin);
  }

  get allowedToAssignAgencyRoles(): boolean {
    return this.authService.hasAnyRole([UserRole.admin, UserRole.planner]);
  }

  ngOnInit(): void {
    const user$ = this.userSubject.asObservable();

    if (this.isExternalPlanner) {
      this.availableRoles = [UserRole.externalPlanner];
      this.loadingCompleted = true;
      user$.subscribe(this.setFormData);
    } else {
      this.availableRoles = [
        UserRole.admin,
        UserRole.accounting,
        UserRole.agency,
        UserRole.client,
        UserRole.eos,
        UserRole.estimator,
        UserRole.externalPlanner,
        UserRole.planner,
        UserRole.sales,
        UserRole.salesManager,
        UserRole.dataScientist,
        UserRole.MeMaApiUser,
      ];

      const adOrgPromise = this.adOrganisationService.getAdOrganisations().then((organisations) => {
        this.organisations = organisations;
      });

      const clientPromise = this.customerService.getClientsForCurrentUser().then((clients) => {
        this.clientCustomers = clients.sort(compareWith('name'));
      });

      const cashPromise = this.customerService.getCashCustomersForCurrentUser().then((customers) => {
        this.cashCustomers = customers.sort(compareWith('name'));
      });

      const agencyPromise = this.agencyService.getAgencies().then((agencies) => {
        this.agencies = agencies.sort(compareWith('name'));
      });

      const everythingLoaded$ = forkJoin([adOrgPromise, clientPromise, cashPromise, agencyPromise]);
      everythingLoaded$.subscribe(() => (this.loadingCompleted = true));
      combineLatest(user$, everythingLoaded$)
        .pipe(map(([user, _]) => user))
        .subscribe(this.setFormData);
    }
  }

  ngOnChanges(changes: SimpleChanges<UserForm>): void {
    if (changes.user && this.user) {
      this.userSubject.next(this.user);
    }
  }

  async createUser(): Promise<void> {
    const formValue = this.newUserFrom.value;

    const confirmation =
      formValue.role !== UserRole.admin
        ? await Promise.resolve()
        : await this.dialogService.openConfirm({
            header: 'Ny systemadministratör',
            textBlocks: [
              'Du håller på att skapa en systemadministratör som kommer få rätt att gör allt som går att göra i WOO.',
              'Att ha för många administratörer är i allmänhet förknippat med säkerhetsrisker och bör undvikas.',
            ],
            confirmText: this.submitLabel,
            cancelText: 'Avbryt',
          });

    try {
      await confirmation;
      const serviceMethod = this.editing ? this.userService.updateUser : this.userService.createUser;
      const user = await serviceMethod(
        {
          id: this.editing ? this.user.id : undefined,
          last_name: formValue.lastName,
          first_name: formValue.firstName,
          email: formValue.email,
          customer_id: formValue.customer && formValue.customer.id,
        },
        formValue.role,
        formValue.role === UserRole.agency
          ? formValue.agencyRoles.map((agencyRole) => ({ role: agencyRole.role, agency_id: agencyRole.agency.id }))
          : [],
        formValue.organisation ? formValue.organisation.id : undefined,
      );

      this.afterSubmit.emit(user);
    } catch (err) {
      if (!err) {
        return;
      }
      ignore422(err);
    }
  }

  private requiredIfRoles(...roles: UserRole[]): ValidatorFn {
    const requiredValidator = validateRequired();
    return (c: AbstractControl) => {
      if (!this.newUserFrom) {
        return null;
      }

      return roles.includes(this.role.value) ? requiredValidator(c) : null;
    };
  }

  private validateAdOrganisation() {
    return this.isExternalPlanner
      ? this.requiredIfRoles(UserRole.sales, UserRole.salesManager)
      : this.requiredIfRoles(UserRole.sales, UserRole.salesManager, UserRole.externalPlanner);
  }

  private setFormData = (user: User) => {
    const customerRole = user.roles.find((r) => [UserRole.client, UserRole.cashUser].includes(r.name));
    const roles = this.getAgencyRoles(user.roles);
    this.newUserFrom.patchValue(
      {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        organisation: this.organisations.find((org) => user.ad_organisation && org.id === user.ad_organisation.id),
        customer: this.clientCustomers
          .concat(this.cashCustomers)
          .find((customer) => customerRole && customer.id === customerRole.resource_id),
        role: this.getRole(user.roles),
        agencyRoles: roles,
      },
      { emitEvent: false },
    );
  };

  private getRole(roles: User['roles']): UserRole {
    const firstRole = roles[0];
    if (!firstRole) {
      return null;
    } else if ([UserRole.agencyAdmin, UserRole.agencyUser, UserRole.agencyUserAtCustomer].includes(firstRole.name)) {
      return UserRole.agency;
    } else {
      return firstRole.name;
    }
  }

  private getAgencyRoles(roles: User['roles']): AgencyRole[] {
    return roles
      .filter((r) => [UserRole.agencyAdmin, UserRole.agencyUser].includes(r.name))
      .map((r) => {
        const agency = this.agencies.find((a) => a.id === r.resource_id);
        return agency ? { agency, role: r.name } : null;
      })
      .filter(Boolean);
  }
}

interface NewUserFormModel {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  organisation: FormControl<AdOrganisation>;
  customer: FormControl<CompactCustomer>;
  role: FormControl<UserRole>;
  agencyRoles: FormControl<AgencyRole[]>;
}
