import { KeyValue } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { compact, groupBy } from '../../../utils/array';
import {
  MAX_DATE,
  MIN_DATE,
  TimePeriod,
  WOO_DATE_PATTERN,
  add,
  beginningOfMonth,
  getDays,
  getWeekNumber,
  inPeriod,
  isDateAfter,
  isDateBefore,
  max,
  min,
  sameDate,
  subtract,
} from '../../../utils/date';
import { openAbove } from '../../../utils/dom';
import { STANDARD_FORMATS } from '../../../utils/format-constants';
import { valueAccessorProvider } from '../../../utils/provider-builders';
import { generateId } from '../../../utils/string';
import { SimpleChanges } from '../../../utils/types';
import { DatePeriod } from '../../../woo_services.module/shared-types';
import { Calender, Day, InitialDate, weekNumber } from '../shared/types';

@Component({
  selector: 'period-select',
  templateUrl: './period-select.component.html',
  styleUrls: ['../shared/date_selectors.scss'],
  providers: [valueAccessorProvider(PeriodSelect)],
})
export class PeriodSelect implements ControlValueAccessor, OnChanges {
  readonly instanceId = generateId();
  readonly DATE_PATTERN = WOO_DATE_PATTERN;
  readonly DATE_FORMAT = STANDARD_FORMATS.date;
  readonly Focus = Focus;

  @Input() defaultInitDate = InitialDate.FirstSelectable;
  @Input() disabled = false;
  @Input() firstSelectableDate: Date = new Date();
  @Input() lastSelectableDate: Date = MAX_DATE;
  @Input() forcedStartDate: Date = null;
  @Input() forcedEndDate: Date = null;
  @Input() uncrossableDateEnabled = false;
  @Input() uncrossableDate: Date = null;
  @ViewChild('element', { static: true }) elementRef: ElementRef;

  get firstSelectableEndDate(): Date {
    return max([this.firstSelectableDate, this.start || MIN_DATE]);
  }

  get lastSelectableEndDate(): Date {
    return max([this.lastSelectableDate || MAX_DATE]);
  }

  firstMonth: Calender;
  secondMonth: Calender;
  firstMonthDate: Date;
  uncrossableMaxDate: Date;

  focus: Focus;
  start: Date;
  end: Date;
  hoverDate: Date;

  show = {
    calender: false,
    calenderAbove: false,
    pristine: {
      start: true,
      end: true,
    },
  };

  private onClickOutsideDateSelect: () => void;

  get secondMonthDate(): Date {
    return add(this.firstMonthDate, 1, TimePeriod.Month);
  }

  constructor() {
    this.updateFirstMonthDate(new Date());
  }

  ngOnChanges(changes: SimpleChanges<PeriodSelect>): void {
    this.uncrossableMaxDate = subtract(this.uncrossableDate, 1);
    if (
      changes.firstSelectableDate &&
      this.firstSelectableDate &&
      !sameDate(changes.firstSelectableDate.currentValue, changes.firstSelectableDate.previousValue)
    ) {
      this.updateFirstMonthDate(this.firstSelectableDate);
    }
  }

  enableMaxDateForUncrossableDate(): boolean {
    return this.uncrossableDateEnabled && !!this.start && isDateBefore(this.start, this.uncrossableDate);
  }

  showCalender(withFocus: Focus): void {
    this.focus = withFocus;
    this.show.calenderAbove = openAbove(this.elementRef);
    this.show.calender = true;
    this.updateFirstMonthDate(this.getFirstMonthDate());

    if (!this.onClickOutsideDateSelect) {
      this.setCloseClickListener();
    }
  }

  hideCalender(): void {
    this.focus = null;
    this.hoverDate = null;
    this.show.calender = false;
    this.show.pristine.start = false;
    this.show.pristine.end = false;
  }

  selectDay(day: Day): void {
    if (!day.selectable) {
      return;
    }

    this.setFocusedDate(day.date);
    this.newSelectedDates();

    // When a date has been selected, mark that input as NOT pristine
    this.show.pristine.start = this.show.pristine.start && !Boolean(this.start);
    this.show.pristine.end = this.show.pristine.end && !Boolean(this.end);

    this.moveFocus();
    if (this.focus === null) {
      this.hideCalender();
    }

    if (this.enableMaxDateForUncrossableDate()) this.updateCalenders();
  }

  newSelectedDates(): void {
    this.propagateOnChange(this.getModelValue());
    this.updateMarked();
  }

  mouseoverDay(day: Day): void {
    this.hoverDate = day.date;
    this.updateMarked();
  }

  mouseleaveDay(day: Day): void {
    if (sameDate(this.hoverDate, day.date)) {
      this.hoverDate = null;
    }
    this.updateMarked();
  }

  isSelected(day: Day): boolean {
    return sameDate(day.date, this.start) || sameDate(day.date, this.end);
  }

  isChanging(day: Day): boolean {
    const focusedDate = this[this.focus] || null;
    return (
      this.isSelected(day) &&
      sameDate(day.date, focusedDate) &&
      !sameDate(this.start, this.end) &&
      Boolean(this.hoverDate) &&
      !sameDate(this.hoverDate, day.date)
    );
  }

  isFirstSelected(day: Day): boolean {
    return this.isFirstOrLastSelected(day.date, 'first');
  }

  isLastSelected(day: Day): boolean {
    return this.isFirstOrLastSelected(day.date, 'last');
  }

  nextMonth(): void {
    this.updateFirstMonthDate(add(this.firstMonthDate, 1, TimePeriod.Month));
  }

  prevMonth(): void {
    this.updateFirstMonthDate(subtract(this.firstMonthDate, 1, TimePeriod.Month));
  }

  clearDates(): void {
    this.writeValue({ start: null, end: null });
  }

  writeValue(model: DatePeriod): void {
    if (!model) {
      model = { start: null, end: null };
    }
    const newStart = model.start ? new Date(model.start) : null;
    const newEnd = model.end ? new Date(model.end) : null;

    const startDateChanged = !sameDate(this.start, newStart);

    this.start = newStart;
    this.end = newEnd;

    if (newStart && startDateChanged) {
      this.updateFirstMonthDate(newStart);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getModelValue(): DatePeriod {
    return { start: this.start, end: this.end };
  }

  isModelValueValid(model: DatePeriod): boolean {
    const error =
      (model.start && this.firstSelectableDate && isDateBefore(model.start, this.firstSelectableDate)) ||
      (model.end && this.firstSelectableDate && isDateBefore(model.end, this.firstSelectableDate)) ||
      (model.end && this.lastSelectableDate && isDateAfter(model.end, this.lastSelectableDate)) ||
      (model.end && model.start && isDateBefore(model.end, model.start));
    return !error;
  }

  touch(): void {
    this.propagateOnTouch();
  }

  setStartDateIfValid(date: Date): void {
    this.setIfValid(date, Focus.Start);
  }

  setEndDateIfValid(date: Date): void {
    this.setIfValid(date, Focus.End);
  }

  compareWeeksByStartDate(d1: KeyValue<weekNumber, Day[]>, d2: KeyValue<weekNumber, Day[]>): number {
    return d1.value[0].date.getTime() - d2.value[0].date.getTime();
  }

  private getFirstMonthDate(): Date {
    if (!this.start || !this.end) {
      if (this.defaultInitDate === InitialDate.Current) {
        return this.start || this.end || new Date();
      } else {
        return this.start || this.end || this.firstSelectableDate || new Date();
      }
    }

    if (
      this.focus === Focus.Start ||
      (this.focus === Focus.End && sameDate(beginningOfMonth(this.start), beginningOfMonth(this.end)))
    ) {
      return this.start;
    } else if (this.focus === Focus.End && isDateBefore(this.start, this.end)) {
      return subtract(this.end, 1, TimePeriod.Month);
    } else {
      return this.end;
    }
  }

  private setFocusedDate(date: Date) {
    this[this.focus] = date;
  }

  private moveFocus() {
    const mapping = {
      start: this.end ? null : 'end',
      end: null,
      null: null,
    };
    this.focus = mapping[this.focus];
  }

  private setIfValid(date: Date, prop: Focus) {
    const model = {
      ...this.getModelValue(),
      ...{ [prop]: date },
    };
    if (this.isModelValueValid(model)) {
      this[prop] = date;
      this.newSelectedDates();
    }
  }

  private setCloseClickListener() {
    this.onClickOutsideDateSelect = () => {
      this.hideCalender();
      this.removeCloseClickListener();
    };
    document.addEventListener('click', this.onClickOutsideDateSelect);
  }

  private removeCloseClickListener() {
    document.removeEventListener('click', this.onClickOutsideDateSelect);
    this.onClickOutsideDateSelect = null;
  }

  private isFirstOrLastSelected(date: Date, firstOrLast: 'first' | 'last'): boolean {
    if (!this.start && !this.end) {
      return false;
    }

    const selectedDates = this.hoverDate
      ? compact(this.getSelectedDates(this.hoverDate, this.focus))
      : compact([this.start, this.end]);

    if (selectedDates.length < 2) {
      return false;
    } else if (sameDate(selectedDates[0], selectedDates[1])) {
      return false;
    }

    const minOrMaxFn = firstOrLast === 'first' ? min : max;
    return sameDate(minOrMaxFn(selectedDates), date);
  }

  private updateFirstMonthDate(date: Date) {
    this.firstMonthDate = date;
    this.updateCalenders();
  }

  private updateCalenders() {
    this.firstMonth = this.createWeeksForMonth(this.firstMonthDate);
    this.secondMonth = this.createWeeksForMonth(this.secondMonthDate);
    this.updateMarked();
  }

  private createWeeksForMonth(yearAndMonth: Date): Calender {
    const startDate = new Date(yearAndMonth.getFullYear(), yearAndMonth.getMonth(), 1);
    const endDate = new Date(yearAndMonth.getFullYear(), yearAndMonth.getMonth() + 1, 0);
    const days = getDays(subtract(startDate, 6), add(endDate, 7));

    const startDateBeforeUncrossableDate = (d: Date) =>
      this.enableMaxDateForUncrossableDate() && isDateBefore(this.uncrossableDate, add(d, 1));
    const isDisabled = (d: Date) => startDate.getMonth() !== d.getMonth() || startDateBeforeUncrossableDate(d);
    const isSelectable = (d: Date) =>
      ((isDateBefore(this.firstSelectableDate, d) || sameDate(this.firstSelectableDate, d)) &&
        (isDateAfter(this.lastSelectableDate, d) || sameDate(this.lastSelectableDate, d))) ||
      startDateBeforeUncrossableDate(d);

    const weeks: Map<weekNumber, Day[]> = groupBy(
      days.map((date) => ({
        date,
        marked: false,
        disabled: isDisabled(date),
        selectable: isSelectable(date),
      })),
      (day) => getWeekNumber(day.date),
    );

    return Array.from(weeks.keys())
      .sort((l, r) => l - r)
      .reduce((map, key) => (weeks.get(key).length === 7 ? map.set(key, weeks.get(key)) : map), new Map());
  }

  private updateMarked() {
    const [date1, date2] = this.hoverDate ? this.getSelectedDates(this.hoverDate, this.focus) : [this.start, this.end];

    this.allDays().forEach((d) => {
      d.marked = date1 && date2 ? !sameDate(date1, date2) && inPeriod(date1, date2, d.date) : false;
    });
  }

  private getSelectedDates(newSelected: Date, focus: Focus): [Date, Date] {
    if (focus === Focus.Start && newSelected) {
      return [newSelected, this.end];
    } else if (focus === Focus.End && newSelected) {
      return [this.start, newSelected];
    }
    return [this.start, this.end];
  }

  private allDays(): Day[] {
    return [].concat(...Array.from(this.firstMonth.values()), ...Array.from(this.secondMonth.values()));
  }

  private propagateOnChange = (_model: DatePeriod) => null;
  private propagateOnTouch = () => null;
}

export enum Focus {
  Start = 'start',
  End = 'end',
}
