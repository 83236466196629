import { Component, Input } from '@angular/core';
import { AdServerStatus } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'dashboard-card-ad-server-status',
  templateUrl: './dashboard-card-ad-server-status.component.html',
})
export class DashboardCardAdServerStatus {
  readonly AdServerStatus = AdServerStatus;
  @Input() adServerStatus: AdServerStatus;
}
