import { Component, Input } from '@angular/core';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { Campaign } from '../../../../woo_services.module/shared-types';

const template = /* html */ `
  <text-button
    icon="TV4_065_visa"
    [disabled]="disabled"
    (click)="onClick()"
  >
    Granska
  </text-button>
`;

@Component({
  selector: 'review-campaign-button',
  template,
})
export class ReviewCampaignButton {
  @Input() campaign: Campaign;
  @Input() disabled = false;

  constructor(private routingService: RoutingService) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    this.goToReviewCampaign(this.campaign);
  };

  private goToReviewCampaign = (campaign: Campaign) => {
    this.routingService.navigate(['/campaigns', campaign.id, 'review']);
  };
}
