import { Component } from '@angular/core';
import { ignore422 } from '../../utils/utils';
import { MessageType } from '../../woo_components.module/feedback/context-message.component';
import { AuthService } from '../../woo_services.module/AuthService';
import { DialogService } from '../../woo_services.module/DialogService';
import { RoutingService } from '../../woo_services.module/RoutingService';
import { AdvancedCampaignService } from '../services/AdvancedCampaignService';
import { BookingModel, BookingStore, MessageKeyEnum } from '../stores/BookingStore';
import { SubscriptionHandler } from './subscription-handler';

const template = /* html */ `
  <button class="button secondary" (click)="save()" [disabled]="!canSave">{{buttonText}}</button>
`;

@Component({
  selector: 'save-button',
  template: template,
})
export class SaveButton extends SubscriptionHandler {
  ALLOWED_ERRORS = [MessageKeyEnum.RegionsNotPresent] as Array<MessageKeyEnum | string>;

  buttonText: string;
  canSave = false;

  constructor(
    private authService: AuthService,
    private bookingStore: BookingStore,
    private campaignService: AdvancedCampaignService,
    private routingService: RoutingService,
    private dialogService: DialogService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  initFromStore = (model: BookingModel): void => {
    this.buttonText = model.editingCampaign ? 'Uppdatera utkast' : 'Spara utkast';
    this.canSave =
      model.validationMessages
        .filter((msg) => !this.ALLOWED_ERRORS.includes(msg.key))
        .filter((msg) => msg.type === MessageType.error).length === 0 && this.authService.allowedToBook();
  };

  save = async (): Promise<void> => {
    const campaign = await this.campaignService.save(this.bookingStore.assembleCampaign());

    try {
      if (campaign.id) {
        this.dialogService.openSuccess('Din kampanj är nu sparad.');
        await this.routingService.goToDashboardWithCampaign(campaign.id);
      }
    } catch (err) {
      ignore422(err);
    }
  };
}
