import { Component, Input } from '@angular/core';
import { Campaign } from '../../woo_services.module/shared-types';

const template = /* html */ `
<div class="text-uppercase text--thin">
  <a class="pointer"
    target="_blank"
    [routerLink]="['/customer', data?.customer_id]"
  >{{data?.customer_name}}</a>
  <span *ngIf="data?.agency_id"> -
  <a class="pointer"
    target="_blank"
    [routerLink]="['/agency', data?.agency_id]"
  >{{data?.agency_nickname}}</a></span>
</div>
`;

@Component({
  selector: 'customer-agency-row',
  template: template,
  styles: ['a:hover {color: #ff3334;}'],
})
export class CustomerAgencyRow {
  @Input() data: CustomerAgencyRowData;
}

export type CustomerAgencyRowData = Pick<Campaign, 'customer_id' | 'customer_name' | 'agency_id' | 'agency_nickname'>;
