import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelfServiceCompensationService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getAgencyReports(): Promise<AgencySummary[]> {
    return lastValueFrom(this.http.get<AgencySummary[]>(`${this.env.apiUrl}/agencies/reports`));
  }

  getAgencyReportExport(id: wooId): Promise<Blob> {
    return lastValueFrom(
      this.http.get<Blob>(`${this.env.apiUrl}/agencies/reports/${id}`, {
        responseType: 'blob' as 'json',
      }),
    );
  }

  getSummaryReport(): Promise<Blob> {
    return lastValueFrom(
      this.http.get<Blob>(`${this.env.apiUrl}/agencies/report_summary`, {
        responseType: 'blob' as 'json',
      }),
    );
  }

  getCampaignListReport(): Promise<Blob> {
    return lastValueFrom(
      this.http.get<Blob>(`${this.env.apiUrl}/agencies/report_campaign_list`, {
        responseType: 'blob' as 'json',
      }),
    );
  }

  updateStatus(reportId: wooId, status: Status): Promise<SelfServiceCompensationReport> {
    return lastValueFrom(
      this.http.patch<SelfServiceCompensationReport>(
        `${this.env.apiUrl}/self_service_compensation_reports/update_status/${reportId}`,
        {
          status,
        },
      ),
    );
  }
}

export interface AgencySummary {
  name: string;
  alias: string;
  id: wooId;
  reports: SelfServiceCompensationReport[];
}

export interface SelfServiceCompensationReport {
  id: wooId;
  year: number;
  quarter: number;
  total: number;
  created_at: string;
  updated_at: string;
  status: string;
}

export enum Status {
  notHandled = 'not_handled',
  invoiced = 'invoiced',
  writtenDown = 'written_down',
}
