import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { ProductFormat, Publisher, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-pause-ad-estimates',
  templateUrl: './manage-pause-ad-estimates.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManagePauseAdEstimates implements OnInit {
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  @Input('headerPos') parentHeader: HTMLElement;
  show = {
    estimatesHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };

  readonly tableHeaders = ['Publicist', 'Placering', 'Andel av estimat RBS preroll', 'Maxuttag'];
  readonly staticFields = 2;
  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  pauseAdRows: PauseAdRow[];
  translateProductFormat: (args: any) => string;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    public dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled, ExtraPublisherField.productFormats)
      .then((publishers: Publisher[]) => {
        this.publishers = publishers.filter((p) => p.enabled);
      })
      .then(() => {
        this.getAndBuildPauseAds();
      })
      .finally(() => {
        this.show.loading = false;
        scrollTo(this.parentHeader);
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      });
  }

  async getAndBuildPauseAds(): Promise<void> {
    this.productFormatService
      .getProductFormats(
        ExtraProductFormatField.enabled,
        ExtraProductFormatField.pauseAdShare,
        ExtraProductFormatField.pauseAdWithdrawalLimit,
      )
      .then((productFormats) => {
        this.productFormats = productFormats.filter((f) => f.enabled);
        this.buildPauseAdRows();
      });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildPauseAds();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.updatingEstimates = true;
        this.productFormatService.updateProductFormats(this.productFormats).then((productFormats) => {
          this.productFormats = productFormats;
          this.buildPauseAdRows();
          this.show.estimatesHaveChanged = true;
        });
      })
      .catch(() => null)
      .finally(() => {
        this.setEditing(false);
        this.show.updatingEstimates = false;
        this.dialogService.closeBlocking();
      });
  }

  rowVisible(obj: PauseAdRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || productFormatFiltered);
  }

  getProductFormat(productFormatId: wooId): ProductFormat {
    return this.productFormats.find((format) => format.id === productFormatId);
  }

  private buildPauseAdRows(): void {
    let pauseAdRows: PauseAdRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((product_format) => {
        pauseAdRows = [
          ...pauseAdRows,
          {
            publisher: publisher.name,
            publisherId: publisher.id,
            productFormat: product_format.name,
            productFormatId: product_format.id,
            pauseAdShare: product_format.pause_ad_share,
            pausAdWithdrawalLimit: product_format.pause_ad_withdrawal_limit,
          },
        ];
      });
    });
    this.pauseAdRows = pauseAdRows;
  }
}
interface PauseAdRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  pauseAdShare?: number;
  pausAdWithdrawalLimit?: number;
}
