<div class="padding--1x background-color--white shadow-z-1 margin-bottom--2x clearfix">
  <expandable-header backgroundColor="white" text="Administrationsersättning">
    <div class="padding--1x">
      <p class="thin">Här kan du avaktivera/aktivera administrationsersättningen för kampanjen. För att ändra måste du ange en kommentar.</p>
      <div class="row">
        <form name="selfServiceCompensationForm">
          <div class="col-md-12">
            <div class="input__container">
              <label for="comment">Kommentar</label>
              <textarea id="comment" name="commentArea" type="text" required [(ngModel)]="updatedDetails.comment" maxlength="maxCommentLength"></textarea>
            </div>
          </div>
          <div class="col-md-12 margin-top--3x">
            <toggle
              name="self_service_compensation"
              [(ngModel)]="updatedDetails.compensation_enabled"
              offLabel="Avaktiverad"
              onLabel="Aktiverad">
            </toggle>
          </div>
          <div class="col-md-12 margin-top--3x">
            <button class="button primary-1 margin-top--2x" [disabled]="!valid" (click)="updateAgencySelfServiceCompensation()">Spara</button>
          </div>
        </form>
      </div>
    </div>
  </expandable-header>
</div>
