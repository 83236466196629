import { DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BookingValidationService } from '../advanced_campaign_booking.module/services/BookingValidationService';
import { BookingStore } from '../advanced_campaign_booking.module/stores/BookingStore';

@NgModule({
  imports: [],
  providers: [
    {
      provide: 'Window',
      useValue: window,
    },
    CookieService,
    DecimalPipe,
    BookingStore,
    BookingValidationService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class WooServiceModule {}
