import { UrlMatchResult, UrlSegment } from '@angular/router';

export function dashboardPageMatcher(segments: UrlSegment[]): UrlMatchResult {
  if (segments.length > 0 && segments[0].path === 'dashboard') {
    if (segments.length === 1) {
      return {
        consumed: segments,
        posParams: {},
      };
    }
    if (segments.length === 2) {
      return {
        consumed: segments,
        posParams: { id: segments[1] },
      };
    }
    if (segments.length === 3) {
      return {
        consumed: segments,
        posParams: { id: segments[1], tab: segments[2] },
      };
    }
    return <UrlMatchResult>(null as any);
  }
  return <UrlMatchResult>(null as any);
}
