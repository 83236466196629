import { Injectable } from '@angular/core';
import { CustomerService } from '../../woo_services.module/CustomerService';
import { CompactCustomer, Customer, wooId } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedCustomerService
  implements
    Pick<CustomerService, 'getCustomersForCurrentUser' | 'getCustomersForCurrentUserExcludingLocked' | 'getCustomer'> {
  private listCache: CompactCustomer[] = null;
  private getCache = new Map<wooId, Customer>();

  constructor(private customerService: CustomerService) {}

  async getCustomersForCurrentUser(): Promise<CompactCustomer[]> {
    if (this.listCache) {
      return Promise.resolve(this.listCache);
    } else {
      const customers = await this.customerService.getCustomersForCurrentUser();
      this.listCache = customers;
      return customers;
    }
  }

  async getCustomersForCurrentUserExcludingLocked(): Promise<CompactCustomer[]> {
    if (this.listCache) {
      return Promise.resolve(this.listCache);
    } else {
      const customers = await this.customerService.getCustomersForCurrentUserExcludingLocked();
      this.listCache = customers;
      return customers;
    }
  }

  async getCustomer(id: wooId): Promise<Customer> {
    if (this.getCache.has(id)) {
      return Promise.resolve(this.getCache.get(id));
    } else {
      const customer = await this.customerService.getCustomer(id);
      this.getCache.set(id, customer);
      return customer;
    }
  }

  clear(customerId: wooId): void {
    this.getCache.delete(customerId);
  }
}
