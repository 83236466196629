import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import validator from 'validator/es';
import { TargetGroupChoices } from '../woo_components.module/inputs/target-group-select/target-group-select.component';
import { TargetGroup, TargetingTargetGroup } from '../woo_services.module/shared-types';

export function validateRequired(): ValidatorFn {
  return (c: AbstractControl) => (Validators.required(c) ? { required: 'Fältet är obligatoriskt' } : null);
}

export function validateEmail(): ValidatorFn {
  return (c: AbstractControl) => (Validators.email(c) ? { email: 'Ange en riktig e-mail address' } : null);
}

export function validateUrl(): ValidatorFn {
  const msg = 'URL:en verkar inte vara giltig';

  return (c: AbstractControl): ValidationErrors | null => {
    if (c.value && !validator.isURL(c.value, { allow_underscores: true })) {
      return { wooUrl: msg };
    }
    return null;
  };
}

export function validateUrlPrefix(...protocols: Array<'http' | 'https'>): ValidatorFn {
  const pattern = new RegExp(`^(${protocols.join('|')}):\/\/`);

  return (c: AbstractControl): ValidationErrors | null => {
    if (c.value && !pattern.test(c.value)) {
      const description = protocols.map((p) => p + '://').join(' eller ');
      return { wooUrlPrefix: `URL:en behöver börja med ${description} för att var giltig` };
    }
    return null;
  };
}

/**
 * @returns { <first validtator error key>: <first validator error> }
 */
export function mergeErrors(...validators: ValidatorFn[]): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    const firstError = validators.map((fn) => fn(c)).find((error) => Boolean(error) && Object.keys(error).length > 0);
    return firstError ? { [Object.keys(firstError)[0]]: Object.values(firstError)[0] } : null;
  };
}

/**
 * Usable when the FormControl return an array of values
 * This validator applies the provided validator to each element in the array
 * @returns The first error
 */
export function validateArray(validatorFn: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const values: any[] = control.value;
    const firstError = values
      .map((v) => new FormControl(v))
      .map((c) => validatorFn(c))
      .find((error) => Boolean(error) && Object.keys(error).length > 0);
    return firstError ? firstError : null;
  };
}

export function validateTargetGroupSelect(): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    const requiredError = { required: true };
    if (!c.value) {
      return requiredError;
    }
    const validState = validTargetGroupSelect(c.value, c.value.choices);
    return validState ? null : requiredError;
  };
}

export function validTargetGroupSelect(
  targetGroup: TargetingTargetGroup | TargetGroup,
  choices: TargetGroupChoices,
): boolean {
  return (
    Array.isArray(targetGroup.genders) &&
    Array.isArray(targetGroup.ages) &&
    (choices.gender ? targetGroup.genders.length === 1 : true) &&
    (choices.age
      ? targetGroup.ages.length > 0 &&
        (choices.gender ? targetGroup.genders.length === 1 : targetGroup.genders.length === 2)
      : true) &&
    (choices.gender || choices.age)
  );
}
