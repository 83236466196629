import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../../utils/types';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { DashboardService } from '../../../woo_services.module/DashboardService';
import { Campaign, HistoryItem, HistoryItemAction } from '../../../woo_services.module/shared-types';
import { ignore422 } from '../../../utils/utils';

const template = /* html*/ `
<div class="tabbable__panel panel-margin right section info clearfix">
  <div class="col-xs-12">
    <h2>Historik</h2>
  </div>
</div>
<div class="tabbable__body clearfix">
  <dot-loader [visibility]="loadingHistory" description="Hämtar historik"></dot-loader>
  <history-list [showDetailsFor]="historyDetailLevel" [items]="campaignHistory"></history-list>
  <p *ngIf="!loadingHistory && campaignHistory.length === 0">Ingen historia hittad för kampanj"{{campaign.name}}"<p>
</div>
`;

@Component({
  selector: 'campaign-history',
  template: template,
})
export class CampaignHistory implements OnChanges {
  @Input() campaign: Campaign;
  loadingHistory = false;
  campaignHistory: HistoryItem[] = [];

  readonly historyDetailLevel = [HistoryItemAction.update];
  readonly isAuthorized: boolean = this.authService.hasAnyRole([
    UserRole.planner,
    UserRole.admin,
    UserRole.externalPlanner,
    UserRole.accounting,
  ]);

  constructor(private authService: AuthService, private dashboardService: DashboardService) {}

  async ngOnChanges(changes: SimpleChanges<CampaignHistory>): Promise<void> {
    if (changes.campaign && this.campaign) {
      await this.loadHistory();
    }
  }

  private async loadHistory() {
    if (this.isAuthorized) {
      this.loadingHistory = true;
      try {
        this.campaignHistory = await this.dashboardService.getHistory(this.campaign.id);
      } catch (error) {
        ignore422(error);
      } finally {
        this.loadingHistory = false;
      }
    }
  }
}
