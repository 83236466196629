import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvalidMmsDaysService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getAll(): Promise<string[]> {
    return lastValueFrom(this.http.get<string[]>(`${this.env.apiUrl}/invalid_mms_days`));
  }

  update(startDay: string, endDay: string, invalidDays: string[]): Promise<void> {
    return lastValueFrom(
      this.http.put<void>(`${this.env.apiUrl}/invalid_mms_days`, {
        start_day: startDay,
        end_day: endDay,
        invalid_days: invalidDays,
      }),
    );
  }
}
