import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NameIdTuple, Region, TargetingRegion, wooId } from '../../../woo_services.module/shared-types';
import { CachedRegionService } from '../../services/CachedRegionService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = `
  <region-select
  class="display--block"
  [regions]="regions"
  [targetingId]="targetingId"
  ></region-select>
  <br>
  <p>Fördelningen mellan valda regioner väljs i nästa steg</p>
`;

@Component({
  selector: 'geo-targeting',
  template: template,
})
export class GeoTargeting extends SubscriptionHandler implements OnInit, OnChanges {
  regions: Region[] = [];
  selectedRegions: NameIdTuple[] = [];
  @Input() targetingId: wooId;

  constructor(private regionService: CachedRegionService, private bookingStore: BookingStore) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnInit(): void {
    this.regionService.getRegions().then((regions) => {
      this.regions = regions.filter((region) => region.enabled);
      this.initFromStore(this.bookingStore.state);
    });
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    if (this.regions.length > 0) {
      const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
      if (targeting) {
        this.selectedRegions = targeting.regions.map((region) => ({ name: region.name, id: region.region_id }));
      }
    }
  };

  regionsChange(regions: NameIdTuple[]): void {
    this.bookingStore.setRegions(regions.map(this.getTargetingRegion), this.targetingId);
  }

  private getTargetingRegion(region: Region): TargetingRegion {
    return { region_id: region.id, name: region.name };
  }
}
