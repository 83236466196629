import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { AuthService } from '../../../woo_services.module/AuthService';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { FormatterService, TargetingLabel, TargetingLabelType } from '../../../woo_services.module/FormatterService';
import { TargetingService } from '../../../woo_services.module/TargetingService';
import { Campaign, CampaignStatus, ProductFormats, TargetingType } from '../../../woo_services.module/shared-types';
import { CampaignTracking } from '../../services/TrackingService';

@Component({
  selector: 'campaign-outcome',
  templateUrl: './campaign-outcome.component.html',
})
export class CampaignOutcome implements OnChanges {
  readonly adminOrPlanner = this.authService.adminOrPlanner();

  @Input() campaign: Campaign;
  @Input() tracking: CampaignTracking;

  campaignHasEnded: boolean;
  campaignLabel: TargetingLabel = {
    type: TargetingLabelType.Instream,
    name: '',
  };
  formattedDays: string;
  showAdminPanel = false;
  campaignHasTvDaysOutcome: boolean;
  leftCol: string[];
  rightCol: Array<string | number>;
  isGrossRating: boolean;

  constructor(
    private authService: AuthService,
    private formatterService: FormatterService,
    private campaignService: CampaignService,
    private targetingService: TargetingService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.campaign && this.campaign) {
      this.showAdminPanel = false;
      this.isGrossRating = this.campaign.agreement?.gross_rating_views_summary.active || false;
    }
    if (changes.tracking && this.tracking) {
      const updated =
        typeof this.tracking.updated === 'string' ? parseISO(this.tracking.updated) : this.tracking.updated;
      this.formattedDays = formatDistanceToNow(updated, {
        addSuffix: true,
      });

      const totalCompletedViews = this.tracking.targetings.reduce(
        (sum, targeting) => sum + targeting.completed_views,
        0,
      );
      const totalImpressions = this.tracking.targetings.reduce((sum, targeting) => sum + targeting.impressions, 0);
      const totalCalculatedGrossRatingViews = this.tracking.targetings.reduce(
        (sum, targeting) => sum + (targeting?.calculated_gross_rating_views || 0),
        0,
      );

      const targetingMetaData = this.campaign.targetings.map((t) => this.targetingService.getMetaData(t));
      const allTargetingsPause = targetingMetaData.every((tmd) => tmd.targetingType === TargetingType.pause);
      const allTargetingsInStream = targetingMetaData.every((tmd) => tmd.targetingType === TargetingType.instream);
      const allTargetingsShortform = targetingMetaData.every((tmd) => tmd.productFormat === ProductFormats.shortForm);

      this.leftCol = ['Levererade impressions'];
      this.rightCol = [this.formatterService.transformNumber(totalImpressions)];
      if (!allTargetingsPause && !allTargetingsShortform) {
        this.leftCol.unshift(
          this.isGrossRating ? 'Levererade beräknade bruttokontakter' : 'Levererade completed views',
        );
        this.rightCol.unshift(
          this.formatterService.transformNumber(
            this.isGrossRating ? totalCalculatedGrossRatingViews : totalCompletedViews,
          ),
        );
      }

      if (allTargetingsPause) {
        this.campaignLabel.type = TargetingLabelType.PauseAd;
      } else if (allTargetingsInStream) {
        this.campaignLabel.type = TargetingLabelType.Instream;
      } else {
        this.campaignLabel.type = TargetingLabelType.Total;
      }

      this.campaignHasEnded = this.campaignService.hasAnyStatus(this.campaign, [
        CampaignStatus.closed,
        CampaignStatus.ended,
        CampaignStatus.stopped,
      ]);

      this.campaignHasTvDaysOutcome = !!this.tracking.tv_days?.length;
    }
  }
}
