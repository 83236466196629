import { Pipe, PipeTransform } from '@angular/core';
import { titleCase } from '../../utils/string';

@Pipe({
  name: 'wooTitleCase',
})
export class WooTitleCasePipe implements PipeTransform {
  transform(value: any): any {
    return value ? titleCase(value) : value;
  }
}
