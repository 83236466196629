import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { verticalExpansion } from '../../../utils/animations';
import { scrollTo } from '../../../utils/dom';
import { TargetGroupChoices } from '../../../woo_components.module/inputs/target-group-select/target-group-select.component';
import { PriceListService, PriceListType } from '../../../woo_services.module/PriceListService';
import { PublisherGroupService } from '../../../woo_services.module/PublisherGroupService';
import { PublisherService } from '../../../woo_services.module/PublisherService';
import { RegionService } from '../../../woo_services.module/RegionService';
import { TargetGroupService } from '../../../woo_services.module/TargetGroupService';
import { TargetingService } from '../../../woo_services.module/TargetingService';
import { ViewportService } from '../../../woo_services.module/ViewportService';
import { MultipleViewChildren } from '../../decorators/multiple-view-children.decorator';
import { PublisherGroupSelectedEvent } from './publisher-group-select.component';
import { TargetingRadioBox } from './targeting-radio-box.component';
import { RegionSelectedEvent } from './validated-region-select.component';
import { TargetGroupSelectedEvent } from './validated-target-group-select.component';

interface Targeting {
  publisher_groups: any[];
  regions: any[];
  gender_target_groups: any[];
  age_target_groups: any[];
}
interface TargetingChangeEvent {
  targeting: Targeting;
  error: boolean;
}

const ANIMATION_TIMING = 250;

@Component({
  selector: 'targeting-select',
  templateUrl: './targeting-select.component.html',
  styleUrls: ['./targeting-select.component.scss'],
  animations: [verticalExpansion(ANIMATION_TIMING)],
})
export class TargetingSelect implements OnChanges {
  TYPES = {
    noTargeting: 'no-targeting',
    regions: 'regions',
    publisherGroup: 'publisher-group',
    targetGroup: 'target-group',
  };

  selected = this.TYPES.noTargeting;
  targeting = this.targetingService.createTargeting();
  error = false;
  model = {
    publisherGroups: [],
    regions: [],
    targetGroups: {
      genders: [],
      ages: [],
    },
  };
  priceList = {};
  publisherGroups = [];
  publishers = [];
  regions = [];
  genders = [];
  ages = [];

  promises = [];

  choices: TargetGroupChoices;

  @Input() initialTargeting: Targeting;
  @Output() modelChange = new EventEmitter<TargetingChangeEvent>();
  @MultipleViewChildren(TargetingRadioBox, { read: [TargetingRadioBox, ElementRef] }) radioBoxes: Array<
    [TargetingRadioBox, ElementRef]
  >;

  constructor(
    priceListService: PriceListService,
    publisherGroupService: PublisherGroupService,
    private publisherService: PublisherService,
    regionService: RegionService,
    private targetingService: TargetingService,
    private targetGroupService: TargetGroupService,
    private viewportService: ViewportService,
  ) {
    priceListService.getCurrentPriceList(new Date(), PriceListType.Cash).then((priceList) => {
      this.priceList = priceList;
    });
    this.promises = [
      publisherGroupService.getPublisherGroups().then((publisherGroups) => (this.publisherGroups = publisherGroups)),
      publisherService.getPublishers().then((publishers) => {
        this.publishers = publishers;
        this.setDefaultPublisher();
      }),
      targetGroupService.getGenders().then((genders) => (this.genders = genders)),
      targetGroupService.getAges().then((ages) => (this.ages = ages)),
      regionService.getRegions().then((regions) => (this.regions = regions.filter((region) => region.enabled))),
    ];
  }

  ngOnChanges(changes: Record<string, any>): void {
    if (changes.initialTargeting && this.initialTargeting) {
      Promise.all(this.promises).then(this.updateModelFromTargeting);
    }
  }

  notifyChange = (): void => {
    const eventObj = { targeting: this.targeting, error: this.error };
    this.modelChange.emit(eventObj);
  };

  setDefaultPublisher = (): void => {
    if (!this.targeting.publishers.length) {
      const defaultPublisher = this.publishers.find((publisher) => publisher.name === 'TV4');
      this.targeting.publishers.push({ publisher_id: defaultPublisher.id, name: defaultPublisher.name });
    }
  };

  scrollTo = (target: TargetingRadioBox): void => {
    if (this.viewportService.phone()) {
      const [_box, elemRef] = this.radioBoxes.find(([box, _ref]) => box === target);
      scrollTo(elemRef.nativeElement);
    }
  };

  targetingChange = (newSelected: string): void => {
    if (this.selected !== newSelected) {
      this.selected = newSelected;
      this.targeting = this.targetingService.createTargeting();
      this.clearInitialValues();
      this.setDefaultPublisher();
    }
  };

  noTargetingChange = (data: { error: boolean }): void => {
    this.error = data.error;
    this.notifyChange();
  };

  regionsChange = (data: RegionSelectedEvent): void => {
    this.model.regions = data.regions;
    this.error = data.error;
    this.targeting.regions = data.regions.map((region) => {
      return { region_id: region.id, name: region.name };
    });
    this.notifyChange();
  };

  publisherGroupsChange = (data: PublisherGroupSelectedEvent): void => {
    this.model.publisherGroups = data.publisherGroups;
    this.error = data.error;
    this.targeting.publisher_groups = data.publisherGroups.map((publisherGroup) => {
      return {
        publisher_group_id: publisherGroup.id,
        name: publisherGroup.name,
      };
    });
    this.notifyChange();
  };

  targetGroupChange = (data: TargetGroupSelectedEvent): void => {
    this.model.targetGroups = { genders: data.genders, ages: data.ages };
    this.error = data.error;
    this.targeting.gender_target_groups = data.genders.map((gender) => ({
      gender_target_group_id: gender.id,
      name: gender.name,
    }));
    this.targeting.age_target_groups = data.ages.map((age) => ({
      age_target_group_id: age.id,
      name: age.name,
      min_age: age.min_age,
      max_age: age.max_age,
    }));
    this.notifyChange();
  };

  clearInitialValues = (): void => {
    this.model = { publisherGroups: [], regions: [], targetGroups: { genders: [], ages: [] } };
  };

  updateModelFromTargeting = (): void => {
    if (this.initialTargeting.publisher_groups.length > 0) {
      this.selected = this.TYPES.publisherGroup;
      this.model.publisherGroups = this.initialTargeting.publisher_groups.map((publisherGroupContainer) =>
        this.publisherGroups.find((p) => p.id === publisherGroupContainer.publisher_group_id),
      );
    } else if (this.initialTargeting.regions.length > 0) {
      this.selected = this.TYPES.regions;
      this.model.regions = this.initialTargeting.regions.map((region) =>
        this.regions.find((r) => r.id === region.region_id),
      );
    } else if (
      this.initialTargeting.gender_target_groups.length > 0 ||
      this.initialTargeting.age_target_groups.length > 0
    ) {
      this.selected = this.TYPES.targetGroup;
      this.model.targetGroups = {
        genders: this.initialTargeting.gender_target_groups.map((gender) =>
          this.genders.find((g) => g.id === gender.gender_target_group_id),
        ),
        ages: this.initialTargeting.age_target_groups.map((age) =>
          this.ages.find((a) => a.id === age.age_target_group_id),
        ),
      };
    } else if (this.initialTargeting) {
      this.selected = this.TYPES.noTargeting;
    }
    this.choices = getDefaultChoices(this.initialTargeting);
  };
}

function getDefaultChoices(targeting: Targeting): TargetGroupChoices {
  return {
    age: targeting.age_target_groups.length > 0 ? true : false,
    gender: targeting.gender_target_groups.length === 1 ? true : false,
  };
}
