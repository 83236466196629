<h3>Externa kampanjer (som vi inte kan lagerberäkna)</h3>
<div class="">
  <table class="table table-woo table--align--middle">
    <thead>
    <tr>
      <th>Namn</th>
      <th>Period</th>
      <th>Prio</th>
      <th>Impr (Tot/Relevant)</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr><dot-loader [visibility]="loading" description="Hämtar kampanjer som vi ej kan lagerberäkna"></dot-loader></tr>
    <tr *ngFor="let campaign of campaigns; trackBy: trackById" >
      <td>{{campaign.name}}</td>
      <td>{{campaign.start_date | date:'yyyy-MM-dd'}} - {{campaign.end_date | date:'yyyy-MM-dd'}}</td>
      <td>{{campaign.priority}}</td>
      <td>{{campaign.goal_total}} / {{campaign.rbs_total}}</td>
      <td (click)="openDetails(campaign)" >
        <button class="link min-height--0">Mer info</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
