import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OutcomeReportsService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getCreatives(campaignId: wooId): Promise<string[]> {
    return lastValueFrom(this.http.get<string[]>(`${this.env.apiUrl}/campaigns/${campaignId}/ad_custom_ids`));
  }

  getOutcomeReport(campaignId: wooId): Promise<OutcomeReport> {
    return lastValueFrom(this.http.get<OutcomeReport>(`${this.env.apiUrl}/campaigns/${campaignId}/outcome_report`));
  }

  addOutcomeReport(campaignId: wooId, outcomeReport: OutcomeReport): Promise<OutcomeReport> {
    return lastValueFrom(
      this.http.post<OutcomeReport>(`${this.env.apiUrl}/campaigns/${campaignId}/outcome_report`, {
        outcome: outcomeReport,
      }),
    );
  }

  summaryExport(campaignId: wooId): Promise<Blob> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/campaigns/${campaignId}/export_outcome`, {
        responseType: 'blob',
      }),
    );
  }

  deleteOutcomeReport(campaignId: wooId): Promise<void> {
    return lastValueFrom(this.http.delete<void>(`${this.env.apiUrl}/campaigns/${campaignId}/outcome_report`));
  }
}

export interface OutcomeReportCreative {
  custom_ad_id: string;
  completed_views_by_mms: number;
  impressions_by_mms: number;
  gross_rating_views_by_mms: number;
  completion_rate_by_mms: number;
}

export interface OutcomeReport {
  id: wooId;
  net_range?: number;
  delivered_imps?: number;
  average_frequency_by_mms?: number;
  comment?: string;
  creatives: OutcomeReportCreative[];
}
