<div>
  <div *ngIf="!(isImage && editCreatives)" class="asset-container asset-size margin-left--2x margin-bottom--1x float--right">
    <video *ngIf="creative?.asset_url && isVideo" [src]="creative?.asset_url" class="asset-size" controls></video>
    <img *ngIf="creative?.asset_url && isImage" [src]="creative?.asset_url" class="asset-size" />
    <div
      class="creative--placeholder asset-size flex__container--vertical"
      *ngIf="!creative?.asset_url"
    >
      <span class="icon icon-ios text--xl text--muted margin-bottom--1x"></span>
      Material saknas
    </div>
  </div>

  <h4 class="padding-bottom--2x">{{displayName()}}</h4>

  <creative-details-row
    label="Leveranssätt"
    [value]="convertCreativeTypes(creative?.creative_type)"
    *ngIf="!editCreatives"
  ></creative-details-row>
  <creative-details-row
    label="Längd"
    [value]="(creative?.length | dashAsDefault) + ' s'"
    *ngIf="isVideo && !editCreatives"
  ></creative-details-row>
  <creative-details-row
    label="Segment"
    [value]="getMergedSegmentDates()"
  ></creative-details-row>
  <creative-details-row
    label="Status"
    [useChildElement]="true">
    <creative-status-label [status]="creative?.status"></creative-status-label>
  </creative-details-row>
  <creative-details-row
    label="Namn"
    [value]="creative?.custom_name | dashAsDefault"
    *ngIf="!editCreatives"
  ></creative-details-row>
  <creative-details-row
    label="Filmkod"
    [value]="creative?.video_code | dashAsDefault"
    *ngIf="isVideo && !editCreatives"
  ></creative-details-row>
  <creative-details-row
    [label]="isVideo ? 'Video-URL' : 'Bild-URL'"
    [value]="creative?.asset_url | dashAsDefault"
    *ngIf="!editCreatives"
  ></creative-details-row>
  <creative-details-row
    label="Destinations-URL"
    [value]="creative?.destination_url | dashAsDefault"
    *ngIf="!editCreatives"
  ></creative-details-row>
  <creative-details-row
    label="Tracking-URL (klick)"
    [value]="creative?.click_tracking_url | dashAsDefault"
    *ngIf="isVideo && !editCreatives"
  ></creative-details-row>
  <creative-details-row
    label="Tracking-URL (impressions)"
    [value]="creative?.impression_tracking_urls?.length > 0 ? creative?.impression_tracking_urls : '' | dashAsDefault"
    *ngIf="!editCreatives"
  ></creative-details-row>
  <creative-details-row
    label="Kommentar"
    [value]="creative?.custom_text | dashAsDefault"
    *ngIf="!editCreatives"
  ></creative-details-row>
  <instream-creative-form
    *ngIf="editCreatives && creative.creative_type !== ImageCreativeType.pauseImage"
    [creative]="creative"
    [creativeLengths]="[creative?.length]"
    [customerId]="customerId"
    [layoutProfile]="instreamCreativeFormProfile"
    [ongoingCreative]="ongoingCreative"
    [disableAG]="disableAG"
    [productFormatConditions]="productFormatConditions"
    (onChange)="creativeChange($event)"
    (onValidityChange)="creativeValidityChange($event)"
    >
  </instream-creative-form>
  <pause-creative-form
    class="display--block"
    *ngIf="editCreatives && creative.creative_type === ImageCreativeType.pauseImage"
    [creative]="creative"
    [customerId]="customerId"
    [layoutProfile]="pauseCreativeFormProfile"
    (onChange)="creativeChange($event)"
    (onValidityChange)="creativeValidityChange($event)"
 ></pause-creative-form>
</div>
