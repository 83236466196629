import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { InventoryTree } from '../../woo_services.module/InventoryService';
import { Pagination, PaginationService } from '../../woo_services.module/PaginationService';
import { wooId } from '../../woo_services.module/shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExternalCampaignService {
  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private paginationService: PaginationService,
  ) {}

  getActiveCampaigns(): Promise<ExternalCampaign[]> {
    return lastValueFrom(this.http.get<ExternalCampaign[]>(`${this.env.apiUrl}/external_campaigns/active`));
  }

  getEndedCampaigns(page: number): Promise<[ExternalCampaign[], Pagination]> {
    const params = new HttpParams().set('page', page.toString());
    return lastValueFrom(
      this.http
        .get<ExternalCampaign[]>(`${this.env.apiUrl}/external_campaigns/ended`, {
          params: params,
          observe: 'response',
        })
        .pipe(
          map((event) => {
            return [event.body, this.paginationService.getPagination(event.headers)] as [
              ExternalCampaign[],
              Pagination,
            ];
          }),
        ),
    );
  }

  getInvalidCampaigns(): Promise<ExternalCampaign[]> {
    return lastValueFrom(this.http.get<ExternalCampaign[]>(`${this.env.apiUrl}/external_campaigns/invalid`));
  }

  getInventoryForCampaign(id: wooId): Promise<InventoryTree> {
    return lastValueFrom(this.http.get<InventoryTree>(`${this.env.apiUrl}/external_campaigns/${id}/inventory`));
  }

  setReduceImpressions(campaign: ExternalCampaign): Promise<ExternalCampaign> {
    return lastValueFrom(
      this.http.patch<ExternalCampaign>(`${this.env.apiUrl}/external_campaigns/set_reduce_impressions`, {
        external_campaign: campaign,
      }),
    );
  }
}

export interface ExternalCampaign {
  id: wooId;
  age_target_group_names: string[];
  behavior_names: string[];
  advanced_target_group_names: string[];
  category_names: string[];
  end_date: string;
  external_goals: ExternalGoal[];
  external_id: string;
  gender_target_group_names: string[];
  goal_total: number;
  goal_type: string;
  name: string;
  priority: number;
  program_format_names: string[];
  publisher_names: string[];
  rbs_total: number;
  reduce_impressions: boolean;
  region_names: string[];
  share_of_voice_factor: number;
  start_date: string;
  valid_inventory: boolean;
}

export interface ExternalGoal {
  age_target_group_names: string[];
  behavior_names: string[];
  advanced_target_group_names: string[];
  category_names: string[];
  end_date: string;
  gender_target_group_names: string[];
  impressions: number;
  share_of_voice_factor: number;
  slot_types: string[];
  start_date: string;
  program_format_names: string[];
  publisher_names: string[];
  region_names: string[];
  format?: string;
}
