import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PriceList, ValidPriceListRow } from '../../../../woo_services.module/PriceListService';
import {
  GeoTargeting,
  PriceListBasePriceTypes,
  PriceListTargetingPriceTypes,
  Publisher,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { EditOverwrittenPriceRowContainer } from './edit-overwritten-price-row-container.component';

@Component({
  selector: 'edit-overwritten-price',
  templateUrl: './edit-overwritten-price.compontent.html',
  styleUrls: ['./price-list-table.component.scss'],
})
export class EditOverwrittenPrice {
  constructor(private translationService: TranslationService) {}
  @Input() publishers: Publisher[];
  @Input() priceList: PriceList;
  @Output() onOverwrittenPricesUpdated = new EventEmitter<wooId>();
  @Output() onClearOverwrittenPrices = new EventEmitter<wooId>();
  @ViewChild(EditOverwrittenPriceRowContainer)
  private editOverwrittenPricesComponent: EditOverwrittenPriceRowContainer;

  readonly productFormatOptions: DropDownOption[] = Object.keys(PriceListBasePriceTypes)
    .map(
      (key: string): DropDownOption => {
        return {
          key: key,
          name: this.translationService.convertProductFormat(key),
        };
      },
    )
    .filter((option: DropDownOption) => option.key !== PriceListBasePriceTypes.includes_linear);

  readonly targetingOptions: DropDownOption[] = Object.keys(PriceListTargetingPriceTypes).map(
    (key: string): DropDownOption => {
      return {
        key: key,
        name: this.translationService.convertProductChoice(key),
      };
    },
  );
  readonly localityOptions: DropDownOption[] = Object.keys(GeoTargeting).map(
    (key: string): DropDownOption => {
      return {
        key: key,
        name: this.translationService.convertLocality(key),
      };
    },
  );

  selectedPublisher: Publisher;
  selectedProductFormat: DropDownOption;
  selectedTargetingType: DropDownOption;
  selectedLocalityType: DropDownOption;
  editingOverwrittenPrices = false;

  priceListIsNew(): boolean {
    return !this.priceList.id?.length;
  }

  overwrittenPricesUpdated(): void {
    this.onOverwrittenPricesUpdated.emit(this.priceList.id);
  }

  overWritenPrices(): ValidPriceListRow[] {
    return this.priceList.valid_prices.filter((validPrice: ValidPriceListRow) => !!validPrice.over_written_price);
  }

  clearOverWritenPrices(): void {
    if (this.priceListIsNew()) {
      this.priceList.valid_prices = [];
      this.onClearOverwrittenPrices.emit();
    }
  }

  save(): void {
    this.editOverwrittenPricesComponent.save();
  }
}

interface DropDownOption {
  key: string;
  name: string;
}
