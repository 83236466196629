import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { uniq } from '../utils/array';
import { EnvironmentService } from './EnvironmentService';
import { Campaign, CategoryShares, ProductFormat, ProductFormats, ProgramShares, Targeting } from './shared-types';
@Injectable({ providedIn: 'root' })
export class ProductFormatService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getProductFormats(...extra: ExtraProductFormatField[]): Promise<ProductFormat[]> {
    const fields = uniq((extra as string[]).concat(['id', 'name', 'publisher_id']));
    return lastValueFrom(
      this.http.get<ProductFormat[]>(`${this.env.apiUrl}/product_formats`, {
        params: { fields: fields.join(',') },
      }),
    );
  }

  updateProductFormats(product_formats: Array<Partial<ProductFormat>>): Promise<ProductFormat[]> {
    return lastValueFrom(
      this.http.put<ProductFormat[]>(`${this.env.apiUrl}/product_formats`, { product_formats: product_formats }),
    );
  }

  updateProductFormatShares(shares: SharesUpdateParams[]): Promise<ProductFormat[]> {
    return lastValueFrom(
      this.http.patch<ProductFormat[]>(`${this.env.apiUrl}/product_formats/update_product_format_shares`, {
        shares_by_id: shares,
      }),
    );
  }

  updateProgramFormatShares(shares: ProgramShares[]): Promise<ProgramShares[]> {
    return lastValueFrom(
      this.http.patch<ProgramShares[]>(`${this.env.apiUrl}/product_formats/update_program_format_shares`, {
        product_format_program_shares: shares,
      }),
    );
  }

  updateCategoryShares(shares: CategoryShares[]): Promise<CategoryShares[]> {
    return lastValueFrom(
      this.http.patch<CategoryShares[]>(`${this.env.apiUrl}/product_formats/update_category_shares`, {
        product_format_category_shares: shares,
      }),
    );
  }

  convertProductFormatToEnum = (productFormat: ProductFormats[]): ProductFormats => {
    if (productFormat) return productFormat.length > 1 ? ProductFormats.combination : productFormat[0];
    return ProductFormats.combination;
  };

  convertProductFormatToArray = (productFormat: ProductFormats): ProductFormats[] => {
    return productFormat === ProductFormats.combination
      ? [ProductFormats.longForm, ProductFormats.shortForm]
      : [productFormat];
  };

  targetingIsCombination = (targeting: Targeting): boolean => {
    return (
      targeting.product_formats.length === 2 &&
      targeting.product_formats.includes(ProductFormats.longForm) &&
      targeting.product_formats.includes(ProductFormats.shortForm)
    );
  };

  targetingIsLongform = (targeting: Targeting): boolean => {
    return targeting.product_formats.length === 1 && targeting.product_formats.includes(ProductFormats.longForm);
  };

  targetingIsShortform = (targeting: Targeting): boolean => {
    return targeting.product_formats.length === 1 && targeting.product_formats.includes(ProductFormats.shortForm);
  };

  campaignIncludesCombination = (campaign: Campaign): boolean => {
    return campaign.targetings
      .map((targeting) => {
        return this.targetingIsCombination(targeting);
      })
      .some((bool) => bool);
  };

  campaignIncludesLongform = (campaign: Campaign): boolean => {
    return campaign.targetings
      .map((targeting) => {
        return this.targetingIsLongform(targeting);
      })
      .some((bool) => bool);
  };

  campaignIncludesShortform = (campaign: Campaign): boolean => {
    return campaign.targetings
      .map((targeting) => {
        return this.targetingIsShortform(targeting);
      })
      .some((bool) => bool);
  };
}

export const enum ExtraProductFormatField {
  enabled = 'enabled',
  derivedSlotTypeShares = 'derived_slot_type_shares',
  regionalShares = 'regional_shares',
  deviceGroupShares = 'device_group_shares',
  advancedTargetGroupShares = 'advanced_target_group_shares',
  behaviorShares = 'behavior_shares',
  pauseAdShare = 'pause_ad_share',
  pauseAdWithdrawalLimit = 'pause_ad_withdrawal_limit',
  daypartWithdrawalLimits = 'daypart_withdrawal_limits',
  deviceGroupWithdrawalLimits = 'device_group_withdrawal_limits',
  advancedTargetGroupWithdrawalLimits = 'advanced_target_group_withdrawal_limits',
  behaviorWithdrawalLimits = 'behavior_withdrawal_limits',
  categoryWithdrawalLimits = 'category_withdrawal_limits',
  gamblingWithdrawalLimits = 'gambling_withdrawal_limits',
  gamblingWithdrawalLimitShares = 'gambling_withdrawal_limit_shares',
  gamblingPauseAdShare = 'gambling_pause_ad_share',
  gamblingPauseAdWithdrawalLimit = 'gambling_pause_ad_withdrawal_limit',
  frequencyLimitRatios = 'frequency_limit_ratios',
  withdrawalLimits = 'withdrawal_limits',
  agWithdrawalLimits = 'ag_withdrawal_limits',
  spotLengthLimits = 'spot_length_limits',
  targetGroupShares = 'target_group_shares',
  targetGroupWithdrawalLimits = 'target_group_withdrawal_limits',
  productFormatShares = 'product_format_shares',
  slotTypes = 'slot_types',
  format = 'format',
  productFormatShare = 'product_format_share',
  productFormatProgramShares = 'product_format_program_shares',
  productFormatCategoryShares = 'product_format_category_shares',
}

export interface SharesUpdateParams {
  id: string;
  product_format_shares: Record<string, number>;
}
