<table *ngIf="invoices.length" class="table table--woo table--hover table--align--middle">
  <thead>
    <tr>
      <th>Orderreferens</th>
      <th>Belopp</th>
      <th>Datum för kredit</th>
      <th colspan="2">Status</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let invoice of invoices">
      <tr (click)="toggleExpanded(invoice.id)" class="toggle-container invoice--row">
        <td>{{invoice.campaign_order_reference}}</td>
        <td>{{formatAmount(invoice.total_gross)}}</td>
        <td>{{formatDate(invoice.invoice_date)}}</td>
        <td>
          <button *ngIf="invoice.attest_status === AttestStatus.AwaitingAttest" class="button primary-1 success margin--none" (click)="attestInvoice(invoice.id, $event)" >Attestera faktura</button>
          <span *ngIf="invoice.attest_status === AttestStatus.AttestedOk"><div class="display--flex">{{attestStatusLabel(invoice.attest_status)}} <div class="status__badge with--icon success small display--inline-block margin-left--1x"></div></div></span>
          <span *ngIf="invoice.attest_status === AttestStatus.PreAttestRules">{{attestStatusLabel(invoice.attest_status)}}</span>
        </td>
        <td>
          <button class="toggle-button">
            <span class="chevron medium text--info" [ngClass]="{'bottom': isExpanded(invoice.id)}"></span>
          </button>
        </td>
      </tr>
      <tr class="invoice--row">
        <td colspan="5" class="hidden--row">
          <div
            class="width--100 padding--1x col-sm-12"
            *ngIf="isExpanded(invoice.id)"
            [@verticalExpansion]>
            <p *ngIf="invoice.campaign_id">
              Kampanjnamn: <a (click)="showCampaign(invoice.campaign_id)">{{invoice.campaign_name}}</a>
            </p>
            <p *ngIf="invoice.customer_name">Kundnamn: {{invoice.customer_name}}</p>
            <p *ngIf="invoice.agency_name">Mediebyrå: {{invoice.agency_name}}</p>
            <p *ngIf="invoice.attest_user">Attesterare: {{invoice.attest_user}}</p>
            <p *ngIf="invoice.attest_user">Datum för attest: {{formatDate(invoice.attested_at)}}</p>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div *ngIf="!invoices.length" class="padding--1x">Inga fakturor att visa</div>
