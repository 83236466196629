import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { addWeeks, endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { groupBy } from '../../../../utils/array';
import { add, formatWooDate, getDays, inPeriod, parseWooDate } from '../../../../utils/date';
import { InvalidMmsDaysService } from '../../../../woo_services.module/InvalidMmsDaysService';
import { DateSegment } from '../../../../woo_services.module/shared-types';

@Component({
  selector: 'invalid-mms-days-admin',
  templateUrl: './invalid-mms-days-admin.component.html',
  styleUrls: ['./invalid-mms-days-admin.component.scss'],
})
export class InvalidMmsDayAdmin implements OnInit {
  days: Map<string, string[]> = new Map();
  editing = false;

  periodStart = this.getDefaultPeriodStart();
  periodEnd = this.getDefaultPeriodEnd();
  segments: DateSegment[] = [];

  private originalDates: Date[] = [];

  constructor(private invalidMmsDaysService: InvalidMmsDaysService) {}

  ngOnInit(): void {
    this.fetchDays();
  }

  startEditing(): void {
    this.editing = true;
    this.segments = this.originalDates.map(this.createSegment);
  }

  keyDescOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  earlier(): void {
    const start = subWeeks(this.periodStart, 1);
    const newSegments = this.originalDates
      .filter((date) => inPeriod(start, this.periodStart, date))
      .map(this.createSegment);
    this.periodStart = start;
    this.segments = newSegments.concat(this.segments);
  }

  later(): void {
    const end = addWeeks(this.periodEnd, 1);
    const newSegments = this.originalDates
      .filter((date) => inPeriod(this.periodEnd, end, date))
      .map(this.createSegment);
    this.segments = newSegments.concat(this.segments);
    this.periodEnd = end;
  }

  submit(): void {
    const days = this.segments
      .map((segment) => getDays(segment.start_date, add(segment.end_date, 1)))
      .reduce((result, daysInSegment) => result.concat(daysInSegment), [])
      .map(formatWooDate);

    this.invalidMmsDaysService.update(formatWooDate(this.periodStart), formatWooDate(this.periodEnd), days).then(() => {
      this.abortEditing();
      this.fetchDays();
    });
  }

  abortEditing(): void {
    this.editing = false;
    this.periodStart = this.getDefaultPeriodStart();
    this.periodEnd = this.getDefaultPeriodEnd();
    this.segments = [];
  }

  private fetchDays() {
    this.invalidMmsDaysService.getAll().then((days) => {
      this.originalDates = days.map(parseWooDate);
      this.days = groupBy(days, (day) => day.slice(0, 4));
    });
  }

  private createSegment(d: Date): DateSegment {
    return { start_date: d, end_date: d };
  }

  private getDefaultPeriodStart(): Date {
    return subWeeks(startOfWeek(new Date(), { weekStartsOn: 0 }), 2);
  }

  private getDefaultPeriodEnd(): Date {
    return addWeeks(endOfWeek(new Date(), { weekStartsOn: 0 }), 2);
  }
}
