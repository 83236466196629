<div class="ng-select--container">
  <label for="{{inputId}}">{{labelText}}</label>
  <ng-select
    labelForId="{{inputId}}"
    [items]="items"
    bindValue="{{bindValue}}"
    [searchFn]="searchFn"
    [(ngModel)]="model"
    [virtualScroll]="true"
    [clearable]="clearable"
    [multiple]="multiple"
    [disabled]="disabled"
    notFoundText="{{notFoundText}}"
    [placeholder]="placeholder">
    <ng-template *ngIf="labelCreate" ng-option-tmp let-item="item">
      <span>{{labelCreate(item)}}</span>
    </ng-template>
    <ng-template *ngIf="labelCreate" ng-label-tmp let-item="item">
      <span>{{labelCreate(item)}}</span>
    </ng-template>
  </ng-select>
</div>
