import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { CampaignStatus, Targeting } from '../../../woo_services.module/shared-types';
import { CashCampaign } from '../../services/CashDashboardService';

@Directive()
export class CashDashboardCard {
  @Input() campaign: CashCampaign;
  @Output() onDelete = new EventEmitter<string>();

  public CampaignStatus = CampaignStatus;
  public removeButtonStatuses = [CampaignStatus.unbooked, CampaignStatus.rejected, CampaignStatus.cancelled];
  public showDeleteQuestion = false;

  get targeting(): Targeting {
    return this.campaign.targetings[0];
  }
  constructor(private route: ActivatedRoute, private routingService: RoutingService) {}

  editCampaign(campaignId: string): void {
    this.routingService.navigate([
      '/customer',
      this.routingService.getParam(this.route, 'customerId'),
      'campaign',
      campaignId,
    ]);
  }

  removeCampaign(campaignId: string): void {
    this.onDelete.emit(campaignId);
  }
}
