import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SimpleChanges } from '../../utils/types';
import { Pagination } from '../../woo_services.module/PaginationService';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() pagination: Pagination;
  @Input() css: string;
  @Output() pageSelected = new EventEmitter<number>();

  range: Page[] = [];
  activePage: Page = null;

  ngOnChanges(changes: SimpleChanges<PaginationComponent>): void {
    if (changes.pagination && this.pagination) {
      this.createRange();
    }
  }

  requestPage = (page: Page): void => {
    this.activePage = page;
    this.pageSelected.emit(page.number);
  };

  private createRange = () => {
    this.range = [];
    for (let i = 1; i <= this.pagination.last; i++) {
      this.range.push({ number: i });
    }
    this.activePage = this.range[0];
  };

  trackByPageNumber(_: any, page: Page): number {
    return page.number;
  }
}

interface Page {
  number: number;
}
