import { Component, Input } from '@angular/core';
import { CampaignService } from '../../../../woo_services.module/CampaignService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { Campaign, CampaignStatus } from '../../../../woo_services.module/shared-types';

const template = /* html */ `
  <edit-button
    [disabled]="disabled"
    (click)="onClick()"
  ></edit-button>
`;

@Component({
  selector: 'edit-campaign-button',
  template,
})
export class EditCampaignButton {
  @Input() campaign: Campaign;
  @Input() disabled = false;

  constructor(private campaignService: CampaignService, private routingService: RoutingService) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    this.editCampaign(this.campaign);
  };

  private editCampaign = (campaign: Campaign) => {
    if (this.campaignService.hasAnyStatus(campaign, [CampaignStatus.ongoing, CampaignStatus.ended])) {
      this.goToEditOngoingCampaign(campaign);
    } else {
      this.goToEditCampaign(campaign);
    }
  };

  private goToEditCampaign = (campaign: Campaign) => {
    this.routingService.navigate(['/campaigns', campaign.id, 'edit'], { state: { id: campaign.id } });
  };

  private goToEditOngoingCampaign = (campaign: Campaign) => {
    this.routingService.navigate(['/campaigns', campaign.id, 'edit_ongoing'], { state: { id: campaign.id } });
  };
}
