import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../../utils/types';
import { CampaignEstimationPart, Targeting, wooId } from '../../../woo_services.module/shared-types';
import { BookingModel, BookingStore, BookingTargetingMetaData } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
<targeting-summary
  [targeting]="targeting"
  [metaData]="targetingMetaData"
  [part]="estimationPart"
  [expanded]="expanded"
  (expandedChange)="expandedChange($event)"
></targeting-summary>
`;

@Component({
  selector: 'advanced-booking-targeting-summary',
  template: template,
})
export class AdvancedBookingTargetingSummary extends SubscriptionHandler implements OnChanges {
  @Input() targetingId: wooId;

  targeting: Targeting;
  targetingMetaData: BookingTargetingMetaData;
  expanded = false;
  estimationPart: CampaignEstimationPart;

  constructor(private bookingStore: BookingStore) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(changes: SimpleChanges<AdvancedBookingTargetingSummary>): void {
    if (changes.targetingId && this.targetingId) {
      this.initFromStore(this.bookingStore.state);
    }
  }

  initFromStore = (model: BookingModel): void => {
    this.targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);

    if (!this.targeting) {
      return;
    }

    this.targetingMetaData = model.targetingMetaData[this.targetingId];
    this.expanded = this.targetingMetaData.expandedSummary;
    this.estimationPart = model.estimation.parts.find((p) => p.targeting_id === this.targetingId);
  };

  expandedChange(expanded: boolean): void {
    this.bookingStore.setExpandedSummary(this.targetingId, expanded);
  }
}
