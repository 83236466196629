<div class="block">
  <h1>Hjälp</h1>
  <div class="account__card no-margin-top-headings inner-block">
    <h2>
      Systemkrav för WOO
    </h2>
    <ul>
      <li>
        <a href="{{technicalSpecSv}}" class="text-info" target="_blank">WOO Teknik & produktspecifikation</a>
      </li>
      <li>
        <a href="{{technicalSpecEn}}" class="text-info" target="_blank">WOO Technical Product Specification</a>
      </li>
    </ul>
  </div>

  <div class="account__card no-margin-top-headings inner-block">
    <h2>
      Materialguide
    </h2>

    <ul>
      <li>
        <text-button (click)="getMaterialGuide()" class="info" target="_blank">Materialguide</text-button>
      </li>
    </ul>
  </div>


  <div class="account__card no-margin-top-headings inner-block">

    <h2>Min kampanj går inte att boka eller spara. Varför?</h2>
    <ul class="ul--woo">

      <li>
        Du har inte fyllt i obligatorisk information i rutorna med röda prickar.
      </li>
      <li>
        Tänk också på att filmlängden medför begränsningar för var din kampanj kan visas.
        <br>
        <br>
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Publicist / Maxlängd</th>
              <th class="text-center">Online Video</th>
              <th class="text-center">Adresserbar TV</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TV4</td>
              <td class="text-center">20 sek.</td>
              <td class="text-center">60 sek.</td>
            </tr>
          </tbody>
        </table>
      </li>
      <li>
        Din kampanj får inte plats under vald period.
        <ul>
          <li>
            Testa att utöka tidsperioden för kampanjen.
          </li>
          <li>
            Testa att välja RBS för en del eller hela budgeten istället för vald styrning.
          </li>
          <li>
            Testa att gå nationellt istället för regionalt, eller lägga till fler regioner.
          </li>
        </ul>
      </li>
    </ul>
  </div>


  <div class="account__card no-margin-top-headings inner-block">
    <h2>Vad sparar vi om dig?</h2>
    <p>För att tillhandahålla tjänsten och erbjuda god användarupplevelse sparar vi följande personuppgifter</p>
    <ul class="ul--woo">
      <li>E-postadress</li>
      <li>För- och efternamn</li>
      <li>Tid och datum, senaste två inloggingar</li>
      <li>Roll(er)</li>
      <li>Skapade kampanjer</li>
      <li>Eventuella mediebyråer du är knuten till</li>
      <li>Pågående sessioner</li>
    </ul>
  </div>

  <div class="account__card no-margin-top-headings inner-block">
    <h2>Glöm mig!</h2>
    <p>Maila <a href="mailto:dpo@tv4.se">dpo&#64;tv4.se</a> för mer information gällande borttagning av användare och konto.</p>
  </div>

  <div class="account__card no-margin-top-headings inner-block">
    <h2>Allmänna Villkor</h2>
    <p><a href="{{userTerms}}" target="_blank"><span>Här kan du läsa</span></a> de allmänna villkoren.</p>
  </div>

  <div class="account__card no-margin-top-headings inner-block">
    <h2>Integritetspolicy</h2>
    <p><a href="{{integrityPolicy}}" target="_blank"><span>Här kan du läsa</span></a> om vår integritetspolicy.</p>
  </div>

  <div class="account__card no-margin-top-headings inner-block">
    <h2>Cookies</h2>
    <p><a href="{{cookiePolicy}}" target="_blank"><span>Här kan du läsa</span></a> om vår cookiepolicy.</p>
  </div>

</div>

