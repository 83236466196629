import { formatDate, formatNumber, formatPercent } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { STANDARD_FORMATS } from '../utils/format-constants';
import { titleCase } from '../utils/string';
import { CreativeService } from './CreativeService';
import { TargetingService } from './TargetingService';
import { TranslationService } from './TranslationService';
import {
  Creative,
  GeoTargeting,
  ProductFormats,
  Targeting,
  TargetingMetaData,
  TargetingType,
  ViewCurrency,
} from './shared-types';

export enum TargetingLabelType {
  Instream = 'Reklamfilm',
  PauseAd = 'Pausreklam',
  Total = 'Total',
}

export interface TargetingLabel {
  type: TargetingLabelType;
  name: string;
  localization?: string;
}

@Injectable({ providedIn: 'root' })
export class FormatterService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private targetingService: TargetingService,
    private creativeService: CreativeService,
    private translationService: TranslationService,
  ) {}

  getTargetingLabel = (targeting: Targeting | null, metaData: TargetingMetaData | null): TargetingLabel => {
    return {
      type: metaData ? this.getTargetingType(metaData) : null,
      name: metaData
        ? this.translationService.convertProductChoice(metaData.productChoice)
        : targeting
        ? this.getTargetingName(targeting)
        : '',
      localization: metaData ? this.getTargetingLocalization(metaData.geoTargeting) : '',
    };
  };

  getTargetingLabelString = (targeting: Targeting, metaData: TargetingMetaData): string => {
    const targetingLabel = this.getTargetingLabel(targeting, metaData);
    return `${targetingLabel.type} ${targetingLabel.name} - ${targetingLabel.localization}`;
  };

  getTargetingType(metaData: TargetingMetaData): TargetingLabelType {
    return metaData.targetingType === TargetingType.instream ? TargetingLabelType.Instream : TargetingLabelType.PauseAd;
  }

  getTargetingName(targeting: Targeting): string {
    // Regions is not relevant for targeting name, it's distinguished in localization instead
    const targetingWithoutRegions = {
      ...targeting,
      regions: [],
    };
    return this.targetingService.getTargetingName(targetingWithoutRegions);
  }

  getTargetingLocalization = (geoTargeting?: GeoTargeting): string => {
    if (geoTargeting === GeoTargeting.regional) {
      return 'regional';
    } else if (geoTargeting === GeoTargeting.national) {
      return 'nationell';
    } else {
      return '';
    }
  };

  getCurrencyName(
    targeting: Targeting,
    useTitleCase = false,
    translationMethod: 'normal' | 'short' | 'description' | 'acronym' = 'normal',
  ): string {
    const shortformTargeting =
      targeting.product_formats.length < 2 && targeting.product_formats.includes(ProductFormats.shortForm);
    const currency = shortformTargeting ? ViewCurrency.impressions : targeting.view_currency;
    return this.mapCurrencyToName(currency, useTitleCase, translationMethod);
  }

  mapCurrencyToName(
    viewCurrency: ViewCurrency,
    useTitleCase = false,
    translationMethod: 'normal' | 'short' | 'description' | 'acronym' = 'normal',
  ): string {
    const mapper = useTitleCase ? titleCase : (s: string) => s;
    const method = {
      normal: this.translationService.convertViewCurrency,
      short: this.translationService.convertViewCurrencyShort,
      description: this.translationService.convertViewCurrencyToDescription,
      acronym: this.translationService.convertViewCurrencyToAcronym,
    }[translationMethod];
    return mapper(method(viewCurrency));
  }

  getCreativeName = (creative: Creative): string => {
    if (this.creativeService.isVideo(creative)) {
      const name =
        creative.video_code ||
        creative.custom_name ||
        this.translationService.convertCreativeTypesShort(creative.creative_type);
      return `${creative.length} s, ${name}`;
    } else {
      const name = creative.custom_name || (creative.asset_url ? 'Levererad' : 'Levereras senare');
      return 'Bild' + `, ${name}`;
    }
  };

  formatPeriod(start: Date | string, end: Date | string): string {
    return !start && !end ? '' : `${this.transformDate(start)} - ${this.transformDate(end)}`;
  }

  transformDate(date: Date | string): string {
    return date ? formatDate(date, STANDARD_FORMATS.date, this.locale) : '';
  }

  transformPercent(discount: string | number): string {
    return formatPercent(Number(discount), this.locale, STANDARD_FORMATS.percent);
  }

  transformNumber(nr: number, format?: string): string {
    if (nr === undefined || nr === null) {
      return '';
    }
    return formatNumber(nr, this.locale, format);
  }
}
