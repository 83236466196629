import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { scrollToOffset, scrollToTop, showTooltip } from '../../../utils/dom';
import { UserRole } from '../../../utils/user-roles';
import { AdOrganisationService } from '../../../woo_services.module/AdOrganisationService';
import { AuthService } from '../../../woo_services.module/AuthService';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { CampaignSets, DashboardService } from '../../../woo_services.module/DashboardService';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { DialogService } from '../../../woo_services.module/DialogService';
import { GoogleTagManagerService } from '../../../woo_services.module/GoogleTagManagerService';
import { HotkeyService } from '../../../woo_services.module/HotkeyService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { SessionService } from '../../../woo_services.module/SessionService';
import {
  AdOrganisation,
  Campaign,
  CampaignStatus,
  CompactCustomer,
  Customer,
  DashboardLoad,
  DashboardTab,
} from '../../../woo_services.module/shared-types';
import { SearchInfoDialog } from '../dialogs/search-info-dialog.component';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
})
export class Dashboard extends SubscriptionHandler implements OnInit {
  readonly adminOrPlanner = this.authService.hasAnyRole([UserRole.admin, UserRole.planner]);
  readonly maySearchOrganisation = false; // With only one adOrg active this is disabled for now.
  readonly maySeeClosedCampaigns = !this.authService.hasAnyRole([
    UserRole.agencyAdmin,
    UserRole.agencyUser,
    UserRole.agency,
    UserRole.client,
  ]);
  allowedToBook: boolean;
  totalCampaigns: number;
  searchDateTypes: Record<string, string>[] = [
    {
      name: 'Bokningsdatum',
      type: 'booked_date',
    },
    {
      name: 'Skapandedatum',
      type: 'created_at',
    },
    {
      name: 'Leveransperiod',
      type: 'period',
    },
    {
      name: 'Ändringsdatum',
      type: 'updated_at',
    },
  ];

  search: Record<string, any> = {
    searchText: '',
    to: '',
    from: '',
    searchActive: false,
    dateType: '',
    includeClosed: false,
    adOrganisation: '',
    customer: '',
  };
  scrolledYOffset = 0;
  activeCampaign: Campaign;
  dashboardExtended = false;
  cardListing = this.sessionService.getUserPreferences('dashboardAsCards');
  customers: CompactCustomer[] = [];
  isLoading: Record<DashboardLoad, boolean> = {
    campaign: false,
    campaigns: false,
    organisations: false,
    search: false,
  };
  showExtendedSearchFilters = false;

  campaignMap = {};
  filterAdOrganisations: AdOrganisation[];
  customersForUser: CompactCustomer[] = [];
  showCustomerNickname: (args: any) => string;

  constructor(
    private adOrganisationService: AdOrganisationService,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private dashboardStore: DashboardStore,
    private dialogService: DialogService,
    private googleTagManagerService: GoogleTagManagerService,
    private hotkeyService: HotkeyService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private sessionService: SessionService,
  ) {
    super();
    this.addSubscription(dashboardStore.state$.subscribe(this.initFromStore));

    this.addSubscription(this.hotkeyService.addShortcut({ keys: 'control.n' }).subscribe(() => this.goToNewCampaign()));
    this.addSubscription(
      this.hotkeyService.addShortcut({ keys: 'control.enter' }).subscribe(() => this.toggleExtended()),
    );

    this.addSubscription(this.routingService.onNavChange(this.onURLParamsChange));
    this.showCustomerNickname = this.customerDisplayName;
  }

  initFromStore = (model: DashboardModel): void => {
    this.activeCampaign = model.activeCampaign;
    this.dashboardExtended = model.dashboardIsExtended;
    this.isLoading = model.isLoading;
  };

  async ngOnInit(): Promise<void> {
    this.customers = await this.dashboardService.getCustomersForCurrentUser();
    const includeClosedCampaignsFilter = this.authService.hasAnyRole([
      UserRole.planner,
      UserRole.admin,
      UserRole.sales,
      UserRole.salesManager,
    ])
      ? ''
      : { includeClosed: true };
    this.dashboardStore.setIsLoading(DashboardLoad.campaigns, true);
    await this.dashboardService.loadDashboard(1, includeClosedCampaignsFilter);
    this.dashboardStore.setIsLoading(DashboardLoad.campaigns, false);
    this.totalCampaigns = this.dashboardService.totalCampaigns;
    this.initCampaignMap(this.dashboardService.campaigns);
    await this.onURLParamsChange();
    this.dashboardStore.setIsLoading(DashboardLoad.organisations, true);
    const organisations = await this.adOrganisationService.getAdOrganisations();
    this.dashboardStore.setIsLoading(DashboardLoad.organisations, false);
    this.filterAdOrganisations = organisations;
    this.allowedToBook = this.authService.allowedToBook();
    this.customersForUser = await this.dashboardService.getCustomersForCurrentUser();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dashboardService.dashboardExtended$.next(false);
    this.dashboardStore.clear();
  }

  initCampaignMap(campaignSets: CampaignSets): void {
    const campaignList = Object.values(campaignSets).flatMap((set: any) => set.list);
    this.campaignMap = campaignList.map((campaign) => {
      return { [campaign.id]: campaign };
    });
  }

  setTabFromSuggestedTab(campaign: Campaign, suggestedTab: DashboardTab): void {
    const tab = this.evaluateSuggestedStartTabForCampaign(campaign, suggestedTab);
    this.dashboardStore.setActiveTab(tab);
  }

  evaluateSuggestedStartTabForCampaign(campaign: Campaign, suggestedTab: DashboardTab): DashboardTab {
    const disabledState =
      (suggestedTab === DashboardTab.stats && !this.campaignService.isFollowup(campaign)) ||
      ((suggestedTab === DashboardTab.inventory || suggestedTab === DashboardTab.invoices) &&
        campaign.status === CampaignStatus.unbooked);

    return disabledState ? this.getCampaignStartTabName(campaign) : suggestedTab;
  }

  getCampaignStartTabName(campaign: Campaign): DashboardTab {
    return this.campaignService.isFollowup(campaign) ? DashboardTab.stats : DashboardTab.details;
  }

  handleTabChange = async (): Promise<void> => {
    const tabParam = this.routingService.getParam(this.route, 'tab');
    if (tabParam) {
      this.setTabFromSuggestedTab(this.activeCampaign, tabParam);
      this.extend();
    } else {
      this.minimize();
      await this.setTab(null);
      if (this.scrolledYOffset) {
        setTimeout(() => {
          scrollToOffset(this.scrolledYOffset);
          this.scrolledYOffset = 0;
        }, 100);
      }
    }
  };

  onURLParamsChange = async (): Promise<void> => {
    const idParam = this.routingService.getParam(this.route, 'id');
    if (!idParam) {
      return;
    } else if (this.activeCampaign && this.activeCampaign?.id === idParam) {
      await this.handleTabChange();
    } else {
      const campaign = Object.keys(this.campaignMap).includes(idParam) ? this.campaignMap[idParam] : { id: idParam };
      await this.setActiveCampaign(campaign);
      await this.handleTabChange();
    }
  };

  setActiveCampaign = async (newActiveCampaign: Campaign): Promise<void> => {
    this.dashboardStore.setIsLoading(DashboardLoad.campaign, true);
    const campaign = await this.campaignService.get(newActiveCampaign.id);
    this.dashboardStore.setIsLoading(DashboardLoad.campaign, false);
    this.dashboardStore.setActiveCampaign(campaign);
    try {
      this.dashboardStore.setCreativeEditCriteria(
        await this.dashboardService.checkAllowedToEditCreativesCriteria(campaign),
      );
    } catch {
      this.dashboardStore.setIsLoading(DashboardLoad.campaign, false);
    }
  };

  async toggleExtended(): Promise<void> {
    if (this.dashboardExtended) {
      this.googleTagManagerService.dashboardCollapsedWithToggleExtendedButton();
      await this.routingService.goToDashboardTab(this.activeCampaign.id, null);
    } else {
      this.scrolledYOffset = window.scrollY;
      const tab = this.getCampaignStartTabName(this.activeCampaign);
      await this.setTab(tab);
    }
  }

  showTooltip(targetElement: HTMLElement): void {
    showTooltip(targetElement);
  }

  minimize(): void {
    this.dashboardStore.setDashboardIsExtended(false);
    this.dashboardService.dashboardExtended$.next(false);
  }

  extend(): void {
    scrollToTop();
    this.dashboardStore.setDashboardIsExtended(true);
    this.dashboardService.dashboardExtended$.next(true);
  }

  setCardListing(): void {
    this.cardListing = !this.cardListing;
    this.sessionService.updateUserPreferences('dashboardAsCards', this.cardListing);
  }

  async setTab(tab: DashboardTab): Promise<void> {
    await this.routingService.goToDashboardTab(this.activeCampaign.id, tab);
  }

  async filterCampaigns(): Promise<void> {
    this.search.searchActive = true;
    this.dashboardStore.setIsLoading(DashboardLoad.campaigns, true);
    try {
      await this.dashboardService.loadDashboard(1, this.search);
    } finally {
      this.dashboardStore.setIsLoading(DashboardLoad.campaigns, false);
    }
  }

  async resetSearchText(): Promise<void> {
    this.search.searchText = '';
    this.search.searchActive = false;
    this.dashboardStore.setIsLoading(DashboardLoad.campaigns, true);
    try {
      await this.dashboardService.loadDashboard(1, this.search);
    } finally {
      this.dashboardStore.setIsLoading(DashboardLoad.campaigns, false);
    }
  }

  openSearchInfo(): void {
    this.dialogService.create(SearchInfoDialog).open();
  }

  goToNewCampaign = async (): Promise<void> => {
    await this.routingService.goToNewBooking();
  };

  private customerDisplayName = (customer: Customer): string => {
    if (this.adminOrPlanner && !!customer.nickname?.length && customer.name !== customer.nickname)
      return `${customer.name} (${customer.nickname})`;

    return customer.name;
  };
}
