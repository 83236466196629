import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { TermsService } from '../../woo_services.module/TermsService';

@Injectable({ providedIn: 'root' })
class TermsPageGuardService {
  constructor(private termsService: TermsService) {}
  canActivate(): boolean {
    if (this.termsService.userNeedsToAcceptTerms()) {
      return true;
    }
    return false;
  }
}

export const TermsPageGuard: CanActivateFn = (): boolean => {
  return inject(TermsPageGuardService).canActivate();
};
