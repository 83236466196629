import { Component, Input } from '@angular/core';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { wooId } from '../../../../woo_services.module/shared-types';
import {
  TargetingMapping,
  TargetingMappingService,
  TargetingMappingType,
} from '../../../../woo_services.module/TargetingMappingService';

@Component({
  selector: 'targeting-mappings-list',
  templateUrl: './targeting-mappings-list.component.html',
})
export class TargetingMappingsList {
  @Input() mappings: TargetingMapping[];
  @Input() heading: string;
  @Input() allowEdit: boolean;

  show = {
    section: true,
    loading: true,
  };
  readonly tableHeaders = ['Namn', 'Styrning', 'Faktor', 'Startdatum', 'Skapad', 'Redigera'];

  constructor(private routingService: RoutingService, private ttvService: TargetingMappingService) {}

  editTargetingMapping = (id: wooId): void => {
    this.routingService.navigate(['/products', 'ttv', id, 'edit']);
  };

  toggleSection = (): void => {
    this.show.section = !this.show.section;
  };

  getTargetingType = (mapping: TargetingMapping): TargetingMappingType => {
    return this.ttvService.getTargetingType(mapping);
  };
}
