import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'cash-video-code-input',
  template: `
    <div class="input__container">
      <label for="asset-code">{{ label }}</label>
      <input
        name="videoCode"
        type="text"
        id="asset-code"
        [(ngModel)]="model"
        (ngModelChange)="changes()"
        required
        cashVideoCodeValidator
        #codeInput="ngModel"
      />
    </div>
    <div *ngIf="codeInput.errors && codeInput.dirty">
      <p *ngIf="codeInput.errors.prefix" class="input__error__text">{{ codeInput.errors.prefix }}</p>
      <p *ngIf="codeInput.errors.codeLength" class="input__error__text">{{ codeInput.errors.codeLength }}</p>
      <p *ngIf="codeInput.errors.filmLength" class="input__error__text">{{ codeInput.errors.filmLength }}</p>
      <p *ngIf="codeInput.errors.typeCode" class="input__error__text">{{ codeInput.errors.typeCode }}</p>
    </div>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CashVideoCodeInput {
  @Input() model: string;
  @Input() label: string;
  @Output() modelChange = new EventEmitter<string>();

  changes = (): void => {
    this.modelChange.emit(this.model);
  };
}
