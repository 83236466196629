<div class="block">
  <h1>Systeminställningar</h1>

  <button
    *ngIf="isAdmin"
    class="tabbable no-outline"
    (click)="setTab(0)"
    [ngClass]="{active : tabVisible === 0}"
  >System</button>
  <button
    *ngIf="isAdmin"
    class="tabbable no-outline"
    (click)="setTab(1)"
    [ngClass]="{active : tabVisible === 1}"
  >Publicistgrupper</button>
  <button
    *ngIf="isAdmin"
    class="tabbable no-outline"
    (click)="setTab(2)"
    [ngClass]="{active : tabVisible === 2}"
  >Systemmeddelanden</button>
  <button
    *ngIf="isAdminOrPlanner"
    class="tabbable no-outline"
    (click)="setTab(3)"
    [ngClass]="{active : tabVisible === 3}"
  >MMS</button>

 
  <div class="account__card" *ngIf="tabVisible === 0">
    <system-settings-form
      *ngIf="isAdmin"></system-settings-form>
  </div>
  <div class="account__card" *ngIf="tabVisible === 1">
    <publisher-group-settings
      *ngIf="isAdmin"></publisher-group-settings>
  </div>
  <div *ngIf="tabVisible === 2">
    <system-messages
      *ngIf="isAdmin"></system-messages>
  </div>
  <div class="account__card" *ngIf="tabVisible === 3">
    <mms-admin
      *ngIf="isAdminOrPlanner"></mms-admin>
  </div>
</div>
