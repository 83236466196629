<div class="followup inner-block">
  <button
    class="tabbable no-outline"
    (click)="setTab(DashboardTab.details)"
    [ngClass]="{active : activeTab === DashboardTab.details}"
  >
    Detaljer
  </button>
  <button
    class="tabbable no-outline"
    (click)="setTab(DashboardTab.stats)"
    [ngClass]="{active : activeTab === DashboardTab.stats, disabled: !isFollowup}"
    [disabled]="!isFollowup"
  >
    Statistik
  </button>
  <button
    class="tabbable no-outline text-capitalize"
    (click)="setTab(DashboardTab.creatives)"
    [ngClass]="{active : activeTab === DashboardTab.creatives}"
  >
    Material
  </button>
  <button
    class="tabbable no-outline"
    (click)="setTab(DashboardTab.inventory)"
    [disabled]="campaign?.status === 'unbooked'"
    [ngClass]="{active : activeTab === DashboardTab.inventory}"
    *ngIf="maySeeInventory"
  >
    Lager
  </button>
  <button
    class="tabbable no-outline"
    (click)="setTab(DashboardTab.invoices)"
    [disabled]="campaign?.status === 'unbooked' || campaign?.ttv_campaign"
    [ngClass]="{active : activeTab === DashboardTab.invoices}"
    *ngIf="maySeeInvoices"
  >
    Fakturor
  </button>
  <button
    class="tabbable no-outline"
    (click)="setTab(DashboardTab.goals)"
    [ngClass]="{active : activeTab === DashboardTab.goals}"
    [disabled]="!campaignHasGoals"
    *ngIf="maySeeGoals && campaign?.status !== 'unbooked'"
  >
    Mål
  </button>
  <button
    class="tabbable no-outline"
    (click)="setTab(DashboardTab.history)"
    [ngClass]="{active : activeTab === DashboardTab.history}"
    *ngIf="maySeeHistory"
  >
    Historik
  </button>
  <button
    class="tabbable no-outline"
    (click)="setTab(DashboardTab.admin)"
    [disabled]="campaign?.ttv_campaign"
    [ngClass]="{active : activeTab === DashboardTab.admin}"
    *ngIf="maySeeAdmin"
  >
    Admin
  </button>
  <button
    class="tabbable no-outline"
    (click)="setTab(DashboardTab.ttv)"
    [ngClass]="{active : activeTab === DashboardTab.ttv}"
    *ngIf="maySeeTtv && campaign?.ttv_campaign"
  >
    TTV
  </button>

  <div *ngIf="activeTab === DashboardTab.details">
    <dashboard-details [campaign]="campaign"></dashboard-details>
  </div>
  <div *ngIf="activeTab === DashboardTab.stats">
    <outcome></outcome>
  </div>
  <div *ngIf="activeTab === DashboardTab.creatives">
    <creatives-details></creatives-details>
  </div>
  <div *ngIf="activeTab === DashboardTab.inventory && maySeeInventory">
    <campaign-inventory [campaign]="campaign"></campaign-inventory>
  </div>
  <div *ngIf="activeTab === DashboardTab.invoices">
    <invoices
      [campaign]="campaign"
    >
    </invoices>
  </div>
  <div *ngIf="activeTab === DashboardTab.goals && maySeeGoals">
    <campaign-goals></campaign-goals>
  </div>
  <div *ngIf="activeTab === DashboardTab.history && maySeeHistory">
    <campaign-history [campaign]="campaign"></campaign-history>
  </div>
  <div *ngIf="activeTab === DashboardTab.admin && maySeeAdmin">
    <admin-follow-up [campaign]="campaign"></admin-follow-up>
  </div>
  <div *ngIf="activeTab === DashboardTab.ttv && maySeeTtv">
    <ttv-bookings [campaign]="campaign"></ttv-bookings>
  </div>
</div>
