<woo-dialog>
<div class="block">

  <h1>{{campaign.name}}</h1>

  <table class="table table--campaign">
    <tbody>
      <tr>
        <td>Kampanjperiod</td>
        <td>{{campaign.start_date | date:'yyyy-MM-dd'}} - {{campaign.end_date | date:'yyyy-MM-dd'}}</td>
      </tr>
      <tr>
        <td>Externt ID</td>
        <td>{{campaign.external_id}}</td>
      </tr>
      <tr>
        <td>Internt ID</td>
        <td>{{campaign.id}}</td>
      </tr>
      <tr *ngIf="campaign.goal_type !== 'SHARE_OF_VOICE'">
        <td>Impr (Tot/Relevant)</td>
        <td>{{campaign.goal_total}} / {{campaign.rbs_total}}</td>
      </tr>
      <tr>
        <td>Prioritet</td>
        <td>{{campaign.priority || "-" }}</td>
      </tr>
      <tr *ngIf="campaign.publisher_names.length">
        <td>Publicister</td>
        <td>
          <ul class="reset--list" *ngIf="campaign.publisher_names">
            <li *ngFor="let publisher of campaign.publisher_names">
              <span class="tab tab--small">
                {{publisher}}
              </span>
            </li>
          </ul>
        </td>
      </tr>
      <tr *ngIf="campaign.category_names.length">
        <td>Kategorier</td>
        <td>
          <ul class="reset--list" *ngIf="campaign.category_names">
            <li *ngFor="let category of campaign.category_names">
              <span class="tab tab--small">
                {{category}}
              </span>
            </li>
          </ul>
        </td>
      </tr>
      <tr *ngIf="campaign.region_names.length">
        <td>Regioner</td>
        <td>
          <ul class="reset--list" *ngIf="campaign.region_names">
            <li *ngFor="let region of campaign.region_names">
              <span class="tab tab--small">
                {{region}}
              </span>
            </li>
          </ul>
        </td>
      </tr>
      <tr *ngIf="campaign.program_format_names.length">
        <td>Programformat</td>
        <td>
          <ul class="reset--list" *ngIf="campaign.program_format_names">
            <li *ngFor="let item of campaign.program_format_names">
              <span class="tab tab--small">
                {{item}}
              </span>
            </li>
          </ul>
        </td>
      </tr>
      <tr *ngIf="campaign.age_target_group_names.length">
        <td>Målgrupper</td>
        <td>
          <ul class="reset--list" *ngIf="campaign.gender_target_group_names || campaign.age_target_group_names">
            <li *ngFor="let item of campaign.gender_target_group_names.concat(campaign.age_target_group_names)">
              <span class="tab tab--small">
                {{item}}
              </span>
            </li>
          </ul>
        </td>
      </tr>
      <tr *ngIf="campaign.behavior_names.length">
        <td>Beteendesegment</td>
        <td>
          <ul class="reset--list" *ngIf="campaign.behavior_names">
            <li *ngFor="let item of campaign.behavior_names">
              <span class="tab tab--small">
                {{item}}
              </span>
            </li>
          </ul>
        </td>
      </tr>
      <tr *ngIf="campaign.advanced_target_group_names.length">
        <td>Avancerade målgruppssegment</td>
        <td>
          <ul class="reset--list" *ngIf="campaign.advanced_target_group_names">
            <li *ngFor="let item of campaign.advanced_target_group_names">
              <span class="tab tab--small">
                {{item}}
              </span>
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>

  <h2>Mål ({{campaign.external_goals.length}} st)</h2>
  <div *ngFor="let goal of campaign.external_goals">
    <h3>{{goal.name}}</h3>
    <table class="table table--campaign">
      <tbody>
        <tr>
          <td>Målperiod</td>
          <td>{{goal.start_date | date:'yyyy-MM-dd'}} - {{goal.end_date | date:'yyyy-MM-dd'}}</td>
        </tr>
        <tr>
          <td>Typ</td>
          <td>{{goal.type}}</td>
        </tr>
        <tr>
          <td>Estimat</td>
          <td>{{goal.impressions}} impressions</td>
        </tr>
        <tr *ngIf="goal.type === 'SHARE_OF_VOICE'">
          <td>Share of Voice</td>
          <td>{{goal.share_of_voice_factor * 100}}%</td>
        </tr>
        <tr>
          <td>Placement</td>
          <td>{{goalFormatLabel(goal.format)}}</td>
        </tr>
        <tr *ngIf="goal.slot_types">
          <td>Slot-types</td>
          <td>
            <ul class="reset--list">
              <li *ngFor="let slot_type of goal.slot_types">
                <span class="tab tab--small">
                  {{slot_type}}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="goal.publisher_names.length">
          <td>Publicister</td>
          <td>
            <ul class="reset--list" *ngIf="goal.publisher_names">
              <li *ngFor="let item of goal.publisher_names">
                <span class="tab tab--small">
                  {{item}}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="goal.category_names.length">
          <td>Kategorier</td>
          <td>
            <ul class="reset--list" *ngIf="goal.category_names">
              <li *ngFor="let item of goal.category_names">
                <span class="tab tab--small">
                  {{item}}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="goal.region_names.length">
          <td>Regioner</td>
          <td>
            <ul class="reset--list" *ngIf="goal.region_names">
              <li *ngFor="let item of goal.region_names">
                <span class="tab tab--small">
                  {{item}}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="goal.program_format_names.length">
          <td>Programformat</td>
          <td>
            <ul class="reset--list" *ngIf="goal.program_format_names">
              <li *ngFor="let item of goal.program_format_names">
                <span class="tab tab--small">
                  {{item}}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="goal.gender_target_group_names.length || goal.age_target_group_names.length">
          <td>Målgrupper</td>
          <td>
            <ul class="reset--list" *ngIf="goal.gender_target_group_names || goal.age_target_group_names">
              <li *ngFor="let item of goal.gender_target_group_names.concat(goal.age_target_group_names)">
                <span class="tab tab--small">
                  {{item}}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="goal.behavior_names.length">
          <td>Beteendesegment</td>
          <td>
            <ul class="reset--list" *ngIf="goal.behavior_names">
              <li *ngFor="let item of goal.behavior_names">
                <span class="tab tab--small">
                  {{item}}
                </span>
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="goal.advanced_target_group_names.length">
          <td>Avancerade målgruppssegment</td>
          <td>
            <ul class="reset--list" *ngIf="goal.advanced_target_group_names">
              <li *ngFor="let item of goal.advanced_target_group_names">
                <span class="tab tab--small">
                  {{item}}
                </span>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <h2 *ngIf="inventoryTree">Utnyttjat lager</h2>
    <div>
      <inventory-visualization
          [inventoryTree]="inventoryTree"
          [filterOptions]="filterOptions"
          [columnNames]="inventoryColumnNames">
        </inventory-visualization>
    </div>
</div>
</woo-dialog>
