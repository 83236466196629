import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { VALID_VIDEO_LENGTHS } from '../../advanced_campaign_booking.module/services/BookingValidationService';
import { setFormDirty } from '../../utils/form';
import { Editable, Hidden, LayoutProfile, SimpleChanges, Visible } from '../../utils/types';
import { UserRole } from '../../utils/user-roles';
import { mergeErrors, validateArray, validateRequired, validateUrl, validateUrlPrefix } from '../../utils/validators';
import { AuthService } from '../../woo_services.module/AuthService';
import { CreativeData, CreativeService } from '../../woo_services.module/CreativeService';
import { FormatterService } from '../../woo_services.module/FormatterService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import {
  CodeService,
  Creative,
  ImageCreativeType,
  ProductFormatConditions,
  VideoCreativeType,
  wooId,
} from '../../woo_services.module/shared-types';
import { CodeServiceFactory } from '../../woo_services.module/video_code_validation/CodeServiceFactory';

@Component({
  selector: 'instream-creative-form',
  templateUrl: './instream-creative-form.component.html',
})
export class InstreamCreativeForm implements OnChanges {
  readonly HTTPS_URL_VALIDATORS = mergeErrors(validateUrlPrefix('https'), validateUrl());
  readonly VIDEO_CODE_VALIDATORS = mergeErrors(
    (c) => this.validateAssetUrlRequired(c),
    validateUrlPrefix('https'),
    validateUrl(),
  );
  readonly VideoCreativeType = VideoCreativeType;
  readonly creativeVideoTypes = this.creativeService.VIDEO_CREATIVE_TYPES;
  readonly translateCreativeTypes = this.translationService.convertCreativeTypes;
  readonly getCreativeName = this.formatterService.getCreativeName;

  @Input() customerId: wooId;
  @Input() creative: CreativeData = null;
  @Input() creatives: Creative[] = [];
  @Input() creativeLengths: number[];
  @Input() productFormatConditions: ProductFormatConditions;
  @Input()
  layoutProfile: InstreamCreativeFormLayoutProfile = {
    heading: Visible,
    length: Editable,
    actionButtons: Editable,
    agAffectInventoryInfo: Visible,
    agDisabledInfo: Hidden,
  };
  @Input() ongoingCreative = false;
  @Input() disableAG = false;
  @Output() onAbort = new EventEmitter();
  @Output() onSubmit = new EventEmitter<CreativeData>();
  @Output() onValidityChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<CreativeData>();

  editing = false;

  automaticallyMarkDirty = false;

  form: FormGroup<FormModel> = this.fb.group<FormModel>({
    creativeType: new FormControl(null, validateRequired()),
    assetUrl: new FormControl(null, this.VIDEO_CODE_VALIDATORS),
    videoCode: new FormControl(
      null,
      mergeErrors(
        () => this.validateVideoCodeRequired(),
        (c: FormControl) => this.validateVideoCode(c),
        (c) => this.validatePlaceholderVideoCode(c),
      ),
    ),
    filmLength: new FormControl(
      null,
      mergeErrors(validateRequired(), (c: FormControl) => this.validateFilmLength(c)),
    ),
    name: new FormControl(null),
    destinationUrl: new FormControl(null, mergeErrors(validateUrlPrefix('https'), validateUrl())),
    clickTrackUrl: new FormControl(null, mergeErrors(validateUrlPrefix('https'), validateUrl())),
    impTrackUrls: new FormControl([], validateArray(this.HTTPS_URL_VALIDATORS)),
    customText: new FormControl(null, () => this.validateCustomTextRequired()),
  });

  get creativeType(): FormControl {
    return this.form.controls.creativeType;
  }

  get videoCode(): FormControl {
    return this.form.controls.videoCode;
  }

  get filmLength(): FormControl {
    return this.form.controls.filmLength;
  }

  get assetUrl(): FormControl {
    return this.form.controls.assetUrl;
  }

  get customText(): FormControl {
    return this.form.controls.customText;
  }

  get videoCodeRequired(): boolean {
    return (
      ((this.showVideoUrlInput || this.showVideoUrlSelect) &&
        this.assetUrl.value &&
        this.creativeType.value !== VideoCreativeType.ag) ||
      (this.ongoingCreative && this.creativeType.value !== VideoCreativeType.ag)
    );
  }

  get customTextRequired(): boolean {
    return this.creativeType.value === VideoCreativeType.other && !this.assetUrlRequired && !this.assetUrl.value;
  }

  get assetUrlRequired(): boolean {
    return (
      this.ongoingCreative && [VideoCreativeType.adstream, VideoCreativeType.other].includes(this.creativeType.value)
    );
  }

  /**
   * Whether or not the user should be allowed to supply a video url as a string
   */
  get showVideoUrlInput(): boolean {
    return this.creativeType.value === VideoCreativeType.ag;
  }

  /**
   * Whether or not the user should be allowed to select a previously uploaded film or supply a string
   */
  get showVideoUrlSelect(): boolean {
    return (this.authService.hasAnyRole([UserRole.admin, UserRole.planner])
      ? [VideoCreativeType.adstream, VideoCreativeType.other]
      : []
    ).includes(this.creativeType.value);
  }

  get videoUrlLabel(): string {
    return this.creativeType.value === VideoCreativeType.ag ? 'VAST-URL' : 'Video-URL';
  }

  get videoCreatives(): Creative[] {
    if (this.productFormatConditions && this.productFormatConditions.onlyShortFormat) {
      return this.creatives
        .filter((c) => c.creative_type !== 'pause_image')
        .filter((c) => c.length <= VALID_VIDEO_LENGTHS.maxLimitShortFormat);
    }
    return this.creatives.filter((c) => c.creative_type !== 'pause_image');
  }

  constructor(
    private fb: FormBuilder,
    private creativeService: CreativeService,
    private serviceFactory: CodeServiceFactory,
    private translationService: TranslationService,
    private authService: AuthService,
    private formatterService: FormatterService,
  ) {
    this.setCreative(this.creativeService.createCreative());
    this.videoCode.valueChanges.subscribe(this.setFilmLengthFromCode);

    this.creativeType.valueChanges.subscribe(() => this.prepareFormForNewCreativeType());
    this.videoCode.valueChanges.subscribe(() => this.filmLength.updateValueAndValidity());
    this.assetUrl.valueChanges.subscribe(() => this.customText.updateValueAndValidity());
    this.assetUrl.valueChanges.subscribe(() => this.videoCode.updateValueAndValidity());
    this.form.valueChanges.subscribe(() => this.onChange.emit(this.getCreativeData()));
    this.form.statusChanges.subscribe(() => this.onValidityChange.emit(this.form.valid));
  }

  ngOnChanges(changes: SimpleChanges<InstreamCreativeForm>): void {
    if (changes.creative && this.creative) {
      this.setCreative(this.creative);
      this.editing = true;

      if (this.layoutProfile.actionButtons === Hidden) {
        this.automaticallyMarkDirty = true;
      }
    }

    if (changes.layoutProfile) {
      this.layoutProfile.length.disabled
        ? this.form.controls.filmLength.disable()
        : this.form.controls.filmLength.enable();
    }
  }

  prepareFormForNewCreativeType = (): void => {
    this.assetUrl.reset();

    this.assetUrl.updateValueAndValidity();
    this.videoCode.updateValueAndValidity();
    this.customText.updateValueAndValidity();
    this.resetAllValidAutoMarkedAsDirtyControls(this.form);
  };

  setCreative = (creative: CreativeData): void => {
    if (creative) {
      const formValues = {
        creativeType: creative.creative_type,
        assetUrl: creative.asset_url,
        videoCode: creative.video_code,
        filmLength: creative.length,
        name: creative.custom_name,
        destinationUrl: creative.destination_url,
        impTrackUrls: creative.impression_tracking_urls,
        clickTrackUrl: creative.click_tracking_url,
        customText: creative.custom_text,
      };
      this.form.patchValue(formValues, { onlySelf: true, emitEvent: true });
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }
  };

  submit = (): void => {
    if (!this.form.valid) {
      setFormDirty(this.form);
      return;
    }
    const data = this.getCreativeData();

    this.onSubmit.emit(data);
  };

  abort = (): void => {
    this.onAbort.emit();
  };

  duplicateCreative(index: number): void {
    if (index) {
      const creative = this.creativeService.duplicateCreative(index, this.creatives);
      if (this.ongoingCreative && this.creativeType.value === VideoCreativeType.peach) creative.asset_url = null;
      this.setCreative(creative);
      this.assetUrl.updateValueAndValidity(); // Needed because assetUrl is not set yet when validators run from setCreative probably because of asset url input component
      this.form.markAsDirty();
    }
  }

  useVideoCodeFromSelected(videoCode: string): void {
    this.videoCode.setValue(videoCode);
  }

  private resetAllValidAutoMarkedAsDirtyControls(form: FormGroup<FormModel>) {
    if (!this.automaticallyMarkDirty) {
      return;
    }

    Object.values(form.controls).forEach((control) => {
      if (control.dirty && control.untouched && control.valid) {
        control.markAsPristine();
      }
    });
  }

  private autoMarkAsDirty(control: AbstractControl) {
    if (this.automaticallyMarkDirty) {
      control.markAsDirty();
    }
  }

  private getCreativeData() {
    const value = this.form.value;
    const data = {
      creative_type: value.creativeType,
      asset_url: value.assetUrl,
      video_code: value.videoCode.trim(),
      length: value.filmLength,
      custom_name: value.name,
      destination_url: value.destinationUrl,
      impression_tracking_urls: (value.impTrackUrls as string[]).filter(Boolean),
      click_tracking_url: value.clickTrackUrl,
      custom_text: value.customText,
    };
    return data;
  }

  private validateCustomTextRequired(): ValidationErrors | null {
    if (!this.form) {
      return null;
    }

    if (this.customTextRequired && !this.customText.value) {
      this.autoMarkAsDirty(this.form.controls.customText);
      return { required: 'Obligatoriskt' };
    }

    return null;
  }

  private validateAssetUrlRequired(control: AbstractControl): ValidationErrors | null {
    if (!this.form) {
      return null;
    }

    if (this.assetUrlRequired && !control.value) {
      this.autoMarkAsDirty(control);
      return { required: 'Obligatoriskt' };
    }

    return null;
  }

  private validateVideoCodeRequired(): ValidationErrors | null {
    if (!this.form) {
      return null;
    }

    if (this.videoCodeRequired && !this.videoCode.value) {
      this.autoMarkAsDirty(this.form.controls.videoCode);
      return { required: 'Fältet är obligatoriskt' };
    }
    return null;
  }

  private validatePlaceholderVideoCode(c: AbstractControl): ValidationErrors {
    if (!this.form || !this.videoCode.value) {
      return null;
    }

    const code: string = c.value;
    if (code && code.includes('xxxx')) {
      return { possiblyInvalidCode: 'Utelämna istället för att ange en placeholder' };
    }
    return null;
  }

  private validateVideoCode(control: AbstractControl): ValidationErrors | null {
    if (!this.form || !this.codeService || !control.value) {
      return null;
    }

    let errors = this.codeService.validate(control.value) as ValidationErrors;

    const lengthIsStaticallySet = this.filmLength.disabled && this.filmLength.value;

    if (lengthIsStaticallySet && this.codeService.getFilmLength(control.value) !== this.filmLength.value) {
      errors = { ...errors, filmLengthFitsStaticLength: 'Koden matchar inte filmlängden' };
    }

    if (Object.keys(errors).length > 0) {
      this.autoMarkAsDirty(control);
    }

    return errors;
  }

  private validateFilmLength(control: AbstractControl): ValidationErrors | null {
    if (!this.form || !this.codeService || !control.value) {
      return null;
    }

    const isStaticallySet = this.filmLength.disabled && control.value;
    const canCompareWithCode = this.videoCode.value && this.codeService.getFilmLength(this.videoCode.value) !== null;
    const codeContainsAValidFilmLength = this.creativeLengths.includes(
      this.codeService.getFilmLength(this.videoCode.value),
    );
    const input_length_does_not_match_code = this.codeService.getFilmLength(this.videoCode.value) !== control.value;

    const invalid_input =
      canCompareWithCode && !isStaticallySet && (input_length_does_not_match_code || !codeContainsAValidFilmLength);

    if (invalid_input) {
      return { videoCodeAndLengthMismatch: 'Längden matchar inte filmkoden' };
    }

    return null;
  }

  private setFilmLengthFromCode = () => {
    const code = this.videoCode.value;
    if (!this.codeService || this.filmLength.disabled) {
      return;
    }
    const filmLength = this.codeService.getFilmLength(code);
    if (this.creativeLengths.includes(filmLength)) {
      this.form.controls.filmLength.setValue(filmLength, { emitEvent: false });
    }
  };

  private get codeService(): CodeService {
    return this.serviceFactory.getService(this.creativeType.value);
  }
}

interface FormModel {
  creativeType: FormControl<VideoCreativeType | ImageCreativeType | ''>; //This should never be an ImageCreativeType, this component handles InstreamCreatives
  assetUrl: FormControl<string>;
  videoCode: FormControl<string>;
  filmLength: FormControl<number>;
  name: FormControl<string>;
  destinationUrl: FormControl<string>;
  clickTrackUrl: FormControl<string>;
  impTrackUrls: FormControl<string[]>;
  customText: FormControl<string>;
}

export interface InstreamCreativeFormLayoutProfile {
  heading: LayoutProfile;
  length: LayoutProfile;
  actionButtons: LayoutProfile;
  agAffectInventoryInfo: LayoutProfile;
  agDisabledInfo: LayoutProfile;
}
