import { addDays, addMonths, addWeeks } from 'date-fns';
import { sortBy } from 'lodash-es';
import { Segment } from '../woo_services.module/shared-types';
import { compact, orderBy } from './array';
import { formatDate } from './object';
import { DURATIONS } from './time-constants';

export function removeTime(date: Date): Date {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function inPeriod(periodDate1: Date, periodDate2: Date, date: Date): boolean {
  if (!periodDate1 || !periodDate2) {
    return false;
  }
  const [start, end] = sortBy([periodDate1, periodDate2], (d) => d.getTime());
  return start.getTime() <= date.getTime() && date.getTime() <= end.getTime();
}

export function isDateBefore(date1: Date | string, date2: Date | string): boolean {
  return removeTime(new Date(date1)).getTime() < removeTime(new Date(date2)).getTime();
}

export function isDateAfter(date1: Date | string, date2: Date | string): boolean {
  return removeTime(new Date(date1)).getTime() > removeTime(new Date(date2)).getTime();
}

export function orderByDate<T, K extends keyof T>(array: T[], prop: K, order: 'asc' | 'desc' = 'desc'): T[] {
  const compareFn = compareWithDate(prop, order);
  return array.sort(compareFn);
}

export function sameDate(date1: Date | string, date2: Date | string): boolean {
  return removeTime(new Date(date1)).getTime() === removeTime(new Date(date2)).getTime();
}

export function beginningOfMonth(date: Date): Date {
  const newDate = removeTime(new Date(date));
  newDate.setDate(1);
  return newDate;
}

export function min(dates: Date[]): Date {
  return dates.reduce((l, r) => (l.getTime() < r.getTime() ? l : r));
}

export function max(dates: Date[]): Date {
  return dates.reduce((l, r) => (l.getTime() > r.getTime() ? l : r));
}

export function subtract(date: Date, days: number, period = TimePeriod.Day): Date {
  return add(date, -days, period);
}

export function add(base: Date, amount: number, period = TimePeriod.Day): Date {
  switch (period) {
    case TimePeriod.Day:
      return addDays(base, amount);
    case TimePeriod.Week:
      return addWeeks(base, amount);
    case TimePeriod.Month:
      return addMonths(base, amount);
    default:
      throw new Error(`Unsupported TimePeriod: ${period}`);
  }
}

export function getDays(start: Date, end: Date): Date[] {
  const days = [];
  for (let date = new Date(start); date.getTime() < end.getTime(); date = add(date, 1)) {
    days.push(date);
  }
  return days;
}

export function getWeekNumber(date: Date): number {
  const [__, weekNo] = getYearAndWeekNumber(date);
  return weekNo;
}

/** For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 *
 * @returns [year, weekNumber]
 */
export function getYearAndWeekNumber(d: Date): [number, number] {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

export function getMaxPeriod(
  items: Array<{ start_date: string | Date; end_date: string | Date }>,
): { start: Date; end: Date } {
  const startDates = compact(items.map((item) => item.start_date)).map((i) => new Date(i));
  const endDates = compact(items.map((item) => item.end_date)).map((i) => new Date(i));

  return {
    start: startDates.length > 0 ? min(startDates) : null,
    end: endDates.length > 0 ? max(endDates) : null,
  };
}

export function compareWithDate(property: any, order: 'asc' | 'desc') {
  return (left: any, right: any): number => {
    const a = new Date(left[property]);
    const b = new Date(right[property]);
    if (order === 'desc') {
      return a > b ? -1 : a < b ? 1 : 0;
    } else {
      return a > b ? 1 : a < b ? -1 : 0;
    }
  };
}

export function mergeAdjacentSegments(segments: Segment[]): Segment[] {
  return orderBy(segments, 'start_date').reduce((curr, segment) => {
    if (curr.length === 0) {
      return [Object.assign({}, segment)];
    }
    const lastSegment = curr[curr.length - 1];
    if (adjacentDays(lastSegment.end_date, segment.start_date)) {
      lastSegment.end_date = segment.end_date;
      return curr;
    } else {
      return curr.concat([Object.assign({}, segment)]);
    }
  }, []);
}

/**
 * Returns true if difference between two dates is less then one day
 */
export function adjacentDays(d1: string | Date, d2: string | Date): boolean {
  return Math.abs(new Date(d1).getTime() - new Date(d2).getTime()) <= DURATIONS.oneDay;
}

/**
 * Parse a string on format YYYY-MM-DD and returns a string or null if the date was invalid
 */
export function parseWooDate(dateString: string): Date | null {
  if (WOO_DATE_PATTERN.test(dateString)) {
    const [year, month, day] = WOO_DATE_PATTERN.exec(dateString).slice(1, 4).map(Number);
    if (1 <= month && month <= 12 && 0 < day && day <= daysInMonth(year, month)) {
      return new Date(year, month - 1, day);
    }
  }
  return null;
}

export function formatWooDate(date: Date): string {
  return formatDate(date);
}

/**
 * This is not really date min since we want a valid date after we have cleared time properties
 *   it is however close enough to use as a min value
 */
export const MIN_DATE = new Date(-8630000000000000);

/**
 * This is not really date max since we want a valid date after we have cleared time properties
 *   it is however close enough to use as a max value
 */
export const MAX_DATE = new Date(8630000000000000);

export const enum TimePeriod {
  Day,
  Week,
  Month,
}

/**
 * This is the official date pattern used in WOO Manager
 */
export const WOO_DATE_PATTERN = /^(\d{4})-(\d{2})-(\d{2})$/;

function leapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

/**
 * @param year The year number (inf - inf)
 * @param month The month number (1 - 12)
 */
function daysInMonth(year: number, month: number) {
  const daysPerMonth = { 1: 31, 2: 28, 3: 31, 4: 30, 5: 31, 6: 30, 7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31 };
  return month === 2 && leapYear(year) ? 29 : daysPerMonth[month];
}
