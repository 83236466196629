import { HttpClient, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WooUploadService {
  constructor(private http: HttpClient) {}

  async upload<T>(url: string, file: File, params: { [key: string]: any } = {}): Promise<T> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const options = {
      params: Object.entries(params).reduce((httpParams, [key, value]) => httpParams.set(key, value), new HttpParams()),
    };
    const req = new HttpRequest('POST', url, formData, options);
    return ((await lastValueFrom(this.http.request<T>(req))) as HttpResponse<T>).body;
  }
}
