import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { DialogService } from '../../../../woo_services.module/DialogService';
import {
  FrequencyLimitService,
  ProductFormatWithdrawalLimitsParams,
} from '../../../../woo_services.module/FrequencyLimitService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { PublisherService } from '../../../../woo_services.module/PublisherService';
import {
  FrequencyLimit,
  ProductFormat,
  Publisher,
  SlotType,
  SlotTypeEstimates,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-frequency-limits',
  templateUrl: './manage-frequency-limits.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageFrequencyLimits implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    estimatesHaveChanged: false,
    loading: true,
    isEditing: false,
  };

  readonly staticFields = 4;
  readonly tableHeaders = ['Namn', 'Publicist', 'Placering', 'impressions', 'Preroll', 'Midroll', 'Postroll'];

  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  frequencyLimitRows: FrequencyLimitRow[];
  frequencyLimits: FrequencyLimit[] = [];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  translateProductFormat: (args: any) => string;
  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private frequencyLimitService: FrequencyLimitService,
    private utilityService: ProductsPageUtilityService,
    private dialogService: DialogService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.frequencyLimitService
      .getAll()
      .then((frequencyLimits) => {
        this.frequencyLimits = frequencyLimits;
      })
      .then(() => {
        this.publisherService.getPublishers().then((publishers) => {
          this.publishers = publishers.filter((p) => p.enabled);
        });
      })
      .then(() => {
        this.getAndBuildFrequencyLimitRows();
      })
      .finally(() => {
        this.show.loading = false;
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
        scrollTo(this.parentHeader);
      });
  }

  async getAndBuildFrequencyLimitRows(): Promise<void> {
    this.productFormatService
      .getProductFormats(
        ExtraProductFormatField.slotTypes,
        ExtraProductFormatField.enabled,
        ExtraProductFormatField.frequencyLimitRatios,
      )
      .then((productFormats) => {
        this.productFormats = productFormats.filter((f) => f.enabled);
        this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
          this.productFormats,
        );
        this.buildFrequencyLimitRows();
      });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildFrequencyLimitRows();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        const allUpdates = [];
        this.frequencyLimits.map((limit) => {
          allUpdates.push(
            this.frequencyLimitService.updateWithdrawalLimitsByProductFormat(
              limit.id,
              this.buildProductFormatWithdrawalParams(limit.id),
            ),
          );
        });
        Promise.all(allUpdates)
          .then(() => {
            this.show.estimatesHaveChanged = true;
          })
          .catch(() => null)
          .finally(() => {
            this.setEditing(false);
            this.getAndBuildFrequencyLimitRows();
            this.dialogService.closeBlocking();
          });
      });
  }

  rowVisible(obj: FrequencyLimitRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || productFormatFiltered);
  }

  getFrequencyLimit(productFormatId: wooId, frequencyLimitId: wooId): SlotTypeEstimates {
    return this.utilityService.getProductFormat(productFormatId, this.productFormats).frequency_limit_ratios[
      frequencyLimitId
    ];
  }

  private buildProductFormatWithdrawalParams(id: wooId): ProductFormatWithdrawalLimitsParams[] {
    let limits: ProductFormatWithdrawalLimitsParams[] = [];
    return this.productFormats.flatMap(
      (format) =>
        (limits = [
          ...limits,
          {
            product_format_id: format.id,
            withdrawal_limits: Object.entries(format.frequency_limit_ratios[id]).map(([slotType, amount]) => ({
              slot_type: slotType as SlotType,
              withdrawal_limit: amount as number,
            })),
          },
        ]),
    );
  }

  private buildFrequencyLimitRows(): void {
    let frequencyLimitRows: FrequencyLimitRow[] = [];

    this.frequencyLimits.map((limit) => {
      this.productFormats.map((format) => {
        frequencyLimitRows = [
          ...frequencyLimitRows,
          {
            name: limit.name,
            FrequencyLimitId: limit.id,
            publisherName: this.utilityService.getPublisherName(format.publisher_id, this.publishers),
            publisherId: format.publisher_id,
            productFormat: format.name,
            productFormatId: format.id,
            frequencyLimitId: limit.id,
            impressions: limit.impressions,
            frequencyRatioPreroll: format.frequency_limit_ratios[limit.id].preroll,
            frequencyRatioMidroll: format.frequency_limit_ratios[limit.id].midroll,
            frequencyRatioPostroll: format.frequency_limit_ratios[limit.id].postroll,
          },
        ];
      });
    });
    this.frequencyLimitRows = frequencyLimitRows;
  }
}

interface FrequencyLimitRow {
  name: string;
  FrequencyLimitId: wooId;
  publisherName: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId?: wooId;
  frequencyLimitId: wooId;
  impressions: number;
  frequencyRatioPreroll?: number;
  frequencyRatioMidroll?: number;
  frequencyRatioPostroll?: number;
}
