import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { verticalExpansion } from '../../../../utils/animations';
import { ConfirmDialogContent } from '../../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { FormatterService } from '../../../../woo_services.module/FormatterService';
import { AttestInvoice, AttestStatus, InvoiceService } from '../../../../woo_services.module/InvoiceService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { DashboardTab, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';

@Component({
  selector: 'invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss'],
  animations: [verticalExpansion(500)],
})
export class InvoiceTable {
  @Input() tableType;
  @Input() invoices: AttestInvoice[];
  @Input() expanded: wooId[] = [];
  @Output() reloadInvoices = new EventEmitter<void>();

  constructor(
    private dialogService: DialogService,
    private formatterService: FormatterService,
    private invoiceService: InvoiceService,
    private translationService: TranslationService,
    private routingService: RoutingService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  AttestStatus = AttestStatus;
  attestStatusLabel = this.translationService.convertAttestStatus;

  toggleExpanded(id: wooId): void {
    this.expanded = this.expanded.includes(id)
      ? this.expanded.filter((item) => item !== id)
      : this.expanded.concat([id]);
  }

  isExpanded(id: wooId): boolean {
    return this.expanded && this.expanded.includes(id);
  }

  async attestInvoice(invoiceId: wooId, event: Event): Promise<void> {
    event.stopPropagation();
    await this.dialogService.openConfirm(this.dialogContent);
    await this.invoiceService.attestInvoice(invoiceId);
    this.reloadInvoices.emit();
  }

  showCampaign(campaignId: wooId): void {
    this.routingService.openAndGoDashboardTab(campaignId, DashboardTab.details);
  }

  formatAmount(amount: number): string {
    return `${this.formatterService.transformNumber(amount)} kr`;
  }

  formatDate(date: string): string {
    return date ? formatDate(date, 'yyyy-MM-dd', this.locale) : 'Okänt';
  }

  get dialogContent(): ConfirmDialogContent {
    return {
      header: 'Godkänn kreditfaktura',
      textBlocks: ['Är du säker på att du vill godkänna kreditfakturan?'],
      confirmText: 'Godkänn',
      cancelText: 'Avbryt',
    };
  }
}
