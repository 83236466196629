import { Component, Input } from '@angular/core';

@Component({
  selector: 'text-button',
  template: `
    <button class="link padding--none slim" [disabled]="disabled">
      <span *ngIf="icon" class=" icon-{{ icon }} "></span>
      <ng-content></ng-content>
    </button>
  `,
  styles: [
    `
      .slim {
        min-height: initial;
      }
      .slim.link {
        margin-left: 0;
        margin-right: 0;
      }
      .margin-right--05x {
        margin-right: 4px;
      }
    `,
  ],
})
export class TextButton {
  @Input() icon: string;
  @Input() disabled = false;
}
