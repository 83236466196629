import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';
import { BlockingDialog } from '../woo_components.module/dialogs/blocking-dialog.component';
import { ConfirmDialog, ConfirmDialogContent } from '../woo_components.module/dialogs/confirm-dialog.component';
import { ErrorDialog } from '../woo_components.module/dialogs/error-dialog.component';
import { PreconditionErrorDialog } from '../woo_components.module/dialogs/precondition-error-dialog.component';
import { SuccessDialog } from '../woo_components.module/dialogs/success-dialog.component';
import { ProgressCallback } from './JobService';

@Injectable({ providedIn: 'root' })
export class DialogService {
  private viewContainerRef: ViewContainerRef;
  private blockingDialog: BlockingDialog;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  setRootViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  create<T extends { onClose: () => void }>(dialog: Type<T>): T {
    this.closeBlocking();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(dialog);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.onClose = () => {
      this.viewContainerRef.remove(this.viewContainerRef.indexOf(componentRef.hostView));
      componentRef.instance.onClose = null;
    };
    return componentRef.instance;
  }

  closeBlocking(): void {
    if (this.blockingDialog) {
      this.blockingDialog.close();
      this.blockingDialog = null;
    }
  }

  openSuccess(...messages: string[]): void {
    this.create(SuccessDialog).show(...messages);
  }

  openError(error: string, ...messages: string[]): void {
    this.create(ErrorDialog).show(error, ...messages);
  }

  openPreconditionError(error: string, error_data: object): void {
    this.create(PreconditionErrorDialog).show(error, error_data);
  }

  openConfirm(content: ConfirmDialogContent): Promise<string> {
    return this.create(ConfirmDialog).show(content);
  }

  openBlocking(message?: string): void {
    if (!this.blockingDialog) {
      this.blockingDialog = this.create(BlockingDialog);
      this.blockingDialog.show(message);
    } else {
      this.blockingDialog.message = message;
    }
  }

  openBlockingWithProgress = (message: string): ProgressCallback => {
    if (!this.blockingDialog) {
      this.blockingDialog = this.create(BlockingDialog);
      this.blockingDialog.show(message, 0);
    }
    return (jobProgress: number, progress_message: string) =>
      this.blockingDialog.show(message, jobProgress, progress_message);
  };
}
