<dot-loader [visibility]="userAgencies.length === 0"></dot-loader>
<div *ngFor="let agency of userAgencies">

  <div class="row" *ngIf="!canGrantAccessToCustomers(agency.id)">
    <p class="small">
      Du måste vara byråadministratör på <strong>{{agency.name}}</strong> för att kunna se och ändra
      vilka kunder som användaren ska ha tillgång till.
    </p>
  </div>

  <div class="row" *ngIf="isAgencyUser(user, agency.id) && canGrantAccessToCustomers(agency.id)">
    <h4><a [routerLink]="['/agency', agency.id]">{{agency.nickname || agency.name}}</a> (byråanvändare)</h4>
    <div *ngIf="agency.customers.length">
      <button class="button primary-1" (click)="grantAccessToAllCustomers(agency)">Alla kunder</button>
      <button class="button primary-2" (click)="removeAccessAllCustomers(agency)">Inga kunder</button>
    </div>
    <p class="small" *ngIf="!agency.customers.length">Byrån har inga aktiva kunder.</p>
    <div *ngFor="let customer of agency.customers">
      <input type="checkbox" class="checkbox" id="{{customer.id}}" [checked]="isAgencyUserAtCustomer(user, customer.id)" (change)="toggleAccess(customer, agency)">
      <label for="{{customer.id}}" class="checkbox">{{customer.nickname || customer.name}}<span *ngIf="customer.locked">(Låst)</span></label>
    </div>
    <h4 *ngIf="agency.inactive_customers">Tidigare kunder</h4>
    <div *ngFor="let customer of agency.inactive_customers">
      <input type="checkbox" class="checkbox" id="{{customer.id}}" [checked]="isAgencyUserAtCustomer(user, customer.id)" (change)="toggleAccess(customer, agency)">
      <label for="{{customer.id}}" class="checkbox">{{customer.nickname || customer.name}} (Inaktiv kund)<span *ngIf="customer.locked">(Låst)</span></label>
    </div>
  </div>

  <div class="row" *ngIf="isAgencyAdmin(user, agency.id) && canGrantAccessToCustomers(agency.id)">
    <h4><a [routerLink]="['/agency', agency.id]">{{agency.nickname || agency.name}}</a> (byråanvändare)</h4>
    <p class="small">Användaren har automatiskt tillgång till alla kunder som administratör.</p>
    <p class="small" *ngIf="!agency.customers.length">Byrån har inga kunder.</p>
    <div *ngFor="let customer of agency.customers">
      <input type="checkbox" id="{{customer.id}}" class="checkbox show-disabled-check" [checked]="true" disabled>
      <label for="{{customer.id}}" class="checkbox">{{customer.nickname || customer.name}}</label>
    </div>
  </div>

</div>
