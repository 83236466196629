import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StandardResponseService } from '../../woo_services.module/StandardResponseService';

@Injectable()
export class StandardResponseInterceptor implements HttpInterceptor {
  constructor(private standardResponseService: StandardResponseService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(this.convertResponse),
      tap({
        next: () => null,
        error: (response: any) => {
          if (response instanceof HttpErrorResponse) {
            this.standardResponseService.dispatchFromStatusCode(response.status, response.error);
          }
        },
      }),

      /*
       * This might cause memory leaks when <observable>.toPromise() is used or
       * when subscriptions are not closed when their components are destroyed.
       *
       * However, since toPromise is something we should try to get rid of and
       * proper subscription handing has other advantages we will try to use this
       * solution anyway. Also the alternative, add catch everywhere we use HttpClient,
       * it not very attractive either.
       */
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === -1 || err.status === 0) {
            console.debug('Ignored HttpErrorResponse', err);
            return NEVER;
          }
        }
        return throwError(err);
      }),
    );
  }

  private convertResponse = (response: any) => {
    return response instanceof HttpErrorResponse && response?.error instanceof Blob
      ? this.parseErrorBlob(response)
      : throwError(response);
  };

  private parseErrorBlob = (err: HttpErrorResponse): Observable<any> => {
    const reader: FileReader = new FileReader();

    const obs$ = new Observable((observer: any) => {
      reader.onloadend = () => {
        const newError = new HttpErrorResponse({
          error: JSON.parse(reader.result as string),
          headers: err.headers,
          status: err.status,
          statusText: err.statusText,
          url: err.url,
        });
        observer.error(newError);
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs$;
  };
}
