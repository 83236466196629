export type Calender = Map<weekNumber, Day[]>;

export interface Day {
  date: Date;
  marked: boolean;
  disabled: boolean;
  selectable: boolean;
}
export enum InitialDate {
  Current = 'current',
  FirstSelectable = 'firstSelectable',
}

export type weekNumber = number;
