import { Component, Input, OnChanges } from '@angular/core';
import { ShareType, ViewCurrency } from '../../woo_services.module/shared-types';

const template = /* html */ `
  <div *ngIf="tags?.length">
    <span *ngFor="let item of tags" class="targeting-tag">
      {{item.name}}
      <ng-container *ngIf="shareType === shareTypes.Budget && item.budget"> ({{item.budget | number}} kr)</ng-container>
      <ng-container *ngIf="shareType === shareTypes.Impressions && item.impressions"> ({{item.impressions | number}} {{formatCurrency()}})</ng-container>
    </span>
  </div>
`;

@Component({
  selector: 'tag-list',
  template: template,
  styleUrls: ['./tag-list.component.scss'],
})
export class TagList implements OnChanges {
  @Input() items: Array<{ name: string }>;
  @Input() defaultTagName: string;
  @Input() shareType: ShareType;
  @Input() viewCurrency: ViewCurrency;

  tags: Array<{ name: string }>;
  shareTypes = ShareType;

  ngOnChanges(): void {
    if (!this.items.length && this.defaultTagName) {
      this.tags = [{ name: this.defaultTagName }];
    } else {
      this.tags = this.items;
    }
  }

  formatCurrency(): string {
    switch (this.viewCurrency) {
      case ViewCurrency.completedViews:
        return 'cv';
      case ViewCurrency.impressions:
        return 'imp';
      case ViewCurrency.grossRatingViews:
        return 'bk';
      default:
        return '';
    }
  }
}
