import { Component, Input } from '@angular/core';
import { AbstractValueAccessor } from '../../../utils/AbstractValueAccessor';
import { trackById } from '../../../utils/object';
import { valueAccessorProvider } from '../../../utils/provider-builders';
import { ShareType, wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'distribution-table',
  templateUrl: './distribution-table.component.html',
  styleUrls: ['./distribution-table.component.scss'],
  providers: [valueAccessorProvider(DistributionTable)],
})
export class DistributionTable extends AbstractValueAccessor<Distributable[]> {
  readonly ShareType = ShareType;

  @Input() shareType: ShareType;
  @Input() itemType: string;
  @Input() totalToDistribute = 0;
  @Input() currency: string;

  distributeRemainingTo = (item: Distributable): void => {
    const amount = this.totalToDistribute - this.getDistributed();
    if (this.shareType === ShareType.Budget) {
      item.budget = (item.budget || 0) + amount;
    } else if (this.shareType === ShareType.CompletedViews) {
      item.completedViews = (item.completedViews || 0) + amount;
    }
    this.change();
  };

  trackById(): (_: any, item: { id: wooId }) => wooId {
    return trackById;
  }

  private getDistributed(): number {
    return this.model
      .map((item) =>
        this.shareType === ShareType.Budget ? Number(item.budget) || 0 : Number(item.completedViews) || 0,
      )
      .reduce((sum, curr) => sum + curr, 0);
  }
}

export interface Distributable {
  id: wooId;
  name: string;
  budget: number;
  completedViews: number;
  percent: number;
}
