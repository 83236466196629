<div class="tabbable__panel panel-margin right section info clearfix">
  <div class="col-xs-12">
    <h2>Utnyttjat lager</h2>
  </div>
</div>

<div class="tabbable__body clearfix">
  <dot-loader [visibility]="show.loading" description="Hämtar lager"></dot-loader>
  <div *ngIf="!show.loading && inventories !== undefined && filterOptions.length">
    <woo-dropdown
      *ngIf="inventories.length > 1"
      [items]="inventories"
      [(ngModel)]="selectedInventory"
      name="selectedInventory"
      bindLabel="name"
      inputId="inventory-select"
      labelText="Lagerdelar"
      notFoundText="Inget lager hittades."
    >
    </woo-dropdown>
    <inventory-visualization
      [viewCurrencyLabel]="selectedInventory.name"
      [inventoryTree]="selectedInventory.inventoryTree"
      [filterOptions]="filterOptions"
      [columnNames]="inventoryColumns">
    </inventory-visualization>
  </div>
</div>
