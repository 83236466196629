<div class="tabbable__panel panel-margin right section info clearfix">
  <div class="col-xs-12">
    <h2>TTV-bokningar</h2>
  </div>
</div>
<div class="tabbable__body clearfix">

  <ng-container *ngFor="let booking of ttvBookings">

    <div class="row">
      <div class="col-xs-12 col-lg-8">
        <heading-with-line text="Total-TV data"></heading-with-line>
      </div>
    </div>

    <div class="row margin-bottom--1x">
      <div class="col-xs-6 col-lg-4"><two-col-row left="Universum" [right]="booking.universe | number"></two-col-row></div>
      <div class="col-xs-6 col-lg-4"><two-col-row left="Snittrabatt" [right]="booking.discount | percent: PERCENT_FORMAT"></two-col-row></div>
    </div>
    <div class="row margin-bottom--1x">
      <div class="col-xs-6 col-lg-4"><two-col-row left="CV från Spotlight" [right]="getViews(booking) | number"></two-col-row></div>
      <div class="col-xs-6 col-lg-4"><two-col-row left="TTV-målgrupp" [right]="booking.target_group"></two-col-row></div>
    </div>
    <div class="row margin-bottom--1x">
      <div class="col-xs-6 col-lg-4"><two-col-row left="Budget" [right]="getBudget(booking) | currency:'SEK'"></two-col-row></div>
      <div class="col-xs-6 col-lg-4"><two-col-row left="TTV-referensnummer" [right]="booking.reference_number"></two-col-row></div>
    </div>
    <div class="row margin-bottom--1x padding-bottom--5x">
      <div class="col-xs-6 col-lg-4"><two-col-row left="Översättningsfaktor" [right]="booking.targeting_mapping?.factor"></two-col-row></div>
      <div class="col-xs-6 col-lg-4"><two-col-row left="BokningsID" [right]="booking.ttv_id"></two-col-row></div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-lg-8">
        <heading-with-line text="Rådata"></heading-with-line>
      </div>
    </div>

    <pre class="border--none background-color--transparent">{{booking | json}}</pre>

  </ng-container>
</div>
