import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeedbackDialog } from '../../woo_components.module/dialogs/feedback-dialog.component';
import { DialogService } from '../../woo_services.module/DialogService';
import { RoutingService } from '../../woo_services.module/RoutingService';
import { MailService } from '../services/MailService';

@Component({
  selector: 'show-mail',
  templateUrl: './show-mail.component.html',
  styleUrls: ['./show-mail.component.scss'],
})
export class ShowMail implements AfterViewInit {
  public errorMessage: string[] = null;
  @ViewChild('iframe', { static: true }) private iframe: ElementRef;
  feedback = (): void => this.dialogService.create(FeedbackDialog).open();

  constructor(
    private mailService: MailService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private dialogService: DialogService,
  ) {}

  ngAfterViewInit(): void {
    this.getMail().then(
      (mailContent) => {
        const iframeDoc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow.document;
        iframeDoc.body.innerHTML = mailContent;

        setTimeout(() => {
          this.iframe.nativeElement.style.height = iframeDoc.body.scrollHeight + 10 + 'px';
        }, 0);
      },
      (error) => console.error(error),
    );
  }

  private getMail(): Promise<string> {
    switch (this.routingService.getParam(this.route, 'mail_type')) {
      case 'booking_confirmation':
        return this.mailService.getBookingConfirmation(this.routingService.getParam(this.route, 'campaign_id'));
      case 'creative_reminder':
        return this.mailService.getCreativeReminder(this.routingService.getParam(this.route, 'campaign_id'));
      case 'user_created':
        return this.mailService.getUserCreated();
      case 'invoicing_disabled':
        return this.mailService.getInvoicingDisabled(this.routingService.getParam(this.route, 'campaign_id'));
      default:
        const message = [
          `Okänd mail-typ: ${this.routingService.getParam(this.route, 'mail_type')}`,
          'Vänligen kontakta supporten för mer hjälp',
        ];
        this.showError(message);
        return Promise.reject(message);
    }
  }

  private showError(error: string[]) {
    this.errorMessage = error;
  }
}
