import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleChanges } from '../../utils/types';

const DEFAULT_OPTIONS = {
  allowEmpty: false,
  allowDecimals: false,
  allowLeadingSign: false,
  numberOfDecimals: 2,
};

@Directive({
  selector: '[wooNumberFormatter]',
  host: {
    '(input)': 'eventHandler($event)',
  },
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: WooNumberFormatter, multi: true }],
})
export class WooNumberFormatter implements ControlValueAccessor, OnChanges {
  @Input('wooNumberFormatter') options = DEFAULT_OPTIONS;

  setValue: number = null;
  private decimalRegex: RegExp;

  constructor(private inputElement: ElementRef<HTMLInputElement>, private renderer: Renderer2) {}

  get allowEmpty(): boolean {
    return this.options && this.options.allowEmpty ? this.options.allowEmpty : false;
  }

  get allowDecimals(): boolean {
    return this.options && this.options.allowDecimals ? this.options.allowDecimals : false;
  }

  get allowLeadingSign(): boolean {
    return this.options && this.options.allowLeadingSign ? this.options.allowLeadingSign : false;
  }

  ngOnChanges(changes: SimpleChanges<WooNumberFormatter>): void {
    if (changes.options) {
      this.options = {
        ...DEFAULT_OPTIONS,
        ...this.options,
      };
      if (this.options.allowDecimals) {
        this.decimalRegex = new RegExp(',\\d{0,' + this.options.numberOfDecimals + '}', 'g');
      }
    }
  }

  eventHandler(inputEvent: any): void {
    const modelValue = this.getInputValue(inputEvent.target.value);

    if (isNaN(parseFloat(modelValue))) {
      this.handleNaNInput(inputEvent);
    } else {
      this.handleValidInput(inputEvent, modelValue);
    }
  }

  sendValue(inputValue: number): void {
    this.propagateChange(inputValue);
  }

  writeValue(model: number): void {
    const incomingValue = model;
    if (this.setValue !== incomingValue) {
      this.inputElement.nativeElement.value = this.parseNumberToRenderValue(incomingValue);
      this.setValue = incomingValue;
    }
  }

  private getInputValue(value: string): string {
    if (this.allowDecimals && value.includes(',')) return this.parseDecimals(this.parseRealValueDecimal(value));
    return this.parseInt(this.parseRealValueInt(value));
  }

  private replaceCommaAndParseFloat(value: string): number {
    return parseFloat(value.replace(/\,/g, '.'));
  }

  private setNewPosition(inputEvent: any, newRenderValue: string): number {
    const startPos = inputEvent.target.selectionStart;
    const subValue = this.parseRealValueDecimal(inputEvent.target.value.substring(startPos));
    return subValue.length === 0
      ? newRenderValue.length
      : newRenderValue.length - this.parseRenderValue(subValue).length;
  }

  private handleNaNInput(inputEvent: any): void {
    if (this.allowLeadingSign && inputEvent.target.value === '-') {
      inputEvent.target.value = '-';
      this.setValue = null;
    } else {
      this.setValue = this.allowEmpty ? null : 0;
      inputEvent.target.value = this.allowEmpty ? '' : '0';
      inputEvent.target.setSelectionRange(0, 1);
    }
    this.sendValue(this.setValue);
  }

  private handleValidInput(inputEvent: any, modelValue: string): void {
    const newRenderValue = this.parseNumberToRenderValue(modelValue);
    const newPosition = this.setNewPosition(inputEvent, newRenderValue);
    inputEvent.target.value = newRenderValue;
    inputEvent.target.setSelectionRange(newPosition, newPosition);
    this.setValue = this.replaceCommaAndParseFloat(modelValue);
    this.sendValue(this.setValue);
  }

  private parseNumberToRenderValue(modelValue: number | string): string {
    return modelValue === null || modelValue === undefined
      ? ''
      : modelValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }

  private parseRenderValue(modelValue: string): string {
    return modelValue === null || modelValue === undefined
      ? ''
      : modelValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }

  private parseRealValueDecimal(renderedValue: string): string {
    return renderedValue.replace(/[^\d|\-+|\,+]/g, '');
  }

  private parseRealValueInt(renderedValue: string): string {
    return renderedValue.replace(/[^\d|\-+]/g, '');
  }

  private parseInt(value: string): string {
    return value === null || value === undefined ? '' : parseFloat(value).toString();
  }

  private parseDecimals(value: string): string {
    if (value === null || value === undefined) {
      return '';
    }
    const decimalValue = value.match(this.decimalRegex)[0];
    return value.replace(/\,.*/g, `${decimalValue}`).replace(/,+/g, ',');
  }

  propagateChange = (_: any): void => null;
  propagateTouch = (): void => null;
  setDisableState = (isDisabled: any, inputElement: ElementRef<HTMLInputElement>): void => {
    if (!inputElement) {
      return;
    }
    this.renderer.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
  };

  registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.setDisableState(isDisabled, this.inputElement);
  }
}
