import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import {
  ConfirmDialogContent,
  ConfirmDialogWarningBlock,
} from '../../../../woo_components.module/dialogs/confirm-dialog.component';
import { AdServerService } from '../../../../woo_services.module/AdServerService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { Campaign, SendToAdServerJobResult } from '../../../../woo_services.module/shared-types';

const template = /* html */ `
  <text-button
    icon="ios"
    [disabled]="disabled"
    (click)="onClick()"
  >
    Skicka till adservern
  </text-button>
`;

@Component({
  selector: 'send-to-ad-server-button',
  template,
})
export class SendToAdServerButton {
  @Input() campaign: Campaign;
  @Input() disabled = false;

  constructor(
    private adServerService: AdServerService,
    private routingService: RoutingService,
    private translationService: TranslationService,
    private dialogService: DialogService,
  ) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    const content = this.getDialogContent();
    this.dialogService
      .openConfirm(content)
      .then(
        () => {
          this.sendCampaignToAdServer(this.campaign);
        },
        () => null,
      )
      .catch((err) => {
        this.sendToAdServerError(err);
      });
  };

  private sendCampaignToAdServer = (campaign: Campaign) => {
    this.dialogService.openBlocking('Skickar till adservern');
    this.adServerService
      .sendCampaign(campaign.id)
      .then(
        () => {
          this.dialogService.closeBlocking();
          this.sendToAdServerSuccess();
        },
        (reason) => {
          this.dialogService.closeBlocking();
          this.sendToAdServerError(reason);
        },
      )
      .catch(() => {
        const msg = 'Ajdå, nånting gick snett. Testa att refresha browsern och försök igen.';
        this.dialogService.openError(msg);
      });
  };

  private sendToAdServerSuccess = () => {
    this.dialogService.openSuccess('Kampanjen har skickats till adservern.');
    this.routingService.navigateWithReload(['/dashboard', this.campaign.id]);
  };

  private sendToAdServerError = (errorResponse: SendToAdServerJobResult) => {
    if (!!errorResponse.data_errors) {
      this.dialogService.openPreconditionError(errorResponse.error, errorResponse.data_errors);
      return;
    }

    if (!(errorResponse instanceof HttpErrorResponse)) {
      const msg = this.translationService.convertSendToAdserverError(errorResponse.error);
      this.dialogService.openError(msg);
    }
  };

  private getDialogContent(): ConfirmDialogContent {
    const textBlocks: string[] = [];
    const warningBlocks: ConfirmDialogWarningBlock[] = [];
    const goalAdjusted =
      this.campaign.mms_gain_impressions > 0 ||
      this.campaign.additional_goal_impressions > 0 ||
      this.campaign.adjusted_goal_impressions > 0;

    if (!this.campaign.external_id) {
      textBlocks.push('Obs! Nu skickas denna kampanj till adservern. Är du säker på att du vill fortsätta?');
    }

    if (this.campaign.external_id && goalAdjusted) {
      warningBlocks.push(this.getOverrideWarningBlock());
    }
    if (this.campaign.external_id) {
      warningBlocks.push(this.getRiskOfOverrideWarningBlock());
    }

    return {
      header: 'Skicka kampanj till adservern',
      textBlocks,
      warningBlocks,
      confirmText: 'Skicka till adservern',
      cancelText: 'Avbryt',
    };
  }

  private getRiskOfOverrideWarningBlock(): ConfirmDialogWarningBlock {
    return {
      header: 'Varning!',
      textBlocks: [
        'Denna kampanj finns redan på adservern.',
        'De manuella ändringar du har gjort på adservern kommer att skrivas över och behöva göras om.',
      ],
    };
  }

  private getOverrideWarningBlock(): ConfirmDialogWarningBlock {
    return {
      header: 'Varning!',
      textBlocks: [
        'Denna kampanj innehåller mål där visningar har justerats manuellt.',
        'Om du skickar om kampanjen till adservern så kommer detta att behöva göras om.',
      ],
    };
  }
}
