import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { scrollTo } from '../../../../utils/dom';
import { DeviceGroupService } from '../../../../woo_services.module/DeviceGroupService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { DeviceGroup, ProductFormat, Publisher, SlotType, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';
import { BehaviorParams } from '../behaviors/behavior-form.component';
import { DeviceGroupParams } from './device-group-form.component';

@Component({
  selector: 'manage-device-groups',
  templateUrl: './manage-device-groups.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageDeviceGroups implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('newDeviceGroupFormContainer', { static: true }) newDeviceGroupFormContainer: ElementRef;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  @Output() onSubmit = new EventEmitter<BehaviorParams>();
  @Output() onChanges = new EventEmitter<void>();

  show = {
    newDeviceGroupForm: false,
    estimatesHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };

  readonly tableHeaders = [
    'Apparat',
    'Placering',
    'Publicist',
    'Preroll',
    'Midroll',
    'Postroll',
    'Preroll',
    'Midroll',
    'Postroll',
    'Redigera',
  ];
  readonly staticFields = 3;
  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  deviceGroups: DeviceGroup[];
  selectedDeviceGroup: DeviceGroup;
  deviceRows: DeviceRow[] = [];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  translateProductFormat: (args: any) => string;
  constructor(
    private dialogService: DialogService,
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private deviceGroupService: DeviceGroupService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.publisherService
      .getPublishers(ExtraPublisherField.productFormats, ExtraPublisherField.enabled)
      .then((publishers) => {
        this.publishers = publishers;
        this.publishers = publishers.filter((p) => p.enabled);
      })
      .then(() => {
        this.productFormatService
          .getProductFormats(ExtraProductFormatField.enabled, ExtraProductFormatField.slotTypes)
          .then((productFormats) => {
            this.productFormats = productFormats.filter((f) => f.enabled);
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
          })
          .then(() => {
            this.getAndBuildDeviceGroups();
          });
      })
      .finally(() => {
        this.show.loading = false;
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
        scrollTo(this.parentHeader);
      });
  }

  async getAndBuildDeviceGroups(): Promise<void> {
    this.deviceGroupService.getDeviceGroups().then((groups) => {
      this.deviceGroups = groups;
      this.buildDeviceRows();
    });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildDeviceGroups();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedDeviceGroup || this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.updatingEstimates = true;
        const allUpdates = [];
        this.deviceGroups.forEach((device) => {
          allUpdates.push(this.deviceGroupService.updateDeviceGroup(device));
        });
        Promise.all(allUpdates)
          .then(() => {
            this.show.estimatesHaveChanged = true;
          })
          .catch(() => null)
          .finally(() => {
            this.getAndBuildDeviceGroups();
            this.setEditing(false);
            this.show.updatingEstimates = false;
            this.dialogService.closeBlocking();
          });
      });
  }
  async addDeviceGroup(deviceGroupParams: DeviceGroupParams): Promise<void> {
    this.deviceGroupService
      .addDeviceGroup(deviceGroupParams)
      .then((deviceGroup) => {
        this.show.newDeviceGroupForm = false;
        this.deviceGroups.push(deviceGroup);
        this.show.estimatesHaveChanged = true;
      })
      .then(() => {
        this.buildDeviceRows();
      });
  }

  rowVisible(obj: DeviceRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const deviceGroupFiltered = this.selectedDeviceGroup && obj.deviceId !== this.selectedDeviceGroup.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || deviceGroupFiltered || productFormatFiltered);
  }

  showNewDeviceGroup(): void {
    this.show.newDeviceGroupForm = true;
    scrollTo(this.newDeviceGroupFormContainer.nativeElement);
  }

  hideNewDeviceGroup(): void {
    this.show.newDeviceGroupForm = false;
    scrollTo(this.parentHeader);
  }

  getDeviceGroup(id: wooId): DeviceGroup {
    return this.deviceGroups.find((device) => device.id === id);
  }
  private buildDeviceRows(): void {
    let deviceRows: DeviceRow[] = [];
    this.deviceGroups.map((deviceGroup) => {
      this.publishers.map((publisher) => {
        this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
          deviceRows = [
            ...deviceRows,
            {
              device: deviceGroup.name,
              deviceId: deviceGroup.id,
              productFormat: productFormat.name,
              productFormatId: productFormat.id,
              publisherId: publisher.id,
              publisher: this.utilityService.getPublisherName(publisher.id, this.publishers),
              sharesPreroll: deviceGroup.shares[publisher.id][productFormat.id].preroll,
              sharesMidroll: deviceGroup.shares[publisher.id][productFormat.id].midroll,
              sharesPostroll: deviceGroup.shares[publisher.id][productFormat.id].postroll,
              withdrawalLimitsPreroll: deviceGroup.withdrawal_limits[publisher.id][productFormat.id].preroll,
              withdrawalLimitsMidroll: deviceGroup.withdrawal_limits[publisher.id][productFormat.id].midroll,
              withdrawalLimitsPostroll: deviceGroup.withdrawal_limits[publisher.id][productFormat.id].postroll,
            },
          ];
        });
      });
    });
    this.deviceRows = this.sortRows(deviceRows);
  }

  private sortRows(rows: DeviceRow[]): DeviceRow[] {
    const sorted = rows.sort((a, b) => {
      if (a.publisher !== b.publisher) return a.publisher.localeCompare(b.publisher);
      if (a.device !== b.device) return a.device.localeCompare(b.device);
      return a.productFormat.localeCompare(b.productFormat);
    });
    return sorted;
  }
}

interface DeviceRow {
  device: string;
  deviceId: wooId;
  productFormat: string;
  productFormatId: wooId;
  publisher: string;
  publisherId?: wooId;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
