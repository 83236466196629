import { Component, Input } from '@angular/core';

const template = `
<p class="ellipsis text--large margin-margin-top--1x margin-bottom--2px campaign-name">{{name}}</p>
<p class="text--small">{{start | date}} - {{end | date}}</p>
`;

@Component({
  selector: 'name-period-header',
  template: template,
  styles: ['.margin-bottom--2px { margin-bottom: 2px; }'],
})
export class NamePeriodHeader {
  @Input() name: string;
  @Input() start: string | Date;
  @Input() end: string | Date;
}
