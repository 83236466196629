import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ignore404 } from '../../../utils/utils';
import { AuthService } from '../../../woo_services.module/AuthService';
import { FileService, FileType } from '../../../woo_services.module/FileService';
import { SessionService } from '../../../woo_services.module/SessionService';
import { SystemMessage, SystemMessageService } from '../../../woo_services.module/SystemMessageService';

@Component({
  selector: 'global-messages',
  templateUrl: './global-messages.component.html',
  styleUrls: ['./global-messages.component.scss'],
})
export class GlobalMessages implements OnChanges {
  @Input() menuOpened = false;
  @Input() cookieBannerDismissed: boolean;
  @Input() systemMessages: SystemMessage[] = [];

  visibleSystemMessages: SystemMessage[] = [];
  cookiePolicyUrl: string;

  constructor(
    private systemMessageService: SystemMessageService,
    fileService: FileService,
    private sessionService: SessionService,
    private authService: AuthService,
  ) {
    this.cookiePolicyUrl = fileService.getUrlToFile(FileType.cookiePolicy);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.systemMessages && this.systemMessages) {
      this.updateVisibleMessages();
    }
  }

  dismissSystemMessage(message: SystemMessage): void {
    message.dismissed = true;
    this.systemMessageService
      .dismiss(message.id)
      .catch(ignore404)
      .catch(() => {
        message.dismissed = false;
      })
      .finally(() => {
        this.updateVisibleMessages();
      });
  }

  dismissCookieBanner(): void {
    this.cookieBannerDismissed = true;
    this.sessionService.setCookieBannerDismissed();
  }

  private updateVisibleMessages() {
    this.visibleSystemMessages = this.systemMessages.filter((m) => this.messageFilter(m));
  }

  private messageFilter(message: SystemMessage): boolean {
    return (message.roles.length === 0 || this.authService.hasAnyRole(message.roles)) && !message.dismissed;
  }
}
