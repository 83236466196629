<div class="block relative">
  <h1>Systemmeddelanden</h1>
  <div class="system__messages__overview">
  	<h3 *ngIf="!systemMessages.length" class="thin">Det finns inga meddelanden just nu.</h3>
    <table
      *ngIf="systemMessages.length"
      class="table table--woo table--hover table--align--middle table--min-width table--system--messages"
    >
      <thead>
        <tr>
          <th>Skapad</th>
          <th>Meddelande</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let message of systemMessages" class="{{message.type}}">
          <td>
            {{message.created_at | date}}
          </td>
          <td style="white-space:pre-wrap;">
            {{message.message}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
