import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { isDateBefore } from '../utils/date';
import { EnvironmentService } from './EnvironmentService';
import { CompactCustomer, Customer, HistoryItem, PartnerAgreement, wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getCustomer(id: wooId): Promise<Customer> {
    return lastValueFrom(this.http.get<Customer>(`${this.env.apiUrl}/customers/${id}`));
  }

  getCustomersForCurrentUser(): Promise<CompactCustomer[]> {
    return lastValueFrom(this.http.get<CompactCustomer[]>(`${this.env.apiUrl}/customers`));
  }

  getCustomersForCurrentUserExcludingLocked(): Promise<CompactCustomer[]> {
    return lastValueFrom(this.http.get<CompactCustomer[]>(`${this.env.apiUrl}/customers?exclude_locked=true`));
  }

  getClientsForCurrentUser(): Promise<CompactCustomer[]> {
    return lastValueFrom(this.http.get<CompactCustomer[]>(`${this.env.apiUrl}/clients`));
  }

  getCashCustomersForCurrentUser(): Promise<CompactCustomer[]> {
    return lastValueFrom(this.http.get<CompactCustomer[]>(`${this.env.apiUrl}/cash_customers`));
  }

  getCustomerHistory(id: wooId): Promise<HistoryItem[]> {
    return lastValueFrom(this.http.get<HistoryItem[]>(`${this.env.apiUrl}/customers/${id}/history`));
  }

  getValidAgreements(agreements: PartnerAgreement[]): PartnerAgreement[] {
    return agreements.filter((agreement) => {
      const agreementHasEnded = agreement.valid_to && isDateBefore(agreement.valid_to, new Date());
      return !agreement.agency.locked && !agreementHasEnded;
    });
  }
}
