<h3>Externa kampanjer</h3>
<div class="">
  <table class="table table-woo table--align--middle">
    <thead>
    <tr>
      <th>Lager</th>
      <th>Namn</th>
      <th>Period</th>
      <th>Prio</th>
      <th>Impr (Tot/Relevant)</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr><dot-loader [visibility]="loading" description="Hämtar aktuella kampanjer"></dot-loader></tr>
    <tr *ngFor="let campaign of campaigns; trackBy: trackById">
      <td>
        <input type="checkbox" id="{{campaign.id}}" name="checkbox" value="value"
        (click)="toggleReduceImpressions(campaign)" [checked]="campaign.reduce_impressions" class="checkbox margin--none"/>
      </td>
      <td>{{campaign.name}}</td>
      <td>{{campaign.start_date | date:'yyyy-MM-dd'}} - {{campaign.end_date | date:'yyyy-MM-dd'}}</td>
      <td>{{campaign.priority}}</td>
      <td *ngIf="campaign.goal_type !== 'SHARE_OF_VOICE'" >{{campaign.goal_total}} / {{campaign.rbs_total}}</td>
      <td *ngIf="campaign.goal_type === 'SHARE_OF_VOICE'" >SoV, {{campaign.share_of_voice_factor * 100}}%</td>
      <td (click)="openDetails(campaign)" >
        <button class="link min-height--0">Mer info</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
