<expandable-targeting-header [expanded]="true" [targeting]="targeting">
  <ng-container *ngIf="tracking">
    <targeting-outcome-summary
      [viewCurrency]="targeting.view_currency"
      [pauseTargeting]="pauseTargeting"
      [tracking]="tracking">
    </targeting-outcome-summary>
    <div class="divider margin-top-bottom--2x"></div>
    <div>
      <tracking-distribution
        *ngIf="deviceData?.length"
        [distributions]="deviceDistribution"
        heading="Fördelning per apparat"
        [viewCurrency]="viewCurrency"
      ></tracking-distribution>
      <tracking-distribution
        *ngIf="regionDistribution?.length"
        [distributions]="regionDistribution"
        heading="Fördelning per region"
        [viewCurrency]="viewCurrency"
      ></tracking-distribution>
      <tracking-distribution
        *ngIf="categoryDistribution?.length"
        [distributions]="categoryDistribution"
        heading="Fördelning per kategori"
        [viewCurrency]="viewCurrency"
      ></tracking-distribution>
      <tracking-distribution
        *ngIf="programDistribution?.length"
        [distributions]="programDistribution"
        heading="Fördelning per programformat"
        [viewCurrency]="viewCurrency"
      ></tracking-distribution>
      <div class="margin-top--3x" *ngIf="dailyData?.length">
        <expandable-header
          text="Totalt {{ viewCurrency }} & click-through per dag"
          chevronColor="black"
        >
          <woo-table
            [headers]="dailyHeaders"
            [rows]="dailyData"
            wooCopyTableAsNumber
          ></woo-table>
        </expandable-header>
      </div>
      <div class="margin-top--2x" *ngIf="deviceData?.length">
        <expandable-header text="Apparater" chevronColor="black">
          <woo-table
            [headers]="deviceHeaders"
            [rows]="deviceData"
            wooCopyTableAsNumber
          ></woo-table>
        </expandable-header>
      </div>
      <div class="margin-top--2x" *ngIf="regionData?.length">
        <expandable-header text="Regioner" chevronColor="black">
          <woo-table
            [headers]="regionHeaders"
            [rows]="regionData"
            wooCopyTableAsNumber
          ></woo-table>
        </expandable-header>
      </div>
      <div class="margin-top--2x" *ngIf="categoryData?.length">
        <expandable-header text="Kategorier" chevronColor="black">
          <woo-table
            [headers]="categoryHeaders"
            [rows]="categoryData"
            wooCopyTableAsNumber
          ></woo-table>
        </expandable-header>
      </div>
      <div class="margin-top--2x" *ngIf="programData?.length">
        <expandable-header text="Program" chevronColor="black">
          <woo-table
            [headers]="programHeaders"
            [rows]="programData"
            wooCopyTableAsNumber
          ></woo-table>
        </expandable-header>
      </div>
      <div class="margin-top--2x" *ngIf="publisherData?.length > 1">
        <expandable-header text="Publicister" chevronColor="black">
          <woo-table
            [headers]="publisherHeaders"
            [rows]="publisherData"
            wooCopyTableAsNumber
          ></woo-table>
        </expandable-header>
      </div>
    </div>
  </ng-container>
  <h4 *ngIf="!tracking">Ingen data tillgänglig</h4>
</expandable-targeting-header>
