import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Category } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CategoryService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getCategories(): Promise<Category[]> {
    return lastValueFrom(this.http.get<Category[]>(`${this.env.apiUrl}/categories`));
  }
}
