import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from '../../../../utils/dom';
import { Behavior, BehaviorService } from '../../../../woo_services.module/BehaviorService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import {
  ProductFormat,
  Publisher,
  SlotType,
  SlotTypeEstimates,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { SystemService } from '../../../../woo_services.module/SystemService';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';
import { BehaviorParams } from './behavior-form.component';

@Component({
  selector: 'manage-behaviors',
  templateUrl: './manage-behaviors.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageBehaviors implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('newBehaviorFormContainer', { static: true }) newBehaviorFormContainer: ElementRef;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    newBehaviorForm: false,
    estimatesHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };

  readonly tableHeaders = [
    'Publicist',
    'Placering',
    'Segment',
    'Tagg',
    'Bokningsbar',
    'Preroll',
    'Midroll',
    'Postroll',
    'Preroll',
    'Midroll',
    'Postroll',
  ];

  readonly staticFields = 5;

  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  behaviors: Behavior[] = [];
  selectedBehavior: Behavior;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  behaviorRows: BehaviorRow[];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  shareOfTotal: number;
  translateProductFormat: (args: any) => string;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private behaviorService: BehaviorService,
    private utilityService: ProductsPageUtilityService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private systemService: SystemService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled)
      .then((publishers) => {
        this.publishers = publishers;
        this.publishers = publishers.filter((p) => p.enabled);
      })
      .then(() => {
        return this.productFormatService
          .getProductFormats(
            ExtraProductFormatField.enabled,
            ExtraProductFormatField.slotTypes,
            ExtraProductFormatField.behaviorShares,
            ExtraProductFormatField.behaviorWithdrawalLimits,
          )
          .then((productFormats) => {
            this.productFormats = productFormats;
            this.productFormats = productFormats.filter((f) => f.enabled);
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
          });
      })
      .then(() => {
        this.getAndBuildBehaviors();
      })
      .finally(() => {
        this.show.loading = false;
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
        scrollTo(this.parentHeader);
      });
    this.systemService.getValue('behavior_estimate_share_of_total').then((shareOfTotal) => {
      this.shareOfTotal = shareOfTotal;
    });
    this.show.estimatesHaveChanged = this.routingService.getState('behaviorEstimatesChanged');
  }

  async getAndBuildBehaviors(): Promise<void> {
    this.behaviorService.getBehaviors().then((behaviors) => {
      this.behaviors = behaviors;
      this.buildBehaviorRows();
    });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildBehaviors();
    this.setEditing(false);
  }

  async updateShareOfTotal(): Promise<void> {
    this.systemService.updateShareOfTotal({ behavior_estimate_share_of_total: this.shareOfTotal }).then((settings) => {
      if (this.shareOfTotal === settings.behavior_estimate_share_of_total) {
        this.shareOfTotal = settings.behavior_estimate_share_of_total;
        this.show.estimatesHaveChanged = true;
      }
    });
  }
  async addBehavior(deviceGroupParams: BehaviorParams): Promise<void> {
    this.behaviorService
      .addBehavior(deviceGroupParams)
      .then((behavior) => {
        this.show.newBehaviorForm = false;
        this.behaviors.push(behavior);
        this.show.estimatesHaveChanged = true;
      })
      .then(() => {
        this.buildBehaviorRows();
        this.hideNewBehavior();
      });
  }

  async save(): Promise<void> {
    const filterActive = this.selectedBehavior || this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.loading = true;
        this.show.updatingEstimates = true;
        this.behaviorService.updateBehaviors(this.behaviors).then(() => {
          this.show.estimatesHaveChanged = true;
        });
      })
      .catch(() => null)
      .finally(() => {
        this.setEditing(false);
        this.getAndBuildBehaviors();
        this.show.loading = false;
        this.show.updatingEstimates = false;
        this.dialogService.closeBlocking();
      });
  }

  showNewBehavior(): void {
    this.show.newBehaviorForm = true;
    scrollTo(this.newBehaviorFormContainer.nativeElement);
  }

  hideNewBehavior(): void {
    this.show.newBehaviorForm = false;
    scrollTo(this.parentHeader);
  }

  toggleActiveForBehavior(behavior: Behavior): void {
    this.behaviorService.updateBehavior(behavior);
  }

  getSpecificBehavior(behaviorId: wooId): Behavior {
    return this.behaviors.find((behavior) => behavior.id === behaviorId);
  }

  getSpecificBehaviorShares(behaviorId: wooId, publisherId: wooId, productFormatId: wooId): SlotTypeEstimates {
    return this.behaviors.find((behavior) => behavior.id === behaviorId).shares[publisherId][productFormatId];
  }

  getSpecificBehaviorLimits(behaviorId: wooId, publisherId: wooId, productFormatId: wooId): SlotTypeEstimates {
    return this.behaviors.find((behavior) => behavior.id === behaviorId).withdrawal_limits[publisherId][
      productFormatId
    ];
  }

  rowVisible(obj: BehaviorRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const selectedBehaviorFiltered = this.selectedBehavior && obj.behaviorId !== this.selectedBehavior.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || selectedBehaviorFiltered || productFormatFiltered);
  }

  private buildBehaviorRows(): void {
    let behaviorRows: BehaviorRow[] = [];
    this.behaviors.map((behavior) => {
      this.publishers.map((publisher) => {
        this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
          behaviorRows = [
            ...behaviorRows,
            {
              publisher: publisher.name,
              publisherId: publisher.id,
              productFormat: productFormat.name,
              productFormatId: productFormat.id,
              behavior: behavior.name,
              behaviorId: behavior.id,
              tag: behavior.tag,
              active: behavior.active,
              sharesPreroll: behavior.shares[publisher.id][productFormat.id].preroll,
              sharesMidroll: behavior.shares[publisher.id][productFormat.id].midroll,
              sharesPostroll: behavior.shares[publisher.id][productFormat.id].postroll,
              withdrawalLimitsPreroll: behavior.withdrawal_limits[publisher.id][productFormat.id].preroll,
              withdrawalLimitsMidroll: behavior.withdrawal_limits[publisher.id][productFormat.id].midroll,
              withdrawalLimitsPostroll: behavior.withdrawal_limits[publisher.id][productFormat.id].postroll,
            },
          ];
        });
      });
    });
    this.behaviorRows = this.sortRows(behaviorRows);
  }

  private sortRows(rows: BehaviorRow[]): BehaviorRow[] {
    const sorted = rows.sort((a, b) => {
      if (a.publisher !== b.publisher) return a.publisher.localeCompare(b.publisher);
      if (a.behavior !== b.behavior) return a.behavior.localeCompare(b.behavior);
      return a.productFormat.localeCompare(b.productFormat);
    });
    return sorted;
  }
}
interface BehaviorRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  behavior: string;
  behaviorId: wooId;
  tag: string;
  active: boolean;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
