import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { WooComponentsModule } from '../woo_components.module/module';
import { EstimatesList } from './components/estimates-list.component';
import { ImportEstimates } from './components/import-estimates.component';
import { EstimateImportService } from './services/EstimateImportService';
import { EstimateValidationErrorService } from './services/EstimateValidationErrorService';

@NgModule({
  imports: [BrowserModule, FormsModule, WooComponentsModule],
  providers: [EstimateImportService, EstimateValidationErrorService],
  declarations: [ImportEstimates, EstimatesList],
  exports: [ImportEstimates],
})
export class WooEstimates {}
