import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { SubscriptionHandler } from '../../advanced_campaign_booking.module/components/subscription-handler';
import { WooDialog } from './woo-dialog.component';

/**
 * This is intended as a helper class for custom dialogs which uses the WooDialog as their base.
 */
@Directive()
export class WooDialogHelper extends SubscriptionHandler implements AfterViewInit {
  public onClose: () => void;
  @ViewChild(WooDialog, { static: true }) public wooDialog: WooDialog;
  public open = (blocking?: boolean): void => this.wooDialog.open(blocking);
  public close = (): void => {
    this.wooDialog.close();
  };

  ngAfterViewInit(): void {
    this.wooDialog.onClose.subscribe(() => this.onClose?.());
  }
}
