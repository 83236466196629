import { Component, Input, OnChanges } from '@angular/core';
import { sum } from '../../../utils/array';
import { SimpleChanges } from '../../../utils/types';
import { TableRows } from '../../../woo_components.module/woo_tables/woo-table.component';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { Targeting, TargetingType, ViewCurrency } from '../../../woo_services.module/shared-types';
import { TargetingService } from '../../../woo_services.module/TargetingService';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { SimpleTracking, TargetingTracking } from '../../services/TrackingService';
import { TrackingDistributionData } from './tracking-distribution.component';

@Component({
  selector: 'targeting-outcome',
  templateUrl: './targeting-outcome.component.html',
})
export class TargetingOutcome implements OnChanges {
  @Input() targeting: Targeting;
  @Input() tracking: TargetingTracking;

  constructor(
    private formatterService: FormatterService,
    private targetingService: TargetingService,
    private translationService: TranslationService,
  ) {}

  viewCurrency: string;

  targetingLabelType: string;
  categoryDistribution: TrackingDistributionData[];
  deviceDistribution: TrackingDistributionData[];
  programDistribution: TrackingDistributionData[];
  regionDistribution: TrackingDistributionData[];
  categoryHeaders: string[];
  categoryData: TableRows;
  dailyHeaders: string[];
  dailyData: TableRows;
  deviceHeaders: string[];
  deviceData: TableRows;
  programHeaders: string[];
  programData: TableRows;
  regionHeaders: string[];
  regionData: TableRows;
  publisherHeaders: string[];
  publisherData: TableRows;
  pauseTargeting: boolean;

  ngOnChanges(changes: SimpleChanges<TargetingOutcome>): void {
    if (changes.targeting && this.targeting) {
      const targetingMetaData = this.targetingService.getMetaData(this.targeting);
      this.targetingLabelType = this.formatterService.getTargetingType(targetingMetaData);
      this.pauseTargeting = targetingMetaData.targetingType === TargetingType.pause;
      this.viewCurrency = this.formatterService.getCurrencyName(this.targeting);
    }

    if (changes.tracking && this.tracking && this.targeting) {
      this.setDistributions();
      this.categoryHeaders = this.getDistributionTableHeaders('Kategori');
      this.categoryData = this.getDistributionTableData(this.tracking.categories);
      this.deviceHeaders = this.getDistributionTableHeaders('Apparater');
      this.deviceData = this.getDistributionTableData(this.tracking.device_groups);
      this.programHeaders = this.getDistributionTableHeaders('Program');
      this.programData = this.getDistributionTableData(this.tracking.program_formats);
      this.publisherHeaders = this.getDistributionTableHeaders('Publicist');
      this.publisherData = this.getDistributionTableData(this.tracking.publishers);
      this.regionHeaders = this.getDistributionTableHeaders('Region');
      this.regionData = this.getDistributionTableData(this.tracking.regions);
      this.dailyHeaders = this.getDailyHeaders(this.targeting.view_currency);
      this.dailyData = this.getDailyData(this.targeting.view_currency);
    }
  }

  private getDistributionTableHeaders(firstCol: string): string[] {
    if (this.targeting.view_currency === ViewCurrency.grossRatingViews) {
      return [
        firstCol,
        'Beräknade bruttokontakter',
        this.translationService.convertViewCurrency(ViewCurrency.impressions),
        'Procent',
      ];
    }

    return [firstCol, this.translationService.convertViewCurrency(this.targeting.view_currency), 'Procent'];
  }

  private getDistribution(category: { [categoryName: string]: SimpleTracking }): TrackingDistributionData[] {
    return Object.entries(category)
      .map(([name, tracking]) => ({
        label: name,
        views: tracking[this.viewsToTrack],
      }))
      .sort((a, b) => b.views - a.views);
  }

  private get viewsToTrack() {
    switch (this.targeting.view_currency) {
      case ViewCurrency.completedViews: {
        return 'ads_completed';
      }
      case ViewCurrency.grossRatingViews: {
        return 'calculated_gross_rating_views';
      }
    }

    return 'impressions';
  }

  private setDistributions(): void {
    this.deviceDistribution = this.getDistribution(this.tracking.device_groups);
    this.regionDistribution = this.getDistribution(this.tracking.regions);
    this.categoryDistribution = this.getDistribution(this.tracking.categories);
    this.programDistribution = this.getDistribution(this.tracking.program_formats);
  }

  private getDailyHeaders(viewCurrency: ViewCurrency): string[] {
    if (viewCurrency === ViewCurrency.grossRatingViews) {
      return [
        'Datum',
        'Beräknade bruttokontakter',
        this.translationService.convertViewCurrency(ViewCurrency.impressions),
        'Click-throughs',
      ];
    }

    return ['Datum', this.translationService.convertViewCurrency(viewCurrency), 'Click-throughs'];
  }

  private getDailyData(viewCurrency: ViewCurrency): TableRows {
    const addEmptyColomn = false;

    if (viewCurrency === ViewCurrency.grossRatingViews) {
      return Object.entries(this.tracking.days)
        .map(([day, tracking]): string[] => [
          day,
          this.formatterService.transformNumber(tracking['calculated_gross_rating_views']),
          this.formatterService.transformNumber(tracking['impressions']),
          this.formatterService.transformNumber(tracking.click_throughs),
        ])
        .sort((d1, d2) => this.sortByDate(d1[0], d2[0]))
        .concat(
          this.createTotalRow(
            this.tracking.days,
            ['calculated_gross_rating_views', 'impressions', 'click_throughs'],
            addEmptyColomn,
          ),
        );
    }

    const trackViewCurrency = viewCurrency === ViewCurrency.completedViews ? 'ads_completed' : 'impressions';
    return Object.entries(this.tracking.days)
      .map(([day, tracking]): string[] => [
        day,
        this.formatterService.transformNumber(tracking[trackViewCurrency]),
        this.formatterService.transformNumber(tracking.click_throughs),
      ])
      .sort((d1, d2) => this.sortByDate(d1[0], d2[0]))
      .concat(this.createTotalRow(this.tracking.days, [trackViewCurrency, 'click_throughs'], addEmptyColomn));
  }

  private getDistributionTableData(category: { [categoryName: string]: SimpleTracking }): TableRows {
    if (!Object.values(category).length) return [];
    const addEmptyColomn = true;

    const totalViewTracker = this.viewsToTrack;
    const total = sum(Object.values(category).map((arg) => arg[this.viewsToTrack]));

    if (this.targeting.view_currency === ViewCurrency.grossRatingViews) {
      return Object.entries(category)
        .sort((a, b) => b[1][totalViewTracker] - a[1][totalViewTracker])
        .map(([productChoice, tracking]) => [
          productChoice,
          this.formatterService.transformNumber(tracking[totalViewTracker]),
          this.formatterService.transformNumber(tracking['impressions']),
          this.formatterService.transformPercent(tracking[totalViewTracker] / (total || 1)),
        ])
        .concat(this.createTotalRow(category, [totalViewTracker, 'impressions'], addEmptyColomn));
    }

    return Object.entries(category)
      .sort((a, b) => b[1][totalViewTracker] - a[1][totalViewTracker])
      .map(([productChoice, tracking]) => [
        productChoice,
        this.formatterService.transformNumber(tracking[totalViewTracker]),
        this.formatterService.transformPercent(tracking[totalViewTracker] / (total || 1)),
      ])
      .concat(this.createTotalRow(category, [totalViewTracker], addEmptyColomn));
  }

  private createTotalRow(
    collection: { [key: string]: SimpleTracking },
    trackKeys: string[],
    addEmptyColomn: boolean,
  ): string[][] {
    if (Object.values(collection).length <= 1) return [];

    const totals = Object.entries(collection).reduce(
      (totals, [_day, tracking]) => {
        trackKeys.forEach((key) => (totals[key] += tracking[key]));
        return totals;
      },
      trackKeys.reduce((zeroObject, key) => {
        zeroObject[key] = 0;
        return zeroObject;
      }, {}),
    );

    return [
      ['Totalt:']
        .concat(trackKeys.map((key) => this.formatterService.transformNumber(totals[key])))
        .concat(addEmptyColomn ? [''] : []),
    ];
  }

  private sortByDate = (date1: string | Date, date2: string | Date): number => {
    if (date1 > date2) {
      return 1;
    }

    if (date1 < date2) {
      return -1;
    }

    return 0;
  };
}
