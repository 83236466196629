import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { roundDecimalError } from 'app/scripts/utils/math';
import { ignore422 } from 'app/scripts/utils/utils';
import { DialogService } from '../../../../woo_services.module/DialogService';
import {
  ExtraProductFormatField,
  ProductFormatService,
  SharesUpdateParams,
} from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { ProductFormat, Publisher, SlotType, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-product-format-shares',
  templateUrl: './manage-product-format-shares.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageProductFormatShares implements OnInit {
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  @Input('headerPos') parentHeader: HTMLElement;
  show = {
    estimatesHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };
  readonly tableHeaders = [
    'Publicist',
    'Placering',
    'Preroll',
    'Midroll',
    'Postroll',
    'Preroll',
    'Midroll',
    'Postroll',
  ];
  readonly staticFields = 2;
  readonly slotTypes: SlotType[] = [SlotType.preroll, SlotType.midroll, SlotType.postroll];
  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  ProductFormatRow: ProductFormatRow[];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private utilityService: ProductsPageUtilityService,
    public dialogService: DialogService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled)
      .then((publishers) => {
        this.publishers = publishers.filter((p) => p.enabled);
        this.selectedPublisher = this.publishers[0];
      })
      .then(() => {
        this.getAndBuildSlotShareRows();
      })
      .finally(() => {
        this.show.loading = false;
        scrollTo(this.parentHeader);
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      });
  }

  async getAndBuildSlotShareRows(): Promise<void> {
    this.productFormatService
      .getProductFormats(
        ExtraProductFormatField.enabled,
        ExtraProductFormatField.productFormatShares,
        ExtraProductFormatField.withdrawalLimits,
        ExtraProductFormatField.slotTypes,
      )
      .then((productFormats) => {
        this.productFormats = productFormats.filter((f) => f.enabled);
        this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
          this.productFormats,
        );
        this.buildSlotTypeShares();
      });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildSlotShareRows();
    this.setEditing(false);
  }

  translateProductFormat(product: string): string {
    return this.translationService.convertProductFormat(product);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedPublisher;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.updatingEstimates = true;
        const shareParams = this.buildUpdateParams();
        this.productFormatService
          .updateProductFormatShares(shareParams)
          .then((_productFormats) => {
            this.productFormatService.updateProductFormats(this.productFormats).then((productFormats) => {
              this.productFormats = productFormats;
              this.show.estimatesHaveChanged = true;
            });
          })
          .catch(ignore422)
          .catch((err) => {
            this.getAndBuildSlotShareRows();
            this.dialogService.openError(this.translationService.convertError(err.error.error));
          });
      })
      .catch(() => this.getAndBuildSlotShareRows())
      .finally(() => {
        this.setEditing(false);
        this.show.updatingEstimates = false;
        this.dialogService.closeBlocking();
      });
  }

  autoFill(row: ProductFormatRow, slotType: SlotType): void {
    const row_shares = this.productFormats.find((productFormat) => productFormat.id === row.productFormatId)
      .product_format_shares;
    const opposite_shares = this.productFormats.find(
      (productFormat) => productFormat.publisher_id === row.publisherId && productFormat.id !== row.productFormatId,
    ).product_format_shares;

    if (slotType === SlotType.preroll) {
      opposite_shares.preroll = roundDecimalError(1.0 - row_shares.preroll, 3);
    } else if (slotType === SlotType.midroll) {
      opposite_shares.midroll = roundDecimalError(1.0 - row_shares.midroll, 3);
    }
  }

  rowVisible(obj: ProductFormatRow): boolean {
    return !this.selectedPublisher || !(obj.publisherId !== this.selectedPublisher.id);
  }

  sumOfProductFormatShares(slotType: SlotType): number {
    const productFormats = this.productFormats.filter((format) => format.publisher_id === this.selectedPublisher.id);
    return (
      Math.round(productFormats.reduce((a, b) => a + (b.product_format_shares[slotType] || 0), 0) * 100 * 100) / 100
    );
  }
  getProductFormat(productFormatId: wooId): ProductFormat {
    return this.productFormats.find((format) => format.id === productFormatId);
  }

  private buildUpdateParams(): SharesUpdateParams[] {
    let updateParams: SharesUpdateParams[] = [];
    this.productFormats.map((productFormat) => {
      updateParams = [
        ...updateParams,
        {
          id: productFormat.id,
          product_format_shares: {
            preroll: productFormat.product_format_shares.preroll,
            midroll: productFormat.product_format_shares.midroll,
            postroll: productFormat.product_format_shares.postroll,
          },
        },
      ];
    });

    return updateParams;
  }

  private buildSlotTypeShares(): void {
    let ProductFormatRow: ProductFormatRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        ProductFormatRow = [
          ...ProductFormatRow,
          {
            publisher: publisher.name,
            publisherId: publisher.id,
            productFormat: productFormat.name,
            productFormatId: productFormat.id,
            withdrawalLimitsPreroll: productFormat.withdrawal_limits.preroll,
            withdrawalLimitsMidroll: productFormat.withdrawal_limits.midroll,
            withdrawalLimitsPostroll: productFormat.withdrawal_limits.postroll,
            sharesPreroll: productFormat.product_format_shares.preroll,
            sharesMidroll: productFormat.product_format_shares.midroll,
            sharesPostroll: productFormat.product_format_shares.postroll,
          },
        ];
      });
    });

    this.ProductFormatRow = ProductFormatRow;
  }
}

interface ProductFormatRow {
  publisher: string;
  publisherId: wooId;
  productFormat: string;
  productFormatId: wooId;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
}
