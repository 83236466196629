
<table>
  <thead>
    <tr>
      <th></th>
      <th class="padding-bottom--1x padding-left--2x text-align--center text--medium">Estimatfördelning</th>
      <th class="padding-bottom--1x padding-left--2x text-align--center text--medium">Maxuttag</th>
    </tr>
  </thead>
  <tr *ngFor="let site of sites">
    <td>
      {{site.name}}
    </td>
    <td class="padding-left--2x text-align--center">
      <div class="input__container margin--none display--inline-block fixed-max-width">
        <input wooPercentFormatter [wooMinValidator]="0" [wooMaxValidator]="1" [(ngModel)]="shares[site.id]">
      </div>
    </td>
    <td class="padding-left--2x text-align--center">
      <div class="input__container margin--none display--inline-block fixed-max-width">
        <input wooPercentFormatter [wooMinValidator]="0" [wooMaxValidator]="1" [(ngModel)]="withdrawalLimits[site.id]">
      </div>
    </td>
  </tr>
</table>
