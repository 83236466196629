import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { wooId } from '../../woo_services.module/shared-types';

@Injectable()
export class TrackingService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getTracking(campaignId: wooId): Promise<CampaignTracking> {
    return lastValueFrom(this.http.get<CampaignTracking>(`${this.env.apiUrl}/tracking/${campaignId}`));
  }

  getPublisherStatistics(): Promise<PublisherOutcome[]> {
    return lastValueFrom(this.http.get<PublisherOutcome[]>(`${this.env.apiUrl}/tracking/publishers`));
  }
}

export interface CampaignTracking {
  id: wooId;
  updated: string;
  days: DayTracking[];
  outcome_mms_status: OutcomeMmsStatus[];
  tv_days: TVDayTracking[];
  tv_days_totals: TotalTVDayTracking[];
  targetings: TargetingTracking[];
}

export interface SimpleTracking {
  impressions: number;
  click_throughs: number;
  ads_started: number;
  ads_completed: number;
  calculated_gross_rating_views?: number | null;
}

export interface DayTracking {
  day: string;
  impressions: number;
  completed_views: number;
  click_throughs: number;
}

export interface TVDayTracking {
  tv_day: string;
  video_code: string;
  pulse_ads_completed: number;
  pulse_share: number;
  mms_total_cv: number;
  mms_total_impressions: number;
  mms_total_gross_rating_views: number;
  pulse_calculated_gross_rating_views: number;
  mms_target_group_cv: number | null;
  mms_target_group_impressions: number | null;
  mms_target_group_gross_rating_views: number | null;
  mms_actuals_cv: number | null;
  mms_actuals_impressions: number | null;
  mms_actuals_gross_rating_views: number | null;
  calculated_from_ad_server: boolean;
}

export interface TotalTVDayTracking extends TVDayTracking {
  mms_actuals_completion_rate: number | null;
  missing_days_for_video_code: number;
}

export interface TargetingTracking {
  targeting_id: wooId;
  bought_impressions: number;
  impressions: number;
  unique_impressions: number;
  completion_rate: string;
  completed_views: number;
  calculated_gross_rating_views?: number | null;
  click_through_rate: string;
  click_throughs: number;
  publishers: { [publisherName: string]: SimpleTracking };
  regions: { [regionName: string]: SimpleTracking };
  categories: { [categoryName: string]: SimpleTracking };
  program_formats: { [programName: string]: SimpleTracking };
  device_groups: { [deviceGroupName: string]: SimpleTracking };
  days: { [date: string]: SimpleTracking & { ctr: string } };
}

export interface PublisherOutcome {
  name: string;
  impressions: number;
}

export enum OutcomeMmsStatus {
  ok = 'ok',
  noMmsTrackedCreatives = 'no_mms_tracked_creatives',
  includesInvalidMmsDays = 'includes_invalid_mms_days',
  usesPulseActuals = 'uses_pulse_actuals',
  missingActiveDays = 'missing_active_days',
  sharesVideoCode = 'shares_video_code',
  userInvalidatedCreativeData = 'user_invalidated_creative_data',
}
