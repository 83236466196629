import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { setFormDirty } from '../../../../utils/form';
import {
  PriceList,
  PriceListCurrency,
  PriceListService,
  PriceListType,
} from '../../../../woo_services.module/PriceListService';

@Component({
  selector: 'select-pricelist-form',
  templateUrl: './select-pricelist-form.component.html',
})
export class SelectPricelistForm implements OnChanges {
  readonly availablePriceListTypes = PriceListType;
  readonly availablePriceListCurrencies = PriceListCurrency;
  readonly getYear = this.priceListService.getYear;
  readonly setFormDirty = setFormDirty;
  readonly translatePriceListType = this.priceListService.translatePriceListType;
  readonly translatePriceListCurrency = this.priceListService.translatePriceListCurrency;

  availableYears: AvailableYear[] = [];
  availablePriceLists: PriceList[] = [];

  selectedPriceListType: PriceListType = PriceListType.Standard;
  selectedPriceListYear: number;
  selectedPriceList: PriceList;
  selectedPriceListCurrency: PriceListCurrency = PriceListCurrency.GrossRatingViews;

  @Input() priceLists: PriceList[] = [];
  @Output() onSubmit = new EventEmitter<{
    priceList: PriceList;
    year: number;
    priceListType: PriceListType;
    priceListCurrency: PriceListCurrency;
  }>();
  @Output() onAbort = new EventEmitter<void>();

  constructor(private priceListService: PriceListService) {}
  ngOnChanges(): void {
    this.updateListSelections();
  }

  updateListSelections(): void {
    const existingPriceListsOfCombination = this.priceLists.filter((list): boolean => {
      return (
        list.price_list_type === this.selectedPriceListType &&
        list.price_list_currency === this.selectedPriceListCurrency
      );
    });
    this.availableYears = this.getAvailableYears(existingPriceListsOfCombination);

    this.availablePriceLists = this.priceLists;
  }

  priceListLabel(priceList: PriceList): string {
    return (
      `Prislista för år ${this.getYear(priceList)} - ` +
      `${this.translatePriceListType(priceList.price_list_type)} - ` +
      `${this.translatePriceListCurrency(priceList.price_list_currency)}`
    );
  }

  creatNewPriceList(): void {
    this.onSubmit.emit({
      priceList: this.selectedPriceList,
      year: this.selectedPriceListYear,
      priceListType: this.selectedPriceListType,
      priceListCurrency: this.selectedPriceListCurrency,
    });
  }

  cancel(): void {
    this.onAbort.emit();
  }

  private getAvailableYears(priceLists: PriceList[]): AvailableYear[] {
    const currentYear = new Date().getFullYear();
    const yearsWithPriceList = priceLists.map(this.getYear);
    return Array.from(new Array(5)).map((_, i) => ({
      year: currentYear + i,
      disabled: yearsWithPriceList.includes(currentYear + i),
    }));
  }
}

interface AvailableYear {
  year: number;
  disabled: boolean;
}
