import { Injectable } from '@angular/core';
import { uniq } from '../../utils/array';
import { add, max } from '../../utils/date';
import { MessageType } from '../../woo_components.module/feedback/context-message.component';
import { Campaign, CampaignStatus, ViewCurrency, wooId } from '../../woo_services.module/shared-types';
import { BookingModel, BookingStep, EstimationStatusTyped } from './BookingStore';

@Injectable()
export class BookingStoreSelectors {
  earliestTargetingStart(model: BookingModel, targetingId: wooId): Date {
    const creditWarningRestriction = model.selectedAgency.invoiceCustomer
      ? this.getCustomerCreditWarningRestriction(model)
      : this.getAgencyCreditWarningRestriction(model);
    const targeting = model.campaign.targetings.find((t) => t.id === targetingId) || { send_invoices: true };

    return max([
      new Date(),
      targeting.send_invoices ? creditWarningRestriction : new Date(),
      model.campaignStart.agencyAgreementRestriction || new Date(),
      model.campaignStart.roleRestriction || new Date(),
    ]);
  }

  editingBooked(campaign: Campaign): boolean {
    return campaign.status !== CampaignStatus.unbooked;
  }

  isValid(model: BookingModel): boolean {
    return model.validationMessages.filter((msg) => msg.type === MessageType.error).length === 0;
  }

  /**
   * @returns true if there are no errors and the current estimation is valid
   */
  isStable(model: BookingModel): boolean {
    return (
      this.isValid(model) &&
      !model.waitingFor.estimateCampaign &&
      model.estimation &&
      model.estimation.status === EstimationStatusTyped.ok
    );
  }

  /**
   * @returns the budget of the selected targeting or the entire campaign if no targetingId is provided
   */
  totalBudget(model: BookingModel, targetingId?: wooId): number {
    return model.campaign.targetings
      .filter((t) => !targetingId || t.id === targetingId)
      .map((t) => {
        const part = model.estimation.parts.find((p) => p.targeting_id === t.id);
        return t.calculate_from_budget
          ? t.budget + t.additional_budget
          : this.isStable(model) && part
          ? part.total_net_budget
          : 0;
      })
      .reduce((sum, budget) => sum + budget, 0);
  }

  /**
   * @returns the views of the selected targeting or the entire campaign if no targetingId is provided
   */
  totalViews(model: BookingModel, targetingId?: wooId, viewCurrency?: ViewCurrency, pauseAd?: boolean): number {
    return model.campaign.targetings
      .filter((t) => !targetingId || t.id === targetingId)
      .filter((t) => !viewCurrency || t.view_currency === viewCurrency)
      .filter((t) => pauseAd === undefined || t.pause_ad === pauseAd || (!pauseAd && t.pause_ad === undefined))
      .map((t) => {
        const part = model.estimation.parts.find((p) => p.targeting_id === t.id);
        return !t.calculate_from_budget
          ? t.budgeted_impressions + t.additional_budgeted_impressions
          : this.isStable(model) && part
          ? part.total_net_views
          : 0;
      })
      .reduce((sum, views) => sum + views, 0);
  }

  /**
   * @returns the bookingsteps that user has visited or is visiting
   */
  visitedSteps(model: BookingModel): BookingStep[] {
    return uniq(model.dirtySteps.concat(model.activeBookingStep));
  }

  private getAgencyCreditWarningRestriction(model: BookingModel): Date {
    const today = new Date();
    if (model.campaign.agency_id && !model.selectedAgency.invoiceCustomer && model.selectedAgency.lowCreditRating) {
      return add(today, model.campaignStart.minNumberOfDaysUntilStartForCreditWarning);
    } else {
      return today;
    }
  }

  private getCustomerCreditWarningRestriction(model: BookingModel): Date {
    const today = new Date();
    if (model.selectedCustomer.lowCreditRating) {
      return add(today, model.campaignStart.minNumberOfDaysUntilStartForCreditWarning);
    } else {
      return today;
    }
  }
}
