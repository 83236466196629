import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

/**
 * This directive adds a class to the target element when a file is drag over it
 */
@Directive({
  selector: '[wooFileDragClass]',
})
export class WooFileDragClass {
  @Input('wooFileDragClass') dragClass: string;
  private enterLeaveCount = 0;

  constructor(private elem: ElementRef, private renderer: Renderer2) {}

  @HostListener('dragenter') enter(): void {
    this.enterLeaveCount += 1;
    this.applyClass();
  }

  @HostListener('dragleave') leave(): void {
    this.enterLeaveCount -= 1;
    this.applyClass();
  }

  @HostListener('drop') drop(): void {
    this.enterLeaveCount = 0;
  }

  private applyClass() {
    if (this.enterLeaveCount > 0) {
      this.renderer.addClass(this.elem.nativeElement, this.dragClass);
    } else {
      this.renderer.removeClass(this.elem.nativeElement, this.dragClass);
    }
  }
}
