<tag-list [viewCurrency]="viewCurrency" [items]="productFormats()"></tag-list>

<tag-list [viewCurrency]="viewCurrency" [items]="targeting.regions" defaultTagName="Nationell" [shareType]="targeting.regional_share_type"></tag-list>

<tag-list [viewCurrency]="viewCurrency" [items]="targeting.categories"></tag-list>

<tag-list [viewCurrency]="viewCurrency" [items]="targeting.device_groups"></tag-list>

<tag-list [viewCurrency]="viewCurrency" [items]="targeting.behaviors"></tag-list>

<tag-list [viewCurrency]="viewCurrency" [items]="targeting.program_formats" [shareType]="targeting.program_format_share_type"></tag-list>

<tag-list [viewCurrency]="viewCurrency" [items]="targeting.dayparts"></tag-list>

<tag-list [viewCurrency]="viewCurrency" [items]="targetGroupNames()"></tag-list>

<tag-list [viewCurrency]="viewCurrency" [items]="targeting.advanced_target_groups"></tag-list>
