import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from '../../../../utils/dom';
import { DefaultFrequencyLimitService } from '../../../../woo_services.module/DefaultFrequencyLimitService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { DefaultFrequencyLimit } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-default-frequency-limits',
  templateUrl: './manage-default-frequency-limits.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageDefaultFrequencyLimits implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    loading: true,
  };

  readonly staticFields = 4;
  readonly tableHeaders = ['Namn', 'Impressions', 'Tidsenhet', 'Redigera'];

  defaultFrequencyLimits: DefaultFrequencyLimit[] = [];
  editingRow: string;
  translateTimeUnit: (args: any) => string;
  constructor(
    private defaultFrequencyLimitService: DefaultFrequencyLimitService,
    private utilityService: ProductsPageUtilityService,
    private dialogService: DialogService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateTimeUnit = this.translationService.convertFrequencyLimitTimeUnit;
    this.getAndSetFrequencyLimits().then(() => {
      this.show.loading = false;
      this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      scrollTo(this.parentHeader);
    });
  }

  async getAndSetFrequencyLimits(): Promise<void> {
    this.defaultFrequencyLimitService.getAll().then((defaultFrequencyLimits) => {
      this.defaultFrequencyLimits = defaultFrequencyLimits;
    });
  }

  setEditingRow(frequncyLimit: DefaultFrequencyLimit): void {
    this.editingRow = frequncyLimit.id;
  }

  unsetEditingRow(): void {
    this.editingRow = '';
  }

  isEditingRow(frequencyLimit: DefaultFrequencyLimit): boolean {
    return frequencyLimit.id === this.editingRow;
  }

  async save(frequencyLimit: DefaultFrequencyLimit): Promise<void> {
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.defaultFrequencyLimitService.updateDefaultFrequencyLimit(frequencyLimit).then((updatedFrequencyLimit) => {
          this.defaultFrequencyLimits.map((frequencyLimit) =>
            frequencyLimit.id === updatedFrequencyLimit.id ? updatedFrequencyLimit : frequencyLimit,
          );
        });
      })
      .catch((err) => {
        this.dialogService.openError(this.translationService.convertError(err.error.error));
      })
      .finally(() => {
        this.unsetEditingRow();
        this.dialogService.closeBlocking();
      });
  }
}
