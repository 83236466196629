import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { TargetingService } from '../../../woo_services.module/TargetingService';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { Campaign, CampaignStatus, Targeting } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'edit-ongoing-campaign-page',
  templateUrl: './edit-ongoing-campaign-page.component.html',
})
export class EditOngoingCampaignPage implements OnInit {
  convertViewCurrency = this.translationService.convertViewCurrency;

  campaign: Campaign;
  minEndDate: Date;
  hasVideoCreatives: boolean;
  targeting: Targeting;
  targetingNames: string[] = [];

  show = {
    selectContainer: true,
    boostGoals: false,
    extendCampaign: false,
    addCreative: false,
    updateCampaignName: false,
    updateCampaignBudget: false,
  };

  targetGroupNames: string[] = [];

  constructor(
    private routingService: RoutingService,
    private campaignService: CampaignService,
    private targetingService: TargetingService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.loadCampaign();
  }

  navigateTo(id: string): void {
    this.show.selectContainer = false;
    this.show.boostGoals = false;
    this.show.extendCampaign = false;
    this.show.addCreative = false;
    this.show.updateCampaignName = false;
    this.show.updateCampaignBudget = false;
    this.show[id] = true;
  }

  loadCampaign(): void {
    this.campaignService.get(this.routingService.getState('id')).then((campaign) => {
      this.campaign = campaign;
      this.targeting = this.campaign.targetings[0];
      this.targetingNames = this.targetingService.getTargetingNames(this.campaign.targetings);
      this.targetGroupNames = this.targetingService.getTargetGroupNames(this.targeting);
      this.hasVideoCreatives = this.campaignService.campaignHasVideoCreatives(
        campaign.targetings.flatMap((t) => t.creatives),
      );
      this.minEndDate =
        this.campaign.status === CampaignStatus.ongoing ? new Date(this.targeting.end_date) : new Date();
    });
  }

  campaignExtended(campaign: Campaign): void {
    this.loadCampaign();
    this.campaign = campaign;
    this.show.extendCampaign = false;
    this.show.selectContainer = true;
  }
}
