import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { range } from '../../../../utils/array';
import { CreativeService } from '../../../../woo_services.module/CreativeService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import {
  FrequencyLimitPrice,
  PriceList,
  PriceListService,
  PriceListType,
} from '../../../../woo_services.module/PriceListService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { PriceListPriceGroups, Publisher, wooId } from '../../../../woo_services.module/shared-types';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'edit-pricelist',
  templateUrl: './edit-pricelist.component.html',
  styleUrls: ['./price-list-table.component.scss'],
})
export class EditPricelist implements OnInit {
  readonly creativeLengths = this.creativeService.getCreativeLengths();
  priceList: Partial<PriceList> = this.priceListService.getEmpty();
  publishers: Publisher[] = [];
  basePriceList: PriceRow[] = [];
  targetingPriceList: PriceRow[] = [];
  ageModifierPriceList: PriceRow[] = [];
  localityPriceList: PriceRow[] = [];
  addonTargetingPriceList: PriceRow[] = [];
  weekIndexWeeks: number[][] = [];
  loading = true;

  readonly PriceListType = PriceListType;
  readonly getLabel = this.priceListService.getLabel;
  readonly translateProductFormat = this.translationService.convertProductFormat;
  readonly translateTargetingProduct = this.translationService.convertProductChoice;
  readonly translateAgeModifierKey = this.translationService.convertAgePriceListKey;
  readonly translateLocality = this.translationService.convertLocality;
  readonly translatePriceListType = this.priceListService.translatePriceListType;
  readonly translatePriceListCurrency = this.priceListService.translatePriceListCurrency;

  @Input() newPriceList?: Partial<PriceList>;
  @Output() onSubmit = new EventEmitter<Omit<PriceList, 'id'>>();

  constructor(
    private publisherService: PublisherService,
    private priceListService: PriceListService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
    private creativeService: CreativeService,
  ) {}

  ngOnInit(): void {
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled)
      .then((publishers) => {
        this.publishers = publishers.filter((p) => p.enabled);
      })
      .then((): any => {
        return this.reloadPriceListAndUpdateCompnent();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  updatePriceList(): void {
    const list = this.priceList as PriceList;
    list.id = this.routingService.getParam(this.route, 'id');
    this.priceListService.updatePriceList(list).then(() => {
      const msg = `Prislistan för ${this.priceListService.getLabel(list)} ${list.price_list_type} har uppdaterats`;
      this.dialogService.openSuccess(msg);
      this.goToProducts();
    });
  }

  get priceListHeading(): string {
    return (
      `Prislista för år ${this.getLabel(this.priceList as PriceList)} - ` +
      `${this.translatePriceListType(this.priceList.price_list_type)} - ` +
      `${this.translatePriceListCurrency(this.priceList.price_list_currency)}`
    );
  }

  get overWrittenHeadingText(): string {
    return `Redigerade priser (${Object.keys(this.priceList.overwritten_product_prices).length} st)`;
  }

  get showOverWrittenPriceComponent(): boolean {
    return !this.newPriceList || !!Object.keys(this.priceList.overwritten_product_prices)?.length;
  }

  get saveButtonLabel(): string {
    return `${this.newPriceList ? 'Skapa' : 'Spara'} prislista`;
  }

  get priceHeadingLabel(): string {
    return `${this.priceListService.translatePriceListCurrencyShort(this.priceList.price_list_currency)}/CPM`;
  }

  goToProducts(): void {
    this.routingService.navigateWithReload(['/products', 'price_lists']);
  }

  getActivePrices(frequencyLimitPrices?: FrequencyLimitPrice[]): FrequencyLimitPrice[] {
    return (frequencyLimitPrices || []).filter((price) => price.frequency_limit.active);
  }

  getPublisherName(publisherId: wooId): string {
    return this.publishers.find((publisher) => publisher.id === publisherId)?.name;
  }

  reloadPriceList(priceListId: wooId): void {
    if (this.priceList.id === priceListId) {
      this.loading = true;
      this.reloadPriceListAndUpdateCompnent().finally(() => {
        this.loading = false;
      });
    }
  }

  clearOverwrittenPrices(): void {
    if (this.newPriceList) {
      this.priceList.overwritten_product_prices = {};
    }
  }

  private reloadPriceListAndUpdateCompnent(): Promise<void> {
    return this.loadPriceList().then(() => {
      this.utilityService.filterDisabledPublisherPrices(this.priceList, this.publishers);
      this.basePriceList = this.buildPriceRows(PriceListPriceGroups.publisher_base_prices);
      this.targetingPriceList = this.buildPriceRows(PriceListPriceGroups.publisher_targeting_prices);
      this.ageModifierPriceList = this.buildPriceRows(PriceListPriceGroups.publisher_age_modifier_prices);
      this.localityPriceList = this.buildPriceRows(PriceListPriceGroups.publisher_locality_prices);
      this.addonTargetingPriceList = this.buildPriceRows(PriceListPriceGroups.publisher_addon_targeting_prices);
      const weeks = this.priceListService.getValidWeekCount(this.priceList as PriceList);
      const firstWeeks = Math.ceil(weeks / 2);
      const secondWeeks = Math.floor(weeks / 2);
      this.weekIndexWeeks = [range(firstWeeks, 1), range(secondWeeks, firstWeeks + 1)];
    });
  }

  private loadPriceList(): Promise<void> {
    if (this.newPriceList) {
      this.priceList = this.newPriceList;
      return Promise.resolve();
    }

    return this.priceListService.getPriceList(this.routingService.getParam(this.route, 'id')).then((priceList) => {
      this.priceList = priceList;
    });
  }

  private buildPriceRows(priceGroup: string): PriceRow[] {
    let priceRows: PriceRow[] = [];

    Object.keys(this.priceList[priceGroup]).map((publisherId) => {
      Object.keys(this.priceList[priceGroup][publisherId]).map((priceType) => {
        priceRows = [
          ...priceRows,
          {
            product: priceType,
            publisherId: publisherId,
            price: this.priceList[priceGroup][publisherId][priceType],
          },
        ];
      });
    });
    return priceRows;
  }
}

interface PriceRow {
  product: string;
  publisherId: wooId;
  price: number;
}
