import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VideoItem } from '../asset-url-input.component';

const template = /* html */ `
<div class="flex__container">
  <div
    *ngIf="listHeader"
    class="video-section__header-container">
    <span>{{listHeader}}</span>
  </div>
  <div
    class="width--33 padding--4px"
    *ngFor="let asset of assets"
    [ngClass]="{'padding--none': selectedVideo?.url === asset?.url}"
    data-toggle="tooltip"
    [title]="asset.name">
    <div class="video-thumbnail__container">
      <video (click)="selectVideo(asset)" [src]="asset.url" class="border-radius--soft pointer"></video>
      <span class="display--block">{{asset.videoCode}}</span>
    </div>
  </div>
</div>
`;

@Component({
  selector: 'asset-list',
  template: template,
  styleUrls: ['./asset-list.component.scss'],
})
export class AssetList {
  @Input() listHeader: string;
  @Input() assets: VideoItem[] = [];
  @Input() selectedVideo;
  @Output() onVideoClick = new EventEmitter<VideoItem>();

  selectVideo(asset: VideoItem): void {
    this.onVideoClick.emit(asset);
  }
}
