import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../woo_services.module/RoutingService';

const template = /* html */ `
  <div class="block">
    <div *ngIf="token">
      <h1>Välkommen!</h1>
      <p>Du behöver välja ett lösenord innan du kan logga in för första gången.</p>
      <update-password-form [token]="token" buttonText="Sätt igång!"></update-password-form>
    </div>
  </div>
`;

@Component({
  selector: 'set-first-password',
  template: template,
})
export class SetFirstPassword {
  token: string;
  constructor(private route: ActivatedRoute, private routingService: RoutingService) {}

  ngOnInit(): void {
    this.token = this.routingService.getQueryParam(this.route, 'token');
  }
}
