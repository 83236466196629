import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[wooCopyTableAsNumber]',
})
export class WooCopyTableAsNumber {
  @HostListener('copy', ['$event'])
  drop(ev: Event & { clipboardData: DataTransfer }): void {
    ev.preventDefault();
    ev.stopPropagation();
    const selection = document.getSelection().toString();
    const formatRow = (row: string): string => {
      const toNum = (v) => Number(v.trim().replace(/\s/, ''));
      return row
        .split('\t')
        .map((col) => (isNaN(toNum(col)) ? col : toNum(col)))
        .join('\t');
    };
    const EOL = this.getEOL();
    const data = selection.split(EOL).map(formatRow).join(EOL);

    ev.clipboardData.setData('text/plain', data);
  }

  private getEOL(): string {
    const str = `
    `;
    return str.includes('\r\n') ? '\r\n' : '\n';
  }
}
