import { Component, Input } from '@angular/core';
import { saveFileAs } from '../../../utils/files';
import { DialogService } from '../../../woo_services.module/DialogService';
import { OutcomeReportsService } from '../../../woo_services.module/OutcomeReportsService';
import { wooId } from '../../../woo_services.module/shared-types';

const template = /* html */ `
<button class="link text--semi-strong margin--none padding--none" (click)="download()">
  <span class="icon-download"></span> Ladda ner slutstatistik
</button>
`;

@Component({
  selector: 'download-outcome-button',
  template: template,
})
export class DownloadOutcomeButton {
  @Input() campaignId: wooId;
  @Input() campaignName: wooId;

  constructor(private dialogService: DialogService, private outcomeReportsService: OutcomeReportsService) {}

  download = (): void => {
    this.dialogService.openBlocking('Skapar fil');
    this.outcomeReportsService.summaryExport(this.campaignId).then(
      (data) => {
        this.dialogService.closeBlocking();
        saveFileAs(data, `Slutstatistik - ${this.campaignName}.xlsx`);
      },
      () => {
        this.dialogService.closeBlocking();
        this.dialogService.openError('Det finns ingen statistik för vald kampanj.');
      },
    );
  };
}
