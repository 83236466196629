import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, Input, OnChanges } from '@angular/core';
import { ValidatedTargetGroupSelect } from '../../../simple_campaign_booking.module/components/targeting/validated-target-group-select.component';
import { AbstractValueAccessor } from '../../../utils/AbstractValueAccessor';
import { maxBy, minBy } from '../../../utils/array';
import { valueAccessorProvider } from '../../../utils/provider-builders';
import { IdScope } from '../../../utils/string';
import { SimpleChanges } from '../../../utils/types';
import { AgeTargetGroup, GenderTargetGroup, TargetGroup } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'target-group-select',
  templateUrl: './target-group-select.component.html',
  providers: [valueAccessorProvider(TargetGroupSelect)],
})
export class TargetGroupSelect extends AbstractValueAccessor<TargetGroupSelected> implements OnChanges {
  @Input() genders: GenderTargetGroup[];
  @Input() ages: AgeTargetGroup[];

  /* Needed to set correct value in checkboxes, because of two components in same view */

  singleGender = false;
  ageRequired = false;
  sliderOptions: Options = {
    disabled: true,
    draggableRange: true,
    floor: 15,
    ceil: 80,
    step: 5,
    minRange: 10,
    translate: (value, label) => {
      if (label === LabelType.High || label === LabelType.Ceil) {
        value -= 1;
      }
      return value + ' år';
    },
  };
  selectedGender = null;
  ageSpan = {
    min: 20,
    max: 29,
  };
  idScope = null;

  constructor() {
    super();
    this.idScope = new IdScope();
  }

  ngOnChanges(changes: SimpleChanges<ValidatedTargetGroupSelect>): void {
    if (changes.ages && this.ages?.length > 0) {
      this.setSliderSpan(this.ages);
    }
  }

  setSliderSpan = (ages: AgeTargetGroup[]): void => {
    this.sliderOptions = { ...this.sliderOptions, floor: minBy(ages, 'min_age'), ceil: maxBy(ages, 'max_age') + 1 };
  };

  onSliderChange(event: { value: number; highValue: number }): void {
    this.ageSpan = {
      min: event.value,
      max: event.highValue,
    };
    this.change();
  }

  change(): void {
    const targetGroup = { genders: this.genders, ages: [], choices: null };
    targetGroup.choices = { gender: this.singleGender, age: this.ageRequired };

    if (this.singleGender) {
      targetGroup.genders = this.selectedGender === null ? [] : [this.selectedGender];
    }
    if (this.ageRequired) {
      const ages = this.ages
        .filter((age) => age.min_age >= this.ageSpan.min)
        .filter((age) => age.max_age <= this.ageSpan.max);
      targetGroup.ages = ages;
    }

    this.model = targetGroup;
  }

  writeValue(model: TargetGroupSelected): void {
    if (model) {
      model.choices = model.choices ? model.choices : { gender: false, age: false };
      this.singleGender = model.choices.gender;
      this.ageRequired = model.choices.age;
      this.selectedGender = this.singleGender ? model.genders[0] || null : null;
      this.sliderOptions = { ...this.sliderOptions, disabled: !this.ageRequired };
      if (model.ages.length > 0) {
        this.ageSpan = {
          min: Math.min(...model.ages.map((age) => age.min_age)),
          max: Math.max(...model.ages.map((age) => age.max_age)),
        };
      }
      super.writeValue(model);
    }
  }

  toggleAgeRequired(): void {
    this.ageRequired = !this.ageRequired;
    this.sliderOptions = { ...this.sliderOptions, disabled: !this.ageRequired };
    this.change();
  }

  toggleSingleGender(): void {
    this.singleGender = !this.singleGender;
    this.selectedGender = null;
    this.change();
  }
}

export interface TargetGroupSelected extends TargetGroup {
  choices: TargetGroupChoices;
}

export interface TargetGroupChoices {
  gender: boolean;
  age: boolean;
}
