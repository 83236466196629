<div class="padding--1x background-color--white shadow-z-1 margin-bottom--1x clearfix">
  <expandable-header backgroundColor="white" text="Automatisk MMS-stärkning">
    <div class="padding--1x">
      <p class="thin" *ngIf="mmsTrackedCampaign">Här kan du avaktivera/aktivera den automatiska MMS-stäkningen</p>
      <p class="thin" *ngIf="!mmsTrackedCampaign"><span class="icon-warning"></span> Kampanjen följs inte upp med MMS och stärks därför inte.</p>
      <p class="thin" *ngIf="mmsTrackedCampaign && wrongCampaignStatus">
        <span class="icon-warning"></span> Kampanjen måste vara bokad för att ändra MMS-stärknings regler.
      </p>
      <div class="col-md-12 padding--none margin-top--3x">
        <toggle
          [(ngModel)]="automaticMmsGainActive"
          offLabel="Avaktiverad"
          onLabel="Aktiverad"
          [disabled]="!mmsTrackedCampaign || wrongCampaignStatus">
        </toggle>
      </div>
      <div class="col-md-12 padding--none margin-top--3x">
        <button class="button primary-1 margin-top--2x"
          [disabled]="!mmsTrackedCampaign || wrongCampaignStatus || toggleIsSetToCampaignValue()"
          (click)="updateCampaignAutomaticMmsGain()">{{saveButtonLabel()}}</button>
      </div>
    </div>
  </expandable-header>
</div>
