<div class="relative" #element>
  <div class="flex__container align-items--start">
    <div class="input__container padding-right--1x color-controlled-container" [ngClass]="{marked: show.calender && !start}">
      <label for="from">Från <span *ngIf="forcedStartDate" class="text--danger">{{forcedStartDate | date:'yyyy-MM-dd'}}</span></label>
      <input
        id="from"
        placeholder="YYYY-MM-DD"
        [ngModel]="start"
        (ngModelChange)="setStartDateIfValid($event); show.pristine.start = false"
        (click)="$event.stopPropagation(); showCalender(Focus.Start)"
        [attr.disabled]="disabled ? '' : null"
        wooDateFormatter
        [wooDateValidator]="{min: firstSelectableDate, max: enableMaxDateForUncrossableDate() ? uncrossableMaxDate : lastSelectableEndDate}"
        wooInputErrors
        (blur)="touch()"
        [ngClass]="{'ng-pristine': show.pristine.start || show.calender, 'ng-focus': focus === Focus.Start }"
      >
    </div>

    <div class="input__container padding-right--1x color-controlled-container" [ngClass]="{marked: show.calender && start && !end}">
      <label for="to">Till <span *ngIf="forcedEndDate" class="text--danger">{{forcedEndDate | date:'yyyy-MM-dd'}}</span></label>
      <input
        id="to"
        placeholder="YYYY-MM-DD"
        [ngModel]="end"
        (ngModelChange)="setEndDateIfValid($event); show.pristine.end = false"
        (click)="$event.stopPropagation(); showCalender(Focus.End)"
        [attr.disabled]="disabled ? '' : null"
        wooDateFormatter
        [wooDateValidator]="{min: firstSelectableEndDate, max: enableMaxDateForUncrossableDate() ? uncrossableMaxDate : lastSelectableEndDate}"
        wooInputErrors
        (blur)="touch()"
        [ngClass]="{'ng-pristine': show.pristine.end || show.calender, 'ng-focus': focus === Focus.End }"
      >
    </div>
  </div>

  <div
    class="overlay"
    [ngClass]="{'open-above': show.calenderAbove, 'open-below': !show.calenderAbove}"
    *ngIf="show.calender"
    (click)="$event.stopPropagation()"
  >
    <div class="calender-container first-one">

      <calender-heading
        class="display--block padding-bottom--1x show-left-button"
        [date]="firstMonthDate"
        (prevClick)="prevMonth()"
        (nextClick)="nextMonth()"
      ></calender-heading>

      <calender-top-row></calender-top-row>

      <div class="flex__container" *ngFor="let pair of firstMonth | keyvalue:compareWeeksByStartDate; last as last">
        <div class="calender-label" [ngClass]="{'bottom-end' : last}">{{pair.key}}</div>
        <div
          class="calender-day"
          [attr.e2e-id]="day.disabled ? null : day.date | date:DATE_FORMAT"
          *ngFor="let day of pair.value"
          [ngClass]="{
            disabled: day.disabled,
            'not-selectable': !day.selectable,
            selected: isSelected(day),
            changing: isChanging(day),
            marked: day.marked,
            first: isFirstSelected(day),
            last: isLastSelected(day)
          }"
          (click)="selectDay(day)"
          (mouseover)="mouseoverDay(day)"
          (mouseleave)="mouseleaveDay(day)"
        >
          <div class="calender-day-content" [ngClass]="{'calender-day-content-last': isLastSelected(day)}">
            {{day.date.getDate()}}
          </div>
        </div>
      </div>

    </div>

    <div class="calender-container second-one">

      <calender-heading
        class="display--block padding-bottom--1x show-right-button"
        [date]="secondMonthDate"
        (prevClick)="prevMonth()"
        (nextClick)="nextMonth()"
      ></calender-heading>

      <calender-top-row></calender-top-row>

      <div class="flex__container" *ngFor="let pair of secondMonth | keyvalue:compareWeeksByStartDate; last as last">
        <div class="calender-label" [ngClass]="{'bottom-end' : last}">{{pair.key}}</div>
        <div
          class="calender-day"
          [attr.e2e-id]="day.disabled ? null : day.date | date:DATE_FORMAT"
          *ngFor="let day of pair.value"
          [ngClass]="{
            disabled: day.disabled,
            'not-selectable': !day.selectable,
            selected: isSelected(day),
            marked: day.marked,
            changing: isChanging(day),
            first: isFirstSelected(day),
            last: isLastSelected(day)
          }"
          (click)="selectDay(day)"
          (mouseover)="mouseoverDay(day)"
          (mouseleave)="mouseleaveDay(day)"
        >
          <div class="calender-day-content" [ngClass]="{'calender-day-content-last': isLastSelected(day)}">
            {{day.date.getDate()}}
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
