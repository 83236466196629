<h1 class="margin-top-bottom--2x">Redigera TTV-inställning</h1>

<div class="tabbable__body shadow-z-1 padding--4x">

  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="input__container">
          <label for="name">Namn</label>
            <input type="text" id="name" formControlName="name">
        </div>
      </div>
    </div>

    <div class="padding-bottom--1x">
      <h4>Externt ID</h4>
      <p>{{targetingMapping.ttv_target_group}}</p>
    </div>

    <div class="padding-bottom--1x">
      <h4>Faktor</h4>
      <p>{{targetingMapping.factor}}</p>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="input__container">
          <span class="input__optional__text">Frivillig</span>
          <label for="description">Kommentar (faktorberäkning)</label>
          <textarea type="text" id="description" formControlName="description"></textarea>
        </div>
      </div>
    </div>

    <div class="padding-bottom--1x">
      <h4>Styrning</h4>
      <p>{{targetingType}}</p>
    </div>

    <div *ngIf="categoryNames.length" class="padding-bottom--1x">
      <h4>Kategorier</h4>
      <span class="tab tab--small" *ngFor="let category of categoryNames">
        {{category}}
      </span>
    </div>

    <div *ngIf="targetGroupNames.length" class="padding-bottom--1x">
      <h4>Målgrupper</h4>
      <span class="tab tab--small" *ngFor="let targetGroup of targetGroupNames">
        {{targetGroup}}
      </span>
    </div>

    <div class="padding-bottom--1x">
      <h4>Startdatum</h4>
      <p>{{targetingMapping.start_date | date: 'yyyy-MM-dd' }}</p>
    </div>

    <div class="padding-bottom--1x">
      <h4>Skapad</h4>
      <p>{{targetingMapping.created_at | date: 'yyyy-MM-dd HH:mm'}}</p>
    </div>

    <div class="padding-top--2x">
      <button class="button primary" [disabled]="!form.valid">Spara</button>
      <button class="button danger" type="button" (click)="remove()">Ta bort</button>
      <button class="link" type="button" (click)="abort()">Avbryt</button>
    </div>

  </form>

</div>
