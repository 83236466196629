<div>
  <form *ngIf="initiated" (ngSubmit)="update(settings)">
    <div *ngFor="let settingGroup of editableSettings">
      <h3>{{settingsGroupHeader(settingGroup)}}</h3>
      <div *ngFor="let settingName of settingsGroupSettings(settingGroup)">
        <div class="input__container">
          <label>{{settingLabel(settingGroup, settingName)}}</label>
          <input id={{settingName}} type="number" name={{settingName}} [(ngModel)]="settings[settingName]">
        </div>
      </div>
      <div class="divider medium margin-top-bottom--4x"></div>
    </div>

    <ng-container *ngIf="!!settings.feature_toggles.length">
      <h3>Feature toggles</h3>
      <div *ngFor="let feature_toggle of settings.feature_toggles;">
        <h5 class="thin">{{toggleLabel(feature_toggle.id, feature_toggle.name)}}</h5>
        <toggle
          [(ngModel)]="feature_toggle.on"
          name={{feature_toggle.id}}
          offLabel="Av"
          onLabel="På">
        </toggle>
      </div>
      <div class="divider medium margin-top-bottom--4x"></div>
    </ng-container>

    <div>
      <h3>Schemalägg paus av fakturering</h3>
      <div class="padding-bottom--1x">
        <toggle
          [(ngModel)]="showPauseInvoices"
          (ngModelChange)="togglePauseInvoices()"
          name="pause-invoices-checkbox"
          offLabel="Av"
          onLabel="På">
        </toggle>
      </div>
      <ng-container *ngIf="showPauseInvoices">
        <p>Fakturering stängs alla datum inklusive start och slutdatum.</p>
        <period-select
          [ngModel]="{start: settings.invoice_pause_start_date, end: settings.invoice_pause_end_date}"
          (ngModelChange)="pauseInvoicesPeriodChanged($event)"
          name="pauseInvoices"
        ></period-select>
      </ng-container>
    </div>
    <div class="divider medium margin-top-bottom--4x"></div>
    <button type="Submit" class="button margin-top--3x" wooLoad>Spara</button>
  </form>
</div>
