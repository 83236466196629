import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../utils/types';
import { AuthService } from '../../woo_services.module/AuthService';
import { ExtendedTargetingBudget, Targeting } from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';

@Component({
  selector: 'extended-targeting-budget-view',
  templateUrl: './extended_targeting_budget_view.compontent.html',
})
export class ExtendedTargetingBudgetView implements OnChanges {
  @Input() targeting: Targeting;

  readonly adminOrPlanner = this.authService.adminOrPlanner();

  extendedBudgets: ExtendedTargetingBudget[];
  totalBudget: number;
  totalAdditionalBudget: number;
  totalImpressions: number;
  viewCurrency: string;
  viewCurrencyShort: string;

  constructor(private translationService: TranslationService, private authService: AuthService) {}

  ngOnChanges(changes: SimpleChanges<ExtendedTargetingBudgetView>): void {
    if (changes.targeting && this.targeting) {
      this.extendedBudgets = this.targeting.extended_budgets;
      this.totalBudget = this.calculateTotalBudget();
      this.totalAdditionalBudget = this.calculateTotalAdditionalBudget();
      this.totalImpressions = this.calculateTotalImpressions();
      this.viewCurrency = this.translationService.convertViewCurrency(this.targeting.view_currency);
      this.viewCurrencyShort = this.translationService.convertViewCurrencyShort(this.targeting.view_currency);
    }
  }

  private calculateTotalBudget(): number {
    return this.extendedBudgets?.reduce((sum, extendedBudget) => sum + extendedBudget.budget, 0) || 0;
  }

  private calculateTotalAdditionalBudget(): number {
    return this.extendedBudgets?.reduce((sum, extendedBudget) => sum + extendedBudget.additional_budget, 0) || 0;
  }

  private calculateTotalImpressions(): number {
    return (
      this.extendedBudgets?.reduce(
        (sum, extendedBudget) => sum + extendedBudget.impressions + extendedBudget.additional_impressions,
        0,
      ) || 0
    );
  }
}
