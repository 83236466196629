import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { WooComponentsModule } from '../woo_components.module/module';
import { ActiveListComponent } from './external_campaigns_page/active-list.component';
import { DetailsDialog } from './external_campaigns_page/details-dialog.component';
import { EndedListComponent } from './external_campaigns_page/ended-list.component';
import { ExternalCampaignsPage } from './external_campaigns_page/external-campaigns-page.component';
import { InvalidListComponent } from './external_campaigns_page/invalid-list.component';
import { ExternalCampaignService } from './services/ExternalCampaignService';

@NgModule({
  imports: [BrowserModule, WooComponentsModule],
  providers: [ExternalCampaignService],
  declarations: [ActiveListComponent, DetailsDialog, EndedListComponent, ExternalCampaignsPage, InvalidListComponent],
  exports: [ExternalCampaignsPage],
})
export class ExternalCampaignsModule {}
