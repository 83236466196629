import { Component, Input } from '@angular/core';
import { CompactCampaign, CreativeStatus } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'dashboard-card-creative-status',
  templateUrl: './dashboard-card-creative-status.component.html',
})
export class DashboardCardCreativeStatus {
  @Input() campaign: CompactCampaign;

  creativeStatus: CreativeStatus;

  async ngOnInit(): Promise<void> {
    this.creativeStatus = this.getCreativeStatus(this.campaign.creatives);
  }

  private getCreativeStatus(creatives): CreativeStatus {
    if (creatives.some((creative) => creative.status === CreativeStatus.PeachFileNotFound)) {
      return CreativeStatus.PeachFileNotFound;
    }
    if (creatives.some((creative) => creative.status === CreativeStatus.NotReady)) {
      return CreativeStatus.NotReady;
    }
    if (creatives.some((creative) => creative.status === CreativeStatus.Unknown)) {
      return CreativeStatus.Unknown;
    }
    if (creatives.some((creative) => creative.status === CreativeStatus.Ready)) {
      return CreativeStatus.Ready;
    }
    return null;
  }
}
