import { Component, Input, OnChanges } from '@angular/core';
import { AbstractValueAccessor } from '../../../utils/AbstractValueAccessor';
import { valueAccessorProvider } from '../../../utils/provider-builders';
import { SimpleChanges } from '../../../utils/types';

@Component({
  selector: 'woo-customer-dropdown',
  templateUrl: './woo-customer-dropdown.component.html',
  styleUrls: ['../woo-dropdown.component.scss'],
  providers: [valueAccessorProvider(WooCustomerDropdown)],
})
export class WooCustomerDropdown<T, U> extends AbstractValueAccessor<U> implements OnChanges {
  @Input() items: T[];
  @Input() inputId: string;
  @Input() labelText: string;

  /**
   * The property of an item to be used as value and set to model
   * Null means the entire item
   */
  @Input() bindValue: string = null;

  /**
   * Function to transform the label of items, e.g. a translation
   */
  @Input() labelCreate: (args: any) => string;
  @Input() clearable = false;
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() notFoundText = 'Inga matchningar';
  @Input() placeholder = '';

  @Input() searchAttributes: string[] = [];

  searchFn;

  ngOnChanges(changes: SimpleChanges<WooCustomerDropdown<T, U>>): void {
    if (changes.searchAttributes && this.searchAttributes) {
      this.searchFn = this.searchForAttributes(this.searchAttributes);
    }
  }

  searchForAttributes(attributes: string[]) {
    return function (searchTerm: string, item: any): boolean {
      let exists = false;
      attributes.forEach((attr) => {
        if (item[attr] && item[attr].toLowerCase().includes(searchTerm.toLowerCase())) {
          exists = true;
        }
      });
      return exists;
    };
  }
}
