import { Component, Input } from '@angular/core';
import { WooCurrencyValue } from '../../../woo_components.module/pipes/woo-currency.pipe';

const template = /* html */ `
  <p>
    Du har fördelat <span class="text--strong">{{distributed | number}}</span> av totalt <span class="text--strong">{{totalToDistribute | wooCurrency:currencyType}}</span>
  </p>
  <p [ngClass]="{'text--danger': !validDistribution}">
    Du har <span class="text--strong">{{totalToDistribute - distributed | wooCurrency:currencyType}}</span> kvar att fördela.
  </p>
`;

@Component({
  selector: 'remaining-distribution',
  template: template,
})
export class RemainingDistribution {
  @Input() distributed = 0;
  @Input() totalToDistribute = 0;
  @Input() currencyType: WooCurrencyValue;

  get validDistribution(): boolean {
    return this.totalToDistribute === this.distributed;
  }
}
