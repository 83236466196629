import { Component, OnInit } from '@angular/core';
import { ignore422 } from '../../utils/utils';
import { AuthService } from '../../woo_services.module/AuthService';
import { DialogService } from '../../woo_services.module/DialogService';
import {
  EosCampaign,
  EosCampaignRow,
  EosInventoryRow,
  EosService,
  EosTTVCampaignRow,
  EosTTVInventoryRow,
  InventoryFilter,
} from '../../woo_services.module/EosService';
import { wooId } from '../../woo_services.module/shared-types';

@Component({
  selector: 'eos-api-tester',
  templateUrl: './eos-api-tester.component.html',
})
export class EosApiTester implements OnInit {
  testMode = 0;
  date: string;
  ttvDate: string;
  campaigns: EosCampaign[] = [];
  ttvCampaigns: EosCampaign[] = [];
  campaign: EosCampaign = null;
  ttvCampaign: EosCampaign = null;
  aggregatedRows: EosAggregatedRowsYear = {
    year: null,
    net_estimate: null,
    months: null,
  };
  aggregatedRowsYear: number;
  rows: EosCampaignRow[] | EosTTVCampaignRow[] = [];
  inventoryRows: Array<EosInventoryRow | EosTTVInventoryRow> = [];
  inventoryRowsFilter: InventoryFilter = {
    startYear: '',
    startWeek: '',
    numberOfWeeks: '',
  };
  readonly isAdmin = this.authService.admin();

  constructor(private eosService: EosService, private dialogService: DialogService, private authService: AuthService) {}

  ngOnInit(): void {
    this.eosService.getCampaigns().then((result) => {
      this.campaigns = result.campaigns;
    });
    this.eosService.getTTVCampaigns().then((result) => {
      this.ttvCampaigns = result.campaigns;
    });
  }

  getRows(campaignId: wooId, dateString: string): void {
    this.rows = [];
    this.testMode = 1;
    const date = dateString ? new Date(dateString) : undefined;
    this.eosService.getRows(campaignId, date).then((result) => {
      console.debug(result.rows);
      this.rows = result.rows;
    });
  }

  getTTVRows(campaignId: wooId, dateString: string): void {
    this.rows = [];
    this.testMode = 2;
    const ttvDate = dateString ? new Date(dateString) : undefined;
    this.eosService.getTTVRows(campaignId, ttvDate).then((result) => {
      console.debug(result.rows);
      this.rows = result.rows;
    });
  }

  getAggregatedRows(): void {
    this.testMode = 3;
    const year = this.aggregatedRowsYear;
    this.eosService
      .getAggregatedRowsByYear(year)
      .then((result) => {
        this.aggregatedRows.year = year;
        this.aggregatedRows.net_estimate = result.net_estimate;
      })
      .then(() => {
        const months = Array.from(Array(12), (e, i) => i + 1);
        const promises = months.map((month) => this.eosService.getAggregatedRowsByMonth(year, month));
        return Promise.all(promises);
      })
      .then((results) => {
        this.aggregatedRows.months = results.map((result, i) => {
          return { month: i + 1, net_estimate: result.net_estimate };
        });
      })
      .catch((err) => {
        if (err) console.error(err);
        this.dialogService.openError('Aj då, nåt gick visst snett');
      });
  }

  getInventoryRows(rowsFilters: InventoryFilter): void {
    this.rows = [];
    this.testMode = 4;
    this.eosService.getInventoryRows(rowsFilters).then((result) => {
      console.debug(result.rows);
      this.inventoryRows = result.rows;
    });
  }

  getTTVInventoryRows(rowsFilters: InventoryFilter): void {
    this.rows = [];
    this.testMode = 4;
    this.eosService.getTTVInventoryRows(rowsFilters).then((result) => {
      console.debug(result.rows);
      this.inventoryRows = result.rows;
    });
  }

  rebuildRows(campaignId: wooId, name: string): void {
    this.dialogService.openBlocking(`Rebuild EOS-rows for ${name}`);
    this.eosService
      .rebuildRows(campaignId)
      .then((result) => {
        this.dialogService.openSuccess(
          `EOS rader har updaterats för kampanj. Det blev ${result.row_count}, bara att läsa in igen.`,
        );
      })
      .catch(ignore422)
      .catch((err) => {
        if (err) console.error(err);
        this.dialogService.openError(err.error.message);
      })
      .finally(() => {
        this.dialogService.closeBlocking();
      });
  }
}

export interface EosAggregatedRowsYear {
  year: number;
  net_estimate: string;
  months: EosAggregatedRowsMonth[];
}

interface EosAggregatedRowsMonth {
  month: number;
  net_estimate: string;
}
