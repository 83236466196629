import { Component, Input } from '@angular/core';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { Campaign } from '../../../woo_services.module/shared-types';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
<div class="flex--align justify-content--space-between padding-top-bottom--3x" *ngIf="campaign.new_contracts && adminOrPlanner">
      <div class="text--cta">
        <p>Det finns nya rabatter att hämta!</p><p *ngIf="campaign.ad_hoc_contracts.length">Notera AdHoc-rabatter</p>
      </div>
      <div class="flex__container">
        <p class="text--medium padding-right--2x">Använd nya rabatter</p>
        <input 
          [(ngModel)]="campaign.use_new_contract" 
          id="{{campaign.id}}" 
          type="checkbox" 
          class="cbx hidden"
          (ngModelChange)="toggleNewContractDiscounts(campaign.use_new_contract)"/>
        <label for="{{campaign.id}}" class="lbl"></label>
      </div>
    </div>
`;

@Component({
  selector: 'new-discount-toggle',
  template: template,
})
export class NewDiscountToggle extends SubscriptionHandler {
  @Input() campaign: Campaign;

  readonly adminOrPlanner = this.authService.hasAnyRole([UserRole.admin, UserRole.planner]);

  constructor(private bookingStore: BookingStore, private authService: AuthService) {
    super();
    this.addSubscription(this.bookingStore.state$.subscribe(this.initFromStore));
  }

  initFromStore = (model: BookingModel): void => {
    this.campaign = model.campaign;
  };

  toggleNewContractDiscounts = (useNewContract: boolean): void => {
    this.bookingStore.setUseNewContract(useNewContract);
  };
}
