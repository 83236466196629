import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  readonly apiUrl = this.window['WOO_ENV']?.API_URL || environment.api_url;
  readonly awsCloudfrontUrl = this.window['WOO_ENV']?.AWS_CLOUDFRONT_URL || environment.aws_cloudfront_url;
  readonly production = Boolean(this.window['WOO_ENV']?.PRODUCTION) || environment.production;
  readonly adServerBreakDate = this.window['WOO_ENV']?.AD_SERVER_BREAK_DATE || environment.ad_server_break_date;
  readonly selfServiceBreakDate =
    this.window['WOO_ENV']?.SELF_SERVICE_BREAK_DATE || environment.self_service_break_date;

  constructor(@Inject('Window') private window: Window) {}
}
