import { Component, Input } from '@angular/core';
import { AdServerStatus } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'ad-server-status-badge',
  templateUrl: './ad-server-status-badge.component.html',
})
export class AdServerStatusBadge {
  readonly AdServerStatus = AdServerStatus;
  @Input() adServerStatus: AdServerStatus;
  @Input() small: boolean;
}
