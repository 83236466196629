import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceInCalendarDays, formatDistanceToNow, min, parseISO, startOfDay } from 'date-fns';
import { Subject, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { stringHash } from '../utils/string';
import { UserRole } from '../utils/user-roles';
import { AuthService } from './AuthService';
import { CustomerService } from './CustomerService';
import { EnvironmentService } from './EnvironmentService';
import { CampaignInventoryTrees, InventoryTree } from './InventoryService';
import { PaginationService } from './PaginationService';
import { SystemService } from './SystemService';
import { Campaign, CampaignStatus, CompactCampaign, CompactCustomer, HistoryItem, wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  dashboardExtended$: Subject<boolean> = new Subject();

  constructor(
    private authService: AuthService,
    private customerService: CustomerService,
    private env: EnvironmentService,
    private http: HttpClient,
    private paginationService: PaginationService,
    private systemService: SystemService,
  ) {}

  campaigns: CampaignSets = {
    booked_campaigns: {
      name: 'Bokade',
      route: 'booked_campaigns',
      color: 'info',
      pagination: {
        page: 1,
        total: 0,
        perPage: 0,
        last: 0,
      },
      loading: true,
      hash: '',
      budget: 0,
      list: [],
      search: {},
    },
    unbooked_campaigns: {
      name: 'Utkast',
      route: 'unbooked_campaigns',
      color: 'cta',
      pagination: {
        page: 1,
        total: 0,
        perPage: 0,
        last: 0,
      },
      loading: true,
      hash: '',
      budget: 0,
      list: [],
      search: {},
    },
    upcoming_campaigns: {
      name: 'Väntar på start',
      route: 'upcoming_campaigns',
      color: 'info',
      pagination: {
        page: 1,
        total: 0,
        perPage: 0,
        last: 0,
      },
      loading: true,
      hash: '',
      budget: 0,
      list: [],
      search: {},
    },
    ongoing_campaigns: {
      name: 'Rullande',
      route: 'ongoing_campaigns',
      color: 'info',
      pagination: {
        page: 1,
        total: 0,
        perPage: 0,
        last: 0,
      },
      loading: true,
      hash: '',
      budget: 0,
      list: [],
      search: {},
    },
    ended_campaigns: {
      name: 'Avslutade',
      route: 'ended_campaigns',
      color: 'success',
      pagination: {
        page: 1,
        total: 0,
        perPage: 0,
        last: 0,
      },
      loading: true,
      hash: '',
      budget: 0,
      list: [],
      search: {},
    },
    cancelled_campaigns: {
      name: 'Avbokade',
      route: 'cancelled_campaigns',
      color: 'danger',
      pagination: {
        page: 1,
        total: 0,
        perPage: 0,
        last: 0,
      },
      loading: true,
      hash: '',
      budget: 0,
      list: [],
      search: {},
    },
    stopped_campaigns: {
      name: 'Stoppade',
      route: 'stopped_campaigns',
      color: 'danger',
      pagination: {
        page: 1,
        total: 0,
        perPage: 0,
        last: 0,
      },
      loading: true,
      hash: '',
      budget: 0,
      list: [],
      search: {},
    },
    inventory_only_campaigns: {
      name: 'Bortbokningar',
      route: 'inventory_only_campaigns',
      color: 'danger',
      pagination: {
        page: 1,
        total: 0,
        perPage: 0,
        last: 0,
      },
      loading: true,
      hash: '',
      budget: 0,
      list: [],
      search: {},
    },
  };

  totalBudget = 0;
  totalCampaigns = 0;
  customersForUser: CompactCustomer[] = [];
  campaignsForCustomer: CompactCampaign[] = [];

  async loadDashboard(pageNr: number, searchAttributes: any): Promise<boolean> {
    const promises = Object.keys(this.campaigns).map((route) => {
      this.campaigns[route].search = Object.assign({}, searchAttributes);
      return this.loadCampaignSet(route, pageNr, searchAttributes);
    });

    return (await Promise.all(promises)).every((b) => b);
  }

  async loadCampaignSet(route: string, pageNr: number, searchAttributes: any): Promise<boolean> {
    this.campaigns[route].loading = true;

    const params = {
      page: pageNr,
      q: searchAttributes.searchText ? searchAttributes.searchText : null,
      start_date: searchAttributes.from ? new Date(searchAttributes.from) : null,
      end_date: searchAttributes.to ? new Date(searchAttributes.to) : null,
      date_type: searchAttributes.dateType ? searchAttributes.dateType.type : null,
      ad_organisation_id: searchAttributes.adOrganisation ? searchAttributes.adOrganisation.id : null,
      customer_id: searchAttributes.customer ? searchAttributes.customer.id : null,
      include_closed: searchAttributes.includeClosed ? true : null,
    };
    const httpParams = Object.entries(params).reduce((httpParams, [key, value]) => {
      return value ? httpParams.set(key, value.toString()) : httpParams;
    }, new HttpParams());

    const campaigns = await lastValueFrom(
      this.http
        .get<CompactCampaign[]>(`${this.env.apiUrl}/${route}`, {
          observe: 'response',
          params: httpParams,
        })
        .pipe(
          map((response) => {
            this.campaigns[route].pagination = this.paginationService.getPagination(response.headers);
            return response.body;
          }),
        ),
    );
    try {
      if (this.campaigns[route].hash !== stringHash(JSON.stringify(campaigns))) {
        this.campaigns[route].hash = stringHash(JSON.stringify(campaigns));
        this.campaigns[route].budget = campaigns.reduce((sum, campaign) => sum + campaign.budget, 0);
        this.totalBudget += this.campaigns[route].budget;
        this.totalCampaigns += campaigns.length;

        this.campaigns[route].list = campaigns.map((campaign) => {
          const c = campaign as DashboardCampaign;
          c.stamp = this.prepareTime(campaign);
          return c;
        });

        return campaigns.length > 0;
      }
      return null;
    } finally {
      this.campaigns[route].loading = false;
    }
  }

  async getAllCampaignsForCustomer(customerId: wooId, fields: string[], orderBy: string): Promise<CompactCampaign[]> {
    const params = new HttpParams();
    params.set('sort', `-${orderBy}`);
    if (fields?.length) {
      params.set('fields', fields.join(','));
    }
    return (this.campaignsForCustomer = await lastValueFrom(
      this.http.get<CompactCampaign[]>(`${this.env.apiUrl}/customers/${customerId}/campaigns`, {
        params,
      }),
    ));
  }

  getApprovableCampaigns(): Promise<CompactCampaign[]> {
    return lastValueFrom(this.http.get<CompactCampaign[]>(`${this.env.apiUrl}/campaigns_with_alerts`));
  }

  cancel(campaignId: wooId, comment: string): Promise<any> {
    return lastValueFrom(
      this.http.delete(`${this.env.apiUrl}/campaigns/${campaignId}/cancel`, { params: { comment: comment } }),
    );
  }

  stop(campaignId: wooId, comment: string): Promise<any> {
    return lastValueFrom(
      this.http.delete(`${this.env.apiUrl}/campaigns/${campaignId}/stop`, { params: { comment: comment } }),
    );
  }

  getHistory(campaignId: wooId): Promise<HistoryItem[]> {
    return lastValueFrom(this.http.get<HistoryItem[]>(`${this.env.apiUrl}/campaigns/${campaignId}/history`));
  }

  getInventoryForCampaign(campaignId: wooId): Promise<CampaignInventoryTrees> {
    return lastValueFrom(this.http.get<CampaignInventoryTrees>(`${this.env.apiUrl}/campaigns/${campaignId}/inventory`));
  }

  getInventoryForTargeting(campaignId: wooId, targetingId: wooId): Promise<InventoryTree> {
    return lastValueFrom(
      this.http.get<InventoryTree>(`${this.env.apiUrl}/campaigns/${campaignId}/targetings/${targetingId}/inventory`),
    );
  }

  // Could be replaced with date-fns
  getTimeStamp(start: Date, end: Date, now: Date): string {
    let timeStamp;
    let diffInDays;

    // Upcoming
    if (start > now) {
      diffInDays = differenceInCalendarDays(start, now);

      if (diffInDays === 1) {
        timeStamp = `Börjar imorgon`;
      } else if (diffInDays < 28) {
        timeStamp = `Börjar om ${diffInDays} dagar`;
      } else {
        timeStamp = `Börjar om ${formatDistanceToNow(start, { addSuffix: false })}`;
      }
    }

    // Ongoing
    if (start <= now && now < end) {
      diffInDays = differenceInCalendarDays(start, now);
      if (diffInDays === 0) {
        timeStamp = `Började idag`;
      } else if (diffInDays === 1) {
        timeStamp = `Började igår`;
      } else if (diffInDays < 28) {
        timeStamp = `Började för ${diffInDays} dagar sedan`;
      } else {
        timeStamp = `Började för ${formatDistanceToNow(start, { addSuffix: false })} sedan`;
      }
    }

    if (now >= end) {
      diffInDays = differenceInCalendarDays(now, end);
      if (diffInDays === 0) {
        timeStamp = `Slutar idag`;
      } else if (diffInDays === 1) {
        timeStamp = `Slutade igår`;
      } else if (diffInDays < 28) {
        timeStamp = `Slutade för ${diffInDays} dagar sedan`;
      } else {
        timeStamp = `Slutade för ${formatDistanceToNow(end, { addSuffix: false })} sedan`;
      }
    }

    return timeStamp;
  }

  private prepareTime(campaign: CompactCampaign): string {
    const start = typeof campaign.start_date === 'string' ? parseISO(campaign.start_date) : campaign.start_date;
    const end = typeof campaign.end_date === 'string' ? parseISO(campaign.end_date) : campaign.end_date;
    const now = startOfDay(new Date());

    return this.getTimeStamp(start, end, now);
  }

  async getCustomersForCurrentUser(): Promise<CompactCustomer[]> {
    const customers = await this.customerService.getCustomersForCurrentUser();
    this.customersForUser = customers.filter((customer) => {
      return !customer.locked;
    });
    if (this.customersForUser.length === 1) {
      await this.getAllCampaignsForCustomer(this.customersForUser[0].id, ['name', 'id'], 'start_date');
    }
    return customers;
  }

  allowedToEditCreatives = async (campaign: Campaign | CompactCampaign): Promise<boolean> => {
    const notAllowedToEditCreativesReason = await this.checkAllowedToEditCreativesCriteria(campaign);
    return !notAllowedToEditCreativesReason;
  };

  async checkAllowedToEditCreativesCriteria(campaign: Campaign | CompactCampaign): Promise<EditCreativeCriterion> {
    const firstCreative =
      'targetings' in campaign ? campaign.targetings[0].creatives[0] ?? null : campaign.creatives[0] ?? null;

    if (!firstCreative) {
      return EditCreativeCriterion.noCreatives;
    }

    if (
      !this.authService.hasAnyRole([
        UserRole.admin,
        UserRole.planner,
        UserRole.agencyUser,
        UserRole.agencyAdmin,
        UserRole.client,
      ])
    ) {
      return EditCreativeCriterion.wrongRole;
    }

    if (this.authService.hasAnyRole([UserRole.agencyUser, UserRole.agencyAdmin, UserRole.client])) {
      return await this.clientCreativeCriteria(campaign);
    }

    return this.adminPlannerCreativeCriteria(campaign);
  }

  private async clientCreativeCriteria(campaign: Campaign | CompactCampaign): Promise<EditCreativeCriterion> {
    const startDateRaw =
      'start_date' in campaign
        ? campaign.start_date
        : min(campaign.targetings.map((targeting) => new Date(targeting.start_date)));

    const startSafe = typeof startDateRaw === 'string' ? parseISO(startDateRaw) : startDateRaw;
    const diffInDays = differenceInCalendarDays(startSafe, startOfDay(new Date()));

    if (campaign.status === CampaignStatus.upcoming || campaign.status === CampaignStatus.booked) {
      const clientUserMinDaysUpdateCreatives = await this.systemService.getValue(
        campaign.gambling_campaign ? 'min_days_to_update_gambling_creatives' : 'min_days_to_update_creatives',
      );
      if (diffInDays + 1 <= clientUserMinDaysUpdateCreatives) {
        return EditCreativeCriterion.campaignStartTooSoon;
      }
    } else {
      return EditCreativeCriterion.wrongCampaignStatus;
    }
    return null;
  }

  private adminPlannerCreativeCriteria(campaign: Campaign | CompactCampaign): EditCreativeCriterion {
    if (
      campaign.status !== CampaignStatus.upcoming &&
      campaign.status !== CampaignStatus.booked &&
      campaign.status !== CampaignStatus.ongoing
    ) {
      return EditCreativeCriterion.wrongCampaignStatus;
    }

    return null;
  }
}

interface DashboardCampaign extends CompactCampaign {
  stamp: string;
}

enum EditCreativeCriterion {
  campaignStartTooSoon = 'campaignStartTooSoon',
  noCreatives = 'noCreatives',
  wrongCampaignStatus = 'wrongCampaignStatus',
  wrongRole = 'wrongRole',
}

export interface CampaignSets {
  booked_campaigns: Record<string, any>;
  unbooked_campaigns: Record<string, any>;
  upcoming_campaigns: Record<string, any>;
  ongoing_campaigns: Record<string, any>;
  ended_campaigns: Record<string, any>;
  cancelled_campaigns: Record<string, any>;
  stopped_campaigns: Record<string, any>;
  inventory_only_campaigns: Record<string, any>;
}
