import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { AdOrganisation } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdOrganisationService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getAdOrganisations(): Promise<AdOrganisation[]> {
    return lastValueFrom(this.http.get<AdOrganisation[]>(`${this.env.apiUrl}/ad_organisations`));
  }
}
