import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { pull } from 'lodash-es';
import { trackById } from '../../../utils/object';
import { UserRole } from '../../../utils/user-roles';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../woo_services.module/DialogService';
import { wooId } from '../../../woo_services.module/shared-types';
import {
  SystemMessage,
  SystemMessageParams,
  SystemMessageService,
} from '../../../woo_services.module/SystemMessageService';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'system-messages',
  templateUrl: './system-messages.component.html',
})
export class SystemMessages implements OnInit {
  newMessage: SystemMessageParams = {
    message: '',
    type: 'info',
    roles: [],
  };
  show = {
    createMessageForm: false,
  };
  roles: Role[];

  systemMessages: SystemMessage[] = [];

  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('newSystemMessage', { static: true }) newSystemMessage: ElementRef;
  constructor(
    private dialogService: DialogService,
    private systemMessageService: SystemMessageService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.roles = this.initRoleList();
    this.systemMessageService.getAll().then((messages) => {
      this.systemMessages = messages;
    });
  }

  addMessage(message: SystemMessageParams): void {
    message.message = message.message.trim();
    if (this.roles.filter((role: Role) => !role.selected).length !== 0) {
      message.roles = this.roles
        .filter((role: Role) => role.selected)
        .reduce<UserRole[]>((userRoles: UserRole[], role: Role) => {
          userRoles.push(role.userRole);
          return userRoles;
        }, []);
    }
    this.systemMessageService.create(message).then((newMessage) => {
      this.systemMessages.push(newMessage);
      this.newMessage = {
        message: '',
        type: 'info',
        roles: [],
      };
      this.selectAllRoles();
      this.show.createMessageForm = false;
      this.dialogService.openSuccess('Meddelandet är nu skapat!');
    });
  }

  removeMessage(message: SystemMessage): void {
    const dialogContent: ConfirmDialogContent = {
      header: 'Ta bort meddelande',
      textBlocks: ['Är du säker på att du vill ta bort följande meddelande?', message.message],
      confirmText: 'Ja, ta bort',
      cancelText: 'Avbryt',
    };
    this.dialogService.openConfirm(dialogContent).then(() => {
      this.systemMessageService.remove(message.id).then(() => {
        this.dialogService.openSuccess('Meddelandet är nu borttaget.');
        pull(this.systemMessages, message);
      });
    });
  }

  disableSubmit(message: SystemMessageParams): boolean {
    return message.message.trim().length === 0 || this.roles.filter((role: Role) => role.selected).length === 0;
  }

  cancelNewMessage(): void {
    this.show.createMessageForm = false;
    this.newMessage = {
      message: '',
      type: 'info',
      roles: [],
    };
    this.selectAllRoles();
  }

  trackById(): (_: any, item: { id: wooId }) => wooId {
    return trackById;
  }

  trackRole(_: any, role: { userRole: UserRole }): UserRole {
    return role.userRole;
  }

  bookingRoles(): Role[] {
    return this.roles.filter((role: Role) => role.bookingRole);
  }

  noneBookingRoles(): Role[] {
    return this.roles.filter((role: Role) => !role.bookingRole);
  }

  selectAllRoles(): void {
    this.selectionAllRows(true);
  }

  deselectAllRoles(): void {
    this.selectionAllRows(false);
  }

  roleName(userRole: UserRole): string {
    return this.translationService.convertRoles(userRole);
  }

  showNewSystemMessage(value: boolean): void {
    this.show.createMessageForm = true;
    if (value) {
      //this.show.createMessageForm = false;
      scrollTo(this.newSystemMessage.nativeElement);
    } else {
      scrollTo(this.parentHeader);
    }
  }

  private selectionAllRows(selected: boolean): void {
    this.roles.map((role: Role) => {
      role.selected = selected;
    });
  }

  private initRoleList(): Role[] {
    return [
      this.createRole(UserRole.admin, true),
      this.createRole(UserRole.planner, true),
      this.createRole(UserRole.agencyAdmin, true),
      this.createRole(UserRole.agencyUser, true),
      this.createRole(UserRole.client, true),
      this.createRole(UserRole.externalPlanner, true),
      this.createRole(UserRole.accounting, false),
      this.createRole(UserRole.eos, false),
      this.createRole(UserRole.sales, false),
      this.createRole(UserRole.salesManager, false),
    ];
  }

  private createRole(userRole: UserRole, bookingRole: boolean): Role {
    return {
      label: this.roleName(userRole),
      selected: true,
      userRole: userRole,
      bookingRole: bookingRole,
    };
  }
}

interface Role {
  label: string;
  selected: boolean;
  userRole: UserRole;
  bookingRole: boolean;
}
