import { Component, OnDestroy } from '@angular/core';
import { zip } from 'lodash-es';
import { SubscriptionHandler } from '../../advanced_campaign_booking.module/components/subscription-handler';
import { AdServerService } from '../../woo_services.module/AdServerService';
import { AuthService } from '../../woo_services.module/AuthService';
import { CampaignService } from '../../woo_services.module/CampaignService';
import { DashboardModel, DashboardStore } from '../../woo_services.module/DashboardStore';
import { DialogService } from '../../woo_services.module/DialogService';
import { GoogleTagManagerService } from '../../woo_services.module/GoogleTagManagerService';
import { SystemService, SystemSettings } from '../../woo_services.module/SystemService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import {
  Campaign,
  CampaignStatus,
  Creative,
  DashboardTab,
  SendToAdServerJobResult,
  Targeting,
  wooId,
} from '../../woo_services.module/shared-types';

@Component({
  selector: 'creatives-details',
  templateUrl: './creatives-details.component.html',
})
export class CreativesDetails extends SubscriptionHandler implements OnDestroy {
  readonly adminOrPlanner = this.authService.adminOrPlanner();
  readonly convertEditCreativesCriterium = this.translationService.convertEditCreativesCriterium;

  editCreatives = false;
  numberOfDaysToEditForAgency = 4;
  targetings: Targeting[];
  notAllowedToEditCreativesReason: string;
  canSave: boolean;
  private isGamblingCampaign: boolean;
  get hasBeenSentToAdServer(): boolean {
    return this.campaignService.hasAnyStatus(this.dashboardStore.state.activeCampaign, [
      CampaignStatus.ongoing,
      CampaignStatus.upcoming,
    ]);
  }
  get saveButtonText(): string {
    return this.authService.adminOrPlanner() && this.hasBeenSentToAdServer
      ? 'Uppdatera och skicka till adservern'
      : 'Spara';
  }

  constructor(
    private adServerService: AdServerService,
    private authService: AuthService,
    private campaignService: CampaignService,
    private dashboardStore: DashboardStore,
    private dialogService: DialogService,
    private googleTagManagerService: GoogleTagManagerService,
    private translationService: TranslationService,
    private sytemService: SystemService,
  ) {
    super();
    dashboardStore.state$.subscribe(this.initFromStore);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.cancelEditCreatives();
  }

  initFromStore = (model: DashboardModel): void => {
    // Timeout to avoid values changing after being checked.
    setTimeout(() => {
      if (model.activeCampaign) {
        this.targetings = model.activeCampaign.targetings;
        this.isGamblingCampaign = !!model.activeCampaign.gambling_campaign;
      }
      this.editCreatives = model.editCreatives;
      this.notAllowedToEditCreativesReason = model.notAllowedToEditCreativesReason;
      this.canSave = model.modifiedCreatives
        ? Object.values(model.modifiedCreatives)
            .flatMap((targetingObj) => Object.values(targetingObj).map((creativeObj) => creativeObj.valid))
            .every((value) => value)
        : false;
      if (!this.notAllowedToEditCreativesReason && !this.adminOrPlanner) {
        this.sytemService.loadSystemwideSettings().then((settings: SystemSettings) => {
          this.numberOfDaysToEditForAgency = this.isGamblingCampaign
            ? settings.min_days_to_update_gambling_creatives_in_mail
            : settings.min_days_to_update_creatives_in_mail;
        });
      }
    });
  };

  startEditCreatives(): void {
    this.googleTagManagerService.clickedEditAllCreatives();
    this.dashboardStore.setEditCreatives(true);
  }

  cancelEditCreatives(): void {
    this.dashboardStore.setEditCreatives(false);
  }

  saveEditCreatives(): void {
    if (!this.canSave) {
      return;
    }

    const campaign = this.dashboardStore.state.activeCampaign;

    const hasChanges = campaign.targetings.some((t) =>
      Object.values(this.dashboardStore.state.modifiedCreatives[t.id])
        .map((c) => c.modified)
        .some((modified) => modified),
    );

    if (!hasChanges) {
      this.dialogService.openSuccess('Det här gick lättare än förväntat, inga ändringar att spara :)');
      this.dashboardStore.setEditCreatives(false);
      return;
    }

    if (campaign.status === CampaignStatus.ongoing) {
      this.updateOngoingCreatives(campaign);
    } else {
      this.updateCreatives(campaign);
    }
  }

  getTargetingExpandedFromStore(targetingId: wooId): boolean {
    const expandedTargetings = this.dashboardStore.state.expandedTargetingHeaders?.[DashboardTab.creatives];
    return expandedTargetings?.[targetingId] ?? true;
  }

  onExpandedChange(expanded: boolean, targetingId: wooId): void {
    this.dashboardStore.setExpandedTargetingHeader(DashboardTab.creatives, targetingId, expanded);
  }

  private updateCreatives(campaign: Campaign) {
    this.dialogService.openBlocking('Uppdaterar material');
    // Store changes in all targetings
    const updateRequests = campaign.targetings.map((t) => {
      const creatives = Object.values(this.dashboardStore.state.modifiedCreatives[t.id]).map(
        (creativeObj) => creativeObj.creative,
      );
      return this.campaignService.updateCreatives(campaign.id, t.id, creatives);
    });
    Promise.all(updateRequests)
      .then((targetingCreatives) => {
        zip(campaign.targetings, targetingCreatives).forEach(([targeting, creatives]: [Targeting, Creative[]]) =>
          this.dashboardStore.setCampaignCreatives(targeting.id, creatives),
        );
        this.dashboardStore.setEditCreatives(false);
        if (campaign.status === CampaignStatus.upcoming) {
          this.sendToAdServer(campaign.id);
        } else {
          this.dialogService.openSuccess('Din kampanj är nu uppdaterad.');
        }
      })
      .catch((err) => {
        console.error('Failed updating material:', err.error);
      });
  }

  private updateOngoingCreatives(campaign: Campaign) {
    this.dialogService
      .openConfirm({
        header: 'Uppdatera material på adservern',
        textBlocks: [
          'Ändringarna du har begärt kommer att medföra uppdateringar på adservern.',
          'Vill du genomföra ändringarna?',
        ],
        confirmText: 'Uppdatera',
        cancelText: 'Avbryt',
      })
      .then(
        async () => {
          this.dialogService.openBlocking('Uppdaterar material');

          // Store changes in all targetings
          for (const targeting of campaign.targetings) {
            const creatives = Object.values(this.dashboardStore.state.modifiedCreatives[targeting.id])
              .filter((creativeObj) => creativeObj.modified)
              .map((creativeObj) => creativeObj.creative);
            if (creatives.length < 1) continue;
            await this.campaignService.updateOngoingCreatives(campaign.id, targeting.id, creatives);
          }

          this.dialogService.openSuccess('Din kampanj är nu uppdaterad.');
          this.dashboardStore.setEditCreatives(false);

          this.reloadCampaignFromServer();
        },
        () => null,
      )
      .catch((err) => {
        console.error('Failed updating material: ', err);
        this.dialogService.openError('Kampanjen kunde inte uppdateras, kontrollera att den ser okej ut på adservern.');
      });
  }

  private reloadCampaignFromServer = () => {
    this.campaignService.get(this.dashboardStore.state.activeCampaign.id).then((updatedCampaign) => {
      updatedCampaign.targetings.forEach((updatedTargeting) =>
        this.dashboardStore.setCampaignCreatives(updatedTargeting.id, updatedTargeting.creatives),
      );
    });
  };

  private sendToAdServer(campaignId: wooId) {
    if (this.adminOrPlanner) {
      this.dialogService.openBlocking('Skickar till adservern');
    }
    this.adServerService.sendCampaign(campaignId).then(this.sendToAdServerSuccess, this.sendToAdServerError);
  }

  private sendToAdServerSuccess = () => {
    const msg = this.adminOrPlanner ? 'Kampanjen har skickats till adservern.' : 'Din kampanj är nu uppdaterad.';
    this.dialogService.openSuccess(msg);
  };

  private sendToAdServerError = (errorResponse: SendToAdServerJobResult) => {
    this.dialogService.closeBlocking();
    if (this.adminOrPlanner) {
      if (!!errorResponse?.data_errors) {
        this.dialogService.openPreconditionError(errorResponse.error, errorResponse.data_errors);
        return;
      }

      const msg = this.translationService.convertSendToAdserverError(errorResponse.error);
      this.dialogService.openError(msg);
    } else {
      this.dialogService.openSuccess('Din kampanj är nu uppdaterad.');
    }
  };
}
