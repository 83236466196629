import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, ValidatorFn } from '@angular/forms';
import { AbstractValueAccessor } from '../../../utils/AbstractValueAccessor';
import { valueAccessorProvider } from '../../../utils/provider-builders';

@Component({
  selector: 'string-array-input',
  templateUrl: './string-array-input.component.html',
  styleUrls: ['./string-array-input.component.scss'],
  providers: [valueAccessorProvider(StringArrayInput)],
})
export class StringArrayInput extends AbstractValueAccessor<string[]> {
  @Input() optional = false;
  @Input() label: string;
  @Input() validator: ValidatorFn | ValidatorFn[];

  arrayForm = this.fb.group({
    array: this.fb.array([]),
  });

  get arrayLength(): number {
    return this.array.controls.length;
  }

  get array(): FormArray {
    return this.arrayForm.get('array') as FormArray;
  }

  constructor(private fb: FormBuilder) {
    super();
    this.array.valueChanges.subscribe(() => this.change());
  }

  writeValue(model: string[]): void {
    super.writeValue(model);
    this.array.clear();
    (model.length ? model : ['']).forEach((value) => this.array.push(this.newControl(value)));
  }

  change(): void {
    this.model = this.array.value.map((value) => value);
  }

  add(): void {
    this.array.push(this.newControl());
  }

  remove(index: number): void {
    this.array.removeAt(index);
  }

  setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled) {
      this.arrayForm.disable();
    } else {
      this.arrayForm.enable();
    }
  }

  private newControl = (value = '') => {
    return this.fb.control({ value, disabled: this.isDisabled }, this.validator ? this.validator : () => null);
  };
}
