import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../utils/types';
import { AuthService } from '../../woo_services.module/AuthService';
import { CampaignStatus } from '../../woo_services.module/shared-types';

const template = /* html */ `
  <span *ngIf="adminOrPlanner() && needsAttention" class="tab tab--small warning">Behöver din uppmärksamhet</span>
  <span *ngFor="let label of labels" class="tab tab--small success">{{label}}</span>
`;

@Component({
  selector: 'campaign-status-label',
  template: template,
})
export class CampaignStatusLabel implements OnChanges {
  readonly adminOrPlanner = this.authService.adminOrPlanner;

  @Input() campaignStatus: CampaignStatus;
  @Input() needsAttention = false;
  labels: string[] = [];

  constructor(private authService: AuthService) {}

  ngOnChanges(changes: SimpleChanges<CampaignStatusLabel>): void {
    if (changes.campaignStatus && this.campaignStatus) {
      this.labels = [
        this.campaignStatus === CampaignStatus.booked ? 'Bokad kampanj' : null,
        this.campaignStatus === CampaignStatus.unbooked ? 'Utkast' : null,
        this.campaignStatus === CampaignStatus.upcoming ? 'Kampanjen väntar på start' : null,
        this.campaignStatus === CampaignStatus.ongoing ? 'Rullande kampanj' : null,
        this.campaignStatus === CampaignStatus.ended ? 'Avslutad kampanj' : null,
        this.campaignStatus === CampaignStatus.cancelled ? 'Avbokad kampanj' : null,
        this.campaignStatus === CampaignStatus.stopped ? 'Stoppad kampanj' : null,
        this.campaignStatus === CampaignStatus.closed ? 'Stängd kampanj' : null,
        this.campaignStatus === CampaignStatus.rejected ? 'Nekad kampanj' : null,
        this.campaignStatus === CampaignStatus.reviewing ? 'Granskas' : null,
      ].filter(Boolean);
    }
  }
}
