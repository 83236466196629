<div class="row">
  <div class="col-xs-12 col-sm-6">
    <div>
      <targeting-checkbox
        (onClick)="toggleSingleGender()"
        [checked]="singleGender"
        [texts]="['Kön']">
      </targeting-checkbox>
    </div>
    <div class="padding-left--2x margin-top--2x">
      <div *ngFor="let gender of genders">
        <input
          class="radio"
          id="{{idScope.getOrCreateId(gender.id)}}"
          type="radio"
          name="{{idScope.getOrCreateId('gender')}}"
          [disabled]="!singleGender"
          [value]="gender"
          [(ngModel)]="selectedGender"
          (ngModelChange)="change()">
        <label
          class="radio"
          for="{{idScope.getOrCreateId(gender.id)}}"
          [attr.e2e-id]="gender.name">{{gender.name}}</label>
      </div>
    </div>
  </div>

  <div class="divider margin-top-bottom--1x col-xs-12 visible-xs"></div>

  <div class="col-xs-12 col-sm-6">
    <div>
      <targeting-checkbox
        (onClick)="toggleAgeRequired()"
        [checked]="ageRequired"
        [texts]="['Ålder']">
      </targeting-checkbox>
    </div>
    <div class="margin-top--2x margin-left--1x">
      <ngx-slider
        [value]="ageSpan.min"
        [highValue]="ageSpan.max"
        [options]="sliderOptions"
        (userChange)="onSliderChange($event)"
        >
      </ngx-slider>
    </div>
  </div>
</div>
