import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { WooComponentsModule } from '../woo_components.module/module';
import { Buylist } from './components/buylist/buylist.component';
import { Inventory } from './components/inventory/inventory.component';
import { InventoryReports } from './components/inventory_reports/inventory-reports.compontent';
import { InventoryView } from './components/inventory_view/inventory-view.component';
import { InventoryWeeksPicker } from './components/inventory_weeks_picker/inventory-weeks-picker.compontent';
import { ProgramFormatsTable } from './components/program_formats_table/program-formats-table.component';

@NgModule({
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, WooComponentsModule],
  providers: [],
  declarations: [Buylist, InventoryReports, InventoryView, Inventory, InventoryWeeksPicker, ProgramFormatsTable],
  exports: [Buylist, Inventory],
})
export class WooInventoryModule {}
