<div class="inner-block relative">
  <div class="row">
    <div class="col-xs-12">
      <dot-loader [visibility]="loading" [large]="true"></dot-loader>
      <div *ngIf="!loading">
        <h2>{{priceListHeading}}</h2>

        <expandable-header
          *ngIf="showOverWrittenPriceComponent"
          class="margin-top-bottom--2x"
          backgroundColor="white" heading="h3"
          padding="true" [text]="overWrittenHeadingText"
        >
          <date-span [start]="priceList.valid_from" [end]="priceList.valid_to"></date-span>
          <edit-overwritten-price
            [publishers]="publishers"
            [priceList]="priceList"
            (onOverwrittenPricesUpdated)="reloadPriceList($event)"
            (onClearOverwrittenPrices)="clearOverwrittenPrices()"
          ></edit-overwritten-price>
        </expandable-header>
        
        
        <div class="account__card">
          <date-span [start]="priceList.valid_from" [end]="priceList.valid_to"></date-span>
          <!-- <calculate-price></calculate-price> Hidden until prices can be chosen through drowdowns instead -->
          <form (ngSubmit)="newPriceList ? onSubmit.emit(priceList) : updatePriceList()" autocomplete="off">
            <div class="margin-top--3x">
              <button type="submit" class="button">{{saveButtonLabel}}</button>
              <button type="button" class="link" (click)="goToProducts()">Avbryt</button>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h3 class="margin-top--3x margin-bottom--3x text--dark-gray">Placeringspris</h3>
                <table class="table table--woo table--hover table--light-red">
                  <thead>
                    <tr>
                      <th>Publicist</th>
                      <th>Produkt</th>
                      <th>{{priceHeadingLabel}}</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <ng-container *ngFor="let basePrice of basePriceList; let index = index;">
                      <tr>
                        <td class="vertical-align--middle">
                          {{getPublisherName(basePrice.publisherId)}}
                        </td>
                        <td class="vertical-align--middle">
                          {{translateProductFormat(basePrice.product)}}
                        </td>
                        <td>
                          <div
                          class="input__container">
                            <input
                            type="text"
                            name={{basePrice.publisherId+basePrice.product}}
                            [(ngModel)]="priceList.publisher_base_prices[basePrice.publisherId][basePrice.product]"
                            required>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
          
              <div class="col-md-6">
                <h3 class="margin-top--3x margin-bottom--3x text--dark-gray">Styrningspris</h3>
          
                <table class="table table--woo table--hover table--light-red">
                  <thead>
                    <tr>
                      <th>Publicist</th>
                      <th>Styrning</th>
                      <th>{{priceHeadingLabel}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let targetingPrice of targetingPriceList; let index = index;">
                      <tr>
                        <td class=" vertical-align--middle">
                          {{getPublisherName(targetingPrice.publisherId)}}
                        </td>
                        <td class=" vertical-align--middle">
                          {{translateTargetingProduct(targetingPrice.product)}}
                        </td>
                        <td class="">
                          <div
                          class="input__container">
                            <input
                            type="text"
                            name={{targetingPrice.publisherId+targetingPrice.product}}
                            [(ngModel)]="priceList.publisher_targeting_prices[targetingPrice.publisherId][targetingPrice.product]"
                            required>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          
            <div class="row">
              <div class="col-md-6">
                <h3 class="margin-top--3x margin-bottom--3x text--dark-gray">Geografipris</h3>
          
                <table class="table table--woo table--hover table--light-red">
                  <thead>
                    <tr>
                      <th>Publicist</th>
                      <th>Geografi</th>
                      <th>{{priceHeadingLabel}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let localityPrice of localityPriceList; let index = index;">
                      <tr>
                        <td class="vertical-align--middle">
                          {{getPublisherName(localityPrice.publisherId)}}
                        </td>
                        <td class="vertical-align--middle">
                          {{translateLocality(localityPrice.product)}}
                        </td>
                        <td>
                          <div
                          class="input__container">
                            <input
                            type="text"
                            name={{localityPrice.publisherId+localityPrice.product}}
                            [(ngModel)]="priceList.publisher_locality_prices[localityPrice.publisherId][localityPrice.product]"
                            required>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <h3 class="margin-top--3x margin-bottom--3x text--dark-gray">Tilläggsstyrning</h3>
          
                <table class="table table--woo table--hover table--light-red">
                  <thead>
                    <tr>
                      <th>Publicist</th>
                      <th>Styrning</th>
                      <th>{{priceHeadingLabel}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let addonTargetingPrice of addonTargetingPriceList; let index = index;">
                      <tr>
                        <td class="vertical-align--middle">
                          {{getPublisherName(addonTargetingPrice.publisherId)}}
                        </td>
                        <td class="vertical-align--middle">
                          {{translateTargetingProduct(addonTargetingPrice.product)}}
                        </td>
                        <td >
                          <div
                          class="input__container">
                            <input
                            type="text"
                            name={{addonTargetingPrice.publisherId+addonTargetingPrice.product}}
                            [(ngModel)]="priceList.publisher_addon_targeting_prices[addonTargetingPrice.publisherId][addonTargetingPrice.product]"
                            required>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <h3 class="margin-top--3x margin-bottom--3x text--dark-gray">Åldersgrupp priser</h3>
        
              <table class="table table--woo table--hover table--light-red">
                <thead>
                  <tr>
                    <th>Publicist</th>
                    <th>Åldersgrupp</th>
                    <th>{{priceHeadingLabel}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let ageModifierPrice of ageModifierPriceList; let index = index;">
                    <tr>
                      <td class="vertical-align--middle">
                        {{getPublisherName(ageModifierPrice.publisherId)}}
                      </td>
                      <td class="vertical-align--middle">
                        {{translateAgeModifierKey(ageModifierPrice.product)}}
                      </td>
                      <td>
                        <div
                        class="input__container">
                          <input
                          type="text"
                          name={{ageModifierPrice.publisherId+ageModifierPrice.product}}
                          [(ngModel)]="priceList.publisher_age_modifier_prices[ageModifierPrice.publisherId][ageModifierPrice.product]"
                          required>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          
            <div class="row">
              <div class="col-xs-12">
                <h3 class="margin-top--3x margin-bottom--3x text--dark-gray">Veckoindex</h3>
              </div>
            </div>
          
            <div class="row">
              <div class="col-md-6 border--info" *ngFor="let weeks of weekIndexWeeks">
                <table class="table table--woo table--hover table--light-red">
                  <thead>
                    <tr>
                      <th>Vecka</th>
                      <th>Video</th>
                      <th>Pausreklam</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let week of weeks">
                      <td class="vertical-align--middle"><strong>{{week}}</strong></td>
                      <td>
                        <div
                        class="input__container">
                          <input
                          type="text"
                          name="week_index_{{week}}"
                          [(ngModel)]="priceList.week_price_indexes[week - 1]"
                          required>
                          </div>
                      </td>
                      <td>
                        <div
                        class="input__container">
                          <input
                          type="text"
                          name="pause_ad_week_index_{{week}}"
                          [(ngModel)]="priceList.pause_ad_week_price_indexes[week - 1]"
                          required>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          
            <div class="row margin-top--3x">
              <div class="col-md-6 border--info">
                <h3 class="margin-top--3x margin-bottom--3x text--dark-gray">Filmindex</h3>
          
                <table class="table table--woo table--hover table--light-red">
                  <thead>
                    <tr>
                      <th>Filmlängd</th>
                      <th>Index</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let length of creativeLengths">
                      <td class="vertical-align--middle"><strong>{{length}}</strong></td>
                      <td>
                        <div
                        class="input__container">
                          <input
                          type="text"
                          name="creative_length_price_indexes{{length}}"
                          [(ngModel)]="priceList.creative_length_price_indexes[length]"
                          required>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <h3 class="margin-top--3x margin-bottom--3x text--dark-gray">Frekvenstaksindex</h3>
            <table class="table table--woo table--hover table--light-red">
              <thead>
                <tr>
                  <th>Frekvenstak</th>
                  <th>Index</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let price of getActivePrices(priceList.frequency_limit_prices); trackBy: trackById">
                  <td class="vertical-align--middle">{{price.frequency_limit.name}}</td>
                  <td>
                    <div
                    class="input__container">
                      <input type="text" name="ratio_{{price.id}}" [(ngModel)]="price.ratio" required>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          
            <div class="margin-top--3x">
              <button type="submit" class="button">{{saveButtonLabel}}</button>
              <button type="button" class="link" (click)="goToProducts()">Avbryt</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
