import { Injectable } from '@angular/core';
import { TranslationService } from '../../woo_services.module/TranslationService';

@Injectable()
export class StatusCollectionService {
  private statusList: string[] = [];

  constructor(private translationService: TranslationService) {}

  addStatus(status: string): void {
    this.statusList.push(status);
  }

  clear(): void {
    this.statusList = [];
  }

  hasError(): boolean {
    return this.statusList.some((status) => status !== 'ok');
  }

  getTranslatedErrors(): string[] {
    return this.statusList.map((status) => this.translationService.convertErrorToEasyMessage(status));
  }
}
