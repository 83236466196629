import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeSv from '@angular/common/locales/sv';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { AdvancedCampaignBookingModule } from '../advanced_campaign_booking.module/module';
import { CashDashboardModule } from '../cash_dashboard.module/module';
import { ExternalCampaignsModule } from '../external_campaigns.module/module';
import { ProductManagementModule } from '../product_management.module/module';
import { SimpleCampaignBookingModule } from '../simple_campaign_booking.module/module';
import { WooComponentsModule } from '../woo_components.module/module';
import { WooDashboardModule } from '../woo_dashboard.module/module';
import { WooEos } from '../woo_eos.module/module';
import { WooEstimates } from '../woo_estimates.module/module';
import { WooInventoryModule } from '../woo_inventory.module/module';
import { WooMails } from '../woo_mails.module/module';
import { WooServiceModule } from '../woo_services.module/module';
import { WooStatisticsDashboard } from '../woo_statistics_dashboard.module/module';
import { WooStyleSheetModule } from '../woo_style_sheet.module/module';
import { WooSystemSettingsModule } from '../woo_system_settings.module/module';
import { WooUserAccountsModule } from '../woo_user_accounts.module/module';
import { EditUser } from './components/accounts/edit-user.component';
import { MyAccount } from './components/accounts/my-account.component';
import { AgencyComponent } from './components/agency/agency.component';
import { AgencyOverview } from './components/agency_overview/agency-overview.component';
import { AppRoot } from './components/app_root/app-root.component';
import { BudgetPartsTable } from './components/common/budget-parts-table.component';
import { CreativeSpan } from './components/common/creative-span.component';
import { CreativeAssetList } from './components/creative_assets/creative-asset-list.component';
import { ManageCreativeAssets } from './components/creative_assets/manage-creative-assets-component';
import { SelectCustomerToViewAssetsFor } from './components/creative_assets/select-customer-to-view-assets-for.component';
import { Customer } from './components/customer/customer.component';
import { AddCreativeToOngoingCampaign } from './components/edit_creatives/add-creative-to-ongoing-campaign.component';
import { EditOngoingCampaignPage } from './components/edit_started_campaign/edit-ongoing-campaign-page.component';
import { ForgotPassword } from './components/forgot_password/forgot-password.component';
import { GlobalMessages } from './components/global_messages/global-messages.component';
import { Help } from './components/help/help.component';
import { InvoiceAttestation } from './components/invoice_attestation/invoice-attestation.component';
import { InvoiceTable } from './components/invoice_attestation/invoice_table/invoice-table.component';
import { InvoiceManagement } from './components/invoice_management/invoice-management.component';
import { InvoiceOverview } from './components/invoice_overview/invoice-overview.component';
import { InvoiceTools } from './components/invoice_tools/invoice-tools.component';
import { Landing } from './components/landing/landing.component';
import { ListItem } from './components/landing/list-item.component';
import { LoginForm } from './components/login/login-form.component';
import { RevenueReportsPage } from './components/revenue_reports_page/revenue-reports-page.component';
import { ReviewCampaign } from './components/review_campaign/review-campaign.component';
import { SelfServiceCompensation } from './components/self_service_compensation/self-service-compensation.component';
import { SetFirstPassword } from './components/set_first_password/set-first-password.component';
import { WooStatus } from './components/status/woo-status.component';
import { TermsAndConditionsPage } from './components/terms_and_conditions/terms-and-conditions-page.component';
import { TTVCampaignBooking } from './components/ttv_campaign_booking/ttv-campaign-booking.component';
import { UpdateOngoingCampaignBudget } from './components/update_ongoing_campaign/update-ongoing-campaign-budget.component';
import { UpdateOngoingCampaignName } from './components/update_ongoing_campaign/update-ongoing-campaign-name.component';
import { UpdatePassword } from './components/update_password/update-password.component';
import { UpdatePasswordForm } from './components/update_password_form/update-password-form.component';
import { SentryErrorHandler } from './error_handlers/SentryErrorHandler';
import { AuthInterceptor } from './interceptors/AuthInterceptor';
import { StandardResponseInterceptor } from './interceptors/StandardResponseInterceptor';
import { routes } from './routing/routes';

registerLocaleData(localeSv);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgChartsModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    AdvancedCampaignBookingModule,
    CashDashboardModule,
    ExternalCampaignsModule,
    ProductManagementModule,
    SimpleCampaignBookingModule,
    WooComponentsModule,
    WooDashboardModule,
    WooEos,
    WooEstimates,
    WooMails,
    WooServiceModule,
    WooStatisticsDashboard,
    WooStyleSheetModule,
    WooSystemSettingsModule,
    WooInventoryModule,
    WooUserAccountsModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'sv' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StandardResponseInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    ThemeService,
  ],
  declarations: [
    AgencyComponent,
    AgencyOverview,
    AppRoot,
    BudgetPartsTable,
    CreativeAssetList,
    CreativeSpan,
    Customer,
    EditOngoingCampaignPage,
    EditUser,
    ForgotPassword,
    GlobalMessages,
    Help,
    InvoiceAttestation,
    InvoiceTable,
    InvoiceTools,
    InvoiceManagement,
    InvoiceOverview,
    Landing,
    ListItem,
    LoginForm,
    ManageCreativeAssets,
    MyAccount,
    RevenueReportsPage,
    ReviewCampaign,
    SelectCustomerToViewAssetsFor,
    SelfServiceCompensation,
    SetFirstPassword,
    TermsAndConditionsPage,
    TTVCampaignBooking,
    UpdatePassword,
    UpdatePasswordForm,
    UpdateOngoingCampaignBudget,
    UpdateOngoingCampaignName,
    AddCreativeToOngoingCampaign,
    WooStatus,
  ],
  bootstrap: [AppRoot],
})
export default class TV4AppModule {}
