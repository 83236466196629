<div class="height--full-desktop section primary">

  <div class="content-wrapper section primary padding-top--8x">
    <div class="content">
      <h1>En bekräftelse har skickats till din mailadress!</h1>
      <div class="sub-heading">
        <p>För att din kampanj ska bli så bra som möjligt så granskas den just nu av oss. Klicka dig in på ”Mina kampanjer” för att följa utvecklingen.</p>
        <div class="button-container">
          <button class="button primary-2" type="button" [routerLink]="['/customer', customerId, 'campaign']">Boka ny kampanj</button>
          <button class="button secondary" type="button" [routerLink]="['/customer', customerId, 'dashboard']">Mina kampanjer</button>
        </div>
      </div>
    </div>
  </div>

  <div class="content-wrapper section primary margin-top--5x">
    <div class="steps-wrapper content">
      <div class="step-container">
        <img class="step-image" src="../../images/campaign_created.svg" alt="">
        <p class="text--large">Din kampanj är bokad.</p>
      </div>
      <div class="arrow-container hidden-md hidden-sm hidden-xs">
        <img class="arrow arrow--flipped" src="../../images/arrow.svg" alt="">
      </div>
      <div class="step-container">
        <img class="step-image" src="../../images/asset.svg" alt="">
        <p class="text--large">Just nu väntar din reklamfilm på att godkännas av oss.</p>
      </div>
      <div class="arrow-container hidden-md hidden-sm hidden-xs">
        <img class="arrow" src="../../images/arrow.svg" alt="">
      </div>
      <div class="step-container">
        <img class="step-image" src="../../images/device.svg" alt="">
        <p class="text--large">Inom 2 dagar blir du meddelad via din mailadress om att din film är godkänd.</p>
      </div>
    </div>
  </div>

</div>
