<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <div class="input__container">
        <label for="name">Namn</label>
          <input type="text" id="name" formControlName="name">
      </div>
    </div>
  </div>

  <div class="padding-top--2x">
    <button class="button primary" [disabled]="!form.valid">Spara</button>
    <button class="link" type="button" (click)="abort()">Avbryt</button>
  </div>
</form>
