import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { generateId, removeGeneratedId } from '../../utils/string';
import { CampaignService } from '../../woo_services.module/CampaignService';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { TargetingService } from '../../woo_services.module/TargetingService';
import {
  Campaign,
  CampaignEstimation,
  Creative,
  CreativeShareType,
  Targeting,
  ViewCurrency,
} from '../../woo_services.module/shared-types';

@Injectable()
export class CashCampaignService {
  campaign;
  current;

  constructor(
    private campaignService: CampaignService,
    private env: EnvironmentService,
    private http: HttpClient,
    private targetingService: TargetingService,
  ) {
    this.resetCampaign();
    this.resetCurrent();
  }

  estimate = async (campaign: flatCampaign): Promise<CampaignEstimation> => {
    const estimation = await lastValueFrom(
      this.http.post<CampaignEstimation>(`${this.env.apiUrl}/campaigns/estimated_budget_and_impressions`, {
        campaign: this.prepareCampaign(campaign),
      }),
    );
    this.current.estimation.completedViews = estimation.net_impressions;
    return estimation;
  };

  resetCampaign(): void {
    this.campaign = {
      id: generateId(),
      name: '',
      status: null,
      cpm_price: 0,
      customer_id: '',
      agency_id: null,
      frequency_limit: null,
      reference_number: '',
      customer_discount: 0,
      creatives: [],
      creative_share_type: 'percent',
      view_currency: ViewCurrency.completedViews,
      additional_info: '',
      impressions_to_deliver: 0,
      additional_goal_impressions: 0,
      adjusted_goal_impressions: 0,
      bookingFormatSelection: this.targetingService.bookingFormatSelections.rbs,
      ad_organisation: null,
      creativeTypeIsVideo: true,
      targetings: [this.targetingService.createTargeting()],
      cash_campaign: false,
      ttv_campaign: false,
    };
  }

  resetCurrent(): void {
    this.current = {
      customer: {},
      agency: {},
      year: new Date().getFullYear(),
      creativeType: {
        isVideo: true,
        label: 'film',
      },
      estimation: {
        budget: 0,
        impressions: 0,
        completedViews: 0,
      },
      startDate: null,
      endDate: null,
      budgetFields: {
        budget: null,
        budgeted_impressions: null,
        additional_budget: null,
        additional_budget_message: '',
        additional_budgeted_impressions: null,
        calculate_from_budget: true,
        send_invoices: true,
        sales_order_number: '',
        invoice_disable_message: '',
        includes_linear: false,
      },
    };
  }

  prepareCampaign = (campaign: flatCampaign): flatCampaign => {
    const clone = { ...campaign };
    removeGeneratedId(clone);
    this.moveCreativesToTargetings(clone);
    this.setTargetingBudgetFields(clone.targetings);
    this.campaignService.cleanCreatives(clone.targetings.flatMap((t) => t.creatives));
    this.campaignService.cleanTargetings(clone.targetings);
    return clone;
  };

  moveCreativesToTargetings = (campaign: flatCampaign): void => {
    campaign.targetings.forEach((t) => {
      t.creative_share_type = campaign.creative_share_type;
      t.creatives = campaign.creatives.map((c) => ({ ...c }));
    });
    delete campaign.creatives;
    delete campaign.creative_share_type;
  };

  // Updates targetings from current.budgetFields
  setTargetingBudgetFields = (targetings: Targeting[]): void => {
    targetings[0].budget = this.current.budgetFields.budget;
    targetings[0].budgeted_impressions = this.current.budgetFields.budgeted_impressions;
    targetings[0].additional_budget = this.current.budgetFields.additional_budget;
    targetings[0].additional_budgeted_impressions = this.current.budgetFields.additional_budgeted_impressions;
  };
}

type flatCampaign = Campaign & { creatives: Creative[]; creative_share_type: CreativeShareType };
