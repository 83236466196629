import { Component } from '@angular/core';

@Component({
  selector: 'calender-top-row',
  template: `
    <div class="flex__container width--100">
      <div
        class="calender-label"
        [ngClass]="{ turn: first, 'right-end': last }"
        *ngFor="let name of dayNames; first as first; last as last"
      >
        {{ name }}
      </div>
    </div>
  `,
  styleUrls: ['./date_selectors.scss'],
})
export class CalenderTopRow {
  dayNames = ['v.', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'];
}
