import { Component } from '@angular/core';
import { UserRole } from '../../../utils/user-roles';
import { WooDialogHelper } from '../../../woo_components.module/dialogs/woo-dialog-helper';
import { AuthService } from '../../../woo_services.module/AuthService';

const template = /* html */ `
<woo-dialog>
  <h2>Du kan söka på:</h2>
  <div>
    <h4>För alla kampanjer</h4>
    <p>Namn, referensnummer, fakturareferens, region, publicist, programformat, målgrupp, apparat, filmkod, filmnamn</p>
    <p>Kund- eller byrånamn, organisationsnummer för kund eller byrå</p>
  </div>
  <br>
  <div *ngIf="showTTVFields">
    <h4>För Total-TV-kampanjer</h4>
    <p>Referensnummer, bookingsID, målgrupp (ex K20-49)</p>
  </div>
  <br>
  <div>
    <h4>Magiska söktermer</h4>
    <p>Pausreklam, spelbolag, startvecka (ex v24), adresserbartv, onlinevideo, kombination, politik, tilläggsstyrning</p>
    <p *ngIf="showTTVFields">ttv</p>
  </div>
</woo-dialog>
`;

@Component({
  selector: 'search-info-dialog',
  template: template,
})
export class SearchInfoDialog extends WooDialogHelper {
  readonly showTTVFields = this.authService.hasAnyRole([UserRole.planner, UserRole.admin, UserRole.accounting]);

  constructor(private authService: AuthService) {
    super();
  }
}
