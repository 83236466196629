import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from '../../../../utils/dom';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { ProductFormat, Publisher, SlotType, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-gambling-estimates',
  templateUrl: './manage-gambling-estimates.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageGamblingEstimates implements OnInit {
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  @Input('headerPos') parentHeader: HTMLElement;

  show = {
    estimatesHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };

  readonly tableHeaders = [
    'Publicist',
    'Placering',
    'Pause Ad',
    'Preroll',
    'Midroll',
    'Postroll',
    'Pause Ad',
    'Preroll',
    'Midroll',
    'Postroll',
  ];

  readonly staticFields = 2;

  publishers = [] as Publisher[];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  gamblingRows: GamblingRow[];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  translateProductFormat: (args: any) => string;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    public dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.publishers = (await this.publisherService.getPublishers(ExtraPublisherField.enabled)).filter((p) => p.enabled);
    try {
      await this.getAndBuildGamblingRows();
    } finally {
      this.show.loading = false;
      scrollTo(this.parentHeader);
      this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
    }
  }

  async getAndBuildGamblingRows(): Promise<void> {
    const productFormats = await this.productFormatService.getProductFormats(
      ExtraProductFormatField.enabled,
      ExtraProductFormatField.gamblingPauseAdShare,
      ExtraProductFormatField.gamblingPauseAdWithdrawalLimit,
      ExtraProductFormatField.gamblingWithdrawalLimitShares,
      ExtraProductFormatField.gamblingWithdrawalLimits,
      ExtraProductFormatField.slotTypes,
    );
    this.productFormats = productFormats.filter((f) => f.enabled);
    this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(this.productFormats);
    this.buildGamblingRows();
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  async cancelEditing(): Promise<void> {
    await this.getAndBuildGamblingRows();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    await this.dialogService.openConfirm({
      header: 'Bekräfta ändringar',
      textBlocks: textBlocks,
      confirmText: 'Spara',
      cancelText: 'Avbryt',
    });
    try {
      this.show.updatingEstimates = true;
      this.productFormats = await this.productFormatService.updateProductFormats(this.productFormats);
      this.buildGamblingRows();
      this.show.estimatesHaveChanged = true;
    } catch {
    } finally {
      this.setEditing(false);
      this.show.updatingEstimates = false;
      this.dialogService.closeBlocking();
    }
  }

  rowVisible(obj: GamblingRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || productFormatFiltered);
  }

  getGamblingProductFormat(productFormatId: wooId): ProductFormat {
    return this.productFormats.find((format) => format.id === productFormatId);
  }

  private buildGamblingRows(): void {
    let gamblingRows: GamblingRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((product_format) => {
        gamblingRows = [
          ...gamblingRows,
          {
            publisher: this.utilityService.getPublisherName(publisher.id, this.publishers),
            publisherId: publisher.id,
            productFormat: product_format.name,
            productFormatId: product_format.id,
            pauseAdShare: product_format.gambling_pause_ad_share,
            sharesPreroll: product_format.gambling_withdrawal_limit_shares.preroll,
            sharesMidroll: product_format.gambling_withdrawal_limit_shares.midroll,
            sharesPostroll: product_format.gambling_withdrawal_limit_shares.postroll,
            pausAdWithdrawalLimit: product_format.gambling_pause_ad_withdrawal_limit,
            withdrawalLimitsPreroll: product_format.gambling_withdrawal_limits.preroll,
            withdrawalLimitsMidroll: product_format.gambling_withdrawal_limits.midroll,
            withdrawalLimitsPostroll: product_format.gambling_withdrawal_limits.postroll,
          },
        ];
      });
    });
    this.gamblingRows = gamblingRows;
  }
}

interface GamblingRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  pauseAdShare?: number;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  pausAdWithdrawalLimit?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
