import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from './AuthService';
import { SessionService } from './SessionService';
import { TermsService } from './TermsService';
@Injectable({ providedIn: 'root' })
export class StandardResponseService {
  tooManyRequests$: Subject<any> = new Subject();
  internalServerError$: Subject<any> = new Subject();
  serviceUnavailableError$: Subject<any> = new Subject();
  returnedUserError$: Subject<any> = new Subject();

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private termsService: TermsService,
  ) {}

  dispatchFromStatusCode(statusCode: number, data: any): void {
    if (statusCode === 401) {
      this.authService.notAuthenticated$.next(data);
    }

    if (statusCode === 429) {
      this.tooManyRequests$.next(data);
    }

    if (statusCode === 404) {
      this.authService.notFound$.next(data);
    }

    if (statusCode === 422) {
      this.returnedUserError$.next(data);
    }

    // 442 Custom WOO status code for when user needs to accept terms and conditions
    if (statusCode === 442) {
      this.sessionService.updateUser('accepted_terms_and_conditions', false);
      this.termsService.needsToAcceptTerms$.next(data);
    }

    if (statusCode >= 500 && statusCode !== 503 && statusCode !== 504) {
      // Application error (500) and other unhandled 5xx codes
      this.internalServerError$.next(data);
    }

    if (statusCode === 503) {
      // Service unavailable (Heroku timeout)
      this.serviceUnavailableError$.next(data);
    }
  }
}

export enum ServerEvents {
  internalServerError = 'server-internal-error',
  serviceUnavailableError = 'service-unavailable-error',
  returnedUserError = 'user-error',
  tooManyRequests = 'too-many-requests',
}
