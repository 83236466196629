import { Component, Input } from '@angular/core';
import { CampaignStatus } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'campaign-status-badge',
  templateUrl: './campaign-status-badge.component.html',
})
export class CampaignStatusBadge {
  readonly CampaignStatus = CampaignStatus;
  @Input() campaignStatus: CampaignStatus;
  @Input() small: boolean;
}
