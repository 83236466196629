<ng-container *ngIf="rows.length && dataFields.length; then showRows; else noRowsFound"></ng-container>

<ng-template #showRows>
  <text-button
    *ngIf="!isEosInventoryRow(rows[0])"
    class="display--block margin-left--1x margin-bottom--1x"
    icon="download"
    (click)="downloadExcel()"
    [disabled]="disabled"
  >
    Exportera rader till Excel
  </text-button>
  <ng-container *ngIf="editFields; then edit; else default"></ng-container>
  
  <ng-template #default>
    <div class="padding-left-right--1x grid-columns--6 button-colum-right text--small align-items--center text--thin margin-bottom--2x">
      <span class="margin-left-right--2x" *ngFor="let column of promotedProperties">
        {{ column.name }}
      </span>
      <button
        class="margin-left-right--2x status-badge badge--focus primary margin--none"
        type="button"
        title="Ändra celler"
        (click)="editFields = !editFields"
      >
        <span class="icon-edit"></span>
      </button>
    </div>
  </ng-template>

  <ng-template #edit>
    <div class="grid-columns--6 button-colum-right padding-left-right--1x align-items--baseline">
      <span
        class="margin-left-right--2x woo-dropdown-small"
        *ngFor="let data of promotedProperties; index as i"
      >
        <woo-dropdown
          [items]="dataFields"
          [(ngModel)]="promotedProperties[i]"
        ></woo-dropdown>
      </span>
      <button
        class="margin-left-right--2x status-badge badge--focus primary margin-top-bottom--none"
        type="button"
        (click)="editFields = !editFields"
      >
        <span class="icon-close-x"></span>
      </button>
    </div>
  </ng-template>

  <div *ngFor="let row of rows">
    <campaign-row-card
      [row]="row"
      [promotedProperties]="promotedProperties"
      [dataFields]="dataFields"
    ></campaign-row-card>
  </div>
</ng-template>

<ng-template #noRowsFound>
  <h3>Inga rader hittades</h3>
</ng-template>
