import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { isEqual } from 'lodash-es';
import { generateId } from '../../../utils/string';
import { SimpleChanges } from '../../../utils/types';
import { validTargetGroupSelect } from '../../../utils/validators';
import {
  TargetGroupChoices,
  TargetGroupSelected,
} from '../../../woo_components.module/inputs/target-group-select/target-group-select.component';
import { AgeTargetGroup, GenderTargetGroup, TargetGroup } from '../../../woo_services.module/shared-types';

const template = `
  <target-group-select
    [ages]="ages"
    [genders]="genders"
    [(ngModel)]="selectedTargetGroups"
    (ngModelChange)="notifyChange()"
    [ngModelOptions]="{standalone: true}"
  ></target-group-select>
`;

/**
 * This component works as a decorator for target-group-select in
 *  order to add validation to each Event that is emitted,
 *  and also trigger events each time input variables are changed.
 */
@Component({
  selector: 'validated-target-group-select',
  template: template,
})
export class ValidatedTargetGroupSelect implements OnChanges {
  @Input() ages: AgeTargetGroup[] = [];
  @Input() genders: GenderTargetGroup[] = [];
  @Input() model: TargetGroup = {
    genders: [],
    ages: [],
  };
  @Input() choices: TargetGroupChoices;

  @Output() choicesChange = new EventEmitter<TargetGroupChoices>();
  @Output() modelAndValidityChange = new EventEmitter<TargetGroupSelectedEvent>();

  selectedTargetGroups: TargetGroupSelected = {
    genders: [],
    ages: [],
    choices: { gender: false, age: false },
  };
  compId: string;
  private lastEvent = null;
  private lastChoices = null;

  constructor(private cd: ChangeDetectorRef) {
    this.compId = generateId();
  }

  ngOnChanges(changes: SimpleChanges<ValidatedTargetGroupSelect>): void {
    if (changes.model && this.model) {
      this.selectedTargetGroups = {
        genders: this.model.genders,
        ages: this.model.ages,
        choices: this.choices,
      };
      this.notifyChange();
    }

    if (changes.choices && this.choices) {
      this.selectedTargetGroups = {
        genders: this.model.genders,
        ages: this.model.ages,
        choices: this.choices,
      };
      this.notifyChange();
    }
  }

  notifyChange(): void {
    this.model = {
      ages: this.selectedTargetGroups.ages,
      genders: this.selectedTargetGroups.genders,
    };
    this.choices = { ...this.selectedTargetGroups.choices };
    const event = {
      ...this.model,
      error: !this.validState(this.model, this.choices),
    };

    if (!isEqual(event, this.lastEvent)) {
      this.lastEvent = event;
      this.modelAndValidityChange.emit(event);
    }

    if (!isEqual(this.choices, this.lastChoices)) {
      this.lastChoices = this.choices;
      this.choicesChange.emit(this.choices);
    }
    this.cd.detectChanges();
  }

  validState(targetGroup: TargetGroup, choices: TargetGroupChoices): boolean {
    return validTargetGroupSelect(targetGroup, choices);
  }
}

export interface TargetGroupSelectedEvent extends TargetGroup {
  error: boolean;
}
