import { Component, Input, OnInit } from '@angular/core';
import { verticalExpansion } from '../../../utils/animations';
import { trackById } from '../../../utils/object';
import { generateId } from '../../../utils/string';
import { PercentModelFormat } from '../../../woo_components.module/directives/woo-percent-formatter.directive';
import { WooCurrencyValue } from '../../../woo_components.module/pipes/woo-currency.pipe';
import { CreativeData, CreativeDistributions, CreativeService } from '../../../woo_services.module/CreativeService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { ProductFormatService } from '../../../woo_services.module/ProductFormatService';
import {
  CampaignEstimationPartCreative,
  CommercialWeek,
  Creative,
  CreativeShareType,
  ProductFormatConditions,
  TargetingType,
  wooId,
} from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { BookingModel, BookingStore, CreativeLengthQuota } from '../../stores/BookingStore';
import { BookingStoreSelectors } from '../../stores/BookingStoreSelectors';
import { CreativeDialog } from '../campaign_creatives/creative-dialog.component';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'ttv-creatives-distribution',
  templateUrl: './ttv-creatives-distribution.component.html',
  styleUrls: ['./ttv-creatives-distribution.component.scss'],
  animations: [verticalExpansion(250)],
})
export class TTVCreativesDistribution extends SubscriptionHandler implements OnInit {
  readonly CreativeShareType = CreativeShareType;
  readonly instanceId = generateId();

  @Input() targetingId: wooId;

  creativeShareType: CreativeShareType = CreativeShareType.Percent;
  distributions: CreativeDistributions;
  creatives: Creative[] = [];
  modelFormat: PercentModelFormat = PercentModelFormat.percent;
  percentPrecision = 3;
  useSegmentation = false;
  targetingPeriod: { start: Date; end: Date } = {
    start: null,
    end: null,
  };
  expanded: { [creativeId: string]: boolean } = {};
  calculateFromBudget = true;
  distributionsPerLength: LengthDistributions;
  totalToDistribute = 0;
  distributed = 0;

  allCreatives: Creative[];
  ttvCampaign: boolean;
  originalCreativeLengthQuota: CreativeLengthQuota;
  creativeLengths: number[];
  currency: string;
  unbookableWeeks: CommercialWeek[] = [];
  productFormatConditions: ProductFormatConditions = {
    onlyShortFormat: false,
  };
  numberOfCreatives: number;
  creativesByLength: CreativesByLength;
  creativeUpdated = false;

  private customerId: wooId = null;
  private targetingType: TargetingType = null;

  get distributionCurrency(): WooCurrencyValue {
    return this.creativeShareType === CreativeShareType.Percent ? WooCurrencyValue.Quota : WooCurrencyValue.Budget;
  }

  get isVideo(): boolean {
    return this.targetingType === TargetingType.instream;
  }

  get editPercentShare(): boolean {
    return !this.singleCreative && this.creativeShareType === CreativeShareType.Percent;
  }

  get singleCreative(): boolean {
    return this.creatives.length <= 1;
  }

  constructor(
    private bookingStore: BookingStore,
    private creativeService: CreativeService,
    private translationService: TranslationService,
    private dialogService: DialogService,
    private selectors: BookingStoreSelectors,
    private formatterService: FormatterService,
    private productFormatService: ProductFormatService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnInit(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    const metaData = model.targetingMetaData[this.targetingId];
    if (targeting) {
      this.creatives = targeting.creatives.map((c) => ({ ...c }));
      this.creatives.forEach((creative) => (this.expanded[creative.id] = true));
      this.creativeShareType = targeting.creative_share_type;
      this.useSegmentation = metaData.segmentedCreatives;
      this.targetingPeriod.start = targeting.start_date ? new Date(targeting.start_date) : null;
      this.targetingPeriod.end = targeting.end_date ? new Date(targeting.end_date) : null;
      this.calculateFromBudget = targeting.calculate_from_budget;

      this.expanded = metaData.expandedCreatives.reduce<{ [id: string]: boolean }>(
        (res, id) => Object.assign(res, { [id]: true }),
        {},
      );

      this.originalCreativeLengthQuota = metaData.originalCreativeLengthQuota;
      this.creativeLengths = this.getCreativeLengths();

      if (this.numberOfCreatives !== this.creatives.length || this.creativeUpdated) {
        this.creativesByLength = this.groupCreativesByLength(this.creatives, this.creativeLengths);
        this.numberOfCreatives = this.creatives.length;
        this.creativeUpdated = false;
      }

      this.currency = this.formatterService.getCurrencyName(targeting, true, 'short');
      this.totalToDistribute = targeting.budget + targeting.additional_budget;

      this.productFormatConditions.onlyShortFormat = this.productFormatService.targetingIsShortform(targeting);
    }
    this.targetingType = metaData ? metaData.targetingType : null;
    this.customerId = model.campaign.customer_id;
    this.ttvCampaign = model.campaign.ttv_campaign;
    this.allCreatives = model.campaign.targetings.flatMap((t) => t.creatives);

    const targetingEstimation = model.estimation.parts.find((part) => part.targeting_id === this.targetingId);
    const estimations = targetingEstimation ? targetingEstimation.creatives : [];
    this.distributionsPerLength = this.getAllDistributions();
    this.distributions = this.getDistributions(this.creatives, estimations, this.creativeShareType);
    this.distributed = Object.values(this.distributions)
      .map((dist) => (this.creativeShareType === CreativeShareType.Percent ? dist.quota : dist.budget))
      .reduce((sum, value) => sum + (value || 0), 0);
    this.unbookableWeeks = model.forcedEstimationCorrection[this.targetingId]?.unbookableWeeks || [];
  };

  editBudgetShare(creatives: Creative[]): boolean {
    return !(creatives.length <= 1) && this.creativeShareType === CreativeShareType.Budget && this.calculateFromBudget;
  }

  updateQuotas(): void {
    this.bookingStore.updateCreativeDistributions(this.targetingId, this.distributions);
  }

  useSegmentationChange(): void {
    this.bookingStore.setSegmentedCreatives(this.targetingId, this.useSegmentation);
  }

  addCreative = (): void => {
    this.creativeLengths = this.getCreativeLengths();
    const newCreative = this.creativeService.createCreative();
    const creativeDialog = this.dialogService.create(CreativeDialog);
    creativeDialog
      .showInstreamForm(
        this.targetingType,
        this.customerId,
        this.allCreatives,
        null,
        this.creativeLengths,
        this.productFormatConditions,
      )
      .then((partialCreative: CreativeData) => {
        this.bookingStore.addCreative(this.targetingId, { ...newCreative, ...partialCreative });
      })
      .finally(() => creativeDialog.close());
  };

  editCreative = (creative: Creative): void => {
    this.creativeUpdated = true;
    this.creativeLengths = this.getCreativeLengths();
    const creativeDialog = this.dialogService.create(CreativeDialog);
    creativeDialog
      .showInstreamForm(
        this.targetingType,
        this.customerId,
        this.allCreatives,
        Object.assign({}, creative),
        this.creativeLengths,
        this.productFormatConditions,
      )
      .then((partialCreative: CreativeData) => {
        this.bookingStore.updateCreative(this.targetingId, { ...creative, ...partialCreative });
      })
      .finally(() => creativeDialog.close());
  };

  validDistribution(length: number): boolean {
    return this.getTotalBudgetForLength(length) === this.distributionsPerLength[length];
  }

  getCreativeLengths = (): number[] => {
    const creativeLengths = this.creativeService.getCreativeLengths(this.productFormatConditions.onlyShortFormat);
    return this.ttvCampaign ? Array.from(this.originalCreativeLengthQuota.keys()) : creativeLengths;
  };

  getTotalBudgetForLength = (length: string | number): number => {
    return (
      this.originalCreativeLengthQuota.get(Number(length)).budget +
      this.originalCreativeLengthQuota.get(Number(length)).budgetDecimals / 100
    );
  };

  groupCreativesByLength = (creatives: Creative[], lengths: number[]): CreativesByLength => {
    return lengths.reduce((lengthObject, creativeLength) => {
      lengthObject[creativeLength] = creatives
        .filter((creative) => creative.length === creativeLength)
        .map((c) => ({ ...c }));
      return lengthObject;
    }, {});
  };

  removeCreative = (creative: Creative): void => {
    const creativesLeft = this.creativeService.creativesWithLength(
      this.creatives.filter((c) => c.id !== creative.id),
      creative.length,
    );
    this.bookingStore.removeCreative(this.targetingId, creative.id, this.ttvCampaign);
    if (creativesLeft.length === 1) {
      this.setRemaining(creativesLeft[0]);
    }
  };

  translateCreativeType = (type: string): string => {
    return this.translationService.convertCreativeTypesShort(type);
  };

  segmentsChange = (creative: Creative): void => {
    this.bookingStore.updateCreative(this.targetingId, creative);
  };

  trackById(): (_: any, item: { id: wooId }) => wooId {
    return trackById;
  }

  creativeShareTypeChanged(newShareType: CreativeShareType): void {
    const currentDist = { ...this.distributions };
    this.bookingStore.setCreativeShareType(this.targetingId, newShareType);

    // in order to save the current state
    this.bookingStore.updateCreativeDistributions(this.targetingId, currentDist);
  }

  getAllDistributions(): LengthDistributions {
    return Object.keys(this.creativesByLength || {}).reduce((distributionObject, creativeLength) => {
      distributionObject[creativeLength] = this.getDistributionForLength(Number(creativeLength));
      return distributionObject;
    }, {});
  }

  setRemaining(creative: Creative): void {
    const field = 'budget';
    const budget = this.getTotalBudgetForLength(creative.length);
    const distributed = this.getDistributionForLength(creative.length);
    this.distributions[creative.id][field] = (this.distributions[creative.id][field] || 0) + (budget - distributed);
    this.creativeUpdated = true;
    this.updateQuotas();
  }

  toggleExpanded(creativeId: wooId): void {
    this.expanded[creativeId] = !this.expanded[creativeId];
    this.bookingStore.setExpandedCreatives(
      Object.entries(this.expanded)
        .filter(([_, value]) => Boolean(value))
        .map(([id, _]) => id),
    );
  }

  private getDistributions(
    creatives: Creative[],
    estimations: CampaignEstimationPartCreative[],
    shareType: CreativeShareType,
  ): CreativeDistributions {
    const stable = this.selectors.isStable(this.bookingStore.state);
    return this.creativeService.getDistributions(creatives, stable ? estimations : [], shareType);
  }

  private getDistributionForLength(length: number): number {
    return this.creatives
      .filter((creative) => creative.length === length)
      .reduce((sum, creative) => sum + (creative.budget || 0) + (creative.budget_decimals || 0) / 100, 0);
  }
}

interface CreativesByLength {
  [key: number]: Creative[];
}

interface LengthDistributions {
  [key: number]: number;
}
