import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { cloneDeep, filter, map } from 'lodash-es';
import { CreativeLengthQuota } from '../advanced_campaign_booking.module/stores/BookingStore';
import { sumBy, uniq } from '../utils/array';
import { round } from '../utils/math';
import { generateId } from '../utils/string';
import { UserRole } from '../utils/user-roles';
import { AuthService } from './AuthService';
import {
  AgreementPartCreative,
  CampaignEstimationPartCreative,
  Creative,
  CreativeShareType,
  ImageCreativeType,
  Segment,
  VideoCreativeType,
} from './shared-types';

@Injectable({ providedIn: 'root' })
export class CreativeService {
  readonly VIDEO_CREATIVE_TYPES: Array<Creative['creative_type']> = this.authService.hasAnyRole([
    UserRole.admin,
    UserRole.planner,
    UserRole.externalPlanner,
  ])
    ? Object.values(VideoCreativeType)
    : Object.values(VideoCreativeType).filter((type) => type !== VideoCreativeType.other);

  readonly IMAGE_CREATIVE_TYPES: Array<Creative['creative_type']> = Object.values(ImageCreativeType);
  readonly CREATIVE_TYPES: Array<Creative['creative_type']> = [
    ...this.VIDEO_CREATIVE_TYPES,
    ...this.IMAGE_CREATIVE_TYPES,
  ];

  private readonly creativeTemplate: Creative = {
    id: '',
    creative_type: null,
    video_code: '',
    asset_url: '',
    length: null,
    destination_url: '',
    click_tracking_url: '',
    impression_tracking_urls: [],
    custom_name: '',
    video_title: '',
    custom_text: '',
    quota: 100,
    has_image: false,
    segments: [],
    budget: 0,
    budget_decimals: 0,
    status: null,
  };

  constructor(private authService: AuthService, @Inject(LOCALE_ID) private locale: string) {}

  getCreativeLengths = (onlyShortFormat = false): number[] => {
    const isExternalPlanner = this.authService.hasRole(UserRole.externalPlanner);
    const lengths = [
      { length: 5, disabled: false },
      { length: 6, disabled: false },
      { length: 10, disabled: false },
      { length: 15, disabled: false },
      { length: 20, disabled: false },
      { length: 25, disabled: false || onlyShortFormat },
      { length: 30, disabled: isExternalPlanner || onlyShortFormat },
      { length: 35, disabled: isExternalPlanner || onlyShortFormat },
      { length: 40, disabled: isExternalPlanner || onlyShortFormat },
      { length: 45, disabled: isExternalPlanner || onlyShortFormat },
      { length: 50, disabled: isExternalPlanner || onlyShortFormat },
      { length: 55, disabled: isExternalPlanner || onlyShortFormat },
      { length: 60, disabled: isExternalPlanner || onlyShortFormat },
    ];
    return map(filter(lengths, { disabled: false }), 'length');
  };

  createCreative = (type = '', length: number | '' = ''): Creative => {
    return Object.assign(
      cloneDeep(this.creativeTemplate),
      { creative_type: type, length: length },
      { id: generateId() },
    );
  };

  checkForAgCreatives = (creatives: Creative[]): boolean => {
    return creatives.some((creative) => creative.creative_type === VideoCreativeType.ag);
  };

  resetCreative = (creative: Creative): void => {
    creative.asset_url = '';
    creative.video_title = '';
  };

  creativesWithLength(creatives: Creative[], length: number): Creative[] {
    return creatives.filter((c) => length === c.length);
  }

  createSegment = (start: Date | string, end: Date | string): Segment => {
    return {
      start_date: formatDate(start, 'yyyy-MM-dd', this.locale),
      end_date: formatDate(end, 'yyyy-MM-dd', this.locale),
    };
  };

  isImage = (creative: Creative): boolean => {
    return this.IMAGE_CREATIVE_TYPES.includes(creative.creative_type);
  };

  isVideo = (creative: Creative): boolean => {
    return !this.isImage(creative);
  };

  duplicateCreative(index: number, creatives: Creative[]): Creative {
    return {
      ...creatives[index],
      id: generateId(),
    };
  }

  getCreativeLengthQuotas(creatives: Creative[]): CreativeLengthQuota {
    const creativeLengths = uniq(creatives.map((creative) => creative.length)).sort();
    return creativeLengths.reduce((result, length) => {
      const creativesWithLength = creatives.filter((creative) => creative.length === length);
      return result.set(length, {
        budget: sumBy(creativesWithLength, 'budget'),
        budgetDecimals: sumBy(creativesWithLength, 'budget_decimals'),
      });
    }, new Map());
  }

  getAgreementDistributions(
    creatives: Creative[],
    parts: AgreementPartCreative[],
    shareType: CreativeShareType,
  ): CreativeDistributions {
    return creatives.reduce<CreativeDistributions>((result, creative, idx) => {
      const part = (parts || [])[idx];
      const creativeResult = this.getCreativeAgreementResult(creative, part);
      const rawQuota = this.getQuota(creative, shareType, creativeResult);
      const rawBudget = this.getBudget(creative, shareType, creativeResult);
      const rawViews = this.getViews(creativeResult);
      return {
        ...result,
        [creative.id]: {
          quota: rawQuota ? round(rawQuota, 3) : rawQuota,
          budget: rawBudget ? round(rawBudget, 2) : rawBudget,
          views: rawViews ? Math.round(rawViews) : rawViews,
        },
      };
    }, {});
  }

  getDistributions(
    creatives: Creative[],
    estimations: CampaignEstimationPartCreative[],
    shareType: CreativeShareType,
  ): CreativeDistributions {
    return creatives.reduce<CreativeDistributions>((result, creative) => {
      const estimation = estimations.find((e) => e.creative_id === creative.id);
      const creativeResult = this.getCreativeResult(estimation);
      const rawQuota = this.getQuota(creative, shareType, creativeResult);
      const rawBudget = this.getBudget(creative, shareType, creativeResult);
      const rawViews = this.getViews(creativeResult);
      return {
        ...result,
        [creative.id]: {
          quota: rawQuota ? round(rawQuota, 3) : rawQuota,
          budget: rawBudget ? round(rawBudget, 2) : rawBudget,
          views: rawViews ? Math.round(rawViews) : rawViews,
        },
      };
    }, {});
  }

  private getViews(result?: CreativeResult): number {
    return result ? result.netImpressions + result.additionalNetImpressions : null;
  }

  private getBudget(creative: Creative, shareType: CreativeShareType, result?: CreativeResult): number {
    if (shareType === CreativeShareType.Budget) {
      return creative.budget + creative.budget_decimals / 100;
    } else {
      return result ? Number(result.netBudget) + Number(result.additionalNetBudget) : null;
    }
  }

  private getQuota(creative: Creative, shareType: CreativeShareType, result?: CreativeResult): number {
    if (shareType === CreativeShareType.Percent) {
      return creative.quota;
    } else {
      return result ? result.creativeQuota : null;
    }
  }

  private getCreativeResult(estimation?: CampaignEstimationPartCreative): CreativeResult {
    return {
      additionalNetBudget: estimation ? estimation.additional_net_budget : 0,
      additionalNetImpressions: estimation ? estimation.additional_net_views : 0,
      creativeQuota: estimation ? estimation.creative_quota * 100 : 0,
      netBudget: estimation ? estimation.net_budget : 0,
      netImpressions: estimation ? estimation.net_views : 0,
    };
  }

  private getCreativeAgreementResult(creative: Creative, part: AgreementPartCreative) {
    return {
      additionalNetBudget: 0,
      additionalNetImpressions: part?.additional_net_views,
      creativeQuota: creative.quota,
      netBudget: creative.budget,
      netImpressions: part?.net_views,
    };
  }
}

export interface CreativeDistributions {
  [creativeId: string]: Partial<Record<'budget' | 'views' | 'quota', number>>;
}

type fields =
  | 'length'
  | 'creative_type'
  | 'custom_name'
  | 'video_code'
  | 'custom_text'
  | 'destination_url'
  | 'impression_tracking_urls'
  | 'click_tracking_url'
  | 'asset_url';
export type CreativeData = Partial<Pick<Creative, fields>>;

interface CreativeResult {
  netImpressions: number;
  additionalNetImpressions: number;
  netBudget: number;
  additionalNetBudget: number;
  creativeQuota: number;
}
