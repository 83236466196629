import { Inject, Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { AuthService } from './AuthService';
import { TermsService } from './TermsService';

@Injectable({ providedIn: 'root' })
export class PollingService {
  polls = [] as Poll[];

  constructor(
    @Inject('Window') private window: Window,
    private authService: AuthService,
    private termsService: TermsService,
  ) {
    this.window.addEventListener('focus', this.onFocus);
    this.window.addEventListener('blur', this.onBlur);
  }

  private runIfAuthenticated(fn: () => void) {
    return () => (this.authService.isAuthenticated() && !this.termsService.userNeedsToAcceptTerms() ? fn() : null);
  }

  private startPoll = (name: string, fn: () => void, delay: number) => {
    this.polls[name] = { subscription: timer(delay).subscribe(() => fn()), fn: fn, delay: delay, name: name };
  };

  startAuthenticatedOnlyPoll(name: string, fn: () => void, delay: number): void {
    this.startPoll(name, this.runIfAuthenticated(fn), delay);
  }

  private stopPoll = (name) => {
    this.polls[name].subscription.unsubscribe();
    this.polls[name].subscription = null;
  };

  private runAllPolls() {
    Object.values(this.polls).forEach((poll: Poll) => poll.fn());
  }

  private onFocus = () => {
    this.runAllPolls();
    Object.values(this.polls)
      .filter((poll: Poll) => !poll.subscription)
      .forEach((poll: Poll) => this.startPoll(poll.name, poll.fn, poll.delay));
  };

  private onBlur = () => {
    Object.keys(this.polls).forEach(this.stopPoll);
  };
}

interface Poll {
  name: string;
  fn: () => void;
  delay: number;
  subscription: Subscription;
}
