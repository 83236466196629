import { Component } from '@angular/core';
import { CashDashboardCard } from './cash-dashboard-card.component';

@Component({
  selector: 'cash-dashboard-card-desktop',
  templateUrl: './cash-dashboard-card-desktop.component.html',
  styleUrls: ['./cash-dashboard-card-desktop.component.scss'],
})
export class CashDashboardCardDesktop extends CashDashboardCard {
  public cardHover = false;

  mouseEnterCard = (): void => {
    this.cardHover = true;
  };

  mouseLeaveCard = (): void => {
    this.cardHover = false;
  };
}
