import { Component } from '@angular/core';
import { uniq } from '../../../utils/array';
import { MessageType } from '../../../woo_components.module/feedback/context-message.component';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { SessionService } from '../../../woo_services.module/SessionService';
import { Targeting, wooId } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import {
  BookingModel,
  BookingStep,
  BookingStore,
  BookingTargetingMetaData,
  EstimationStatusTyped,
  ForcedEstimationCorrection,
  Message,
  MessageAction,
  MessageKey,
} from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'advanced-booking-messages',
  templateUrl: './advanced-booking-messages.component.html',
  styles: ['.negative-margin-left--4px { margin-left: -4px }'],
})
export class AdvanceBookingMessages extends SubscriptionHandler {
  activeStep: BookingStep;
  private messages: Message[] = [];

  get visibleMessages(): Message[] {
    const messages = this.messages
      .filter((msg) => !(this.sessionService.getUserPreferences('dismissedIntroMessagesAbf') ?? []).includes(msg.key))
      .filter((msg) => !msg.dismissed);
    return messages;
  }

  constructor(
    private bookingStore: BookingStore,
    private formatterService: FormatterService,
    private sessionService: SessionService,
    private translationService: TranslationService,
  ) {
    super();
    this.addSubscription(this.bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnInit(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    const dirtySteps = model.dirtySteps;
    const validationMessages = model.validationMessages.map((msg) => ({ ...msg }));

    this.activeStep = model.activeBookingStep;
    if (validationMessages.length > 0) {
      this.messages = validationMessages.filter((msg) => dirtySteps.includes(msg.originStep));
    } else {
      this.messages = this.getApiMessages(model);
    }
    this.messages = this.messages.concat(this.getFullWeeksMessage(model.forcedEstimationCorrection));
    this.messages = this.filterDuplicates(this.messages); // TODO: Duplicates should not be generated to begin with: https://teliatv4media.atlassian.net/browse/WOO-1470
  };

  getApiMessages = (model: BookingModel): Message[] => {
    if (!model.estimation || model.estimation.status === EstimationStatusTyped.ok) {
      return [];
    }
    if (model.estimation.status === EstimationStatusTyped.inventoriesSoldOut) {
      return [
        {
          text: this.getMessageText(model.estimation.status),
          type: MessageType.error,
          dismissed: false,
          key: model.estimation.status,
        },
      ];
    }
    return model.estimation.parts.map((part) => {
      const targeting = model.campaign.targetings.find((t) => t.id === part.targeting_id);
      return {
        text: [this.getMessageText(model.estimation.status, part.overbooked_impressions)],
        type: MessageType.error,
        targetingId: part.targeting_id,
        action: this.getAction(targeting, model.targetingMetaData[targeting.id], part.overbooked_impressions),
        dismissed: false,
        key: model.estimation.status,
      };
    });
  };

  getMessageText = (status: string, overbookedImpressions?: number): string => {
    if (overbookedImpressions && overbookedImpressions > 0) {
      return `${this.formatterService.transformNumber(overbookedImpressions)} visningar fick inte plats i kampanjdelen`;
    } else {
      return `${this.translationService.convertError(status)}`;
    }
  };

  getAction = (
    targeting: Targeting,
    metaData: BookingTargetingMetaData,
    overbookedImpressions: number,
  ): MessageAction => {
    if (overbookedImpressions > 0) {
      return {
        label: this.formatterService.getTargetingLabelString(targeting, metaData),
        fn: () => this.bookingStore.focusTargeting(targeting.id),
      };
    } else {
      return null;
    }
  };

  getFullWeeksMessage(forced: Record<wooId, ForcedEstimationCorrection>): Message[] {
    const messages: Message[] = [];
    const fullWeeks = uniq(
      Object.values(forced).reduce((campaign_weeks, f) => {
        return campaign_weeks.concat(f?.unbookableWeeks?.length ? f.unbookableWeeks.map((w) => w.week) : []);
      }, []),
    );
    if (fullWeeks.length) {
      messages.push({
        key: 'fullWeeks',
        text: this.getFullWeeksTexts(fullWeeks, forced),
        type: MessageType.warning,
        dismissed: false,
      });
    }
    return messages;
  }

  dismissMessage = (message: Message): void => {
    message.dismissed = true;
  };

  dismissPermanently = (messageKey: MessageKey): void => {
    const dismissedIntroMessages = this.sessionService.getUserPreferences('dismissedIntroMessagesAbf') ?? [];
    dismissedIntroMessages.push(messageKey);
    this.sessionService.updateUserPreferences('dismissedIntroMessagesAbf', dismissedIntroMessages);
  };

  private getFullWeeksTexts(fullWeeks, forced: Record<wooId, ForcedEstimationCorrection>): string[] {
    const useRbsMessage = Object.values(forced).some((correction: ForcedEstimationCorrection) => correction.rbsFull);
    const weekString = `veck${fullWeeks.length > 1 ? 'or' : 'a'} ${fullWeeks.join(' ,')}.`;
    if (useRbsMessage) {
      return [
        `Just nu är vi slutsålda för ${weekString}`,
        'Ni kommer därför inte få några visningar under den perioden. Ni kan välja att gå vidare med bokningen eller justera perioden.',
      ];
    }

    return [
      `Just nu är vi slutsålda i den valda styrningen för ${weekString}`,
      `Ni kommer därför inte få några visningar under den perioden. Gör ett bredare/annat val av styrning eller
       välj att gå vidare med befintlig bokning`,
    ];
  }

  private filterDuplicates(messages: Message[]) {
    return messages.reduce((accumulator, message) => {
      if (!accumulator.some((messageComparison) => this.messagesEqual(messageComparison, message))) {
        accumulator.push(message);
      }
      return accumulator;
    }, []);
  }

  private messagesEqual(message: Message, comparisonMessage: Message) {
    if (message.key !== comparisonMessage.key) return false;
    if (Array.isArray(message.text) && Array.isArray(comparisonMessage.text)) {
      return message.text.join() === comparisonMessage.text.join();
    } else {
      return message.text === comparisonMessage.text;
    }
  }
}
