import { Component, Input } from '@angular/core';
import { AbstractValueAccessor } from '../../utils/AbstractValueAccessor';
import { valueAccessorProvider } from '../../utils/provider-builders';

const template = /* html */ `
<div class="flex__container">
  <radio-button-box
    *ngFor="let choice of choices"
    class="display--block radio-button-box"
    [ngClass]="{selected: model === choice.value}"
    [text]="choice.label"
    [(ngModel)]="model"
    [value]="choice.value"
    [disabled]="choice.disabled"
  ></radio-button-box>
</div>
<div class="radio-content__container" [ngClass]="{'first-item-active': model === choices[0]?.value}">
  <ng-content></ng-content>
</div>
`;

@Component({
  selector: 'radio-button-block',
  template: template,
  styleUrls: ['./radio-button-block.component.scss'],
  providers: [valueAccessorProvider(RadioButtonBlock)],
})
export class RadioButtonBlock<T> extends AbstractValueAccessor<T> {
  @Input() choices: Array<RadioButtonBlockChoice<T>> = [];
}

export interface RadioButtonBlockChoice<T> {
  value: T;
  label: string;
  disabled?: boolean;
}
