import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SimpleChanges } from '../../../../utils/types';
import {
  AdvancedTargetGroupCategory,
  AdvancedTargetGroupCategoryParams,
  AdvancedTargetGroupService,
} from '../../../../woo_services.module/AdvancedTargetGroupService';
import { DialogService } from '../../../../woo_services.module/DialogService';

const template = /* html */ `
<div>
  <form #newCategoryForm="ngForm" (submit)="createCategory()">
    <div class="input__container">
      <label for="name">Namn</label>
      <input id="name" name="name" type="text" [(ngModel)]="category.name" required>
    </div>
    <button type="submit" class="button primary-1" [disabled]="newCategoryForm.invalid || nameTaken">Skapa</button>
    <button type="button" class="link primary" (click)="onClose.emit()">Avbryt</button>
  </form>
</div>
`;

@Component({
  selector: 'advanced-target-group-category-form',
  template: template,
})
export class AdvancedTargetGroupCategoryForm implements OnChanges {
  @Input() categories: AdvancedTargetGroupCategory[] = [];
  @Output() onCreated = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();
  category: AdvancedTargetGroupCategoryParams = {
    name: null,
  };
  unavailableCategoryNames: string[] = [];

  constructor(private advancedTargetGroupService: AdvancedTargetGroupService, private dialogService: DialogService) {}

  get nameTaken(): boolean {
    return this.unavailableCategoryNames.includes(this.category.name);
  }

  ngOnChanges(changes: SimpleChanges<AdvancedTargetGroupCategoryForm>): void {
    if (changes.categories && this.categories) {
      this.unavailableCategoryNames = this.categories.map((c) => c.name);
    }
  }

  async createCategory(): Promise<void> {
    this.dialogService.openBlocking('Skapar kategori...');
    this.advancedTargetGroupService.createTargetGroupCategory(this.category).then(() => {
      this.dialogService.openSuccess('Ny kategori skapad!');
      this.onCreated.emit();
      this.onClose.emit();
    });
  }
}
