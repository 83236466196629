import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { setFormDirty } from '../../../../utils/form';
import { DeviceGroupService } from '../../../../woo_services.module/DeviceGroupService';
import { OutstreamSite, OutstreamSiteService } from '../../../../woo_services.module/OutstreamSiteService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { DeviceGroup, ProductFormat, Publisher, SlotType, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'edit-device-group',
  templateUrl: './edit-device-group.component.html',
})
export class EditDeviceGroup implements OnInit {
  readonly setFormDirty = setFormDirty;
  publishers: Publisher[];
  productFormats: ProductFormat[];
  deviceGroup: DeviceGroup = {} as DeviceGroup;
  sites: OutstreamSite[] = [];
  inputRows: InputRow[] = [];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  loading = true;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private deviceGroupService: DeviceGroupService,
    private outstreamSiteService: OutstreamSiteService,
    private utilityService: ProductsPageUtilityService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private translationService: TranslationService,
  ) {}
  ngOnInit(): void {
    this.deviceGroupService
      .getDeviceGroup(this.routingService.getParam(this.route, 'id'))
      .then((deviceGroup) => {
        this.deviceGroup = deviceGroup;
      })
      .then(() => {
        this.outstreamSiteService.getSites().then((sites) => (this.sites = sites));
      })
      .then(() => {
        return this.publisherService.getPublishers(ExtraPublisherField.enabled).then((publishers) => {
          this.publishers = publishers.filter((p) => p.enabled);
        });
      })
      .then(() => {
        this.productFormatService
          .getProductFormats(ExtraProductFormatField.slotTypes, ExtraProductFormatField.enabled)
          .then((productFormats) => {
            this.productFormats = productFormats.filter((f) => f.enabled);
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
            this.buildInputRows();
          });
      })
      .finally(() => (this.loading = false));
  }

  abort(): void {
    this.routingService.navigate(['/products', 'device_groups']);
  }

  updateDeviceGroup(): void {
    this.deviceGroupService.updateDeviceGroup(this.deviceGroup).then(() => {
      this.routingService.navigate(['/products', 'device_groups'], { state: { deviceGroupEstimatesChanged: true } });
    });
  }

  translateProductFormat(product: string): string {
    return this.translationService.convertProductFormat(product);
  }

  private buildInputRows(): void {
    let inputRow: InputRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        inputRow = [
          ...inputRow,
          {
            publisherId: publisher.id,
            publisher: publisher.name,
            productFormat: productFormat.name,
            productFormatId: productFormat.id,
            sharesPreroll: this.deviceGroup.shares[publisher.id][productFormat.id].preroll,
            sharesMidroll: this.deviceGroup.shares[publisher.id][productFormat.id].midroll,
            sharesPostroll: this.deviceGroup.shares[publisher.id][productFormat.id].postroll,
            withdrawalLimitsPreroll: this.deviceGroup.withdrawal_limits[publisher.id][productFormat.id].preroll,
            withdrawalLimitsMidroll: this.deviceGroup.withdrawal_limits[publisher.id][productFormat.id].midroll,
            withdrawalLimitsPostroll: this.deviceGroup.withdrawal_limits[publisher.id][productFormat.id].postroll,
          },
        ];
      });
    });
    inputRow.sort((a, b) => (a.publisher > b.publisher ? 0 : 1));
    this.inputRows = inputRow;
  }
}

interface InputRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
