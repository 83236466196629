import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { JobService } from '../../woo_services.module/JobService';
import { Pagination, PaginationService } from '../../woo_services.module/PaginationService';
import { NameIdTuple } from '../../woo_services.module/shared-types';
import { WooUploadService } from '../../woo_services.module/WooUploadService';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class EstimateImportService {
  constructor(
    private env: EnvironmentService,
    private paginationService: PaginationService,
    private jobService: JobService,
    private http: HttpClient,
    private fileUpload: WooUploadService,
  ) {}

  upload = (file: File): Promise<EstimateImport> => {
    return this.fileUpload.upload<EstimateImport>(`${this.env.apiUrl}/estimates`, file);
  };

  validate = (skipPastWeeks: boolean): Promise<EstimateInitState> => {
    return lastValueFrom(
      this.http.post<EstimateInitState>(`${this.env.apiUrl}/estimates/validate`, null, {
        params: { skip_past_weeks: skipPastWeeks.toString() },
      }),
    );
  };

  get = (id: string): Promise<EstimateImport> => {
    return lastValueFrom(this.http.get<EstimateImport>(`${this.env.apiUrl}/estimates/${id}`));
  };

  estimation_state = (): Promise<EstimateInitState> => {
    return lastValueFrom(this.http.get<EstimateInitState>(`${this.env.apiUrl}/estimates/state`));
  };

  import = (skipPastWeeks: boolean): Promise<EstimateInitState> => {
    return lastValueFrom(
      this.http.post<EstimateInitState>(`${this.env.apiUrl}/estimates/import`, null, {
        params: { skip_past_weeks: skipPastWeeks.toString() },
      }),
    );
  };

  list = (type: EstimateImportType, page: number, perPage = 5): Promise<[EstimateImport[], Pagination]> => {
    return lastValueFrom(
      this.http
        .get<EstimateImport[]>(`${this.env.apiUrl}/estimates/recent`, {
          params: {
            page: page.toString(),
            per_page: perPage.toString(),
            type: type,
          },
          observe: 'response',
        })
        .pipe(
          map(
            (event) =>
              [event.body, this.paginationService.getPagination(event.headers)] as [EstimateImport[], Pagination],
          ),
        ),
    );
  };

  download = (id: string): Promise<Blob> => {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/estimates/${id}/download`, {
        responseType: 'blob',
      }),
    );
  };

  duplicateLatestEstimates = (): Promise<EstimateImport[]> => {
    return lastValueFrom(this.http.post<EstimateImport[]>(`${this.env.apiUrl}/estimates/duplicate_latest`, null));
  };

  deleteVerified = (): Promise<EstimateInitState> => {
    return lastValueFrom(this.http.delete<EstimateInitState>(`${this.env.apiUrl}/estimates/delete_verified`));
  };
}

export const enum EstimateImportStatus {
  pending = 'pending',
  readyToValidate = 'ready_to_validate',
  readyToImport = 'ready_to_import',
  error = 'error',
  inventoryError = 'inventory_error',
  verifying = 'verifying',
  verified = 'verified',
  importing = 'importing',
  imported = 'imported',
}

export enum EstimateImportType {
  video = 'video_estimates',
  outstream = 'outstream_estimates',
}

export interface OverbookingResult {
  year: number;
  week: number;
  path: string;
  impressions: number;
  frequency_limit_ratios?: { '2week': number; '3campaign': number; '5campaign': number };
  frequency_limit_ratios_modified: boolean;
  withdrawal_limit_ratio: number;
  overbooked: boolean;
  overbooked_impressions: number;
}

export interface ValidationResult {
  rows_to_update: number;
  weeks: number[];
  errors: any;
  video_overbookings?: OverbookingResult[];
  pause_image_overbookings?: OverbookingResult[];
  overbookings?: OverbookingResult[];
}

export interface EstimateImport {
  id: string;
  name: string;
  author: NameIdTuple;
  updated_at: string;
  status: EstimateImportStatus;
  type: EstimateImportType;
  error_message?: string;
  year?: number;
  weeks: number;
  validation_result: ValidationResult;
}

export interface EstimateInitState {
  state: EstimateImportStatus;
  job_id?: string;
  estimate_files?: EstimateImport[];
  user?: string;
  current_estimating?: boolean;
}
