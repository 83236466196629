import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { UiEventType, WooUiEvent, WooUiEventService } from '../../../woo_services.module/WooUiEventService';

@Directive({
  selector: '[wooLoad]',
})
export class WooLoad extends SubscriptionHandler implements OnInit {
  private dotLoader: any;
  constructor(
    private button: ElementRef,
    private renderer: Renderer2,
    private routingService: RoutingService,
    private wooUiEventService: WooUiEventService,
  ) {
    super();
    this.addSubscription(
      this.routingService.onNavChange(() => {
        this.stopDotLoaderAnimation();
      }),
    );
  }

  ngOnInit(): void {
    this.dotLoader = this.buildDotLoaderElement();
    this.wooUiEventService.events$.subscribe((event: WooUiEvent) => {
      if (event.type === UiEventType.StopLoadDotLoader) {
        this.stopDotLoaderAnimation();
      }
    });
  }

  @HostListener('click', ['$event'])
  clickEvent(): void {
    this.startDotLoaderAnimation();
  }

  private startDotLoaderAnimation(): void {
    if (!this.button.nativeElement.classList.contains('loader')) {
      this.renderer.appendChild(this.button.nativeElement, this.dotLoader);
      this.renderer.addClass(this.button.nativeElement, 'loader');
    }
  }

  private stopDotLoaderAnimation(): void {
    if (this.button.nativeElement.classList.contains('loader')) {
      this.renderer.removeChild(this.button.nativeElement, this.dotLoader);
      this.renderer.removeClass(this.button.nativeElement, 'loader');
    }
  }

  private buildDotLoaderElement() {
    const dotElement = this.renderer.createElement('div');
    this.renderer.addClass(dotElement, 'loader');
    this.renderer.addClass(dotElement, 'dot-loader');
    this.renderer.addClass(dotElement, 'white');
    this.renderer.addClass(dotElement, 'fit-inside-button');
    return dotElement;
  }
}
