<div class="flex__container align-items--end">
  <div *ngIf="!ShowRegionGroups" class="regions__container">
    <div class="region__item" *ngFor="let region of regionList">
      <div class="input__container margin-bottom--none">
        <input type="checkbox" id="{{region.region_id}}-{{instanceId}}" name="checkbox"
          [(ngModel)]="selectedRegions[region.name]" (ngModelChange)="change()" class="checkbox">
        <label class="checkbox text-align--left" for="{{region.region_id}}-{{instanceId}}">{{region.name}}</label>
      </div>
    </div>
  </div>
<div *ngIf="ShowRegionGroups" class="regions__container">
  <div class="region__item" *ngFor="let region of regionListWithGroups">
    <div class="input__container margin-bottom--none">
      <input type="checkbox" id="{{region.region_id}}-{{instanceId}}" name="checkbox"
        [(ngModel)]="selectedRegions[region.name]" (ngModelChange)="change()" class="checkbox">
      <label class="checkbox text-align--left" for="{{region.region_id}}-{{instanceId}}">{{region.name}}</label>
    </div>
  </div>
</div>

  <div class="map__container">
    <region-map [(selectedRegionNames)]="selectedRegions" [ShowRegionGroups]="ShowRegionGroups" [regionToGroupMap]="regionNameToGroupNameMap" (selectedRegionChange)="change()"></region-map>
  </div>
</div>
