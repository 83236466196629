<div class="status-badge large_icon cta" [ngClass]="{ 'small': small }" *ngIf="creativeStatus === CreativeStatus.NotReady">
  <span class="icon-waiting-outline"></span>
  <div class="hover-text">{{translateCreativeStatus(creativeStatus)}}</div>
</div>
<div class="status-badge large_icon error" [ngClass]="{ 'small': small }" *ngIf="creativeStatus === CreativeStatus.PeachFileNotFound">
  <span class="icon-heads-up-outline"></span>
  <div class="hover-text">{{translateCreativeStatus(creativeStatus)}}</div>
</div>
<div class="status-badge large_icon success" [ngClass]="{ 'small': small }" *ngIf="creativeStatus === CreativeStatus.Ready">
  <span class="icon-check"></span>
  <div class="hover-text">{{translateCreativeStatus(creativeStatus)}}</div>
</div>
<div class="status-badge large_icon info" [ngClass]="{ 'small': small }" *ngIf="creativeStatus === CreativeStatus.Unknown">
  <span class="icon-question-mark-outline"></span>
  <div class="hover-text">{{translateCreativeStatus(creativeStatus)}}</div>
</div>