import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService } from './JobService';
import { WooUploadService } from './WooUploadService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UniverseService {
  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private wooUploadService: WooUploadService,
    private jobService: JobService,
  ) {}

  getUniverses(): Promise<UniverseSummary[]> {
    return lastValueFrom(this.http.get<UniverseSummary[]>(`${this.env.apiUrl}/universe`));
  }

  getTargetGroupUniverse(gender: string, minAge: number, maxAge: number): Promise<{ universe: number }> {
    return lastValueFrom(
      this.http.get<{ universe: number }>(`${this.env.apiUrl}/universe/target_group_universe`, {
        params: {
          gender: gender || '',
          min_age: !minAge && minAge !== 0 ? '' : minAge.toString(),
          max_age: !maxAge ? '' : maxAge.toString(),
        },
      }),
    );
  }

  async uploadFile(file: File): Promise<UniverseSummary[]> {
    return this.jobService.waitForJobCompletion(
      ((await this.wooUploadService.upload(`${this.env.apiUrl}/universe/upload`, file)) as Job).id,
    );
  }
}

export interface UniverseSummary {
  age_span: string;
  woman_universe: number;
  man_universe: number;
  all_universe: number;
}
