<dot-loader [visibility]="loadingMmsData"></dot-loader>

<form
  [formGroup]="outcomeForm"
  (ngSubmit)="saveOutcomeReport()"
  class="padding-top--1x"
>
  <p class="thin" *ngIf="!isReportPersisted">Fyll i de fälten du vill och klicka på spara för att kunden ska kunna ladda ned slutstatistiken. </p>
  <p class="thin" *ngIf="isReportPersisted">Slutstatistiken är sparad med nedanstående siffror.</p>
  <p class="thin" *ngIf="mmsMarkedAsInvalid()">Datat är markerat som ogiltigt och kommer inte att synas i rapporten.</p>
  <p class="thin" *ngIf="outcomeMmsStatus.noMmsTrackedCreatives">Videokod saknas, data från MMS kommer inte synas i rapporten.</p>
  <p class="thin" *ngIf="outcomeMmsStatus.includesInvalidMmsDays">
    <span class="text-warning icon-warning padding-right--1x"></span>
    En eller flera dagar inom kampanjens period har flaggats som felaktiga av MMS. Tabellen innehåller därför data från adservern.
  </p>
  <p class="thin" *ngIf="outcomeMmsStatus.usesPulseActuals">
    <span class="text-warning icon-warning padding-right--1x"></span>
    En eller flera dagar inom kampanjens period saknar data från MMS och använder sig istället av data från adservern.
  </p>

  <ng-container *ngIf="showMmsTable() && !loadingMmsData">
    <woo-table
    [ngClass]="{'invalid-data-table': mmsDataInvalidated}"
    [headers]="reportHeaders"
    [rows]="reportTableMmsData"
    ></woo-table>
    <button
      *ngIf="!isReportPersisted"
      type="button"
      (click)="toggleMmsDataValidity()"
      class="button primary-1 margin-top--2x">Markera MMS-data som {{mmsDataInvalidated ? 'giltig' : 'ogiltig'}}</button>
  </ng-container>

  <h4 class="margin-top--3x">Hela kampanjen</h4>
  <p *ngIf="outcomeMmsStatus.sharesVideoCode" class="padding-top--1x">
    <span class="text-warning icon-warning padding-right--1x"></span>En filmkod delas med andra kampanjer.
  </p>
  <div class="row">
    <div class="col-sm-6 col-md-4 col-lg-3">
      <div class="input__container">
        <label for="net-range">Nettoräckvidd</label>
        <input id="net-range" formControlName="net_range" type="number" >
      </div>
    </div>

    <ng-container *ngIf="showAverageFrequency">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="input__container">
          <label for="average-frequency">Frekvens från MMS</label>
          <input id="average-frequency" formControlName="average_frequency_by_mms" type="number" >
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="hasImpressionsTargetings">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="input__container">
          <label for="delivered-imps">Levererade impressions</label>
          <input id="delivered-imps" formControlName="delivered_imps" type="number" >
        </div>
      </div>
    </ng-container>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-8 col-lg-6">
      <div class="input__container">
        <label for="outcome-comment">Kommentar</label>
        <textarea id="outcome-comment" formControlName="comment" type="text" ></textarea>
      </div>
    </div>
  </div>

  <button type="submit" class="button primary-1" *ngIf="!isReportPersisted" [disabled]="saveButtonDisabled()">Spara</button>
  <button type="button" class="button danger" *ngIf="isReportPersisted" (click)="dialogRemoveOutcomeRapport()">Ta bort</button>

</form>
