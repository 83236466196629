import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WooComponentsModule } from '../woo_components.module/module';
import { BookingSummary } from './components/booking-summary/booking-summary.component';
import { CampaignBooking } from './components/campaign-booking.component';
import { CampaignConfirmation } from './components/campaign-confirmation/campaign-confirmation.component';
import { AssetSelect } from './components/creative/asset-select.component';
import { CustomerAssetListDialog } from './components/creative/customer-asset-list-dialog.component';
import { CustomerAssetList } from './components/creative/customer-asset-list.component';
import { PaymentRadioBox } from './components/payment/payment-radio-box.component';
import { SimpleBooking } from './components/simple-booking/simple-booking.component';
import { NoTargetingSelect } from './components/targeting/no-targeting-select.component';
import { PublisherGroupSelect } from './components/targeting/publisher-group-select.component';
import { TargetingRadioBox } from './components/targeting/targeting-radio-box.component';
import { TargetingSelect } from './components/targeting/targeting-select.component';
import { ValidatedRegionSelect } from './components/targeting/validated-region-select.component';
import { ValidatedTargetGroupSelect } from './components/targeting/validated-target-group-select.component';
import { StatusCollectionService } from './services/status-collection.service';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, WooComponentsModule],
  declarations: [
    AssetSelect,
    BookingSummary,
    CampaignBooking,
    CampaignConfirmation,
    CustomerAssetList,
    CustomerAssetListDialog,
    NoTargetingSelect,
    PaymentRadioBox,
    PublisherGroupSelect,
    SimpleBooking,
    ValidatedTargetGroupSelect,
    TargetingRadioBox,
    TargetingSelect,
    ValidatedRegionSelect,
  ],
  providers: [CustomerAssetListDialog, StatusCollectionService],
})
export class SimpleCampaignBookingModule {}
