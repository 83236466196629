<div class="block">
  <h1>Hantera estimat</h1>
  <div class="tabbable__body clearfix shadow-z-1 block">
    <div *ngIf="estimateProgressWidget.show" [@verticalExpansion]>
      <progress-content
        [message]="estimateProgressWidget.message"
        [progress] = "estimateProgressWidget.progress"
        [progress_message] = "estimateProgressWidget.progress_message"
      ></progress-content>
      <div class="divider margin-top--1x"></div>
    </div>

    <h2>Instream estimat</h2>
    <estimates-list [type]="EstimateImportType.video"></estimates-list>

    <h2>Outstream estimat</h2>
    <estimates-list [type]="EstimateImportType.outstream"></estimates-list>

    <form *ngIf="!estimateProgressWidget.show">
      <div *ngIf="show.validateFile">

        <div *ngIf="show.uploadFile">
          <h3>Uppdatera lager</h3>
          <div>
            <input type="radio" id="duplicate-est" class="radio" name="update-estimate" [value]="importOption.duplicate" [(ngModel)]="importEstimateOption">
            <label for="duplicate-est" class="radio">Ladda om senaste estimat</label>
          </div>
          <div>
            <input type="radio" id="new-est" class="radio" name="update-estimate" [value]="importOption.new" [(ngModel)]="importEstimateOption">
            <label for="new-est" class="radio">Ladda upp ny estimatfil (excel/.xlsx)</label>
          </div>
          <div *ngIf="importEstimateOption === importOption.new" class="margin-left--4x margin-top--1x">
            <p><span class="icon-warning text--info"></span> Filen skall heta <strong>woo_estimates_yyyy.xlsx</strong> eller <strong>woo_outstream_estimates_yyyy.xlsx</strong> där yyyy motsvarar aktuellt år.</p>
            <input type="file" (change)="upload.file = $event.target.files[0]" name="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" [disabled]="importEstimateOption !== importOption.new" required>
          </div>
          <div *ngIf="isAdmin">
            <div class="divider margin-top--1x"></div>
            <input id="import_past_weeks" name="import_past_weeks" type="checkbox" class="checkbox" [(ngModel)]="upload.importPastWeeks">
            <label for="import_past_weeks" class="checkbox">Skriv över tidigare veckor</label>
          </div>
          <div [hidden]="!validationErrorMessages.length">
            <h4> <span class="icon-warning text-danger"></span> Följande valideringsfel existerar:</h4>
            <ul class="ul__woo">
              <li *ngFor="let errorMsg of validationErrorMessages">
                <span class="">{{errorMsg}}</span>
              </li>
            </ul>
          </div>
          <button class="button primary-1" (click)="validateEstimate()">Validera</button>
        </div>
      </div>

      <div *ngIf="show.verifyUpload">
        <h2>Verifiera uppladdad data</h2>
        <div *ngFor="let estimateImport of this.validatedEstimateImport">
          <p>Uppladdad fil:<span>
              <button (click)="download(estimateImport)" class="link margin--none">
                {{estimateImport.name}}
              </button>
            </span>
          </p>
          <p *ngFor="let item of estimateImport.validation_result.errors">
            <span class="icon-warning text-warning"></span> <span class="text--medium">{{item}}</span>
          </p>
          <div *ngIf="!estimateImport.validation_result.rows_to_update" class="text-success">
            Filen har inget att ladda upp.
          </div>
          <div *ngIf="estimateImport.validation_result.rows_to_update > 0">
            <strong>{{estimateImport.weeks}} veckor</strong> kommer att importeras.
          </div>
          <div *ngIf="estimateImport.validation_result.video_overbookings?.length">
            <h4>Videolager överbokningar</h4>
            <p *ngFor="let item of estimateImport.validation_result.video_overbookings">
              <span class="icon-warning text-warning"></span> <strong>{{item.year}}-{{item.week}}</strong>  Lager <strong>{{item.path}}</strong>  kommer överbokas med <strong>{{item.overbooked_impressions}}</strong> impressions.
            </p>
          </div>
          <div *ngIf="estimateImport.validation_result.pause_image_overbookings?.length">
            <h4>Pausreklamslager överbokningar</h4>
            <p *ngFor="let item of estimateImport.validation_result.pause_image_overbookings">
              <span class="icon-warning text-warning"></span> <strong>{{item.year}}-{{item.week}}</strong>  Lager <span class="text--italic">'{{item.path}}'</span>  kommer överbokas med <strong>{{item.overbooked_impressions}}</strong> impressions.
            </p>
          </div>
          <div *ngIf="estimateImport.validation_result.overbookings?.length">
            <h4>Outstreamlager överbokningar</h4>
            <p *ngFor="let item of estimateImport.validation_result.overbookings">
              <span class="icon-warning text-warning"></span> <strong>{{item.year}}-{{item.week}}</strong>  Lager <span class="text--italic">'{{item.path}}'</span>  kommer överbokas med <strong>{{item.overbooked_impressions}}</strong> impressions.
            </p>
          </div>
        </div>
          <p *ngIf="noOverbooking() && !importErrorMessage" class="text-success">Inget kommer att överbokas.</p>
          <div *ngIf="!!importErrorMessage">
            <p class="text-danger">Filen kunde inte importeras, vänligen kontakta support.</p>
            <p class="text-danger">Felmeddelande: {{this.importErrorMessage}}</p>
          </div>
      <button *ngIf="noValidationErrors()" class="button primary-1" (click)="importEstimates()">Importera estimat</button>
      <button *ngIf="noValidationErrors()" class="button primary-2" (click)="deleteVerified()">Avbryt</button>
      </div>
    </form>
  </div>
</div>
