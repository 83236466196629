<div class="input__container">
  <label for="named-object-filter">Sök</label>
  <input id="named-object-filter" type="text" [(ngModel)]="searchString" (ngModelChange)="filter()">
</div>
<table class="table table--woo table--small table--hover">
  <thead>
    <tr>
      <td>Namn</td>
      <td *ngIf="showNicknames">Kundnamn TV4</td>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let obj of filteredObjects"
      [hidden]="obj.hidden"
      class="clickable-link"
      [routerLink]="['/' + routePath, obj.id]"
      >
      <td>
        <a>
          {{obj.name}}
        </a>
      </td>
      <td *ngIf="showNicknames">
        <a *ngIf="obj.name !== obj.nickname">
          {{obj.nickname}}
        </a>
      </td>
    </tr>
    <tr
      *ngFor="let obj of filteredInactiveObjects"
      [hidden]="obj.hidden"
      class="clickable-link danger"
      [routerLink]="['/' + routePath, obj.id]"
    >
      <td>
        <a>
          {{obj.name}} (tidigare kund)
        </a>
      </td>
      <td *ngIf="showNicknames">
        <a *ngIf="obj.name !== obj.nickname">
          {{obj.nickname}}
        </a>
      </td>
    </tr>
  </tbody>
</table>
<button
  class="button primary-1 margin-top--2x"
  (click)="showInactiveObjects = !showInactiveObjects; filter()"
  *ngIf="filteredInactiveObjects.length"
>
  {{!showInactiveObjects ? 'Visa inaktiva' : 'Dölj inaktiva'}}
</button>
