<div class="display--inline-block">
  <voucher-select [targetingId]="targetingId"></voucher-select>
  <p>Välj ett eller flera tidsintervall som du vill att din kampanj ska visas på.</p>
  <div
    class="col-lg-4 col-md-12 padding-left--none padding-right--1x padding-top--1x padding-bottom--none"
    *ngFor="let daypart of dayparts; let index = index"
  >
    <targeting-checkbox
      (onClick)="toggleDaypart(daypart.id)"
      [texts]="[daypart.name, daypart.description]"
      [checked]="isSelected(daypart.id)">
      <img [src]="images[daypart.name]" alt="{{daypart.name}} logo" class="daypart-image margin-left--1x">
    </targeting-checkbox>
  </div>
</div>
