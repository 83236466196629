import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService, ProgressCallback } from './JobService';
import { wooId } from './shared-types';
import { NamedBlob, TemporaryFileService } from './TemporaryFileService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private jobService: JobService,
    private temporaryFileService: TemporaryFileService,
  ) {}

  getInvoicesForCampaign(campaignId: wooId): Promise<Invoice[]> {
    return lastValueFrom(this.http.get<Invoice[]>(`${this.env.apiUrl}/invoices/${campaignId}`));
  }

  getSummary(): Promise<InvoiceSummary> {
    return lastValueFrom(this.http.get<InvoiceSummary>(`${this.env.apiUrl}/invoices/summary`));
  }

  createManualInvoice(invoice: ManualInvoice): Promise<Invoice> {
    return lastValueFrom(
      this.http.post<Invoice>(`${this.env.apiUrl}/invoices`, { invoice }),
    );
  }

  cancelInvoice(invoiceId: wooId): Promise<Invoice> {
    return lastValueFrom(this.http.delete<Invoice>(`${this.env.apiUrl}/invoices/${invoiceId}`));
  }

  attestInvoice(invoiceId: wooId): Promise<AttestInvoice> {
    return lastValueFrom(
      this.http.patch<AttestInvoice>(`${this.env.apiUrl}/invoices/attest_invoice`, { id: invoiceId }),
    );
  }

  getAwaitingAttest(): Promise<AttestInvoice[]> {
    return lastValueFrom(this.http.get<AttestInvoice[]>(`${this.env.apiUrl}/invoices/awaiting_attest`));
  }

  getSentCreditInvoices(): Promise<AttestInvoice[]> {
    return lastValueFrom(this.http.get<AttestInvoice[]>(`${this.env.apiUrl}/invoices/sent_credit_invoices`));
  }

  async getExcelSheet(params: Record<string, string>, progressCallback: ProgressCallback): Promise<NamedBlob> {
    const job = await lastValueFrom(
      this.http.get<Job>(`${this.env.apiUrl}/invoices/export_excel`, {
        params: params,
      }),
    );
    return this.temporaryFileService.download(
      (await this.jobService.waitForJobCompletion(job.id, progressCallback)).temporary_file_id,
    );
  }
}

export interface Invoice {
  id: wooId;
  status: InvoiceStatus;
  invoice_date: string;
  sent_at?: string;
  rows: InvoiceRow[];
  total_gross: string;
  total_vat: string;
  internal_message?: string;
  customer_name: string;
  customer_org_no: string;
  customer_address1: string;
  customer_address2: string;
  customer_zip: string;
  customer_city: string;
  customer_country: string;
  payment_terms: number;
  payment_terms_code?: string;
  campaign_name: string;
  invoice_period_start_date: string;
  invoice_period_end_date: string;
  attest_status: AttestStatus;
}

export enum InvoiceStatus {
  sent = 'sent',
  created = 'created',
  cancelled = 'cancelled',
}

export interface ManualInvoice {
  campaign_id: string;
  description: string;
  total_gross_excluding_agency_discount: number;
  invoice_date: Date;
  original_invoice: string;
  internal_message: string;
}

export interface InvoiceRow {
  amount?: number;
  campaign_id: wooId;
  description: string;
  free1code?: string;
  revaccount?: string;
}

export interface InvoiceSummary {
  yesterday: CompactInvoice[];
  today: CompactInvoice[];
  upcoming: CompactInvoice[];
}

export interface CompactInvoice {
  name: string;
  campaign: string;
  booked: string;
  gross: string;
  vat: string;
  date: string;
  status: string;
}

export interface AttestInvoice
  extends Pick<
    Invoice,
    'id' | 'status' | 'invoice_date' | 'sent_at' | 'total_gross' | 'campaign_name' | 'customer_name' | 'attest_status'
  > {
  campaign_id: wooId;
  campaign_order_reference: string;
  agency_name?: string;
  attest_user?: string;
  attested_at?: string;
}

export enum AttestStatus {
  AttestedOk = 'attested_ok',
  AwaitingAttest = 'awaiting_attest',
  NoAttestNeeded = 'no_attest_needed',
  PreAttestRules = 'pre_attest_rules',
}
