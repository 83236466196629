import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EMPTY } from 'rxjs';
import { orderByDate } from '../../../../utils/date';
import { ConfirmDialogContent } from '../../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { GrossRatingService } from '../../../../woo_services.module/GrossRatingService';
import { HistoryItem } from '../../../../woo_services.module/shared-types';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-gross-rating-factor',
  templateUrl: './manage-gross-rating-factor.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageGrossRatingFactor implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('stickyHeader', { static: false }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  @Output() onClose = new EventEmitter<boolean>();

  readonly minimumGrossRatingFactor = 1;
  readonly alarminglyHighGrossRatingFactor = 6;
  readonly numberOfDecimals = 3;

  show = {
    loading: true,
    history: false,
    hasHistory: false,
  };

  historyGrossRatingData: HistoryItemView[] = [];
  grossRatingFactor: number;
  tableHeaders = ['Omvandlingsnyckel (tidigare värde -> nytt värde)', 'Uppdaterad', 'Användare'];

  constructor(
    private grossFactorService: GrossRatingService,
    private utilityService: ProductsPageUtilityService,
    public dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.load().finally(() => {
      this.show.loading = false;
      this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
    });
  }

  load = async (): Promise<void> => {
    const factor = await this.grossFactorService.getGrossRatingFactor();
    this.grossRatingFactor = factor.gross_rating_factor;
    const historyData = await this.grossFactorService.getGrossRatingHistoryData();
    if (historyData.length > 0) {
      this.historyGrossRatingData = orderByDate(historyData, 'updated_at')
        .map(
          (historyItem: HistoryItem): HistoryItemView => {
            return {
              updater: historyItem['updater_name'],
              updatedAt: historyItem.updated_at,
              grossRatingFactor: this.formatHistoryGrossRatingFactor(historyItem),
            };
          },
        )
        .filter((historyItemView: HistoryItemView): boolean => !!historyItemView.grossRatingFactor);
      this.show.hasHistory = this.historyGrossRatingData.length > 0;
    }
  };

  formatHistoryGrossRatingFactor(historyItem: HistoryItem): string | null {
    if ('gross_rating_factor' in historyItem['modified'] && 'gross_rating_factor' in historyItem['original']) {
      return `${historyItem['original']['gross_rating_factor']['$numberDecimal']} -> ${historyItem['modified']['gross_rating_factor']['$numberDecimal']}`;
    }
    return null;
  }

  disableSaveButton(): boolean {
    return this.grossRatingFactor < this.minimumGrossRatingFactor;
  }

  saveConfirmationDialog(): ConfirmDialogContent {
    if (this.grossRatingFactor < this.alarminglyHighGrossRatingFactor)
      return {
        header: 'Bekräfta ändringar',
        textBlocks: [`Vill du spara omvandlingsnyckel ${this.grossRatingFactor} ?`],
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      };

    return {
      header: 'Bekräfta ändringar',
      textBlocks: [''],
      confirmText: 'Spara',
      cancelText: 'Avbryt',
      warningBlocks: [
        {
          header: 'Varning!',
          textBlocks: [`Du är på väg att sätta omvandlingsnyckeln till ${this.grossRatingFactor}.`, 'Är du säker?'],
        },
      ],
    };
  }

  formatGrossRatingFactorDecimals(): number {
    return Number(this.grossRatingFactor.toFixed(this.numberOfDecimals));
  }

  save = async (): Promise<void> => {
    try {
      await this.dialogService.openConfirm(this.saveConfirmationDialog());
      this.show.loading = true;
      await this.grossFactorService.updateGrossRatingFactor(this.formatGrossRatingFactorDecimals()).catch(() => EMPTY);
    } catch (err) {
      EMPTY;
    } finally {
      await this.load();
      this.show.loading = false;
    }
  };

  openCard(): void {
    this.show.history = true;
  }

  closeCard(): void {
    this.show.history = false;
  }
}

interface HistoryItemView {
  updater: string;
  updatedAt: string | Date;
  grossRatingFactor?: string;
}
