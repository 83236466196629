<div class="relative" #element>
  <div class="flex__container align-items--start">
    <div class="input__container padding-right--1x color-controlled-container" [ngClass]="{marked: show.calender && !selectedDate}">
      <label for="date-input-{{instanceId}}">{{label}}</label>
      <input
        id="date-input-{{instanceId}}"
        placeholder="YYYY-MM-DD"
        [ngModel]="selectedDate"
        (ngModelChange)="setDateIfValid($event); show.pristine = false"
        (click)="$event.stopPropagation(); showCalender()"
        [attr.disabled]="disabled ? '' : null"
        wooDateFormatter
        [wooDateValidator]="{min: firstSelectableDate, max: lastSelectableDate}"
        wooInputErrors
        (blur)="touch()"
        [ngClass]="{'ng-pristine': show.pristine || show.calender, 'ng-focus': show.calender }"
      >
    </div>
  </div>
  <div
      class="overlay"
      [ngClass]="{'open-above': show.calenderAbove, 'open-below': !show.calenderAbove}"
      *ngIf="show.calender"
      (click)="$event.stopPropagation()"
    >
    <div class="calender-container first-one">
      <calender-heading
        class="display--block padding-bottom--1x show-left-button"
        [date]="firstMonthDate"
        (prevClick)="prevMonth()"
        (nextClick)="nextMonth()"
      ></calender-heading>
      <calender-top-row></calender-top-row>
      <div class="flex__container" *ngFor="let pair of firstMonth | keyvalue:compareWeeksByStartDate; last as last">
        <div class="calender-label" [ngClass]="{'bottom-end' : last}">{{pair.key}}</div>
        <div
          class="calender-day"
          [attr.e2e-id]="day.disabled ? null : day.date | date:DATE_FORMAT"
          *ngFor="let day of pair.value"
          [ngClass]="{
            disabled: day.disabled,
            'not-selectable': !day.selectable,
            selected: isSelected(day),
            changing: isChanging(day),
            marked: day.marked
          }"
          (click)="selectDay(day)"
          (mouseover)="mouseoverDay(day)"
          (mouseleave)="mouseleaveDay(day)"
        >
          <div class="calender-day-content">
            {{day.date.getDate()}}
          </div>
        </div>
      </div>
    </div>
    <div class="calender-container second-one">
      <calender-heading
        class="display--block padding-bottom--1x show-right-button"
        [date]="secondMonthDate"
        (prevClick)="prevMonth()"
        (nextClick)="nextMonth()"
      ></calender-heading>
      <calender-top-row></calender-top-row>
      <div class="flex__container" *ngFor="let pair of secondMonth | keyvalue:compareWeeksByStartDate; last as last">
        <div class="calender-label" [ngClass]="{'bottom-end' : last}">{{pair.key}}</div>
        <div
          class="calender-day"
          [attr.e2e-id]="day.disabled ? null : day.date | date:DATE_FORMAT"
          *ngFor="let day of pair.value"
          [ngClass]="{
            disabled: day.disabled,
            'not-selectable': !day.selectable,
            selected: isSelected(day),
            changing: isChanging(day),
            marked: day.marked
          }"
          (click)="selectDay(day)"
          (mouseover)="mouseoverDay(day)"
          (mouseleave)="mouseleaveDay(day)"
        >
          <div class="calender-day-content">
            {{day.date.getDate()}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
