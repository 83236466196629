import { Component, Input, OnChanges } from '@angular/core';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { SimpleChanges } from '../../../utils/types';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { Targeting, wooId } from '../../../woo_services.module/shared-types';

const template = /* html */ `
<div class="row">
  <edit-goal-form
    *ngFor="let goal of targeting.goals"
    [goalId]="goal.id"
    [targetingId]="targeting.id"
  ></edit-goal-form>
</div>
`;

@Component({
  selector: 'edit-targeting-form',
  template: template,
})
export class EditTargetingForm extends SubscriptionHandler implements OnChanges {
  @Input() targetingId: wooId;

  targeting: Targeting;
  editGoals = false;

  constructor(private dashboardStore: DashboardStore) {
    super();
    this.addSubscription(this.dashboardStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(changes: SimpleChanges<EditTargetingForm>): void {
    if (changes.targetingId && this.targetingId) {
      this.initFromStore(this.dashboardStore.state);
    }
  }

  initFromStore = (model: DashboardModel): void => {
    this.editGoals = model.editGoals;
    this.targeting = this.dashboardStore.state.activeCampaign.targetings.find((t) => t.id === this.targetingId);
  };
}
