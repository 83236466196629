import { Directive, Input, OnChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isDateBefore, isDateAfter } from '../../utils/date';

@Directive({
  selector: '[wooDateValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: WooDateValidator, multi: true }],
})
export class WooDateValidator implements Validator, OnChanges {
  @Input('wooDateValidator') options: { min?: Date; max?: Date } = {};

  ngOnChanges(): void {
    this.propagateValidatorChange();
  }

  validate(control: AbstractControl): ValidationErrors {
    const errors = {};
    if (control.value && this.options.min && isDateBefore(control.value, this.options.min)) {
      Object.assign(errors, { wooDateValidatorMin: 'Datumet ligger för tidigt' });
    }
    if (control.value && this.options.max && isDateAfter(control.value, this.options.max)) {
      Object.assign(errors, { wooDateValidatorMax: 'Datumet ligger för sent' });
    }
    return errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.propagateValidatorChange = fn;
  }

  private propagateValidatorChange = () => null;
}
