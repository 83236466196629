import { Injectable } from '@angular/core';
import { atLeastOneItem, sum, sumBy } from '../../utils/array';
import { isDateAfter, isDateBefore } from '../../utils/date';
import { round } from '../../utils/math';
import { UserRole } from '../../utils/user-roles';
import { validTargetGroupSelect } from '../../utils/validators';
import { TargetGroupChoices } from '../../woo_components.module/inputs/target-group-select/target-group-select.component';
import { AuthService } from '../../woo_services.module/AuthService';
import { CampaignService } from '../../woo_services.module/CampaignService';
import { CreativeService } from '../../woo_services.module/CreativeService';
import { FormatterService } from '../../woo_services.module/FormatterService';
import { ProductFormatService } from '../../woo_services.module/ProductFormatService';
import {
  Campaign,
  Creative,
  CreativeShareType,
  GeoTargeting,
  ProductChoice,
  ShareType,
  Targeting,
  TargetingProgram,
  TargetingRegion,
  ViewCurrency,
} from '../../woo_services.module/shared-types';
import { BookingTargetingMetaData, CreativeLengthQuota } from '../stores/BookingStore';

@Injectable()
export class BookingValidationService {
  constructor(
    private authService: AuthService,
    private campaignService: CampaignService,
    private creativeService: CreativeService,
    private formatterService: FormatterService,
    private productFormatService: ProductFormatService,
  ) {}

  validateCampaignDetails = (campaign: Campaign, cashCampaign: boolean, memaCampaign: boolean): string[] => {
    const agencyRequired = this.authService.hasAnyRole([UserRole.agencyAdmin, UserRole.agencyUser]);
    const fields = new Map([
      ['name', 'Namn'],
      ['client_invoice_reference', 'Er referens'],
      ['reference_number', 'Internt referensnummer'],
      ['customer_id', 'Kund'],
      ['brand', 'Varumärke'],
    ]);
    if (cashCampaign) {
      fields.delete('reference_number');
    }
    if (agencyRequired) {
      fields.set('agency_id', 'Byrå');
    }
    if (memaCampaign) {
      fields.delete('brand');
    }

    const missingFields = this.missingFields(campaign, Array.from(fields.keys()));
    return missingFields.length === 0
      ? null
      : [this.missingFieldsLabel(), missingFields.map((field) => fields.get(field)).join(', ')];
  };

  validateTargeting = (targeting: Targeting): string[] => {
    const fields = new Map([
      ['start_date', 'Startdatum'],
      ['end_date', 'Slutdatum'],
    ]);
    const missingBudget = this.validateBudget(targeting);
    const missingFields = this.missingFields(targeting, Array.from(fields.keys())).concat(missingBudget || []);
    const datesInWrongOrder =
      targeting.end_date && targeting.end_date && isDateBefore(targeting.end_date, targeting.start_date);
    return [
      datesInWrongOrder ? 'Startdatum måste vara före slutdatum' : null,
      ...(missingFields.length === 0
        ? []
        : [this.missingFieldsLabel(), missingFields.map((field) => fields.get(field) || field).join(', ')]),
    ].filter(Boolean);
  };

  validateCreatives = (campaign: Campaign): string[] => {
    const missingCreative = campaign.targetings.some((t) => t.creatives.length === 0);
    return [missingCreative ? 'Det saknas material' : null].filter((msg) => Boolean(msg));
  };

  validateCreativeDistribution = (targeting: Targeting): string[] => {
    const distributeWithQuota = targeting.creative_share_type === CreativeShareType.Percent;
    const missingCreative = targeting.creatives.length === 0;
    const creativeBudget = round(sum(targeting.creatives.map((c) => c.budget + c.budget_decimals / 100)), 2);
    const totalBudget = round(targeting.budget + targeting.additional_budget, 2);
    const missingQuotaOrBudget = targeting.creatives.some((c) =>
      distributeWithQuota ? !c.quota : !(c.budget + c.budget_decimals / 100),
    );
    const creativeQuota = round(sumBy(targeting.creatives, 'quota'), 2);

    const validQuotas = missingCreative || creativeQuota === 100;
    const validBudgets = missingCreative || creativeBudget === totalBudget;

    return [
      missingQuotaOrBudget ? 'Minst en av filmerna/bilderna saknar fördelning' : null,
      !validQuotas && distributeWithQuota ? 'Materialfördelningen summerar inte till 100%' : null,
      !validBudgets && !distributeWithQuota
        ? `Materialfördelningen summerar inte till ${this.formatterService.transformNumber(totalBudget)}`
        : null,
    ].filter((msg) => Boolean(msg));
  };

  validateCreativeFilmLengthQuotas = (
    creatives: Creative[],
    ttvCampaign: boolean,
    originalLengthQuotas: CreativeLengthQuota,
  ): string => {
    if (!ttvCampaign) {
      return null;
    }
    const newCreativeLengthQuotas = this.creativeService.getCreativeLengthQuotas(creatives);
    return !this.quotasEqual(originalLengthQuotas, newCreativeLengthQuotas)
      ? 'TTV-kampanj! Fördelningen mellan filmlängderna är förändrad'
      : null;
  };

  validateCreativeSegments = (campaign: Campaign): string[] => {
    const creatives = campaign.targetings.flatMap((t) => t.creatives);
    const inactiveCreative = creatives.some((c) => c.segments.length === 0);
    const invalidCreativeAndShortFormat = this.filterShortFormatCreativeLength(campaign);

    const segments = creatives.flatMap((c) => c.segments);
    const campaignPeriod = this.campaignService.getCampaignPeriod(campaign);
    const invalidStartDates = segments.every((s) => isDateAfter(s.start_date, campaignPeriod.start));
    const invalidEndDates = segments.every((s) => isDateBefore(s.end_date, campaignPeriod.end));

    return [
      inactiveCreative ? 'Varje film måste någon gång vara aktiv' : null,
      invalidStartDates ? 'Det saknas aktivt material vid kampanjens start' : null,
      invalidEndDates ? 'Det saknas aktivt material vid kampanjens slut' : null,
      invalidCreativeAndShortFormat
        ? 'Du har bokat Online Video, denna placering får ha en filmlängd av som mest 20 sekunder'
        : null,
    ];
  };

  validateRegions = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    const regional = metaData ? metaData.geoTargeting === GeoTargeting.regional : false;
    return this.missingRegions(targeting, regional) ? 'Det saknas regioner' : null;
  };

  validateIncludesLinear = (targeting: Targeting, isMeMaCampaign: boolean): string => {
    if (targeting.includes_linear && !targeting.sales_order_number && !isMeMaCampaign) {
      return 'Det saknas obligatoriska fält: Salesordernummer';
    }
    return null;
  };

  validateRegionDistribution = (targeting: Targeting): string => {
    return this.validateDistribution(targeting.regional_share_type, targeting.regions, 'regionerna', targeting);
  };

  validateProgramDistribution = (targeting: Targeting): string => {
    return this.validateDistribution(
      targeting.program_format_share_type,
      targeting.program_formats,
      'programmen',
      targeting,
    );
  };

  validateAdvancedTargetGroups = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    return metaData.productChoice === ProductChoice.advancedTargetGroup && !targeting.advanced_target_groups.length
      ? 'Det saknas avancerad målgrupp'
      : null;
  };

  validateAddonTargetingAdvancedTargetGroups = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    return metaData.addonTargeting === ProductChoice.advancedTargetGroup && !targeting.advanced_target_groups.length
      ? 'Det saknas avancerad målgrupp som Tilläggsstyrning'
      : null;
  };

  validateTargetGroups = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    const shouldHaveTargetGroups = metaData ? metaData.productChoice === ProductChoice.targetGroup : false;
    const targetGroupChoices = metaData ? metaData.targetGroupChoices : { gender: false, age: false };
    return this.missingTargetGroups(targeting, shouldHaveTargetGroups, targetGroupChoices)
      ? 'Det saknas målgrupp'
      : null;
  };

  validateAddonTargetingTargetGroups = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    const shouldHaveTargetGroups = metaData ? metaData.addonTargeting === ProductChoice.targetGroup : false;
    const targetGroupChoices = metaData ? metaData.targetGroupChoices : { gender: false, age: false };
    return this.missingTargetGroups(targeting, shouldHaveTargetGroups, targetGroupChoices)
      ? 'Det saknas målgrupp som Tilläggsstyrning'
      : null;
  };

  validatePrograms = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    return metaData.productChoice === ProductChoice.program && !targeting.program_formats.length
      ? 'Det saknas program'
      : null;
  };

  validateCategories = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    return metaData.productChoice === ProductChoice.category && !targeting.categories.length
      ? 'Det saknas kategorier'
      : null;
  };

  validateDayparts = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    return metaData.productChoice === ProductChoice.daypart && !targeting.dayparts.length
      ? 'Det saknas dayparts'
      : null;
  };

  validateDeviceGroups = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    return metaData.productChoice === ProductChoice.device && !targeting.device_groups.length
      ? 'Det saknas apparater'
      : null;
  };

  validateAddonTargetingDeviceGroups = (targeting: Targeting, metaData: BookingTargetingMetaData): string => {
    return metaData.addonTargeting === ProductChoice.device && !targeting.device_groups.length
      ? 'Det saknas apparater som Tilläggsstyrning'
      : null;
  };

  validateAdditionalBudget = (targeting: Targeting, metaData: BookingTargetingMetaData): string[] => {
    const shouldHaveAdditionalBudget = metaData ? metaData.adminChoices.additionalBudget : false;

    if (!shouldHaveAdditionalBudget) {
      return null;
    }

    const fields = new Map([
      targeting.calculate_from_budget
        ? ['additional_budget', 'Extra budget']
        : ['additional_budgeted_impressions', 'Extra budget'],
      ['additional_budget_message', 'Extra budget-meddelande'],
    ]);

    const missingFields = this.missingFields(targeting, Array.from(fields.keys()));
    return missingFields.length === 0
      ? null
      : [this.missingFieldsLabel(), missingFields.map((field) => fields.get(field)).join(', ')];
  };

  validateNoInvoices = (targeting: Targeting, isTTVCampaign: boolean, isMeMaCampaign: boolean): string[] => {
    if (targeting.includes_linear || targeting.send_invoices || isTTVCampaign || isMeMaCampaign) {
      return null;
    }

    const fields = new Map([
      ['sales_order_number', 'Salesorder-nummer'],
      ['invoice_disable_message', 'Ingen faktura-meddelande'],
    ]);

    const missingFields = this.missingFields(targeting, Array.from(fields.keys()));
    return missingFields.length === 0
      ? null
      : [this.missingFieldsLabel(), missingFields.map((field) => fields.get(field)).join(', ')];
  };

  validateGamblingCustomerRestrictions = (targeting: Targeting, isGambling: boolean): string | null => {
    if (!isGambling) {
      return null;
    }

    if (targeting.age_target_groups.some((ageTargetGroup) => ageTargetGroup.min_age < 18)) {
      return 'Det är inte tillåtet för spelbolag att styra mot ålder under 18';
    }
    return null;
  };

  private filterShortFormatCreativeLength = (campaign: Campaign): boolean => {
    return campaign.targetings
      .filter((t) => this.productFormatService.targetingIsShortform(t))
      .flatMap((t) => t.creatives)
      .some((c) => c.length > VALID_VIDEO_LENGTHS.maxLimitShortFormat);
  };

  missingShortFormInstreamCreativeOnCombination = (campaign: Campaign): boolean => {
    return campaign.targetings
      .filter(
        (t) => this.productFormatService.targetingIsCombination(t) && t.view_currency !== ViewCurrency.impressions,
      )
      .some((t) => this.missingShortCreative(t.creatives));
  };

  private missingFields = (obj: any, fields: string[]): string[] => {
    return fields.filter((field) => !Boolean(obj[field]));
  };

  private missingRegions = (targeting: Targeting, regional: boolean): boolean => {
    return regional && !atLeastOneItem(targeting.regions);
  };

  private missingShortCreative = (creatives: Creative[]): boolean => {
    return !creatives.some((c) => c.length <= VALID_VIDEO_LENGTHS.maxLimitShortFormat);
  };

  private missingTargetGroups = (
    targeting: Targeting,
    shouldHaveTargetGroups: boolean,
    targetGroupChoices: TargetGroupChoices,
  ): boolean => {
    return (
      shouldHaveTargetGroups &&
      !validTargetGroupSelect(
        { genders: targeting.gender_target_groups, ages: targeting.age_target_groups },
        targetGroupChoices,
      )
    );
  };

  private missingFieldsLabel = () => {
    return 'Det saknas obligatoriska fält:';
  };

  private validateDistribution = (
    shareType: ShareType,
    items: Array<TargetingRegion | TargetingProgram>,
    pluralDefiniteItemName: string,
    targeting: Targeting,
  ): string => {
    if (shareType === ShareType.WOO) {
      return null;
    }

    const isBudgetShare = shareType === ShareType.Budget;
    const expectedDistribution = isBudgetShare
      ? targeting.budget + targeting.additional_budget
      : targeting.budgeted_impressions + targeting.additional_budgeted_impressions;
    const values = items.map((r) => (isBudgetShare ? r.budget : r.impressions));
    const distributionSum = values.reduce((acc, amount) => acc + amount, 0);
    const allValuesPresent = values.every((v) => v > 0);

    if (!allValuesPresent) {
      return `Minst en av ${pluralDefiniteItemName} saknar budget`;
    } else if (distributionSum !== expectedDistribution) {
      return `Fördelningen mellan ${pluralDefiniteItemName} summerar inte till budget`;
    } else {
      return null;
    }
  };

  private validateBudget(targeting: Targeting): string | null {
    const getNumberOrNaN = (v?: number | null) => (Number.isFinite(v) ? v : NaN);

    const value = targeting.calculate_from_budget
      ? getNumberOrNaN(targeting.budget) + (targeting.additional_budget || 0)
      : getNumberOrNaN(targeting.budgeted_impressions) + (targeting.additional_budgeted_impressions || 0);
    return value > 0 ? null : 'Budget';
  }

  private quotasEqual(originalQ: CreativeLengthQuota, newQ: CreativeLengthQuota): boolean {
    if (originalQ.size !== newQ.size) {
      return false;
    }
    let testValue;

    return Array.from(originalQ.entries()).every(([key, value]) => {
      testValue = newQ.get(key);
      return (
        testValue !== undefined &&
        testValue.budget === value.budget &&
        testValue.budgetDecimals === value.budgetDecimals
      );
    });
  }
}

export const VALID_VIDEO_LENGTHS = {
  maxLimitShortFormat: 20,
};
