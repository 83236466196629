import { Component } from '@angular/core';

@Component({
  selector: 'invoice-management',
  templateUrl: './invoice-management.component.html',
})
export class InvoiceManagement {
  tabVisible = Tabs.Attest;

  Tabs = Tabs;
}

enum Tabs {
  Attest = 'Attest',
  Overview = 'Fakturor',
  Tools = 'Fakturaverktyg',
}
