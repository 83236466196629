import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { DURATIONS } from '../utils/time-constants';
import { EnvironmentService } from './EnvironmentService';

@Injectable({ providedIn: 'root' })
export class JobService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  public async waitForJobCompletion(
    jobId: string,
    progressCallback: ProgressCallback = null,
    wait = 100,
  ): Promise<any> {
    const job = (await new Promise((resolve) =>
      setTimeout(() => {
        resolve(this.getJob(jobId));
      }, wait),
    )) as Job;
    return this.handleJobProgress(job, progressCallback, wait);
  }

  private getJob(jobId: string): Promise<Job> {
    return lastValueFrom(this.http.get<Job>(`${this.env.apiUrl}/jobs/${jobId}`));
  }

  private handleJobProgress(job: Job, progressCallback: ProgressCallback, wait: number): Promise<any> {
    if (progressCallback) {
      progressCallback(job.progress, job.progress_message);
    }
    switch (job.status) {
      case JobStatus.failed:
        return Promise.reject(job.result);
      case JobStatus.success:
        return Promise.resolve(job.result);
      case JobStatus.enqueued:
      case JobStatus.running:
        return this.waitForJobCompletion(
          job.id,
          progressCallback,
          this.exponentialBackoff(wait, Boolean(progressCallback)),
        );
    }
  }

  private exponentialBackoff(lastBackoff: number, withProgress: boolean) {
    return withProgress
      ? Math.min(lastBackoff * 2, DURATIONS.oneSecond)
      : Math.min(lastBackoff * 2, DURATIONS.oneMinute);
  }
}

export interface Job {
  id: string;
  status: JobStatus;
  progress: number;
  progress_message: string;
  result: any;
}

const enum JobStatus {
  failed = 'failed',
  success = 'success',
  enqueued = 'enqueued',
  running = 'running',
}

export type ProgressCallback = (progress: number, progress_message: string) => void;
