import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  endOfDay,
  format,
  getISOWeek,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { inPeriod, isDateAfter, isDateBefore, MAX_DATE, MIN_DATE } from '../utils/date';
import { EnvironmentService } from './EnvironmentService';
import { InventoryWeeks } from './InventoryService';
import { DateSegment, Period, Program, ProgramSegment, ProgramShares, wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class ProgramFormatService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getProgramFormats(): Promise<Program[]> {
    return lastValueFrom(this.http.get<Program[]>(`${this.env.apiUrl}/program_formats`));
  }

  getActiveProgramFormats(): Promise<Program[]> {
    return lastValueFrom(
      this.http.get<Program[]>(`${this.env.apiUrl}/program_formats`, {
        params: { active: 'true' },
      }),
    );
  }

  addProgramFormat(programParams: ProgramFormatParams, shares: ProgramShares[]): Promise<Program> {
    return lastValueFrom(
      this.http.post<Program>(`${this.env.apiUrl}/program_formats`, {
        program_format: programParams,
        product_format_program_shares: shares,
      }),
    );
  }

  updateProgramFormat(programParams: ProgramFormatParams, shares: ProgramShares[]): Promise<Program> {
    return lastValueFrom(
      this.http.put<Program>(`${this.env.apiUrl}/program_formats/${programParams.id}`, {
        program_format: programParams,
        product_format_program_shares: shares,
      }),
    );
  }

  filterUpcomingAndActiveFormats = (programFormats: Program[], targetDate: Date | string = new Date()): Program[] => {
    const target = typeof targetDate === 'string' ? parseISO(targetDate) : targetDate;

    return programFormats.filter((format) => {
      return format.periods.some((period) => {
        const end = typeof period.end_date === 'string' ? parseISO(period.end_date) : period.end_date;
        const endWeek = startOfWeek(end, { weekStartsOn: 1 });
        const startWeek = startOfWeek(target, { weekStartsOn: 1 });
        return isAfter(end, startWeek) || isEqual(endWeek, startWeek);
      });
    });
  };

  getPeriodFromFormats = (programFormats: Program[]): InventoryWeeks => {
    const formatDates = this.getFormatsStartAndEndDate(programFormats);
    return this.getPeriodFromDates(formatDates);
  };

  getFormatsStartAndEndDate = (programFormats: Program[], targetDate: string | Date = new Date()): Period => {
    let earliestStartDate: Date = typeof MAX_DATE === 'string' ? parseISO(MAX_DATE) : MAX_DATE;
    let latestEndDate: Date = typeof MIN_DATE === 'string' ? parseISO(MIN_DATE) : MIN_DATE;
    programFormats.forEach((format) => {
      format.periods.forEach((period) => {
        const start = typeof period.start_date === 'string' ? parseISO(period.start_date) : period.start_date;
        const end = typeof period.end_date === 'string' ? parseISO(period.end_date) : period.end_date;

        if (isBefore(start, earliestStartDate)) {
          earliestStartDate = start;
        }
        if (isAfter(end, latestEndDate)) {
          latestEndDate = end;
        }
      });
    });
    const parsedTargetDate = typeof targetDate === 'string' ? parseISO(targetDate) : targetDate;
    if (isBefore(earliestStartDate, parsedTargetDate)) {
      earliestStartDate = parsedTargetDate;
    }

    const formattedStart = format(startOfDay(earliestStartDate), "yyyy-MM-dd'T'HH:mm:ssxxx").replace('+00:00', 'Z');
    const formattedEnd = format(endOfDay(latestEndDate), "yyyy-MM-dd'T'HH:mm:ssxxx").replace('+00:00', 'Z');

    return {
      start: formattedStart,
      end: formattedEnd,
    };
  };

  /**
   * @returns The active program period at the given time.
   */
  activePeriod = (program: Program, period: DateSegment): ProgramSegment | undefined => {
    return program.periods.find((p) => {
      const start = new Date(p.start_date);
      const end = new Date(p.end_date);
      return (
        inPeriod(start, end, period.start_date) ||
        inPeriod(start, end, period.end_date) ||
        (isDateBefore(start, period.end_date) && isDateAfter(end, period.start_date))
      );
    });
  };

  activePeriods = (program: Program, period: DateSegment): ProgramSegment[] | undefined => {
    return program.periods.filter((p) => {
      const start = new Date(p.start_date);
      const end = new Date(p.end_date);
      return (
        inPeriod(start, end, period.start_date) ||
        inPeriod(start, end, period.end_date) ||
        (isDateBefore(start, period.end_date) && isDateAfter(end, period.start_date))
      );
    });
  };

  private getPeriodFromDates = (formatDates: Period): InventoryWeeks => {
    const start = typeof formatDates.start === 'string' ? parseISO(formatDates.start) : formatDates.start;
    const end = typeof formatDates.end === 'string' ? parseISO(formatDates.end) : formatDates.end;

    return {
      startWeekNumber: getISOWeek(start),
      endWeekNumber: getISOWeek(end),
      startWeekYear: getYear(start),
      endWeekYear: getYear(end),
    };
  };
}

export interface ProgramFormatParams {
  name: string;
  publisher_id: wooId;
  active: boolean;
  mrm_id: string;
  id?: wooId;
  category_id?: wooId;
}
