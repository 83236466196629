<div class="block">

  <h1>Kontohantering</h1>
  <div>
    <button class="tabbable no-outline" *ngIf="hasAccessToTab(Tab.AgencyCustomers)"
      (click)="setTab(Tab.AgencyCustomers)" [ngClass]="{active : tabVisible === Tab.AgencyCustomers}">
      Byråkunder
    </button>
    <button class="tabbable no-outline" *ngIf="hasAccessToTab(Tab.Agencies)"
      (click)="setTab(Tab.Agencies)" [ngClass]="{active : tabVisible === Tab.Agencies}">
      Byråer
    </button>
    <button class="tabbable no-outline" *ngIf="hasAccessToTab(Tab.DirectCustomers)"
      (click)="setTab(Tab.DirectCustomers)" [ngClass]="{active : tabVisible === Tab.DirectCustomers}">
      Direktkunder
    </button>
    <button class="tabbable no-outline" *ngIf="hasAccessToTab(Tab.CashCustomers) && cashTabVisible"
      (click)="setTab(Tab.CashCustomers)" [ngClass]="{active : tabVisible === Tab.CashCustomers}">
      Cashkunder
    </button>
    <button class="tabbable no-outline" *ngIf="hasAccessToTab(Tab.Users)"
      (click)="setTab(Tab.Users)" [ngClass]="{active : tabVisible === Tab.Users}">
      WOO-användare
    </button>
    <button [routerLink]="['/accounts', 'new']" class="tabbable no-outline right" *ngIf="isAdminOrExternalPlanner">
      <span class="icon-plus icon__small"></span>
    </button>
    <div class="clearfix"></div>

  </div>
  <div class="relative">

    <div *ngIf="hasAccessToTab(Tab.Agencies) && tabVisible === Tab.Agencies">
      <div class="tabbable__panel clearfix shadow-z-1">
        <div class="col-xs-12">
          <h2>Byråer</h2>
        </div>
      </div>
      <div class="tabbable__body clearfix shadow-z-1">
        <dot-loader [visibility]="!hasLoaded.agencies" description="Hämtar byråer"></dot-loader>
        <name-list [objects]="agencies" routePath="agency" [showNicknames]="show.nicknames"></name-list>
      </div>
    </div>

    <div *ngIf="hasAccessToTab(Tab.AgencyCustomers) && tabVisible === Tab.AgencyCustomers">
      <div class="tabbable__panel clearfix shadow-z-1">
        <div class="col-xs-12">
          <h2>Byråkunder</h2>
        </div>
      </div>
      <div class="tabbable__body clearfix shadow-z-1">
        <dot-loader [visibility]="!hasLoaded.agencyCustomers" description="Hämtar kunder"></dot-loader>
        <name-list [objects]="agencyCustomers" [inactiveObjects]="inactiveAgencyCustomers" routePath="customer"
          [showNicknames]="show.nicknames"></name-list>
      </div>
    </div>

    <div *ngIf="hasAccessToTab(Tab.DirectCustomers) && tabVisible === Tab.DirectCustomers">
      <div class="tabbable__panel clearfix shadow-z-1">
        <div class="col-xs-12">
          <h2>Direktkunder</h2>
        </div>
      </div>
      <div class="tabbable__body clearfix shadow-z-1">
        <dot-loader [visibility]="!hasLoaded.clients" description="Hämtar kunder"></dot-loader>
        <name-list [objects]="clients" routePath="customer" [showNicknames]="show.nicknames"></name-list>
      </div>
    </div>

    <div *ngIf="hasAccessToTab(Tab.CashCustomers) && tabVisible === Tab.CashCustomers">
      <div class="tabbable__panel clearfix shadow-z-1">
        <div class="col-xs-12">
          <h2>Cashkunder</h2>
        </div>
      </div>
      <div class="tabbable__body clearfix shadow-z-1">
        <dot-loader [visibility]="!hasLoaded.cashCustomers" description="Hämtar kunder"></dot-loader>
        <name-list [objects]="cashCustomers" routePath="customer" [showNicknames]="show.nicknames"></name-list>
      </div>
    </div>

    <div *ngIf="hasAccessToTab(Tab.Users) && tabVisible === Tab.Users">
      <div class="tabbable__panel shadow-z-1 clearfix">
        <div class="col-xs-12">
          <h2>WOO-användare</h2>
        </div>
      </div>
      <div class="tabbable__body clearfix shadow-z-1">
        <dot-loader [visibility]="!hasLoaded.users" description="Hämtar användare"></dot-loader>
        <user-list [activeUsers]="activeUsers" [inactiveUsers]="inactiveUsers"></user-list>
      </div>
    </div>
  </div>
</div>
