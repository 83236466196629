<div class="block">

  <h1 class="text--dark-gray">Produkthantering</h1>
  <div #inventoryNav></div>
  <div *ngIf="inventoryVisible" [ngClass]="{'sticky-section on-top-background': tabRowSelected('inventory')}">
    <h2>Lager</h2>

    <div class="margin-top--3x">
      <span *ngFor="let tab of inventoryTabs; let index = index; trackBy: trackById">
        <button
          class="tabbable no-outline"
          *ngIf="tab.visible"
          (click)="setTab(tab.id, inventoryNav)"
          [ngClass]="{active : activeTabId === tab.id}"
          tabindex="{{350 + index * 10}}"
        >{{tab.name}}</button>
      </span>
    </div>

    <div *ngIf="!tabRowSelected('inventory')">
      <div class="tabbable__panel rounded-top-right">
        <h3 class="margin-left--1x">Här kan du administrera lager</h3>
      </div>
  </div>
  </div>

  <div class="relative">
    <div *ngIf="activeTabId === Tab.agWithdrawalLimit">
      <manage-ag-withdrawal-limits [headerPos]="inventoryNav"></manage-ag-withdrawal-limits>
    </div>
    <div *ngIf="activeTabId === Tab.completionRates">
      <manage-completion-rates-estimates [headerPos]="inventoryNav"></manage-completion-rates-estimates>
    </div>
    <div *ngIf="activeTabId === Tab.frequencyLimits">
      <manage-frequency-limits [headerPos]="inventoryNav"></manage-frequency-limits>
    </div>
    <div *ngIf="activeTabId === Tab.gambling">
      <manage-gambling-estimates [headerPos]="inventoryNav"></manage-gambling-estimates>
    </div>
    <div *ngIf="activeTabId === Tab.pauseAd">
      <manage-pause-ad-estimates [headerPos]="inventoryNav"></manage-pause-ad-estimates>
    </div>
    <div *ngIf="activeTabId === Tab.productFormats">
      <manage-product-format-shares [headerPos]="inventoryNav"></manage-product-format-shares>
    </div>
    <div *ngIf="activeTabId === Tab.categories">
      <manage-categories [headerPos]="inventoryNav"></manage-categories>
    </div>
    <div *ngIf="activeTabId === Tab.grossRatingFactor">
      <manage-gross-rating-factor [headerPos]="inventoryNav"></manage-gross-rating-factor> 
    </div>
  </div>

  <div #targetingNav></div>
  <div [ngClass]="{'sticky-section on-top-background': tabRowSelected('targeting')}">
    <h2>Styrning</h2>

    <div class="margin-top--3x">
      <span *ngFor="let tab of targetingTabs; let index = index; trackBy: trackById">
        <button
          class="tabbable no-outline"
          *ngIf="tab.visible"
          (click)="setTab(tab.id, targetingNav)"
          [ngClass]="{active : activeTabId === tab.id}"
          tabindex="{{350 + index * 10}}"
        >{{tab.name}}</button>
      </span>
    </div>

    <div *ngIf="!tabRowSelected('targeting')">
      <div class="tabbable__panel rounded-top-right">
        <h3 class="margin-left--1x">Här kan du administrera Styrning</h3>
      </div>
    </div>
  </div>

  <div class="relative">
    <div *ngIf="activeTabId === Tab.advancedTargetGroups">
      <manage-advanced-target-groups [headerPos]="targetingNav"></manage-advanced-target-groups>
    </div>
    <div *ngIf="activeTabId === Tab.behaviors">
      <manage-behaviors [headerPos]="targetingNav"></manage-behaviors>
    </div>
    <div *ngIf="activeTabId === Tab.dayparts">
      <manage-dayparts [headerPos]="targetingNav"></manage-dayparts>
    </div>
    <div *ngIf="activeTabId === Tab.deviceGroups">
      <manage-device-groups [headerPos]="targetingNav"></manage-device-groups>
    </div>
    <div *ngIf="activeTabId === Tab.programFormats">
      <manage-program-formats [headerPos]="targetingNav"></manage-program-formats>
    </div>
    <div *ngIf="activeTabId === Tab.regions">
      <regions [headerPos]="targetingNav"></regions>
    </div>
    <div *ngIf="activeTabId === Tab.targetGroups">
      <manage-target-groups [headerPos]="targetingNav"></manage-target-groups>
    </div>
    <div *ngIf="activeTabId === Tab.ttv">
      <manage-ttv-targeting-mapping [headerPos]="targetingNav"></manage-ttv-targeting-mapping>
    </div>
  </div>

  <div #miscNav></div>
  <div [ngClass]="{'sticky-section on-top-background': tabRowSelected('misc')}">
    <h2>Övrigt</h2>

    <div class="margin-top--3x">
      <span *ngFor="let tab of miscTabs; let index = index; trackBy: trackById">
        <button
          class="tabbable no-outline"
          *ngIf="tab.visible"
          (click)="setTab(tab.id, miscNav)"
          [ngClass]="{active : activeTabId === tab.id}"
          tabindex="{{350 + index * 10}}"
        >{{tab.name}}</button>
      </span>
    </div>

    <div *ngIf="!tabRowSelected('misc')">
      <div class="tabbable__panel rounded-top-right">
        <h3 class="margin-left--1x">Här kan du administrera Övrigt</h3>
      </div>
    </div>
  </div>

  <div class="relative">
    <div *ngIf="activeTabId === Tab.devices">
      <manage-devices [headerPos]="miscNav"></manage-devices>
    </div>
    <div *ngIf="activeTabId === Tab.priceLists">
      <manage-pricelists [headerPos]="miscNav"></manage-pricelists>
    </div>
    <div *ngIf="activeTabId === Tab.universe">
      <manage-universe [headerPos]="miscNav"></manage-universe>
    </div>
    <div *ngIf="activeTabId === Tab.defaultFrequencyLimit">
      <manage-default-frequency-limits [headerPos]="miscNav"></manage-default-frequency-limits>
    </div>
  </div>
</div>
