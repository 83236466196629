<p class="text--large">
    Placering
    <info-badge gaName="productFormat">
      Här kan du välja på vilken typ av innehåll du vill att din kampanj ska synas. 
      Vid val Adresserbar TV syns kampanjen på fullängdsprogram och vid Online video på klipp från våra program och siter. 
      Väljer du istället Kombination kan din kampanj synas på båda typerna av innehåll
    </info-badge>
  </p>

  <div class="input__container display--inline-block margin-right--4x" *ngIf="showLongForm">
    <input class="radio" id="long-{{instanceId}}" type="radio" name="content-type-{{instanceId}}"
      [(ngModel)]="selectedProductFormat"
      (ngModelChange)="updateProductFormat()" [value]="ProductFormats.longForm" [disabled]="disableLongform(targetingId)">
    <label class="radio" for="long-{{instanceId}}">
      Adresserbar TV
    </label>
  </div>

  <div class="input__container display--inline-block margin-right--4x" *ngIf="showShortForm">
    <input class="radio" id="short-{{instanceId}}" type="radio" name="content-type-{{instanceId}}"
      [(ngModel)]="selectedProductFormat"
      (ngModelChange)="updateProductFormat()" [value]="ProductFormats.shortForm" [disabled]="disableShortform(targetingId)">
    <label class="radio" for="short-{{instanceId}}">
      Online Video
    </label>
  </div>

  <div class="input__container display--inline-block" *ngIf="showLongForm && showShortForm">
    <input class="radio" id="digital-{{instanceId}}" type="radio" name="content-type-{{instanceId}}"
      [(ngModel)]="selectedProductFormat"
      (ngModelChange)="updateProductFormat()" [value]="ProductFormats.combination" [disabled]="disableCombination(targetingId)">
    <label class="radio" for="digital-{{instanceId}}">
      Kombination
    </label>
  </div>

  <span *ngFor="let message of productFormatMessage" class="text--gray" [ngClass]="{'text--cta': message.warningMessage, 'text--gray': !message.warningMessage}">
    <p>{{message.message}}</p>
  </span>

  <div class="divider medium margin-top--3x margin-bottom--1x"></div>
