<div class="radio-box__container">
  <targeting-radio-box
    class="radio-box__item"
    [headline]="'Ingen styrning'"
    [text]="'Visningarna kommer distribureras baserat på där det finns en ledig plats.'"
    [model]="selected"
    (modelChange)="targetingChange($event)"
    [value]="TYPES.noTargeting"
  ></targeting-radio-box>

  <targeting-radio-box
    #regionRadioBox
    class="radio-box__item"
    [headline]="'Geografiskt område'"
    [text]="'Välj inom vilket geografiskt område du vill att kampanjen ska visas.'"
    [model]="selected"
    (modelChange)="targetingChange($event)"
    [value]="TYPES.regions"
  >
    <validated-region-select
      [@verticalExpansion]
      (@verticalExpansion.done)="scrollTo(regionRadioBox)"
      *ngIf="selected === TYPES.regions"
      class="visible-xs margin-top--2x"
      [regions]="regions"
      (modelAndValidityChange)="regionsChange($event)"
      [model]="model.regions"
    ></validated-region-select>
  </targeting-radio-box>

  <targeting-radio-box
    #targetGroupRadioBox
    class="radio-box__item"
    [headline]="'Målgrupp'"
    [text]="'Välj vilken målgrupp du vill visa din kampanj för. Du kan välja att begränsa den på kön och/eller ålder.'"
    [model]="selected"
    (modelChange)="targetingChange($event)"
    [value]="TYPES.targetGroup"
  >

    <validated-target-group-select
      [@verticalExpansion]
      (@verticalExpansion.done)="scrollTo(targetGroupRadioBox)"
      *ngIf="selected === TYPES.targetGroup"
      class="visible-xs margin-top--2x"
      [genders]="genders"
      [ages]="ages"
      [model]="model.targetGroups"
      (modelAndValidityChange)="targetGroupChange($event)"
      [(choices)]="choices"
    ></validated-target-group-select>
  </targeting-radio-box>
</div>

<div id="none-mobile-selector-container" class="hidden-xs margin-top--2x">
  <no-targeting-select
    *ngIf="selected === TYPES.noTargeting"
    class="display--block targeting-block"
    (noTargetingChange)="noTargetingChange($event)"
  ></no-targeting-select>

  <div *ngIf="selected === TYPES.regions" class="targeting-block">
    <p>Välj inom vilket område du vill att kampanjen skall visas.</p>
    <validated-region-select
      [regions]="regions"
      (modelAndValidityChange)="regionsChange($event)"
      [model]="model.regions"
    ></validated-region-select>
  </div>

  <div *ngIf="selected === TYPES.publisherGroup" class="targeting-block">
    <p>Välj via vilken publicist som du vill visa din kampanj.</p>
    <publisher-group-select
      [publisherGroups]="publisherGroups"
      (modelAndValidityChange)="publisherGroupsChange($event)"
      [model]="model.publisherGroups"
    ></publisher-group-select>
  </div>

  <div *ngIf="selected === TYPES.targetGroup" class="targeting-block">
    <p class="width--66">Välj vilken målgrupp du vill visa din kampanj för, du kan välja att begränsa den på kön och/eller ålder.</p>
    <validated-target-group-select
      [genders]="genders"
      [ages]="ages"
      [model]="model.targetGroups"
      (modelAndValidityChange)="targetGroupChange($event)"
      [(choices)]="choices"
    ></validated-target-group-select>
  </div>
</div>
