import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SimpleChanges } from '../../../../utils/types';
import { ConfirmDialogContent } from '../../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { PriceList, PriceListService, ValidPriceListRow } from '../../../../woo_services.module/PriceListService';
import { PriceListBasePriceTypes, Publisher, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';

@Component({
  selector: 'edit-overwritten-price-row-container',
  template: `
    <table class="table table--woo table--hover table--light-red table--align--middle">
      <thead>
        <tr>
          <th *ngFor="let header of tableHeaders" class="capitalize--first-letter">
            {{ header }}
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let row of priceListRows; let index = index">
          <tr [hidden]="!rowVisible(row)">
            <td>
              {{ getPublisherName(row.publisher_id) }}
            </td>
            <td>
              {{ translateBaseProduct(row.product_format) }}
            </td>
            <td>
              {{ translateTargetingProduct(row.targeting_type) }}
            </td>
            <td>
              {{ translateTargetingProduct(row.addon_targeting) }}
            </td>
            <td>
              {{ translateLocality(row.locality_type) }}
            </td>
            <td>
              <p class="display--inline-block text--semi-strong margin--none">{{ row.calculated_price }}</p>
              {{ getTypeOfCost(row.product_format) }}
            </td>
            <td>
              <div class="input__container">
                <input
                  type="text"
                  name="{{ row.price_row_key }}"
                  (ngModelChange)="change(row, $event)"
                  [(ngModel)]="overwrittenPrices[row.price_row_key]"
                />
              </div>
            </td>
            <td>
              {{ getTypeOfCost(row.product_format) }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  `,
})
export class EditOverwrittenPriceRowContainer implements OnChanges {
  readonly tableHeaders = [
    'Publicist',
    'Placering',
    'Styrning',
    'Tilläggsstyrning',
    'Geografi',
    'Beräknat Pris',
    'Nytt Pris',
    '',
  ];
  readonly translateBaseProduct = this.translationService.convertProductFormat;
  readonly translateTargetingProduct = this.translationService.convertProductChoice;
  readonly translateLocality = this.translationService.convertLocality;

  @Input() publishers: Publisher[];
  @Input() priceListRows: ValidPriceListRow[];
  @Input() priceList: PriceList;
  @Input() selectedPublisher: string;
  @Input() selectedProductFormat: string;
  @Input() selectedTargetingType: string;
  @Input() selectedLocalityType: string;
  @Output() onOverwrittenPricesUpdated = new EventEmitter<void>();

  overwrittenPrices: Record<string, number | null> = {};

  constructor(
    private translationService: TranslationService,
    private priceListService: PriceListService,
    private dialogService: DialogService,
  ) {}

  ngOnChanges(changes: SimpleChanges<EditOverwrittenPriceRowContainer>): void {
    if (changes.priceListRows) {
      this.overwrittenPrices = this.priceListRows.reduce((overwrittenPrices, row: ValidPriceListRow) => {
        overwrittenPrices[row.price_row_key] = row.over_written_price;
        return overwrittenPrices;
      }, {} as Record<string, number | null>);
    }
  }

  getTypeOfCost(baseProductName: string): string {
    if (['shortform', 'pause'].some((product) => baseProductName.includes(product))) return 'CPM';
    return `${this.priceListService.translatePriceListCurrencyShort(this.priceList.price_list_currency)}`;
  }

  getPublisherName(publisherId: wooId): string {
    return this.publishers.find((publisher) => publisher.id === publisherId).name;
  }

  rowVisible(row: ValidPriceListRow): boolean {
    if (row.product_format === PriceListBasePriceTypes.includes_linear) return false;
    if (this.selectedPublisher && row.publisher_id !== this.selectedPublisher) return false;
    if (this.selectedProductFormat && row.product_format !== this.selectedProductFormat) return false;
    if (
      this.selectedTargetingType &&
      !(row.targeting_type === this.selectedTargetingType || row.addon_targeting === this.selectedTargetingType)
    ) {
      return false;
    }
    if (this.selectedLocalityType && row.locality_type !== this.selectedLocalityType) return false;
    return true;
  }

  change(row: ValidPriceListRow, new_price: string): void {
    let value = parseInt(new_price);
    if (Number.isNaN(value) || value < 1 || value === row.calculated_price) {
      value = null;
    }
    this.overwrittenPrices[row.price_row_key] = value;
  }

  save(): void {
    this.dialogService
      .openConfirm(this.saveOverwrittenPricesDialogContent())
      .then(() => {
        this.priceListService
          .updateOverwritenPrices(this.priceList.id, this.overwrittenPrices)
          .then(() => {
            this.onOverwrittenPricesUpdated.emit();
          })
          .catch(() => null);
      })
      .catch(() => null);
  }

  private saveOverwrittenPricesDialogContent(): ConfirmDialogContent {
    const textBlocks = new Array<string>();
    textBlocks.push('Följande priser kommer skrivas över');
    this.priceListRows.forEach((row: ValidPriceListRow) => {
      if (!this.overwrittenPrices[row.price_row_key]) return;

      textBlocks.push(this.priceRowUpdateText(row));
    });

    return {
      header: 'Skriv över pris',
      textBlocks: textBlocks,
      confirmText: 'Ja, skriv över priserna',
      cancelText: 'Avbryt',
    };
  }

  private priceRowUpdateText(row: ValidPriceListRow): string {
    let retString = `${this.getPublisherName(row.publisher_id)}`;
    retString += `|${this.translateBaseProduct(row.product_format)}`;
    retString += `|${this.translateTargetingProduct(row.targeting_type)}`;
    if (row.addon_targeting) {
      retString += `|${this.translateTargetingProduct(row.addon_targeting)}`;
    }
    retString += `|${this.translateLocality(row.locality_type)}`;
    retString += ` (${row.over_written_price || row.calculated_price} => ${this.overwrittenPrices[row.price_row_key]})`;

    return retString;
  }
}
