import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ImageUpload } from './asset_upload/image-upload.component';
import { VideoUpload } from './asset_upload/video-upload.component';
import { InfoBadge } from './badges/info-badge.component';
import { NotificationBadge } from './badges/notification-badge.component';
import { WooLoad } from './buttons/directives/woo-load.directive';
import { RegionalIndicator } from './campaign_indicators/regional-indicator.component';
import { SendInvoicesIndicator } from './campaign_indicators/send-invoices-indicator.component';
import { CampaignMmsGainForm } from './campaign_mms_gain_form/campaign-mms-gain-form.component';
import { CampaignProgress } from './campaign_progress/campaign-progress';
import { CreativeSegmentsList } from './creative-segments-list.component';
import { CreativeDetailsRow } from './creative_details/creative-details-row.component';
import { CreativeDetails } from './creative_details/creative-details.component';
import { CreativesDetails } from './creatives_details/creatives-details.component';
import { CreativesList } from './creatives_list/creatives-list.component';
import { DashboardCard } from './dashboard_card/dashboard-card.component';
import { DashboardCardAdServerStatus } from './dashboard_card/dashboard_card_ad_server_status/dashboard-card-ad-server-status.component';
import { DashboardCardCampaignStatus } from './dashboard_card/dashboard_card_campaign_status/dashboard-card-campaign-status.component';
import { DashboardCardCreativeStatus } from './dashboard_card/dashboard_card_creative_status/dashboard-card-creative-status.component';
import { DateSelect } from './date_selectors/date_select/date-select.component';
import { PeriodSelect } from './date_selectors/period_select/period-select.component';
import { CalenderHeading } from './date_selectors/shared/calender-heading.component';
import { CalenderTopRow } from './date_selectors/shared/calender-top-row.component';
import { BlockingDialog } from './dialogs/blocking-dialog.component';
import { ConfirmDialog } from './dialogs/confirm-dialog.component';
import { ErrorDialog } from './dialogs/error-dialog.component';
import { FeedbackDialog } from './dialogs/feedback-dialog.component';
import { PreconditionErrorDialog } from './dialogs/precondition-error-dialog.component';
import { ProgressContent } from './dialogs/progress-content.compontent';
import { SuccessDialog } from './dialogs/success-dialog.component';
import { WooDialogContainer } from './dialogs/woo-dialog-container.component';
import { WooDialog } from './dialogs/woo-dialog.component';
import { CashVideoCodeValidator } from './directives/cash-video-code-validator.directive';
import { FixedWidth } from './directives/fixedWidth.directive';
import { MmsCodeValidator } from './directives/mms-code-validator.directive';
import { ToggleTab } from './directives/toggleTab';
import { WooCopyTableAsNumber } from './directives/woo-copy-table-as-numbers.directive';
import { WooDateFormatter } from './directives/woo-date-formatter.directive';
import { WooDateValidator } from './directives/woo-date-validator.directive';
import { WooFileDragClass } from './directives/woo-file-drag-class.directive';
import { WooFileDrop } from './directives/woo-file-drop.directive';
import { WooMaxValidator } from './directives/woo-max-validator.directive';
import { WooMinValidator } from './directives/woo-min-validator.directive';
import { WooNumberFormatter } from './directives/woo-number-formatter.directive';
import { WooPercentFormatter } from './directives/woo-percent-formatter.directive';
import { WooFocus } from './directives/wooFocus.directive';
import { AdHocContractList } from './discounts/ad_hoc_contracts/ad-hoc-contracts-list.component';
import { NewAdHocContract } from './discounts/ad_hoc_contracts/new-ad-hoc-contract.component';
import { ProductDiscountInput } from './discounts/ad_hoc_contracts/product-discount-input.component';
import { NewVoucher } from './discounts/vouchers/new-voucher.component';
import { VouchersList } from './discounts/vouchers/vouchers-list.component';
import { ContractDiscountsList } from './discounts/yearly_contracts/contract-discounts-list.component';
import { WooCustomerDropdown } from './dropdown/customer_dropdown/woo-customer-dropdown.component';
import { WooDropdown } from './dropdown/woo-dropdown.component';
import { ExpandableHeader } from './expandable_header/expandable-header.component';
import { ExpandableTargetingHeader } from './expandable_targeting_header/expandable-targeting-header.component';
import { ExtendedTargetingBudgetView } from './extended_targeting_budget/extended_targeting_budget_view.compontent';
import { ContextMessage } from './feedback/context-message.component';
import { FileUpload } from './file_upload/file-upload.component';
import { GoalAdditionalImpressionsForm } from './goal_additional_impressions_form/goal_additional_impressions_form/goal-additional-impressions-form.component';
import { CustomerAgencyRow } from './helpers/customer-agency-row.component';
import { DateSpan } from './helpers/date-span.component';
import { HeadingWithLine } from './helpers/heading-with-line.component';
import { NamePeriodHeader } from './helpers/name-period-header.component';
import { TwoColRow } from './helpers/two-col-row.component';
import { HistoryList } from './history_list/history-list.component';
import { ImportWarning } from './import_warning/import-warning.component';
import { AssetUrlInput } from './inputs/asset_url_input/asset-url-input.component';
import { AssetList } from './inputs/asset_url_input/asset_list/asset-list.component';
import { CashVideoCodeInput } from './inputs/cash-video-code-input.component';
import { InputErrors } from './inputs/input-errors.component';
import { InputErrorsDirective } from './inputs/input-errors.directive';
import { RegionMap } from './inputs/region-select/region-map.component';
import { RegionSelect } from './inputs/region-select/region-select.component';
import { Selector } from './inputs/selector/selector.component';
import { StringArrayInput } from './inputs/string_array_input/string-array-input.component';
import { TargetGroupSelect } from './inputs/target-group-select/target-group-select.component';
import { WooNumberInput } from './inputs/woo-number-input.componenet';
import { WooTextInput } from './inputs/woo-text-input.componenet';
import { InstreamCreativeForm } from './instream_creative_form/instream-creative-form.component';
import { InventoryFilter } from './inventory/inventory-filter/inventory-filter.component';
import { InventoryVisualization } from './inventory/inventory-visualization/inventory-visualization.component';
import { Loader } from './loader/loader.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PauseCreativeForm } from './pause_creative_form/pause-creative-form.component';
import { PdfViewer } from './pdf_viewer/pdf-viewer.component';
import { DashAsDefault } from './pipes/dash-as-default.pipe';
import { NullIfZero } from './pipes/null-if-zero.pipe';
import { WooCurrency } from './pipes/woo-currency.pipe';
import { WooDatePipe } from './pipes/woo-date.pipe';
import { WooTitleCasePipe } from './pipes/woo-title-case.pipe';
import { ShowPricelist } from './pricelist/show-pricelist.component';
import { ProgressBar } from './progress_bar/progress-bar.component';
import { RadioButtonBlock } from './radio_button_block/radio-button-block.component';
import { RadioButtonBox } from './radio_button_box/radio-button-box.component';
import { SegmentSelect } from './segment_select/segment-select.component';
import { Signup } from './signup/signup.component';
import { SimpleTargetingSummary } from './simple_targeting_summary/simple-targeting-summary.component';
import { DotLoader } from './spinner/dot-loader.component';
import { Spinner } from './spinner/spinner.component';
import { AdServerStatusBadge } from './status_badges/ad_server_status_badge/ad-server-status-badge.component';
import { CampaignStatusBadge } from './status_badges/campaign_status_badge/campaign-status-badge.component';
import { CreativeStatusBadge } from './status_badges/creative_status_badge/creative-status-badge.component';
import { CreativeStatusLabel } from './status_badges/creative_status_badge/creative-status-label.component';
import { SystemMessagesOverview } from './system_messages_overview/system-messages-overview.component';
import { TargetingLabel } from './targeting-label.component';
import { TargetingBox } from './targeting_box/targeting-box.component';
import { TargetingCheckbox } from './targeting_checkbox/targeting-checkbox.component';
import { TargetingSummary } from './targeting_summary/targeting-summary.component';
import { TagList } from './targeting_tags/tag-list.component';
import { TargetingTags } from './targeting_tags/targeting-tags.component';
import { EditButton } from './text_button/edit-button.component';
import { TextButton } from './text_button/text-button.component';
import { Toggle } from './toggle/toggle.component';
import { TTVBookings } from './ttv_bookings/ttv-bookings.component';
import { CampaignStatusLabel } from './utils/campaign-status-label.component';
import { SpecialCampaignLabel } from './utils/special-campaign-label.component';
import { TwoColTable } from './woo_tables/two-col-table.component';
import { WooTable } from './woo_tables/woo-table.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    NgCircleProgressModule.forRoot(),
    NgSelectModule,
    NgxSliderModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    AdHocContractList,
    AdServerStatusBadge,
    AssetList,
    AssetUrlInput,
    BlockingDialog,
    CalenderHeading,
    CalenderTopRow,
    CampaignMmsGainForm,
    CampaignProgress,
    CampaignStatusBadge,
    CampaignStatusLabel,
    CashVideoCodeInput,
    CashVideoCodeValidator,
    ConfirmDialog,
    ContextMessage,
    ContractDiscountsList,
    CreativeDetails,
    CreativeDetailsRow,
    CreativesDetails,
    CreativeSegmentsList,
    CreativesList,
    CreativeStatusLabel,
    CreativeStatusBadge,
    CustomerAgencyRow,
    DashAsDefault,
    DashboardCard,
    DashboardCardAdServerStatus,
    DashboardCardCampaignStatus,
    DashboardCardCreativeStatus,
    DateSelect,
    DateSpan,
    EditButton,
    ErrorDialog,
    ExpandableHeader,
    ExpandableTargetingHeader,
    ExtendedTargetingBudgetView,
    FeedbackDialog,
    FileUpload,
    GoalAdditionalImpressionsForm,
    HeadingWithLine,
    HistoryList,
    ImageUpload,
    ImportWarning,
    RegionalIndicator,
    InfoBadge,
    InputErrors,
    InputErrorsDirective,
    InstreamCreativeForm,
    InfoBadge,
    InventoryVisualization,
    Loader,
    MmsCodeValidator,
    NamePeriodHeader,
    NewAdHocContract,
    InventoryFilter,
    FixedWidth,
    NewVoucher,
    NotificationBadge,
    NullIfZero,
    PaginationComponent,
    PauseCreativeForm,
    PdfViewer,
    PeriodSelect,
    PreconditionErrorDialog,
    ProductDiscountInput,
    ProgressContent,
    ProgressBar,
    DotLoader,
    RadioButtonBlock,
    RadioButtonBox,
    RegionMap,
    RegionSelect,
    SegmentSelect,
    Selector,
    SendInvoicesIndicator,
    ShowPricelist,
    Signup,
    SimpleTargetingSummary,
    SpecialCampaignLabel,
    Spinner,
    StringArrayInput,
    SuccessDialog,
    SystemMessagesOverview,
    TagList,
    TargetGroupSelect,
    TargetingBox,
    TargetingCheckbox,
    TargetingLabel,
    TargetingSummary,
    TargetingTags,
    TextButton,
    Toggle,
    ToggleTab,
    TTVBookings,
    TwoColRow,
    TwoColTable,
    VideoUpload,
    VouchersList,
    WooCopyTableAsNumber,
    WooCurrency,
    WooCustomerDropdown,
    WooDateFormatter,
    WooDatePipe,
    WooDateValidator,
    WooDialog,
    WooDialogContainer,
    WooDropdown,
    WooFileDragClass,
    WooFileDrop,
    WooFocus,
    WooLoad,
    WooMaxValidator,
    WooMinValidator,
    WooNumberFormatter,
    WooNumberInput,
    WooPercentFormatter,
    WooTable,
    WooTextInput,
    WooTitleCasePipe,
  ],
  exports: [
    AdHocContractList,
    AdServerStatusBadge,
    AssetList,
    AssetUrlInput,
    BlockingDialog,
    CampaignMmsGainForm,
    CampaignProgress,
    CampaignStatusBadge,
    CampaignStatusLabel,
    CashVideoCodeInput,
    CashVideoCodeValidator,
    ConfirmDialog,
    ContextMessage,
    ContractDiscountsList,
    CreativesDetails,
    CreativeSegmentsList,
    CreativesList,
    CreativeStatusBadge,
    CreativeStatusLabel,
    CustomerAgencyRow,
    DashAsDefault,
    DashboardCard,
    DateSelect,
    DateSpan,
    EditButton,
    ErrorDialog,
    ExpandableHeader,
    ExpandableTargetingHeader,
    ExtendedTargetingBudgetView,
    FileUpload,
    GoalAdditionalImpressionsForm,
    HeadingWithLine,
    HistoryList,
    ImageUpload,
    ImportWarning,
    RegionalIndicator,
    InfoBadge,
    InputErrors,
    InputErrorsDirective,
    InstreamCreativeForm,
    InventoryVisualization,
    Loader,
    MmsCodeValidator,
    NamePeriodHeader,
    NewAdHocContract,
    FixedWidth,
    InventoryFilter,
    NewVoucher,
    NotificationBadge,
    NullIfZero,
    PaginationComponent,
    PauseCreativeForm,
    PdfViewer,
    PeriodSelect,
    PreconditionErrorDialog,
    ProductDiscountInput,
    ProgressBar,
    ProgressContent,
    DotLoader,
    RadioButtonBlock,
    RadioButtonBox,
    RegionMap,
    RegionSelect,
    SegmentSelect,
    Selector,
    SendInvoicesIndicator,
    ShowPricelist,
    Signup,
    SimpleTargetingSummary,
    SpecialCampaignLabel,
    Spinner,
    StringArrayInput,
    SuccessDialog,
    TagList,
    TargetGroupSelect,
    TargetingBox,
    TargetingCheckbox,
    TargetingLabel,
    TargetingSummary,
    TargetingTags,
    TextButton,
    Toggle,
    ToggleTab,
    TTVBookings,
    TwoColRow,
    TwoColTable,
    VideoUpload,
    VouchersList,
    WooCopyTableAsNumber,
    WooCurrency,
    WooCustomerDropdown,
    WooDateFormatter,
    WooDatePipe,
    WooDateValidator,
    WooDialog,
    WooDialogContainer,
    WooDropdown,
    WooFileDragClass,
    WooFileDrop,
    WooFocus,
    WooLoad,
    WooMaxValidator,
    WooMinValidator,
    WooNumberFormatter,
    WooNumberInput,
    WooPercentFormatter,
    WooTable,
    WooTextInput,
    WooTitleCasePipe,
  ],
})
export class WooComponentsModule {}
