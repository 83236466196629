
<div *ngIf="!model" class="image__dropbox min-height--0 height--full flex__container--vertical justify-content--center"
  wooFileDragClass="file-hover" (wooFileDrop)="fileSelected($event[0])">
  <div *ngIf="!loading">
    <span class="icon icon-ios text--xl"
      [ngClass]="{'text--info': customerId, 'text-danger': !customerId}"></span>
    <p *ngIf="!errors.length && customerId">Dra hit eller klicka för att ladda upp</p>
    <p *ngIf="!errors.length && customerId">
      <a *ngIf="technicalSpec" [href]="fileService.getUrlToFile('technical_spec_sv')"
        target="_blank">({{technicalSpec}})</a>
    </p>

    <p *ngIf="!customerId" class="text-danger">Du behöver välja kund innan du kan ladda upp.</p>

    <div *ngIf="customerId">
      <input class="display--none" type="file" [accept]="acceptedFileType" #fileInputElement
        (change)="fileSelected($event.target.files[0])">
      <button class="link primary-1" type="button" (click)="fileInputElement.click()">
        Välj fil...
      </button>
    </div>
    <ng-container *ngFor="let error of errors">
      <span class="text--danger">{{error}}</span>
      <br>
    </ng-container>
  </div>

  <dot-loader [visibility]="loading"></dot-loader>

</div>
<div *ngIf="model" class="flex__container--vertical">
  <video *ngIf="assetType === creativeAssetType.Video" class="border-radius" [src]="model.url" controls></video>
  <img *ngIf="assetType === creativeAssetType.Image" class="border-radius" [src]="model.url" />
  <div class="selected-file__options-container">
    <button class="link primary" type="button" (click)="clearSelected()">Byt fil</button>
    <button class="link primary" type="button" (click)="useSelectedVideosCode()" *ngIf="model.videoCode">Använd filmens kod</button>
  </div>
</div>
