import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TargetingMappingService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getTargetingMappings(): Promise<TargetingMapping[]> {
    return lastValueFrom(this.http.get<TargetingMapping[]>(`${this.env.apiUrl}/ttv_targeting_mappings`));
  }

  getExternalIds(): Promise<string[]> {
    return lastValueFrom(this.http.get<string[]>(`${this.env.apiUrl}/ttv_targeting_mappings/ttv_target_groups`));
  }

  getTargetingMapping(id: wooId): Promise<TargetingMapping> {
    return lastValueFrom(this.http.get<TargetingMapping>(`${this.env.apiUrl}/ttv_targeting_mappings/${id}`));
  }

  createTargetingMapping(mapping: TargetingMappingParams): Promise<TargetingMapping> {
    return lastValueFrom(
      this.http.post<any>(`${this.env.apiUrl}/ttv_targeting_mappings`, {
        ttv_targeting_mapping: mapping,
      }),
    );
  }

  updateTargetingMapping(updatedFields: Record<string, string>, id: wooId): Promise<TargetingMapping> {
    return lastValueFrom(
      this.http.patch<TargetingMapping>(`${this.env.apiUrl}/ttv_targeting_mappings/${id}`, {
        ttv_targeting_mapping: updatedFields,
      }),
    );
  }

  removeTargetingMapping(id: wooId): Promise<any> {
    return lastValueFrom(this.http.delete(`${this.env.apiUrl}/ttv_targeting_mappings/${id}`));
  }

  getTargetingType(targetingMapping: TargetingMapping): TargetingMappingType {
    if (targetingMapping.category_ids.length) {
      return TargetingMappingType.category;
    } else if (targetingMapping.gender_target_group_ids.length || targetingMapping.age_target_group_ids.length) {
      return TargetingMappingType.targetGroup;
    } else {
      return TargetingMappingType.rbs;
    }
  }
}

export interface TargetingMapping {
  name: string;
  id: wooId;
  ttv_target_group: string;
  factor: string;
  description?: string;
  start_date: string;
  created_at: string;
  category_ids: string[];
  age_target_group_ids: string[];
  gender_target_group_ids: string[];
}

export type TargetingMappingParams = Pick<
  TargetingMapping,
  | 'name'
  | 'description'
  | 'ttv_target_group'
  | 'factor'
  | 'start_date'
  | 'category_ids'
  | 'age_target_group_ids'
  | 'gender_target_group_ids'
>;

export enum TargetingMappingType {
  rbs = 'RBS',
  category = 'Kategori',
  targetGroup = 'Målgrupp',
}
