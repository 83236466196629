<div class="section block">
  <div class="account__card inner-block">
    <h2>Allmänna villkor</h2>
    <pdf-viewer style="height: 40vh" class="display--block margin-top-bottom--2x" [src]="termsUrl"></pdf-viewer>
    <div>
      <p>
        Här kan du läsa om vår <a href="{{integrityPolicyUrl}}" target="_blank">integritetspolicy</a> och <a href="{{cookiePolicyUrl}}" target="_blank">cookiepolicy</a>.
      </p>
    </div>

    <div class="text--center">
        <button id="acceptTermsButton" class="button primary-1" (click)="acceptTerms()">Godkänn och gå vidare</button>
        <button id="rejectTermsButton" class="link primary-1 ng-click-active" (click)="rejectTerms()">Avbryt</button>
    </div>
  </div>
</div>


