import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { AdOrganisation, CompactAgency } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { VoucherConditionDefinition, VoucherParams, VoucherService } from '../../../woo_services.module/VoucherService';

@Component({
  selector: 'new-voucher',
  templateUrl: './new-voucher.component.html',
})
export class NewVoucher {
  @Output() onNewVoucher = new EventEmitter<VoucherParams>();
  @Input() agencies: CompactAgency[];
  @Input() adOrganisations: AdOrganisation[];
  @Output() onAbort = new EventEmitter<void>();

  voucher: VoucherParams;
  conditions: VoucherConditionDefinition[] = [];
  convertVoucherConditions = this.translationService.convertVoucherConditions;
  convertDiscount = this.translationService.convertDiscount;

  readonly isAdminOrPlanner = this.authService.adminOrPlanner();
  readonly isAdminOrPlannerOrSales =
    this.isAdminOrPlanner || this.authService.hasAnyRole([UserRole.sales, UserRole.salesManager]);

  constructor(
    private authService: AuthService,
    voucherService: VoucherService,
    private translationService: TranslationService,
  ) {
    voucherService.getConditions().then((conditions: VoucherConditionDefinition[]) => {
      this.conditions = conditions;
      this.voucher.conditions = this.getVoucherConditions();
    });
    this.resetVoucherParams();
  }

  newVoucher = (): void => {
    this.voucher.ad_organisation = { id: this.adOrganisations.find((org) => org.name === 'TV4').id };
    this.onNewVoucher.emit(Object.assign({}, this.voucher));
  };

  validDiscountAmount = (): boolean => {
    return this.voucher.discount > 0 && this.voucher.discount < 1;
  };

  private resetVoucherParams = () => {
    this.voucher = {
      discount: null,
      ad_organisation: null,
      agency: { id: null },
      conditions: this.getVoucherConditions(),
    };
  };

  private getVoucherConditions = () => this.conditions.map((condition) => ({ type: condition.type, values: null }));
}
