import { Component } from '@angular/core';
import { WooUiEventService } from '../../../woo_services.module/WooUiEventService';

@Component({
  selector: 'load-button-styles',
  templateUrl: './load-button-styles.component.html',
  styleUrls: ['./load-button-styles.component.scss'],
})
export class LoadButtonStyles {
  constructor(private wooUiEventService: WooUiEventService) {}

  shutdown(): void {
    this.wooUiEventService.stopLoadDotAnimation();
  }

  getText(): string {
    return '<button class="button X Y" wooLoad>Z</button>';
  }
}
