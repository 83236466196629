import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../utils/types';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { CampaignFrequencyReport } from '../services/campaign-frequency-report.service';

@Component({
  selector: 'frequency-limit-per-month-table',
  templateUrl: './frequency-limit-per-month-table.component.html',
})
export class FrequencyLimitPerMonthTable implements OnChanges {
  readonly convertMonthIndex = this.translationService.convertMonthIndex;

  @Input() reports: CampaignFrequencyReport[] = [];

  constructor(private translationService: TranslationService) {}

  ngOnChanges(changes: SimpleChanges<FrequencyLimitPerMonthTable>): void {
    if (changes.reports) {
      this.reports = this.reports.sort((a, b) => b.month - a.month).sort((a, b) => b.year - a.year);
    }
  }
}
