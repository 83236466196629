import { Component, OnInit } from '@angular/core';
import { saveFileAs } from 'app/scripts/utils/files';
import { groupBy, range } from '../../utils/array';
import { inPeriod } from '../../utils/date';
import { trackById } from '../../utils/object';
import { UserRole } from '../../utils/user-roles';
import { AuthService } from '../../woo_services.module/AuthService';
import { CreativeService } from '../../woo_services.module/CreativeService';
import {
  FrequencyLimitPrice,
  PriceList,
  PriceListCurrency,
  PriceListService,
  PriceListType,
  ValidPriceListRow,
} from '../../woo_services.module/PriceListService';
import { ExtraPublisherField, PublisherService } from '../../woo_services.module/PublisherService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import {
  GeoTargeting,
  PriceListBasePriceTypes,
  PriceListPriceGroups,
  PriceListTargetingPriceTypes,
  Publisher,
  wooId,
} from '../../woo_services.module/shared-types';

@Component({
  selector: 'pricelist',
  templateUrl: './show-pricelist.component.html',
  styleUrls: ['./show-pricelist.component.scss'],
})
export class ShowPricelist implements OnInit {
  readonly getLabel = this.priceListService.getLabel;
  readonly tableHeaders = ['Publicist', 'Placering', 'Styrning', 'Tilläggsstyrning', 'Geografi', 'Totalpris'];

  publishers: Publisher[] = [];
  productFormatOptions: DropDownOption[] = [];
  targetingOptions: DropDownOption[] = [];
  localityOptions: DropDownOption[] = [];
  selectedPublisher: Publisher;
  selectedProductFormat: DropDownOption;
  selectedTargetingType: DropDownOption;
  selectedLocalityType: DropDownOption;
  standardPriceLists: PriceList[] = [];
  yearlyTables: AnnualPriceTable[] = [];
  AgeModifierPrices: ageModifierPriceRow[] = [];
  visiblePriceList: wooId;
  activeViewCurrency: PriceListCurrency = PriceListCurrency.GrossRatingViews;
  currentPriceListCurrency: PriceListCurrency;
  isExternalPlanner: boolean;
  creativeLengths: number[] = [];
  weekInfo: Map<PriceList, WeekIndexInfo> = new Map();
  translateBaseProduct: (string: string) => string;
  translateTargetingProduct: (string: string) => string;
  translateAgeModifierKey: (string: string) => string;
  translateLocality: (string: string) => string;
  changes: any;

  constructor(
    private publisherService: PublisherService,
    private priceListService: PriceListService,
    private creativeService: CreativeService,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateBaseProduct = this.translationService.convertProductFormat;
    this.translateTargetingProduct = this.translationService.convertProductChoice;
    this.translateLocality = this.translationService.convertLocality;
    this.translateAgeModifierKey = this.translationService.convertAgePriceListKey;
    this.productFormatOptions = this.buildDropDownChoices(
      Object.keys(PriceListBasePriceTypes),
      this.translateBaseProduct,
    );
    this.targetingOptions = this.buildDropDownChoices(
      Object.keys(PriceListTargetingPriceTypes),
      this.translateTargetingProduct,
    );

    this.localityOptions = this.buildDropDownChoices(Object.keys(GeoTargeting), this.translateLocality);
    this.isExternalPlanner = this.authService.hasRole(UserRole.externalPlanner);
    this.creativeLengths = this.creativeService.getCreativeLengths();
    Promise.all([
      this.publisherService.getPublishers(ExtraPublisherField.enabled),
      this.priceListService.getPriceLists(),
    ]).then(([publishers, priceLists]) => {
      this.publishers = publishers.filter((p) => p.enabled);

      this.standardPriceLists = this.visibleStandardPriceLists(priceLists).map(this.filterDisabledPublisherPrices);
      this.buildTablePriceRows();

      const is100 = (i: number) => i === 100;
      this.weekInfo = this.standardPriceLists.reduce<Map<PriceList, WeekIndexInfo>>((map, list) => {
        const weeks = this.priceListService.getValidWeekCount(list);
        const firstWeeks = Math.ceil(weeks / 2);
        const secondWeeks = Math.floor(weeks / 2);

        return map.set(list, {
          weekIndexesPresent: !list.week_price_indexes.concat(list.pause_ad_week_price_indexes).every(is100),
          firstWeeks: range(firstWeeks, 1),
          secondWeeks: range(secondWeeks, firstWeeks + 1),
        });
      }, new Map());

      // Show pricelist for current date as default
      const now = new Date();
      const currentPriceList = this.standardPriceLists.find((list) =>
        inPeriod(new Date(list.valid_from), new Date(list.valid_to), now),
      );
      if (currentPriceList) {
        this.changeVisiblePriceList(currentPriceList);
      }
    });
  }

  buildDropDownChoices(choices: string[], translateChoice: (string: string) => string): DropDownOption[] {
    let dropDownChoices: DropDownOption[] = [];
    choices.map((choice) => {
      dropDownChoices = [
        ...dropDownChoices,
        {
          key: choice,
          name: translateChoice(choice),
        },
      ];
    });

    return dropDownChoices;
  }

  getActivePrices(frequencyLimitPrices?: FrequencyLimitPrice[]): FrequencyLimitPrice[] {
    return (frequencyLimitPrices || []).filter((price) => price.frequency_limit.active);
  }

  trackById(): (_: any, item: { id: wooId }) => wooId {
    return trackById;
  }

  getYearlyTable(id: wooId): YearlyTablePriceRow[] {
    return this.yearlyTables.find((table) => table.yearId === id).table;
  }

  getTypeOfCost(baseProductName: string): string {
    if (['shortform', 'pause'].some((product) => baseProductName.includes(product))) return 'CPM';
    return this.priceListService.translatePriceListCurrencyShort(this.currentPriceListCurrency);
  }

  getPublisherName(publisherId: wooId): string {
    return this.publishers.find((publisher) => publisher.id === publisherId).name;
  }

  downloadExcel(allPriceLists: boolean): void {
    const publisherIds = this.publishers.map((publisher) => publisher.id);
    const priceLists = allPriceLists
      ? this.standardPriceLists.map((priceList) => priceList.id)
      : [this.visiblePriceList];
    let fileName = 'prislista_woo';
    if (!allPriceLists) {
      fileName +=
        '_' +
        this.standardPriceLists
          .find((priceList) => priceList.id === this.visiblePriceList)
          ?.valid_from.slice(0, 4)
          .replace(/-/, '_');
    }
    fileName += '.xlsx';
    this.priceListService.getExcelSheet(priceLists, publisherIds).then((res) => {
      saveFileAs(res.body, fileName);
    });
  }

  rowVisible(row: YearlyTablePriceRow): boolean {
    if (this.selectedPublisher && row.publisherId !== this.selectedPublisher.id) return false;
    if (this.selectedProductFormat && row.productFormat !== this.selectedProductFormat.key) return false;
    if (
      this.selectedTargetingType &&
      !(row.targetingType === this.selectedTargetingType.key || row.addonTargeting === this.selectedTargetingType.key)
    ) {
      return false;
    }
    return !(this.selectedLocalityType && row.localityType !== this.selectedLocalityType.key);
  }

  changeVisiblePriceList(priceList: PriceList): void {
    this.visiblePriceList = priceList.id;
    this.currentPriceListCurrency = priceList.price_list_currency;
  }

  get showingCompletedViewsPriceList(): boolean {
    return this.currentPriceListCurrency === PriceListCurrency.CompletedViews;
  }

  get showingGrossRatingViewsPriceList(): boolean {
    return this.currentPriceListCurrency === PriceListCurrency.GrossRatingViews;
  }

  private filterDisabledPublisherPrices = (list: PriceList): PriceList => {
    const enabledPublisherIds = this.publishers.map((publisher) => publisher.id);
    const priceGroups = [
      PriceListPriceGroups.publisher_base_prices,
      PriceListPriceGroups.publisher_targeting_prices,
      PriceListPriceGroups.publisher_locality_prices,
    ];
    priceGroups.map((priceGroup) => {
      list[priceGroup] = Object.keys(list[priceGroup])
        .filter((key) => enabledPublisherIds.includes(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: list[priceGroup][key],
          };
        }, {});
    });

    return list;
  };

  private buildTablePriceRows() {
    let yearlyTables: AnnualPriceTable[] = [];
    this.standardPriceLists.map((priceList: PriceList) => {
      const table: YearlyTablePriceRow[] = priceList.valid_prices?.map((validPriceRow: ValidPriceListRow) => {
        return this.convertValidPriceRowToYearlyTableRow(validPriceRow);
      });
      yearlyTables = [
        ...yearlyTables,
        {
          yearId: priceList.id,
          table: table,
        },
      ];
    });

    this.yearlyTables = yearlyTables;
  }

  private buildAgeModifierPriceRows(priceList: PriceList) {
    let AgeModifierPrices: ageModifierPriceRow[] = [];
    this.publishers.map((publisher) => {
      return Object.entries(priceList.publisher_age_modifier_prices[publisher.id]).map((prices) => {
        AgeModifierPrices = [
          ...AgeModifierPrices,
          {
            publisherId: publisher.id,
            age: prices[0],
            price: prices[1],
          },
        ];
      });
    });

    return AgeModifierPrices;
  }

  private convertValidPriceRowToYearlyTableRow = (validPriceRow: ValidPriceListRow): YearlyTablePriceRow => {
    return {
      publisherId: validPriceRow.publisher_id,
      productFormat: validPriceRow.product_format,
      targetingType: validPriceRow.targeting_type,
      addonTargeting: validPriceRow.addon_targeting || '-',
      localityType: validPriceRow.locality_type,
      totalPrice: !!validPriceRow.over_written_price
        ? validPriceRow.over_written_price
        : validPriceRow.calculated_price,
      calculatedPrice: validPriceRow.calculated_price,
      overWrittenPrice: validPriceRow.over_written_price,
      rowKey: validPriceRow.price_row_key,
    };
  };

  private visibleStandardPriceLists(priceLists: PriceList[]): PriceList[] {
    return Array.from(
      groupBy(
        priceLists.filter((list) => list.price_list_type === PriceListType.Standard),
        (priceList: PriceList) => this.getLabel(priceList),
      ),
    ).reduce<PriceList[]>((visiblePriceLists, [_year, priceLists]): PriceList[] => {
      if (!priceLists?.length) return visiblePriceLists;
      if (priceLists.length === 1) return visiblePriceLists.concat(priceLists);

      return visiblePriceLists.concat(
        priceLists.filter((priceList) => priceList.price_list_currency === this.activeViewCurrency),
      );
    }, [] as PriceList[]);
  }
}

interface WeekIndexInfo {
  weekIndexesPresent: boolean;
  firstWeeks: number[];
  secondWeeks: number[];
}

interface AnnualPriceTable {
  yearId: wooId;
  table: YearlyTablePriceRow[];
}

interface YearlyTablePriceRow {
  publisherId: wooId;
  productFormat: string;
  targetingType: string;
  addonTargeting: string;
  localityType: string;
  totalPrice: number;
  calculatedPrice: number;
  overWrittenPrice?: number | null;
  rowKey: string;
}

interface ageModifierPriceRow {
  publisherId: wooId;
  age: string;
  price: number;
}

interface DropDownOption {
  key: string;
  name: string;
}
