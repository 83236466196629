import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../woo_services.module/DialogService';
import { GoogleTagManagerService } from '../../../woo_services.module/GoogleTagManagerService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { CompactCampaign, CompactCustomer } from '../../../woo_services.module/shared-types';
import { CampaignSelectionDialog } from '../dialogs/campaign-selection-dialog.component';

const template = /* html */ `
  <button
    (click)="onClick()"
    class="status-badge inverse"
    title="Duplicera kampanj"
    [tabindex]="tabindex"
    data-toggle="tooltip"
    data-placement="bottom"
    (mouseenter)="hover.emit($event)"
  >
    <span class="icon-thumbs-single-row"></span>
  </button>
`;

@Component({
  selector: 'create-campaign-from-template-button',
  template,
})
export class CreateCampaignFromTemplateButton {
  @Input() customersForUser: CompactCustomer[];
  @Input() tabindex: number;
  @Input() disabled = false;
  @Output() hover = new EventEmitter();

  constructor(
    private googleTagManagerService: GoogleTagManagerService,
    private routingService: RoutingService,
    private dialogService: DialogService,
  ) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    this.openCampaignSelectionDialog()
      .then((selectedCampaign: CompactCampaign) => {
        if (selectedCampaign) {
          const content = this.getDialogContent(selectedCampaign.name);
          return this.dialogService.openConfirm(content).then(
            async () => {
              await this.duplicateCampaign(selectedCampaign);
            },
            () => null,
          );
        } else {
          return this.createNewCampaign();
        }
      })
      .catch(() => {
        const msg = 'Ajdå, nånting gick snett. Var god försök igen!';
        this.dialogService.openError(msg);
      });
  };

  private openCampaignSelectionDialog() {
    return this.dialogService.create(CampaignSelectionDialog).showSelect(this.customersForUser);
  }

  private async createNewCampaign() {
    await this.routingService.navigate(['/campaigns', 'new']);
  }

  private duplicateCampaign = async (campaign: CompactCampaign) => {
    this.googleTagManagerService.duplicateCampaign('specific');
    await this.routingService.navigate(['/campaigns', 'new'], { state: { id: campaign.id, templating: true } });
  };

  private getDialogContent = (campaignName: string): ConfirmDialogContent => ({
    header: 'Duplicera kampanj',
    textBlocks: [
      'Du är nu på väg att duplicera en kampanj. Det innebär att allt kopieras från den tidigare kampanjen till den nya, förutom namn och kampanjperiod. Eventuella perioder för filmerna behöver också sättas om.',
      `Vill du fortsätta duplicera kampanjen <strong>${campaignName}</strong>?`,
    ],
    confirmText: 'Duplicera kampanj',
    cancelText: 'Avbryt',
  });
}
