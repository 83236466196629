<div class="tabbable__panel panel-margin right section info clearfix">
  <div class="col-xs-12">
    <h2>Detaljer</h2>
  </div>
</div>

<div class="tabbable__body clearfix">
  <div class="margin-bottom--2x">
    <date-span
      [start]="campaignPeriod.start"
      [end]="campaignPeriod.end"
    ></date-span>
  </div>
  <customer-agency-row [data]="campaign"></customer-agency-row>
  <h3 class="margin-top--none margin-bottom--2x">{{ campaignName }}</h3>

  <div class="divider margin-top-bottom--2x"></div>
  <ng-container *ngIf="brand" class="margin-bottom--2x">
    <p>Varumärke: {{ brand?.name }}</p>
  </ng-container>
  <ng-container *ngIf="additionalInfo" class="margin-bottom--4x">
    <p>Övrig info</p>
    <p>{{ additionalInfo }}</p>
  </ng-container>

  <div
    class="margin-left--none margin-right--none margin-bottom--2x shadow-z-1"
    *ngFor="let data of targetingData"
  >
    <targeting-summary
      [targeting]="data.targeting"
      [metaData]="data.meta"
      [part]="data.part"
      [expanded]="true"
    ></targeting-summary>
  </div>
</div>
