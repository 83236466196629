import { Component, OnInit } from '@angular/core';
import { CampaignFrequencyReport, CampaignFrequencyReportService } from '../services/campaign-frequency-report.service';

@Component({
  selector: 'statistics-dashboard',
  templateUrl: './statistics-dashboard.component.html',
})
export class StatisticsDashboard implements OnInit {
  frequencyReports: CampaignFrequencyReport[];

  constructor(private campaignFrequencyReportService: CampaignFrequencyReportService) {}

  ngOnInit(): void {
    this.campaignFrequencyReportService.getAll().then((reports) => {
      return (this.frequencyReports = reports);
    });
  }
}
