<svg version="1.1" *ngIf="sufficientSVGSupport" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" (click)="click($event)"
	 viewBox="0 0 233.5 530" style="enable-background:new 0 0 233.5 530;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;fill:none;stroke:#FF7575;}
	.region{fill:#FFF4F4;stroke:#FF7575;stroke-width:0.25;stroke-linejoin:round;}
</style>
<desc  style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">Created with Raphaël 2.1.4</desc>
<path class="st0" d="M158.3,6.2l-0.4,0.2c-1.2,0.1-5.1-0.2-6,0.6c-0.5,0.4,3.5,6.7,3.3,8.8c-0.3,2.7-5.7,6.8-5.5,8.6
	c0.4,3,7-0.6,2.8,4.6l-2.4,2.9c-3.8,1.4-26.1-8-29.8-3.3c-1,1.2,4.1,17.1-1.7,19.3c-2.6,1-7.4-4.3-10.2-4c-0.7,0.1-0.7,1.3-1.2,1.8
	c-1.9,1.8-5.5,2.9-7,5.1c-2.1,3.2-2,8.5-4,12.1c-1.1,2-5.8,2.3-5.6,5.4c0.4,5.3,8.3,6.9,5.1,13.9c-0.9,2-3.9,3.3-5.2,5
	c-2.4,3-3.5,7.2-5.9,10.3c-0.2,0.3-0.6,0.7-1,1l0,0c-1.2,1-3,2.3-3.2,3.3c-0.4,2,2.6,5.2,2,7.1c-1.8,5.5-15.3,4.7-16.1,5.9
	c-0.9,1.4,2.6,8.8,2.6,10.7c0,2.8-1.7,5.7-2,8.5c-0.1,0.6,0.1,1.1,0.1,1.7c0,0.9,0,1.8,0.1,2.7c0,0.8,0,1.6,0.1,2.5
	c0,0.6,0,1.1,0.1,1.7c0,0.6,0.2,1.2,0,1.7l-2.2,2.5c-0.6,1.2-0.2,3-0.7,4.3c-1.7,4.6-5,9.1-7.4,13.4c-0.7,1.2-4.2,5.9-3.1,7.5
	c3.7,5,10.8,0.5,8.6,13.9c-1.9,11.6-11.8,1.4-19,4.6c-2.3,1-5.3,1.9-7.2,3.7c-1,1-9.3,13.1-9.4,14.1c-0.1,1.3,1.4,2.4,1.2,3.7
	c-0.3,2.8-4.5,5.2-4.8,8c-0.2,3,4.4,6.4,4.3,9.4c-0.1,1.5-2.2,2.9-2.4,4.3c-0.3,1.7,1,3.8,0.9,5.6c0,1.8-1.4,3.8-1.2,5.6
	c0.4,4.3,5,8.1,5.3,12.6c0,0.2,0,0.4,0,0.5c0.1,6-4.7,13.8-3.3,19.5c1.7,7,12.3,6.5,13.9,13.9c0.3,1.2-2.4,9.7-3.5,11.2
	c0,0.1-0.1,0.1-0.1,0.2c-1.5,1.3-8-0.4-7.9,3.1c0.1,6.7,7.1,12.8,7.2,19.7c0,2.7-1.7,4-2.2,6.4c-0.4,1.9,0,4.6,0.1,6.6
	c0,0.6,0.2,1.2,0,1.7c-2.7,6.8-12.5,7.5-13,8.8c-0.3,0.8,2.2,4,1.6,5.8c0,0.1,0,0.1-0.1,0.2c0.3,0.4,0.6,1,0.9,1.5
	c-0.4-0.5-0.7-1.1-1-1.5c-0.8,1.5-4.1,2-4.5,3.6c-1.4,5.1,6,19.8-0.6,26.3c-3.9,3.9-3.9-0.7-4.4-4.1c-0.8,0-2-6.7-5.2-0.5
	c-1.7,3.2-0.3-0.5,0.7,1.9l-1.7,1.2l2,1.3c1.1,2.9-0.9,6.1-0.4,7.7c0.6,1.7,2,3.9,2,5.9c0,2.5-1.8,5.4-1.5,7.7
	c0.1,0.4-0.3,1.3,0.1,1.3c1.4,0,2.3-2.5,3.6-2.7c0.8-0.1,1.4,1.4,1.1,2.2c-0.2,0.5-1.1,0.2-1.3,0.6s0.3,2.6,0.3,2.6
	c1.4,0.4,2.3-1.5,2.7-2.5c0.6-1.7,0-3.7,0.3-5.1c0.2-0.9,0.2,2.3,1,2.6c0.5,0.2,0.9-0.6,1.3-0.6c0.5,0-0.8,0.8-1,1.3
	c-0.7,1.5-3.1,3.8-2.4,5.8c0.2,0.6,1.1-0.3,1.7-0.6c3.1-1.7,1.9-3.2,6.1-3.2c0.4,0-0.6,0.4-0.8,0.7c-0.3,0.2,0.3,5.9-0.1,7.8
	c-0.3,1.2-1.7,5.5-2.6,8.3c-0.5,1.5-0.8,2.6-0.8,2.6c0.2,0.9,2.9,1.7,3.1,2c0.2,0.2-3,2-2.4,3.2c0.2,0.4,0.6-0.7,1-0.7
	c3.7,0.1,0.8,3.5,2.4,4.6c0.3,0.2,0.8-1,1-0.7c1,1.6-0.4,8.9,0.4,10.4c1,1.9,2.1-6.9,3.4-3.2c0.5,1.3-1.9,2.9-1,3.9
	c1.1,1.2,1.4-1.1,2.1,0.7c0.2,0.4,0.1,0.8,0.1,1.1l0,0c-0.1,0.7-0.7,1.3-1.1,2.1c-0.1,0.1,1.5,1.2,2.1,2c0,0.1,3.2,10.3,3.2,10.4
	l2.8,1.3c2.3,1.8,3,5.4,3.9,7.9c1.2,3.3,5.6,0,5.7,5.9c0.1,7.4-5.4-0.4-6.6,4.6c-0.1,0.2,1.2,1.4,2.4,2.8s2.3,3.1,1.9,4.4
	c0,0.1-0.1,0.1-0.1,0.2c-1.1,1.8-7.4-4-6.8-1.5c0.6,2.7,4.6,12.2,6.7,15.1c0.1-1.4,0.3-3.2,0.4-3.8c-0.1,0.6-0.3,2.4-0.4,3.8
	c0.2,0.2,0.3,0.4,0.4,0.5c1.6,1.7,1.5-0.6,2.5,2c0.2,0.4,0,0.9,0,1.3s-0.5,0.9-0.3,1.3c0.5,1.4,2.3,1.6,3.3,3.3
	c1,1.8-3.2,2.8-3.2,3.9c0.1,1.4,1.8,3.9,1.5,5.2c0,0-5.1,0.8-3.2,2c1,0.6,2.2-0.7,2.5-0.7c2.3,0,5.7,3.7,9.4,2.6
	c2.6-0.8,3.7-2.4,5.7-3.3c1.4-0.6,3.6-0.2,5-0.7c2.2-0.8,4.3,3.6,6.9,1.9c6-4,0.8-8.8-0.4-13.1c-1.2-4.4,2.9-9.6,5.5-12.4
	c0.1-0.1,0.1-0.1,0.2-0.2c1.8-2,3.5,2.1,5.4,1.2c2.7-1.3-2.8-4.3-1.1-5.8c2.4-2,1.5,0.5,3,0.5c0.4,0-0.1-1.1,0.3-1.3
	c1-0.6,3.3,1.2,4.3,0.5c0.4-0.2-0.1-1.3,0.3-1.3c0.8,0,2.1,1.5,3.6,1.2c0.6-0.1,0.8-1.3,1.4-1.3s-0.3,1.5,0.1,2
	c0.5,0.6,5.5-3.5,6.3-2.9c0.3,0.3-0.7,1.1-0.3,1.3c0.5,0.3,3.8-0.2,5,0.4c1.3,0.7-2.2,1.3,0.8,2.6c0,0,0.9-2.3,1.1-2.6
	c0.7-1.7,2.4-2.1,2.9-4.1c0.1-0.3,0.1-0.5,0.2-0.8c0.9-3.8,1.3-7.3,2.7-10.4c0.6-1.3-0.7-3.4,0.8-4.6c5.2-4.3-0.5,0.2,1-3.7
	c0.4-0.9,2.5,0.2,2.7-0.8c0.6-3-0.9-0.6-1.1-0.6c-1.7,0.1,0.2-3.2,0.2-3.7c0-2-0.7-6,0.1-7c0.8-1.1,2.5,0.3,2.8-0.1
	c0.5-1.1-2.3-3.1-2.7-4.4c-0.9-3.1,2.9-11.2,4.1-11.9c2.5-1.3-1-2.3-3.1-3.5c-1.3-0.7-2.1-1.4-1-2.2c0.4-0.3,0.6,1.2,1.1,1.2
	c2.4,0.3-0.4-4.4-0.3-4.5c0.7-0.8,3.1,0.9,3.1-0.2c-0.1-1.9-5.2-4.2-6.2-6.5c-0.1-0.3-0.7-1.1-0.4-1c0,0,0-0.6,1.3-0.7
	c0.8,0,1,1.2,1.8,1.2c0.2,0,0.5-1.6,0.2-2c-1.3-1.6-1.7,0.7-1.8-1.9c0-0.9,1.6,0.6,2.1,1.2c1.2,1.3,3.4,2.7,3.6,2.4
	c1.1-2.4-2.3-3.4-2.3-3.8c0.1-0.5-0.7-0.8-0.8-1.2c-0.1-0.4-0.1-1.1,0.3-1.3c0.6-0.3,0.7,0.6,0.7,0c0-0.1,0-0.1,0-0.2
	c0.2-0.5,1.2-0.6,1.2-1.2c0-0.1-2.7-3.7-3-4.4c-0.6-1.5,3.7,4,3.9,2.4c0.1-1.3,0.9-2.9-0.3-4.6l-2.1-0.5c-0.1-1.2,0.4-1.8,0.5-2
	c-0.5,0.4-0.9,1.5-2.1-1.2c-0.3-0.7,4.2,1.7,1.6-2l-3.1-1.1c-0.4-1.3,2.5,0.5,3.7,0.5c2.1-0.1,1.1-2.7,3.5-2.8
	c0.4,0-0.8-0.3-1.1-0.6c-0.9-0.9-1.5-3.3-2.3-3.7c-0.7-0.4-5.6-0.9-7.1-0.6l-0.5,1.6l-1.5-1.5c-1-0.1-3.9,0.3-3-0.2
	c4-2.2,17.2-1,17.1-0.9c0.5-0.5,0.2-0.3,1-0.7c0.7-0.4,1.9-1.6,1.2-2c-0.9-0.5-2.4-0.2-2.5-1.8c-0.1-1.6,3.8,1.4,4.4,1
	c0.5-0.3,0.5-1.1,0.9-1.3c0.4-0.2,1,0.2,1.3-0.1c0.3-0.3,0.4-0.9,0.3-1.3c-0.2-0.4-1.2-0.2-1-0.6c0.3-0.4,1-0.1,1.5-0.1
	s1.1,0.3,1.5-0.1c0.5-0.5-0.2-1.5-0.7-2.4c-0.4-0.7-0.7-1.3-0.2-1.5c3.3-1.3,2.3-0.2,2.7-0.2c0.3,0,0.7,0.1,1-0.1
	c0.7-0.5-0.7-1.7-0.5-2.5c0.3-1.1-0.2-7.7,0.5-7.8c0.1,0,1.1,1.2,2.1,1.2c0.4,0-0.5,8.8,1,9.6c0.9,0.5,2,1.9,2.4,0.5
	c0.4-1.6,2.5-9.9,5.6-10.1c0.6,0-0.4,1.3-0.2,1.9c0.1,0.4,4.3-4.2,4.3-4.2c-1.6-2.6-3-0.7-3.4-5.5c0-0.3,6.2,1.5,7.2-0.6
	c0.2-0.3-0.4-1.5-2.3-3c-0.7-0.6-2.7-2-2.4-1.1c0.4,1.2,1.7,1.5,1,3.8c0,0.1-3.1,0.2-4.9,1c-0.5,0.2-3.8-0.7-5-0.3
	c-2,0.6-1.6,4.8-1.6,4.6c-0.8-2.3-1.3-0.5-3.5-1.7c-0.7-0.4-2-1.2-2.4-0.5s1.1,1.9,0.5,2.5c0,0-1.7-3.1-3.6-3c-0.4,0-1.9,4.6-4,2.2
	c-0.3-0.4,1-0.2,1.3-0.7l-0.8-1.9c0.1-0.1,2.6,0.3,1.2-1.4c-1.1-1.3-4.4,0.3-5.6-0.3c-1.4-0.6-2.4-4.4-4.2-4.3
	c-0.9,0-2.7-0.2-2.6,0.8l2.5,1.6c0.2,1.2-3-1.1-4.1-1.2c-2.9-0.4-5.4,1.2-8.5,1.4c-0.5,0-1.4-0.2-2.1-0.6l0,0
	c-0.8-0.4-1.4-1-0.8-1.1c0.5-0.1,1,0,1.6,0c0.4,0.1,0.8,0.5,1.2,0.4c0.5-0.1,1.1-1.9,1.6-1.3c0.5,0.5,1.2-0.2,2.3-0.8
	c0.3-0.1,0.4,0.5,0.7,0.6c0.4,0.1,1,0.3,1.3-0.1c2.3-2.5-1.3-5.1,1.1-4.6c0.9,0.2,2.8,4.1,3.8,3.7c0,0,0.3-0.6,0.6-1.2
	c0.4-0.7,0.9-1.5,0.9-1.5c1.5-0.9,3.9,2.9,5,1.6c0.2-0.2-2.6-2.9-0.2-2.5c0.7,0.1,3.1,1.7,3.1,1.7c1.6,0.8,2.3,2.4,3.8,3
	c0.2,0.1-2.2-6.9-0.8-7c0.7,0,0,1.6,0.5,2.2s1.4,2,2.1,2.1c0.4,0.1,0.7-1.1,0.9-0.7s-0.3,1.2-0.3,1.7c0,0.3,3.4,2,3.8,2
	c1-0.1,0.7-4.2,0.7-4.2c-0.4-1.9-2.5-1.7-3.4-3.2c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.2-0.4-0.2-0.7
	c-0.1-0.4,0.1-0.6,0.1-1c0.1-0.4,0.2-1-0.1-1.3c-1.6-1.1-1.8,2.1-1.7,3.3c0,0.7,0.8,2.6,0.5,2s-1.6-2.8-1.6-3.2l0.4-0.8l0.5-0.9
	c-0.6-0.8-3.3-0.1-2.7-0.8c0.4-0.4,1.1-0.4,1.6-0.4s1.1,0.1,1.6-0.1s1.1-3,1.9-1.1c1,2.5-0.4,3.8-0.3,5c0,0.1,0.1,0.2,0.1,0.3
	c0.5,0.2,1.9,0.7,1.9,0.7c0.3-0.2-0.1-0.8-0.1-1.3c0-0.6,0.1-1,0.2-1.6c0.3-1,0.3,2.2,0.9,3.2c0,0.1,0.1,0.1,0.1,0.2
	c3.1,5.3-0.6,2.4,0.4,7.5c0.6,3,5.1,1.4,5.3,0.3c0.1-0.6,1.3,0.3,1.6-0.1c0.4-0.5-0.2-1.4,0.2-1.9c2.1-2.8,0.2,3.3,4.1,2.3
	c1-0.3-1.9-2.3-1.2-3.1c0.3-0.3,0.9,0,1.3-0.1c0.4,0,0.9-0.1,1.3-0.1l1.1-2.7c0.6-0.7,3.5-1.3,3.8-2.2c0.2-0.6-0.8-1.3-0.5-1.9
	c0.3-0.5,1.2,0.2,1.6-0.1c0.8-0.7,3.3-3.7,3.2-4.8c-0.1-1.5-1.9-0.4-2.1-0.7c-0.7-1.1,2.1-1.9,2.1-3.2c0-1.1-2.3-2.8-1.2-3.1
	c1.1-0.3,1.3,2.6,2.4,2.8c0.4,0.1,0.3-0.7,0.3-1.1c-0.1-2.5-4-8.1-4.7-8.6c-1.1-0.9-1.2,0.5-1.6,0.1c-2.6-2.5-2.1,0.4-2.8,2.1
	c-0.4,0.9-0.1-2.2-0.7-2.9c-0.2-0.3-1.2-0.7-2.1-1.1c-1.4-0.6-2.5-1.3-0.3-1.5c1.3-0.1,0.5,0,1.9-0.1c0.4,0,1.1-0.4,0.9-0.7
	c-0.6-1.2-2.6-0.6-3.4-1.6c-2.1-2.7,3.3-0.2-0.2-2.5c-1.1-0.8-3.1,0.9-3.8,0.9c-0.6,0,0.4-1.3,0.5-2c0.1-0.3-0.6,0.1-0.9,0.1
	c-0.3,0-0.7,0.2-1,0.1c-3.5-2.1-3.6-7.4-5.4-7.9c-3.2-0.8-2.5,3.6-3.2,4.7c-0.2,0.3-0.7-0.4-1-0.6c-0.1-0.1-0.2-0.1-0.3-0.2
	c-2.3-1.6,0.1-3.5-1.3-4.8c-1.9-1.6-3.6,0.9-5.4,1c-0.2,0-0.5-3.1-3.1-3c-1.2,0.1,3-1.4,2.7-2.7c-0.1-0.5-1.2-2.7-1.7-3.7
	s-2.3-2.5-2.4-4c0-0.3,0.1-0.7,0.3-1c0.3-0.5,1.6-0.1,1.6-0.7s-1.1,0.3-1.6,0.1c-0.3-0.1,0.6-3,0.4-3.2c-0.1-0.2-1.5-3.4-1.2-4.4
	c0.1-0.5,1.2-0.2,1.2-0.7c0-1.3-2-1.8-2.4-3c-0.4-1,0.2,0,0.9-0.1c0.7,0,2.6,0.5,2.2-0.1c-0.6-0.9-3-1.3-3.3-2.9
	c-0.5-2.6,1.4-0.2,2.1-0.8c0.4-0.3-0.8-0.7-1-1.2c-0.3-0.8-0.3-1.7-0.2-2.5c0.1-0.7-1.8-0.4-1.6-1.1c0.1-0.3-1.6-0.4,1.1-2.3
	c0.5-0.4,2.3,0.7,1.9,0.2c-0.7-0.8-3.1,0-2.9-1.1c0.2-1.3,3.1-0.8,3-2.1c-0.1-0.9-2.9-0.6-2.3-1.1c2.6-2.4,3.5,2.9,6.7,2.7
	c0-0.4,0.1-0.9,0.1-1.3c0.2-4.1-3.3-0.3-3-4.9c0.1-1-1.2-0.5-0.8-2.5c0.5-2.6,1.9-4.3,2.1-5.2c0.2-0.9,0-2.4-0.1-4
	c-0.1-1.7-0.2-3.5,0.5-4.8c0.6-1.3-0.1,1.8,1.6,0.5c0.3-0.2,1.1-0.4,0.9-0.7c-1.3-1.8-6.4-1.6-6.3-5.9c0-0.2,0.5,0.1,0.6,0
	c0.2-0.4,0.3-0.8,0.2-1.3l-1-1.8c1.2-5.5,5.5,4.6,6.8,1.4c0.2-0.5-0.9-0.6-1-1.2c-0.2-2.7,2.8-0.4,3.6-0.9c1.2-0.7,0.6-4.1,1.2-5.1
	c0.4-0.7,0.1,2,0.8,2.4l1.7-2c0.2-0.7-1.1-1.1-1.4-1.8c-1.4-3.4-1.5-7.3-3.8-10.3c-0.3-0.4-0.7-0.8-1.2-1.2h-0.1
	c-0.6,0-1.3-1.2-1.3-1.2s0.7,0.5,1.4,1.1c1-0.2,1.4-1.6,3.1,2.3c0.4,0.9,2,1.7,2.6,1.7c0.4,0-1.3,1.6-1.1,1.9c1.8,3.1,2.9-1,3.3-0.8
	c0.4,0.1,0.5-1.6,1-2.6c0.1-0.2,3,3.2,4,0.9c0.2-0.4,0.2-0.9-0.1-1.2c-0.3-0.4-1.5-0.1-1.3-0.5c0.2-0.5,1-0.1,1.5-0.1
	c0.1,0,0.2,0,0.3,0c1.6-1.3,0.9-0.8,1.3-2c0.9-2.3-3.1,0.2-3.8-2.9c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.8,1.2,1.2,1.9,1.1
	c0.7-0.1,2.5-4.7,1.9-5.1c-0.3-0.2-1.1-0.5-0.8-0.6l3.4,0.4c0.6-0.6-1.4-1.6-1.1-2.4c0.1-0.3,0.7-0.1,1.1-0.1c0.4,0,2.3,1.3,2.7,1.1
	c1.5-1.3-2-2.4-1.4-2.4s1.1-1.3,2.1-0.1c0.5,0.6,0.8,3.1,2.3,2.3c0.3-0.2,1-0.3,0.8-0.7c-0.2-0.5,0.1-4.4,2-4.6
	c0.1,0,0.5-2.4,0.8-4.3c0.2-1.1,0.4-2,0.4-2c0.3-0.2,3,3.3,3.2,2.8c0.9-1.4-3.7-6.2-1.7-6c2.5,0.2,2.7,3.6,4.3,4.6
	c1.9,1.1,1-4,3.5-7.2c1.3-1.6,5.3-1,5.8-1.8c0.5-0.9-0.1-2.3,0.6-3.2c0.6-0.8,0-0.3,0.5-0.7c1.5-1.1,0.4,3.7,1.5,2.9
	c1.1-0.7-0.3-5.5,1.5-5.7c0.7-0.1,0.4,1.8,1.1,1.7l1-2c3.3-0.9,3.9-7.8,4.6-12.3c0.2-1.4,2.5-3.9,4.2-5.6c1-1,1.7-1.7,1.8-1.8
	c0.3-0.5-0.2-0.8,0.2-0.7c0.5,0.1,3.8-4.3,3.9-4.8c0.3-2.5-1.1,0.5-2,0.3l-0.3-2.4c-0.7-0.4-3.2-0.2-2.4-0.3
	c6.4-0.8,1.1-0.3-2.2-4.1l-2.4-0.9c-2.3-4.7,1.8-2.7,1.8-2.7c0-0.5,1.2-0.3,1.1-0.7c-0.6-1.7-4.5,1.2-4.9-1.9
	c-0.2-1.6,1.6,1.5,2.6-0.3c0.4-0.7,0.1-3.3,0.7-4.4c0.6-1.1,3.6-2.1,4-2.9c0.8-1.5-0.5-1.2-0.9-1.1c0.1-0.1,0.5-0.2,1.1-0.5
	c0.3-0.1,0.9-0.1,0.9-0.4c0-0.8-0.1-1.8-0.4-2.9c-0.5-2-1.7-4.3-3.8-5.7l-2.3-0.3c-1.1-3.5,5.9,3.4,7.2,2.2c2.8-2.9-6.3-2-4-4.4
	c0.8-0.9,7.7-1.3,7.9-1.5c0.4-0.4-0.4-1.5,0.1-1.9c0.4-0.3,0.6,1.3,1,1.1c2.7-1.5-2.5-3.7-3.1-3.9l0,0c0.2,0,1,0.1,1.3,0.2
	c1.6,0.2,3.6-0.1,4.6-1.5c0.4-0.5-1-0.7-1.6-1c-1.8-1-6.2-1.1-6.5-4c-0.1-0.4,0.6,0.5,0.9,0.8c1.6,1.4,0.3,0.3,2.2,1.2
	c1.4,0.7,7.6,3.3,8.4,3.2c0.9-0.1-2.8-6.1-2.6-4.6c0.1,0.4-0.6,0.3-0.6-0.5c0.1-2-2-1.6-0.9-2.3c2.5-1.8,2.9,2.4,2-4.5
	c0-0.2,3,1.4,3.1,1.5c0.1,0.2,0.1,0.4,0.2,0.5c0.2-0.2,0.5-0.2,1-0.1c0.6,0.1,0.5,1.8,1.1,1.7c2.3-0.3-0.9-5.1-1-5.3
	c-0.2-1.2,3.7,3,3.8,3.1c0.7,0.6,2.9,0.1,3.9,1.3c0.4,0.5,0.3,1.4,0.8,1.7c0.6,0.4,0.5-1.4,0.9-2c1.1-1.5-0.2-2.5,0.4-3.1
	c0.9-0.8,2.5,0.4,3.3,1.3c0.2,0.3,0.7,0.8,0.9,0.5c0.5-0.8,0.6-1.5,1-1.3c2.5,1.1,1.9-1.7,3.8-1.2c0.9,0.2,2.9,1.8,3.7,1.9
	c0.2,0,2.3-3.2,3.5-3c-0.8-2.9-2.1-5.6-3.9-7.9c-3.5-4.4-6.4-5.2-7.6-11.6c-0.6-3.4,2.2-3.7,3-6.4c0.6-1.9-1-4.6-0.6-6.5l1.4-2
	c-0.1-4.6-8.5-7.3-8.9-11.8c-0.3-3.1,2.5-3.5,2.7-6.3c0.4-5.4-4.9-1.6-6.3-3.8c-1-1.5,1.2-3,1.3-4.3c0.2-2.9-3.7-7.2-2.3-10.3
	c0.5-1,1.9-1.1,2.4-2.1c1-1.8-3.1-2.6-4.5-4c-0.4-0.4-0.8-0.9-1.2-1.3c-1.4-1.5-3.1,0-3.5-0.4c-0.5-0.6,0-2.9-1.1-3.9
	c-6.2-6-11.9-2.9-17.9-5.8c-2.6-1.2-17.9-10.5-19.7-12.1c-1.2-1.1,1.2-2-0.9-3.3C161.9,7.3,159.3,6.7,158.3,6.2z M173.7,414.7
	c-1.9,0-4.3,1.5-3.7,3.5l2.3,2.4c0.5,0.1,0-3.6,0.3-3.9c0.8-0.7,2-0.6,3-0.9C175.3,415,174.5,414.7,173.7,414.7z M162.9,418.8
	c-0.2,0-0.5,0.1-0.8,0.3c-2.5,1.6,0.5,1.2-1.1,3.3c-0.6,0.9-2.2,0.1-3,0.9c-1.5,1.6-1.7,4.9-2.9,6.7c-4.1,6.1-3.3,3.8-1.6,10.5
	c0.8,3-1.8,3.2-0.3,5.9c1.1,2,1.7,4.3,1.9,5.8c0,0.5,0,1.3,0.4,1.3c0.5,0,0.5-1.7,0.9-1.4c1,0.8-3.5,5.7-2.6,6.7
	c0.4,0.4,1.1,0,1.7-0.1c5.1-1.3,1.3-4.9,2.3-6.7l2-1.5c0-0.4-1.2-0.2-1.1-0.6c0.6-2.5,5.1-2.8,6.2-4.4c0.1-0.2-1.8-3,0.9-5.3
	c0.6-0.5,2.6-0.5,2.7-1.5c0.2-2.6-2.2-0.1-3.3-2.3c-0.7-1.3-0.2-4.7-0.5-5.8c-1.4-5.8,2.2-3.4,3.1-4.2l0.1-2.6
	c0.8-0.7,2.4-0.5,2.6-1.5c0.3-1.2-1.3-2.1-2-3.1c-0.4,0-1.8,1.1-2,0.8c-0.3-0.5-0.8-1.6-1.1-1.2c-0.2,0.2,0.1,3.9-0.7,4
	C163.7,422.9,164.4,418.6,162.9,418.8z M130.4,443.5c-2.4,0.2-2.8,9.1-3.7,11.4c-4.8,12.4-10.2,17.7-7.2,32.8
	c1.1,5.3,5-15.6,5.1-16.2c0.5-3,3.3-8.9,3.2-11c-0.1-0.9,0-4.4,0.1-4.6c1-2.3,4-8.7,3.7-12C131.1,443.6,130.7,443.5,130.4,443.5z"/>
<path id="skelleftea" class="region" [class.selected]="isSelected('skelleftea')" d="M190.6,167.2l-0.3-2.4c-0.7-0.4-3.2-0.2-2.4-0.3c6.4-0.8,1.2-0.3-2.2-4.1c0,0-2.4-0.9-2.4-1
	c-2.3-4.7,1.8-2.7,1.8-2.7c0-0.5,1.2-0.3,1.1-0.7c-0.6-1.7-4.5,1.2-4.9-1.9c-0.2-1.6,1.6,1.5,2.6-0.3c0.4-0.7,0.1-3.3,0.7-4.4
	c0.6-1.1,3.6-2.1,4-2.9c0.8-1.5-0.5-1.2-0.9-1.1c0.1-0.1,0.5-0.2,1.1-0.5c0.3-0.1,1,0,1-0.4c0-0.8-0.1-1.8-0.4-3
	c-3.7-0.1-10.1-0.3-14.7-1.1c-6.8-1.2-12.1-4.2-14.3-4.1c-2.2,0.1-4.8,5.7-6.7,6.8s-9.3,7.3-10.8,7.9c-0.4,0.2-0.9,0.1-1.4-0.1
	c1.1,0.8,4,3.2,4.9,3.9c1,0.9,3.8,2.9,5.1,6s4.1,4.4,5.7,4.5c1.6,0.1,3.2,2.2,3.6,3.1s2.9,2,5.1,4.1c2.2,2,6.4,2.2,7.4,1.7
	c1-0.4,1.6-2.8,3.1-3.3c1.5-0.6,5.4,2.5,7.4,2.9c0.9,0.2,2.1,0.3,3,0.4c1-1,1.7-1.7,1.8-1.8c0.3-0.5-0.2-0.8,0.2-0.6
	c0.5,0.1,3.8-4.3,3.9-4.8C192.9,164.5,191.6,167.4,190.6,167.2z"/>
<path id="halsingland" class="region" [class.selected]="isSelected('halsingland')" d="M125.2,272.3c0.1-1-1.1-0.5-0.8-2.5c0.5-2.6,1.9-4.3,2.1-5.2c0.2-0.9,0-2.4-0.1-4
	c-2.1,0.2-7,0.7-7.7,0.7c-0.9-0.1-6-1.7-7-1.9c-0.9-0.2-3.9,0-5.4-0.9c-0.8,0.2-1.8,0.5-3,0.5c-2.4,0-6.2-1.7-7.8-2.2
	c-1.6-0.5-3.6-1.1-4.8-0.2c-1.2,0.9-1.3,4.2-1.2,6.1c0.1,1.9-2.5,4.1-3.5,4.7s-2.1,1.9-2.1,4.3c0,2.3-1.6,4.8-2.4,5.1
	s-2.4,0.9-3.3,0.2c-0.9-0.7-2-1.9-2.5-1.7s-2.4,7.4-2.9,8.3c-0.1,0.2-0.3,0.5-0.6,0.8c0.1,0,0.1,0,0.2,0c2,0,3.8,1.4,4.6,1.9
	c0.8,0.6,3.4,2.6,4.7,2.5c1.3-0.1,2.2-1.3,2.6-1.7c0.4-0.5,1.7-0.9,2.6,0.8c0.9,1.7,2.2,3,3,3.8s3.4,1.7,3.8,3.4
	c0.4,1.6,1.4,6,2.8,8c1.5,1.9,3.1,3.6,4.7,3.8c1.6,0.2,5.4-1.8,7.4-2.4c1.9-0.6,5.2-0.2,6.9,0.2c1.3,0.3,5.6-0.3,7.6-0.6
	c0-0.3,0.1-0.7,0.3-1c0.3-0.5,1.6-0.1,1.5-0.7c0-0.6-1.1,0.3-1.6,0.1c-0.3-0.1,0.5-3,0.4-3.2s-1.5-3.4-1.2-4.4
	c0.1-0.5,1.2-0.2,1.2-0.7c0-1.3-1.9-1.8-2.4-3c-0.4-1,0.2,0,0.9-0.1c0.7,0,2.6,0.5,2.2-0.1c-0.6-0.9-3-1.4-3.4-3
	c-0.5-2.6,1.4-0.2,2.2-0.8c0.4-0.3-0.8-0.7-1-1.2c-0.3-0.8-0.3-1.7-0.2-2.5c0.1-0.7-1.8-0.5-1.6-1.2c0.1-0.3-1.6-0.4,1.1-2.3
	c0.5-0.4,2.3,0.7,1.9,0.2c-0.7-0.8-3.1,0-2.9-1.1c0.2-1.3,3.1-0.8,3-2.1c-0.1-0.9-2.9-0.6-2.3-1.1c2.6-2.4,3.5,2.9,6.8,2.7
	c0-0.4,0.1-0.8,0.1-1.3C128.4,273.1,124.9,276.9,125.2,272.3z"/>
<path id="gavle" class="region" [class.selected]="isSelected('gavle')" d="M109.1,315.5c0,1.4-0.1,3.2-0.8,3.8c-0.7,0.6-3,2.9-2.7,4.5s2.7,3.2,4.1,3.9
	c1.4,0.7,3.6,3.1,4.2,4.6s2.5,3.4,3.5,3.4s4-2.2,5.5-2.2s2.6-0.2,2.9-0.4c0.3-0.2,0-2.7,0.6-4.3c0.6-1.6,2.7-2.6,4.2-3.4
	c1-0.5,2.8-2.9,3.8-4.3c-2.3-1.6,0.1-3.5-1.3-4.8c-1.9-1.6-3.6,0.9-5.4,1c-0.2,0-0.4-3.2-3.1-3c-1.2,0.1,3-1.4,2.7-2.7
	c-0.1-0.5-1.2-2.7-1.7-3.7s-2.3-2.5-2.4-4c-2,0.3-6.3,0.9-7.6,0.6c-1.6-0.4-5-0.8-6.9-0.2c-1.3,0.4-3.4,1.4-5.1,2
	c0.4,1,1.1,2.4,1.4,2.8C105.6,309.7,109.1,314.1,109.1,315.5z"/>
<path id="vasteras" class="region" [class.selected]="isSelected('vasteras')" d="M94.9,360.8c0.9,1.1,3.6,2.8,3.4,4.8c-0.2,1.9-1.5,4.4-1.5,5.1s-0.6,3.1,3.2,3.6
	c3.8,0.5,5.8,1.4,7.1,1.7c1.3,0.4,4.5-0.4,5.8-0.7c1.4-0.3,7.3-0.8,7.6-1.9c0.3-1.2,0.7-5.7-2.1-6.5c-2.8-0.8-8-0.1-9.4-0.2
	c-1.5-0.1-2.4-0.5-2.9-1.7c-0.3-0.8-0.3-1.5-0.2-1.9c-0.8-0.4-1.4-1-0.8-1.1c0.5-0.1,1-0.1,1.6,0c0.4,0.1,0.8,0.5,1.2,0.4
	c0.5-0.1,1.2-1.9,1.6-1.4c0.5,0.5,1.2-0.2,2.3-0.8c0.3-0.1,0.4,0.5,0.7,0.6c0.4,0.1,1,0.3,1.3-0.1c2.3-2.5-1.3-5.1,1.1-4.5
	c0.9,0.2,2.8,4.1,3.8,3.7c0,0,0.3-0.5,0.6-1.1c-0.7-1.5-1.6-4.2-1.3-5.9c0.3-1.8,4.3-1.6,5-2.7c0.8-1.1,0.6-4.1,0.9-5.1
	c0.3-1.1,2.5-3.9,3.7-5.1c1.2-1.3,0.9-3.2,0.5-4.3c-0.2-0.6-1.4-1.8-2.3-2.7c-0.4,0.2-1.4,0.3-2.8,0.3c-1.6,0-4.6,2.2-5.5,2.2
	c-0.2,0-0.4-0.1-0.6-0.2c-0.6,0.9-1.2,2.1-1.4,2.3c-0.4,0.5-2.7,2.4-3.5,3.4s-3.3,1.6-4.5,0.8c-1.2-0.8-3-2.9-3.9-3.4
	s-2.6-0.4-4.3,0.3c-1.6,0.7-1.6,4.1-1.6,5.6s-0.6,2.4-1.5,3.1s-3.6,1.2-4.5,1.4c0.1,1.6,0.3,4,0.4,5.4
	C92.2,356.6,94,359.7,94.9,360.8z"/>
<path id="norrkoping" class="region" [class.selected]="isSelected('norrkoping')" d="M134.5,384.3c-2-0.1-5.9-0.4-8.1-0.9c-3.1-0.8-4.6-3.9-5.1-5.1c-0.4-0.8-1-2.9-1.4-4.3
	c-1.6,0.8-5.8,1.2-7,1.4c-1.4,0.3-4.6,1.1-5.8,0.7c-1.2-0.4-3.1-1.2-6.5-1.7c-0.2,1.7-0.7,4.5-1.4,5.9c-1,2.1-2.2,4.7-3.4,6
	c-1.2,1.4-4.8,4.9-4,7.3s4.3,5,5.5,6c1.3,1.1,4.4,3.8,5.2,4.6s5.6,4.6,7.3,5.4c1.7,0.9,4.6,3.9,4.8,4.2c0.2,0.3,2.7,1.2,3.2,1.5
	c0.3,0.2,1.8,0.7,3.1,1.2c0.2-0.5,1.2-0.6,1.2-1.2c0-0.1-2.7-3.7-3-4.4c-0.6-1.5,3.7,4,3.9,2.4c0.1-1.3,0.9-2.9-0.3-4.5l-2.1-0.5
	c-0.1-1.2,0.4-1.8,0.5-2c-0.5,0.4-0.9,1.6-2.1-1.2c-0.3-0.7,4.2,1.7,1.6-2l-3.1-1.1c-0.4-1.3,2.5,0.5,3.7,0.5
	c2.1-0.1,1.1-2.6,3.5-2.8c0.4,0-0.8-0.3-1-0.6c-0.9-0.9-1.5-3.3-2.3-3.8c-0.7-0.4-5.6-0.9-7.1-0.6l-0.6,1.6l-1.4-1.5
	c-1-0.1-3.9,0.3-3-0.2c4-2.2,17.2-1,17.1-0.9c0.5-0.5,0.2-0.3,1-0.7c0.7-0.4,1.9-1.6,1.2-2c-0.9-0.5-2.4-0.3-2.5-1.8
	c-0.1-1.6,3.8,1.4,4.4,1c0.5-0.3,0.5-1.1,0.9-1.3c0.4-0.2,1,0.2,1.3-0.1c0.3-0.3,0.4-0.9,0.2-1.3s-1.3-0.2-1-0.6s1-0.1,1.5-0.1
	s1.1,0.2,1.5-0.1C135.8,386.2,135,385.2,134.5,384.3z"/>
<path id="orebro" class="region" [class.selected]="isSelected('orebro')" d="M80.8,385.3c3-1.7,4.9-1.6,6.7-0.2c1.8,1.4,3.1,2.9,4,3.7c0.4,0.4,1,0.8,1.4,1.1
	c1-1.5,2.3-2.8,2.9-3.6c1.2-1.4,2.4-3.9,3.4-6c0.6-1.4,1.1-4.2,1.4-5.9c-0.2,0-0.4-0.1-0.6-0.1c-3.8-0.5-3.2-2.8-3.2-3.6
	s1.3-3.2,1.5-5.1s-2.5-3.7-3.4-4.8c-0.9-1.1-2.7-4.2-2.9-6.5c-0.1-1.5-0.3-3.8-0.4-5.4l0,0c-0.9,0.1-1.9-0.8-2.8-1.5
	s-3.4-3.3-5.8-3.6c-2.4-0.3-3.7,1.3-5,2.2c-0.9,0.6-2.4,0.6-3.7,0.3c-0.7,0.5-1.5,1.3-1.7,1.8c-0.4,1.1,0.4,3.5,0.6,4.4
	s0.4,5.5,0.5,6.6s-0.1,3.2-0.4,4s-0.7,1.8-0.7,1.8s1.1,1.2,0.6,1.9c-0.5,0.8-3.5,3.7-2.5,4.7s4.5,1.7,4.8,2.6s0.5,4.5-1,6.6
	c-1.1,1.7-2.4,2.6-3.5,3c0,0.9,0.1,1.9,0.3,2.4c0.4,1.1,2.4,4,3.2,5.1c0.7,1,2,3.6,2.3,5.4c0.2-0.2,0.4-0.4,0.5-0.5
	C77.4,393.4,78.5,386.6,80.8,385.3z"/>
<path id="linkoping" class="region" [class.selected]="isSelected('linkoping')" d="M114.7,413.8c-0.2-0.3-3.1-3.3-4.8-4.2c-1.7-0.9-6.4-4.7-7.3-5.4s-4-3.5-5.2-4.6
	c-1.3-1.1-4.8-3.6-5.5-6c-0.4-1.1,0.2-2.5,1-3.7c-0.5-0.4-1-0.8-1.4-1.1c-0.9-0.8-2.1-2.3-4-3.7c-1.8-1.4-3.7-1.6-6.7,0.2
	c-2.4,1.4-3.4,8.1-3.7,11c0.4-0.3,0.6-0.5,0.6-0.5c1.5-0.5,4.5-5.2,5-5.5s1.1-0.3,2.3,2.4c1.3,2.7-0.5,5.2-1,6.6s1.4,0.9,1.6,1.7
	c0.2,0.9-3.4,3.5-5.3,5.6s-2.6,6.9-2.7,8.7c-0.1,1-0.4,2.4-0.7,3.5c0.7,0,1.7,0.1,2.9,0.1c1.9,0,3.8-1.7,6.1-1.2
	c2.3,0.6,1.4,8.8,1.8,10.5c0.5,1.6,2.9,5.1,3.9,5.2s3-1.4,3.9-2.7c0.9-1.4,3.8-3.6,5-4s3.2,1.4,4.2,2.2c1,0.9,1.9-0.4,2.3-1.2
	c0.4-0.8,1.1-2.8,0.7-4s-1.1-3.4-1-3.8s1.6-1.6,2.1-1.7c0.6-0.2,2.1-1.8,4.2-2.5c1.2-0.4,2.9-0.7,4.1-1
	C116.1,414.5,114.8,414,114.7,413.8z"/>
<path id="vast" class="region" [class.selected]="isSelected('vast')" d="M30.2,419.5c1.9-0.9,4.6-3.4,5.6-3.9c1.1-0.5,2.7-1.1,3.8-1.7c1.1-0.7,1.3-3.6,1.5-5.3
	c0.1-0.9,0.2-2.1,0.2-3.1c-1.1-0.2-2.8-0.7-3.8-0.8c-1.4-0.2-2.1,1.7-3.2,2.7c-1.1,1.1-3.7,0.7-4.2-0.8s5.6-6.5,6.5-8.2
	s-2.9-5-3.2-5.7c-0.3-0.8,1.5-1.4,3.2-2.4c1.7-1.1,1.8-3.4,2.2-5.6s2.4-3.8,2.4-3.8c0.1-0.1,0.3-0.2,0.4-0.3
	c-0.4-0.3-0.9-0.6-1.4-0.8c-1-0.4-4.2,0.1-5.7-1.2c-1.6-1.3,0.6-3.7,0.9-5.6c0.3-1.9-0.6-3.6-1.4-4.8c-0.8-1.2-1.4-3.3-1.7-4.2
	c-0.4-0.9-1.9-1.6-3.1-2s-2.8-2.3-4-3.9c-0.7-1-1.6-2.2-2.1-3c-0.8,1.5-4.1,2-4.5,3.6c-1.4,5.1,6,19.8-0.6,26.3
	c-3.9,3.9-3.9-0.7-4.4-4.1l0,0c-0.8,0-2-6.7-5.2-0.5c-1.7,3.2-0.3-0.5,0.7,1.9l-1.7,1.3l2,1.3c1.1,2.9-0.9,6.1-0.4,7.7
	c0.6,1.7,2,3.8,2,5.8c0,2.5-1.8,5.4-1.5,7.7c0.1,0.4-0.2,1.3,0.2,1.3c1.4,0,2.3-2.5,3.6-2.7c0.8-0.1,1.4,1.4,1.1,2.2
	c-0.2,0.5-1.1,0.2-1.4,0.6c-0.2,0.4,0.3,2.6,0.3,2.6c1.4,0.4,2.4-1.5,2.7-2.5c0.6-1.7,0-3.7,0.3-5.2c0.2-0.9,0.2,2.3,1,2.6
	c0.5,0.2,0.9-0.6,1.4-0.6s-0.8,0.8-1,1.3c-0.7,1.5-3.1,3.8-2.4,5.8c0.2,0.6,1.2-0.3,1.7-0.6c3.1-1.7,1.9-3.2,6.1-3.2
	c0.4,0-0.6,0.4-0.8,0.6c-0.3,0.2,0.3,5.9-0.1,7.8c-0.3,1.2-1.7,5.5-2.6,8.4c1.4-0.3,3.7-0.9,4.6-1.3
	C25.6,422.4,28.3,420.4,30.2,419.5z"/>
<path id="goteborg" class="region" [class.selected]="isSelected('goteborg')" d="M31.8,443.9c0-1.1-1.4-4.1-1.7-5.3c-0.4-1.3,2.4-3.7,3.1-4.6c0.7-0.9,3.1-4.8,3.6-6.1
	c0.5-1.4-1.7-3.8-1.9-4.6c-0.2-0.8,0.6-1.8,1.7-2.7c1-0.9,1.6-2.6,1.8-3.9c0.1-0.6,0.1-1.5,0-2.2c-0.9,0.5-1.7,0.8-2.5,1.1
	c-1.1,0.5-3.7,3-5.6,3.9s-4.7,2.9-6,3.6c-0.9,0.5-3.2,1-4.6,1.3c-0.5,1.5-0.8,2.6-0.8,2.6c0.2,0.9,2.9,1.7,3.1,2
	c0.2,0.2-3,2-2.4,3.2c0.2,0.4,0.6-0.6,1-0.6c3.7,0.1,0.8,3.5,2.4,4.6c0.3,0.2,0.8-1,1-0.6c1,1.6-0.4,8.9,0.4,10.4
	c1,1.9,2.1-6.9,3.4-3.2c0.5,1.3-1.9,2.9-1,3.9c1.1,1.2,1.4-1.1,2.1,0.7c0.2,0.4,0.2,0.8,0.1,1.1c1-0.1,2-0.3,2.4-0.6
	C32,447.2,31.8,445,31.8,443.9z"/>
<path id="boras" class="region" [class.selected]="isSelected('boras')" d="M38.2,448.4c0.8,0.5,1.9,2,2.6,2.5c0.7,0.5,1.9,1.1,2.6,1.9c0.7,0.9,1.9,2.1,2.5,1.9
	s1.7-1.9,2.1-2.7c0.4-0.8,9-10.1,9.7-12s2.7-11.9,2.4-13.2c0.2-1.3-2.6-3.1-4.8-3.9c-2.1-0.8-2.6-3.6-4.9-4.1
	c-2.2-0.5-6.9-1.2-8.2-1.8c-0.9-0.5-2.6-1.6-3.8-2.5c0.1,0.7,0.1,1.5,0,2.1c-0.3,1.3-0.8,3.2-1.9,4.1c-1.2,0.9-1.8,2-1.5,2.8
	c0.3,0.7,2.3,3,1.8,4.4s-3,5.3-3.7,6.2c-0.7,0.9-3.3,3.2-2.9,4.5c0.4,1.3,1.4,4,1.6,4.9c0.2,0.9,0,2.9-0.1,3.7
	c0.7,0.2,1.6,0.8,2.1,1C34.7,448.5,37.4,447.9,38.2,448.4z"/>
<path id="varmland" class="region" [class.selected]="isSelected('varmland')" d="M29.3,362.1c1.2,0.4,2.7,1.2,3.1,2c0.4,0.9,1,3,1.7,4.2c0.8,1.2,1.7,2.8,1.4,4.8
	c-0.3,1.9-2.4,4.4-0.9,5.6c1.6,1.3,4.8,0.8,5.7,1.2c0.5,0.2,1,0.5,1.4,0.8c1.1-0.6,2.5-0.9,3.7-0.5c1.5,0.5,3.5,4.9,3.5,4.9
	s0.8-3.6-0.4-6s-2.4-6.9-1.8-8.3c0.6-1.4,5.9-1.4,7.2-1.4s2.9-1,3.5-1.7s2.4-0.2,3.4,1.4s2.8,1.5,5.3,3.1c2.5,1.7,1,4.9-0.1,6
	c-1.1,1.2-0.6,2.2,0.2,3.7c0.2,0.4,0.3,1,0.3,1.8c0.9,0.1,2.6,0.3,3.3,0.3c1.1,0,3.2-1.2,4.7-3.3s1.3-5.7,1-6.6s-3.8-1.7-4.8-2.6
	c-1-1,2-3.9,2.5-4.7c0.5-0.8-0.6-1.9-0.6-1.9s0.4-1.1,0.7-1.8c0.3-0.8,0.5-2.9,0.4-4s-0.3-5.7-0.5-6.6s-1-3.3-0.6-4.4
	c0.2-0.6,1-1.3,1.7-1.8c-0.7-0.2-1.6-0.5-2.1-0.8c-1.8-0.8-3.7-4.9-4.6-6.1s-2.3-0.6-2.7-0.2c-0.4,0.4-1.4,1-1.8,0.6
	c-0.5-0.4-1-3.5-1.5-4.6s-4-3.7-5.1-4.5c-1.2-0.8-2.3-3.5-2.4-4.1c-0.1-0.6-1.4-2.8-2.3-3.7c-1-0.9-3.6-5.3-4.8-7.2
	c-1.2-1.8-4.9-7.2-5.9-8.8c-0.7-1-2.4-3-3.6-4.3c0,0.1-0.1,0.1-0.1,0.2c-1.5,1.3-8-0.4-7.9,3.1c0.1,6.7,7.1,12.8,7.2,19.7
	c0,2.7-1.7,4-2.2,6.4c-0.4,1.9,0,4.6,0.1,6.6c0,0.6,0.2,1.2,0,1.7c-2.7,6.8-12.5,7.5-13,8.8c-0.3,0.8,2.3,4,1.6,5.8
	c0,0.1-0.1,0.1-0.1,0.2c0.5,0.8,1.4,2.1,2.1,3C26.4,359.7,28.1,361.7,29.3,362.1z"/>
<path id="skaraborg" class="region" [class.selected]="isSelected('skaraborg')" d="M66.5,383.7c0,2.1-0.8,5.3-1.7,7.8c-1.3,3.4-5.1,3.8-8,5.2c-2.9,1.5-8.4,8-9,7.6s0-5.5-0.4-5.2
	s-4.7,6.2-5.2,6.4c-0.1,0.1-0.5,0-1-0.1c0,0.9-0.1,2.2-0.2,3.1c-0.2,1.7-0.4,4.7-1.5,5.4c-0.3,0.2-0.6,0.3-1,0.5
	c0,0.1-0.1,0.1-0.1,0.1c1.2,0.9,3,2,3.9,2.5c1.3,0.7,5.9,1.4,8.2,1.9c2.2,0.5,2.7,3.2,4.8,4c2,0.7,4.4,2.3,4.8,3.6
	c0.4-0.2,2.5-1.5,3.4-2.3c0.5-0.5,1.1-1.6,1.6-2.6s0.9-1.9,0.9-1.9l1.5-0.1c0.1-0.5,0.3-1.4,0.3-1.6c0.2-1.4,2.3-7.3,3.3-10
	s3.9-8,4.3-9.5c0.2-0.6,1-1.5,1.4-1.9c-0.3-1.8-1.7-4.4-2.3-5.4c-0.8-1.2-2.7-3.9-3.1-4.9c-0.2-0.6-0.4-1.8-0.4-2.6
	c-0.3,0.1-0.9,0.3-1.2,0.3C68.7,384,67.4,383.8,66.5,383.7z"/>
<path id="kalmar" class="region" [class.selected]="isSelected('kalmar')" d="M131.6,444c0.2,3.2-2.7,9.7-3.8,12c-0.1,0.1-0.1,3.6-0.1,4.6c0.1,2.1-2.7,8-3.2,11
	c-0.1,0.7-4,21.5-5.1,16.2c-3.1-15.2,2.4-20.5,7.2-32.8C127.7,452.3,128.1,441.1,131.6,444z M119.1,435.6c-1.1-0.6-1.7-1.2-0.6-2
	c0.4-0.3,0.6,1.2,1.1,1.2c2.4,0.3-0.4-4.4-0.3-4.5c0.7-0.8,3.1,0.9,3.1-0.2c-0.1-1.9-5.2-4.2-6.2-6.5c-0.1-0.3-0.7-1.1-0.4-1
	c0,0,0-0.6,1.3-0.7c0.8,0,1,1.2,1.8,1.2c0.2,0,0.5-1.6,0.2-2c-1.3-1.6-1.7,0.7-1.8-1.9c0-0.9,1.6,0.6,2.1,1.2
	c1.2,1.3,3.4,2.7,3.6,2.4c1.1-2.4-2.4-3.4-2.3-3.8c0.1-0.5-0.7-0.8-0.8-1.3c-0.1-0.4-0.1-1.1,0.3-1.3c0.6-0.3,0.7,0.6,0.7,0
	c0-0.1,0-0.1,0-0.2c-1.3-0.5-2.8-1-3.1-1.2c-0.2-0.1-0.5-0.2-0.9-0.4c-1.2,0.2-3,0.6-4.1,1c-2,0.7-3.6,2.3-4.2,2.5s-2,1.4-2.1,1.7
	c-0.1,0.4,0.6,2.6,1,3.8s-0.3,3.2-0.7,4c-0.4,0.8-1.4,2-2.3,1.2c-1-0.9-3-2.6-4.2-2.2s-4.1,2.6-5,4c-0.4,0.6-0.9,1.1-1.5,1.6
	c0.3,0.7,0.8,1.6,1.1,1.9c0.7,0.6,2.7,2.4,2.7,4.2s-0.4,5.6-1.2,7.3c-0.8,1.6-1.4,3-0.3,4.1c0.2,0.2,0.6,0.6,0.9,0.8l0.2,0.2l0,0
	c-0.3,0-0.6,0-0.8,0.1c-1,0.2-7.7,0-9.3,1.1c-1.6,1.1-3.2,2.1-2.5,2.4s3.6,2.2,4.3,4.9c0.7,2.6,0.8,6.4-0.2,8.3s-1,2.9-0.2,3.3
	s3.4,1.6,3.7,3.1c0.3,1.5-0.4,4.6,1.6,5.3c1.9,0.8,3.6,0.7,4.2,0.6s4.4-0.5,5,0.8s0.8,3.5,2.1,4.2c1,0.5,4.7,1.4,6.8,1.8
	c0.9-3.8,1.3-7.3,2.7-10.5c0.6-1.3-0.7-3.3,0.8-4.6c5.2-4.3-0.5,0.2,1-3.7c0.4-0.9,2.5,0.2,2.7-0.8c0.6-3-1-0.6-1.1-0.6
	c-1.7,0.1,0.2-3.2,0.2-3.8c0-2-0.7-6,0.1-7c0.8-1.1,2.5,0.3,2.8-0.1c0.5-1.1-2.3-3.1-2.7-4.4c-0.9-3.1,2.9-11.3,4.2-11.9
	c2.4-1.3-0.8-2.3-3-3.4L119.1,435.6z"/>
<path id="halland" class="region" [class.selected]="isSelected('halland')" d="M62.3,476.3c-0.2-1.5-1.7-1.8-3.1-1.9c-1.4-0.1-5,0.5-6.7-0.7s-0.6-6,0.4-7.2s3.8-1.7,5.4-2.6
	c1.6-1,1.7-2.5,1.1-4c-0.7-1.5-1.1-4.6-0.9-6.1c0.2-1.6-0.2-3-0.6-3.9c-0.4-0.9-0.4-4.1-0.4-6.1c0-1,0.3-2.1,0.5-2.9
	c-0.2,0.2-0.6,0.3-1,0.4c-2.3,3.2-8.6,10.1-8.9,10.7c-0.4,0.8-1.6,2.5-2.1,2.7c-0.6,0.2-1.8-1.1-2.5-1.9c-0.7-0.9-1.9-1.5-2.6-1.9
	c-0.7-0.5-1.8-2-2.6-2.5s-3.9,0.1-4.5-0.1c-0.5-0.2-1.4-0.8-2.1-1c-0.1,0.2-0.2,0.4-0.3,0.5c-0.4,0.3-1.4,0.5-2.4,0.6
	c-0.1,0.7-0.7,1.3-1.1,2.1c-0.1,0.1,1.5,1.2,2.1,2c0,0.1,3.2,10.3,3.2,10.4l2.8,1.3c2.3,1.8,3,5.4,3.9,7.8c1.2,3.3,5.6,0,5.7,5.9
	c0.1,7.4-5.3-0.4-6.6,4.6c-0.1,0.2,1.2,1.4,2.4,2.8c1-0.1,2.3-0.2,3.3-0.1c1.8,0.3,4.4,0.9,6.1,0.1c1.7-0.8,7.5-3.1,10.4-3.8
	c0.3-0.1,0.6-0.1,1-0.2C62.2,479.7,62.4,477.3,62.3,476.3z"/>
<path id="blekinge" class="region" [class.selected]="isSelected('blekinge')" d="M105.1,484.9c-1.4-0.7-1.6-2.9-2.1-4.2c-0.6-1.3-4.4-0.9-5-0.8c-0.5,0.1-2,0.2-3.7-0.4
	c-1.2,0.2-2.7,0.6-3.8,0.7c-2,0.3-4.8,3.4-6.7,4.4c-1.9,1-4.2-1-5.5-1.8c-0.7-0.4-1.5-1-2.5-1.4c-0.4,0.9-1,2.5-1.1,3.3
	c-0.1,1.2-0.8,3.5,0.8,5.3c1.6,1.8,2.8,3.5,2.9,5c0,0.7,0.2,1.6,0.4,2.4c0.1-0.1,0.1-0.1,0.2-0.2c1.8-2,3.5,2.1,5.4,1.2
	c2.7-1.3-2.8-4.3-1-5.8c2.4-2,1.5,0.5,3,0.5c0.4,0-0.1-1.1,0.3-1.3c1-0.6,3.3,1.2,4.3,0.5c0.4-0.2-0.1-1.3,0.3-1.3
	c0.8,0,2.2,1.5,3.6,1.2c0.6-0.1,0.7-1.3,1.4-1.4c0.6,0-0.3,1.5,0.1,2c0.5,0.6,5.5-3.5,6.2-2.9c0.3,0.3-0.7,1.1-0.3,1.3
	c0.5,0.3,3.8-0.2,5,0.5c1.3,0.7-2.2,1.3,0.8,2.6c0,0,0.9-2.3,1.1-2.6c0.7-1.7,2.4-2.2,2.9-4.1c0.1-0.3,0.1-0.5,0.2-0.8
	C109.8,486.2,106,485.3,105.1,484.9z"/>
<path id="skane" class="region" [class.selected]="isSelected('skane')" d="M43.1,490c-1.2,1.6-7.3-4-6.7-1.6c0.6,2.7,4.5,12,6.6,15l0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4
	c1.6,1.7,1.4-0.6,2.5,2c0.2,0.4,0.1,0.9,0,1.3c0,0.4-0.5,0.9-0.3,1.3c0.5,1.4,2.3,1.5,3.3,3.3c1,1.8-3.2,2.8-3.2,3.9
	c0.1,1.4,1.8,3.9,1.5,5.2c0,0-5.1,0.8-3.2,2c1,0.6,2.1-0.7,2.5-0.7c2.3,0,5.7,3.7,9.4,2.6c2.6-0.8,3.7-2.4,5.7-3.3
	c1.4-0.6,3.6-0.2,5-0.7c2.2-0.8,4.3,3.5,6.9,1.8c6-4,0.8-8.8-0.4-13.1c-1.2-4.4,2.9-9.6,5.5-12.4c-0.2-0.8-0.3-1.8-0.4-2.4
	c-0.1-1.5-1.4-3.1-2.9-5c-1.6-1.8-0.9-4.2-0.8-5.3c0.1-0.8,0.7-2.3,1.1-3.3c-0.9-0.4-1.9-0.9-2.9-1.1c-2.1-0.5-8.7,0.7-11.6,1.4
	c-2.9,0.7-8.6,3-10.4,3.8c-1.7,0.8-4.3,0.2-6.1-0.1c-1-0.2-2.3-0.1-3.3,0.1c1.2,1.4,2.2,3.1,1.9,4.4v0.1L43.1,490z"/>
<path id="stockholm" class="region" [class.selected]="isSelected('stockholm')" d="M161.9,343.9c-1.2-0.2-1.3-3.1-2.4-2.8c-1,0.3,1.2,2,1.2,3.1c0,1.3-2.8,2.1-2.1,3.2
	c0.2,0.3,2-0.8,2.1,0.7c0.1,1-2.4,4.1-3.2,4.8c-0.4,0.3-1.4-0.4-1.6,0.1c-0.3,0.5,0.7,1.3,0.5,1.9c-0.3,0.9-3.2,1.5-3.8,2.2
	l-1.1,2.7c-0.4,0-0.9,0.1-1.3,0.1c-0.4,0-1-0.2-1.3,0.1c-0.7,0.8,2.3,2.9,1.2,3.1c-4,1-2.1-5.1-4.1-2.3c-0.4,0.5,0.2,1.5-0.2,1.9
	s-1.5-0.5-1.6,0.1c-0.2,1.2-4.7,2.7-5.3-0.3c-1-5.1,2.7-2.2-0.4-7.5c0.3-0.5,0.8-1.4,1.2-1.9c0.7-0.9,3.1-2.6,4.4-3.4
	c1.3-0.8,3.7-4.6,4.3-5.7c0.6-1.2,0.5-3.6,0.8-5.2c0.2-1.2,1-4.7,1.4-6.4c0.9,0.4,1.9,0.8,2.1,1.1c0.6,0.8,0.3,3.8,0.7,2.9
	c0.7-1.7,0.1-4.7,2.8-2.1c0.4,0.4,0.5-1.1,1.6-0.1c0.7,0.6,4.6,6.1,4.7,8.6C162.3,343.2,162.3,344,161.9,343.9z M154.7,362.1
	c-0.7-0.6-2.7-2-2.4-1.1c0.4,1.2,1.7,1.5,1,3.8c0,0.1-3.1,0.2-4.9,1c-0.5,0.2-3.8-0.7-5-0.3c-2,0.6-1.6,4.8-1.7,4.6
	c-0.8-2.3-1.3-0.5-3.4-1.7c-0.7-0.4-2-1.2-2.4-0.5s1.1,1.9,0.5,2.5c0,0-1.8-3.1-3.6-3c-0.4,0-1.9,4.6-4.1,2.2
	c-0.3-0.4,1-0.3,1.3-0.7l-0.8-1.9c0.1-0.1,2.6,0.3,1.2-1.4c-1.1-1.3-4.4,0.2-5.6-0.3c-1.4-0.6-2.4-4.4-4.2-4.3
	c-0.9,0-2.7-0.2-2.6,0.8c0,0,2.5,1.5,2.5,1.6c0.2,1.2-3-1.1-4.2-1.2c-2.9-0.4-5.4,1.2-8.5,1.4c-0.5,0-1.4-0.3-2.1-0.7
	c-0.1,0.4-0.1,1.1,0.2,1.9c0.5,1.3,1.5,1.7,2.9,1.7c1.5,0.1,6.6-0.6,9.4,0.2c2.8,0.8,2.4,5.3,2.1,6.5c-0.1,0.2-0.3,0.4-0.6,0.5
	c0.4,1.4,1,3.5,1.4,4.3c0.6,1.3,2,4.4,5.1,5.1c2.2,0.6,6.1,0.8,8.1,0.9c-0.4-0.7-0.7-1.3-0.2-1.5c3.3-1.3,2.3-0.2,2.7-0.2
	c0.3,0,0.7,0.1,1-0.1c0.7-0.5-0.7-1.7-0.5-2.5c0.3-1.1-0.3-7.7,0.5-7.8c0.1,0,1.1,1.2,2.1,1.1c0.4,0-0.5,8.8,1,9.6
	c0.9,0.5,2,2,2.4,0.5c0.4-1.6,2.5-9.9,5.6-10.1c0.6,0-0.4,1.3-0.2,1.9c0.1,0.4,4.3-4.2,4.3-4.2c-1.6-2.6-3-0.7-3.4-5.5
	c0-0.3,6.2,1.5,7.2-0.6C157.1,364.8,156.5,363.6,154.7,362.1z M120.1,357.3c1.5-0.9,3.9,2.9,5,1.6c0.2-0.2-2.6-2.9-0.2-2.6
	c0.7,0.1,3.1,1.7,3.1,1.7c1.6,0.8,2.3,2.4,3.8,3c0.2,0.1-2.2-6.9-0.8-7c0.7,0,0,1.6,0.5,2.2s1.5,2,2.1,2.1c0.4,0.1,0.7-1.1,0.9-0.7
	s-0.3,1.2-0.2,1.7c0,0.3,3.4,2,3.8,2c1-0.1,0.7-4.2,0.7-4.2c-0.4-1.9-2.5-1.7-3.4-3.2c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2
	c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.4,0-0.6,0.1-1s0.2-1-0.1-1.3c-1.6-1.1-1.8,2.1-1.7,3.3c0,0.7,0.8,2.6,0.5,2s-1.5-2.8-1.6-3.2
	l0.4-0.7c-0.6-0.1-2.4-0.5-3-1c-0.8-0.6-3-3-4.2-3.5c-0.7-0.3-1.3-0.7-1.7-1c-0.1,1.3-0.2,3-0.7,3.8c-0.8,1.1-4.8,0.9-5,2.7
	c-0.3,1.7,0.7,4.4,1.3,5.9C119.6,358.1,120.1,357.3,120.1,357.3z M175.5,415.8c-1,0.3-2.2,0.2-3,0.9c-0.4,0.3,0.2,4-0.3,3.9
	l-2.3-2.4C169.1,415.3,174.7,413.4,175.5,415.8z M166.5,420c-0.3-0.5-0.8-1.6-1.1-1.2c-0.2,0.2,0.1,3.9-0.7,4
	c-1.2,0.1,0-5.4-2.7-3.7c-2.5,1.6,0.5,1.2-1.1,3.3c-0.6,0.9-2.3,0.1-3,0.9c-1.5,1.6-1.7,4.9-2.9,6.7c-4.1,6.1-3.3,3.8-1.6,10.5
	c0.8,3-1.8,3.2-0.2,5.9c1.1,2,1.7,4.3,1.8,5.7c0,0.5,0,1.3,0.4,1.3c0.5,0,0.5-1.7,0.9-1.4c1,0.8-3.5,5.7-2.6,6.7
	c0.4,0.4,1.2,0,1.7-0.1c5.1-1.3,1.3-4.9,2.3-6.7l2-1.5c0-0.4-1.2-0.2-1.1-0.6c0.6-2.5,5.1-2.8,6.2-4.4c0.1-0.2-1.8-3,0.9-5.3
	c0.6-0.5,2.5-0.5,2.6-1.5c0.2-2.6-2.2-0.1-3.3-2.3c-0.7-1.3-0.2-4.7-0.5-5.8c-1.4-5.8,2.2-3.4,3.1-4.2l0.1-2.6
	c0.8-0.7,2.4-0.5,2.6-1.5c0.3-1.2-1.3-2.1-2-3.1C168.2,419.3,166.8,420.4,166.5,420z"/>
<path id="uppsala" class="region" [class.selected]="isSelected('uppsala')" d="M153.1,330.1c-0.6-1.2-2.6-0.6-3.4-1.7c-2.1-2.7,3.3-0.2-0.2-2.5c-1.1-0.8-3,0.9-3.8,0.9
	c-0.6,0,0.4-1.3,0.5-2c0.1-0.3-0.6,0.1-1,0.1c-0.3,0-0.7,0.2-1,0.1c-3.5-2.1-3.6-7.5-5.4-7.9c-3.2-0.8-2.5,3.6-3.2,4.7
	c-0.2,0.3-0.7-0.4-1-0.6c-0.1-0.1-0.2-0.1-0.3-0.2c-1,1.4-2.8,3.7-3.8,4.3c-1.5,0.8-3.6,1.8-4.2,3.4s-0.3,4.1-0.6,4.3
	c0,0-0.1,0-0.1,0.1c0.9,0.8,2.1,2,2.3,2.7c0.4,1.1,0.7,3-0.5,4.3s-3.4,4.1-3.7,5.1c-0.1,0.3-0.1,0.8-0.2,1.4c0.4,0.3,1.1,0.7,1.7,1
	c1.2,0.6,3.5,3,4.2,3.5c0.6,0.5,2.4,0.9,3,1l0.5-0.9c-0.6-0.8-3.3-0.1-2.7-0.8c0.4-0.4,1.1-0.4,1.6-0.4s1.1,0.1,1.6-0.1s1-3,1.9-1.1
	c1,2.5-0.3,3.8-0.3,5c0,0.1,0.1,0.2,0.1,0.3c0.5,0.2,1.9,0.7,1.9,0.7c0.3-0.2-0.1-0.8-0.1-1.3c0-0.6,0-1,0.2-1.6
	c0.3-1,0.3,2.2,0.9,3.1c0,0.1,0.1,0.1,0.1,0.2c0.3-0.5,0.8-1.4,1.2-1.9c0.7-0.9,3.1-2.6,4.4-3.4c1.3-0.8,3.7-4.6,4.3-5.7
	c0.6-1.2,0.5-3.6,0.8-5.2c0.2-1.2,1-4.7,1.4-6.4c-1.4-0.6-2.5-1.3-0.3-1.5c1.3-0.1,0.5,0,1.9-0.2
	C152.5,330.8,153.2,330.4,153.1,330.1z"/>
<path id="kronoberg" class="region" [class.selected]="isSelected('kronoberg')" d="M82.5,451.1c-1.6,0.6-4,2.5-5.1,2.9c-1.2,0.4-4.1,0.5-4.3,1.5c-0.2,1-0.6,3.8-0.5,5.4
	s0.5,2.9,0,3.2c-0.5,0.3-2.9-0.5-3.3-1.8s-2.2-4.3-3.1-3.6c-0.9,0.7-1.1,1.5-2.3,1.8c-1,0.3-3.1,0.6-4.1,0.4
	c0.2,1.1-0.2,2.1-1.4,2.9c-1.6,1-4.5,1.5-5.4,2.6c-1,1.2-2.1,6-0.4,7.2c1.7,1.2,5.3,0.6,6.7,0.7s2.9,0.5,3.1,1.9
	c0.1,0.9,0,3.4-0.2,5c3.2-0.7,8.7-1.6,10.6-1.1c2.1,0.5,4.1,1.6,5.4,2.5c1.4,0.9,3.6,2.8,5.5,1.8s4.7-4.1,6.7-4.4
	c1.1-0.2,2.7-0.5,3.8-0.7c-0.1-0.1-0.3-0.1-0.4-0.2c-1.9-0.8-1.3-3.9-1.6-5.3s-2.9-2.7-3.7-3.1c-0.8-0.4-0.8-1.4,0.2-3.3
	s0.9-5.7,0.2-8.3c-0.7-2.6-3.6-4.6-4.3-4.9c-0.4-0.2,0-0.6,0.6-1.1C84.9,452.5,83.8,450.7,82.5,451.1z"/>
<path id="norrbotten" class="region" [class.selected]="isSelected('norrbotten')" d="M226.1,103.7c-3.5-4.4-6.4-5.2-7.6-11.6c-0.6-3.4,2.2-3.8,3-6.5c0.6-1.9-1-4.5-0.6-6.5l1.4-2
	c-0.1-4.6-8.5-7.3-8.9-11.8c-0.3-3.1,2.5-3.5,2.7-6.3c0.4-5.4-4.9-1.6-6.3-3.8c-1-1.5,1.2-3,1.3-4.3c0.2-2.9-3.7-7.2-2.2-10.3
	c0.5-1,1.9-1.1,2.4-2.1c1-1.8-3.1-2.6-4.5-4c-0.4-0.4-0.8-0.9-1.2-1.3c-1.4-1.5-3.1,0.1-3.5-0.4c-0.5-0.6,0-3-1.1-3.9
	c-6.2-6-11.9-2.9-17.9-5.8c-2.6-1.2-17.9-10.5-19.7-12.1c-1.2-1.1,1.2-2-0.9-3.3c-0.5-0.3-3.1-0.9-4.2-1.4l-0.4,0.2
	c-1.2,0.1-5.1-0.2-6,0.6c-0.5,0.4,3.5,6.7,3.3,8.8c-0.3,2.7-5.7,6.9-5.5,8.6c0.4,3,7-0.6,2.8,4.5l-2.4,2.9c-3.8,1.4-26.1-8-29.8-3.3
	c-1,1.2,4,17.1-1.8,19.3c-2.6,1-7.4-4.3-10.2-4c-0.7,0.1-0.7,1.3-1.2,1.8c-1.9,1.8-5.5,2.9-7,5.1c-2.1,3.2-2,8.5-4,12.1
	c-1.1,2-5.8,2.3-5.6,5.3c0.4,5.3,8.3,6.9,5.1,13.9c-0.9,2-3.9,3.3-5.3,5c-2.4,3-3.5,7.2-5.9,10.3c-0.2,0.3-0.6,0.6-1,1
	c1.4,1.4,3.8,4,4.8,4.8c1.5,1.2,10.8,5.7,12.5,7.4c1.7,1.7,3.5,3.8,6.3,5.1c2.8,1.3,8.7,7,11.6,9.8c2.9,2.8,6.7,4.7,9.3,4.7
	s5.8,0.6,4.4,4.2c-1.5,3.6-3.3,7.9-1.7,9.3c1.6,1.5,6.6,3.6,7.6,4.8s3.1,3.2,4.5,2.6c1.5-0.6,8.9-6.7,10.8-7.9
	c1.9-1.2,4.5-6.7,6.7-6.8s7.4,2.9,14.3,4.1c4.6,0.8,11.1,1,14.7,1.1c-0.5-2-1.7-4.3-3.8-5.7l-2.3-0.3c-1.1-3.5,5.9,3.5,7.2,2.2
	c2.8-2.9-6.3-2-4-4.4c0.8-0.9,7.7-1.3,7.9-1.6c0.4-0.4-0.4-1.5,0.1-1.8c0.4-0.3,0.6,1.3,1,1.1c2.7-1.5-2.5-3.7-3.1-3.9
	c-0.4-0.1,0.9,0.1,1.3,0.1c1.6,0.2,3.5-0.1,4.6-1.5c0.4-0.5-1-0.7-1.6-1c-1.8-1-6.2-1.1-6.5-4.1c-0.1-0.4,0.6,0.5,1,0.8
	c1.6,1.4,0.2,0.4,2.2,1.3c1.4,0.7,7.6,3.3,8.4,3.2c0.9-0.1-2.8-6.1-2.6-4.5c0.1,0.4-0.7,0.3-0.6-0.5c0.1-2-2-1.5-0.9-2.3
	c2.5-1.8,2.9,2.4,2-4.5c0-0.2,3,1.4,3,1.4c0.1,0.2,0.1,0.4,0.2,0.6c0.2-0.2,0.5-0.2,1-0.1c0.6,0.1,0.5,1.8,1.1,1.7
	c2.3-0.3-0.9-5.1-1-5.3c-0.2-1.2,3.7,3,3.8,3.1c0.7,0.6,2.9,0.1,3.9,1.3c0.4,0.5,0.3,1.4,0.8,1.7c0.6,0.4,0.5-1.4,0.9-1.9
	c1.1-1.5-0.2-2.5,0.4-3.1c0.9-0.8,2.5,0.4,3.3,1.3c0.2,0.3,0.7,0.8,0.9,0.5c0.5-0.8,0.6-1.5,1-1.4c2.5,1.1,1.9-1.7,3.8-1.2
	c0.9,0.2,2.9,1.8,3.7,1.9c0.2,0,2.3-3.2,3.5-3C229.1,108.7,227.8,105.9,226.1,103.7z M99.4,60.5c0.7-0.5,1.7-0.8,2.3-1.5
	c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1,0-0.3,0-0.4-0.2c-0.3-0.3-0.7-0.6-0.9-1h-0.1c-0.7,0.1-1-1-0.3-1.1c1.2-0.2,2.2,0.3,3.3,0.4
	c0.4-0.3,1-0.2,1.6,0c0.5,0.2,0.3,1-0.1,1.1c-0.3,0.1-0.7,0.1-1,0c-0.3,0.3-0.4,1.2-0.6,1.6c0.1,0.7,0.2,1.4,0.4,2
	c0.1,0.2,0,0.4-0.1,0.5c0,0.2,0,0.3,0,0.5c0,0.4-0.4,0.7-0.7,0.6c-1.1-0.3-2.1-0.9-3.1-1.4C99,61.3,99.1,60.7,99.4,60.5z
	 M104.5,79.3c-0.8-0.2-1.5-0.5-2.2-1c-0.6-0.5-1.1-1.2-1.6-1.7c-0.2-0.3-0.2-0.6,0-0.8l-0.1-0.1c-0.6-0.5,0.3-1.3,0.8-0.8
	c1.3,1.1,2.6,2.2,3.6,3.5C105.4,78.8,105.1,79.5,104.5,79.3z M131.2,76.9c1,0.1,2.1,0.2,3.1,0.3c0.7,0.1,0.4,1.2-0.3,1.1
	c-0.9-0.1-1.8-0.1-2.8-0.3C130.5,77.9,130.4,76.8,131.2,76.9z M125.8,78.7c0.2-0.2,0.4-0.2,0.7-0.2c0.8,0,0.7,1.1,0,1.2
	c0,0-0.1,0-0.1,0.1C125.8,80.1,125.2,79.1,125.8,78.7z M109.9,46.2c-0.1-0.5,0.4-0.9,0.9-0.7c1.7,0.9,2.9,2.3,4.8,3
	c0.9,0.3,1.8,0.6,2.6,1.2c1.5,1.3,3.3,0.9,4.9,1.9c0.9,0.5,1.5,1.1,2.1,1.9c0.4,0.5-0.1,1.3-0.7,0.9c-3.7-2.4-8.4-2.6-11.9-5.3
	c-0.2,0-0.3-0.1-0.4-0.3c0,0,0,0,0-0.1c-0.2-0.1-0.3-0.3-0.5-0.4v-0.1C110.8,48,110.2,47.2,109.9,46.2z M123.7,92.8
	c0.6,0.5-0.3,1.3-0.8,0.8c-0.6-0.5-1.2-1.4-2.1-1.4c-0.8,0-0.8-1.2,0-1.2C122.2,91.1,122.8,92,123.7,92.8z M122,77.1
	c0.4,0.2,0.8,0.3,1.3,0.4c0.7,0,0.8,1.2,0,1.2c-0.7,0-1.3-0.2-1.9-0.5C120.7,77.8,121.3,76.8,122,77.1z M117.5,87.6
	c-0.2,0.2-0.5,0.2-0.7,0.1c-0.5-0.3-0.5-0.7-0.4-1.2c0-0.4,0.4-0.6,0.7-0.5c0.3,0,0.7,0.1,0.7,0.5C117.9,87,117.9,87.3,117.5,87.6z
	 M125.7,117.6c-1-0.8-1.6-1.3-1.7-2.6c-0.1-1.6-1.7-0.5-2.3-1.5c-0.2-0.3-0.2-0.5-0.3-0.8c-1-0.3-2-0.8-3.1-1.2
	c-0.2,0.1-0.3,0.1-0.5,0.2c-0.3,0.1-0.6-0.1-0.7-0.4c-0.2,0.2-0.5,0.4-0.7,0.6c-0.4,0.6-1.4,0.1-1-0.6c0.5-0.8,1-1.2,1.8-1.4
	c0.3-0.1,0.5,0.1,0.7,0.3l0,0c0,0,0,0,0.1,0h0.7c0.3,0,0.5,0.2,0.6,0.5c1,0.4,1.9,0.8,2.8,1.1c0.5-0.3,1.3-0.3,1.8-0.2
	c0.3,0.1,0.5,0.4,0.4,0.7l0,0c0.2,0.2,0.2,0.6-0.1,0.9c0.3,0.1,0.5,0.2,0.5,0.3c0.2,0.2,0.3,0.7,0.4,1c0,1,0.6,1.8,1.4,2.4
	C127.1,117.3,126.3,118.1,125.7,117.6z M128.7,111.8c-1.7-0.6-3.1-1.6-4.6-2.5s-3.2-1.5-4.7-2.5c-1.4-0.9-1.4-2.9-2.6-3.9
	c-1-0.8-2-1.4-2.7-2.6c0-0.1-0.1-0.1-0.1-0.2c-0.9-0.4-1.7-0.8-2.6-1.4c-0.6-0.4-0.1-1.4,0.6-1c1.5,0.9,3.4,1.3,4.6,2.6
	c1.1,1.1,1.8,2.5,2.7,3.7c1.4,1.9,3.1,2.3,5,3.5c1.7,1,3.1,2.2,4.8,3.2C129.7,111.1,129.3,112,128.7,111.8z M135.4,125.9
	c-1.3-0.8-2.7-1-3.9-1.9c-0.1,0-0.1,0-0.2,0c0.6,1.1,0.9,2.3,1.2,3.5c0.2,0.7-0.9,1-1.1,0.3c-0.4-1.6-0.7-3-1.8-4.2
	c-0.6-0.6-0.6-1.2-0.2-2c0,0,0-0.1,0.1-0.1l0,0c-0.5-0.4-0.2-1.2,0.4-1.1c0.3,0.1,0.7,0.2,1,0.3c0.3-0.1,0.7-0.2,1.1-0.1
	c1.3,0.3,1.1,0.8,1.4,1.8c0.4,1,2.1,1.8,2.8,2.5C136.6,125.4,136,126.2,135.4,125.9z M142.3,82c-0.7-0.1-1.2-0.5-1.4-1.2
	c-0.3-0.7,0.9-1,1.1-0.3c0.1,0.2,0.1,0.3,0.3,0.3C143,80.9,143,82,142.3,82z M146.7,84.7c-0.2,0.2-0.4,0.4-0.7,0.4
	c-0.2,0-0.4-0.2-0.6-0.2c-0.4-0.1-0.5-0.5-0.3-0.8c-0.4-0.2-0.9-0.4-1.3-0.6c-0.6-0.4-0.1-1.4,0.6-1c0.8,0.5,1.8,0.7,2.4,1.5
	C147,84.2,146.9,84.5,146.7,84.7z M150,70.3c-2.1-0.7-4.2-1.8-6.1-3.1c-1.5-1.1-3.4-2.4-3.8-4.3c-0.1-0.7,1-1,1.1-0.3
	c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.2,0,0.4,0.1c2.9,2.2,7.1,3.1,8.9,6.5C150.9,69.9,150.5,70.5,150,70.3z M157.4,89.5
	c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0.3-0.5,0.6-0.8,0.4c-0.5-0.3-0.9-0.4-1.1-1.1c-0.1-0.5,0.3-0.8,0.7-0.7c0.7,0.1,1.5,0.2,2.2,0.4
	C158.4,88.7,158.1,89.8,157.4,89.5z M164.2,92.9c-0.5,0.2-1.1,0.2-1.6,0.2c-0.7,0-0.7-0.9-0.2-1.1c-0.3,0.2-0.9,0-0.9-0.5
	c0-0.3-0.2-0.6,0.1-0.9c0.2-0.2,0.6-0.4,0.9-0.2c0.7,0.4,1.5,0.9,2,1.5C164.8,92.2,164.7,92.7,164.2,92.9z"/>
<path id="umea" class="region" [class.selected]="isSelected('umea')" d="M183.8,173.9c-2-0.4-6-3.5-7.4-2.9c-1.5,0.6-2,2.9-3.1,3.3c-1,0.4-5.2,0.3-7.4-1.7s-4.7-3.2-5.1-4.1
	s-2-2.9-3.6-3.1c-1.6-0.1-4.4-1.5-5.7-4.5s-4.1-5.1-5.1-6c-0.8-0.7-3.8-3-4.9-3.9c-1.2-0.5-2.4-1.7-3.2-2.5c-1-1.2-6-3.3-7.6-4.8
	s0.3-5.7,1.7-9.3c1.5-3.6-1.7-4.2-4.4-4.2c-2.6,0-6.4-1.9-9.3-4.7s-8.9-8.4-11.6-9.8c-2.8-1.3-4.5-3.3-6.3-5.1
	c-1.7-1.7-11.1-6.3-12.5-7.4c-1-0.8-3.5-3.3-4.8-4.8c-1.2,1-3,2.3-3.2,3.3c-0.4,2,2.6,5.2,2,7.1c-1.8,5.5-15.2,4.7-16,5.9
	c-0.9,1.4,2.6,8.8,2.6,10.7c0,2.8-1.7,5.7-2,8.5c-0.1,0.6,0,1.1,0.1,1.7c0,0.9,0.1,1.8,0.1,2.7c2.6,0,9.6-0.1,12.2,0
	c4.1,0.1,11,0.1,13.3,0.9c2.3,0.8,4.6,2.7,6.4,6.6c1.8,3.9,5.7,6.7,8,8s12.7,7.5,14.6,9.3c1.8,1.8,3.8,5.1,2.7,7.2s-4.5,3.4-10.2,4
	s-7.9,3-6.3,5.2s4.7,5.9,5,7.3c0.4,1.4-0.1,6.7,2,7.2s3.8-0.4,5.3-0.1c1.6,0.3,4.6,0.9,6.2,0.3c1.6-0.6,5.2-1.6,6.9-2.4
	c1.7-0.9,5-2.3,5.4-1.8s1.7,3.8,3.6,4.9s7.1,3.2,8.1,4.7s2.6,6.4,4,7.7c0.7,0.7,1.4,1.2,1.8,1.6c0.2-1.1,0.4-2,0.4-2
	c0.3-0.2,3,3.3,3.3,2.8c0.9-1.4-3.8-6.2-1.8-6.1c2.5,0.2,2.7,3.6,4.3,4.6c1.9,1.1,1-4,3.5-7.2c1.3-1.6,5.3-1,5.8-1.8
	c0.5-0.9-0.1-2.3,0.6-3.2c0.6-0.8,0.1-0.3,0.5-0.7c1.5-1.1,0.4,3.7,1.5,2.9c1.1-0.7-0.3-5.6,1.5-5.7c0.7-0.1,0.4,1.8,1.1,1.7l1-2
	c3.3-0.9,4-7.8,4.6-12.3c0.2-1.4,2.5-3.9,4.2-5.7C185.8,174.2,184.7,174.1,183.8,173.9z M80.9,114.8c-0.1,0.2-0.1,0.3-0.2,0.4
	c0,0.2,0.1,0.5,0.1,0.7c0.1,0.3-0.1,0.5-0.3,0.6c-0.1,0.4-0.6,0.8-1,0.4c-0.3-0.4-0.5-0.8-0.7-1.3c-0.2-0.3,0-0.7,0.3-0.9
	c0.1,0,0.1,0,0.2-0.1c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.4,0.4-1,0.9-0.7C80.5,113.6,81.1,114.1,80.9,114.8z M112.5,146.3
	c-1-0.5-1.7-1.4-2.4-2.3c-0.5-0.6-1-1.2-1.7-1.5c-0.4-0.2-0.8-0.4-1.1-0.7l-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.3,0-0.5-0.4-0.4-0.7
	c0.2-0.6,0.5-0.8,1.1-1c0.3-0.1,0.5,0,0.6,0.3c0.3,0,0.5,0.1,0.7,0.2c0.5,0.2,0.5,0.8,0,1.1c0.5,0.2,0.9,0.5,1.2,0.9
	c0.9,1,1.6,2.2,2.8,2.9C113.8,145.6,113.2,146.6,112.5,146.3z"/>
<path id="sundsvall" class="region" [class.selected]="isSelected('sundsvall')" d="M111.3,233c-2.4,1.2-5,1.5-5.1,5.5c-0.2,4.1,1.6,4.6,1.9,5.9c0.3,1.4-0.8,6.5-1.1,7.5
	c-0.3,1-2.2,5.1-1,6.3c1.3,1.2,4.8,1,5.7,1.2c1,0.2,6.1,1.8,7,1.9c0.7,0.1,5.6-0.4,7.7-0.7c-0.1-1.7-0.2-3.5,0.5-4.8
	c0.6-1.3-0.1,1.8,1.6,0.5c0.3-0.2,1.1-0.4,0.9-0.7c-1.3-1.8-6.4-1.6-6.3-5.9c0-0.2,0.5,0.1,0.6,0c0.2-0.4,0.3-0.8,0.2-1.3l-1-1.8
	c1.2-5.5,5.6,4.6,6.9,1.4c0.2-0.5-1-0.7-1-1.2c-0.2-2.7,2.8-0.4,3.6-0.9c1.2-0.7,0.6-4.1,1.2-5.1c0.4-0.7,0.1,2,0.8,2.5l1.7-2
	c0,0-1.9-3.5-2.1-3.8c-0.5-0.7-1.3-0.9-2.1-1.1c-4-0.9-8.3-0.3-12.2-1.7c-1-0.4-2-0.9-2.8-1.7c-1.1-1.2-1.9-2.8-2.8-4.2
	c-0.4,0.7-0.2,1.5-0.7,2.3C112.9,232,112.1,232.6,111.3,233z"/>
<path class="region" d="M57.9,441.5"/>
<path id="jonkoping" class="region" [class.selected]="isSelected('jonkoping')" d="M57.6,441.2c0.1-0.1,0.2,0,0.4-0.3c-0.1,0.2-0.2,0.5-0.2,0.6l0,0c-0.2,0.7-0.4,1.5-0.4,2.3
	c0,2,0,5.2,0.4,6.1s0.8,2.3,0.6,3.9s0.2,4.7,0.9,6.1c0.2,0.4,0.3,0.7,0.4,1.1c1,0.1,3-0.2,4.1-0.4c1.2-0.3,1.5-1.2,2.3-1.8
	s2.7,2.3,3.1,3.6c0.4,1.3,2.8,2.2,3.3,1.8c0.5-0.3,0.1-1.6,0-3.2s0.3-4.1,0.5-5.2l0.2-0.5c0.7-0.7,3.1-0.8,4.1-1.2
	c1.2-0.4,3.5-2.4,5.1-2.9c1.3-0.5,2.5,1.4,2.9,2.2c0.5-0.4,1.2-0.9,1.9-1.3c1.6-1.1,8.3-0.9,9.3-1.1c0.2,0,0.5-0.1,0.8-0.1
	c-0.3-0.3-0.7-0.6-1.1-1c-1.1-1.1-0.5-2.4,0.3-4.1s1.2-5.5,1.2-7.3s-2-3.6-2.7-4.2c-0.4-0.3-0.8-1.2-1.1-1.9
	c-0.9,0.7-1.8,1.2-2.4,1.1c-1-0.1-3.4-3.6-3.9-5.2c-0.5-1.7,0.5-9.9-1.8-10.5s-4.2,1.2-6.1,1.2c-0.5,0-0.9,0-1.3,0h-0.2
	c-0.5,0-1,0-1.3,0c-0.3,1-0.6,1.8-0.9,2.2c-0.6,0.8-2.8,1.9-3.9,3.2c-1.1,1.3-0.9,4.5-1.6,5c-0.8,0.5-2.7-1.5-3.3-3.2
	c-0.5-1.4,0.2-4.5,0.7-6.5l-1.9,0c0,0-0.3,0.7-0.8,1.7s-1.2,2.3-1.7,2.8c-0.9,0.9-3.1,2.1-3.4,2.3c0,0.1,0,0.7,0,0.8
	c0.2,1.4-1.8,10.9-2.4,12.8c-0.1,0.3-0.3,0.8-0.7,1.3C56.9,441.2,57.4,441.2,57.6,441.2L57.6,441.2z"/>
<path id="ornskoldsvik" class="region" [class.selected]="isSelected('ornskoldsvik')" d="M154.2,207.2c-1.2-1.4-2.9-6.1-3.9-7.6s-6.2-3.6-8.1-4.7c-1.8-1.1-3.2-4.4-3.6-4.9
	c-0.4-0.5-3.8,1-5.4,1.8c-1.7,0.9-5.2,1.8-6.9,2.4s-4.9,0-6.4-0.3c-0.7-0.1-2.1,0.1-2.9,0.2c-0.8,0.1-1.3,0.1-2.2-0.1
	c-2.1-0.5-1.7-6.1-2.1-7.5c-0.1-0.2-0.2-0.4-0.3-0.7c-0.6,0.7-1.6,2.1-1.9,2.7c-0.4,1.4-3,6.3-3,7.3s0.2,4.7-1.6,5.9
	c-1.9,1.1-8.3,2.4-9.1,2.6c-0.9,0.2-3.4,0.8-3.4,1.4s2.9,5.1,4,6.3s4.8,3.9,6.3,5c1.6,1.2,4,4.5,5.1,5.7c1,1.1,4.2,4.4,5,5.8
	l0.3,0.3c0.9,1.3,1.8,2.9,2.8,4.2c0.7,0.8,1.7,1.4,2.8,1.7c3.9,1.4,8.2,0.8,12.2,1.7c0.8,0.2,1.6,0.4,2.1,1.1l0.2-0.3
	c-0.8-2.9-1.2-5.9-3.1-8.4c-0.3-0.4-0.7-0.8-1.2-1.2h-0.1c-0.6,0-1.3-1.2-1.3-1.2s0.7,0.5,1.4,1.1c1-0.2,1.4-1.6,3.1,2.3
	c0.4,0.9,2,1.7,2.6,1.7c0.4,0-1.3,1.6-1.1,2c1.8,3.1,2.9-1,3.3-0.9c0.4,0.1,0.5-1.6,1-2.6c0.1-0.2,3,3.2,4,0.9
	c0.2-0.4,0.2-0.9-0.1-1.2c-0.3-0.4-1.4-0.1-1.3-0.5c0.2-0.5,1-0.1,1.5-0.1c0.1,0,0.2,0,0.3,0c1.6-1.3,0.9-0.8,1.4-2
	c0.9-2.2-3.1-0.5-3.9-3.2c0-0.1,0-0.2,0-0.3c-0.1-0.8,1.2,1.2,1.9,1.1c0.7-0.1,2.5-4.7,1.9-5.1c-0.3-0.2-1.1-0.4-0.8-0.6l3.4,0.4
	c0.6-0.6-1.4-1.6-1.1-2.4c0.1-0.3,0.7-0.1,1.1-0.1c0.4,0,2.3,1.3,2.7,1c1.5-1.3-2-2.3-1.4-2.4c0.6,0,1.1-1.3,2.1-0.2
	c0.5,0.6,0.8,3.1,2.3,2.3c0.3-0.2,1-0.3,0.8-0.7c-0.2-0.5,0.1-4.4,2-4.5c0.1,0,0.3-1.7,0.6-3.5C155.8,208.7,154.9,207.9,154.2,207.2
	z M119.8,217.5c-0.7-1.2-4.3-3.5-5.3-5.6c-0.9,0.1-1.8-0.5-1.2-2c0.3-0.6,1.3-0.1,1.5-0.3c0.3-0.3,0.9-0.2,1,0.3
	c0.2,2.8,4.5,6.1,5,7C121.1,217.6,120.1,218.2,119.8,217.5z"/>
<path id="dalarna" class="region" [class.selected]="isSelected('dalarna')" d="M113.8,332.2c-0.6-1.5-2.8-3.9-4.2-4.6c-1.4-0.7-3.8-2.3-4.1-3.9c-0.3-1.6,2-3.9,2.7-4.5
	s0.8-2.4,0.8-3.8s-3.5-5.8-4.1-6.5c-0.3-0.4-1-1.7-1.4-2.8c-0.9,0.3-1.7,0.5-2.3,0.5c-1.6-0.2-3.2-1.8-4.7-3.8
	c-1.5-1.9-2.4-6.3-2.8-8s-3-2.6-3.8-3.4s-2.1-2.1-3-3.8c-0.9-1.6-2.2-1.3-2.6-0.8s-1.4,1.6-2.6,1.7c-1.3,0.1-3.9-1.9-4.7-2.5
	c-0.8-0.6-2.5-1.9-4.6-1.9s-6-0.8-7-1.1c-1-0.3-6.5-0.5-7.8-0.9s-2.7-2.3-2.9-3.3s-1.7-2.8-2.8-4.1c-1.1-1.3-0.9-2.9,0.1-4.2
	c1-1.3,1-2.2,0.4-3.3s-4.2-0.4-5.2-0.8c-1.1-0.4-1.6-2.6-2.2-4.2c-0.6-1.6-4-1.7-5-1.8c-1.1-0.1-4.5-0.8-5.5-1.4
	c-0.7-0.4-2.8-1-4.2-1.3c0.1,6-4.7,13.8-3.3,19.5c1.7,7,12.3,6.5,13.9,13.9c0.3,1.2-2.5,9.7-3.5,11.2c1.2,1.4,2.9,3.3,3.6,4.3
	c1.1,1.7,4.8,7,5.9,8.8c1.2,1.8,3.8,6.3,4.8,7.2c1,0.9,2.2,3.1,2.3,3.7s1.3,3.3,2.4,4.1c1.2,0.8,4.7,3.4,5.1,4.5
	c0.5,1.1,1.1,4.5,1.6,4.9s1.5-0.2,1.8-0.6c0.4-0.4,1.8-1.1,2.7,0.2c0.9,1.3,2.7,5.2,4.3,6c1.6,0.8,4.8,1.6,6.1,0.6
	c1.4-1,2.6-2.5,5-2.2s4.6,2.7,5.4,3.4c0.9,0.7,1.9,1.6,2.8,1.5s4.1-0.6,5-1.3c0.9-0.7,1.4-1.6,1.5-3.1c0.1-1.6-0.1-5,1.6-5.6
	c1.7-0.7,3.1-0.9,4-0.4s2.7,2.6,3.9,3.4c1.2,0.8,3.7,0.2,4.5-0.8s3.1-2.9,3.5-3.4c0.2-0.3,0.9-1.4,1.4-2.3
	C115.7,334.9,114.3,333.4,113.8,332.2z M83.3,314c-0.6,0.5-1.3,1.1-1.3,1.9c0,0.2,0.1,0.5,0,0.7s-0.4,0.4-0.5,0.3s-0.1-0.2-0.1-0.3
	c-0.1-0.6-0.7-1.1-1.3-1.2c-0.6-0.1-1.3,0.1-1.8,0.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3-0.1-0.4-0.2c-0.9-0.9-1.8-1.9-2.8-2.8
	c-0.2-0.2-0.3-0.3-0.4-0.6c-0.1-0.2,0-0.5,0.2-0.6c0.1-0.1,0.2-0.1,0.3-0.1c0.5,0,0.9,0,1.4,0c0-0.4-0.5-0.8-1-1.2
	c-0.5-0.4-1.1-0.9-0.9-1.6c0.1-0.5,0.5-0.9,1.1-0.8c0.5,0.1,0.8,0.5,1,1c0.1,0.3,0.1,0.6,0.4,0.8c0.2,0.2,0.5,0.2,0.7,0.3
	c0.3,0.1,0.6,0.3,0.8,0.5c0.7,0.6,1.3,1.1,2,1.7c0.1,0.1,0.2,0.2,0.3,0.2c1.1,0.3,2.2-1.6,3.5-1.1c0.1,0,0.2,0.1,0.2,0.2
	c0.1,0.1,0.2,0.4,0.2,0.6C84.6,312.9,83.9,313.5,83.3,314z"/>
<path id="jamtland" class="region" [class.selected]="isSelected('jamtland')" d="M121.5,163.1c-1.8-1.8-12.3-8.1-14.6-9.3c-2.2-1.3-6.1-4.1-8-8c-1.8-3.9-4.1-5.8-6.4-6.6
	s-9.2-0.8-13.3-0.9c-2.6-0.1-9.6,0-12.2,0c0,0.8,0.1,1.7,0.1,2.5c0,0.6,0,1.1,0.1,1.7c0,0.6,0.2,1.2,0,1.7l-2.2,2.5
	c-0.6,1.2-0.3,3-0.7,4.3c-1.7,4.6-5,9.1-7.4,13.4c-0.7,1.2-4.2,5.9-3.1,7.5c3.7,5,10.8,0.5,8.6,14c-1.9,11.6-11.8,1.4-19,4.5
	c-2.3,1-5.3,1.9-7.2,3.7c-1,1-9.3,13.1-9.4,14.1c-0.1,1.3,1.4,2.4,1.2,3.7c-0.3,2.8-4.5,5.2-4.8,8c-0.2,3,4.4,6.4,4.3,9.4
	c-0.1,1.5-2.2,2.9-2.4,4.3c-0.3,1.7,1,3.9,0.9,5.6c0,1.8-1.4,3.8-1.2,5.6c0.4,4.3,5,8.1,5.3,12.5c0,0.2,0,0.4,0,0.6
	c1.4,0.4,3.5,0.9,4.2,1.3c1.1,0.6,4.5,1.3,5.5,1.4c1.1,0.1,4.5,0.3,5,1.8c0.6,1.6,1.2,3.8,2.2,4.2c1.1,0.4,4.7-0.3,5.2,0.8
	c0.6,1.1,0.6,2-0.4,3.3s-1.2,2.9-0.1,4.2c1.1,1.3,2.6,3.1,2.8,4.1s1.7,2.9,2.9,3.3c1.3,0.4,6.8,0.6,7.8,0.9c0.9,0.3,4.7,1,6.8,1.1
	c0.2-0.3,0.5-0.5,0.6-0.8c0.5-0.9,2.4-8,2.9-8.3s1.6,1,2.5,1.7s2.4,0.2,3.3-0.2c0.9-0.4,2.4-2.8,2.4-5.1c0-2.3,1.2-3.7,2.1-4.3
	c1-0.6,3.6-2.7,3.5-4.7c-0.1-1.9,0-5.2,1.2-6.1c1.2-0.9,3.2-0.3,4.8,0.2c1.6,0.5,5.3,2.2,7.8,2.2c1.2,0,2.2-0.2,3-0.5
	c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2,0.7-5.3,1-6.3s1.4-6.1,1.1-7.5s-2.1-1.8-1.9-5.9c0.2-4.1,2.7-4.4,5.1-5.5c2.4-1.2,2.8-3,2.7-3.9
	c-0.1-0.9-3.8-5-5-6.3s-3.6-4.6-5.1-5.7c-1.6-1.2-5.2-3.9-6.3-5c-1.1-1.2-4.1-5.9-4.1-6.5s3.1-1.3,4-1.5s6.6-1.4,8.5-2.4
	c1.9-1.1,1.7-5,1.7-6s2.5-5.4,2.9-6.8c0.2-0.9,1.4-2.4,2-3.1c-0.9-1.7-3.1-4.1-4.4-6c-1.6-2.2,0-4.9,5.7-5.4
	c5.7-0.6,9.5-1.9,10.6-4.1S123.3,164.9,121.5,163.1z M61,167c0.2-0.7,1.4-0.4,1.1,0.3c-0.5,1.4,2.9,2.2,3.8,2.2c0.7,0,0.7,1.2,0,1.2
	C64.3,170.6,60.2,169.4,61,167z M73,221.9c-0.5-0.2-1.2,0-1.6,0.4c0.3,0.2,0.7,0.4,1,0.7c0.3,0.4,0.4,0.9,0,1.3
	c-0.2,0.2-0.4,0.2-0.6,0.3c-0.7,0.4-1,1.2-1,2s0.3,1.5,0.5,2.3c0.5,1.8,0.8,3.7,0.9,5.6c0,1.1-0.2,2.5-1.3,2.9
	c-1.2-0.6-1.5-2.1-1.4-3.5c0.1-1.3,0.6-2.6,0.6-3.9c-0.1-2.9-2.8-5-5.4-6.5c-0.4-0.3-0.9-0.6-1-1.1c-0.1-0.6,0.5-1.2,1.1-1.1
	c-0.9-1.1-1.6-2.4-2.1-3.7c0,0,3.1,0.7,3.4,0.8c1,0.4,2,0.8,2.8,1.5c0.2,0.2,0.5,0.4,0.8,0.5c0.5,0.1,0.9-0.1,1.4-0.2
	c0.8-0.1,1.6,0.5,2.2,1C73.4,221.5,73.3,221.8,73,221.9z M75.5,177.7c0,0.3-0.2,0.5-0.6,0.5c-0.9,0-1.7-0.3-2.3-1
	c-0.2-0.2-0.3-0.6,0-0.8c1.1-1,2.3-0.5,3.1,0.6C76,177.2,75.8,177.5,75.5,177.7z M90.9,198.3c-0.1-0.1-0.2-0.1-0.2-0.2
	c-0.3-0.2-0.3-0.5-0.2-0.8c-0.5-1-1.4-2-1.9-2.9c-0.7-1.2-1.3-2.5-1.8-3.8c-0.4-1.2-0.8-2.4-1-3.6c-0.2-0.4-0.4-0.8-0.6-1.2
	c-0.6-1.2-1.3-1.9-2.4-2.8c-0.9-0.7-1.4-1.4-1.4-2.6c0-0.8,1.2-0.8,1.2,0c0,1.2,1,1.9,1.9,2.6c0.1-0.3,0.6-0.6,1-0.2
	c0.3,0.3,0.5,0.5,0.8,0.7c0.3,0,0.5,0.2,0.6,0.4v0.1c0.2,0.2,0.3,0.4,0.2,0.6c0.3,0.9,0.3,1.7,0.2,2.8c0,0.2-0.2,0.4-0.3,0.5
	c0.4,1.9,1.3,3.7,2.1,5.2c0.7,1.4,2.3,3.1,2.6,4.6C92,198.2,91.3,198.7,90.9,198.3z M95.5,189.7c-0.2-1.7-0.9-2.6-2.1-3.8
	c-0.9-0.9-1.6-1.9-2.4-2.8c-0.3-0.1-0.6-0.2-0.9-0.2s-0.6-0.2-0.6-0.6c0-0.7,0.2-1,0.8-1.4c0.3-0.3,0.7-0.1,0.9,0.2
	c0.6-0.3,1.1,0.6,0.5,1c0,0,0,0-0.1,0.1l0,0c1.2,0.5,2.3,1.3,3.2,2.1c1.3,1.1,1.6,4,1.8,5.5C96.7,190.5,95.6,190.5,95.5,189.7z
	 M103,164.8c-0.1-0.7-0.6-1.4-1-1.9c-0.7-1-1.8-1.6-2.7-2.3c-1.5-1-3.3-2.2-3.8-4.1c-0.2-0.7,0.9-1,1.1-0.3c0.6,2.3,3.5,3.3,5.2,4.8
	c0.6,0.5,1.3,1.2,1.6,1.9c0,0,0.5,0.3,0.6,0.4c0.2,0.3,0.2,0.8,0.3,1.1C104.2,165.3,103.1,165.6,103,164.8z"/>
</svg>
<div class="display--none">
  <svg #svgSupportElement></svg>
</div>
