import { Component, Input, OnChanges } from '@angular/core';
import { verticalExpansion } from 'app/scripts/utils/animations';
import { generateId } from '../../../utils/string';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../woo_services.module/DialogService';
import { ProductChoice, TargetingType, wooId } from '../../../woo_services.module/shared-types';
import { TargetingCombinationRulesService } from '../../../woo_services.module/TargetingCombinationRulesService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
  <p class="text--large"><span class="icon-TV4_118_lokal_styrning icon__medium text--info margin-right--1x"></span>Regionalpaketering</p>
  <div>
    <input class="checkbox" id="include-linear-{{instanceId}}" type="checkbox"
      [checked]="includesLinear" (change)="includesLinearChanged()"
      [disabled]="disabled"
      e2e-id="includes-linear"
    >
    <label class="checkbox" for="include-linear-{{instanceId}}">Boka denna styrning som en regionalpaketering</label>
  </div>
  <div class="input__container width--33 padding-top--2x" *ngIf="includesLinear" [@verticalExpansion]>
    <label for="sales-order-number-{{instanceId}}">Salesordernummer</label>
    <input id="sales-order-number-{{instanceId}}" type="text"
      [(ngModel)]="salesOrderNumber" (ngModelChange)="salesOrderNumberChanged()"
      [disabled]="disabled"
      e2e-id="includes-linear-order-number"
    >
  </div>
`;

@Component({
  selector: 'includes-linear',
  template: template,
  animations: [verticalExpansion(100)],
})
export class IncludesLinear extends SubscriptionHandler implements OnChanges {
  readonly instanceId = generateId();
  includesLinear: boolean;
  salesOrderNumber: string;
  isInstreamTargeting: boolean;
  productChoice: ProductChoice;
  disabled: boolean;
  @Input() targetingId: wooId;

  private isPartOfCombinationCampaign: boolean;
  private sendInvoices: boolean;

  constructor(
    private bookingStore: BookingStore,
    private dialogService: DialogService,
    private targetingCombinationRulesService: TargetingCombinationRulesService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    this.isPartOfCombinationCampaign = model.campaign.targetings.length > 1;
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.sendInvoices = targeting.send_invoices;
      this.includesLinear = targeting.includes_linear;
      this.salesOrderNumber = targeting.sales_order_number;
      this.isInstreamTargeting = model.targetingMetaData[this.targetingId].targetingType === TargetingType.instream;
      this.productChoice = model.targetingMetaData[this.targetingId].productChoice;
      this.disabled = this.targetingCombinationRulesService.disableIncludeLinear(this.targetingId);
    }
  };

  includesLinearChanged(): void {
    this.includesLinear = !this.includesLinear;
    if (!this.isPartOfCombinationCampaign || !(this.sendInvoices && this.includesLinear)) {
      this.bookingStore.setIncludesLinear(this.targetingId, this.includesLinear);
      return;
    }

    this.dialogService
      .openConfirm(this.changeSendInvoiceForCampaignContent())
      .then(() => {
        this.bookingStore.setIncludesLinear(this.targetingId, this.includesLinear);
        this.bookingStore.setNoInvoicesChoice(!this.sendInvoices);
      })
      .catch(() => (this.includesLinear = !this.includesLinear));
  }

  salesOrderNumberChanged(): void {
    this.bookingStore.setSalesOrderNumber(this.targetingId, this.salesOrderNumber);
  }

  private changeSendInvoiceForCampaignContent(): ConfirmDialogContent {
    return {
      header: `Starta regionalpaketering`,
      textBlocks: [
        'Vi godkänner inte att en kampanjs fakturering delas upp.',
        'Aktiveras regionalpaketering så kommer faktureringen stängas för hela kampanjen',
      ],
      confirmText: 'Ja, stäng fakturering för kampanjen',
      cancelText: 'Avbryt',
    };
  }
}
