import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractValueAccessor } from 'app/scripts/utils/AbstractValueAccessor';
import { valueAccessorProvider } from 'app/scripts/utils/provider-builders';

@Component({
  selector: 'woo-number-input',
  template: `
    <div class="input__container">
      <span class="input__optional__text" *ngIf="inputOptionalText">{{ inputOptionalText }}</span>
      <label for="{{ inputId }}">{{ labelText }}</label>
      <input
        name="{{ name }}"
        id="{{ inputId }}"
        maxlength="{{ maxlength }}"
        [(ngModel)]="model"
        (ngModelChange)="changes()"
        [disabled]="disabled"
        [required]="required"
        #field="ngModel"
        [wooNumberFormatter]="formatterOptions"
      />
      <ng-container *ngIf="!hideValidationIcons">
        <div *ngIf="field.invalid && field.dirty" class="status-badge small input__status error">
          <span class="icon-close-x icon__small"></span>
        </div>
        <div *ngIf="field.valid && field.dirty" class="status-badge small input__status green-success">
          <span class="icon-check icon__small"></span>
        </div>
      </ng-container>
    </div>
  `,
  providers: [valueAccessorProvider(WooNumberInput)],
})
export class WooNumberInput extends AbstractValueAccessor<string> {
  @Input() inputId: string;
  @Input() labelText: string;
  @Input() disabled = false;
  @Input() name: string;
  @Input() maxlength: number;
  @Input() inputOptionalText = '';
  @Input() required = false;
  @Input() hideValidationIcons = false;
  @Input() e2eId = '';
  @Input() formatterOptions = { allowEmpty: false, allowDecimals: false, numberOfDecimals: 2 };

  @Output() inputValueChanged = new EventEmitter<string>();

  changes(): void {
    this.inputValueChanged.emit(this.model);
  }
}
