<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera maxuttag för RBS och kategorier</h3>
</div>

<div>
  <div #stickyHeader>
    <div class="tabbable__body">
      <import-warning *ngIf="show.withdrawalLimitsHaveChanged"></import-warning>
      <div class="button-filter-container">
        <div>
          <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="categoryWithdrawalLimits.invalid">Spara</button>
          <button *ngIf="!show.isEditing" class="button primary" (click)="setEditing(true)">Redigera Kategorier</button>
          <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
        </div>
        <div class="display--flex">
          <div class="filter-dropdown">
            <woo-dropdown
            [items]="categories"
            [(ngModel)]="selectedCategory"
            name="selectedCategory"
            inputId="select-category"
            labelText="Filtrera på kategori"
            notFoundText="Inga val hittades."
            [clearable]="true"></woo-dropdown>
          </div>
            <div *ngIf="productFormats && productFormats.length > 1" class="filter-dropdown margin-left--2x">
              <woo-dropdown
              [items]="productFormats"
              [(ngModel)]="selectedProductFormat"
              name="selectedProductFormat"
              inputId="select-product-format"
              labelText="Filtrera på Placering"
              [labelTransform]="translateProductFormat"
              notFoundText="Inga Placeringar hittades."
              [clearable]="true"></woo-dropdown>
            </div>
          <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="publishers"
            [(ngModel)]="selectedPublisher"
            name="selectedPublisher"
            inputId="select-publisher"
            labelText="Filtrera på Publicist"
            notFoundText="Inga publicister hittades."
            [clearable]="true"></woo-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="tabbable__panel clearfix shadow-z-1">
      <h3 class="margin-left--3x">Tillgängliga kategorier</h3>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table--woo table--info table--hover table--align--middle" ngForm #categoryWithdrawalLimits="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-center" colspan="2">
            Estimatfördelning
          </th>
          <th class="text-center" colspan="2">
            Maxuttag
          </th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>

      <tbody *ngIf="!show.loading">
        <ng-container *ngFor="let row of withdrawalLimitRows; let index = index;">
          <tr [hidden]="!rowVisible(row)">
            <td class="text--thin vertical-align--middle">
              <span>{{row.publisher}}</span>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{translateProductFormat(row.productFormat)}}</span>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{row.category}}</span>
            </td>

            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.sharesPreroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-sharesPreroll"
                  [(ngModel)]="getCategoryRowShares(row).preroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll"
                  (ngModelChange)="autoFill(row)">
              </div>
            </td>

            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.sharesMidroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-sharesMidroll"
                  [(ngModel)]="getCategoryRowShares(row).midroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                  [disabled]="true">
              </div>
            </td>

            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPreroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-waithdrawalLimitsPreroll"
                  [(ngModel)]="getWithdrawalLimits(row).preroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
              </div>
            </td>

            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{row.withdrawalLimitsMidroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-waithdrawalLimitsMidroll"
                  [(ngModel)]="getWithdrawalLimits(row).midroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                  [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
      <button class="button primary" (click)="save()" [disabled]="categoryWithdrawalLimits.invalid">Spara</button>
      <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>
