<div
  (mouseenter)="mouseEnterCard()"
  (mouseleave)="mouseLeaveCard()"
  class="cash-card--container-desktop justify-content--space-between"
  [ngClass]="{
    'shadow-z-2': cardHover,
    'background-color--white': cardHover || showDeleteQuestion
  }"
  >

  <video
    [hidden]="cardHover || showDeleteQuestion"
    class="cash-card--background-thumbnail"
    [src]="campaign.creatives && campaign.creatives[0] ? campaign.creatives[0].asset_url + '#t=0.1' : ''"
  ></video>


  <div class="cash-card--banner" *ngIf="!showDeleteQuestion && !(cardHover && campaign.status === CampaignStatus.rejected)">
    <div class="cash-card--headline-container">
      <name-period-header [name]="campaign.name" [start]="targeting.start_date" [end]="targeting.end_date"></name-period-header>
    </div>
    <card-banner *ngIf="!cardHover" [status]="campaign.status" class="card-banner-desktop"></card-banner>
  </div>

  <div class="cash-card--body" *ngIf="!showDeleteQuestion">
    <div *ngIf="!cardHover">
      <campaign-progress
        *ngIf="campaign.status === CampaignStatus.ongoing || campaign.status === CampaignStatus.ended || campaign.status === CampaignStatus.closed"
        text="Färdiga visningar"
        [progress]="campaign.outcome?.ads_completed"
        [target]="campaign.agreement?.net_impressions"
      ></campaign-progress>
    </div>

    <div *ngIf="cardHover && campaign.status !== CampaignStatus.unbooked && campaign.status !== CampaignStatus.rejected && campaign.status !== CampaignStatus.cancelled">
      <div class="cash-card-hover--info">
        <div class="text--strong text--small">
          Färdiga<br>visningar
        </div>
        <div>
          <h1 class="thin">{{campaign.outcome?.ads_completed || 0 | number}}</h1>
        </div>
      </div>
      <div class="divider  strong float--clear"></div>
      <div class="cash-card-hover--info">
        <div class="text--strong text--small">
          Påbörjade<br>visningar
        </div>
        <div>
          <h1 class="thin">{{campaign.outcome?.impressions || 0 | number}}</h1>
        </div>
      </div>
      <div class="divider  strong float--clear"></div>
      <div class="cash-card-hover--info">
        <div class="text--strong text--small">
          Antal<br>klick
        </div>
        <div>
          <h1 class="thin">{{campaign.outcome?.click_throughs || 0 | number}}</h1>
        </div>
      </div>
    </div>
    <div class="rejection-message" *ngIf="cardHover && campaign.status === CampaignStatus.rejected">
      <h3 class="rejection-headline">Din film blev inte godkänd.</h3>
      <p>{{campaign.reject_message}}</p>
    </div>
  </div>

  <div class="cash-card--footer" *ngIf="!showDeleteQuestion">

    <ng-container [ngSwitch]="campaign.status">
      <button *ngSwitchCase="CampaignStatus.unbooked" class="button primary edit-button" (click)="$event.stopPropagation(); editCampaign(campaign.id)" disabled>Redigera</button>
      <button id="fix-campaign-btn" *ngSwitchCase="CampaignStatus.rejected" class="button primary edit-button" (click)="$event.stopPropagation(); editCampaign(campaign.id)" disabled>Åtgärda</button>
      <ng-container *ngSwitchCase="CampaignStatus.cancelled">
        <br><br>
      </ng-container>
      <two-col-row *ngSwitchDefault left="Köpta visningar" [right]="campaign.agreement?.net_impressions || 0 | number"></two-col-row>
    </ng-container>

    <div class="float--clear divider medium"></div>

    <ng-container *ngIf="removeButtonStatuses.includes(campaign.status); then removeButton; else row2"></ng-container>
    <ng-template #removeButton>
      <button class="link primary width--100" (click)="$event.stopPropagation(); showDeleteQuestion = true">Ta bort</button>
      <div class="float--clear divider medium"></div>
    </ng-template>
    <ng-template #row2>
      <two-col-row left="Styrning" [right]="campaign.targetingName"></two-col-row>
    </ng-template>

    <ng-container [ngSwitch]="campaign.status">
      <two-col-row *ngSwitchCase="CampaignStatus.unbooked" left="Sparad" [right]="campaign.updated_at | date"></two-col-row>
      <two-col-row *ngSwitchCase="CampaignStatus.rejected" left="Avvisad" [right]="campaign.updated_at | date"></two-col-row>
      <two-col-row *ngSwitchCase="CampaignStatus.cancelled" left="Avbokad" [right]="campaign.updated_at | date"></two-col-row>
      <two-col-row *ngSwitchDefault left="Kostnad" [right]="campaign.budget | currency:'SEK'"></two-col-row>
    </ng-container>
  </div>

  <div *ngIf="showDeleteQuestion" class="cash-card--splash" >
    <h3 class="text-align--center margin-bottom--3x">Är du säker på att du vill radera din kampanj?</h3>
    <button class="button primary danger margin-bottom--2x" (click)="$event.stopPropagation(); removeCampaign(campaign.id)">Radera</button>
    <button class="button secondary" (click)="$event.stopPropagation(); showDeleteQuestion = false">Nej, ta inte bort den</button>
  </div>
</div>
