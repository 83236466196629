import { Component, Input, OnChanges } from '@angular/core';
import { getMaxPeriod } from '../../../utils/date';
import { SimpleChanges } from '../../../utils/types';
import { TargetingService } from '../../../woo_services.module/TargetingService';
import {
  AgreementPart,
  Brand,
  Campaign,
  DatePeriod,
  Targeting,
  TargetingMetaData,
} from '../../../woo_services.module/shared-types';

@Component({
  selector: 'dashboard-details',
  templateUrl: './dashboard-details.component.html',
})
export class DashboardDetails implements OnChanges {
  campaignPeriod: DatePeriod = { start: null, end: null };

  @Input() campaign: Campaign;

  customerName: string;
  agencyName: string;
  campaignName: string;
  brand: Brand;
  additionalInfo: string;
  targetingData: TargetingData[] = [];

  constructor(private targetingService: TargetingService) {}

  ngOnChanges(changes: SimpleChanges<DashboardDetails>): void {
    if (changes.campaign && this.campaign) {
      this.customerName = this.campaign.customer_name;
      this.agencyName = this.campaign.agency_name;
      this.additionalInfo = this.campaign.additional_info;
      this.campaignName = this.campaign.name;
      this.brand = this.campaign.brand;

      if (this.campaign.targetings) {
        this.campaignPeriod = getMaxPeriod(this.campaign.targetings);
        this.targetingData = this.campaign.targetings.map((t, idx) => ({
          targeting: t,
          meta: this.targetingService.getMetaData(t),
          part: this.campaign.agreement && this.campaign.agreement.parts[idx],
        }));
      }
    }
  }
}

interface TargetingData {
  targeting: Targeting;
  meta: TargetingMetaData;
  part: AgreementPart;
}
