import { Component } from '@angular/core';
import { AnalyticsService } from '../../services/AnalyticsService';
import { BookingModel, BookingStep, BookingStore, BOOKING_STEPS_ORDER, Message } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
})
export class Footer extends SubscriptionHandler {
  BookingStep = BookingStep;
  activeBookingStep: BookingStep;
  enabledBookingSteps: BookingStep[] = BOOKING_STEPS_ORDER;
  validationMessages = [];

  get firstStep(): BookingStep {
    return this.enabledBookingSteps[0];
  }

  get lastStep(): BookingStep {
    return this.enabledBookingSteps[this.enabledBookingSteps.length - 1];
  }

  constructor(private bookingStore: BookingStore, private trackingService: AnalyticsService) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  initFromStore = (model: BookingModel): void => {
    this.activeBookingStep = model.activeBookingStep;
    this.enabledBookingSteps = BOOKING_STEPS_ORDER.filter((step) => !model.disabledSteps.includes(step));
    this.validationMessages = model.validationMessages.map((msg) => ({ ...msg }));
  };

  nextStep(): void {
    const activeStep = this.bookingStore.state.activeBookingStep;
    const validationMessagesForActiveStep = this.validationMessages.filter((msg) => activeStep === msg.originStep);
    const hasValidationErrors = validationMessagesForActiveStep.length > 0;
    this.trackingService.clickedNextStepButton(hasValidationErrors, activeStep);
    if (hasValidationErrors) {
      this.bookingStore.setDirtyBookingStep();
      this.trackMessages(validationMessagesForActiveStep);
      return;
    }
    const activeIndex = this.enabledBookingSteps.findIndex((step) => step === this.activeBookingStep);
    const nextStep = this.enabledBookingSteps[activeIndex + 1];
    this.bookingStore.setActiveBookingStep(nextStep);
    this.resetFocus();
  }

  previousStep(): void {
    const activeIndex = this.enabledBookingSteps.findIndex((step) => step === this.activeBookingStep);
    const previousStep = this.enabledBookingSteps[activeIndex - 1];
    this.bookingStore.setActiveBookingStep(previousStep);
    this.resetFocus();
  }

  convertSecondButtonText(step: BookingStep): string | BookingStep {
    const nextText = 'Nästa steg';
    const map = new Map([
      [this.BookingStep.details, nextText],
      [this.BookingStep.targeting, nextText],
      [this.BookingStep.distribution, nextText],
      [this.BookingStep.summary, 'Spara utkast'],
    ]);
    return map.get(step) || step;
  }

  private resetFocus() {
    const activeHTMLElem = document.activeElement as HTMLElement;
    if (document.activeElement && activeHTMLElem.blur) {
      activeHTMLElem.blur();
    }
  }

  private trackMessages(messages: Message[]) {
    const messageTextList = messages
      .filter((message) => message.originStep === this.activeBookingStep)
      .map((message) => this.concatText(message.text));
    this.trackingService.showedErrorMessages(messageTextList);
  }

  private concatText(text: string[] | string): string {
    return [].concat(text).join(' ');
  }
}
