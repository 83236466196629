import { Injectable } from '@angular/core';
import {
  AdvancedTargetGroup,
  AdvancedTargetGroupCategory,
  AdvancedTargetGroupService,
} from '../../woo_services.module/AdvancedTargetGroupService';

@Injectable()
export class CachedAdvancedTargetGroupService implements Pick<AdvancedTargetGroupService, 'getActiveTargetGroups'> {
  private targetGroupsListCache: AdvancedTargetGroup[] = null;
  private categoriesListCache: AdvancedTargetGroupCategory[] = null;

  constructor(private advancedTargetGroupService: AdvancedTargetGroupService) {}

  async getActiveTargetGroups(): Promise<AdvancedTargetGroup[]> {
    if (!this.targetGroupsListCache) {
      this.targetGroupsListCache = await this.advancedTargetGroupService.getActiveTargetGroups();
    }
    return this.targetGroupsListCache;
  }

  async getTargetGroupCategories(): Promise<AdvancedTargetGroupCategory[]> {
    if (!this.categoriesListCache) {
      this.categoriesListCache = await this.advancedTargetGroupService.getTargetGroupCategories();
    }
    return this.categoriesListCache;
  }
}
