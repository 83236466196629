import { Component, Input } from '@angular/core';

const template = `
  <div class="button-wrapper text-align--center">
    <button (click)="clickFn()">
      <span class="status-badge primary no-outline display--inline-block">
        <span class="icon-plus"></span>
      </span>
      <span class="text--info margin-left--1x">{{label}}</span>
    </button>
  </div>
`;

@Component({
  selector: 'button-with-line',
  template: template,
  styleUrls: ['./button-with-line.component.scss'],
})
export class ButtonWithLine {
  @Input() onClick;
  @Input() label: string;

  clickFn(): void {
    this.onClick();
  }
}
