import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../../utils/types';
import { AuthService } from '../../../woo_services.module/AuthService';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { CampaignTracking, TotalTVDayTracking } from '../../services/TrackingService';

const template = /* html */ `
  <two-col-table
    *ngIf="!!viewValueCol?.length"
    bold="left"
    enlarge="right"
    [left]="totalLabelCol"
    [right]="viewValueCol"
  ></two-col-table>
`;

@Component({
  selector: 'mms-totals-summary',
  template: template,
})
export class MMSTotalsSummary implements OnChanges {
  readonly adminOrPlanner = this.authService.adminOrPlanner();

  @Input() isGrossRating: boolean;
  @Input() tracking: CampaignTracking;
  @Input() videoCodes: string[];

  constructor(private formatterService: FormatterService, private authService: AuthService) {}

  nothingToShow: boolean;
  totalLabelCol: string[];
  viewValueCol: Array<string | number>;

  ngOnChanges(changes: SimpleChanges<MMSTotalsSummary>): void {
    if ((changes.tracking || changes.videoCodes) && this.tracking && this.videoCodes) {
      this.totalLabelCol = this.labels;
      this.viewValueCol = this.totalViews;
    }
  }

  private get labels(): string[] {
    return this.isGrossRating
      ? ['Totala MMS bruttokontakter', 'Totala MMS impressions']
      : ['Totala MMS completed views', 'Totala MMS impressions'];
  }

  private get totalViews(): string[] {
    const numberOfVideos = this.videoCodes.length;
    if (numberOfVideos > 1) {
      const summary = this.tracking.tv_days_totals.find((tvDay) => tvDay.video_code === 'All videos');
      return [
        this.formatterService.transformNumber(this.mmsActualsInView(summary)),
        this.formatterService.transformNumber(summary?.mms_actuals_impressions),
      ];
    } else if (numberOfVideos === 1) {
      return [
        this.formatterService.transformNumber(this.mmsActualsInView(this.tracking.tv_days_totals[0])),
        this.formatterService.transformNumber(this.tracking.tv_days_totals[0].mms_actuals_impressions),
      ];
    }

    return [];
  }

  private mmsActualsInView(tracking: TotalTVDayTracking): number {
    return this.isGrossRating ? tracking?.mms_actuals_gross_rating_views : tracking?.mms_actuals_cv;
  }
}
