import { Component } from '@angular/core';
import { WooDialogHelper } from '../../../woo_components.module/dialogs/woo-dialog-helper';
import { DashboardService } from '../../../woo_services.module/DashboardService';
import { CompactCampaign, CompactCustomer } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'campaign-selection-dialog',
  templateUrl: './campaign-selection-dialog.component.html',
})
export class CampaignSelectionDialog extends WooDialogHelper {
  show = {
    customerSelect: true,
    isLoading: false,
  };

  availableCustomers: CompactCustomer[] = [];
  availableCampaigns: CompactCampaign[] = [];

  selectedCustomer: CompactCustomer = null;
  selectedCampaign: CompactCampaign = null;

  abort: () => void;
  submit: () => void;

  constructor(private dashboardService: DashboardService) {
    super();
  }

  showSelect = (customersForUser: CompactCustomer[]): Promise<CompactCampaign> => {
    this.availableCustomers = customersForUser;
    if (this.availableCustomers.length === 1) {
      this.selectedCustomer = this.availableCustomers[0];
      this.show.customerSelect = false;
      this.getCampaignsForCustomer();
    }

    return new Promise<CompactCampaign>((resolve, reject) => {
      this.abort = () => {
        this.close();
        reject();
      };
      this.submit = () => {
        this.close();
        resolve(this.selectedCampaign);
      };
      this.open(false); // non-blocking
    });
  };

  selectedCustomerChanged(): void {
    this.getCampaignsForCustomer();
  }

  private getCampaignsForCustomer() {
    this.show.isLoading = true;
    this.availableCampaigns = [];
    this.selectedCampaign = null;

    this.dashboardService
      .getAllCampaignsForCustomer(this.selectedCustomer.id, ['name', 'id', 'ttv_campaign '], 'start_date')
      .then((campaigns) => {
        this.availableCampaigns = campaigns.filter((campaign) => !campaign.ttv_campaign);
        this.show.isLoading = false;
      });
  }
}
