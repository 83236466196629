import { Routes, ɵEmptyOutletComponent } from '@angular/router';
import { AdvancedCampaignBooking } from '../../advanced_campaign_booking.module/components/advanced-campaign-booking.component';
import { CashDashboard } from '../../cash_dashboard.module/components/cash-dashboard.component';
import { ExternalCampaignsPage } from '../../external_campaigns.module/external_campaigns_page/external-campaigns-page.component';
import { EditAdvancedTargetGroup } from '../../product_management.module/components/products_page/advanced_target_groups/edit-advanced-target-groups.component';
import { EditDeviceGroup } from '../../product_management.module/components/products_page/device_groups/edit-device-group.component';
import { EditPricelist } from '../../product_management.module/components/products_page/pricelists/edit-pricelist.component';
import { ProductsPage } from '../../product_management.module/components/products_page/products-page.component';
import { EditTtvTargetingMapping } from '../../product_management.module/components/products_page/ttv/edit-ttv-targeting-mapping.component';
import { CampaignBooking } from '../../simple_campaign_booking.module/components/campaign-booking.component';
import { CampaignConfirmation } from '../../simple_campaign_booking.module/components/campaign-confirmation/campaign-confirmation.component';
import { UserRole } from '../../utils/user-roles';
import { FileUpload } from '../../woo_components.module/file_upload/file-upload.component';
import { ShowPricelist } from '../../woo_components.module/pricelist/show-pricelist.component';
import { SystemMessagesOverview } from '../../woo_components.module/system_messages_overview/system-messages-overview.component';
import { Dashboard } from '../../woo_dashboard.module/components/dashboard/dashboard.component';
import { Eos } from '../../woo_eos.module/components/eos.component';
import { ImportEstimates } from '../../woo_estimates.module/components/import-estimates.component';
import { Buylist } from '../../woo_inventory.module/components/buylist/buylist.component';
import { Inventory } from '../../woo_inventory.module/components/inventory/inventory.component';
import { ShowMail } from '../../woo_mails.module/components/show-mail.component';
import { StatisticsDashboard } from '../../woo_statistics_dashboard.module/components/statistics-dashboard.component';
import { StyleSheet } from '../../woo_style_sheet.module/components/style_sheet/style-sheet.component';
import { SystemSettings } from '../../woo_system_settings.module/components/system_settings/system-settings.component';
import { ManageAccounts } from '../../woo_user_accounts.module/components/manage-accounts.component';
import { NewUser } from '../../woo_user_accounts.module/components/new-user.component';
import { EditUser } from '../components/accounts/edit-user.component';
import { MyAccount } from '../components/accounts/my-account.component';
import { AgencyComponent } from '../components/agency/agency.component';
import { AgencyOverview } from '../components/agency_overview/agency-overview.component';
import { ManageCreativeAssets } from '../components/creative_assets/manage-creative-assets-component';
import { SelectCustomerToViewAssetsFor } from '../components/creative_assets/select-customer-to-view-assets-for.component';
import { Customer } from '../components/customer/customer.component';
import { EditOngoingCampaignPage } from '../components/edit_started_campaign/edit-ongoing-campaign-page.component';
import { ForgotPassword } from '../components/forgot_password/forgot-password.component';
import { Help } from '../components/help/help.component';
import { InvoiceManagement } from '../components/invoice_management/invoice-management.component';
import { Landing } from '../components/landing/landing.component';
import { RevenueReportsPage } from '../components/revenue_reports_page/revenue-reports-page.component';
import { ReviewCampaign } from '../components/review_campaign/review-campaign.component';
import { SelfServiceCompensation } from '../components/self_service_compensation/self-service-compensation.component';
import { SetFirstPassword } from '../components/set_first_password/set-first-password.component';
import { WooStatus } from '../components/status/woo-status.component';
import { TermsAndConditionsPage } from '../components/terms_and_conditions/terms-and-conditions-page.component';
import { TTVCampaignBooking } from '../components/ttv_campaign_booking/ttv-campaign-booking.component';
import { UpdatePassword } from '../components/update_password/update-password.component';
import { AuthGuard } from '../guards/auth.guard';
import { LandingGuard } from '../guards/landing.guard';
import { RoleGuard } from '../guards/role.guard';
import { TermsPageGuard } from '../guards/terms-page.guard';
import { TermsGuard } from '../guards/terms.guard';
import { cashBookingPageMatcher } from './route_matchers/cash-booking-page-matcher';
import { dashboardPageMatcher } from './route_matchers/dashboard-page-matcher';

export const routes: Routes = [
  {
    path: 'start',
    children: [
      {
        path: '',
        component: ɵEmptyOutletComponent,
      },
      {
        path: '',
        component: Landing,
        outlet: 'mainFull',
      },
    ],
  },
  {
    path: 'accounts',
    children: [
      {
        path: '',
        component: ManageAccounts,
        canActivate: [RoleGuard],
        data: {
          roles: [
            UserRole.agencyAdmin,
            UserRole.agency,
            UserRole.planner,
            UserRole.externalPlanner,
            UserRole.admin,
            UserRole.accounting,
            UserRole.sales,
            UserRole.salesManager,
            UserRole.agencyUser,
            UserRole.client,
          ],
        },
      },
      {
        path: 'new',
        component: NewUser,
        canActivate: [RoleGuard],
        data: {
          roles: [UserRole.externalPlanner, UserRole.admin],
        },
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'agency/:id',
    component: AgencyComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        UserRole.agencyAdmin,
        UserRole.agency,
        UserRole.planner,
        UserRole.admin,
        UserRole.accounting,
        UserRole.sales,
        UserRole.salesManager,
        UserRole.agencyUser,
      ],
    },
  },
  {
    path: 'user/:id',
    component: EditUser,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        UserRole.agencyAdmin,
        UserRole.agency,
        UserRole.planner,
        UserRole.externalPlanner,
        UserRole.admin,
        UserRole.accounting,
        UserRole.agencyUser,
        UserRole.client,
      ],
    },
  },

  {
    path: 'customer/:id',
    component: Customer,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        UserRole.agencyAdmin,
        UserRole.agency,
        UserRole.planner,
        UserRole.admin,
        UserRole.accounting,
        UserRole.sales,
        UserRole.salesManager,
        UserRole.agencyUser,
        UserRole.client,
      ],
    },
  },
  {
    path: 'device_groups/:id',
    component: EditDeviceGroup,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.estimator],
    },
  },
  {
    path: 'advanced_target_groups/:id',
    component: EditAdvancedTargetGroup,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.estimator],
    },
  },
  {
    path: 'my-account',
    component: MyAccount,
    canActivate: [AuthGuard],
  },
  {
    // Forgot password mail
    path: 'forgot-password',
    component: ForgotPassword,
  },
  {
    // Welcome mail
    path: 'change-password',
    component: SetFirstPassword,
  },
  {
    // User has to reset password
    path: 'update-password',
    component: UpdatePassword,
  },
  {
    path: 'import',
    component: ImportEstimates,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.planner, UserRole.estimator, UserRole.accounting],
    },
  },
  {
    matcher: dashboardPageMatcher,
    component: Dashboard,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        UserRole.client,
        UserRole.agencyUser,
        UserRole.agencyAdmin,
        UserRole.agency,
        UserRole.planner,
        UserRole.admin,
        UserRole.sales,
        UserRole.accounting,
        UserRole.externalPlanner,
        UserRole.salesManager,
      ],
    },
  },
  {
    path: 'terms',
    component: TermsAndConditionsPage,
    canActivate: [AuthGuard, TermsPageGuard],
  },
  {
    path: 'file_upload',
    component: FileUpload,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.planner],
    },
  },
  {
    path: 'stylesheet',
    component: StyleSheet,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin],
    },
  },
  {
    path: 'campaigns',
    children: [
      {
        path: 'new',
        children: [
          {
            path: '',
            component: ɵEmptyOutletComponent,
          },
          {
            path: '',
            component: AdvancedCampaignBooking,
            outlet: 'mainFull',
          },
        ],
        canActivate: [AuthGuard, RoleGuard, TermsGuard],
        data: {
          roles: [
            UserRole.client,
            UserRole.agencyUser,
            UserRole.agencyAdmin,
            UserRole.agency,
            UserRole.planner,
            UserRole.admin,
            UserRole.salesManager,
          ],
        },
      },
      {
        path: ':id',
        children: [
          {
            path: 'edit_ongoing',
            component: EditOngoingCampaignPage,
            canActivate: [AuthGuard, RoleGuard],
            data: {
              roles: [UserRole.admin, UserRole.planner],
            },
          },
          {
            path: 'review',
            component: ReviewCampaign,
            canActivate: [AuthGuard, RoleGuard],
            data: {
              roles: [UserRole.admin, UserRole.planner],
            },
          },
          {
            path: 'edit',
            children: [
              {
                path: '',
                component: ɵEmptyOutletComponent,
              },
              {
                path: '',
                component: AdvancedCampaignBooking,
                outlet: 'mainFull',
              },
            ],
            canActivate: [AuthGuard, RoleGuard, TermsGuard],
            data: {
              roles: [
                UserRole.client,
                UserRole.agencyUser,
                UserRole.agencyAdmin,
                UserRole.agency,
                UserRole.planner,
                UserRole.admin,
                UserRole.salesManager,
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: 'inventory',
    component: Inventory,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        UserRole.admin,
        UserRole.planner,
        UserRole.estimator,
        UserRole.sales,
        UserRole.accounting,
        UserRole.externalPlanner,
        UserRole.salesManager,
      ],
    },
  },
  {
    path: 'eos',
    component: Eos,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.eos, UserRole.accounting],
    },
  },
  {
    path: 'external_campaigns',
    component: ExternalCampaignsPage,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.planner, UserRole.salesManager],
    },
  },
  {
    path: 'help',
    component: Help,
  },
  {
    path: 'system',
    component: SystemSettings,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin],
    },
  },
  {
    path: 'status',
    component: WooStatus,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        UserRole.admin,
        UserRole.sales,
        UserRole.statusUser,
        UserRole.planner,
        UserRole.accounting,
        UserRole.salesManager,
      ],
    },
  },
  {
    path: 'pricelist',
    children: [
      {
        path: '',
        component: ShowPricelist,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          exemptedRoles: [UserRole.cashUser],
        },
      },
    ],
  },
  {
    path: 'agency_overview',
    component: AgencyOverview,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.accounting, UserRole.planner, UserRole.salesManager],
    },
  },
  {
    path: 'invoice_overview',
    component: InvoiceManagement,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.accounting],
    },
  },
  {
    path: 'self_service_compensation',
    component: SelfServiceCompensation,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.planner, UserRole.agencyAdmin, UserRole.accounting, UserRole.salesManager],
    },
  },
  {
    path: 'products/ttv/:id/edit',
    component: EditTtvTargetingMapping,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin],
    },
  },
  {
    path: 'products/pricelist/:id',
    component: EditPricelist,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin],
    },
  },
  {
    path: 'products',
    children: [
      {
        path: ':tab',
        component: ProductsPage,
      },
      {
        path: '',
        redirectTo: 'initial',
        pathMatch: 'full',
      },
    ],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.estimator, UserRole.planner],
    },
  },
  {
    path: 'revenue_reports',
    component: RevenueReportsPage,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.accounting],
    },
  },
  {
    path: 'buylist',
    component: Buylist,
    canActivate: [AuthGuard],
  },
  {
    path: 'system_messages',
    component: SystemMessagesOverview,
    canActivate: [AuthGuard],
  },
  {
    matcher: cashBookingPageMatcher,
    component: CampaignBooking,
    canActivate: [AuthGuard, RoleGuard, TermsGuard],
    data: {
      roles: [UserRole.admin, UserRole.cashUser, UserRole.sales, UserRole.salesManager],
    },
  },
  {
    path: 'customer/:customerId',
    children: [
      {
        path: 'campaign/:campaignId/confirmation',
        children: [
          {
            path: '',
            component: CampaignConfirmation,
            outlet: 'mainFull',
          },
          {
            path: '',
            component: ɵEmptyOutletComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard, TermsGuard],
        data: {
          roles: [UserRole.admin, UserRole.cashUser, UserRole.sales, UserRole.salesManager],
        },
      },
      {
        path: 'dashboard',
        component: CashDashboard,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [UserRole.admin, UserRole.cashUser, UserRole.sales, UserRole.salesManager],
        },
      },
      {
        path: 'creative_assets',
        component: ManageCreativeAssets,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: [UserRole.admin, UserRole.planner],
        },
      },
    ],
  },
  {
    path: 'mails/:mail_type/:campaign_id',
    component: ShowMail,
    canActivate: [AuthGuard],
  },
  {
    path: 'new_ttv_booking',
    component: TTVCampaignBooking,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.planner],
    },
  },
  {
    path: 'statistics',
    component: StatisticsDashboard,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin],
    },
  },
  {
    path: 'select_customer_to_view_assets_for',
    component: SelectCustomerToViewAssetsFor,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRole.admin, UserRole.planner],
    },
  },
  {
    path: '',
    component: Landing,
    outlet: 'mainFull',
    pathMatch: 'full',
    canActivate: [LandingGuard],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
