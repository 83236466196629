import { Injectable } from '@angular/core';
import { RegionService } from '../../woo_services.module/RegionService';
import { Region } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedRegionService implements Pick<RegionService, 'getRegions'> {
  private listCache: Region[] = null;

  constructor(private regionService: RegionService) {}

  async getRegions(): Promise<Region[]> {
    if (!this.listCache) {
      this.listCache = await this.regionService.getRegions();
    }
    return this.listCache;
  }
}
