<form [formGroup]="urlForm">
  <div class="flex__container align-items--end">
    <div class="input__container  margin--none">
      <span class="input__optional__text" *ngIf="!isRequired">Frivillig</span>
      <label for="video-url">Video-URL</label>
      <input type="url" id="video-url" formControlName="url" [wooInputErrors]="{absolute: true}">
    </div>

    <button type="button" class="button margin--none info margin-left--1x"
      [disabled]="!customerId" (click)="toggleShowVideoSelect()">
      <span class="chevron" [ngClass]="{bottom: showVideoSelect}"></span>
    </button>
  </div>
</form>

<div class="row padding-top--2x" *ngIf="showVideoSelect" [@verticalExpansion]>
  <video-upload
    class="col-md-6 col-xs-12"
    [customerId]="customerId"
    [(ngModel)]="selectedVideo"
    (ngModelChange)="selectedVideoChange($event)"
    (onUseSelectedVideosCode)="setVideoCode($event)"
    >
  </video-upload>
  <div class="col-md-6 col-xs-12 padding-left--1x">
    <asset-list
      *ngIf="!matchingCodeAssets.length && !matchingDurationAssets.length"
      [assets]="assets"
      [selectedVideo]="selectedVideo"
      (onVideoClick)="selectedVideoClick($event)"></asset-list>
    <asset-list
      *ngIf="matchingCodeAssets.length"
      listHeader="Matchande filmkod"
      [assets]="matchingCodeAssets"
      [selectedVideo]="selectedVideo"
      (onVideoClick)="selectedVideoClick($event)"></asset-list>
    <asset-list
      *ngIf="matchingDurationAssets.length"
      listHeader="Matchande filmlängd"
      [assets]="matchingDurationAssets"
      [selectedVideo]="selectedVideo"
      (onVideoClick)="selectedVideoClick($event)"></asset-list>
    <asset-list
      *ngIf="showNoMatchAssets()"
      listHeader="Övriga"
      [assets]="noMatchAssets"
      [selectedVideo]="selectedVideo"
      (onVideoClick)="selectedVideoClick($event)"></asset-list>
    <asset-list
      *ngIf="hideUnselectedDurations && unknownDataAssets.length"
      listHeader="Okänd matchning"
      [assets]="unknownDataAssets"
      [selectedVideo]="selectedVideo"
      (onVideoClick)="selectedVideoClick($event)"></asset-list>
  </div>
</div>
