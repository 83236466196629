import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { CampaignStatus, Creative, Targeting } from '../../woo_services.module/shared-types';
import { TargetingService } from '../../woo_services.module/TargetingService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { lastValueFrom } from 'rxjs';

export interface CashCampaign {
  id: string;
  name: string;
  start_date: Date;
  end_date: Date;
  stopped_date: Date;
  cancelled_date: Date;
  updated_at: Date;
  budget: number;
  status: CampaignStatus;
  creatives: Creative[];
  targetings: Targeting[];
  targetingName?: string;
  outcome?: Outcome;
  agreement?: Agreement;
}

export interface Agreement {
  net_impressions: any;
}

export interface Outcome {
  impressions: any;
  click_throughs: any;
  ads_completed: any;
}

@Injectable()
export class CashDashboardService {
  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private targetingService: TargetingService,
    private translationService: TranslationService,
  ) {}

  getCampaigns(customerId: string): Promise<CashCampaign[]> {
    return lastValueFrom(
      this.http
        .get(`${this.env.apiUrl}/cash_campaigns`, {
          params: { customer_id: customerId },
        })
        .pipe(
          tap((campaigns: CashCampaign[]) => {
            campaigns.forEach((campaign) => (campaign.targetingName = this.getTargetName(campaign.targetings[0])));
            campaigns.forEach((campaign) => (campaign.status = this.getStatus(campaign)));
          }),
        ),
    );
  }

  private getTargetName(targeting: Targeting): string {
    const bookingFormatSelection = this.targetingService.getBookingFormatSelectionFromTargeting(targeting);
    return this.translationService.convertBookingFormatForCashCampaign(bookingFormatSelection);
  }

  private getStatus(campaign: CashCampaign): CampaignStatus {
    if (campaign.stopped_date) {
      return CampaignStatus.stopped;
    } else if (campaign.cancelled_date) {
      return CampaignStatus.cancelled;
    }
    return campaign.status;
  }
}
