<ul class="ul__woo">
  <li [ngClass]="getRequirementClass('minlength')">
    Minst {{ requirements.minLength }} tecken
  </li>
  <li [ngClass]="getRequirementClass('uppercaseMissing')">
    Minst en stor bokstav ({{ requirements.uppercaseLetters }})
  </li>
  <li [ngClass]="getRequirementClass('lowercaseMissing')">
    Minst en liten bokstav ({{ requirements.lowercaseLetters }})
  </li>
  <li [ngClass]="getRequirementClass('numberMissing')">
    Minst en siffra ({{ requirements.numbers }})
  </li>
  <li [ngClass]="getRequirementClass('specialCharacterMissing')">
    Minst ett specialtecken ({{ requirements.specialCharacters }})
  </li>
</ul>

<form [formGroup]="form" (ngSubmit)="updatePassword()">
  <div class="input__container" *ngIf="current">
    <label for="currentPassword">Nuvarande lösenord</label>
    <input
      type="password"
      id="currentPassword"
      formControlName="currentPassword"
      [required]="current"
    />
  </div>
  <div class="input__container">
    <label for="password">Nytt lösenord</label>
    <input type="password" id="password" formControlName="password" />
  </div>
  <div class="input__container">
    <label for="password">Bekräfta ditt nya lösenord</label>
    <input
      type="password"
      id="confirmPassword"
      formControlName="confirmPassword"
    />
  </div>

  <button
    id="update-password-button"
    type="submit"
    class="button"
    [disabled]="!form.valid"
  >
    {{ buttonText }}
  </button>
</form>
