import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { orderBy } from '../../utils/array';
import { SimpleChanges } from '../../utils/types';
import { UserRole } from '../../utils/user-roles';
import { AgencyService } from '../../woo_services.module/AgencyService';
import { AuthService } from '../../woo_services.module/AuthService';
import { Agency, CompactCustomer, User, wooId } from '../../woo_services.module/shared-types';
import { UserService } from '../../woo_services.module/UserService';

@Component({
  selector: 'edit-user-customer-access',
  templateUrl: './edit-user-customer-access.component.html',
})
export class EditUserCustomerAccess implements OnChanges {
  readonly currentUser = this.authService.getUser();

  @Input() user: User;
  @Output() refresh = new EventEmitter();
  userAgencies: Agency[] = [];

  constructor(
    private agencyService: AgencyService,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  ngOnChanges(changes: SimpleChanges<EditUserCustomerAccess>): void {
    if (changes.user && this.user) {
      this.loadAgencies();
    }
  }

  grantAccessToAllCustomers(agency: Agency): void {
    Promise.all(
      agency.customers
        .filter((c) => !this.isAgencyUserAtCustomer(this.user, c.id))
        .map((customer) => this.userService.grantAccess(this.user.id, customer.id, agency.id)),
    ).then(() => this.refresh.emit());
  }

  removeAccessAllCustomers(agency: Agency): void {
    Promise.all(
      agency.customers
        .filter((c) => this.isAgencyUserAtCustomer(this.user, c.id))
        .map((customer) => this.userService.revokeAccess(this.user.id, customer.id)),
    ).then(() => this.refresh.emit());
  }

  toggleAccess(customer: CompactCustomer, agency: Agency): void {
    if (this.isAgencyUserAtCustomer(this.user, customer.id)) {
      this.userService.revokeAccess(this.user.id, customer.id).then((user) => {
        this.user.roles = user.roles;
      });
    } else {
      this.userService.grantAccess(this.user.id, customer.id, agency.id).then((user) => {
        this.user.roles = user.roles;
      });
    }
  }

  canGrantAccessToCustomers(agencyId: wooId): boolean {
    return this.hasAccountHandlingRole() || this.isAgencyAdmin(this.currentUser, agencyId);
  }

  isAgencyUser(user: User, agencyId: wooId): boolean {
    return this.hasRole(user, UserRole.agencyUser, agencyId);
  }

  isAgencyAdmin(user: User, agencyId: wooId): boolean {
    return this.hasRole(user, UserRole.agencyAdmin, agencyId);
  }

  private loadAgencies() {
    if (this.user.agency_ids && this.user.agency_ids.length > 0) {
      Promise.all(
        this.user.roles
          .filter((r) => r.name !== UserRole.agencyUserAtCustomer)
          .filter(
            (role) =>
              this.isAgencyAdmin(this.currentUser, role.resource_id) ||
              this.isAgencyUser(this.currentUser, role.resource_id) ||
              this.hasAccountHandlingRole(),
          )
          .map((role) => this.agencyService.getAgency(role.resource_id)),
      ).then((agencies) => {
        agencies.forEach((a) => (a.customers = orderBy(orderBy(a.customers, 'name'), 'nickname')));
        this.userAgencies = orderBy(orderBy(agencies, 'name'), 'nickname');
      });
    }
  }

  private isAgencyUserAtCustomer(user: User, customerId: wooId) {
    return this.hasRole(user, UserRole.agencyUserAtCustomer, customerId);
  }

  private hasAccountHandlingRole(): boolean {
    return this.authService.hasAnyRole([UserRole.admin, UserRole.planner, UserRole.accounting]);
  }

  private hasRole(user: User, role: UserRole, resourceId: wooId): boolean {
    return Boolean(user.roles.find((r) => r.name === role && r.resource_id === resourceId));
  }
}
