import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { round, roundDecimalError } from '../../utils/math';
import { SimpleChanges } from '../../utils/types';

export function validPercentage(value: any): boolean {
  return !Number.isNaN(parseFloat(value));
}

const DEFAULT_OPTIONS = {
  allowEmpty: true,
  modelFormat: PercentModelFormat.decimal,
  precision: 10,
};

@Directive({
  selector: '[wooPercentFormatter]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: WooPercentFormatter,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: WooPercentFormatter,
      multi: true,
    },
  ],
})
export class WooPercentFormatter implements ControlValueAccessor, OnChanges, Validator {
  @Input('wooPercentFormatter') options = DEFAULT_OPTIONS;

  private lastViewValue = null;

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  get allowEmpty(): boolean {
    return this.options && this.options.allowEmpty ? this.options.allowEmpty : false;
  }

  get modelFormat(): PercentModelFormat {
    return this.options && this.options.modelFormat ? this.options.modelFormat : PercentModelFormat.decimal;
  }

  get modelFormatFactor(): number {
    return this.modelFormat === PercentModelFormat.decimal ? 100 : 1;
  }

  ngOnChanges(changes: SimpleChanges<WooPercentFormatter>): void {
    if (changes.options) {
      this.options = {
        ...DEFAULT_OPTIONS,
        ...this.options,
      };
    }
  }

  @HostListener('input', ['$event.target.value'])
  input(viewValue: string): void {
    this.lastViewValue = viewValue;
    const modelValue = this.convertViewToModel(viewValue);
    this.propagateChange(Number.isNaN(modelValue) ? null : modelValue);
  }

  @HostListener('blur', ['$event.target.value'])
  blur(viewValue: string): void {
    const modelValue = this.convertViewToModel(viewValue);
    this.updateView(Number.isNaN(modelValue) ? this.lastViewValue : modelValue);
  }

  writeValue(modelValue: number): void {
    if (modelValue !== undefined && modelValue !== null) {
      this.updateView(modelValue);
    }
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }

  validate = (c: AbstractControl): ValidationErrors => {
    return validPercentage(c.value) || (this.allowEmpty && !c.value)
      ? null
      : { invalidPercentage: 'Värdet måste kunna tolkas som ett procentvärde' };
  };

  private convertViewToModel(viewValue: string): number {
    const valueWithoutPercent = viewValue.replace(',', '.').replace(/[^\d.\-+]/g, '');
    return round(parseFloat(valueWithoutPercent) / this.modelFormatFactor, this.options.precision);
  }

  private updateView(modelValue: any) {
    const isNumber = validPercentage(modelValue);
    const formattedValue = isNumber
      ? `${roundDecimalError(modelValue * this.modelFormatFactor).toLocaleString()}%`
      : modelValue;
    this.renderer.setProperty(this.element.nativeElement, 'value', formattedValue);
  }

  private propagateChange = (_: any) => null;
  private propagateTouch = (_: any) => null;
}

export const enum PercentModelFormat {
  decimal = 'decimal',
  percent = 'percent',
}
