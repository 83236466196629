<div class="relative">
  <form [formGroup]="newUserFrom" (ngSubmit)="newUserFrom.valid ? createUser() : setFormDirty(newUserFrom)" >
    <div class="input__container">
      <label for="firstName">Förnamn</label>
      <input id="firstName" formControlName="firstName" type="text" wooInputErrors>
    </div>
    <div class="input__container">
      <label for="lastName">Efternamn</label>
      <input id="lastName" formControlName="lastName" type="text" wooInputErrors>
    </div>
    <div class="input__container">
      <label for="email">Email</label>
      <input id="email" formControlName="email" type="email" wooInputErrors>
    </div>
    <div class="select__container" *ngIf="allowToChangeRole">
      <label for="role">Välj roll</label>
      <select id="role" formControlName="role" wooInputErrors>
        <option value=''></option>
        <option *ngFor="let role of availableRoles" [value]="role">{{convertRoles(role)}}</option>
      </select>
    </div>

    <div *ngIf="selectAdOrgData">
      <small>Rollen behöver knytas till en organisation.</small>
      <div class="select__container">
        <label for="organisation">Välj organisation</label>
        <select id="organisation" formControlName="organisation">
          <option value=''></option>
          <option *ngFor="let org of selectAdOrgData.organisations" [ngValue]="org">{{org.name}}</option>
        </select>
      </div>
    </div>

    <div class="margin-bottom--3x" *ngIf="selectCustomerData">
      <small>{{selectCustomerData.description}}</small>
      <div class="select__container">
        <label for="customer">Välj kund</label>
        <select id="customer" formControlName="customer">
          <option value=''></option>
          <option *ngFor="let customer of selectCustomerData.customers" [ngValue]="customer">{{customer.name}}</option>
        </select>
      </div>
    </div>

    <div class="margin-bottom--3x" *ngIf="selectAgency && allowedToAssignAgencyRoles">
      <small>
        Byråanvändare behöver knytas till en eller flera byråer. De kan även ges administratörsrättigheter för
        respektive byrå.
      </small>
      <agency-role-select formControlName="agencyRoles" [agencies]="agencies"></agency-role-select>
    </div>

    <div>
      <button type="submit" class="button primary-1">{{submitLabel}}</button>
      <button type="button" (click)="onAbort.emit()" class="link primary-1">Avbryt</button>
    </div>
  </form>
  <div class="position--absolute display--flex justify-content--center align-items--center cover" *ngIf="!loadingCompleted">
    <dot-loader [visibility]="true" [large]="true"></dot-loader>
  </div>
</div>
