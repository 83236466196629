import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { CampaignService } from '../../../../woo_services.module/CampaignService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { AttestStatus, Invoice, InvoiceService } from '../../../../woo_services.module/InvoiceService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { Campaign } from '../../../../woo_services.module/shared-types';
import { StopCampaignDialog } from '../stop_campaign_dialog/stop-campaign-dialog.component';

const template = /* html */ `
  <text-button
    icon="close-x"
    [disabled]="disabled"
    (click)="onClick()"
  >
    Stoppa
  </text-button>
`;

@Component({
  selector: 'stop-campaign-button',
  template,
})
export class StopCampaignButton {
  @Input() campaign: Campaign;
  @Input() disabled = false;

  constructor(
    private campaignService: CampaignService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private invoiceService: InvoiceService,
  ) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    const stopCampaignDialog = this.dialogService.create(StopCampaignDialog);
    stopCampaignDialog
      .show(this.campaign.order_value)
      .then(
        (dialogData) => {
          this.stopCampaign(this.campaign, dialogData.comment, dialogData.newOrderValue);
        },
        () => null,
      )
      .finally(() => stopCampaignDialog.close());
  };

  private stopCampaign = (campaign: Campaign, comment: string, newOrderValue: number) => {
    this.dialogService.openBlocking('Stoppar kampanjen');
    this.campaignService
      .stop(campaign.id, comment, newOrderValue)
      .then(
        (campaign: Campaign) => {
          this.invoiceService
            .getInvoicesForCampaign(campaign.id)
            .then((invoices: Invoice[]) => {
              const newCreditInvoices = invoices.filter((invoice) => {
                return invoice.status === 'created' && invoice.attest_status === AttestStatus.AwaitingAttest;
              });
              if (newCreditInvoices.length !== 0) {
                this.dialogService.openSuccess(
                  'Kampanjen har stoppats.',
                  'Kreditfaktura är skapad!',
                  'Ni måste skapa ett Scrive-underlag för att ekonomi ska kunna godkänna denna kreditfaktura.',
                );
              } else {
                this.dialogService.openSuccess('Kampanjen har stoppats.');
              }
            })
            .catch(() => {
              this.dialogService.openSuccess('Kampanjen har stoppats.');
            })
            .finally(() => this.routingService.navigate(['/dashboard']));
        },
        (reason) => {
          if (!(reason instanceof HttpErrorResponse)) {
            const msg = 'Ajdå, något gick snett. Din kampanj kunde inte stoppas.';
            this.dialogService.openError(msg);
          }
        },
      )
      .catch(() => {
        const msg = 'Ajdå, nånting gick snett. Testa att refresha browsern och försök igen.';
        this.dialogService.openError(msg);
      });
  };
}
