<woo-dialog>
  <h2>Duplicera kampanj</h2>
  <p>Skapar en ny kampanj baserad på en tidigare.</p>
  <dot-loader [visibility]="show.isLoading" description="Hämtar..."></dot-loader>
  <woo-dropdown
    *ngIf="show.customerSelect"
    [items]="availableCustomers"
    [(ngModel)]="selectedCustomer"
    (ngModelChange)="selectedCustomerChanged()"
    bindLabel="name"
    inputId="customer-search"
    labelText="Kund"
    [searchAttributes]="['name', 'nickname']"
    notFoundText="Inga kunder hittades."
  ></woo-dropdown>
  <woo-dropdown
    *ngIf="availableCampaigns.length"
    [items]="availableCampaigns"
    [(ngModel)]="selectedCampaign"
    bindLabel="name"
    inputId="campaign-search"
    labelText="Kampanj"
    [searchAttributes]="['name']"
    notFoundText="Inga kampanjer hittades."
  ></woo-dropdown>

  <div *ngIf="selectedCustomer && !availableCampaigns.length && !show.isLoading">
  	<p class="text--medium"><span class="icon-warning text--info"></span> Den valda kunden har inga tidigare bokade kampanjer</p>
    <button class="button primary-1" (click)="submit()"><span class="text--medium thin">Skapa en helt ny kampanj</span></button>
  </div>

  <button *ngIf="selectedCampaign && !show.isLoading" type="button" class="button primary-1" (click)="submit()">Duplicera kampanj</button>
</woo-dialog>
