import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SimpleChanges, ToFormGroup } from '../../utils/types';
import { validateRequired } from '../../utils/validators';
import { Campaign, Goal, Targeting } from '../../woo_services.module/shared-types';

const template = /* html */ `
<form [formGroup]="form" autocomplete="off" *ngIf="showMmsGainForm">
  <div class="row">
    <div class="col-md-3">
      <div class="input__container">
        <label for="mms-gain-input">MMS-stärkning för videomål</label>
        <input
          id="mms-gain-input-{{campaign.id}}"
          formControlName="mmsGain"
          wooPercentFormatter
          [wooMinValidator]="0"
          [wooInputErrors]="{ absolute: false }"
        />
      </div>
    </div>
  </div>
</form>
<div class="row" *ngIf="!showMmsGainForm">
  <div class="col-md-4" *ngIf="!automaticMmsGainActive && campaignCanBeMmsGained">
    Automatisk mms-stärkning är avstängd
  </div>
  <div class="col-md-4" *ngIf="!campaignCanBeMmsGained">
    Kampanjen kommer inte MMS-stärkas:
    <ul>
      <li>Innehåller bara Pausreklam</li>
      <li>Vast-kampanj bokad i Completed Views </li>
    </ul>
  </div>
</div>
`;

@Component({
  selector: 'campaign-mms-gain-form',
  template: template,
})
export class CampaignMmsGainForm implements OnChanges {
  @Input() campaign: Campaign;
  @Input() disabled = false;
  @Output() onValidityChanges = new EventEmitter<boolean>();
  @Output() onValueChanges = new EventEmitter<CampaignMmsGainFormData>();

  showMmsGainForm = true;
  automaticMmsGainActive = true;
  campaignCanBeMmsGained = true;

  form: FormGroup<ToFormGroup<CampaignMmsGainFormData>> = this.fb.group({
    mmsGain: new FormControl(null, validateRequired()),
  });

  private get formValues() {
    return <CampaignMmsGainFormData>this.form?.value;
  }
  constructor(private fb: FormBuilder) {
    this.form.valueChanges.subscribe(() => this.onValueChanges.emit(this.formValues));
    this.form.statusChanges.subscribe(() => this.onValidityChanges.emit(this.form.valid));
  }

  ngOnChanges(changes: SimpleChanges<CampaignMmsGainForm>): void {
    if (changes.campaign && this.campaign) {
      this.setCampaign(this.campaign);
    }

    if (changes.disabled) {
      if (this.disabled) {
        this.form.disable({ onlySelf: true, emitEvent: false });
        if (!changes.campaign) {
          this.setCampaign(this.campaign);
        }
      } else {
        this.form.enable({ onlySelf: true, emitEvent: false });
      }
    }
  }

  setCampaign = (campaign: Campaign): void => {
    if (!campaign) {
      return;
    }

    this.showMmsGainForm = this.mmsGainFormVisible(campaign);
    const formValues: CampaignMmsGainFormData = {
      mmsGain: campaign.mms_gain,
    };

    this.form.reset(formValues, { onlySelf: true, emitEvent: false });
  };

  private mmsGainFormVisible(campaign: Campaign): boolean {
    this.automaticMmsGainActive = campaign.automatic_mms_gain;
    this.campaignCanBeMmsGained = this.isAnyPartOfCampaignMmsTracked(campaign);

    return this.automaticMmsGainActive && this.campaignCanBeMmsGained;
  }

  private isAnyPartOfCampaignMmsTracked(campaign: Campaign): boolean {
    return campaign.targetings.some((targeting: Targeting) => targeting.goals?.some((g: Goal) => g.allow_mms_gain));
  }
}

export interface CampaignMmsGainFormData {
  mmsGain: number;
}
