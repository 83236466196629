import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * This directive triggers a callback when files are dropped on the target element
 */
@Directive({
  selector: '[wooFileDrop]',
})
export class WooFileDrop {
  @Output('wooFileDrop') fileEmitter = new EventEmitter<FileList>();

  constructor(private elem: ElementRef) {}

  @HostListener('dragover', ['$event'])
  dragOver(event: any): void {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  drop(event: any): void {
    event.preventDefault();
    this.fileEmitter.emit(event.dataTransfer.files);
  }
}
