import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import {
  PerIdEstimates,
  Publisher,
  SlotType,
  SlotTypeEstimates,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-completion-rates-estimates',
  templateUrl: './manage-completion-rates-estimates.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageCompletionRatesEstimates implements OnInit {
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    estimatesHaveChanged: false,
    updatingEstimates: false,
    isEditing: false,
    loading: true,
  };
  readonly tableHeaders = ['Placering', 'Preroll', 'Midroll', 'Postroll', 'Preroll Sticky', 'Postroll Sticky'];
  readonly staticFields = 1;

  publishers = [] as Publisher[];
  selectedPublisher: Publisher;
  completionRateRows: CompletionRateRow[] = [];
  includedPublisherSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  estimates = null as PerIdEstimates;
  slotTypes = [SlotType.preroll, SlotType.midroll, SlotType.postroll, SlotType.prerollSticky, SlotType.postrollSticky];

  @Input('headerPos') parentHeader: HTMLElement;
  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private utilityService: ProductsPageUtilityService,
    public dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.getAndBuildCompletionRates()
      .then(() => {
        this.productFormatService
          .getProductFormats(ExtraProductFormatField.slotTypes, ExtraProductFormatField.enabled)
          .then((productFormats) => {
            this.includedPublisherSlotTypes = this.utilityService.setIncludedPublisherSlotTypes(
              productFormats.filter((f) => f.enabled),
            );
          });
      })
      .finally(() => {
        this.show.loading = false;
        scrollTo(this.parentHeader);
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      });
  }

  async getAndBuildCompletionRates(): Promise<void> {
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled, ExtraPublisherField.completionRates)
      .then((publishers) => {
        this.publishers = publishers.filter((p) => p.enabled);
        this.buildCompletionRateRows();
      });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildCompletionRates();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedPublisher;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade publicister sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.updatingEstimates = true;
        this.show.loading = true;
        this.publisherService.updatePublishers(this.publishers).then(() => {
          this.getAndBuildCompletionRates();
          this.show.estimatesHaveChanged = true;
        });
      })
      .finally(() => {
        this.show.updatingEstimates = false;
        this.show.loading = false;
        this.setEditing(false);
      });
  }

  rowVisible(obj: CompletionRateRow): boolean {
    return !this.selectedPublisher || !(obj.publisherId !== this.selectedPublisher.id);
  }

  getPublisherCompletionRate(publisherId: wooId): SlotTypeEstimates {
    return this.publishers.find((publisher) => publisher.id === publisherId).completion_rates;
  }

  private buildCompletionRateRows(): void {
    let completionRateRows: CompletionRateRow[] = [];

    this.publishers.map((publisher) => {
      completionRateRows = [
        ...completionRateRows,
        {
          publisher: publisher.name,
          publisherId: publisher.id,
          preroll: publisher.completion_rates.preroll,
          midroll: publisher.completion_rates.midroll,
          postroll: publisher.completion_rates.postroll,
          prerollSticky: publisher.completion_rates.preroll_sticky,
          postrollSticky: publisher.completion_rates.postroll_sticky,
        },
      ];
    });
    this.completionRateRows = completionRateRows;
  }
}

interface CompletionRateRow {
  publisher: string;
  publisherId?: wooId;
  preroll?: number;
  midroll?: number;
  postroll?: number;
  prerollSticky?: number;
  postrollSticky?: number;
}
