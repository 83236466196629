import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ignore422 } from '../../../utils/utils';
import { AuthService } from '../../../woo_services.module/AuthService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { PasswordService } from '../../../woo_services.module/PasswordService';
import { SessionService } from '../../../woo_services.module/SessionService';
import { ToFormGroup } from '../../../utils/types';

@Component({
  selector: 'update-password-form',
  templateUrl: './update-password-form.component.html',
})
export class UpdatePasswordForm implements OnInit {
  @Input() current: boolean;
  @Input() token: string;
  @Input() buttonText: string;
  requirements;

  form: FormGroup<ToFormGroup<PasswordForm>> = this.fb.group({
    currentPassword: new FormControl(''),
    password: new FormControl('', [
      Validators.required,
      () => this.validateMinLength(),
      () => this.validateNumberMissing(),
      () => this.validateLowercaseMissing(),
      () => this.validateUppercaseMissing(),
      () => this.validateSpecialCharacterMissing(),
    ]),
    confirmPassword: new FormControl('', [() => this.validatePasswordConfirmation(), Validators.required]),
  });

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private sessionService: SessionService,
  ) {}

  ngOnInit(): void {
    this.requirements = this.passwordService.getRequirements();
    this.buttonText = this.buttonText || 'Ändra lösenord';
  }

  getRequirementClass(validator: string): string {
    let className = '';
    if (!this.form) {
      return className;
    }
    if (!this.password.dirty) {
      return className;
    }
    if (!this.password.errors) {
      className = 'text-success';
    } else if (this.password.errors && !this.password.errors[validator]) {
      className = 'text-success';
    } else if (this.password.errors[validator] || this.password.errors.required) {
      className = 'text-danger';
    }
    return className;
  }

  updatePassword(): void {
    if (this.form.valid && this.token) {
      this.passwordService.changePassword(this.token, this.password.value, this.confirmPassword.value).then((user) => {
        this.dialogService.openSuccess('Ditt lösenord har uppdaterats.');
        this.sessionService.create(user);
        this.authService.login$.next();
      }, ignore422);
    } else if (this.form.valid && !this.token) {
      this.passwordService
        .updatePassword(this.password.value, this.confirmPassword.value, this.currentPassword.value)
        .then((user) => {
          this.sessionService.create(user);
          this.dialogService.openSuccess('Ditt lösenord har uppdaterats.');
          this.currentPassword.setValue('');
          this.password.setValue('');
          this.confirmPassword.setValue('');
          this.form.markAsPristine();
        }, ignore422);
    }
  }

  private validatePasswordConfirmation() {
    if (!this.form) {
      return null;
    }
    if (this.password.value !== this.confirmPassword.value) {
      return { matching: 'Passwords not matching.' };
    }
    return null;
  }

  private validateMinLength() {
    if (!this.form) {
      return null;
    }
    if (this.form.dirty && this.password.value.length < this.requirements.minLength) {
      return { minlength: `Password should be more than ${this.requirements.minLength} characters.` };
    }
    return null;
  }

  private validateNumberMissing() {
    if (!this.form) {
      return null;
    }
    const regex = new RegExp('[' + this.requirements.numbers + ']');
    if (!regex.test(this.password.value)) {
      return { numberMissing: 'A number is required' };
    }
    return null;
  }

  private validateUppercaseMissing() {
    if (!this.form) {
      return null;
    }
    const regex = new RegExp('[' + this.requirements.uppercaseLetters + ']');
    if (!regex.test(this.password.value)) {
      return { uppercaseMissing: 'Missing uppercase letter.' };
    }
    return null;
  }

  private validateLowercaseMissing() {
    if (!this.form) {
      return null;
    }
    const regex = new RegExp('[' + this.requirements.lowercaseLetters + ']');
    if (!regex.test(this.password.value)) {
      return { lowercaseMissing: 'Missing lowercase letter.' };
    }

    return null;
  }

  private validateSpecialCharacterMissing() {
    if (!this.form) {
      return null;
    }
    const regex = new RegExp('[' + this.requirements.specialCharacters + ']');
    if (!regex.test(this.password.value)) {
      return { specialCharacterMissing: 'Missing special character' };
    }
    return null;
  }

  get currentPassword(): AbstractControl {
    return this.form.controls.currentPassword;
  }

  get password(): AbstractControl {
    return this.form.controls.password;
  }

  get confirmPassword(): AbstractControl {
    return this.form.controls.confirmPassword;
  }
}

interface PasswordForm {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}
