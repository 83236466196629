import { Component, Input } from '@angular/core';

@Component({
  selector: 'dot-loader',
  template: `
    <div class="text-center" *ngIf="visibility">
      <h4 *ngIf="description && !large" class="thin">{{ description }}</h4>
      <h3 *ngIf="description && large" class="thin text--dark-gray">{{ description }}</h3>
      <div class="loader centered">
        <div class="dot-loader" [ngClass]="{ huge: large, center: large, centered: !large, fast: fast }"></div>
      </div>
    </div>
  `,
})
export class DotLoader {
  @Input() visibility: boolean;
  @Input() large: boolean;
  @Input() description;
  @Input() fast = false;
}
