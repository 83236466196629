<h3 class="margin-bottom--3x">Lägg till film</h3>
<instream-creative-form
  [customerId]="campaign.customer_id"
  [creatives]="oldCreatives"
  [creativeLengths]="creativeLengths"
  [layoutProfile]="instreamFormProfile"
  [ongoingCreative]="true"
  [disableAG]="!hasAgCreative"
  [productFormatConditions]="productFormatConditions"
  (onChange)="changeCreativeData($event)"
  (onValidityChange)="creativeDataValid = $event"
></instream-creative-form>

<div>

  <info-badge>
    Om kampanjen har bokats med specifika dagar för creatives, viktning av region eller programformat m.m., så kommer placements ha pacing. Detta kommer alltid styra hur mycket creatives leverar till specifika datum utefter lagerbokningen
  </info-badge>

  <p>
    Välj bland vilka placements du vill lägga till din creative
  </p>

  <ng-container *ngIf="show.warnPlacementsCleared">
    <p class="text--warning text--strong">
      <span class="icon-warning"></span><span class="margin-left--1x">Om du ändrar filmlängd nollställs dina placement-val för att säkerställa att filmlängden är valid för placementen</span>
    </p>
  </ng-container>

  <ng-container *ngIf="!show.loadingPlacements">
    <div class="placement-container">
      <ng-container *ngFor="let placement of placementCards; let index = index;">
        <div class="placement-row">
          <div class="placement-info">
            <div class="text-rows">
              <p class="header">Namn</p>
              <p class="info">{{ placement.name }}</p>
  
              <p class="header">ID</p>
              <p class="info">{{ placement.external_id }}</p>
  
              <p class="header">Startdatum</p>
              <p class="info">{{ placement.start_date | date:'yyyy-MM-dd' }}</p>
  
              <p class="header">Slutdatum</p>
              <p class="info">{{ placement.end_date | date:'yyyy-MM-dd' }}</p>

              <p class="header">Lägg Creative på placement</p>
              <p class="check-box">
                <input
                class="checkbox"
                id="index"
                type="checkbox"
                [disabled]="validForVideoLength(placement)"
                [(ngModel)]="placement.picked"
                (ngModelChange)="addCreativeToPlacement(placement)"
                >
              </p>
            </div>
          </div>
          <div class="ad-unit-rows">
            <ng-container *ngFor="let adUnit of placement.ad_unit_nodes; let last = last;">
              <div class="ad-unit-row">
                 <div class="ad-unit-info">
                  <div>
                    <p class="header">ID</p>
                    <p class="info">{{ adUnit.ad_unit_node_id }}</p>
                  </div>
                  <div class="slot-type-margin">
                    <p class="header">Slot Type</p>
                    <p class="info">{{ adUnit.slot_type }}</p>
                  </div>
                 </div>
                <ng-container *ngFor="let creativeInstance of adUnit.creative_instances; let last = last;">
                  <div [ngClass]="['creative-instance-row', creativeInstance.added ? 'added-creative-border' : 'non-added-creative-border', last ? 'last-creative-row' : '']">
                    <div class="creative-info">
                      <p class="header">ID</p>
                      <p class="info">{{ creativeInstance.creative_external_id || 'Inget ID'}}</p>
                      <p class="header">Namn</p>
                      <p class="info">{{ creativeInstance.custom_name || 'Inget namn' }}</p>
                      <p class="header">Videokod</p>
                      <p class="info">{{ creativeInstance.video_code || 'Ingen videokod'}}</p>
                      <p class="header">Längd</p>
                      <p class="info">{{ creativeInstance.length || 'Ingen längd'}}</p>
                    </div>

                    <div>
                      <period-select
                      [ngModel]="creativeInstance"
                      (ngModelChange)="updateInstanceDates($event, creativeInstance)"
                      [firstSelectableDate]="placement.start_date"
                      [lastSelectableDate]="placement.end_date"
                      [defaultInitDate]="creativeInstance.start"
                      ></period-select>
                    </div>
                  </div>
                  <ng-container *ngIf="!last">
                    <div class="divider medium dark divider-row-creative"></div>
                  </ng-container>
                </ng-container>
              </div>
              <ng-container *ngIf="!last">
                <div class="divider medium dark divider-row"></div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <dot-loader [visibility]="show.loadingPlacements" [large]="true"></dot-loader>
</div>


<div class="submit-footer">
  <button class="button primary-1" type="submit" [disabled]="!allowSubmit" (click)="addCreative()">Lägg till</button>
  <button class="link primary-1" type="button" (click)="onAbort.emit()">Avbryt</button>
</div>