import { Component, Input } from '@angular/core';
import { STANDARD_FORMATS } from '../../utils/format-constants';
import { CreativeDistributions } from '../../woo_services.module/CreativeService';
import { FormatterService } from '../../woo_services.module/FormatterService';
import { Creative, Targeting, TargetingMetaData, TargetingType } from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';

@Component({
  selector: 'creatives-list',
  templateUrl: './creatives-list.component.html',
})
export class CreativesList {
  readonly translateCreativeType = this.translationService.convertCreativeTypes;
  readonly percentFormat = STANDARD_FORMATS.percent;

  @Input() creatives: Creative[] = [];
  @Input() distributions: CreativeDistributions = {};
  @Input() metaData: TargetingMetaData;
  @Input() targeting: Targeting;
  @Input() creativesAddedAfterAgreement = false;

  get currency(): string {
    return this.targeting ? this.formatterService.getCurrencyName(this.targeting, true, 'short') : '';
  }

  get isVideo(): boolean {
    return this.metaData ? this.metaData.targetingType === TargetingType.instream : true;
  }

  get showDistribution(): boolean {
    return (
      this.creatives.map((c) => this.distributions && this.distributions[c.id]).every(Boolean) &&
      !this.creativesAddedAfterAgreement
    );
  }

  constructor(private translationService: TranslationService, private formatterService: FormatterService) {}
}
