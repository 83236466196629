import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { getNoCacheHeader } from '../utils/http';
import { EnvironmentService } from './EnvironmentService';
import { GoogleTagManagerService } from './GoogleTagManagerService';

@Injectable({ providedIn: 'root' })
export class SystemService {
  variables = {} as SystemSettings;
  refreshApp = false;

  private loaded = false;
  private _promise_resolve_functions = [];
  private applicationVersion: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private googleTagManagerService: GoogleTagManagerService,
  ) {}

  /**
   * load unmodified system variables for editing system settings
   */
  loadSystemwideSettings(): Promise<SystemSettings> {
    return lastValueFrom(this.http.get<SystemSettings>(`${this.env.apiUrl}/settings?systemwide`));
  }

  /**
   * load system variables modified according to user role
   */
  async load(): Promise<SystemSettings> {
    const systemSettings = await lastValueFrom(this.http.get<SystemSettings>(`${this.env.apiUrl}/settings`));
    this.loaded = true;
    this.variables = systemSettings;
    this.resolvePromises();
    return systemSettings;
  }

  async update(settings: SystemSettings): Promise<SystemSettings> {
    const systemSettings = await lastValueFrom(
      this.http.put<SystemSettings>(`${this.env.apiUrl}/settings`, { settings: settings }),
    );
    this.loaded = true;
    this.variables = systemSettings;
    this.resolvePromises();
    return systemSettings;
  }

  async updateShareOfTotal(shareSettings: SystemShareParams): Promise<SystemSettings> {
    const systemSettings = await lastValueFrom(
      this.http.patch<SystemSettings>(`${this.env.apiUrl}/settings/update_share_of_total`, {
        settings: shareSettings,
      }),
    );
    this.loaded = true;
    this.variables = systemSettings;
    this.resolvePromises();
    return systemSettings;
  }

  whenLoaded(): Promise<SystemSettings> {
    const promise = new Promise<SystemSettings>((resolve) => {
      this._promise_resolve_functions.push(resolve);
    });
    if (this.loaded) {
      this.resolvePromises();
    }
    return promise;
  }

  getValue<T extends keyof SystemSettings>(setting: T): Promise<SystemSettings[T]> {
    return this.whenLoaded().then(function (variables) {
      return variables[setting];
    });
  }

  isFeatureToggleOn(id: string): Promise<boolean> {
    return this.whenLoaded().then(function (variables) {
      const featureToggle = variables.feature_toggles.find((toggle) => toggle.id === id);
      return featureToggle ? featureToggle.on : false;
    });
  }

  checkForNewVersion = (): void => {
    if (this.refreshApp === false) {
      this.getVersion().then((version) => {
        if (this.applicationVersion && this.applicationVersion !== version) {
          this.googleTagManagerService.detectedNewVersion(this.applicationVersion, version);
          this.refreshApp = true;
        }
        this.applicationVersion = version;
      });
    }
  };

  private getVersion() {
    const timeNow = new Date().getTime().toString();
    return lastValueFrom(
      this.http.get(
        '/version/', // note: this calls a woo-web endpoint, not an api endpoint
        {
          params: { time: timeNow },
          headers: getNoCacheHeader(),
          responseType: 'text',
        },
      ),
    );
  }

  private resolvePromises() {
    this._promise_resolve_functions.forEach((resolve) => {
      resolve(this.variables);
    });
    this._promise_resolve_functions = [];
  }
}

export interface SystemShareParams {
  advanced_target_group_estimate_share_of_total?: number;
  target_group_estimate_share_of_total?: number;
  behavior_estimate_share_of_total?: number;
  regional_estimate_share_of_total?: number;
}

export interface SystemSettings {
  advanced_target_group_estimate_share_of_total: number;
  agency_discount: number;
  agency_user_min_days_until_start: number;
  min_days_to_update_creatives: number;
  min_days_to_update_creatives_in_mail: number;
  min_days_to_update_gambling_creatives: number;
  min_days_to_update_gambling_creatives_in_mail: number;
  automatic_mms_gain_default_value: number;
  automatic_mms_gain_vast_default_value: number;
  available_user_roles: { id: string; name: string }[];
  behavior_estimate_share_of_total: number;
  campaign_complexity_threshold: number;
  cash_customer_payment_terms: number;
  cash_user_min_days_until_start: number;
  contract_interim_month_number: number;
  default_self_service_compensation: number;
  enable_woo_local_rbs_share: boolean;
  enable_woo_target_group_rbs_share: boolean;
  eos_row_event_horizon: number;
  eos_row_tolerance: number;
  external_planner_min_days_until_start: number;
  feature_toggles: { id: string; name: string; on: boolean }[];
  format_region_min_allowed_imps: number;
  invoice_interval_budget_threshold: number;
  invoice_interval_days: number;
  invoice_pause_end_date: Date | string;
  invoice_pause_start_date: Date | string;
  low_credit_rating_min_days_until_start: number;
  max_allowed_budget: number;
  min_allowed_impressions: number;
  min_allowed_impressions_cash_customers: number;
  min_campaign_length: number;
  min_days_until_start: number;
  missing_creative_reminder_threshold_days: number;
  missing_creative_reminder_gambling_threshold_days: number;
  planner_mail_address: string;
  regional_estimate_share_of_total: number;
  share_group_id: string;
  target_group_estimate_share_of_total: number;
  ttv_actuals_program_format_share_cutoff: number;
  woo_support_mail_address: string;
  years_agency_can_follow_up_old_customers: number;
  day_to_expand_a_campaign_targeting: number;
}

export type SystemSettingsParams = Omit<SystemSettings, 'available_user_roles'>;
