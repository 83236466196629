import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ForcedEstimationCorrection } from '../../advanced_campaign_booking.module/stores/BookingStore';
import {
  targetingAdditionalBudgetWithAgreementPart,
  targetingNetBudgetWithAgreementPart,
  targetingTotalViewsWithAgreementPart,
} from '../../utils/budget_helpers/targeting_budget_helper';
import { SimpleChanges } from '../../utils/types';
import { calculateTRP } from '../../utils/utils';
import { AuthService } from '../../woo_services.module/AuthService';
import { FormatterService, TargetingLabel } from '../../woo_services.module/FormatterService';
import { ProductFormatService } from '../../woo_services.module/ProductFormatService';
import {
  AgreementPart,
  CampaignEstimationPart,
  GeoTargeting,
  RegionalDivision,
  Targeting,
  TargetingMetaData,
  ViewCurrency,
} from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';

@Component({
  selector: 'targeting-box',
  templateUrl: './targeting-box.component.html',
  styleUrls: ['./targeting-box.component.scss'],
})
export class TargetingBox implements OnChanges {
  readonly RegionalDivision = RegionalDivision;
  readonly isAdminOrPlanner = this.authService.adminOrPlanner();

  @Input() targeting: Targeting;
  @Input() metaData: TargetingMetaData;
  @Input() allowDeleteTargeting = false;
  @Input() part: Part;
  @Input() forcedCorrection: ForcedEstimationCorrection;

  @Output() onRemove = new EventEmitter<void>();
  @Output() onEditTargeting = new EventEmitter<void>();
  @Output() onEditCreatives = new EventEmitter<void>();

  targetingLabel: TargetingLabel;
  targetingRows: RowItem[] = [];
  estimationRows: RowItem[] = this.getEstimationRows(null);
  estimatedViews: number = null;
  sendInvoices = true;
  includesLinear = false;
  isRegional = false;
  regionalDivision: RegionalDivision;
  creativesRows: string[] = [];
  currency: string;
  salesOrderNumber: string;

  get showRemove(): boolean {
    return this.onRemove.observed && this.allowDeleteTargeting;
  }

  get showEditTargeting(): boolean {
    return this.onRemove.observed;
  }

  get showEditCreatives(): boolean {
    return this.onRemove.observed;
  }

  get showUniverse(): boolean {
    return this.metaData.geoTargeting === GeoTargeting.national;
  }

  get unbookableWeeks(): string {
    return `(v${this.forcedCorrection?.unbookableWeeks?.map((w) => w.week).join(', v')})`;
  }

  constructor(
    public authService: AuthService,
    private currencyFormatter: CurrencyPipe,
    private formatterService: FormatterService,
    private translationServie: TranslationService,
    private productFormatService: ProductFormatService,
  ) {}

  ngOnChanges(_: SimpleChanges<TargetingBox>): void {
    this.estimatedViews = targetingTotalViewsWithAgreementPart(this.targeting, this.part);

    this.targetingLabel = this.formatterService.getTargetingLabel(this.targeting, this.metaData);

    if (this.targeting) {
      this.creativesRows = this.targeting.creatives.map(this.formatterService.getCreativeName);
      this.includesLinear = this.targeting.includes_linear;
      this.salesOrderNumber = this.targeting.sales_order_number;
      this.sendInvoices = this.targeting.send_invoices;
      this.regionalDivision = this.targeting.regional_division;
    }

    if (this.targeting && this.metaData) {
      this.targetingRows = this.getTargetingRows(this.targeting, this.part, this.metaData);
      this.currency = this.formatterService.getCurrencyName(this.targeting);
      this.isRegional = this.metaData.geoTargeting === GeoTargeting.regional;
    }

    this.estimationRows = this.getEstimationRows(this.part);
  }

  private getTargetingRows(targeting: Targeting, part: Part | null, metaData: TargetingMetaData): RowItem[] {
    const targetingRows: RowItem[] = this.getBudgetRows(targeting, part);
    if (this.showUniverse) {
      const estimatedViews = targetingTotalViewsWithAgreementPart(this.targeting, this.part);
      const universe = targeting.universe || targeting.full_universe;
      if (estimatedViews && universe) {
        targetingRows.unshift({
          label: 'TRP',
          value: this.formatterService.transformNumber(calculateTRP(estimatedViews, universe), '1.2-2'),
        });
      }
      targetingRows.unshift({
        label: 'Universum',
        value: this.formatterService.transformNumber(universe),
      });
    }
    targetingRows.unshift({
      label: 'Tilläggsstyrning',
      value: this.translationServie.convertProductChoice(metaData.addonTargeting),
    });
    targetingRows.unshift({
      label: 'Styrning',
      value: this.formatterService.getTargetingLabel(targeting, metaData).name,
    });
    if (part && targeting.view_currency === ViewCurrency.grossRatingViews) {
      targetingRows.push({
        label: 'Estimerade impressions',
        value: this.formatterService.transformNumber(part.total_inventory_views),
      });
      targetingRows.push({
        label: 'Estimerat CPM',
        value: this.currencyFormatter.transform(part.net_cost_per_inventory_view, 'SEK'),
      });
    }
    if (targeting.view_currency === ViewCurrency.grossRatingViews && this.isAdminOrPlanner) {
      const grossRatingFactor = targeting.gross_rating_factor || targeting.current_gross_rating_factor;
      targetingRows.push({
        label: 'Bruttokontaktsfaktor',
        value: this.formatterService.transformNumber(grossRatingFactor),
      });
    }
    targetingRows.push({
      label: 'Period',
      value: this.formatterService.formatPeriod(
        this.forcedCorrection?.forcedNewStartDate || targeting.start_date,
        this.forcedCorrection?.forcedNewEndDate || targeting.end_date,
      ),
    });
    targetingRows.unshift({
      label: 'Placering',
      value: this.translationServie.convertProductFormat(
        this.productFormatService.convertProductFormatToEnum(targeting.product_formats),
      ),
    });
    return targetingRows;
  }

  private getEstimationRows(part: Part | null): RowItem[] {
    const currency = this.targeting ? this.formatterService.getCurrencyName(this.targeting, false, 'acronym') : '';
    const price = this.getPrice(part);
    const discount = this.getDiscount(part);
    return [
      { label: currency, value: price },
      { label: 'Rabatt', value: discount },
    ];
  }

  private getBudgetRows(targeting: Targeting, part: Part | null): RowItem[] {
    const netBudget = targetingNetBudgetWithAgreementPart(targeting, part);
    const budgetRows: RowItem[] = [
      { label: 'Budget', value: netBudget ? this.currencyFormatter.transform(netBudget, 'SEK') : null },
    ];

    const additionalBudget = targetingAdditionalBudgetWithAgreementPart(targeting, part);
    if (additionalBudget) {
      budgetRows.push({ label: 'Extra/Komp Budget', value: this.currencyFormatter.transform(additionalBudget, 'SEK') });
      budgetRows.push({
        label: 'Total Budget',
        value: this.currencyFormatter.transform(netBudget + additionalBudget, 'SEK'),
      });
    }

    return budgetRows;
  }

  private getDiscount(part: Part | null): string {
    return part ? this.formatterService.transformPercent(part.discount) : null;
  }

  private getPrice(part: Part | null): string {
    return part ? this.currencyFormatter.transform(part.net_cpm, 'SEK') : null;
  }
}
interface RowItem {
  label: string;
  value: string;
}

type Part = CampaignEstimationPart | AgreementPart;
