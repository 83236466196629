import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { WooComponentsModule } from '../woo_components.module/module';
import { AdvancedTargetGroupCategoryForm } from './components/products_page/advanced_target_groups/advanced-target-group-category-form.component';
import { AdvancedTargetGroupForm } from './components/products_page/advanced_target_groups/advanced-target-group-form.component';
import { EditAdvancedTargetGroup } from './components/products_page/advanced_target_groups/edit-advanced-target-groups.component';
import { ManageAdvancedTargetGroups } from './components/products_page/advanced_target_groups/manage-advanced-target-groups.component';
import { ManageAgWithdrawalLimits } from './components/products_page/ag_withdrawal_limit/manage-ag-withdrawal-limits.component';
import { BehaviorForm } from './components/products_page/behaviors/behavior-form.component';
import { ManageBehaviors } from './components/products_page/behaviors/manage-behaviors.component';
import { ManageCategories } from './components/products_page/categories/manage-categories.component';
import { ManageCompletionRatesEstimates } from './components/products_page/completed_views/manage-completion-rates-estimates.component';
import { ManageDayparts } from './components/products_page/dayparts/manage-dayparts.component';
import { ManageDefaultFrequencyLimits } from './components/products_page/default_frequency_limits/manage-default-frequency-limits.component';
import { DeviceGroupForm } from './components/products_page/device_groups/device-group-form.component';
import { EditDeviceGroup } from './components/products_page/device_groups/edit-device-group.component';
import { ManageDeviceGroups } from './components/products_page/device_groups/manage-device-groups.component';
import { ManageDevices } from './components/products_page/devices/manage-devices.component';
import { ManageFrequencyLimits } from './components/products_page/frequency_limit/manage-frequency-limits.component';
import { ManageGamblingEstimates } from './components/products_page/gambling/manage-gambling-estimates.component';
import { ManageGrossRatingFactor } from './components/products_page/gross_rating_views/manage-gross-rating-factor.component';
import { ManagePauseAdEstimates } from './components/products_page/pause_ad/manage-pause-ad-estimates.component';
import { CalculatePrice } from './components/products_page/pricelists/calculate-price.component';
import { EditOverwrittenPriceRowContainer } from './components/products_page/pricelists/edit-overwritten-price-row-container.component';
import { EditOverwrittenPrice } from './components/products_page/pricelists/edit-overwritten-price.compontent';
import { EditPricelist } from './components/products_page/pricelists/edit-pricelist.component';
import { ManagePriceLists } from './components/products_page/pricelists/manage-pricelists.component';
import { PriceRowContainer } from './components/products_page/pricelists/price-row-container.component';
import { SelectPricelistForm } from './components/products_page/pricelists/select-pricelist-form.component';
import { ManageProductFormatShares } from './components/products_page/product_formats/manage-product-formats-shares';
import { ProductsPage } from './components/products_page/products-page.component';
import { CreateNewProgramFormat } from './components/products_page/program_formats/create-new-program-format.component';
import { ManageProgramFormats } from './components/products_page/program_formats/manage-program-formats.component';
import { ManageRegions } from './components/products_page/regions/manage-regions.component';
import { ManageTargetGroups } from './components/products_page/target_groups/manage-target-groups.component';
import { EditTtvTargetingMapping } from './components/products_page/ttv/edit-ttv-targeting-mapping.component';
import { ManageTTVTargetingMapping } from './components/products_page/ttv/manage-ttv-targeting-mapping.component';
import { NewTtvTargetingMapping } from './components/products_page/ttv/new-ttv-targeting-mapping.component';
import { TargetingMappingsList } from './components/products_page/ttv/targeting-mappings-list.component';
import { ManageUniverse } from './components/products_page/universe/manage-universe.component';
import { EditEstimates } from './components/shared/edit_estimates/edit-estimates.component';
import { PerIdEstimatesInput } from './components/shared/estimate_input/per-id-estimates-input.component';
import { OutstreamEstimatesInput } from './components/shared/outstream_estimates_input/outstream-estimates-input.component';
import { ProductsPageUtilityService } from './components/shared/products-page-utility-service';

@NgModule({
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, RouterModule, WooComponentsModule],
  declarations: [
    AdvancedTargetGroupCategoryForm,
    AdvancedTargetGroupForm,
    BehaviorForm,
    DeviceGroupForm,
    CalculatePrice,
    CreateNewProgramFormat,
    EditAdvancedTargetGroup,
    EditDeviceGroup,
    EditEstimates,
    EditOverwrittenPrice,
    EditOverwrittenPriceRowContainer,
    EditPricelist,
    EditTtvTargetingMapping,
    ManageAdvancedTargetGroups,
    ManageAgWithdrawalLimits,
    ManageBehaviors,
    ManageCompletionRatesEstimates,
    ManageGrossRatingFactor,
    ManageDayparts,
    ManageDeviceGroups,
    ManageDevices,
    ManageDefaultFrequencyLimits,
    ManageFrequencyLimits,
    ManageGamblingEstimates,
    ManagePauseAdEstimates,
    ManagePriceLists,
    ManageProductFormatShares,
    ManageProgramFormats,
    ManageRegions,
    ManageTargetGroups,
    ManageTTVTargetingMapping,
    ManageUniverse,
    ManageCategories,
    NewTtvTargetingMapping,
    OutstreamEstimatesInput,
    PerIdEstimatesInput,
    PriceRowContainer,
    ProductsPage,
    SelectPricelistForm,
    TargetingMappingsList,
  ],
  exports: [
    ProductsPage,
    EditPricelist,
    EditEstimates,
    EditTtvTargetingMapping,
    NewTtvTargetingMapping,
    EditAdvancedTargetGroup,
    EditDeviceGroup,
    CalculatePrice,
  ],
  providers: [ProductsPageUtilityService],
})
export class ProductManagementModule {}
