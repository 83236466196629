import { Component, Input, OnChanges } from '@angular/core';
import { addWeeks, formatISO, getISOWeek, isBefore, isEqual, parseISO, startOfWeek } from 'date-fns';
import { chain, flatten, groupBy, mapValues, sortBy, uniq } from 'lodash-es';
import { SimpleChanges } from '../../../utils/types';
import { Program, ProgramSegment } from '../../../woo_services.module/shared-types';

chain.prototype.groupBy = groupBy;
chain.prototype.mapValues = mapValues;
chain.prototype.sortBy = sortBy;

@Component({
  selector: 'program-formats-table',
  templateUrl: './program-formats-table.component.html',
  styleUrls: ['./program-formats-table.component.scss'],
})
export class ProgramFormatsTable implements OnChanges {
  @Input() programFormats: Program[];

  programsByWeek: ProgramWeek[] = [];

  ngOnChanges(changeObj: SimpleChanges<ProgramFormatsTable>): void {
    if (changeObj.programFormats && changeObj.programFormats.currentValue) {
      this.programsByWeek = this.orderProgramsByWeek(changeObj.programFormats.currentValue).filter((item) => {
        const start = startOfWeek(new Date(), { weekStartsOn: 1 });
        const itemDate = parseISO(item.period);
        return isBefore(start, itemDate) || isEqual(start, itemDate);
      });
    }
  }

  formatWeek = (dateString: string): string => {
    return `V. ${getISOWeek(parseISO(dateString))}`;
  };

  /**
   *
   * @param {*} programs
   *
   * Returns a list of objects {period: ... programs: [...]}
   *  - period: is the date of the beginning of each week as a string
   *  - programs: is a list of those programs that have a period that spans that week
   */
  orderProgramsByWeek = (programs: Program[]): ProgramWeek[] => {
    type WeekTuple = [string, Program];
    const programsByWeek: WeekTuple[] = flatten(
      programs.map((prog) => this.programWeeks(prog).map((weekStartDate) => [weekStartDate, prog] as WeekTuple)),
    );

    const grouped = groupBy(programsByWeek, ([weekStartDate]) => weekStartDate);
    const mapped = mapValues(grouped, (items) => {
      return flatten(items.map(([, prog]) => prog));
    });
    const arrayOfObjects = Object.entries(mapped).map(([weekStartDate, progs]) => ({
      period: weekStartDate,
      programs: progs,
    }));

    const result = sortBy(arrayOfObjects, 'period');

    return result;
  };

  programWeeks = (program: Program): string[] => {
    const allWeeks = flatten(program.periods.map((period) => this.periodWeeks(period)));
    return uniq(allWeeks);
  };

  periodWeeks = (period: ProgramSegment): string[] => {
    const result: string[] = [];
    const start = typeof period.start_date === 'string' ? parseISO(period.start_date) : period.start_date;
    const end = typeof period.end_date === 'string' ? parseISO(period.end_date) : period.end_date;
    let current = startOfWeek(start, { weekStartsOn: 1 });

    while (isBefore(current, end) || isEqual(current, end)) {
      result.push(formatISO(current));
      current = addWeeks(current, 1);
    }
    return result;
  };
}

interface ProgramWeek {
  period: string;
  programs: Program[];
}
