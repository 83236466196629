import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../utils/types';
import { Campaign, CompactCampaign } from '../../woo_services.module/shared-types';

const template = /* html */ `
  <span *ngFor="let label of labels" class="tab tab--small tab-margin">{{label}}</span>
`;

@Component({
  selector: 'special-campaign-label',
  template: template,
  styles: [
    `
      .tab-margin {
        margin: 0 4px 0 0;
      }
    `,
  ],
})
export class SpecialCampaignLabel implements OnChanges {
  @Input() campaign: CompactCampaign | Campaign;
  labels: string[] = [];

  ngOnChanges(changes: SimpleChanges<SpecialCampaignLabel>): void {
    if (changes.campaign && this.campaign) {
      this.labels = [
        this.campaign.ttv_campaign ? 'TTV' : null,
        this.campaign.cash_campaign ? 'Cash' : null,
        this.campaign.gambling_campaign ? 'Spel' : null,
        this.campaign.political_campaign ? 'Politik' : null,
        this.campaign.mema_campaign ? 'MeMa' : null,
      ].filter(Boolean);
    }
  }
}
