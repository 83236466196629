import { ControlValueAccessor } from '@angular/forms';

export abstract class AbstractValueAccessor<T> implements ControlValueAccessor {
  protected isDisabled = false;
  private m: T;
  get model(): T {
    return this.m;
  }
  set model(v: T) {
    if (v !== this.m) {
      this.m = v;
      this.propagateChange(v);
    }
  }

  propagateChange = (_: T): void => null;
  propagateTouch = (): void => null;

  writeValue(model: T): void {
    this.m = model;
  }

  change(): void {
    this.propagateChange(this.m);
  }

  registerOnChange(fn: (_: T) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
