import { Component, Input, OnChanges } from '@angular/core';
import { generateId } from '../../../../utils/string';
import { Category, ProgramCategoryName, TargetingCategory, wooId } from '../../../../woo_services.module/shared-types';
import { TargetingCombinationRulesService } from '../../../../woo_services.module/TargetingCombinationRulesService';
import { CachedCategoryService } from '../../../services/CachedCategoryService';
import { BookingModel, BookingStore } from '../../../stores/BookingStore';
import { SubscriptionHandler } from '../../subscription-handler';

@Component({
  selector: 'category-targeting',
  templateUrl: './category-targeting.component.html',
  styleUrls: ['./category-targeting.component.scss'],
})
export class CategoryTargeting extends SubscriptionHandler implements OnChanges {
  @Input() targetingId: wooId;
  @Input() regionalIsSelected: boolean;
  @Input() pauseAdIsSelected: boolean;

  readonly instanceId = generateId();
  readonly images = {
    [ProgramCategoryName.documentary]: 'images/category/documentary.svg',
    [ProgramCategoryName.food_home_leisure]: 'images/category/home.svg',
    [ProgramCategoryName.news_and_current_events]: 'images/category/news.svg',
    [ProgramCategoryName.entertainment]: 'images/category/entertainment.svg',
    [ProgramCategoryName.sports]: 'images/category/sports.svg',
    [ProgramCategoryName.tv_shows]: 'images/category/tv_shows.svg',
    [ProgramCategoryName.disney]: 'images/category/disney.svg',
    [ProgramCategoryName.tv_channels]: 'images/category/tv_channels.svg',
  };

  categories: Category[] = [];
  selectedCategoryIds = {};
  frequencyLimitCompatible = false;

  constructor(
    cachedCategoryService: CachedCategoryService,
    private bookingStore: BookingStore,
    private targetingCombinationRulesService: TargetingCombinationRulesService,
  ) {
    super();
    cachedCategoryService.getCategories().then((categories) => {
      this.categories = categories;
      this.initFromStore(bookingStore.state);
    });

    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      targeting.categories.forEach((category) => (this.selectedCategoryIds[category.category_id] = true));
      this.frequencyLimitCompatible = this.targetingCombinationRulesService.frequencyLimitCompatible(this.targetingId);
    }
  };

  disabledForDisneyIf = (is_disney: boolean, regionalIsSelected: boolean, pauseAdIsSelected: boolean): boolean => {
    return (regionalIsSelected || pauseAdIsSelected) && is_disney;
  };

  change(categoryId: wooId): void {
    this.selectedCategoryIds[categoryId] = !this.selectedCategoryIds[categoryId];
    const selectedCategories: TargetingCategory[] = this.categories
      .filter((category) => this.selectedCategoryIds[category.id])
      .map((category) => {
        return {
          category_id: category.id,
          name: category.name,
          is_disney: category.is_disney,
        };
      });
    this.bookingStore.setCategories(selectedCategories, this.targetingId);
  }
}
