import { Component, Input } from '@angular/core';

const imageUrl = '../../../images/no-invoices-icon.svg';
const template = /* html */ `
<p class="margin-bottom--4px" *ngIf="!sendInvoices">
<img src="${imageUrl}">
<span>Faktureras ej</span><span class="padding-left--1x text--italic" *ngIf="message">{{message}}</span>
</p>
`;

@Component({
  selector: 'send-invoices-indicator',
  template: template,
  styles: ['img { max-width: 16px; transform: translateY(-2px);}'],
})
export class SendInvoicesIndicator {
  @Input() sendInvoices = true;
  @Input() message: string;
}
