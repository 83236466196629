<div *ngIf="show.selectFile">
  <div class="relative">
    <div class="image__dropbox flex__container--vertical justify-content--center"
      wooFileDragClass="file-hover"
      (wooFileDrop)="fileDropped($event)"
    >
      <div>
        <span class="icon icon-ios text--xl text--info"></span>
      </div>
      <p>Välj fil eller dra din fil hit för att ladda upp den.</p>
      <p><a [href]="fileService.getUrlToFile('technical_spec_sv')" target="_blank">(16:9, mp4, max 50 MB, max 15s, ljudkrav)</a></p>
      <div>
        <input class="display--none" type="file" accept="video/mp4" #fileInputElement (change)="fileSelectedHandler($event)">
        <button class="button primary-1" type="file" (click)="fileInputElement.click()">
          Välj fil...
        </button>
      </div>
      <a class="text--info clickable-link" (click)="openCustomerAssetExplorer()" id="select-uploaded-material-btn">Välj bland tidigare uppladdat material</a>
    </div>
  </div>
</div>
<div *ngIf="!show.selectFile">
  <div [ngClass]="{'image__dropbox': show.loading}">
    <dot-loader [visibility]="show.loading" [large]="true" [description]="show.validatingFile ? 'Bearbetar...' : 'Laddar upp film...'"></dot-loader>
    <div *ngIf="asset.url">
      <video class="border-radius" controls [src]="asset.url"></video>
    </div>
  </div>
</div>

<div class="row margin-top--3x" *ngIf="uploadedVideo.errors">
  <div class="col-md-12" *ngFor="let pair of uploadedVideo.errors | keyvalue">
    <p class="text-danger text--medium">
      <span class="icon-warning"></span>
      <span class="margin-left--1x">{{pair.value}}</span>
    </p>
  </div>
</div>

<div class="row" *ngIf="!show.selectFile && !show.loading">
  <div >
    <button type="button" class="button primary-1" (click)="removeFile()">Byt fil</button>
  </div>
</div>

