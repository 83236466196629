import { Component, Input } from '@angular/core';
import { valueAccessorProvider } from '../../utils/provider-builders';
import { JPGValidatorService } from '../../woo_services.module/asset_validator/JPGValidatorService';
import { CreativeAssetService } from '../../woo_services.module/CreativeAssetService';
import { DialogService } from '../../woo_services.module/DialogService';
import { CreativeAssetType, wooId } from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { AssetUpload } from './asset-upload.component';

@Component({
  selector: 'image-upload',
  templateUrl: './asset-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [valueAccessorProvider(ImageUpload)],
})
export class ImageUpload extends AssetUpload {
  @Input() customerId: wooId;

  assetType = CreativeAssetType.Image;
  acceptedFileType = 'image/jpeg';

  constructor(
    protected dialogService: DialogService,
    protected creativeAssetService: CreativeAssetService,
    protected translationService: TranslationService,
    protected validationService: JPGValidatorService,
  ) {
    super(dialogService, creativeAssetService, translationService, validationService, null);
  }
}
