<h1 class="margin-top--3x" >Stilmall</h1>
<div class="style-sheet-container">
  <div class="row margin-top--2x">
    <div class="col-xs-3 table-of-contents">
      <h2>Innehåll</h2>
      <ul class="ul__woo headings" *ngFor="let heading of tableOfContents | keyvalue: compareFn"  >
        <li><a class="link primary-2 text--semi-strong" (click)="scrollTo(heading.key)">{{heading.key}}</a></li>
        <ul class="sub-headings" *ngFor="let subHeading of heading.value">
          <li>{{subHeading}}</li>
        </ul>
      </ul>
    </div>
    <div class="col-xs-9">

    <div>
        <h2 id="Byggstenar">Byggstenar</h2>
        <h3>Grid</h3>
        <p>Woo använder Bootstraps gridsystem. Systemet är består av 12 kolumner som delar in sidan i lika stora bitar. Dessa går att fördela för att enkelt få struktur på layouten. Rader (row) wrappar kolumnerna. Exemplet nedan innehåller en rad fördelad till 4 respektive 8.</p>

        <h5>Syntax</h5>

        <pre>
          {{toHtmlTagString('div class="row"')}}{{toHtmlTagString('div')}}
          {{toHtmlTagString('div class="row"')}}
            {{toHtmlTagString('div class="col-xs-4"')}}
            {{toHtmlTagString('div class="col-xs-8"')}}
          {{toHtmlTagString('div')}}</pre>

        <h5>Exempel</h5>
        
        <div class="margin-top-bottom--3x">
          <div class="row margin--none style-sheet-example-row">
            div class="row"
          </div>
          <div class="row margin--none">
            <div class="col-xs-4 style-sheet-example-col">
              col-xs-4
            </div>
           <div class="col-xs-8 style-sheet-example-col pink">
            col-xs-8
           </div>
          </div>
        </div>

        <div class="row">
          <h3>Kolumnstorlekar</h3>
          <div class="col-xs-4">
            <ul class="ul__woo">
              <li>Mobiler: col-xs-(1-12)</li>
              <li>Tablets: col-md-(1-12)</li>
              <li>Normala skärmar: col-lg-(1-12)</li>
              <li>Stora skärmar: col-xl-(1-12)</li>
            </ul>
          </div>
          <div class="col-xs-8">
            <table class="table table-woo">
              <thead>
                <tr>
                  <th>Mobiler: col-xs-(1-12)</th>
                  <th>Tablets: col-md-(1-12)</th>
                  <th>Normala skärmar: col-lg-(1-12)</th>
                  <th>Stora skärmar: col-xl-(1-12)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> mindre än 768 px </td>
                  <td> mellan 768 px och 991 px </td>
                  <td> mellan 992 px och 1199 px </td>
                  <td> 1200 px och uppåt</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row margin-top--2x">
          <p>Ibland kan man behöva override:a css-klasser som kommer från Bootstrap. Detta görs bäst i en css-fil för den givna komponenten. Används komponenten eller elementet det berör på flera ställen kan det vara bäst att skriva en override i någon av de globala css-filerna.</p>
          Mer om hur Bootstrap fungerar finns att läsa <a href="http://getbootstrap.com/css/#grid" target="_blank" class="text-info">här</a>.
        </div>
  
  <div class="divider block"></div>

  <h2 id="Indelningar">Indelningar</h2>
  
  <p>För att dela in en sidas innehåll kan följade sektionsindelningar användas</p>

  <h3>Account Card</h3>

  <p>Detta är det vanligast elementet i Woo Manager. Det används för att lyfta fram innehåll på vit bakgrund som ger kontrast till den beigea bakgrunden.</p>

  <h5>Syntax</h5>

  <pre>{{toHtmlTagString("div class='account__card'")}}...{{toHtmlTagString("div")}}</pre>
  
  <h5>Exempel</h5>

  <div class="account__card">
    <p>
      Umami vice street art, flexitarian locavore try-hard blue bottle plaid vinyl bitters aesthetic. Chillwave 90's tofu schlitz. Helvetica street art XOXO affogato bitters, PBR&B chicharrones dreamcatcher tumblr plaid. DIY echo park put a bird on it, taxidermy selvage intelligentsia banjo tacos YOLO hammock twee franzen single-origin coffee artisan. Drinking vinegar next level skateboard fashion axe organic kombucha. Tacos intelligentsia forage godard kinfolk aesthetic, tumblr next level lomo seitan typewriter taxidermy. Irony cronut small batch, quinoa fixie microdosing church-key banjo wayfarers biodiesel bicycle rights narwhal chartreuse next level pork belly.
    </p>
  </div>



  <h3>Flikar</h3>

  <p>Fliksystemet används på många ställen i Woo för att kunna visa många sidor som alla har en relation till något specifikt. Exempelvis om man vill ha mer information om en kampanj, där informationen kan delas upp på flera olika flikar. Någon flik visar material en annan fakturor.</p>

  <ul class="ul__woo">
    <li>tabbable - endast flikarna</li>
    <li>tabbable__panel - utrymme under flikarna ifall man vill ha en rubrik</li>
    <li>tabbable__body - innehållet</li>
  </ul>
  
  <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString("button class='tabbable' (click)='X = Y'")}}{{toHtmlTagString("button")}}
    {{toHtmlTagString("button class='tabbable' (click)='X = Z'")}}{{toHtmlTagString("button")}}
    {{toHtmlTagString("div class='tabbable__panel' *ngIf='X === Y'")}}{{toHtmlTagString("div")}}
    {{toHtmlTagString("div class='tabbable__body' *ngIf='X === Y'")}}{{toHtmlTagString("div")}}
    {{toHtmlTagString("div class='tabbable__body' *ngIf='X === Z'")}}{{toHtmlTagString("div")}}</pre>

  <h5>Exempel</h5>

  <button class="tabbable" (click)="setTab(0)" [ngClass]="{active : tabVisible === 0}">Sida 1</button>
  <button class="tabbable" (click)="setTab(1)" [ngClass]="{active : tabVisible === 1}">Sida 2</button>
  <button class="tabbable disabled">Disabled</button>
  <button class="tabbable" (click)="setTab(2)" [ngClass]="{active : tabVisible === 2}">Sida 3</button>
  
  <div class="tabbable__panel">
    <h3 class="margin-left--3x heading-margin">Rubrik kan gå här</h3>
  </div>

  <div class="tabbable__body " *ngIf="tabVisible === 0">
    <p>
      Här går annat innehåll som hör till flik 1.
    </p>
    <p>Lorem ipsums och sånt...</p>
  </div>
  <div class="tabbable__body " *ngIf="tabVisible === 1">
    <p>
      Photo booth selfies direct trade asymmetrical, disrupt fashion axe man braid kombucha viral umami taxidermy. Flexitarian brooklyn mixtape lumbersexual migas. Locavore gastropub distillery roof party. Meggings kale chips semiotics, shabby chic ennui sartorial jean shorts fingerstache. Tilde butcher hella ethical. Tote bag hella disrupt, mixtape venmo raw denim crucifix try-hard wayfarers locavore tumblr irony 3 wolf moon humblebrag health goth. Pug intelligentsia man bun brunch.
    </p>
  </div>

  <div class="tabbable__body " *ngIf="tabVisible === 2">
    <p>
      Cronut farm-to-table kickstarter taxidermy everyday carry, disrupt lumbersexual echo park ethical franzen pabst typewriter you probably haven't heard of them viral leggings. Tacos listicle tousled meh, hammock microdosing organic thundercats. Vegan poutine you probably haven't heard of them, mustache chambray fixie plaid photo booth narwhal. IPhone artisan crucifix pork belly bicycle rights. Polaroid trust fund semiotics tofu mumblecore, aesthetic flannel ethical butcher letterpress occupy chambray dreamcatcher mixtape. Freegan slow-carb disrupt forage, photo booth master cleanse affogato readymade pabst listicle intelligentsia stumptown +1 four loko. Pour-over gastropub seitan, listicle mustache blog man braid gentrify.
    </p>
  </div>

  <p class="margin-top--4x" >Om ingen rubrik behöver finnas under flikarna bör de sättas ihop med innehållet i .tabbable__body med .margin--none som nedan, alternativt använda .account__card</p>

  <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString("button class='tabbable' (click)='X = Y'")}}{{toHtmlTagString("button")}}
    {{toHtmlTagString("button class='tabbable' (click)='X = Z'")}}{{toHtmlTagString("button")}}
    {{toHtmlTagString("div class='tabbable__body margin--none' *ngIf='X === Y'")}}{{toHtmlTagString("div")}}
    {{toHtmlTagString("div class='tabbable__body margin--none' *ngIf='X === Z'")}}{{toHtmlTagString("div")}}</pre>

  <h5>Exempel</h5>


  <button class="tabbable" (click)="setTab(0)" [ngClass]="{active : tabVisible === 0}">Sida 1</button>
  <button class="tabbable" (click)="setTab(1)" [ngClass]="{active : tabVisible === 1}">Sida 2</button>
  <button class="tabbable disabled">Disabled</button>
  <button class="tabbable" (click)="setTab(2)" [ngClass]="{active : tabVisible === 2}">Sida 3</button>  

  <div class="tabbable__body margin--none" *ngIf="tabVisible === 0">
    <p>
      Här går annat innehåll som hör till flik 1.
    </p>
    <p>Lorem ipsums och sånt...</p>
  </div>
  <div class="tabbable__body margin--none" *ngIf="tabVisible === 1">
    <p>
      Photo booth selfies direct trade asymmetrical, disrupt fashion axe man braid kombucha viral umami taxidermy. Flexitarian brooklyn mixtape lumbersexual migas. Locavore gastropub distillery roof party. Meggings kale chips semiotics, shabby chic ennui sartorial jean shorts fingerstache. Tilde butcher hella ethical. Tote bag hella disrupt, mixtape venmo raw denim crucifix try-hard wayfarers locavore tumblr irony 3 wolf moon humblebrag health goth. Pug intelligentsia man bun brunch.
    </p>
  </div>

  <div class="tabbable__body margin--none" *ngIf="tabVisible === 2">
    <p>
      Cronut farm-to-table kickstarter taxidermy everyday carry, disrupt lumbersexual echo park ethical franzen pabst typewriter you probably haven't heard of them viral leggings. Tacos listicle tousled meh, hammock microdosing organic thundercats. Vegan poutine you probably haven't heard of them, mustache chambray fixie plaid photo booth narwhal. IPhone artisan crucifix pork belly bicycle rights. Polaroid trust fund semiotics tofu mumblecore, aesthetic flannel ethical butcher letterpress occupy chambray dreamcatcher mixtape. Freegan slow-carb disrupt forage, photo booth master cleanse affogato readymade pabst listicle intelligentsia stumptown +1 four loko. Pour-over gastropub seitan, listicle mustache blog man braid gentrify.
    </p>
  </div>


 <h3>Sektion</h3>

 <p>Sätter en transparant bakgrund med lite padding till elementet. Går att sätta annan bakgrundsfärg som i exemplet med info (vit) eller genom att exempelvis lägga till .lighter-gray m.fl.</p>

 <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString('div class="section info"')}}...{{toHtmlTagString('div')}}</pre>


  <h5>Exempel</h5>

  <div class=" section info" [ngClass]="sectionClr">
    <p >
      Lumbersexual schlitz bicycle rights, hashtag green juice bespoke pickled mumblecore man braid cronut. Semiotics cliche PBR&B venmo 90's. 90's paleo vice cliche, ethical art party cray iPhone disrupt microdosing post-ironic kombucha. Lumbersexual pabst hammock chillwave post-ironic. Ugh offal austin single-origin coffee hammock, you probably haven't heard of them VHS scenester brooklyn chartreuse thundercats. Gentrify keytar tumblr hella beard, etsy godard. Direct trade master cleanse slow-carb vinyl retro tacos gentrify, normcore thundercats hammock kogi swag banjo trust fund brooklyn.
    </p>
  </div>

  <div class="divider margin-top-bottom--4x"></div>


  <h2 id="Typografi">Typografi</h2>

  <p>Woo Manager följer de riktlinjer som finns i TV4s designmanual. Dessa riktlinjer säger bland annat att Quattro News ska användas som typsnitt. Quattro News finns i flera olika vikter; Light, Regular, Medium och Bold.</p>

  <p>Vissa hjälpklasser existerar också ifall man vill att texten ska förändras på något sätt.</p>

  <p>Mer om Typografi finns att läsa i <a href="https://varumarke.tv4.se/document/39#/verktygslada/typografi" target="_blank" class="text-info">designmanualen</a>.</p>

  <div class="margin-top--5x">
    
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <h1>Heading 1</h1>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font size: 2.25rem</p>
          <p>Font weight: Regular</p>
          <p>Line height: 1.67</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <h2>Heading 2</h2>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font size: 1.875rem</p>
          <p>Font weight: Regular</p>
          <p>Line height: 1.37</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <h3>Heading 3</h3>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font size: 1.5rem</p>
          <p>Font weight: Regular</p>
          <p>Line height: 1.375</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <h4>Heading 4</h4>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font size: 1rem</p>
          <p>Font weight: Regular</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <h5>Heading 5</h5>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font size: 0.875rem</p>
          <p>Font weight: Regular</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
  </div>

    <h3>Hjälpklasser</h3>
    <p>Hjälpklasserna går att använda på all text. De sätter då om texten/rubriken till dessa storlekar.</p>
    <div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <p class="text--small margin--none">.text--small</p>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font size: 0.75rem</p>
          <p>Font weight: Regular</p>
          <p>Line height: 1.4167</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <p class="text--medium margin--none">.text--medium</p>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font size: 1rem</p>
          <p>Font weight: Regular</p>
          <p>Line height: 1.375</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <p class="text--large margin--none">.text--large</p>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font size: 1.1875rem</p>
          <p>Font weight: Regular</p>
          <p>Line height: 1.369</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <p class="text--strong margin--none">.text--strong</p>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font weight: Bold</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <p class="text--semi-strong margin--none">.text--semi-strong</p>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font weight: Medium</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <p class="text--thin margin--none">.text--thin</p>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Font weight: Light</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <p class="text--center margin--none">.text--center</p>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Text align: Center</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="row margin-top--2x">
        <div class="col-xs-8">
          <p class="text--right margin--none">.text--right</p>
        </div>
        <div class="col-xs-4 paragraph--small">
          <p>Text align: Right</p>
        </div>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
    </div>

  
  <h2 id="Färger">Färger</h2>

  <p>Här är alla färger som används i systemet. Primär- och sekundärfärger återfinns i designmanualen. Resterande färger är främst härledda utifrån dessa och har namn som beskriver deras främst syfte även om många används till annat också.</p>
  
  <h3>Primärfärger</h3>
  <div class="flex__container">
    <div *ngFor="let number of colors.primary" class="style-sheet-color-box color-varible-{{number}} shadow-z-1">
      <h4></h4>
      <h5></h5>
    </div>
  </div>
  <h3>Sekundärfärger</h3>
  <div class="flex__container">
    <div *ngFor="let number of colors.secondary" class="style-sheet-color-box color-varible-{{number}} shadow-z-1">
      <h4></h4>
      <h5></h5>
    </div>
  </div>
  <h3>Grå</h3>
  <div class="flex__container">
    <div *ngFor="let number of colors.gray" class="style-sheet-color-box color-varible-{{number}} shadow-z-1">
      <h4></h4>
      <h5></h5>
    </div>
  </div>
  <h3>Varianter</h3>
  <div class="flex__container">
    <div *ngFor="let number of colors.variants" class="style-sheet-color-box color-varible-{{number}} shadow-z-1">
      <h4></h4>
      <h5></h5>
    </div>
  </div>
  <h3>Semantiska färger</h3>
  <div class="flex__container">
    <div *ngFor="let number of colors.semantic" class="style-sheet-color-box color-varible-{{number}} shadow-z-1">
      <h4></h4>
      <h5></h5>
    </div>
  </div>
  <h3>Misc</h3>
  <div class="flex__container">
    <div *ngFor="let number of colors.misc" class="style-sheet-color-box color-varible-{{number}} shadow-z-1">
      <h4></h4>
      <h5></h5>
    </div>
  </div>

  
  <h2 id="Buttons">Buttons</h2>
  <div class="row">
    <div class="">
      <h5>Syntax</h5>
      <pre>
        {{toHtmlTagString("button class='button'")}}{{toHtmlTagString("button")}}
        {{toHtmlTagString("button class='button primary-1'")}}{{toHtmlTagString("button")}}
        {{toHtmlTagString("button class='button primary-2'")}}{{toHtmlTagString("button")}}
        {{toHtmlTagString("button class='button secondary'")}}{{toHtmlTagString("button")}}</pre>

      <h5>Exempel</h5>
      <button class="button"> Default </button>
      <button class="button primary-1"> .primary-1 </button>
      <button class="button primary-2"> .primary-2 </button>
      <button class="button secondary"> .secondary </button>

      <br>

      <button class="button"  disabled> Default </button>
      <button class="button primary-1"  disabled> .primary-1 </button>
      <button class="button primary-2"  disabled> .primary-2 </button>
      <button class="button secondary"  disabled> .secondary </button>
    </div>
    <div class="">
      <h5>Syntax</h5>
      <pre>
        {{toHtmlTagString("button class='link'")}}{{toHtmlTagString("button")}}
        {{toHtmlTagString("button class='link primary-1'")}}{{toHtmlTagString("button")}}
        {{toHtmlTagString("button class='link primary-2'")}}{{toHtmlTagString("button")}}</pre>

      <h5>Exempel</h5>
      <button class="link"> Default </button>
      <button class="link primary-1"> .primary-1 </button>
      <button class="link primary-2"> .primary-2 </button>
    </div>
  </div>

  <div class="divider margin-top-bottom--4x"></div>

  <h2 id="Tabeller">Tabeller</h2>
  <p>Tabeller i Woo kan se ut på några olika sätt. Det vanligaste är att använda woo-table med table-info</p>
  <h3>Default</h3>
  <h5>Syntax</h5>
  <pre>
    {{toHtmlTagString("table class='table'")}}
    {{toHtmlTagString("thead")}}
    {{toHtmlTagString("/thead")}}
    {{toHtmlTagString("tbody")}}
    {{toHtmlTagString("/tbody")}}
    {{toHtmlTagString("/table")}}</pre>
  <h5>Exempel</h5>
  <table class="table">
    <thead>
      <tr>
        <td>Lorem</td>
        <td>Ipsum</td>
        <td>Dolor</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>shoreditch</td>
        <td>farm-to-table</td>
        <td>truffaut</td>
      </tr>
      <tr>
        <td>before</td>
        <td>they</td>
        <td>sold</td>
      </tr>
      <tr>
        <td>out</td>
        <td>biodiesel</td>
        <td>ramps</td>
      </tr>
      <tr>
        <td>hoodie</td>
        <td>Kickstarter</td>
        <td>shoreditch</td>
      </tr>
      <tr>
        <td>cardigan</td>
        <td>banjo</td>
        <td>artisan</td>
      </tr>
      <tr>
        <td>You</td>
        <td>probably</td>
        <td>haven't</td>
      </tr>
      <tr>
        <td>heard</td>
        <td>of</td>
        <td>them</td>
      </tr>
      <tr>
        <td>literally</td>
        <td>waistcoat</td>
        <td>chia</td>
      </tr>
      <tr>
        <td>hella</td>
        <td>meh</td>
        <td>mumblecore</td>
      </tr>
      <tr>
        <td>typewriter</td>
        <td>salvia</td>
        <td>normcore</td>
      </tr>
    </tbody>
  </table>

  <h3>Woo</h3>

  <p>Testa de olika typerna av tabeller här</p>

  <button class="button primary-1" (click)="activeClass = ''">table--woo</button>
  <button class="button primary-1" (click)="activeClass = 'table--small'">&.table--small</button>
  <button class="button primary-1" (click)="activeClass = 'table--hover'">&.table--hover</button>
  <button class="button primary-1" (click)="activeClass = 'table--info'">&.table--info</button>
  <button class="button primary-1" (click)="activeClass = 'table--light-red'">&.table--light-red</button>
  <button class="button primary-1" (click)="activeClass = 'table--brown'">&.table--brown</button>



  <div class="margin-top-bottom--3x">
    <div *ngIf="activeClass === '' || !activeClass">
      <pre>
        {{toHtmlTagString("table class='table table--woo'")}}
          {{toHtmlTagString("thead")}}{{toHtmlTagString("/thead")}}
          {{toHtmlTagString("tbody")}}{{toHtmlTagString("/tbody")}}
        {{toHtmlTagString("/table")}}</pre>
    </div>
    <div *ngIf="activeClass === 'table--small'">
      <pre>
        {{toHtmlTagString("table class='table table--woo table--small'")}}
          {{toHtmlTagString("thead")}}{{toHtmlTagString("/thead")}}
          {{toHtmlTagString("tbody")}}{{toHtmlTagString("/tbody")}}
        {{toHtmlTagString("/table")}}</pre>
    </div>
    <div *ngIf="activeClass === 'table--hover'">
      <pre>
        {{toHtmlTagString("table class='table table--woo table--hover'")}}
          {{toHtmlTagString("thead")}}{{toHtmlTagString("/thead")}}
          {{toHtmlTagString("tbody")}}{{toHtmlTagString("/tbody")}}
        {{toHtmlTagString("/table")}}</pre>
    </div>
    <div *ngIf="activeClass === 'table--info'">
      <pre>
        {{toHtmlTagString("table class='table table--info'")}}
          {{toHtmlTagString("thead")}}{{toHtmlTagString("/thead")}}
          {{toHtmlTagString("tbody")}}{{toHtmlTagString("/tbody")}}
        {{toHtmlTagString("/table")}}</pre>
    </div>
    <div *ngIf="activeClass === 'table--light-red'">
      <pre>
        {{toHtmlTagString("table class='table table--light-red'")}}
          {{toHtmlTagString("thead")}}{{toHtmlTagString("/thead")}}
          {{toHtmlTagString("tbody")}}{{toHtmlTagString("/tbody")}}
        {{toHtmlTagString("/table")}}</pre>
    </div>
    <div *ngIf="activeClass === 'table--brown'">
      <pre>
        {{toHtmlTagString("table class='table table--brown'")}}
          {{toHtmlTagString("thead")}}{{toHtmlTagString("/thead")}}
          {{toHtmlTagString("tbody")}}{{toHtmlTagString("/tbody")}}
        {{toHtmlTagString("/table")}}</pre>
    </div>
  </div>

  <h4>Denna tabell är {{activeClass ? activeClass : 'table--woo'}}</h4>

  <table class="table table--woo" [ngClass]="activeClass">
    <thead>
      <tr>
        <td>Lorem</td>
        <td>Ipsum</td>
        <td>Dolor</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>shoreditch</td>
        <td>farm-to-table</td>
        <td>truffaut</td>
      </tr>
      <tr>
        <td>before</td>
        <td>they</td>
        <td>sold</td>
      </tr>
      <tr>
        <td>out</td>
        <td>biodiesel</td>
        <td>ramps</td>
      </tr>
      <tr>
        <td>hoodie</td>
        <td>Kickstarter</td>
        <td>shoreditch</td>
      </tr>
      <tr>
        <td>cardigan</td>
        <td>banjo</td>
        <td>artisan</td>
      </tr>
      <tr>
        <td>You</td>
        <td>probably</td>
        <td>haven't</td>
      </tr>
      <tr>
        <td>heard</td>
        <td>of</td>
        <td>them</td>
      </tr>
      <tr>
        <td>literally</td>
        <td>waistcoat</td>
        <td>chia</td>
      </tr>
      <tr>
        <td>hella</td>
        <td>meh</td>
        <td>mumblecore</td>
      </tr>
      <tr>
        <td>typewriter</td>
        <td>salvia</td>
        <td>normcore</td>
      </tr>
    </tbody>
  </table>


  <div class="divider margin-top-bottom--4x"></div>

  <h2 id="Inputs">Inputs</h2>
  <p>Här listan många olika sätt att mata in data genom olika inputs</p>
  <div class="row ">
    <div class="col-xs-6">
      <h3>Checkbox</h3>
      <div>
        <input type="checkbox" class="checkbox" id="checkbox">
        <label for="checkbox" class="checkbox">Checkbox</label>
      </div>
      <div>
        <input type="checkbox" class="checkbox" id="checkbox">
        <label for="checkbox" class="checkbox">Checkbox</label>
      </div>
      <div>
        <input type="checkbox" class="checkbox" id="checkbox">
        <label for="checkbox" class="checkbox">Checkbox</label>
      </div>
    </div>
    <div class="col-xs-6">
      <h3>Radio button</h3>
      <div>
        <input type="radio" id="one" class="radio" name="test">
        <label for="one" class="radio">Val #1</label>
      </div>
      <div>
        <input type="radio" id="two" class="radio" name="test">
        <label for="two" class="radio">Val #2</label>
      </div>
      <div>
        <input type="radio" id="three" class="radio" name="test">
        <label for="three" class="radio">Val #3</label>
      </div>
    </div>
  </div>

  <h3>Woo text input</h3>

  <p>Woo text-input är en komponent för text i Woo Manager. Det som krävs är ett inputId och name. Validering sker med border color och ikoner. Ikonerna går att stänga av. Det går att inputa att alla mellanslag på slutet ska trimmas bort.</p>

  <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString("woo-text-input'")}}{{toHtmlTagString("woo-text-input'")}}</pre>

  <h5>Krävs</h5>
  <ul>
    <li> inputId </li>
    <li> name </li>
  </ul>
  <br>
  <h5>Alternativ</h5>
  <ul>
    <li> labelText </li>
    <li> disabled</li>
    <li> maxlength</li>
    <li> inputOptionalText</li>
    <li> required</li>
    <li> hideValidationIcons</li>
    <li> e2eId</li>
    <li>placeholder</li>
    <li> removeBlankSpaces</li>
  </ul>

  <br>

  <h5>Exempel</h5>

  <div class="row">
    <div class="col-xs-6">
      <woo-text-input
        inputId="textinput" 
        labelText="Label Text"
        disabled="false"
        name="name-text"
        maxlength="30"
        inputOptionalText="Optional Text" 
        required="true"
        e2eId=""
        placeholder="Testa att skriva nåt"
        removeBlankSpaces="false"
      ></woo-text-input>
    </div>
    <div class="col-xs-6">
      <woo-text-input
        inputId="textinput" 
        labelText="Label Text"
        disabled="true"
        name="name-text"
        maxlength="30"
        inputOptionalText="Optional Text" 
        required="true"
        e2eId=""
        placeholder="Disabled"
        removeBlankSpaces="false"
      ></woo-text-input>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <woo-text-input
        inputId="textinput" 
        labelText="Inga ikoner"
        disabled="false"
        name="name-text"
        maxlength="30"
        inputOptionalText="Optional Text" 
        hideValidationIcons="true"
        e2eId=""
        placeholder=""
        removeBlankSpaces="false"
      ></woo-text-input>
    </div>
    <div class="col-xs-6">
      <woo-text-input
        inputId="textinput" 
        labelText="Label Text"
        name="name-text"
        maxlength="5"
        inputOptionalText="Optional Text" 
        e2eId=""
        placeholder="Maxlängd 5"
        removeBlankSpaces="false"
      ></woo-text-input>
    </div>
  </div>

  <h3>Woo number input</h3>

  <p>Woo number-input är väldigt lik text-input med med skilnaden att det bara går att skriva nummer. Inputfältet använder WooNumberFormatter, ett direktiv för att formatera nummer. Komponenten formatterar med mellanrum och tillåter inte att fältet är tomt eller har decimaler om det inte inputas att det ska få göra det.</p>

  <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString("woo-number-input'")}}{{toHtmlTagString("woo-number-input'")}}</pre>

  <h5>Krävs</h5>
  <ul>
    <li> inputId </li>
    <li> name </li>
  </ul>
  <br>
  <h5>Alternativ</h5>
  <ul>
    <li> labelText </li>
    <li> disabled</li>
    <li> maxlength</li>
    <li> inputOptionalText</li>
    <li> required</li>
    <li> hideValidationIcons</li>
    <li> e2eId</li>
    <li> formatterOptions = allowEmpty: false, allowDecimals: false</li>
  </ul>

  <br>

  <h5>Exempel</h5>

  <div class="row">
    <div class="col-xs-6">
      <woo-number-input
        inputId="textinput" 
        labelText="Label Text"
        disabled="false"
        name="name-text"
        maxlength="30"
        inputOptionalText="Optional Text" 
        required="true"
        e2eId=""
        
      ></woo-number-input>
    </div>
    <div class="col-xs-6">
      <woo-number-input
        inputId="textinput" 
        labelText="Disable"
        disabled="true"
        name="name-text"
        maxlength="30"
        inputOptionalText="Optional Text" 
        required="true"
        e2eId=""
        
      ></woo-number-input>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <woo-number-input
        inputId="textinput" 
        labelText="Decimaler tillåtna"
        disabled="false"
        name="name-text"
        maxlength="30"
        inputOptionalText="Optional Text" 
        e2eId=""
        [formatterOptions]="{allowEmpty: false, allowDecimals: true}"
      ></woo-number-input>
    </div>
    <div class="col-xs-6">
      <woo-number-input
        inputId="textinput" 
        labelText="Maxlängd 5"
        name="name-text"
        maxlength="5"
        inputOptionalText="Optional Text" 
        e2eId=""
        
      ></woo-number-input>
    </div>
  </div>
  

  <h3>Woo-dropdown</h3>
 <p>Woo-dropdown är en ng-select wrappad i en angular component som är en sökbar select (dropdown).</p>

  <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString("woo-dropdown", true)}}
    [items]='customers'
    ng-model='current.customer'
    bind-label='name'
    input-id='customer-search'
    label-text='Kund'
    [search-attributes]='['name', 'nickname']'
    not-found-text='Inga kunder hittades.'>
    {{toHtmlTagString("/woo-dropdown")}}</pre>
  
  <br>

  <div class="relative">
    <woo-dropdown
      [items]="ipsums"
      inputId="example-search"
      labelText="Exempel"
      notFoundText="Inga exempel hittades."
    ></woo-dropdown>
  </div>

  <ul class="ul__woo">
    <li><strong>items</strong> - JSON-lista med objekt som ska fylla listan</li>
    <li><strong>bindValue</strong> - Attributet på item som ska bli value i vid val</li>
    <li><strong>bindLabel</strong> - Texten på item som visas i listan</li>
    <li><strong>inputId</strong> - Id på inputen</li>
    <li><strong>labelText</strong> - Texten för label</li>
    <li><strong>notFoundText</strong> - Text som visas om inga items hittades vid sökning</li>
    <li><strong>searchAttributes</strong> - Attribut på item som ska inkluderas i sökningen</li>
  </ul>

  

  <h3>Period Select</h3>
  <p>En periodväljare som visar tillängliga veckor. Går att klicka sig fram till ett datum eller skiva i inputfälten. NgModel används för att komma åt värdet.</p>

  <h5>Syntax</h5>
  <pre>
    {{toHtmlTagString("period-select", true)}}
    ngModel='period'
    ngModelChange='$event'>
    {{toHtmlTagString("/period-select")}}</pre>
  <br>
  <h5>Exempel</h5>
  <period-select [ngModel]="period" (ngModelChange)="periodChanged($event)"></period-select>
  <br>
  <h4>Datumobjekt</h4>
  <p class="text--strong">startdatum: {{period.start}}</p>
  <p class="text--strong">slutdatum: {{period.end}}</p>
  

  <ul class="ul__woo">
    <li><strong>disabled</strong> - true/false</li>
    <li><strong>firstSelectableDate</strong> - Minsta datum som kan väljas</li>
    <li><strong>lastSelectableDate</strong> - Sista datum som kan väljas</li>
    <li><strong>forcedStartDate</strong> - Visar tvingat startdatum i text ovanför inputfälten</li>
    <li><strong>forcedEndDate</strong> - Visar tvingat slutdatum i text ovanför inputfälten</li>
    <li><strong>defaultInitDate</strong> - Initialt datum om inget annat datum är valt</li>
  </ul>

  <h3>Toggles</h3>

  <p>Toggle-komponeten är vad det låter som, en toggle som även går att få med on- och off-labels som i exemplet nedan.</p>

  <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString("toggle", true)}}
    onLabel="Ja"
    offLabel="Nej"
    {{toHtmlTagString("/toggle")}}</pre>

  <br>
  <h5>Exempel</h5>

  <toggle
  onLabel="Ja"
  offLabel="Nej"
  ></toggle>

  <h3>Nummer-formattering</h3>
  <p>För att få en snyggare formattering av nummerfält så kan man använda ett angular directive som heter <strong>format</strong>. Detta directive påverkar endast formatteringen i input-fältet och inte själva skickade objektet. Anropet returnerar en int istället för string.</p>

  <h5>Syntax</h5>
  <pre>
    {{toHtmlTagString("input type='text' format='number' ng-model='number'")}}</pre>
  
  <h5>Exempel</h5>
  
  <input type="text" format="number" [(ngModel)]="number">

  <h5>Output</h5>
  <div class="single-html-container">
    {{number || 0}}
  </div>

  <h3>Listor</h3>

  <h5>Syntax</h5>
  <pre>
    {{toHtmlTagString("ul class='ul__woo'")}}
      {{toHtmlTagString("li class=''")}}{{toHtmlTagString("/li")}}
    {{toHtmlTagString("/ul")}}</pre>
  <h5>Exempel</h5>
  <div class="row">
    <div class="col-xs-6">
      <span>Det finns följande klasser för ul-elementet:</span>
      <ul class="ul__woo">
        <li>.ul--small</li>
        <li>.ul--hover</li>
      </ul>
    </div>
    <div class="col-xs-6">
      <span>Hover</span>
      <ul class="ul__woo ul--hover">
        <li>.ul--small</li>
        <li>.ul--hover</li>
      </ul>
    </div>
  </div>


  <h3>Badges</h3>

  <p>Badges är uppbyggda av en cirkel med bakgrundsfärg och en valfri ikon. </p>

  <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString("div class='status-badge large_icon success'")}}
      {{toHtmlTagString("span class='icon-check'")}}{{toHtmlTagString("/span")}}
    {{toHtmlTagString("/div")}}
    {{toHtmlTagString("div class='status-badge large_icon error'")}}
      {{toHtmlTagString("span class='icon-heads-up-outline'")}}{{toHtmlTagString("/span")}}
    {{toHtmlTagString("/div")}}
    {{toHtmlTagString("div class='status-badge large_icon warning'")}}
      {{toHtmlTagString("span class='icon-heads-up-outline'")}}{{toHtmlTagString("/span")}}
    {{toHtmlTagString("/div")}}
    {{toHtmlTagString("div class='status-badge large_icon info'")}}
      {{toHtmlTagString("span class='icon-question-mark'")}}{{toHtmlTagString("/span")}}
      {{toHtmlTagString("div class='hover-text'")}}Text här...{{toHtmlTagString("/div")}}
    {{toHtmlTagString("/div")}}
    {{toHtmlTagString("div class='status-badge large_icon inverse'")}}
    {{toHtmlTagString("span class='icon-happy'")}}{{toHtmlTagString("/span")}}
  {{toHtmlTagString("/div")}}</pre>

  <h5>Exempel</h5>

  
  <div class="row">
    <div class="col-xs-1">
      <div class="status-badge large_icon success">
        <span class="icon-check"></span>
      </div>
    </div>
    <div class="col-xs-1">
      <div class="status-badge large_icon error">
        <span class="icon-close-x"></span>
      </div>
    </div>
    <div class="col-xs-1">
      <div class="status-badge large_icon warning">
        <span class="icon-heads-up-outline"></span>
      </div>
    </div>
    <div class="col-xs-1">
      <div class="status-badge large_icon info">
        <span class="icon-question-mark-outline"></span>
        <div class="hover-text">Det går även lägga till en hover-text</div>
      </div>
    </div>
    <div class="col-xs-1">
      <div class="status-badge large_icon inverse">
        <span class="icon-happy"></span>
      </div>
    </div>
    
  </div>

  <h5>Färger</h5>
  <div class="row">
    <ng-container *ngFor="let badge of statusBadgeColors">
      <div class="col-xs-1">
        <div class="status-badge {{badge}}">
          <div class="hover-text">.{{badge}}</div>
        </div>
      </div> 
    </ng-container>
  </div>
  <br>


  <h3>Skuggor</h3>
  <span>Det finns följande klasser för att applicera skuggor på element</span>
  <h5>Syntax</h5>
  <pre>
    {{toHtmlTagString("div class='section info text--center shadow-z-1'")}}
    {{toHtmlTagString("div class='section info text--center shadow-z-2'")}}
    {{toHtmlTagString("div class='section info text--center shadow-z-3'")}}
    {{toHtmlTagString("div class='section info text--center shadow-z-4'")}}
    {{toHtmlTagString("div class='section info text--center shadow-z-5'")}}</pre>
  <br>
  <h4>Exempel</h4>
  <div class="row">
    <div class="col-xs-2">
      <div class="section info text--center shadow-z-1">.shadow-z-1</div>
    </div>
    <div class="col-xs-2">
      <div class="section info text--center shadow-z-2">.shadow-z-2</div>
    </div>
    <div class="col-xs-2">
      <div class="section info text--center shadow-z-3">.shadow-z-3</div>
    </div>
    <div class="col-xs-2">
      <div class="section info text--center shadow-z-4">.shadow-z-4</div>
    </div>
    <div class="col-xs-2">
      <div class="section info text--center shadow-z-5">.shadow-z-5</div>
    </div>
  </div>

  <div class="block">
    <h3>Avskiljare</h3>

    <p>
      Skapar en avskiljare mellan element som default är 1px hög och 100% bred.
    </p>

    <h5 >Syntax</h5>
    <pre>
      {{toHtmlTagString("div class='divider'")}}{{toHtmlTagString("/div")}}
      {{toHtmlTagString("div class='divider medium'")}}{{toHtmlTagString("/div")}}
      {{toHtmlTagString("div class='divider strong'")}}{{toHtmlTagString("/div")}}
      {{toHtmlTagString("div class='divider dark'")}}{{toHtmlTagString("/div")}}
      {{toHtmlTagString("div class='divider info'")}}{{toHtmlTagString("/div")}}</pre>
    
    <h5>Exempel</h5>

    <span>Avskiljare har följande klasser:</span>
    <ul class="ul__woo">
      <li>medium</li>
      <li>.strong</li>
      <li>.dark</li>
      <li>.info</li>
    </ul>

    <div class="account__card">
      <div class="divider"></div>
      <br>
      <div class="divider medium"></div>
      <br>
      <div class="divider strong"></div>
      <br>
      <div class="divider dark"></div>
      <br>
      <div class="divider info"></div>
    </div>
  </div>


  <h2 id="Ikoner">Ikoner</h2>

  <h3>TV4-ikoner</h3>
  <p>Ikonerna är spciellt framtagna för TV4. I Woo är ikonerna genererade som en font. de har därför fasta ration och storleken sätts med font-size. I rutan nedanför är ikonen 48px. Du kan söka efter vad du behöver i dropdownen eller se alla ikoner i listan nedanför</p>
  <br>
  <available-icons-style></available-icons-style>

  <h2 id="Loader">Loader</h2>

  <h3>Loader-komponenten med och utan kommentarer</h3>
  <pre>
    {{toHtmlTagString("dot-loader", true)}}
    visability=true
    {{toHtmlTagString("/dot-loader")}}

    {{toHtmlTagString("dot-loader", true)}}
    visability=true
    description="lite text som beskriver vad som händer"
    {{toHtmlTagString("/dot-loader")}}

    {{toHtmlTagString("dot-loader", true)}}
    visability=true
    large=true
    {{toHtmlTagString("/dot-loader")}}</pre>
  
  <h5>Exempel</h5>
  <div class="row margin-top--3x">
    <div class="col-xs-8">
      <p>Default loader, utan beskrivning, 32px stor</p>
    </div>
    <div class="col-xs-4">
      <dot-loader [visibility]="true"></dot-loader>
    </div>
  </div>
  <div class="divider margin-top-bottom--2x" ></div>
  <div class="row">
    <div class="col-xs-8">
      <p>Default loader, med beskrivande text, 32px stor</p>
    </div>
    <div class="col-xs-4">
      <dot-loader [visibility]="true" description="lite text som beskriver vad som händer"></dot-loader>
    </div>
  </div>
  <div class="divider margin-top-bottom--2x"></div>
  <div class="row">
    <div class="col-xs-8">
      <p>Default loader, utan beskrivning, 72px stor</p>
    </div>
    <div class="col-xs-4">
      <dot-loader [visibility]="true" [large]="true"></dot-loader>
    </div>
  </div>
  <br>
  <br>
  <load-button-styles></load-button-styles>

  <h2 id="Meddelanden">Meddelanden</h2>

  <h3>Context-message</h3>

  <p>Context-message används främst i bokningsflöden. Det finns fyra olika varianter</p>

  <h5>Syntax</h5>
  <pre>{{toHtmlTagString("context-message message='...' messageType='...'")}}{{toHtmlTagString("/context-message")}}</pre>

  <h5>Exempel</h5>
  <div class="row">
    <div class="col-xs-3">
      Type = warning
      <context-message message="Inget fel, men viktigt" messageType="warning"></context-message>
    </div>
    <div class="col-xs-3">
      Type = error
      <context-message message="Något har gått fel" messageType="error"></context-message>
    </div>
    <div class="col-xs-3">
      Type = success
      <context-message message="It's all goooood" messageType="success"></context-message>
    </div>
    <div class="col-xs-3">
      Type = info
      <context-message message="Bra information" messageType="info"></context-message>
    </div>
  </div>

  <h3>Modaler</h3>

  <p>Modaler är popup-rutor med information om att något händer eller hur något gick. Om något gick bra kan modalen se ut som i exemplet nedan, success-dialog. Det finns även:</p>
  <ul class="ul__woo">
    <li>Blocking-dialog</li>
    <li>Confirm-dialog</li>
    <li>Error-dialog</li>
    <li>Feedback-dialog</li>
  </ul>

  <h5>Syntax</h5>

  <pre>
    {{toHtmlTagString("woo-dialog")}}
    content here...
    {{toHtmlTagString("success-dialog")}}
    {{toHtmlTagString("blocking-dialog")}}
    etc...
    {{toHtmlTagString("/woo-dialog")}}</pre>
  
  <h5>Exempel</h5>
  <div class="style-sheet-modal">
    <div class="style-modal-header" >
      <button type="button" class="close">
        <span class="icon-close-x" ></span>
      </button>
    </div>
    <div class="style-modal-content">
    <div class="display--flex margin-bottom--2x">
      <div class="status__badge success text-center display--inline-block margin--none">
        <span class="icon-check"></span>
      </div>
      <h2 class="display--inline margin--none margin-left--2x">Wooho! Det där gick ju bra.</h2>
    </div>
    <p>Så här ser en modal ut</p>

    <button class="button primary-1 center display--block margin-top--2x">Stäng</button>
    </div>
  </div>
  
  <h2 id="Övrigt">Övrigt</h2>
  <h3>Hjälpklasser</h3>
  Det finns många hjälpklasser för att positionera, visa eller ändra på element. Kika i <strong>_utils.scss</strong>, där finns de flesta!
  <h3>Mail templates</h3>
  <p *ngFor="let link of mailLinks">
    <a class="primary-1" target="_blank" [href]="link.link">{{link.name}}</a>
  </p>

  <h3>Påfågeln</h3>

  <p>
    Det här var den tidigare loadern för Woo Manager. Den är himla fin och ligger kvar som ett minne :) 
  </p>

  <div class="margin-top-bottom--5x" style="height: 200px;">
    <div class="feather large" style="left: 45%; ">
      <div class="feather--base"></div>
      <div class="feather--layer-1"></div>
      <div class="feather--layer-2"></div>
      <div class="feather--layer-3"></div>
      <div class="feather--layer-4"></div>
      <div class="feather--layer-5"></div>
    </div>
  </div>

  

</div>
