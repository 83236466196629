import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { orderByDate } from '../../../utils/date';
import { UploadProgressEvent } from '../../../woo_components.module/asset_upload/asset-upload.component';
import { CreativeAssetService } from '../../../woo_services.module/CreativeAssetService';
import { CustomerService } from '../../../woo_services.module/CustomerService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { CreativeAsset, wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'manage-creative-assets',
  templateUrl: './manage-creative-assets-component.html',
})
export class ManageCreativeAssets implements OnInit {
  allowUploadImage = false;
  customerName: string;
  customerId: wooId = this.routingService.getParam(this.route, 'customerId');
  assets: CreativeAsset[];

  show = {
    uploadVideo: false,
    uploadImage: false,
    loader: true,
  };

  constructor(
    private creativeAssetService: CreativeAssetService,
    private customerService: CustomerService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
  ) {}

  ngOnInit(): void {
    Promise.all([this.setCustomerName(), this.loadAssets()]).finally(() => (this.show.loader = false));
  }

  toggleUploadVideo(): void {
    this.show.uploadVideo = !this.show.uploadVideo;
  }

  toggleUploadImage(): void {
    this.show.uploadImage = !this.show.uploadImage;
  }

  uploadProgress(event: UploadProgressEvent): void {
    this.assets = (event.asset ? [event.asset] : []).concat(this.assets.filter((a) => a.created_at));
    this.show.uploadVideo = false;
    this.show.uploadImage = false;
  }

  async delete(asset: CreativeAsset): Promise<void> {
    const oldAssets = this.assets;
    try {
      await this.dialogService.openConfirm({
        header: 'Ta bort uppladdat material',
        textBlocks: [`Är du säker på att du vill ta bort ${asset.name}?`],
        confirmText: 'Ja, ta bort!',
        cancelText: 'Avbryt',
      });
      this.assets = this.assets.filter((a) => a.id !== asset.id);
      await this.creativeAssetService.delete(asset.id);
    } catch {
      this.assets = oldAssets;
    }
  }

  private async loadAssets(): Promise<any> {
    const assets = await this.creativeAssetService.getAll(this.routingService.getParam(this.route, 'customerId'));
    this.assets = orderByDate(assets, 'created_at');
  }

  private async setCustomerName(): Promise<any> {
    const customerName = this.routingService.getState('customerName');
    if (customerName) {
      this.customerName = customerName;
      return Promise.resolve();
    } else {
      this.customerName = (
        await this.customerService.getCustomer(this.routingService.getParam(this.route, 'customerId'))
      ).name;
    }
  }
}
