import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { maxBy, minBy } from '../utils/array';
import { EnvironmentService } from './EnvironmentService';
import { TranslationService } from './TranslationService';
import { AgeTargetGroup, GenderTargetGroup, TargetingAgeTargetGroup, TargetingGenderTargetGroup } from './shared-types';

@Injectable({ providedIn: 'root' })
export class TargetGroupService {
  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private translationService: TranslationService,
  ) {}

  getTargetGroupEstimates(): Promise<Estimates> {
    return lastValueFrom(this.http.get<Estimates>(`${this.env.apiUrl}/target_group_estimates/`));
  }

  updateTargetGroupEstimates(data: Estimates): Promise<Estimates> {
    return lastValueFrom(this.http.put<Estimates>(`${this.env.apiUrl}/target_group_estimates/`, data));
  }

  getGenders(): Promise<GenderTargetGroup[]> {
    return lastValueFrom(this.http.get<GenderTargetGroup[]>(`${this.env.apiUrl}/gender_target_groups`));
  }

  getAges(): Promise<AgeTargetGroup[]> {
    return lastValueFrom(this.http.get<AgeTargetGroup[]>(`${this.env.apiUrl}/age_target_groups`));
  }

  getGendersAndAges(): Promise<[AgeTargetGroup[], GenderTargetGroup[]]> {
    return Promise.all([this.getAges(), this.getGenders()] as const);
  }

  getTargetGroupNames(
    ageTargetGroups: TargetingAgeTargetGroup[] | AgeTargetGroup[],
    genderTargetGroups: TargetingGenderTargetGroup[] | GenderTargetGroup[],
  ): string[] {
    const list = [];
    let ageString = '';

    if (ageTargetGroups.length) {
      const ageSpan = this.getAgeSpanFromAgeTargetGroups(ageTargetGroups);
      ageString = ` ${ageSpan.minAge}-${ageSpan.maxAge} år`;
      if (genderTargetGroups.length) {
        genderTargetGroups.forEach((gender) => {
          list.push(gender.name + ageString);
        });
      } else {
        list.push(ageString);
      }
    } else if (genderTargetGroups.length) {
      genderTargetGroups.forEach((gender) => {
        list.push(gender.name);
      });
    }
    return list;
  }

  getAgeSpanFromAgeTargetGroups(
    ageTargetGroups: Partial<AgeTargetGroup>[] | Partial<TargetingAgeTargetGroup>[],
  ): Record<string, number> {
    return {
      minAge: minBy(ageTargetGroups, 'min_age'),
      maxAge: maxBy(ageTargetGroups, 'max_age'),
    };
  }

  getGenderNamesInPluralFromGenderTargetGroups(genderTargetGroups: GenderTargetGroup[]): string[] {
    return genderTargetGroups.map((gender) => this.translationService.convertGenderPlural(gender.name));
  }
}

export interface Estimates {
  shares: {
    [week: number]: {
      [publisherId: string]: PublisherEstimates;
    };
  };
  withdrawal_limits: {
    [publisherId: string]: PublisherEstimates;
  };
}

export interface PublisherEstimates {
  [genderId: string]: GenderEstimates;
}
export interface GenderEstimates {
  ages: AgeEstimates;
  shares?: TargetGroupEstimatesSlotTypes;
  withdrawal_limits?: TargetGroupEstimatesSlotTypes;
}

export interface AgeEstimates {
  [ageId: string]: EstimatesCategory;
}

export interface EstimatesCategory {
  shares?: TargetGroupEstimatesSlotTypes;
  withdrawal_limits?: TargetGroupEstimatesSlotTypes;
}

export interface TargetGroupEstimatesSlotTypes {
  preroll?: number;
  midroll?: number;
  postroll?: number;
}
