<div class="centered-loading-spinner" [ngClass]="{'show--spinner': show.loadingSpinner}">
  <dot-loader class="position--fixed" [visibility]="show.loadingSpinner" [description]="loading.campaign || activeCampaign ? 'Laddar kampanj' : null"></dot-loader>
</div>
<div class="padding--1x padding-top--3x animate" *ngIf="show.campaign" [ngClass]="{'dimmed--loading': show.loadingSpinner}">
  <div class="flex__container--vertical align-items--end campaign-labels">
    <campaign-status-label class="campaign-label" [campaignStatus]="activeCampaign?.status" [needsAttention]="needsAttention"></campaign-status-label>
  </div>
  <div class="flex__container--vertical align-items--end" >
    <special-campaign-label *ngIf="adminOrPlanner()" class="campaign-label"  [campaign]="activeCampaign"></special-campaign-label>
  </div>
  <div class="margin-top--1x">
    <date-span [start]="campaignPeriod.start" [end]="campaignPeriod.end"></date-span>
  </div>
  <div class="margin-top--3x">
    <customer-agency-row [data]="activeCampaign"></customer-agency-row>
    <h3 class="margin-top--none margin-bottom--none word-break--word text--dark-red">{{activeCampaign?.name}}</h3>
  </div>
  <div *ngIf="!editOngoingCampaign" class="margin-top--3x">
    <manage-campaign-buttons
      [campaign]="activeCampaign"
      [campaignStartsToday]="campaignStartsToday"
    ></manage-campaign-buttons>
  </div>
  <div class="margin-top--2x" *ngIf="!editOngoingCampaign && show.button.showStats || show.button.collapse">
      <text-button
      icon=""
      [disabled]="disable.button.showStats"
      *ngIf="show.button.showStats"
      (click)="onClickStats()"
    >
      Visa statistik
    </text-button>
    <text-button
      icon="TV4_008_fram"
      [disabled]="disable.button.collapse"
      *ngIf="show.button.collapse"
      (click)="onClickCollapse()"
    >
      Fäll tillbaka
    </text-button>
  </div>
  <div class="margin-top--3x">
    <table class="table no-border campaign-summary-table margin-top-bottom--none">
      <tbody>
        <tr>
          <td>Total budget</td>
          <td>{{campaignSummary?.totalBudget | wooNullIfZero | currency: 'SEK' | dashAsDefault}}</td>
        </tr>
        <tr *ngIf="show.element.campaignSummaryOrderValue">
          <td>{{show.element.campaignSummaryNewOrderValue ? 'Initialt ordervärde' : 'Ordervärde'}}</td>
          <td>{{campaignSummary?.orderValue | currency: 'SEK' | dashAsDefault}}</td>
        </tr>
        <tr *ngIf="show.element.campaignSummaryNewOrderValue">
          <td>Nytt ordervärde</td>
          <td>{{campaignSummary?.newOrderValue | currency: 'SEK' | dashAsDefault}}</td>
        </tr>
        <tr>
          <td>Referensnummer</td>
          <td>{{campaignSummary?.referenceNumber | dashAsDefault}}</td>
        </tr>
        <tr>
          <td>Er referens</td>
          <td>{{campaignSummary?.clientInvoiceReference | dashAsDefault}}</td>
        </tr>
        <tr *ngIf="show.element.campaignSummaryOrderRef">
          <td>Orderreferens</td>
          <td>{{campaignSummary?.orderRef | dashAsDefault}}</td>
        </tr>
        <tr>
          <td>{{campaignSummary?.useUpdatedDate ? 'Senast uppdaterad' : 'Skapades'}}</td>
          <td>{{campaignSummary?.useUpdatedDate ? campaignSummary?.updatedDate : campaignSummary?.createdDate | dashAsDefault}}</td>
        </tr>
        <tr *ngIf="campaignSummary?.bookedDate">
          <td>Bokad</td>
          <td>{{campaignSummary?.bookedDate | dashAsDefault}}</td>
        </tr>
        <tr *ngIf="campaignSummary?.bookedBy">
          <td>Bokad av</td>
          <td>{{campaignSummary?.bookedBy | dashAsDefault}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="margin-top--3x margin-bottom--1x" *ngIf="show.element.statusComments">
    <div *ngFor="let statusComment of statusComments">
      <heading-with-line [text]="statusComment.title | wooTitleCase"></heading-with-line>
      <div class="display--block campaign-comment-text-box padding-left-right--1x">
        <h4 class="text--medium">{{statusComment?.text | dashAsDefault}}</h4>
      </div>
    </div>
  </div>
  <div class="margin-top--3x" *ngIf="show.element.viewCurrencyBlock">
    <view-currency-block
      [campaign]="activeCampaign"
    ></view-currency-block>
  </div>
  <div class="margin-top--4x">
    <targeting-box
      *ngFor="let data of targetingBoxData"
      class="display--block margin-top--2x"
      [targeting]="data.targeting"
      [metaData]="data.metaData"
      [part]="data.part"
    ></targeting-box>
  </div>
  <div class="margin-top--4x">
    <text-button
      icon="download"
      [disabled]="disable.button.download"
      *ngIf="show.button.download"
      (click)="onClickDownload()"
    >
      Ladda ner bokningsbekräftelse (PDF)
    </text-button>
    <table class="table no-border campaign-summary-table margin-top--1x margin-bottom--none">
      <tbody>
        <tr>
          <td>Kampanj-ID</td>
          <td class="word--break">{{activeCampaign?.id | dashAsDefault}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
