import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MessageKey } from '../advanced_campaign_booking.module/stores/BookingStore';
import { User } from './shared-types';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private user: User;
  private authenticationToken: string;
  private userPreferences: UserPreferences;

  constructor(private cookieService: CookieService, @Inject('Window') private window: Window) {
    this.user = this.loadUser();
    this.authenticationToken = this.loadToken();
    this.userPreferences = this.loadPreferences();
  }

  updateUser<K extends keyof User>(key: K, value: User[K]): any {
    this.user[key] = value;
    this.storeUser(this.user);
  }

  updateUserPreferences<K extends keyof UserPreferences>(key: K, value: UserPreferences[K]): void {
    this.userPreferences[key] = value;
    this.storePreferences(this.userPreferences);
  }

  getUserPreferences<K extends keyof UserPreferences>(key: K): UserPreferences[K] {
    return this.userPreferences[key];
  }

  create(user: User & { authentication_token: string }): void {
    const userCopy = { ...user };
    delete userCopy.authentication_token;
    delete userCopy.reset_password_token;
    this.user = userCopy;
    this.authenticationToken = user.authentication_token;
    this.storeToken(this.authenticationToken);
    this.storeUser(this.user);
  }

  destroy(): void {
    this.user = null;
    this.authenticationToken = null;
    this.storeToken(this.authenticationToken);
    this.storeUser(this.user);
  }

  isAuthenticated(): boolean {
    return !!(this.user && this.authenticationToken);
  }

  getUser(): User {
    if (!this.user) {
      this.user = this.loadUser();
    }

    return this.user;
  }

  getUserEmail(): string {
    return this.user?.email;
  }

  getAuthenticationToken(): string {
    return this?.authenticationToken;
  }

  getAuthHeader(): { [key: string]: string } {
    const email = this.user ? this.user.email : null;
    return email && this.authenticationToken ? { 'X-User-Email': email, 'X-User-Token': this.authenticationToken } : {};
  }

  getHeader(): { [key: string]: string } {
    return Object.assign(this.getAuthHeader(), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  getCurrentUser(): User {
    return this.user;
  }

  getCashboardNotificationDismissed = (): boolean => {
    return this.cookieService.get('CashboardNotificationDismissed') === 'true';
  };

  setCashboardNotificationDismissed = (): void => {
    this.cookieService.set('CashboardNotificationDismissed', 'true', null, null, null, null, 'Lax');
  };

  getCookieBannerDismissed = (): boolean => {
    return this.cookieService.get('CookieBannerDismissed') === 'true';
  };

  setCookieBannerDismissed = (): void => {
    const now = new Date();
    const expireDate = new Date(now.getFullYear() + 2, now.getMonth(), now.getDate());
    this.cookieService.set('CookieBannerDismissed', 'true', expireDate, null, null, null, 'Lax');
  };

  private storeUser(user: User): void {
    if (user) {
      this.window.localStorage.setItem('woouser', JSON.stringify(user));
    } else {
      this.window.localStorage.removeItem('woouser');
    }
  }

  private loadUser(): User {
    const storedUser = JSON.parse(this.window.localStorage.getItem('woouser'));
    return storedUser || null;
  }

  private storeToken(token: string): void {
    if (token) {
      this.cookieService.set('wootoken', token, null, null, null, null, 'Lax');
    } else {
      this.cookieService.delete('wootoken');
    }
  }

  private loadToken(): string {
    return this.cookieService.get('wootoken');
  }

  private storePreferences(preferences: UserPreferences) {
    this.window.localStorage.setItem('woouserpreferences', JSON.stringify(preferences));
  }

  private loadPreferences(): UserPreferences {
    const defaultPreferences: UserPreferences = {
      dashboardAsCards: true,
      dismissedIntroMessagesAbf: [],
    };
    return JSON.parse(this.window.localStorage.getItem('woouserpreferences')) || defaultPreferences;
  }
}

export type UserPreferences = {
  dashboardAsCards: boolean;
  dismissedIntroMessagesAbf: MessageKey[];
};
