<div class="border--all border--info padding--2x flex--align" (click)="modelChange.emit(value)" [ngClass]="{selected: model === value}">
  <div class="display--inline-block width--75">
    <div>
      <input class="radio" id="{{getId()}}" type="radio" name="payment" [(ngModel)]="model" [value]="value">
      <label class="radio text-align--left" for="{{getId()}}">{{headline}}</label>
    </div>
    <div class="margin-top--1x text--small">
      {{text}}
    </div>
  </div>
  <img src="../../images/invoice.svg">
</div>
