import { Component, OnInit } from '@angular/core';
import { inPeriod, isDateAfter } from '../../../utils/date';
import { DialogService } from '../../../woo_services.module/DialogService';
import { SystemService, SystemSettings } from '../../../woo_services.module/SystemService';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { WooUiEventService } from '../../../woo_services.module/WooUiEventService';

@Component({
  selector: 'system-settings-form',
  templateUrl: './system-settings-form.component.html',
})
export class SystemSettingsForm implements OnInit {
  settings: SystemSettings;
  initiated = false;
  showPauseInvoices: boolean;

  private editableSystemSettings = {
    campaign_related: {
      header: 'Kampanjregler',
      settings: {
        max_allowed_budget: 'Maxbudget',
        min_campaign_length: 'Minsta antalet dagar en kampanj får vara',
        min_allowed_impressions: 'Minsta tillåtna antalet estimerade impressions för en kampanj',
        format_region_min_allowed_imps:
          'Minsta tillåtna antalet estimerade impressions för en kampanj innehållandes specific (programformat eller kategori) i kombination med regioner',
        day_to_expand_a_campaign_targeting: 'Förlängning av kampanj. (Max antal dagar)',
        cash_customer_payment_terms: 'Betalningsvillkor (antal dagar) för Cashkunder',
      },
    },
    book_in_advanced: {
      header: 'Dagar i förväg användare ska bokat sin kampanj',
      settings: {
        min_days_until_start: 'Minsta antalet arbetsdagar en bokning får ske innan kampanjstart',
        agency_user_min_days_until_start:
          'Minsta antalet arbetsdagar en bokning får ske innan kampanjstart för en mediebyråanvändare',
        external_planner_min_days_until_start:
          'Minsta antalet arbetsdagar en bokning får ske innan kampanjstart för en extern planerare',
        low_credit_rating_min_days_until_start:
          'Minsta antalet dagar en bokning får ske innan kampanjstart för kund med låg kreditvärdighet',
      },
    },
    update_creative: {
      header: 'Avändare kan updatera material',
      settings: {
        min_days_to_update_creatives:
          'Minsta antalet arbetsdagar en boknings material får ändras innan kampanjstart för mediabyråer och direktkunder',
        min_days_to_update_creatives_in_mail:
          'Minsta antalet arbetsdagar en boknings material får ändras innan kampanjstart för mediabyråer och direktkunder (enligt mailet)',
        missing_creative_reminder_threshold_days:
          'Antalet arbetsdagar innan kampanjstart som påminnelse-mailet om saknat kampanjmaterial skickas till kunden',
        min_days_to_update_gambling_creatives:
          'Minsta antalet arbetsdagar ett spel-bolags boknings material får ändras innan kampanjstart för mediabyråer och direktkunder',
        min_days_to_update_gambling_creatives_in_mail:
          'Minsta antalet arbetsdagar ett spel-bolags boknings material får ändras innan kampanjstart för mediabyråer och direktkunder (enligt mailet)',
        missing_creative_reminder_gambling_threshold_days:
          'Antalet arbetsdagar innan kampanjstart för spel-bolag som påminnelse-mailet om saknat kampanjmaterial skickas till kunden',
      },
    },
    agency_rules: {
      header: 'Byråregler',
      settings: {
        years_agency_can_follow_up_old_customers: 'Antalet år en byrå ska kunna följa upp kampanjer på en gammal kund.',
      },
    },
  };

  constructor(
    private dialogService: DialogService,
    private systemService: SystemService,
    private translationService: TranslationService,
    private wooUiEventService: WooUiEventService,
  ) {}

  ngOnInit(): void {
    this.systemService.loadSystemwideSettings().then((systemSettings) => {
      this.settings = systemSettings;
      this.initPauseInvoiceState();
      this.initiated = true;
    });
  }

  update(): void {
    this.systemService
      .update(this.settings)
      .then(() => {
        this.dialogService.openSuccess('Systemet har uppdaterats');
      })
      .finally(() => {
        this.wooUiEventService.stopLoadDotAnimation();
      });
  }

  toggleLabel = this.translationService.convertFeatureToggles;
  editableSettings = Object.keys(this.editableSystemSettings);

  settingsGroupHeader(settingsGroup: string): string {
    return this.editableSystemSettings[settingsGroup].header;
  }

  settingsGroupSettings(settingsGroup: string): string[] {
    return Object.keys(this.editableSystemSettings[settingsGroup].settings);
  }

  settingLabel(settingsGroup: string, systemSetting: string): string {
    if (!!this.editableSystemSettings[settingsGroup]?.settings[systemSetting]) {
      return this.editableSystemSettings[settingsGroup].settings[systemSetting];
    }

    return systemSetting;
  }

  pauseInvoicesPeriodChanged = (event: { start: Date; end: Date }): void => {
    this.settings.invoice_pause_start_date = event.start;
    this.settings.invoice_pause_end_date = event.end;
  };

  private clearPauseInvoiceDates = (): void => {
    this.settings.invoice_pause_start_date = null;
    this.settings.invoice_pause_end_date = null;
  };

  togglePauseInvoices = (): void => {
    this.clearPauseInvoiceDates();
  };

  private initPauseInvoiceState = (): void => {
    const missingDates = !this.settings.invoice_pause_start_date || !this.settings.invoice_pause_end_date;

    const now = new Date();
    const active = inPeriod(
      new Date(this.settings.invoice_pause_start_date),
      new Date(this.settings.invoice_pause_end_date),
      now,
    );
    const upcoming = isDateAfter(this.settings.invoice_pause_start_date, now);

    if (missingDates || !(active || upcoming)) {
      this.showPauseInvoices = false;
      this.clearPauseInvoiceDates();
    } else {
      this.showPauseInvoices = true;
    }
  };
}
