<div>
  <div class="list__item__bullet">
    <span class="list__item__number">
      {{number}}
    </span>
  </div>
  <div class="list__item__content">
    <p class="list__item__heading">
      {{heading}}
    </p>
    <p class="list__item__text">
      {{text}}
    </p>
  </div>
</div>
