<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera tillgängliga programformat</h3>
</div>

<div>
  <div #stickyHeader>
    <div class="tabbable__body">
      <p>Notera att enbart konfigurerade programformat här går att importera estimat för.</p>
      <import-warning *ngIf="show.estimatesHaveChanged"></import-warning>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="button-filter-container">
        <div>
          <button *ngIf="!show.isEditing" class="button primary-1" (click)="openCard('program')">Nytt programformat</button>
          <button *ngIf="!show.isEditing" class="button secondary" (click)="setEditingDistribution(true)">Redigera fördelning</button>
          <button *ngIf="show.isEditing" class="button primary-1" [disabled]="programFormatsForm.invalid" (click)="save()">Spara</button>
          <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
        </div>
        <div class="filter-dropdown">
          <woo-dropdown
          [items]="categories"
          [(ngModel)]="selectedCategory"
          inputId="select-category"
          labelText="Filtrera på kategori"
          notFoundText="Inga val hittades."
          [clearable]="true"></woo-dropdown>
        </div>
      </div>
    </div>

    <div class="tabbable__panel clearfix shadow-z-1">
      <h3 class="margin-left--3x">Tillgängliga programformat</h3>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table--woo table--info table--hover table--align--middle" ngForm #programFormatsForm="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th colspan="3"></th>
          <th colspan="2" class="text-center">Fördelning Preroll</th>
          <th colspan="2"></th>
        </tr>
        <tr [ngClass]="showMidroll ? 'text--small' : ''">
          <th class="text--thin capitalize--first-letter">Programformat</th>
          <th class="text--thin capitalize--first-letter">Kategori</th>
          <th class="text--thin capitalize--first-letter">Datum</th>
          <th class="text--thin capitalize--first-letter">Adresserbar TV</th>
          <th class="text--thin capitalize--first-letter">Online Video</th>
          <th *ngIf="showMidroll" class="text--thin capitalize--first-letter">Adresserbar TV</th>
          <th *ngIf="showMidroll" class="text--thin capitalize--first-letter">Online Video</th>
          <th class="text--thin capitalize--first-letter">Bokningsbar</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="!show.loading">
        <ng-container *ngFor="let programFormat of programFormats let index = index">
          <tr [hidden]="!rowVisible(programFormat)" [ngClass]="openTab(programFormat) ? 'row-card-clicked' : ''" style="height: 65px;">
            <td class="text--thin vertical-align--middle">
              {{programFormat.name}}
            </td>
            <td class="text--thin vertical-align--middle">
              {{programFormat.category_name}}
            </td>
            <td class="text--thin vertical-align--middle">
              <span *ngFor="let period of filterPeriods(programFormat.periods)">{{formatterService.formatPeriod(period?.start_date, period?.end_date)}}</span>
              <b class="text-danger" *ngIf="!programFormat.periods.length"><span class="icon-warning"></span> Lager saknas</b>
            </td>
            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{programShareLongform(programFormat.product_format_program_share).preroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-sharesLongformPreroll"
                  [(ngModel)]="programShareLongform(programFormat.product_format_program_share).preroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  required
                  (ngModelChange)="autoFill(programFormat.product_format_program_share, inputFieldIds.prerollLongform)">
              </div>
            </td>
            <td class="vertical-align--middle">
              <span *ngIf="!show.isEditing">{{programShareShortform(programFormat.product_format_program_share).preroll| percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-sharesShortformPreroll"
                  [(ngModel)]="programShareShortform(programFormat.product_format_program_share, shortformId).preroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  required
                  (ngModelChange)="autoFill(programFormat.product_format_program_share, inputFieldIds.prerollShortform)">
              </div>
            </td>
            <td *ngIf="showMidroll" class="vertical-align--middle text-center">
              <span *ngIf="!show.isEditing">{{programShareLongform(programFormat.product_format_program_share).midroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-sharesLongformMidroll"
                  [(ngModel)]="programShareLongform(programFormat.product_format_program_share).midroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  required
                  (ngModelChange)="autoFill(programFormat.product_format_program_share, inputFieldIds.midrollLongform)">
              </div>
            </td>
            <td *ngIf="showMidroll" class="vertical-align--middle text-center">
              <span *ngIf="!show.isEditing">{{programShareShortform(programFormat.product_format_program_share).midroll | percent: "1.0-3"}}</span>
              <div
                *ngIf="show.isEditing"
                class="input__container">
                <input
                  name="row-{{index}}-sharesShortformMidroll"
                  [(ngModel)]="programShareShortform(programFormat.product_format_program_share, shortformId).midroll"
                  wooPercentFormatter
                  [wooMinValidator]="0"
                  [wooMaxValidator]="1"
                  required
                  (ngModelChange)="autoFill(programFormat.product_format_program_share, inputFieldIds.midrollShortform)">
              </div>
            </td>
            <td>
              <div class="input__container">
                <toggle
                  name="row-{{index}}-active"
                  [(ngModel)]="programFormat.active"
                  [ngModelOptions]="{standalone: true}"
                  id="{{programFormat.id}}"
                  (ngModelChange)="toggleActiveFormat(programFormat)"
                ></toggle>
              </div>
            </td>
            <td class="text-center" (click)="setSelected(programFormat.id)" style="cursor: pointer;">
              <span class="chevron" [ngClass]="openTab(programFormat) ? 'bottom' : ''"></span>
            </td>
          </tr>
          <tr [hidden]="!rowVisible(programFormat)" [ngStyle]="!openTab(programFormat) && {'display': 'none'}">
            <td colspan="9">
              <div class="padding-top-bottom--2x">
                <div class="input__container override-table-input col-xs-3">
                  <label for="row-{{index}}-name">Namn</label>
                  <p *ngIf="!isEditing(programFormat.id)">{{programFormat.name}}</p>
                  <div *ngIf="isEditing(programFormat.id)">
                    <input type="text" name="row-{{index}}-name"
                    [(ngModel)]="programFormat.name"
                    required>
                  </div>
                </div>
                <div class="input__container override-table-input col-xs-3">
                  <label for="row-{{index}}-mrmId">MRM Id</label>
                  <p *ngIf="!isEditing(programFormat.id)">{{programFormat.mrm_id}}</p>
                  <div *ngIf="isEditing(programFormat.id)">
                    <input type="text" name="row-{{index}}-mrmId"
                    [(ngModel)]="programFormat.mrm_id"
                    required>
                  </div>
                </div>
                <div class="input__container override-table-input col-xs-2">
                  <label *ngIf="!isEditing(programFormat.id)" for="row-{{index}}-publisher">Publicist</label>
                  <p *ngIf="!isEditing(programFormat.id)">{{programFormat.publisher_name}}</p>
                  <div *ngIf="isEditing(programFormat.id)" >
                    <woo-dropdown
                      [items]="publishers"
                      [(ngModel)]="programFormat.publisher_name"
                      name="row-{{index}}-publisher"
                      labelText="Publicist"
                      [searchAttributes]="['name']"
                      notFoundText="Inga publicister hittades."
                      placeholder="Välj..."
                      required
                    ></woo-dropdown>
                  </div>
                </div>
                <div class="input__container override-table-input col-xs-3">
                  <label *ngIf="!isEditing(programFormat.id)" for="row-{{index}}-category">Kategori</label>
                  <p *ngIf="!isEditing(programFormat.id)">{{programFormat.category_name}}</p>
                  <div *ngIf="isEditing(programFormat.id)">
                    <woo-dropdown
                    [items]="categories"
                    [(ngModel)]="programFormat.category_id"
                    name="row-{{index}}-catergory"
                    bindLabel="name"
                    bindValue="id"
                    inputId="category-search"
                    labelText="Kategori"
                    [searchAttributes]="['name']"
                    notFoundText="Inga kategorier hittades."
                    placeholder="Välj..."
                  ></woo-dropdown>
                  </div>
                </div>
                <div class="input__container override-table-input col-xs-3">
                  <label for="row-{{index}}-site_group">Styrd mot site group</label>
                  <toggle
                    name="row-{{index}}-site_group"
                    [(ngModel)]="programFormat.is_site_group"
                    [ngModelOptions]="{standalone: true}"
                    id="{{programFormat.id}}"
                    [disabled]="!isEditing(programFormat.id)"
                  ></toggle>
                </div>
                <button *ngIf="!isEditing(programFormat.id)" (click)="setEditingDetails(true, programFormat.id)" class="button float--right">
                  <span class="icon-edit icon__semi-medium"></span>
                  Redigera programformat
                </button>
                <div *ngIf="isEditing(programFormat.id)" class="row">
                  <div class="col-xs-4 margin-left--2x margin-top--2x">
                    <button *ngIf="isEditing(programFormat.id)" class="button primary" [disabled]="programFormatsForm.invalid" (click)="save(programFormat.id)">Spara</button>
                    <button *ngIf="isEditing(programFormat.id)" class="link" (click)="cancelEditing()">Avbryt redigering</button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
  </div>
</div>

<div #newItemProgramFormat>
  <div *ngIf="show.newProgramFormatForm" class="tabbable__body clearfix shadow-z-1">
    <div class="col-xs-12">
      <div class="tabbable__panel clearfix shadow-z-1">
          <h3>Nytt Programformat</h3>
      </div>
      <div class="tabbable__panel shadow-z-1">
        <create-new-program-format
          [newProgramFormat]="show.newProgramFormatForm"
          (onClose)="closeCard()"
          (onCreateNewProgramFormat)="addNewFormat($event)" ></create-new-program-format>
      </div>
  </div>
</div>
