import { Component } from '@angular/core';
import { WooDialogHelper } from '../../../woo_components.module/dialogs/woo-dialog-helper';
import { wooId, CreativeAsset } from '../../../woo_services.module/shared-types';

const template = /* html */ `
<woo-dialog>
  <customer-asset-list
    [customerId]="customerId"
    (onSelect)="onSelect($event)"
    (onAbort)="onAbort()"
  ></customer-asset-list>
</woo-dialog>
`;

@Component({
  selector: 'customer-asset-list-dialog',
  template: template,
})
export class CustomerAssetListDialog extends WooDialogHelper {
  customerId: string;
  onSelect: (asset: CreativeAsset) => void;
  onAbort: () => void;

  show(customerId: wooId): Promise<CreativeAsset> {
    this.customerId = customerId;
    return new Promise((resolve, reject) => {
      this.onSelect = (asset: CreativeAsset) => {
        this.close();
        resolve(asset);
      };
      this.onAbort = () => {
        this.close();
        reject();
      };
      this.open();
    });
  }
}
