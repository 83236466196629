<div class="tabbable__panel panel-margin right section info clearfix">
  <div class="col-xs-12">
    <h2>
      Statistik
    </h2>
  </div>
</div>

<div class="background-color--white tabbable__body margin-bottom--3x padding--2x">
  <campaign-outcome
    [campaign]="campaign"
    [tracking]="tracking"
  ></campaign-outcome>
</div>

  <div
    *ngFor="let targeting of campaign?.targetings"
    class="background-color--white tabbable__body margin-bottom--1x padding--3x"
  >
    <targeting-outcome
      [targeting]="targeting"
      [tracking]="targetingTrackings[targeting.id]"
    ></targeting-outcome>
  </div>