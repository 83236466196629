import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SimpleChanges } from '../../../utils/types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'calender-heading',
  template: `
    <span class="change-month-button float--right right-button" (click)="nextClick.emit()">
      <span class="icon-TV4_008_fram"></span>
    </span>
    <span class="change-month-button float--left left-button" (click)="prevClick.emit()">
      <span class="icon-TV4_009_bak"></span>
    </span>
    <span class="float--clear"></span>
    <span class="width--100">{{ dateName }}</span>
  `,
  styleUrls: ['./calender-heading.component.scss'],
})
export class CalenderHeading implements OnChanges {
  @Input() date: Date;
  @Output() prevClick = new EventEmitter<void>();
  @Output() nextClick = new EventEmitter<void>();

  dateName = '';

  constructor(private translationService: TranslationService) {}

  ngOnChanges(changes: SimpleChanges<CalenderHeading>): void {
    if (changes.date && this.date) {
      const monthName = this.translationService.convertDate(this.date).month.full;
      this.dateName = `${monthName} ${this.date.getFullYear()}`;
    }
  }
}
