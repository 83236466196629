import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[fixedWidth]',
})
export class FixedWidth implements OnInit {
  @Input() ref: string;
  unlisten: () => void;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.fixSize();
    this.unlisten = this.renderer.listen('window', 'resize', () => {
      this.fixSize();
    });
  }

  fixSize(): void {
    const refOffset = document.getElementsByClassName(this.ref)?.item(0)?.getBoundingClientRect();
    const targetOffset = this.el.nativeElement.getBoundingClientRect();

    if (targetOffset && refOffset?.left > 0) {
      const newWidth = `${refOffset.left - targetOffset.left}px`;
      this.renderer.setStyle(this.el.nativeElement, 'width', newWidth);
    }
  }
  ngOnDestroy(): void {
    this.unlisten();
  }
}
