import { Component, Input, OnChanges } from '@angular/core';
import { uniq } from '../../../utils/array';
import { generateId } from '../../../utils/string';
import { ProductFormatService } from '../../../woo_services.module/ProductFormatService';
import {
  Creative,
  GeoTargeting,
  ProductFormats,
  Targeting,
  TargetingMetaData,
  TargetingType,
  wooId,
} from '../../../woo_services.module/shared-types';
import { TargetingCombinationRulesService } from '../../../woo_services.module/TargetingCombinationRulesService';
import { VALID_VIDEO_LENGTHS } from '../../services/BookingValidationService';
import { CachedProductFormatService } from '../../services/CachedProductFormatService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'product-format-select',
  templateUrl: './product-format-select.component.html',
})
export class ProductFormatSelect extends SubscriptionHandler implements OnChanges {
  readonly instanceId = generateId();

  @Input() targetingId: wooId;
  ProductFormats = ProductFormats;
  selectedProductFormat: ProductFormats;
  showLongForm = false;
  showShortForm = false;
  targetingMetaData: TargetingMetaData;

  targetingIsRegionalAndPause = false;
  targetingMissShortCreativeAndCombination = false;
  longCreativeExistOnTargeting = false;
  includesLinear = false;
  productFormatMessage: ProductFormatMessage[];

  disableLongform: (targeingId: wooId) => boolean;
  disableShortform: (targeingId: wooId) => boolean;
  disableCombination: (targeingId: wooId) => boolean;

  constructor(
    private bookingStore: BookingStore,
    private cachedProductFormatService: CachedProductFormatService,
    private productFormatService: ProductFormatService,
    private targetingCombinationRulesService: TargetingCombinationRulesService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
    this.disableLongform = this.targetingCombinationRulesService.disableLongform;
    this.disableShortform = this.targetingCombinationRulesService.disableShortform;
    this.disableCombination = this.targetingCombinationRulesService.disableCombination;
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
    const targeting = this.bookingStore.state.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.readProductFormats(targeting);
    }
  }

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.targetingMetaData = model.targetingMetaData[targeting.id];
      this.selectedProductFormat = this.productFormatService.convertProductFormatToEnum(targeting.product_formats);
      this.includesLinear = targeting.includes_linear;
      this.targetingIsRegionalAndPause =
        this.targetingMetaData.targetingType === TargetingType.pause &&
        this.targetingMetaData.geoTargeting === GeoTargeting.regional;
      this.targetingMissShortCreativeAndCombination =
        this.missingShortCreative(targeting.creatives) && this.productFormatService.targetingIsCombination(targeting);

      this.longCreativeExistOnTargeting = this.longCreativeExist(targeting.creatives);
      this.productFormatMessage = this.getProductFormatMessage();
    }
  };

  updateProductFormat = (): void => {
    const convertSelectedProductFormat = this.productFormatService.convertProductFormatToArray(
      this.selectedProductFormat,
    );
    this.bookingStore.setProductFormat(this.targetingId, convertSelectedProductFormat);
  };

  private readProductFormats = (targeting: Targeting): void => {
    const enabledPublisherIds = targeting.publishers.map((publishers) => publishers.publisher_id);
    this.cachedProductFormatService
      .getProductFormats()
      .then((listOfProductFormats) => {
        return enabledPublisherIds.reduce((allFormats, publisherId) => {
          const publisherProductFormats = listOfProductFormats.filter((pf) => pf.publisher_id === publisherId);
          return uniq(allFormats.concat(publisherProductFormats.map((pf) => pf.format)));
        }, []);
      })
      .catch(() => null)
      .then((activeProductFormats) => {
        this.showShortForm = activeProductFormats.includes(ProductFormats.shortForm);
        this.showLongForm = activeProductFormats.includes(ProductFormats.longForm);

        if (activeProductFormats && activeProductFormats.length < 2) {
          this.selectedProductFormat = activeProductFormats.includes(ProductFormats.longForm)
            ? ProductFormats.longForm
            : ProductFormats.shortForm;
          this.updateProductFormat();
        }
      });
  };

  private getProductFormatMessage = (): ProductFormatMessage[] | null => {
    const messages = [];
    if (this.disableShortform(this.targetingId) && this.targetingIsRegionalAndPause) {
      messages.push({ message: 'Online Video går inte boka med regionstyrning' });
    }
    if (this.targetingMissShortCreativeAndCombination) {
      messages.push({
        message:
          'Observera att om det inte finns en 20 sek film eller kortare så kommer kampanjen endast att visas på Adresserbar TV.',
        warningMessage: true,
      });
    }
    if (this.longCreativeExistOnTargeting) {
      messages.push({ message: 'Online Video går endast boka med 5-20 sekunders filmer' });
    }
    if (this.includesLinear) {
      messages.push({ message: 'Regionalpaketering går endast boka med Adresserbar TV' });
    }
    return messages ? messages : null;
  };

  private longCreativeExist = (creatives: Creative[]): boolean => {
    return creatives.some((c) => c.length > VALID_VIDEO_LENGTHS.maxLimitShortFormat);
  };

  private missingShortCreative = (creatives: Creative[]): boolean => {
    if (creatives.length) {
      return !creatives.some((c) => c.length <= VALID_VIDEO_LENGTHS.maxLimitShortFormat);
    } else {
      return false;
    }
  };
}

interface ProductFormatMessage {
  message: string;
  warningMessage?: boolean;
}
