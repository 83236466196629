<div class="site full-height" [ngClass]="{'nav-open': menuOpened}">
  <div class="navigation scroll--x">
    <div class="nav-item--login" *ngIf="!isLoggedIn">
      <login-form [menuOpened]="menuOpened"></login-form>
    </div>
    <div *ngIf="isLoggedIn">
      <div class="nav-item visible-xs">
        <a (click)="toggleNav()"><span class="icon-close-x"></span> Stäng meny</a>
      </div>
      <a tabindex="-1" [wooFocus]="menuOpened"></a>
      <div class="nav-item" [routerLinkActive]="['active']">
        <a [routerLink]="['/start']" [attr.tabindex]="menuOpened ? '110' : '-1'" >Vad är WOO.ad?</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']"
        *ngIf="isAdminOrPlanner || ((allowedToBook || isSalesManager) && !isCashUser)">
        <a
          id="book-campaign"
          [routerLink]="['/campaigns', 'new']"
          [attr.tabindex]="menuOpened ? '130' : '-1'">Boka
          kampanj</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="allowedToBook && isCashUser">
        <a id="book-campaign" [routerLink]="['/customer', idOfCurrentUsersCashCustomer(), 'campaign']" [attr.tabindex]="menuOpened ? '120' : '-1'">Boka kampanj</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="(allowedToBook || isAccountingOrSales) && !isCashUser">
        <a [routerLink]="['/dashboard']" [attr.tabindex]="menuOpened ? '150' : '-1'">Mina kampanjer</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="allowedToBook && isCashUser">
          <a [routerLink]="['/customer', idOfCurrentUsersCashCustomer(), 'dashboard']" [attr.tabindex]="menuOpened ? '150' : '-1'">Mina kampanjer</a>
        </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdminOrPlanner">
        <a [routerLink]="['/select_customer_to_view_assets_for']"  [attr.tabindex]="menuOpened ? '160' : '-1'">Material</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeInventory">
        <a [routerLink]="['/inventory']" [attr.tabindex]="menuOpened ? '170' : '-1'">Lager</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeExternalCampaigns">
        <a [routerLink]="['/external_campaigns']" [attr.tabindex]="menuOpened ? '180' : '-1'">Externa kampanjer</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeImportEstimates">
        <a [routerLink]="['/import']" [attr.tabindex]="menuOpened ? '190' : '-1'">Importera estimat</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeProducts">
        <a [routerLink]="['/products']" [attr.tabindex]="menuOpened ? '200' : '-1'">Produkthantering</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
        <a [routerLink]="['/statistics']" [attr.tabindex]="menuOpened ? '205' : '-1'">Statistik</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeAccounts">
        <a [routerLink]="['/accounts']" [attr.tabindex]="menuOpened ? '210' : '-1'">Kontohantering</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeEos">
        <a [routerLink]="['/eos']" [attr.tabindex]="menuOpened ? '220' : '-1'">EOS API</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="!isCashUser">
        <a [routerLink]="['/pricelist']" [attr.tabindex]="menuOpened ? '230' : '-1'">Prislista</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="!isExternalPlanner && !isCashUser">
        <a [routerLink]="['/buylist']" [attr.tabindex]="menuOpened ? '240' : '-1'">Köplista TV4</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdminOrAccounting">
        <a [routerLink]="['/invoice_overview']" [attr.tabindex]="menuOpened ? '250' : '-1'">Fakturaöversikt</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeAgencyOverview">
        <a [routerLink]="['/agency_overview']" [attr.tabindex]="menuOpened ? '260' : '-1'">Byråöversikt</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdminOrAccounting">
        <a [routerLink]="['/revenue_reports']" [attr.tabindex]="menuOpened ? '270' : '-1'">Intäktsfiler</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeSelfServiceCompensation">
        <a [routerLink]="['/self_service_compensation']" [attr.tabindex]="menuOpened ? '280' : '-1'">Administrationsersättning</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdminOrPlanner">
        <a [routerLink]="['/file_upload']" [attr.tabindex]="menuOpened ? '290' : '-1'">Filuppladdning</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']">
        <a [routerLink]="['/system_messages']" [attr.tabindex]="menuOpened ? '300' : '-1'">Systemmeddelanden</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']">
        <a [routerLink]="['/my-account']" [attr.tabindex]="menuOpened ? '310' : '-1'">Mitt konto</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
        <a [routerLink]="['/stylesheet']" [attr.tabindex]="menuOpened ? '320' : '-1'">Stilmall</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="!isCashUser">
        <a [routerLink]="['/help']" [attr.tabindex]="menuOpened ? '330' : '-1'">Hjälp</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
        <a [routerLink]="['/system']" [attr.tabindex]="menuOpened ? '340' : '-1'">Systeminställningar</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="maySeeStatus">
        <a [routerLink]="['/status']" [attr.tabindex]="menuOpened ? '350' : '-1'">Status</a>
      </div>
      <div class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdminOrPlanner">
        <a [routerLink]="['/new_ttv_booking']" [attr.tabindex]="menuOpened ? '360' : '-1'">Ny TTV-Kampanj</a>
      </div>
      <div class="nav-item">
        <a id="logout-button" (click)="logout()" [attr.tabindex]="menuOpened ? '370' : '-1'">Logga ut</a>
      </div>
    </div>
  </div>

  <nav class="navbar navbar-default sticky-top shadow-z-1">
    <div id="toggle-navigation" class="center-align-navbar">
      <div class="content__container">
        <ul class="nav-list">
          <li>
            <button id="menu" class="toggle-navigation margin-right--5x" (click)="toggleNav()" [attr.tabindex]="menuOpened ? '90' : '-1'">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar top" [ngClass]="{'open': menuOpened}"></span>
              <span class="icon-bar middle" [ngClass]="{'open': menuOpened}"></span>
              <span class="icon-bar bottom" [ngClass]="{'open': menuOpened}"></span>
            </button>
          </li>
          <li>
            <a class="navbar-logo" [routerLink]="['/']"></a>
          </li>
          <li>
            <h3 class="text--dark-gray header-text">Woo Manager</h3>
          </li>
        </ul>

        <div class="float-right">
          <button class="button secondary text-uppercase text--thin margin-top--none hidden-xs" *ngIf="!isLoggedIn" (click)="toggleNav()" tabindex="20">Logga in</button>
        </div>

        <div class="float-right">
          <button (click)="gotoAnchor('signup')" class="button primary-1 text-uppercase text--thin margin-top--none" *ngIf="showGetStartedButton()" tabindex="10">Kom igång</button>
          <a id="my-account-button" class="link primary-2 margin-right--3x" [routerLink]="['/my-account']" *ngIf="isLoggedIn" tabindex="-1">
            <span class="icon-User"></span> {{currentUser?.first_name}} {{currentUser?.last_name}}
          </a>
          <a
            href="https://teliatv4media.atlassian.net/jira/core/projects/WOM/form/8"
            target="_blank"
            class="link primary-2 margin-right--3x"
            *ngIf="isLoggedIn && maySeeImprovementSuggestions"
            tabindex="-1">
            <span class="icon-open-new-window"></span> Förbättringsförslag
          </a>
          <a (click)="feedback()" class="link primary-2" *ngIf="isLoggedIn" tabindex="-1">Support</a>
        </div>
      </div>
    </div>
  </nav>

  <global-messages [menuOpened]="menuOpened" [systemMessages]="systemMessages"
    [cookieBannerDismissed]="cookieBannerDismissed">
  </global-messages>

  <div class="site-wrapper" id="site-wrapper" [ngClass]="{'nav-open': menuOpened, 'no-scroll': mode.extended}" (click)="menuOpened ? toggleNav() : null">
    <div class="content__container">
      <router-outlet></router-outlet>
    </div>
    <router-outlet name="mainFull"></router-outlet>
  </div>
  <woo-dialog-container></woo-dialog-container>
</div>
