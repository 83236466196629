<div class="row">
  <div class="col-xs-12">

    <h3 *ngIf="invoiceType === InvoiceType.Debit">Skapa debetfaktura</h3>
    <h3 *ngIf="invoiceType === InvoiceType.Credit">Skapa kreditfaktura</h3>
    <div>
      <form [formGroup]="form" (ngSubmit)="form.valid ? submit() : setFormDirty(form)">

        <div class="row">
          <div class="col-md-12">
            <div class="input__container">
              <span class="input__optional__text">Frivillig</span>
              <label for="credit-invoice-message">Meddelande</label>
              <input id="credit-invoice-message" name="message" type="text" formControlName="description" maxlength="60">
            </div>
          </div>
        </div>

        <div class="row">
          <div *ngIf="invoiceType === InvoiceType.Credit" class="col-md-6">
            <div class="input__container">
              <label for="credit-invoice-total-gross-excluding-agency-discount">Belopp exklusive moms före mediebyråersättning *</label>
              <input id="credit-invoice-total-gross-excluding-agency-discount" name="creditTotalGrossExcludingAgencyDiscount" type="number" step="1" max="0" required formControlName="total_gross_excluding_agency_discount">
            </div>
          </div>

          <div *ngIf="invoiceType === InvoiceType.Debit" class="col-md-6">
            <div class="input__container">
              <label for="debit-invoice-total-gross-excluding-agency-discount">Belopp exklusive moms före mediebyråersättning *</label>
              <input id="debit-invoice-total-gross-excluding-agency-discount" name="debitTotalGrossExcludingAgencyDiscount" type="number" step="1" min="0" required formControlName="total_gross_excluding_agency_discount" >
            </div>
          </div>
          <div *ngIf="agencyDiscount" class="col-md-6 padding-top--3x">
            <p>
              Mediebyråersättning (med dagens nivå, {{agencyDiscount | percent:'1.0-2'}}):
              {{form.value.total_gross_excluding_agency_discount * agencyDiscount | currency:'SEK' }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="input__container">
              <label for="date">Fakturadatum *</label>
              <input id="date" type="date" name="invoiceDate" formControlName="invoice_date" min="{{minInvoiceDateString}}" required>
            </div>
          </div>

          <div *ngIf="invoiceType === InvoiceType.Credit" class="col-md-6">
            <div class="input__container">
              <label for="credit-invoice-original-invoice-number">Originalfakturanummer</label>
              <input id="credit-invoice-original-invoice-number" name="originalInvoiceNumber" type="number" formControlName="original_invoice">
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-12">
            <div class="input__container">
              <label for="credit-invoice-internal-message">Internt meddelande (kommer ej på fakturan)</label>
              <textarea id="credit-invoice-internal-message" name="internalMessage" [required]="false" formControlName="internal_message" maxlength="240"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <button class="button primary-1" type="submit">Skapa</button>
            <button class="link primary-1" (click)="onAbort.emit()">Avbryt</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
