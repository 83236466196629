import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { add, getMaxPeriod, isDateBefore, sameDate, TimePeriod } from '../../../utils/date';
import { SimpleChanges } from '../../../utils/types';
import { InitialDate } from '../../../woo_components.module/date_selectors/shared/types';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { Campaign, Targeting } from '../../../woo_services.module/shared-types';
import { SystemService } from '../../../woo_services.module/SystemService';

@Component({
  selector: 'extend-campaign',
  templateUrl: './extend-campaign.component.html',
})
export class ExtendCampaign implements OnChanges {
  @Input() campaign: Campaign;
  @Output() campaignUpdated = new EventEmitter<Campaign>();
  @Output() onAbort = new EventEmitter<void>();

  readonly InitialDate = InitialDate;
  targetingData: ExtendTargetingData[];
  maxNewEndDate: Date;

  disabled: boolean;

  constructor(
    private campaignService: CampaignService,
    private dialogService: DialogService,
    private systemService: SystemService,
  ) {}

  ngOnChanges(changes: SimpleChanges<ExtendCampaign>): void {
    if (changes.campaign && this.campaign) {
      this.targetingData = this.campaign.targetings.map((t) => {
        const targetingEndDate = new Date(t.end_date);
        return {
          targeting: t,
          newEndDate: new Date(t.end_date),
          minEndDate: isDateBefore(targetingEndDate, new Date()) ? new Date() : targetingEndDate,
          maxNewEndDate: this.setNewMaxDate(t),
        };
      });
    }
  }

  async updateCampaign(): Promise<void> {
    this.dialogService
      .openConfirm({
        header: 'Redigering av startad kampanj',
        textBlocks: [
          'Du försöker nu att uppdatera en startad kampanj i WOO Manager. Detta innebär att att den kommer att skickas till adservern automatiskt om du godkänner nedan.',
          'Vill du genomföra ändringarna på kampanjen?',
        ],
        confirmText: 'Ja, uppdatera!',
        cancelText: 'Nej, jag ångrar mig',
      })
      .then(() => {
        this.dialogService.openBlocking('Uppdaterar kampanj');
        const allUpdates = [];
        this.targetingData.forEach((data) => {
          if (!sameDate(data.newEndDate, data.targeting.end_date)) {
            allUpdates.push(this.campaignService.extendTargeting(this.campaign.id, data.targeting.id, data.newEndDate));
          }
        });
        if (allUpdates.length < 1) {
          this.dialogService.openSuccess('Det här gick lättare än förväntat, inga ändringar att spara :)');
          this.dialogService.closeBlocking();
        } else {
          Promise.all(allUpdates)
            .then((results) => {
              this.dialogService.openSuccess('Din kampanj är nu uppdaterad.');
              this.campaignUpdated.emit(results[-1]);
            })
            .finally(() => {
              this.dialogService.closeBlocking();
            });
        }
      });
  }

  abort(): void {
    this.onAbort.emit();
  }

  setEndDate(newEndDate: Date, targetingData: ExtendTargetingData): void {
    targetingData.newEndDate = newEndDate;
    this.validateDates();
  }

  private setNewMaxDate(targeting: Targeting): Date {
    const breakDate = new Date('02 January 2023, 00:00:00');
    if (targeting.includes_linear && new Date(targeting.start_date).getTime() < breakDate.getTime()) {
      return breakDate;
    } else {
      return add(
        getMaxPeriod(this.campaign.targetings).end,
        this.systemService.variables.day_to_expand_a_campaign_targeting,
        TimePeriod.Day,
      );
    }
  }

  private validateDates(): void {
    this.disabled = this.targetingData.some((td) => !td.newEndDate);
  }
}

interface ExtendTargetingData {
  targeting: Targeting;
  newEndDate: Date;
  minEndDate: Date;
  maxNewEndDate: Date;
}
