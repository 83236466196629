<div>
  <div class="tabbable__panel panel-margin right section info clearfix">
    <div class="col-xs-12">
      <h2>Material</h2>
    </div>
  </div>

  <div class="tabbable__body clearfix" *ngIf="!notAllowedToEditCreativesReason">
    <p *ngIf="!adminOrPlanner">
      Allt material (film, URL och/eller tredjeparts-tracking) ska vara inlagd på kampanjen senast <b>{{numberOfDaysToEditForAgency}} arbetsdagar</b> innan första utsändningstillfälle. Har material inte inkommit i tid garanteras ej reklamutsändning och TV4 äger rätten att räkna ner antal CV/impressions motsvarande utebliven reklamtid samt rätten att debitera en förseningsavgift. Saknas information om URL/landningssida länkar vi till startsidan på annonsörens hemsida tillsvidare. Vid eventuella problem att addera materialet på kampanjen maila till reklamplanerare.digitalt&#64;tv4.se</p>
    <p *ngIf="adminOrPlanner">
      Kampanjens material går att redigera fram till kampanjslut.</p>
    <edit-button
      class="margin-top--2x"
      (click)="startEditCreatives()"
      *ngIf="!editCreatives"
      textOverride="Redigera allt material"
    ></edit-button>
    <div class="margin-top--2x" *ngIf="editCreatives">
      <button class="button" (click)="saveEditCreatives()" [disabled]="!canSave">{{saveButtonText}}</button>
      <button class="button secondary" (click)="cancelEditCreatives()">Avbryt</button>
    </div>
  </div>

  <div class="tabbable__body clearfix" *ngIf="convertEditCreativesCriterium(notAllowedToEditCreativesReason)">
    <p >
      {{convertEditCreativesCriterium(notAllowedToEditCreativesReason)}}</p>
  </div>

  <div class="tabbable__body padding--3x background-color--white margin-top--3x" *ngFor="let targeting of targetings">
    <expandable-targeting-header
      [targeting]="targeting"
      [expanded]="getTargetingExpandedFromStore(targeting.id)"
      (expandedChange)="onExpandedChange($event, targeting.id)"
    >
      <ng-container *ngFor="let creative of targeting.creatives; let last = last">
        <creative-details [creativeId]="creative.id" [targetingId]="targeting.id"></creative-details>
        <div *ngIf="!last" class="divider margin-top-bottom--4x"></div>
      </ng-container>
    </expandable-targeting-header>
  </div>

  <div class="margin-top--2x" *ngIf="editCreatives && !notAllowedToEditCreativesReason">
    <button class="button" (click)="saveEditCreatives()" [disabled]="!canSave">{{saveButtonText}}</button>
    <button class="button secondary" (click)="cancelEditCreatives()">Avbryt</button>
  </div>
</div>
