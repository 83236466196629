import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { WooComponentsModule } from '../woo_components.module/module';
import { AvailableIconsStyle } from './components/icons/available-icons-style-component';
import { StyleSheet } from './components/style_sheet/style-sheet.component';
import { LoadButtonStyles } from './components/woo_load_button_styles/load-button-styles.component';

@NgModule({
  imports: [BrowserModule, FormsModule, WooComponentsModule],
  providers: [],
  declarations: [LoadButtonStyles, StyleSheet, AvailableIconsStyle],
  exports: [LoadButtonStyles],
})
export class WooStyleSheetModule {}
