import { Injectable } from '@angular/core';
import { CodeErrors, CodeService } from '../shared-types';
import { VideoCodeService } from './VideoCodeService';

@Injectable({ providedIn: 'root' })
export class AdstreamService extends VideoCodeService implements CodeService {
  protected readonly prefix = '51';
  protected readonly lengthIndexes = { start: 6, end: 7 };
  protected readonly adstreamCodeLength = { min: 10, max: 11 };

  /**
   * Validates adstream codes with the explicit format
   *  51   – Prefix, always '51'
   *  XXXX – Unknown
   *  10   – Film length in seconds, 0-99
   *  XX   – Unknown    or    XXX   - Unknown (extra character since 22/01/03)
   */
  validate = (code: string): CodeErrors => {
    const errors = {} as CodeErrors;
    errors.prefix = this.prefixError(code, this.prefix);
    errors.codeLength = this.codeLengthError(code, this.adstreamCodeLength.min, this.adstreamCodeLength.max);
    errors.whiteSpace = this.whiteSpaceError(code);
    errors.filmLength = this.filmLengthError(
      code,
      this.creativeService.getCreativeLengths(),
      this.lengthIndexes.start,
      this.lengthIndexes.end,
    );
    return this.removeBlankKeys(errors);
  };

  getFilmLength(code: string): number | null {
    const canReadLengthFromCode = code?.length > this.lengthIndexes.end;
    if (!code || !canReadLengthFromCode) {
      return null;
    }
    return this.getVideoCodeFilmLength(code, this.lengthIndexes.start, this.lengthIndexes.end);
  }
}
