import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserRole } from '../utils/user-roles';
import { EnvironmentService } from './EnvironmentService';
import { wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SystemMessageService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getAll(): Promise<SystemMessage[]> {
    return lastValueFrom(this.http.get<SystemMessage[]>(`${this.env.apiUrl}/system_messages`));
  }

  create(message: SystemMessageParams): Promise<SystemMessage> {
    return lastValueFrom(
      this.http.post<SystemMessage>(`${this.env.apiUrl}/system_messages`, {
        system_message: message,
      }),
    );
  }

  remove(id: wooId): Promise<void> {
    return lastValueFrom(this.http.delete<void>(`${this.env.apiUrl}/system_messages/${id}`));
  }

  dismiss(id: wooId): Promise<SystemMessage> {
    return lastValueFrom(this.http.patch<SystemMessage>(`${this.env.apiUrl}/system_messages/${id}/dismiss`, null));
  }
}

export interface SystemMessage {
  id: wooId;
  dismissed: boolean;
  created_at: string;
  message: string;
  type: 'info' | 'warning';
  roles: UserRole[];
}

export type SystemMessageParams = Pick<SystemMessage, 'message' | 'type' | 'roles'>;
