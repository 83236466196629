import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AdvancedTargetGroup,
  AdvancedTargetGroupCategory,
  AdvancedTargetGroupService,
} from '../../../../woo_services.module/AdvancedTargetGroupService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { ProductFormat, Publisher, SlotType, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'edit-advanced-target-groups',
  templateUrl: './edit-advanced-target-groups.component.html',
})
export class EditAdvancedTargetGroup implements OnInit {
  @Input() categories: AdvancedTargetGroupCategory[];
  @Output() onChanges = new EventEmitter<void>();
  readonly staticFields = 2;
  readonly tableHeaders = [
    'Publicist',
    'Placering',
    'Preroll',
    'Midroll',
    'Postroll',
    'Preroll',
    'Midroll',
    'Postroll',
  ];

  isEditing = false;
  advancedTargetGroup: AdvancedTargetGroup;
  advancedTargetGroups: AdvancedTargetGroup[];
  publishers: Publisher[] = [];
  productFormats: ProductFormat[];
  inputRows: InputRow[] = [];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  loading = true;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private advancedTargetGroupService: AdvancedTargetGroupService,
    private utilityService: ProductsPageUtilityService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.advancedTargetGroupService
      .getTargetGroup(this.routingService.getParam(this.route, 'id'))
      .then((advancedTargetGroup) => {
        this.advancedTargetGroup = advancedTargetGroup;
      })
      .then(() => {
        this.publisherService.getPublishers(ExtraPublisherField.enabled).then((publishers) => {
          this.publishers = publishers.filter((p) => p.enabled);
        });
      })
      .then(() => {
        this.productFormatService
          .getProductFormats(ExtraProductFormatField.slotTypes, ExtraProductFormatField.enabled)
          .then((productFormats) => {
            this.productFormats = productFormats.filter((f) => f.enabled);
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
            this.buildInputRows();
          });
      })
      .finally(() => (this.loading = false));
  }

  abort(): void {
    this.routingService.navigate(['/products', 'advanced_target_groups']);
  }

  async updateAdvancedTargetGroup(): Promise<void> {
    this.advancedTargetGroupService.updateTargetGroup(this.advancedTargetGroup).then(() => {
      this.routingService.navigate(['/products', 'advanced_target_groups'], {
        state: { deviceGroupEstimatesChanged: true },
      });
    });
  }

  translateProductFormat(product: string): string {
    return this.translationService.convertProductFormat(product);
  }

  private buildInputRows(): void {
    let inputRow: InputRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        inputRow = [
          ...inputRow,
          {
            productFormat: productFormat.name,
            productFormatId: productFormat.id,
            publisherId: publisher.id,
            publisher: publisher.name,
            sharesPreroll: this.advancedTargetGroup.shares[publisher.id][productFormat.id].preroll,
            sharesMidroll: this.advancedTargetGroup.shares[publisher.id][productFormat.id].midroll,
            sharesPostroll: this.advancedTargetGroup.shares[publisher.id][productFormat.id].postroll,
            withdrawalLimitsPreroll: this.advancedTargetGroup.withdrawal_limits[publisher.id][productFormat.id].preroll,
            withdrawalLimitsMidroll: this.advancedTargetGroup.withdrawal_limits[publisher.id][productFormat.id].midroll,
            withdrawalLimitsPostroll: this.advancedTargetGroup.withdrawal_limits[publisher.id][productFormat.id]
              .postroll,
          },
        ];
      });
    });
    inputRow.sort((a, b) => (a.publisher > b.publisher ? 0 : 1));
    this.inputRows = inputRow;
  }
}

interface InputRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
