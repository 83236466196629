<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera maxuttag för dayparts</h3>
</div>

<div>
  <div #stickyHeader>
  <div class="tabbable__body">
    <import-warning *ngIf="show.withdrawalLimitsHaveChanged"></import-warning>
    <dot-loader [visibility]="show.updatingEstimates"></dot-loader>
    <div class="button-filter-container">
      <div>
        <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="daypartWithdrawalLimits.invalid">Spara</button>
        <button *ngIf="!show.isEditing" class="button primary" (click)="setEditing(true)">Redigera maxuttag</button>
        <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
      </div>
      <div class="display--flex">
        <div class="filter-dropdown">
          <woo-dropdown
          [items]="dayparts"
          [(ngModel)]="selectedDaypart"
          name="selectedDaypart"
          inputId="select-daypart"
          labelText="Filtrera på daypart"
          notFoundText="Inga dayparts hittades."
          [clearable]="true"></woo-dropdown>
        </div>
          <div *ngIf="productFormats && productFormats.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="productFormats"
            [(ngModel)]="selectedProductFormat"
            name="selectedProductFormat"
            inputId="select-product-format"
            labelText="Filtrera på Placering"
            [labelTransform]="translateProductFormat"
            notFoundText="Inga Placeringar hittades."
            [clearable]="true"></woo-dropdown>
          </div>
        <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
          <woo-dropdown
          [items]="publishers"
          [(ngModel)]="selectedPublisher"
          name="selectedPublisher"
          inputId="select-publisher"
          labelText="Filtrera på Publicist"
          notFoundText="Inga publicister hittades."
          [clearable]="true"></woo-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="tabbable__panel clearfix shadow-z-1">
    <h3 class="margin-left--3x">Tillgängliga dayparts</h3>
  </div>
  </div>

  <div class="tabbable__body">
    <table class="table table-woo table--info table--hover width--100" ngForm #daypartWithdrawalLimits="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-centered" colspan="3">
            Maxuttag
          </th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>

      <tbody *ngIf="!show.loading">
        <ng-container *ngFor="let row of daypartsRows; let index = index;">
        <tr [hidden]="!rowVisible(row)">
          <td class="text--thin vertical-align--middle">
            <span>{{row.publisher}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{translateProductFormat(row.productFormat)}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{row.daypart}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{row.daypartDescription}}</span>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPreroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPreroll"
                [(ngModel)]="getDaypartWithdrawalLimits(row.productFormatId)[row.daypartId].preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsMidroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesMidroll"
                [(ngModel)]="getDaypartWithdrawalLimits(row.productFormatId)[row.daypartId].midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPostroll"
                [(ngModel)]="getDaypartWithdrawalLimits(row.productFormatId)[row.daypartId].postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>

    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
      <button class="button primary" (click)="save()" [disabled]="daypartWithdrawalLimits.invalid">Spara</button>
      <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>
