import { Component } from '@angular/core';
import { uniq } from '../../../utils/array';
import { BookingModel, BookingStep, BookingStore, BOOKING_STEPS_ORDER } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'snake-nav',
  templateUrl: './snake-nav.component.html',
  styleUrls: ['./snake-nav.component.scss'],
})
export class SnakeNav extends SubscriptionHandler {
  BookingStep = BookingStep;
  activeBookingStep = BookingStep.details;
  bookingStepsOrder: BookingStep[] = BOOKING_STEPS_ORDER;
  invalidVisitedBookingsteps = [];
  validVisitedBookingsteps = [];
  disabledBookingsteps = [];
  indicatorStart = 0;
  indicatorWidth = 0;
  validBookingsteps = [];
  constructor(private bookingStore: BookingStore) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  initFromStore = (model: BookingModel): void => {
    this.activeBookingStep = model.activeBookingStep;
    const invalidBookingSteps = uniq(model.validationMessages.map((message) => message.originStep));
    this.validBookingsteps = [BookingStep.details, BookingStep.targeting, BookingStep.distribution];
    this.validBookingsteps = this.validBookingsteps.filter((step) => !invalidBookingSteps.includes(step));
    this.invalidVisitedBookingsteps = model.dirtySteps.filter(
      (step) => invalidBookingSteps.includes(step) && this.activeBookingStep !== step,
    );
    this.validVisitedBookingsteps = model.dirtySteps.filter(
      (step) => !invalidBookingSteps.includes(step) && this.activeBookingStep !== step,
    );
    this.disabledBookingsteps = model.disabledSteps;
    const enabledBookingSteps = this.bookingStepsOrder.filter((step) => !this.disabledBookingsteps.includes(step));

    const activeBookingStepIndexInEnabledBookingSteps = enabledBookingSteps.findIndex(
      (step) => step === this.activeBookingStep,
    );
    const firstEnabledBookingStepIndex = this.bookingStepsOrder.findIndex((step) => step === enabledBookingSteps[0]);
    this.indicatorStart = (firstEnabledBookingStepIndex / (this.bookingStepsOrder.length - 1)) * 100;
    this.indicatorWidth = Math.max(
      0,
      (activeBookingStepIndexInEnabledBookingSteps / (enabledBookingSteps.length - 1)) * 100 - this.indicatorStart,
    );
  };

  stepInvalid(step: BookingStep): boolean {
    return this.invalidVisitedBookingsteps.includes(step);
  }

  stepValidAndVisited(step: BookingStep): boolean {
    return this.validVisitedBookingsteps.includes(step);
  }

  stepValid(step: BookingStep): boolean {
    return this.validBookingsteps.includes(step);
  }

  stepDisabled(step: BookingStep): boolean {
    return this.disabledBookingsteps.includes(step);
  }

  updateActiveBookingStep(step: BookingStep): void {
    if (this.stepDisabled(step)) {
      return;
    }
    this.bookingStore.setActiveBookingStep(step);
  }

  convertBookingStep(step: BookingStep): string | BookingStep {
    const map = new Map([
      [this.BookingStep.details, 'Kampanj'],
      [this.BookingStep.targeting, 'Styrning'],
      [this.BookingStep.distribution, 'Material & Fördelning'],
      [this.BookingStep.summary, 'Summering'],
    ]);
    return map.get(step) || step;
  }
}
