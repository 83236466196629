import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { verticalExpansion } from '../../utils/animations';
import { saveFileAs } from '../../utils/files';
import { UserRole } from '../../utils/user-roles';
import { AuthService } from '../../woo_services.module/AuthService';
import { DialogService } from '../../woo_services.module/DialogService';
import { JobService } from '../../woo_services.module/JobService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import {
  EstimateImport,
  EstimateImportService,
  EstimateImportStatus,
  EstimateImportType,
  EstimateInitState,
  ValidationResult,
} from '../services/EstimateImportService';
import { EstimateValidationErrorService } from '../services/EstimateValidationErrorService';
import { EstimatesList } from './estimates-list.component';

enum ImportOption {
  new = 'new',
  duplicate = 'duplicate',
}

@Component({
  selector: 'import-estimates',
  templateUrl: './import-estimates.component.html',
  animations: [verticalExpansion(250)],
})
export class ImportEstimates implements OnInit {
  @ViewChildren(EstimatesList) estimationLists: QueryList<EstimatesList>;

  upload = {
    file: null as File,
    importPastWeeks: false,
    error: '',
  };

  show = {
    validateFile: true,
    uploadFile: true,
    verifyUpload: false,
  };

  validationErrorMessages: string[] = [];
  importErrorMessage = '';

  importOption = ImportOption;
  importEstimateOption = ImportOption.duplicate;

  estimateProgressWidget = {
    show: false,
    message: '',
    progress: 0,
    progress_message: '',
  };

  isAdmin: boolean = this.authService.hasRole(UserRole.admin);
  EstimateImportType = EstimateImportType;
  validatedEstimateImport: EstimateImport[] = [];
  constructor(
    private importService: EstimateImportService,
    private authService: AuthService,
    private estimateErrorValidationService: EstimateValidationErrorService,
    private dialogService: DialogService,
    private translationService: TranslationService,
    private jobService: JobService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initView();
  }

  updateEstimateProgressWidgetProgress = (progress: number, progress_message?: string): void => {
    this.estimateProgressWidget.progress = progress;
    this.estimateProgressWidget.progress_message = this.translationService.convertProgressStates(progress_message);
  };

  validateEstimate = async (): Promise<void> => {
    this.clearUploadData();
    if (this.importEstimateOption === ImportOption.new) {
      await this.validateUploadedFile();
    } else {
      await this.duplicateLatestEstimate();
    }
  };

  validateUploadedFile = async (): Promise<void> => {
    if (!this.upload.file) {
      this.validationErrorMessages[0] = 'Ingen fil vald.';
      return;
    }

    try {
      await this.importService.upload(this.upload.file);
      await this.validate();
    } catch (error) {
      if (error.status === 412) {
        this.dialogService.openError('Det går inte ladda upp estimat fil för tillfället');
      }
      await this.initView();
    }
  };

  duplicateLatestEstimate = async (): Promise<void> => {
    try {
      const estimateImports = await this.importService.duplicateLatestEstimates();
      if (!estimateImports?.length) {
        this.validationErrorMessages[0] = 'Inga estimatfiler att importera om.';
        return;
      }

      await this.validate();
    } catch (error) {
      if (error.status === 412) {
        this.dialogService.openError('Det går inte att estimera för tillfället.');
      }
      await this.initView();
    }
  };

  importEstimates = async (): Promise<void> => {
    if (this.validatedEstimateImport.length === 0) {
      this.validationErrorMessages[0] = 'Ingen fil validerad.';
      return;
    }

    await this.importEstimate();
  };

  deleteVerified = async (): Promise<void> => {
    await this.handleEstimateState(await this.importService.deleteVerified());
  };

  noOverbooking = (): boolean => {
    for (const estimateImport of this.validatedEstimateImport) {
      if (this.isOverbooked(estimateImport.validation_result, estimateImport.type)) {
        return false;
      }
    }
    return true;
  };

  noValidationErrors = (): boolean => {
    for (const estimateImport of this.validatedEstimateImport) {
      if (this.hasErrors(estimateImport.validation_result)) {
        return false;
      }
    }
    return true;
  };

  download = async (estimate: EstimateImport): Promise<void> => {
    saveFileAs(await this.importService.download(estimate.id), estimate.name);
  };

  private async initView(): Promise<void> {
    await this.handleEstimateState(await this.importService.estimation_state());
  }

  private handleEstimateState = async (estimateState: EstimateInitState) => {
    switch (estimateState.state) {
      case EstimateImportStatus.verifying:
      case EstimateImportStatus.importing:
        await this.trackRunningProgress(estimateState);
        break;
      case EstimateImportStatus.verified:
        this.getEstimateImport(estimateState.estimate_files);
        break;
      case EstimateImportStatus.pending:
        this.show.verifyUpload = false;
        this.show.validateFile = true;
        this.show.uploadFile = true;
        break;
    }
  };

  private async trackRunningProgress(initState: EstimateInitState) {
    this.showEstimateProgressWidgetProgress(this.dialogMessage(initState));

    try {
      await this.jobFinished(
        await this.jobService.waitForJobCompletion(initState.job_id, this.updateEstimateProgressWidgetProgress),
      );
    } catch (jobResult) {
      await this.jobError(jobResult);
    } finally {
      this.estimateProgressWidget.show = false;
    }
  }

  private dialogMessage(estimateState: EstimateInitState) {
    const estimatorString = estimateState.current_estimating ? 'dig' : estimateState.user;
    const processString = estimateState.state === EstimateImportStatus.verifying ? 'verifieras' : 'importeras';
    const fileNames = estimateState.estimate_files.map((estimateFile) => estimateFile.name).join(' och ');

    return `${fileNames} ${processString} av ${estimatorString}.`;
  }

  private jobError = async (jobResult: { error_message: string; estimate_file_ids: string[] }) => {
    this.dialogService.openError(jobResult.error_message);

    return this.jobFinished(jobResult);
  };

  private jobFinished = async (jobResult: { estimate_file_ids: string[] }) => {
    const filesAll = jobResult.estimate_file_ids.map((estimateId: string) => {
      return this.importService.get(estimateId);
    });

    return this.getEstimateImport(await Promise.all(filesAll));
  };

  private clearUploadData = () => {
    this.validatedEstimateImport = [];
    this.validationErrorMessages = [];
  };

  private validate = async (): Promise<void> => {
    await this.handleEstimateState(await this.importService.validate(!this.upload.importPastWeeks));
  };

  private importEstimate = async (): Promise<void> => {
    await this.handleEstimateState(await this.importService.import(!this.upload.importPastWeeks));
  };

  private isOverbooked = (validationResult: ValidationResult, type: string) => {
    if (type === EstimateImportType.video) {
      return validationResult.video_overbookings.length > 0 || validationResult.pause_image_overbookings.length > 0;
    } else {
      return validationResult.overbookings.length > 0;
    }
  };

  private hasErrors = (validationResult: ValidationResult) => {
    return validationResult.errors.length > 0;
  };

  private reloadLists = () => this.estimationLists.forEach((component) => component.reload());

  private getEstimateImport = (estimateImports: EstimateImport[]) => {
    switch (estimateImports[0].status) {
      case EstimateImportStatus.error:
        this.validationErrorMessages = this.estimateErrorValidationService.extractingAllErrorMessages(
          estimateImports.flatMap((estimateImport: EstimateImport) => estimateImport.validation_result.errors),
        );
        this.show.verifyUpload = false;
        this.show.validateFile = true;
        break;
      case EstimateImportStatus.inventoryError:
        this.importErrorMessage = estimateImports
          .flatMap((estimateImport: EstimateImport) => estimateImport.error_message)
          .join(', ');
        this.show.verifyUpload = true;
        this.show.validateFile = false;
        break;
      case EstimateImportStatus.verified:
        const hasRowsToUpdate = estimateImports.some((estimateImport: EstimateImport) => {
          return estimateImport.validation_result.rows_to_update > 0;
        });

        if (hasRowsToUpdate) {
          estimateImports.forEach((estimateImport: EstimateImport) =>
            this.validatedEstimateImport.push(cloneDeep(estimateImport)),
          );
        } else {
          this.dialogService.openSuccess('Filen ändrar inget i lagret och behöver inte importeras.');
        }
        this.show.verifyUpload = hasRowsToUpdate;
        this.show.validateFile = !hasRowsToUpdate;
        this.show.uploadFile = !hasRowsToUpdate;
        break;
      case EstimateImportStatus.imported:
        this.dialogService.openSuccess('Estimaten och lagret har uppdaterats.');
        this.show.verifyUpload = false;
        this.show.validateFile = true;
        this.show.uploadFile = true;
        this.reloadLists();
        break;
    }
  };

  private showEstimateProgressWidgetProgress = (msg?: string) => {
    this.estimateProgressWidget.message = msg;
    this.estimateProgressWidget.show = true;
  };
}
