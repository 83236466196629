<div class="sidebar__content">
  <special-campaign-label class="pull-right" [campaign]="campaign"></special-campaign-label>

  <div class="margin-bottom--1x">
    <date-span [start]="this.totalPeriod.start" [end]="this.totalPeriod.end"></date-span>
  </div>
  <customer-agency-row [data]="campaign"></customer-agency-row>
  <h3 class="margin-top--none margin-bottom--2x word-break--word">{{campaignName}}</h3>
  <div class="margin-bottom--1x">
    <h4>Total budget: {{totalBudget | currency: 'SEK' || '-'}}</h4>
  </div>

  <div class="margin-bottom--1x" *ngIf="pauseImpressions">
    <h4 [ngClass]="{invisible: estimatingCampaign}" >Paus - {{impressionsCurrency}}</h4>
    <div class="estimated-views-text relative">
      <span [ngClass]="{invisible: estimatingCampaign}">
      <dot-loader class="estimation-spinner" fast="true" [visibility]="estimatingCampaign"></dot-loader>
        {{ pauseImpressions | number | dashAsDefault }}
      </span>
    </div>
  </div>

  <div class="margin-bottom--1x" *ngIf="onlineVideoImpressions">
    <h4 [ngClass]="{invisible: estimatingCampaign}" >Online Video - {{impressionsCurrency}}</h4>
    <div class="estimated-views-text relative">
      <span [ngClass]="{invisible: estimatingCampaign}">
      <dot-loader class="estimation-spinner" fast="true" [visibility]="estimatingCampaign"></dot-loader>
        {{ onlineVideoImpressions | number | dashAsDefault }}
      </span>
    </div>
  </div>

  <div class="margin-bottom--1x" *ngIf="grossRatingViews">
    <h4 [ngClass]="{invisible: estimatingCampaign}" >Adresserbar TV - {{  grossRatingViewsCurrency }}</h4>
    <div class="estimated-views-text relative">
      <dot-loader class="estimation-spinner" fast="true" [visibility]="estimatingCampaign"></dot-loader>
      <span *ngIf="!estimatingCampaign">
        {{ grossRatingViews | number | dashAsDefault }}
      </span>
    </div>
  </div>

  <div class="margin-bottom--1x" *ngIf="completedViews">
    <h4 [ngClass]="{invisible: estimatingCampaign}" >Adresserbar TV - {{completedViewsCurrency}}</h4>
    <div class="estimated-views-text relative">
      <dot-loader class="estimation-spinner" fast="true" [visibility]="estimatingCampaign"></dot-loader>
      <span *ngIf="!estimatingCampaign">
        {{ completedViews | number | dashAsDefault }}
      </span>
    </div>
  </div>

  <div class="margin-bottom--2x">
    <p class="text--cta" *ngIf="campaign.use_new_contract">Om du bokar om nu appliceras nya rabatter</p>
    <book-button></book-button>
    <save-button *ngIf="showSaveButton"></save-button>
  </div>

  <ng-container *ngIf="showTargetingsBox">
    <advanced-booking-targeting-box
      *ngFor="let targeting of targetings; index as idx"
      class="display--block border-radius--soft margin-bottom--1x"
      [targetingId]="targeting.id"
      [attr.e2e-id]="'targeting-box-' + idx"
    ></advanced-booking-targeting-box>
  </ng-container>
  <button-with-line class="display--block margin-top--2x" *ngIf="showTargetingsBox" [onClick]="addTargeting" label="Lägg till kampanjdel"></button-with-line>
</div>
