import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionHandler } from '../../advanced_campaign_booking.module/components/subscription-handler';
import { CashCampaignService } from '../../cash_dashboard.module/services/CashCampaignService';
import { CampaignService } from '../../woo_services.module/CampaignService';
import { CreativeService } from '../../woo_services.module/CreativeService';
import { DialogService } from '../../woo_services.module/DialogService';
import { RoutingService } from '../../woo_services.module/RoutingService';
import { SessionService } from '../../woo_services.module/SessionService';
import { SimpleBooking } from './simple-booking/simple-booking.component';

@Component({
  selector: 'campaign-booking',
  template: `
    <simple-booking [hidden]="isOnSummary" (saveDraft)="saveCampaign()" (nextStep)="showSummary()"></simple-booking>
    <booking-summary
      *ngIf="isOnSummary"
      (abortSummary)="showBooking()"
      (bookCampaign)="bookCampaign()"
    ></booking-summary>
  `,
})
export class CampaignBooking extends SubscriptionHandler implements OnInit {
  @ViewChild(SimpleBooking, { static: true }) simpleBooking: SimpleBooking;
  isOnSummary: boolean;

  constructor(
    private campaignService: CampaignService,
    private cashCampaignService: CashCampaignService,
    private creativeService: CreativeService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private sessionService: SessionService,
  ) {
    super();
    this.addSubscription(
      this.routingService.urlOnNavChange((url) => {
        this.isOnSummary = this.isOnSummaryRoute(url);
      }),
    );
    this.resetCampaign();
    this.setStaticCampaignFields(this.cashCampaignService.campaign);
  }

  ngOnInit(): void {
    if (this.isEditingCampaign()) {
      this.loadCampaign();
    }
  }

  loadCampaign(): void {
    this.dialogService.openBlocking('Laddar kampanj');
    const campaignId = this.routingService.getParam(this.route, 'campaignId');
    this.campaignService
      .get(campaignId)
      .then(() => {
        this.simpleBooking.campaignLoaded();
      })
      .finally(() => this.dialogService.closeBlocking());
  }

  saveCampaign = (): void => {
    this.dialogService.openBlocking('Sparar utkast');
    const saveMethod = this.isEditingCampaign() ? this.campaignService.update : this.campaignService.create;
    saveMethod(this.cashCampaignService.campaign).then(
      () => {
        this.dialogService.closeBlocking();
        this.routingService.navigate([
          '/customer',
          this.routingService.getParam(this.route, 'customerId'),
          'dashboard',
        ]);
      },
      (error) => {
        console.error(error); // TODO what to do here?
        this.dialogService.closeBlocking();
      },
    );
  };

  bookCampaign = (): void => {
    this.dialogService.openBlocking('Bokar kampanj');
    const bookMethod = this.isEditingCampaign()
      ? this.campaignService.updateAndBook
      : this.campaignService.createAndBook;
    bookMethod(this.cashCampaignService.campaign, false).then(
      (resData) => {
        this.dialogService.closeBlocking();
        this.routingService.navigate([
          '/customer',
          this.routingService.getParam(this.route, 'customerId'),
          'campaign',
          resData.campaign_id,
          'confirmation',
        ]);
      },
      (error) => {
        console.error(error); // TODO what to do here?
        this.dialogService.closeBlocking();
      },
    );
  };

  showSummary = (): Promise<boolean> =>
    this.routingService.navigate([
      'customer',
      this.routingService.getParam(this.route, 'customerId'),
      'campaign',
      'summary',
    ]);

  showBooking = (): Promise<boolean> =>
    this.routingService.navigate(['customer', this.routingService.getParam(this.route, 'customerId'), 'campaign']);

  private isEditingCampaign = () => Boolean(this.routingService.getParam(this.route, 'campaignId'));

  private resetCampaign = () => {
    this.cashCampaignService.resetCampaign();
    const campaign = this.cashCampaignService.campaign;
    const creative = this.creativeService.createCreative('other', 15);
    creative.segments = [{ start_date: new Date(), end_date: new Date() }];
    campaign.creatives = [creative];
  };

  private setStaticCampaignFields(campaign) {
    campaign.customer_id = this.routingService.getParam(this.route, 'customerId');
    const user = this.sessionService.getUser();
    campaign.client_invoice_reference = user.first_name + ' ' + user.last_name;
    campaign.reference_number = '';
  }

  isOnSummaryRoute(url: string): boolean {
    return url.includes('/summary');
  }
}
