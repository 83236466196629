<div class="display--inline-block width--100">
  <voucher-select [targetingId]="targetingId"></voucher-select>
  <div
    class="col-xs-12 padding--none"
    *ngFor="let category of advancedTargetGroupsByCategory; let index = index"
    [ngClass]="{'padding-bottom--1x': isNotLastCategory(index)}">
    <p class="text--strong margin--none">{{category.name}}</p>
    <div
      class="col-lg-4 col-md-12 padding-left--none padding-right--1x padding-top--1x padding-bottom--none"
      *ngFor="let targetGroup of category.advancedTargetGroups"
    >
      <targeting-checkbox
        (onClick)="toggleTargetGroup(targetGroup.id)"
        [checked]="isSelected(targetGroup.id)"
        [texts]="[targetGroup.name]">
      </targeting-checkbox>
    </div>
  </div>
</div>
