<form #form="ngForm" (ngSubmit)="form.valid ? submit() : setFormDirty(form.control)">
  <div class="input__container">
    <label for="name">Namn</label>
    <input id="name" name="name" type="text" [(ngModel)]="behavior.name" required>
  </div>
  <div class="input__container">
    <label for="tag">Tagg</label>
    <input id="tag" name="tag" type="text" [(ngModel)]="behavior.tag" required>
  </div>
    <table class="table table-woo table--info table--hover width--100">
      <thead>
        <tr>
          <th class="capitalize--first-letter centered" colspan="2"></th>
          <th class="capitalize--first-letter centered" colspan="3">
            Estimatfördelning
          </th>
          <th class="capitalize--first-letter centered" colspan="3">
            Maxuttag
          </th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin text--small capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let row of inputRows; let index = index;">
        <tr [hidden]="!rowVisible(row)">
          <td class="text--thin vertical-align--middle">
            <span>{{translateProductFormat(row.productFormat)}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{row.publisher}}</span>
          </td>

          <td class="vertical-align--middle">
            <div
              class="input__container">
              <input
                name="row-{{index}}-sharesPreroll"
                [(ngModel)]="behavior.shares[row.publisherId][row.productFormatId].preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <div
              class="input__container">
              <input
                name="row-{{index}}-sharesMidroll"
                [(ngModel)]="behavior.shares[row.publisherId][row.productFormatId].midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <div
              class="input__container">
              <input
                name="row-{{index}}-sharesPostroll"
                [(ngModel)]="behavior.shares[row.publisherId][row.productFormatId].postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <div
              class="input__container">
              <input
                name="row-{{index}}-withdrawalLimitsPreroll"
                [(ngModel)]="behavior.withdrawal_limits[row.publisherId][row.productFormatId].preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <div
              class="input__container">
              <input
                name="row-{{index}}-withdrawalLimitsMidroll"
                [(ngModel)]="behavior.withdrawal_limits[row.publisherId][row.productFormatId].midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <div
              class="input__container">
              <input
                name="row-{{index}}-withdrawalLimitsPostroll"
                [(ngModel)]="behavior.withdrawal_limits[row.publisherId][row.productFormatId].postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <div class="margin-top-bottom--1x">
      <h5>Bokningsbar</h5>
      <toggle
        [(ngModel)]="behavior.active"
        name={{behavior.name}}-bookable
        onLabel="Ja"
        offLabel="Nej">
      </toggle>
    </div>
    <button type="submit" value="Skapa" class="button primary-1">Skapa</button>
    <button type="button" class="link primary" (click)="onAbort.emit()">Avbryt</button>
</form>
