import { Component, OnInit } from '@angular/core';
import { InvoiceService, InvoiceStatus, InvoiceSummary } from '../../../woo_services.module/InvoiceService';

@Component({
  selector: 'invoice-overview',
  templateUrl: './invoice-overview.component.html',
})
export class InvoiceOverview implements OnInit {
  readonly InvoiceStatus = InvoiceStatus;

  invoices: InvoiceSummary;

  constructor(private invoiceService: InvoiceService) {}

  async ngOnInit(): Promise<void> {
    this.invoices = await this.invoiceService.getSummary();
  }
}
