import { Injectable } from '@angular/core';
import { SubscriptionHandler } from '../advanced_campaign_booking.module/components/subscription-handler';
import { CachedBookingRulesService } from '../advanced_campaign_booking.module/services/CachedBookingRulesService';
import {
  BookingModel,
  BookingStore,
  BookingTargetingMetaData,
} from '../advanced_campaign_booking.module/stores/BookingStore';
import { duplicateValuesExist } from '../utils/array';
import {
  DeviceGroupName,
  GeoTargeting,
  ProductChoice,
  ProductFormats,
  RuleSelection,
  Targeting,
  TargetingType,
  wooId,
} from './shared-types';

@Injectable()
export class TargetingCombinationRulesService extends SubscriptionHandler {
  metaData: Record<wooId, BookingTargetingMetaData>;
  targetings: Targeting[];
  bookingRules: any[][];

  constructor(private bookingStore: BookingStore, private cachedBookingRulesService: CachedBookingRulesService) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.updatesFromBookingStore));
    cachedBookingRulesService.getAndConvertBookingRulesAbf().then((rules) => (this.bookingRules = rules));
  }

  updatesFromBookingStore = (model: BookingModel): void => {
    this.metaData = model.targetingMetaData;
    this.targetings = model.campaign.targetings;
  };

  //-----Annonstyp------------------------------

  disablePause = (targetingId: wooId): boolean => {
    if (this.disneyCategoryIsSelected(targetingId)) {
      return true;
    }

    return !this.validCombinationOfInputs({
      targetingType: TargetingType.pause,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: this.metaData[targetingId].productChoice,
      addonTargeting: this.metaData[targetingId].addonTargeting,
      specialCase: this.getTargeting(targetingId).includes_linear ? SpecialCase.includesLinear : null,
    });
  };

  disableRegional = (targetingId: wooId): boolean => {
    if (this.disneyCategoryIsSelected(targetingId)) {
      return true;
    }

    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: GeoTargeting.regional,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: this.metaData[targetingId].productChoice,
      addonTargeting: this.metaData[targetingId].addonTargeting,
    });
  };

  //-----Styrning------------------------------

  disableAdvancedTargetGroup = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: ProductChoice.advancedTargetGroup,
      addonTargeting: this.metaData[targetingId].addonTargeting,
    });
  };

  disableCategory = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: ProductChoice.category,
      addonTargeting: this.metaData[targetingId].addonTargeting,
      specialCase: this.getTargeting(targetingId).includes_linear ? SpecialCase.includesLinear : null,
    });
  };

  disableDaypart = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: ProductChoice.daypart,
      addonTargeting: this.metaData[targetingId].addonTargeting,
    });
  };

  disableDevice = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: ProductChoice.device,
      addonTargeting: this.metaData[targetingId].addonTargeting,
    });
  };

  disableProgram = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: ProductChoice.program,
      addonTargeting: this.metaData[targetingId].addonTargeting,
      specialCase: this.getTargeting(targetingId).includes_linear ? SpecialCase.includesLinear : null,
    });
  };

  disableRBS = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: ProductChoice.rbs,
      addonTargeting: this.metaData[targetingId].addonTargeting,
    });
  };

  disableTargetGroup = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: ProductChoice.targetGroup,
      addonTargeting: this.metaData[targetingId].addonTargeting,
    });
  };

  //-----Tilläggsstyrning-----------------------

  disableAddonTargetGroup = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      addonTargeting: ProductChoice.targetGroup,
      productChoice: this.metaData[targetingId].productChoice,
    });
  };

  disableAddonAdvancedTargetGroup = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      addonTargeting: ProductChoice.advancedTargetGroup,
      productChoice: this.metaData[targetingId].productChoice,
    });
  };

  disableAddonDevice = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      addonTargeting: ProductChoice.device,
      productChoice: this.metaData[targetingId].productChoice,
    });
  };

  disableAllAddonTargetingOptions = (targetingId: wooId): boolean => {
    const targeting = this.targetings.find((targeting) => targeting.id === targetingId);
    if (targeting) {
      return !targeting.device_groups.every((dg) => dg.addon_targetable);
    } else return false;
  };

  //-----Placering------------------------------

  disableShortform = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: ProductFormats.shortForm,
      productChoice: this.metaData[targetingId].productChoice,
      addonTargeting: this.metaData[targetingId].addonTargeting,
      specialCase: this.getTargeting(targetingId).includes_linear ? SpecialCase.includesLinear : null,
    });
  };

  disableLongform = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: ProductFormats.longForm,
      addonTargeting: this.metaData[targetingId].addonTargeting,
      productChoice: this.metaData[targetingId].productChoice,
    });
  };

  disableCombination = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: ProductFormats.combination,
      productChoice: this.metaData[targetingId].productChoice,
      addonTargeting: this.metaData[targetingId].addonTargeting,
      specialCase: this.getTargeting(targetingId).includes_linear ? SpecialCase.includesLinear : null,
    });
  };

  //-----Misc------------------------------------

  disableIncludeLinear = (targetingId: wooId): boolean => {
    return !this.validCombinationOfInputs({
      targetingType: this.metaData[targetingId].targetingType,
      geoTargeting: this.metaData[targetingId].geoTargeting,
      productFormat: this.metaData[targetingId].productFormat,
      productChoice: this.metaData[targetingId].productChoice,
      addonTargeting: this.metaData[targetingId].addonTargeting,
      specialCase: SpecialCase.includesLinear,
    });
  };

  disableDeviceGroup = (targetingId: wooId, deviceGroupName: string): boolean => {
    const addonTargetingIsTargetGroups =
      this.metaData[targetingId].addonTargeting === ProductChoice.advancedTargetGroup ||
      this.metaData[targetingId].addonTargeting === ProductChoice.targetGroup;
    if (addonTargetingIsTargetGroups && deviceGroupName !== DeviceGroupName.CTV) {
      return true;
    }
    return false;
  };

  frequencyLimitCompatible = (targetingId: wooId): boolean => {
    if (
      this.metaData[targetingId].targetingType === TargetingType.instream &&
      this.metaData[targetingId].geoTargeting === GeoTargeting.national &&
      this.metaData[targetingId].productFormat !== ProductFormats.shortForm &&
      (this.metaData[targetingId].productChoice === ProductChoice.rbs ||
        this.metaData[targetingId].productChoice === ProductChoice.category)
    ) {
      return true;
    }
    return false;
  };

  private getTargeting(targetingId: wooId): Targeting {
    return this.targetings.find((targeting) => targeting.id === targetingId);
  }

  private validCombinationOfInputs(selection: RuleSelection): boolean {
    const selectionArray = Object.values(selection).filter((value) => !!value && value !== ProductChoice.noChoice);
    if (duplicateValuesExist(selectionArray)) return false;

    if (this.bookingRules) {
      return this.bookingRules.some((row) => selectionArray.every((input) => row.includes(input)));
    } else return true;
  }

  private disneyCategoryIsSelected(targetingId: wooId): boolean {
    return this.getTargeting(targetingId).categories.some((category) => category.is_disney === true);
  }
}

export enum SpecialCase {
  includesLinear = 'includes_linear',
}
