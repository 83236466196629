import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService } from './JobService';
import { ViewCurrency, wooId } from './shared-types';
import { TargetingMapping } from './TargetingMappingService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TTVBookingService {
  constructor(private http: HttpClient, private jobService: JobService, private env: EnvironmentService) {}

  getTTVBooking(campaignId: wooId, targetingId: wooId): Promise<TTVBooking> {
    return lastValueFrom(
      this.http.get<TTVBooking>(`${this.env.apiUrl}/campaigns/${campaignId}/targetings/${targetingId}/ttv_booking`),
    );
  }

  async updateBooking(params: TTYBookingParams): Promise<TTVBookingJobResult> {
    return this.jobService.waitForJobCompletion(
      (
        await lastValueFrom(
          this.http.post<Job>(`${this.env.apiUrl}/ttv_bookings/update_booking`, { ttv_booking: params }),
        )
      ).id,
    );
  }
}

export type TTYBookingParams = Omit<TTVBooking, 'id' | 'targeting_mapping'>;

export interface TTVBooking {
  id: wooId;
  ttv_id: string;
  name: string;
  reference_number: string;
  created_at: string;
  updated_at: string;
  agency_crm_id?: string;
  customer_crm_id: string;
  order_reference?: string;
  customer_contact: string;
  target_group: string;
  additional_information?: string;
  discount: string;
  universe: number;
  commercial_planner: {
    _id: wooId;
    email: string;
    first_name: string;
    last_name: string;
  };
  weeks: TTVWeek[];
  films: TTVFilm[];
  targeting_mapping: TargetingMapping;
}

export interface TTVWeek {
  year: number;
  week: number;
  film_id: string;
  channel_name: string;
  views: number;
  view_currency: ViewCurrency;
  gross_budget: string;
  net_budget: string;
  discount: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface TTVFilm {
  film_id: string;
  length: number;
  film_code?: string;
}

export interface TTVBookingJobResult {
  ttv_id: string;
  campaign_id?: wooId;
  status: 'Success' | 'Failed';
  warnings: TTVProblems[];
  errors: TTVProblems[];
}

export interface TTVProblems {
  error_code: string;
  parameters?: Record<string, any>;
}
