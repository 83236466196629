import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { setFormDirty } from '../../../utils/form';
import { validateRequired } from '../../../utils/validators';
import { ManualInvoice } from '../../../woo_services.module/InvoiceService';

@Component({
  selector: 'invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
})
export class InvoiceForm extends SubscriptionHandler implements OnInit {
  readonly setFormDirty = setFormDirty;
  readonly InvoiceType = InvoiceType;
  readonly minInvoiceDateString = new Date().toISOString().substring(0, 10);

  @Input() invoiceType: InvoiceType;
  @Input() agencyDiscount: number;
  @Output() onSubmit = new EventEmitter<ManualInvoice>();
  @Output() onAbort = new EventEmitter<void>();

  form: FormGroup<FormModel>;

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group<FormModel>({
      description: new FormControl(''),
      total_gross_excluding_agency_discount: new FormControl(null, [
        validateRequired(),
        this.invoiceType === InvoiceType.Debit ? Validators.min(1) : Validators.max(-1),
      ]),
      invoice_date: new FormControl(
        new Date(this.minInvoiceDateString),
        this.minStringValidator(this.minInvoiceDateString),
      ),
      original_invoice: new FormControl(''),
      internal_message: new FormControl(''),
    });

    // Listen to total gross changes and force the correct sign
    this.addSubscription(
      this.form.controls.total_gross_excluding_agency_discount.valueChanges.subscribe((totalGross: number) => {
        const sign = this.invoiceType === this.InvoiceType.Credit ? -1 : 1;
        const correctedGross = sign * Math.abs(totalGross);
        if (correctedGross !== totalGross) {
          this.form.controls.total_gross_excluding_agency_discount.setValue(correctedGross);
        }
      }),
    );
  }

  submit(): void {
    this.onSubmit.emit({
      ...this.form.getRawValue(),
      campaign_id: null,
      description: this.form.value.description.trim(),
    });
  }

  // A min validator that uses lexical ordering of the string to validate
  private minStringValidator(min: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (control.value == null) {
        return null;
      }

      return control.value >= min
        ? null
        : {
            strmin: {
              strmin: min,
              actual: control.value,
            },
          };
    };
  }
}

export enum InvoiceType {
  Credit,
  Debit,
}

interface FormModel {
  description: FormControl<string>;
  total_gross_excluding_agency_discount: FormControl<number>;
  invoice_date: FormControl<Date | null>;
  original_invoice: FormControl<string>;
  internal_message: FormControl<string>;
}
