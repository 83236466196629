import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserRole } from '../utils/user-roles';
import { AuthService } from './AuthService';
import { SessionService } from './SessionService';
import { DashboardTab, wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class RoutingService {
  constructor(private authService: AuthService, private sessionService: SessionService, private router: Router) {}

  getParam = (route: ActivatedRoute, param: string): any => route.snapshot.params[param];

  getQueryParam = (route: ActivatedRoute, param: string): any => route.snapshot.queryParams[param];

  getState = (key: string): any => window.history.state[key];

  navigate = (state: any[], extras?: NavigationExtras): Promise<boolean> => {
    return this.router.navigate(state, extras);
  };

  idOfCurrentUsersCashCustomer = (): wooId => {
    const cashRoles = this.sessionService.getUser().roles.filter((role) => role.name === UserRole.cashUser);
    if (cashRoles.length === 1) {
      return cashRoles[0].resource_id;
    }
    return null;
  };

  async navigateWithReload(state: any[], extras?: NavigationExtras): Promise<void> {
    const oldStrategy = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigated = false;
    try {
      await this.navigate(state, extras);
    } finally {
      this.router.routeReuseStrategy.shouldReuseRoute = oldStrategy;
    }
  }

  goToDashboard = async (): Promise<void> => {
    if (this.authService.hasRole(UserRole.cashUser)) {
      const customerId = this.idOfCurrentUsersCashCustomer();
      await this.navigate(this.cashDashboardUrl(customerId));
    } else {
      await this.navigate(['/dashboard']);
    }
  };

  goToNewBooking = async (): Promise<void> => {
    if (this.authService.hasRole(UserRole.cashUser)) {
      const customerId = this.idOfCurrentUsersCashCustomer();
      await this.navigate(this.cashBookingUrl(customerId));
    } else {
      await this.navigate(['/campaigns', 'new']);
    }
  };

  goToRolesInitialPage = async (): Promise<void> => {
    if (this.authService.hasRole(UserRole.eos)) {
      await this.navigate(['/eos']);
    } else if (this.authService.hasRole(UserRole.statusUser)) {
      await this.navigate(['/status']);
    } else if (this.authService.hasRole(UserRole.estimator)) {
      await this.navigate(['/import']);
    } else if (this.authService.hasRole(UserRole.accounting)) {
      await this.navigate(['/invoice_overview']);
    } else if (this.authService.allowedToSeeDashboard()) {
      await this.goToDashboard();
    } else {
      await this.navigate(['/start']);
    }
  };

  goToDashboardWithCampaign = async (campaignId: wooId, noScroll?: boolean): Promise<void> => {
    await this.navigate(['/dashboard', campaignId], { state: { noScrollToTop: noScroll } });
  };

  goToDashboardTab = async (id: wooId, tabName?: DashboardTab, noScroll?: boolean): Promise<void> => {
    if (tabName) {
      await this.navigate(['/dashboard', id, tabName], { state: { noScrollToTop: noScroll } });
    } else {
      await this.goToDashboardWithCampaign(id, noScroll);
    }
  };

  openAndGoDashboardTab = (campaignId: wooId, tabName: DashboardTab): void => {
    const url = `/#/dashboard/${campaignId}/${tabName}`;
    window.open(url, '_blank');
  };

  onStart = (callback: () => any): Subscription =>
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        callback();
      }
    });

  urlOnNavChange = (callback: (url: string) => any): Subscription => {
    return this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e) => (e instanceof NavigationEnd ? e.url : {})),
      )
      .subscribe((url: string) => {
        callback(url);
      });
  };

  onNavChange = (callback: (event: Event) => void): Subscription => {
    return this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e) => {
      callback(e);
    });
  };

  private cashDashboardUrl = (customerId: wooId): any[] => ['/customer', customerId, 'dashboard'];
  private cashBookingUrl = (customerId: wooId): any[] => ['/customer', customerId, 'campaign'];
}
