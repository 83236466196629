import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { wooId } from './shared-types';
import { EnvironmentService } from './EnvironmentService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getDevices(): Promise<Device[]> {
    return lastValueFrom(this.http.get<Device[]>(`${this.env.apiUrl}/devices`));
  }

  updateDevices(devices: Device[]): Promise<void> {
    return lastValueFrom(
      this.http.put<void>(`${this.env.apiUrl}/devices`, { devices: devices }),
    );
  }
}

export interface Device {
  id: wooId;
  name: string;
  active: boolean;
  automated_guaranteed_enabled: boolean;
  clickable: boolean;
  pause_ad_enabled: boolean;
}
