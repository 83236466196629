<div class="snake-container">

  <div
  *ngFor="let step of bookingStepsOrder; let last = last; let first = first;"
    class="step"
    [ngClass]="{
      'step--active': activeBookingStep === step,
      'step--invalid': stepInvalid(step),
      'step--valid': stepValid(step) || activeBookingStep === step && last || stepValidAndVisited(step), 
      'step--disabled': stepDisabled(step),
      'step--valid-and-active': stepValid(step) && activeBookingStep === step
    }"
    (click)="updateActiveBookingStep(step)"
  >
  <div class="margin-bottom--1x">
    <span class="step--untouched" attr.e2e-id="snake-nav-{{step}}">{{convertBookingStep(step)}}</span>
    <span class="step-circle-new margin-left--1x">
      <span *ngIf="stepValid(step) || activeBookingStep === step && last || stepValidAndVisited(step) || stepDisabled(step)" class="icon icon-check complete"></span>
      <span *ngIf="stepInvalid(step)" class="icon icon-close-x incomplete"></span>
    </span>
  </div>
  <div class="step-line" [ngClass]="{
    'step--active': activeBookingStep === step,
    'step--invalid': stepInvalid(step),
    'step--valid': stepValid(step) || activeBookingStep === step && last || stepValidAndVisited(step),
    'step--disabled': stepDisabled(step),
    'step--valid-and-active': stepValid(step) && activeBookingStep === step
  }"
  ></div>
  </div>
</div>
