<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera fördelningen för regioner</h3>
</div>

<div>
  <div class="tabbable__body">
    <import-warning *ngIf="show.totalSharesHaveChanged"></import-warning>
    <form #shareOfTotalForm="ngForm" (ngSubmit)="updateShareOfTotal()" >
      <div class="input__container flex__container align-items--end">
        <div>
          <label>Andel av totallagret som ska vara tillgängligt för regionslagret</label>
          <input name="regionShareOfTotal" [(ngModel)]="shareOfTotal" wooPercentFormatter [wooMinValidator]="0" [wooMaxValidator]="1" required>
        </div>
        <button class="button margin-left--2x" type="submit" [disabled]="!shareOfTotalForm.valid">Spara</button>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="button-filter-container">
        <div>
          <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="regionForm.invalid">Spara</button>
          <button *ngIf="!show.isEditing" class="button primary" (click)="setEditing(true)">Redigera Estimatfördelning</button>
          <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
        </div>
        <div class="display--flex">
            <div *ngIf="productFormats && productFormats.length > 1" class="filter-dropdown margin-left--2x">
              <woo-dropdown
              [items]="productFormats"
              [(ngModel)]="selectedProductFormat"
              name="selectedProductFormat"
              inputId="select-product-format"
              labelText="Filtrera på Placering"
              [labelTransform]="translateProductFormat"
              notFoundText="Inga Placeringar hittades."
              [clearable]="true"></woo-dropdown>
            </div>
        </div>
      </div>
    </form>
  </div>

  <div #stickyHeader>
    <div class="tabbable__panel clearfix shadow-z-1">
      <h3 class="margin-left--3x">Tillgängliga regioner</h3>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table-woo table--info table--hover width--100" ngForm #regionForm="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th class="text--thin capitalize--first-letter">
            Regioner
          </th>
          <ng-container *ngIf="regionRows">
            <ng-container *ngFor="let productFormatId of regionRows[0].productFormatIds">
              <th [hidden]="!colVisible(productFormatId)" class="text--thin capitalize--first-letter">
                {{translateProductFormat(tableHeaders[productFormatId])}}
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </thead>

      <tbody *ngIf="!show.loading">
        <ng-container *ngFor="let row of regionRows; let index = index; trackBy: trackByIndex">
          <tr [hidden]="!rowVisible(row.regionId)">
            <td class="text--thin vertical-align--middle">
              <span>{{row.regionName}}</span>
            </td>

            <ng-container *ngFor="let productFormatId of row.productFormatIds; let colIndex = index; trackBy: trackByIndex">
              <td [hidden]="!colVisible(productFormatId)" class="text--thin vertical-align--middle">
                <span *ngIf="!show.isEditing">{{getRegion(row.regionId).shares[productFormatId] | percent: "1.0-3"}}</span>
                <div *ngIf="show.isEditing" class="input__container">
                  <input
                    name="row-{{index}}-{{colIndex}}-regionShare"
                    [(ngModel)]="getRegion(row.regionId).shares[productFormatId]"
                    wooPercentFormatter
                    [wooMinValidator]="0"
                    [wooMaxValidator]="1"
                    [required]="true">
                </div>
              </td>
            </ng-container>
          </tr>
        </ng-container>

        <tr>
          <td class="vertical-align--middle">
            <strong>Totalt:</strong>
          </td>
          <ng-container *ngIf="regionRows && sumOfProductFormatShares">
            <ng-container *ngFor="let productFormatId of regionRows[0].productFormatIds">
              <td [hidden]="!colVisible(productFormatId)">
                <strong>{{sumOfProductFormatShares(productFormatId)}}</strong> %
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </tbody>
    </table>
    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
      <button class="button primary" (click)="save()" [disabled]="regionForm.invalid">Spara</button>
      <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>
