<table class="table table--medium-font-header table--with-small-images margin--none centered" *ngIf="creatives.length">
  <thead>
    <tr>
      <th *ngIf="!isVideo">Bild</th>
      <th>Namn</th>
      <th *ngIf="isVideo">Leveranssätt</th>
      <th *ngIf="isVideo">Filmkod</th>
      <th *ngIf="isVideo">Längd</th>
      <ng-container *ngIf="showDistribution">
        <th >Budget</th>
        <th >{{currency}}</th>
        <th >Fördelning (budget)</th>
      </ng-container>
      <th *ngIf="creativesAddedAfterAgreement"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let creative of creatives; trackBy: trackById">
      <tr [attr.e2e-id]="creative.custom_name">
        <td *ngIf="!isVideo">
          <img *ngIf="creative.asset_url" [src]="creative.asset_url">
          <ng-container *ngIf="!creative.asset_url">Levereras senare</ng-container>
        </td>
        <td>{{creative.custom_name}}</td>
        <td *ngIf="isVideo">{{translateCreativeType(creative.creative_type)}}</td>
        <td *ngIf="isVideo">{{creative.video_code}}</td>
        <td *ngIf="isVideo">{{creative.length}} s</td>
        <ng-container *ngIf="showDistribution">
          <td >
            {{ distributions[creative.id]?.budget | currency:'kr' | dashAsDefault }}
          </td>
          <td>
            {{ distributions[creative.id]?.views | number | dashAsDefault }}
          </td>
          <td>
            {{ distributions[creative.id]?.quota / 100 | percent:percentFormat }}
          </td>
        </ng-container>
        <td *ngIf="creativesAddedAfterAgreement"></td>
      </tr>
    </ng-container>
  </tbody>
</table>
