<table class="table--woo table table--hover table--info table--align--middle">
  <thead>
    <tr>
      <th>Namn</th>
      <th>Uppladdad</th>
      <th>Sammanfattning</th>
      <th></th>
    </tr>
  </thead>
  <tbody *ngFor="let estimate of estimates">
    <tr>
      <td>{{estimate.name}}</td>
      <td>{{estimate.updated_at | date:'yyyy-MM-dd HH:mm'}}</td>
      <td><b>{{estimate.weeks}}</b> veckor {{ estimate.source === 'duplicated' ? 'importerades igen' : 'importerades'}} av <b>{{estimate.author.name || "Okänd"}}.</b></td>
      <td class="text-center">
        <button (click)="download(estimate)" class="link primary-1 margin--none">
          Ladda ner
        </button>
      </td>
    </tr>
  </tbody>
</table>
<div class="text-right">
  <button class="link primary" (click)="loadPrevious()" [disabled]="pagination?.page === 1">Nyare estimat</button>
  <button class="link primary" (click)="loadNext()" [disabled]="pagination?.page === pagination?.last">Äldre estimat</button>
</div>
