import { Injectable } from '@angular/core';
import { AdOrganisationService } from '../../woo_services.module/AdOrganisationService';
import { AdOrganisation } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedAdOrganisationService implements Pick<AdOrganisationService, 'getAdOrganisations'> {
  private listCache: AdOrganisation[] = null;

  constructor(private adOrganisationService: AdOrganisationService) {}

  async getAdOrganisations(): Promise<AdOrganisation[]> {
    if (this.listCache) {
      return this.listCache;
    } else {
      const adOrgansiations = await this.adOrganisationService.getAdOrganisations();
      this.listCache = adOrgansiations;
      return adOrgansiations;
    }
  }
}
