import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, lastValueFrom, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserRole } from '../utils/user-roles';
import { EnvironmentService } from './EnvironmentService';
import { SessionService } from './SessionService';
import { User } from './shared-types';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private http: HttpClient;

  login$: Subject<void> = new Subject();
  loginFailed$: Subject<{ error: string }> = new Subject();
  logout$: Subject<void> = new Subject();
  needsToAcceptTermsAndConditions$: Subject<void> = new Subject();
  notAuthenticated$: Subject<{ authorizationRequest: boolean; attemptedUrl: string }> = new Subject();
  notAuthorized$: Subject<void> = new Subject();
  notFound$: Subject<void> = new Subject();
  acceptedTermsAndConditions$: Subject<void> = new Subject();
  needsToUpdatePassword$: Subject<{ token: string }> = new Subject();

  constructor(private sessionService: SessionService, private env: EnvironmentService, httpBackend: HttpBackend) {
    // Bypasses all global http interceptors in order to return
    //   a promise on failed login attempts (status: 401)
    this.http = new HttpClient(httpBackend);
  }

  login = (credentials: { email: string; password: string }): Promise<any> => {
    return lastValueFrom(
      this.http
        .post<User & { authentication_token: string }>(`${this.env.apiUrl}/users/sign_in`, {
          user: { email: credentials.email, password: credentials.password },
        })
        .pipe(
          catchError((response: HttpErrorResponse) => {
            if (response.status === 302) {
              this.needsToUpdatePassword$.next({
                token: response.error.reset_password_token,
              });
            } else {
              this.loginFailed$.next({
                error: response.error.error,
              });
            }
            return throwError(() => response);
          }),
          tap((user) => this.sessionService.create(user)),
          tap(() => this.login$.next()),
        ),
    );
  };

  logout = (): Promise<void> => {
    return lastValueFrom(
      this.http.delete<void>(`${this.env.apiUrl}/users/sign_out`).pipe(
        tap(() => this.sessionService.destroy()),
        catchError(() => {
          this.sessionService.destroy();
          return EMPTY;
        }),
        tap(() => this.logout$.next()),
      ),
    );
  };

  isAuthenticated(): boolean {
    return this.sessionService.isAuthenticated();
  }

  getUser = (): User => {
    return this.sessionService.getUser();
  };

  dropUserLocally = (): void => {
    this.sessionService.destroy();
  };

  hasRole = (role: UserRole): boolean => {
    const currentUser = this.getUser();
    if (!currentUser || !currentUser.roles) {
      return false;
    }
    return currentUser.roles.map((r) => r.name).includes(role);
  };

  hasAnyRole = (roles: UserRole[]): boolean => {
    const currentUser = this.getUser();
    if (!currentUser || !currentUser.roles) {
      return false;
    }
    const userRoleNames = currentUser.roles.map((r) => r.name);
    return roles.some((r) => userRoleNames.includes(r));
  };

  getFirstUserRole = (): UserRole => {
    return this.getUser().roles[0].name;
  };

  adminOrPlanner = (): boolean => {
    return this.hasAnyRole([UserRole.admin, UserRole.planner]);
  };

  sales = (): boolean => {
    return this.hasAnyRole([UserRole.salesManager, UserRole.sales]);
  };

  adminPlannerOrAccounting = (): boolean => this.adminOrPlanner() || this.hasAnyRole([UserRole.accounting]);

  admin = (): boolean => {
    return this.hasRole(UserRole.admin);
  };

  allowedToBook = (): boolean => {
    return this.hasAnyRole([
      UserRole.client,
      UserRole.cashUser,
      UserRole.agency,
      UserRole.agencyUser,
      UserRole.agencyAdmin,
      UserRole.planner,
      UserRole.admin,
      UserRole.externalPlanner,
    ]);
  };

  allowedToSeeDashboard = (): boolean => {
    return this.hasAnyRole([
      UserRole.client,
      UserRole.cashUser,
      UserRole.agency,
      UserRole.agencyUser,
      UserRole.agencyAdmin,
      UserRole.planner,
      UserRole.admin,
      UserRole.sales,
      UserRole.accounting,
      UserRole.externalPlanner,
      UserRole.salesManager,
    ]);
  };

  allowedToBookEarlyWithoutVerifyPeachCode = (): boolean => {
    return this.hasAnyRole([UserRole.planner, UserRole.admin, UserRole.externalPlanner]);
  };
}
