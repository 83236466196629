import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { orderBy } from 'lodash-es';
import { DialogService } from '../../woo_services.module/DialogService';
import { FileService, FileType } from '../../woo_services.module/FileService';
import { TranslationService } from '../../woo_services.module/TranslationService';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUpload {
  uploadedFiles: any;
  fileTypes: FileType[];
  newFile = {
    fileType: '',
    file: null,
    name: '',
  };
  translateFileType = this.translationService.convertFileUploadTypes;
  getUrlToFile = this.fileService.getUrlToFile;
  constructor(
    private translationService: TranslationService,
    private dialogService: DialogService,
    private fileService: FileService,
  ) {
    this.loadFiles().then();
    this.fileService.listTypes().then((types) => {
      this.fileTypes = types;
    });
  }

  loadFiles = async (): Promise<void> => {
    this.uploadedFiles = orderBy(await this.fileService.listFiles(), (file) => this.translateFileType(file.file_type));
  };

  uploadFile = async (form: NgForm): Promise<void> => {
    this.dialogService.openBlocking('Laddar upp fil...');
    await this.fileService.uploadFile(this.newFile.file, this.newFile.fileType as FileType);
    await this.loadFiles();
    this.dialogService.openSuccess('Filen laddades upp!');
    form.resetForm();
  };
}
