import { Component, Input } from '@angular/core';
import { AbstractValueAccessor } from '../../../utils/AbstractValueAccessor';
import { valueAccessorProvider } from '../../../utils/provider-builders';
import { generateId } from '../../../utils/string';

@Component({
  selector: 'selector',
  template: `
    <form>
      <span *ngFor="let choice of choices; last as last; first as first">
        <input
          id="{{ choice.label }}-{{ uniqueId }}"
          class="visually-hidden"
          type="radio"
          name="selector-input-name"
          [disabled]="isDisabled"
          [(ngModel)]="model"
          [value]="choice.value"
        />
        <label
          for="{{ choice.label }}-{{ uniqueId }}"
          class="selector-button text-align--center"
          [ngClass]="{ 'first-button': first, 'last-button': last }"
        >
          {{ choice.label }}
        </label>
      </span>
    </form>
  `,
  styleUrls: ['./selector.component.scss'],
  providers: [valueAccessorProvider(Selector)],
})
export class Selector<T> extends AbstractValueAccessor<T> {
  @Input() choices: Array<SelectorChoice<T>> = [];
  uniqueId = generateId();
}

export interface SelectorChoice<T> {
  value: T;
  label: string;
}
