import { Component, Input, OnChanges } from '@angular/core';
import {
  campaignTotalAdditionalCompletedViews,
  campaignTotalAdditionalGrossRatingViews,
  campaignTotalAdditionalImpressions,
  campaignTotalAdditionalOnlineVideoImpressions,
  campaignTotalAdditionalPauseImpressions,
  campaignTotalNetCompletedViews,
  campaignTotalNetGrossRatingViews,
  campaignTotalNetImpressions,
  campaignTotalNetOnlineVideoImpressions,
  campaignTotalNetPauseImpressions,
} from '../../../../utils/budget_helpers/campaign_budget_helper';
import { SimpleChanges } from '../../../../utils/types';
import { AuthService } from '../../../../woo_services.module/AuthService';
import { ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { Campaign, CombinedViewCurrencies, ViewCurrency } from '../../../../woo_services.module/shared-types';

@Component({
  selector: 'view-currency-block',
  templateUrl: './view-currency-block.component.html',
})
export class ViewCurrencyBlock implements OnChanges {
  readonly adminOrPlanner = this.authService.adminOrPlanner;

  @Input() campaign: Campaign;

  viewCurrencyName: string;
  impressionsActive: boolean;
  pauseImpressionsActive: boolean;
  onlineVideoImpressionsActive: boolean;
  completedViewsActive: boolean;
  grossRatingViewsActive: boolean;
  dualCurrencyActive: boolean;

  impressionsBudgeted: number;
  pauseImpressionsBudgeted: number;
  onlineVideoImpressionsBudgeted: number;
  completedViewsBudgeted: number;
  grossRatingViewsBudgeted: number;
  impressionsAdditional: number;
  pauseImpressionsAdditional: number;
  onlineVideoImpressionsAdditional: number;
  completedViewsAdditional: number;
  grossRatingViewsAdditional: number;

  additionalAfterStart: number;
  updateGoals: number;
  mmsGain: number;
  totalImpressions: number;
  totalInventoryViews: number;
  totalViews: number;

  grossRatingViewsHeading = 'Adresserbar TV - Bruttokontakter';

  constructor(
    private authService: AuthService,
    private translationService: TranslationService,
    private productFormatService: ProductFormatService,
  ) {}

  ngOnChanges(changes: SimpleChanges<ViewCurrencyBlock>): void {
    if (changes.campaign && this.campaign && this.campaign.agreement) {
      this.impressionsActive = this.campaign.agreement.impressions_summary.active;
      this.pauseImpressionsActive = this.campaign.agreement.pause_ad_summary.active;
      this.onlineVideoImpressionsActive = this.campaign.agreement.online_video_summary.active;
      this.completedViewsActive = this.campaign.agreement.completed_views_summary.active;
      this.grossRatingViewsActive = this.campaign.agreement.gross_rating_views_summary.active;
      this.dualCurrencyActive =
        [this.impressionsActive, this.completedViewsActive, this.grossRatingViewsActive].filter(Boolean).length === 2;
      this.viewCurrencyName = this.getViewCurrencyName();

      this.impressionsBudgeted = campaignTotalNetImpressions(this.campaign);
      this.pauseImpressionsBudgeted = campaignTotalNetPauseImpressions(this.campaign);
      this.onlineVideoImpressionsBudgeted = campaignTotalNetOnlineVideoImpressions(this.campaign);
      this.impressionsBudgeted = campaignTotalNetImpressions(this.campaign);
      this.completedViewsBudgeted = campaignTotalNetCompletedViews(this.campaign);
      this.grossRatingViewsBudgeted = campaignTotalNetGrossRatingViews(this.campaign);
      this.impressionsAdditional = campaignTotalAdditionalImpressions(this.campaign);
      this.pauseImpressionsAdditional = campaignTotalAdditionalPauseImpressions(this.campaign);
      this.onlineVideoImpressionsAdditional = campaignTotalAdditionalOnlineVideoImpressions(this.campaign);
      this.completedViewsAdditional = campaignTotalAdditionalCompletedViews(this.campaign);
      this.grossRatingViewsAdditional = campaignTotalAdditionalGrossRatingViews(this.campaign);

      this.additionalAfterStart = this.campaign.additional_goal_impressions; // @Todo remove in future
      this.updateGoals = this.campaign.adjusted_goal_order_views;
      this.mmsGain = this.campaign.mms_gain_order_views;
      this.totalImpressions = this.impressionsBudgeted + this.impressionsAdditional;
      this.totalViews = this.calculateTotalViews();
      this.totalInventoryViews = this.calculateTotalInventoryViews();

      this.setGrossRatingViewsHeading();
    }
  }

  private getViewCurrencyName(): string {
    if (this.impressionsActive && this.completedViewsActive)
      return this.translationService.convertViewCurrency(CombinedViewCurrencies.impcv);
    if (this.impressionsActive && this.grossRatingViewsActive)
      return this.translationService.convertViewCurrency(CombinedViewCurrencies.impgrv);
    if (this.impressionsActive) return this.translationService.convertViewCurrency(ViewCurrency.impressions);
    if (this.completedViewsActive) return this.translationService.convertViewCurrency(ViewCurrency.completedViews);
    if (this.grossRatingViewsActive) return this.translationService.convertViewCurrency(ViewCurrency.grossRatingViews);
    console.error('invalid view currency');
    return '';
  }

  private calculateTotalViews() {
    if (this.adminOrPlanner()) {
      return (
        this.impressionsBudgeted +
        this.completedViewsBudgeted +
        this.grossRatingViewsBudgeted +
        this.impressionsAdditional +
        this.completedViewsAdditional +
        this.grossRatingViewsAdditional +
        this.additionalAfterStart +
        this.updateGoals +
        this.mmsGain
      );
    }

    const extraBudgets = this.impressionsAdditional + this.completedViewsAdditional + this.additionalAfterStart;

    return extraBudgets > 0 ? this.impressionsBudgeted + this.completedViewsBudgeted + extraBudgets : 0;
  }

  private calculateTotalInventoryViews() {
    return this.campaign.agreement.parts.reduce((sum, AgreementPart) => sum + AgreementPart.total_inventory_views, 0);
  }

  private setGrossRatingViewsHeading(): void {
    const combinationPresent = this.productFormatService.campaignIncludesCombination(this.campaign);
    const longformPresent = this.productFormatService.campaignIncludesLongform(this.campaign);

    if (combinationPresent && longformPresent) {
      this.grossRatingViewsHeading = 'Adresserbar TV / Kombination - Bruttokontakter';
    } else if (combinationPresent) {
      this.grossRatingViewsHeading = 'Kombination - Bruttokontakter';
    } else if (longformPresent) {
      this.grossRatingViewsHeading = 'Adresserbar TV - Bruttokontakter';
    }
  }
}
