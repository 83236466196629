<div>
  <div class="tabbable__panel rounded-top-right">
    <h3 class="margin-left--3x">Här kan du administrera estimatfördelning för placeringar</h3>
  </div>

  <div *ngIf="show.estimatesHaveChanged" class="tabbable__body">
    <import-warning></import-warning>
    <dot-loader [visibility]="show.updatingEstimates"></dot-loader>
  </div>
</div>

<div #stickyHeader>
  <div class="tabbable__body">
    <div class="button-filter-container">
      <div>
        <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="agWithdrawalLimits.invalid">Spara</button>
        <button *ngIf="!show.isEditing" class="button primary" (click)="setEditing(true)">Redigera Placeringar</button>
        <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
      </div>
      <div class="display--flex">
        <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
          <woo-dropdown
          [items]="publishers"
          [(ngModel)]="selectedPublisher"
          name="selectedPublisher"
          inputId="select-publisher"
          labelText="Välj Publicist"
          notFoundText="Inga publicister hittades."></woo-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="tabbable__panel clearfix shadow-z-1">
    <div class="col-xs-12">
      <h3>Estimatfördelning för placeringar</h3>
    </div>
  </div>
</div>

<div class="tabbable__body shadow-z-1">
  <table class="table table-woo table--info table--hover width--100" ngForm #agWithdrawalLimits="ngForm">
    <thead #stickyTableHeader>
      <tr>
        <th [colSpan]="staticFields"></th>
        <th class="text-center" colspan="3">
          Estimatfördelning
        </th>
        <th class="text-center" colspan="3">
          Maxuttag
        </th>
      </tr>
      <tr>
        <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
          {{header}}
        </th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let row of ProductFormatRow; let index = index;">
      <tr [hidden]="!rowVisible(row)">
        <td class="text--thin vertical-align--middle">
          <span>{{row.publisher}}</span>
        </td>
        <td class="text--thin vertical-align--middle">
          <span>{{translateProductFormat(row.productFormat)}}</span>
        </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesPreroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPreroll"
                [(ngModel)]="getProductFormat(row.productFormatId).product_format_shares.preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll"
                (ngModelChange)="autoFill(row, 'preroll')">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesMidroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesMidroll"
                [(ngModel)]="getProductFormat(row.productFormatId).product_format_shares.midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="true">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPostroll"
                [(ngModel)]="getProductFormat(row.productFormatId).product_format_shares.postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>

        <td class="vertical-align--middle">
          <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPreroll | percent: "1.0-3"}}</span>
          <div
            *ngIf="show.isEditing"
            class="input__container">
            <input
              name="row-{{index}}-withdrawalLimitesPreroll"
              [(ngModel)]="getProductFormat(row.productFormatId).withdrawal_limits.preroll"
              wooPercentFormatter
              [wooMinValidator]="0"
              [wooMaxValidator]="1"
              [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
              [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
          </div>
        </td>

        <td class="vertical-align--middle">
          <span *ngIf="!show.isEditing">{{row.withdrawalLimitsMidroll | percent: "1.0-3"}}</span>
          <div
            *ngIf="show.isEditing"
            class="input__container">
            <input
              name="row-{{index}}-withdrawalLimitesMidroll"
              [(ngModel)]="getProductFormat(row.productFormatId).withdrawal_limits.midroll"
              wooPercentFormatter
              [wooMinValidator]="0"
              [wooMaxValidator]="1"
              [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
              [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
          </div>
        </td>

        <td class="vertical-align--middle">
          <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPostroll | percent: "1.0-3"}}</span>
          <div
            *ngIf="show.isEditing"
            class="input__container">
            <input
              name="row-{{index}}-withdrawalLimitesPostroll"
              [(ngModel)]="getProductFormat(row.productFormatId).withdrawal_limits.postroll"
              wooPercentFormatter
              [wooMinValidator]="0"
              [wooMaxValidator]="1"
              [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
              [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
          </div>
        </td>
      </tr>
      </ng-container>

      <tr>
        <td class="vertical-align--middle">
          <strong>Totalt:</strong>
        </td>
        <td></td>
        <ng-container *ngIf="productFormats">
          <ng-container *ngFor="let slotType of slotTypes">
            <td>
              <span *ngIf="sumOfProductFormatShares(slotType) !== 0">
                <strong>{{sumOfProductFormatShares(slotType)}}</strong> %
              </span>
            </td>
          </ng-container>
          <td colspan="3"></td>
        </ng-container>
      </tr>

    </tbody>
  </table>

  <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
  <div *ngIf="show.isEditing" class="margin-top--2x">
    <button class="button primary" (click)="save()" [disabled]="agWithdrawalLimits.invalid">Spara</button>
    <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
  </div>
</div>
