import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { endOfISOWeek, format, parse, startOfISOWeek } from 'date-fns';
import { sv } from 'date-fns/locale';
import { clone, each, find, map, maxBy, range } from 'lodash-es';
import { verticalExpansion } from '../../../utils/animations';
import { generateId } from '../../../utils/string';
import { InventoryFilterOption } from '../../../woo_services.module/InventoryService';

@Component({
  selector: 'inventory-visualization',
  templateUrl: './inventory-visualization.component.html',
  styleUrls: ['./inventory-visualization.component.scss'],
  animations: [verticalExpansion(500)],
})
export class InventoryVisualization implements OnChanges {
  @Input() inventoryTree: Record<string, unknown>;
  @Input() filterOptions: InventoryFilterOption[];
  @Input() columnNames: { [name: string]: string };
  @Input() firstRowInfoText: string;
  @Input() showTooltip;
  @Input() viewCurrencyLabel = 'Filtrering';

  widthLeftForDynamicColumns = 45;
  inventory = [];
  weekIndex = 0;
  visibleLevels = [];
  numberOfColumns = 0;
  ANIMATION_THRESHOLD = 400;

  showTooltipCheck(inventoryRow: { row; column }): string {
    return this.showTooltip ? this.showTooltip(inventoryRow.row, inventoryRow.column) : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventoryTree) {
      this.updateInventory();
    }
  }

  updateInventory(): void {
    if (!this.inventoryTree) {
      return;
    }

    this.numberOfColumns = Object.keys(this.columnNames).length;
    const newInventory = this.flattenTree(this.inventoryTree).filter((n) => !this.isEmptyNode(n));
    newInventory.forEach(this.addIdProperty);
    const maxLevel = maxBy(map(newInventory, 'level'));
    this.visibleLevels = range(maxLevel + 1).map(function () {
      return true;
    });
    this.inventory = newInventory;
  }

  getLevelVisibility(level: number): boolean {
    return this.visibleLevels[level];
  }

  isLastVisibleOrHidden(level: number): boolean {
    if (!this.visibleLevels[level]) {
      return true;
    } else if (this.visibleLevels[level] && !this.visibleLevels[level + 1]) {
      return true;
    } else {
      return false;
    }
  }

  isLastLevel(level: number): boolean {
    return level === this.visibleLevels.length - 1;
  }

  toggleVisibilityForLevelsBelow = function (topLevel: number): void {
    if (topLevel >= this.visibleLevels.length) {
      return;
    }
    const newVisibility = !this.visibleLevels[topLevel + 1];
    for (let index = topLevel + 1; index < this.visibleLevels.length; ++index) {
      this.visibleLevels[index] = newVisibility;
    }
  };

  getIndentation(level: number): number {
    return 12 + level * 20;
  }

  flattenTree(tree: Record<string, unknown>): any[] {
    const _flattenTree = (tree, level: number) => {
      const node = clone(tree);
      node.level = level;
      delete node.children;
      let result = [node];
      if (tree.children.length === 0) {
        return result;
      }
      each(tree.children, function (child) {
        result = result.concat(_flattenTree(child, level + 1));
      });
      return result;
    };
    return _flattenTree(tree, 0);
  }

  private readonly filterClass = {
    weeks: 'table--background-weeks',
    week: 'table--background-week',
    publisher_groups: 'table--background-publisher_groups',
    publishers: 'table--background-publisher',
    slot_types: 'table--background-slot_type',
    film_length: 'table--background-film_length',
    programs: 'table--background-program_format',
    regions: 'table--background-region',
    targets: 'table--background-target',
    categories: 'table--background-category',
    genders: 'table--background-gender',
    ages: 'table--background-age',
    product_formats: 'table--background-product-format',
  };
  getFilterClass(filterKey: string): string {
    return this.filterClass[filterKey] || '';
  }

  getFilterValueName(filterKey: string, filterValueKey: string): string {
    const SPECIAL_NAMES = {
      weeks: function () {
        return 'Totalt';
      },
      week: function (valueKey) {
        return 'V. ' + valueKey.split(':')[1];
      },
    };
    if (filterKey in SPECIAL_NAMES) {
      return SPECIAL_NAMES[filterKey](filterValueKey);
    }
    if (filterKey === 'categories' && filterValueKey === 'unknown') {
      return 'Utan WOO kategori';
    }

    const filterOption = find(this.filterOptions, { filter_key: filterKey });
    if (filterOption) {
      const filterValue = find(filterOption.values, { key: filterValueKey });
      if (filterValue) {
        return filterValue.name;
      }
    }
    return filterValueKey;
  }

  convertPeriodToDateString(period: string, multipleWeeks: boolean): string {
    let firstDay: Date, lastDay: Date;
    if (multipleWeeks) {
      const startDate = period.split('-')[0];
      const endDate = period.split('-')[1];

      const startYear = startDate.split(':')[0];
      const startWeek = startDate.split(':')[1];
      const endYear = endDate.split(':')[0];
      const endWeek = endDate.split(':')[1];

      firstDay = startOfISOWeek(parse(`${startYear}-${startWeek}`, 'R-I', new Date()));
      lastDay = endOfISOWeek(parse(`${endYear}-${endWeek}`, 'R-I', new Date()));
    } else {
      const year = period.split(':')[0];
      const week = period.split(':')[1];
      const aWeekDate = parse(`${year}-${week}`, 'R-I', new Date());

      firstDay = startOfISOWeek(aWeekDate);
      lastDay = endOfISOWeek(aWeekDate);
    }

    return `${format(firstDay, 'PP', { locale: sv })} - ${format(lastDay, 'PP', { locale: sv })}`;
  }

  addIdProperty(obj: { [key: string]: string }): void {
    obj.id = generateId();
  }

  isEmptyNode(node: any): boolean {
    const keys = Object.keys(node);
    return keys.length === 1 && keys[0] === 'level' && node.level === 0;
  }

  trackElement(_index: any, element: any): any {
    return element ? element.id : undefined;
  }

  asIsKeyOrder(_k1: any, _k2: any): number {
    return 0;
  }
}
