import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trackById } from '../../../utils/object';
import { CompactCampaign, wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'campaign-approval-list',
  templateUrl: './campaign-approval-list.component.html',
})
export class CampaignApprovalList {
  @Input() approvableCampaigns: CompactCampaign[];
  @Output() onClick = new EventEmitter<CompactCampaign>();
  reverse: boolean;
  Column = Column;
  column = Column.StartDate;

  selectCampaign(campaign: CompactCampaign, event: Event): void {
    event.stopPropagation();
    this.onClick.emit(campaign);
  }

  trackById(): (_: any, item: { id: wooId }) => wooId {
    return trackById;
  }

  selectSortColumn(columnName: Column, event: Event): void {
    event.stopPropagation();
    this.column = columnName;
    this.reverse = !this.reverse;
  }

  sortApprovableCampaignBy(column: Column, reverse: boolean): CompactCampaign[] {
    return this.approvableCampaigns.sort((a, b) => {
      if (a[column] === b[column]) {
        return 0;
      }

      return a[column] > b[column] ? (reverse ? -1 : 1) : reverse ? 1 : -1;
    });
  }
}

enum Column {
  Name = 'name',
  CustomerName = 'customer_name',
  StartDate = 'start_date',
  Status = 'status',
  AdServerStatus = 'ad_server_status',
}
