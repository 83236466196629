<div class="clearfix">
  <div class="pull-left text--small margin-top-bottom--1x">
    Visar {{((pagination.page - 1) * pagination.perPage) + 1}} - {{(((pagination.page - 1) * pagination.perPage) + pagination.perPage) > pagination.total ? pagination.total : (((pagination.page - 1) * pagination.perPage) + pagination.perPage)}} av {{pagination.total}}
  </div>
  <div class="pull-right">
    <button
      class="pagination {{css}}"
      *ngFor="let page of range; trackBy: trackByPageNumber"
      [ngClass]="{active: pagination.page === page.number}"
      (click)="requestPage(page)"
    >{{page.number}}</button>
  </div>
</div>
