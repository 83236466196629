import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { SystemService } from '../../../../woo_services.module/SystemService';
import { Estimates, TargetGroupService } from '../../../../woo_services.module/TargetGroupService';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import {
  AgeTargetGroup,
  GenderTargetGroup,
  ProductFormat,
  Publisher,
  SlotType,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-target-groups',
  templateUrl: './manage-target-groups.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageTargetGroups {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    estimatesHaveChanged: false,
    loading: true,
    isEditingWithdrawalLimit: false,
  };

  targetGroupRows: TargetGroupRow[] = [];
  weekly_shares: WeekRow[] = [];
  estimates: Estimates;
  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[] = [];
  selectedProductFormat: ProductFormat;
  genders: GenderTargetGroup[];
  ages: AgeTargetGroup[];
  selectedGender: GenderTargetGroup;
  selectedRowForEditing: wooId = null;
  selectedRows: wooId[] = [];

  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  translateProductFormat: (args: any) => string;
  readonly staticFields = 5;
  readonly tableHeaders = ['Kön', 'Ålder', 'Tagg', 'Publicist', 'Placering', 'Preroll', 'Midroll', 'Postroll'];
  readonly tableHeadersWeeklyShares = ['Vecka', 'Preroll', 'Midroll', 'Postroll'];
  shareOfTotal = 0;
  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private targetGroupService: TargetGroupService,
    private dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private systemService: SystemService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.systemService.getValue('target_group_estimate_share_of_total').then((shareOfTotal: number) => {
      this.shareOfTotal = shareOfTotal;
    });
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled, ExtraPublisherField.productFormats)
      .then((publishers) => {
        this.publishers = publishers.filter((p) => p.enabled);
      })
      .then(() => {
        this.productFormatService
          .getProductFormats(ExtraProductFormatField.enabled, ExtraProductFormatField.slotTypes)
          .then((productFormats) => {
            this.productFormats = productFormats.filter((f) => f.enabled);
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
          });
      })
      .then(() => {
        this.getAndBuildTargetGroupEstimates();
      })
      .finally(() => {
        this.show.loading = false;
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
        scrollTo(this.parentHeader);
      });
  }

  async getAndBuildTargetGroupEstimates(): Promise<void> {
    this.targetGroupService.getTargetGroupEstimates().then((estimates) => {
      this.estimates = estimates;
      this.buildEstimatesRows();
    });
  }

  setSelected(row: TargetGroupRow): void {
    const idKey = this.createIdKey(row);
    if (this.selectedRows.includes(idKey)) {
      this.selectedRows = this.selectedRows.filter((row) => !(row === idKey));
    } else {
      this.selectedRows.push(idKey);
    }
  }

  openTab(row: TargetGroupRow): boolean {
    const idKey = this.createIdKey(row);
    return this.selectedRows.includes(idKey) && this.rowVisible(row);
  }

  cancelEditingWeeks(): void {
    this.getAndBuildTargetGroupEstimates();
    this.selectedRowForEditing = null;
  }

  setEditingDetails(row: TargetGroupRow): void {
    this.selectedRowForEditing = this.createIdKey(row);
    this.show.isEditingWithdrawalLimit = false;
  }

  isEditingWeekShares(row: TargetGroupRow): boolean {
    return this.selectedRowForEditing === this.createIdKey(row);
  }

  setEditingWithdrawalLimits(value: boolean): void {
    this.show.isEditingWithdrawalLimit = value;
  }

  cancelEditingWithdrawalLimits(): void {
    this.getAndBuildTargetGroupEstimates();
    this.setEditingWithdrawalLimits(false);
  }

  async saveTargetGroups(): Promise<void> {
    const filterActive = this.selectedGender || this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.dialogService.openBlocking();
        this.targetGroupService
          .updateTargetGroupEstimates(this.estimates)
          .then((estimates) => {
            this.estimates = estimates;
            this.buildEstimatesRows().then(() => {
              this.show.estimatesHaveChanged = true;
            });
          })
          .finally(() => {
            this.getAndBuildTargetGroupEstimates();
            this.setEditingWithdrawalLimits(false);
            this.selectedRowForEditing = null;
            this.dialogService.closeBlocking();
          });
      });
  }

  updateShareOfTotal(): void {
    this.systemService
      .updateShareOfTotal({ target_group_estimate_share_of_total: this.shareOfTotal })
      .then((settings) => {
        if (this.shareOfTotal === settings.target_group_estimate_share_of_total) {
          this.shareOfTotal = settings.target_group_estimate_share_of_total;
          this.show.estimatesHaveChanged = true;
        }
      });
  }

  rowVisible(obj: TargetGroupRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const genderFiltered = this.selectedGender && obj.genderId !== this.selectedGender.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || genderFiltered || productFormatFiltered);
  }

  getGenderName(genderId: wooId): string {
    return this.genders.find((gender) => gender.id === genderId).name;
  }

  getPublisherName(publisherId: wooId): string {
    return this.publishers.find((publisher) => publisher.id === publisherId).name;
  }

  getProductFormatName(productFormatId: wooId): string {
    return this.translationService.convertProductFormat(
      this.productFormats.find((format) => format.id === productFormatId).name,
    );
  }

  getAgeName(ageId: wooId): string {
    if (!ageId) {
      return '';
    }
    return this.ages.find((age) => age.id === ageId).name;
  }

  private createIdKey(row: TargetGroupRow) {
    let idKey = `${row.productFormatId}-${row.genderId}`;

    if (row.ageId !== null) {
      idKey += `-${row.ageId}`;
    }

    return idKey;
  }

  private getTagName(obj: { tag: string; legacy_tag?: string }): string {
    return obj.legacy_tag ? `(${obj.tag} eller ${obj.legacy_tag})` : obj.tag;
  }

  private async buildEstimatesRows(): Promise<void> {
    this.targetGroupService.getGendersAndAges().then(([ages, genders]) => {
      this.genders = genders;
      this.ages = ages;

      let targetGroupCombinations: TargetGroupRow[] = [];
      targetGroupCombinations = this.getGenderAndAgeTargetGroups(genders, ages);
      targetGroupCombinations = this.addPublishersToTargetGroups(targetGroupCombinations);
      targetGroupCombinations = this.addWithdrawalLimitsToTargetGroups(targetGroupCombinations);
      this.targetGroupRows = targetGroupCombinations;
    });
  }

  private getGenderAndAgeTargetGroups(genders: GenderTargetGroup[], ages: AgeTargetGroup[]): TargetGroupRow[] {
    const targetGroupCombinations: TargetGroupRow[] = [];
    genders.forEach((gender) => {
      const genderWithoutAges = { genderId: gender.id, ageId: null, tag: this.getTagName(gender) };
      targetGroupCombinations.push(genderWithoutAges);
      ages.forEach((age) => {
        const genderWithAges = {
          genderId: gender.id,
          ageId: age.id,
          tag: `${this.getTagName(gender)} och ${this.getTagName(age)}`,
        };
        targetGroupCombinations.push(genderWithAges);
      });
    });
    return targetGroupCombinations;
  }

  private addPublishersToTargetGroups(targetGroupCombinations: TargetGroupRow[]): TargetGroupRow[] {
    const withPublishers: TargetGroupRow[] = this.productFormats.flatMap((format) => {
      return targetGroupCombinations.map((tg) => {
        return { ...tg, publisherId: format.publisher_id, productFormatId: format.id };
      });
    });
    return withPublishers;
  }

  private addWithdrawalLimitsToTargetGroups(targetGroupCombinations: TargetGroupRow[]): TargetGroupRow[] {
    return targetGroupCombinations.map((tg) => {
      if (!tg.ageId) {
        return {
          ...tg,
          weekly_shares: this.addSharesToWeekIndex(this.estimates.shares[tg.productFormatId], tg.genderId, null),
          withdrawalLimitsPreroll: this.estimates.withdrawal_limits[tg.productFormatId][tg.genderId].withdrawal_limits
            .preroll,
          withdrawalLimitsMidroll: this.estimates.withdrawal_limits[tg.productFormatId][tg.genderId].withdrawal_limits
            .midroll,
          withdrawalLimitsPostroll: this.estimates.withdrawal_limits[tg.productFormatId][tg.genderId].withdrawal_limits
            .postroll,
        };
      } else {
        return {
          ...tg,
          weekly_shares: this.addSharesToWeekIndex(this.estimates.shares[tg.productFormatId], tg.genderId, tg.ageId),
          withdrawalLimitsPreroll: this.estimates.withdrawal_limits[tg.productFormatId][tg.genderId].ages[tg.ageId]
            .withdrawal_limits.preroll,
          withdrawalLimitsMidroll: this.estimates.withdrawal_limits[tg.productFormatId][tg.genderId].ages[tg.ageId]
            .withdrawal_limits.midroll,
          withdrawalLimitsPostroll: this.estimates.withdrawal_limits[tg.productFormatId][tg.genderId].ages[tg.ageId]
            .withdrawal_limits.postroll,
        };
      }
    });
  }

  private addSharesToWeekIndex(weekly_shares, gender_id, age_id): WeekRow[] {
    const allWeeks: WeekRow[] = [];

    Object.keys(weekly_shares).forEach((week) => {
      if (weekly_shares.hasOwnProperty(week)) {
        const weekData = weekly_shares[week];
        const weekRow: WeekRow = {
          week: parseInt(week, 10),
        };

        if (!age_id) {
          weekRow.sharesPreroll = weekData[gender_id].shares.preroll;
          weekRow.sharesMidroll = weekData[gender_id].shares.midroll;
          weekRow.sharesPostroll = weekData[gender_id].shares.postroll;
        } else {
          weekRow.sharesPreroll = weekData[gender_id].ages[age_id].shares.preroll;
          weekRow.sharesMidroll = weekData[gender_id].ages[age_id].shares.midroll;
          weekRow.sharesPostroll = weekData[gender_id].ages[age_id].shares.postroll;
        }

        allWeeks.push(weekRow);
      }
    });

    return allWeeks;
  }
}

interface WeekRow {
  week: number;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
}

interface TargetGroupRow {
  genderId: wooId;
  ageId: wooId;
  tag: string;
  publisherId?: wooId;
  productFormatId?: wooId;
  weekly_shares?: WeekRow[];
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
