import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreativeAssetService } from '../../../woo_services.module/CreativeAssetService';
import { CreativeAsset, CreativeAssetType, wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'customer-asset-list',
  templateUrl: './customer-asset-list.component.html',
  styleUrls: ['./customer-asset-list.component.scss'],
})
export class CustomerAssetList implements OnInit {
  @Input() customerId: wooId;
  @Output() onSelect = new EventEmitter<CreativeAsset>();
  @Output() onAbort = new EventEmitter<void>();

  assets: CreativeAsset[] = [];
  selectedAsset: CreativeAsset = null;
  loading = true;

  constructor(private creativeAssetService: CreativeAssetService) {}

  ngOnInit(): void {
    this.creativeAssetService.getAll(this.customerId).then((assets) => {
      this.assets = assets.filter((asset) => asset.asset_type === CreativeAssetType.Video);
      this.loading = false;
    });
  }

  toggleAsset(asset: CreativeAsset): void {
    this.selectedAsset = this.selectedAsset === asset ? null : asset;
  }

  confirm(): void {
    this.onSelect.emit(this.selectedAsset);
  }

  cancel(): void {
    this.onAbort.emit();
  }
}
