import { Directive, Input } from '@angular/core';
import { generateId } from '../../../utils/string';
import { SimpleChanges } from '../../../utils/types';
import { calculateBudget, calculateCompletedViews } from '../../../utils/utils';
import { WooCurrencyValue } from '../../../woo_components.module/pipes/woo-currency.pipe';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { ShareType, Targeting, ViewCurrency, wooId } from '../../../woo_services.module/shared-types';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';
import { Distributable } from './distribution-table.component';

@Directive()
export abstract class AbstractDistribution<T extends DistributionStruct> extends SubscriptionHandler {
  readonly instanceId = generateId();
  readonly ShareType = ShareType;

  @Input() targetingId: wooId;

  distributables: Distributable[] = [];
  distributed = 0;
  useWooDistribution = true;
  shareType: ShareType = ShareType.WOO;
  calculateFromBudget = true;
  targeting: Targeting;
  currency: string;
  currencyShort: string;
  distributionCurrency: WooCurrencyValue;

  abstract distributionName: string;
  abstract disabled: boolean;

  protected constructor(protected bookingStore: BookingStore, protected formatterService: FormatterService) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  get totalToDistribute(): number {
    if (!this.targeting) {
      return 0;
    }

    return this.shareType === ShareType.Budget
      ? this.targeting.budget + this.targeting.additional_budget
      : this.targeting.budgeted_impressions + this.targeting.additional_budgeted_impressions;
  }

  ngOnChanges(changes: SimpleChanges<AbstractDistribution<any>>): void {
    if (changes.targetingId && this.targetingId) {
      this.initFromStore(this.bookingStore.state);
    }
  }

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (!targeting) {
      return;
    }

    this.targeting = targeting;
    this.useWooDistribution = this.getShareType(targeting) === ShareType.WOO;
    this.shareType = this.getShareType(targeting);
    this.distributionCurrency = this.getDistributionCurrency(this.shareType, targeting.view_currency);
    this.calculateFromBudget = targeting.calculate_from_budget;
    this.currency = this.formatterService.getCurrencyName(targeting);
    this.currencyShort = this.formatterService.getCurrencyName(targeting, true, 'short');
    const part = model.estimation ? model.estimation.parts.find((p) => p.targeting_id === targeting.id) : null;
    const price = part ? Number(part.net_cpm) : null;
    this.distributables = this.getDistributionStructs(targeting).map((struct) =>
      this.createDistributable(
        struct,
        this.getBudget(struct, this.shareType, price),
        this.getImpressions(struct, this.shareType, price),
        this.getPercent(struct, this.shareType),
      ),
    );
    this.distributed = this.distributables
      .map((r) => (this.shareType === ShareType.Budget ? r.budget : r.completedViews) || 0)
      .reduce((sum, value) => sum + Number(value), 0);
  };

  abstract getShareType(targeting: Targeting): ShareType;

  abstract getDistributionStructs(targeting: Targeting): T[];

  abstract updateDistribution(structs: T[]): void;

  abstract shareTypeChanged(newShareType: ShareType): void;

  abstract createDistributable(struct: T, budget: number, completedViews: number, percent: number): Distributable;

  protected getBudget(struct: T, shareType: ShareType, price: number | null): number {
    return shareType === ShareType.Budget ? struct.budget : price ? calculateBudget(struct.impressions, price) : null;
  }

  protected getImpressions(struct: T, shareType: ShareType, price: number | null): number {
    return shareType === ShareType.Impressions
      ? struct.impressions
      : price
      ? calculateCompletedViews(struct.budget, price)
      : null;
  }

  protected getPercent(struct: T, shareType: ShareType): number {
    const amount = shareType === ShareType.Budget ? struct.budget : struct.impressions;
    return amount && this.totalToDistribute ? amount / this.totalToDistribute : null;
  }

  protected getDistributionCurrency(shareType: ShareType, targetingViewCurrency: ViewCurrency): WooCurrencyValue {
    if (shareType === ShareType.Budget) {
      return WooCurrencyValue.Budget;
    } else {
      return this.viewCurrencyToWooCurrencyValue(targetingViewCurrency);
    }
  }

  private viewCurrencyToWooCurrencyValue(viewCurrency: ViewCurrency): WooCurrencyValue {
    if (viewCurrency === ViewCurrency.completedViews) {
      return WooCurrencyValue.CompletedViews;
    } else if (viewCurrency === ViewCurrency.grossRatingViews) {
      return WooCurrencyValue.GrossRatingViews;
    } else {
      return WooCurrencyValue.Impressions;
    }
  }
}

export interface DistributionStruct {
  budget?: number;
  impressions?: number;
}
