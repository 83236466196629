import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TemporaryFileService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getFilenameFromHeaders(headers: HttpHeaders): string {
    return /filename="(.*)"/g.exec(headers.get('Content-Disposition'))[1];
  }

  download(id: string | number): Promise<NamedBlob> {
    return lastValueFrom(
      this.http.get(this.env.apiUrl + '/temporary_files/' + id, {
        responseType: 'blob',
        observe: 'response',
      }),
    ).then((res) => ({
      blob: res.body,
      name: this.getFilenameFromHeaders(res.headers),
    }));
  }
}

export interface NamedBlob {
  blob: Blob;
  name: string;
}
