<woo-dialog>
  <h2>Support</h2>
  <h4 class="thin">
    Behöver du hjälp med din bokning? Ring <a href="tel:{{supportPhone}}" class="text-info">{{supportPhone}}</a>
  </h4>
  <h4 class="thin">För mer info, besök <a [routerLink]="['/help']" class="text-info">hjälpsidan</a>.</h4>

  <h4 class="thin" *ngIf="!feedbackSent">
    Har du tekniska problem, fyll i formuläret nedan.
  </h4>
  <div class="inner-block">
    <div *ngIf="!feedbackSent">
      <form #feedbackForm="ngForm" (ngSubmit)="sendFeedback(feedback)">
        <div class="input__container" *ngIf="!isLoggedIn">
          <label for="name">Vad heter du?</label>
          <input id="name" name="name"  type="text" [required]="!isLoggedIn" [(ngModel)]="feedback.name">
        </div>
        <div class="input__container" *ngIf="!isLoggedIn">
          <label for="email">Hur kan vi kontakta dig?</label>
          <input id="email" name="email"  type="text" [required]="!isLoggedIn" [(ngModel)]="feedback.email">
        </div>
        <div class="input__container">
          <label for="desc">Beskriv problemet med en mening</label>
          <input id="desc" name="desc"  type="text" required [(ngModel)]="feedback.problem_description">
        </div>
        <div class="input__container">
          <label for="where">Var uppstår problemet?</label>
          <input id="where" name="where"  type="text" required [(ngModel)]="feedback.problem_area">
        </div>
        <div class="input__container">
          <label for="exp">Vad förväntas hända?</label>
          <textarea id="exp" name="exp"  required [(ngModel)]="feedback.expected_outcome" row="3"></textarea>
        </div>
        <div class="input__container">
          <label for="got">Vad händer istället?</label>
          <textarea id="got" name="got"  required [(ngModel)]="feedback.actual_outcome" row="3"></textarea>
        </div>
        <button class="button primary-1" type="submit" [disabled]="!feedbackForm.valid">Skicka</button>
      </form>
    </div>
    <div *ngIf="feedbackSent" class="text-center">
      <h3 class="thin">
        <span class="icon-ok text-info"></span><br>
        Tack för att du hjälper oss göra WOO ännu bättre!
      </h3>
    </div>
  </div>
</woo-dialog>
