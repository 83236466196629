<div class="body full-width-view-mobile">
  <div class="max-width--container">
    <h1>Summering</h1>
    <div class="summary-container">
      <div class="col-xs-12 col-md-6 padding--none__phone">
        <name-period-header [name]="campaign.name" [start]="targeting.start_date" [end]="targeting.end_date"></name-period-header>
        <video width="width--100" controls [src]="creative.asset_url"></video>
      </div>
      <div class="col-xs-12 col-md-6 targeting-container">
        <simple-targeting-summary [targeting]="targeting"></simple-targeting-summary>
        <div class="divider margin-top-bottom--1x"></div>
        <p><span>Antal visningar: </span><span class="text--strong">{{estimation.completedViews | number}} st</span></p>
        <p><span>Totalkostnad: </span><span class="text--strong">{{targeting.budget | currency:'SEK'}}</span></p>
      </div>
    </div>

    <h1>Välj betalningssätt</h1>
    <p>Observera att du endast betalar för de visningar där tittaren sett hela din reklamfilm. Övriga starter får du gratis. </p>
    <div class="row margin--none__phone">
      <payment-radio-box
        class="col-sm-6 col-xs-12 display--inline-block margin-bottom--4x padding--none__phone"
        headline="Betala med faktura"
        text="Fakturan skickas på kampanjperiodens första dag till din mailadress. Läs mer om betalningsvillkor i Allmänna Villkor."
        [value]="PaymentMethod.invoice"
        [(model)]="selectedPaymentMethod"
      ></payment-radio-box>
    </div>

    <h1 class="margin-bottom--1x">Godkänn våra villkor</h1>
    <div class="divider medium"></div>
    <div class="accept-terms-content long-label">
      <input type="checkbox" id="accept-terms" name="terms" value="true" [(ngModel)]="acceptTerms" class="checkbox">
      <label class="checkbox" for="accept-terms">Ja, jag godkänner de <a [href]="getUrlToFile(FileType.cashUserTerms)" target="_blank">allmänna villkoren</a> och <a [href]="getUrlToFile(FileType.dataPolicy)" target="_blank">datapolicyn</a> för att publicera en kampanj via WOO Manager.</label>
    </div>
    <div class="divider medium margin-bottom--2x"></div>

    <div class="row margin--none__phone">
      <context-message class="col-md-7 col-xs-12 padding--none__phone margin-bottom--3x" message="Ett bekräftelsemail kommer i samband med bokning att skickas till din angivna mailadress. Observera att din bokning är bindande." messageType="info"></context-message>
    </div>

    <div class="button-container">
      <button class="button secondary margin-right--2x" (click)="abort()" id="abort-btn">Tillbaka</button>
      <button class="button primary-1 margin-left--none" (click)="book()" [disabled]="!bookable()" id="book-campaign-btn">Boka</button>
    </div>
  </div>
</div>
