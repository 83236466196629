import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../woo_services.module/DialogService';
import { ExternalCampaign, ExternalCampaignService } from '../services/ExternalCampaignService';
import { DetailsDialog } from './details-dialog.component';

@Component({
  selector: 'external-campaigns-page',
  templateUrl: './external-campaigns-page.component.html',
})
export class ExternalCampaignsPage implements OnInit {
  activeCampaigns: ExternalCampaign[] = [];
  endedCampaigns: ExternalCampaign[] = [];
  invalidCampaigns: ExternalCampaign[] = [];
  showDetails = false;
  tabVisible = 1;
  loadingActiveCampaigns = true;
  loadingInvalidCampaigns = true;
  loadingEndedCampaigns = true;
  endedCampaignPagination = {
    page: 1,
    total: 0,
    perPage: 0,
    last: 0,
  };

  constructor(private externalCampaignService: ExternalCampaignService, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.externalCampaignService.getActiveCampaigns().then((activeCampaigns) => {
      this.activeCampaigns = activeCampaigns;
      this.loadingActiveCampaigns = false;
    });

    this.externalCampaignService.getInvalidCampaigns().then((invalidCampaigns) => {
      this.invalidCampaigns = invalidCampaigns;
      this.loadingInvalidCampaigns = false;
    });

    this.loadEndedCampaigns(1);
  }

  loadEndedCampaigns(page: number): void {
    this.loadingEndedCampaigns = true;
    this.externalCampaignService.getEndedCampaigns(page).then(([campaigns, pagination]) => {
      this.endedCampaigns = campaigns;
      this.endedCampaignPagination = pagination;
      this.loadingEndedCampaigns = false;
    });
  }

  toggleReduceImpressions(campaign: ExternalCampaign): void {
    campaign.reduce_impressions = !campaign.reduce_impressions;
    this.externalCampaignService.setReduceImpressions(campaign);
  }

  openDetails(campaign: ExternalCampaign): void {
    this.dialogService.create(DetailsDialog).show(campaign, this.invalidCampaigns);
  }
}
