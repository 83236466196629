import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '.toggleTab',
})
export class ToggleTab implements OnInit {
  private readonly closeClass = 'closed';
  private chevronIcon: ElementRef;
  openTab = true;

  constructor(private toggleTrigger: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.openTab = this.isOpen();
    this.chevronIcon = this.toggleTrigger.nativeElement.querySelector('.chevron');
    this.updateChevron();
  }

  @HostListener('click', ['$event'])
  clickEvent(): void {
    this.openTab = !this.openTab;
    this.toogleClose();
  }

  private toogleClose() {
    if (this.isOpen()) {
      this.renderer.addClass(this.toggleTrigger.nativeElement, this.closeClass);
    } else {
      this.renderer.removeClass(this.toggleTrigger.nativeElement, this.closeClass);
    }
    this.updateChevron();
  }

  private isOpen(): boolean {
    return !this.toggleTrigger.nativeElement.classList.contains(this.closeClass);
  }

  private updateChevron() {
    if (this.openTab) {
      this.renderer.addClass(this.chevronIcon, 'bottom');
    } else {
      this.renderer.removeClass(this.chevronIcon, 'bottom');
    }
  }
}
