import { AfterViewInit, Component, ViewContainerRef } from '@angular/core';
import { DialogService } from '../../woo_services.module/DialogService';

@Component({
  selector: 'woo-dialog-container',
  template: '',
})
/**
 * For now, this component needs to be added to every route root component that need to use dynamic dialogs
 * When the migration to angular 2+ is done, it can be placed in the angular application root component.
 * Or if maybe ApplicationRef.components[0] can be used instead.
 */
export class WooDialogContainer implements AfterViewInit {
  constructor(private viewContainerRef: ViewContainerRef, private dialogService: DialogService) {}

  ngAfterViewInit(): void {
    this.dialogService.setRootViewContainerRef(this.viewContainerRef);
  }
}
