<div class="section block">
  <h1>Byråöversikt</h1>

  <div class="account__card">
    <div class="container padding--none">
      <h3 class="thin">Filtrera</h3>
      <div class="row">


        <div class="col-12 col-sm-6 col-lg-5">
          <div *ngIf="!hasLoaded.agencies">
            <dot-loader [visibility]="true" description="Hämtar byråer"></dot-loader>
          </div>
          <woo-dropdown
            *ngIf="hasLoaded.agencies"
            [items]="agencies"
            [(ngModel)]="request.agency"
            (ngModelChange)="agencyChanged($event)"
            bindLabel="nickname"
            inputId="agency-search"
            labelText="Byrå"
            [searchAttributes]="['name', 'nickname']"
            notFoundText="Inga byråer hittades."
          ></woo-dropdown>
        </div>
        <div class="col-12 col-sm-6 col-lg-5">
          <woo-dropdown
            *ngIf="request?.agency?.customers?.length"
            [items]="request?.agency?.customers"
            [(ngModel)]="request.customer"
            bindLabel="name"
            inputId="customer-search"
            labelText="Kund"
            [searchAttributes]="['name', 'nickname']"
            notFoundText="Inga kunder hittades."
            [clearable]="true"
          ></woo-dropdown>
        </div>
      </div>

      <br>

      <div class="row">

        <div class="col-12 clearfix">
          <input id="ongoingCmp" type="radio" [(ngModel)]="request.dateType" [value]="DateType.StartDate" class="radio">
          <label class="radio" for="ongoingCmp">Visa kampanjer som <span class="strong">startar</span> under vald period</label>
        </div>

        <div class="col-12">
          <input id="bookedCmp" type="radio" [(ngModel)]="request.dateType" [value]="DateType.BookedDate" class="radio">
          <label class="radio" for="bookedCmp">Visa kampanjer som <span class="strong">bokats</span> under vald period</label>
        </div>

        <div class="col-12">
          <input id="endedCmp" type="radio" [(ngModel)]="request.dateType" [value]="DateType.EndDate" class="radio">
          <label class="radio" for="endedCmp">Visa kampanjer som <span class="strong">avslutas</span> under vald period</label>
        </div>
      </div>

      <h3 class="thin">Datum</h3>
      <period-select
        [ngModel]="request"
        [firstSelectableDate]="MIN_DATE"
        [defaultInitDate]="InitialDate.Current"
        (ngModelChange)="periodChanged($event)" >
      </period-select>
      <button class="button margin-top--3x" (click)="getData(request)">Hämta data</button>
    </div>

  </div>
  <div #overviewSection id="totalOverview">
    <div class="container padding--none" *ngIf="show.overviewSection">

      <div class="grid-container">
        <div class="grid-item">
          <div class="account__card fixed--height">
            <div class="container padding--none">
              <div class="col-12 text--center border--info">
                <div class="status-badge active">
                  <span class="icon icon-TV4_049_direktbetalning_internetbank icon__large"></span>
                </div>
                <div class="text--large thin">
                  Intäkter
                </div>
                <span class="text--xl text--info text--strong">{{statistics.counts.booked_budget | wooCurrency:'budget'}}</span>
                <div>
                  <span class="text-large text-strong">{{statistics.counts.estimated_budget | wooCurrency:'budget'}} estimerat för perioden</span>
                </div>
                <div class="status-badge active">
                  <span class="icon icon-TV4_048_fakturabetalning icon__large "></span>
                </div>
                <div class="text--large thin">
                  Mediebyråersättning
                </div>
                <span class="text--xl text--info text--strong">{{statistics.counts.booked_discount | wooCurrency:'budget'}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="account__card fixed--height">
            <div class="icon-container">
              <div class="text--center font">
                <div class="status-badge success text--center">
                  <span class="icon icon-check icon__large"></span>
                </div>
                <div class="text--large thin">
                  Bokade
                </div>
                <div class="text--xl text--success text--strong text--center">{{statistics.counts.booked_count}} st</div>
              </div>

              <div class="text--center border--info font">
                <div class="status-badge cta">
                  <span class="icon icon-TV4_005_spara icon__large"></span>
                </div>
                <div class="text--large thin">
                  Sparade
                </div>
                <span class="text--xl text--cta  text--strong">{{statistics.counts.saved_count}} st</span>
              </div>

              <div class="text--center font">
                <div class="status-badge error ">
                  <span class="icon icon-close-x icon__large"></span>
                </div>
                <div class="text--large thin">
                  Avbokade
                </div>
                <span class="text--xl text--danger text--strong">{{statistics.counts.cancelled_count}} st</span>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="margin--none">
            <div class="account__card give-me-some-400px-min-height">
              <h3 class="thin">Topplista</h3>
              <table class="table table--woo table--info table--hover">
                <thead>
                  <tr class="text--medium">
                    <th></th>
                    <th>Namn</th>
                    <th>Roll</th>
                    <th>Antal</th>
                    <th>Total budget</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of bookingTopList, index as index">
                    <td><span class="icon-User text--info"></span> {{index + 1}}.</td>
                    <td>{{person.name}}</td>
                    <td>{{person.type}}</td>
                    <td>{{person.count}}</td>
                    <td>{{person.budget | wooCurrency:'budget'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="margin--none">
            <div class="account__card give-me-some-400px-min-height">
              <h3 class="thin">Bokningarna gjordes av</h3>
              <div *ngIf="request!==undefined">
                <div class="chart-wrapper">
                  <canvas baseChart
                  [data]="bookingStats.counts"
                  [labels]="bookingStats.types"
                  [options]="doughnutOptions"
                  [colors]="doughnutColors"
                  chartType="doughnut"
                  >
                  </canvas>
                  <canvas *ngIf="pieChartUnit==='st'"
                          baseChart
                          [data]="bookingStats.counts"
                          [labels]="bookingStats.types"
                          [options]="doughnutOptions"
                          [colors]="doughnutColors"
                          chartType="doughnut"
                  >
                  </canvas>
                  <canvas *ngIf="pieChartUnit==='kr'"
                          baseChart
                          [data]="bookingStats.budgets"
                          [labels]="bookingStats.types"
                          [options]="doughnutOptions"
                          [colors]="doughnutColors"
                          chartType="doughnut"
                  >
                  </canvas>
                </div>
              </div>
              <div class="text-align--right">
                <span class="margin--none animate text--medium thin" [ngClass]="{'text-muted': pieChartUnit === ChartUnit.Count}">Visa budget (kr)</span>
                <div class="toggle__container">
                  <input type="checkbox" id="displayType" name="checkbox" class="cbx hidden"
                  [checked]="pieChartUnit === ChartUnit.Count" (change)="togglePieChartUnit()">
                  <label class="lbl center" for="displayType" ></label>
                </div>
                <span class="margin--none animate thin" [ngClass]="{'text-muted': pieChartUnit === ChartUnit.Budget}">Visa antal (st)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container padding--none" *ngIf="show.overviewSection">
      <div class="row">


      </div>

      <div class="row">
        <div class="col-12 margin--none">
          <div class="account__card ">
            <h3 class="thin">Alla bokade kampanjer
              <info-badge>
                Kolumnen <strong>Snittsumma för period</strong> är ett estimat ​på​ hur mycket av kampanjens budget som ligger innanför perioden du angav.
                Det är uträknat genom att ta genomsnittspriset per kampanjdag gånger antalet kampanjdagar som faller inom perioden.
                Detta gäller för kampanjer som pågår efter den angivna periodens slut.
                För kampanjer som slutar inom perioden är <strong>Summa</strong> därför detsamma som <strong>Snittsumma för period</strong>.
              </info-badge>
            </h3>
            <table class="table table--woo table--info table--hover table--small">
              <thead>
                <tr class="text--medium">
                  <th (click)="column='name'; reverse=!reverse">Namn<span class="icon-TV4_011_ner" *ngIf="column==='name'" [ngClass]="{'rotate': reverse}"></span></th>
                  <th (click)="column='type'; reverse=!reverse">Bokad av<span class="icon-TV4_011_ner" *ngIf="column==='type'" [ngClass]="{'rotate': reverse}"></span></th>
                  <th (click)="column='budget'; reverse=!reverse">Summa<span class="icon-TV4_011_ner" *ngIf="column==='budget'" [ngClass]="{'rotate': reverse}"></span></th>
                  <th (click)="column='agency_discount'; reverse=!reverse">MB-ersättning<span class="icon-TV4_011_ner" *ngIf="column==='agency_discount'" [ngClass]="{'rotate': reverse}"></span></th>
                  <th (click)="column='administrative_charge'; reverse=!reverse">Admin-ersättning<span class="icon-TV4_011_ner" *ngIf="column==='administrative_charge'" [ngClass]="{'rotate': reverse}"></span></th>
                  <th (click)="column='period_estimate'; reverse=!reverse">Snittsumma för period<span class="icon-TV4_011_ner" *ngIf="column==='period_estimate'" [ngClass]="{'rotate': reverse}"></span></th>
                  <th (click)="column='estimated_agency_discount'; reverse=!reverse">Varav MB-ersättning<span class="icon-TV4_011_ner" *ngIf="column==='estimated_agency_discount'" [ngClass]="{'rotate': reverse}"></span></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let campaign of sortedCampaigns">
                  <td>{{campaign.name}}</td>
                  <td>{{formatNameAndType(campaign.booked_by.name, campaign.booked_by.type)}}</td>
                  <td>{{campaign.budget | wooCurrency:'budget'}}</td>
                  <td>{{campaign.agency_discount | wooCurrency:'budget'}}</td>
                  <td>{{campaign.self_service_compensation | wooCurrency:'budget'}}</td>
                  <td>{{campaign.period_estimate | wooCurrency:'budget'}}</td>
                  <td>{{campaign.estimated_agency_discount | wooCurrency:'budget'}}</td>
                </tr>
                <tr class="text--medium ">
                  <td class="">Totalt</td>
                  <td></td>
                  <td>{{statistics.counts.booked_budget | wooCurrency:'budget'}}</td>
                  <td>{{statistics.counts.booked_discount | wooCurrency:'budget'}}</td>
                  <td>{{statistics.counts.charge_sum| wooCurrency:'budget'}}</td>
                  <td>{{statistics.counts.estimated_budget | wooCurrency:'budget'}}</td>
                  <td>{{statistics.counts.estimated_agency_discount | wooCurrency:'budget'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
