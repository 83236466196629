import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreativeService } from '../CreativeService';
import { EnvironmentService } from '../EnvironmentService';
import { CodeErrors, CodeService } from '../shared-types';
import { VideoCodeService } from './VideoCodeService';

@Injectable({ providedIn: 'root' })
export class PeachService extends VideoCodeService implements CodeService {
  protected readonly adtooxPrefix = 'S';
  protected readonly peachPrefix = 'E';
  protected readonly typeCodeRangePeach = { min: 1, max: 7 };
  protected readonly typeCodeRangeAdToox = { min: 1, max: 9 };
  protected readonly lengthIndexes = { start: 6, end: 7 };

  constructor(creativeService: CreativeService, private env: EnvironmentService, private http: HttpClient) {
    super(creativeService);
  }

  /**
   * Validates peach/adtoox codes with the explicit format
   *  S/E  – Ska alltid vara statiskt S vilket representerar landskod för Sverige för Adtoox
   *       eller statiskt E ifall koden är för Peach
   *  1  – Alltid en siffra. Kan vara siffror mellan 1-7 och anger innehållskod
   *       såsom t.ex. spot, sponsorship, promotion.
   *  FZ – Alltid två tecken, annonsörskod.
   *  NZ – Alltid två tecken, varumärkeskod.
   *  10 – Alltid två tecken som representerar filmlängden i sekunder. Om längden
   *       är större än 99 så visas XX.
   *  07 – Alltid två tecken som representerar versionen, räknar från 00 till ZZ.
   */
  validate = (code: string): CodeErrors => {
    const errors = {} as CodeErrors;
    errors.prefix = this.prefixError(code, this.adtooxPrefix, this.peachPrefix);
    const typeCodeRange = code.charAt(0) === 'S' ? this.typeCodeRangePeach : this.typeCodeRangeAdToox;
    errors.typeCode = this.typeCodeError(code, typeCodeRange.min, typeCodeRange.max);
    errors.codeLength = this.codeLengthError(code);
    errors.version = this.versionError(code);
    errors.whiteSpace = this.whiteSpaceError(code);
    errors.filmLength = this.filmLengthError(
      code,
      this.creativeService.getCreativeLengths(),
      this.lengthIndexes.start,
      this.lengthIndexes.end,
    );
    return this.removeBlankKeys(errors);
  };

  getFilmLength(code: string): number | null {
    const canReadLengthFromCode = code?.length > this.lengthIndexes.end;
    if (!code || !canReadLengthFromCode) {
      return null;
    }
    return this.getVideoCodeFilmLength(code, this.lengthIndexes.start, this.lengthIndexes.end);
  }
}
