import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../woo_services.module/RoutingService';

const template = /* html */ `
  <div class="block">
    <div *ngIf="token">
      <h1>Uppdatera lösenord</h1>
      <p>Du behöver uppdatera ditt lösenord innan du kan logga in.</p>
      <update-password-form [token]="token"></update-password-form>
    </div>
  </div>
`;

@Component({
  selector: 'update-password',
  template: template,
})
export class UpdatePassword {
  token: string;
  constructor(private route: ActivatedRoute, private routingService: RoutingService) {}

  ngOnInit(): void {
    this.token = this.routingService.getQueryParam(this.route, 'token');
  }
}
