import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AuthService } from '../../../woo_services.module/AuthService';
import { Voucher, wooId } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

export enum ListVouchersMode {
  select = 'select',
  edit = 'edit',
  readOnly = 'readOnly',
}

@Component({
  selector: 'vouchers-list',
  templateUrl: './vouchers-list.component.html',
  styleUrls: ['./vouchers-list.component.scss'],
})
export class VouchersList implements OnChanges {
  @Input() vouchers: Voucher[] = [];
  @Input() selectedId: wooId;
  @Input() showUsedVouchers = false;
  @Input() mode = ListVouchersMode.edit;
  @Input() disabledVoucherIds = new Set<wooId>();
  @Output() selectedIdChange = new EventEmitter<wooId>();
  @Output() onDelete = new EventEmitter<Voucher>();

  readonly convertDiscount = this.translationService.convertDiscount;
  readonly modes = ListVouchersMode;
  readonly isAdminOrPlannerOrSales = this.authService.adminOrPlanner() || this.authService.sales();

  visibleVouchers: Voucher[] = [];

  constructor(private translationService: TranslationService, private authService: AuthService) {}

  ngOnChanges(): void {
    if (this.vouchers) {
      this.visibleVouchers = this.showUsedVouchers
        ? this.vouchers
        : this.vouchers.filter((voucher) => !voucher.campaign);
    } else {
      this.visibleVouchers = [];
    }
  }

  delete = (voucher: Voucher): void => {
    this.onDelete.emit(voucher);
  };

  select = (voucher: Voucher): void => {
    if (this.isDisabled(voucher)) {
      return;
    }
    this.selectedId = this.selectedId === voucher.id ? null : voucher.id;
    this.selectedIdChange.emit(this.selectedId);
  };

  isDisabled = (voucher: Voucher): boolean => {
    return this.disabledVoucherIds.has(voucher.id);
  };
}
