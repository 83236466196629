import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { generateId } from '../../utils/string';

const template = `
<div class="pointer box" (click)="selected()">
  <input class="radio" id="radio-button-box-{{id}}" type="radio" [(ngModel)]="model" [value]="value" [disabled]="disabled">
  <label class="radio" for="radio-button-box-{{id}}" tabIndex="0">{{text}}</label>
</div>
`;

@Component({
  selector: 'radio-button-box',
  template: template,
  styleUrls: ['./radio-button-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonBox),
      multi: true,
    },
  ],
})
export class RadioButtonBox implements ControlValueAccessor {
  @Input() value: any;
  @Input() text: string;

  id = generateId();
  model = null;
  disabled = false;

  selected = (): void => {
    if (this.disabled) {
      return;
    }

    this.model = this.value;
    this.propagateOnChange(this.model);
  };

  writeValue(obj: any): void {
    this.model = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateOnChange = (_: any) => null;
  private propagateOnTouch = () => null;
}
