import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Editable, Hidden, SimpleChanges } from 'app/scripts/utils/types';
import { InstreamCreativeFormLayoutProfile } from 'app/scripts/woo_components.module/instream_creative_form/instream-creative-form.component';
import { CampaignService } from 'app/scripts/woo_services.module/CampaignService';
import {
  FetchPlacementsJobResult,
  MrmCreativeInstance,
  Placement,
  PlacementService,
} from 'app/scripts/woo_services.module/PlacementService';
import { TranslationService } from 'app/scripts/woo_services.module/TranslationService';
import { max } from 'lodash-es';
import { CreativeData, CreativeService } from '../../../woo_services.module/CreativeService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { Campaign, Creative, DatePeriod, ProductFormatConditions } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'add-creative-to-ongoing-campaign',
  templateUrl: './add-creative-to-ongoing-campaign.component.html',
  styleUrls: ['./add-creative-to-ongoing-campaign.component.scss'],
})
export class AddCreativeToOngoingCampaign implements OnChanges {
  readonly instreamFormProfile: InstreamCreativeFormLayoutProfile = {
    actionButtons: Hidden,
    agAffectInventoryInfo: Hidden,
    agDisabledInfo: Hidden,
    heading: Hidden,
    length: Editable,
  };

  @Input() campaign: Campaign;
  @Output() onAbort = new EventEmitter<void>();
  creativeData: CreativeData;
  creativeDataValid: boolean;
  oldCreatives: Creative[] = [];
  creativeLengths = [];
  productFormatConditions: ProductFormatConditions = { onlyShortFormat: false };
  hasAgCreative = false;

  placements: Placement[] = [];
  placementCards: PlacementCard[] = [];
  pickedPlacements: [];

  period: DatePeriod = { start: new Date(), end: new Date() };

  show = {
    loadingPlacements: true,
    warnPlacementsCleared: false,
  };

  get allowSubmit(): boolean {
    return this.creativeData && this.creativeDataValid && this.getPickedPlacementIds().length > 0;
  }

  constructor(
    private creativeService: CreativeService,
    private campaignService: CampaignService,
    private placementService: PlacementService,
    private dialogService: DialogService,
    private translationService: TranslationService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.campaign) {
      this.creativeLengths = this.determineValidCreativeLengths();
    }

    this.placementService
      .getCampaignPlacements(this.campaign.id)
      .then(
        (jobResult: FetchPlacementsJobResult) => {
          this.placements = jobResult.placements;
          this.buildPlacementCards();
        },
        (error: FetchPlacementsJobResult) => {
          this.dialogService.openError(this.translationService.convertError(error.error));
          throw error;
        },
      )
      .finally(() => {
        this.show.loadingPlacements = false;
      });
  }

  changeCreativeData(event: Creative): void {
    if (this.creativeData && this.creativeData.length !== event.length) {
      this.warnPlacementSelectionCleared();
      this.clearAllPickedPlacementsAndRemoveAddedInstances();
    }
    this.creativeData = event;
  }

  warnPlacementSelectionCleared(): void {
    const pickedPlacements = this.getPickedPlacementIds();
    this.show.warnPlacementsCleared = pickedPlacements.length > 0;
  }

  clearAllPickedPlacementsAndRemoveAddedInstances(): void {
    this.placementCards.map((placementCard) => {
      placementCard.picked = false;
      placementCard.ad_unit_nodes.map((ad_unit_node) => {
        ad_unit_node.creative_instances = ad_unit_node.creative_instances.filter((creative_instance) => {
          return !creative_instance.added;
        });
      });
    });
  }

  async ngOnChanges(changes: SimpleChanges<AddCreativeToOngoingCampaign>): Promise<void> {
    if (changes.campaign && this.campaign) {
      this.oldCreatives = this.campaign.targetings.flatMap((t) => t.creatives);
      this.hasAgCreative = this.creativeService.checkForAgCreatives(this.oldCreatives);
    }
  }

  async addCreative(): Promise<void> {
    this.dialogService.openBlocking('Lägger till film');
    return this.campaignService
      .addCreativeToOngoingCampaign(this.campaign.id, this.creativeData, this.filterAddedInstances())
      .then((_response) => {
        this.dialogService.closeBlocking();
        this.dialogService.openSuccess('Filmen har lagts till på kampanjen.');
        this.onAbort.emit();
      })
      .catch((error: any) => {
        this.dialogService.closeBlocking();
        throw error;
      });
  }

  validForVideoLength(placement: PlacementCard): boolean {
    if (typeof this.creativeData.length !== 'number') return true;
    const maxCreativeLength = max(
      placement.ad_unit_nodes.flatMap((ad_unit_node) => {
        return ad_unit_node.creative_instances.flatMap((creative_instance) => {
          return creative_instance.length;
        });
      }),
    );
    return this.creativeData.length > maxCreativeLength;
  }

  addCreativeToPlacement(placement: PlacementCard): void {
    if (placement.picked) {
      placement.ad_unit_nodes.map((ad_unit_node) => {
        const creative_instance = {
          custom_name: this.creativeData.custom_name,
          video_code: this.creativeData.video_code,
          length: this.creativeData.length,
          creative_external_id: '',
          start: placement.start_date,
          end: placement.end_date,
          added: true,
        };
        ad_unit_node.creative_instances.push(creative_instance);
      });
    } else {
      placement.ad_unit_nodes.map((ad_unit_node) => {
        ad_unit_node.creative_instances.pop();
      });
    }
    return;
  }

  updateInstanceDates(event: { start: Date; end: Date }, creativeInstance: MrmCreativeInstance): void {
    creativeInstance.start = event.start;
    creativeInstance.end = event.end;
  }

  private getPickedPlacementIds(): string[] | [] {
    const pickedPlacements = this.placementCards.filter((placement) => placement.picked);
    if (!pickedPlacements) return [];
    return pickedPlacements.map((placement) => placement.external_id);
  }

  private filterAddedInstances(): Placement[] | [] {
    const pickedPlacements = this.placementCards.filter((placement) => placement.picked) as Placement[];
    return !pickedPlacements ? [] : pickedPlacements;
  }

  private determineValidCreativeLengths(): number[] {
    const potentialCreativeLengths = this.creativeService.getCreativeLengths();
    const longestVideoLength = max(
      this.campaign.targetings.flatMap((t) => t.creatives).map((creative) => creative.length),
    );
    return potentialCreativeLengths.filter((length) => length <= longestVideoLength);
  }

  private buildPlacementCards(): void {
    let placementCards: PlacementCard[] = [];

    this.placements.map((placement) => {
      const start_date = new Date(placement.start_date);
      const end_date = new Date(placement.end_date);
      placementCards = [
        ...placementCards,
        {
          ...placement,
          picked: false,
          start_date: start_date,
          end_date: end_date,
          ad_unit_nodes: placement.ad_unit_nodes.map((ad_unit_node) => {
            return {
              ...ad_unit_node,
              creative_instances: ad_unit_node.creative_instances.map((creative_instance) => {
                const start_date = !creative_instance.start
                  ? new Date(placement.start_date)
                  : new Date(creative_instance.start);
                const end_date = !creative_instance.end
                  ? new Date(placement.end_date)
                  : new Date(creative_instance.end);
                return {
                  ...creative_instance,
                  start: start_date,
                  end: end_date,
                  added: false,
                };
              }),
            };
          }),
        },
      ];
    });

    this.placementCards = placementCards;
  }
}

interface PlacementCard extends Placement {
  picked: boolean;
}
