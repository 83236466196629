import { Component } from '@angular/core';
import { HotkeyService } from '../../woo_services.module/HotkeyService';
import { WooDialogHelper } from './woo-dialog-helper';

const template = /* html */ `
<woo-dialog>
  <div class="display--flex margin-bottom--2x">
    <div class="icon-wrapper text-danger">
      <span class="icon-warning-outline icon__large"></span>
    </div>
    <h3 *ngIf="error" class="display--inline margin--none margin-left--2x">{{error}}</h3>
    <h3 *ngIf="!error" class="display--inline margin--none margin-left--2x">Hoppsan! Något gick fel.</h3>
  </div>
  <p *ngFor="let msg of messages">{{msg}}</p>

  <button class="button primary-1 center display--block margin-top--2x" (click)="close()">Stäng</button>
</woo-dialog>
`;

@Component({
  selector: 'error-dialog',
  template: template,
})
export class ErrorDialog extends WooDialogHelper {
  error: string;
  messages: string[] = [];

  constructor(private hotkeyService: HotkeyService) {
    super();
    this.addSubscription(this.hotkeyService.addShortcut({ keys: 'enter' }).subscribe(this.close));
  }

  show(error: string, ...messages: string[]): void {
    this.error = error;
    this.messages = messages;
    this.open();
  }
}
