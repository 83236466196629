import { formatCurrency } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EosCampaignRow } from '../../woo_services.module/EosService';

@Component({
  selector: 'campaign-row-card',
  templateUrl: './campaign-row-card.component.html',
  styleUrls: ['./campaign-row-card.component.scss'],
})
export class CampaignRowCard {
  @Input() row: EosCampaignRow;
  @Input() promotedProperties: { name; value }[];
  @Input() dataFields: any[];

  isTabOpen = false;

  currencySEK = [
    'net_publisher_kickback',
    'net_tv4_kickback',
    'net_estimate',
    'net_net_estimate',
    'gross_estimate',
    'agency_discount_estimate',
    'net_actual',
    'net_net_actual',
    'invoice_total',
  ];

  formatData(key: string): string {
    if (key === 'product' || key === 'publisher') return this.row[key]?.name || '';
    if (key === 'send_invoices' || key === 'invoice_sent') return this.row[key] ? 'Ja' : 'Nej';
    if (this.currencySEK.includes(key)) return formatCurrency(this.row[key], 'sv', 'kr') || '';
    if (key === 'date' && this.row[key].length > 10) return this.row[key].slice(0, 10);
    if (key === 'frequency_limit')
      return this.row[key] ? this.row[key].impressions + ' per ' + this.row[key].time_unit : '';

    return this.row[key];
  }
}
