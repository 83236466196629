<div *ngIf="visibleSystemMessages.length || !cookieBannerDismissed" id="global-messages"
  class="global__message__container" [ngClass]="{'nav-open': menuOpened}">
  <div *ngFor="let message of visibleSystemMessages; trackBy:message?.id" class="global__message info">
    <div class="message__content">

      <div class="icon-container">
        <span class="status-badge {{message.type}} margin--2x">
          <span *ngIf="message.type !== 'warning'" class="icon icon-info-outline icon__large" ></span>
          <span *ngIf="message.type === 'warning'" class="icon icon-warning-outline icon__medium" ></span>
        </span>
      </div>


      <div>
        <h3 class="">Systemmeddelande, {{message.created_at | date:'yyyy-MM-dd HH:mm'}}</h3>
        <p class="message__text">{{message.message}}</p>
      </div>
      
      <div class="close-container">
        <button (click)="dismissSystemMessage(message)" class=" margin--1x" >
          <span class="icon icon-close-x icon__large pull-right"></span>
        </button>
      </div>
    </div>
  </div>
  <div id="cookie-banner" class="global__message info" *ngIf="!cookieBannerDismissed">
    <div class="message__content cookie_banner__content">
      <div class="cookie_banner__text">
        Vi använder cookies för att tillhandahålla funktioner för inloggning och analysera vår trafik. Vi
        vidarebefordrar information om din användning av vår webbplats till de analysföretag som vi samarbetar med.
        <a href="{{cookiePolicyUrl}}" target="_blank">Läs mer.</a>
      </div>
      <div class="cookie_banner__button_container">
        <button class="button cookie_banner__button" (click)="dismissCookieBanner()">Jag förstår</button>
      </div>
    </div>
  </div>
</div>
