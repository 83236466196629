import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from '../../../utils/dom';
import { EnvironmentService } from '../../../woo_services.module/EnvironmentService';
import { FileService, FileType } from '../../../woo_services.module/FileService';

@Injectable()
@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class Landing implements OnInit {
  getUrlToFile = this.fileService.getUrlToFile;
  FileType = FileType;

  videos = {
    footer: {
      sources: [{ src: this.env.awsCloudfrontUrl + '/peacock.mp4', type: 'video/mp4' }],
    },
    instructions: {
      poster: '../../../../images/landing/video_cover_abf_red.png',
      sources: [{ src: this.env.awsCloudfrontUrl + '/landing_page_book_campaign_short.mp4', type: 'video/mp4' }],
    },
  };

  videoDuration: string;
  videoIsFullscreen = false;

  uspImages: UspImages = {
    control: {
      default: 'images/landing/usp_full_koll_red.svg',
      hover: 'images/landing/usp_full_koll_hover_red.svg',
    },
    easy: {
      default: 'images/landing/usp_woo_manager_red.svg',
      hover: 'images/landing/usp_woo_manager_hover_red.svg',
    },
    specific: {
      default: 'images/landing/usp_full_styrning_red.svg',
      hover: 'images/landing/usp_full_styrning_hover_red.svg',
    },
  };

  visibleImage = {
    control: this.uspImages.control.default,
    easy: this.uspImages.easy.default,
    specific: this.uspImages.specific.default,
  };

  show = {
    playButton: true,
    replayButton: false,
  };

  @ViewChild('videoPlayer', { static: true }) videoplayer: ElementRef;

  constructor(private env: EnvironmentService, private fileService: FileService) {}

  ngOnInit(): void {
    this.enableVideoDurationEventListnener();
    this.enableVideoIsFullscreenEventListener();
    this.enableReplayButtonEventListener();
  }

  setVisibleImage(image: string, state: string): void {
    this.visibleImage[image] = this.uspImages[image][state];
  }

  scrollTo(element: HTMLElement): void {
    scrollTo(element);
  }

  playVideo(): void {
    this.show.playButton = false;
    this.videoplayer.nativeElement.play();
  }

  private enableVideoDurationEventListnener(): void {
    try {
      const video = this.videoplayer.nativeElement as HTMLVideoElement;
      video.onloadeddata = () => {
        const roundedDuration = Math.round(video.duration);
        const min = Math.floor(roundedDuration / 60);
        const s = roundedDuration % 60;
        const minutes = min > 0 ? `${min} min ` : '';
        const seconds = s > 0 || !minutes ? `${s} sek` : '';
        this.videoDuration = `${minutes}${seconds}`;
      };
    } catch (err) {
      // Send error to Sentry
      console.error('Failed to calculate film length:', err);
    }
  }

  private enableVideoIsFullscreenEventListener(): void {
    try {
      const video = this.videoplayer.nativeElement as HTMLVideoElement;
      const isFullscreen = () => {
        if (document.fullscreenElement) {
          this.videoIsFullscreen = document.fullscreenElement === video;
        } else {
          this.videoIsFullscreen = false;
        }
      };
      video.addEventListener('fullscreenchange', isFullscreen);
      // For IE11 (see https://caniuse.com/#feat=mdn-api_document_fullscreenchange_event)
      video.addEventListener('MSFullscreenChange', isFullscreen);
    } catch (err) {
      // Send error to Sentry
      console.error(err);
    }
  }

  private enableReplayButtonEventListener(): void {
    try {
      const video = this.videoplayer.nativeElement as HTMLVideoElement;
      video.onended = () => {
        this.show.replayButton = true;
      };
      video.onplay = () => {
        this.show.replayButton = false;
      };
    } catch (err) {
      // Send error to Sentry
      console.error('Failed to set replay button bool:', err);
    }
  }
}

export interface UspImage {
  default: string;
  hover: string;
}
export interface UspImages {
  control: UspImage;
  easy: UspImage;
  specific: UspImage;
}
