<div
  class="row-card grid-columns--6 button-colum-right text--small"
  (click)="isTabOpen = !isTabOpen"
  [ngClass]="isTabOpen ? 'row-card-clicked' : 'row-card-unclicked'"
  role="button"
>
  <span style="overflow-wrap: anywhere;" *ngFor="let data of promotedProperties">
    {{ formatData(data.value) }}
  </span>
  <span class="chevron" [ngClass]="isTabOpen ? 'bottom' : ''"></span>
</div>

<div
  class="accordion-tab text--small text--thin grid-columns--3"
  [ngClass]="isTabOpen ? 'accordion-tab-open' : 'accordion-tab-closed'"
>
  <span *ngFor="let field of dataFields">
    {{ field.name }}:
    <b>{{ formatData(field.value) }}</b>
  </span>
</div>
