import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from './EnvironmentService';
import { CampaignStatus, SlotType, wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class EosService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getCampaigns(): Promise<{ campaigns: EosCampaign[] }> {
    return lastValueFrom(this.http.get<{ campaigns: EosCampaign[] }>(`${this.env.apiUrl}/eos/campaigns`));
  }

  getTTVCampaigns(): Promise<{ campaigns: EosCampaign[] }> {
    return lastValueFrom(this.http.get<{ campaigns: EosCampaign[] }>(`${this.env.apiUrl}/eos/ttv_campaigns`));
  }

  getRegions(): Promise<{ regions: string[] }> {
    return lastValueFrom(this.http.get<{ regions: string[] }>(`${this.env.apiUrl}/eos/regions`));
  }

  getPublishers(): Promise<{ publishers: string[] }> {
    return lastValueFrom(this.http.get<{ publishers: string[] }>(this.env.apiUrl + '/eos/publishers'));
  }

  getProducts(): Promise<{ products: string[] }> {
    return lastValueFrom(this.http.get<{ products: string[] }>(`${this.env.apiUrl}/eos/products`));
  }

  getRows(id: wooId, date: Date): Promise<{ rows: EosCampaignRow[] }> {
    return lastValueFrom(
      this.http.get<{ rows: EosCampaignRow[] }>(
        `${this.env.apiUrl}/eos/rows/${id}` + (date ? '/' + date.toISOString().substring(0, 10) : ''),
      ),
    );
  }

  downloadRowsToExcel = (id: wooId, ttv = false): Promise<HttpResponse<Blob>> => {
    return lastValueFrom(
      this.http.post(
        `${this.env.apiUrl}/eos/excel_summary`,
        { id: id, ttv: ttv },
        {
          observe: 'response',
          responseType: 'blob' as 'json',
        },
      ),
    ) as Promise<HttpResponse<Blob>>;
  };

  getTTVRows(id: wooId, date: Date): Promise<{ rows: EosTTVCampaignRow[] }> {
    return lastValueFrom(
      this.http.get<{ rows: EosTTVCampaignRow[] }>(
        `${this.env.apiUrl}/eos/ttv_rows/${id}` + (date ? '/' + date.toISOString().substring(0, 10) : ''),
      ),
    );
  }

  rebuildRows(id: wooId): Promise<{ row_count: number }> {
    return lastValueFrom(this.http.put<{ row_count: number }>(`${this.env.apiUrl}/eos/rebuild_rows/${id}`, {}));
  }

  getAggregatedRowsByYear(year: number): Promise<{ net_estimate: 'net_estimate' }> {
    return lastValueFrom(
      this.http.get<{ net_estimate: 'net_estimate' }>(`${this.env.apiUrl}/eos/aggregated_rows/${year}`),
    );
  }

  getAggregatedRowsByMonth(year: number, month: number): Promise<{ net_estimate: 'net_estimate' }> {
    return lastValueFrom(
      this.http.get<{ net_estimate: 'net_estimate' }>(`${this.env.apiUrl}/eos/aggregated_rows/${year}/${month}`),
    );
  }

  getInventoryRows(rowsFilters: InventoryFilter): Promise<{ rows: EosInventoryRow[] }> {
    const params = this.getInventoryParams(rowsFilters);
    return lastValueFrom(
      this.http.get<{ rows: EosInventoryRow[] }>(`${this.env.apiUrl}/eos/inventory_rows`, {
        params,
      }),
    );
  }

  getTTVInventoryRows(rowsFilters: InventoryFilter): Promise<{ rows: EosTTVInventoryRow[] }> {
    const params = this.getInventoryParams(rowsFilters);
    return lastValueFrom(
      this.http.get<{ rows: EosTTVInventoryRow[] }>(`${this.env.apiUrl}/eos/ttv_inventory_rows`, {
        params,
      }),
    );
  }

  private getInventoryParams(
    filter: InventoryFilter,
  ): { start_year?: string; start_week?: string; number_of_weeks?: string } {
    return filter && filter.startYear && filter.startWeek && filter.numberOfWeeks
      ? {
          start_year: filter.startYear,
          start_week: filter.startWeek,
          number_of_weeks: filter.numberOfWeeks,
        }
      : {};
  }
}

export interface EosCampaign {
  name: string;
  id: wooId;
  end_date: string;
  send_invoices: boolean;
  start_date: string;
}

export interface InventoryFilter {
  startYear: string;
  startWeek: string;
  numberOfWeeks: string;
}
export interface EosInventoryRow {
  date: string;
  region: string;
  estimated: number;
  available: number;
  withdrawal_limit: number;
  slot_type: SlotType;
  publisher: 'TV4' | string;
}

export interface EosTTVInventoryRow {
  date: string;
  ttv_target_group: string;
  channel_name: string;
  estimated: number;
  available: number;
  withdrawal_limit: number;
  slot_type: SlotType;
}

export interface EosCampaignRow {
  advanced_target_groups: string;
  agency: string;
  agency_discount_actual: string;
  agency_discount_estimate: string;
  agreement_date: string;
  agresso_id: string;
  behaviors: string;
  booking_organisation: string;
  campaign_id: wooId;
  campaign_name: string;
  campaign_end_date: string;
  campaign_start_date: string;
  campaign_status: CampaignStatus;
  category: string;
  creative_lengths: number[];
  customer_crm_id: string;
  date: string;
  dayparts: string;
  device_groups: string;
  devices: string;
  frequency_limit: any;
  gross_estimate: string;
  impressions_actual: number;
  impressions_actual_mms: number;
  gross_rating_views_actual_mms: number;
  impressions_additional_estimate: number;
  impressions_estimate: number;
  gross_rating_views_additional_estimate: number;
  gross_rating_views_estimate: number;
  invoice_date?: string;
  invoice_sent: boolean;
  invoice_total: string;
  net_actual: string;
  net_cpm: string;
  net_estimate: string;
  net_net_actual: string;
  net_net_estimate: string;
  net_publisher_kickback: string;
  net_tv4_kickback: string;
  product: { name: string; type: string };
  product_category: string;
  product_format: string;
  program_formats: string;
  publisher: { id: wooId; name: string };
  publisher_combination: string;
  region: string;
  regional_type: string;
  sale_type: string;
  send_invoices: boolean;
  so_number: string;
  target_groups: string;
  targeting_type: string;
  external_reference_id: string;
}
export interface EosTTVCampaignRow {
  agency: string;
  campaign_id: wooId;
  campaign_name: string;
  campaign_end_date: string;
  campaign_start_date: string;
  campaign_status: CampaignStatus;
  creative_length: number;
  customer_crm_id: string;
  date: string;
  impressions_actual_mms: number;
  gross_rating_views_actual_mms: number;
  impressions_actual_pulse: number;
  impressions_adjusted: number;
  impressions_estimate: number;
  impressions_estimate_ttv: number;
  gross_rating_views_estimate_ttv: number;
  mms_gain_factor: string;
  net_estimate: string;
  net_estimate_ttv: string;
  ttv_reference_number: string;
  ttv_booking_id: string;
  ttv_booking_name: string;
  ttv_mapping_factor: string;
  ttv_target_group: string;
  universe: string;
}
