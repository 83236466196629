<p class="text--large">Adminpanel</p>

<div class="input__container">
  <input type="checkbox" id="additional-budget-choice-{{instanceId}}" name="checkbox" class="checkbox"
    [(ngModel)]="additionalBudgetChoice"
    (ngModelChange)="toggleAdditionalBudgetChoice()"
    e2e-id="additional-budget-choice"
  >
  <label class="checkbox" for="additional-budget-choice-{{instanceId}}">
    <ng-container *ngIf="calculateFromBudget">Lägg till extra/komp budget</ng-container>
    <ng-container *ngIf="!calculateFromBudget">Lägg till extra/komp {{currency}}</ng-container>
  </label>
  <info-badge gaName="extra budget">
    Om du vill stärka denna kampanjdel med extra/kompensation budget som ej ska faktureras
    så kan du mata in ökningen i fältet. Glöm inte att beskriva varför.
  </info-badge>

</div>

<div *ngIf="additionalBudgetChoice" class="row">
  <div class="input__container col-xs-4">
    <ng-container *ngIf="calculateFromBudget">
      <label for="additional-budget-{{instanceId}}">Extra/Komp budget (netto)</label>
      <input id="additional-budget-{{instanceId}}" [(ngModel)]="targetingAdditionalBudget"
        (ngModelChange)="additionalBudgetChange()" required e2e-id="additional-budget"
        wooNumberFormatter>
    </ng-container>
    <ng-container *ngIf="!calculateFromBudget">
      <label for="additional-budgeted-impr-{{instanceId}}">Extra/Komp budg. {{currency}} (netto)</label>
      <input id="additional-budget-impr-{{instanceId}}" [(ngModel)]="targetingAdditionalBudgetedImpressions"
        (ngModelChange)="additionalBudgetChange()" required wooNumberFormatter e2e-id="additional-budget-impr">
    </ng-container>
  </div>
  <div class="input__container col-xs-8">
    <label for="additional-budget-message-{{instanceId}}">Meddelande</label>
    <input id="additional-budget-message-{{instanceId}}" [(ngModel)]="targetingAdditionalBudgetMessage"
      (ngModelChange)="additionalBudgetChange()" required e2e-id="additional-budget-message"
    >
  </div>
</div>

<div class="input__container">
  <input type="checkbox" id="no-invoices-choice-{{instanceId}}" name="checkbox" class="checkbox"
    [checked]="noInvoicesChoice"
    (change)="toggleNoInvoicesChoice()"
    [disabled]="includesLinear"
    e2e-id="no-invoices-choice">
  <label class="checkbox" for="no-invoices-choice-{{instanceId}}">Stäng av fakturering</label>
  <info-badge gaName="fakturering">
    För att avaktivera faktureringen för kampanjdelen måste du ange anledning till detta, samt
    salesorder-numret. Informationen skickas sedan till ekonomiavdelningen.
  </info-badge>
</div>

<div *ngIf="noInvoicesChoice && !includesLinear" class="row">
  <div class="input__container col-xs-4">
    <label for="sales-order-number-{{instanceId}}">Salesorder-nummer</label>
    <input id="sales-order-number-{{instanceId}}" e2e-id="sales-order-number"
      [(ngModel)]="salesOrderNumber" (ngModelChange)="noInvoicesChange()"
      required>
  </div>
  <div class="input__container col-xs-8">
    <label for="no-invoices-message-{{instanceId}}">Meddelande</label>
    <input id="no-invoices-message-{{instanceId}}" e2e-id="no-invoices-message"
      [(ngModel)]="noInvoicesMessage" (ngModelChange)="noInvoicesChange()"
      required>
  </div>
</div>
