import { Injectable } from '@angular/core';
import { BookingStep } from '../advanced_campaign_booking.module/stores/BookingStore';
import { UserRole } from '../utils/user-roles';
import { BookingFormat } from './TargetingService';

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerService {
  // Mock dataLayer when google analytics script tag is disabled
  dataObject = window['dataLayer'] ? window['dataLayer'] : [];

  campaignBooked(bookingFormat: BookingFormat, templating = false): void {
    this.dataObject.push({
      event: 'orderComplete',
      specificType: bookingFormat,
    });
    if (templating) {
      this.dataObject.push({
        event: 'duplicatedCampaignBooked',
      });
    }
  }

  campaignSaved(bookingFormat: BookingFormat): void {
    this.dataObject.push({
      event: 'orderSaved',
      specificType: bookingFormat,
    });
  }

  stepLoad(visibleStates: any, currentVisible: number): void {
    this.dataObject.push({
      event: 'orderFormStepLoad',
      orderFormStepName: visibleStates ? visibleStates[currentVisible].display : 'Unknown',
      orderFormStepNumber: currentVisible + 1,
      orderFormStepType: currentVisible < 6 ? true : false,
    });
  }

  infoButtonClicked(stepName: string): void {
    this.dataObject.push({
      event: 'orderFormInfo',
      orderFormInfoField: stepName,
    });
  }

  orderFormError(message: string): void {
    this.dataObject.push({
      event: 'orderFormError',
      orderFormError: message,
    });
  }

  stepSuccess(state: any, index: number): void {
    this.dataObject.push({
      event: 'orderFormStepSuccess',
      orderFormStepName: state.display,
      orderFormStepNumber: index + 1,
      orderFormStepType: index < 6 ? true : false,
    });
  }

  stepError(state: any, index: number): void {
    this.dataObject.push({
      event: 'orderFormStepFail',
      orderFormStepName: state.display,
      orderFormStepNumber: index + 1,
      orderFormStepType: index < 6 ? true : false,
    });
  }

  offerBackfill(acceptedBackfill: boolean): void {
    this.dataObject.push({
      event: 'offeredBackfill',
      acceptedBackfill: acceptedBackfill,
    });
  }

  toggledBackfill(acceptedBackfill: boolean): void {
    this.dataObject.push({
      event: 'toggledBackfill',
      acceptedBackfill: acceptedBackfill,
    });
  }

  reduceBudgetWhenBackfill(): void {
    this.dataObject.push({
      event: 'reduceBudget',
    });
  }

  userLogin(): void {
    this.dataObject.push({
      event: 'userLogIn',
    });
  }

  duplicateCampaign(origin: string): void {
    this.dataObject.push({
      event: 'duplicateCampaign',
      duplicateCampaignOrigin: origin,
    });
  }

  openSupport(): void {
    this.dataObject.push({
      event: 'openedSupport',
    });
  }

  sendSupport(): void {
    this.dataObject.push({
      event: 'sentSupport',
    });
  }

  detectedNewVersion(previous: string, current: string): void {
    this.dataObject.push({
      event: 'detectedNewVersion',
      previousWebVersion: previous,
      currentWebVersion: current,
    });
  }

  hardReload(): void {
    this.dataObject.push({
      event: 'hardReload',
    });
  }

  openedAdvancedBookingFlow(userRole: UserRole): void {
    this.dataObject.push({
      event: 'openedAdvancedBookingFlow',
      userRole: userRole,
    });
  }

  bookedCampaignAdvancedBookingFlow(userRole: UserRole, bookingFormat: string): void {
    this.dataObject.push({
      event: 'bookedCampaignAdvancedBookingFlow',
      userRole: userRole,
      specificType: bookingFormat,
    });
  }

  changedToAbf(userRole: UserRole, editingCampaign: boolean): void {
    this.dataObject.push({
      event: 'changedToABF',
      userRole: userRole,
      campaignStatus: editingCampaign ? 'editing' : 'new',
    });
  }

  changedToObf(userRole: UserRole, editingCampaign: boolean): void {
    this.dataObject.push({
      event: 'changedToOBF',
      userRole: userRole,
      campaignStatus: editingCampaign ? 'editing' : 'new',
    });
  }

  openedInfoBadge(userRole: UserRole, badgeName: string): void {
    this.dataObject.push({
      event: 'openedInfoBadge',
      userRole: userRole,
      orderFormInfoField: badgeName,
    });
  }

  showedErrorMessage(message: string): void {
    this.dataObject.push({
      event: 'showedErrorMessage',
      message: message,
    });
  }

  clickedNextStepButton(hasErrors: boolean, activeStep: BookingStep): void {
    this.dataObject.push({
      event: 'clickedNextStepButton',
      hasErrors: hasErrors,
      activeStep: activeStep,
    });
  }

  clickedEditAllCreatives(): void {
    this.dataObject.push({
      event: 'clickedEditAllCreatives',
    });
  }

  addTargetingAbf(fromComponent: string): void {
    this.dataObject.push({
      event: 'addTargetingAbf',
      fromComponent,
    });
  }

  downloadSummaryAbf(): void {
    this.dataObject.push({
      event: 'downloadSummaryAbf',
    });
  }

  dashboardCollapsedWithToggleExtendedButton(): void {
    this.dataObject.push({
      event: 'dashboardCollapsedWithToggleExtendedButton',
    });
  }

  dashboardCollapsedWithSidebarCollapseButton(): void {
    this.dataObject.push({
      event: 'dashboardCollapsedWithSidebarCollapseButton',
    });
  }
}
