import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uniq } from '../utils/array';
import { EnvironmentService } from './EnvironmentService';
import { Publisher } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PublisherService {
  defaultPublisher = 'TV4';

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getPublishers(...extra: ExtraPublisherField[]): Promise<Publisher[]> {
    function splitExtraFields(extra) {
      return extra.reduce(
        ([field, includes], e) => {
          return e === ExtraPublisherField.productFormats || e === ExtraPublisherField.categories
            ? [[...field, e], includes]
            : [field, [...includes, e]];
        },
        [[], []],
      );
    }
    // eslint-disable-next-line prefer-const
    let [includes, fields] = splitExtraFields(extra);
    fields = uniq((fields as string[]).concat(['id', 'name', 'slot_types']));
    return lastValueFrom(
      this.http.get<Publisher[]>(`${this.env.apiUrl}/publishers`, {
        params: { fields: fields.join(','), include: includes.join(',') },
      }),
    );
  }

  updatePublishers(publishers: Array<Partial<Publisher>>): Promise<Publisher[]> {
    return lastValueFrom(
      this.http.put<Publisher[]>(`${this.env.apiUrl}/publishers`, { publishers: publishers }),
    );
  }
}

export const enum ExtraPublisherField {
  enabled = 'enabled',
  withdrawalLimits = 'withdrawal_limits',
  categoryWithdrawalLimits = 'category_withdrawal_limits',
  daypartWithdrawalLimits = 'daypart_withdrawal_limits',
  pauseAdShare = 'pause_ad_share',
  pauseAdWithdrawalLimit = 'pause_ad_withdrawal_limit',
  categories = 'categories',
  agWithdrawalLimits = 'ag_withdrawal_limits',
  derivedSlotTypeShares = 'derived_slot_type_shares',
  gamblingWithdrawalLimits = 'gambling_withdrawal_limits',
  gamblingWithdrawalLimitShares = 'gambling_withdrawal_limit_shares',
  gamblingPauseAdShare = 'gambling_pause_ad_share',
  gamblingPauseAdWithdrawalLimit = 'gambling_pause_ad_withdrawal_limit',
  gambling = 'gambling',
  completionRates = 'completion_rates',
  productFormats = 'product_formats',
}
