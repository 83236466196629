import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../../utils/types';
import { DashboardService } from '../../../woo_services.module/DashboardService';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { InventoryFilterOption, InventoryService, InventoryTree } from '../../../woo_services.module/InventoryService';
import { ProductFormatService } from '../../../woo_services.module/ProductFormatService';
import { Campaign, ProductFormats, Targeting, ViewCurrency } from '../../../woo_services.module/shared-types';
import { TargetingService } from '../../../woo_services.module/TargetingService';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'campaign-inventory',
  templateUrl: './campaign-inventory.component.html',
})
export class CampaignInventoryComponent implements OnChanges {
  @Input() campaign: Campaign;
  editGoals = false;
  canSave: boolean;
  hasChanges: boolean;
  inventories: BookResultInventoryObject[] = [];
  selectedInventory: BookResultInventoryObject;
  businessViewCurrency: ViewCurrency = ViewCurrency.completedViews;
  readonly inventoryViewCurrency: ViewCurrency = ViewCurrency.impressions;
  readonly viewCurrencyLabel = this.translationService.translateViewCurrency;

  filterOptions: InventoryFilterOption[] = [];

  show = {
    loading: false,
  };

  constructor(
    private dashboardService: DashboardService,
    private formatterService: FormatterService,
    private inventoryService: InventoryService,
    private targetingService: TargetingService,
    private translationService: TranslationService,
    private productFormatService: ProductFormatService,
  ) {}

  ngOnChanges(changes: SimpleChanges<CampaignInventoryComponent>): void {
    if (changes.campaign && this.campaign) {
      this.businessViewCurrency = this.campaign.targetings.some((targeting: Targeting) => {
        return targeting.view_currency === ViewCurrency.completedViews;
      })
        ? ViewCurrency.completedViews
        : ViewCurrency.grossRatingViews;
      this.loadInventory();
    }
  }

  get inventoryColumns(): any {
    return this.inventoryColumnNames(this.selectedInventory.type);
  }

  inventoryColumnNames(inventoryPartType: InventoryPartTypes): any {
    if (inventoryPartType === InventoryPartTypes.videoPart) {
      return {
        estimated: `Estimerade\n${this.viewCurrencyLabel(this.inventoryViewCurrency)}`,
        ordered: `Beställda\n${this.viewCurrencyLabel(this.businessViewCurrency)}`,
        used: `Utnyttjade\n${this.viewCurrencyLabel(this.inventoryViewCurrency)}`,
      };
    }

    return {
      estimated: `Estimerade\n${this.viewCurrencyLabel(this.inventoryViewCurrency)}`,
      ordered: `Beställda\n${this.viewCurrencyLabel(this.inventoryViewCurrency)}`,
    };
  }

  private loadInventory() {
    this.show.loading = true;
    const optionsLoader = this.inventoryService.getFilterOptions().then((options) => {
      this.filterOptions = options;
    });

    const campaignInventoryLoader = this.dashboardService
      .getInventoryForCampaign(this.campaign.id)
      .then((campaignInventoryTree) => {
        const campaignInventory: BookResultInventoryObject[] = [];
        if (campaignInventoryTree.video_inventory_tree.children.length) {
          campaignInventory.push({
            name: InventoryPartTypes.videoPart,
            type: InventoryPartTypes.videoPart,
            inventoryTree: this.inventoryService.pruneZerosFromInventoryTree(
              campaignInventoryTree.video_inventory_tree,
              Object.keys(this.inventoryColumnNames(InventoryPartTypes.videoPart)),
            ),
          });
        }
        if (campaignInventoryTree.online_video_inventory_tree.children.length) {
          campaignInventory.push({
            name: InventoryPartTypes.onlineVideoPart,
            type: InventoryPartTypes.onlineVideoPart,
            inventoryTree: this.inventoryService.pruneZerosFromInventoryTree(
              campaignInventoryTree.online_video_inventory_tree,
              Object.keys(this.inventoryColumnNames(InventoryPartTypes.onlineVideoPart)),
            ),
          });
        }
        if (campaignInventoryTree.pause_inventory_tree.children.length) {
          campaignInventory.push({
            name: InventoryPartTypes.pauseAdPart,
            type: InventoryPartTypes.pauseAdPart,
            inventoryTree: this.inventoryService.pruneZerosFromInventoryTree(
              campaignInventoryTree.pause_inventory_tree,
              Object.keys(this.inventoryColumnNames(InventoryPartTypes.pauseAdPart)),
            ),
          });
        }

        return campaignInventory;
      });

    const targetingInventoryLoaders =
      this.campaign.targetings.length === 1
        ? []
        : this.campaign.targetings.map((targeting, idx) => {
            return this.dashboardService
              .getInventoryForTargeting(this.campaign.id, targeting.id)
              .then((targetingInventoryTree) => {
                const metaData = this.targetingService.getMetaData(targeting);
                const targetingName = [
                  targeting.regions,
                  targeting.program_formats,
                  this.targetingService.getTargetGroupNames(targeting).map((tg) => ({ name: tg })),
                  targeting.categories,
                  targeting.device_groups,
                  targeting.behaviors,
                ]
                  .flat()
                  .map((item) => item.name)
                  .join(', ');

                const name = `Del ${idx + 1}: ${this.formatterService.getTargetingLabelString(
                  targeting,
                  metaData,
                )} (${targetingName})`;

                const intentoryPartType = this.intentoryPartType(targeting);

                return {
                  name: name,
                  type: intentoryPartType,
                  inventoryTree: this.inventoryService.pruneZerosFromInventoryTree(
                    targetingInventoryTree,
                    Object.keys(this.inventoryColumnNames(intentoryPartType)),
                  ),
                };
              });
          });

    const declareTypes = <T extends any[]>(...args: T): any[] => args;
    const inventoryLoaders = declareTypes(campaignInventoryLoader, ...targetingInventoryLoaders);

    // Wait for all inventory requests to return to keep them in order
    const inventoryLoader = Promise.all(inventoryLoaders).then((inventories) => {
      this.inventories = inventories.flat();
      this.selectedInventory = this.inventories[0];
    });

    Promise.all([optionsLoader, inventoryLoader])
      .then(
        () => null,
        () => null,
      ) // finally
      .then(() => (this.show.loading = false));
  }

  private intentoryPartType(targeting: Targeting): InventoryPartTypes {
    if (targeting.pause_ad) {
      return InventoryPartTypes.pauseAdPart;
    } else if (
      this.productFormatService.convertProductFormatToEnum(targeting.product_formats) === ProductFormats.shortForm
    ) {
      return InventoryPartTypes.onlineVideoPart;
    } else {
      return InventoryPartTypes.videoPart;
    }
  }
}

enum InventoryPartTypes {
  videoPart = 'Videodelar totalt',
  onlineVideoPart = 'OnlineVideodelar totalt',
  pauseAdPart = 'Pausdelar totalt',
}

interface BookResultInventoryObject {
  name: string;
  type: InventoryPartTypes;
  inventoryTree: InventoryTree;
}
