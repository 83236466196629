import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashAsDefault',
})
export class DashAsDefault implements PipeTransform {
  transform(value: any): any {
    return [undefined, null, ''].includes(value) ? '-' : value;
  }
}
