import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { WooComponentsModule } from '../woo_components.module/module';
import { DefaultMmsValueSetting } from './components/mms_admin/default-mms-value-setting.component';
import { InvalidMmsDayAdmin } from './components/mms_admin/invalid-mms-day-admin/invalid-mms-days-admin.component';
import { MmsAdmin } from './components/mms_admin/mms-admin.component';
import { PublisherGroupSettings } from './components/publisher_group_settings/publisher-group-settings.component';
import { SystemMessages } from './components/system_messages/system-messages.component';
import { SystemSettings } from './components/system_settings/system-settings.component';
import { SystemSettingsForm } from './components/system_settings_form/system-settings-form.component';

@NgModule({
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, WooComponentsModule],
  providers: [],
  declarations: [
    DefaultMmsValueSetting,
    MmsAdmin,
    InvalidMmsDayAdmin,
    SystemMessages,
    SystemSettingsForm,
    PublisherGroupSettings,
    SystemSettings,
  ],
  exports: [SystemSettings],
})
export class WooSystemSettingsModule {}
