import { Injectable } from '@angular/core';
import { AssetDimensions, AssetMetaData, AssetUploadErrors } from './asset_upload_types';

@Injectable({ providedIn: 'root' })
export class JPGValidatorService {
  validate = async (file: File): Promise<AssetMetaData> => {
    let dimensionError;
    let fileTypeError = this.validateFileType(file);
    let metaData: AssetMetaData;
    const fileSizeError = this.validateFileSize(file);

    if (!fileTypeError && !fileSizeError) {
      try {
        const image = await this.readImageFile(file);
        metaData = await this.getImageMetaData(image);

        dimensionError = this.validateDimensions(metaData.dimensions);
      } catch (_) {
        fileTypeError = 'Bilden kunde inte läsas, dubbekolla att filen är av rätt typ (måste vara .jpg/.jpeg)';
        console.error(
          'Failed to read uploaded file for JPG image validation (browser considers file not to be an image)',
        );
      }
    }

    return new Promise<AssetMetaData>((resolve, reject) => {
      if (!fileTypeError && !fileSizeError && !dimensionError) {
        resolve(metaData);
      } else {
        reject({
          ...(fileTypeError ? { fileTypeError } : {}),
          ...(fileSizeError ? { fileSizeError } : {}),
          ...(dimensionError ? { dimensionError } : {}),
        } as AssetUploadErrors);
      }
    });
  };

  private maxFileSizeBytes = 200 * 1024;
  private maxSizeForValidation = 400 * 1024;
  private requiredDimensions = {
    width: 1135,
    height: 640,
  };

  private validateFileSize = (file: File): string | null => {
    return file.size <= this.maxFileSizeBytes ? null : 'Filen får max vara 200 KB';
  };

  private validateDimensions = (dimensions: AssetDimensions): string | null => {
    return dimensions.width === this.requiredDimensions.width && dimensions.height === this.requiredDimensions.height
      ? null
      : 'Bilden måste vara exakt 1135x640px';
  };

  private validateFileType = (file: File): string | null => {
    if (file.type.includes('image/')) {
      return this.correctExtension(file) ? null : 'Bilden måste vara jpg-format';
    } else {
      return 'Filen måste vara en bild av jpg-format';
    }
  };

  private correctExtension(file: File): boolean {
    return file.name.endsWith('jpg') || file.name.endsWith('jpeg') || /jp(e)?g$/.test(file.type);
  }

  private readImageFile = (file: File): Promise<HTMLImageElement> => {
    return new Promise((res, rej) => {
      if (!file.type.includes('image/')) {
        rej(null);
      }

      const reader = new FileReader();
      reader.onerror = () => {
        rej(null);
      };
      reader.onload = () => {
        const image = new Image();
        image.onerror = () => {
          rej(null);
        };
        image.src = reader.result as string;
        res(image);
      };
      reader.readAsDataURL(file);
    });
  };

  private getImageMetaData = (image: HTMLImageElement): Promise<AssetMetaData> => {
    return new Promise((res, rej) => {
      if (!image) {
        rej(null);
      }

      image.onerror = () => {
        rej(null);
      };
      image.onload = () => {
        if (!image.height || !image.width) {
          rej(null);
        }
        res({ dimensions: { width: image.width, height: image.height } });
      };
    });
  };
}
