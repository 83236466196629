import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { setFormDirty } from '../../../../utils/form';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { OutstreamSite, OutstreamSiteService } from '../../../../woo_services.module/OutstreamSiteService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import {
  DeviceGroup,
  OutstreamEstimates,
  ProductFormat,
  Publisher,
  SlotType,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'device-group-form',
  templateUrl: './device-group-form.component.html',
})
export class DeviceGroupForm implements OnInit {
  readonly setFormDirty = setFormDirty;
  @Input() scrollBack: HTMLElement;
  @Output() onSubmit = new EventEmitter<DeviceGroupParams>();
  @Output() onAbort = new EventEmitter<void>();

  readonly tableHeaders = [
    'Placering',
    'Publicist',
    'Preroll',
    'Midroll',
    'Postroll',
    'Preroll',
    'Midroll',
    'Postroll',
  ];
  readonly staticFields = 3;

  sites: OutstreamSite[] = [];
  publishers: Publisher[] = [];
  productFormats: ProductFormat[];
  inputRows: InputRow[] = [];
  deviceGroup: DeviceGroup = {} as DeviceGroup;
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  loading = true;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private outstreamSiteService: OutstreamSiteService,
    private dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    Promise.all([
      this.publisherService.getPublishers(ExtraPublisherField.enabled).then((publishers) => {
        this.publishers = publishers.filter((p) => p.enabled);
      }),
      this.outstreamSiteService.getSites().then((sites) => (this.sites = sites)),
    ])
      .then(() => {
        this.productFormatService
          .getProductFormats(ExtraProductFormatField.enabled, ExtraProductFormatField.slotTypes)
          .then((productFormats) => {
            this.productFormats = productFormats.filter((f) => f.enabled);
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
            this.buildDeviceGroup();
            this.buildInputRows();
          });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  saveNewDeviceGroup(): void {
    const textBlocks = ['Är du säker på att du vill lägga till apparat?'];
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.onSubmit.emit(this.deviceGroup);
      })
      .finally(() => {
        this.dialogService.closeBlocking();
      });
  }

  translateProductFormat(product: string): string {
    return this.translationService.convertProductFormat(product);
  }

  rowVisible(obj: InputRow): boolean {
    return obj.publisherEnabled && obj.ProductFormatEnabled;
  }

  private getEmptyOutstreamEstimates(sites: OutstreamSite[]): OutstreamEstimates {
    return sites.reduce<OutstreamEstimates>(
      (estimates, site) => ({
        ...estimates,
        [site.id]: 0,
      }),
      {},
    );
  }

  private buildDeviceGroup(): void {
    this.deviceGroup.name = null;
    this.deviceGroup.active = true;
    this.deviceGroup.description = null;
    this.deviceGroup.outstream_shares = this.getEmptyOutstreamEstimates(this.sites);
    this.deviceGroup.outstream_withdrawal_limits = this.getEmptyOutstreamEstimates(this.sites);
    this.deviceGroup.shares = this.utilityService.buildEmptyProductFormatEstimates(
      this.publishers,
      this.productFormats,
    );
    this.deviceGroup.withdrawal_limits = this.utilityService.buildEmptyProductFormatEstimates(
      this.publishers,
      this.productFormats,
    );
  }

  private buildInputRows(): void {
    let inputRow: InputRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        inputRow = [
          ...inputRow,
          {
            publisherId: publisher.id,
            publisher: publisher.name,
            publisherEnabled: publisher.enabled,
            productFormat: productFormat.name,
            productFormatId: productFormat.id,
            ProductFormatEnabled: productFormat.enabled,
            sharesPreroll: 0,
            sharesMidroll: 0,
            sharesPostroll: 0,
            withdrawalLimitsPreroll: 0,
            withdrawalLimitsMidroll: 0,
            withdrawalLimitsPostroll: 0,
          },
        ];
      });
    });
    inputRow.sort((a, b) => (a.publisher > b.publisher ? 0 : 1));
    this.inputRows = inputRow;
  }
}

interface InputRow {
  publisher: string;
  publisherId?: wooId;
  publisherEnabled: boolean;
  productFormat: string;
  productFormatId: wooId;
  ProductFormatEnabled: boolean;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}

export type DeviceGroupParams = Omit<DeviceGroup, 'id'>;
