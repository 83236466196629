import { Injectable } from '@angular/core';
import { Campaign } from 'app/scripts/woo_services.module/shared-types';
import { AuthService } from '../../woo_services.module/AuthService';
import { GoogleTagManagerService } from '../../woo_services.module/GoogleTagManagerService';
import { TargetingService } from '../../woo_services.module/TargetingService';
import { BookingStep } from '../stores/BookingStore';

@Injectable()
export class AnalyticsService {
  constructor(
    private targetingService: TargetingService,
    private googleTagManagerService: GoogleTagManagerService,
    private authService: AuthService,
  ) {}

  openedBookingFlow(): void {
    this.googleTagManagerService.openedAdvancedBookingFlow(this.authService.getFirstUserRole());
  }

  bookedCampaign(campaign: Campaign): void {
    this.googleTagManagerService.bookedCampaignAdvancedBookingFlow(
      this.authService.getFirstUserRole(),
      this.targetingService.getBookingFormatSelectionFromTargeting(campaign.targetings[0]),
    );
  }

  changedToObf(editingCampaign: boolean): void {
    this.googleTagManagerService.changedToObf(this.authService.getFirstUserRole(), editingCampaign);
  }

  showedErrorMessages(messages: string[]): void {
    messages.forEach((message) => {
      this.googleTagManagerService.showedErrorMessage(message);
    });
  }

  clickedNextStepButton(hasErrors: boolean, activeStep: BookingStep): void {
    this.googleTagManagerService.clickedNextStepButton(hasErrors, activeStep);
  }
}
