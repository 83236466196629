import { Injectable } from '@angular/core';
import { isGeneratedId } from '../../utils/string';
import { AdServerService } from '../../woo_services.module/AdServerService';
import { CampaignService } from '../../woo_services.module/CampaignService';
import {
  Campaign,
  CampaignBookingJobResult,
  CampaignEstimation,
  CampaignStatus,
  Publisher,
  SendToAdServerJobResult,
  TargetingPublisher,
  wooId,
} from '../../woo_services.module/shared-types';
import { BookingStore } from '../stores/BookingStore';
import { AnalyticsService } from './AnalyticsService';
import { CachedPublisherService } from './CachedPublisherService';

/**
 * This class works as a "Facade" for the CampaignService in order to provide an easier interface.
 * In addition, it connects to the BookingStore so that responses are stored correctly.
 */
@Injectable()
export class AdvancedCampaignService {
  constructor(
    private campaignService: CampaignService,
    private bookingStore: BookingStore,
    private trackingService: AnalyticsService,
    private adServerService: AdServerService,
    private cachedPublisherService: CachedPublisherService,
  ) {}

  load(id: wooId): Promise<Campaign> {
    return this.bookingStore.setCampaign(this.campaignService.get(id));
  }

  template(id: wooId): Promise<Campaign> {
    return this.bookingStore.setCampaign(
      this.campaignService.get(id).then((campaign) => {
        this.campaignService.stripCampaignForTemplating(campaign);
        return campaign;
      }),
      false,
    );
  }
  save(campaign: Campaign): Promise<Campaign> {
    return this.bookingStore.setWaitingFor(this.getUpdateMethod(campaign)(campaign), 'saveCampaign');
  }

  book(campaign: Campaign, force: boolean): Promise<CampaignBookingJobResult> {
    this.trackingService.bookedCampaign(campaign);
    return this.bookingStore.setWaitingFor(this.getBookMethod(campaign)(campaign, force), 'bookCampaign');
  }

  sendToAdServerIfNecessary(campaign: Campaign): Promise<SendToAdServerJobResult> {
    if (campaign.status === CampaignStatus.upcoming) {
      return this.bookingStore.setWaitingFor(
        this.adServerService.sendCampaign(campaign.id),
        'sendingCampaignToAdserver',
      );
    } else {
      return Promise.resolve({ status: 'success' });
    }
  }

  estimate(campaign: Campaign): Promise<CampaignEstimation> {
    return this.bookingStore.updateEstimation(
      this.campaignService.estimate(campaign).catch((response) => {
        if (response.error.status === 422) {
          return {
            status: response.error.error,
            parts: campaign.targetings.map(({ id }) => ({ targeting_id: id, creatives: [] })),
            creatives: [],
          } as CampaignEstimation;
        } else {
          throw response;
        }
      }),
    );
  }

  getEstimationTriggerValues = (campaign: Campaign): Campaign => {
    const strippedTargetings = campaign.targetings.map((targeting) => {
      return {
        ...targeting,
        additional_budget_message: null,
        sales_order_number: null,
        invoice_disable_message: null,
      };
    });

    return {
      ...campaign,
      client_invoice_reference: null,
      reference_number: null,
      name: null,
      targetings: strippedTargetings,
    };
  };

  setDefaultPublisherForTargeting(targetingId: wooId): void {
    this.cachedPublisherService.getPublishers().then((publishers: Publisher[]) => {
      const defaultPublishers = this.getDefaultPublishersForTargeting(publishers);
      this.bookingStore.setDefaultPublishers(targetingId, defaultPublishers);
    });
  }

  private getUpdateMethod(campaign: Campaign): (c: Campaign) => Promise<Campaign> {
    return this.isPersisted(campaign) ? this.campaignService.update : this.campaignService.create;
  }

  private getBookMethod(campaign: Campaign): (c: Campaign, force: boolean) => Promise<CampaignBookingJobResult> {
    if (!this.isPersisted(campaign)) {
      return this.campaignService.createAndBook;
    } else if (campaign.status === CampaignStatus.unbooked) {
      return this.campaignService.updateAndBook;
    } else {
      return this.campaignService.updateBooked;
    }
  }

  private isPersisted(campaign: Campaign): boolean {
    return campaign.id && !isGeneratedId(campaign.id);
  }

  private getDefaultPublishersForTargeting(publishers: Publisher[]): TargetingPublisher[] {
    return publishers
      .map((publisher) => ({ publisher_id: publisher.id, name: publisher.name }))
      .filter((publisher) => publisher.name === this.cachedPublisherService.defaultPublisher);
  }
}
