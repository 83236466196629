<div class="creative-asset__list">
  <div class="input__container width--50" *ngIf="hasAssets">
    <label for="searchAsset">Sök</label>
    <input id="searchAsset" type="text" [(ngModel)]="searchText" (ngModelChange)="filter()" />
  </div>
  <table class="table__woo table table--info text--medium" *ngIf="hasVideos">
    <thead>
      <tr>
        <th class="preview--width">Filmer</th>
        <th>Namn</th>
        <th>Storlek</th>
        <th>Uppladdad</th>
        <th *ngIf="showDelete"></th>
        <th *ngIf="showSelect"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let asset of videos">
        <td>
          <video controls class="creative-asset__preview preview--width" [src]="asset.public_url"></video>
        </td>
        <td><h4 class="thin">{{asset.name}}</h4></td>
        <td>{{getFileSizeText(asset.file_size)}}</td>
        <td><div *ngIf="asset.created_at">{{asset.created_at | date}}</div><dot-loader [visibility]="!asset.created_at" ></dot-loader></td>
        <td *ngIf="showDelete"><button class="button danger" (click)="onDelete.emit(asset)"><span class="icon-trash"></span></button></td>
        <td *ngIf="showSelect"><button class="button success" [ngClass]="{'disabled' : !asset.created_at}" [disabled]="!asset.created_at" (click)="onSelect.emit(asset)">Välj film</button></td>
      </tr>
    </tbody>
  </table>

  <div class="margin-top--3x"></div>

  <table class="table__woo table table--info text--medium" *ngIf="hasImages">
    <thead>
      <tr>
        <th class="preview--width">Bilder</th>
        <th>Namn</th>
        <th>Storlek</th>
        <th>Uppladdad</th>
        <th *ngIf="showDelete"></th>
        <th *ngIf="showSelect"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let asset of images">
        <td>
          <img class="preview--width" [src]="asset.public_url" alt="Bild på materialet">
        </td>
        <td><h4 class="thin">{{asset.name}}</h4></td>
        <td>{{getFileSizeText(asset.file_size)}}</td>
        <td><div *ngIf="asset.created_at">{{asset.created_at | date}}</div><dot-loader [visibility]="!asset.created_at" ></dot-loader></td>
        <td *ngIf="showDelete"><button class="button danger" (click)="onDelete.emit(asset)"><span class="icon-trash"></span></button></td>
        <td *ngIf="showSelect"><button class="button success" [ngClass]="{'disabled' : !asset.created_at}" [disabled]="!asset.created_at" (click)="onSelect.emit(asset)">Välj bild</button></td>
      </tr>
    </tbody>
  </table>
  <h3 *ngIf="!hasAssets" class="thin">Det finns inget material tillagt</h3>
</div>
