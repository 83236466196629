import { Component } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { AuthService } from '../../../woo_services.module/AuthService';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { Campaign, wooId } from '../../../woo_services.module/shared-types';
import { CampaignTracking, TargetingTracking, TrackingService } from '../../services/TrackingService';

@Component({
  selector: 'outcome',
  templateUrl: './outcome.component.html',
})
export class Outcome extends SubscriptionHandler {
  readonly adminOrPlanner = this.authService.adminOrPlanner();

  targetingTrackings: Record<wooId, TargetingTracking> = {};
  tracking: CampaignTracking;
  campaign: Campaign;
  isLoading: boolean;

  constructor(
    private dashboardStore: DashboardStore,
    private campaignService: CampaignService,
    private trackingService: TrackingService,
    private authService: AuthService,
  ) {
    super();
    this.addSubscription(
      dashboardStore.state$
        .pipe(
          distinctUntilChanged(
            (prev: DashboardModel, curr: DashboardModel) => prev.activeCampaign.id === curr.activeCampaign.id,
          ),
        )
        .subscribe(this.initFromStore),
    );
  }

  initFromStore = (model: DashboardModel): void => {
    this.campaign = model.activeCampaign;
    const isFollowup = this.campaignService.isFollowup(this.campaign);
    if (!isFollowup) {
      return;
    }
    if (!model.activeCampaignTracking) {
      this.loadTracking();
    } else {
      this.setTracking(model.activeCampaignTracking);
    }
  };

  loadTracking = (): void => {
    if (!this.isLoading) {
      this.isLoading = true;
      this.trackingService
        .getTracking(this.campaign.id)
        .then((tracking) => {
          this.dashboardStore.setTracking(tracking);
          this.setTracking(tracking);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  };

  setTracking = (tracking: CampaignTracking): void => {
    this.tracking = tracking;
    this.targetingTrackings =
      tracking.targetings?.reduce((acc, tracking) => ({ ...acc, [tracking.targeting_id]: tracking }), {}) || {};
  };
}
