import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  InventoryFilterOption,
  InventoryFilterValue,
  InventoryService,
} from '../../../woo_services.module/InventoryService';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'inventory-filter',
  templateUrl: './inventory-filter.component.html',
})
export class InventoryFilter implements OnInit {
  @Input() filterOption: InventoryFilterOption;
  @Output() onChange = new EventEmitter<Record<string, unknown>>();
  translate = this.translationService.convertInventoryFilter;
  selected: Selected = null;

  constructor(private translationService: TranslationService, private inventoryService: InventoryService) {}

  ngOnInit(): void {
    this.resetSelected();
    this.inventoryService.clear$.subscribe(() => {
      this.resetSelected();
    });
  }

  isSingleChoiceFilter(): boolean {
    return this.filterOption.accepted_values === 1 && this.filterOption.optional;
  }

  isSingleChoiceFilterAndRequired(): boolean {
    return this.filterOption.accepted_values === 1 && !this.filterOption.optional;
  }

  isMultiChoiceFilter(): boolean {
    return !this.isSingleChoiceFilterAndRequired() && !this.isSingleChoiceFilter();
  }

  resetSelected(): void {
    this.selected = {
      singleChoiceValue: undefined,
      singleChoiceRequiredValue: this.filterOption.values[0],
      multiChoiceValue: undefined,
    };
    this.notify();
  }

  notify(): void {
    if (this.isSingleChoiceFilter()) {
      this.onChange.emit({ newValue: [this.selected.singleChoiceValue] });
    } else if (this.isSingleChoiceFilterAndRequired()) {
      this.onChange.emit({ newValue: [this.selected.singleChoiceRequiredValue] });
    } else if (this.isMultiChoiceFilter()) {
      this.onChange.emit({ newValue: this.selected.multiChoiceValue });
    }
  }
}

interface Selected {
  singleChoiceValue: InventoryFilterValue;
  singleChoiceRequiredValue: InventoryFilterValue;
  multiChoiceValue: InventoryFilterValue[];
}
