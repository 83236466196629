<div class="load-button-container">
  <h3>wooLoad</h3>
  <p>Vid användning av Woo's standardknappar kan man applicera en loader vid långsamma händelser (ajax-anrop etc).</p>

  <p>Man lägger till attributet <strong>wooLoad</strong> på det elementet man vill lägga till en spinner på. Det triggas när elementet klickas</p>
  <h5>Syntax</h5>
  <pre>
    {{getText()}}</pre>

  <p>
    <span class="icon-warning text--info"></span>
    För att programatiskt stänga av laddare utan att rendera om så tar man injektar man wooUiEventService: WooUiEventService.
    Sen när man laddat klart stänger man med.
  </p>

  <pre>{{"this.wooUiEventService.stopLoadFeatherAnimation()"}}</pre>

  <h5>Exempel</h5>

  <div class="row">
    <div class="col-xs-2">
      <button class="button primary-1" wooLoad>
        Click!
      </button>
    </div>
    <div class="col-xs-2">
      <button class="button" (click)="shutdown()">Stäng av spinners</button>
    </div>
  </div>
</div>
