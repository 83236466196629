import { formatDate } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { parseWooDate, WOO_DATE_PATTERN } from '../../utils/date';
import { STANDARD_FORMATS } from '../../utils/format-constants';

@Directive({
  selector: '[wooDateFormatter]',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: WooDateFormatter, multi: true },
    { provide: NG_VALIDATORS, useExisting: WooDateFormatter, multi: true },
  ],
})
export class WooDateFormatter implements ControlValueAccessor, Validator {
  private errors: ValidationErrors = {};

  constructor(private renderer: Renderer2, private element: ElementRef, @Inject(LOCALE_ID) private locale: string) {}

  propagateValidatorChange = (): void => null;
  propagateChange = (_: Date): void => null;
  propagateTouch = (): void => null;

  @HostListener('input', ['$event.target.value'])
  input(value: string): void {
    this.errors = this.getErrors(value);
    this.propagateValidatorChange();
    this.propagateChange(parseWooDate(value));
  }

  writeValue(value: any): void {
    const element = this.element.nativeElement;
    const inputValue = value ? formatDate(value, STANDARD_FORMATS.date, this.locale) : '';
    this.errors = this.getErrors(inputValue);
    this.renderer.setProperty(element, 'value', inputValue);
  }

  validate(): ValidationErrors {
    return this.errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.propagateValidatorChange = fn;
  }
  registerOnChange(fn: (_: Date) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  private getErrors(value: string): ValidationErrors {
    if (!WOO_DATE_PATTERN.test(value)) {
      return { wooDateFormat: 'Ogiltigt datumformat' };
    } else if (parseWooDate(value) === null) {
      return { wooDateFormat: 'Ogiltigt datum' };
    } else {
      return {};
    }
  }
}
