import { Component, Input, OnChanges } from '@angular/core';
import { maxBy, minBy } from '../../../utils/array';
import { TargetGroupSelected } from '../../../woo_components.module/inputs/target-group-select/target-group-select.component';
import {
  AgeTargetGroup,
  GenderTargetGroup,
  GeoTargeting,
  TargetingAgeTargetGroup,
  TargetingGenderTargetGroup,
  wooId,
} from '../../../woo_services.module/shared-types';
import { UniverseService } from '../../../woo_services.module/UniverseService';
import { CachedTargetGroupService } from '../../services/CachedTargetGroupService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
  <ng-container *ngIf="geoTargeting === GeoTargeting.national">
    <p>
      Universum: {{targetingUniverse | number | dashAsDefault}}
    </p>
    <div class="divider margin-top-bottom--1x"></div>
  </ng-container>
  <voucher-select class="display--block padding-bottom--2x" [targetingId]="targetingId">
  </voucher-select>
  <target-group-select
    class="display--block"
    [genders]="genders"
    [ages]="ages"
    [ngModel]="selectedTargetGroups"
    (ngModelChange)="targetGroupsChange($event)"
  >
  </target-group-select>
`;

@Component({
  selector: 'target-group-targeting',
  template: template,
})
export class TargetGroupTargeting extends SubscriptionHandler implements OnChanges {
  @Input() targetingId: wooId;

  selectedTargetGroups: TargetGroupSelected = {
    ages: [],
    genders: [],
    choices: {
      gender: false,
      age: false,
    },
  };
  ages: AgeTargetGroup[] = [];
  genders: GenderTargetGroup[] = [];

  geoTargeting: GeoTargeting;
  targetingUniverse: number;

  GeoTargeting = GeoTargeting;

  constructor(
    private targetGroupService: CachedTargetGroupService,
    private bookingStore: BookingStore,
    private universeService: UniverseService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
    this.targetGroupService.getGendersAndAges().then(([ages, genders]) => {
      this.ages = ages;
      this.genders = genders;
      this.initFromStore(this.bookingStore.state);
    });
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (this.ages.length > 0 || this.genders.length > 0) {
      if (targeting) {
        const genderIds = targeting.gender_target_groups.map((g) => g.gender_target_group_id);
        const ageIds = targeting.age_target_groups.map((g) => g.age_target_group_id);
        this.selectedTargetGroups = {
          genders: this.genders.filter((tg) => genderIds.includes(tg.id)),
          ages: this.ages.filter((tg) => ageIds.includes(tg.id)),
          choices: model.targetingMetaData[targeting.id].targetGroupChoices,
        };
      }
    }
    if (targeting) {
      this.geoTargeting = model.targetingMetaData[this.targetingId].geoTargeting;
      this.targetingUniverse = targeting.universe || targeting.full_universe;
    }
  };

  targetGroupsChange(targetGroups: TargetGroupSelected): void {
    const ages = targetGroups.ages.map(this.getTargetingAgeTargetGroup);
    const genders = targetGroups.genders.map(this.getTargetingGenderTargetGroup);

    this.universeService
      .getTargetGroupUniverse(
        targetGroups.choices.gender && !!targetGroups.genders.length ? targetGroups.genders[0].name : null,
        targetGroups.choices.age ? minBy(targetGroups.ages, 'min_age') : null,
        targetGroups.choices.age ? maxBy(targetGroups.ages, 'max_age') : null,
      )
      .then((data: { universe: number }) => {
        this.bookingStore.setTargetGroups(ages, genders, targetGroups.choices, this.targetingId, data.universe);
      });
  }

  private getTargetingAgeTargetGroup(targetGroup: AgeTargetGroup): TargetingAgeTargetGroup {
    return {
      age_target_group_id: targetGroup.id,
      name: targetGroup.name,
      min_age: targetGroup.min_age,
      max_age: targetGroup.max_age,
    };
  }

  private getTargetingGenderTargetGroup(targetGroup: GenderTargetGroup): TargetingGenderTargetGroup {
    return {
      gender_target_group_id: targetGroup.id,
      name: targetGroup.name,
    };
  }
}
