import { Component, Input } from '@angular/core';
import { TargetingLabel as ITargetingLabel, TargetingLabelType } from '../woo_services.module/FormatterService';

const template = /* html */ `
  <span *ngIf="isInstream" class="icon-movies text--dark-red margin-right--half-1x icon__1-5em"></span>
  <span *ngIf="isPauseAd" class="icon-TV4_014_placeholder text--dark-red margin-right--half-1x position-relative-top--half-1x icon__1-5em"></span>
  <span *ngIf="isTotal" class="icon-TV4_087_film-serier text--dark-red margin-right--half-1x icon__1-5em"></span>
  <span>{{label.type}} </span><span>{{label.name}} </span><span *ngIf="label.localization"> - <span class="text--italic">{{label.localization}}</span></span>
`;

@Component({
  selector: 'targeting-label',
  template: template,
})
export class TargetingLabel {
  @Input() label: ITargetingLabel = { type: null, localization: '', name: '' };

  get isInstream(): boolean {
    return this.label.type === TargetingLabelType.Instream;
  }

  get isPauseAd(): boolean {
    return this.label.type === TargetingLabelType.PauseAd;
  }

  get isTotal(): boolean {
    return this.label.type === TargetingLabelType.Total;
  }
}
