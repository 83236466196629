import { Component, Input } from '@angular/core';
import { EosAggregatedRowsYear } from './eos-api-tester.component';

@Component({
  selector: 'eos-aggregations',
  templateUrl: './eos-aggregations.component.html',
})
export class EosAggregations {
  @Input() aggregatedRows: EosAggregatedRowsYear;
}
