import { Component } from '@angular/core';

@Component({
  selector: 'info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss'],
})
export class InfoSection {
  info = [
    {
      heading: 'Lätt att boka',
      fileUrl: 'images/cash/campaign.svg',
      description:
        'Du väljer för var och vem kampanjen ska visas och vilken budget du är beredd att satsa. WOO Manager räknar ut hur många visningar du får',
    },
    {
      heading: 'Din kampanj visas',
      fileUrl: 'images/cash/device.svg',
      description:
        'Innan reklamen publiceras måste filmen granskas. Du kan välja att visa kampanjen för en specifik region, publicist eller målgrupp.',
    },
    {
      heading: 'Betalning via faktura',
      fileUrl: 'images/cash/invoice.svg',
      description: 'Fakturan skickas ut i samband med att kampanjen skapas. Betalning sker inom 10 arbetsdagar. ',
    },
    {
      heading: 'Följ utvecklingen',
      fileUrl: 'images/cash/stats.svg',
      description: 'Ha full koll på planerade, pågående och avslutade kampanjer i realtid.',
    },
  ];
}
