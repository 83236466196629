<div class="block">
  <h1>Externa kampanjer</h1>
  <button class="tabbable no-outline" (click)="tabVisible=1" [ngClass]="{active : tabVisible === 1}">Aktuella</button>
  <button class="tabbable no-outline" (click)="tabVisible=2" [ngClass]="{active : tabVisible === 2}">Avslutade</button>

  
  <div>
    <div class="account__card no--animation" *ngIf="tabVisible === 1">
      <div>
        <active-list
          [loading]="loadingActiveCampaigns"
          [campaigns]="activeCampaigns"
          (onToggleReduceImpressions)="toggleReduceImpressions($event)"
          (onOpenDetails)="openDetails($event)"
        ></active-list>
        <invalid-list
          [loading]="loadingInvalidCampaigns"
          [campaigns]="invalidCampaigns"
          (onOpenDetails)="openDetails($event)"
        ></invalid-list>
      </div>
    </div>
    <div class="account__card no--animation" *ngIf="tabVisible === 2">
      <div>
        <ended-list
          [loading]="loadingEndedCampaigns"
          [pagination]="endedCampaignPagination"
          [campaigns]="endedCampaigns"
          (onToggleReduceImpressions)="toggleReduceImpressions($event)"
          (onLoadPage)="loadEndedCampaigns($event)"
          (onOpenDetails)="openDetails($event)"
        ></ended-list>
      </div>
    </div>
  </div>
</div>
