import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { SessionService } from './SessionService';
import { User } from './shared-types';
import { lastValueFrom } from 'rxjs';

type UserWithToken = User & { authentication_token: string };

@Injectable({ providedIn: 'root' })
export class PasswordService {
  constructor(private sessionService: SessionService, private env: EnvironmentService, private http: HttpClient) {}

  getRequirements = (): Record<string, number | string> => {
    return {
      minLength: 8,
      maxLength: 72,
      specialCharacters: '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~',
      uppercaseLetters: 'A-Z',
      lowercaseLetters: 'a-z',
      numbers: '0-9',
    };
  };

  /**
   * Triggers a mail with instructions how to set a new password
   */
  resetPassword = (email: string): Promise<User & { reset_password_token: string }> => {
    return lastValueFrom(
      this.http.post<User & { reset_password_token: string }>(`${this.env.apiUrl}/users/password`, {
        user: { email: email },
      }),
    );
  };

  /* Lost password, not authenticated */
  changePassword = (token: string, password: string, passwordConfirmation: string): Promise<UserWithToken> => {
    return lastValueFrom(
      this.http.put<UserWithToken>(`${this.env.apiUrl}/users/password`, {
        user: {
          reset_password_token: token,
          password: password,
          password_confirmation: passwordConfirmation,
        },
      }),
    );
  };

  /* Edit password, user is already authenticated and authorized */
  updatePassword = (
    password: string,
    passwordConfirmation: string,
    currentPassword: string,
  ): Promise<UserWithToken> => {
    const currentUserId = this.sessionService.getCurrentUser().id;
    return lastValueFrom(
      this.http.patch<UserWithToken>(`${this.env.apiUrl}/users/${currentUserId}/update_password`, {
        user: {
          current_password: currentPassword,
          password: password,
          password_confirmation: passwordConfirmation,
        },
      }),
    );
  };
}
