<div class="margin-top--3x">
  <div>
    <h3 class="thin" *ngIf="!assets.length && !loading">Du har inte laddat upp några filmer ännu.</h3>
    <dot-loader [visibility]="loading" [large]="true" description="Hämtar filmer..."></dot-loader>
    <div *ngIf="!loading">
	    <h2>Kampanjfilmer</h2>
	    <div class="flex__container">
	    	<div class="asset__item" *ngFor="let asset of assets" (click)="toggleAsset(asset)" [ngClass]="{'selected': asset === selectedAsset}">
          <video [src]="asset.public_url"></video>
		      <p>{{asset.name}}</p>
	    	</div>
	    </div>

    </div>
  </div>
  <div class="text--center">
  	<button type="button" class="button primary-1" (click)="confirm()" [disabled]="!selectedAsset" id="select-asset-btn">Välj</button>
  	<button type="button" class="link primary" (click)="cancel()">Avbryt</button>
  </div>
</div>
