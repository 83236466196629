import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../../utils/types';
import { AdServerService } from '../../../woo_services.module/AdServerService';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { Campaign } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'frequency-limit',
  templateUrl: './frequency-limit.component.html',
})
export class FrequencyLimit implements OnChanges {
  @Input() campaign: Campaign;
  frequencyLimitActive: boolean;

  constructor(
    private adServerService: AdServerService,
    private campaignService: CampaignService,
    private dialogService: DialogService,
  ) {}

  ngOnChanges(changes: SimpleChanges<FrequencyLimit>): void {
    if (changes.campaign && this.campaign) {
      this.frequencyLimitActive = this.campaign.default_video_frequency_limit;
    }
  }

  updateCampaignDefaultFrequencyLimit(): void {
    let errorMsg = 'Nu ville det sig inte riktigt. Var god försök igen!';
    this.dialogService.openBlocking('Uppdaterar frekvenstaket och skickar till adservern.');
    this.campaignService
      .updateCampaignDefaultFrequencyLimit(this.campaign.id, this.frequencyLimitActive)
      .then(() => {
        errorMsg =
          'Frekvenstaket uppdaterades men kampanjen kunde inte skickas till adservern. Var god försök att skicka till adservern manuellt.';
        return this.adServerService.sendCampaign(this.campaign.id);
      })
      .then(() => {
        this.dialogService.closeBlocking();
        this.campaign.default_video_frequency_limit = this.frequencyLimitActive;
        this.dialogService.openSuccess('Frekvenstaket uppdaterades.');
      })
      .catch(() => {
        this.dialogService.closeBlocking();
        this.dialogService.openError('Attans!', errorMsg);
      });
  }
}
