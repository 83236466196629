import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trackById } from '../../utils/object';
import { ExternalCampaign } from '../services/ExternalCampaignService';

@Component({
  selector: 'invalid-list',
  templateUrl: './invalid-list.component.html',
})
export class InvalidListComponent {
  @Input() loading = true;
  @Input() campaigns: ExternalCampaign[] = [];
  @Output() onOpenDetails = new EventEmitter<ExternalCampaign>();

  readonly trackById = trackById;

  openDetails(campaign: ExternalCampaign): void {
    this.onOpenDetails.emit(campaign);
  }
}
