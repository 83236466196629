import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../woo_services.module/AuthService';
import { DashboardService } from '../../woo_services.module/DashboardService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import {
  CampaignStatus,
  CombinedViewCurrencies,
  CompactCampaign,
  ViewCurrency,
} from '../../woo_services.module/shared-types';

@Component({
  selector: 'dashboardcard',
  templateUrl: './dashboard-card.component.html',
  host: { '(click)': 'click()', '(focus)': 'focus()' },
})
export class DashboardCard implements OnInit {
  readonly adminOrPlanner = this.authService.adminOrPlanner();

  @Input() campaign: CompactCampaign;
  @Input() active: boolean;
  @Input() hasRoleAdminOrPlanner: boolean;
  @Input() cardListing: boolean;
  @Output() onEditCallback = new EventEmitter<CompactCampaign>();
  @Output() onFocus = new EventEmitter<CompactCampaign>();
  @Output() onClick = new EventEmitter<CompactCampaign>();

  allowedToEditCreatives: boolean;

  convertViewCurrency = this.translationService.convertViewCurrency;
  convertViewCurrencyShort = this.translationService.convertViewCurrencyShort;
  convertViewCurrencyExtraShort = this.translationService.convertViewCurrencyExtraShort;

  constructor(
    private dashboardService: DashboardService,
    private translationService: TranslationService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.setAllowedToEditCreatives();
  }

  getTrackingValue(campaign: CompactCampaign): number {
    if (!(campaign && campaign.tracking)) {
      return 0;
    }
    return this.viewCurrencyTrackingValue(campaign);
  }

  viewCurrencyTrackingValue(campaign: CompactCampaign): number {
    if (campaign.view_currency === ViewCurrency.grossRatingViews) {
      return campaign.tracking?.gross_rating_views;
    } else if (campaign.view_currency === ViewCurrency.completedViews) {
      return campaign.tracking?.completed_views;
    } else if (campaign.view_currency === ViewCurrency.impressions) {
      return campaign.tracking?.impressions;
    } else if (campaign.view_currency === CombinedViewCurrencies.impgrv) {
      return campaign.tracking.impressions + campaign.tracking?.gross_rating_views;
    } else if (campaign.view_currency === CombinedViewCurrencies.impcv) {
      return campaign.tracking.impressions + campaign.tracking?.completed_views;
    } else {
      return 0;
    }
  }

  async setAllowedToEditCreatives(): Promise<void> {
    this.allowedToEditCreatives = await this.dashboardService.allowedToEditCreatives(this.campaign);
  }

  editCallback(): void {
    this.onEditCallback.emit(this.campaign);
  }

  expectedTrackingData(): boolean {
    return ![CampaignStatus.unbooked, CampaignStatus.booked, CampaignStatus.upcoming].includes(this.campaign.status);
  }

  click(): void {
    this.onClick.emit(this.campaign);
  }

  focus(): void {
    this.onFocus.emit(this.campaign);
  }
}
