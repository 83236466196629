import { Component, Input } from '@angular/core';
import { CreativeStatus } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

const template = /* html */ `
<span *ngIf="status===CreativeStatus.Unknown" class="icon-question-mark-outline text--cta icon__semi-medium"></span>
<span *ngIf="status===CreativeStatus.NotReady" class="icon-waiting-outline text--cta icon__semi-medium"></span>
<span *ngIf="status===CreativeStatus.PeachFileNotFound" class="icon-heads-up-outline text--danger icon__semi-medium"></span>
<span *ngIf="status===CreativeStatus.Ready" class="icon-check text--success icon__semi-medium"></span>
{{convertCreativeStatus(status)}}
`;

@Component({
  selector: 'creative-status-label',
  template: template,
})
export class CreativeStatusLabel {
  readonly CreativeStatus = CreativeStatus;
  readonly convertCreativeStatus = this.translationService.convertCreativeStatus;
  @Input() status: CreativeStatus;
  constructor(private translationService: TranslationService) {}
}
