<div class="full-width-view">

  <div class="section lighter-gray shadow-z-1">
    <div class="content__container">
      <button
        id="new-campaign-btn"
        class="button primary-1 margin-top-bottom--3x"
        [routerLink]="['/customer', customerId, 'campaign']"
        disabled
      >Boka ny kampanj</button>
      <h3>Tyvärr går det inte längre att boka kampanjer som cashkund</h3>
    </div>
  </div>

  <div class="content__container padding-top-bottom--4x">
    <h1 class="display--inline-block">Mina kampanjer</h1>
    <notification-badge class="visible-xs-inline-block margin-left--1x" *ngIf="rejectedCampaigns > 0" [count]="rejectedCampaigns"></notification-badge>
    <div class="tabs-container padding-top--3x hidden-xs">
      <div *ngFor="let tab of campaignTabs" class="display--inline-block tab-wrapper">
        <button class="tab-button" [ngClass]="{'active': isTabActive(tab)}" (click)="setActiveTab(tab)">
          {{translateTab(tab)}}
        </button>
        <notification-badge *ngIf="tab === DashboardTab.booked && rejectedCampaigns > 0" [count]="rejectedCampaigns"></notification-badge>
      </div>
    </div>
    <div class="select__container no-label visible-xs display--inline-block">
      <select id="campaign-status" [(ngModel)]="activeTab" class="no-valid-color">
        <option [ngValue]="tab" *ngFor="let tab of campaignTabs">{{translateTab(tab)}}</option>
      </select>
    </div>

    <context-message
      *ngIf="rejectedCampaigns > 0 && !dismissedRejectedCampaignsNotification()"
      [hidden]="activeTab !== DashboardTab.booked"
      [message]="rejectionMessage()"
      messageType="error"
      (dismiss)="dismissRejectedCampaignsNotification()"
    ></context-message>
  </div>

  <div id="campaigns-container">
    <div class="content__container hidden-xs padding-bottom--3x">
      <cash-dashboard-card-desktop
        *ngFor="let campaign of campaigns.get(activeTab)"
        (click)="showSummaryDialog(campaign)"
        [campaign]="campaign"
        class="margin-right--2x margin-bottom--2x cash-dashboard-card display--inline-block"
        (onDelete)="deleteCampaign($event)"
      ></cash-dashboard-card-desktop>
    </div>

    <div class="visible-xs">
      <cash-dashboard-card-mobile
        *ngFor="let campaign of campaigns.get(activeTab)"
        (click)="showSummaryDialog(campaign)"
        [campaign]="campaign"
        class="cash-dashboard-card"
        (onDelete)="deleteCampaign($event)"
      ></cash-dashboard-card-mobile>
    </div>
  </div>

  <div
    *ngIf="campaigns.get(activeTab).length === 0"
    class="width--100 margin-bottom--2x text-align--center cash-dashboard-card--sized-desktop">
    <dot-loader [visibility]="loadingCampaigns" [large]="true"></dot-loader>
    <div *ngIf="!loadingCampaigns">
      <img class="empty__image" src="../images/empty_state.svg" alt="">
      <p class="width--100 text--large text--italic text--gray">Här kommer dina kampanjer att visas.<br>
        WOO stödjer tyvärr inte längre bokning av cashkampanjer.
      </p>
      <button id="new-campaign-btn2" class="button primary" [routerLink]="['/customer', customerId, 'campaign']" disabled>Boka ny kampanj</button>
    </div>
  </div>

  <div class="section primary padding-top-bottom--4x">
    <div class="content__container">
      <info-section></info-section>
    </div>
  </div>
</div>

