<div class="flex__container distribution-row text--semi-strong border--bottom border-width--thin border">
  <div class="item">{{itemType}}</div>
  <div class="item">Budget</div>
  <div class="item">{{currency}}</div>
  <div class="item">Fördelning</div>
</div>
<div
  class="flex__container distribution-row"
  *ngFor="let item of model; trackBy: trackById"
>
  <div class="item">
    {{item.name}}
  </div>
  <div class="item">
    <div *ngIf="shareType === ShareType.Budget">
      <input
        [attr.e2e-id]="item.name"
        class="input--compact input--max-width"
        [(ngModel)]="item.budget"
        (ngModelChange)="change()"
        wooNumberFormatter
        [wooMinValidator]="0"
        [wooMaxValidator]="totalToDistribute"
      />
      <remaining-button (click)="distributeRemainingTo(item)"></remaining-button>
    </div>
    <span *ngIf="shareType === ShareType.CompletedViews">{{ item.budget | currency:'SEK' | dashAsDefault }}</span>
  </div>
  <div class="item">
    <div *ngIf="shareType === ShareType.CompletedViews">
      <input
        *ngIf="shareType === ShareType.CompletedViews"
        [attr.e2e-id]="item.name"
        class="input--compact input--max-width"
        [(ngModel)]="item.completedViews"
        (ngModelChange)="change()"
        wooNumberFormatter
        [wooMinValidator]="0"
        [wooMaxValidator]="totalToDistribute"
      />
      <remaining-button (click)="distributeRemainingTo(item)"></remaining-button>
    </div>
    <span *ngIf="shareType === ShareType.Budget">{{ item.completedViews | number | dashAsDefault }}</span>
  </div>
  <div class="item">
    <span>{{ item.percent | percent | dashAsDefault }}</span>
  </div>
</div>
