import { Component } from '@angular/core';

@Component({
  selector: 'available-icons-style',
  template: `
    <div>
      <div class="row">
        <div class="col-xs-6">
          <woo-dropdown [items]="availableIcons" [(ngModel)]="choosenIcon" placeholder="Välj en ikon..."></woo-dropdown>
        </div>
        <div class="col-xs-2"></div>
        <div class="section info text--center col-xs-2 padding--3x icon-box">
          <span class="{{ choosenIcon }}"></span>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-xs-4" style="font-size: 24px" *ngFor="let icon of availableIcons">
          <span class="{{ icon }}"></span>
          <h5 class="display--inline-block margin-left--2x">{{ icon }}</h5>
        </div>
      </div>
    </div>
  `,
  styles: ['.icon-box {width: 150px; height: 100px; font-size: 48px; color: black; border-radius: 5px}'],
})
export class AvailableIconsStyle {
  readonly availableIcons = [
    'icon-open-new-window',
    'icon-waiting-filled',
    'icon-waiting-outline',
    'icon-not-happy',
    'icon-pause-outlined',
    'icon-play-outlined',
    'icon-close-x',
    'icon-shopping-cart',
    'icon-thumbnails-grid',
    'icon-thumbs-large',
    'icon-thumbs-single-row',
    'icon-heads-up-outline',
    'icon-question-mark-outline',
    'icon-change-user',
    'icon-android',
    'icon-ios',
    'icon-pause-filled',
    'icon-locked',
    'icon-unlocked',
    'icon-off',
    'icon-on',
    'icon-calendar',
    'icon-Category',
    'icon-check-filled',
    'icon-check',
    'icon-close-x-filled',
    'icon-continue-watching',
    'icon-download',
    'icon-edit',
    'icon-eye-hide',
    'icon-family-kids',
    'icon-happy',
    'icon-home',
    'icon-jump-back',
    'icon-jump-forward',
    'icon-link',
    'icon-live-dot',
    'icon-log-out',
    'icon-mail',
    'icon-maximize',
    'icon-menu',
    'icon-minimize',
    'icon-miniplayer',
    'icon-more',
    'icon-movies',
    'icon-News',
    'icon-notification',
    'icon-play-filled',
    'icon-plus',
    'icon-program-guide',
    'icon-refresh',
    'icon-replay',
    'icon-search',
    'icon-series',
    'icon-shield',
    'icon-sport',
    'icon-trash',
    'icon-tv-channels',
    'icon-User',
    'icon-volume-1',
    'icon-volume-2',
    'icon-volume-mute',
    'icon-volume',
    'icon-bookmark',
    'icon-bookmarked',
    'icon-heads-up-filled',
    'icon-info-filled',
    'icon-info-outline',
    'icon-question-mark-filled',
    'icon-warning-outline',
    'icon-TV4_001_kategorier',
    'icon-TV4_002_sok',
    'icon-TV4_003_anvandare',
    'icon-TV4_004_dela',
    'icon-TV4_005_spara',
    'icon-TV4_006_support',
    'icon-TV4_007_stang',
    'icon-TV4_008_fram',
    'icon-TV4_009_bak',
    'icon-TV4_010_upp',
    'icon-TV4_011_ner',
    'icon-TV4_012_check',
    'icon-TV4_013_filter',
    'icon-TV4_014_placeholder',
    'icon-TV4_015_meny',
    'icon-TV4_016_kommentar',
    'icon-TV4_017_tidsbegransad',
    'icon-TV4_024_chromecast',
    'icon-TV4_025_airplay',
    'icon-TV4_026_kontakt',
    'icon-TV4_028_desktop',
    'icon-TV4_029_tablet',
    'icon-TV4_030_smartphone',
    'icon-TV4_031_nyheter',
    'icon-TV4_032_tillatet-for-alla',
    'icon-TV4_033_tillatet-fran-7-ar',
    'icon-TV4_034_tillatet-fran-11-ar',
    'icon-TV4_035_tillatet-fran-15-ar',
    'icon-TV4_036_tillatet-fran-18-ar',
    'icon-TV4_037_fotboll',
    'icon-TV4_038_ishockey',
    'icon-TV4_039_ovrig_sport',
    'icon-TV4_040_live_sport',
    'icon-TV4_041_utforska',
    'icon-TV4_043_instagram',
    'icon-TV4_044_pinterest',
    'icon-TV4_045_facebook',
    'icon-TV4_046_twitter',
    'icon-TV4_047_kortbetalning',
    'icon-TV4_048_fakturabetalning',
    'icon-TV4_049_direktbetalning_internetbank',
    'icon-TV4_050_saker_betalning',
    'icon-TV4_051_valdsinslag',
    'icon-TV4_052_sexuellt-innehall',
    'icon-TV4_053_kan_skapa_angest',
    'icon-TV4_054_innehaller_rusningsmedel',
    'icon-TV4_055_premiar',
    'icon-TV4_056_direktsandning',
    'icon-TV4_057_sista_chansen',
    'icon-TV4_058_skrattar',
    'icon-TV4_059_glad',
    'icon-TV4_060_flirt',
    'icon-TV4_061_nojd',
    'icon-TV4_062_ledsen',
    'icon-TV4_063_arg',
    'icon-TV4_064_trott',
    'icon-TV4_065_visa',
    'icon-TV4_066_dolj_1',
    'icon-TV4_066_dolj_2',
    'icon-TV4_067_golf',
    'icon-TV4_068_film',
    'icon-TV4_069_barn',
    'icon-TV4_070_serier',
    'icon-TV4_071_animerat',
    'icon-TV4_072_action',
    'icon-TV4_073_drama',
    'icon-TV4_074_deckare',
    'icon-TV4_075_dokumentar',
    'icon-TV4_076_familj',
    'icon-TV4_077_fantasy',
    'icon-TV4_078_komedi',
    'icon-TV4_079_thriller',
    'icon-TV4_080_skrack',
    'icon-TV4_081_science-fiction',
    'icon-TV4_082_svenskt',
    'icon-TV4_083_ovriga-genres',
    'icon-TV4_084_TV',
    'icon-TV4_085_undo',
    'icon-TV4_086_favoritborttagen',
    'icon-TV4_087_film-serier',
    'icon-TV4_089_video',
    'icon-TV4_092_rating',
    'icon-TV4_094_skriv-ut',
    'icon-TV4_096_e-handel',
    'icon-TV4_102_smartTV',
    'icon-TV4_103_x-box',
    'icon-TV4_106_multiple-devices',
    'icon-TV4_113_reklamfritt',
    'icon-TV4_114_klipp',
    'icon-TV4_115_instream',
    'icon-TV4_116_outstream',
    'icon-TV4_117_styrning',
    'icon-TV4_118_lokal_styrning',
    '',
  ];
  choosenIcon: string;
}
