<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera hur estimaten ska räknas om till completed views</h3>
</div>

<div *ngIf="show.estimatesHaveChanged" class="tabbable__body">
  <import-warning></import-warning>
  <dot-loader [visibility]="show.updatingEstimates"></dot-loader>
</div>

<div>
  <div #stickyHeader>
    <div class="tabbable__body">
      <div class="button-filter-container">
        <div>
          <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="completionRates.invalid">Spara</button>
          <button *ngIf="!show.isEditing" class="button primary" (click)="setEditing(true)">Redigera completion rate</button>
          <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
        </div>
        <div class="display--flex">
          <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="publishers"
            [(ngModel)]="selectedPublisher"
            name="selectedPublisher"
            inputId="select-publisher"
            labelText="Filtrera på Publicist"
            notFoundText="Inga publicister hittades."
            [clearable]="true"></woo-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Tillgängliga publicister</h3>
      </div>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table-woo table--info table--hover width--100" ngForm #completionRates="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-center" colspan="5">
            Completion Rate
          </th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let row of completionRateRows; let index = index;">
        <tr [hidden]="!rowVisible(row)">
          <td class="text--thin vertical-align--middle">
            <span>{{row.publisher}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.preroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPreroll"
                [(ngModel)]="getPublisherCompletionRate(row.publisherId).preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedPublisherSlotTypes[row.publisherId].preroll"
                [disabled]="!includedPublisherSlotTypes[row.publisherId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.midroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesMidroll"
                [(ngModel)]="getPublisherCompletionRate(row.publisherId).midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedPublisherSlotTypes[row.publisherId].midroll"
                [disabled]="!includedPublisherSlotTypes[row.publisherId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.postroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPostroll"
                [(ngModel)]="getPublisherCompletionRate(row.publisherId).postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedPublisherSlotTypes[row.publisherId].postroll"
                [disabled]="!includedPublisherSlotTypes[row.publisherId].postroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.prerollSticky | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPrerollSticky"
                [(ngModel)]="getPublisherCompletionRate(row.publisherId).preroll_sticky"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedPublisherSlotTypes[row.publisherId].preroll_sticky"
                [disabled]="!includedPublisherSlotTypes[row.publisherId].preroll_sticky">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.postrollSticky | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPostrollSticky"
                [(ngModel)]="getPublisherCompletionRate(row.publisherId).postroll_sticky"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedPublisherSlotTypes[row.publisherId].postroll_sticky"
                [disabled]="!includedPublisherSlotTypes[row.publisherId].postroll_sticky">
            </div>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>

    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
      <button class="button primary" (click)="save()" [disabled]="completionRates.invalid">Spara</button>
      <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>
