import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { CampaignMmsGainFormData } from '../../../woo_components.module/campaign_mms_gain_form/campaign-mms-gain-form.component';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { DialogService } from '../../../woo_services.module/DialogService';
import {
  Campaign,
  CampaignStatus,
  DashboardTab,
  GoalUpdateBookingError,
  UpdateGoalsJobParams,
  UpdateGoalsJobResult,
  UpdateGoalsJobTargetingData,
  wooId,
} from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'campaign-goals',
  templateUrl: './campaign-goals.component.html',
})
export class CampaignGoalsComponent extends SubscriptionHandler implements OnDestroy {
  campaign: Campaign;
  editGoals = false;
  canSave: boolean;
  hasChanges: boolean;
  statusDisabled: boolean;

  constructor(
    private campaignService: CampaignService,
    private dashboardStore: DashboardStore,
    private dialogService: DialogService,
    private translationService: TranslationService,
  ) {
    super();
    this.dashboardStore.state$.subscribe(this.initFromStore);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.cancelEditGoals();
  }

  initFromStore = (model: DashboardModel): void => {
    if (model.activeCampaign) {
      this.campaign = model.activeCampaign;
      this.statusDisabled =
        model.activeCampaign.status !== CampaignStatus.upcoming &&
        model.activeCampaign.status !== CampaignStatus.ongoing;
    }

    this.editGoals = model.editGoals;
    this.canSave = model.modifiedGoals ? this.combineValidityStatesFromStore() : false;
    this.hasChanges = model.modifiedGoals ? this.combineModifiedStatesFromStore() : false;
  };

  startEditGoals = (): void => {
    this.dashboardStore.setEditGoals(true);
  };

  cancelEditGoals = (): void => {
    this.dashboardStore.setEditGoals(false);
  };

  saveEditGoals = (): void => {
    if (!this.canSave) {
      return;
    }

    if (!this.hasChanges) {
      this.dialogService.openSuccess('Det här gick lättare än förväntat, inga ändringar att spara :)');
      this.dashboardStore.setEditGoals(false);
    } else {
      this.dialogUpdateGoals();
    }
  };

  onMmsFormChange = (mmsFormData: CampaignMmsGainFormData): void => {
    const mergedMmsFormValues = {
      ...this.dashboardStore.state.modifiedGoals?.mmsForm.values,
      ...mmsFormData,
    };

    this.dashboardStore.setModifiedGoalsMmsFormValues(mergedMmsFormValues);
  };

  onMmsFormValidityChange = (valid: boolean): void => {
    this.dashboardStore.setModifiedGoalsMmsFormValidity(valid);
  };

  getTargetingExpandedFromStore = (targetingId: wooId): boolean => {
    const expandedTargetings = this.dashboardStore.state.expandedTargetingHeaders?.[DashboardTab.goals];
    return expandedTargetings?.[targetingId] ?? true;
  };

  onTargetingHeaderExpandedChange = (expanded: boolean, targetingId: wooId): void => {
    this.dashboardStore.setExpandedTargetingHeader(DashboardTab.goals, targetingId, expanded);
  };

  private dialogUpdateGoals = () => {
    const successMessage = 'WOO har matat in de extra visningarna i sitt lager';
    const updateParams = this.convertModifiedGoalsToParams(this.dashboardStore.state.modifiedGoals);
    let continueEditingAfterSumbit = false;

    this.updateGoals(updateParams)
      .then(
        () => {
          this.dialogService.openSuccess(`${successMessage}.`);
        },
        (res) => {
          if (res.booking_error === GoalUpdateBookingError.goalTotalImpressionsBelowZero) {
            continueEditingAfterSumbit = true;
          }

          if (!updateParams.force && res.booking_error === GoalUpdateBookingError.insufficientInventory) {
            return this.dialogService.openConfirm(this.getConfirmOverbookingDialogContent()).then(
              () => {
                updateParams.force = true;
                return this.updateGoals(updateParams).then(() => {
                  this.dialogService.openSuccess(`${successMessage}, men är nu överbokat.`);
                });
              },
              () => {
                continueEditingAfterSumbit = true;
              },
            );
          } else {
            throw res;
          }
        },
      )
      .catch(this.showBookingError)
      .finally(() => {
        if (!continueEditingAfterSumbit) {
          this.dashboardStore.setEditGoals(false);
          this.reloadCampaignFromServer(updateParams.campaign_id);
        }
      });
  };

  private updateGoals = (updateParams: UpdateGoalsJobParams) => {
    this.dialogService.openBlocking('Uppdaterar WOOs lager');
    return this.campaignService.updateGoals(updateParams).then(
      (response) => {
        this.dialogService.closeBlocking();
        return response;
      },
      (error: UpdateGoalsJobResult) => {
        this.dialogService.closeBlocking();
        throw error;
      },
    );
  };

  private getConfirmOverbookingDialogContent = (): ConfirmDialogContent => ({
    header: 'Varning!',
    textBlocks: [
      `${this.translationService.convertUpdateGoalsError(GoalUpdateBookingError.insufficientInventory)}`,
      'Är du säker på att du vill överboka?',
    ],
    confirmText: 'Överboka lagret',
    cancelText: 'Avbryt',
  });

  private showBookingError = (error: UpdateGoalsJobResult) => {
    if (error.booking_error && !(error instanceof HttpErrorResponse)) {
      this.dialogService.openError(this.translationService.convertUpdateGoalsError(error.booking_error));
    }
  };

  private reloadCampaignFromServer = (id: wooId = this.campaign.id) => {
    this.campaignService.get(id).then((campaign) => {
      this.dashboardStore.setActiveCampaign(campaign);
    });
  };

  private combineValidityStatesFromStore = (): boolean => {
    const mmsForm = this.dashboardStore.state.modifiedGoals.mmsForm.valid;
    const targetingForms = Object.values(this.dashboardStore.state.modifiedGoals.targetingForms).every((t) => t.valid);
    const goalForms = Object.values(this.dashboardStore.state.modifiedGoals.goalForms)
      .flatMap((t) => Object.values(t).map((g) => g.valid))
      .every((value) => value);
    return mmsForm && targetingForms && goalForms;
  };

  private combineModifiedStatesFromStore = (): boolean => {
    const mmsForm = this.dashboardStore.state.modifiedGoals.mmsForm.modified;
    const targetingForms = Object.values(this.dashboardStore.state.modifiedGoals.targetingForms).some(
      (t) => t.modified,
    );
    const goalForms = Object.values(this.dashboardStore.state.modifiedGoals.goalForms)
      .flatMap((t) => Object.values(t).map((g) => g.modified))
      .some((value) => value);
    return mmsForm || targetingForms || goalForms;
  };

  private convertModifiedGoalsToParams = (
    modifiedGoals: DashboardModel['modifiedGoals'],
    force = false,
  ): UpdateGoalsJobParams => {
    const targetings: UpdateGoalsJobTargetingData[] = Object.entries(modifiedGoals.targetingForms).map(
      ([targetingId, targetingForm]) => ({
        id: targetingId,
        additional_goal_budget: targetingForm.values.additionalGoalBudget,
        additional_goal_budget_message: targetingForm.values.additionalGoalBudgetMessage,
        goals: Object.entries(modifiedGoals.goalForms[targetingId]).map(([goalId, goalForm]) => ({
          id: goalId,
          additional_impressions: goalForm.values.additionalImpressions,
        })),
      }),
    );

    return {
      campaign_id: this.campaign.id,
      force: force,
      mms_gain: modifiedGoals.mmsForm.values.mmsGain,
      targetings: targetings,
    };
  };
}
