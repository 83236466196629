<div class="padding-left-right--1x">
  <p class="text--small margin--none">
    Senast uppdaterad {{ formattedDays }}.
  </p>
  <ng-container *ngIf="!campaign.ttv_campaign">
    <download-outcome-button
      [campaignId]="campaign.id"
      [campaignName]="campaign.name"
      *ngIf="campaign?.outcome_report_id"
    ></download-outcome-button>
    <p
      class="thin padding-bottom--2x margin--none"
      *ngIf="adminOrPlanner && !campaignHasEnded"
    >
      Kampanjen måste avslutas innan slutstatistiken kan aktiveras.
    </p>
    <div class="padding-bottom--2x" *ngIf="adminOrPlanner && campaignHasEnded">
      <button class="button" (click)="showAdminPanel = !showAdminPanel">
        {{ showAdminPanel ? "Dölj panel" : "Administrera rapport" }}
      </button>
      <edit-outcome-report
        *ngIf="showAdminPanel"
        [campaign]="campaign"
        [tracking]="tracking"
      ></edit-outcome-report>
    </div>
    <div class="divider"></div>
  </ng-container>
</div>

<h3 class="display--inline-block margin-top--2x margin--bottom-2x margin-left--1x">
  <targeting-label [label]="campaignLabel"></targeting-label>
</h3>
<div class="width--50">
  <two-col-table
    bold="left"
    enlarge="right"
    [left]="leftCol"
    [right]="rightCol"
  ></two-col-table>
</div>

<div class="shadow-z-1 margin-bottom--1x margin-top--2x" *ngIf="adminOrPlanner && campaignHasTvDaysOutcome">
  <expandable-header backgroundColor="white" text="MMS - Uppföljning">
    <tv-day-outcome
      [tracking]="tracking"
      [isTTVCampaign]="campaign.ttv_campaign"
      [useGrossRating]="isGrossRating"></tv-day-outcome>
  </expandable-header>
</div>
