import { Component } from '@angular/core';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { DialogService } from '../../../woo_services.module/DialogService';
import { Campaign, CampaignStatus, wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'automatic-mms-gain-toggle',
  templateUrl: './automatic-mms-gain-toggle.component.html',
})
export class AutomaticMmsGainToggle extends SubscriptionHandler {
  campaign: Campaign;
  automaticMmsGainActive: boolean;
  mmsTrackedCampaign: boolean;
  wrongCampaignStatus: boolean;
  private campaignSentToAdserver: boolean;

  constructor(
    private campaignService: CampaignService,
    private dashboardStore: DashboardStore,
    private dialogService: DialogService,
  ) {
    super();
    this.dashboardStore.state$.subscribe(this.initFromStore);
  }

  initFromStore = (model: DashboardModel): void => {
    if (model.activeCampaign) {
      this.campaign = model.activeCampaign;
      this.mmsTrackedCampaign = !!model.activeCampaign.mms_tracked;
      this.automaticMmsGainActive = model.activeCampaign.automatic_mms_gain && this.mmsTrackedCampaign;
      this.campaignSentToAdserver = !!model.activeCampaign.external_id?.length;
      this.wrongCampaignStatus = !this.campaignService.hasAnyStatus(this.campaign, [
        CampaignStatus.booked,
        CampaignStatus.upcoming,
        CampaignStatus.ongoing,
        CampaignStatus.approved,
      ]);
    }
  };

  async updateCampaignAutomaticMmsGain(): Promise<void> {
    const errorMsg = 'Nu ville det sig inte riktigt. Var god försök igen!';
    this.dialogService.openBlocking(
      `${this.automaticMmsGainActive ? 'Startar' : 'Stänger'} automatisk MMS-stärkning och skickar till adservern`,
    );
    await this.campaignService.updateCampaignAutomaticMmsGain(this.campaign.id, this.automaticMmsGainActive);
    try {
      this.dialogService.closeBlocking();
      this.campaign.automatic_mms_gain = this.automaticMmsGainActive;
      this.dialogService.openSuccess(
        `Automatisk MMS-stärkning är nu ${this.automaticMmsGainActive ? 'Aktiverad' : 'Avstängd'}`,
      );
    } catch {
      this.dialogService.closeBlocking();
      this.dialogService.openError('Attans!', errorMsg);
    } finally {
      this.reloadCampaignFromServer(this.campaign.id);
    }
  }

  saveButtonLabel(): string {
    if (!this.campaignSentToAdserver) {
      return 'Spara';
    }

    return 'Spara och skicka till adservern';
  }

  toggleIsSetToCampaignValue(): boolean {
    return this.campaign.automatic_mms_gain === this.automaticMmsGainActive;
  }

  private reloadCampaignFromServer = (id: wooId) => {
    this.campaignService.get(id).then((campaign) => {
      this.dashboardStore.setActiveCampaign(campaign);
    });
  };
}
