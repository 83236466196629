import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdServerService } from '../../../woo_services.module/AdServerService';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { Campaign } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'review-campaign',
  templateUrl: './review-campaign.component.html',
})
export class ReviewCampaign {
  campaign: Campaign;
  firstCreative: any;
  assetUrl: string;
  reviewStatus: 'rejected' | 'approved';
  rejectMessage: string;

  constructor(
    private adServerService: AdServerService,
    private dialogService: DialogService,
    private translationService: TranslationService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    protected routingService: RoutingService,
  ) {
    campaignService.get(routingService.getParam(this.route, 'id')).then((campaign) => {
      this.campaign = campaign;
      this.firstCreative = campaign.targetings[0].creatives[0];
      this.assetUrl = this.firstCreative.asset_url;
    });
  }

  submitForm = async (): Promise<void> => {
    if (this.reviewStatus === 'approved') {
      await this.approveCampaign();
    } else {
      await this.rejectCampaign();
    }
  };

  rejectCampaign = async (): Promise<void> => {
    this.dialogService.openBlocking('Nekar kampanj...');
    const campaign = await this.campaignService.reviewCampaign(this.campaign.id, this.reviewStatus, this.rejectMessage);
    this.dialogService.openSuccess(
      'Kom ihåg att skicka ett mail till kunden med anledningen till att den nekades.',
      'Anledningen du angav var: ' + this.rejectMessage,
    );
    await this.routingService.goToDashboardWithCampaign(campaign.id);
  };

  approveCampaign = async (): Promise<void> => {
    this.dialogService.openBlocking('Godkänner kampanj och skickar till adservern...');

    await Promise.all([
      this.campaignService.reviewCampaign(this.campaign.id, this.reviewStatus, this.rejectMessage),
      this.campaignService.updateCreatives(this.campaign.id, this.campaign.targetings[0].id, [this.firstCreative]),
    ]);
    await this.adServerService.sendCampaign(this.campaign.id);
    try {
      this.dialogService.openSuccess(
        'Kampanjen har godkänts och skickats till adservern',
        'Kom ihåg att skicka ett mail till kunden.',
      );
      await this.routingService.goToDashboardWithCampaign(this.campaign.id);
    } catch (errorResponse) {
      const msg = this.translationService.convertSendToAdserverError(errorResponse.error);
      this.dialogService.openError(msg);
    }
  };
}
