import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { AbstractValueAccessor } from '../../../utils/AbstractValueAccessor';
import { verticalExpansion } from '../../../utils/animations';
import { valueAccessorProvider } from '../../../utils/provider-builders';
import { ToFormGroup } from '../../../utils/types';
import { CreativeAssetService } from '../../../woo_services.module/CreativeAssetService';
import { CreativeAssetType, wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'asset-url-input',
  templateUrl: './asset-url-input.component.html',
  styleUrls: ['./asset-url-input.component.scss'],
  providers: [valueAccessorProvider(AssetUrlInput)],
  animations: [verticalExpansion(250)],
})
export class AssetUrlInput extends AbstractValueAccessor<string> {
  @Input() customerId: wooId;
  @Input() validator: ValidatorFn = () => null;
  @Input() isRequired = false;
  @Input() videoCode: string;
  @Input() videoLength: number;
  @Input() hideUnselectedDurations: boolean;
  @Output() onUseSelectedVideosCode = new EventEmitter<string>();

  showVideoSelect = false;
  selectedVideo: VideoItem;

  assets: VideoItem[] = [];
  matchingCodeAssets: VideoItem[] = [];
  matchingDurationAssets: VideoItem[] = [];
  noMatchAssets: VideoItem[] = [];
  unknownDataAssets: VideoItem[] = [];

  urlForm: FormGroup<ToFormGroup<FormModel>> = this.fb.group({
    url: new FormControl(null, this.validator),
  });

  get url(): string {
    return this.urlForm.value.url;
  }

  constructor(private creativeAssetService: CreativeAssetService, private fb: FormBuilder) {
    super();
    this.urlForm.valueChanges.subscribe((value) => this.propagateChange(value.url));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validator && this.validator) {
      this.urlForm.controls.url.clearValidators();
      this.urlForm.controls.url.setValidators(this.validator);
    }
    if (changes.customerId && this.customerId) {
      this.reloadAssets();
    }
    if (changes.videoCode) {
      this.filterMatchingCodeAssets();
    }
    if (changes.videoLength) {
      this.filterMatchingDurationAssets();
    }
  }

  writeValue(model: string): void {
    this.urlForm.controls.url.patchValue(model, { onlySelf: true });
  }

  toggleShowVideoSelect(): void {
    this.showVideoSelect = !this.showVideoSelect;
    if (this.showVideoSelect) {
      this.selectedVideo =
        this.assets.find((asset) => asset.url === this.url) ||
        (this.url ? { duration: null, name: '', url: this.url, videoCode: '' } : null);
    }
  }

  selectedVideoChange(item: VideoItem): void {
    if (!item) {
      this.model = '';
    } else {
      this.model = item.url;
      this.assets = this.assets.concat(item);
    }
    this.urlForm.controls.url.patchValue(this.model);
  }

  selectedVideoClick(item: VideoItem): void {
    this.selectedVideo = item;
    this.urlForm.controls.url.patchValue(item.url);
  }

  showNoMatchAssets(): boolean {
    return Boolean(
      !this.hideUnselectedDurations &&
        this.noMatchAssets.length &&
        !(this.matchingCodeAssets.length || this.matchingDurationAssets.length),
    );
  }

  setVideoCode(videoCode: string): void {
    this.onUseSelectedVideosCode.emit(videoCode);
  }

  private reloadAssets() {
    this.creativeAssetService.getAll(this.customerId).then((assets) => {
      this.assets = assets
        .filter((asset) => asset.asset_type === CreativeAssetType.Video)
        .map((asset) => ({
          duration: asset.duration,
          name: asset.name,
          url: asset.public_url,
          videoCode: asset.video_code,
        }));
      this.setFilteredAssets();
    });
  }

  private setFilteredAssets() {
    this.filterMatchingCodeAssets();
    this.filterMatchingDurationAssets();
    this.filterNoMatchAssets();
    this.filterUnknownDataAssets();
  }

  private filterMatchingCodeAssets(): void {
    this.matchingCodeAssets = !this.videoCode?.length
      ? []
      : this.assets.filter(
          (asset) => asset.videoCode?.localeCompare(this.videoCode, undefined, { sensitivity: 'accent' }) === 0,
        );
  }

  private filterMatchingDurationAssets(): void {
    this.matchingDurationAssets = this.assets
      .filter((asset) => asset.duration === this.videoLength)
      .sort((asset1, asset2) => {
        return asset1.videoCode?.localeCompare(asset2.videoCode);
      });
  }

  private filterNoMatchAssets(): void {
    this.noMatchAssets = this.assets.filter(
      (asset) => asset.videoCode !== this.videoCode && asset.duration !== this.videoLength,
    );
  }

  private filterUnknownDataAssets(): void {
    this.unknownDataAssets = this.assets.filter((asset) => !asset.duration || !asset.videoCode);
  }
}

export interface VideoItem {
  duration: number;
  name: string;
  url: string;
  videoCode: string;
}

interface FormModel {
  url: string;
}
