import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { DeviceGroup, wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeviceGroupService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getDeviceGroups(): Promise<DeviceGroup[]> {
    return lastValueFrom(this.http.get<DeviceGroup[]>(`${this.env.apiUrl}/device_groups`));
  }

  getDeviceGroup(id: wooId): Promise<DeviceGroup> {
    return lastValueFrom(this.http.get<DeviceGroup>(`${this.env.apiUrl}/device_groups/${id}`));
  }

  addDeviceGroup(deviceGroup: Omit<DeviceGroup, 'id'>): Promise<DeviceGroup> {
    return lastValueFrom(
      this.http.post<DeviceGroup>(`${this.env.apiUrl}/device_groups`, { device_group: deviceGroup }),
    );
  }

  updateDeviceGroup(deviceGroup: DeviceGroup): Promise<DeviceGroup> {
    return lastValueFrom(
      this.http.put<DeviceGroup>(`${this.env.apiUrl}/device_groups/${deviceGroup.id}`, {
        device_group: deviceGroup,
      }),
    );
  }
}
