import { Component } from '@angular/core';
import { SystemService } from '../../../woo_services.module/SystemService';
import { WooUiEventService } from '../../../woo_services.module/WooUiEventService';

const template = /* html */ `
<h2 class="margin-top--none" >Automatisk stärkning</h2>
<form *ngIf="initiated" (ngSubmit)="update(mmsGainDefaultValue, mmsGainDefaultVastValue)">
  <div class="input__container">
  <label>{{inputLabel()}}</label>
    <input name="mmsGainDefaultValue"
      [(ngModel)]="mmsGainDefaultValue"
      wooPercentFormatter
      [wooMinValidator]="0"
      [wooMaxValidator]="1">
  </div>
  <div class="input__container">
  <label>{{inputLabelVast()}}</label>
    <input name="mmsGainDefaultVastValue"
      [(ngModel)]="mmsGainDefaultVastValue"
      wooPercentFormatter
      [wooMinValidator]="0"
      [wooMaxValidator]="1">
  </div>
  <button
    type="Submit"
    class="button margin-top--3x"
    [disabled]=invalidMmsDefaultValue()
    wooLoad>Spara</button>
</form>
`;

@Component({
  selector: 'default-mms-value-setting',
  template: template,
})
export class DefaultMmsValueSetting {
  mmsGainDefaultValue: number;
  mmsGainDefaultVastValue: number;
  initiated = false;

  constructor(private systemService: SystemService, private wooUiEventService: WooUiEventService) {}

  async ngOnInit(): Promise<void> {
    const systemSettings = await this.systemService.loadSystemwideSettings();
    this.mmsGainDefaultValue = systemSettings.automatic_mms_gain_default_value;
    this.mmsGainDefaultVastValue = systemSettings.automatic_mms_gain_vast_default_value;
    this.initiated = true;
  }

  update(newMmsGainDefaultValue: number, newMmsGainDefaultVastValue: number): void {
    this.systemService.loadSystemwideSettings().then((systemSettings) => {
      this.systemService
        .update({
          ...systemSettings,
          automatic_mms_gain_default_value: newMmsGainDefaultValue,
          automatic_mms_gain_vast_default_value: newMmsGainDefaultVastValue,
        })
        .finally(() => {
          this.wooUiEventService.stopLoadDotAnimation();
        });
    });
  }

  invalidMmsDefaultValue(): boolean {
    return !(
      this.mmsGainDefaultValue &&
      this.mmsGainDefaultValue <= 1 &&
      this.mmsGainDefaultValue > 0 &&
      this.mmsGainDefaultVastValue &&
      this.mmsGainDefaultVastValue <= 1 &&
      this.mmsGainDefaultVastValue > 0
    );
  }

  inputLabel(): string {
    return 'Defaultvärdet för den automatiska MMS-stärkningen';
  }
  inputLabelVast(): string {
    return 'Defaultvärdet för den automatiska MMS-stärkningen av kampanjer innehållandes VAST länkar';
  }
}
