<div class="row">
  <div class=" col-sm-6">
    <div class="input__container">
      <label for="agency-search">Sök byrå</label>
      <input id="agency-search" type="text" [(ngModel)]="search">
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
    <table class="table table--small table--woo thead--info">
      <thead>
        <tr>
          <th>Byråer</th>
          <th></th>
        </tr>
      </thead>
    </table>
    <div class="scrollable__list border--left border--bottom">
      <table class="table table--small  centered">
        <tbody>
          <tr *ngFor="let agency of filteredAgencies">
            <td>{{agency.nickname}}</td>
            <td><button type="button" class="button primary-1 pull-right" (click)="addAgencyToUser(agency)">Ge
                behörighet</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <table class="table table--small table--woo thead--info">
      <thead>
        <tr>
          <th>Valda byråer</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
    </table>
    <div class="scrollable__list">
      <table class="table table--small centered">
        <tbody>
          <tr *ngFor="let agencyRole of model; index as index">
            <td>{{agencyRole.agency.nickname}}</td>
            <td>
              <input type="checkbox" class="checkbox" [checked]="agencyRole.role === UserRole.agencyAdmin"
                (change)="toggleRole(agencyRole)" id="user-agency{{index}}">
              <label class="checkbox" for="user-agency{{index}}"><span
                  class="text--small thin">Administratör</span></label>
            </td>
            <td>
              <button type="button" class="button danger pull-right" (click)="removeAgencyFromUser(agencyRole)">Ta
                bort behörighet</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
