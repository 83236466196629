import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { intersection } from 'lodash-es';
import { UserRole } from '../../utils/user-roles';
import { AuthService } from '../../woo_services.module/AuthService';
import { SessionService } from '../../woo_services.module/SessionService';

@Injectable({ providedIn: 'root' })
class RoleGuardService {
  constructor(private authService: AuthService, private sessionService: SessionService) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      return false;
    }
    if (route.data.roles && !this.authService.hasAnyRole(route.data.roles)) {
      this.authService.notAuthorized$.next();
      return false;
    }
    if (route.data.exemptedRoles) {
      const user = this.sessionService.getUser();
      const userRoleNames = (user.roles || []).map((role) => role.name);
      const isExempted = intersection(userRoleNames.concat([UserRole.everyone]), route.data.exemptedRoles).length > 0;
      return !isExempted;
    }
    return true;
  }
}

export const RoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean => {
  return inject(RoleGuardService).canActivate(route);
};
