import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from './EnvironmentService';
import { DefaultFrequencyLimit } from './shared-types';

@Injectable({ providedIn: 'root' })
export class DefaultFrequencyLimitService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getAll(): Promise<DefaultFrequencyLimit[]> {
    return lastValueFrom(this.http.get<DefaultFrequencyLimit[]>(`${this.env.apiUrl}/default_frequency_limits`));
  }

  updateDefaultFrequencyLimit(frequency_limit: DefaultFrequencyLimit): Promise<DefaultFrequencyLimit> {
    return lastValueFrom(
      this.http.put<DefaultFrequencyLimit>(`${this.env.apiUrl}/default_frequency_limits/${frequency_limit.id}`, {
        default_frequency_limit: frequency_limit,
      }),
    );
  }
}
