import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WooDialogHelper } from '../../../../woo_components.module/dialogs/woo-dialog-helper';
import { FormatterService } from '../../../../woo_services.module/FormatterService';
import { ToFormGroup } from '../../../../utils/types';

const template = /* html */ `
<woo-dialog>
  <div class="display--block">
    <h2>Stoppa pågående kampanj</h2>
    <p>Initialt ordervärde: {{formattedInitialOrderValue}} kr</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="input__container">
        <label for="newOrderValue">Nytt ordervärde</label>
        <input id="newOrderValue" type="text" formControlName="newOrderValue" [wooNumberFormatter]="{ allowEmpty: false, allowDecimals: true }"/>
        <p class="text--danger text--small" *ngIf="newOrderValue.invalid && form.dirty">{{newOrderValue.errors.invalidOrderValue}}</p>
      </div>
      <div class="input__container">
        <label for="comment">Stoppmeddelande</label>
        <textarea id="comment" type="text" formControlName="comment"></textarea>
        <p class="text--danger text--small" *ngIf="comment.invalid">{{comment.errors.invalidComment}}</p>
      </div>
      <button class="button primary-1" type="submit" [disabled]="!form.valid" e2e-id="confirm">Stoppa kampanj</button>
      <button class="link primary-1" (click)="onAbort()" type="button">Avbryt</button>
    </form>
  </div>
</woo-dialog>
`;

@Component({
  selector: 'stop-campaign-dialog',
  template: template,
})
export class StopCampaignDialog extends WooDialogHelper {
  initialOrderValue: number;
  formattedInitialOrderValue = '';
  onSubmit: () => void;
  onAbort: () => void;

  form: FormGroup<ToFormGroup<StopCampaignData>> = this.fb.group({
    newOrderValue: new FormControl(null, [Validators.required, () => this.validateOrderValue()]),
    comment: new FormControl('', [Validators.required, (comment) => this.validateComment(comment)]),
  });

  constructor(private fb: FormBuilder, private formatterService: FormatterService) {
    super();
  }

  show(currentOrderValue: number): Promise<StopCampaignData> {
    this.initialOrderValue = currentOrderValue;
    this.formattedInitialOrderValue = this.formatterService.transformNumber(this.initialOrderValue);
    return new Promise((resolve, reject) => {
      this.open(true);
      this.onSubmit = () => {
        const data = { newOrderValue: this.newOrderValue.value, comment: this.comment.value.trim() };
        resolve(data);
      };
      this.onAbort = () => {
        reject();
      };
    });
  }

  private validateOrderValue() {
    if (!this.form || !this.initialOrderValue || !this.newOrderValue.value) {
      return null;
    }
    const invalid = this.newOrderValue.value > this.initialOrderValue;
    return invalid ? { invalidOrderValue: 'Nytt ordervärde får inte vara större än nuvarande ordervärde.' } : null;
  }

  private validateComment(comment) {
    if (!this.form || !comment.value) {
      return null;
    }

    const invalid = comment.value.trim().length === 0;
    return invalid ? { invalidComment: 'Måste ha ett stoppmeddelande' } : null;
  }

  get newOrderValue(): AbstractControl {
    return this.form.controls.newOrderValue;
  }
  get comment(): AbstractControl {
    return this.form.controls.comment;
  }
}

interface StopCampaignData {
  newOrderValue: number;
  comment: string;
}
