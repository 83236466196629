import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleChanges } from 'app/scripts/utils/types';
import { AssetMetaData, AssetUploadErrors } from '../../../woo_services.module/asset_validator/asset_upload_types';
import { MP4Validator } from '../../../woo_services.module/asset_validator/MP4ValidatorService';
import { CreativeAssetService } from '../../../woo_services.module/CreativeAssetService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { ErrorService } from '../../../woo_services.module/ErrorService';
import { FileService } from '../../../woo_services.module/FileService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { CreativeAsset, CreativeAssetType } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { ValidatedRegionSelect } from '../targeting/validated-region-select.component';
import { CustomerAssetListDialog } from './customer-asset-list-dialog.component';

interface Asset {
  name: string;
  url: string;
}

interface AssetSelectedEvent {
  error: boolean;
  asset: Asset;
}

@Component({
  selector: 'asset-select',
  templateUrl: './asset-select.component.html',
})
export class AssetSelect implements OnChanges {
  @Input() initialAsset: any;
  @Output() assetChange = new EventEmitter<AssetSelectedEvent>();

  private filmValidator: MP4Validator;

  asset: Asset = {
    url: null,
    name: null,
  };

  show = {
    selectFile: true,
    loading: false,
    validatingFile: false,
  };

  uploadedVideo = {
    errors: null as AssetUploadErrors | { validationRejectionError: string },
    valid: false,
    metaData: null as AssetMetaData,
    file: {} as any,
  };

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public fileService: FileService,
    private creativeAssetService: CreativeAssetService,
    private translationService: TranslationService,
  ) {
    this.filmValidator = new MP4Validator();
    this.filmValidator.setValidationOptions({
      maxFilmDurationSeconds: 15,
    });
  }

  ngOnChanges(changes: SimpleChanges<ValidatedRegionSelect> & { initialAsset: any }): void {
    if (changes.initialAsset && this.validAsset(this.initialAsset)) {
      this.show.selectFile = false;
      this.uploadedVideo.valid = true;
      this.uploadedVideo.errors = null;
      Object.assign(this.asset, this.initialAsset);
      this.updateModel({ public_url: this.asset.url, name: this.asset.name });
    }
  }

  fileSelectedHandler(event: any): void {
    this.validateVideo(event.target.files[0]);
  }

  validateVideo = (file: any): void => {
    this.uploadedVideo.errors = null;
    this.show.validatingFile = true;
    this.show.loading = true;
    this.show.selectFile = false;
    this.filmValidator
      .validate(file)
      .then((metaData: AssetMetaData) => {
        this.uploadedVideo.file = file;
        this.uploadedVideo.valid = true;
        this.uploadedVideo.metaData = metaData;
        this.upload();
      })
      .catch((errors: AssetUploadErrors) => {
        this.uploadedVideo.errors = errors;
        console.error('Failed to run validation script:', errors);
        this.dialogService.openError(
          'Hoppsan! Något gick fel och vi misslyckades att hantera filen. Var god försök igen.',
        );
        this.resetUploadAfterError();
      })
      .finally(() => {
        this.show.validatingFile = false;
      });
  };

  removeFile = (): void => {
    this.show.selectFile = true;
    this.uploadedVideo = {
      errors: null,
      valid: false,
      metaData: null,
      file: {},
    };
    this.updateModel({}, true);
  };

  upload = (): void => {
    const data = {
      name: this.uploadedVideo.file.name,
      asset_type: CreativeAssetType.Video,
      file: this.uploadedVideo.file,
      metaData: this.uploadedVideo.metaData,
    };
    this.show.loading = true;
    this.creativeAssetService
      .create(
        this.routingService.getParam(this.route, 'customerId'),
        data.file,
        data.name,
        data.asset_type,
        data.metaData,
      )
      .then(
        (asset) => {
          this.updateModel(asset);
        },
        (err) => {
          if (err.status === 422) {
            const errorMsg = `Filen uppfyller inte kraven${this.translationService.convertMessage(err.error.message)}`;
            this.dialogService.openError(errorMsg);
          } else {
            this.errorService.showError(err.error.error);
          }
          this.uploadedVideo.valid = false;
          this.show.selectFile = true;
          this.updateModel({}, true);
        },
      )
      .finally(() => {
        this.show.loading = false;
      });
  };

  openCustomerAssetExplorer = (): void => {
    this.dialogService
      .create(CustomerAssetListDialog)
      .show(this.routingService.getParam(this.route, 'customerId'))
      .then(
        (asset) => {
          this.show.selectFile = false;
          this.uploadedVideo.valid = true;
          this.uploadedVideo.errors = null;
          this.updateModel(asset);
        },
        () => {
          // dialog closed without choosing any asset
        },
      );
  };

  updateModel = (asset: CreativeAsset | any, error = false): void => {
    this.asset.url = asset.public_url;
    this.asset.name = asset.name;
    this.assetChange.emit({ error, asset: this.asset });
  };

  fileDropped(files: FileList): void {
    this.validateVideo(files[0]);
  }

  private validAsset(asset) {
    return Boolean(asset) && Object.keys(asset).includes('url') && Object.keys(asset).includes('name');
  }

  private resetUploadAfterError = (): void => {
    this.show.loading = false;
    this.uploadedVideo.valid = false;
    this.show.selectFile = true;
    this.updateModel({}, true);
  };
}
