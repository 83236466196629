<div class="block account__card">
  <h1>Importerade filer</h1>
  <table class="table--woo table table-striped table--align--middle">
    <thead>
      <tr>
        <th>Namn</th>
        <th>Typ</th>
        <th>Uppladdad</th>
        <th>Användare</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of uploadedFiles">
        <td>{{file.name}}</td>
        <td>{{translateFileType(file.file_type)}}</td>
        <td>{{file.createdAt | date}}</td>
        <td>{{file.author.name}}</td>
        <td><a class="link primary-1 min-height--0" [href]="getUrlToFile(file.file_type, file.id)" target="_blank">Öppna</a></td>
      </tr>
    </tbody>
  </table>

  <h2>Ladda upp ny fil</h2>
  <form #fileForm="ngForm" (ngSubmit)="uploadFile(fileForm)">
    <input type="file" (change)="newFile.file = $event.target.files[0]" [(ngModel)]="newFile.name" name="fileInput"
      accept="application/pdf" required />
    <div class="select__container width--30 margin-top--2x">
      <label for="newFileType">Filtyp</label>
      <select id="newFileType" [(ngModel)]="newFile.fileType" required name="fileType">
        <option value=''>Välj...</option>
        <option *ngFor="let type of fileTypes" [value]="type">
          {{translateFileType(type)}}
        </option>
      </select>
    </div>
    <button class="button primary-1" [disabled]="!fileForm.valid && this.newFile">Ladda upp</button>
  </form>
</div>
