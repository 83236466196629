<div class="block">
  <h1>Fakturor</h1>
  <button class="tabbable no-outline" (click)="tabVisible=Tabs.Attest" [ngClass]="{active : tabVisible === Tabs.Attest}">{{Tabs.Attest}}</button>
  <button class="tabbable no-outline" (click)="tabVisible=Tabs.Overview" [ngClass]="{active : tabVisible === Tabs.Overview}">{{Tabs.Overview}}</button>
  <button class="tabbable no-outline" (click)="tabVisible=Tabs.Tools" [ngClass]="{active : tabVisible === Tabs.Tools}">{{Tabs.Tools}}</button>
  <div class="tabbable__panel">
    <h3 class="margin--1x">{{tabVisible}}</h3>
  </div>
  <invoice-attestation *ngIf="tabVisible === Tabs.Attest"></invoice-attestation>
  <invoice-overview *ngIf="tabVisible === Tabs.Overview"></invoice-overview>
  <invoice-tools *ngIf="tabVisible === Tabs.Tools"></invoice-tools>
</div>
