import { Component, Input } from '@angular/core';

const template = `
  <h4 *ngIf="size==='h4'">{{text}}</h4>
  <h3 *ngIf="size==='h3'">{{text}}</h3>
  <div class="divider"></div>
`;

@Component({
  selector: 'heading-with-line',
  template: template,
  styles: ['h4 { margin-bottom: 4px; margin-top: 24px;}'],
})
export class HeadingWithLine {
  @Input() size: 'h3' | 'h4' = 'h4';
  @Input() text: string;
}
