import { Component, Input } from '@angular/core';
import { FeedbackDialog } from '../../../woo_components.module/dialogs/feedback-dialog.component';
import { AuthService } from '../../../woo_services.module/AuthService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { WooUiEventService } from '../../../woo_services.module/WooUiEventService';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
})
export class LoginForm {
  @Input() menuOpened = false;

  private readonly emptyCredentials: Credentials = { email: '', password: '' };
  isLoginFormValid = false;
  credentials = this.emptyCredentials;
  feedback = (): void => this.dialogService.create(FeedbackDialog).open();

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private wooUiEventService: WooUiEventService,
  ) {}

  async login(credentials = this.credentials): Promise<void> {
    try {
      await this.authService.login(credentials);
      this.credentials = this.emptyCredentials;
    } catch {
      this.wooUiEventService.stopLoadDotAnimation();
    }
  }

  loginFormChanged(): void {
    this.isLoginFormValid = this.validateLoginForm();
  }

  validateLoginForm(): boolean {
    if (!this.credentials.email.length) {
      return false;
    }
    if (!this.credentials.password.length) {
      return false;
    }

    return this.emailRegexp.test(this.credentials.email);
  }
  private readonly emailRegexp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;
}

interface Credentials {
  email: string;
  password: string;
}
