<div>
  <div class="tabbable__panel panel-margin right section info clearfix">
    <div class="col-xs-12">
      <h2>Adserver</h2>
    </div>
  </div>
</div>
<div class="tabbable__body">
  <campaign-mms-gain-form
    [campaign]="campaign"
    [disabled]="!editGoals"
    (onValidityChanges)="onMmsFormValidityChange($event)"
    (onValueChanges)="onMmsFormChange($event)"
  ></campaign-mms-gain-form>
  <edit-button
    class="display--block"
    (click)="startEditGoals()"
    *ngIf="!editGoals"
    [disabled]="statusDisabled"
    textOverride="Redigera alla mål"
  ></edit-button>
  <div *ngIf="editGoals">
    <button
      class="button"
      (click)="saveEditGoals()"
      [disabled]="!canSave"
    >
      Uppdatera mål på adservern
    </button>
    <button class="button secondary" (click)="cancelEditGoals()">
      Avbryt
    </button>
  </div>
</div>
<div
  class="padding--3x shadow-z-1 background-color--white margin-top--3x"
  *ngFor="let targeting of campaign.targetings"
>
  <expandable-targeting-header
    [targeting]="targeting"
    [expanded]="getTargetingExpandedFromStore(targeting.id)"
    (expandedChange)="onTargetingHeaderExpandedChange($event, targeting.id)">
    <edit-targeting-form
      [targetingId]="targeting.id"
    ></edit-targeting-form>
  </expandable-targeting-header>
</div>
<div class="margin-top--2x" *ngIf="editGoals">
  <button
    class="button"
    (click)="saveEditGoals()"
    [disabled]="!canSave"
  >
    Uppdatera mål på adservern
  </button>
  <button class="button secondary" (click)="cancelEditGoals()">
    Avbryt
  </button>
</div>
