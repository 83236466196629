import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { PerIdEstimates, wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdvancedTargetGroupService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getTargetGroups(): Promise<AdvancedTargetGroup[]> {
    return lastValueFrom(this.http.get<AdvancedTargetGroup[]>(`${this.env.apiUrl}/advanced_target_groups`));
  }

  async getActiveTargetGroups(): Promise<AdvancedTargetGroup[]> {
    return (
      await lastValueFrom(this.http.get<AdvancedTargetGroup[]>(`${this.env.apiUrl}/advanced_target_groups`))
    ).filter((atg) => atg.active);
  }

  getTargetGroup(id: wooId): Promise<AdvancedTargetGroup> {
    return lastValueFrom(this.http.get<AdvancedTargetGroup>(`${this.env.apiUrl}/advanced_target_groups/${id}`));
  }

  createTargetGroup(advancedTargetGroup: AdvancedTargetGroupParams): Promise<AdvancedTargetGroupParams> {
    return lastValueFrom(
      this.http.post<AdvancedTargetGroupParams>(`${this.env.apiUrl}/advanced_target_groups`, {
        advanced_target_group: advancedTargetGroup,
      }),
    );
  }

  updateTargetGroups(advancedTargetGroups: AdvancedTargetGroup[]): Promise<AdvancedTargetGroup[]> {
    return lastValueFrom(
      this.http.put<AdvancedTargetGroup[]>(`${this.env.apiUrl}/advanced_target_groups`, {
        advanced_target_groups: advancedTargetGroups,
      }),
    );
  }

  updateTargetGroup(advancedTargetGroup: AdvancedTargetGroup): Promise<AdvancedTargetGroup> {
    return lastValueFrom(
      this.http.put<AdvancedTargetGroup>(`${this.env.apiUrl}/advanced_target_groups/${advancedTargetGroup.id}`, {
        advanced_target_group: advancedTargetGroup,
      }),
    );
  }

  getTargetGroupCategories(): Promise<AdvancedTargetGroupCategory[]> {
    return lastValueFrom(
      this.http.get<AdvancedTargetGroupCategory[]>(`${this.env.apiUrl}/advanced_target_group_categories`),
    );
  }

  createTargetGroupCategory(category: AdvancedTargetGroupCategoryParams): Promise<AdvancedTargetGroupCategory> {
    return lastValueFrom(
      this.http.post<AdvancedTargetGroupCategory>(`${this.env.apiUrl}/advanced_target_group_categories`, { category }),
    );
  }
}

export interface AdvancedTargetGroup {
  id: wooId;
  name: string;
  category_id: wooId;
  tag: string;
  mrm_id: string;
  active: boolean;
  shares: AdvancedTargetGroupPublisherShares;
  withdrawal_limits: AdvancedTargetGroupPublisherShares;
}

export type AdvancedTargetGroupParams = Omit<AdvancedTargetGroup, 'id'>;

export interface AdvancedTargetGroupCategory {
  id: wooId;
  name: string;
}

export interface AdvancedTargetGroupPublisherShares {
  [publisherId: string]: PerIdEstimates;
}

export type AdvancedTargetGroupCategoryParams = Omit<AdvancedTargetGroupCategory, 'id'>;
