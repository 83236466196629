import { Component, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { AdHocContractService } from '../../../woo_services.module/AdHocContractService';
import { TranslationService } from '../../../woo_services.module/TranslationService';

export interface Part {
  selected: boolean;
  discount_rate: string;
  discount_types: string[];
}

@Component({
  selector: 'product-discount-input',
  templateUrl: './product-discount-input.component.html',
  styleUrls: ['./product-discount-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductDiscountInput),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProductDiscountInput),
      multi: true,
    },
  ],
})
export class ProductDiscountInput implements ControlValueAccessor, Validator {
  model: Part[] = [];
  convertDiscount = this.translationService.convertDiscount;

  constructor(private translationService: TranslationService, private adHocContractService: AdHocContractService) {
    this.resetModel();
  }

  propagateChange = (_: any): void => null;
  propagateTouch = (): void => null;

  writeValue(obj: any): void {
    this.resetModel();
    if (Array.isArray(obj)) {
      obj.forEach((item) =>
        Object.assign(
          this.model.find((part) => part.discount_types === item.discount_types),
          item,
        ),
      );
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  change = (): void => {
    const data = this.model
      .filter((part) => part.selected)
      .map((part) => ({ discount_rate: part.discount_rate, discount_types: part.discount_types }));
    this.propagateChange(data);
  };

  touch = (): void => {
    this.propagateTouch();
  };

  validate(c: FormControl): ValidationErrors {
    const errors = {};
    if (!c.value || c.value.length === 0) {
      Object.assign(errors, { required: 'Minst en produkt måste väljas' });
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }

  private resetModel = () => {
    this.model = this.adHocContractService.getDiscountTypes();
  };
}
