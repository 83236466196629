import { Component, Input } from '@angular/core';
import { PriceList, PriceListService, ValidPriceListRow } from '../../../../woo_services.module/PriceListService';
import { PriceListBasePriceTypes, Publisher, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
@Component({
  selector: 'price-row-container',
  template: `
    <table *ngIf="priceListRows.length" class="table table--woo table--hover table--light-red">
      <thead>
        <tr>
          <th *ngFor="let header of tableHeaders" class="capitalize--first-letter">
            {{ header }}
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let row of priceListRows; let index = index">
          <tr [hidden]="!rowVisible(row)">
            <td class="vertical-align--middle">
              {{ getPublisherName(row.publisher_id) }}
            </td>
            <td class="vertical-align--middle">
              {{ translateBaseProduct(row.product_format) }}
            </td>
            <td class="vertical-align--middle">
              {{ translateTargetingProduct(row.targeting_type) }}
            </td>
            <td class="vertical-align--middle">
              {{ translateTargetingProduct(row.addon_targeting) }}
            </td>
            <td class="vertical-align--middle">
              {{ translateLocality(row.locality_type) }}
            </td>
            <td class="vertical-align--middle">
              <p class="display--inline-block text--semi-strong margin--none">
                {{ !!row.over_written_price ? row.over_written_price : row.calculated_price }}
              </p>
              {{ getTypeOfCost(row.product_format) }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <p *ngIf="!priceListRows.length">Inga redigerade priser ännu!</p>
  `,
})
export class PriceRowContainer {
  constructor(private translationService: TranslationService, private priceListService: PriceListService) {}

  @Input() publishers: Publisher[];
  @Input() priceListRows: ValidPriceListRow[];
  @Input() priceList: PriceList;
  @Input() selectedPublisher: string;
  @Input() selectedProductFormat: string;
  @Input() selectedTargetingType: string;
  @Input() selectedLocalityType: string;
  readonly tableHeaders = ['Publicist', 'Placering', 'Styrning', 'Tilläggsstyrning', 'Geografi', 'Totalpris'];
  readonly translateBaseProduct = this.translationService.convertProductFormat;
  readonly translateTargetingProduct = this.translationService.convertProductChoice;
  readonly translateLocality = this.translationService.convertLocality;

  getTypeOfCost(baseProductName: string): string {
    if (['shortform', 'pause'].some((product) => baseProductName.includes(product))) return 'CPM';
    return `${this.priceListService.translatePriceListCurrencyShort(this.priceList.price_list_currency)}`;
  }

  getPublisherName(publisherId: wooId): string {
    return this.publishers.find((publisher) => publisher.id === publisherId).name;
  }

  rowVisible(row: ValidPriceListRow): boolean {
    if (row.product_format === PriceListBasePriceTypes.includes_linear) return false;
    if (this.selectedPublisher && row.publisher_id !== this.selectedPublisher) return false;
    if (this.selectedProductFormat && row.product_format !== this.selectedProductFormat) return false;
    if (
      this.selectedTargetingType &&
      !(row.targeting_type === this.selectedTargetingType || row.addon_targeting === this.selectedTargetingType)
    ) {
      return false;
    }
    if (this.selectedLocalityType && row.locality_type !== this.selectedLocalityType) return false;
    return true;
  }
}
