import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { openAbove } from '../../utils/dom';
import { AuthService } from '../../woo_services.module/AuthService';
import { GoogleTagManagerService } from '../../woo_services.module/GoogleTagManagerService';

@Component({
  selector: 'info-badge',
  templateUrl: './info-badge.component.html',
  styleUrls: ['./info-badge.component.scss'],
})
export class InfoBadge {
  @ViewChild('element', { static: true }) elementRef: ElementRef;

  @Input() gaName: string;

  openUpwards = false;

  constructor(private googleTagManagerService: GoogleTagManagerService, private authService: AuthService) {}

  mouseEnter(): void {
    this.openUpwards = openAbove(this.elementRef);
    this.trackOpenedInfoBadge(this.gaName);
  }

  private trackOpenedInfoBadge(name: string) {
    this.googleTagManagerService.openedInfoBadge(this.authService.getFirstUserRole(), name);
  }
}
