import { Injectable } from '@angular/core';
import { ConfirmDialogContent } from '../woo_components.module/dialogs/confirm-dialog.component';
import { AdHocContract, AdHocContractParams, AdHocContractService } from './AdHocContractService';
import { DialogService } from './DialogService';
import { Voucher, wooId } from './shared-types';
import { VoucherParams, VoucherService } from './VoucherService';

@Injectable({ providedIn: 'root' })
export class DiscountsInteractionService {
  readonly confirmRemoveContent: ConfirmDialogContent = {
    header: 'Ta bort rabatt',
    textBlocks: ['Är du säker på att du vill ta bort rabatten?'],
    confirmText: 'Ja, fortsätt',
    cancelText: 'Nej, avbryt',
  };

  readonly confirmCreateContent: ConfirmDialogContent = {
    header: 'Ny rabatt',
    textBlocks: ['Är du säker på att du vill lägga till den nya rabatten?'],
    confirmText: 'Ja, fortsätt',
    cancelText: 'Nej, avbryt',
  };

  constructor(
    private dialogService: DialogService,
    private voucherService: VoucherService,
    private adHocContractService: AdHocContractService,
  ) {}

  deleteVoucher = async (voucherId: wooId): Promise<void> => {
    await this.dialogService.openConfirm(this.confirmRemoveContent);
    await this.voucherService.deleteVoucher(voucherId);
    await this.dialogService.openSuccess('Rabatten är nu borttagen.');
    return null;
  };

  createVoucher = async (newVoucher: VoucherParams, customerId: wooId): Promise<Voucher | null> => {
    await this.dialogService.openConfirm(this.confirmCreateContent);
    const voucher = await this.voucherService.createVoucher(customerId, newVoucher);
    this.dialogService.openSuccess('Rabatten är nu tillagd.');
    return voucher;
  };

  deleteAdHocContract = async (contractId: wooId): Promise<any> => {
    await this.dialogService.openConfirm(this.confirmRemoveContent);
    await this.adHocContractService.deleteAdHocContract(contractId);
    this.dialogService.openSuccess('Rabatten är nu borttagen.');
    return null;
  };

  createAdHocContract = async (newContract: AdHocContractParams, customerId: wooId): Promise<AdHocContract | null> => {
    await this.dialogService.openConfirm(this.confirmCreateContent);
    const contract = await this.adHocContractService.createAdHocContract(customerId, newContract);
    this.dialogService.openSuccess('Rabatten är nu tillagd.');
    return contract;
  };
}
