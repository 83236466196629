import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SimpleChanges } from '../../utils/types';

const template = `
<object [data]="safeSrc" type="application/pdf" width="100%" height="100%">
  <context-message messageType="warning">
    <p>Din webbläsare kan inte visa upp dokumentet. <a [href]="safeSrc" target="_blank">Klicka här</a> för att ladda ned och läsa.</p>
  </context-message>
</object>
`;

@Component({
  selector: 'pdf-viewer',
  template: template,
})
export class PdfViewer implements OnChanges {
  @Input() src: string;

  safeSrc = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges<PdfViewer>): void {
    if (changes.src && this.src) {
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    }
  }
}
