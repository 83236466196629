import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { isDateBefore, orderByDate } from '../../../../utils/date';
import { AuthService } from '../../../../woo_services.module/AuthService';
import { TargetingMapping, TargetingMappingService } from '../../../../woo_services.module/TargetingMappingService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-ttv-targeting-mapping',
  templateUrl: './manage-ttv-targeting-mapping.component.html',
})
export class ManageTTVTargetingMapping implements OnInit {
  mappings: TargetingMapping[] = [];
  upcomingMappings: TargetingMapping[] = [];
  ongoingMappings: TargetingMapping[] = [];
  oldMappings: TargetingMapping[] = [];

  show = {
    newTTVMappingForm: false,
    loading: true,
    isEditing: false,
  };

  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('newTTVMappingFormContainer', { static: true }) newTTVMappingFormContainer: ElementRef;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  constructor(
    private ttvService: TargetingMappingService,
    public readonly authService: AuthService,
    private utilityService: ProductsPageUtilityService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getTargetingMappingsAndSort();
  }

  async getTargetingMappingsAndSort(): Promise<void> {
    try {
      this.mappings = orderByDate(await this.ttvService.getTargetingMappings(), 'start_date');
      this.orderTargetingMappingsByStatusAndStartDate(this.mappings, new Date());
    } finally {
      scrollTo(this.parentHeader);
      this.utilityService.stickifyHeaders(this.stickyHeader);
    }
  }

  showNewTargetingMapping = (): void => {
    this.show.newTTVMappingForm = true;
    scrollTo(this.newTTVMappingFormContainer.nativeElement);
  };

  hideNewTargetingMapping(): void {
    this.show.newTTVMappingForm = false;
    scrollTo(this.parentHeader);
  }

  async hideAndUpdateTargetingMapping(): Promise<void> {
    await this.getTargetingMappingsAndSort();
    this.hideNewTargetingMapping();
  }

  private isNotAlreadyOngoing = (mapping: TargetingMapping): boolean => {
    return !this.ongoingMappings.find((object) => object.ttv_target_group === mapping.ttv_target_group);
  };

  private orderTargetingMappingsByStatusAndStartDate = (mappings: TargetingMapping[], date: Date): void => {
    this.upcomingMappings = [];
    this.ongoingMappings = [];
    this.oldMappings = [];
    mappings.forEach((item) => {
      if (isDateBefore(date, item.start_date)) {
        this.upcomingMappings.push(item);
      } else if (this.isNotAlreadyOngoing(item)) {
        this.ongoingMappings.push(item);
      } else {
        this.oldMappings.push(item);
      }
    });
  };
}
