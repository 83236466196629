import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { roundDecimalError } from '../../../../utils/math';
import { ToFormGroup } from '../../../../utils/types';
import { validateRequired } from '../../../../utils/validators';
import { CategoryService } from '../../../../woo_services.module/CategoryService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ProgramFormatService } from '../../../../woo_services.module/ProgramFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import {
  Category,
  ProductFormats,
  Program,
  ProgramShares,
  Publisher,
} from '../../../../woo_services.module/shared-types';

@Component({
  selector: 'create-new-program-format',
  templateUrl: './create-new-program-format.component.html',
})
export class CreateNewProgramFormat implements OnInit {
  @Input() newProgramFormat: boolean;
  @Output() onCreateNewProgramFormat = new EventEmitter<Program>();
  @Output() onClose = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private programFormatService: ProgramFormatService,
    private productFormatService: ProductFormatService,
    private publisherService: PublisherService,
    private categoryService: CategoryService,
  ) {}

  form: FormGroup<ToFormGroup<FormModel>> = this.fb.group({
    name: new FormControl(null, validateRequired()),
    mrm_id: new FormControl(null, validateRequired()),
    publisher_id: new FormControl(null, validateRequired()),
    category_id: new FormControl(null),
    longform: new FormControl(null, validateRequired()),
    shortform: new FormControl(null, validateRequired()),
  });

  newProgramFormatObj = { name: null, mrm_id: null, category_id: null };
  longformId: string;
  shortformId: string;

  programFormats: Program[] = [];
  publishers: Publisher[] = [];
  categories: Category[] = [];

  ngOnInit(): void {
    Promise.all([
      this.publisherService.getPublishers(ExtraPublisherField.enabled),
      this.categoryService.getCategories(),
      this.programFormatService.getProgramFormats(),
      this.productFormatService.getProductFormats(ExtraProductFormatField.enabled, ExtraProductFormatField.format),
    ]).then(([publishers, categories, programFormats, productFormats]) => {
      this.publishers = publishers.filter((p) => p.enabled);
      this.categories = categories;
      this.programFormats = programFormats;

      const availableFormats = productFormats.filter((pf) => pf.enabled);
      this.longformId = availableFormats.find((pf) => pf.format === ProductFormats.longForm).id;
      this.shortformId = availableFormats.find((pf) => pf.format === ProductFormats.shortForm).id;
    });
  }

  addProgramFormat(programFormat: Program): void {
    const product_format_program_share: ProgramShares[] = [
      { preroll: this.form.value.longform, product_format_id: this.longformId },
      { preroll: this.form.value.shortform, product_format_id: this.shortformId },
    ];
    programFormat = { ...programFormat, ...this.form.value };
    this.programFormatService.addProgramFormat(programFormat, product_format_program_share).then((format) => {
      this.newProgramFormat = false;
      this.onCreateNewProgramFormat.emit(format);
      this.form.reset();
      this.onClose.emit();
    });
  }

  closeCard(): void {
    this.newProgramFormat = false;
    this.onClose.emit();
  }

  autofillShortform(fill: number): void {
    this.form.patchValue({ shortform: roundDecimalError(1 - fill, 3) });
  }

  autofillLongform(fill: number): void {
    this.form.patchValue({ longform: roundDecimalError(1 - fill, 3) });
  }
}

interface FormModel {
  name: string;
  publisher_id: string;
  mrm_id: string;
  category_id: string;
  longform: number;
  shortform: number;
}
