import { Injectable } from '@angular/core';
import { CodeService, VideoCreativeType } from '../shared-types';
import { AdstreamService } from './AdstreamService';
import { MmsCodeService } from './MmsCodeService';
import { PeachService } from './PeachService';

@Injectable({ providedIn: 'root' })
export class CodeServiceFactory {
  constructor(
    private peachService: PeachService,
    private adstreamService: AdstreamService,
    private mmsService: MmsCodeService,
  ) {}

  getService(creativeType: VideoCreativeType): CodeService | null {
    switch (creativeType) {
      case VideoCreativeType.peach:
        return this.peachService;
      case VideoCreativeType.adstream:
        return this.adstreamService;
      case VideoCreativeType.other:
        return this.mmsService;
      default:
        return null;
    }
  }
}
