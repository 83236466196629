<div class="inner-block">
  <dot-loader
    [visibility]="show.loadingCustomers"
    description="Hämtar kund..."
  ></dot-loader>
  <div *ngIf="!show.loadingCustomers">
    <div class="account__card">
      <div class="pull-right text--gray">
        <p *ngIf="customer.crm_last_modified_at" class="text--small">
          <span class="icon-refresh"></span> Uppdaterad i CRAM för
          <span>{{ cramUpdated }}</span>
        </p>
        <p *ngIf="customer.crm_last_synced_at" class="text--small">
          <span class="icon-refresh"></span> Synkad från CRAM för
          <span>{{ cramSynced }}</span>
        </p>
      </div>
      <h2 class="">{{ customer.name }}</h2>
      <h3 *ngIf="customer.locked">
        Denna kund är låst och det går inte att boka kampanjer.
      </h3>
      <h5 *ngIf="!isAdminPlannerAccounting">
        Hör av dig till din säljare om informationen nedan inte stämmer, så ser
        vi till att ändra den så snart som möjligt
      </h5>
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div *ngIf="customer.nickname && isAdminPlannerAccounting" class="card__info">
            <span class="text--medium">Kundnamn TV4</span><br />
            <span>{{ customer.nickname || "-" }}</span>
          </div>
          <div class="card__info">
            <div class="tab tab rounded">
              {{getCustomerType()}}
            </div>
            <div *ngIf="customer.gambling_customer" class="tab tab rounded">
              Spelbolag
            </div>
            <div *ngIf="customer.political_customer" class="tab tab rounded">
              Politiskt parti
            </div>
          </div>
          <div class="card__info">
            <span class="text--medium">Org. nr.</span><br />
            <span>{{ customer.org_no }}</span>
          </div>
          <div *ngIf="isAdminPlannerAccounting" class="card__info">
            <span class="text--medium">CRM-id</span><br />
            <span>{{ customer.crm_id || "-" }}</span>
          </div>
          <div *ngIf="isAdminPlannerAccounting" class="card__info">
            <span class="text--medium">MRM-id</span><br />
            <span>{{ customer.mrm_id || "-" }}</span>
          </div>
          <div *ngIf="isAdminPlannerAccounting" class="card__info">
            <span class="text--medium">TSC-id</span><br />
            <span>{{ customer.tsc_id || "-" }}</span>
          </div>
          <div class="card__info">
            <span class="text--medium">Land, säte</span><br />
            <span>{{ customer.hq_country }}</span>
          </div>
          <div class="card__info" *ngIf="customer.partner_agreements.length">
            <span class="text--medium">Byråtillhörighet</span><br />
            <ul>
              <li *ngFor="let agreement of customer.partner_agreements">
                <a [routerLink]="['/agency', agreement.agency.id]">
                  <span>{{ agreement.agency.nickname }}</span>
                </a>
                <div>
                  <span *ngIf="agreement.agency_type === 'media_agency'"
                    >Mediebyrå.
                  </span>
                  <span
                    *ngIf="
                      agreement.agency_type ===
                      'digital_media_agency_with_discount'
                    "
                    >Digital mediebyrå med rabatt.</span
                  >
                  <span
                    *ngIf="
                      agreement.agency_type !== 'media_agency' &&
                      agreement.agency_type !==
                        'digital_media_agency_with_discount'
                    "
                    >Annanbyrå.</span
                  >

                  <span *ngIf="agreement.valid_from">
                    Från
                    <span>{{
                      agreement.valid_from | date: "yyyy-MM-dd"
                    }}</span></span
                  >
                  <span *ngIf="agreement.valid_to">
                    till
                    <span>{{
                      agreement.valid_to | date: "yyyy-MM-dd"
                    }}</span></span
                  >
                </div>
                <span *ngIf="agreement.agency.locked" class="text--cta text--medium">
                  <span class="icon-warning"></span>
                  Denna byrå är låst</span>
              </li>
            </ul>
          </div>
          <div
            class="card__info"
            *ngIf="customer.inactive_partner_agreements.length"
          >
            <span class="text--medium">Tidigare byråer för kunden</span><br />
            <ul>
              <li
                *ngFor="let agreement of customer.inactive_partner_agreements"
              >
                <a [routerLink]="['/agency', agreement.agency.id]">
                  <span>{{ agreement.agency.name }}</span>
                </a>
                <div>
                  <span *ngIf="agreement.agency_type === 'media_agency'"
                    >Mediebyrå.</span
                  >
                  <span
                    *ngIf="
                      agreement.agency_type ===
                      'digital_media_agency_with_discount'
                    "
                    >Digital mediebyrå med rabatt.</span
                  >
                  <span
                    *ngIf="
                      agreement.agency_type !== 'media_agency' &&
                      agreement.agency_type !==
                        'digital_media_agency_with_discount'
                    "
                    >Annanbyrå.</span
                  >

                  <span *ngIf="agreement.valid_from">
                    Från
                    <span>{{
                      agreement.valid_from | date: "yyyy-MM-dd"
                    }}</span></span
                  >
                  <span *ngIf="agreement.valid_to">
                    till
                    <span>{{
                      agreement.valid_to | date: "yyyy-MM-dd"
                    }}</span></span
                  >
                </div>
              </li>
            </ul>
          </div>

          <div
            class="card__info"
            *ngIf="activeBrands.length"
          >
            <span class="text--medium">Varumärken för kunden</span><br />
            <ul>
              <li *ngFor="let brand of activeBrands">
              <span>{{brand.name}}</span>
              </li>
            </ul>
          </div>

          <div
          class="card__info"
          *ngIf="inactiveBrands.length"
        >
          <span class="text--medium">Inaktiva varumärken för kunden</span><br />
          <ul>
            <li *ngFor="let brand of inactiveBrands">
            <span>{{brand.name}}</span>
            </li>
          </ul>
        </div>

        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="address__info section">
            <span class="text--medium">Faktureringsadress</span><br />
            <div>{{ customer.address_1 }}</div>
            <div *ngIf="customer.address_2 !== null">
              {{ customer.address_2 }}
            </div>
            <div>
              <span>{{ customer.zip_code }} </span>
              <span>{{ customer.city }}</span>
            </div>
            <div>{{ customer.country }}</div>
          </div>

          <div class="card__info">
            <span class="text--medium">God kreditvärdighet</span><br />
            <span
              class="status__badge with--icon small float--left margin-right--2x"
              [ngClass]="{
                success: !customer.low_credit_rating,
                error: customer.low_credit_rating
              }"
            ></span>
            <span *ngIf="!customer.low_credit_rating">Ja</span>
            <span *ngIf="customer.low_credit_rating"> Nej</span>
          </div>
          <div class="card__info">
            <span class="text--medium">Betalningsvillkor</span><br />
            <span>{{ customer.payment_terms }} dagar</span>
            <span *ngIf="customer.payment_terms_code"> - {{ customer.payment_terms_code }}</span>
          </div>
        </div>
      </div>

      <button
        class="button secondary pull-right"
        (click)="show.endedDiscounts = !show.endedDiscounts"
      >
        <span *ngIf="!show.endedDiscounts">Visa</span>
        <span *ngIf="show.endedDiscounts">Dölj</span>
        gamla rabatter/avtal
      </button>

      <div class="row">
        <div class="col-xs-12">
          <h3>Avtal</h3>
          <contract-discounts-list
            [contracts]="customer.contracts || []"
            [showEnded]="show.endedDiscounts"
          ></contract-discounts-list>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <h3>Ad Hoc-rabatter (Engång)</h3>
          <p>
            Gäller endast en bokning fr.o.m. dagen rabatten skapats och löpande.
          </p>
          <vouchers-list
            mode="edit"
            [vouchers]="customer.vouchers"
            [showUsedVouchers]="show.endedDiscounts"
            (onDelete)="onVoucherDestroy($event)"
          ></vouchers-list>
        </div>
        <div class="col-xs-12" *ngIf="!show.newVoucher && mayCreateDiscount">
          <button
            class="primary-1 button margin-top--2x"
            (click)="openNewDiscount('newVoucher')"
          >
            Skapa ny rabatt
          </button>
        </div>
        <div class="col-xs-12" *ngIf="show.newVoucher">
          <h3>Skapa ny Ad Hoc-rabatt (Engång)</h3>
          <new-voucher
            class="display--block width--50"
            [adOrganisations]="organisations"
            [agencies]="customer.agencies"
            (onNewVoucher)="onNewVoucher($event)"
            (onAbort)="show.newVoucher = false"
          ></new-voucher>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <h3>Ad Hoc-rabatter (Period)</h3>
          <p>Obegränsad användning under alla periodens dagar.</p>
          <ad-hoc-contracts-list
            [contracts]="customer.ad_hoc_contracts"
            (onDelete)="onAdHocContractDestroy($event)"
            [showEnded]="show.endedDiscounts"
          ></ad-hoc-contracts-list>
        </div>
        <div
          class="col-xs-12"
          *ngIf="!show.newAdHocContract && mayCreateDiscount"
        >
          <button
            class="primary-1 button margin-top--2x"
            (click)="openNewDiscount('newAdHocContract')"
          >
            Skapa ny rabatt
          </button>
        </div>
        <div class="col-xs-12" *ngIf="show.newAdHocContract">
          <h3>Skapa ny Ad Hoc-rabatt (Period)</h3>
          <new-ad-hoc-contract
            class="display--block width--50"
            [agencies]="customer.agencies"
            [adOrganisations]="organisations"
            (onNewAdHocContract)="onNewAdHocContract($event)"
            (onAbort)="show.newAdHocContract = false"
          ></new-ad-hoc-contract>
        </div>
      </div>

      <div
        *ngIf="customer.cash_customer && mayBrowseAsCashCustomer"
        class="row"
      >
        <div class="col-xs-12">
          <h3>Cashkundsspecifikt</h3>
          <button
            type="button"
            class="button"
            [routerLink]="['/customer', customer.id, 'campaign']"
          >
            Boka cash-kampanj
          </button>
          <button
            type="button"
            class="button"
            [routerLink]="['/customer', customer.id, 'dashboard']"
          >
            Visa cashboard
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="tabbable__panel clearfix shadow-z-1">
          <div class="col-xs-12">
            <h3><span class="icon-user"></span> Användare</h3>
          </div>
        </div>
        <div class="account__card margin--none padding--none clearfix">
          <table class="table table--woo table--hover">
            <tbody>
              <tr *ngFor="let user of customer.users">
                <td>
                  <a
                    [routerLink]="['/user', user.id]"
                  >
                    {{ user.first_name }} {{ user.last_name }}
                  </a>
                </td>
              </tr>
              <tr *ngFor="let user of customer.previous_agency_users">
                <td>
                  <a
                    [routerLink]="['/user', user.id]"
                  >
                    {{ user.first_name }} {{ user.last_name }} (tidigare)
                  </a>
                </td>
              </tr>
              <tr
                *ngIf="
                  !customer.users.length &&
                  !customer.previous_agency_users.length
                "
              >
                <td>
                  Inga användare kopplade till denna kund
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          *ngIf="
            !customer.agencies.length &&
            mayCreateNewUser
          "
          class="button secondary pull-right"
          (click)="openNewUser()"
        >
          Ny användare
        </button>
      </div>

      <div *ngIf="mayViewHistory" class="col-xs-12 col-md-6">
        <div class="tabbable__panel clearfix shadow-z-1">
          <div class="col-xs-12">
            <h3>Historik</h3>
          </div>
        </div>
        <div class="account__card margin--none clearfix">
          <history-list [items]="customerHistory"></history-list>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div id="newItemInCustomer" class="col-xs-12">
      <div class="account__card" *ngIf="show.newUser">
        <h3>Skapa nytt användarkonto för {{ customer.name }}</h3>
        <form
          name="forms.newUserForm"
          (ngSubmit)="addUser(newUserObj, customer.id, customer.cash_customer)"
        >
          <div class="input__container">
            <label for="firstname">Förnamn *</label>
            <input
              id="firstname"
              name="firstName"
              type="text"
              [(ngModel)]="newUserObj.first_name"
            />
          </div>
          <div class="input__container">
            <label for="lastname">Efternamn *</label>
            <input
              id="lastname"
              name="lastName"
              type="text"
              [(ngModel)]="newUserObj.last_name"
            />
          </div>
          <div class="input__container">
            <label for="email">Email *</label>
            <input
              name="email"
              id="emailuser"
              type="email"
              [(ngModel)]="newUserObj.email"
              required
            />
          </div>
          <button type="submit" class="button primary-1">Skapa</button>
          <button type="button" class="link primary-1" (click)="closeNewUser()">
            Avbryt
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
