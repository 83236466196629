<div class="padding-bottom--2x">
  <input
    class="checkbox"
    id="use-segmentation-{{ instanceId }}"
    type="checkbox"
    [(ngModel)]="useSegmentation"
    (ngModelChange)="useSegmentationChange()"
    e2e-id="use-segmentation"
  />
  <label class="checkbox" for="use-segmentation-{{ instanceId }}"
    >Välj vilka dagar materialet ska visas</label
  >
</div>

<div class="padding-bottom--2x">
    Du har fördelat <span class="text--strong">{{ distributed | number }}</span> av totalt <span class="text--strong">{{ totalToDistribute | wooCurrency:distributionCurrency }}</span>
</div>

<div>
  <p>
    <span class="icon-warning text--warning"></span> Obs TTV-kampanj! Du kan enbart ändra fördelningen mellan filmlängder.
    <info-badge>
      Fördelning från bokningen i Spotlight:
      <table class="table table--small width--unset">
        <thead>
          <tr class="text--semi-strong">
            <td>Längd</td>
            <td>Budget</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let creativeQuotaPair of originalCreativeLengthQuota | keyvalue">
            <td>{{ creativeQuotaPair.key }} s</td>
            <td>
              {{
                creativeQuotaPair.value.budget + creativeQuotaPair.value.budgetDecimals / 100
                  | currency: "kr"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </info-badge>
  </p>
</div>

<ng-container *ngFor="let lengthCreativesPair of creativesByLength | keyvalue"> 
  <div>
    <h3 >Filmlängd: {{ lengthCreativesPair.key }} s </h3>
    <p [ngClass]="{'text--danger': !validDistribution(lengthCreativesPair.key)}"> 
      Du har <span class="text--strong">{{(getTotalBudgetForLength(lengthCreativesPair.key)) - distributionsPerLength[lengthCreativesPair.key] | currency: "kr"}}</span> kvar att fördela av totalt <span class="text--semi-strong">{{getTotalBudgetForLength(lengthCreativesPair.key) | currency: "kr"}}</span>
    </p>
  </div>

<table 
  class="table table--with-small-images margin-bottom--6x centered"
  *ngIf="creatives.length && distributions"
>
  <thead>
    <tr>
      <td *ngIf="!isVideo">Bild</td>
      <td>Namn</td>
      <td *ngIf="isVideo">Leveranssätt</td>
      <td *ngIf="isVideo">Filmkod</td>
      <td *ngIf="isVideo">Längd</td>
      <td>Budget</td>
      <td>{{ currency }}</td>
      <td>Fördelning</td>
      <td></td>
    </tr>
  </thead>
  
  <tbody *ngFor="let creative of lengthCreativesPair.value; trackBy: trackById" [attr.e2e-id]="creative.custom_name" class="padding-bottom--2x"> 
    <tr>
      <td *ngIf="!isVideo">
        <img class="margin-right--1x" *ngIf="creative.asset_url" [src]="creative.asset_url" />
        <ng-container *ngIf="!creative.asset_url"
          >Levereras senare</ng-container
        >
      </td>
      <td>{{ creative.custom_name }}</td>
      <td *ngIf="isVideo">
        {{ translateCreativeType(creative.creative_type) }}
      </td>
      <td *ngIf="isVideo">{{ creative.video_code }}</td>
      <td *ngIf="isVideo">{{ creative.length }} s</td>
      <td>
        <ng-container *ngIf="editBudgetShare(lengthCreativesPair.value)">
          <input
            type="text"
            class="input--compact long"
            [(ngModel)]="distributions[creative.id].budget"
            [wooMinValidator]="1"
            (ngModelChange)="updateQuotas()"
            wooNumberFormatter
          />
          <remaining-button
            (click)="setRemaining(creative)"
          ></remaining-button>
        </ng-container>
        <ng-container *ngIf="!editBudgetShare(lengthCreativesPair.value)">{{
          distributions[creative.id].budget | currency: "kr" | dashAsDefault
        }}</ng-container>
      </td>
      <td>
        {{ distributions[creative.id].views | number | dashAsDefault }}
      </td>
      <td>
        {{ distributions[creative.id].quota / 100 | percent: "1.0-2" }}
      </td>

      <td>
        <button (click)="removeCreative(creative)">
          <span class="icon-trash icon__semi-medium text--info"></span>
        </button>
        <button (click)="editCreative(creative)">
          <span
            class="icon-edit icon__semi-medium text--info"
          ></span>
        </button>
      </td>
    </tr>

    <tr *ngIf="useSegmentation">
      <td colspan="8">
        <button class="pull-right" (click)="toggleExpanded(creative.id)">
          <span
            class="chevron large text--info"
            [ngClass]="{ bottom: expanded[creative.id] }"
          >
          </span>
        </button>
        <p class="padding-bottom--2x">
          Välj de dagar som materialet ska gå.
          <br />
          Du kan endast välja mellan de dagar du har valt i styrningssteget.
        </p>
        <segment-select
          *ngIf="expanded[creative.id]"
          [@verticalExpansion]
          [attr.e2e-id]="creative.custom_name"
          class="display--block width--75"
          [periodStart]="targetingPeriod.start"
          [periodEnd]="targetingPeriod.end"
          [(ngModel)]="creative.segments"
          (ngModelChange)="segmentsChange(creative)"
          [unbookableWeeks]="unbookableWeeks"
        ></segment-select>
      </td>
    </tr>
  </tbody>
</table>
<div class="margin-top-bottom--4x"></div>
</ng-container>

<button-with-line
  [onClick]="addCreative"
  label="Lägg till material"
></button-with-line>
