import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from './AuthService';
import { SessionService } from './SessionService';
import { wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class TermsService {
  needsToAcceptTerms$: Subject<void> = new Subject();
  bookingDraftRedirect: wooId;

  constructor(private sessionService: SessionService, private authService: AuthService) {}

  userNeedsToAcceptTerms(): boolean {
    if (!this.authService.isAuthenticated()) {
      return false;
    }
    const user = this.sessionService.getUser();
    return !this.authService.adminOrPlanner() && user.accepted_terms_and_conditions !== true;
  }

  resetBookingDraftRedirect(): void {
    this.bookingDraftRedirect = undefined;
  }
}
