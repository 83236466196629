import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from '../../../../utils/dom';
import {
  AdvancedTargetGroup,
  AdvancedTargetGroupCategory,
  AdvancedTargetGroupParams,
  AdvancedTargetGroupService,
} from '../../../../woo_services.module/AdvancedTargetGroupService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import {
  ProductFormat,
  Publisher,
  SlotType,
  SlotTypeEstimates,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { SystemService } from '../../../../woo_services.module/SystemService';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-advanced-target-groups',
  templateUrl: './manage-advanced-target-groups.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageAdvancedTargetGroups implements OnInit {
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    newTargetGroupForm: false,
    newCategoryForm: false,
    estimatesHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };

  readonly tableHeaders = [
    'Publicist',
    'Placering',
    'Kategori',
    'Tagg',
    'MRM-id',
    'Namn',
    'Bokningsbar',
    'Preroll',
    'Midroll',
    'Postroll',
    'Preroll',
    'Midroll',
    'Postroll',
    'Redigera',
  ];

  readonly staticFields = 7;

  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  advancedTargetGroups: AdvancedTargetGroup[] = [];
  selectedAdvancedTargetGroups: AdvancedTargetGroup;
  advancedTargetGroupRows: AdvancedTargetGroupRow[];
  categories: AdvancedTargetGroupCategory[] = [];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  shareOfTotal: number;
  translateProductFormat: (args: any) => string;

  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('newCategoryFormContainer', { static: true }) newCategoryFormContainer: ElementRef;
  @ViewChild('newTargetGroupFormContainer', { static: true }) newTargetGroupFormContainer: ElementRef;
  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private advancedTargetGroupService: AdvancedTargetGroupService,
    private utilityService: ProductsPageUtilityService,
    private dialogService: DialogService,
    private systemService: SystemService,
    private translationService: TranslationService,
  ) {}

  get noAvailableCategories(): boolean {
    return this.categories.length === 0;
  }

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.advancedTargetGroupService
      .getTargetGroupCategories()
      .then((categories) => {
        this.categories = categories;
      })
      .then(() => {
        this.systemService.getValue('advanced_target_group_estimate_share_of_total').then((shareOfTotal) => {
          this.shareOfTotal = shareOfTotal;
        });
      })
      .then(() => {
        this.publisherService
          .getPublishers(ExtraPublisherField.enabled, ExtraPublisherField.productFormats)
          .then((publishers) => {
            this.publishers = publishers;
            this.publishers = publishers.filter((p) => p.enabled);
          });
      })
      .then(() => {
        this.productFormatService
          .getProductFormats(
            ExtraProductFormatField.enabled,
            ExtraProductFormatField.slotTypes,
            ExtraProductFormatField.advancedTargetGroupShares,
            ExtraProductFormatField.advancedTargetGroupWithdrawalLimits,
          )
          .then((productFormats) => {
            this.productFormats = productFormats;
            this.productFormats = productFormats.filter((f) => f.enabled);
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
          });
      })
      .then(() => {
        this.setAndBuildAdvancedTargetGroups();
      })
      .finally(() => {
        this.show.loading = false;
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
        scrollTo(this.parentHeader);
      });
  }

  async setAndBuildAdvancedTargetGroups(): Promise<void> {
    this.advancedTargetGroupService.getTargetGroups().then((targetGroups) => {
      this.advancedTargetGroups = targetGroups;
      this.buildAdvancedTargetGroupRows();
    });
  }

  async setCategories(): Promise<void> {
    this.advancedTargetGroupService.getTargetGroupCategories().then((categories) => {
      this.categories = categories;
    });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.setAndBuildAdvancedTargetGroups();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedAdvancedTargetGroups || this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.updatingEstimates = true;
        this.advancedTargetGroupService
          .updateTargetGroups(this.advancedTargetGroups)
          .then(() => {
            this.setAndBuildAdvancedTargetGroups();
            this.show.estimatesHaveChanged = true;
          })
          .catch((err) => {
            this.dialogService.openError(this.translationService.convertError(err.error.error));
          });
      })
      .catch(() => null)
      .finally(() => {
        this.setEditing(false);
        this.show.updatingEstimates = false;
        this.dialogService.closeBlocking();
      });
  }

  async addAdvancedTargetGroup(advancedTargetGroupParams: AdvancedTargetGroupParams): Promise<void> {
    this.advancedTargetGroupService
      .createTargetGroup(advancedTargetGroupParams)
      .then(() => {
        this.show.newTargetGroupForm = false;
        this.show.estimatesHaveChanged = true;
        this.setAndBuildAdvancedTargetGroups();
      })
      .then(() => {
        this.buildAdvancedTargetGroupRows();
        this.show.newTargetGroupForm = false;
      });
  }

  showNewCategoryForm(value: boolean): void {
    this.show.newCategoryForm = value;
    if (value) {
      this.show.newTargetGroupForm = false;
      scrollTo(this.newCategoryFormContainer.nativeElement);
    } else {
      scrollTo(this.parentHeader);
    }
  }

  showNewTargetGroupForm(value: boolean): void {
    this.show.newTargetGroupForm = value;
    if (value) {
      this.show.newCategoryForm = false;
      scrollTo(this.newTargetGroupFormContainer.nativeElement);
    } else {
      scrollTo(this.parentHeader);
    }
  }

  async updateShareOfTotal(): Promise<void> {
    this.systemService
      .updateShareOfTotal({ advanced_target_group_estimate_share_of_total: this.shareOfTotal })
      .then((settings) => {
        if (this.shareOfTotal === settings.advanced_target_group_estimate_share_of_total) {
          this.shareOfTotal = settings.advanced_target_group_estimate_share_of_total;
          this.show.estimatesHaveChanged = true;
        }
      });
  }

  getSpecificAdvancedTargetGroupShares(
    advancedTargetGroupId: wooId,
    publisherId: wooId,
    productFormatId: wooId,
  ): SlotTypeEstimates {
    return this.advancedTargetGroups.find((advancedTargetGroup) => advancedTargetGroup.id === advancedTargetGroupId)
      .shares[publisherId][productFormatId];
  }

  getSpecificAdvancedTargetGroupLimits(
    advancedTargetGroupId: wooId,
    publisherId: wooId,
    productFormatId: wooId,
  ): SlotTypeEstimates {
    return this.advancedTargetGroups.find((advancedTargetGroup) => advancedTargetGroup.id === advancedTargetGroupId)
      .withdrawal_limits[publisherId][productFormatId];
  }

  getSpecificAdvancedTargetGroup(advancedTargetGroupId: wooId): AdvancedTargetGroup {
    return this.advancedTargetGroups.find((advancedTargetGroup) => advancedTargetGroup.id === advancedTargetGroupId);
  }

  getCategoryName(categoryId: wooId): string {
    return this.categories.find((category) => category.id === categoryId).name;
  }

  rowVisible(obj: AdvancedTargetGroupRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const advancedTargetGroupFiltered =
      this.selectedAdvancedTargetGroups && obj.advancedTargetGroupId !== this.selectedAdvancedTargetGroups.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || advancedTargetGroupFiltered || productFormatFiltered);
  }

  private buildAdvancedTargetGroupRows(): void {
    let advancedTargetGroupRows: AdvancedTargetGroupRow[] = [];
    this.publishers.map((publisher) => {
      this.advancedTargetGroups.map((advancedTargetGroup) => {
        this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
          advancedTargetGroupRows = [
            ...advancedTargetGroupRows,
            {
              publisher: publisher.name,
              publisherId: publisher.id,
              productFormat: productFormat.name,
              productFormatId: productFormat.id,
              advancedTargetGroupName: advancedTargetGroup.name,
              advancedTargetGroupId: advancedTargetGroup.id,
              category: this.getCategoryName(advancedTargetGroup.category_id),
              tag: advancedTargetGroup.tag,
              mrm_id: advancedTargetGroup.mrm_id,
              active: advancedTargetGroup.active,
              sharesPreroll: advancedTargetGroup.shares[publisher.id][productFormat.id].preroll,
              sharesMidroll: advancedTargetGroup.shares[publisher.id][productFormat.id].midroll,
              sharesPostroll: advancedTargetGroup.shares[publisher.id][productFormat.id].postroll,
              withdrawalLimitsPreroll: advancedTargetGroup.withdrawal_limits[publisher.id][productFormat.id].preroll,
              withdrawalLimitsMidroll: advancedTargetGroup.withdrawal_limits[publisher.id][productFormat.id].midroll,
              withdrawalLimitsPostroll: advancedTargetGroup.withdrawal_limits[publisher.id][productFormat.id].postroll,
            },
          ];
        });
      });
    });
    this.advancedTargetGroupRows = this.sortRows(advancedTargetGroupRows);
  }

  private sortRows(rows: AdvancedTargetGroupRow[]): AdvancedTargetGroupRow[] {
    const sorted = rows.sort((a, b) => {
      if (a.publisher !== b.publisher) return a.publisher.localeCompare(b.publisher);
      if (a.category !== b.category) return a.category.localeCompare(b.category);
      return a.advancedTargetGroupName.localeCompare(b.advancedTargetGroupName);
    });
    return sorted;
  }
}

interface AdvancedTargetGroupRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  advancedTargetGroupName: string;
  advancedTargetGroupId: wooId;
  category: string;
  tag: string;
  mrm_id: string;
  active: boolean;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
