import { Component } from '@angular/core';
import { WooDialogHelper } from '../../../woo_components.module/dialogs/woo-dialog-helper';
import { CreativeData } from '../../../woo_services.module/CreativeService';
import { Creative, ProductFormatConditions, TargetingType, wooId } from '../../../woo_services.module/shared-types';

const template = /* html */ `
<woo-dialog>
  <instream-creative-form
    *ngIf="targetingType === TargetingType.instream"
    class="display--block"
    (onAbort)="onAbort()"
    (onSubmit)="onSubmit($event)"
    [creative]="creative"
    [creatives]="creatives"
    [customerId]="customerId"
    [creativeLengths]="creativeLengths"
    [productFormatConditions]="productFormatConditions"
  ></instream-creative-form>
  <pause-creative-form
    class="display--block"
    *ngIf="targetingType === TargetingType.pause"
    (onAbort)="onAbort()"
    (onSubmit)="onSubmit($event)"
    [creative]="creative"
    [creatives]="creatives"
    [customerId]="customerId"
  ></pause-creative-form>
</woo-dialog>
`;

@Component({
  selector: 'creative-dialog',
  template: template,
})
export class CreativeDialog extends WooDialogHelper {
  readonly TargetingType = TargetingType;

  creative: CreativeData;
  creatives: Creative[];
  customerId: wooId;
  targetingType: TargetingType;
  creativeLengths: number[];
  productFormatConditions?: ProductFormatConditions;
  onSubmit: (c: CreativeData) => void;
  onAbort: () => void;

  showInstreamForm(
    targetingType: TargetingType,
    customerId: wooId,
    creatives?: Creative[],
    creative?: CreativeData,
    creativeLengths?: number[],
    productFormatConditions?: ProductFormatConditions,
  ): Promise<CreativeData> {
    return this.show(targetingType, customerId, creatives, creative, creativeLengths, productFormatConditions);
  }

  showPauseAdForm(
    targetingType: TargetingType,
    customerId: wooId,
    creatives?: Creative[],
    creative?: CreativeData,
    creativeLengths?: number[],
  ): Promise<CreativeData> {
    return this.show(targetingType, customerId, creatives, creative, creativeLengths);
  }

  private show(
    targetingType: TargetingType,
    customerId: wooId,
    creatives?: Creative[],
    creative?: CreativeData,
    creativeLengths?: number[],
    productFormatConditions?: ProductFormatConditions,
  ): Promise<CreativeData> {
    return new Promise((resolve, reject) => {
      this.targetingType = targetingType;
      this.open(true);
      this.creative = creative;
      this.creatives = creatives;
      this.customerId = customerId;
      this.creativeLengths = creativeLengths;
      this.productFormatConditions = productFormatConditions;
      this.onSubmit = (cr) => {
        this.targetingType = null;
        resolve(cr);
      };
      this.onAbort = () => {
        this.targetingType = null;
        reject();
      };
    });
  }
}
