<table class="text-align--center width--100">
  <ng-container *ngFor="let pair of weeks | keyvalue">
    <tr>
      <td>
        <span class=" text--normal background-color--lightest-gray padding-left-right--1x">v.{{getWeekNumber(pair.key)}}</span>
      </td>
      <td *ngFor="let item of pair.value" class="background-color--lightest-gray">
        <div>
          <label class="margin--none text--normal" [attr.for]="dateFieldId(item)">{{item.date | date:DATE_FORMAT}}</label>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="flex__container justify-content--center padding-bottom--2x">
          <input class="checkbox" [disabled]="isWeekForcedDisabled(pair.key)" [checked]="isWeekSelected(pair.key)" (click)="toggleWeek(pair.key)" type="checkbox">
        </div>
      </td>
      <td *ngFor="let item of pair.value">
        <div class="flex__container justify-content--center padding-bottom--2x">
          <input class="checkbox" [disabled]="item.disabled || item.forceDisabled" [checked]="item.selected" (click)="toggleDay(item);" type="checkbox" [attr.id]="dateFieldId(item)">
        </div>
      </td>
    </tr>
  </ng-container>
</table>
