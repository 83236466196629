<div class="account__card margin-top--3x">
  <h2 class="margin-top--1x">Hämta fakturor i excel format</h2>
  <div>
    <period-select
    name="period"
    [defaultInitDate]="InitialDate.Current"
    [firstSelectableDate]="firstSelectableDate"
    [ngModel]="period"
    (ngModelChange)="periodChanged($event)"
    ></period-select>
    <button type="submit" class="button primary" [disabled]="false" (click)="downloadInvoiceExcel()">Hämta</button>
  </div>
</div>
