import { Component, Input, OnChanges } from '@angular/core';
import { allValuesFalse, updateAttributesFromObj } from '../../utils/object';
import { SimpleChanges } from '../../utils/types';
import { TargetGroupService } from '../../woo_services.module/TargetGroupService';
import { TargetingService } from '../../woo_services.module/TargetingService';

@Component({
  selector: 'simple-targeting-summary',
  templateUrl: './simple-targeting-summary.component.html',
  styleUrls: ['./simple-targeting-summary.component.scss'],
})
export class SimpleTargetingSummary implements OnChanges {
  @Input() targeting: any;
  hasNoBookingFormat: boolean;
  targetGroupNames: string[];
  targetingGroupTargetText: string;
  bookingFormat = {
    region: false,
    publisher_group: false,
    target_group: false,
  };

  TV4_NAME = 'TV4';
  BN_NAME = 'Bonnier News';
  selectedPublisherGroup: string;

  constructor(private targetingService: TargetingService, private targetGroupService: TargetGroupService) {}
  ngOnChanges(changes: SimpleChanges<SimpleTargetingSummary>): void {
    if (!changes.targeting.currentValue) {
      return;
    }

    this.bookingFormat = updateAttributesFromObj(
      this.bookingFormat,
      this.targetingService.getBookingFormat(this.targeting),
    );
    this.hasNoBookingFormat = allValuesFalse(this.bookingFormat);

    if (this.bookingFormat.target_group) {
      this.targetGroupNames = this.targetingService.getTargetGroupNames(this.targeting);
      this.buildTargetGroupTargetingText();
    }
    if (this.bookingFormat.publisher_group) {
      this.targeting.publisher_groups.forEach((group) => {
        if (group.name === this.TV4_NAME) {
          this.selectedPublisherGroup = this.TV4_NAME;
        } else if (group.name === this.BN_NAME) {
          this.selectedPublisherGroup = this.BN_NAME;
        }
      });
    }
  }

  buildTargetGroupTargetingText = (): void => {
    this.targetingGroupTargetText = "Reklamfilmen visas på TV4's digitala plattformar för ";
    if (this.targeting.gender_target_groups.length) {
      const genderNames = this.targetGroupService.getGenderNamesInPluralFromGenderTargetGroups(
        this.targeting.gender_target_groups,
      );
      genderNames.forEach((i, index, array) => {
        if (array.length > 1 && index === array.length - 1) {
          this.targetingGroupTargetText += ' och ';
        }
        this.targetingGroupTargetText += i;
      });
    } else {
      this.targetingGroupTargetText += 'tittare';
    }
    if (this.targeting.age_target_groups.length) {
      const ageSpan = this.targetGroupService.getAgeSpanFromAgeTargetGroups(this.targeting.age_target_groups);
      this.targetingGroupTargetText += ' i åldern ' + ageSpan.minAge + '-' + ageSpan.maxAge + ' år.';
    } else {
      this.targetingGroupTargetText += '.';
    }
  };
}
