import { enableProdMode } from '@angular/core';
/**
 * =========================
 * Bootstrap the application
 * =========================
 */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// Load js dependencies
import 'zone.js'; // Needed by angular
import './init/errorhandling';
import TV4AppModule from './tv4_app.module';
import './utils/polyfills';

if (Boolean(window['WOO_ENV']?.PRODUCTION)) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(TV4AppModule)
  .catch((err) => console.error(err));
