import { Component, Input, OnChanges } from '@angular/core';
import { generateId } from '../../../utils/string';
import { FrequencyLimit, ProductFormat, wooId } from '../../../woo_services.module/shared-types';
import { CachedFrequencyLimitService } from '../../services/CachedFrequencyLimitService';
import { CachedProductFormatService } from '../../services/CachedProductFormatService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
  <p>
    Frekvenstak bestämmer hur många gånger din film maximalt kan visas för samma unika webbläsare/app.
    Frekvenstak på 3 per kampanj eller 2 per vecka går att välja till mot extra kostnad för RBS-kampanjer eller vid styrning mot kategorier.
  </p>

  <form>
    <p *ngFor="let limit of filteredLimits">
      <input class="radio" id="fl-{{limit.id}}-{{instanceId}}" name="frequency-limit-{{instanceId}}" type="radio" [(ngModel)]="selectedId" (ngModelChange)="limitChanged(limit)" [value]="limit.id">
      <label class="radio" for="fl-{{limit.id}}-{{instanceId}}">{{limit.name}}</label>
    </p>
  </form>
`;

@Component({
  selector: 'frequency-limit-targeting',
  template: template,
})
export class FrequencyLimitTargeting extends SubscriptionHandler implements OnChanges {
  readonly noLimit: Readonly<FrequencyLimit> = {
    id: '',
    name: 'Inget frekvenstak',
    impressions: 0,
    rbs_default: false,
    product_formats: [],
    time_unit: 'campaign',
    active: true,
  };
  readonly instanceId = generateId();

  selectedId: wooId;
  filteredLimits: FrequencyLimit[] = [];
  @Input() targetingId: wooId;
  private allLimits: FrequencyLimit[] = [];
  private allProductFormats: ProductFormat[];

  constructor(
    private bookingStore: BookingStore,
    frequencyLimitService: CachedFrequencyLimitService,
    private cachedProductFormatService: CachedProductFormatService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
    frequencyLimitService
      .getAll()
      .then((limits) => {
        this.allLimits = limits;
      })
      .then(() => {
        this.cachedProductFormatService.getProductFormats().then((allPf) => {
          this.allProductFormats = allPf;
          this.initFromStore(bookingStore.state);
        });
      });
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  limitChanged(newLimit: FrequencyLimit): void {
    if (newLimit === this.noLimit) {
      newLimit = null;
    }
    this.bookingStore.setFrequencyLimit(this.targetingId, newLimit);
  }

  private initFromStore = (model: BookingModel) => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.selectedId = targeting.frequency_limit ? targeting.frequency_limit.id : this.noLimit.id;
      this.filteredLimits = [this.noLimit].concat(this.filterLimits(this.allLimits));
    }
  };

  private filterLimits(limits: FrequencyLimit[]): FrequencyLimit[] {
    const targeting = this.bookingStore.state.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting && this.allProductFormats) {
      const requiredPublisherIds = targeting.publishers.map((publishers) => publishers.publisher_id);
      const requiredProductFormatIds = this.allProductFormats
        .filter((pf) => requiredPublisherIds.includes(pf.publisher_id))
        .map((pf) => pf.id);
      return limits
        .filter((limit) => limit.active)
        .filter((limit) => limit.product_formats.some((pf) => requiredProductFormatIds.includes(pf.id)));
    } else {
      return [];
    }
  }
}
