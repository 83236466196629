import { Injectable } from '@angular/core';
import { mapValues } from 'lodash-es';
import { mapConditionalId, uniq } from '../../utils/array';
import { add, getMaxPeriod, isDateAfter, isDateBefore, sameDate } from '../../utils/date';
import { distributeInteger } from '../../utils/math';
import { reject } from '../../utils/object';
import { dispatchMethod } from '../../utils/redux-dev-tools';
import { Store } from '../../utils/store';
import { MessageType } from '../../woo_components.module/feedback/context-message.component';
import { TargetGroupChoices } from '../../woo_components.module/inputs/target-group-select/target-group-select.component';
import { CampaignService } from '../../woo_services.module/CampaignService';
import { CreativeService } from '../../woo_services.module/CreativeService';
import { DialogService } from '../../woo_services.module/DialogService';
import { ProductFormatService } from '../../woo_services.module/ProductFormatService';
import { TargetingService } from '../../woo_services.module/TargetingService';
import { VoucherService } from '../../woo_services.module/VoucherService';
import {
  Brand,
  Campaign,
  CampaignEstimation,
  CampaignEstimationPart,
  CommercialWeek,
  CompactAgency,
  CompactCustomer,
  Creative,
  CreativeShareType,
  Customer,
  DefaultTargetingData,
  FrequencyLimit,
  FullTargetingType,
  GeoTargeting,
  Period,
  ProductChoice,
  ProductFormats,
  RegionalDivision,
  Segment,
  ShareType,
  Targeting,
  TargetingAdvancedTargetGroup,
  TargetingAgeTargetGroup,
  TargetingCategory,
  TargetingDaypart,
  TargetingDeviceGroup,
  TargetingGenderTargetGroup,
  TargetingMetaData,
  TargetingProgram,
  TargetingPublisher,
  TargetingRegion,
  TargetingType,
  ViewCurrency,
  Voucher,
  wooId,
} from '../../woo_services.module/shared-types';
import { GamblingCustomerInformationDialog } from '../components/dialogs/info/gambling-customer-information-dialog.component';
import { BookingValidationService } from '../services/BookingValidationService';
import { MessageFactory } from '../services/MessageFactory';

@Injectable()
export class BookingStore extends Store<BookingModel> {
  private campaignService: CampaignService;
  private creativeService: CreativeService;
  private dialogService: DialogService;
  private messageFactory: MessageFactory;
  private targetingService: TargetingService;
  private validationService: BookingValidationService;
  private voucherService: VoucherService;
  private productFormatService: ProductFormatService;

  constructor(
    campaignService: CampaignService,
    creativeService: CreativeService,
    dialogService: DialogService,
    messageFactory: MessageFactory,
    targetingService: TargetingService,
    validationService: BookingValidationService,
    voucherService: VoucherService,
    productFormatService: ProductFormatService,
  ) {
    const initialTargeting = targetingService.createTargeting();
    const initialState: BookingModel = {
      campaign: {
        ...campaignService.getEmptyCampaign(),
        targetings: [initialTargeting],
      },
      targetingMetaData: {
        [initialTargeting.id]: getDefaultTargetingMetaData(),
      },
      editingCampaign: false,
      templatingCampaign: false,
      activeBookingStep: null,
      estimation: {
        views: null,
        additionalViews: null,
        averageCPCV: null,
        averageDiscount: null,
        status: null,
        parts: [],
      },
      campaignPeriod: {
        start: null,
        end: null,
      },
      waitingFor: {},
      dirtySteps: [],
      disabledSteps: [],
      validationMessages: [],
      campaignStart: {
        agencyAgreementRestriction: null,
        customerCreditWarningRestriction: null,
        agencyCreditWarningRestriction: null,
        roleRestriction: null,
        invoiceCustomer: true,
        minNumberOfDaysUntilStartForCreditWarning: 0,
      },
      agencyUserMinDaysUpdateCreative: 0,
      selectedCustomer: {
        lowCreditRating: false,
        vouchers: [],
      },
      selectedAgency: {
        lowCreditRating: false,
        invoiceCustomer: true,
        agencyAgreementActiveFrom: null,
      },
      featureToggles: {},
      forcedEstimationCorrection: {
        [initialTargeting.id]: null,
      },
    };
    super(initialState);

    this.campaignService = campaignService;
    this.creativeService = creativeService;
    this.dialogService = dialogService;
    this.messageFactory = messageFactory;
    this.targetingService = targetingService;
    this.validationService = validationService;
    this.voucherService = voucherService;
    this.productFormatService = productFormatService;

    const bookingStepStart = BOOKING_STEPS_ORDER[0];
    this.setActiveBookingStep(bookingStepStart);
  }

  setState(model: BookingModel): void {
    super.setState({
      ...model,
      validationMessages: this.getValidationMessages(model),
    });
  }

  assembleCampaign(): Campaign {
    return {
      ...this.state.campaign,
      targetings: this.state.campaign.targetings.map((t) => ({
        ...t,
        unbookable_weeks: this.state.forcedEstimationCorrection[t.id]?.unbookableWeeks || [],
      })),
    };
  }

  @dispatchMethod()
  setActiveBookingStep(bookingStep: BookingStep): void {
    this.setState({
      ...this.state,
      activeBookingStep: bookingStep,
      dirtySteps: this.state.activeBookingStep ? uniq(this.state.dirtySteps.concat(this.state.activeBookingStep)) : [],
    });
  }

  @dispatchMethod()
  setDirtyBookingStep(): void {
    this.setState({
      ...this.state,
      dirtySteps: this.state.activeBookingStep ? uniq(this.state.dirtySteps.concat(this.state.activeBookingStep)) : [],
    });
  }

  @dispatchMethod()
  removeDirtyBookingStep(removeStep: BookingStep): void {
    this.setState({
      ...this.state,
      dirtySteps: this.state.dirtySteps.includes(removeStep)
        ? this.state.dirtySteps.filter((step) => step !== removeStep)
        : this.state.dirtySteps,
    });
  }

  @dispatchMethod()
  setCustomer(customer: CompactCustomer): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        customer_id: customer.id,
        customer_name: customer.name,
        agency_id: null,
        agency_name: null,
        voucher_ids: [],
      },
      selectedAgency: {
        ...this.getSelectedAgency(null, null),
      },
      selectedCustomer: {
        lowCreditRating: false,
        vouchers: [],
      },
    });
  }

  @dispatchMethod()
  setCustomerData(
    customer: Pick<Customer, 'low_credit_rating' | 'gambling_customer' | 'cash_customer' | 'vouchers'>,
  ): void {
    const newCampaign = {
      ...this.state.campaign,
      cash_campaign: customer.cash_customer,
      gambling_campaign: customer.gambling_customer,
    };
    this.setState({
      ...this.state,
      selectedCustomer: {
        lowCreditRating: customer.low_credit_rating,
        vouchers: this.addVoucherToCampaign(newCampaign, newCampaign.voucher_ids, customer.vouchers),
      },
      campaign: newCampaign,
    });
  }

  @dispatchMethod()
  setAgency(agency: CompactAgency, agreementStart: Date | null, resetVoucherId = true): void {
    const newVoucherIds = resetVoucherId ? [] : [...this.state.campaign.voucher_ids];
    const newVouchers: Voucher[] = resetVoucherId
      ? this.removeVouchersFromCampaign()
      : this.state.selectedCustomer.vouchers;

    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        agency_id: agency ? agency.id : null,
        agency_name: agency ? agency.name : null,
        voucher_ids: newVoucherIds,
      },
      selectedAgency: {
        ...this.getSelectedAgency(agency, agreementStart),
      },
      selectedCustomer: {
        ...this.state.selectedCustomer,
        vouchers: newVouchers,
      },
    });
  }

  @dispatchMethod()
  setName(name: string): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        name: name,
      },
    });
  }

  setInventoryOnlyCampaign(inventory_only: boolean): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        inventory_only_campaign: inventory_only,
      },
    });
  }

  setBrand(brand: Brand): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        brand: brand,
      },
    });
  }

  @dispatchMethod()
  setReferenceNumber(referenceNumber: string): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        reference_number: referenceNumber,
      },
    });
  }

  @dispatchMethod()
  setClientInvoiceReference(clientInvoiceReference: string): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        client_invoice_reference: clientInvoiceReference,
      },
    });
  }

  @dispatchMethod()
  setAdditionalInfo(additionalInfo: string): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        additional_info: additionalInfo,
      },
    });
  }

  @dispatchMethod()
  setSalesOrderNumber(targetingId: wooId, salesOrderNumber: string): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.transformTargeting(targetingId, (t) => ({
          ...t,
          sales_order_number: salesOrderNumber,
        })),
      },
    });
  }

  @dispatchMethod()
  setIncludesLinear(targetingId: wooId, includesLinear: boolean): void {
    const campaignForcedNoInvoice =
      this.state.campaign.targetings.length > 1 && this.state.campaign.targetings.every((t) => !t.send_invoices);
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.transformTargeting(targetingId, (t) => ({
          ...t,
          includes_linear: includesLinear,
          sales_order_number: campaignForcedNoInvoice || includesLinear ? t.sales_order_number : '',
          send_invoices: campaignForcedNoInvoice ? false : !includesLinear,
        })),
      },
    });
  }

  @dispatchMethod()
  setAdditionalBudgetChoice(targetingId: wooId, additionalBudgetChoice: boolean): void {
    const newTargetings = this.state.campaign.targetings.map((t: Targeting) =>
      t.id === targetingId
        ? {
            ...t,
            additional_budget: null,
            additional_budgeted_impressions: null,
            additional_budget_message: '',
          }
        : t,
    );

    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          adminChoices: {
            ...this.state.targetingMetaData[targetingId].adminChoices,
            additionalBudget: additionalBudgetChoice,
          },
        },
      },
    });
  }

  @dispatchMethod()
  setAdditionalBudget(
    targetingId: wooId,
    additionalBudget: number,
    additionalBudgetedImpressions: number,
    additionalBudgetMessage: string,
  ): void {
    const newTargetings = this.state.campaign.targetings.map((t: Targeting) =>
      t.id === targetingId
        ? {
            ...t,
            additional_budget: t.calculate_from_budget ? additionalBudget : null,
            additional_budgeted_impressions: !t.calculate_from_budget ? additionalBudgetedImpressions : null,
            additional_budget_message: additionalBudgetMessage,
          }
        : t,
    );

    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setNoInvoices(targetingId: wooId, invoiceDisableMessage: string, salesOrderNumber: string): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.transformTargeting(targetingId, (t) => ({
          ...t,
          invoice_disable_message: invoiceDisableMessage,
          sales_order_number: salesOrderNumber,
        })),
      },
    });
  }

  @dispatchMethod()
  setNoInvoicesChoice(noInvoicesChoice: boolean): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t: Targeting) => {
          return {
            ...t,
            includes_linear: t.includes_linear ? noInvoicesChoice : false,
            send_invoices: !noInvoicesChoice,
            invoice_disable_message: '',
            sales_order_number: '',
          };
        }),
      },
    });
  }

  @dispatchMethod()
  setGeoTargeting(targetingId: wooId, geoTargeting: GeoTargeting): void {
    const isNational = geoTargeting === GeoTargeting.national;
    const campaignForcedNoInvoice =
      this.state.campaign.targetings.length > 1 && this.state.campaign.targetings.every((t) => !t.send_invoices);

    const salesOrderNumber = (noInvoiceForced: boolean, t: Targeting) => {
      return noInvoiceForced || !t.includes_linear ? t.sales_order_number : '';
    };

    const salesDisabledMessage = (noInvoiceForced: boolean, t: Targeting) => {
      return noInvoiceForced || !t.includes_linear ? t.invoice_disable_message : '';
    };

    const newTargetings = isNational
      ? this.state.campaign.targetings.map((t) =>
          t.id === targetingId
            ? {
                ...t,
                regional_share_type: ShareType.WOO,
                regions: [],
                includes_linear: false,
                sales_order_number: salesOrderNumber(campaignForcedNoInvoice, t),
                send_invoices: campaignForcedNoInvoice ? false : t.includes_linear ? true : t.send_invoices,
                invoice_disable_message: salesDisabledMessage(campaignForcedNoInvoice, t),
                frequency_limit: null,
              }
            : t,
        )
      : this.state.campaign.targetings;
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
        voucher_ids: this.similarTargetingExists(targetingId, 'geoTargeting')
          ? [...this.state.campaign.voucher_ids]
          : this.setApplicableVoucherIds(targetingId),
      },
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          geoTargeting: geoTargeting,
        },
      },
      selectedCustomer: {
        ...this.state.selectedCustomer,
        vouchers: this.removeVouchersFromCampaign(),
      },
    });
  }

  @dispatchMethod()
  setRegionalDivision(targetingId: wooId, regionalDivision: RegionalDivision): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t) =>
          t.id === targetingId ? { ...t, regional_division: regionalDivision } : t,
        ),
      },
    });
  }

  @dispatchMethod()
  setSegmentedCreatives(targetingId: wooId, use: boolean): void {
    const targeting = this.state.campaign.targetings.find((t) => t.id === targetingId);
    const newMetaData = {
      ...this.state.targetingMetaData,
      [targetingId]: {
        ...this.state.targetingMetaData[targetingId],
        segmentedCreatives: use,
      },
    };
    const newTargetings = this.state.campaign.targetings.map((t) =>
      t.id === targetingId
        ? {
            ...t,
            creatives: t.creatives.map((c) => ({
              ...c,
              segments: [{ start_date: targeting.start_date, end_date: targeting.end_date }],
            })),
          }
        : t,
    );

    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
      targetingMetaData: newMetaData,
    });
  }

  @dispatchMethod()
  setProductChoice(targetingId: wooId, productChoice: ProductChoice): void {
    const newTargetings = this.state.campaign.targetings.map((targeting: Targeting) =>
      targeting.id === targetingId ? this.resetTargetingForProduct(targeting) : { ...targeting, frequency_limit: null },
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
        voucher_ids: this.similarTargetingExists(targetingId, 'productChoice')
          ? [...this.state.campaign.voucher_ids]
          : this.setApplicableVoucherIds(targetingId),
      },
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          productChoice: productChoice,
          addonTargeting: ProductChoice.noChoice,
          targetGroupChoices: { age: false, gender: false },
        },
      },
      selectedCustomer: {
        ...this.state.selectedCustomer,
        vouchers: this.removeVouchersFromCampaign(),
      },
    });
  }

  @dispatchMethod()
  setAddonTargeting(targetingId: wooId, addonTargeting: ProductChoice): void {
    const newTargetings = this.state.campaign.targetings.map((t: Targeting) =>
      t.id === targetingId ? this.resetAddonTargetingForProduct(t) : { ...t, frequency_limit: null },
    );
    const prevoiusAddonTargeting = this.state.targetingMetaData[targetingId].addonTargeting;
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
        voucher_ids: this.similarTargetingExists(targetingId, 'addonTargeting')
          ? [...this.state.campaign.voucher_ids]
          : this.setApplicableVoucherIds(targetingId),
      },
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          addonTargeting: addonTargeting,
          targetGroupChoices:
            prevoiusAddonTargeting === ProductChoice.targetGroup
              ? { age: false, gender: false }
              : this.state.targetingMetaData[targetingId].targetGroupChoices,
        },
      },
      selectedCustomer: {
        ...this.state.selectedCustomer,
        vouchers: this.removeVouchersFromCampaign(),
      },
    });
  }

  @dispatchMethod()
  removeLegacyProducts(): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map<Targeting>((t: Targeting) => ({
          ...t,
          publisher_groups: [],
          behaviors: [],
          publisher_group_share_type: 'woo',
        })),
      },
    });
  }

  @dispatchMethod()
  setBudget(targetingId: wooId, budget: number): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t) => ({
          ...t,
          budget: t.id === targetingId ? budget : t.budget,
        })),
      },
    });
  }

  @dispatchMethod()
  setTargetingType(targetingId: wooId, type: TargetingType): void {
    const productFormat = this.state.campaign.targetings.find((t) => t.id === targetingId).product_formats;
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        voucher_ids: this.similarTargetingExists(targetingId, 'targetingType')
          ? [...this.state.campaign.voucher_ids]
          : this.setApplicableVoucherIds(targetingId),
        targetings: this.state.campaign.targetings.map((t) =>
          t.id === targetingId
            ? {
                ...t,
                pause_ad: type === TargetingType.pause ? true : false,
                frequency_limit: null,
                creatives: [],
                view_currency: this.setViewCurrency(type, productFormat),
              }
            : t,
        ),
      },
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          targetingType: type,
        },
      },
      selectedCustomer: {
        ...this.state.selectedCustomer,
        vouchers: this.removeVouchersFromCampaign(),
      },
    });
  }

  @dispatchMethod()
  setBudgetedImpressions(targetingId: wooId, impressions: number): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t) => ({
          ...t,
          budgeted_impressions: t.id === targetingId ? impressions : t.budgeted_impressions,
        })),
      },
    });
  }

  @dispatchMethod()
  setCalculateFromBudget(targetingId: wooId, calculateFromBudget: boolean): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t) => {
          return t.id === targetingId
            ? {
                ...this.resetDistributions(t),
                creative_share_type: CreativeShareType.Percent,
                calculate_from_budget: calculateFromBudget,
                budget: calculateFromBudget ? t.budgeted_impressions : 0,
                budgeted_impressions: calculateFromBudget ? 0 : t.budget,
                additional_budgeted_impressions: calculateFromBudget ? 0 : t.additional_budget,
                additional_budget: calculateFromBudget ? t.additional_budgeted_impressions : 0,
              }
            : t;
        }),
      },
    });
  }

  @dispatchMethod()
  setTargetingPeriod(id: wooId, newPeriod: Period): void {
    const targeting = this.state.campaign.targetings.find((t) => t.id === id);
    const oldPeriod = {
      start: targeting.start_date,
      end: targeting.end_date,
    };

    const newTargetings = this.transformTargeting(id, (t) => ({
      ...t,
      start_date: newPeriod.start,
      end_date: newPeriod.end,
      creatives: t.creatives.map((c) => ({ ...c, segments: this.getSegments(c, newPeriod, oldPeriod) })),
    }));

    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
      campaignPeriod: getMaxPeriod(newTargetings),
    });
  }

  @dispatchMethod()
  setCurrentGrossRatingFactor(): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t) => ({
          ...t,
          current_gross_rating_factor:
            this.campaignService.getCampaignGrossRatingFactor(this.state.campaign) ||
            this.state.campaign.targetings[0].current_gross_rating_factor,
        })),
      },
    });
  }

  @dispatchMethod()
  setDefaultPublishers(targetingId: wooId, publishers: TargetingPublisher[]): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t) => ({
          ...t,
          publishers: t.id === targetingId ? publishers : t.publishers,
        })),
      },
    });
  }

  @dispatchMethod()
  setDefaultViewCurrency(): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t) => ({
          ...t,
          view_currency: this.defaultViewCurrency(),
        })),
      },
    });
  }

  @dispatchMethod()
  setDefaultTargetingData(defaultTargeting: DefaultTargetingData): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.map((t) => ({
          ...t,
          ...defaultTargeting,
        })),
      },
    });
  }

  @dispatchMethod()
  setVoucher(voucherId: wooId): void {
    const voucherIds = this.state.campaign.voucher_ids?.includes(voucherId)
      ? this.state.campaign.voucher_ids.filter((vid) => vid !== voucherId)
      : [...this.state.campaign.voucher_ids, voucherId];
    const newCampaign = {
      ...this.state.campaign,
      voucher_ids: voucherIds,
    };
    this.setState({
      ...this.state,
      campaign: newCampaign,
      selectedCustomer: {
        ...this.state.selectedCustomer,
        vouchers: voucherId ? this.addVoucherToCampaign(newCampaign, [voucherId]) : this.removeVouchersFromCampaign(),
      },
    });
  }

  @dispatchMethod()
  focusTargeting(targetingId: wooId): void {
    this.setState({
      ...this.state,
      activeBookingStep: BookingStep.targeting,
      targetingMetaData: Object.entries(this.state.targetingMetaData).reduce((result, [id, metaData]) => {
        return Object.assign(result, { [id]: { ...metaData, expandedTargeting: id === targetingId } });
      }, {}),
    });
  }

  @dispatchMethod()
  addTargeting(): wooId {
    const lastTargetingIndex = this.state.campaign.targetings.length - 1;
    const lastTargeting = this.state.campaign.targetings[lastTargetingIndex];
    const lastTargetingMetaData = Object.values(this.state.targetingMetaData)[lastTargetingIndex];
    const currentGrossRatingFactor = this.state.campaign.targetings[0].current_gross_rating_factor;
    const newTargetingViewCurrency =
      lastTargetingMetaData.targetingType === TargetingType.pause
        ? ViewCurrency.impressions
        : this.getCampaignDefaultVideoViewCurrency();
    const copyFromTargetingData = {
      start_date: this.state.campaignPeriod.start,
      end_date: this.state.campaignPeriod.end,
      includes_linear: lastTargeting.includes_linear,
      send_invoices: lastTargeting.send_invoices,
      invoice_disable_message: lastTargeting.includes_linear ? '' : lastTargeting.invoice_disable_message,
      sales_order_number: lastTargeting.sales_order_number,
      regional_division: lastTargeting.regional_division,
      full_universe: this.state.campaign.targetings[0].full_universe,
      gross_rating_factor:
        newTargetingViewCurrency === ViewCurrency.grossRatingViews
          ? this.campaignService.getCampaignGrossRatingFactor(this.state.campaign) || currentGrossRatingFactor
          : null,
      current_gross_rating_factor: currentGrossRatingFactor,
      view_currency: newTargetingViewCurrency,
    };
    const newTargeting = {
      ...this.targetingService.createTargeting(),
      ...copyFromTargetingData,
    };
    const copyTargetingMetaData = {
      targetingType: lastTargetingMetaData.targetingType,
      geoTargeting: lastTargetingMetaData.geoTargeting,
    };
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.state.campaign.targetings.concat(newTargeting),
      },
      targetingMetaData: {
        ...mapValues(this.state.targetingMetaData, (data) => ({
          ...data,
          expandedTargeting: false,
        })),
        [newTargeting.id]: { ...getDefaultTargetingMetaData(), ...copyTargetingMetaData },
      },
      activeBookingStep: BookingStep.targeting,
    });
    return newTargeting.id;
  }

  @dispatchMethod()
  removeTargeting(targetingId: wooId): void {
    const newTargetings = this.state.campaign.targetings.filter((t) => t.id !== targetingId);
    if (newTargetings.length === 0) {
      return;
    }
    const newMetaData = Object.entries(this.state.targetingMetaData)
      .filter(([id, _]) => id !== targetingId)
      .reduce<BookingModel['targetingMetaData']>((acc, [key, meta]) => Object.assign(acc, { [key]: meta }), {});
    this.setState({
      ...this.state,
      campaign: { ...this.state.campaign, targetings: newTargetings },
      targetingMetaData: newMetaData,
    });
  }

  @dispatchMethod()
  setRegionalShareType(targetingId: wooId, shareType: ShareType): void {
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId
        ? { ...this.resetDistributions(targeting), regional_share_type: shareType }
        : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setProgramShareType(targetingId: wooId, shareType: ShareType): void {
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId
        ? { ...this.resetDistributions(targeting), program_format_share_type: shareType }
        : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setRegions(regions: TargetingRegion[], targetingId: wooId): void {
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId
        ? {
            ...targeting,
            regions: regions,
            regional_share_type: regions.length > 1 ? targeting.regional_share_type : ShareType.WOO,
          }
        : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setCategories(categories: TargetingCategory[], targetingId: wooId): void {
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId ? { ...targeting, categories: categories } : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setDeviceGroups(deviceGroups: TargetingDeviceGroup[], targetingId: wooId): void {
    const addonTargetingCompatible = deviceGroups.every((dg) => dg.addon_targetable);
    const newTargetings = this.state.campaign.targetings.map((targeting) => {
      if (targeting.id === targetingId) {
        const resetAddonTargeting = this.resetAddonTargetingForProduct(targeting);
        return {
          ...(addonTargetingCompatible ? targeting : resetAddonTargeting),
          device_groups: deviceGroups,
        };
      } else return targeting;
    });
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          addonTargeting: addonTargetingCompatible
            ? this.state.targetingMetaData[targetingId].addonTargeting
            : ProductChoice.noChoice,
          targetGroupChoices: addonTargetingCompatible
            ? this.state.targetingMetaData[targetingId].targetGroupChoices
            : { gender: false, age: false },
        },
      },
    });
  }

  @dispatchMethod()
  setTargetGroups(
    ages: TargetingAgeTargetGroup[],
    genders: TargetingGenderTargetGroup[],
    choices: TargetGroupChoices,
    targetingId: wooId,
    universe?: number,
  ): void {
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId
        ? {
            ...targeting,
            age_target_groups: ages,
            gender_target_groups: genders,
            universe: universe,
          }
        : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          targetGroupChoices: choices,
        },
      },
    });
  }

  @dispatchMethod()
  setProgramFormats(programs: TargetingProgram[], targetingId: wooId): void {
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId
        ? {
            ...targeting,
            program_formats: programs,
            program_format_share_type: programs.length > 1 ? targeting.program_format_share_type : ShareType.WOO,
          }
        : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setDayparts(dayparts: TargetingDaypart[], targetingId: wooId): void {
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId ? { ...targeting, dayparts } : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setAdvancedTargetGroups(advancedTargetGroups: TargetingAdvancedTargetGroup[], targetingId: wooId): void {
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId ? { ...targeting, advanced_target_groups: advancedTargetGroups } : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setExpandedTargetingIsAnimating(targetingId: wooId, isAnimating: boolean): void {
    this.setState({
      ...this.state,
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          expandedTargetingIsAnimating: isAnimating,
        },
      },
    });
  }

  @dispatchMethod()
  setExpandedTargeting(targetingId: wooId, expanded: boolean): void {
    this.setState({
      ...this.state,
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          expandedTargeting: expanded,
        },
      },
    });
  }

  @dispatchMethod()
  setExpandedSummary(targetingId: wooId, expanded: boolean): void {
    this.setState({
      ...this.state,
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          expandedSummary: expanded,
        },
      },
    });
  }

  @dispatchMethod()
  addCreative(targetingId: wooId, creative: Creative): void {
    const targeting = this.state.campaign.targetings.find((t) => t.id === targetingId);
    const newCreative = this.newCreative(targeting, creative, this.state.campaign.ttv_campaign);
    let newCreatives = targeting.creatives.concat(newCreative);
    const totalBudget = this.getSelectedBudget(this.state.campaign);

    if (!this.state.campaign.ttv_campaign) {
      const creativeQuotasMap = this.getCreativeQuotas(newCreatives, totalBudget);
      newCreatives = this.setCreativeDistributions(newCreatives, creativeQuotasMap);
    }

    const newTargetings = mapConditionalId(this.state.campaign.targetings, targetingId, (t) => ({
      ...t,
      creatives: newCreatives,
    }));
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
      targetingMetaData: mapValues(this.state.targetingMetaData, (data) => ({
        ...data,
        expandedCreatives: data.expandedCreatives.concat(creative.id),
      })),
    });
  }

  @dispatchMethod()
  setExpandedCreatives(creativeIds: wooId[]): void {
    this.setState({
      ...this.state,
      targetingMetaData: mapValues(this.state.targetingMetaData, (data) => ({
        ...data,
        expandedCreatives: creativeIds,
      })),
    });
  }

  @dispatchMethod()
  updateCreativeDistributions(targetingId: wooId, distributions: CreativeDistributions): void {
    const newTargetings = this.transformTargeting(targetingId, (t) => ({
      ...t,
      creatives: this.setCreativeDistributions(t.creatives, distributions),
    }));
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
    });
  }

  @dispatchMethod()
  setCreativeShareType(targetingId: wooId, shareType: CreativeShareType): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.transformTargeting(targetingId, (t) => ({ ...t, creative_share_type: shareType })),
      },
    });
  }

  @dispatchMethod()
  updateCreative(targetingId: wooId, newCreative: Creative): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.transformTargeting(targetingId, (t) => ({
          ...t,
          creatives: mapConditionalId(t.creatives, newCreative.id, (c) => ({ ...c, ...newCreative })),
        })),
      },
    });
  }

  @dispatchMethod()
  removeCreative(targetingId: wooId, creativeId: wooId, TTVCampaign: boolean): void {
    const newTargetings = this.transformTargeting(targetingId, (t) => {
      const newCreatives = t.creatives.filter((c) => c.id !== creativeId);
      if (!TTVCampaign) {
        const totalBudget = this.getSelectedBudget(this.state.campaign);
        const creativeQuotasMap = this.getCreativeQuotas(newCreatives, totalBudget);
        const updatedCreatives = this.setCreativeDistributions(newCreatives, creativeQuotasMap);
        return {
          ...t,
          creatives: updatedCreatives,
          creative_share_type: updatedCreatives.length > 1 ? t.creative_share_type : CreativeShareType.Percent,
        };
      } else {
        return {
          ...t,
          creatives: newCreatives,
          creative_share_type: CreativeShareType.Budget,
        };
      }
    });
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
      targetingMetaData: mapValues(this.state.targetingMetaData, (data) => ({
        ...data,
        expandedCreatives: data.expandedCreatives.filter((id) => id !== creativeId),
      })),
    });
  }

  @dispatchMethod()
  setCampaignStartRoleRestriction(value: number): void {
    const restrictedDate = add(new Date(), value);
    this.setState({
      ...this.state,
      campaignStart: {
        ...this.state.campaignStart,
        roleRestriction: restrictedDate,
      },
    });
  }

  @dispatchMethod()
  setFrequencyLimit(targetingId: wooId, limit: FrequencyLimit | null): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: this.transformTargeting(targetingId, (t) => ({ ...t, frequency_limit: limit })),
      },
    });
  }

  @dispatchMethod()
  setMinNumberOfDaysUntilStartForCreditWarning(value: number): void {
    this.setState({
      ...this.state,
      campaignStart: {
        ...this.state.campaignStart,
        minNumberOfDaysUntilStartForCreditWarning: value,
      },
    });
  }

  @dispatchMethod()
  setAgencyUserMinDaysUpdateCreative(value: number): void {
    this.setState({
      ...this.state,
      agencyUserMinDaysUpdateCreative: value,
    });
  }

  @dispatchMethod()
  setFeatureToggle(toggleName: string, value: boolean): void {
    this.setState({
      ...this.state,
      featureToggles: { ...this.state.featureToggles, [toggleName]: value },
    });
  }

  @dispatchMethod({ promiseParameterIndex: 0 })
  setWaitingFor<T>(promise: Promise<T>, waitingFlag: WaitingFlagProp): Promise<T> {
    return this.updateAsync(promise, waitingFlag, () => ({}));
  }

  @dispatchMethod({ promiseParameterIndex: 0 })
  setCampaign(promise: Promise<Campaign>, editing = true): Promise<Campaign> {
    return this.updateAsync(promise, 'loadCampaign', (campaign: Campaign) => {
      const targetingMetaData = campaign.targetings.reduce<Record<string, BookingTargetingMetaData>>(
        (obj, t) => ({
          ...obj,
          [t.id]: {
            ...this.targetingService.getMetaData(t),
            targetGroupChoices: this.getDefaultTargetGroupChoices(t),
            segmentedCreatives: this.isCreativeSegmented(t),
            adminChoices: this.getDefaultAdminChoices(t),
            expandedTargeting: true,
            expandedTargetingIsAnimating: false,
            expandedSummary: true,
            expandedCreatives: t.creatives.map(({ id }) => id),
            originalCreativeLengthQuota: campaign.ttv_campaign
              ? this.creativeService.getCreativeLengthQuotas(t.creatives)
              : null,
          },
        }),
        {},
      );
      const disabledSteps = this.getDisabledBookingSteps(campaign);
      const enabledSteps = BOOKING_STEPS_ORDER.filter((step) => !disabledSteps.includes(step));
      const firstEnabledStep = this.getFirstEnabledStep(enabledSteps);
      return {
        campaign: campaign,
        campaignPeriod: getMaxPeriod(campaign.targetings),
        editingCampaign: editing,
        templatingCampaign: !editing,
        targetingMetaData: targetingMetaData,
        disabledSteps: disabledSteps,
        activeBookingStep: firstEnabledStep,
      };
    });
  }

  @dispatchMethod({ promiseParameterIndex: 0 })
  updateEstimation(promise: Promise<CampaignEstimation>): Promise<CampaignEstimation> {
    return this.updateAsync(promise, 'estimateCampaign', (estimation) => {
      return {
        forcedEstimationCorrection: estimation.parts.reduce<Record<string, ForcedEstimationCorrection>>(
          (correction, part) => ({
            ...correction,
            [part.targeting_id]: {
              unbookableWeeks: part.unbookable_weeks,
              forcedNewStartDate: part.forced_new_start_date,
              forcedNewEndDate: part.forced_new_end_date,
              rbsFull: part.full_targeting_type === FullTargetingType.rbs,
            },
          }),
          {},
        ),
        estimation: {
          views: estimation.total_net_impressions,
          additionalViews: estimation.additional_net_impressions,
          averageDiscount: estimation.average_discount,
          averageCPCV: estimation.average_net_cpm,
          status: estimation.status,
          parts: estimation.parts,
        },
      };
    });
  }

  @dispatchMethod()
  setUseNewContract(useContract: boolean): void {
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        use_new_contract: useContract,
      },
    });
  }

  @dispatchMethod()
  setProductFormat(targetingId: wooId, productFormat: ProductFormats[]): void {
    const type = this.state.targetingMetaData[targetingId].targetingType;
    const newTargetings = this.state.campaign.targetings.map((targeting) =>
      targeting.id === targetingId
        ? {
            ...targeting,
            product_formats: productFormat,
            view_currency: this.setViewCurrency(type, productFormat),
          }
        : targeting,
    );
    this.setState({
      ...this.state,
      campaign: {
        ...this.state.campaign,
        targetings: newTargetings,
      },
      targetingMetaData: {
        ...this.state.targetingMetaData,
        [targetingId]: {
          ...this.state.targetingMetaData[targetingId],
          productFormat: this.productFormatService.convertProductFormatToEnum(productFormat),
        },
      },
    });
  }

  private removeVouchersFromCampaign(
    campaignId = this.state.campaign.id,
    vouchers = this.state.selectedCustomer.vouchers,
  ): Voucher[] {
    return vouchers.map((v) => (v?.campaign?.id === campaignId ? { ...v, campaign: undefined } : v));
  }

  private addVoucherToCampaign(
    campaign: Campaign,
    voucherIds: wooId[] | null,
    vouchers = this.state.selectedCustomer.vouchers,
  ): Voucher[] {
    return this.removeVouchersFromCampaign(campaign.id, vouchers).map((v) =>
      voucherIds?.includes(v.id) ? { ...v, campaign: this.campaignService.getCompactCampaign(campaign) } : v,
    );
  }

  private getSelectedAgency(agency: CompactAgency, agreementStart: Date | null): BookingModel['selectedAgency'] {
    return {
      invoiceCustomer: agency ? agency.invoice_customer : true,
      lowCreditRating: agency ? agency.low_credit_rating : false,
      agencyAgreementActiveFrom: agreementStart,
    };
  }

  private transformTargeting(targetingId: wooId, transform: (t: Targeting) => Targeting) {
    return mapConditionalId(this.state.campaign.targetings, targetingId, transform);
  }

  private getFirstEnabledStep(enabledSteps): BookingStep {
    return BOOKING_STEPS_ORDER.find((step) => step === enabledSteps[0]);
  }

  private getSegments(creative: Creative, newPeriod: Period, oldPeriod: Period): Segment[] {
    if (!newPeriod.start || !newPeriod.end) {
      return [];
    }

    if (creative.segments.length === 0) {
      return [{ start_date: newPeriod.start, end_date: newPeriod.end }];
    }

    const changedSegments = creative.segments.reduce<Segment[]>((segments: Segment[], segment: Segment) => {
      if (isDateAfter(segment.start_date, newPeriod.end) || isDateBefore(segment.end_date, newPeriod.start)) {
        return segments;
      }

      let newStart = segment.start_date;
      let newEnd = segment.end_date;

      if (sameDate(segment.end_date, oldPeriod.end) || isDateAfter(segment.end_date, newPeriod.end)) {
        newEnd = newPeriod.end;
      }

      if (sameDate(segment.start_date, oldPeriod.start) || isDateBefore(segment.start_date, newPeriod.start)) {
        newStart = newPeriod.start;
      }

      segments.push({ start_date: newStart, end_date: newEnd });

      return segments;
    }, []);

    if (changedSegments.length === 0) {
      return [{ start_date: newPeriod.start, end_date: newPeriod.end }];
    }

    return changedSegments;
  }

  private updateAsync<T>(
    promise: Promise<T>,
    waitingFlag: WaitingFlagProp,
    getUpdateData: (arg: T) => Partial<BookingModel>,
  ): Promise<T> {
    this.setState({
      ...this.state,
      waitingFor: { ...this.state.waitingFor, [waitingFlag]: true },
    });
    return promise.then(
      (data) => {
        this.setState({
          ...this.state,
          waitingFor: { ...this.state.waitingFor, [waitingFlag]: false },
          ...getUpdateData(data),
        });
        return data;
      },
      (reason) => {
        this.setState({
          ...this.state,
          waitingFor: { ...this.state.waitingFor, [waitingFlag]: false },
        });
        throw reason;
      },
    );
  }

  private getValidationMessages(model: BookingModel): Message[] {
    return [].concat(
      this.messageFactory.createValidationMessages(
        [model.campaign],
        (c) =>
          this.validationService.validateCampaignDetails(c, model.campaign.cash_campaign, model.campaign.mema_campaign),
        () => () => this.setActiveBookingStep(BookingStep.details),
        BookingStep.details,
        MessageKeyEnum.InvalidCampaignFields,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        this.validationService.validateTargeting,
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.InvalidTargetingFields,
      ),

      this.messageFactory.createValidationMessages(
        [model.campaign],
        this.validationService.validateCreatives,
        () => () => this.setActiveBookingStep(BookingStep.distribution),
        BookingStep.distribution,
        MessageKeyEnum.InvalidCreatives,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        this.validationService.validateCreativeDistribution,
        () => () => this.setActiveBookingStep(BookingStep.distribution),
        BookingStep.distribution,
        MessageKeyEnum.InvalidCreativeDistribution,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) =>
          this.validationService.validateCreativeFilmLengthQuotas(
            t.creatives,
            model.campaign.ttv_campaign,
            model.targetingMetaData[t.id].originalCreativeLengthQuota,
          ),
        () => () => this.setActiveBookingStep(BookingStep.distribution),
        BookingStep.distribution,
        MessageKeyEnum.InvalidCreativeLengthQuota,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateRegions(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.RegionsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        [model.campaign],
        (c) => this.validationService.validateCreativeSegments(c),
        () => () => this.setActiveBookingStep(BookingStep.distribution),
        BookingStep.distribution,
        MessageKeyEnum.InvalidCreativeSegments,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateIncludesLinear(t, model.campaign.mema_campaign),
        () => () => this.setActiveBookingStep(BookingStep.targeting),
        BookingStep.targeting,
        MessageKeyEnum.InvalidIncludesLinearFields,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateAdditionalBudget(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.InvalidTargetingFields,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateNoInvoices(t, model.campaign.ttv_campaign, model.campaign.mema_campaign),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.InvalidTargetingFields,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings,
        (t) => this.validationService.validateRegionDistribution(t),
        () => () => this.setActiveBookingStep(BookingStep.distribution),
        BookingStep.distribution,
        MessageKeyEnum.InvalidRegionDistribution,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings,
        (t) => this.validationService.validateProgramDistribution(t),
        () => () => this.setActiveBookingStep(BookingStep.distribution),
        BookingStep.distribution,
        MessageKeyEnum.InvalidProgramDistribution,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateTargetGroups(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.TargetGroupsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateAdvancedTargetGroups(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.AdvancedTargetGroupsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateAddonTargetingTargetGroups(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.TargetGroupsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateAddonTargetingAdvancedTargetGroups(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.AdvancedTargetGroupsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validatePrograms(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.ProgramsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateCategories(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.CategoriesNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateDeviceGroups(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.DeviceGroupsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateAddonTargetingDeviceGroups(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.DeviceGroupsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t) => this.validationService.validateDayparts(t, model.targetingMetaData[t.id]),
        (t) => () => this.focusTargeting(t.id),
        BookingStep.targeting,
        MessageKeyEnum.DaypartsNotPresent,
      ),

      this.messageFactory.createValidationMessages(
        model.campaign.targetings || [],
        (t: Targeting) =>
          this.validationService.validateGamblingCustomerRestrictions(t, model.campaign.gambling_campaign),
        (t) => () => this.actionGamblingCustomerValidation(t.id),
        BookingStep.targeting,
        MessageKeyEnum.InvalidTargetingForGamblingCustomer,
      ),
    );
  }

  private actionGamblingCustomerValidation(id) {
    this.dialogService.create(GamblingCustomerInformationDialog).open();
    this.focusTargeting(id);
  }

  private getCreativeQuotas(creatives: Creative[], totalBudget: number): CreativeDistributions {
    const quotas = distributeInteger(
      creatives.map((c) => c.id),
      100,
    );
    const budgets = distributeInteger(
      creatives.map((c) => c.id),
      totalBudget,
    );
    return creatives.reduce<CreativeDistributions>(
      (result, { id }) => ({
        ...result,
        [id]: {
          budget: budgets[id],
          quota: quotas[id],
        },
      }),
      {},
    );
  }

  private newCreative(targeting: Targeting, creative: Creative, isTTVCampaign: boolean): Creative {
    if (!isTTVCampaign)
      return { ...creative, segments: [{ start_date: targeting.start_date, end_date: targeting.end_date }] };

    const quotas = this.state.targetingMetaData[targeting.id].originalCreativeLengthQuota;
    const firstCreativeForLength =
      this.creativeService.creativesWithLength(targeting.creatives, creative.length).length === 0;

    return {
      ...creative,
      segments: [{ start_date: targeting.start_date, end_date: targeting.end_date }],
      quota: 0,
      budget: firstCreativeForLength ? quotas.get(creative.length).budget : 0,
      budget_decimals: firstCreativeForLength ? quotas.get(creative.length).budgetDecimals : 0,
    };
  }

  private setCreativeDistributions(creatives: Creative[], creativeQuotasMap: CreativeDistributions): Creative[] {
    return creatives.map((creative: Creative) => {
      return {
        ...creative,
        ...{
          quota: creativeQuotasMap[creative.id].quota,
          budget: Math.floor(creativeQuotasMap[creative.id].budget),
          budget_decimals: Math.round((creativeQuotasMap[creative.id].budget % 1) * 100),
        },
      };
    });
  }

  private getDefaultTargetGroupChoices(targeting: Targeting): TargetGroupChoices {
    return {
      age: targeting.age_target_groups.length > 0 ? true : false,
      gender: targeting.gender_target_groups.length === 1 ? true : false,
    };
  }

  private getDefaultAdminChoices(targeting: Targeting): AdminChoices {
    return {
      additionalBudget: targeting.additional_budget > 0 || targeting.additional_budgeted_impressions > 0 ? true : false,
    };
  }

  private getDisabledBookingSteps(campaign: Campaign): BookingStep[] {
    return campaign.ttv_campaign ? [BookingStep.details, BookingStep.targeting] : [];
  }

  private isCreativeSegmented(targeting: Targeting): boolean {
    return targeting.creatives
      .flatMap((c) => c.segments)
      .some((s) => {
        return !(sameDate(s.start_date, targeting.start_date) && sameDate(s.end_date, targeting.end_date));
      });
  }

  private resetTargetingForProduct(targeting: Targeting): Targeting {
    return {
      ...targeting,
      regional_share_type: targeting.regional_share_type ? targeting.regional_share_type : ShareType.WOO,
      regions: targeting.regions,
      age_target_groups: [],
      gender_target_groups: [],
      advanced_target_groups: [],
      program_format_share_type: ShareType.WOO,
      program_formats: [],
      categories: [],
      device_groups: [],
      dayparts: [],
      frequency_limit: null,
      universe: targeting.full_universe,
    };
  }

  private resetAddonTargetingForProduct(targeting: Targeting): Targeting {
    const prevoiusAddonTargeting = this.state.targetingMetaData[targeting.id].addonTargeting;

    return {
      ...targeting,
      age_target_groups: prevoiusAddonTargeting === ProductChoice.targetGroup ? [] : targeting.age_target_groups,
      gender_target_groups: prevoiusAddonTargeting === ProductChoice.targetGroup ? [] : targeting.gender_target_groups,
      advanced_target_groups:
        prevoiusAddonTargeting === ProductChoice.advancedTargetGroup ? [] : targeting.advanced_target_groups,
      program_formats: prevoiusAddonTargeting === ProductChoice.program ? [] : targeting.program_formats,
      categories: prevoiusAddonTargeting === ProductChoice.category ? [] : targeting.categories,
      device_groups: prevoiusAddonTargeting === ProductChoice.device ? [] : targeting.device_groups,
      dayparts: prevoiusAddonTargeting === ProductChoice.daypart ? [] : targeting.dayparts,
    };
  }

  private resetDistributions(targeting: Targeting): Targeting {
    return {
      ...targeting,
      regional_share_type: ShareType.WOO,
      regions: targeting.regions.map((r) => reject(r, 'budget', 'impressions')),
      program_format_share_type: ShareType.WOO,
      program_formats: targeting.program_formats.map((r) => reject(r, 'budget', 'impressions')),
    };
  }

  // TODO change to work per targeting
  private getSelectedBudget(campaign: Campaign) {
    const calculateFromBudget = campaign.targetings.every((t) => t.calculate_from_budget);
    return calculateFromBudget
      ? this.state.campaign.targetings
          .map((t) => t.budget)
          .concat(this.state.campaign.targetings.map((t) => t.additional_budget))
          .reduce((sum, amount) => sum + amount || 0, 0)
      : 0;
  }

  private similarTargetingExists(targetingId: wooId, valueToTrack: string): boolean {
    return Object.entries(this.state.targetingMetaData)
      .filter((targetingMetaData) => targetingMetaData[0] !== targetingId)
      .some(
        (targetingMetaData) =>
          targetingMetaData[1][valueToTrack] === this.state.targetingMetaData[targetingId][valueToTrack],
      );
  }

  private setApplicableVoucherIds(targetingId: wooId): wooId[] {
    const selectedVouchers = this.state.campaign.voucher_ids.map((voucherId) =>
      this.state.selectedCustomer.vouchers.find((voucher) => voucher.id === voucherId),
    );
    const applicableVouchers = this.voucherService.filterVouchers(selectedVouchers, this.state, targetingId, false);
    return applicableVouchers.map((voucher) => voucher.id);
  }

  private setViewCurrency(type: TargetingType, productFormat: ProductFormats[]): ViewCurrency {
    if (productFormat.length < 2 && productFormat.includes(ProductFormats.shortForm)) {
      return ViewCurrency.impressions;
    } else if (type === TargetingType.pause) {
      return ViewCurrency.impressions;
    } else {
      return this.getCampaignDefaultVideoViewCurrency();
    }
  }

  private getCampaignDefaultVideoViewCurrency(): ViewCurrency {
    const noneImpressionsTargeting = this.state.campaign.targetings.find(
      (targeting: Targeting) => targeting.view_currency !== ViewCurrency.impressions,
    );
    if (!!noneImpressionsTargeting) {
      return noneImpressionsTargeting.view_currency;
    }

    return this.defaultViewCurrency();
  }

  private defaultViewCurrency(): ViewCurrency {
    return ViewCurrency.grossRatingViews;
  }
}

function getDefaultTargetingMetaData(): BookingTargetingMetaData {
  return {
    geoTargeting: GeoTargeting.national,
    productChoice: ProductChoice.rbs,
    targetingType: TargetingType.instream,
    productFormat: ProductFormats.longForm,
    addonTargeting: ProductChoice.noChoice,
    targetGroupChoices: {
      age: false,
      gender: false,
    },
    adminChoices: {
      additionalBudget: false,
    },
    expandedTargeting: true,
    expandedTargetingIsAnimating: false,
    expandedSummary: true,
    segmentedCreatives: false,
    expandedCreatives: [],
    originalCreativeLengthQuota: null,
  };
}

export enum BookingStep {
  details = 'details',
  targeting = 'targeting',
  distribution = 'distribution',
  summary = 'summary',
}

export const BOOKING_STEPS_ORDER = [
  BookingStep.details,
  BookingStep.targeting,
  BookingStep.distribution,
  BookingStep.summary,
];

export interface BookingModel {
  campaign: Campaign;
  editingCampaign: boolean;
  templatingCampaign: boolean;
  activeBookingStep: BookingStep;
  estimation: {
    views?: number;
    additionalViews?: number;
    averageDiscount?: number;
    averageCPCV?: number;
    status: string;
    parts: CampaignEstimationPart[];
  };
  campaignPeriod: {
    start?: Date;
    end?: Date;
  };
  waitingFor: WaitingFlags;
  dirtySteps: BookingStep[];
  disabledSteps: BookingStep[];
  targetingMetaData: Record<wooId, BookingTargetingMetaData>;
  validationMessages: Message[];
  campaignStart: {
    agencyAgreementRestriction: Date | null;
    customerCreditWarningRestriction: Date | null;
    agencyCreditWarningRestriction: Date;
    roleRestriction: Date;
    invoiceCustomer: boolean;
    minNumberOfDaysUntilStartForCreditWarning: number;
  };
  agencyUserMinDaysUpdateCreative: number;
  selectedCustomer: {
    lowCreditRating: boolean;
    vouchers: Voucher[];
  };
  selectedAgency: {
    lowCreditRating: boolean;
    invoiceCustomer: boolean;
    agencyAgreementActiveFrom: Date;
  };
  featureToggles: Record<string, boolean>;
  forcedEstimationCorrection: Record<wooId, ForcedEstimationCorrection>;
}

export interface ForcedEstimationCorrection {
  forcedNewStartDate?: Date;
  forcedNewEndDate?: Date;
  unbookableWeeks: CommercialWeek[];
  rbsFull: boolean;
}

export interface BookingTargetingMetaData extends TargetingMetaData {
  targetGroupChoices: TargetGroupChoices;
  adminChoices: AdminChoices;
  expandedTargeting: boolean;
  expandedTargetingIsAnimating: boolean;
  expandedSummary: boolean;
  segmentedCreatives: boolean;
  expandedCreatives: wooId[];
  originalCreativeLengthQuota: CreativeLengthQuota;
}

export type WaitingFlagProp =
  | 'loadCampaign'
  | 'saveCampaign'
  | 'estimateCampaign'
  | 'bookCampaign'
  | 'sendingCampaignToAdserver';
type WaitingFlags = Partial<Record<WaitingFlagProp, boolean>>;

export const enum EstimationStatusTyped {
  ok = 'ok',
  insufficientAvailableInventory = 'insufficient_available_inventory',
  inventoriesSoldOut = 'inventories_sold_out',
}

export type EstimationStatus = EstimationStatusTyped | string;

export interface AdminChoices {
  additionalBudget: boolean;
}

type creativeLength = number;
export type CreativeLengthQuota = Map<creativeLength, Record<'budget' | 'budgetDecimals', number>>;
export interface MessageAction {
  label: string;
  fn: () => void;
}

export interface Message {
  key: MessageKey;
  text: string | string[];
  type: MessageType;
  action?: MessageAction;
  dismissed: boolean;
  originStep?: BookingStep;
}

export type MessageKey = MessageKeyEnum | string;

export const enum MessageKeyEnum {
  InvalidCampaignFields,
  InvalidTargetingFields,
  InvalidCreativeDistribution,
  CategoriesNotPresent,
  DaypartsNotPresent,
  DeviceGroupsNotPresent,
  AdvancedTargetGroupsNotPresent,
  TargetGroupsNotPresent,
  RegionsNotPresent,
  ProgramsNotPresent,
  InvalidIncludesLinearFields,
  InvalidRegionDistribution,
  InvalidProgramDistribution,
  InvalidCreativeSegments,
  InvalidCreatives,
  InvalidTargetingForGamblingCustomer,
  InvalidCreativeLengthQuota,
  InvalidTargetingCombination,
}

interface CreativeDistributions {
  [creativeId: string]: Partial<Record<'budget' | 'quota', number>>;
}
