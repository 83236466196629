import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WooUiEventService {
  private eventsSubject: Subject<WooUiEvent> = new Subject();
  readonly events$ = this.eventsSubject.asObservable();

  stopLoadDotAnimation = (): void => this.emitEvent({ type: UiEventType.StopLoadDotLoader });
  private emitEvent = (event: WooUiEvent): void => this.eventsSubject.next(event);
}

export enum UiEventType {
  StopLoadDotLoader,
}

export type StopLoadDotLoaderEvent = {
  type: UiEventType.StopLoadDotLoader;
};

export type WooUiEvent = StopLoadDotLoaderEvent;
