import { Component, Input, OnChanges } from '@angular/core';
import { SubscriptionHandler } from '../../advanced_campaign_booking.module/components/subscription-handler';
import { formatWooDate, isDateBefore, mergeAdjacentSegments, sameDate } from '../../utils/date';
import { STANDARD_FORMATS } from '../../utils/format-constants';
import { Hidden, SimpleChanges, Visible } from '../../utils/types';
import { AuthService } from '../../woo_services.module/AuthService';
import { CreativeData, CreativeService } from '../../woo_services.module/CreativeService';
import { DashboardModel, DashboardStore } from '../../woo_services.module/DashboardStore';
import { ProductFormatService } from '../../woo_services.module/ProductFormatService';
import {
  AdServerStatus,
  Creative,
  CreativeStatus,
  ImageCreativeType,
  ProductFormatConditions,
  Segment,
  VideoCreativeType,
  wooId,
} from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { InstreamCreativeFormLayoutProfile } from '../instream_creative_form/instream-creative-form.component';
import { PauseAdCreativeFormLayoutProfile } from '../pause_creative_form/pause-creative-form.component';

@Component({
  selector: 'creative-details',
  templateUrl: './creative-details.component.html',
  styleUrls: ['./creative-details.component.scss'],
})
export class CreativeDetails extends SubscriptionHandler implements OnChanges {
  readonly convertCreativeTypes = this.translationService.convertCreativeTypes;
  readonly convertCreativeStatus = this.translationService.convertCreativeStatus;
  readonly PERCENT_FORMAT = STANDARD_FORMATS.percent;
  readonly ImageCreativeType = ImageCreativeType;
  readonly instreamCreativeFormProfile: InstreamCreativeFormLayoutProfile = {
    heading: Hidden,
    length: Visible,
    actionButtons: Hidden,
    agAffectInventoryInfo: Hidden,
    agDisabledInfo: Visible,
  };
  readonly pauseCreativeFormProfile: PauseAdCreativeFormLayoutProfile = {
    actionButtons: Hidden,
    heading: Hidden,
  };
  readonly adminOrPlanner = this.authService.adminOrPlanner();

  @Input() creativeId: wooId;
  @Input() targetingId: wooId;

  creative: Creative;
  creativeIndex: number;
  customerId: string;
  editCreatives: boolean;
  mergedSegments: Segment[] = [];
  ongoingCreative: boolean;
  disableAG: boolean;
  productFormatConditions: ProductFormatConditions = { onlyShortFormat: false };
  campaginId: wooId;
  sentToAdserverAndCreativeStatusReady: boolean;
  get isVideo(): boolean {
    return this.creativeService.isVideo(this.creative);
  }
  get isImage(): boolean {
    return this.creativeService.isImage(this.creative);
  }

  constructor(
    private creativeService: CreativeService,
    private translationService: TranslationService,
    private dashboardStore: DashboardStore,
    private productFormatService: ProductFormatService,
    private authService: AuthService,
  ) {
    super();
    this.addSubscription(this.dashboardStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(changes: SimpleChanges<CreativeDetails>): void {
    if (changes.targetingId || changes.creativeId) {
      this.initFromStore(this.dashboardStore.state);
    }
  }

  initFromStore = (model: DashboardModel): void => {
    this.campaginId = model.activeCampaign.id;
    this.customerId = model.activeCampaign.customer_id;
    this.editCreatives = model.editCreatives;
    this.disableAG = false;

    const targeting = this.dashboardStore.state.activeCampaign.targetings.find((t) => t.id === this.targetingId);
    if (!targeting) {
      return;
    }

    this.creative = targeting.creatives.find((c) => c.id === this.creativeId);
    if (!this.creative) {
      return;
    }

    this.sentToAdserverAndCreativeStatusReady =
      model.activeCampaign.ad_server_status === AdServerStatus.sent && this.creative.status === CreativeStatus.Ready;
    this.disableAG = targeting.creatives.every((c) => c.creative_type !== VideoCreativeType.ag);
    this.mergedSegments = mergeAdjacentSegments(this.creative ? this.creative.segments : []);
    this.ongoingCreative = this.creativeHasStarted(this.creative.segments);
    this.creativeIndex = targeting.creatives.findIndex((c) => c.id === this.creativeId);
    this.productFormatConditions.onlyShortFormat = this.productFormatService.targetingIsShortform(targeting);
  };

  creativeValidityChange(isValid: boolean): void {
    this.dashboardStore.setCreativeValidity(this.targetingId, this.creative.id, isValid);
  }

  creativeChange(creativeData: CreativeData): void {
    const creativeDataWithoutDisabledValues = Object.keys(creativeData)
      .filter((key) => creativeData[key] !== undefined)
      .reduce((res, key) => ((res[key] = creativeData[key]), res), {});
    const creative = { ...this.creative, ...creativeDataWithoutDisabledValues };
    this.dashboardStore.setModifiedCreative(this.targetingId, creative);
  }

  displayName = (): string => {
    return this.creative
      ? this.creative.custom_name ||
          this.creative.video_code ||
          this.creative.video_title ||
          (this.creativeIndex >= 0 && this.isVideo && `Film ${this.creativeIndex + 1}`) ||
          (this.creativeIndex >= 0 && this.isImage && `Bild ${this.creativeIndex + 1}`) ||
          this.translationService.convertCreativeTypes(this.creative.creative_type)
      : 'Material';
  };

  getMergedSegmentDates = (): string[] =>
    this.mergedSegments.map(
      (segment) => `${formatWooDate(new Date(segment.start_date))} - ${formatWooDate(new Date(segment.end_date))}`,
    );

  private creativeHasStarted = (segments: Segment[]): boolean => {
    const creativeStartDate = segments[0].start_date;
    const todayDate = new Date();
    return isDateBefore(creativeStartDate, todayDate) || sameDate(creativeStartDate, todayDate);
  };
}
