import { wooId } from '../woo_services.module/shared-types';

export function compareWith(property: any) {
  return (left: unknown, right: unknown): any => {
    const l = left[property] instanceof Date ? formatDate(left[property]) : left[property];
    const r = right[property] instanceof Date ? formatDate(right[property]) : right[property];
    if (!l || !r || l === r) {
      return 0;
    }
    return l.localeCompare(r);
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function compact(obj: unknown): {} {
  return Object.entries(obj).reduce((current, [key, value]) => {
    if (value !== undefined) {
      current[key] = value;
    }
    return current;
  }, {});
}

export function allValuesFalse(obj: unknown): boolean {
  const isFalsy = (value) => !Boolean(value);
  return Object.values(obj).every(isFalsy);
}

/**
 * Returns a new objects with only the specified keys
 */
export function slice<T extends { [key: string]: any }>(obj: T, ...keys: Array<keyof T>): any {
  return Object.entries(obj)
    .filter(([key, _]) => keys.includes(key))
    .reduce((result, [key, value]) => Object.assign(result, { [key]: value }), {} as T);
}

/**
 * Returns a new objects without the specified keys
 */
export function reject<T extends { [key: string]: any }>(obj: T, ...keys: Array<keyof T>): any {
  return Object.entries(obj)
    .filter(([key, _]) => !keys.includes(key))
    .reduce((result, [key, value]) => Object.assign(result, { [key]: value }), {} as T);
}

export function updateAttributesFromObj(obj1: any, obj2: any): any {
  Object.keys(obj2)
    .filter((key) => key in obj1)
    .forEach((key) => (obj1[key] = obj2[key]));
  return obj1;
}

export function trackById(_: any, item: { id: wooId }): wooId {
  return item.id;
}

export function trackByIndex(index: number, _: any): number {
  return index;
}

export function nullOrEmpty(obj: string | string[] | null): boolean {
  return Array.isArray(obj)
    ? obj.length === 0 || obj.every((value) => value === null || value === '')
    : obj === '' || obj === null;
}

export function formatDate(date: Date): string {
  // Located here to resolve circular dependency issues.
  if (!date) {
    return null;
  }
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

export function objectDigger(object: any, keys: string[]): any {
  const key = keys.shift();
  if (!key) return object?.hasOwnProperty('$numberDecimal') ? object['$numberDecimal'] : object;
  if (object?.hasOwnProperty(key)) return objectDigger(object[key], keys);
  return null;
}
