import { Component, OnInit } from '@angular/core';
import { saveFileAs } from '../../../utils/files';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { DialogService } from '../../../woo_services.module/DialogService';
import {
  FilterOptionKeys,
  InventoryFilterOption,
  InventoryFilterValue,
  InventoryService,
  InventoryWeeks,
} from '../../../woo_services.module/InventoryService';
import { ViewCurrency } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
@Component({
  selector: 'inventory-view',
  templateUrl: './inventory-view.component.html',
})
export class InventoryView implements OnInit {
  timeStamp;
  weeks: InventoryWeeks;
  fetchingFilters = true;
  filterOptions: InventoryFilterOption[] = [];
  fetchingData = false;
  filters = {} as { [name: string]: InventoryFilterValue[] };
  inventoryTree = undefined;
  breadcrumb = '';
  columnNamesInventoryCurrency = {
    estimated: 'Estimerat',
    available: 'Tillgängligt',
    withdrawal_limit: 'Maxuttag',
    max_bookable_in_leaf_nodes: 'MeMa Bokningsbart',
  };
  columnNamesBusinessCurrency = {
    estimated_business_currency: 'Estimerat',
    available_business_currency: 'Tillgängligt',
    withdrawal_limit_business_currency: 'Maxuttag',
    max_bookable_in_leaf_nodes_business_currency: 'MeMa Bokningsbart',
  };
  columnNames: any = this.columnNamesBusinessCurrency;
  presentationCurrency: ViewCurrency = ViewCurrency.grossRatingViews;
  businessViewCurrency: ViewCurrency = ViewCurrency.grossRatingViews;

  showOnlyImps = false;
  readonly viewCurrencyLabel = this.translationService.translateViewCurrency;

  constructor(
    private inventoryService: InventoryService,
    private authService: AuthService,
    private dialogService: DialogService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.getFilterOptions();
  }

  weeksChanged = (weeks: InventoryWeeks): void => {
    this.weeks = weeks;
  };

  filter(): void {
    this.fetchingData = true;
    const weekParams = this.inventoryService.buildWeekParams(this.weeks);
    const filterParams = this.inventoryService.buildFilterParams(
      this.filters,
      weekParams,
      this.filterOptions,
    ) as InventoryWeeks;
    this.inventoryService.filter(filterParams).then(
      (inventoryTree) => {
        this.timeStamp = new Date();
        this.inventoryTree = inventoryTree;
        this.breadcrumb = this.inventoryService.getBreadcrumbFromCurrentFilters(this.filters);
        this.fetchingData = false;
        this.setViewCurrency();
      },
      () => {
        this.fetchingData = false;
      },
    );
  }

  export(): void {
    const progressCallback = this.dialogService.openBlockingWithProgress('Exporterar lager');
    this.inventoryService
      .export(this.inventoryService.buildWeekParams(this.weeks), progressCallback)
      .then((response) => {
        this.dialogService.closeBlocking();
        saveFileAs(response.blob, response.name);
      })
      .catch(() => {
        this.dialogService.closeBlocking();
        this.dialogService.openError('Det gick inte att exportera lagret.');
      });
  }

  clearFilters(): void {
    this.inventoryService.clear$.next();
  }

  setViewCurrency(): void {
    this.showOnlyImps = this.showOnlyImpressions;
    this.businessViewCurrency = this.showOnlyImps ? ViewCurrency.impressions : ViewCurrency.grossRatingViews;
    this.presentationCurrency = this.showOnlyImps ? ViewCurrency.impressions : ViewCurrency.grossRatingViews;
    this.setColumnNames();
  }

  get showOnlyImpressions(): boolean {
    return this.isPauseAd || this.isShortForm;
  }

  get isShortForm(): boolean {
    return this.filters.product_formats?.length === 1 && this.filters.product_formats[0]?.key === 'shortform';
  }

  get isPauseAd(): boolean {
    return this.filters.type?.some((pf) => pf.key === 'pause_ad');
  }

  get showViewCurrencyToggle(): boolean {
    return !this.showOnlyImps;
  }

  toggleViewCurrency(): void {
    this.togglePresentationViewCurrency();
    this.setColumnNames();
  }

  get toggleViewCurrencyLabel(): string {
    return `Visa som ${this.presentationCurrency === ViewCurrency.impressions ? 'Bruttokontakter' : 'Impressions'}`;
  }

  togglePresentationViewCurrency(): void {
    this.presentationCurrency =
      this.presentationCurrency === ViewCurrency.impressions ? ViewCurrency.grossRatingViews : ViewCurrency.impressions;
  }

  setColumnNames(): void {
    this.columnNames =
      this.presentationCurrency === ViewCurrency.grossRatingViews
        ? this.columnNamesBusinessCurrency
        : this.columnNamesInventoryCurrency;
  }

  authorizedForExport(): boolean {
    return this.authService.hasAnyRole([UserRole.admin, UserRole.planner, UserRole.accounting, UserRole.salesManager]);
  }

  warnForWithdrawalLimit(row: Record<string, number>, column: string): string {
    if (row.withdrawal_limit > row.available && column === 'withdrawal_limit') {
      return 'Maxuttaget är större än Tillgängligt';
    }
    return '';
  }

  private getFilterOptions() {
    this.inventoryService.getFilterOptions().then((options) => {
      this.filterOptions = this.inventoryService.pushRadioOptionsToLast(options);
      this.sortFilterOptions(this.filterOptions);
      this.fetchingFilters = false;
    });
  }

  private sortFilterOptions(filterOptions: InventoryFilterOption[]) {
    filterOptions.map((options: InventoryFilterOption) => {
      if (options.filter_key === FilterOptionKeys.FilmLength) {
        options.values = options.values.sort((a, b) => (parseInt(a.key) > parseInt(b.key) ? 1 : -1));
      } else if (options.filter_key === FilterOptionKeys.Type) {
        options.values = options.values.sort((a, b) => b.name.localeCompare(a.name, 'sv'));
      } else {
        options.values = options.values.sort((a, b) => a.name.localeCompare(b.name, 'sv'));
      }
    });
  }
}
