import {
  AgreementPart,
  Campaign,
  CampaignEstimationPart,
  ExtendedTargetingBudget,
  Targeting,
} from '../../woo_services.module/shared-types';
import { sum } from '../array';

export const targetingTotalBudgetWithCampaign = (campaign: Campaign, targeting: Targeting): number => {
  const targetingIndex = campaign.targetings.findIndex((t) => t.id === targeting.id);
  const part = targetingIndex < 0 ? null : campaign.agreement.parts[targetingIndex];
  return targetingTotalBudgetWithAgreementPart(targeting, part);
};

export const targetingTotalBudgetWithAgreementPart = (
  targeting: Targeting,
  part?: AgreementPart | CampaignEstimationPart,
): number => {
  return (
    targetingNetBudgetWithAgreementPart(targeting, part) + targetingAdditionalBudgetWithAgreementPart(targeting, part)
  );
};

export const targetingNetBudgetWithAgreementPart = (
  targeting: Targeting,
  part?: AgreementPart | CampaignEstimationPart,
): number => {
  if (part) {
    return (part.net_budget || 0) + allExtendedNetBudgets(targeting);
  }

  return (
    (targeting.calculate_from_budget && targeting.budget ? targeting.budget : 0) + allExtendedNetBudgets(targeting)
  );
};

export const targetingTotalViewsWithAgreementPart = (
  targeting: Targeting,
  part?: AgreementPart | CampaignEstimationPart,
): number => {
  return (
    targetingNetBudgetViewsWithAgreementPart(targeting, part) +
    targetingAdditionalViewsWithAgreementPart(targeting, part)
  );
};

export const targetingNetBudgetViewsWithAgreementPart = (
  targeting: Targeting,
  part?: AgreementPart | CampaignEstimationPart,
): number => {
  if (part) {
    return (part.net_views || 0) + targetingAllExtendedNetBudgetViews(targeting);
  }

  return (
    (!targeting.calculate_from_budget && targeting.budgeted_impressions ? targeting.budgeted_impressions : 0) +
    targetingAllExtendedNetBudgetViews(targeting)
  );
};

export const targetingAdditionalBudgetWithAgreementPart = (
  targeting: Targeting,
  part?: AgreementPart | CampaignEstimationPart,
): number => {
  if (part) {
    return (part.additional_net_budget || 0) + allExtendedAdditionalBudgets(targeting);
  }

  return (
    (targeting.calculate_from_budget && targeting.additional_budget ? targeting.additional_budget : 0) +
    allExtendedAdditionalBudgets(targeting)
  );
};

export const targetingAdditionalViewsWithAgreementPart = (
  targeting: Targeting,
  part?: AgreementPart | CampaignEstimationPart,
): number => {
  if (part) {
    return (part.additional_net_views || 0) + allExtendedAdditionalViews(targeting);
  }

  return (
    (!targeting.calculate_from_budget && targeting.additional_budgeted_impressions
      ? targeting.additional_budgeted_impressions
      : 0) + allExtendedAdditionalViews(targeting)
  );
};

export const targetingAllExtendedBudgetTotal = (targeting: Targeting): number => {
  return sum(
    targeting.extended_budgets?.map(
      (eb: ExtendedTargetingBudget) => (eb.budget || 0) + (eb.additional_budget || 0),
    ) || [0],
  );
};

const allExtendedNetBudgets = (targeting: Targeting): number => {
  return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.budget) || [0]);
};

const allExtendedAdditionalBudgets = (targeting: Targeting): number => {
  return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_budget) || [0]);
};

export const targetingAllExtendedNetBudgetViews = (targeting: Targeting): number => {
  return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.impressions) || [0]);
};

const allExtendedAdditionalViews = (targeting: Targeting): number => {
  return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_impressions) || [0]);
};
