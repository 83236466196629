import { Component, Input } from '@angular/core';
import { STANDARD_FORMATS } from '../../utils/format-constants';

const template = `
  <div class="wrapper display--inline-block">
    <span class="icon-calendar"></span>
    <span *ngIf="start || end">
      <span class="margin-left--1x">{{start | date: dateFormat}}</span> -
       <span>{{end | date: dateFormat}}</span>
    </span>
  </div>
`;

@Component({
  selector: 'date-span',
  template: template,
  styleUrls: ['./date-span.component.scss'],
})
export class DateSpan {
  @Input() start: string | Date;
  @Input() end: string | Date;
  dateFormat: string = STANDARD_FORMATS.date;
}
