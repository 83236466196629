<table class="table table-hover" *ngIf="visibleContracts.length">
  <thead>
    <tr>
      <th>Rabatter</th>
      <th>Rabattsats</th>
      <th>Startdatum</th>
      <th>Slutdatum</th>
      <th colspan="2">Ägare</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let contract of visibleContracts">
      <ng-container *ngFor="let ycpDiscount of contract.discounts">
        <tr
          class="pointer"
          [ngClass]="{'inactive': contract.ended}"
          (click)="toggleExpanded(ycpDiscount.id)">
          <td>
            <div class="tab tab--small">
              <ul class="reset--list">
                <li *ngFor="let discount_type of ycpDiscount.discount_types">
                  {{convert(discount_type)}}
                </li>
              </ul>
            </div>
          </td>
          <td>
            {{ycpDiscount.yclis?.length ? '' : ycpDiscount.discount_rate | percent: '1.0-2'}}
          </td>
          <td>
            {{ycpDiscount.start_date | date:'yyyy-MM-dd'}}
          </td>
          <td>
            {{ycpDiscount.end_date | date:'yyyy-MM-dd'}}
          </td>
          <td>
            {{contract.owner_name || 'Finns ej i WOO Manager'}}
          </td>
          <td>
            <button *ngIf="ycpDiscount.yclis?.length" class="toggle-button">
              <span class="chevron small text--info" [ngClass]="{'bottom': expandedDiscounts[ycpDiscount.id]}"></span>
            </button>
          </td>
        </tr>
        <tr
          *ngFor="let ycliDiscount of ycpDiscount.yclis"
          [ngClass]="{'inactive': contract.ended, 'text--strong': applicableYclis[ycliDiscount.id]}" >
          <td class="hidden-cell">
            <div
            *ngIf="expandedDiscounts[ycpDiscount.id]"
            [ngClass]="{'expanded': expandedDiscounts[ycpDiscount.id]}"
            [@verticalExpansion]>
            <!-- empty cell to align columns -->
          </div>
          </td>
          <td class="hidden-cell">
            <div
              *ngIf="expandedDiscounts[ycpDiscount.id]"
              [ngClass]="{'expanded': expandedDiscounts[ycpDiscount.id]}"
              [@verticalExpansion]>
            {{ycliDiscount.discount_rate | percent: '1.0-2'}}
            </div>
          </td>
          <td class="hidden-cell">
            <div
              *ngIf="expandedDiscounts[ycpDiscount.id]"
              [ngClass]="{'expanded': expandedDiscounts[ycpDiscount.id]}"
              [@verticalExpansion]>
            {{ycliDiscount.start_date | date:'yyyy-MM-dd'}}
            </div>
          </td>
          <td class="hidden-cell">
            <div
              *ngIf="expandedDiscounts[ycpDiscount.id]"
              [ngClass]="{'expanded': expandedDiscounts[ycpDiscount.id]}"
              [@verticalExpansion]>
            {{ycliDiscount.end_date | date:'yyyy-MM-dd'}}
            </div>
          </td>
          <td class="hidden-cell">
            <div
              *ngIf="expandedDiscounts[ycpDiscount.id]"
              [ngClass]="{'expanded': expandedDiscounts[ycpDiscount.id]}"
              [@verticalExpansion]></div>
          </td>
          <td
            *ngIf="expandedDiscounts[ycpDiscount.id]"
            class="hidden-cell"
            [ngClass]="{'expanded': expandedDiscounts[ycpDiscount.id]}"
          ></td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<p class="text--italic" *ngIf="!visibleContracts.length">Det finns inga upplagda</p>
