import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'region-map',
  templateUrl: './region-map.component.html',
  styleUrls: ['./region-map.component.scss'],
})
export class RegionMap implements OnChanges {
  @Input() selectedRegionNames: any;
  @Input() ShowRegionGroups = false;
  @Input() regionToGroupMap = {};
  @Output() selectedRegionChange = new EventEmitter<any>();
  groupMap = {};

  @ViewChild('svgSupportElement', { static: true }) svgSupportElement: ElementRef;

  sufficientSVGSupport = false;
  selectedRegions = {};
  regionsMap = {
    skelleftea: 'Skellefteå',
    halsingland: 'Hälsingland',
    gavle: 'Gävle',
    vasteras: 'Västerås',
    norrkoping: 'Norrköping',
    orebro: 'Örebro',
    linkoping: 'Linköping',
    vast: 'Väst',
    goteborg: 'Göteborg',
    boras: 'Borås',
    varmland: 'Värmland',
    skaraborg: 'Skaraborg',
    kalmar: 'Kalmar',
    halland: 'Halland',
    blekinge: 'Blekinge',
    skane: 'Skåne',
    stockholm: 'Stockholm + Gotland',
    uppsala: 'Uppsala',
    kronoberg: 'Kronoberg',
    norrbotten: 'Norrbotten',
    umea: 'Umeå',
    sundsvall: 'Sundsvall',
    jonkoping: 'Jönköping',
    ornskoldsvik: 'Örnsköldsvik',
    dalarna: 'Dalarna',
    jamtland: 'Jämtland',
  };

  ngOnChanges(): void {
    if (this.svgSupportElement.nativeElement.classList) {
      this.sufficientSVGSupport = true;
    }
    this.createGroupMap();
  }

  createGroupMap(): void {
    Object.keys(this.regionsMap).forEach((key) => {
      this.groupMap[key] = this.regionToGroupMap[this.regionsMap[key]];
    });
  }

  click(event: { target: { id: string | number } }): void {
    if (this.ShowRegionGroups) {
      this.selectedRegionNames[this.groupMap[event.target.id]] = !this.selectedRegionNames[
        this.groupMap[event.target.id]
      ];
    } else {
      this.selectedRegionNames[this.regionsMap[event.target.id]] = !this.selectedRegionNames[
        this.regionsMap[event.target.id]
      ];
    }
    this.selectedRegionChange.emit(this.selectedRegionNames);
  }

  isSelected(element: string): string {
    if (this.ShowRegionGroups) {
      return this.selectedRegionNames[this.groupMap[element]];
    }
    return this.selectedRegionNames[this.regionsMap[element]];
  }
}
