<h1 class="margin-top--2x">Granskar kampanj</h1>

<div class="account__card">
  <video controls *ngIf="assetUrl" class="width--50 border-radius margin-top-bottom--1x" [src]="assetUrl"></video>
  <div class="margin-bottom--2x">
    <a [href]="assetUrl" target="_blank" download><span class="icon-download"></span> Ladda ner</a>
    <p class="margin-top--1x"><strong>Video-url: </strong>{{assetUrl}}</p>
  </div>
  <form #reviewForm="ngForm" (ngSubmit)="submitForm()">
    <h4>Är filmen godkänd?</h4>
    <div>
      <input id="approveCreative" type="radio" class="radio" name="approving-creative" value="approved" [(ngModel)]="reviewStatus">
      <label class="radio" for="approveCreative">Ja</label>
    </div>
    <div>
      <input id="dismissCreative" type="radio" class="radio" name="approving-creative" value="rejected" [(ngModel)]="reviewStatus">
      <label class="radio" for="dismissCreative">Nej</label>
    </div>
    <div *ngIf="reviewForm.dirty">
      <div class="divider block"></div>
      <div *ngIf="reviewStatus === 'approved'" class="width--50">
        <h4>Godkänn film</h4>
        <cash-video-code-input [(model)]="firstCreative.video_code" label="Filmkod (genererad från MMS)"></cash-video-code-input>
      </div>
      <div *ngIf="reviewStatus === 'rejected'" class="width--50">
        <h4>Neka film</h4>
        <div class="input__container">
          <label for="reject-desc">Anledning</label>
          <textarea name="rejectMessage" id="reject-desc" [(ngModel)]="rejectMessage" rows="3" required></textarea>
        </div>
      </div>
      <button class="button primary" [disabled]="!reviewForm.valid">Skicka</button>
    </div>

  </form>

</div>
