import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../woo_services.module/AuthService';
import { RoutingService } from '../../woo_services.module/RoutingService';

@Injectable({ providedIn: 'root' })
class LandingGuardService {
  constructor(private authService: AuthService, private routingService: RoutingService) {}
  canActivate(): boolean {
    if (this.authService.isAuthenticated() && this.authService.allowedToSeeDashboard()) {
      this.routingService.goToDashboard();
      return false;
    }
    return true;
  }
}

export const LandingGuard: CanActivateFn = (): boolean => {
  return inject(LandingGuardService).canActivate();
};
