import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { isEqual } from 'lodash-es';
import { of, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { campaignTotalBudget } from '../../../utils/budget_helpers/campaign_budget_helper';
import { formatWooDate, getMaxPeriod, sameDate } from '../../../utils/date';
import { saveFileAs } from '../../../utils/files';
import { AuthService } from '../../../woo_services.module/AuthService';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { DialogService } from '../../../woo_services.module/DialogService';
import { GoogleTagManagerService } from '../../../woo_services.module/GoogleTagManagerService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import {
  AgreementPart,
  Campaign,
  CampaignStatus,
  DashboardTab,
  DatePeriod,
  Targeting,
  TargetingMetaData,
} from '../../../woo_services.module/shared-types';
import { TargetingService } from '../../../woo_services.module/TargetingService';

const defaultShowValues: {
  button: SidebarButtonSettings;
  element: SidebarElementSettings;
  campaign: boolean;
  loadingSpinner: boolean;
} = {
  button: {
    collapse: false,
    showStats: false,
    download: false,
  },
  element: {
    campaignSummaryNewOrderValue: false,
    campaignSummaryOrderValue: false,
    campaignSummaryOrderRef: false,
    statusComments: false,
    viewCurrencyBlock: true,
  },
  campaign: false,
  loadingSpinner: false,
};

const defaultDisableValues: {
  button: SidebarButtonSettings;
} = {
  button: {
    collapse: false,
    showStats: false,
    download: false,
  },
};

@Component({
  selector: 'dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.scss'],
})
export class DashboardSidebar extends SubscriptionHandler implements OnChanges {
  readonly adminOrPlanner = this.authService.adminOrPlanner;

  @Input() editOngoingCampaign = false;
  @Input() campaign: Campaign;

  activeCampaign: Campaign | null;
  campaignSummary: CampaignSummary | null;

  campaignPeriod: DatePeriod = { start: null, end: null };
  campaignStartsToday = false;
  dashboardIsExtended: boolean;
  hasBeenSentToAdServer = false;
  isFollowup = false;
  needsAttention = false;
  statusComments: StatusComment[] = [];
  targetingBoxData: TargetingBoxData[] = [];

  show: {
    button: SidebarButtonSettings;
    element: SidebarElementSettings;
    campaign: boolean;
    loadingSpinner: boolean;
  } = { ...defaultShowValues };

  disable: {
    button: SidebarButtonSettings;
  } = { ...defaultDisableValues };

  loading: Record<string, boolean> = {
    campaign: false,
    campaigns: false,
  };

  constructor(
    private authService: AuthService,
    private campaignService: CampaignService,
    private dashboardStore: DashboardStore,
    private dialogService: DialogService,
    private googleTagManagerService: GoogleTagManagerService,
    private routingService: RoutingService,
    private targetingService: TargetingService,
  ) {
    super();

    this.addSubscription(
      dashboardStore.state$
        .pipe(
          debounce((ev) => (this.isLoading(ev.isLoading) ? timer(200) : of(ev))),
          distinctUntilChanged((prev: DashboardModel, curr: DashboardModel) => prev.isLoading === curr.isLoading),
        )
        .subscribe(this.initLoadingFromStore),
    );

    this.addSubscription(
      dashboardStore.state$
        .pipe(
          distinctUntilChanged((prev: DashboardModel, curr: DashboardModel) =>
            isEqual(prev.activeCampaign, curr.activeCampaign),
          ),
        )
        .subscribe(this.setActiveCampaignFromStore),
    );

    this.addSubscription(
      dashboardStore.state$
        .pipe(
          distinctUntilChanged(
            (prev: DashboardModel, curr: DashboardModel) => prev.dashboardIsExtended === curr.dashboardIsExtended,
          ),
        )
        .subscribe(this.initDashboardIsExtendedFromStore),
    );
  }

  ngOnChanges(): void {
    if (this.editOngoingCampaign) {
      this.setPropsFromCampaign(this.campaign);
    }
  }

  setActiveCampaignFromStore = (model: DashboardModel): void => {
    const campaign = model.activeCampaign;
    this.setPropsFromCampaign(campaign);
  };

  setPropsFromCampaign(campaign: Campaign): void {
    if (campaign && campaign.targetings) {
      const activeProps = this.getPropertiesFromCampaign(campaign);
      this.resetShow();
      this.resetDisable();
      this.hasBeenSentToAdServer = activeProps.hasBeenSentToAdServer;
      this.campaignStartsToday = activeProps.startsToday;
      this.isFollowup = activeProps.isFollowup;
      this.needsAttention = activeProps.needsAttention;
      this.activeCampaign = campaign;
      this.campaignPeriod = activeProps.maxPeriod;
      this.campaignStartsToday = activeProps.startsToday;
      this.campaignSummary = activeProps.summary;
      this.statusComments = activeProps.statusComments;
      this.targetingBoxData = activeProps.targetingBoxData;
      this.show.button.collapse = this.dashboardIsExtended;
      this.show.button.showStats = this.isFollowup && !this.dashboardIsExtended;
      this.show.button.download = this.activeCampaign.external_id ? true : false;
      this.show.element = this.getActiveCampaignElementsSettings();
      this.show.campaign = true;
    }
  }

  initLoadingFromStore = (model: DashboardModel): void => {
    this.loading = model.isLoading;
    this.show.loadingSpinner = this.isLoading(model.isLoading);
  };

  initDashboardIsExtendedFromStore = (model: DashboardModel): void => {
    this.dashboardIsExtended = model.dashboardIsExtended;
    this.show.button.collapse = this.dashboardIsExtended;
    this.show.button.showStats = this.isFollowup && !this.dashboardIsExtended;
  };

  isLoading = (loading: Record<string, boolean>): boolean => {
    return Object.keys(loading).some((key) => {
      return loading[key];
    });
  };

  onClickCollapse = (): void => {
    this.googleTagManagerService.dashboardCollapsedWithSidebarCollapseButton();
    this.routingService.goToDashboardTab(this.activeCampaign.id, null);
    //this.collapseDashboard();
  };
  onClickStats = (): void => {
    if (this.activeCampaign) {
      this.goToStatsTab();
    }
  };
  onClickDownload = (): void => {
    this.downloadBookingConfirmation(this.activeCampaign);
  };

  private getPropertiesFromCampaign = (campaign: Campaign) => {
    if (!campaign.targetings) {
      throw 'Cannot calculate properties from an incomplete campaign: targetings is empty.';
    }

    const hasBeenSentToAdServer: boolean = campaign.external_id ? true : false;
    const startsToday: boolean = sameDate(this.campaignPeriod.start, new Date());
    const isFollowup: boolean = this.campaignService.hasAnyStatus(campaign, [
      CampaignStatus.ended,
      CampaignStatus.ongoing,
      CampaignStatus.closed,
      CampaignStatus.stopped,
    ]);

    const maxPeriod: DatePeriod = getMaxPeriod(campaign.targetings);
    const summary: CampaignSummary = this.getCampaignSummary(campaign);
    const statusComments: StatusComment[] = this.getStatusComments(campaign);
    const targetingBoxData: TargetingBoxData[] = this.getTargetingBoxData(campaign);

    let needsAttention = false;

    if (this.authService.adminOrPlanner()) {
      needsAttention = this.dashboardStore.state.campaignsWithAlerts.includes(campaign.id);
    }

    return {
      hasBeenSentToAdServer,
      startsToday,
      isFollowup,
      needsAttention,
      maxPeriod,
      summary,
      statusComments,
      targetingBoxData,
    };
  };

  private getActiveCampaignElementsSettings = (): SidebarElementSettings => {
    return {
      campaignSummaryNewOrderValue:
        this.authService.adminPlannerOrAccounting() &&
        this.campaignService.hasAnyStatus(this.activeCampaign, [CampaignStatus.stopped]),
      campaignSummaryOrderValue:
        this.authService.adminPlannerOrAccounting() &&
        !this.campaignService.hasAnyStatus(this.activeCampaign, [CampaignStatus.unbooked]),
      campaignSummaryOrderRef: this.authService.adminPlannerOrAccounting(),
      statusComments: this.statusComments ? true : false,
      viewCurrencyBlock: this.activeCampaign.agreement ? true : false,
    };
  };

  private getCampaignSummary = (campaign: Campaign): CampaignSummary => {
    return {
      totalBudget: campaignTotalBudget(campaign),
      orderValue: campaign.order_value,
      newOrderValue: campaign.new_order_value,
      referenceNumber: campaign.reference_number,
      clientInvoiceReference: campaign.client_invoice_reference,
      orderRef: campaign.agresso_invoice_token,
      updatedDate: campaign.updated_at && formatWooDate(new Date(campaign.updated_at)),
      createdDate: campaign.created_at && formatWooDate(new Date(campaign.created_at)),
      useUpdatedDate: new Date(campaign.created_at) < new Date(campaign.updated_at),
      bookedDate: campaign.booked_date && formatWooDate(new Date(campaign.booked_date)),
      bookedBy: campaign.booked_by,
    };
  };

  private getStatusComments = (campaign: Campaign): StatusComment[] => {
    const statusComments: StatusComment[] = [];
    if (campaign.closed_message) {
      statusComments.push({
        title: 'Kommentar för stängning',
        text: campaign.closed_message,
      });
    }
    if (campaign.status_comment) {
      statusComments.push({
        title: 'Statusmeddelande',
        text: campaign.status_comment,
      });
    }
    if (campaign.reject_message) {
      statusComments.push({
        title: 'Granskningsmeddelande',
        text: campaign.reject_message,
      });
    }
    return statusComments;
  };

  private getTargetingBoxData = (campaign: Campaign): TargetingBoxData[] => {
    return campaign.targetings.map((t, idx) => ({
      targeting: t,
      metaData: this.targetingService.getMetaData(t),
      part: campaign.agreement ? campaign.agreement.parts[idx] : null,
    }));
  };

  private goToStatsTab = () => {
    this.routingService.goToDashboardTab(this.activeCampaign.id, DashboardTab.stats);
  };

  private collapseDashboard = () => {
    if (this.dashboardIsExtended) {
      this.dashboardStore.setDashboardIsExtended(false);
    }
  };

  private downloadBookingConfirmation = (campaign: Campaign) => {
    this.dialogService.openBlocking('Skapar fil');
    this.campaignService.exportBookingConfirmation(campaign).then(
      (res) => {
        this.dialogService.closeBlocking();
        saveFileAs(res, `Bokningsbekräftelse - ${campaign.name}.pdf`);
      },
      (reason) => {
        if (!(reason instanceof HttpErrorResponse)) {
          const msg = 'Ajdå, något gick snett. Det gick inte att skapa din bokningsbekräftelse.';
          this.dialogService.openError(msg);
        }
      },
    );
  };

  private resetShow = () => (this.show = { ...defaultShowValues, loadingSpinner: this.show.loadingSpinner });

  private resetDisable = () => (this.disable = { ...defaultDisableValues });
}

interface CampaignSummary {
  totalBudget: number;
  orderValue: number;
  newOrderValue: number;
  referenceNumber: string;
  clientInvoiceReference: string;
  orderRef: string;
  createdDate: string;
  updatedDate: string;
  useUpdatedDate: boolean;
  bookedDate: string;
  bookedBy: string;
}

interface SidebarButtonSettings {
  collapse: boolean;
  showStats: boolean;
  download: boolean;
}

interface SidebarElementSettings {
  campaignSummaryNewOrderValue: boolean;
  campaignSummaryOrderValue: boolean;
  campaignSummaryOrderRef: boolean;
  statusComments: boolean;
  viewCurrencyBlock: boolean;
}

interface StatusComment {
  title: string;
  text: string;
}

interface TargetingBoxData {
  targeting: Targeting;
  metaData: TargetingMetaData;
  part: AgreementPart;
}
