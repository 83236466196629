<div class="flex__container">
  <div class="publisher__item" (click)="publisherChoice = PublisherChoices.tv4; notifyChange()">
    <input class="radio" id="tv4-publisher-id" type="radio" [(ngModel)]="publisherChoice" [value]="PublisherChoices.tv4">
    <label class="radio" for="tv4-publisher-id">TV4</label>
    <div class="margin-left--4x">
      <p class="thin small">Kampanjen visas på TV4:s digitala plattformar.</p>
      <div class="flex__container margin-top--3x">
        <img class="width--25 padding-right--2x" src="../../../../images/logos/tv4.svg" alt="tv4s logga test">
      </div>
    </div>
  </div>

  <div class="divider margin-top-bottom--1x visible-xs"></div>

  <div class="publisher__item" (click)="publisherChoice = PublisherChoices.bn; notifyChange()">
    <input class="radio" id="bn-publisher-id" type="radio" [(ngModel)]="publisherChoice" [value]="PublisherChoices.bn">
    <label class="radio" for="bn-publisher-id">Bonnier News</label>
    <div class="margin-left--4x">
      <p class="thin small">Inkluderar nyhetsplattformarna Expressen TV, DN TV och Di TV.</p>
      <div class="flex__container margin-top--3x">
        <img class="width--33" src="../../../../images/logos/expressen.svg" alt="expressens logga">
        <img class="width--33" src="../../../../images/logos/dn.svg" alt="dns logga">
        <img class="width--33" src="../../../../images/logos/di.svg" alt="dis logga">
      </div>
    </div>
  </div>
</div>
