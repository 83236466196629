<div
  class="dashboard__container"
  [ngClass]="{'no-scroll': dashboardExtended}"
  fixedWidth
  ref="campaign-display"
  >
  <div
    class="dashboard-area"
    [ngClass]="{'extra__row': showExtendedSearchFilters}"
  >
    <div
      class="filters clearfix show-filter inner-block small clearfix shadow-z-1"
      [ngClass]="{'extra__row': showExtendedSearchFilters}"
      >
      <div class="content clearfix">
        <div class="dashboard__container" fixedWidth ref="campaign-display">
          <div class="row margin-right--3x">
            <form
              (ngSubmit)="filterCampaigns()"
              class="display--inline"
              novalidate
            >
            <div class="col-sm-12 col-md-6 col-lg-4 padding-right--none">
              <div class="relative">
                <div class="search--box">
                  <span
                    *ngIf="search.searchText !== '' && search.searchActive"
                    (click)="resetSearchText()"
                    class="icon-close-x"
                  ></span>
                  <div class="input__container">
                    <a tabindex="-1" wooFocus="!dashboardExtended"></a>
                    <label for="searchQuery">Sök</label>
                    <input
                      id="searchQuery"
                      type="text"
                      [(ngModel)]="search.searchText"
                      name="searchText"
                      tabindex="400"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-2 padding-right--none">
              <woo-customer-dropdown
                *ngIf="customers.length"
                [items]="customers"
                [(ngModel)]="search.customer"
                name="customer-search"
                inputId="customer-search"
                labelText="Kund"
                [searchAttributes]="['name', 'nickname']"
                notFoundText="Inga kunder hittades."
                [clearable]="true"
                [labelCreate]="showCustomerNickname"
              ></woo-customer-dropdown>
            </div>
            <div class="col-xs-6 col-md-6 col-lg-3 padding-right--none">
              <button
              class="status-badge inverse"
              (click)="showExtendedSearchFilters = !showExtendedSearchFilters"
              type="button"
              title="Fler sökmöjligheter"
              tabindex="420"
              data-toggle="tooltip"
              data-placement="bottom"
              (mouseenter)="showTooltip($event.target)"
              >
                <span
                  class="icon-TV4_013_filter"
                  *ngIf="!showExtendedSearchFilters"
                ></span>
                <span
                  class="icon-close-x"
                  *ngIf="showExtendedSearchFilters"
                ></span>
              </button>

              <button
                class="status-badge inverse margin-left--half-1x"
                type="button"
                (click)="openSearchInfo()"
                title="Sökinformation"
                tabindex="440"
                data-toggle="tooltip"
                data-placement="bottom"
                (mouseenter)="showTooltip($event.target)"
                >
                <span class="icon-info-outline"></span>
              </button>
              <button class="button primary-1 min-width--auto search_button" tabindex="430">
                Sök
              </button>
            </div>
            <div class="col-xs-6 col-md-6 col-lg-3 padding--none">
              <div class="pull-right margin-left--4x">
                <button
                class="status-badge inverse active"
                (click)="setCardListing()"
                title="Ändra listvy"
                tabindex="450"
                data-toggle="tooltip"
                data-placement="bottom"
                (mouseenter)="showTooltip($event.target)"
                >
                <span *ngIf="!cardListing" class="icon-thumbnails-grid"></span>
                <span *ngIf="cardListing" class="icon-menu"></span>
              </button>
              <create-campaign-from-template-button
                class="margin-left--half-1x"
                *ngIf="allowedToBook && totalCampaigns"
                [customersForUser]="customersForUser"
                tabindex="460"
                (hover)="showTooltip($event.target)"
                ></create-campaign-from-template-button>
              <button
                *ngIf="allowedToBook"
                (click)="goToNewCampaign()"
                class="status-badge primary margin-left--half-1x"
                title="Ny kampanj"
                tabindex="470"
                id="new-campaign-btn"
                data-toggle="tooltip"
                data-placement="bottom"
                (mouseenter)="showTooltip($event.target)"
                >
                <span class="icon-plus"></span>
              </button>
              </div>
            </div>
          </form>

          </div>

          <div class="row margin-right--3x" *ngIf="showExtendedSearchFilters">
            <div class="col-xs-4 col-md-3 col-lg-2 padding-right--none">
              <div class="relative">
                <woo-dropdown
                  [items]="searchDateTypes"
                  [(ngModel)]="search.dateType"
                  bindLabel="name"
                  inputId="date-type-select"
                  labelText="Datumtyp"
                  [searchAttributes]="['name']"
                ></woo-dropdown>
              </div>
            </div>

            <div class="col-xs-4 col-md-3 col-lg-2 padding-right--none">
              <div class="input__container">
                <label for="fromDate">Startdatum</label>
                <input id="fromDate" type="date" [(ngModel)]="search.from" />
              </div>
            </div>
            <div class="col-xs-4 col-md-3 col-lg-2 padding-right--none">
              <div class="input__container">
                <label for="toDate">Slutdatum</label>
                <input id="toDate" type="date" [(ngModel)]="search.to" />
              </div>
            </div>
            <div
              class="col-xs-4 col-md-3 col-lg-2 padding-right--none"
              *ngIf="!isLoading.organisations && maySearchOrganisation"
            >
              <div class="relative">
                <woo-dropdown
                  [items]="filterAdOrganisations"
                  [(ngModel)]="search.adOrganisation"
                  bindLabel="name"
                  inputId="adorgansation-search"
                  labelText="Organisation"
                  [searchAttributes]="['name']"
                ></woo-dropdown>
              </div>
            </div>
            <div
              class="pull-left"
              *ngIf="maySeeClosedCampaigns"
            >
              <div class="relative margin-top--3x margin-left--2x">
                <input
                  id="includeClosed"
                  type="checkbox"
                  class="checkbox"
                  [(ngModel)]="search.includeClosed"
                />
                <label for="includeClosed" class="checkbox"
                  >Inkl. stängda kampanjer</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="dashboard dashboard--block"
      [ngClass]="{'list': !cardListing, 'padding-top--4x': showExtendedSearchFilters}"
    >
      <dashboard-campaigns [cardListing]="cardListing" [totalCampaigns]="totalCampaigns"></dashboard-campaigns>
    </div>
  </div>

  <div
    class="campaign-display narrow"
    [ngClass]="{'narrow': activeCampaign !== null, 'take-over': dashboardExtended }"
    >
    <div class="command-panel">
      <button
        id="openCampaign"
        class="vertical-align no-outline"
        (click)="toggleExtended()"
        *ngIf="activeCampaign !== null"
      >
        <span class="command-button circle" [ngClass]="{'rotate': dashboardExtended}"  >
          <span class="icon icon-TV4_009_bak"></span>
        </span>
      </button>
    </div>
    <div class="campaign-details relative">
      <dashboard-sidebar></dashboard-sidebar>
    </div>
    <div class="campaign-info">
      <follow-up></follow-up>
    </div>
  </div>
</div>
