<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera estimat för målgrupper</h3>
</div>

<div>
  <div class="tabbable__body">
    <import-warning *ngIf="show.estimatesHaveChanged"></import-warning>
    <form #shareOfTotalForm="ngForm" (ngSubmit)="updateShareOfTotal()">
      <div class="input__container flex__container align-items--end">
        <div>
          <label for="share">Andel av totallagret som ska vara tillgängligt för målgruppsbokningar</label>
          <input name="share" [(ngModel)]="shareOfTotal" wooPercentFormatter [wooMinValidator]="0" [wooMaxValidator]="1">
        </div>
        <button class="button margin-left--2x" type="submit" [disabled]="!shareOfTotalForm.valid">Spara</button>
      </div>
      <div class="divider margin-top-bottom--2x"></div>
      <div class="button-filter-container">
        <div>
          <button *ngIf="show.isEditingWithdrawalLimit" class="button primary" (click)="saveTargetGroups()" [disabled]="targetGroupsForm.invalid">Spara</button>
          <button *ngIf="!show.isEditingWithdrawalLimit" class="button primary" (click)="setEditingWithdrawalLimits(true)">Redigera maxuttag</button>
          <button *ngIf="show.isEditingWithdrawalLimit" class="link" (click)="cancelEditingWithdrawalLimits()">Avbryt redigering</button>
        </div>
        <div class="display--flex">
          <div class="filter-dropdown">
            <woo-dropdown
            [items]="genders"
            [(ngModel)]="selectedGender"
            name="selectedGender"
            inputId="select-gender"
            labelText="Filtrera på Kön"
            notFoundText="Inga val hittades."
            [clearable]="true"></woo-dropdown>
          </div>
          <div *ngIf="productFormats.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="productFormats"
            [(ngModel)]="selectedProductFormat"
            name="selectedProductFormat"
            inputId="select-product-format"
            labelText="Filtrera på Placering"
            [labelTransform]="translateProductFormat"
            notFoundText="Inga Placeringar hittades."
            [clearable]="true"></woo-dropdown>
          </div>
          <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="publishers"
            [(ngModel)]="selectedPublisher"
            name="selectedPublisher"
            inputId="select-publisher"
            labelText="Filtrera på Publicist"
            notFoundText="Inga publicister hittades."
            [clearable]="true"></woo-dropdown>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div #stickyHeader>
    <div class="tabbable__panel clearfix shadow-z-1">
      <h3 class="margin-left--3x">Tillgängliga målgrupper</h3>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table-woo table--info table--hover width--100" ngForm #targetGroupsForm="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-center" colspan="3">
            Maxuttag
          </th>
          <th></th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
            {{header}}
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody *ngIf="!show.loading">
        <ng-container *ngFor="let row of targetGroupRows; let index = index;">
        <tr [hidden]="!rowVisible(row)">
          <td class="text--thin vertical-align--middle">
            <span>{{getGenderName(row.genderId)}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{getAgeName(row.ageId)}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{row.tag}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{getPublisherName(row.publisherId)}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{getProductFormatName(row.productFormatId)}}</span>
          </td>
          <td>
            <span *ngIf="!show.isEditingWithdrawalLimit">{{row.withdrawalLimitsPreroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditingWithdrawalLimit"
              class="input__container">
              <input
                *ngIf="row.ageId"
                name="row-{{index}}-withdrawalLimitsPreroll"
                [(ngModel)]="estimates.withdrawal_limits[row.productFormatId][row.genderId].ages[row.ageId].withdrawal_limits['preroll']"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]="includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
              <input
                *ngIf="!row.ageId"
                name="row-{{index}}-withdrawalLimitsPreroll"
                [(ngModel)]="estimates.withdrawal_limits[row.productFormatId][row.genderId].withdrawal_limits['preroll']"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]="includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td>
            <span *ngIf="!show.isEditingWithdrawalLimit">{{row.withdrawalLimitsMidroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditingWithdrawalLimit"
              class="input__container">
              <input
                *ngIf="row.ageId"
                name="row-{{index}}-withdrawalLimitsMidroll"
                [(ngModel)]="estimates.withdrawal_limits[row.productFormatId][row.genderId].ages[row.ageId].withdrawal_limits['midroll']"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]="includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
              <input
                *ngIf="!row.ageId"
                name="row-{{index}}-withdrawalLimitsMidroll"
                [(ngModel)]="estimates.withdrawal_limits[row.productFormatId][row.genderId].withdrawal_limits['midroll']"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]="includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td>
            <span *ngIf="!show.isEditingWithdrawalLimit">{{row.withdrawalLimitsPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditingWithdrawalLimit"
              class="input__container">
              <input
                *ngIf="row.ageId"
                name="row-{{index}}-withdrawalLimitsPostroll"
                [(ngModel)]="estimates.withdrawal_limits[row.productFormatId][row.genderId].ages[row.ageId].withdrawal_limits['postroll']"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]="includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
              <input
                *ngIf="!row.ageId"
                name="row-{{index}}-withdrawalLimitsPostroll"
                [(ngModel)]="estimates.withdrawal_limits[row.productFormatId][row.genderId].withdrawal_limits['postroll']"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]="includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>
          <td class="text-center" (click)="setSelected(row)" style="cursor: pointer;">
            <span class="chevron" [ngClass]="openTab(row) ? 'bottom' : ''"></span>
          </td>
        </tr>
        <tr [ngStyle]="!openTab(row) && {'display': 'none'}">
          <td colspan="3">
            <tr>
              <h3 class=" text--semi-strong vertical-align--middle">Estimatfördelning</h3>
            </tr>
            <table>
              <thead>
                <tr>
                  <th class="text-left text--white text--thin capitalize--first-letter padding--1x" colspan="1" *ngFor="let header of tableHeadersWeeklyShares">
                    {{header}}
                  </th>
                </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let week_shares of row.weekly_shares; let innerIndex = index;">
                <tr>
                  <td class="text-left text--semi-strong col-xs-2 ">
                    <span>{{week_shares.week}}</span>
                  </td>
                  <td class="input__container override-table-input col-xs-2">
                    <span *ngIf="!isEditingWeekShares(row)">{{week_shares.sharesPreroll | percent: "1.0-3"}}</span>
                    <span *ngIf="isEditingWeekShares(row)">
                      <input
                        *ngIf="row.ageId"
                        name="row-{{index}}-shares-{{innerIndex}}-sharesPreroll-{{row.ageId}}"
                        [(ngModel)]="estimates.shares[row.productFormatId][week_shares.week][row.genderId].ages[row.ageId].shares['preroll']"
                        wooPercentFormatter
                        [wooMinValidator]="0"
                        [wooMaxValidator]="1"
                        [required]="includedProductFormatSlotTypes[row.productFormatId].preroll"
                        [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
                      <input
                        *ngIf="!row.ageId"
                        name="row-{{index}}-shares-{{innerIndex}}-sharesPreroll"
                        [(ngModel)]="estimates.shares[row.productFormatId][week_shares.week][row.genderId].shares['preroll']"
                        wooPercentFormatter
                        [wooMinValidator]="0"
                        [wooMaxValidator]="1"
                        [required]="includedProductFormatSlotTypes[row.productFormatId].preroll"
                        [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
                    </span>
                  </td>
                  <td class="input__container override-table-input col-xs-2">
                    <span *ngIf="!isEditingWeekShares(row)">{{week_shares.sharesMidroll | percent: "1.0-3"}}</span>
                    <span *ngIf="isEditingWeekShares(row)">
                      <input
                        *ngIf="row.ageId"
                        name="row-{{index}}-shares-{{innerIndex}}-sharesMidroll-{{row.ageId}}"
                        [(ngModel)]="estimates.shares[row.productFormatId][week_shares.week][row.genderId].ages[row.ageId].shares['midroll']"
                        wooPercentFormatter
                        [wooMinValidator]="0"
                        [wooMaxValidator]="1"
                        [required]="includedProductFormatSlotTypes[row.productFormatId].midroll"
                        [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
                      <input
                        *ngIf="!row.ageId"
                        name="row-{{index}}-shares-{{innerIndex}}-sharesMidroll"
                        [(ngModel)]="estimates.shares[row.productFormatId][week_shares.week][row.genderId].shares['midroll']"
                        wooPercentFormatter
                        [wooMinValidator]="0"
                        [wooMaxValidator]="1"
                        [required]="includedProductFormatSlotTypes[row.productFormatId].midroll"
                        [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
                    </span>
                  </td>
                  <td class="input__container override-table-input col-xs-2">
                    <span *ngIf="!isEditingWeekShares(row)">{{week_shares.sharesPostroll | percent: "1.0-3"}}</span>
                    <span *ngIf="isEditingWeekShares(row)">
                      <input
                        *ngIf="row.ageId"
                        name="row-{{index}}-shares-{{innerIndex}}-sharesPostroll-{{row.ageId}}"
                        [(ngModel)]="estimates.shares[row.productFormatId][week_shares.week][row.genderId].ages[row.ageId].shares['postroll']"
                        wooPercentFormatter
                        [wooMinValidator]="0"
                        [wooMaxValidator]="1"
                        [required]="includedProductFormatSlotTypes[row.productFormatId].postroll"
                        [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
                      <input
                        *ngIf="!row.ageId"
                        name="row-{{index}}-shares-{{innerIndex}}-sharesPostroll"
                        [(ngModel)]="estimates.shares[row.productFormatId][week_shares.week][row.genderId].shares['postroll']"
                        wooPercentFormatter
                        [wooMinValidator]="0"
                        [wooMaxValidator]="1"
                        [required]="includedProductFormatSlotTypes[row.productFormatId].postroll"
                        [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
                    </span>
                  </td>
                </ng-container>
              </tbody>
              <div class="margin-top--4x">
                <button *ngIf="!isEditingWeekShares(row)" (click)="setEditingDetails(row)" class="button float--left">
                  <span class="icon-edit icon__semi-medium"></span>
                  Redigera veckoestimat
                </button>
              </div>
              <div class="row">
                <div class="margin-left--2x margin-top--1x">
                  <button *ngIf="isEditingWeekShares(row)" class="button primary" [disabled]="false" (click)="saveTargetGroups(row.productFormatId)">Spara</button>
                  <button *ngIf="isEditingWeekShares(row)" class="link" (click)="cancelEditingWeeks(row)">Avbryt redigering</button>
                </div>
              </div>
            </table>
          </td>
          <td colspan="6">
            <div class="margin-top--8x">
              <button *ngIf="!isEditingWeekShares(row)" (click)="setEditingDetails(row)" class="button float--left">
                <span class="icon-edit icon__semi-medium"></span>
                Redigera veckoestimat
              </button>
            </div>
            <div class="row">
              <span class="margin-left--2x margin-top--2x">
                <button *ngIf="isEditingWeekShares(row)" class="button primary" [disabled]="false" (click)="saveTargetGroups(row.productFormatId)">Spara</button>
                <button *ngIf="isEditingWeekShares(row)" class="link" (click)="cancelEditingWeeks(row)">Avbryt redigering</button>
              </span>
            </div>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>
    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditingWithdrawalLimit" class="margin-top--2x">
      <button class="button primary" (click)="saveTargetGroups()" [disabled]="targetGroupsForm.invalid">Spara</button>
      <button class="link" (click)="cancelEditingWithdrawalLimits()">Avbryt redigering</button>
    </div>
  </div>
</div>
