<section>
  <h2>EOS API</h2>
  <button class="tabbable no-outline margin-right--4px" (click)="setTab(0)" [ngClass]="{active : tab === 0}">Översikt</button>
  <button class="tabbable no-outline margin-right--4px" (click)="setTab(1)" [ngClass]="{active : tab === 1}">Kampanjrader</button>
  <button class="tabbable no-outline margin-right--4px" (click)="setTab(2)" [ngClass]="{active : tab === 2}">Kampanjrader (TTV)</button>
  <button class="tabbable no-outline margin-right--4px" (click)="setTab(3)" [ngClass]="{active : tab === 3}">Lagerrader (regionalt)</button>
  <button class="tabbable no-outline margin-right--4px" (click)="setTab(4)" [ngClass]="{active : tab === 4}">Lagerrader (TTV)</button>
  <button class="tabbable no-outline margin-right--4px" (click)="setTab(5)" [ngClass]="{active : tab === 5}">Aggregerat utfall</button>
  <button class="tabbable no-outline margin-right--4px" (click)="setTab(6)" [ngClass]="{active : tab === 6}">API-Testare</button>

  <div class="tabbable__panel clearfix shadow-z-1">
    <div class="col-12"></div>
  </div>

  <div class="account__card">
    <div *ngIf="tab === 0">
      <h2>Översikt</h2>
      EOS API används för att hämta ut information om kampanjer från WOO för att användas i statistiksystemet EOS.
      Informationen förbereds av WOO periodisk, ingen databehandling sker i realtid vid själva anropen. Genereringen
      börjar varje natt kl 00:30 CET och bearbetar kampanjer med ett slutdatum 2 månader bakåt i tiden eller senare.
      Raderna hämtas av EOS kl 03:00 CET varje natt.
      <p></p>

      <h3>Inloggning</h3>
      Inloggning mot API:t sker via formulärautenticering. Användaren måste ha rollen EOS för att få tillgång till
      EOS-api:t. Inloggning sker genom att posta användarnamn och lösenord i JSON-format till <b>{{apiBaseUrl}}/users/sign_in</b>.

      <p></p>
      <h5>Anrop</h5>
      <div class="card card-body bg-light">
        <pre class="code">
    <b>POST</b> {{apiBaseUrl}}/users/sign_in
    {{ '{' }}
      "user":
      {{ '{' }}
        "email": "eos&#64;woo.ad",
        "password": "password"
      {{ '}' }}
    {{ '}' }}
        </pre>
      </div>
      <p></p>
      <h5>Förväntat svar</h5>
      <div class="card card-body bg-light">
        <pre class="code">
    {{ '{' }}
      "user":
      {{ '{' }}
        "id":"55b8b42c6c6f632691370000",
        "first_name":"Eos",
        "last_name":"Eosson",
        "email":"eos&#64;woo.ad",
        "agency_id":null,
        <b>"authentication_token":"KVJEhZfW4GDJ4AsCpZ7L"</b>,
        "roles":[
          {{ '{'}}"name":"eos", "resource_id":null {{ '}' }}
        ]
      {{ '}' }}
    {{ '}' }}
        </pre>
      </div>
    </div>

    <div *ngIf="tab === 1">
      <h2>Kampanjrader</h2>
      <p>
        Varje kampanj ger upphov till ett antal kampanjrader som skickas till EOS. Antal rader varierar beroende på
        kampanjens längd, antal regioner, kategorier, publicister etc.
      </p>
      <h3>Anrop</h3>
      <table class="table bg-light">
        <thead>
        <tr>
          <th>URL</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/campaigns</td>
          <td>Returnerar en lista innehållande kampanjer med bland annat <b>namn</b> och <b>kampanj-id</b>. Se detaljer nedan.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/campaigns?end_date_greater_than=2018-06-30</td>
          <td>Filtrerar kampanjrader på slut datum med formatet YYYY-MM-DD</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/campaigns?send_invoices=false</td>
          <td>Filtrerar kampanjrader på dem som ska skicka fakturor eller inte. Formatet 'true' eller 'false'</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/campaigns?end_date_greater_than=2018-06-30&send_invoices=false</td>
          <td>Det går att kombinera filter end_date_greater_than och send_invoices</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/publishers</td>
          <td>Returnerar en lista innehållande publicister med <b>namn</b>.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/regions</td>
          <td>Returnerar en lista innehållande regioner och sammanslagna (regionalpaketering) regioner med <b>namn</b>.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/products</td>
          <td>Returnerar en lista innehållande produkter med <b>namn</b>.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/organisations</td>
          <td>Returnerar en lista innehållande organisationers <b>namn</b> resp. <b>id</b>.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/behaviors</td>
          <td>Returnerar en lista innehållande information om beteendesement.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/dayparts</td>
          <td>Returnerar en lista innehållande information om dayparts.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/advanced_target_groups</td>
          <td>Returnerar en lista innehållande information om befintliga advanced_target_groups <b>namn</b> resp. <b>id</b>.</td>
        </tr>
        <tr>
          <td><b>GET</b> {{apiBaseUrl}}/eos/rows/[kampanj-id]</td>
          <td>Returnerar alla tillgängliga EOS-rader för en given kampanj.</td>
        </tr>
        <tr>
          <td><b>GET</b> {{apiBaseUrl}}/eos/rows/[kampanj-id]/[datum]</td>
          <td>Returnerar alla tillgängliga EOS-rader för en viss kampanj en given dag.
            Datum anges i svensk tid på formatet YYYY-MM-DD.
          </td>
        </tr>
        <tr>
          <td><b>GET</b> {{apiBaseUrl}}/eos/rows/by_date/[datum]</td>
          <td>Returnerar alla tillgängliga EOS-rader för en given dag.
            Datum anges i svensk tid på formatet YYYY-MM-DD.
          </td>
        </tr>
        </tbody>
      </table>

      <h3>Fältbeskrivningar</h3>

      Varje EOS-kampanj som tillgängliggörs via kampanjanropen ovan innehåller de här fälten.
      <table class="table woo--table">
        <thead>
        <tr>
          <th>Namn</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr><td>id</td><td>Kampanjens unika identifierare.</td></tr>
        <tr><td>name</td><td>Namn på kampanjen.</td></tr>
        <tr><td>start_date</td><td>Datum kampanjstart.</td></tr>
        <tr><td>end_date</td><td>Datum kampanjslut.</td></tr>
        <tr><td>send_invoices</td><td>En flagga som berättar om Woo skickar fakturor för kampanjen.</td></tr>
        <tr><td>order_value</td><td>Kampanjens ordervärde.</td></tr>
        </tbody>
      </table>

      Varje EOS-rad som tillgängliggörs via radanropen ovan levererar en uppsättning fält inehållande aggregerad data.
      <table class="table woo--table">
        <thead>
        <tr>
          <th>Namn</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr><td>advanced_target_groups</td><td>De avancerade målgrupper raden avser.</td></tr>
        <tr><td>agency</td><td>Mediebyrånamn.</td></tr>
        <tr><td>agency_discount_actual</td><td>Faktiskt mediebyråersättning för raden.</td></tr>
        <tr><td>agency_discount_estimate</td><td>Estimerad mediebyråersättning för raden.</td></tr>
        <tr><td>agreement_date</td><td>Avtalsdatum, när kampanjen bokades i WOO.</td></tr>
        <tr><td>agresso_id</td><td>Agresso faktura identifierare (invoice token).</td></tr>
        <tr><td>behaviors</td><td>De beteendesegment raden avser, t.ex. 'E-handel, Familj & Barn'.</td></tr>
        <tr><td>booking_organisation</td><td>Bokande organisation, tex: 'TV4'.</td></tr>
        <tr><td>campaign_end_date</td><td>Datum kampanjslut.</td></tr>
        <tr><td>campaign_id</td><td>Kampanjens unika identifierare.</td></tr>
        <tr><td>campaign_name</td><td>Namn på kampanjen.</td></tr>
        <tr><td>campaign_start_date</td><td>Datum kampanjstart.</td></tr>
        <tr><td>category</td><td>De kategorier som rader avser, tex: 'Nöje, Sport'.</td></tr>
        <tr><td>creative_lengths</td><td>Längder på filmer associerade med kampanjen.</td></tr>
        <tr><td>customer_crm_id</td><td>Kundnummer i CRAM för raden.</td></tr>
        <tr><td>date</td><td>Det datum som raden avser.</td></tr>
        <tr><td>dayparts</td><td>De dayparts-raden avser, t.ex. 'Natt, Eftermiddag'.</td></tr>
        <tr><td>device_groups</td><td>De apparater-raden avser, t.ex. 'Mobil & Tablet, CTV'.</td></tr>
        <tr><td>frequency_limit</td><td>Frekvenstak, med fälten time_unit (t.ex. week, campaign) och impressions (ett positivt heltal).</td></tr>
        <tr><td>gross_estimate</td><td>Bruttoestimat (utan rabatter) per dag/publicist/produkt/region.</td></tr>
        <tr><td>gross_rating_views_actual_mms</td><td>Levererade bruttokontakter per dag/publicist/produkt/region enligt MMS.</td></tr>
        <tr><td>gross_rating_views_additional_estimate</td><td>Ej fakturerade estimerade bruttokontakter per dag/publicist/produkt/region.</td></tr>
        <tr><td>gross_rating_views_estimate</td><td>Fakturerade estimerade bruttokontakter per dag/publicist/produkt/region.</td></tr>
        <tr><td>impressions_actual</td><td>Levererade impressions per dag/publicist/produkt/region (CVs för CV kampanjer).</td></tr>
        <tr><td>impressions_actual_mms</td><td>Levererade impressions per dag/publicist/produkt/region enligt MMS.</td></tr>
        <tr><td>impressions_additional_estimate</td><td>Ej fakturerade estimerade impressions per dag/publicist/produkt/region (CVs för CV kampanjer).</td></tr>
        <tr><td>impressions_estimate</td><td>Fakturerade estimerade impressions per dag/publicist/produkt/region (CVs för CV kampanjer).</td></tr>
        <tr><td>invoice_date</td><td>Det datum då fakturan skickas. Endast tillgängligt om faktura skickats.</td></tr>
        <tr><td>invoice_sent</td><td>Om fakturan är skickad.</td></tr>
        <tr><td>invoice_total</td><td>Det belopp som fakturerats kunden per dag. Endast tillgängligt om faktura skickats.</td></tr>
        <tr><td>net_actual</td><td>Faktiskt nettobelopp i kronor per dag.</td></tr>
        <tr><td>net_cpm</td><td>Faktiskt CPM.</td></tr>
        <tr><td>net_estimate</td><td>Nettoestimat i kronor per dag.</td></tr>
        <tr><td>net_net_actual</td><td>Faktiskt nettobelopp inklusive mediebyråersättning i kronor.</td></tr>
        <tr><td>net_net_estimate</td><td>Nettoestimat minus mediebyråersättningen.</td></tr>
        <tr><td>net_publisher_kickback</td><td>Den andel av det fakturade belopp som tillfallet publicisten per dag.</td></tr>
        <tr><td>net_tv4_kickback</td><td>Den andel av det fakturade belopp som tillfallet TV4 per dag.</td></tr>
        <tr><td>placement</td><td>Om kampanj är av typ kombination, regionalpaketering, eller TTV (t.ex.'Digital Kombination', m.m.).</td></tr>
        <tr><td>product</td><td>Den produkt som raden avser.</td></tr>
        <tr><td>product_category</td><td>Den kategori som raden tillhör.</td></tr>
        <tr><td>product_format</td><td>Det produktformat som raden tillhör (Förutom i fall av TTV kampanj, då är värdet TV4-total TV).</td></tr>
        <tr><td>program_formats</td><td>De programformat raden avser, tex: 'Idol, Bonde söker fru'</td></tr>
        <tr><td>publisher</td><td>Den publicist som rader avser.</td></tr>
        <tr><td>publisher_combination</td><td>Kan vara 'WOO', 'Enskild' eller 'WOO Select' beroende på hur många publicister är köpt</td></tr>
        <tr><td>region</td><td>Den region som raden avser, om hela landet avses returneras <b>Riket</b>.</td></tr>
        <tr><td>regional_type</td><td>Den region som raden tillhör.</td></tr>
        <tr><td>sale_type</td><td>Försäljningstyp.</td></tr>
        <tr><td>send_invoices</td><td>En flagga som berättar om WOO skickar fakturor för kampanjen.</td></tr>
        <tr><td>so_number</td><td>Sales order nummer, om kampanjen är regionalpaketerad.</td></tr>
        <tr><td>status</td><td>Status på kampanjen, tex: 'booked' eller 'closed'.</td></tr>
        <tr><td>target_groups</td><td>Typ av målgrupp: <b>kön</b>, <b>ålder</b>, eller <b>kön_ålder</b>.</td></tr>
        <tr><td>targeting_type</td><td>Targeting typen som raden tillhör (Till exempel RBS, kategori, osv).</td></tr>
        <tr><td>external_reference_id</td><td>Externt referens nummer för kampanjen</td></tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="tab === 2">
      <h2>Kampanjrader (TTV)</h2>
      <p>
        Varje TTV-kampanj ger upphov till ett antal rader som hämtas av EOS. Antal rader varierar beroende på
        kampanjens längd, filmlängder, etc.
      </p>
      <h3>Anrop</h3>
      <table class="table woo--table bg-light border">
        <thead>
        <tr>
          <th>URL</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/ttv_campaigns</td>
          <td>Returnerar en lista innehållande TTV-kampanjer med <b>namn</b> resp. <b>kampanj-id</b>.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/ttv_campaigns?end_date_greater_than=2018-06-30</td>
          <td>Filtrerar TTV-kampanjrader på slut datum med formatet YYYY-MM-DD</td>
        </tr>
        <tr>
          <td><b>GET</b> {{apiBaseUrl}}/eos/ttv_rows/[kampanj-id]</td>
          <td>Returnerar alla tillgängliga EOS-rader för en given TTV-kampanj.</td>
        </tr>
        <tr>
          <td><b>GET</b> {{apiBaseUrl}}/eos/ttv_rows/[kampanj-id]/[datum]</td>
          <td>Returnerar alla tillgängliga EOS-rader för en viss TTV-kampanj en given dag.
            Datum anges i svensk tid på formatet YYYY-MM-DD.
          </td>
        </tr>
        <tr>
          <td><b>GET</b> {{apiBaseUrl}}/eos/ttv_rows/by_date/[datum]</td>
          <td>Returnerar alla tillgängliga EOS-rader för en given dag.
            Datum anges i svensk tid på formatet YYYY-MM-DD.
          </td>
        </tr>
        </tbody>
      </table>

      <h3>Fältbeskrivningar</h3>
      Varje EOS-rad som tillgängliggörs via radanropet ovan levererar en uppsättning fält inehållande aggregerad data.
      <table class="table">
        <thead>
        <tr>
          <th>Namn</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr><td>agency</td><td>Mediebyrånamn.</td></tr>
        <tr><td>customer_crm_id</td><td>Kundnummer i CRAM.</td></tr>
        <tr><td>date</td><td>Datum.</td></tr>
        <tr><td>creative_length</td><td>Filmlängd.</td></tr>
        <tr><td>net_estimate_ttv</td><td>Spotlight nettobudget.</td></tr>
        <tr><td>impressions_actual_pulse</td><td>Levererade impressions från Pulse.</td></tr>
        <tr><td>impressions_adjusted</td><td>Ökat/minskat mål (CVs).</td></tr>
        <tr><td>impressions_actual_mms</td><td>Levererade impressions enligt MMS (MMS-siffra eller beräknad från Pulse).</td></tr>
        <tr><td>impressions_estimate_ttv</td><td>Skickade bruttokontakter dividerat på faktor för bruttokontakter vid bokande stund.</td></tr>
        <tr><td>gross_rating_views_actual_mms</td><td>Levererade bruttokontakter enligt MMS (MMS-siffra enbart).</td></tr>
        <tr><td>gross_rating_views_estimate_ttv</td><td>Skickade bruttokontakter från spotlight.</td></tr>
        <tr><td>mms_gain_factor</td><td>MMS-stärkning (faktor, 2.5 procents stärkning representeras som 1.025).</td></tr>
        <tr><td>ttv_booking_name</td><td>Spotlight bokningsnamn.</td></tr>
        <tr><td>ttv_booking_id</td><td>TTV-boknings-ID som kampanjen motsvarar.</td></tr>
        <tr><td>ttv_mapping_factor</td><td>Översättningsfaktor (magic box).</td></tr>
        <tr><td>ttv_reference_number</td><td>Spotlights kampanj-ID.</td></tr>
        <tr><td>ttv_target_group</td><td>Total-TV-målgrupp.</td></tr>
        <tr><td>universe</td><td>Universumtal för kampanjen.</td></tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="tab === 3">
      <h2>Regionalt lager</h2>
      Lagerraderna används för att beskriva WOO-lagret uppdelat per region och vecka.
      Det skapas alltså en lagerrad för varje vecka och region.
      <h3>Anrop</h3>
      <table class="table woo--table bg-light border">
        <thead>
        <tr>
          <th>URL</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/inventory_rows</td>
          <td>Returnerar alla lagerrader.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/inventory_rows?start_year=2018&start_week=51&number_of_weeks=4</td>
          <td>Returnerar alla lagerrader från vecka 51 (2018) tom vecka 2 (2019)</td>
        </tr>
        </tbody>
      </table>

      <h3>Fältbeskrivningar</h3>
      Varje lagerrad som tillgängliggörs via radanropet ovan levererar en uppsättning fält
      innehållande följande aggregerade data.
      <table class="table">
        <thead>
        <tr>
          <th>Namn</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr><td>available</td><td>Det tillgängliga regionslagret för den vecka och region som raden avser.</td></tr>
        <tr><td>date</td><td>Måndagen i den vecka som lagerraden avser.</td></tr>
        <tr><td>estimated</td><td>Estimerade impressions den veckan som raden avser.</td></tr>
        <tr><td>publisher</td><td>Den publicist som raden avser.</td></tr>
        <tr><td>region</td><td>Den region som raden avser.</td></tr>
        <tr><td>slot_type</td><td>Den annonsplacering som raden avser.</td></tr>
        <tr><td>withdrawal_limit</td><td>Det tillgängliga maxuttaget för den vecka och region som raden avser.</td></tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="tab === 4">
      <h2>TTV-lager per målgrupp</h2>
      Lagerraderna används för att beskriva WOO-lagret uppdelat per TTV-målgrupp, kanal, slot_type, och vecka.
      <h3>Anrop</h3>
      <table class="table woo--table bg-light border">
        <thead>
        <tr>
          <th>URL</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/ttv_inventory_rows</td>
          <td>Returnerar alla lagerrader.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/ttv_inventory_rows?start_year=2018&start_week=51&number_of_weeks=4</td>
          <td>Returnerar alla lagerrader från vecka 51 (2018) tom vecka 2 (2019)</td>
        </tr>
        </tbody>
      </table>

      <h3>Fältbeskrivningar</h3>
      <table class="table">
        <thead>
        <tr>
          <th>Namn</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr><td>available</td><td>Det tillgängliga TTV-lagret för den vecka och som raden avser.</td></tr>
        <tr><td>channel_name</td><td>Den kanal som raden avser.</td></tr>
        <tr><td>date</td><td>Måndagen i den vecka som raden avser.</td></tr>
        <tr><td>estimated</td><td>Estimerade impressions den veckan som raden avser.</td></tr>
        <tr><td>slot_type</td><td>Den annonsplacering som raden avser.</td></tr>
        <tr><td>ttv_target_group</td><td>Den TTV-målgrupp som raden avser.</td></tr>
        <tr><td>withdrawal_limit</td><td>Det tillgängliga maxuttaget för den vecka som raden avser.</td></tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="tab === 5">
      <h2>Aggregerat utfall</h2>
      Det aggregerade utfallet för ett givet år eller månad är summan av alla EOS-raders utfall.
      <h3>Anrop</h3>
      <table class="table woo--table bg-light border">
        <thead>
        <tr>
          <th>URL</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/aggregated_rows/:year</td>
          <td>Returnerar det aggregerade utfallet för det valda året. Året anges på formatet <i>YYYY</i>.</td>
        </tr>
        <tr>
          <td><b>GET </b> {{apiBaseUrl}}/eos/aggregated_rows/:year/:month</td>
          <td>Returnerar det aggregerade utfallet för den valda månaden. Året anges på formatet <i>YYYY</i> och månaden på formatet <i>mm</i> (01-12)</td>
        </tr>
        </tbody>
      </table>

      <h3>Fältbeskrivningar</h3>
      Svaret innehåller följande aggregerade data.
      <table class="table">
        <thead>
        <tr>
          <th>Namn</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr><td>net_estimate</td><td>Ekonomiskt nettoutfall för den valda perioden (året eller månaden).</td></tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="tab === 6">
      <eos-api-tester></eos-api-tester>
    </div>

  </div>
</section>
