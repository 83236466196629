import { KeyValue, ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../woo_services.module/DashboardService';
import { AdServerStatus, CampaignStatus } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'style-sheet',
  templateUrl: './style-sheet.component.html',
  styleUrls: ['./style-sheet.component.scss'],
})
export class StyleSheet implements OnInit {
  labels: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul'];
  series: string[] = ['Impressions (1000)'];
  data: number[][] = [[0, 25, 30, 37, 11, 24, 0]];
  date = null;
  showWeek = true;
  minDate: Date = null;
  maxDate: Date = null;
  disabled = false;
  number = 0;
  tabVisible = 0;
  mailLinks = [];
  campaigns;
  colors = {
    primary: [1, 2, 3],
    secondary: [4, 5, 6],
    gray: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    variants: [18, 19, 20, 21, 22, 23, 24, 25],
    semantic: [26, 27, 28],
    misc: [29],
  };
  period: { start: Date; end: Date } = { start: null, end: null };
  ipsums: Record<string, any>[] = [
    {
      id: 1,
      name: 'Hipsum.co',
      data:
        'Waistcoat shoreditch farm-to-table, truffaut before they sold out biodiesel ramps hoodie. Kickstarter shoreditch cardigan banjo artisan. You probably havent heard of them literally waistcoat, chia hella meh mumblecore typewriter salvia normcore. Austin franzen thundercats, brooklyn cronut semiotics heirloom umami taxidermy portland fingerstache kale chips. Sriracha cardigan kogi taxidermy PBR&B, narwhal cred ugh. Seitan taxidermy meditation kogi. Chillwave normcore roof party yuccie.',
    },
    {
      id: 2,
      name: 'Lorem Ipsum',
      data:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ullamcorper nunc sit amet lacinia imperdiet. Quisque eu est turpis. Donec nulla erat, ornare ut pulvinar eget, finibus et est. Fusce eu mollis mauris. Pellentesque ex diam, vehicula eget placerat aliquam, suscipit quis urna. Nam a tristique tortor. In et diam ac arcu convallis lacinia a sed ipsum. Aliquam finibus massa et egestas fringilla. Duis lacinia risus quis accumsan condimentum. Nam ut elit a libero tincidunt auctor. Quisque facilisis scelerisque magna vel gravida. Praesent tincidunt, lacus id auctor viverra, risus magna dignissim tortor, id dictum turpis lacus at sapien.',
    },
    {
      id: 3,
      name: 'Baconipsum.com',
      data:
        'Bacon ipsum dolor amet tri-tip boudin strip steak pork chop shank. Pastrami beef ham hock jowl bacon ribeye, ground round sirloin t-bone flank pancetta tri-tip landjaeger. Biltong strip steak flank pork loin fatback shankle pastrami pancetta hamburger bresaola turkey capicola landjaeger tenderloin pork. Frankfurter pancetta landjaeger pig sirloin jerky short loin tri-tip beef pork pork chop spare ribs chicken rump. Filet mignon fatback prosciutto, ball tip biltong meatloaf bacon rump. Pork belly doner ham hock shankle porchetta jerky brisket t-bone leberkas ham.',
    },
    {
      id: 4,
      name: 'Legal Ipsum',
      data:
        'While this license apply to this License. For compatibility reasons, you are re-using the page history is sufficient. Licensing notice: Each copy or copies of the License published by the Free Software Foundation; we sometimes make exceptions for this. Our decision will be liable to you for damages, including direct, indirect, special, incidental, or consequential damages arising out of any updates or upgrades to such actions. The Recipient may not impose any terms of this section do not need to make thoroughly clear what is believed to be a Modified Version as Source (either gratis or for any purpose does not attempt to limit or alter any copyright notices in the case that a license of your Derivative Works as a result of any works derived from this License are fulfilled for the user community. They therefore concern themselves with the Licensed Product and then distribute are governed by this Artistic License to the Licensed Product.',
    },
    {
      id: 5,
      name: 'Hodor Ipsum',
      data:
        'Hodor hodor HODOR! Hodor hodor hodor - hodor... Hodor hodor hodor. Hodor! Hodor hodor, hodor... Hodor hodor hodor hodor... Hodor hodor HODOR hodor, hodor hodor, hodor, hodor hodor. Hodor. Hodor hodor hodor hodor - hodor; hodor hodor hodor! Hodor, hodor. Hodor. Hodor, hodor hodor hodor - hodor? Hodor, hodor. Hodor. Hodor, hodor; hodor hodor, hodor. Hodor hodor; hodor hodor, hodor. Hodor hodor, hodor, hodor hodor. Hodor hodor HODOR! Hodor hodor hodor hodor hodor hodor hodor!',
    },
  ];
  statusBadgeColors = ['success', 'error', 'warning', 'info', 'inverse', 'dark', 'green-success'];
  activeClass: string;
  sectionClr: string;
  tableOfContents = {
    Byggstenar: ['Grid', 'Kolumnstorlekar'],
    Indelningar: ['Account Card', 'Flikar', 'Sektion'],
    Typografi: ['Rubriker', 'Hjälpklasser'],
    Färger: ['Primärfärger', 'Sekundärfärger', 'Grå', 'Varianter', 'Semantiska färger', 'Misc'],
    Buttons: [],
    Tabeller: ['Default', 'Woo'],
    Inputs: [
      'Checkbox',
      'Radio button',
      'Woo text input',
      'Woo number input',
      'Woo-dropdown',
      'Period Select',
      'Toggles',
      'Nummer-formattering',
      'Listor',
      'Badges',
      'Skuggor',
      'Avskiljare',
    ],
    Ikoner: ['TV4-ikoner'],
    Loader: ['Loader-komponenten', 'wooLoad'],
    Meddelanden: ['Context-message', 'Modaler'],
    Övrigt: ['Hjälpklasser', 'Mail templates', 'Påfågeln'],
  };

  constructor(private dashboardService: DashboardService, private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    this.dashboardService.loadDashboard(1, {}).then((emptyResult: boolean) => {
      if (!emptyResult) {
        const loadedCampaign = Object.values<any>(this.dashboardService.campaigns).find(
          (campaignSet: any): any => campaignSet.list && campaignSet.list.length > 0,
        ).list[0];
        this.mailLinks = [{ link: `/#/mails/booking_confirmation/${loadedCampaign.id}`, name: 'Bokningsbekräftelse' }];
      } else {
        console.error('Could not load any campaigns');
      }
    });

    this.campaigns = [
      this.generateCampaign(CampaignStatus.booked, AdServerStatus.sent, 'ready'),
      this.generateCampaign(CampaignStatus.ongoing, AdServerStatus.sent, 'ready'),
      this.generateCampaign(CampaignStatus.cancelled, AdServerStatus.unsent, 'ready'),
    ];
    this.viewportScroller.setOffset([0, 130]);
  }

  setTab = (index: number): void => {
    this.tabVisible = index;
  };

  setMin = (): void => {
    this.minDate = new Date();
  };

  setMax = (): void => {
    this.maxDate = new Date();
  };

  scrollTo(header: string): void {
    this.viewportScroller.scrollToAnchor(header);
  }

  compareFn(_left: KeyValue<string, string[]>, _right: KeyValue<string, string[]>): number {
    return 0;
  }

  private randomName = (): string => {
    const names = [
      'Vice',
      'next level taxidermy',
      'actually keytar',
      'literally chambray',
      'microdosing kinfolk',
      'bicycle rights.',
      'Flexitarian',
      'man braid',
      'portland craft beer',
      'pork belly',
      'fap pabst truffaut',
      'marfa tofu',
      'occupy etsy meggings +1',
      'put a bird on it.',
      'Single-origin coffee',
      'vice chambray',
      'mumblecore',
      'salvia actually disrupt keytar',
      'austin fingerstache migas',
      'listicle street art',
      'waistcoat ramps.',
      'Church-key godard fixie',
      'bespoke mustache.',
      'shoreditch readymade tumblr wayfarers',
      'intelligentsia artisan man',
      'bun blue bottle.',
      'Food truck pabst flexitarian sriracha ethical.',
    ];
    return names[Math.floor(Math.random() * names.length)];
  };

  private generateCampaign = (status: CampaignStatus, pulse: AdServerStatus, film: string) => {
    return {
      additional_info: '',
      budget: Math.floor(Math.random() * 100000) + 10000,
      budgeted_impressions: Math.floor(Math.random() * 500000) + 10000,
      categories: [],
      cpm_price: 0,
      created_at: '2015-05-01T00:00:00.000+02:00',
      customer_discount: 0,
      customer_name: 'Stora fabriken',
      enabled: true,
      end_date: new Date(),
      external_id: pulse,
      ad_server_status: pulse,
      followup: false,
      id: '',
      send_invoices: true,
      sent_to_ad_server_at: null,
      name: this.randomName(),
      program_formats: [],
      reference_number: '12345678',
      regions: [],
      publishers: [],
      stamp: 'Slutade för ' + Math.floor(Math.random() * 12) + ' månader sedan',
      start_date: new Date(),
      statistics: {
        total: {
          labels: [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ],
          series: [''],
          impressions: [
            [
              2981,
              2193,
              2054,
              2020,
              678,
              0,
              0,
              2469,
              2281,
              1968,
              1875,
              1340,
              0,
              0,
              2118,
              1920,
              1952,
              1727,
              1542,
              667,
              1,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          ],
        },
      },
      status: status,
      targetings: [
        {
          creatives: [{ status: film }],
        },
      ],
      creatives: [{ status: film }],
      creative_status: film,
      creativeStatus: film,
      total_additional_impressions: 0,
      updated_at: '2015-05-01T00:00:00.000+02:00',
    };
  };

  periodChanged = (event: { start: Date; end: Date }): void => {
    this.period.start = event.start;
    this.period.end = event.end;
  };

  toHtmlTagString = (text: string, openTag = false): string =>
    openTag ? `<${text}`.replace(/'/g, '"') : `<${text}>`.replace(/'/g, '"');
}
