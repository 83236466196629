<h2 class="margin-bottom--4x" *ngIf="layoutProfile.heading.visible">
  <ng-container *ngIf="editing">Redigera film</ng-container>
  <ng-container *ngIf="!editing">Lägg till film</ng-container>
</h2>
<p *ngIf="layoutProfile.agAffectInventoryInfo.visible" class="text--small">
  Observera att val av leveranssätt kan påverka tillgång av reklamlager, det är därför extra viktigt att du väjer
  VAST 2.0 - 4.0 redan vid bokningstillfället om detta är förväntad filmleverans.
</p>
<p *ngIf="disableAG && layoutProfile.agDisabledInfo.visible" class="text--small">
  Det går inte att byta till VAST 2.0 - 4.0 eftersom att kampanjdelen inte bokades med det.
</p>
<div class="row margin-bottom--1x">
  <div class="col-xs-12">
    <div *ngIf="videoCreatives.length && !editing" class="row padding-bottom--1x">
      <div class="col-xs-6">
        <div class="select__container">
          <label for="duplicate-creative">
            Kopiera film från tidigare tillagda
          </label>
          <select id="duplicate-creative" (change)="duplicateCreative($event.target.value)">
            <option value=""></option>
            <option *ngFor="let creative of videoCreatives, index as index" [value]="index">
              {{getCreativeName(creative)}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
      <div class="row padding-bottom--2x">
        <div class="col-md-6">
          <div class="select__container">
            <label for="creative-type">
              Leveranssätt
              <info-badge gaName="leveranssätt" class="creative-form-position">
                <p class="text--strong">Peach</p>
                <p>
                  Om ditt kampanjmaterial levereras via Peach så skriver du in din Peach-kod här.
                  Ex: E1JPEU2005
                </p>
                <p class="text--strong">Adstream</p>
                <p>
                  Om ditt kampanjmaterial levereras via Adstream så skriver i du in den koden här.
                  Ex: 51RECC1502
                </p>
                <p class="text--strong">VAST 2.0 - 4.0</p>
                <p>
                  Om ditt kampanjmaterial levereras via tredjepartshost så skriver du in din VAST-länk här.
                  Ex: http://top-domain.se/uri/to/vast.xml
                </p>
              </info-badge>
            </label>
            <select formControlName="creativeType" id="creative-type" [wooInputErrors]="{absolute: true}">
                <option value=""></option>
                <option
                  *ngFor="let type of creativeVideoTypes"
                  [value]="type"
                  [disabled]="disableAG && type === VideoCreativeType.ag"
                >
                  {{translateCreativeTypes(type)}}
                </option>
              </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="input__container">
            <span *ngIf="!videoCodeRequired" class="input__optional__text">Frivillig</span>
            <label for="video-code">Filmkod</label>
            <input type="text" id="video-code" formControlName="videoCode" [wooInputErrors]="{absolute: true}">
          </div>
        </div>
        <div class="col-md-3" *ngIf="layoutProfile.length.visible">
          <div class="select__container">
            <label for="creative-length">
              Filmlängd
              <info-badge gaName="filmlängd" class="creative-form-position">
                Maxlängd för Preroll är 20 sekunder och Midroll 60 sekunder.
              </info-badge>
            </label>
            <select
              formControlName="filmLength"
              id="creative-length"
              [wooInputErrors]="{absolute: true}"
            >
              <option value=""></option>
              <option
                *ngFor="let length of creativeLengths"
                [ngValue]="length"
              >
                {{length}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <p *ngIf="creativeType.value === VideoCreativeType.ag" class="text-danger text--small">
        <span class="icon-warning"></span>
        <span class="strong">Viktigt!</span> Ingen styrning, frekvens eller impressiongoal får adderas på DSP-sidan. Detta kan
        påverka leverans av
        kampanj. Om impressiongoal måste adderas bör man ta höjd med extra 10% för att CPCV ska kunna uppnås.
      </p>
      <div *ngIf="showVideoUrlSelect" class="row padding-bottom--3x">
        <div class="col-xs-12">
          <asset-url-input
            formControlName="assetUrl"
            [customerId]="customerId"
            [videoCode]="videoCode.value"
            [videoLength]="filmLength.value"
            [validator]="VIDEO_CODE_VALIDATORS"
            [isRequired]="assetUrlRequired"
            (onUseSelectedVideosCode)="useVideoCodeFromSelected($event)"
            [hideUnselectedDurations]="filmLength.disabled"
            >
          </asset-url-input>
        </div>
      </div>
      <div *ngIf="showVideoUrlInput && !showVideoUrlSelect" class="row padding-bottom--1x">
        <div class="col-xs-12">
          <div class="input__container">
            <span class="input__optional__text" *ngIf="!assetUrlRequired">Frivillig</span>
            <label for="video-url">{{videoUrlLabel}}</label>
            <input type="url" id="video-url" formControlName="assetUrl" [wooInputErrors]="{absolute: true}">
          </div>
        </div>
      </div>
      <div class="row padding-bottom--1x">
        <div class="col-md-6">
          <div class="input__container">
            <span class="input__optional__text">Frivillig</span>
            <label for="film-name">Filmnamn</label>
            <input type="text" id="film-name" formControlName="name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="input__container">
            <span class="input__optional__text">Frivillig</span>
            <label for="destination-url">
              Destinations-URL / Tracking med URL
              <info-badge gaName="destinations-url-video">
                Landningssida när man klickar på reklamfilmen.
              </info-badge>
            </label>
            <input type="url" id="destination-url" formControlName="destinationUrl" [wooInputErrors]="{absolute: true}">
          </div>
        </div>
      </div>
      <div class="row padding-bottom--1x">
        <div class="col-xs-12">
          <div class="input__container">
            <span class="input__optional__text">Frivillig</span>
            <label for="click-text-input">
              Tracking klickmätning
              <info-badge gaName="destinations-url-video">
                Tracking för endast mätning av klick.
              </info-badge>
            </label>
            <input type="url" id="click-text-input" formControlName="clickTrackUrl" [wooInputErrors]="{absolute: true}">
          </div>
        </div>
      </div>
      <div class="row padding-bottom--3x">
        <div class="col-xs-12">
          <string-array-input
            [optional]="true"
            formControlName="impTrackUrls"
            label="Tracking impressionmätning"
            [validator]="HTTPS_URL_VALIDATORS"
            [wooInputErrors]="{absolute: true}"
          ></string-array-input>
        </div>
      </div>
      <div class="row padding-bottom--1x">
        <div class="col-xs-12">
          <div class="input__container">
            <span class="input__optional__text" *ngIf="!customTextRequired">Frivillig</span>
            <label for="custom-text">Kommentar</label>
            <textarea id="custom-text" rows="2" formControlName="customText" [wooInputErrors]="{absolute: true}"></textarea>
          </div>
        </div>
      </div>
      <div class="padding-top--2x" *ngIf="layoutProfile.actionButtons.visible">
        <button class="button primary" [disabled]="layoutProfile.actionButtons.disabled">Spara</button>
        <button class="button secondary" type="button" (click)="abort()" [disabled]="layoutProfile.actionButtons.disabled">Avbryt</button>
      </div>
    </form>
  </div>
</div>
