import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgChartsModule } from 'ng2-charts';
import { WooComponentsModule } from '../woo_components.module/module';
import { AdminFollowUp } from './components/admin_follow_up/admin-follow-up.component';
import { AgencySelfServiceForm } from './components/admin_follow_up/agency-self-service-form.compontent';
import { AutomaticMmsGainToggle } from './components/admin_follow_up/automatic-mms-gain-toggle.component';
import { FrequencyLimit } from './components/admin_follow_up/frequency-limit.component';
import { CreateCampaignFromTemplateButton } from './components/buttons/create-campaign-from-template-button.component';
import { DownloadOutcomeButton } from './components/buttons/download-outcome-button.component';
import { DashboardCampaigns } from './components/campaigns/dashboard-campaigns.component';
import { CampaignApprovalList } from './components/campaign_approval/campaign-approval-list.component';
import { CampaignGoalsComponent } from './components/campaign_goals/campaign-goals.component';
import { EditGoalForm } from './components/campaign_goals/edit-goal-form.component';
import { EditTargetingForm } from './components/campaign_goals/edit-targeting-form.component';
import { CampaignHistory } from './components/campaign_history/campaign-history.component';
import { CampaignInventoryComponent } from './components/campaign_inventory/campaign-inventory.campaign';
import { Dashboard } from './components/dashboard/dashboard.component';
import { DashboardSidebar } from './components/dashboard_sidebar/dashboard-sidebar.component';
import { CancelCampaignButton } from './components/dashboard_sidebar/manage_campaign_buttons/cancel-campaign-button.component';
import { CloseCampaignButton } from './components/dashboard_sidebar/manage_campaign_buttons/close-campaign-button.component';
import { DuplicateCampaignButton } from './components/dashboard_sidebar/manage_campaign_buttons/duplicate-campaign-button.component';
import { EditCampaignButton } from './components/dashboard_sidebar/manage_campaign_buttons/edit-campaign-button.component';
import { ManageCampaignButtons } from './components/dashboard_sidebar/manage_campaign_buttons/manage-campaign-buttons.component';
import { RemoveCampaignButton } from './components/dashboard_sidebar/manage_campaign_buttons/remove-campaign-button.component';
import { ReviewCampaignButton } from './components/dashboard_sidebar/manage_campaign_buttons/review-campaign-button.component';
import { SendToAdServerButton } from './components/dashboard_sidebar/manage_campaign_buttons/send-to-ad-server-button.component';
import { StopCampaignButton } from './components/dashboard_sidebar/manage_campaign_buttons/stop-campaign-button.component';
import { StopCampaignDialog } from './components/dashboard_sidebar/stop_campaign_dialog/stop-campaign-dialog.component';
import { ViewCurrencyBlock } from './components/dashboard_sidebar/view_currency_block/view-currency-block.component';
import { DashboardDetails } from './components/details/dashboard-details.component';
import { CampaignSelectionDialog } from './components/dialogs/campaign-selection-dialog.component';
import { SearchInfoDialog } from './components/dialogs/search-info-dialog.component';
import { EditGoals } from './components/edit_goals/edit-goals.component';
import { ExtendCampaign } from './components/extend_campaign/extend-campaign.component';
import { FollowUp } from './components/follow_up/follow-up.component';
import { InvoiceForm } from './components/invoices/invoice-form.component';
import { Invoices } from './components/invoices/invoices.component';
import { CampaignOutcome } from './components/outcome/campaign-outcome.component';
import { EditOutcomeReport } from './components/outcome/edit_outcome_report/edit-outcome-report.component';
import { MMSTotalsSummary } from './components/outcome/mms-totals-summary.component';
import { Outcome } from './components/outcome/outcome.component';
import { TargetingOutcomeSummary } from './components/outcome/targeting-outcome-summary.component';
import { TargetingOutcome } from './components/outcome/targeting-outcome.component';
import { TrackingDistribution } from './components/outcome/tracking-distribution.component';
import { TvDayOutcome } from './components/outcome/tv-day-outcome.component';
import { TvDayOutcomeTable } from './components/outcome/tv_day_outcome_table/tv-day-outcome-table.component';
import { TrackingService } from './services/TrackingService';

@NgModule({
  imports: [BrowserModule, NgChartsModule, FormsModule, ReactiveFormsModule, RouterModule, WooComponentsModule],
  providers: [TrackingService],
  declarations: [
    AdminFollowUp,
    AgencySelfServiceForm,
    AutomaticMmsGainToggle,
    CampaignApprovalList,
    CampaignGoalsComponent,
    CampaignHistory,
    CampaignInventoryComponent,
    CampaignOutcome,
    Dashboard,
    DashboardCampaigns,
    CampaignSelectionDialog,
    CancelCampaignButton,
    CloseCampaignButton,
    CreateCampaignFromTemplateButton,
    DashboardDetails,
    DashboardSidebar,
    DownloadOutcomeButton,
    DuplicateCampaignButton,
    EditCampaignButton,
    EditGoalForm,
    EditGoals,
    EditOutcomeReport,
    EditTargetingForm,
    ExtendCampaign,
    FollowUp,
    FrequencyLimit,
    InvoiceForm,
    Invoices,
    ManageCampaignButtons,
    MMSTotalsSummary,
    Outcome,
    RemoveCampaignButton,
    ReviewCampaignButton,
    SearchInfoDialog,
    SendToAdServerButton,
    StopCampaignButton,
    StopCampaignDialog,
    TargetingOutcome,
    TargetingOutcomeSummary,
    TrackingDistribution,
    TvDayOutcome,
    TvDayOutcomeTable,
    ViewCurrencyBlock,
  ],
  exports: [
    CampaignApprovalList,
    CampaignHistory,
    CreateCampaignFromTemplateButton,
    DashboardDetails,
    DashboardSidebar,
    EditGoals,
    ExtendCampaign,
    Outcome,
    AdminFollowUp,
  ],
})
export class WooDashboardModule {}
