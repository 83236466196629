import { Component, Input } from '@angular/core';
import { CampaignStatus } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

const template = `
<div class="cash-card--banner-info"
    [ngClass]="{
      'under-review': status === CampaignStatus.reviewing,
      'accepted': status === CampaignStatus.upcoming,
      'rejected': status === CampaignStatus.rejected,
      'stopped': status === CampaignStatus.stopped,
      'cancelled': status === CampaignStatus.cancelled
    }">
  <span class="glyphicon"
    [ngClass]="{
      'glyphicon-eye-open': status === CampaignStatus.reviewing,
      'glyphicon-ok-sign': status === CampaignStatus.upcoming,
      'glyphicon-remove-sign': status === CampaignStatus.rejected,
      'glyphicon-remove-circle': status === CampaignStatus.cancelled || status === CampaignStatus.stopped
    }"
  ></span>
  <span class="state-text strong margin-left--1x">{{translationService.convertCampaignStatus(status)}}</span>
</div>
`;

@Component({
  selector: 'card-banner',
  template: template,
  styleUrls: ['./card-banner.component.scss'],
})
export class CardBanner {
  @Input() status: CampaignStatus;

  public CampaignStatus = CampaignStatus;

  constructor(public translationService: TranslationService) {}
}
