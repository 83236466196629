import { Component, Input } from '@angular/core';

const template = /* html */ `
  <p class="margin-bottom--4px">
    <span class="icon-TV4_118_lokal_styrning icon__semi-medium text--info"></span>
    {{title}} <span class="text--italic">{{subtitle}}</span>
  </p>
`;

@Component({
  selector: 'regional-indicator',
  template: template,
  styles: ['h4 { margin-bottom: 4px; margin-top: 24px;}'],
})
export class RegionalIndicator {
  @Input() title: string;
  @Input() subtitle: string;
}
