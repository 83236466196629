<h2 class="margin-bottom--3x">Välj annonstyp och styrning</h2>
<targeting-details
  *ngFor="let targeting of targetings; let index = index; trackBy: trackById"
  [id]="targeting.id"
  [targetingId]="targeting.id"
  [attr.e2e-id]="'targeting-' + index"
  class="container--white"
>
</targeting-details>

<div class="container--white padding--3x">
  <h3 class="margin-top--none">Boka flera kampanjdelar med olika styrningar</h3>
  <p class="margin-bottom--2x">
    Observera att kampanjens leverans följs upp på den totala kampanjen, inklusive alla eventuella kampanjdelar. Värdet av eventuell skuld till följd av underleverans beräknas på kampanjens högsta CPT.
    <a href="{{userTerms}}" target="_blank">Mer information i de allmänna villkoren.</a>
  </p>
  <div class="targetings-booking-info margin-bottom--2x padding-left-right--1x padding-top-bottom--2x">
    <info-badge class="padding-right--1x no-tooltip"></info-badge>
    <span>
    Vid bokning av flera kampanjdelar med olika styrningar kommer fakturan skickas vid första kampanjdels startdatum och uppföljningen sammanställas när alla kampanjdelar gått klart.
    </span>
  </div>
  <button-with-line
    label="Lägg till kampanjdel"
    e2e-id="add-targeting"
    [onClick]="addTargeting"
  >
  </button-with-line>
</div>
