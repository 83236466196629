import { Component, Input, OnChanges } from '@angular/core';
import { STANDARD_FORMATS } from '../../utils/format-constants';
import { SimpleChanges } from '../../utils/types';
import { Campaign } from '../../woo_services.module/shared-types';
import { TTVBooking, TTVBookingService } from '../../woo_services.module/TTVBookingService';
@Component({
  selector: 'ttv-bookings',
  templateUrl: './ttv-bookings.component.html',
})
export class TTVBookings implements OnChanges {
  readonly PERCENT_FORMAT = STANDARD_FORMATS.percent;

  @Input() campaign: Campaign;
  ttvBookings: TTVBooking[] = [];

  constructor(private ttvBookingService: TTVBookingService) {}

  ngOnChanges(changes: SimpleChanges<TTVBookings>): void {
    if (changes.campaign && this.campaign) {
      const promises = this.campaign.targetings.map((t) =>
        this.ttvBookingService.getTTVBooking(this.campaign.id, t.id),
      );
      Promise.all(promises).then((ttvBookings) => (this.ttvBookings = ttvBookings));
    }
  }

  getViews(booking: TTVBooking): number {
    return booking.weeks.reduce((sum, week) => sum + week.views, 0);
  }

  getBudget(booking: TTVBooking): number {
    return booking.weeks.reduce((sum, week) => sum + Number(week.net_budget), 0);
  }
}
