import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ConfirmDialogContent } from '../../../../woo_components.module/dialogs/confirm-dialog.component';
import { CampaignService } from '../../../../woo_services.module/CampaignService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { Campaign, CampaignStatus } from '../../../../woo_services.module/shared-types';

const template = /* html */ `
  <text-button
    icon="trash"
    [disabled]="disabled"
    (click)="onClick()"
  >
    Ta bort
  </text-button>
`;

@Component({
  selector: 'remove-campaign-button',
  template,
})
export class RemoveCampaignButton {
  @Input() campaign: Campaign;
  @Input() disabled = false;

  constructor(
    private campaignService: CampaignService,
    private routingService: RoutingService,
    private dialogService: DialogService,
  ) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    const content = this.getDialogContent();
    this.dialogService
      .openConfirm(content)
      .then(
        () => {
          this.removeCampaign(this.campaign);
        },
        () => null,
      )
      .catch(() => {
        const msg = 'Din kampanj kunde inte tas bort :(';
        this.dialogService.openError(msg);
      });
  };

  private removeCampaign = (campaign: Campaign) => {
    if (this.campaignService.hasAnyStatus(campaign, [CampaignStatus.unbooked])) {
      this.removeUnbooked(campaign);
    } else if (
      this.campaignService.hasAnyStatus(campaign, [
        CampaignStatus.cancelled,
        CampaignStatus.ended,
        CampaignStatus.stopped,
      ])
    ) {
      this.removeEnded(campaign);
    } else {
      const msg = 'Endast obokade, avslutade eller stoppade kanpanjer kan tas bort.';
      this.dialogService.openError(msg);
    }
  };

  private removeUnbooked = (campaign: Campaign) => {
    this.campaignService
      .removeUnbooked(campaign.id)
      .then(
        () => {
          this.removeSuccess();
        },
        (reason) => {
          this.removeFailure(reason);
        },
      )
      .catch(() => {
        this.removeError();
      });
  };

  private removeEnded = (campaign: Campaign) => {
    this.campaignService
      .removeEnded(campaign.id)
      .then(
        () => {
          this.removeSuccess();
        },
        (reason) => {
          this.removeFailure(reason);
        },
      )
      .catch(() => {
        this.removeError();
      });
  };

  private removeSuccess = (): void => {
    this.dialogService.openSuccess('Kampanjen har tagits bort.');
    this.routingService.navigateWithReload(['/dashboard']);
  };

  private removeFailure = (reason): void => {
    if (!(reason instanceof HttpErrorResponse)) {
      const msg = 'Ajdå, något gick snett. Din kampanj kunde inte tas bort.';
      this.dialogService.openError(msg);
    }
  };

  private removeError = (): void => {
    const msg = 'Ajdå, nånting gick snett. Testa att refresha browsern och försök igen.';
    this.dialogService.openError(msg);
  };

  private getDialogContent = (): ConfirmDialogContent => ({
    header: 'Ta bort kampanj',
    textBlocks: [`Är du säker på att du vill ta bort <strong>${this.campaign.name}</strong>?`],
    confirmText: 'Ta bort kampanj',
    cancelText: 'Avbryt',
  });
}
