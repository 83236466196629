import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { scrollTo } from '../../../utils/dom';
import { UserRole } from '../../../utils/user-roles';
import { AgencyService } from '../../../woo_services.module/AgencyService';
import { AuthService } from '../../../woo_services.module/AuthService';
import { CustomerService } from '../../../woo_services.module/CustomerService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { User } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { UserService } from '../../../woo_services.module/UserService';

@Component({
  selector: 'edit-user',
  templateUrl: './edit-user.component.html',
})
export class EditUser implements OnInit {
  readonly currentUser = this.authService.getUser();
  readonly availableRoles: ReturnType<EditUser['getAvailableRoles']> = this.getAvailableRoles();
  readonly UserRole = UserRole;
  readonly hasAnyRole = this.authService.hasAnyRole;
  readonly hasRole = this.authService.hasRole;

  @ViewChild('editUserSection', { static: true }) editUserSection: ElementRef;

  user: User;
  role: { name: UserRole; translatedName: string } = {
    name: null,
    translatedName: '',
  };
  editUser = false;

  cashUserCustomer = null;
  clientUserCustomer = null;
  allAgencies: { id: string; name: string; role: string }[] = [];

  constructor(
    private agencyService: AgencyService,
    private userService: UserService,
    private translationService: TranslationService,
    private customerService: CustomerService,
    private authService: AuthService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
  ) {}

  async ngOnInit(): Promise<void> {
    // Populate list of all agencies, is used when editing/creating new user
    this.allAgencies = (await this.agencyService.getAgencies()).map((agency) => ({
      id: agency.id,
      name: agency.name,
      role: 'agency_user',
    }));

    await this.refresh();
  }

  userUpdated = async (): Promise<void> => {
    this.editUser = false;
    await this.refresh();
  };

  deactivateUser = async (user: User): Promise<void> => {
    await this.dialogService.openConfirm({
      header: 'Ta bort användare',
      textBlocks: ['Är du säker på att du vill ta bort den här användaren?'],
      confirmText: 'Ta bort användare',
      cancelText: 'Avbryt',
    });
    await this.userService.deactivateUser(user.id);
    if (this.authService.hasRole(UserRole.agencyAdmin)) {
      await this.routingService.navigate(['/accounts']);
      this.dialogService.openSuccess(`${user.first_name} ${user.last_name} är nu borttagen!`);
    } else {
      await this.refresh();
      this.editUser = false;
    }
  };

  reactivateUser = async (user: User): Promise<void> => {
    await this.dialogService.openConfirm({
      header: 'Återaktivera användare',
      textBlocks: ['Är du säker på att du vill återaktivera den här användaren?'],
      confirmText: 'Återaktivera användare',
      cancelText: 'Avbryt',
    });
    await this.userService.reactivateUser(user.id);
    this.dialogService.openSuccess(`${user.first_name} ${user.last_name} är nu återaktiverad!`);
    await this.refresh();
  };

  async resetPassword(userId: string): Promise<void> {
    try {
      await this.dialogService.openConfirm({
        header: 'Återställa lösenord för användare',
        textBlocks: ['Detta gör att nästa gång användaren loggar in behöver den sätta om sitt lösenord. Är du säker?'],
        cancelText: 'Avbryt',
        confirmText: 'Ja, återställ lösenord',
      });
      await this.userService.resetPassword(userId);
      this.dialogService.openSuccess('Nästa gång användaren loggar in behöver den välja ett nytt lösenord.');
    } catch {}
  }

  toggleEdit(): void {
    this.editUser = !this.editUser;
    if (this.editUser) {
      scrollTo(this.editUserSection.nativeElement);
    }
  }

  hasAccountHandlingRole = (): boolean => {
    return this.authService.adminPlannerOrAccounting();
  };

  isAgencyAdmin = function (user: User): number {
    return user.roles.filter((role) => role.name === UserRole.agencyAdmin).length;
  };

  canReactivateAccount = (): boolean => {
    return this.hasRole(UserRole.admin) && !this.user.allowed_to_sign_in && !this.isForgottenUser();
  };

  async refresh(): Promise<void> {
    const user = await this.userService.getUser(this.routingService.getParam(this.route, 'id'));
    this.user = user;

    const firstRole = user.roles.length > 0 ? user.roles[0].name : null;
    let availableRole = this.availableRoles.find((obj) => obj.id === firstRole);

    if ([UserRole.agencyAdmin, UserRole.agencyUser, UserRole.agencyUserAtCustomer].includes(firstRole)) {
      // User is agency_*
      availableRole = { id: UserRole.agency, name: 'Byråanvändare' };
    }

    if (availableRole) {
      this.role.name = availableRole.id;
      this.role.translatedName = availableRole.name;
    } else {
      this.role.name = null;
      this.role.translatedName = '';
    }

    const clientRole = user.roles.find((role) => role.name === UserRole.client);
    const cashRole = user.roles.find((role) => role.name === UserRole.cashUser);
    if (clientRole) {
      this.clientUserCustomer = this.customerService.getCustomer(clientRole.resource_id);
    } else if (cashRole) {
      this.cashUserCustomer = await this.customerService.getCustomer(cashRole.resource_id);
    }
  }

  private getAvailableRoles() {
    return Object.values(UserRole).map((role) => ({ id: role, name: this.translationService.convertRoles(role) }));
  }

  private isForgottenUser = (): boolean => {
    return this.user.first_name === 'Borttagen';
  };
}
