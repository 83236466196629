import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { setFormDirty } from '../../../utils/form';
import { mergeErrors } from '../../../utils/validators';
import { BlockingDialog } from '../../../woo_components.module/dialogs/blocking-dialog.component';
import { SuccessDialog } from '../../../woo_components.module/dialogs/success-dialog.component';
import { DialogService } from '../../../woo_services.module/DialogService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import {
  TTVBookingJobResult,
  TTVBookingService,
  TTYBookingParams,
} from '../../../woo_services.module/TTVBookingService';
import { ToFormGroup } from '../../../utils/types';

@Component({
  selector: 'ttv-campaign-booking',
  templateUrl: './ttv-campaign-booking.component.html',
  styleUrls: ['./ttv-campaign-booking.component.scss'],
})
export class TTVCampaignBooking {
  readonly setFormDirty = setFormDirty;

  errors: any;
  warnings: any;
  form: FormGroup<ToFormGroup<FormModel>> = this.fb.group({
    ttvBookingParams: new FormControl(null, mergeErrors(this.notEmpty, this.validJSON)),
  });

  constructor(
    private ttvBookingService: TTVBookingService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private routingService: RoutingService,
  ) {}

  async createBooking(): Promise<void> {
    this.errors = this.warnings = null;
    const params: TTYBookingParams = JSON.parse(this.form.controls.ttvBookingParams.value);

    const blocking = this.dialogService.create(BlockingDialog);
    blocking.show('Skapar TTV Bokning');

    try {
      const TTVBooking: TTVBookingJobResult = await this.ttvBookingService.updateBooking(params);
      this.dialogService.create(SuccessDialog).open();
      this.routingService.goToDashboardWithCampaign(TTVBooking.campaign_id);
    } catch (e) {
      this.showError(e);
    } finally {
      blocking.close();
    }
  }

  showError = (result: TTVBookingJobResult): void => {
    this.errors = result.errors.length > 0 ? result.errors : null;
    this.warnings = result.warnings.length > 0 ? result.warnings : null;
  };

  private notEmpty(c: FormControl): ValidationErrors {
    return !c.value ? { empty: 'Fältet får inte vara tomt' } : null;
  }

  private validJSON(c: FormControl): ValidationErrors {
    try {
      JSON.parse(c.value);
    } catch (err) {
      return { invalidJSON: err };
    }
    return null;
  }
}

interface FormModel {
  ttvBookingParams: string;
}
