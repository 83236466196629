import { Injectable } from '@angular/core';

const DESKTOP_UPPER = 1199; // eslint-disable-line @typescript-eslint/no-unused-vars
const TABLET_UPPER = 1024; // eslint-disable-line @typescript-eslint/no-unused-vars
const PHONE_UPPER = 768;

@Injectable({ providedIn: 'root' })
export class ViewportService {
  phone(): boolean {
    return Boolean(window.matchMedia(`(max-width: ${PHONE_UPPER}px)`).matches);
  }
}
