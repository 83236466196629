<div class="display--inline-block width--100">
  <voucher-select [targetingId]="targetingId"></voucher-select>
  <div
    class="col-lg-4 col-md-12 padding-left--none padding-right--1x padding-top--1x padding-bottom--none"
    *ngFor="let device of deviceGroups; let index = index"
  >
    <targeting-checkbox
      (onClick)="toggleDeviceGroup(device.id)"
      [checked]="selectedDeviceGroupIds[device.id]"
      [texts]="[device.name]"
      [disabled]="device.disabled">
      <img *ngIf="device.disabled; else enabled" [src]="disabledDeviceGroupsImages[device.name]" alt="{{device.name}} logo" class="device_group-image margin-left--1x">
      <ng-template #enabled>
        <img [src]="deviceGroupsImages[device.name]" alt="{{device.name}} logo" class="device_group-image margin-left--1x"> 
      </ng-template>
    </targeting-checkbox>
  </div>
</div>
