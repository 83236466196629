import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRole } from '../../../utils/user-roles';
import { AdHocContract } from '../../../woo_services.module/AdHocContractService';
import { AuthService } from '../../../woo_services.module/AuthService';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'ad-hoc-contracts-list',
  templateUrl: './ad-hoc-contracts-list.component.html',
})
export class AdHocContractList {
  @Input() contracts: AdHocContract[];
  @Input() enableDelete = true;
  @Input() showEnded = false;
  @Output() onDelete = new EventEmitter<AdHocContract>();

  visibleContracts: AdHocContract[];

  readonly isAdminOrPlanner = this.authService.adminOrPlanner();
  readonly isAdminOrPlannerOrSales =
    this.isAdminOrPlanner || this.authService.hasAnyRole([UserRole.sales, UserRole.salesManager]);
  readonly translateDiscountType = this.translationService.convertDiscount;

  constructor(private authService: AuthService, private translationService: TranslationService) {}

  delete = (contract: AdHocContract): void => {
    if (this.enableDelete && this.isAdminOrPlannerOrSales) {
      this.onDelete.emit(contract);
    }
  };

  ngOnChanges(): void {
    if (!this.contracts) {
      this.visibleContracts = [];
      return;
    }
    this.visibleContracts = this.showEnded ? this.contracts : this.contracts.filter((contract) => !contract.ended);
  }
}
