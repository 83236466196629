import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ConfirmDialogContent } from '../../../../woo_components.module/dialogs/confirm-dialog.component';
import { CampaignService } from '../../../../woo_services.module/CampaignService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { Campaign } from '../../../../woo_services.module/shared-types';

const template = /* html */ `
  <text-button
    icon="close-x"
    [disabled]="disabled"
    (click)="onClick()"
  >
    Stäng
  </text-button>
`;

@Component({
  selector: 'close-campaign-button',
  template,
})
export class CloseCampaignButton {
  @Input() campaign: Campaign;
  @Input() disabled = false;

  constructor(
    private campaignService: CampaignService,
    private routingService: RoutingService,
    private dialogService: DialogService,
  ) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    const content = this.getDialogContent();
    this.dialogService.openConfirm(content).then(
      (comment) => {
        this.closeCampaign(this.campaign, comment);
      },
      () => null,
    );
  };

  private closeCampaign = (campaign: Campaign, comment: string) => {
    this.campaignService
      .close(campaign.id, comment)
      .then(
        () => {
          this.dialogService.openSuccess('Kampanjen har stängts.');
          this.routingService.navigate(['/dashboard']);
        },
        (reason) => {
          if (!(reason instanceof HttpErrorResponse)) {
            const msg = 'Ajdå, något gick snett. Din kampanj kunde inte stängas.';
            this.dialogService.openError(msg);
          }
        },
      )
      .catch(() => {
        const msg = 'Ajdå, nånting gick snett. Testa att refresha browsern och försök igen.';
        this.dialogService.openError(msg);
      });
  };

  private getDialogContent = (): ConfirmDialogContent => ({
    header: 'Stäng avslutad kampanj',
    textBlocks: [`Är du säker på att du vill stänga <strong>${this.campaign.name}</strong>?`],
    comment: {
      label: 'Kommentar för stängning',
      required: true,
    },
    confirmText: 'Stäng kampanj',
    cancelText: 'Avbryt',
  });
}
