<div class="block relative">
  <h1>Lager</h1>
  <div *ngIf="authorizedForInventory">
    <button class="tabbable no-outline" (click)="setTab(0)" [ngClass]="{active : tabVisible === 0}">
      Lager
    </button>
    <button class="tabbable no-outline" (click)="setTab(1)" [ngClass]="{active : tabVisible === 1}">
      Månadsrapporter
    </button>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12"></div>
    </div>
  </div>

  <div *ngIf="tabVisible === 0">
    <inventory-view></inventory-view>
  </div>
  <div *ngIf="tabVisible === 1">
    <inventory-reports></inventory-reports>
  </div>
</div>
