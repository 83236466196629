import { Component, Input } from '@angular/core';

const template = /* html */ `
  <h3 class="text-align--center">{{message}}</h3>
  <dot-loader [large]="true" [visibility]="true"></dot-loader>
  <progress-bar *ngIf="progress !== null && progress !== undefined" [progress]="progress"></progress-bar>
  <h3 *ngIf="!!progress_message" class="text-align--center">{{progress_message}}</h3>
`;

@Component({
  selector: 'progress-content',
  template: template,
})
export class ProgressContent {
  @Input() message: string;
  @Input() progress: number;
  @Input() progress_message: string;
}
