<div class="block">
  <h1>Woo Status</h1>
  <div class="account__card">
    <div class="border--all border-width--strong border--{{metric.css}} margin-bottom--1x" *ngFor="let metric of metrics" >
      <div class="section row margin--none {{metric.css}}" (click)="toggle(metric);">
        <div class="col-1">
          <div class="status__badge with--icon {{metric.css}}"></div>
        </div>
        <div class="col-7">
          <h2 class="text--white">{{metric.name}}</h2>
        </div>
        <div class="col-4">
          <h2 class="float-right text--white">{{metric.description}}</h2>
        </div>
      </div>
      <ng-container *ngIf="expanded[metric.name]">
        <div class="row margin--none">
          <div class="col-12" *ngIf="showMetricDetails(metric)">
            <pre class="margin-top-bottom--1x border p-2 bg-light">{{metric.details}}</pre>
          </div>
          <div class="col-12" *ngIf="showPlannerAction(metric)">
            <div class="padding--1x">
              <h3>Vad kan man göra?</h3>
              <p>{{metric.planner_action}}</p>
            </div>
          </div>
          <div class="col-12" *ngIf="showAdminAction(metric)">
            <div class="padding--1x">
              <h3 *ngIf="!showPlannerAction(metric)">Vad kan man göra?</h3>
              <p>{{metric.admin_action}}</p>
            </div>
          </div>
          <div class="col-12" *ngIf="showMetricTask(metric)">
            <div class="padding--1x">
              <h3>Verktyg</h3>
              <pre>{{metric.task}}</pre>
            </div>
          </div>
          <div class="padding--1x" *ngIf="showCRMUpdateButton(metric)">
            <button class="button primary" (click)="updateCRMStatus()">Uppdatera CRAM-import status</button>
          </div>
        </div>
      </ng-container>
    </div>
    <p *ngIf="metricsUpdated">Uppdaterad <b>{{metricsUpdated}}</b>.</p>  
  </div>
</div>

