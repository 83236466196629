import { Component, Input, OnChanges } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  DeviceGroup,
  DeviceGroupName,
  TargetingDeviceGroup,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { TargetingCombinationRulesService } from '../../../../woo_services.module/TargetingCombinationRulesService';
import { CachedDeviceGroupService } from '../../../services/CachedDeviceGroupService';
import { BookingModel, BookingStore } from '../../../stores/BookingStore';
import { BookingStoreSelectors } from '../../../stores/BookingStoreSelectors';
import { SubscriptionHandler } from '../../subscription-handler';

@Component({
  selector: 'device-group-targeting',
  templateUrl: './device-group-targeting.component.html',
  styleUrls: ['./device-group-targeting.component.scss'],
})
export class DeviceGroupTargeting extends SubscriptionHandler implements OnChanges {
  @Input() targetingId: wooId;
  @Input() addonTargeting = false;

  readonly deviceGroupsImages = {
    [DeviceGroupName.CTV]: 'images/device_group/CTV.svg',
    [DeviceGroupName.desktop]: 'images/device_group/desktop.svg',
    [DeviceGroupName.mobile_and_tablet]: 'images/device_group/mobile_and_tablet.svg',
  };

  readonly disabledDeviceGroupsImages = {
    [DeviceGroupName.CTV]: 'images/device_group/CTV_disabled.svg',
    [DeviceGroupName.desktop]: 'images/device_group/desktop_disabled.svg',
    [DeviceGroupName.mobile_and_tablet]: 'images/device_group/mobile_and_tablet_disabled.svg',
  };

  deviceGroups: DeviceGroup[] = [];
  selectedDeviceGroupIds = {};
  disableDeviceGroup: (tragetingId: wooId, deviceGroupName: string) => boolean;

  constructor(
    private cachedDeviceGroupService: CachedDeviceGroupService,
    private bookingStore: BookingStore,
    private bookingStoreSelectors: BookingStoreSelectors,
    private targetingCombinationRulesService: TargetingCombinationRulesService,
  ) {
    super();

    this.addSubscription(
      this.bookingStore.state$
        .pipe(distinctUntilChanged((prev: BookingModel, curr: BookingModel) => prev.campaign.id === curr.campaign.id))
        .subscribe(this.initDeviceGroups),
    );

    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
    this.disableDeviceGroup = targetingCombinationRulesService.disableDeviceGroup;
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initDeviceGroups = (model: BookingModel): void => {
    this.cachedDeviceGroupService.getDeviceGroups().then((deviceGroups) => {
      if (this.bookingStoreSelectors.editingBooked(model.campaign)) {
        const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
        if (targeting) {
          this.deviceGroups = deviceGroups.filter(
            (dg) => dg.active || targeting.device_groups.map((d) => d.device_group_id).includes(dg.id),
          );
        }
      } else {
        this.deviceGroups = deviceGroups.filter((dg) => dg.active);
      }
      if (this.addonTargeting) {
        this.deviceGroups = this.deviceGroups.filter((dg) => dg.addon_targetable);
      }
    });
  };

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      targeting.device_groups.forEach((dg) => (this.selectedDeviceGroupIds[dg.device_group_id] = true));
      this.deviceGroups.map((dg) => (dg.disabled = this.disableDeviceGroup(this.targetingId, dg.name)));
    }
  };

  toggleDeviceGroup(deviceGroupId: wooId): void {
    this.selectedDeviceGroupIds[deviceGroupId] = !this.selectedDeviceGroupIds[deviceGroupId];
    const selectedDeviceGroups: TargetingDeviceGroup[] = this.deviceGroups
      .filter((dg) => this.selectedDeviceGroupIds[dg.id])
      .map((dg) => {
        return {
          device_group_id: dg.id,
          name: dg.name,
          addon_targetable: dg.addon_targetable,
        };
      });
    this.bookingStore.setDeviceGroups(selectedDeviceGroups, this.targetingId);
  }
}
