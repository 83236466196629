import { Injectable } from '@angular/core';
import { atLeastOneItem } from '../utils/array';
import { nullIfZero } from '../utils/math';
import { generateId } from '../utils/string';
import { UserRole } from '../utils/user-roles';
import { AuthService } from './AuthService';
import { ProductFormatService } from './ProductFormatService';
import { TargetGroupService } from './TargetGroupService';
import { TranslationService } from './TranslationService';
import {
  CreativeShareType,
  GeoTargeting,
  ImageCreativeType,
  ProductChoice,
  ProductFormats,
  RegionalDivision,
  ShareType,
  Targeting,
  TargetingMetaData,
  TargetingType,
  ViewCurrency,
} from './shared-types';

@Injectable({ providedIn: 'root' })
export class TargetingService {
  separator = '-';

  bookingFormatSelections = {
    rbs: 'rbs',
    publisher: 'publisher',
    region: 'region',
    category: 'category',
    daypart: 'daypart',
    programFormat: 'programFormat',
    targetGroup: 'targetGroup',
    deviceGroup: 'deviceGroup',
    behavior: 'behavior',
    publisher_region: 'publisher-region',
    publisher_category: 'publisher-category',
    publisher_targetGroup: 'publisher-targetGroup',
    publisher_deviceGroup: 'publisher-deviceGroup',
    category_region: 'category-region',
    category_region_publisher: 'category-region-publisher',
    programFormat_region: 'programFormat-region',
    publisherGroup: 'publisherGroup',
    publisherGroup_region: 'publisherGroup-region',
    targetGroup_daypart: 'targetGroup-daypart',
  };

  constructor(
    private translationService: TranslationService,
    private targetGroupService: TargetGroupService,
    private authService: AuthService,
    private productFormatService: ProductFormatService,
  ) {}

  createTargeting = (): Targeting => {
    return {
      id: generateId(),
      start_date: null,
      end_date: null,
      advanced_target_groups: [],
      age_target_groups: [],
      categories: [],
      dayparts: [],
      device_groups: [],
      gender_target_groups: [],
      goals: [],
      program_formats: [],
      regions: [],
      publishers: [],
      behaviors: [],
      publisher_groups: [],
      regional_share_type: ShareType.WOO,
      program_format_share_type: ShareType.WOO,
      publisher_share_type: 'woo',
      publisher_group_share_type: 'woo',
      budget: null,
      budgeted_impressions: null,
      additional_budget: null,
      additional_budgeted_impressions: null,
      additional_budget_message: '',
      additional_goal_budget: 0,
      additional_goal_budget_message: '',
      calculate_from_budget: true,
      creatives: [],
      creative_share_type: CreativeShareType.Percent,
      send_invoices: true,
      invoice_disable_message: '',
      sales_order_number: '',
      includes_linear: false,
      view_currency: ViewCurrency.completedViews,
      regional_division: RegionalDivision.ZipCodeBased,
      product_formats: [ProductFormats.longForm],
    };
  };

  updateBudgets = (
    targetings: Targeting[],
    targetingBudgets: { primaryBudget: number; secondaryBudget: number },
  ): void => {
    let budgetField: string, additionalBudgetField: string;

    if (targetings[0].calculate_from_budget) {
      budgetField = 'budget';
      additionalBudgetField = 'additional_budget';
    } else {
      budgetField = 'budgeted_impressions';
      additionalBudgetField = 'additional_budgeted_impressions';
    }
    const oldPrimaryAdditionalBudget = targetings[0][additionalBudgetField];
    targetings[0][additionalBudgetField] = nullIfZero(
      Math.min(targetingBudgets.primaryBudget, targetings[0][additionalBudgetField]),
    );
    targetings[0][budgetField] = nullIfZero(targetingBudgets.primaryBudget - targetings[0][additionalBudgetField]);

    targetings[1][additionalBudgetField] = nullIfZero(
      oldPrimaryAdditionalBudget - targetings[0][additionalBudgetField],
    );
    targetings[1][budgetField] = nullIfZero(targetingBudgets.secondaryBudget - targetings[1][additionalBudgetField]);
  };

  getBookingFormatSelection = (bookingFormat: BookingFormat): string => {
    if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.rbs;
    } else if (
      bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.region;
    } else if (
      !bookingFormat.region &&
      bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.category;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.publisher;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.programFormat;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.targetGroup;
    } else if (
      bookingFormat.region &&
      !bookingFormat.category &&
      bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.publisher_region;
    } else if (
      !bookingFormat.region &&
      bookingFormat.category &&
      bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.publisher_category;
    } else if (
      bookingFormat.region &&
      bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.category_region;
    } else if (
      bookingFormat.region &&
      bookingFormat.category &&
      bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.category_region_publisher;
    } else if (
      bookingFormat.region &&
      !bookingFormat.category &&
      bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.programFormat_region;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.deviceGroup;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.publisher_deviceGroup;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      bookingFormat.publisher &&
      !bookingFormat.program_format &&
      bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.publisher_targetGroup;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.behavior;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.publisherGroup;
    } else if (
      bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.publisherGroup_region;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      !bookingFormat.target_group &&
      !bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.daypart;
    } else if (
      !bookingFormat.region &&
      !bookingFormat.category &&
      !bookingFormat.publisher &&
      !bookingFormat.program_format &&
      bookingFormat.target_group &&
      bookingFormat.device_group &&
      !bookingFormat.behavior &&
      !bookingFormat.publisher_group &&
      !bookingFormat.daypart
    ) {
      return this.bookingFormatSelections.targetGroup_daypart;
    } else {
      console.error('No matching bookingformat', bookingFormat);
      return '';
    }
  };

  getTargetGroupNames = (targeting: Targeting): string[] => {
    return this.targetGroupService.getTargetGroupNames(targeting.age_target_groups, targeting.gender_target_groups);
  };

  getTargetingName = (targeting: Targeting): string => {
    const bookingFormatSelection = this.getBookingFormatSelectionFromTargeting(targeting);
    return bookingFormatSelection.split(this.separator).map(this.translationService.convertProductChoice).join(' & ');
  };

  getBookingFormat = (targeting: Targeting): BookingFormat => {
    const lengthGtZero = (list: any[]): boolean => list && list.length > 0;

    const bookingFormat = {
      category: lengthGtZero(targeting.categories),
      publisher: false, // Publisher as a targeting name concept no longer exists, should always be called RBS
      region: lengthGtZero(targeting.regions),
      advanced_target_group: lengthGtZero(targeting.advanced_target_groups),
      target_group: lengthGtZero(targeting.age_target_groups) || lengthGtZero(targeting.gender_target_groups),
      device_group: lengthGtZero(targeting.device_groups),
      program_format: lengthGtZero(targeting.program_formats),
      publisher_group: lengthGtZero(targeting.publisher_groups),
      behavior: lengthGtZero(targeting.behaviors),
      daypart: lengthGtZero(targeting.dayparts),
    };
    return bookingFormat;
  };

  getBookingFormatSelectionFromTargeting = (targeting: Targeting): string => {
    const BookingFormat = this.getBookingFormat(targeting);
    return this.getBookingFormatSelection(BookingFormat);
  };

  getTargetingNames = (targetings: Targeting[]): string[] => {
    return targetings.map(this.getTargetingName);
  };

  getRBStype = (targeting: Targeting): string => {
    if (targeting.publishers.length) {
      return targeting.publishers.map((publisher) => publisher.name).join(' & ');
    } else {
      return 'WOO';
    }
  };

  stripTargeting = (targeting: Targeting): Targeting => {
    const adminOrPlanner = this.authService.hasAnyRole([UserRole.admin, UserRole.planner]);

    targeting.start_date = null;
    targeting.end_date = null;
    targeting.additional_budget_message = '';
    targeting.additional_budget = null;
    targeting.additional_budgeted_impressions = null;
    targeting.extended_budgets = [];
    targeting.send_invoices = adminOrPlanner ? !targeting.includes_linear : true;
    targeting.invoice_disable_message = '';
    targeting.sales_order_number = '';
    targeting.includes_linear = adminOrPlanner ? targeting.includes_linear : false;
    targeting.gross_rating_factor = null;
    targeting.id = generateId();

    // Set duplicated campaign by default to ZipCodeBased
    targeting.regional_division = RegionalDivision.ZipCodeBased;

    return targeting;
  };

  getMetaData = (targeting: Targeting): TargetingMetaData => {
    return {
      geoTargeting: atLeastOneItem(targeting.regions) ? GeoTargeting.regional : GeoTargeting.national,
      productChoice: this.getProductChoice(targeting),
      targetingType: this.getTargetingType(targeting),
      productFormat: this.productFormatService.convertProductFormatToEnum(targeting.product_formats),
      addonTargeting: this.getAddonTargeting(targeting),
    };
  };

  private getProductChoice(t: Targeting): ProductChoice {
    if (t.age_target_groups.length || t.gender_target_groups.length) {
      return ProductChoice.targetGroup;
    } else if (t.program_formats.length) {
      return ProductChoice.program;
    } else if (t.advanced_target_groups.length) {
      return ProductChoice.advancedTargetGroup;
    } else if (t.categories.length) {
      return ProductChoice.category;
    } else if (t.device_groups.length) {
      return ProductChoice.device;
    } else if (t.dayparts.length) {
      return ProductChoice.daypart;
    } else {
      return ProductChoice.rbs;
    }
  }

  private getAddonTargeting(targeting: Targeting): ProductChoice {
    const targetGroup = targeting.age_target_groups.length || targeting.gender_target_groups.length;
    const advancedTargetGroup = targeting.advanced_target_groups.length;
    const deviceGroup = targeting.device_groups.length;
    if ((targetGroup || advancedTargetGroup) && deviceGroup) return ProductChoice.device;
    return ProductChoice.noChoice;
  }

  private getTargetingType(targeting: Targeting): TargetingType {
    return targeting.creatives.some((c) => c.creative_type === ImageCreativeType.pauseImage)
      ? TargetingType.pause
      : TargetingType.instream;
  }
}

export interface BookingFormat {
  category: boolean;
  publisher: boolean;
  region: boolean;
  advanced_target_group: boolean;
  target_group: boolean;
  device_group: boolean;
  program_format: boolean;
  publisher_group: boolean;
  behavior: boolean;
  daypart: boolean;
}
