import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { lastValueFrom } from 'rxjs';
import { Part } from '../woo_components.module/discounts/ad_hoc_contracts/product-discount-input.component';
import { EnvironmentService } from './EnvironmentService';
import { wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class AdHocContractService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  createAdHocContract(customerId: wooId, contract: AdHocContractParams): Promise<AdHocContract> {
    return lastValueFrom(
      this.http.post(`${this.env.apiUrl}/customers/${customerId}/ad_hoc_contracts`, {
        ad_hoc_contract: this.formatContract(contract),
      }),
    ) as Promise<AdHocContract>;
  }

  deleteAdHocContract(contractId: wooId): Promise<AdHocContract> {
    return lastValueFrom(this.http.delete(`${this.env.apiUrl}/ad_hoc_contracts/${contractId}`)) as Promise<
      AdHocContract
    >;
  }

  getDiscountTypes(): Part[] {
    return [
      { selected: false, discount_rate: null, discount_types: ['national_pause'] },
      { selected: false, discount_rate: null, discount_types: ['national_rbs'] },
      { selected: false, discount_rate: null, discount_types: ['national_specific_and_category'] },
      { selected: false, discount_rate: null, discount_types: ['national_target_group'] },
      { selected: false, discount_rate: null, discount_types: ['local_pause'] },
      { selected: false, discount_rate: null, discount_types: ['local_rbs'] },
      { selected: false, discount_rate: null, discount_types: ['local_specific_and_category'] },
    ];
  }

  private formatContract(contract: AdHocContractParams): AdHocContractParams {
    const cloneContract = cloneDeep(contract);
    cloneContract.start_date = format(new Date(contract.start_date), 'yyyy-MM-dd');
    cloneContract.end_date = format(new Date(contract.end_date), 'yyyy-MM-dd');
    return cloneContract;
  }
}

export interface AdHocContractPart {
  discount_types: string[];
  discount_rate: number;
}

export interface AdHocContract {
  id: wooId;
  start_date: string;
  end_date: string;
  ad_organisation_id: wooId;
  agency_id: wooId | null;
  ended: boolean;
  discounts: AdHocContractPart[];
}

export interface AdHocContractParams {
  start_date: string;
  end_date: string;
  ad_organisation_id: wooId;
  agency_id: wooId | null;
  discounts: AdHocContractPart[];
}
