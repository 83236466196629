<div class="block">
  <h1>Köplista</h1>
  <div>
    <button class="tabbable no-outline" (click)="show.tab = tabs.withdrawalLimits" [ngClass]="{active : show.tab === tabs.withdrawalLimits}">Maxuttag</button>
    <button class="tabbable no-outline" (click)="show.tab = tabs.programFormats" [ngClass]="{active : show.tab === tabs.programFormats}">Programformat</button>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12"></div>
    </div>
  </div>

  <div class="tabbable__body margin--none shadow-z-1" *ngIf="show.tab === tabs.withdrawalLimits">
    <div class="margin-top--3x">
      Siffrorna nedan avser maxuttag för antal reklamexponeringar på <strong>TV4</strong>. Reklamlager stäms av med Reklamplanering, ansvarig säljare eller via bokning i WOO Manager.
    </div>
    <form (ngSubmit)="filter()" id="inventory-form">
      <div class="row">
        <inventory-weeks-picker (weeksChanged)="weeksChanged($event)" [disabled]="setPeriodFromFormat"></inventory-weeks-picker>
        <div class="col-xs-4 block">
          <div class="relative margin-top--3x">
            <input id="autoDate" name="setPeriodFromFormat" type="checkbox" [(ngModel)]="setPeriodFromFormat" class="checkbox" >
            <label for="autoDate" class="checkbox text-align--left">Sätt period utifrån programformaten</label>
          </div>
        </div>
      </div>
      <p><span class="icon-warning text--info"></span> Alla kombinationer nedan är inte köpbara, se <a class="text--info" href="/#/pricelist" target="_blank">prislistan</a> för möjliga kombinationer.</p>
      <div class="row">
        <dot-loader [visibility]="fetchingFilters" description="Laddar filter"></dot-loader>
        <div class="flex__container">
          <inventory-filter *ngFor="let filterOption of getFilterOptionsBuylist()"
                            class="col-sm-4 col-xs-6"
                            [filterOption]="filterOption"
                            (onChange)="filters[filterOption.filter_key] = $event.newValue">
          </inventory-filter>
        </div>
      </div>
      <button class="button margin-top--3x" type="submit" [disabled]="fetchingData">Hämta köplista</button>
      <button class="link margin-top--3x" type="button" (click)="clearFilters()">Rensa filter</button>
    </form>

    <div id="inventory-result">
      <div class="block" *ngIf="fetchingData">
        <dot-loader [visibility]="fetchingData" description="Hämtar köplista"></dot-loader>
      </div>
      <div class="margin-top--3x" *ngIf="inventoryTree !== undefined">
        <div class="text--gray pull-right">
          <p class="text--small">
            <span class="icon-refresh"></span> Köplistan hämtades {{timeStamp | date:"d MMMM yyyy 'kl.'H.mm"}}
          </p>
        </div>
        <div class="text--gray pull-left">
          <p class="text--small">
            {{breadcrumb}}
          </p>
        </div>
        <div class="clearfix"></div>

        <inventory-visualization
          #buylistResults
          [inventoryTree]="inventoryTree"
          [filterOptions]="filterOptionsBuylist"
          [columnNames]="columnNames"
          [viewCurrencyLabel]="viewCurrencyLabel(presentationCurrency)">
        </inventory-visualization>
      </div>
    </div>
  </div>

  <div *ngIf="show.tab === tabs.programFormats" class="tabbable__body margin--none hadow-z-1">
    <program-formats-table [programFormats]="programFormats"></program-formats-table>
  </div>

</div>
