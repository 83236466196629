import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DebounceService {
  debounce = (fn: any, time: number): ((...args: any) => void) => {
    let timerId = null;

    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(function () {
        fn(...args);
      }, time);
    };
  };
}
