import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { verticalExpansion } from '../../utils/animations';
import { SimpleChanges } from '../../utils/types';
import { FormatterService, TargetingLabel } from '../../woo_services.module/FormatterService';
import { Targeting, TargetingMetaData } from '../../woo_services.module/shared-types';
import { TargetingService } from '../../woo_services.module/TargetingService';

const template = /* html */ `
  <div (click)="toggleExpanded()" class="toggle-container">
    <div class="display--flex justify-content--space-between">
      <h3 class="display--inline-block margin--none">
        <targeting-label [label]="targetingLabel"></targeting-label>
      </h3>

      <button class="toggle-button">
        <span class="chevron large text--info" [ngClass]="{bottom: expanded}">
        </span>
      </button>
    </div>
    <div
      class="width--100 targeting-name-wrapper"
    >
      <p
        *ngFor="let targetingName of targetingNames"
        class="text--thin margin--none"
      >
        {{targetingNamesData[targetingName]}}
      </p>
    </div>
  </div>
  <div class="display--flex width--100 expandable-content-wrapper">
    <div
      *ngIf="expanded"
      class="margin-top--3x width--100 expandable-content"
      [@verticalExpansion]
      (@verticalExpansion.done)="onExpandEnd.emit($event)"
    >
      <ng-content></ng-content>
    </div>
  </div>
`;

@Component({
  selector: 'expandable-targeting-header',
  template: template,
  styleUrls: ['./expandable-targeting-header.component.scss'],
  animations: [verticalExpansion(500)],
})
export class ExpandableTargetingHeader implements OnChanges {
  targetingNamesData: Record<TargetingName, string>;
  targetingNames: string[];
  targetingLabel: TargetingLabel;

  @Input() targeting: Targeting;
  @Input() targetingMetaData: TargetingMetaData;
  @Input() expanded = true;
  @Output() expandedChange = new EventEmitter<boolean>();
  @Output() onExpandEnd = new EventEmitter<void>();

  constructor(private formatterService: FormatterService, private targetingService: TargetingService) {}

  ngOnChanges(changes: SimpleChanges<ExpandableTargetingHeader>): void {
    if (changes.targeting && this.targeting) {
      if (!this.targetingMetaData) {
        this.targetingMetaData = this.targetingService.getMetaData(this.targeting);
      }

      this.targetingNamesData = {
        regions: this.targeting.regions.map((item) => item.name).join(', '),
        programFormats: this.targeting.program_formats.map((item) => item.name).join(', '),
        targetGroups: this.targetGroupNames()
          .map((item) => item.name)
          .join(', '),
        categories: this.targeting.categories.map((item) => item.name).join(', '),
        deviceGroups: this.targeting.device_groups.map((item) => item.name).join(', '),
        behaviors: this.targeting.behaviors.map((item) => item.name).join(', '),
        dayparts: this.targeting.dayparts.map((item) => item.name).join(', '),
        advancedTargetGroups: this.targeting.advanced_target_groups.map((item) => item.name).join(', '),
      };

      this.targetingNames = Object.keys(this.targetingNamesData).filter((item) => this.targetingNamesData[item]);

      this.targetingLabel = this.formatterService.getTargetingLabel(this.targeting, this.targetingMetaData);
    }

    if (changes.targetingMetaData && this.targetingMetaData) {
      this.targetingLabel = this.formatterService.getTargetingLabel(this.targeting, this.targetingMetaData);
    }

    if (changes.expanded) {
      this.expandedChange.emit(this.expanded);
    }
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

  private targetGroupNames(): Array<{ name: string }> {
    return this.targetingService.getTargetGroupNames(this.targeting).map((tg) => ({ name: tg }));
  }
}

enum TargetingName {
  regions = 'regions',
  programFormats = 'programFormats',
  advancedTargetGroups = 'advancedTargetGroups',
  targetGroups = 'targetGroups',
  categories = 'categories',
  deviceGroups = 'deviceGroups',
  behaviors = 'behaviors',
  dayparts = 'dayparts',
}
