<div class="section primary padding--2x margin-bottom--2x">
  <div *ngIf="!showDeleteQuestion">
    <name-period-header [name]="campaign.name" [start]="targeting.start_date" [end]="targeting.end_date"></name-period-header>
    <card-banner [status]="campaign.status" class="card-banner-mobile"></card-banner>
    <ng-container [ngSwitch]="campaign.status">
      <two-col-row *ngSwitchCase="CampaignStatus.unbooked" left="Sparad" [right]="campaign.updated_at | date"></two-col-row>
      <two-col-row *ngSwitchCase="CampaignStatus.rejected" left="Avvisad" [right]="campaign.updated_at | date"></two-col-row>
      <two-col-row *ngSwitchCase="CampaignStatus.cancelled" left="Avbokad" [right]="campaign.updated_at | date"></two-col-row>
      <two-col-row *ngSwitchDefault left="Kostnad" [right]="targeting.budget | currency:'SEK'"></two-col-row>
    </ng-container>
    <div class="text-center">
      <ng-container [ngSwitch]="campaign.status">
        <button *ngSwitchCase="CampaignStatus.unbooked" class="button primary edit-button" (click)="$event.stopPropagation(); editCampaign(campaign.id)" disabled>Redigera</button>
        <div *ngSwitchCase="CampaignStatus.rejected">
          <div class="text-align--left">
            {{campaign.reject_message}}
          </div>
          <button class="button primary edit-button" (click)="$event.stopPropagation(); editCampaign(campaign.id)" disabled>Åtgärda</button>
        </div>
        <ng-container *ngSwitchCase="CampaignStatus.cancelled">
          <br>
          <br>
        </ng-container>
        <two-col-row *ngSwitchDefault left="Köpta visningar" [right]="campaign.agreement?.net_impressions || 0 | number"></two-col-row>
      </ng-container>
      <ng-container *ngIf="removeButtonStatuses.includes(campaign.status); then removeButton; else row2"></ng-container>
      <ng-template #removeButton>
        <div>
          <button class="link primary" (click)="$event.stopPropagation(); showDeleteQuestion = true">Radera kampanj</button>
        </div>
      </ng-template>
      <ng-template #row2>
        <two-col-row left="Styrning" [right]="campaign.targetingName"></two-col-row>
      </ng-template>
    </div>
  </div>
  <div *ngIf="showDeleteQuestion" class="cash-card--splash text--center" >
    <h3 class="text-align--center margin-bottom--3x">Är du säker på att du vill radera din kampanj?</h3>
    <button class="button primary danger margin-bottom--2x" (click)="$event.stopPropagation(); removeCampaign(campaign.id)">Radera</button>
    <button class="button secondary" (click)="$event.stopPropagation(); showDeleteQuestion = false">Nej, ta inte bort den</button>
  </div>
</div>
