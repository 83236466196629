import { Directive, Input, OnInit, Self, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SubscriptionHandler } from '../../advanced_campaign_booking.module/components/subscription-handler';
import { InputErrors } from './input-errors.component';

@Directive({
  selector: '[wooInputErrors]',
})
export class InputErrorsDirective extends SubscriptionHandler implements OnInit {
  @Input('wooInputErrors') options: { absolute?: boolean } = { absolute: false };

  constructor(private viewContainerRef: ViewContainerRef, @Self() private ngControl: NgControl) {
    super();
  }

  ngOnInit(): void {
    const componentRef = this.viewContainerRef.createComponent(InputErrors);
    componentRef.instance.positionAbsolute = this.options.absolute;
    componentRef.instance.errors = this.ngControl.errors;
    componentRef.changeDetectorRef.detectChanges();

    this.addSubscription(
      this.ngControl.statusChanges.subscribe(() => {
        componentRef.instance.errors = this.ngControl.errors;
        componentRef.changeDetectorRef.detectChanges();
      }),
    );
  }
}
