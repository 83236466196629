import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SimpleChanges } from '../../../../utils/types';
import { OutstreamSite } from '../../../../woo_services.module/OutstreamSiteService';
import { OutstreamEstimates } from '../../../../woo_services.module/shared-types';

@Component({
  selector: 'outstream-estimates-input',
  templateUrl: './outstream-estimates-input.component.html',
  styles: ['.fixed-max-width { max-width: 100px; }'],
})
export class OutstreamEstimatesInput implements OnChanges {
  @Input() sites: OutstreamSite[] = [];

  @Input() shares: OutstreamEstimates = {};
  @Output() sharesChange = new EventEmitter<OutstreamEstimates>();

  @Input() withdrawalLimits: OutstreamEstimates = {};
  @Output() withdrawalLimitsChange = new EventEmitter<OutstreamEstimates>();

  ngOnChanges(changes: SimpleChanges<OutstreamEstimatesInput>): void {
    if (changes.shares && !this.shares) {
      this.shares = {};
    }

    if (changes.withdrawalLimits && !this.withdrawalLimits) {
      this.withdrawalLimits = {};
    }
  }
}
