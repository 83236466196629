<div class="tabbable__panel panel-margin right section info clearfix">
  <div class="col-xs-12">
    <h2>
      Fakturor
    </h2>
  </div>
</div>

<div class="tabbable__body clearfix">
  <dot-loader [visibility]="show.loadingInvoices" description="Hämtar fakturor"></dot-loader>
  <div *ngIf="invoicesDisabled">
    <p>Faktureringen <strong>avstängd:</strong> <span class="thin"> {{invoiceDisabledMessages().join(', ')}}</span></p>
    <p>Salesorder-nummer: <span class="thin">{{salesOrderNumbers().join(', ')}}</span></p>
  </div>
  <button id="adminPanelButton" class="button primary-1" (click)="show.adminPanel = !show.adminPanel" *ngIf="campaign.status !== 'closed' && !invoicesDisabled">
    {{show.adminPanel ? 'Dölj' : 'Visa'}} adminpanel
  </button>
  <div *ngIf="campaign.status === 'closed'"><span class="icon-warning text--info"></span> Den här kampanjen är stängd och faktureringen går därmed inte att ändra.</div>
  <div *ngIf="show.adminPanel">
    <h3>Adminpanel</h3>
    <div class="row">
      <div class="col-sm-12">
        <button *ngIf="!show.invoiceForm" id="newCreditInvoiceButton" class="button primary-1" (click)="newInvoice(InvoiceType.Credit)">Skapa kreditfaktura</button>
        <button *ngIf="!show.invoiceForm" id="newDebitInvoiceButton" class="button secondary" (click)="newInvoice(InvoiceType.Debit)">Skapa debetfaktura</button>
        <invoice-form
          *ngIf="show.invoiceForm"
          [invoiceType]="invoiceType"
          [agencyDiscount]="agency.agency_discount"
          (onAbort)="closeInvoiceForm()"
          (onSubmit)="createManualInvoice($event)">
        </invoice-form>
      </div>
    </div>
  <div *ngIf="invoices.length" class="divider block info"></div>
  </div>

  <div *ngIf="invoices.length">
    <h3>Fakturering i siffror</h3>
    <div class="row">
      <div class="col-sm-12">
        <table class="table table--woo table--small">
          <tbody>
            <tr>
              <td>Fakturerat netnet: </td><td>{{invoiceSums.sent_total_gross | currency:'SEK'}}</td>
              <td>Ofakturerat netnet: </td><td>{{invoiceSums.unsent_total_gross |currency:'SEK'}}</td>
              <td>Summa netnet: </td><td>{{(invoiceSums.unsent_total_gross + invoiceSums.sent_total_gross) | currency:'SEK'}}</td>
            </tr>
            <tr>
              <td>Fakturerat moms: </td><td>{{invoiceSums.sent_total_vat | currency:'SEK'}}</td>
              <td>Ofakturerat moms: </td><td>{{invoiceSums.unsent_total_vat | currency:'SEK'}}</td>
              <td>Summa moms: </td><td>{{(invoiceSums.unsent_total_vat + invoiceSums.sent_total_vat) | currency:'SEK'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="divider block info"></div>
  </div>

  <div *ngFor="let invoice of invoices; index as invoiceIndex" class="relative">
    <div class="cancelled__cover col-sm-12" *ngIf="invoice.status === 'cancelled'">
    </div>
    <div>
      <h3>Faktura #{{invoiceIndex + 1}}</h3>
      <div *ngIf="invoice.status === 'cancelled'" class="text--danger strong text--medium text-uppercase">Makulerad</div>

      <div *ngIf="invoice.status === 'created' && !invoice.cancelled && campaign.status !== 'closed'" class="margin-bottom--1x">
        <button class="button danger" (click)="cancelInvoice(invoice)">Makulera</button>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <table class="table table--hover">
            <tbody>
              <tr>
                <td class="text--medium text--thin">Faktura-id</td>
                <td class="text--medium">{{invoice.id}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin">Status</td>
                <td *ngIf="invoice.status === InvoiceStatus.created" class="text--medium">Ej skickad</td>
                <td *ngIf="invoice.status === InvoiceStatus.sent" class="text--medium">Skickad</td>
                <td *ngIf="invoice.status === InvoiceStatus.cancelled" class="text--medium text--danger">Makulerad</td>
              </tr>
              <tr>
                <td class="text--medium text--thin">Planerat fakturadatum</td>
                <td class="text--medium">{{invoice.invoice_date | date}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin">Fakturerat</td>
                <td *ngIf="invoice.status !== InvoiceStatus.sent" class="text--medium">-</td>
                <td *ngIf="invoice.status === InvoiceStatus.sent" class="text--medium">{{invoice.sent_at | date}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin">Internt meddelande</td>
                <td class="text--medium">{{invoice.internal_message}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin">Betalvillkorskod</td>
                <td class="text--medium">{{paymentTerms(invoice)}}</td>
              </tr>
              <tr *ngIf="showAttestStatus(invoice)">
                <td class="text--medium text--thin">Attesteringsstatus</td>
                <td class="text--medium">{{attestStatus(invoice.attest_status)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6">
          <table class="table">
            <tbody>
              <tr>
                <td class="text--medium text--thin">Kampanjnamn</td>
                <td class="text--medium">{{invoice.campaign_name}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin">Fakturamottagare</td>
                <td class="text--medium">{{invoice.customer_name}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin">Org.no</td>
                <td class="text--medium">{{invoice.customer_org_no}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin">Fakturaadress</td>
                <td class="text--medium">{{invoice.customer_address1}} {{invoice.customer_address2}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin"> </td>
                <td class="text--medium">{{invoice.customer_zip}} {{invoice.customer_city}}</td>
              </tr>
              <tr>
                <td class="text--medium text--thin"> </td>
                <td class="text--medium">{{invoice.customer_country}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">

          <table class="table table--woo table--hover">
            <thead>
              <tr class="text--medium">
                <td class="text--medium">Beskrivning</td>
                <td>Intäktskonto</td>
                <td>Summa</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of invoice.rows">
                <td>{{row.description}}</td>
                <td>{{row.revaccount}}</td>
                <td>{{row.amount | currency:'SEK':''}}</td>
              </tr>
              <tr>
                <td>Beräknad moms</td>
                <td></td>
                <td>{{invoice.total_vat | currency:'SEK':''}}</td>
              </tr>
              <tr>
                <td>Fakturerat netnet</td>
                <td></td>
                <td>{{invoice.total_gross | currency:'SEK':''}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="divider block info"></div>
  </div>
</div>
