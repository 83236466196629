import { range, sample } from 'lodash-es';

export function stringHash(str: string): number {
  let hash = 0,
    i,
    chr,
    len;
  if (str.length === 0) {
    return hash;
  }
  for (i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function matchString(str: string, pattern: string): boolean {
  return pattern
    .split(' ')
    .filter((pat) => pat.length > 0)
    .every((pat) => str.includes(pat));
}

export function randomString(length: number): string {
  const CHARACTERS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return range(length).reduce((acc) => acc + sample(CHARACTERS), '');
}

const GENERATED_ID_PREFIX = 'WOO_GENERATED_ID-';

export function generateId(): string {
  return GENERATED_ID_PREFIX + randomString(10);
}

export function isGeneratedId(id?: string): boolean {
  return id && id.startsWith(GENERATED_ID_PREFIX);
}

export function removeGeneratedId(obj: { id: string }): void {
  if (isGeneratedId(obj.id)) {
    delete obj.id;
  }
}

export function titleCase(str: string): string {
  return (str[0] || '').toUpperCase() + str.slice(1, str.length);
}

export class IdScope {
  private ids = {};

  getOrCreateId = (key: string): string => {
    if (!this.ids[key]) {
      this.ids[key] = key + '-' + randomString(10);
    }
    return this.ids[key];
  };
}
