import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ConfirmDialogContent } from '../../../../woo_components.module/dialogs/confirm-dialog.component';
import { CampaignService } from '../../../../woo_services.module/CampaignService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { Campaign } from '../../../../woo_services.module/shared-types';

const template = /* html */ `
  <text-button
    icon="close-x"
    [disabled]="disabled"
    (click)="onClick()"
  >
    Avboka
  </text-button>
`;

@Component({
  selector: 'cancel-campaign-button',
  template,
})
export class CancelCampaignButton {
  @Input() campaign: Campaign;
  @Input() disabled = false;

  constructor(
    private campaignService: CampaignService,
    private routingService: RoutingService,
    private dialogService: DialogService,
  ) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    const content = this.getDialogContent();
    this.dialogService.openConfirm(content).then(
      (comment) => {
        this.cancelCampaign(this.campaign, comment);
      },
      () => null,
    );
  };

  private cancelCampaign = (campaign: Campaign, comment: string) => {
    this.dialogService.openBlocking('Avbokar kampanjen');
    this.campaignService
      .cancel(campaign.id, comment)
      .then(
        (campaign: Campaign) => {
          this.dialogService.openSuccess('Kampanjen har avbokats.');
          this.routingService.navigateWithReload(['/dashboard', campaign.id]);
        },
        (reason) => {
          if (!(reason instanceof HttpErrorResponse)) {
            const msg = 'Ajdå, något gick snett. Din kampanj kunde inte avbokas.';
            this.dialogService.openError(msg);
          }
        },
      )
      .catch(() => {
        const msg = 'Ajdå, nånting gick snett. Testa att refresha browsern och försök igen.';
        this.dialogService.openError(msg);
      });
  };

  private getDialogContent = (): ConfirmDialogContent => {
    const content: ConfirmDialogContent = {
      header: 'Avboka kampanj',
      textBlocks: [`Är du säker på att du vill avboka <b>${this.campaign.name}</b>?`],
      comment: {
        label: 'Statusmeddelande',
      },
      confirmText: 'Avboka kampanj',
      cancelText: 'Avbryt',
    };

    if (this.campaign.has_sent_invoices) {
      content.consent = {
        text: 'Fakturorna har redan skickats',
        label: 'Jag är medveten om att jag avbokar en kampanj med skickade fakturor.',
      };
    }
    return content;
  };
}
