import { Component, Input } from '@angular/core';

const template = /* html */ `
  <text-button
    icon="edit"
    [disabled]="disabled"
  >
    {{textOverride || 'Redigera'}}
  </text-button>
`;

@Component({
  selector: 'edit-button',
  template,
})
export class EditButton {
  @Input() disabled = false;
  @Input() textOverride: string = null;
}
