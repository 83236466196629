import { Injectable } from '@angular/core';
import { CodeErrors, CodeService } from '../shared-types';
import { VideoCodeService } from './VideoCodeService';

@Injectable({ providedIn: 'root' })
export class MmsCodeService extends VideoCodeService implements CodeService {
  protected readonly prefix = 'H';
  protected readonly typeCodeRange = { min: 1, max: 6 };
  protected readonly lengthIndexes = { start: 7, end: 9 };

  /**
   * Validates adstream codes with the explicit format
   *  H      – prefix, always 'H'
   *  1      – type [1-6]
   *  ABCDE  – movie code [A-Z]{5}
   *  010    – film length in seconds, 0-999
   */
  validate = (code: string): CodeErrors => {
    const errors = {} as CodeErrors;
    errors.prefix = this.prefixError(code, this.prefix);
    errors.typeCode = this.typeCodeError(code, this.typeCodeRange.min, this.typeCodeRange.max);
    errors.codeLength = this.codeLengthError(code);
    errors.whiteSpace = this.whiteSpaceError(code);
    errors.filmLength = this.filmLengthError(
      code,
      this.creativeService.getCreativeLengths(),
      this.lengthIndexes.start,
      this.lengthIndexes.end,
    );
    return this.removeBlankKeys(errors);
  };

  getFilmLength(code: string): number | null {
    const canReadLengthFromCode = code?.length > this.lengthIndexes.end;
    if (!code || !canReadLengthFromCode) {
      return null;
    }
    return this.getVideoCodeFilmLength(code, this.lengthIndexes.start, this.lengthIndexes.end);
  }
}
