import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

@Directive({
  selector: '[wooMinValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: WooMinValidator,
      multi: true,
    },
  ],
})
export class WooMinValidator implements Validator {
  @Input('wooMinValidator') min: number;

  validate(c: AbstractControl): ValidationErrors {
    return Validators.min(this.min)(c);
  }
}
