import { Component, OnInit } from '@angular/core';
import { saveFileAs } from '../../../utils/files';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { GoogleTagManagerService } from '../../../woo_services.module/GoogleTagManagerService';
import { BookingStore } from '../../stores/BookingStore';
import { BookingStoreSelectors } from '../../stores/BookingStoreSelectors';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
<text-button class="display--block margin--1x" icon="download" (click)="download()" [disabled]="disabled">
  Ladda ner summering
</text-button>
`;

@Component({
  selector: 'download-summary-button',
  template: template,
})
export class DownloadSummaryButton extends SubscriptionHandler implements OnInit {
  disabled = false;

  constructor(
    private bookingStore: BookingStore,
    private campaignService: CampaignService,
    private googleTagManagerService: GoogleTagManagerService,
    private selectors: BookingStoreSelectors,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(
      this.bookingStore.state$.subscribe((model) => {
        this.disabled = !this.selectors.isValid(model);
      }),
    );
  }

  download = (): void => {
    this.googleTagManagerService.downloadSummaryAbf();
    const campaign = this.bookingStore.state.campaign;
    this.campaignService.downloadSummary(campaign).then((res) => {
      saveFileAs(res.body, `Sammanfattning - ${campaign.name}.xlsx`);
    });
  };
}
