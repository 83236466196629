import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { roundDecimalError } from 'app/scripts/utils/math';
import { SystemService } from 'app/scripts/woo_services.module/SystemService';
import { CategoryService } from '../../../../woo_services.module/CategoryService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import {
  Category,
  PerIdEstimates,
  ProductFormat,
  Publisher,
  SlotType,
  SlotTypeEstimates,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageCategories implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    withdrawalLimitsHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };
  readonly tableHeaders = ['Publicist', 'Placering', 'Kategori', 'Preroll', 'Midroll', 'Preroll', 'Midroll'];
  readonly staticFields = 3;
  withdrawalLimitsHaveChanged = false;
  withdrawalLimitRows: WithdrawalLimitRow[] = [];
  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  categories: Category[] = [];
  selectedCategory: Category;
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  disney_feature_toggle_on = false;
  tv_channels_feature_toggle_on = false;
  translateProductFormat: (args: any) => string;
  constructor(
    private publisherService: PublisherService,
    private systemService: SystemService,
    private productFormatService: ProductFormatService,
    private categoryService: CategoryService,
    private dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled, ExtraPublisherField.categories)
      .then((publishers) => {
        this.publishers = publishers.filter((p) => p.enabled);
        this.getAndBuildCategoryRows();
      })
      .finally(() => {
        this.show.loading = false;
        this.utilityService.setStickyPosition(this.stickyHeader, 2, 0);
        this.utilityService.setStickyPosition(this.stickyTableHeader, 1, this.stickyHeader.nativeElement.offsetHeight);
        scrollTo(this.parentHeader);
      });
  }

  async getAndBuildCategoryRows(): Promise<void> {
    // TODO: Remove once Disney and TV-kanaler feature toggles are no longer needed.
    this.disney_feature_toggle_on = await this.systemService.isFeatureToggleOn('disney_feature_toggle');
    this.tv_channels_feature_toggle_on = await this.systemService.isFeatureToggleOn('tv_channels_feature_toggle');

    Promise.all([
      this.productFormatService.getProductFormats(
        ExtraProductFormatField.enabled,
        ExtraProductFormatField.categoryWithdrawalLimits,
        ExtraProductFormatField.slotTypes,
        ExtraProductFormatField.withdrawalLimits,
        ExtraProductFormatField.productFormatShares,
      ),
      this.categoryService.getCategories(),
    ]).then(([productFormats, categories]) => {
      this.categories = categories;
      this.productFormats = productFormats;
      this.productFormats = productFormats.filter((f) => f.enabled);
      this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(this.productFormats);
      this.buildWithdrawalLimitRows();
    });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildCategoryRows();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedCategory || this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade beteendesegment sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.updatingEstimates = true;
        const CategoryShares = this.categories
          .filter((category) => category.id !== 'RBS-ID')
          .flatMap((category) => {
            return category.product_format_category_share;
          });
        Promise.all([
          this.productFormatService.updateProductFormats(this.productFormats),
          this.productFormatService.updateCategoryShares(CategoryShares),
        ])
          .then(() => {
            this.show.withdrawalLimitsHaveChanged = true;
            this.getAndBuildCategoryRows();
          })
          .catch((err) => {
            this.dialogService.openError(this.translationService.convertError(err.error.error));
          });
      })
      .finally(() => {
        this.setEditing(false);
        this.show.updatingEstimates = false;
        this.dialogService.closeBlocking();
      });
  }

  autoFill(row: WithdrawalLimitRow): void {
    const shares = this.categories
      .find((category) => category.id === row.categoryId)
      .product_format_category_share.find((shares) => shares.product_format_id !== row.productFormatId);

    shares.preroll = roundDecimalError(1 - this.getCategoryRowShares(row).preroll, 3);
  }

  rowVisible(obj: WithdrawalLimitRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const categoryFiltered = this.selectedCategory && obj.categoryId !== this.selectedCategory.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || categoryFiltered || productFormatFiltered);
  }

  getWithdrawalLimits(row: WithdrawalLimitRow): PerIdEstimates | SlotTypeEstimates {
    const productFormat = this.productFormats.find((productFormat) => productFormat.id === row.productFormatId);
    return productFormat.category_withdrawal_limits[row.categoryId];
  }

  getCategoryRowShares(row: WithdrawalLimitRow): SlotTypeEstimates {
    return this.getCategoryShares(row.categoryId, row.productFormatId);
  }

  getCategoryShares(categoryId: string, productFormatId: string): SlotTypeEstimates {
    const shares = this.categories
      .find((category) => category.id === categoryId)
      .product_format_category_share.find((shares) => shares.product_format_id === productFormatId);
    return shares;
  }

  // TODO: remove once Disney and TV-kanaler feature toggle is no longer needed.
  matchesCategoryId(categoryId: string): boolean {
    return this.categories.some((category) => category.id === categoryId);
  }

  private buildWithdrawalLimitRows(): void {
    let withdrawalLimitRows: WithdrawalLimitRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        Object.keys(productFormat.category_withdrawal_limits)
          .filter((categoryId) => {
            if (!this.disney_feature_toggle_on || !this.tv_channels_feature_toggle_on) {
              return this.matchesCategoryId(categoryId);
            }
            return true;
          })
          .map((categoryId) => {
            const shares = this.getCategoryShares(categoryId, productFormat.id);
            withdrawalLimitRows = [
              ...withdrawalLimitRows,
              {
                publisher: publisher.name,
                publisherId: publisher.id,
                productFormat: productFormat.name,
                productFormatId: productFormat.id,
                category: this.utilityService.getCategoryName(categoryId, publisher.categories),
                categoryId: categoryId,
                withdrawalLimitsPreroll: productFormat.category_withdrawal_limits[categoryId].preroll,
                withdrawalLimitsMidroll: productFormat.category_withdrawal_limits[categoryId].midroll,
                withdrawalLimitsPostroll: productFormat.category_withdrawal_limits[categoryId].postroll,
                sharesPreroll: shares.preroll,
                sharesMidroll: shares.midroll,
                sharesPostroll: shares.postroll,
              },
            ];
          });
      });
    });
    this.withdrawalLimitRows = this.sortRows(withdrawalLimitRows);
  }

  private sortRows(rows: WithdrawalLimitRow[]): WithdrawalLimitRow[] {
    const sorted = rows.sort((a, b) => {
      if (a.publisher !== b.publisher) return a.publisher.localeCompare(b.publisher);
      if (a.category !== b.category) return a.category.localeCompare(b.category);
      return a.productFormat.localeCompare(b.productFormat);
    });
    return sorted;
  }
}

interface WithdrawalLimitRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  category: string;
  categoryId: wooId;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
}
