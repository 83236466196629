import { Component, EventEmitter, Output } from '@angular/core';
import { CashCampaignService } from '../../../cash_dashboard.module/services/CashCampaignService';
import { FileService, FileType } from '../../../woo_services.module/FileService';
import { Campaign, Creative, CreativeShareType, Targeting } from '../../../woo_services.module/shared-types';

enum PaymentMethod {
  invoice,
}

@Component({
  selector: 'booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss'],
})
export class BookingSummary {
  public PaymentMethod = PaymentMethod;
  public selectedPaymentMethod = PaymentMethod.invoice;
  public acceptTerms = false;
  public getUrlToFile = this.fileService.getUrlToFile;
  public estimation = this.cashCampaignService.current.estimation;
  FileType = FileType;

  @Output() abortSummary = new EventEmitter<void>();
  @Output() bookCampaign = new EventEmitter<void>();

  get campaign(): Campaign & { creatives: Creative[]; creative_share_type: CreativeShareType } {
    return this.cashCampaignService.campaign;
  }

  get creative(): Creative {
    return this.campaign.creatives[0];
  }

  get targeting(): Targeting {
    return this.cashCampaignService.campaign.targetings[0];
  }

  constructor(private cashCampaignService: CashCampaignService, private fileService: FileService) {}

  bookable = (): boolean => this.selectedPaymentMethod !== null && this.acceptTerms;

  abort = (): void => this.abortSummary.emit();

  book = (): void => this.bookCampaign.emit();
}
