import { Component } from '@angular/core';
import { asyncScheduler, EMPTY, empty, Observable, of, scheduled, throwError } from 'rxjs';
import { catchError, mapTo, mergeMap } from 'rxjs/operators';
import {
  ConfirmDialogContent,
  ConfirmDialogWarningBlock,
} from '../../woo_components.module/dialogs/confirm-dialog.component';
import { SuccessDialog } from '../../woo_components.module/dialogs/success-dialog.component';
import { MessageType } from '../../woo_components.module/feedback/context-message.component';
import { AuthService } from '../../woo_services.module/AuthService';
import { DialogService } from '../../woo_services.module/DialogService';
import { FormatterService } from '../../woo_services.module/FormatterService';
import { RoutingService } from '../../woo_services.module/RoutingService';
import {
  Campaign,
  CampaignBookingJobResult,
  CampaignEstimationPart,
  CampaignStatus,
  SendToAdServerJobResult,
} from '../../woo_services.module/shared-types';
import { TermsService } from '../../woo_services.module/TermsService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { AdvancedCampaignService } from '../services/AdvancedCampaignService';
import { BookingValidationService } from '../services/BookingValidationService';
import { BookingModel, BookingStore, EstimationStatusTyped } from '../stores/BookingStore';
import { SubscriptionHandler } from './subscription-handler';

const template = /* html */ `
  <button class="button primary-1" [disabled]="!canBook" (click)="book()">{{buttonText}}</button>
`;

@Component({
  selector: 'book-button',
  template: template,
})
export class BookButton extends SubscriptionHandler {
  readonly adminOrPlanner = this.authService.adminOrPlanner();

  buttonText: string;
  canBook = false;
  autoSendToAdServer = false;
  unbooked = false;
  agencyUserMinDaysUpdateCreative: number;
  validEstimate = false;
  mayBookInvalidEstimate = false;
  totalOverbooked = 0;
  noShortformCreative = false;

  constructor(
    private authService: AuthService,
    private bookingStore: BookingStore,
    private campaignService: AdvancedCampaignService,
    private formatterService: FormatterService,
    private routingService: RoutingService,
    private dialogService: DialogService,
    private termsService: TermsService,
    private translationService: TranslationService,
    private bookingValidationService: BookingValidationService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
    this.addSubscription(
      this.termsService.needsToAcceptTerms$.subscribe(() => {
        this.saveBeforeRedirectToTerms();
      }),
    );
  }

  initFromStore = (model: BookingModel): void => {
    this.unbooked = model.campaign.status === CampaignStatus.unbooked;
    this.autoSendToAdServer = model.campaign.status === CampaignStatus.upcoming;
    this.buttonText = this.unbooked ? 'Boka' : 'Boka om';
    this.totalOverbooked = this.getOverbookedAmount(model.estimation.parts);
    this.validEstimate = model.estimation && model.estimation.status === EstimationStatusTyped.ok;
    this.mayBookInvalidEstimate =
      this.adminOrPlanner && model.estimation.status === EstimationStatusTyped.insufficientAvailableInventory;
    const mayBookEstimation = this.validEstimate || this.mayBookInvalidEstimate;
    this.canBook =
      model.validationMessages.filter((m) => m.type === MessageType.error).length === 0 &&
      mayBookEstimation &&
      this.authService.allowedToBook();
    this.agencyUserMinDaysUpdateCreative = model.agencyUserMinDaysUpdateCreative;
    this.noShortformCreative = this.bookingValidationService.missingShortFormInstreamCreativeOnCombination(
      model.campaign,
    );
  };

  book = (): void => {
    const content = this.unbooked ? this.getDialogContentBookNew() : this.getDialogContentBookBooked();
    const overbookedContent = this.unbooked
      ? this.getOverbookDialogContentNew()
      : this.getOverbookDialogContentBooked();
    const campaign = this.bookingStore.assembleCampaign();

    of(null)
      .pipe(
        mergeMap(() =>
          this.validEstimate
            ? this.toObservable(this.dialogService.openConfirm(content))
            : this.toObservable(this.dialogService.openConfirm(overbookedContent)),
        ),
        catchError(() => empty()),

        mergeMap(() => this.toObservable(this.campaignService.book(campaign, !this.validEstimate))),
        catchError(this.ignoreHttpErrors),
        catchError(this.handleBookingError),

        mergeMap((result: CampaignBookingJobResult) =>
          this.toObservable(this.campaignService.sendToAdServerIfNecessary(campaign)).pipe(mapTo(result)),
        ),
        catchError(this.ignoreHttpErrors),
        catchError(this.handleSendToAdServerError),
      )
      .subscribe((result: CampaignBookingJobResult) => {
        this.dialogService.create(SuccessDialog).open();
        this.routingService.goToDashboardWithCampaign(result.campaign_id);
      });
  };

  private ignoreHttpErrors = (err) => {
    return err.name === 'HttpErrorResponse' ? empty() : throwError(err);
  };

  private handleBookingError = (errorResult: Partial<CampaignBookingJobResult>) => {
    const errorMessage = this.translationService.convertError(errorResult.booking_error || 'unableToBook');
    this.dialogService.openError(errorMessage);

    if (errorResult.campaign_id) {
      this.campaignService.load(errorResult.campaign_id);
    }
    return empty();
  };

  private handleSendToAdServerError = (err: SendToAdServerJobResult) => {
    if (!!err?.data_errors) {
      this.dialogService.openPreconditionError(err.error, err.data_errors);
      return EMPTY;
    }

    const errorMessage = this.translationService.convertSendToAdserverError(err.error);
    this.dialogService.openError(errorMessage);
    return empty();
  };

  private getDialogContentBookNew(): ConfirmDialogContent {
    return {
      header: 'Är all information korrekt?',
      textBlocks: [
        `Observera att din bokning är bindande! Du kan endast redigera viss information
         kopplad till ditt kampanjmaterial fram till ${this.agencyUserMinDaysUpdateCreative} dagar innan kampanjstart.`,
        `Är du inte säker på din budget eller styrning kan du välja att spara kampanjen som ett
          utkast och fortsätta vid ett annat tillfälle.`,
      ],
      confirmText: 'Boka',
      cancelText: 'Avbryt',
      warningBlocks: this.noShortformCreative ? this.getWarningBlockNoShortformCreative() : [],
    };
  }

  private getDialogContentBookBooked(): ConfirmDialogContent {
    return {
      header: 'Uppdatering av bokad kampanj',
      textBlocks: [
        'Du försöker nu att uppdatera en bokad kampanj.',
        'Vill du genomföra ändringarna på kampanjen?',
        this.autoSendToAdServer ? 'Detta innebär att kampanjen också skickas om till adservern.' : null,
      ],
      confirmText: 'Ja!',
      cancelText: 'Nej, jag är inte helt klar än',
      warningBlocks: this.noShortformCreative ? this.getWarningBlockNoShortformCreative() : [],
    };
  }

  private getOverbookDialogContentNew(): ConfirmDialogContent {
    return {
      header: 'Är all information korrekt?',
      textBlocks: [
        `Observera att din bokning är bindande! Du kan endast redigera viss information kopplad till ditt kampanjmaterial fram till ${this.agencyUserMinDaysUpdateCreative} dagar innan kampanjstart.`,
        'Är du inte säker på din budget eller styrning kan du välja att spara kampanjen som ett utkast och fortsätta vid ett annat tillfälle.',
      ],
      consent: {
        text: `Nu kommer du överboka lagret med ${this.formatterService.transformNumber(
          this.totalOverbooked,
        )} visningar.`,
        label: 'Inga problem, jag vill boka',
      },
      confirmText: 'Boka',
      cancelText: 'Avbryt',
    };
  }

  private getOverbookDialogContentBooked(): ConfirmDialogContent {
    return {
      header: 'Uppdatering av bokad kampanj',
      textBlocks: [
        'Du försöker nu att uppdatera en bokad kampanj.',
        'Vill du genomföra ändringarna på kampanjen?',
        this.autoSendToAdServer ? 'Detta innebär att kampanjen också skickas om till adservern.' : null,
      ],
      consent: {
        text: `Nu kommer du överboka lagret med ${this.formatterService.transformNumber(
          this.totalOverbooked,
        )} visningar.`,
        label: 'Inga problem, jag vill boka',
      },
      confirmText: 'Ja!',
      cancelText: 'Nej, jag är inte helt klar än',
    };
  }

  private getOverbookedAmount(parts: CampaignEstimationPart[]): number {
    return parts
      .map((part) => part.overbooked_impressions)
      .reduce((acc, curr) => {
        return acc + curr;
      }, 0);
  }

  private toObservable<T>(promise: Promise<T>): Observable<T> {
    return scheduled(promise, asyncScheduler);
  }

  private saveBeforeRedirectToTerms = () => {
    this.campaignService.save(this.bookingStore.state.campaign).then((campaign: Campaign) => {
      this.dialogService.openError(
        'Saknar accepterade användarvillkor',
        'Du måste acceptera användarvillkoren för att göra en bokning. Din kampanj har sparats som ett utkast.',
      );
      this.termsService.bookingDraftRedirect = campaign.id;
      this.routingService.navigate(['/terms']);
    });
  };

  private getWarningBlockNoShortformCreative(): ConfirmDialogWarningBlock[] {
    return [
      {
        header: 'Varning!',
        textBlocks: [
          'Observera att om det inte finns en 20 sek film eller kortare så kommer kampanjen endast att visas på Adresserbar TV.',
        ],
      },
    ];
  }
}
