<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera maxuttag för AG kampanjer</h3>
</div>

<div>
  <div #stickyHeader>
    <div class="tabbable__body">
      <import-warning *ngIf="show.estimatesHaveChanged"></import-warning>
      <dot-loader [visibility]="show.updatingEstimates"></dot-loader>
      <div class="button-filter-container">
        <div>
          <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="agWithdrawalLimits.invalid">Spara</button>
          <button *ngIf="!show.isEditing" class="button primary" (click)="setEditing(true)">Redigera maxuttag</button>
          <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
        </div>
        <div class="display--flex">
           <div *ngIf="productFormats && productFormats.length > 1" class="filter-dropdown margin-left--2x">
             <woo-dropdown
             [items]="productFormats"
             [(ngModel)]="selectedProductFormat"
             name="selectedProductFormat"
             inputId="select-product-format"
             labelText="Filtrera på Placering"
             [labelTransform]="translateProductFormat"
             notFoundText="Inga Placeringar hittades."
             [clearable]="true"></woo-dropdown>
            </div>
          <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
            <woo-dropdown
            [items]="publishers"
            [(ngModel)]="selectedPublisher"
            name="selectedPublisher"
            inputId="select-publisher"
            labelText="Filtrera på Publicist"
            notFoundText="Inga publicister hittades."
            [clearable]="true"></woo-dropdown>
          </div>
        </div>

      </div>
    </div>
    <div class="tabbable__panel clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Maxuttag för AG kampanjer</h3>
      </div>
    </div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table-woo table--info table--hover width--100" ngForm #agWithdrawalLimits="ngForm">
      <thead #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-center" colspan="3">
            Maxuttag
          </th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let row of agWithdrawalLimitRows; let index = index;">
        <tr [hidden]="!rowVisible(row)">
          <td class="text--thin vertical-align--middle">
            <span>{{row.publisher}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{translateProductFormat(row.productFormat)}}</span>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPreroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPreroll"
                [(ngModel)]="getProductFormat(row.productFormatId).ag_withdrawal_limits.preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsMidroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesMidroll"
                [(ngModel)]="getProductFormat(row.productFormatId).ag_withdrawal_limits.midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPostroll"
                [(ngModel)]="getProductFormat(row.productFormatId).ag_withdrawal_limits.postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>

    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
    <button class="button primary" (click)="save()" [disabled]="agWithdrawalLimits.invalid">Spara</button>
    <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>
