import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OutstreamSiteService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getSites(): Promise<OutstreamSite[]> {
    return lastValueFrom(this.http.get<OutstreamSite[]>(`${this.env.apiUrl}/outstream_sites/`));
  }
}

export interface OutstreamSite {
  id: string;
  name: string;
}
