<h3>Avslutade externa kampanjer</h3>
<div>
  <div>
    <div class="side--padded">
      <pagination css="info" [pagination]="pagination" (pageSelected)="loadPage($event)"></pagination>
    </div>
  </div>
  <div class="clearfix">
    <dot-loader [visibility]="loading"></dot-loader>
  </div>
  <table class="table table-woo">
    <thead>
    <tr>
      <th>Lager</th>
      <th>Namn</th>
      <th>Period</th>
      <th>Prio</th>
      <th>Impr (Tot/Relevant)</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let campaign of campaigns; trackBy: trackById" >
      <td>
        <input type="checkbox" id="{{campaign.id}}" name="checkbox" value="value"
               (click)="toggleReduceImpressions(campaign)" [checked]="campaign.reduce_impressions" class="checkbox"/>
      </td>
      <td>{{campaign.name}}</td>
      <td (click)="openDetails(campaign)">{{campaign.start_date | date:'yyyy-MM-dd'}} - {{campaign.end_date | date:'yyyy-MM-dd'}}</td>
      <td (click)="openDetails(campaign)">{{campaign.priority}}</td>
      <td (click)="openDetails(campaign)">{{campaign.goal_total}} / {{campaign.rbs_total}}</td>
      <td (click)="openDetails(campaign)"><span class="icon-info" style="margin: 0 4px;"></span></td>
    </tr>
    </tbody>
  </table>
</div>
