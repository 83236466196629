import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../woo_services.module/CustomerService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { CompactCustomer } from '../../../woo_services.module/shared-types';

const template = /* html */ `
<div class="mt-3">
  <h1>Vilken kunds material vill du se?</h1>
  <dot-loader [visibility]="loading" description="Hämtar kunder"></dot-loader>
  
  <h3 *ngIf="error">{{ error }}</h3>
  <div *ngIf="!error">
    <woo-dropdown
      *ngIf="customers.length"
      name="customerSelector"
      [items]="customers"
      [(ngModel)]="customer"
      inputId="customer-search"
      labelText="Kund"
      [searchAttributes]="['name', 'nickname']"
      notFoundText="Inga kunder hittades."
    ></woo-dropdown>
      <button [disabled]="!customer?.id" class="button primary-1" (click)="goToCustomer()">Visa</button>
    </div>
  </div>
`;

@Component({
  selector: 'select-customer-to-view-assets-for',
  template: template,
})
export class SelectCustomerToViewAssetsFor implements OnInit {
  error: string;
  customers: CompactCustomer[] = [];
  loading = true;
  customer: CompactCustomer = null;

  constructor(private routingService: RoutingService, private customerService: CustomerService) {}

  ngOnInit(): void {
    this.customerService
      .getCustomersForCurrentUser()
      .then((customers) => (this.customers = customers))
      .catch(() => (this.error = 'Kunde inte hämta kunder'))
      .then(() => (this.loading = false));
  }

  goToCustomer(): void {
    this.routingService.navigate(['/customer', this.customer.id, 'creative_assets'], {
      state: { customerName: this.customer.name },
    });
  }
}
