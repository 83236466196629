<div class="tabbable__panel rounded-top-right">
  <h3 class="margin-left--3x">Här kan du administrera tillgängliga enheter</h3>
</div>

<div>
  <div #stickyHeader >
    <div class="tabbable__body clearfix shadow-z-1">
      <div class="col-xs-12">
        <h3>Tillgängliga enheter</h3>
      </div>
    </div>
    <div class="tabbable__panel shadow-z-1"></div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <div id="lists" class="row">
      <div class="col-xs-12">
        <form (ngSubmit)="updateDevices()">
          <table class="table table--woo table--info table--hover table--align--middle">
            <thead #stickyTableHeader>
              <tr>
                <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
                  {{header}}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let device of devices">
                <td>
                  {{device.name}}
                </td>
                <td>
                  <div class="input__container">
                    <toggle
                      name="active-{{device.id}}"
                      [(ngModel)]="device.active"
                      id="active-{{device.id}}"
                    ></toggle>
                  </div>
                </td>
                <td>
                  <div class="input__container">
                    <toggle
                      name="click-{{device.id}}"
                      [(ngModel)]="device.clickable"
                      id="click-{{device.id}}"
                    ></toggle>
                  </div>
                </td>
                <td>
                  <div class="input__container">
                    <toggle
                      name="ag-{{device.id}}"
                      [(ngModel)]="device.automated_guaranteed_enabled"
                      id="ag-{{device.id}}"
                    ></toggle>
                  </div>
                </td>
                <td>
                  <div class="input__container">
                    <toggle
                      name="pause-{{device.id}}"
                      [(ngModel)]="device.pause_ad_enabled"
                      id="pause-{{device.id}}"
                    ></toggle>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button type="submit" class="button margin-top--3x">Spara</button>
        </form>
      </div>
    </div>
  </div>
</div>
