import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class CampaignFrequencyReportService {
  constructor(private http: HttpClient, private ENV: EnvironmentService) {}

  getAll(): Promise<CampaignFrequencyReport[]> {
    return lastValueFrom(this.http.get<CampaignFrequencyReport[]>(`${this.ENV.apiUrl}/campaign_frequency_reports`));
  }
}

export interface CampaignFrequencyReport {
  year: number;
  month: number;
  average_adserver_frequency: number;
  average_mms_frequency: number;
}
