<form #pickerForm="ngForm"
  (ngSubmit)="pickerForm.valid ? creatNewPriceList() : setFormDirty(pickerForm.control)">
  <div class="select__container">
    <label for="listType">Typ av prislista</label>
    <select id="listType" name="listType" [(ngModel)]="selectedPriceListType"
      (ngModelChange)="updateListSelections()" required>
      <option *ngFor="let typePair of availablePriceListTypes | keyvalue" [ngValue]="typePair.value">
        {{translatePriceListType(typePair.value)}}</option>
    </select>
  </div>

  <div class="select__container">
    <label for="listCurrency">Valuta för prislista</label>
    <select id="listCurrency" name="listCurrency" [(ngModel)]="selectedPriceListCurrency"
      (ngModelChange)="updateListSelections()" required>
      <option *ngFor="let currencyPair of availablePriceListCurrencies | keyvalue" [ngValue]="currencyPair.value">
        {{translatePriceListCurrency(currencyPair.value)}}</option>
    </select>
  </div>

  <div class="select__container">
    <label for="listYear">År för prislistan att gälla</label>
    <select id="listYear" name="listYear" [(ngModel)]="selectedPriceListYear"
      [disabled]="!selectedPriceListType || !selectedPriceListCurrency" required>
      <option value=""></option>
      <option [disabled]="availableYear.disabled" *ngFor="let availableYear of availableYears"
        [ngValue]="availableYear.year">{{availableYear.year}}</option>
    </select>
  </div>

  <p>Välj vilken prislista den nya ska utgå ifrån</p>
  <div class="select__container">
    <label for="copyYear">Befintliga prislistor</label>
    <select id="copyYear" name="copyYear" [(ngModel)]="selectedPriceList" [disabled]="!selectedPriceListType || !selectedPriceListCurrency"
      required>
      <option value=""></option>
      <option *ngFor="let list of availablePriceLists" [ngValue]="list">{{priceListLabel(list)}}</option>
    </select>
  </div>
  <button type="submit" class="button">Skapa</button>
  <button type="button" class="link" (click)="cancel()">
    Avbryt
  </button>
</form>
