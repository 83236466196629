import { Component, Input } from '@angular/core';
import { Daypart, DaypartName, TargetingDaypart, wooId } from '../../../../woo_services.module/shared-types';
import { CachedDaypartService } from '../../../services/CachedDaypartService';
import { BookingModel, BookingStore } from '../../../stores/BookingStore';
import { SubscriptionHandler } from '../../subscription-handler';

@Component({
  selector: 'daypart-targeting',
  templateUrl: './daypart-targeting.component.html',
  styleUrls: ['./daypart-targeting.component.scss'],
})
export class DaypartTargeting extends SubscriptionHandler {
  @Input() targetingId: wooId;

  readonly images = {
    [DaypartName.night]: 'images/daypart/night.svg',
    [DaypartName.morning]: 'images/daypart/morning.svg',
    [DaypartName.day]: 'images/daypart/day.svg',
    [DaypartName.afternoon]: 'images/daypart/afternoon.svg',
    [DaypartName.evening]: 'images/daypart/evening.svg',
  };

  dayparts: Daypart[];
  private selectedDaypartIds: wooId[];

  constructor(cachedDaypartService: CachedDaypartService, private bookingStore: BookingStore) {
    super();
    cachedDaypartService.getDayparts().then((dayparts) => {
      this.dayparts = dayparts;
    });

    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  private initFromStore = (model: BookingModel) => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.selectedDaypartIds = targeting.dayparts.map((daypart) => daypart.daypart_id);
    }
  };

  toggleDaypart(daypartId: wooId): void {
    const newSelectedDaypartIds: wooId[] = this.selectedDaypartIds.includes(daypartId)
      ? this.selectedDaypartIds.filter((dpId) => dpId !== daypartId)
      : [...this.selectedDaypartIds, daypartId];
    const selectedDayparts: TargetingDaypart[] = this.dayparts
      .filter((daypart) => newSelectedDaypartIds.includes(daypart.id))
      .map((daypart) => {
        return { daypart_id: daypart.id, name: daypart.name, description: daypart.description };
      });
    this.bookingStore.setDayparts(selectedDayparts, this.targetingId);
  }

  isSelected(daypartId: wooId): boolean {
    return this.selectedDaypartIds.includes(daypartId);
  }
}
