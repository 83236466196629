import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { DaypartService } from '../../../../woo_services.module/DaypartService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import {
  Daypart,
  PerIdEstimates,
  ProductFormat,
  Publisher,
  SlotType,
  wooId,
} from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-dayparts',
  templateUrl: './manage-dayparts.component.html',
  styleUrls: ['../../shared//table.component.scss'],
})
export class ManageDayparts implements OnInit {
  @Input('headerPos') parentHeader: HTMLElement;
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  show = {
    withdrawalLimitsHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };
  readonly staticFields = 4;

  readonly tableHeaders = ['Publicist', 'Placering', 'Daypart', 'Tidsspann', 'Preroll', 'Midroll', 'Postroll'];
  withdrawalLimitsHaveChanged = false;
  daypartsRows: DaypartsRow[];
  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  dayparts: Daypart[];
  selectedDaypart: Daypart;
  translateProductFormat: (args: any) => string;
  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private daypartService: DaypartService,
    private dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.daypartService
      .getDayparts()
      .then((dayparts) => (this.dayparts = dayparts))
      .then(() => this.publisherService.getPublishers(ExtraPublisherField.enabled))
      .then((publishers) => {
        this.publishers = publishers;
        this.publishers = publishers.filter((p) => p.enabled);
      })
      .then(() => {
        this.getAndBuildDayParts();
      })
      .finally(() => {
        this.show.loading = false;
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
        scrollTo(this.parentHeader);
      });
  }

  async getAndBuildDayParts(): Promise<void> {
    this.productFormatService
      .getProductFormats(
        ExtraProductFormatField.enabled,
        ExtraProductFormatField.daypartWithdrawalLimits,
        ExtraProductFormatField.slotTypes,
      )
      .then((productFormats) => {
        this.productFormats = productFormats.filter((f) => f.enabled);
        this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
          this.productFormats,
        );
        this.buildDaypartRows();
      });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildDayParts();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedDaypart || this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.updatingEstimates = true;
        this.productFormatService.updateProductFormats(this.productFormats).then((productFormats) => {
          this.productFormats = productFormats;
          this.buildDaypartRows();
          this.show.withdrawalLimitsHaveChanged = true;
        });
      })
      .catch(() => null)
      .finally(() => {
        this.setEditing(false);
        this.show.updatingEstimates = false;
        this.dialogService.closeBlocking();
      });
  }

  rowVisible(obj: DaypartsRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const daypartFiltered = this.selectedDaypart && obj.daypartId !== this.selectedDaypart.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || daypartFiltered || productFormatFiltered);
  }

  getDaypartName(id: wooId): string {
    return this.dayparts.find((device) => device.id === id).name;
  }

  getDaypartDescription(id: wooId): string {
    return this.dayparts.find((device) => device.id === id).description;
  }

  getDaypartWithdrawalLimits(productFormatId: wooId): PerIdEstimates {
    return this.productFormats.find((format) => format.id === productFormatId).daypart_withdrawal_limits;
  }

  private buildDaypartRows(): void {
    let daypartsRows: DaypartsRow[] = [];
    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        Object.keys(productFormat.daypart_withdrawal_limits).map((daypartId) => {
          daypartsRows = [
            ...daypartsRows,
            {
              publisher: publisher.name,
              publisherId: publisher.id,
              productFormat: productFormat.name,
              productFormatId: productFormat.id,
              daypart: this.getDaypartName(daypartId),
              daypartDescription: this.getDaypartDescription(daypartId),
              daypartId: daypartId,
              withdrawalLimitsPreroll: productFormat.daypart_withdrawal_limits[daypartId].preroll,
              withdrawalLimitsMidroll: productFormat.daypart_withdrawal_limits[daypartId].midroll,
              withdrawalLimitsPostroll: productFormat.daypart_withdrawal_limits[daypartId].postroll,
            },
          ];
        });
      });
    });
    this.daypartsRows = daypartsRows;
  }
}

interface DaypartsRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  daypart: string;
  daypartDescription: string;
  daypartId: wooId;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
