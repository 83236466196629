<h3 class="text--large text--thin">{{ heading }}</h3>
<div class="flex__container margin-top--2x">
  <div
    class="display--block margin-right--6x"
    style="height: 220px; width: 220px;"
  >
  <canvas 
    baseChart
    [type]="chartType"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [legend]="false"
  >
  </canvas>
</div>
  <div>
    <table>
      <tbody>
        <tr *ngFor="let label of chartLabels; index as index">
          <td class="text--semi-strong padding-right--3x padding-bottom--2x">
            <span
              class="color-box margin-right--1x"
              [ngStyle]="{ 'background-color': colors[index] }"
            ></span>
            {{ label }}
          </td>
          <td class="padding-bottom--2x text--thin">
            {{ distributionData[index] | number }} {{ viewCurrency }} ({{
              totalDataShare(distributionData[index]) | percent: PERCENT_FORMAT
            }})
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
