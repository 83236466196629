<div class="row">
  <div class="col-xs-8">
    Produkt
  </div>
  <div class="col-xs-4 text-align--center">
    Rabattsats (%)
  </div>
</div>
<div class="row" *ngFor="let part of model" class="margin--none padding--none">
  <div class="col-xs-9">
    <input
      class="checkbox"
      id="discount-part-select-{{part.discount_types}}"
      type="checkbox"
      [(ngModel)]="part.selected"
      (ngModelChange)="change()"
      (blur)="touch()"
    >
    <label class="checkbox" for="discount-part-select-{{part.discount_types}}" *ngFor="let discount_type of part.discount_types">
      {{convertDiscount(discount_type)}}
    </label>
  </div>
  <div class="col-xs-3 input__container text-align--center">
    <div class="display--inline-block input-fixed-max-width">
      <input
        type="text"
        wooPercentFormatter
        [wooMinValidator]="0.001"
        [wooMaxValidator]="0.999"
        class="small-input-height"
        [(ngModel)]="part.discount_rate"
        (ngModelChange)="change()"
        [required]="part.selected"
        (blur)="touch()"
        [disabled]="!part.selected"
      >
    </div>
  </div>
</div>

