import { Injectable } from '@angular/core';
import { ProgramFormatService } from '../../woo_services.module/ProgramFormatService';
import { Program } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedProgramFormatService implements Pick<ProgramFormatService, 'getActiveProgramFormats'> {
  private activeProgramsCache: Program[] = null;

  constructor(private programService: ProgramFormatService) {}

  async getActiveProgramFormats(): Promise<Program[]> {
    if (!this.activeProgramsCache) {
      this.activeProgramsCache = await this.programService.getActiveProgramFormats();
    }
    return this.activeProgramsCache;
  }
}
