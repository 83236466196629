<div class="padding-bottom--3x">
  <span>
    <input
      id="use-woo-distribution-{{instanceId}}"
      name="woo-distribution-name-{{instanceId}}"
      type="radio"
      class="radio"
      [(ngModel)]="useWooDistribution"
      [value]="true"
      (ngModelChange)="shareTypeChanged(ShareType.WOO)"
      [disabled]="disabled"
    >
    <label for="use-woo-distribution-{{instanceId}}" class="radio">
      WOO Managers fördelning
    </label>
  </span>
  <span *ngIf="calculateFromBudget">
    <input
      id="use-budget-distribution-{{instanceId}}"
      name="woo-distribution-name-{{instanceId}}"
      type="radio"
      class="radio"
      [(ngModel)]="useWooDistribution"
      [value]="false"
      (ngModelChange)="shareTypeChanged(ShareType.Budget)"
      [disabled]="disabled"
    >
    <label for="use-budget-distribution-{{instanceId}}" class="radio">
      Sätt egen fördelning i kr
    </label>
  </span>
  <span *ngIf="!calculateFromBudget">
    <input
      id="use-impression-distribution-{{instanceId}}"
      name="woo-distribution-name-{{instanceId}}"
      type="radio"
      class="radio"
      [(ngModel)]="useWooDistribution"
      [value]="false"
      (ngModelChange)="shareTypeChanged(ShareType.Impressions)"
      [disabled]="disabled"
    >
    <label for="use-impression-distribution-{{instanceId}}" class="radio">
      Sätt egen fördelning i {{currency}}
    </label>
  </span>
</div>

<div *ngIf="!useWooDistribution">
  <remaining-distribution
    class="padding-bottom--2x display--block"
    [totalToDistribute]="totalToDistribute"
    [distributed]="distributed"
    [currencyType]="distributionCurrency"
  ></remaining-distribution>

  <distribution-table
    [(ngModel)]="distributables"
    (ngModelChange)="updateDistribution($event)"
    [shareType]="shareType"
    [itemType]="distributionName"
    [totalToDistribute]="totalToDistribute"
    [currency]="currencyShort"
  ></distribution-table>

</div>
