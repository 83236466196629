import { ElementRef } from '@angular/core';

declare const $: any;

const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

export function getBannerHeight(): number {
  return $('nav').outerHeight() || 0;
}

export function scrollTo(element: HTMLElement): void {
  $('html, body')
    .stop()
    .animate({ scrollTop: $(element).offset().top - getBannerHeight() });
}

export function scrollToOffset(offset: number, smooth?: boolean): void {
  smooth ? $('html, body').stop().animate({ scrollTop: offset }) : $('html, body').stop().scrollTop(offset);
}

export function scrollToTop(smooth?: boolean): void {
  smooth
    ? $('html, body')
        .stop()
        .animate({ scrollTop: 0 - getBannerHeight() })
    : $('html, body').stop().scrollTop(0);
}

export const smoothScroll = (
  y: number,
  useBannerOffset = true,
  mainScrollContainer: HTMLElement = document.body,
): void => {
  const yWithHeaderOffset = useBannerOffset ? y - getBannerHeight() : y;
  if (supportsNativeSmoothScroll) {
    mainScrollContainer.scrollTo({ top: yWithHeaderOffset, left: 0, behavior: 'smooth' });
  } else {
    $(mainScrollContainer).stop().animate({ scrollTop: yWithHeaderOffset });
  }
};

export const smoothScrollTo = (element: HTMLElement, mainScrollContainer: HTMLElement = document.body): void => {
  const y = element.offsetTop;
  smoothScroll(y, true, mainScrollContainer);
};

export function openAbove(elementRef: ElementRef<unknown>): boolean {
  const element = elementRef.nativeElement as HTMLElement;
  const rect = element.getBoundingClientRect();
  const middleOfElement = rect.top + (rect.bottom - rect.top) / 2;
  const screenHeight = window.innerHeight;
  return middleOfElement > screenHeight / 2;
}

export function showTooltip(targetElement: HTMLElement): void {
  $(targetElement).tooltip('show');
}
