<table class="table" *ngIf="visibleVouchers?.length">
  <thead
  >
    <tr>
      <th>Rabattsats</th>
      <th>Villkor</th>
      <th>Byrå</th>
      <th>Skapad</th>
      <th *ngIf="mode === modes.edit && isAdminOrPlannerOrSales">Ta bort</th>
      <th class="text-align--center" *ngIf="mode === modes.select">Använd</th>
      <th *ngIf="showUsedVouchers">Kampanj</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let voucher of visibleVouchers"
      [ngClass]="{
        'inactive': voucher.campaign,
        'pointer': mode === modes.select && !voucher.campaign && !isDisabled(voucher),
        'disabled': !voucher.campaign && isDisabled(voucher)
      }"
      (click)="select(voucher)"
    >
      <td>
        {{voucher.discount | percent:'1.0-2'}}
      </td>
      <td>
        <ul class="reset--list" *ngFor="let condition of voucher.conditions">
          <li>
            <div class="tab tab--small" *ngFor="let value of condition.values" [ngClass]="{'disabled': !voucher.campaign && isDisabled(voucher)}">
              {{convertDiscount(value)}}
            </div>
          </li>
        </ul>
      </td>
      <td>
        {{voucher.agency?.nickname || '-'}}
      </td>
      <td>{{voucher.created_at | date:'yyyy-MM-dd'}}</td>
      <td *ngIf="mode === modes.edit && isAdminOrPlannerOrSales">
        <button
          [ngClass]="{'text-danger': !voucher.campaign, 'text-muted': voucher.campaign}"
          (click)="$event.stopPropagation(); delete(voucher)"
          [disabled]="voucher.campaign || isDisabled(voucher)"
        >
          <span class="icon-trash"></span>
        </button>
      </td>
      <td class="text-align--center" *ngIf="mode === modes.select">
        <div class="display--inline-block">
          <input type="checkbox" class="checkbox" [checked]="voucher.id === selectedId" [disabled]="voucher.campaign || isDisabled(voucher)">
        </div>
      </td>
      <td *ngIf="showUsedVouchers">
        <a *ngIf="voucher.campaign" class="pointer" [routerLink]="['/dashboard', voucher.campaign.id]">{{voucher.campaign.name}}</a>
      </td>
    </tr>
  </tbody>
</table>

<p class="text--italic" *ngIf="!visibleVouchers?.length">Det finns inga upplagda.</p>
