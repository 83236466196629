<div class="display--flex justify-content--space-between align-items--end">
  <h2 class="margin-bottom--3x">Sammanfattning</h2>
  <download-summary-button></download-summary-button>
</div>
<div class="summary-wrapper">
  <div class="margin-bottom--2x">
    <date-span [start]="campaignStart" [end]="campaignEnd"></date-span>
  </div>
  <customer-agency-row [data]="customerAgencyData"></customer-agency-row>

  <h2 class="margin-top--none margin-bottom--2x">{{campaignName}}</h2>

  <div class="divider margin-top-bottom--2x"></div>

  <div class="row">
    <div class="col-xs-12 col-sm-6 column--left">
      <two-col-row left="Total budget" [right]="totalBudget | currency: 'SEK'" [bothBold]="true"></two-col-row>
      <two-col-row *ngIf="grossRatingViews" left="Totala {{grossRatingViewsCurrency}}" [right]="grossRatingViews | number" [bothBold]="true"></two-col-row>
      <two-col-row *ngIf="completedViews" left="Totala {{completedViewsCurrency}}" [right]="completedViews | number" [bothBold]="true"></two-col-row>
      <two-col-row *ngIf="impressions" left="Totala {{impressionsCurrency}}" [right]="impressions | number" [bothBold]="true"></two-col-row>
      <two-col-row *ngIf="brand" left="Varumärke" [right]="brand.name" [bothBold]="true"></two-col-row>
    </div>
    <div class="col-xs-12 col-sm-6 column--right">
      <two-col-row left="Referensnummer" [right]="referenceNumber" [bothBold]="true"></two-col-row>
      <two-col-row left="Er referens" [right]="clientInvoiceReference" [bothBold]="true"></two-col-row>
    </div>
  </div>

  <div class="input__container margin-top--2x margin-bottom--none">
    <span class="input__optional__text">Frivillig</span>
    <label for="additional-info">Övrig info</label>
    <textarea
      id="additional-info"
      [(ngModel)]="additionalInfo"
      (ngModelChange)="additionalInfoUpdated()"
      [disabled]="ttvCampaign"
    >
    </textarea>
  </div>

</div>

<advanced-booking-targeting-summary
  *ngFor="let targeting of targetings; trackBy: trackById; index as index"
  [targetingId]="targeting.id"
  [attr.e2e-id]="'targeting-summary-' + index"
></advanced-booking-targeting-summary>
