<div class="toggable__tab toggleTab no--animation" *ngIf="(!!approvableCampaigns?.length)" id="approval">
  <div class="toggler" id="approval-toggler">
    <div class="section info left-right-border padding-top-bottom--1x margin-top--3x">
      <h3 class="margin--none">
        <span class="chevron"></span>
        Kampanjer som behöver din uppmärksamhet
      </h3>
    </div>
  </div>

  <div class="clearfix">
    <table class="table table-woo table--hover small">
      <thead>
        <tr>
          <th class="pointer" (click)="selectSortColumn(Column.Name, $event)">
            Namn <span class="icon-TV4_011_ner" *ngIf="column===Column.Name" [ngClass]="{'rotate': reverse}"></span>
          </th>
          <th class="pointer" (click)="selectSortColumn(Column.CustomerName, $event)">
            Kund <span class="icon-TV4_011_ner" *ngIf="column===Column.CustomerName" [ngClass]="{'rotate': reverse}"></span>
          </th>
          <th class="pointer no-wrap" (click)="selectSortColumn(Column.StartDate, $event)">
            Startdatum <span class="icon-TV4_011_ner" *ngIf="column===Column.StartDate" [ngClass]="{'rotate': reverse}"></span>
          </th>
          <th class="pointer" (click)="selectSortColumn(Column.Status, $event)">
            Status <span class="icon-TV4_011_ner" *ngIf="column===Column.Status" [ngClass]="{'rotate': reverse}"></span>
          </th>
          <th class="pointer" (click)="selectSortColumn(Column.AdServerStatus, $event)">
            Adserver <span class="icon-TV4_011_ner" *ngIf="column===Column.AdServerStatus" [ngClass]="{'rotate': reverse}"></span>
          </th>
          <th>
            Material Move
          </th>
          <th>
            Tagg
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let campaign of sortApprovableCampaignBy(column, reverse); trackBy: trackById"
          class="pointer campaign__list__item"
          (click)="selectCampaign(campaign, $event)">
          <td>{{campaign.name}}</td>
          <td>{{campaign.customer_name}}</td>
          <td class="no-wrap">{{campaign.start_date | date:'yyyy-MM-dd'}}</td>
          <td class="text-center">
            <campaign-status-badge [campaignStatus]="campaign.status" [small]="true"></campaign-status-badge>
          </td>
          <td class="text-center">
            <ad-server-status-badge [adServerStatus]="campaign.ad_server_status" [small]="true"></ad-server-status-badge>
          </td>
          <td class="text-center">
            <div class="pull-left" *ngFor="let creative of campaign.creatives">
              <creative-status-badge [creativeStatus]="creative.status" [small]="true"></creative-status-badge>
            </div>
          </td>
          <td>
            <special-campaign-label [campaign]="campaign"></special-campaign-label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
