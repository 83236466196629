import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { SessionService } from '../../woo_services.module/SessionService';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService, private env: EnvironmentService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(this.env.apiUrl) && this.sessionService.isAuthenticated()) {
      request = request.clone({
        setHeaders: {
          'X-User-Email': this.sessionService.getUserEmail(),
          'X-User-Token': this.sessionService.getAuthenticationToken(),
        },
      });
    }

    return next.handle(request);
  }
}
