/**
 * This file provides a way to integrate our store solution with the redux dev tool
 * In order for this to work the developers need to download and install the browser extension
 * https://github.com/zalmoxisus/redux-devtools-extension
 *
 * Tested with version v2.17.1
 */

let caller = null;

function pushCaller(call: string) {
  caller = call;
}

function popCaller(): string {
  const call = caller;
  caller = null;
  return call;
}

function getDevtool(): { send: (action: string, newState: any) => void } {
  return (window as any).__REDUX_DEVTOOLS_EXTENSION__;
}

function isDevtoolIntegrationSupported(): boolean {
  return Boolean(getDevtool()) && (window as any).Proxy;
}

function devtoolCommit(actionName: string, newState: any) {
  if (isDevtoolIntegrationSupported() && actionName) {
    getDevtool().send(actionName, newState);
  }
}

/**
 * This decorator is used to record which function was responsible for updating the state.
 *
 * @param promiseParameterIndex
 * This parameter should be used if the dispatch is delayed until a provided promise is resolved.
 */
export function dispatchMethod(options = { promiseParameterIndex: null }) {
  return function (_: any, prop: string, definition: PropertyDescriptor): void {
    if (!isDevtoolIntegrationSupported()) {
      return;
    }

    const index = options.promiseParameterIndex;
    definition.value = new Proxy(definition.value, {
      apply(target, thisArgs, args) {
        if (index !== null) {
          args[index] = args[index].then(
            (result) => {
              pushCaller(prop);
              return result;
            },
            (err) => {
              pushCaller(prop);
              throw err;
            },
          );
        }
        pushCaller(prop);
        return target.apply(thisArgs, args);
      },
    });
  };
}

/**
 * This decorator is used to mark the function responsible for committing state changes.
 * @param newStateParameterIndex Should point to the parameter containing the new state.
 */
export function commitMethod(newStateParameterIndex = 0) {
  return function (_: any, __: any, definition: PropertyDescriptor): void {
    if (!isDevtoolIntegrationSupported()) {
      return;
    }

    const method = definition.value;
    definition.value = function () {
      devtoolCommit(popCaller(), Array.from(arguments)[newStateParameterIndex]); // eslint-disable-line prefer-rest-params
      return method.apply(this, arguments); // eslint-disable-line prefer-rest-params
    };
  };
}
