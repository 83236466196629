import { Component } from '@angular/core';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';

@Component({
  selector: 'inventory',
  templateUrl: './inventory.component.html',
})
export class Inventory {
  tabVisible = 0;

  constructor(private authService: AuthService) {}

  setTab(tab: number): void {
    this.tabVisible = tab;
  }

  readonly authorizedForInventory = this.authService.hasAnyRole([
    UserRole.admin,
    UserRole.planner,
    UserRole.accounting,
    UserRole.salesManager,
    UserRole.estimator,
  ]);
}
