import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { groupBy } from '../../../utils/array';
import { round } from '../../../utils/math';
import { SimpleChanges } from '../../../utils/types';
import { CreativeAsset, CreativeAssetType } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'creative-asset-list',
  templateUrl: './creative-asset-list.component.html',
  styleUrls: ['./creative-asset-list.component.scss'],
})
export class CreativeAssetList implements OnChanges {
  images: CreativeAsset[] = [];
  videos: CreativeAsset[] = [];
  searchText: string;

  get showDelete(): boolean {
    return this.onDelete.observers.length > 0;
  }

  get showSelect(): boolean {
    return this.onSelect.observers.length > 0;
  }

  get hasAssets(): boolean {
    return this.assets && this.assets.length > 0;
  }

  get hasImages(): boolean {
    return this.unfilteredImages.length > 0;
  }

  get hasVideos(): boolean {
    return this.unfilteredVideos.length > 0;
  }

  @Input() assets: CreativeAsset[] = [];
  @Output() onDelete = new EventEmitter<CreativeAsset>();
  @Output() onSelect = new EventEmitter<CreativeAsset>();

  private unfilteredImages: CreativeAsset[] = [];
  private unfilteredVideos: CreativeAsset[] = [];

  ngOnChanges(changes: SimpleChanges<CreativeAssetList>): void {
    if (changes.assets && this.assets) {
      const types = groupBy(this.assets, (asset) => asset.asset_type);
      this.unfilteredImages = types.get(CreativeAssetType.Image) || [];
      this.unfilteredVideos = types.get(CreativeAssetType.Video) || [];
      this.filter();
    }
  }

  filter(): void {
    const selectByName = (asset: CreativeAsset) => {
      return !this.searchText || asset.name.toLowerCase().includes(this.searchText.toLowerCase());
    };
    this.images = this.unfilteredImages.filter(selectByName);
    this.videos = this.unfilteredVideos.filter(selectByName);
  }

  getFileSizeText(bytes: number): string {
    const byteMultiplier = 1024;
    if (bytes >= 1000 * 1000 && bytes <= byteMultiplier * byteMultiplier) {
      return '1 mb';
    }
    if (bytes < byteMultiplier * byteMultiplier) {
      return round(bytes / byteMultiplier, 2) + ' kb';
    } else {
      return round(bytes / (byteMultiplier * byteMultiplier), 2) + ' mb';
    }
  }
}
