<div class="flex__container--vertical">
  <duplicate-campaign-button [campaign]="campaign" [disabled]="disable.duplicate" *ngIf="show.duplicate"></duplicate-campaign-button>
  <review-campaign-button [campaign]="campaign" [disabled]="disable.review" *ngIf="show.review"></review-campaign-button>
  <edit-campaign-button [campaign]="campaign" [disabled]="disable.edit" *ngIf="show.edit"></edit-campaign-button>
  <remove-campaign-button [campaign]="campaign" [disabled]="disable.remove" *ngIf="show.remove"></remove-campaign-button>
  <send-to-ad-server-button [campaign]="campaign" [disabled]="disable.sendToAdServer" *ngIf="show.sendToAdServer"></send-to-ad-server-button>
  <stop-campaign-button [campaign]="campaign" [disabled]="disable.stop" *ngIf="show.stop"></stop-campaign-button>
  <cancel-campaign-button [campaign]="campaign" [disabled]="disable.cancel" *ngIf="show.cancel"></cancel-campaign-button>
  <close-campaign-button [campaign]="campaign" [disabled]="disable.close" *ngIf="show.close"></close-campaign-button>
</div>
