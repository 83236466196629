import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { verticalExpansion } from '../../../utils/animations';
import { STANDARD_FORMATS } from '../../../utils/format-constants';
import { generateId, isGeneratedId } from '../../../utils/string';
import { SelectorChoice } from '../../../woo_components.module/inputs/selector/selector.component';
import { RadioButtonBlockChoice } from '../../../woo_components.module/radio_button_block/radio-button-block.component';
import { AuthService } from '../../../woo_services.module/AuthService';
import { EnvironmentService } from '../../../woo_services.module/EnvironmentService';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { SystemService } from '../../../woo_services.module/SystemService';
import { TargetingCombinationRulesService } from '../../../woo_services.module/TargetingCombinationRulesService';
import {
  GeoTargeting,
  ProductChoice,
  ProductFormats,
  RegionalDivision,
  Targeting,
  TargetingType,
  wooId,
} from '../../../woo_services.module/shared-types';
import {
  BookingModel,
  BookingStore,
  BookingTargetingMetaData,
  ForcedEstimationCorrection,
} from '../../stores/BookingStore';
import { BookingStoreSelectors } from '../../stores/BookingStoreSelectors';
import { SubscriptionHandler } from '../subscription-handler';

@Component({
  selector: 'targeting-details',
  templateUrl: './targeting-details.component.html',
  animations: [verticalExpansion(250)],
})
export class TargetingDetails extends SubscriptionHandler implements OnInit, OnChanges {
  readonly instanceId = generateId();
  readonly isAdminOrPlanner = this.authService.adminOrPlanner();

  @Input() targetingId: wooId;
  TargetingType = TargetingType;
  GeoTargeting = GeoTargeting;
  ProductChoice = ProductChoice;

  targetingMetaData: BookingTargetingMetaData;
  targetingType = TargetingType.instream;
  geoTargeting: GeoTargeting = null;
  productFormat: ProductFormats;
  regionalDivision: RegionalDivision;
  productChoice = ProductChoice.rbs;
  addonProductChoice = ProductChoice.noChoice;

  targetingBudget = undefined;
  targetingPeriod = { start: null, end: null };
  calculateFromBudget = true;
  earliestCampaignStart: Date = new Date();
  applicableProductChoices: Array<RadioButtonBlockChoice<ProductChoice>> = [];
  applicableAddonProductChoices: Array<RadioButtonBlockChoice<ProductChoice>> = [];
  disablePeriodSelect = false;
  disabledInstreamMessage: string | null;
  disabledPauseAdMessage: string | null;
  includesLinear = false;
  expanded = false;
  regionalDivisionChoices = [];
  forcedEstimationCorrection: ForcedEstimationCorrection;
  forcedStartDate: Date | null;
  forcedEndDate: Date | null;
  includesLinearYear: string;
  memaCampaign = false;

  minCampaignLength: number;
  dateFormat = STANDARD_FORMATS.date;
  calculateFromBudgetChoices: Array<SelectorChoice<boolean>> = [];
  targeting: Targeting;
  disablePauseAd: (targetingId: wooId) => boolean;
  disableRegional: (targetingId: wooId) => boolean;

  constructor(
    private bookingStore: BookingStore,
    private selectors: BookingStoreSelectors,
    private authService: AuthService,
    private formatterService: FormatterService,
    private systemService: SystemService,
    private targetingCombinationRulesService: TargetingCombinationRulesService,
    private env: EnvironmentService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
    this.disablePauseAd = targetingCombinationRulesService.disablePause;
    this.disableRegional = targetingCombinationRulesService.disableRegional;
  }

  async ngOnInit(): Promise<void> {
    this.minCampaignLength = await this.systemService.getValue('min_campaign_length');
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  initFromStore = (model: BookingModel): void => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.targeting = targeting;
      this.targetingMetaData = model.targetingMetaData[this.targetingId];
      this.targetingBudget = targeting.budget;
      this.targetingPeriod = { start: targeting.start_date, end: targeting.end_date };
      this.calculateFromBudget = targeting.calculate_from_budget;
      this.includesLinear = targeting.includes_linear;
      this.regionalDivision = targeting.regional_division;
      this.setRegionalDivisionChoices();

      this.geoTargeting = this.targetingMetaData.geoTargeting;
      this.targetingType = this.targetingMetaData.targetingType;
      this.productChoice = this.targetingMetaData.productChoice;
      this.productFormat = this.targetingMetaData.productFormat;
      this.addonProductChoice = this.targetingMetaData.addonTargeting;
      this.memaCampaign = model.campaign.mema_campaign;
      this.calculateFromBudgetChoices = this.getBudgetChoices(targeting);
      this.expanded = this.targetingMetaData.expandedTargeting;

      const editingBooked = this.selectors.editingBooked(model.campaign);
      this.disabledInstreamMessage = this.getDisabledInstreamMessage(editingBooked);
      this.disabledPauseAdMessage = this.getDisabledPauseAdMessage(editingBooked);
      this.applicableProductChoices = this.getProductChoices();
      this.applicableAddonProductChoices = this.getAddonProductChoices();
      this.includesLinearYear = targeting.start_date
        ? new Date(targeting.start_date).getFullYear().toString()
        : this.includesLinearYear;
    }

    this.earliestCampaignStart = this.selectors.earliestTargetingStart(model, this.targetingId);
    this.disablePeriodSelect = model.campaign.has_sent_invoices;
    this.forcedEstimationCorrection = model.forcedEstimationCorrection[this.targetingId];
    this.forcedStartDate = this.forcedEstimationCorrection?.forcedNewStartDate;
    this.forcedEndDate = this.forcedEstimationCorrection?.forcedNewEndDate;
  };

  periodChanged = (event: { start: Date; end: Date }): void => {
    const oldPeriod = Object.assign({}, this.targetingPeriod);
    this.targetingPeriod.start = event.start;
    this.targetingPeriod.end = event.end;
    if (oldPeriod.start !== this.targetingPeriod.start || oldPeriod.end !== this.targetingPeriod.end) {
      this.bookingStore.setTargetingPeriod(this.targetingId, this.targetingPeriod);
    }
  };

  setRegionalDivisionChoices = (): void => {
    const regionalDivisionChoices = [
      {
        label: 'Postnummer',
        value: RegionalDivision.ZipCodeBased,
        disabled: false,
      },
    ];

    this.regionalDivisionChoices = regionalDivisionChoices;
  };

  updateCalculateFromBudget(): void {
    this.bookingStore.setCalculateFromBudget(this.targetingId, this.calculateFromBudget);
  }

  updateGeoTargeting(): void {
    this.bookingStore.setGeoTargeting(this.targetingId, this.geoTargeting);
  }

  updateRegionalDivision(): void {
    this.bookingStore.setRegionalDivision(this.targetingId, this.regionalDivision);
  }

  updateTargetingType(): void {
    this.bookingStore.setTargetingType(this.targetingId, this.targetingType);
  }

  expandedChange(expanded: boolean): void {
    this.bookingStore.setExpandedTargeting(this.targetingId, expanded);
    this.bookingStore.setExpandedTargetingIsAnimating(this.targetingId, true);
  }

  onExpandEnd(): void {
    this.bookingStore.setExpandedTargetingIsAnimating(this.targetingId, false);
  }

  productChoiceChanged(): void {
    const currentProduct = this.bookingStore.state.targetingMetaData[this.targetingId].productChoice;
    if (currentProduct !== this.productChoice) {
      this.bookingStore.setProductChoice(this.targetingId, this.productChoice);
    }
  }

  addonTargetingChanged(): void {
    const currentProduct = this.bookingStore.state.targetingMetaData[this.targetingId].addonTargeting;
    if (currentProduct !== this.addonProductChoice) {
      this.bookingStore.setAddonTargeting(this.targetingId, this.addonProductChoice);
    }
  }

  get isDatesChangedByEstimation(): boolean {
    return !!this.forcedEstimationCorrection?.forcedNewStartDate || !!this.forcedEstimationCorrection?.forcedNewEndDate;
  }

  get whyIsDatesChanged(): string {
    if (!this.forcedEstimationCorrection) {
      return '';
    }

    let text = `Då veckorna ${this.forcedEstimationCorrection.unbookableWeeks
      .map((w) => w.week)
      .join(', ')} i ditt valda tidsspann inte går att boka för vald styrning `;
    if (this.forcedEstimationCorrection?.forcedNewStartDate && this.forcedEstimationCorrection?.forcedNewEndDate) {
      text += `så kommer denna styrning att få spannet ${this.forcedEstimationCorrection?.forcedNewStartDate} - ${this.forcedEstimationCorrection?.forcedNewEndDate}`;
    } else if (this.forcedEstimationCorrection?.forcedNewStartDate) {
      text += ` så kommer denna styrning att startdatum ${this.forcedEstimationCorrection?.forcedNewStartDate}`;
    } else if (this.forcedEstimationCorrection?.forcedNewEndDate) {
      text += ` så kommer denna styrning att slutdatum ${this.forcedEstimationCorrection?.forcedNewEndDate}`;
    }
    text += ' vid boknings tillfället.';
    return text;
  }

  private getProductChoices(): Array<RadioButtonBlockChoice<ProductChoice>> {
    return [
      ProductChoice.rbs,
      ProductChoice.targetGroup,
      ProductChoice.advancedTargetGroup,
      ProductChoice.category,
      ProductChoice.program,
      ProductChoice.daypart,
      ProductChoice.device,
    ].map((product) => ({
      value: product,
      label: this.convertProductChoice(product),
      disabled: this.isProductDisabled(product),
    }));
  }

  private getAddonProductChoices(): Array<RadioButtonBlockChoice<ProductChoice>> {
    return [
      ProductChoice.noChoice,
      ProductChoice.device,
      ProductChoice.targetGroup,
      ProductChoice.advancedTargetGroup,
    ].map((product) => ({
      value: product,
      label: this.convertProductChoice(product),
      disabled: this.isAddonTargetingDisabled(product),
    }));
  }

  private isProductDisabled(product: ProductChoice): boolean {
    switch (product) {
      case ProductChoice.targetGroup:
        return this.targetingCombinationRulesService.disableTargetGroup(this.targetingId);
      case ProductChoice.rbs:
        return this.targetingCombinationRulesService.disableRBS(this.targetingId);
      case ProductChoice.category:
        return this.targetingCombinationRulesService.disableCategory(this.targetingId);
      case ProductChoice.daypart:
        return this.targetingCombinationRulesService.disableDaypart(this.targetingId);
      case ProductChoice.device:
        return this.targetingCombinationRulesService.disableDevice(this.targetingId);
      case ProductChoice.program:
        return this.targetingCombinationRulesService.disableProgram(this.targetingId);
      case ProductChoice.advancedTargetGroup:
        return this.targetingCombinationRulesService.disableAdvancedTargetGroup(this.targetingId);
      case ProductChoice.noChoice:
        return false;
      default:
        return false;
    }
  }

  private isAddonTargetingDisabled(product: ProductChoice): boolean {
    switch (product) {
      case ProductChoice.targetGroup:
        return (
          this.targetingCombinationRulesService.disableAddonTargetGroup(this.targetingId) ||
          this.targetingCombinationRulesService.disableAllAddonTargetingOptions(this.targetingId)
        );
      case ProductChoice.device:
        return (
          this.targetingCombinationRulesService.disableAddonDevice(this.targetingId) ||
          this.targetingCombinationRulesService.disableAllAddonTargetingOptions(this.targetingId)
        );
      case ProductChoice.advancedTargetGroup:
        return (
          this.targetingCombinationRulesService.disableAddonAdvancedTargetGroup(this.targetingId) ||
          this.targetingCombinationRulesService.disableAllAddonTargetingOptions(this.targetingId)
        );
      case ProductChoice.noChoice:
        return false;
      default:
        return false;
    }
  }

  private convertProductChoice(type: ProductChoice) {
    const list = {
      [ProductChoice.rbs]: 'RBS',
      [ProductChoice.category]: 'Kategori',
      [ProductChoice.program]: 'Program',
      [ProductChoice.device]: 'Apparat',
      [ProductChoice.targetGroup]: 'Målgrupp',
      [ProductChoice.daypart]: 'Daypart',
      [ProductChoice.advancedTargetGroup]: 'Avancerad målgrupp',
      [ProductChoice.noChoice]: 'Inget val',
    };
    return list[type] !== undefined ? list[type] : type;
  }

  private getDisabledInstreamMessage(editingBooked: boolean): string | null {
    if (editingBooked && this.targetingType === TargetingType.pause && !isGeneratedId(this.targetingId)) {
      return 'Går inte att ändra bokad kampanjdel till reklamfilm';
    }
    return null;
  }

  private getDisabledPauseAdMessage(editingBooked: boolean): string | null {
    if (this.disablePauseAd(this.targetingId)) {
      return 'Pausreklam går inte ihop med de valda styrningarna';
    } else if (editingBooked && this.targetingType === TargetingType.instream && !isGeneratedId(this.targetingId)) {
      return 'Går inte att ändra bokad kampanjdel till pausreklam';
    } else {
      return null;
    }
  }

  private getBudgetChoices(targeting: Targeting) {
    return [
      { label: 'Pengar', value: true },
      { label: this.formatterService.getCurrencyName(targeting, true), value: false },
    ];
  }
}
