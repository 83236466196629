import { Component, OnInit } from '@angular/core';
import { clone } from 'lodash-es';
import { PublisherOutcome, TrackingService } from '../../../woo_dashboard.module/services/TrackingService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { PublisherGroup, PublisherGroupService } from '../../../woo_services.module/PublisherGroupService';
import { SystemService, SystemSettings } from '../../../woo_services.module/SystemService';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'publisher-group-settings',
  templateUrl: './publisher-group-settings.component.html',
})
export class PublisherGroupSettings implements OnInit {
  publisherGroups: PublisherGroup[];
  publisherOutcomes: PublisherOutcome[];
  publisherOutcomeTotal = 0;
  settings: SystemSettings;

  loaded = {
    publisherGroupSettings: false,
    publisherOutcomes: false,
    systemServiceSettings: false,
  };

  constructor(
    private dialogService: DialogService,
    private publisherGroupService: PublisherGroupService,
    private trackingService: TrackingService,
    private translationService: TranslationService,
    private systemService: SystemService,
  ) {}

  ngOnInit(): void {
    this.loadPublisherGroupsSettings();
    this.loadSystemSettings();
    this.trackingService
      .getPublisherStatistics()
      .then((publisherOutcomes) => {
        this.publisherOutcomes = publisherOutcomes;
        this.publisherOutcomeTotal = publisherOutcomes.reduce(
          (sum, publisherOutcome) => sum + publisherOutcome.impressions,
          0,
        );
      })
      .finally(() => (this.loaded.publisherOutcomes = true));
  }

  updatePublisherGroups(): void {
    const apiPublisherGroups = this.publisherGroups.map(function (publisherGroup) {
      const apiPublisherGroup = clone(publisherGroup);
      apiPublisherGroup.woo_rbs_share /= 100;
      apiPublisherGroup.woo_local_rbs_share /= 100;
      apiPublisherGroup.woo_target_group_rbs_share /= 100;
      return apiPublisherGroup;
    });
    this.publisherGroupService.updatePublisherGroups(apiPublisherGroups).then(() => {
      this.systemService.update(this.settings).then(() => {
        this.dialogService.openSuccess('Publicistgrupperna har uppdaterats');
      });
    });
  }

  private loadSystemSettings() {
    this.systemService.loadSystemwideSettings().then((systemSettings) => {
      this.settings = systemSettings;
      this.loaded.systemServiceSettings = true;
    });
  }

  private loadPublisherGroupsSettings() {
    this.publisherGroupService.getPublisherGroups().then((publisherGroups) => {
      this.publisherGroups = publisherGroups.map((publisherGroup) => {
        publisherGroup.woo_rbs_share *= 100;
        publisherGroup.woo_local_rbs_share *= 100;
        publisherGroup.woo_target_group_rbs_share *= 100;
        return publisherGroup;
      });
      this.loaded.publisherGroupSettings = true;
    });
  }
}
