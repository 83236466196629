import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

export interface PublisherGroup {
  id: wooId;
  name: string;
  quota: number;
  woo_rbs_share: number;
  woo_local_rbs_share: number;
  woo_target_group_rbs_share: number;
}

@Injectable({ providedIn: 'root' })
export class PublisherGroupService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getPublisherGroups(): Promise<PublisherGroup[]> {
    return lastValueFrom(this.http.get<PublisherGroup[]>(`${this.env.apiUrl}/publisher_groups`));
  }

  updatePublisherGroups(publisherGroups: PublisherGroup[]): Promise<PublisherGroup[]> {
    return lastValueFrom(
      this.http.put<PublisherGroup[]>(`${this.env.apiUrl}/publisher_groups`, {
        publisher_groups: publisherGroups,
      }),
    );
  }
}
