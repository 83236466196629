import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { WooComponentsModule } from '../woo_components.module/module';
import { CampaignRowCard } from './components/campaign-row-card.component';
import { CampaignRowList } from './components/campaign-row-list.component';
import { EosAggregations } from './components/eos-aggregations.component';
import { EosApiTester } from './components/eos-api-tester.component';
import { Eos } from './components/eos.component';

@NgModule({
  imports: [BrowserModule, FormsModule, WooComponentsModule],
  declarations: [CampaignRowList, CampaignRowCard, Eos, EosAggregations, EosApiTester],
  exports: [Eos],
})
export class WooEos {}
