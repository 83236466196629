<table class="table" *ngIf="visibleContracts?.length">
  <thead>
    <tr>
      <th>Rabatter</th>
      <th>Startdatum</th>
      <th>Slutdatum</th>
      <th>Byrå</th>
      <th>Skapad</th>
      <th *ngIf="enableDelete && isAdminOrPlannerOrSales">Ta bort</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let contract of visibleContracts"
      [hidden]="contract.ended && !showEnded"
      [ngClass]="{'inactive': contract.ended}"
    >
      <td>
        <ul class="reset--list" *ngFor="let contractPart of contract.discounts">
          <li *ngFor="let discount_type of contractPart.discount_types">
            <div class="tab tab--small">
              {{translateDiscountType(discount_type)}} {{contractPart.discount_rate | percent: '1.0-2'}}
            </div>
          </li>
        </ul>
      </td>
      <td>{{contract.start_date | date:'yyyy-MM-dd'}}</td>
      <td>{{contract.end_date | date:'yyyy-MM-dd'}}</td>
      <td>{{contract.agency?.nickname || '-'}}</td>
      <td>{{contract.created_at | date:'yyyy-MM-dd'}}</td>
      <td *ngIf="enableDelete && isAdminOrPlannerOrSales">
        <button class="text-danger" (click)="delete(contract)">
          <span class="icon-trash"></span>
        </button>
      </td>
    </tr>
  </tbody>
</table>
<p class="text--italic" *ngIf="!visibleContracts?.length">
  Det finns inga upplagda.
</p>
