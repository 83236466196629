import { NG_VALIDATORS, Validator, FormControl, ValidationErrors } from '@angular/forms';
import { Directive } from '@angular/core';
import { CashCustomerCodeService } from '../../woo_services.module/video_code_validation/CashCustomerCodeService';

@Directive({
  selector: '[cashVideoCodeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CashVideoCodeValidator, multi: true }],
})
export class CashVideoCodeValidator implements Validator {
  constructor(private cashCustomerCodeService: CashCustomerCodeService) {}

  validate(formControl: FormControl): ValidationErrors | null {
    return this.cashCustomerCodeService.validate(formControl.value);
  }
}
