<form ngNativeValidate #f="ngForm" (ngSubmit)="newAdHocContract()">
  <div class="row">
    <period-select
    name="period"
    [ngModel]="period"
    (ngModelChange)="periodChanged($event)"
    ></period-select>
  </div>
  <div class="row">
    <div class="col-xs-6" *ngIf="agencies.length > 0">
      <div class="select__container">
        <label for="agency">Byrå</label>
        <select id="agency" name="agency_id" [(ngModel)]="contract.agency_id" class="no-valid-color">
          <option [value]="agency.id" *ngFor="let agency of agencies">{{agency.nickname}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <product-discount-input name="product-discount-input" [(ngModel)]="contract.discounts"></product-discount-input>
    </div>
  </div>
  <div class="margin-top--2x">
    <button type="submit" class="button primary-1" [disabled]="!allFieldsValid()">Lägg till rabatt</button>
    <button type="button" class="link" *ngIf="onAbort.observers.length" (click)="onAbort.emit()">Avbryt</button>
  </div>
</form>
