import { Injectable } from '@angular/core';
import { AssetDimensions, AssetMetaData, AssetUploadErrors } from './asset_upload_types';

export interface MP4ValidatorOptions {
  maxFilmDurationSeconds?: number;
  maxFileSizeBytes?: number;
  requiredAspectRatio?: number;
  maxDimensions?: AssetDimensions;
  minDimensions?: AssetDimensions;
}

@Injectable({ providedIn: 'root' })
export class MP4Validator {
  private maxFileSizeBytes = 60 * 1024 * 1024;
  private maxSizeForValidation = 80 * 1024 * 1024;
  private maxFilmDurationSeconds = 60;
  private requiredAspectRatio = 16 / 9;

  // TODO: Do we want to check these? If left null these checks will currently be skipped.
  private maxDimensions: AssetDimensions;
  private minDimensions: AssetDimensions;

  setValidationOptions = (options: MP4ValidatorOptions): void => {
    if (!options) {
      return;
    }

    if (options.maxFileSizeBytes) {
      this.maxFileSizeBytes = options.maxFileSizeBytes;
      const maxSizeMB = this.maxFileSizeBytes / 1024 / 1024;
      this.maxSizeForValidation = (maxSizeMB + 20) * 1024 * 1024;
    }

    options.maxFilmDurationSeconds && (this.maxFilmDurationSeconds = options.maxFilmDurationSeconds);
    options.requiredAspectRatio && (this.requiredAspectRatio = options.requiredAspectRatio);
    options.maxDimensions && (this.maxDimensions = options.maxDimensions);
    options.minDimensions && (this.minDimensions = options.minDimensions);
  };

  async validate(file: File): Promise<AssetMetaData> {
    let aspectRatioError: string;
    let durationError: string;
    let dimensionError: string;
    let metaData: AssetMetaData;

    let fileTypeError = this.validateFileType(file);
    const fileSizeError = this.validateFileSize(file);

    if (!fileTypeError && !fileSizeError) {
      try {
        const video = await this.readVideoFile(file);
        metaData = await this.getVideoMetaData(video);

        durationError = this.validateDuration(metaData);
        aspectRatioError = this.validateAspectRatio(metaData.dimensions);
        dimensionError = this.maxDimensions || this.minDimensions ? this.validateDimensions(metaData.dimensions) : null;
      } catch {
        fileTypeError =
          'Filmen kunde inte läsas in, dubbekolla att filmen går att spela och att den har rätt filändelse (måste vara .mp4)';
        console.error(
          'Failed to read uploaded file for MP4 video validation (browser considers file not to be a video file)',
        );
      }
    }

    return new Promise<AssetMetaData>((resolve, reject) => {
      if (
        !fileSizeError &&
        !durationError &&
        !aspectRatioError &&
        !dimensionError &&
        !fileTypeError &&
        !fileSizeError
      ) {
        resolve(metaData);
      } else {
        reject({
          ...(fileTypeError ? { fileTypeError } : {}),
          ...(fileSizeError ? { fileSizeError } : {}),
          ...(durationError ? { durationError } : {}),
          ...(aspectRatioError ? { aspectRatioError } : {}),
          ...(dimensionError ? { dimensionError } : {}),
        } as AssetUploadErrors);
      }
    });
  }

  private validateFileType(file: File): string | null {
    if (file.type.includes('video/')) {
      return this.correctExtension(file) ? null : 'Filmen har inte en korrekt filändelse (måste vara .mp4)';
    } else {
      return 'Filen måste vara en film av mp4-format';
    }
  }

  private validateFileSize(file: File): string | null {
    return this.correctSize(file) ? null : 'Filmen är för stor (max 50MB)';
  }

  private validateDuration(metaData: AssetMetaData): string | null {
    return Math.floor(metaData.duration) <= this.maxFilmDurationSeconds
      ? null
      : `Filmen är för lång (max ${this.maxFilmDurationSeconds} s)`;
  }

  private validateAspectRatio(dimensions: AssetDimensions): string | null {
    return dimensions.width / dimensions.height === this.requiredAspectRatio
      ? null
      : `Filmens dimensioner matchar inte kraven (filmen måste vara i 16:9)`;
  }

  private validateDimensions(dimensions: AssetDimensions): string | null {
    if (dimensions.width > this.maxDimensions.width || dimensions.height > this.maxDimensions.height) {
      return `Filmens upplösning är för stor (får max vara ${dimensions.width}x${dimensions.height} px)`;
    }
    if (dimensions.width < this.minDimensions.width || dimensions.height < this.minDimensions.height) {
      return `Filmens upplösning är för liten (får minst vara ${dimensions.width}x${dimensions.height} px)`;
    }

    return null;
  }

  private readVideoFile(file: File): Promise<HTMLVideoElement> {
    return new Promise((res, rej) => {
      if (!file.type.includes('video/')) {
        rej(null);
      }

      const reader = new FileReader();
      reader.onerror = () => {
        rej(null);
      };
      reader.onload = () => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onerror = () => {
          rej(null);
        };
        video.src = reader.result as string;
        res(video);
      };
      reader.readAsDataURL(file);
    });
  }

  private getVideoMetaData(video: HTMLVideoElement): Promise<AssetMetaData> {
    return new Promise((res, rej) => {
      if (!video) {
        rej(null);
      }

      video.onerror = () => {
        rej(null);
      };
      video.onloadedmetadata = () => {
        if (!video.videoHeight || !video.videoWidth || !video.duration) {
          rej(null);
        }
        res({
          dimensions: {
            width: video.videoWidth,
            height: video.videoHeight,
          },
          duration: video.duration,
        });
      };
    });
  }

  private correctExtension(file: File): boolean {
    return file.name.endsWith('.mp4') || /mp4$/.test(file.type);
  }

  // 60MB is accepted but 50MB should be communicated
  private correctSize(file: File): boolean {
    return file.size <= this.maxFileSizeBytes;
  }
}
