import { Component, Input, OnChanges } from '@angular/core';
import { matchString } from '../../../utils/string';
import { SimpleChanges } from '../../../utils/types';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { User } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
})
export class UserList implements OnChanges {
  @Input() activeUsers: User[] = [];
  @Input() inactiveUsers: User[] = [];

  searchString = '';
  filteredActiveUsers = [] as ListUser[];
  filteredInactiveUsers = [] as ListUser[];
  showInactiveUsers = false;
  readonly isAdmin: boolean = this.authService.admin();

  constructor(private translationService: TranslationService, private authService: AuthService) {}

  ngOnChanges(changes: SimpleChanges<UserList>): void {
    if (changes.activeUsers && this.activeUsers) {
      this.filteredActiveUsers = this.activeUsers.map(this.createListUser);
      this.filter();
    }
    if (changes.inactiveUsers && this.inactiveUsers) {
      this.filteredInactiveUsers = this.inactiveUsers.map(this.createListUser);
      this.filter();
    }
  }

  /**
   * In order to increase performance we do not filter the lists which would
   *   case element to be added/removed from the DOM. Instead we just set a
   *   flag in the element.
   */
  filter = (): void => {
    const searchFilter = (user: ListUser) => {
      return !this.searchString || matchString(user.search, this.searchString.toLowerCase());
    };
    const inactiveFilter = () => {
      return this.showInactiveUsers && this.isAdmin;
    };
    this.filteredActiveUsers.forEach((user) => (user.hidden = !searchFilter(user)));
    this.filteredInactiveUsers.forEach((user) => (user.hidden = !inactiveFilter() || !searchFilter(user)));
  };

  private createListUser = (user: User): ListUser => {
    const name = `${user.first_name} ${user.last_name}`;
    const roleNames = user.roles.filter(this.roleFilter).map((role) => this.translationService.convertRoles(role.name));
    return {
      id: user.id,
      name: name,
      email: user.email,
      hidden: false,
      roles: roleNames,
      search: `${name} ${roleNames.join(' ')} ${user.id} ${user.email}`.toLowerCase(),
    };
  };

  private roleFilter = (role: { name: string }) => role.name !== UserRole.agencyUserAtCustomer;
}

interface ListUser {
  id: string;
  name: string;
  email: string;
  hidden: boolean;
  roles: string[];
  search: string;
}
