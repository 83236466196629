import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgChartsModule } from 'ng2-charts';
import { WooComponentsModule } from '../woo_components.module/module';
import { FrequencyLimitPerMonthGraph } from './components/frequency-limit-per-month-graph.component';
import { FrequencyLimitPerMonthTable } from './components/frequency-limit-per-month-table.component';
import { StatisticsDashboard } from './components/statistics-dashboard.component';
import { CampaignFrequencyReportService } from './services/campaign-frequency-report.service';

@NgModule({
  imports: [BrowserModule, NgChartsModule, WooComponentsModule],
  providers: [CampaignFrequencyReportService],
  declarations: [FrequencyLimitPerMonthGraph, FrequencyLimitPerMonthTable, StatisticsDashboard],
  exports: [StatisticsDashboard],
})
export class WooStatisticsDashboard {}
