<form ngNativeValidate (ngSubmit)="newVoucher()">
  <div class="row">
    <div class="input__container col-xs-5">
      <label for="discount-rate">Rabattsats (%)</label>
      <input 
        required
        id="discount-rate"
        type="text"
        wooPercentFormatter
        [wooMinValidator]="0.001"
        [wooMaxValidator]="0.999"
        name="discount-rate"
        [(ngModel)]="voucher.discount"
      >
    </div>
  </div>
  <div class="row" *ngIf="agencies.length > 0">
    <div class="col-xs-10">
      <div class="select__container">
        <label for="agency">Byrå</label>
        <select id="agency" name="agency" [(ngModel)]="voucher.agency.id" class="no-valid-color">
          <option [value]="agency.id" *ngFor="let agency of agencies">{{agency.nickname}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row" *ngFor="let condition of conditions; index as index">
    <div class="col-xs-12">
      <div class="select__container">
        <label for="condition--{{condition.type}}">{{convertVoucherConditions(condition.type)}}</label>
        <select
          id="condition--{{condition.type}}"
          name="condition--{{condition.type}}"
          [(ngModel)]="voucher.conditions[index].values"
          required
        >
          <option value=""></option>
          <option [ngValue]="[value]" *ngFor="let value of condition.accepted_values">{{convertDiscount(value)}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="margin-top--2x">
    <button type="submit" class="button primary-1" [disabled]="!validDiscountAmount()">Lägg till rabatt</button>
    <button type="button" class="link" *ngIf="onAbort.observers.length" (click)="onAbort.emit()">Avbryt</button>
  </div>
</form>
