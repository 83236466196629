<div class="full-width-view wrapper" [ngStyle]="{'padding-bottom.px': footerHeight + 16}">
  <div class="content__container">
    <h1 *ngIf="!editingCampaign">Boka ny kampanj</h1>
    <h1 *ngIf="editingCampaign">Redigera kampanj</h1>
    <div *ngIf="campaign.reject_message" class="row margin--none__phone">
      <context-message class="col-md-7 col-xs-12 padding--none__phone margin-bottom--1x" [message]="campaign.reject_message" messageType="error"></context-message>
    </div>

    <div class="row margin-top--4x">
      <div class="col-md-6">
        <asset-select (assetChange)="assetChanged($event)" [initialAsset]="initialValues.asset"></asset-select>
      </div>
    </div>

    <div class="row margin-top--4x">
      <div class="col-md-4">
        <div class="input__container">
          <span class="info__text"></span>
          <label for="text-input">Kampanjnamn</label>
          <input type="text" id="name-input" name="campaign-name" [(ngModel)]="campaignName" (ngModelChange)="nameChanged(campaignName)" required tabindex="0">
        </div>
      </div>

      <div class="col-md-2">
        <div class="input__container">
          <label for="text-input">Budget (SEK)</label>
          <input type="text" id="budget-input" name="budget" [(ngModel)]="campaignBudget"  (ngModelChange)="budgetChanged(campaignBudget)" tabindex="0" wooNumberFormatter>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="input__container">
          <span class="input__optional__text">Frivillig</span>
          <label for="text-input">Landningssida</label>
          <input type="url" id="dest-input" name="dest-url" [(ngModel)]="destinationUrl" (ngModelChange)="destinationUrlChanged(destinationUrl)" pattern="https://.+" tabindex="0">
          <span class="input__help__text">URL till den sida som tittaren hamnar på om han eller hon klickar på filmen på en enhet som stöder det. (Måste börja med https://)</span>
        </div>
      </div>
    </div>

    <div class="row margin-top--6x">
      <div class="col-md-8">
        <div>
          <h2>När vill du visa din kampanj?</h2>
          <period-select
            [ngModel]="initialValues.period"
            (ngModelChange)="periodChanged($event)"
            [firstSelectableDate]="campaignRequirements.minDate"
          ></period-select>
        </div>
      </div>
    </div>

    <h2 class="margin-top--4x">Vem vill du ska se din kampanj?</h2>
    <div class="row">
      <div class="col-xs-12 col-sm-4">
        <div class="display--flex">
          <img class="default-logo padding-right--2x" src="../../../../images/logos/tv4.svg" alt="tv4 logga">
          <p>Din kampanj kommer att visas på TV4's digitala plattformar.</p>
        </div>
      </div>
    </div>

    <div class="row margin-top-bottom--2x">
      <div class="col-md-8">
            Du kan välja att lägga på en styrning av din kampanj, antingen på geografiskt område eller målgrupp.
            Det går inte att kombinera olika sorters styrningar.
            Väljer du ingen styrning kommer din kampanj visas där det finns plats utan styrning på område eller målgrupp.
      </div>
    </div>
    <targeting-select (modelChange)="targetingChange($event)" [initialTargeting]="initialValues.targeting"></targeting-select>

    <div class="visible-xs padding-top--3x">
      <div class="row" *ngIf="statusService.hasError()">
          <div class="col-xs-12 col-sm-6 padding-bottom--1x">
          <context-message *ngFor="let message of statusService.getTranslatedErrors()" [message]="message" messageType="error">
          </context-message>
        </div>
      </div>
      <button class="button primary-1 display--block margin--center" (click)="nextStep.emit()" [disabled]="!isCampaignBookable()" id="next-step-btn-mobile">Nästa</button>
      <button class="button secondary display--block margin--center" (click)="saveDraft.emit()" [disabled]="!isCampaignSavable()" id="save-campaign-btn-mobile">Spara utkast</button>
    </div>

    <div id="footer" #footer class="booking__footer horizontal-align" >
      <div class="content__container">
        <div class="row hidden-xs" *ngIf="statusService.hasError()" [@verticalExpansion]>
          <div class="col-xs-12 col-sm-6">
            <context-message *ngFor="let message of statusService.getTranslatedErrors()" [message]="message" messageType="error">
            </context-message>
          </div>
        </div>
        <div class="flex--align justify-content--space-between">
          <h3 class="display--inline-block margin--none" [ngClass]="{'text--smaller': statusService.hasError()}">Antal färdigspelade visningar:
            <loader class="padding-right--5x" *ngIf="loading.estimation"></loader>
            <span class="text--gray" *ngIf="!isCampaignSavable() && !loading.estimation">-</span>
            <span class="text--strong" *ngIf="isCampaignSavable() && !loading.estimation">{{estimatedCompletedViews | number}} st</span>
          </h3>
          <div class="hidden-xs">
            <button class="button primary-1" (click)="nextStep.emit()" [disabled]="!isCampaignBookable() || loading.estimation" id="next-step-btn">Nästa</button>
            <button class="button secondary" (click)="saveDraft.emit()" [disabled]="!isCampaignSavable() || loading.estimation" id="save-campaign-btn">Spara utkast</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
