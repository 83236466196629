import { Injectable } from '@angular/core';
import { ExtraPublisherField, PublisherService } from '../../woo_services.module/PublisherService';
import { Publisher } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedPublisherService implements Pick<PublisherService, 'getPublishers'> {
  readonly defaultPublisher: string;
  private listCache: Publisher[] = null;

  constructor(private publisherService: PublisherService) {
    this.defaultPublisher = publisherService.defaultPublisher;
  }

  async getPublishers(): Promise<Publisher[]> {
    if (!this.listCache) {
      this.listCache = await this.publisherService.getPublishers(
        ExtraPublisherField.categories,
        ExtraPublisherField.gambling,
        ExtraPublisherField.enabled,
      );
    }
    return this.listCache;
  }
}
