<div class="status-badge large_icon success" [ngClass]="{ 'small': small }" *ngIf="campaignStatus === CampaignStatus.unbooked">
  <span class="icon-TV4_005_spara"></span>
  <div class="hover-text">Sparad</div>
</div>
<div class="status-badge large_icon cta" [ngClass]="{ 'small': small }" *ngIf="campaignStatus === CampaignStatus.booked">
  <span class="icon-waiting-outline"></span>
  <div class="hover-text">Bokad</div>
</div>
<div class="status-badge large_icon success" [ngClass]="{ 'small': small }" *ngIf="campaignStatus === CampaignStatus.upcoming || campaignStatus === CampaignStatus.approved">
  <span class="icon-check"></span>
  <div class="hover-text">Godkänd</div>
</div>
<div class="status-badge large_icon success" [ngClass]="{ 'small': small }" *ngIf="campaignStatus === CampaignStatus.ongoing">
  <span class="icon-jump-forward"></span>
  <div class="hover-text">Pågående</div>
</div>
<div class="status-badge large_icon error" [ngClass]="{ 'small': small }" *ngIf="campaignStatus === CampaignStatus.cancelled">
  <span class="icon-close-x"></span>
  <div class="hover-text">Avbokad</div>
</div>
<div class="status-badge large_icon error" [ngClass]="{ 'small': small }" *ngIf="campaignStatus === CampaignStatus.stopped">
  <span class="icon-close-x"></span>
  <div class="hover-text">Stoppad</div>
</div>
<div class="status-badge large_icon success" [ngClass]="{ 'small': small }" *ngIf="campaignStatus === CampaignStatus.ended">
  <span class="icon-check"></span>
  <div class="hover-text">Avslutad</div>
</div>
<div class="status-badge success" [ngClass]="{ 'small': small }" *ngIf="campaignStatus === CampaignStatus.closed">
  <span class="icon-locked"></span>
  <div class="hover-text">Stängd</div>
</div>
