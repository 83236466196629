import { Injectable } from '@angular/core';
import { ExtraProductFormatField, ProductFormatService } from '../../woo_services.module/ProductFormatService';
import { ProductFormat } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedProductFormatService implements Pick<ProductFormatService, 'getProductFormats'> {
  private listCache: ProductFormat[] = null;

  constructor(private productFormatService: ProductFormatService) {}

  async getProductFormats(): Promise<ProductFormat[]> {
    if (!this.listCache) {
      this.listCache = await this.productFormatService.getProductFormats(ExtraProductFormatField.format);
    }
    return this.listCache;
  }
}
