import { Component, Input, OnInit } from '@angular/core';
import { saveFileAs } from '../../utils/files';
import { Pagination } from '../../woo_services.module/PaginationService';
import { EstimateImport, EstimateImportService, EstimateImportType } from '../services/EstimateImportService';

@Component({
  selector: 'estimates-list',
  templateUrl: './estimates-list.component.html',
})
export class EstimatesList implements OnInit {
  @Input() type: EstimateImportType;

  estimates: EstimateImport[] = [];
  pagination: Pagination = null;

  constructor(private importService: EstimateImportService) {}

  ngOnInit(): void {
    this.load(1);
  }

  loadNext = (): void => this.load(this.pagination.page + 1);
  loadPrevious = (): void => this.load(this.pagination.page - 1);
  reload = (): void => this.load(this.pagination.page);

  download = (estimate: EstimateImport): void => {
    this.importService.download(estimate.id).then((result: Blob) => {
      saveFileAs(result, estimate.name);
    });
  };

  private load = (page: number) => {
    this.importService.list(this.type, page).then(([data, pagination]) => {
      this.estimates = data;
      this.pagination = pagination;
    });
  };
}
