import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../../../woo_services.module/InvoiceService';

@Component({
  selector: 'invoice-attestation',
  templateUrl: './invoice-attestation.component.html',
})
export class InvoiceAttestation implements OnInit {
  TableType = TableType;

  invoices = {
    approved: [],
    waitingForApproval: [],
  };
  loadingInvoices = {
    approved: true,
    waitingForApproval: true,
  };

  constructor(private invoiceService: InvoiceService) {}

  async ngOnInit(): Promise<void> {
    await this.loadInvoices();
  }

  async loadInvoices(): Promise<void> {
    this.invoices.waitingForApproval = await this.invoiceService.getAwaitingAttest();
    this.loadingInvoices.waitingForApproval = false;
    this.invoices.approved = await this.invoiceService.getSentCreditInvoices();
    this.loadingInvoices.approved = false;
  }
}

export enum TableType {
  Approved = 'approved',
  WaitingForApproval = 'waitingForApproval',
}
