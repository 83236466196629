import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { DefaultTargetingData } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DefaultDataService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getTargetingData(): Promise<DefaultTargetingData> {
    return lastValueFrom(this.http.get<DefaultTargetingData>(`${this.env.apiUrl}/default_data/targeting`));
  }
}
