<div class="clearfix">
  <h4 class="thin margin-top" *ngIf="!isLoading.search && !isLoading.campaigns && campaignListsEmpty">Vi hittade tyvärr inte det du sökte.</h4>

  <dot-loader [visibility]="isLoading.search" description="Söker" *ngIf="isLoading.search"></dot-loader>
  <dot-loader [visibility]="isLoading.campaigns" description="Hämtar kampanjer" *ngIf="isLoading.campaigns"></dot-loader>

  <div *ngIf="adminOrPlanner">
    <campaign-approval-list
      [approvableCampaigns]="approvableCampaigns"
      (onClick)="cardClick($event)">
    </campaign-approval-list>
  </div>

  <ng-container *ngFor="let set of campaignSections">
    <div class="toggable__tab toggleTab no--animation" id="{{set.route}}" *ngIf="set.list?.length">
      <div class="toggler">
        <div class="section info padding-top-bottom--1x margin-top--3x">
          <h3 class="margin--none" >
            <span class="chevron"></span>
            {{set.name}}
          </h3>
        </div>
      </div>
      <div (click)="$event.stopPropagation()">
        <div class="section gray">
          <div class="padding--1x">
            <pagination [pagination]="set.pagination" (pageSelected)="pageSelected(set, $event)"></pagination>
          </div>
        </div>
        <div class="clearfix" style="margin-left: 2px;">
          <div class="clearfix">
            <dot-loader [visibility]="set.loading"></dot-loader>
          </div>
          <dashboardcard
            class="no-outline"
            tabindex="490"
            *ngFor="let campaign of set.list"
            id="{{campaign.id}}"
            [campaign]="campaign"
            (onEditCallback)="goToCreativesTab(campaign)"
            [hasRoleAdminOrPlanner]="adminOrPlanner"
            [active]="campaign.id === activeCampaign?.id"
            (onClick)="cardClick(campaign)"
            (onFocus)="cardClick(campaign)"
            [cardListing]="cardListing">
          </dashboardcard>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="text--center" *ngIf="totalCampaigns === 0 && !isLoading.campaigns">
    <h1 class="thin">Det finns inga kampanjer att visa.</h1>
    <button *ngIf="allowedToBook && !cashUser" class="button primary-1" [routerLink]="['/campaigns', 'new']">Boka din första kampanj</button>
    <button *ngIf="allowedToBook && cashUser" class="button" [routerLink]="['/customer', idOfCurrentUsersCashCustomer, campaign]"><span class="text--medium thin">Boka din första kampanj</span></button>
  </div>

</div>
