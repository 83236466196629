import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { DashboardService } from '../../../woo_services.module/DashboardService';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { Campaign, DashboardLoad, DashboardTab } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'dashboard-campaigns',
  templateUrl: './dashboard-campaigns.component.html',
})
export class DashboardCampaigns extends SubscriptionHandler implements OnInit {
  readonly adminOrPlanner = this.authService.hasAnyRole([UserRole.admin, UserRole.planner]);
  readonly cashUser = this.authService.hasRole(UserRole.cashUser);

  @Input() totalCampaigns;
  @Input() cardListing;

  activeCampaign: Campaign;
  approvableCampaigns;
  campaignSections = [];
  isLoading: Record<DashboardLoad, boolean>;
  campaignListsEmpty: boolean;
  allowedToBook: boolean;
  idOfCurrentUsersCashCustomer;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private dashboardStore: DashboardStore,
    private routingService: RoutingService,
  ) {
    super();
    this.addSubscription(this.dashboardStore.state$.subscribe(this.initFromStore));
  }
  initFromStore = (model: DashboardModel): void => {
    this.activeCampaign = model.activeCampaign;
    this.isLoading = model.isLoading;
    this.campaignListsEmpty = this.campaignSections.every((campaignSection) => campaignSection.list.length === 0);
  };

  ngOnInit(): void {
    this.campaignSections = Object.values(this.dashboardService.campaigns);
    if (this.adminOrPlanner) {
      this.loadApprovableCampaigns();
    }
    this.allowedToBook = this.authService.allowedToBook();
    this.idOfCurrentUsersCashCustomer = this.routingService.idOfCurrentUsersCashCustomer();
  }

  cardClick(campaign: { id: string }): void {
    this.routingService.goToDashboardWithCampaign(campaign.id, true);
  }

  pageSelected(set: { route: string; search: any }, page: number): void {
    this.dashboardService.loadCampaignSet(set.route, page, set.search);
  }

  goToCreativesTab(campaign: { id: string }): void {
    this.routingService.goToDashboardTab(campaign.id, DashboardTab.creatives);
  }

  private loadApprovableCampaigns() {
    this.dashboardService.getApprovableCampaigns().then((campaigns) => {
      const campaignIds = campaigns.map((campaign) => campaign.id);
      this.dashboardStore.setCampaignsWithAlerts(campaignIds);
      this.approvableCampaigns = campaigns;
      this.approvableCampaigns.map((approvableCampaign) => {
        approvableCampaign.creativeReady = true;
        approvableCampaign.creatives.map((creative) => {
          if (approvableCampaign.creativeReady) {
            approvableCampaign.creativeReady = creative.status === 'ready';
          }
        });
      });
    });
  }
}
