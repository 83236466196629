<div class="margin-top--3x">
  <h1>Statistik dashboard</h1>

  <div class="row">
    <div class="col-xs-6">
      <div class="padding--2x shadow-z-1 background-color--white">
        <h3>Snittfrekvens per månad</h3>
        <dot-loader [visibility]="!frequencyReports"></dot-loader>
        <frequency-limit-per-month-table *ngIf="frequencyReports" [reports]="frequencyReports">
        </frequency-limit-per-month-table>
      </div>
    </div>
    <div class="col-xs-6">
      <div class="padding--2x shadow-z-1 background-color--white">
        <h3>Snittfrekvens per månad</h3>
        <dot-loader [visibility]="!frequencyReports"></dot-loader>
        <frequency-limit-per-month-graph *ngIf="frequencyReports" [reports]="frequencyReports">
        </frequency-limit-per-month-graph>
      </div>
    </div>
  </div>
</div>
