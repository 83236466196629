import { Component, Input } from '@angular/core';

const template = /* html */ `
  <table class="table table-woo table--hover width--100">
    <thead>
      <tr>
        <th *ngFor="let header of headers" class="text--thin text--small capitalize--first-letter">
          {{header}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows" class="{{row.rowClass}}">
        <td *ngFor="let cell of row.cells" class="text--thin" class="{{cell.cellClass}}">
          {{cell.value}}
        </td>
      </tr>
    </tbody>
  </table>
`;

export type TvDayOutcomeTableRows = Array<{
  cells: { value: number | string; cellClass?: string }[];
  rowClass?: string;
}>;

@Component({
  selector: 'tv-day-outcome-table',
  template: template,
  styleUrls: ['./tv-day-outcome-table.component.scss'],
})
export class TvDayOutcomeTable {
  @Input() headers: string[];
  @Input() rows: TvDayOutcomeTableRows;
}
