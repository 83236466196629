import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from '../../../utils/dom';
import { DialogService } from '../../../woo_services.module/DialogService';
import {
  FilterOptionKeys,
  InventoryFilterOption,
  InventoryFilterValue,
  InventoryService,
  InventoryWeeks,
} from '../../../woo_services.module/InventoryService';
import { ProgramFormatService } from '../../../woo_services.module/ProgramFormatService';
import { Program, ViewCurrency } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'buylist',
  templateUrl: './buylist.component.html',
})
export class Buylist implements OnInit {
  tabs = {
    withdrawalLimits: 'withdrawalLimits',
    programFormats: 'programFormats',
  };

  show = {
    tab: this.tabs.withdrawalLimits,
  };

  weeks: InventoryWeeks;

  setPeriodFromFormat = false;
  private defaultFilterFields = { key: 'fields', value: ['withdrawal_limit', 'withdrawal_limit_business_currency'] };
  readonly columnNamesInventoryCurrency = { withdrawal_limit: 'Maxuttag' };
  readonly columnNamesBusinessCurrency = { withdrawal_limit_business_currency: 'Maxuttag' };
  columnNames: any = this.columnNamesInventoryCurrency;
  timeStamp = {};

  filters = {} as { [name: string]: InventoryFilterValue[] };
  filterOptionsBuylist: InventoryFilterOption[] = [];
  breadcrumb = '';
  fetchingFilters = true;
  inventoryTree = undefined;
  fetchingData = false;
  visibleLevels = [];

  programFormats: Program[] = [];
  defaultFilterOptions;
  presentationCurrency: ViewCurrency = ViewCurrency.impressions;
  readonly viewCurrencyLabel = this.translationService.translateViewCurrency;

  @ViewChild('buylistResults', { static: false, read: ElementRef }) buylistResults: ElementRef;

  constructor(
    private dialogService: DialogService,
    private inventoryService: InventoryService,
    private programFormatService: ProgramFormatService,
    private changeDetectorRef: ChangeDetectorRef,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    Promise.all([
      this.inventoryService.getFilterOptionsForBuylist(),
      this.programFormatService.getProgramFormats(),
    ]).then(([options, programFormats]) => {
      this.fetchingFilters = false;
      this.filterOptionsBuylist = this.inventoryService.pushRadioOptionsToLast(options);
      this.programFormats = programFormats;
      this.defaultFilterOptions = this.getDefaultFilter(this.filterOptionsBuylist);
      this.presentationCurrency = ViewCurrency.grossRatingViews;
      this.columnNames = this.columnNamesBusinessCurrency;

      const activeAndUpcomingFormatIds = this.programFormatService
        .filterUpcomingAndActiveFormats(programFormats)
        .map((format) => format.id);

      const programFilter = this.filterOptionsBuylist.find((filter) => filter.filter_key === FilterOptionKeys.Programs);
      programFilter.values = programFilter.values.filter((format) => activeAndUpcomingFormatIds.includes(format.id));
    });
  }

  weeksChanged = (weeks: InventoryWeeks): void => {
    this.weeks = weeks;
  };

  getDefaultFilter(filterOptions: InventoryFilterOption[]): Array<{ key: string; value: string | string[] }> {
    const defaultFilterOptions = filterOptions
      .filter((option) => option.values.length === 1 && !option.optional)
      .map((option) => {
        return {
          key: option.filter_key,
          value: option.accepted_values && option.accepted_values === 1 ? option.values[0].key : [option.values[0].key],
        };
      });
    defaultFilterOptions.push(this.defaultFilterFields);
    return defaultFilterOptions;
  }

  clearFilters(): void {
    this.inventoryService.clear$.next();
  }

  getFilterOptionsBuylist(): InventoryFilterOption[] {
    const nonOptionalFilters = this.filterOptionsBuylist.filter(
      (filterOption) => !(filterOption.values.length === 1 && !filterOption.optional),
    );
    this.sortFilterOptionsBuyList(nonOptionalFilters);
    return nonOptionalFilters;
  }

  filter(): void {
    this.fetchingData = true;
    if (this.setPeriodFromFormat && !this.filters.programs) {
      this.dialogService.openError(
        'Du måste välja minst ett programformat om du vill att perioden ska sättas automatiskt.',
      );
      this.fetchingData = false;
      return;
    } else if (this.setPeriodFromFormat && this.filters.programs) {
      const selectedIds = this.filters.programs.map((format) => format.id);
      const selectedProgramFormats = this.programFormats.filter((format) => selectedIds.includes(format.id));
      this.weeks = this.programFormatService.getPeriodFromFormats(selectedProgramFormats);
    }

    const weekParams = this.inventoryService.buildWeekParams(this.weeks);
    const filterParams = this.inventoryService.buildFilterParams(
      this.filters,
      weekParams,
      this.filterOptionsBuylist,
    ) as InventoryWeeks;
    this.breadcrumb = this.inventoryService.getBreadcrumbFromCurrentFilters(this.filters);
    const completeFilterParams = this.updateDefaultFilters(filterParams, this.defaultFilterOptions);
    this.inventoryService.buylistFilter(completeFilterParams).then(
      (inventoryTree) => {
        this.timeStamp = new Date();
        this.inventoryTree = inventoryTree;
        this.fetchingData = false;

        this.changeDetectorRef.detectChanges();
        scrollTo(this.buylistResults.nativeElement);
      },
      () => {
        this.fetchingData = false;
      },
    );
  }

  updateDefaultFilters(
    filterParams: InventoryWeeks,
    defaultFilterOptions: Array<{ key: string; value: string | string[] }>,
  ): InventoryWeeks {
    defaultFilterOptions.forEach((filter) => {
      filterParams[filter.key] = filter.value;
    });
    return filterParams;
  }

  private sortFilterOptionsBuyList(filterOptions: InventoryFilterOption[]) {
    filterOptions.map((options: InventoryFilterOption) => {
      if (options.filter_key === FilterOptionKeys.FilmLength) {
        options.values = options.values.sort((a, b) => (parseInt(a.key) > parseInt(b.key) ? 1 : -1));
      } else {
        options.values = options.values.sort((a, b) => a.name.localeCompare(b.name, 'sv'));
      }
    });
  }
}
