import { Component, Input } from '@angular/core';
import { CampaignStatus } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'dashboard-card-campaign-status',
  templateUrl: './dashboard-card-campaign-status.component.html',
})
export class DashboardCardCampaignStatus {
  readonly CampaignStatus = CampaignStatus;
  @Input() campaignStatus: CampaignStatus;
}
