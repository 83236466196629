import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SimpleChanges } from '../../../../utils/types';
import { CreativeService } from '../../../../woo_services.module/CreativeService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { FormatterService } from '../../../../woo_services.module/FormatterService';
import {
  OutcomeReport,
  OutcomeReportCreative,
  OutcomeReportsService,
} from '../../../../woo_services.module/OutcomeReportsService';
import { Campaign, ViewCurrency } from '../../../../woo_services.module/shared-types';
import { CampaignTracking, OutcomeMmsStatus } from '../../../services/TrackingService';
import { ignore422 } from '../../../../utils/utils';

@Component({
  selector: 'edit-outcome-report',
  templateUrl: './edit-outcome-report.component.html',
  styleUrls: ['./edit-outcome-report.component.scss'],
})
export class EditOutcomeReport implements OnChanges {
  @Input() campaign: Campaign;
  @Input() tracking: CampaignTracking;

  outcomeForm = this.fb.group({
    net_range: undefined,
    average_frequency_by_mms: undefined,
    delivered_imps: undefined,
    comment: undefined,
  });

  loadingMmsData: boolean;
  mmsData: OutcomeReportCreative[] = [];
  reportTableMmsData: string[][] = [];
  mmsDataInvalidated: boolean;
  isGrossRating: boolean;
  reportHeaders: string[];

  outcomeMmsStatus: Record<string, boolean> = {
    ok: false,
    noMmsTrackedCreatives: false,
    includesInvalidMmsDays: false,
    usesPulseActuals: false,
    missingActiveDays: false,
    sharesVideoCode: false,
    userInvalidatedCreativeData: false,
  };

  constructor(
    private creativeService: CreativeService,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private formatterService: FormatterService,
    private outcomeReportsService: OutcomeReportsService,
  ) {}

  async ngOnChanges(changes: SimpleChanges<EditOutcomeReport>): Promise<void> {
    if (changes.campaign && this.campaign) {
      this.loadingMmsData = true;
      this.isGrossRating = this.campaign.agreement.gross_rating_views_summary.active;
      this.reportHeaders = this.headers;
      if (this.isReportPersisted) {
        try {
          await this.setMmsData();
          const report = await this.outcomeReportsService.getOutcomeReport(this.campaign.id);
          this.reportIncludesMmsData(report);
          this.setReportData(report);
          this.outcomeForm.disable();
          this.outcomeForm.patchValue(report);
        } finally {
          this.loadingMmsData = false;
        }
      } else {
        this.outcomeForm.enable();
        try {
          await this.setMmsData();
        } finally {
          this.loadingMmsData = false;
        }
      }
    }

    if (changes.tracking && this.tracking) {
      this.outcomeMmsStatus = this.mmsStatus;
      this.mmsDataInvalidated =
        this.outcomeMmsStatus.noMmsTrackedCreatives || this.outcomeMmsStatus.userInvalidatedCreativeData;
    }
  }

  get mmsStatus(): Record<string, boolean> {
    const outcomeMmsStatus = this.tracking.outcome_mms_status;
    return {
      ok: outcomeMmsStatus.includes(OutcomeMmsStatus.ok),
      includesInvalidMmsDays: outcomeMmsStatus.includes(OutcomeMmsStatus.includesInvalidMmsDays),
      missingActiveDays: outcomeMmsStatus.includes(OutcomeMmsStatus.missingActiveDays),
      noMmsTrackedCreatives: outcomeMmsStatus.includes(OutcomeMmsStatus.noMmsTrackedCreatives),
      sharesVideoCode: outcomeMmsStatus.includes(OutcomeMmsStatus.sharesVideoCode),
      usesPulseActuals: outcomeMmsStatus.includes(OutcomeMmsStatus.usesPulseActuals),
      userInvalidatedCreativeData: outcomeMmsStatus.includes(OutcomeMmsStatus.userInvalidatedCreativeData),
    };
  }

  get isReportPersisted(): boolean {
    return Boolean(this.campaign.outcome_report_id);
  }

  get showAverageFrequency(): boolean {
    return this.hasGrossRatingViewsTargetings || this.hasCompletedViewsTargetings;
  }

  get hasImpressionsTargetings(): boolean {
    return this.campaign.targetings.some((t) => t.view_currency === ViewCurrency.impressions);
  }

  toggleMmsDataValidity(): void {
    this.mmsDataInvalidated = !this.mmsDataInvalidated;
  }

  saveButtonDisabled(): boolean {
    return !this.outcomeForm.valid;
  }

  showMmsTable(): boolean {
    return !this.outcomeMmsStatus.noMmsTrackedCreatives && this.reportTableMmsData.length > 0;
  }

  mmsMarkedAsInvalid(): boolean {
    return this.mmsDataInvalidated && !this.outcomeMmsStatus.noMmsTrackedCreatives;
  }

  saveOutcomeReport = async (): Promise<void> => {
    if (this.outcomeMmsStatus.missingActiveDays && !this.mmsDataInvalidated) {
      await this.dialogService.openConfirm({
        header: 'Det saknas aktiva dagar från adservern',
        textBlocks: [
          'Om kampanjen nyligen slutat kan det vara värt att vänta ett par dagar för rätt statistik.',
          'Eller så har kampanjen pausats i adservern.',
        ],
        confirmText: 'Skapa rapport med befintlig MMS-data',
        cancelText: 'Avbryt',
      });
      await this.createOutcomeReport();
    } else {
      await this.createOutcomeReport();
    }
  };

  dialogRemoveOutcomeRapport = async (): Promise<void> => {
    try {
      await this.dialogService.openConfirm({
        header: 'Ta bort slutstatistik',
        textBlocks: ['Är du säker?', 'Tänk på att kunden redan kan ha laddat ner slutrapporten.'],
        cancelText: 'Avbryt',
        confirmText: 'Ta bort',
      });
      await this.deleteOutcomeReport();
    } catch {}
  };

  private createOutcomeReport = async (): Promise<void> => {
    this.dialogService.openBlocking('Sparar rapport...');
    const report = await this.outcomeReportsService.addOutcomeReport(this.campaign.id, this.getOutcomeReportParams());
    try {
      this.outcomeForm.disable();
      this.campaign.outcome_report_id = report.id;
      this.dialogService.openSuccess(
        'Utfallet sparades och rapporten genererades! Ett mail har även skickats till den som bokat kampanjen.',
      );
    } catch {
      this.dialogService.closeBlocking();
    }
  };

  private get hasCompletedViewsTargetings(): boolean {
    return this.campaign.targetings.some((t) => t.view_currency === ViewCurrency.completedViews);
  }

  private get hasGrossRatingViewsTargetings(): boolean {
    return this.campaign.targetings.some((t) => t.view_currency === ViewCurrency.grossRatingViews);
  }

  private deleteOutcomeReport = async (): Promise<void> => {
    await this.outcomeReportsService.deleteOutcomeReport(this.campaign.id);
    this.outcomeForm.enable();
    this.campaign.outcome_report_id = null;
    await this.setMmsData();
    this.dialogService.openSuccess('Slutstatistiken är nu borttagen och behöver genereras om.');
  };

  private setMmsData = async (): Promise<void> => {
    const isVideoCampaign = this.campaign.targetings.flatMap((t) => t.creatives).some(this.creativeService.isVideo);
    if (isVideoCampaign) {
      try {
        this.mmsData = (await this.outcomeReportsService.getCreatives(this.campaign.id)).map((customAdId) =>
          this.getMmsDataByCreative(customAdId),
        );
        if (this.mmsData && this.mmsData.length) {
          this.setFormattedMmsData();
        }
      } catch (err) {
        ignore422(err);
      }
    } else {
      return Promise.resolve();
    }
  };

  private get headers(): string[] {
    if (this.isGrossRating) {
      return ['Filmkod', 'Bruttokontakter från MMS', 'Impressions från MMS', 'Completion rate från MMS (%)'];
    }

    return ['Filmkod', 'Completed views från MMS', 'Impressions från MMS', 'Completion rate från MMS (%)'];
  }

  private setReportData(report: OutcomeReport): void {
    if (this.reportIncludesMmsData(report)) {
      this.reportTableMmsData = this.formatReportData(report.creatives);

      const cvTot = report.creatives.reduce(
        (sum: number, creative: OutcomeReportCreative) => sum + creative.completed_views_by_mms,
        0,
      );
      const impTot = report.creatives.reduce(
        (sum: number, creative: OutcomeReportCreative) => sum + creative.impressions_by_mms,
        0,
      );
      const grossRatingViewsTot = report.creatives.reduce(
        (sum: number, creative: OutcomeReportCreative) => sum + creative.gross_rating_views_by_mms,
        0,
      );
      const completionRate =
        report.creatives?.length > 1 ? (cvTot / impTot).toPrecision(4) : report.creatives[0]?.completion_rate_by_mms;

      this.reportTableMmsData.push([
        'Summa',
        this.formatterService.transformNumber(this.isGrossRating ? grossRatingViewsTot : cvTot),
        this.formatterService.transformNumber(impTot),
        this.formatterService.transformPercent(completionRate),
      ]);
    }
  }

  private getMmsDataByCreative(customAdId = ''): OutcomeReportCreative {
    const tvDayTotal = this.tracking?.tv_days_totals?.find((tvDay) => {
      return tvDay.video_code === customAdId;
    });
    return {
      custom_ad_id: tvDayTotal?.video_code,
      completed_views_by_mms: tvDayTotal?.mms_actuals_cv,
      gross_rating_views_by_mms: tvDayTotal.mms_actuals_gross_rating_views,
      impressions_by_mms: tvDayTotal?.mms_actuals_impressions,
      completion_rate_by_mms: tvDayTotal?.mms_actuals_completion_rate,
    };
  }

  private getFormattedSummaryRow(): string[] {
    const summary = this.tracking?.tv_days_totals?.find((tvDay) => {
      return tvDay.video_code === 'All videos';
    });
    if (summary) {
      return [
        'Summa',
        this.formatterService.transformNumber(
          this.isGrossRating ? summary.mms_actuals_gross_rating_views : summary.mms_actuals_cv,
        ),
        this.formatterService.transformNumber(summary.mms_actuals_impressions),
        this.formatterService.transformPercent(summary.mms_actuals_completion_rate),
      ];
    } else {
      return [
        'Summa',
        this.formatterService.transformNumber(
          this.isGrossRating
            ? this.tracking?.tv_days_totals[0]?.mms_actuals_gross_rating_views
            : this.tracking?.tv_days_totals[0]?.mms_actuals_cv,
        ),
        this.formatterService.transformNumber(this.tracking?.tv_days_totals[0]?.mms_actuals_impressions),
        this.formatterService.transformPercent(this.tracking?.tv_days_totals[0]?.mms_actuals_completion_rate),
      ];
    }
  }

  private setFormattedMmsData(): void {
    this.reportTableMmsData = this.formatReportData(this.mmsData);
    this.reportTableMmsData.push(this.getFormattedSummaryRow());
  }

  private formatReportData(outComeReportCreatives: OutcomeReportCreative[]): string[][] {
    return outComeReportCreatives.map((data) => [
      data.custom_ad_id,
      this.isGrossRating
        ? this.formatterService.transformNumber(data.gross_rating_views_by_mms)
        : this.formatterService.transformNumber(data.completed_views_by_mms),
      this.formatterService.transformNumber(data.impressions_by_mms),
      this.formatterService.transformPercent(data.completion_rate_by_mms),
    ]);
  }

  private getOutcomeReportParams(): OutcomeReport {
    if (this.mmsDataInvalidated) {
      const emptyMmsData: OutcomeReportCreative[] = this.mmsData.map((data) => {
        return {
          custom_ad_id: data.custom_ad_id,
          completed_views_by_mms: null,
          impressions_by_mms: null,
          gross_rating_views_by_mms: null,
          completion_rate_by_mms: null,
        };
      });
      return <OutcomeReport>{ ...this.outcomeForm.value, creatives: emptyMmsData };
    }
    return <OutcomeReport>{ ...this.outcomeForm.value, creatives: this.mmsData };
  }

  private reportIncludesMmsData(report: OutcomeReport): boolean {
    return report.creatives.every(
      (creative) => creative.completed_views_by_mms && creative.impressions_by_mms && creative.completion_rate_by_mms,
    );
  }
}
