import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { interval } from 'rxjs';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { AuthService } from '../../../woo_services.module/AuthService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { StatusMetric, StatusService } from '../../../woo_services.module/StatusService';

@Component({
  selector: 'woo-status',
  templateUrl: './woo-status.component.html',
})
export class WooStatus extends SubscriptionHandler implements OnInit, OnDestroy {
  private static readonly CLASSES = { red: 'danger', yellow: 'cta', green: 'success' };

  public metricsUpdated: string;
  public expanded: { [name: string]: boolean } = {};
  public metrics: Array<StatusMetric & { css: string }> = [];

  readonly isAdmin = this.authService.admin();

  constructor(
    private statusService: StatusService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.update();

    // Update every 10:th-minute.
    this.addSubscription(interval(1000 * 60 * 10).subscribe(this.update));
  }

  toggle(metric: StatusMetric): void {
    if (this.metricIsExpandable(metric)) {
      this.expanded[metric.name] = !this.expanded[metric.name];
    }
  }

  metricIsExpandable(metric: StatusMetric): boolean {
    return (
      this.showMetricDetails(metric) ||
      this.showCRMUpdateButton(metric) ||
      this.showPlannerAction(metric) ||
      this.showAdminAction(metric) ||
      this.showMetricTask(metric)
    );
  }

  showMetricDetails(metric: StatusMetric): boolean {
    return !!metric.details.length;
  }

  showCRMUpdateButton(metric: StatusMetric): boolean {
    return metric.id === 'crm_sync' && this.isAdmin;
  }

  showPlannerAction(metric: StatusMetric): boolean {
    return !!metric.planner_action?.length;
  }

  showAdminAction(metric: StatusMetric): boolean {
    return this.isAdmin && !!metric.admin_action?.length;
  }

  showMetricTask(metric: StatusMetric): boolean {
    return this.isAdmin && !!metric.task?.length;
  }

  async updateCRMStatus(): Promise<void> {
    this.dialogService.openBlocking('Upddaterar CRM Import Status');
    await this.statusService.updateCRMStatus();
    await this.update();
    this.dialogService.closeBlocking();
  }

  private update = async () => {
    const statusData = await this.statusService.getStatus();
    if (statusData && statusData.metrics) {
      this.metrics = statusData.metrics.map((metric) => ({
        ...metric,
        css: WooStatus.CLASSES[metric.status] || '',
      }));
      this.metricsUpdated = formatDistanceToNow(new Date(), { addSuffix: true });
    }
  };
}
