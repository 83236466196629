<voucher-select [targetingId]="targetingId"></voucher-select>

<div class="input__container">
  <label for="search-string-{{instanceId}}">Sök program</label>
  <input id="search-string-{{instanceId}}" type="text" [(ngModel)]="searchString" (ngModelChange)="filter()"
    e2e-id="search-string">
</div>

<div class="flex__container align-items--stretch">

  <div class="width--50 flex__container--vertical padding-right--1x">
    <div class="padding--1x background-color--info text--white rounded-top">Bokningsbara program</div>
    <div class="background-color--white program-format-item--container">
      <program-format-item
        class="display--block padding-left-right--1x padding-top-bottom--1x"
        *ngFor="let program of selectablePrograms"
        [program]="program"
        [targetingStart]="targetingStart"
        [targetingEnd]="targetingEnd"
        [mode]="ProgramFormatItemMode.Add"
        (onClick)="addProgram($event)"
      ></program-format-item>
      <h3 class="text--strong text--center" *ngIf="!targetingStart || !targetingEnd">
        Du måste välja period för att kunna lägga till program
      </h3>
    </div>
  </div>

  <div class="width--50 flex__container--vertical padding-left--1x">
    <div class="padding--1x background-color--info text--white rounded-top">Valda program</div>
    <div class="background-color--white program-format-item--container">
      <program-format-item
        class="display--block padding-left-right--1x padding-top-bottom--1x"
        *ngFor="let program of selectedPrograms"
        [program]="program"
        [targetingStart]="targetingStart"
        [targetingEnd]="targetingEnd"
        [mode]="ProgramFormatItemMode.Remove"
        (onClick)="removeProgram($event)"
      ></program-format-item>
    </div>
  </div>
</div>
