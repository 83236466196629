import { Component } from '@angular/core';
import { SubscriptionHandler } from '../../../advanced_campaign_booking.module/components/subscription-handler';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { DashboardModel, DashboardStore } from '../../../woo_services.module/DashboardStore';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { Campaign, DashboardTab } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'follow-up',
  templateUrl: './follow-up.component.html',
})
export class FollowUp extends SubscriptionHandler {
  readonly maySeeInventory = this.authService.hasAnyRole([
    UserRole.planner,
    UserRole.admin,
    UserRole.accounting,
    UserRole.externalPlanner,
  ]);
  readonly maySeeHistory = this.authService.hasAnyRole([
    UserRole.planner,
    UserRole.admin,
    UserRole.accounting,
    UserRole.externalPlanner,
  ]);
  readonly maySeeInvoices = this.authService.hasAnyRole([UserRole.planner, UserRole.admin, UserRole.accounting]);
  readonly maySeeGoals = this.authService.hasAnyRole([UserRole.planner, UserRole.admin]);
  readonly maySeeAdmin = this.authService.hasAnyRole([UserRole.planner, UserRole.admin]);
  readonly maySeeTtv = this.authService.hasAnyRole([UserRole.planner, UserRole.admin, UserRole.accounting]);

  activeTab: DashboardTab;
  campaign: Campaign;
  DashboardTab = DashboardTab;
  campaignHasGoals: boolean;
  isFollowup: boolean;

  constructor(
    private authService: AuthService,
    private dashboardStore: DashboardStore,
    private campaignService: CampaignService,
    private routingService: RoutingService,
  ) {
    super();
    this.addSubscription(this.dashboardStore.state$.subscribe(this.initFromStore));
  }

  initFromStore = (model: DashboardModel): void => {
    this.campaign = model.activeCampaign;
    this.activeTab = model.activeTab;
    if (this.campaign) {
      this.campaignHasGoals = this.campaign.targetings?.flatMap((t) => t.goals).length > 0;
      this.isFollowup = this.campaignService.isFollowup(this.campaign);
    }
  };

  setTab(tab: DashboardTab): void {
    this.routingService.goToDashboardTab(this.campaign.id, tab);
  }
}
