import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { TimePeriod, add, formatWooDate, isDateBefore } from '../utils/date';
import { EnvironmentService } from './EnvironmentService';
import { FrequencyLimit, wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class PriceListService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getPriceLists(): Promise<PriceList[]> {
    return lastValueFrom(this.http.get<PriceList[]>(`${this.env.apiUrl}/price_lists`));
  }

  getPriceList(id: wooId): Promise<PriceList> {
    return lastValueFrom(this.http.get<PriceList>(`${this.env.apiUrl}/price_lists/${id}`));
  }

  getCurrentPriceList(currentDate: Date, priceListType: PriceListType): Promise<PriceList> {
    return lastValueFrom(
      this.http.get<PriceList>(`${this.env.apiUrl}/price_lists`, {
        params: {
          valid_on: currentDate.toISOString(),
          price_list_type: priceListType,
        },
      }),
    );
  }

  updatePriceList(priceList: PriceList): Promise<PriceList> {
    return lastValueFrom(
      this.http.put<PriceList>(`${this.env.apiUrl}/price_lists/${priceList.id}`, { price_list: priceList }),
    );
  }

  updateOverwritenPrices(priceListId: wooId, overwrittenProductprices: Record<string, number>): Promise<PriceList> {
    return lastValueFrom(
      this.http.patch<PriceList>(`${this.env.apiUrl}/price_lists/${priceListId}/update_overwritten_prices`, {
        overwritten_product_prices: overwrittenProductprices,
      }),
    );
  }

  addPriceList(priceList: PriceList): Promise<PriceList> {
    return lastValueFrom(
      this.http.post<PriceList>(`${this.env.apiUrl}/price_lists/`, { price_list: priceList }),
    );
  }

  getExcelSheet(priceListIds: wooId[], publisherIds: wooId[]): Promise<HttpResponse<Blob>> {
    return lastValueFrom(
      this.http.post(
        `${this.env.apiUrl}/price_lists/excel`,
        { price_list_ids: priceListIds, publisher_ids: publisherIds },
        {
          observe: 'response',
          responseType: 'blob' as 'json',
        },
      ),
    ) as Promise<HttpResponse<Blob>>;
  }

  getLabel = (list: PriceList): string => {
    return this.getYear(list).toString();
  };

  /**
   * It assumes that valid_from and valid_to is approximately one year apart.
   *
   * @returns the year price list is valid for
   */
  getYear(list: PriceList): number {
    return add(new Date(list.valid_from), 6, TimePeriod.Month).getFullYear();
  }

  /**
   * @returns the number of weeks the provided price list is valid for
   */
  getValidWeekCount(list: Pick<PriceList, 'valid_from' | 'valid_to'>): number {
    let weeks = 0;
    for (
      let curr = new Date(list.valid_from);
      isDateBefore(curr, new Date(list.valid_to));
      curr = add(curr, 1, TimePeriod.Week)
    ) {
      weeks++;
    }
    return weeks;
  }

  getEmpty(): Omit<PriceList, 'id'> {
    const now = new Date();
    return {
      publisher_base_prices: {},
      publisher_targeting_prices: {},
      publisher_age_modifier_prices: {},
      publisher_locality_prices: {},
      publisher_addon_targeting_prices: {},
      creative_length_price_indexes: {},
      frequency_limit_prices: [],
      price_list_type: PriceListType.Standard,
      price_list_currency: PriceListCurrency.GrossRatingViews,
      pause_ad_week_price_indexes: [],
      overwritten_product_prices: {},
      week_price_indexes: [],
      created_at: now.toISOString(),
      updated_at: now.toISOString(),
      valid_from: formatWooDate(now),
      valid_to: formatWooDate(now),
    };
  }

  priceListSort = (firstPriceList: PriceList, secondPriceList: PriceList): number => {
    if (this.getYear(firstPriceList) < this.getYear(secondPriceList)) return 1;
    if (this.getYear(firstPriceList) > this.getYear(secondPriceList)) return -1;
    if (firstPriceList.price_list_type < secondPriceList.price_list_type) return 1;
    if (firstPriceList.price_list_type > secondPriceList.price_list_type) return -1;
    if (firstPriceList.price_list_currency < secondPriceList.price_list_currency) return 1;
    if (firstPriceList.price_list_currency > secondPriceList.price_list_currency) return -1;

    return 0;
  };

  translatePriceListType = (priceListType: string): string => {
    const priceListTypes = {
      [PriceListType.Standard]: 'Standard',
      [PriceListType.Cash]: 'Cash',
    };
    return !!priceListTypes[priceListType] ? priceListTypes[priceListType] : 'Ingen typ';
  };

  translatePriceListCurrency = (priceListCurrency: string): string => {
    const priceListCurrencies = {
      [PriceListCurrency.CompletedViews]: 'Completed Views',
      [PriceListCurrency.GrossRatingViews]: 'Bruttokontakter',
    };

    return !!priceListCurrencies[priceListCurrency] ? priceListCurrencies[priceListCurrency] : 'Ingen valuta';
  };

  translatePriceListCurrencyShort = (priceListCurrency: string): string => {
    const priceListCurrencies = {
      [PriceListCurrency.CompletedViews]: 'CPCV',
      [PriceListCurrency.GrossRatingViews]: 'CPT',
    };

    return !!priceListCurrencies[priceListCurrency] ? priceListCurrencies[priceListCurrency] : 'Ingen valuta';
  };
}

export interface PriceList {
  id: wooId;
  publisher_base_prices: Record<wooId, BasePrices>;
  publisher_targeting_prices: Record<wooId, TargetingPrices>;
  publisher_age_modifier_prices: Record<wooId, AgeModifierPrices>;
  publisher_locality_prices: Record<wooId, LocalityPrices>;
  publisher_addon_targeting_prices: Record<wooId, AddonTargetingPrices>;
  week_price_indexes: number[];
  pause_ad_week_price_indexes: number[];
  creative_length_price_indexes: Record<string, number>;
  overwritten_product_prices?: Record<string, number>;
  frequency_limit_prices: FrequencyLimitPrice[];
  price_list_type: PriceListType;
  price_list_currency: PriceListCurrency;
  created_at: string;
  updated_at: string;
  valid_from: string;
  valid_to: string;
  valid_prices?: ValidPriceListRow[];
}

export interface ValidPriceListRow {
  publisher_id: wooId;
  product_format: string;
  targeting_type: string;
  addon_targeting: string;
  locality_type: string;
  calculated_price: number;
  over_written_price: number | null;
  price_row_key: string;
}

export enum PriceListType {
  Standard = 'standard',
  Cash = 'cash',
}

export enum PriceListCurrency {
  CompletedViews = 'completed_views',
  GrossRatingViews = 'gross_rating_views',
}

export interface FrequencyLimitPrice {
  id: wooId;
  ratio: number;
  frequency_limit: FrequencyLimit;
}

export interface BasePrices {
  longform: number;
  shortform: number;
  combination: number;
  pause_longform: number;
  pause_shortform: number;
  pause_combination: number;
  includes_linear: number;
}

export interface TargetingPrices {
  rbs: number;
  category: number;
  program_format: number;
  daypart: number;
  device_group: number;
  advanced_target_group: number;
  gender: number;
  age: number;
  gender_and_age: number;
}
export interface AgeModifierPrices {
  age_1519: number;
  age_2024: number;
  age_2529: number;
  age_3034: number;
  age_3539: number;
  age_4044: number;
  age_4549: number;
  age_5054: number;
  age_5559: number;
  age_6064: number;
  age_6569: number;
  age_7074: number;
  age_7579: number;
}

export interface LocalityPrices {
  national: number;
  regional: number;
}

export interface AddonTargetingPrices {
  device_group: number;
}
