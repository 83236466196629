import { Component, Input } from '@angular/core';

const template = /* html */ `
  <table class="table table-woo table--hover table--layout--fixed width--100">
    <thead>
      <tr>
        <th *ngFor="let header of headers" class="text--thin text--small capitalize--first-letter">
          {{header}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <td *ngFor="let col of row" class="text--thin">
          {{col}}
        </td>
      </tr>
    </tbody>
  </table>
`;

export type TableRows = Array<number | string>[];
export type TableHeaders = string[];

@Component({
  selector: 'woo-table',
  template: template,
})
export class WooTable {
  @Input() headers: TableHeaders;
  @Input() rows: TableRows;
}
