import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UserRole } from '../utils/user-roles';
import { AuthService } from './AuthService';
import { EnvironmentService } from './EnvironmentService';
import { WooUploadService } from './WooUploadService';

export interface LegalDocument {
  id: string;
  name: string;
  author: {
    id: string;
    name: string;
  };
  createdAt: string;
  file_type: FileType;
}

export enum FileType {
  userTerms = 'user_terms',
  cashUserTerms = 'cash_user_terms',
  cookiePolicy = 'cookie_policy',
  dataPolicy = 'data_policy',
  integrityPolicy = 'integrity_policy',
  technicalSpecEn = 'technical_spec_en',
  technicalSpecSv = 'technical_spec_sv',
  productInformation = 'product_information',
  materialGuide = 'material_guide',
}

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(
    private authService: AuthService,
    private env: EnvironmentService,
    private wooUploadService: WooUploadService,
    private http: HttpClient,
  ) {}

  listFiles(): Promise<LegalDocument[]> {
    return lastValueFrom(this.http.get(`${this.env.apiUrl}/legal_documents/list/`)) as Promise<LegalDocument[]>;
  }

  listTypes(): Promise<FileType[]> {
    return lastValueFrom(this.http.get(`${this.env.apiUrl}/legal_documents/file_types`)) as Promise<FileType[]>;
  }

  downloadFile(fileType: FileType, id?: string): Promise<Blob> {
    let idAttribute = '';
    if (id) {
      idAttribute = '/' + id;
    }

    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/legal_documents/download/${fileType}${idAttribute}`, {
        responseType: 'blob',
      }),
    );
  }

  getUrlToFile = (fileType: FileType, id?: string): string => {
    let idAttribute = '';
    if (id) {
      idAttribute = '/' + id;
    }
    return `${this.env.apiUrl}/legal_documents/download/${fileType}${idAttribute}`;
  };

  downloadLatestFile = (fileType: FileType): Promise<Blob> => {
    return this.downloadFile(fileType);
  };

  getTermsFileTypeForUser = (): FileType => {
    return this.authService.hasRole(UserRole.cashUser) ? FileType.cashUserTerms : FileType.userTerms;
  };

  uploadFile(file: File, fileType: FileType): Promise<LegalDocument> {
    return this.wooUploadService.upload(`${this.env.apiUrl}/legal_documents/upload`, file, { file_type: fileType });
  }
}
