<div class="fixed-top section">
  <div class="advanced-booking-wrapper">
    <div #scrollContainer class="advanced-booking-grid--width advanced-booking-grid--height padding-right--4x" id="main-scroll-container">
      <div class="padding-top-bottom--2x">
        <snake-nav></snake-nav>
      </div>
      <div class="no-scroll--x" [@horizontalSlide]="activeBookingStepIndex">
        <campaign-details class="display--block" *ngIf="activeBookingStep === BookingStep.details"></campaign-details>
        <campaign-targetings class="display--block" *ngIf="activeBookingStep === BookingStep.targeting">
        </campaign-targetings>
        <campaign-distribution class="display--block" *ngIf="activeBookingStep === BookingStep.distribution">
        </campaign-distribution>
        <advanced-booking-campaign-summary class="display--block" *ngIf="activeBookingStep === BookingStep.summary"
         >
        </advanced-booking-campaign-summary>

      </div>
    </div>
    <div class="sidebar-wrapper advanced-booking-grid--height padding--2x">
      <div class="messages-container">
        <advanced-booking-messages></advanced-booking-messages>
      </div>
      <advanced-booking-sidebar></advanced-booking-sidebar>
    </div>
  </div>
  <div class="footer" id="footer" #footer>
    <footer></footer>
  </div>
</div>
