import { Component, Input } from '@angular/core';

@Component({
  selector: 'notification-badge',
  template: '{{count}}',
  styleUrls: ['./notification-badge.component.scss'],
})
export class NotificationBadge {
  @Input() count: number;
}
