import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { setFormDirty } from '../../../../utils/form';
import { Behavior } from '../../../../woo_services.module/BehaviorService';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { ProductFormat, Publisher, SlotType, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'behavior-form',
  templateUrl: './behavior-form.component.html',
})
export class BehaviorForm implements OnInit {
  readonly setFormDirty = setFormDirty;
  @Output() onSubmit = new EventEmitter<BehaviorParams>();
  @Output() onAbort = new EventEmitter<void>();

  readonly tableHeaders = [
    'Placering',
    'Publicist',
    'Preroll',
    'Midroll',
    'Postroll',
    'Preroll',
    'Midroll',
    'Postroll',
  ];

  publishers: Publisher[] = [];
  productFormats: ProductFormat[];
  inputRows: InputRow[] = [];
  behavior: BehaviorParams = {} as BehaviorParams;
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  loading = true;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private dialogService: DialogService,
    private utilityService: ProductsPageUtilityService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled)
      .then((publishers) => {
        this.publishers = publishers;
      })
      .then(() => {
        this.productFormatService
          .getProductFormats(ExtraProductFormatField.enabled, ExtraProductFormatField.slotTypes)
          .then((productFormats) => {
            this.productFormats = productFormats;
            this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
              this.productFormats,
            );
            this.behavior = this.getEmptyBehavior();
            this.buildInputRows();
          });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  submit(): void {
    const textBlocks = ['Är du säker på att du vill lägga till beteende segment?'];
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.onSubmit.emit(this.behavior);
      })
      .finally(() => {
        this.dialogService.closeBlocking();
      });
  }

  translateProductFormat(product: string): string {
    return this.translationService.convertProductFormat(product);
  }

  rowVisible(obj: InputRow): boolean {
    return obj.publisherEnabled && obj.ProductFormatEnabled;
  }

  private getEmptyBehavior() {
    return {
      name: null,
      active: true,
      tag: null,
      shares: this.utilityService.buildEmptyProductFormatEstimates(this.publishers, this.productFormats),
      withdrawal_limits: this.utilityService.buildEmptyProductFormatEstimates(this.publishers, this.productFormats),
    };
  }

  private buildInputRows(): void {
    let inputRow: InputRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        inputRow = [
          ...inputRow,
          {
            publisher: publisher.name,
            publisherId: publisher.id,
            publisherEnabled: publisher.enabled,
            productFormat: productFormat.name,
            productFormatId: productFormat.id,
            ProductFormatEnabled: productFormat.enabled,
            sharesPreroll: 0,
            sharesMidroll: 0,
            sharesPostroll: 0,
            withdrawalLimitsPreroll: 0,
            withdrawalLimitsMidroll: 0,
            withdrawalLimitsPostroll: 0,
          },
        ];
      });
    });
    inputRow.sort((a, b) => (a.publisher > b.publisher ? 0 : 1));
    this.inputRows = inputRow;
  }
}

interface InputRow {
  publisher: string;
  publisherId?: wooId;
  publisherEnabled: boolean;
  productFormat: string;
  productFormatId: wooId;
  ProductFormatEnabled: boolean;
  sharesPreroll?: number;
  sharesMidroll?: number;
  sharesPostroll?: number;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}

export type BehaviorParams = Omit<Behavior, 'id'>;
