import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService } from './JobService';
import { wooId } from './shared-types';

@Injectable({ providedIn: 'root' })
export class PlacementService {
  constructor(private http: HttpClient, private env: EnvironmentService, private jobService: JobService) {}

  async getCampaignPlacements(id: wooId): Promise<FetchPlacementsJobResult> {
    return this.jobService.waitForJobCompletion(
      (await lastValueFrom(this.http.get<Job>(`${this.env.apiUrl}/campaigns/${id}/placements`))).id,
    );
  }
}

export interface FetchPlacementsJobResult {
  test: string;
  status: string;
  placements: Placement[];
  error: string;
}

export interface Placement {
  name: string;
  external_id: string;
  start_date: Date | string;
  end_date: Date | string;
  ad_unit_nodes: MrmAdUnitNode[];
}

export interface MrmAdUnitNode {
  ad_unit_node_id: string;
  slot_type: string;
  creative_instances: MrmCreativeInstance[];
}

export interface MrmCreativeInstance {
  custom_name: string;
  video_code: string;
  length: number;
  creative_external_id: string;
  start: Date | string;
  end: Date | string;
  added: boolean;
}
