<h3 class="margin-top--2x margin-bottom--none">Visas för</h3>
<div class="divider margin-bottom--1x"></div>

<div *ngIf="hasNoBookingFormat">
  <p class="text--strong margin--none">Ingen styrning</p>
  <p>Reklamfilmen visas där det finns plats på TV4's digitala plattformar utan styrning på geografiskt område eller målgrupp.</p>
</div>

<div *ngIf="bookingFormat.region">
  <p>Reklamfilmen visas på TV4's digitala plattformar i det geografiska område du valt.</p>
  <span *ngFor="let region of targeting.regions" class="targeting-box">{{region.name}}</span>
</div>
<div *ngIf="bookingFormat.publisher_group">
  <p>Reklamfilmen visas på {{selectedPublisherGroup}} digitala plattformar.</p>
  <div *ngIf="selectedPublisherGroup === BN_NAME" class="flex__container margin-top--2x">
    <img class="width--33" src="../../../images/logos/expressen.svg" alt="expressens logga">
    <img class="width--33" src="../../../images/logos/dn.svg" alt="dns logga">
    <img class="width--33" src="../../../images/logos/di.svg" alt="dis logga">
  </div>
  <div *ngIf="selectedPublisherGroup === TV4_NAME" class="flex__container margin-top-bottom--2x">
    <img class="width--25" src="../../../images/logos/tv4.svg" alt="tv4s logga">
  </div>
</div>

<div *ngIf="bookingFormat.target_group">
  <p>{{targetingGroupTargetText}}</p>
  <span *ngFor="let name of targetGroupNames" class="targeting-box">{{name}}</span>
</div>

<div class="margin-top--1x">
  <img class="default-logo" src="../../../images/logos/tv4.svg" alt="tv4 logga">
</div>
