import { Component } from '@angular/core';
import { WooDialogHelper } from '../../../woo_components.module/dialogs/woo-dialog-helper';
import { CampaignStatus, Targeting } from '../../../woo_services.module/shared-types';
import { CashCampaign } from '../../services/CashDashboardService';

@Component({
  selector: 'cash-campaign-summary-dialog',
  templateUrl: './cash-campaign-summary-dialog.component.html',
  styleUrls: ['./cash-campaign-summary-dialog.component.scss'],
})
export class CashCampaignSummaryDialog extends WooDialogHelper {
  public campaign: CashCampaign = {} as CashCampaign;
  public CampaignStatus = CampaignStatus;
  targeting: Targeting = {} as Targeting;

  show(campaign: CashCampaign): void {
    this.campaign = campaign;
    this.targeting = this.campaign.targetings[0];
    this.open();
  }
}
