import { FormGroup } from '@angular/forms';

export function setFormDirty(form: FormGroup): void {
  Object.values(form.controls).forEach((control) => {
    if (!control.valid) {
      control.markAsDirty();
    }
  });
}

export function setFormTouched(form: FormGroup): void {
  Object.values(form.controls).forEach((control) => {
    if (!control.valid) {
      control.markAllAsTouched();
    }
  });
}
