<ul>
  <li *ngFor="let row of items">
    <span class="strong">{{row.updated_at | date:'yyyy-MM-dd HH:mm:ss'}}</span>
    <a
      class="pointer"
      *ngIf="row.updater_name"
      [routerLink]="['/user', row.updater_id]"
      class="text-info"
    > {{row.updater_name}}</a>
    {{convert(row.action)}} {{convert(row.model)}}
    <ul *ngIf="showDetailsFor.includes(row.action)">
      <li *ngFor="let pair of row.modified | keyvalue">
        <span *ngIf="row.original[pair.key] !== undefined">
          <span>{{convert(pair.key)}}</span> ändrades: {{formatHistoryChange(row.original[pair.key])}} => <span class="text-success">{{formatHistoryChange(pair.value)}}</span>
        </span>
        <span *ngIf="row.original[pair.key] === undefined">
          <span>{{convert(pair.key)}}</span> sattes till: <span class="text-success">{{formatHistoryChange(pair.value)}}</span>
        </span>
      </li>
    </ul>
    </li>
  </ul>
