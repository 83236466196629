<div>
  <div class="tabbable__panel rounded-top-right">
    <h3 class="margin-left--3x">Här kan du administrera maxuttag och lager för spelbolag</h3>
  </div>

  <div *ngIf="show.estimatesHaveChanged" class="tabbable__body">
    <import-warning></import-warning>
    <dot-loader [visibility]="show.updatingEstimates"></dot-loader>
  </div>
</div>

<div>
  <div #stickyHeader>
    <div class="tabbable__body shadow-z-1">
      <div class="row">
        <div class="col-xs-12">
          <div class="button-filter-container">
            <div>
              <button *ngIf="show.isEditing" class="button primary" (click)="save()" [disabled]="gamblingEstimateShares.invalid">Spara</button>
              <button *ngIf="!show.isEditing" class="button primary" (click)="setEditing(true)">Redigera spelbolag</button>
              <button *ngIf="show.isEditing" class="link" (click)="cancelEditing()">Avbryt redigering</button>
            </div>
            <div class="display--flex">
              <div *ngIf="productFormats && productFormats.length > 1" class="filter-dropdown margin-left--2x">
                <woo-dropdown
                [items]="productFormats"
                [(ngModel)]="selectedProductFormat"
                name="selectedProductFormat"
                inputId="select-product-format"
                labelText="Filtrera på Placering"
                [labelTransform]="translateProductFormat"
                notFoundText="Inga Placeringar hittades."
                [clearable]="true"></woo-dropdown>
              </div>
              <div *ngIf="publishers.length > 1" class="filter-dropdown margin-left--2x">
                <woo-dropdown
                [items]="publishers"
                [(ngModel)]="selectedPublisher"
                name="selectedPublisher"
                inputId="select-publisher"
                labelText="Filtrera på Publicist"
                notFoundText="Inga publicister hittades."
                [clearable]="true"></woo-dropdown>
              </div>
            </div>
          </div>
          <div class="tabbable__panel rounded-top-right">
            <div class="col-xs-12">
              <h3>Maxuttag och lager för spelbolag</h3>
              <p><span class="text--info icon-warning"></span> OBS: Estimat anges i andel av totallagret som ska vara tillgängligt för spelbolagsbokningar.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabbable__panel shadow-z-1"></div>
  </div>

  <div class="tabbable__body shadow-z-1">
    <table class="table table-woo table--info table--hover width--100" ngForm #gamblingEstimateShares="ngForm">
      <thead  #stickyTableHeader>
        <tr>
          <th [colSpan]="staticFields"></th>
          <th class="text-center" colspan="4">
            Estimatfördelning
          </th>
          <th class="text-center" colspan="4">
            Maxuttag
          </th>
        </tr>
        <tr>
          <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
            {{header}}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of gamblingRows; let index = index;">
        <tr [hidden]="!rowVisible(row)">
          <td class="text--thin vertical-align--middle">
            <span>{{row.publisher}}</span>
          </td>
          <td class="text--thin vertical-align--middle">
            <span>{{translateProductFormat(row.productFormat)}}</span>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.pauseAdShare | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPauseAdShare"
                [(ngModel)]="getGamblingProductFormat(row.productFormatId).gambling_pause_ad_share"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].pause_ad"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].pause_ad">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesPreroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPreroll"
                [(ngModel)]="getGamblingProductFormat(row.productFormatId).gambling_withdrawal_limit_shares.preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesMidroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesMidroll"
                [(ngModel)]="getGamblingProductFormat(row.productFormatId).gambling_withdrawal_limit_shares.midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.sharesPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-sharesPostroll"
                [(ngModel)]="getGamblingProductFormat(row.productFormatId).gambling_withdrawal_limit_shares.postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.pausAdWithdrawalLimit | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-limitsPauseAdShare"
                [(ngModel)]="getGamblingProductFormat(row.productFormatId).gambling_pause_ad_withdrawal_limit"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].pause_ad"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].pause_ad">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPreroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-limitsPreroll"
                [(ngModel)]="getGamblingProductFormat(row.productFormatId).gambling_withdrawal_limits.preroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsMidroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-limitsMidroll"
                [(ngModel)]="getGamblingProductFormat(row.productFormatId).gambling_withdrawal_limits.midroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
            </div>
          </td>

          <td class="vertical-align--middle">
            <span *ngIf="!show.isEditing">{{row.withdrawalLimitsPostroll | percent: "1.0-3"}}</span>
            <div
              *ngIf="show.isEditing"
              class="input__container">
              <input
                name="row-{{index}}-limitsPostroll"
                [(ngModel)]="getGamblingProductFormat(row.productFormatId).gambling_withdrawal_limits.postroll"
                wooPercentFormatter
                [wooMinValidator]="0"
                [wooMaxValidator]="1"
                [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
            </div>
          </td>
        </tr>
        </ng-container>
      </tbody>
    </table>
    <dot-loader [visibility]="show.loading" [large]="true"></dot-loader>
    <div *ngIf="show.isEditing" class="margin-top--2x">
    <button class="button primary" (click)="save()" [disabled]="gamblingEstimateShares.invalid">Spara</button>
    <button class="link" (click)="cancelEditing()">Avbryt redigering</button>
    </div>
  </div>
</div>
