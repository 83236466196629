import { Pipe, PipeTransform } from '@angular/core';
import { formatWooDate } from '../../utils/date';
import formatDistance from '../../utils/formatDistance';

@Pipe({
  name: 'wooDate',
})
export class WooDatePipe implements PipeTransform {
  transform(value: any, format = WooDateFormat.WooDate): any {
    const dateValue = new Date(value);
    const isValidDate = !isNaN(dateValue.valueOf());
    if (!isValidDate) {
      return value;
    }
    if (format === WooDateFormat.Distance) {
      return formatDistance(dateValue);
    } else {
      return formatWooDate(dateValue);
    }
  }
}

export enum WooDateFormat {
  Distance = 'distance',
  WooDate = 'wooDate',
}
