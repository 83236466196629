import { Injectable } from '@angular/core';
@Injectable()
export class EstimateValidationErrorService {
  extractingAllErrorMessages(errorInformation: any[]): string[] {
    const tempValidationErrorMessages: string[] = [];
    const mostErrors = [
      'invalid_column',
      'invalid_category_column',
      'illegal_target_group_column',
      'invalid_program_format_column',
      'invalid_slot_type_column',
      'duplicate_column_name',
      'invalid_sheet_name',
      'invalid_cell_value',
      'invalid_week_number',
      'duplicate_week_number',
    ];
    const remainingWeekNumberOutOfRangeError = ['week_number_out_of_range'];

    errorInformation.forEach((currError) => {
      const errorInformationObject = {
        errorType: currError.error_type,
        columnTitle: currError.column_title,
        columnIndex: currError.column_index,
        sheetName: currError.sheet_name,
        categories: currError.categories,
        slotTypes: currError.slot_types,
        publishers: currError.publishers,
        value: currError.value,
        row: currError.row,
        column: currError.column,
        year: currError.year,
        validWeeks: currError.valid_weeks,
      };

      if (mostErrors.includes(currError.error_type)) {
        const currTestError = this.convertEstimateValidationErrors(errorInformationObject);
        tempValidationErrorMessages.push(currTestError);
      } else if (remainingWeekNumberOutOfRangeError.includes(currError.error_type)) {
        const currWeekNumberOutOfRangeError = this.convertEstimateWeekNumberOutOfRangeError(errorInformationObject);
        tempValidationErrorMessages.push(currWeekNumberOutOfRangeError);
      }
    });
    return tempValidationErrorMessages;
  }

  private convertEstimateValidationErrors = function (errorInformationObject) {
    const list = {
      invalid_column: `Ogiltig kolumn ${errorInformationObject.columnTitle} (index  ${errorInformationObject.columnIndex}) på blad  ${errorInformationObject.sheetName}.`,
      invalid_category_column: `Ogiltig kategori i kolumn ${errorInformationObject.columnTitle} (index ${errorInformationObject.columnIndex}) på blad  ${errorInformationObject.sheetName}, måste vara någon av följande kategorier: ${errorInformationObject.categories}.`,
      illegal_target_group_column: `Målgrupp ej tillåtet i estimatfil (index ${errorInformationObject.columnIndex}) på blad ${errorInformationObject.sheetName}.`,
      invalid_program_format_column: `Ogiltigt programformat i kolumn ${errorInformationObject.columnTitle} (index ${errorInformationObject.columnIndex}) på blad ${errorInformationObject.sheetName}.`,
      invalid_slot_type_column: `Ogiltig slot type i kolumn ${errorInformationObject.columnTitle} (index ${errorInformationObject.columnIndex}) på blad ${errorInformationObject.sheetName}, måste vara någon av följande slot types: ${errorInformationObject.slotTypes}.`,
      duplicate_column_name: `Duplicerad kolumn ${errorInformationObject.columnTitle} på blad ${errorInformationObject.sheetName}.`,
      invalid_sheet_name: `Ogiltigt namn på blad ${errorInformationObject.sheetName}, måste vara något av följande sajter: ${errorInformationObject.publishers}.`,
      invalid_cell_value: `Ogiltigt värde ${errorInformationObject.value} på rad ${errorInformationObject.row}, kolumn ${errorInformationObject.column} på blad ${errorInformationObject.sheetName}, måste vara ett heltal.`,
      invalid_week_number: `Ogiltig vecka ${errorInformationObject.value} på rad ${errorInformationObject.row} på blad ${errorInformationObject.sheetName}, veckan måste vara ett heltal.`,
      duplicate_week_number: `Duplicerad vecka ${errorInformationObject.value} på rad ${errorInformationObject.row}, har förekommit tidigare på blad ${errorInformationObject.sheetName}.`,
    };
    return list[errorInformationObject.errorType] !== undefined
      ? list[errorInformationObject.errorType]
      : errorInformationObject.errorType;
  };
  private convertEstimateWeekNumberOutOfRangeError = function (errorInformationObject) {
    let weekNumberOutOfRange;
    if (errorInformationObject.validWeeks !== undefined) {
      weekNumberOutOfRange = `Ogiltigt veckonummer ${errorInformationObject.value} på rad ${errorInformationObject.row} på blad ${errorInformationObject.sheetName}, måste vara inom [${errorInformationObject.validWeeks.min}, ${errorInformationObject.validWeeks.max}] för år ${errorInformationObject.year}.`;
    }
    return weekNumberOutOfRange !== undefined ? weekNumberOutOfRange : errorInformationObject.errorType;
  };
}
