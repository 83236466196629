<div class="padding--4x shadow-z-1 background-color--white margin-top--3x">
  <h3 class="margin-top--none">Utöka kampanjbudget</h3>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <two-col-row *ngIf="campaignExtraBudget" left="Kampanjbudget (netto): " [right]="campaignTotalNetBudget | currency: 'SEK'" [bothBold]="true"></two-col-row>
      <two-col-row *ngIf="campaignExtraBudget" left="Extra/Komp Kampanjbudget: " [right]="campaignExtraBudget | currency: 'SEK'" [bothBold]="true"></two-col-row>
      <two-col-row left="Kampanjbudget: " [right]="campaignTotalBudget | currency: 'SEK'" [bothBold]="true"></two-col-row>
    </div>
  </div>
  <div class="padding-top--2x">
    <button class="button" (click)="extendCampaignBudget()" [disabled]="budgetForm.form.pristine || formBudgetAndMessageInvalid() || !formBudgetsValid()">Spara</button>
    <button class="button secondary" (click)="abort()">Avbryt</button>
  </div>
</div>
<form #budgetForm="ngForm" >
  <div *ngFor="let data of targetingData" class="padding--3x shadow-z-1 background-color--white margin-top--3x">
    <expandable-targeting-header [targeting]="data.targeting">
      <div class="row">
        <div class="col-xs-12 col-md-6 padding-bottom--2x">
          <two-col-row left="Budget:" [right]="data.netBudget | currency: 'SEK'" [bothBold]="true"></two-col-row>
          <two-col-row left="Extra budget:" [right]="data.extraBudget | currency: 'SEK'" [bothBold]="true"></two-col-row>
        </div>
      </div>
      <div class="row" *ngIf="!data.endedTargeting">
        <div class="input__container col-md-3">
          <label for="extended-targeting-budget">Utöka budget</label>
          <label for="extended-targeting-budget">(faktureras kr)</label>
          <input
            id="extended-targeting-budget-{{data.targeting.id}}"
            name="extended-targeting-budget-{{data.targeting.id}}"
            [(ngModel)]="data.extendBudget"
            [wooMinValidator]="0"
            wooNumberFormatter
            #extendBudgetControl="ngModel"
            />
        </div>
        <div class="input__container col-md-6 label-offset">
          <label for="message">
            Meddelande
          </label>
          <input
            type="text"
            id="message-{{data.targeting.id}}"
            name="message-{{data.targeting.id}}"
            [(ngModel)]="data.extendBudgetMessage"
            required
            #extendMessageControl="ngModel"
            />
            <span *ngIf="extendBudgetControl.dirty && extendMessageControl.invalid" class="input__error__text">Du behöver ha ett meddelande</span>
        </div>
      </div>
      <div class="row" *ngIf="!data.endedTargeting">
        <div class="input__container col-md-3">
          <label for="extended-additional-targeting-budget">Extra/Komp budget</label>
          <label for="extended-additional-targeting-budget">(faktureras ej kr)</label>
          <input
            id="extended-targeting-additional-budget-{{data.targeting.id}}"
            name="extended-targeting-additional-budget-{{data.targeting.id}}"
            [(ngModel)]="data.extendAdditionalBudget"
            [wooMinValidator]="0"
            wooNumberFormatter
            #additionalBudgetControl="ngModel"
          />
        </div>
        <div class="input__container col-md-6 label-offset">
          <label for="additional-message">
            Extra/Komp budget meddelande
          </label>
          <input
            type="text"
            id="additional-message-{{data.targeting.id}}"
            name="additional-message-{{data.targeting.id}}"
            [(ngModel)]="data.extendAdditionalBudgetMessage"
            #additionalMessageControl
            />
            <span *ngIf="additionalBudgetControl.dirty && additionalMessageControl.invalid" class="input__error__text">Du behöver ha ett meddelande</span>
        </div>
      </div>
      <extended-targeting-budget-view
        [targeting]="data.targeting"
      ></extended-targeting-budget-view>
    </expandable-targeting-header>
  </div>
</form>

<div class="padding--2x">
  <button class="button" (click)="extendCampaignBudget()" [disabled]="budgetForm.form.pristine || formBudgetAndMessageInvalid() || !formBudgetsValid()">Spara</button>
  <button class="button secondary" (click)="abort()">Avbryt</button>
</div>
