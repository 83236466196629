import { Component, OnInit } from '@angular/core';
import { SystemMessage, SystemMessageService } from '../../woo_services.module/SystemMessageService';

@Component({
  selector: 'system-messages-overview',
  templateUrl: './system-messages-overview.component.html',
})
export class SystemMessagesOverview implements OnInit {
  systemMessages: SystemMessage[] = [];

  constructor(private systemMessageService: SystemMessageService) {}

  ngOnInit(): void {
    this.systemMessageService.getAll().then((systemMessages) => {
      this.systemMessages = systemMessages;
    });
  }
}
