import { Component, Input } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { sum } from '../../../utils/array';
import { STANDARD_FORMATS } from '../../../utils/format-constants';
import { SimpleChanges } from '../../../utils/types';
import { FormatterService } from '../../../woo_services.module/FormatterService';

export interface TrackingDistributionData {
  label: string;
  views: number;
}

@Component({
  selector: 'tracking-distribution',
  templateUrl: './tracking-distribution.component.html',
  styles: ['.color-box { width: 12px; height: 12px; display: inline-block}'],
})
export class TrackingDistribution {
  readonly PERCENT_FORMAT = STANDARD_FORMATS.percent;
  readonly colors = ['#710923', '#da2c2d', '#ff3334', '#616161', '#808080', '#a3a3a3'];
  readonly chartColors = [{ backgroundColor: this.colors }];
  readonly chartType: ChartType = 'doughnut';
  readonly chartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const index = tooltipItem.dataIndex;
            const dataset = tooltipItem.dataset.data;
            const views = dataset[index] as number;
            const viewShare = this.formatterService.transformPercent(views / sum(dataset));
            const values = `${this.formatterService.transformNumber(views)} (${viewShare})`;
            return [`${tooltipItem.label}, ${values}`];
          },
          afterLabel: (tooltipItem) => {
            if (tooltipItem.dataIndex === this.MAX_CHART_VALUES - 1) {
              return this.restLabels.map(
                (restLabel, restIndex) =>
                  `${restLabel}, ${this.formatterService.transformNumber(
                    this.restData[restIndex],
                  )} (${this.formatterService.transformPercent(this.restData[restIndex] / this.totalViews)})`,
              );
            }
            return null;
          },
        },
      },
    },
  };

  chartData = [
    {
      data: [],
      backgroundColor: this.colors,
      hoverBackgroundColor: this.colors,
      hoverBorderColor: '#fff',
    },
  ];

  @Input() distributions: TrackingDistributionData[];
  @Input() heading: string;
  @Input() viewCurrency: string;

  chartLabels: string[] = [];
  distributionData: number[] = [];
  totalViews: number;

  MAX_CHART_VALUES = 6;

  restLabels: string[] = [];
  restData: number[] = [];

  constructor(private formatterService: FormatterService) {}

  ngOnChanges(changes: SimpleChanges<TrackingDistribution>): void {
    if (changes.distributions && this.distributions) {
      this.chartLabels = this.distributions.map((data) => data.label);
      this.distributionData = this.distributions.map((data) => data.views);
      this.chartData[0].data = this.distributions.map((data) => data.views);

      if (this.distributions.length > this.MAX_CHART_VALUES) {
        this.restLabels = this.chartLabels.splice(this.MAX_CHART_VALUES - 1);
        this.restData = this.distributionData.splice(this.MAX_CHART_VALUES - 1);
        this.totalViews = sum(this.distributions.map((device) => device.views));

        const rest = this.distributions.slice(this.MAX_CHART_VALUES - 1);
        this.chartLabels.push('Övriga');
        this.distributionData.push(sum(rest.map((device) => device.views)));
      }
    }
  }

  totalDataShare(share: number): number {
    return share / sum(this.distributionData);
  }
}
