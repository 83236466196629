<form (ngSubmit)="login()" role="form" id="login-form">
  <div class="input__container">
    <label for="login-email">E-mail</label>
    <input type="email" name="email" id="login-email"
      [(ngModel)]="credentials.email"
      (ngModelChange)="loginFormChanged()"
      [attr.tabindex]="menuOpened ? '30' : '-1'"
      [wooFocus]="menuOpened"
      required>
    </div>
  <div class="input__container">
    <label for="login-password">Lösenord</label>
    <input type="password" name="password" id="login-password"
      [(ngModel)]="credentials.password"
      (ngModelChange)="loginFormChanged()"
      required [attr.tabindex]="menuOpened ? '40' : '-1'">
    <ul class="ul--woo">
      <li>
        <a class="link primary-2" id="forgotten-password"
          [routerLink]="['/forgot-password']"
          [attr.tabindex]="menuOpened ? '60' : '-1'">
          Glömt lösenord
        </a>
      </li>
      <li>
        <a class="link primary-2" (click)="feedback()"
          [attr.tabindex]="menuOpened ? '70' : '-1'">Kontakta support</a>
      </li>
    </ul>
  </div>
  <button id="send-login" type="submit" [disabled]="!isLoginFormValid"
    class="button primary-1 margin-top--1x padding-left-right--4x "
    [attr.tabindex]="menuOpened ? '50' : '-1'" wooLoad>
    Logga in
  </button>
</form>
