import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Daypart } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DaypartService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getDayparts(): Promise<Daypart[]> {
    return lastValueFrom(this.http.get<Daypart[]>(`${this.env.apiUrl}/dayparts`));
  }
}
