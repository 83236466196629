import { Component, EventEmitter, Input, Output } from '@angular/core';
import { verticalExpansion } from '../../utils/animations';
@Component({
  selector: 'expandable-header',
  template: `
    <div
      [ngClass]="{
        'background-color--white': backgroundColor === 'white',
        'background-color--light-red': backgroundColor === 'light-red',
        'padding--4x': padding
      }"
    >
      <div
        (click)="toggleExpanded()"
        class="display--flex justify-content--space-between toggle-container"
        [ngClass]="{ 'padding--none': heading === 'h3' }"
      >
        <h4 *ngIf="heading === 'h4'" class="display--inline-block margin--none text--large ">
          {{ text }}
        </h4>
        <h3 *ngIf="heading === 'h3'" class="display--inline-block margin--none">
          {{ text }}
        </h3>

        <button class="toggle-button">
          <span class="chevron large" [ngClass]="{ bottom: expanded, 'text--black': chevronColor === 'black' }"> </span>
        </button>
      </div>
      <div class="margin-top--2x" *ngIf="expanded" [@verticalExpansion]>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./expandable-header.component.scss'],
  animations: [verticalExpansion(500)],
})
export class ExpandableHeader {
  @Input() text: string;
  @Input() expanded = false;
  @Input() chevronColor: 'black' | 'blue' = 'blue';
  @Input() backgroundColor: 'white' | 'light-red' = 'light-red';
  @Input() heading: 'h3' | 'h4' = 'h4';
  @Input() padding = false;
  @Output() expandedChange = new EventEmitter<boolean>();

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }
}
