import { Injectable } from '@angular/core';
import { AgeTargetGroup, GenderTargetGroup } from '../../woo_services.module/shared-types';
import { TargetGroupService } from '../../woo_services.module/TargetGroupService';

@Injectable()
export class CachedTargetGroupService implements Pick<TargetGroupService, 'getGendersAndAges'> {
  private listCache: [AgeTargetGroup[], GenderTargetGroup[]];

  constructor(private targetGroupService: TargetGroupService) {}

  async getGendersAndAges(): Promise<[AgeTargetGroup[], GenderTargetGroup[]]> {
    if (!this.listCache) {
      this.listCache = await this.targetGroupService.getGendersAndAges();
    }
    return this.listCache;
  }
}
