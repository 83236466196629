import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trackById } from '../../utils/object';
import { ExternalCampaign } from '../services/ExternalCampaignService';

@Component({
  selector: 'active-list',
  templateUrl: './active-list.component.html',
})
export class ActiveListComponent {
  @Input() loading = true;
  @Input() campaigns: ExternalCampaign[] = [];
  @Output() onToggleReduceImpressions = new EventEmitter<ExternalCampaign>();
  @Output() onOpenDetails = new EventEmitter<ExternalCampaign>();

  readonly trackById = trackById;

  toggleReduceImpressions(campaign: ExternalCampaign): void {
    this.onToggleReduceImpressions.emit(campaign);
  }

  openDetails(campaign: ExternalCampaign): void {
    this.onOpenDetails.emit(campaign);
  }
}
