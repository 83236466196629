import { Component, Input } from '@angular/core';
import {
  AdvancedTargetGroup,
  AdvancedTargetGroupCategory,
} from '../../../../woo_services.module/AdvancedTargetGroupService';
import { TargetingAdvancedTargetGroup, wooId } from '../../../../woo_services.module/shared-types';
import { CachedAdvancedTargetGroupService } from '../../../services/CachedAdvancedTargetGroupService';
import { BookingModel, BookingStore } from '../../../stores/BookingStore';
import { SubscriptionHandler } from '../../subscription-handler';

@Component({
  selector: 'advanced-target-group-targeting',
  templateUrl: './advanced-target-group-targeting.component.html',
})
export class AdvancedTargetGroupTargeting extends SubscriptionHandler {
  @Input() targetingId: wooId;
  private selectedTargetGroupIds: wooId[] = [];

  advancedTargetGroups: AdvancedTargetGroup[];
  advancedTargetGroupsByCategory: AdvancedTargetGroupsData[] = [];

  constructor(
    private cachedAdvancedTargetGroupService: CachedAdvancedTargetGroupService,
    private bookingStore: BookingStore,
  ) {
    super();
    this.setAdvancedTargetGroupData();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  private initFromStore = (model: BookingModel) => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.selectedTargetGroupIds = targeting.advanced_target_groups.map((atg) => atg.advanced_target_group_id);
    }
  };

  private setAdvancedTargetGroupData(): void {
    Promise.all([
      this.cachedAdvancedTargetGroupService.getActiveTargetGroups(),
      this.cachedAdvancedTargetGroupService.getTargetGroupCategories(),
    ]).then(([advancedTargetGroups, categories]) => {
      this.advancedTargetGroups = advancedTargetGroups;
      this.advancedTargetGroupsByCategory = categories
        .map((c) => {
          const targetGroupsForCategory = advancedTargetGroups.filter((atg) => atg.category_id === c.id);
          return { ...c, advancedTargetGroups: targetGroupsForCategory };
        })
        .filter((row) => row.advancedTargetGroups.length > 0);
    });
  }

  toggleTargetGroup(targetGroupId: wooId): void {
    const newSelectedTargetGroupIds: wooId[] = this.selectedTargetGroupIds.includes(targetGroupId)
      ? this.selectedTargetGroupIds.filter((atgId) => atgId !== targetGroupId)
      : [...this.selectedTargetGroupIds, targetGroupId];
    const selectedTargetGroups: TargetingAdvancedTargetGroup[] = this.advancedTargetGroups
      .filter((targetGroup) => newSelectedTargetGroupIds.includes(targetGroup.id))
      .map((targetGroup) => {
        return {
          advanced_target_group_id: targetGroup.id,
          name: targetGroup.name,
        };
      });
    this.bookingStore.setAdvancedTargetGroups(selectedTargetGroups, this.targetingId);
  }

  isSelected(targetGroupId: wooId): boolean {
    return this.selectedTargetGroupIds.includes(targetGroupId);
  }

  isNotLastCategory(index: number): boolean {
    return index < this.advancedTargetGroupsByCategory.length - 1;
  }
}

interface AdvancedTargetGroupsData extends AdvancedTargetGroupCategory {
  advancedTargetGroups: AdvancedTargetGroup[];
}
