import { Component, Input, OnChanges } from '@angular/core';
import { uniq } from '../../../utils/array';
import { SimpleChanges } from '../../../utils/types';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { AgencyService } from '../../../woo_services.module/AgencyService';
import { DialogService } from '../../../woo_services.module/DialogService';
import {
  AttestStatus,
  Invoice,
  InvoiceService,
  InvoiceStatus,
  ManualInvoice,
} from '../../../woo_services.module/InvoiceService';
import { Campaign } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { InvoiceType } from './invoice-form.component';

@Component({
  selector: 'invoices',
  templateUrl: './invoices.component.html',
})
export class Invoices implements OnChanges {
  readonly InvoiceType = InvoiceType;
  readonly InvoiceStatus = InvoiceStatus;

  @Input() campaign: Campaign;

  invoicesDisabled: boolean;
  invoiceType = InvoiceType.Credit;

  show = {
    invoiceForm: false,
    adminPanel: false,
    loadingInvoices: false,
  };

  agency = {
    has_discount: false,
    agency_discount: 0,
  };

  invoiceSums = {
    sent_total_gross: 0,
    sent_total_vat: 0,
    unsent_total_gross: 0,
    unsent_total_vat: 0,
  };

  invoices = [] as Invoice[];

  constructor(
    private invoiceService: InvoiceService,
    private dialogService: DialogService,
    private agencyService: AgencyService,
    private translationService: TranslationService,
  ) {}

  ngOnChanges(changes: SimpleChanges<Invoices>): void {
    if (changes.campaign && this.campaign) {
      this.reloadInvoices();
      this.invoicesDisabled = this.campaign.targetings.every((t) => !t.send_invoices);
    }
  }

  private validateInvoicePlan(diff: number): boolean {
    const campaign = this.campaign;
    const expectedBudget = campaign.new_order_value || campaign.order_value;
    let totalInvoiceBudget = this.invoices
      .map((invoice) => (invoice.status === 'cancelled' ? 0 : parseFloat(invoice.total_gross)))
      .reduce((sum, value) => sum + Number(value), 0);
    totalInvoiceBudget = totalInvoiceBudget + diff;
    return Math.abs(expectedBudget - totalInvoiceBudget) < 1;
  }

  cancelInvoice(invoice: Invoice): void {
    const dialogContent: ConfirmDialogContent = {
      header: 'Makulera faktura',
      textBlocks: [`Det här går inte att ångra så tänk gärna till en extra gång.`],
      confirmText: 'Makulera',
      cancelText: 'Avbryt',
    };
    const valid = this.validateInvoicePlan(-invoice.total_gross);
    if (!valid) {
      dialogContent.warningBlocks = [
        {
          header: '',
          textBlocks: [
            'Observera att faktureringsplanen inte är lika med kampanjens totala budget om du makulerar denna fakturan.',
          ],
        },
      ];
    }
    this.dialogService.openConfirm(dialogContent).then(() => {
      this.invoiceService.cancelInvoice(invoice.id).then(() => {
        this.dialogService.openSuccess('Fakturan har makulerats.');
        this.reloadInvoices();
      });
    });
  }

  newInvoice(type: InvoiceType): void {
    if (this.campaign.agency_id) {
      this.agencyService.getAgency(this.campaign.agency_id).then((agency) => {
        this.agency.agency_discount = parseFloat(agency.agency_discount);
        this.agency.has_discount = agency.has_discount;
      });
    }
    this.invoiceType = type;
    this.show.invoiceForm = true;
  }

  closeInvoiceForm(): void {
    this.show.invoiceForm = false;
  }

  private reloadInvoices(): void {
    this.show.loadingInvoices = true;
    this.invoiceService.getInvoicesForCampaign(this.campaign.id).then((invoices) => {
      this.invoices = invoices;
      this.summariseInvoices();
      this.show.loadingInvoices = false;
    });
  }

  private summariseInvoices(): void {
    this.invoiceSums = { sent_total_gross: 0, sent_total_vat: 0, unsent_total_gross: 0, unsent_total_vat: 0 };
    this.invoices.forEach((invoice) => {
      if (invoice.status === InvoiceStatus.sent) {
        this.invoiceSums.sent_total_gross += parseFloat(invoice.total_gross);
        this.invoiceSums.sent_total_vat += parseFloat(invoice.total_vat);
      } else if (invoice.status === InvoiceStatus.created) {
        this.invoiceSums.unsent_total_gross += parseFloat(invoice.total_gross);
        this.invoiceSums.unsent_total_vat += parseFloat(invoice.total_vat);
      }
    });
  }

  createManualInvoice(invoice: ManualInvoice): void {
    invoice.campaign_id = this.campaign.id;

    const dialogContent: ConfirmDialogContent =
      this.invoiceType === InvoiceType.Credit
        ? {
            header: 'Skicka kreditfaktura',
            textBlocks: [
              `Är du säker på att du vill kreditfakturera <strong>${this.campaign.name}</strong> med <strong>${invoice.total_gross_excluding_agency_discount}</strong> kr <strong>${invoice.invoice_date}</strong>?`,
            ],
            confirmText: 'Ja, fortsätt',
            cancelText: 'Nej, avbryt',
          }
        : {
            header: 'Skicka debetfaktura',
            textBlocks: [
              `Är du säker på att du vill fakturera <strong>${this.campaign.name}</strong> med <strong>${invoice.total_gross_excluding_agency_discount}</strong> kr <strong>${invoice.invoice_date}</strong>?`,
            ],
            confirmText: 'Ja, fortsätt',
            cancelText: 'Nej, avbryt',
          };

    const diff = this.agency.has_discount
      ? invoice.total_gross_excluding_agency_discount -
        invoice.total_gross_excluding_agency_discount * this.agency.agency_discount
      : invoice.total_gross_excluding_agency_discount;
    const valid = this.validateInvoicePlan(diff);
    if (!valid) {
      dialogContent.warningBlocks = [
        {
          header: '',
          textBlocks: ['Observera att faktureringsplanen inte är lika med kampanjens totala budget.'],
        },
      ];
    }

    this.dialogService.openConfirm(dialogContent).then(() => {
      this.invoiceService
        .createManualInvoice(invoice)
        .then((invoice: Invoice) => {
          if (invoice.attest_status === AttestStatus.AwaitingAttest) {
            this.dialogService.openSuccess(`Kreditfaktura är skapad!
          Ni måste skapa ett Scrive-underlag för att ekonomi ska kunna godkänna denna kreditfaktura.`);
          } else {
            this.dialogService.openSuccess('Fakturan har skapats.');
          }
        })
        .catch(() => {
          const msg = 'Det gick inte att skapa en faktura.';
          this.dialogService.openError(msg);
        })
        .finally(() => {
          this.closeInvoiceForm();
          this.reloadInvoices();
          this.agency.has_discount = false;
          this.agency.agency_discount = 0;
        });
    });
  }

  invoiceDisabledMessages(): string[] {
    return uniq(
      this.campaign.targetings
        .map((t) => (t.includes_linear ? 'Regionalpaketering' : t.invoice_disable_message))
        .filter((msg) => msg && msg.length),
    );
  }

  salesOrderNumbers(): string[] {
    return uniq(this.campaign.targetings.map((t) => t.sales_order_number).filter((so) => so && so.length));
  }

  paymentTerms(invoice: Invoice): string {
    return [invoice.payment_terms, invoice.payment_terms_code].filter((term) => term !== null).join(' - ');
  }

  attestStatus = this.translationService.convertAttestStatus;
  showAttestStatus(invoice: Invoice): boolean {
    return invoice.attest_status !== AttestStatus.NoAttestNeeded && invoice.status !== 'cancelled';
  }
}
