import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../woo_services.module/DialogService';
import { PasswordService } from '../../../woo_services.module/PasswordService';
import { RoutingService } from '../../../woo_services.module/RoutingService';
import { TranslationService } from '../../../woo_services.module/TranslationService';

const template = /* html */ `
  <div class="block">
    <div *ngIf="!token">
      <h1>Har du glömt ditt lösenord?</h1>
      <p>Ange din e-postadress nedan så kommer WOO skicka instruktioner om hur du kan återställa ditt lösenord.</p>
      <form #form="ngForm" (ngSubmit)="resetPassword(email)">
        <div class="input__container margin-top--2x">
          <label for="email">Email</label>
          <input type="email" id="email" name="email" [(ngModel)]="email" required>
        </div>
        <button id="reset-password-btn" type="submit" class="button primary-1" [disabled]="!form.valid">Återställ lösenord</button>
      </form>
    </div>
    <div *ngIf="token">
      <h1>Nytt lösenord</h1>
      Du behöver välja ett nytt lösenord för att kunna logga in i WOO Manager igen.
      <update-password-form [token]="token"></update-password-form>
    </div>
  </div>
`;

@Component({
  selector: 'forgot-password',
  template: template,
})
export class ForgotPassword {
  email: string;
  token: string;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private dialogService: DialogService,
    private passwordService: PasswordService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.token = this.routingService.getQueryParam(this.route, 'token');
  }

  resetPassword(email: string): void {
    this.dialogService.openBlocking();
    this.passwordService.resetPassword(email).then(
      () => {
        this.dialogService.closeBlocking();
        this.dialogService.openSuccess(
          'Instruktioner om hur du återställer ditt lösenord har skickats till din e-postadress.',
        );
      },
      (response) => {
        const errors = response?.error?.errors || {};
        let message;
        if (this.translationService.convertLoginError(errors.user) !== errors.user) {
          message = this.translationService.convertLoginError(errors.user);
        } else if (errors.email && errors.email[0] === 'not found') {
          message = 'E-postadressen finns tyvärr inte i systemet';
        } else {
          message = 'Någon la krokben!';
        }
        this.dialogService.openError('Nedrans!', message);
      },
    );
  }
}
