import { Component, Input } from '@angular/core';
import { HistoryItem, HistoryItemAction } from '../../woo_services.module/shared-types';
import { TranslationService } from '../../woo_services.module/TranslationService';

@Component({
  selector: 'history-list',
  templateUrl: './history-list.component.html',
})
export class HistoryList {
  @Input() items: HistoryItem[];
  @Input() showDetailsFor = [HistoryItemAction.create, HistoryItemAction.destroy, HistoryItemAction.update];

  convert = this.translationService.convertHistory;

  constructor(private translationService: TranslationService) {}

  formatHistoryChange = (newValue: any): any => {
    if (Array.isArray(newValue) && newValue.length > 0) {
      return this.formatArray(newValue);
    } else if (typeof newValue === 'object' && newValue !== null && !(newValue instanceof String)) {
      return this.objectFormatter(newValue);
    } else {
      return newValue;
    }
  };

  private formatArray = (array: any[]) => {
    const body = array.map((item) => this.getArrayItemFormatter(item)(item)).join(', ');
    return `[${body}]`;
  };

  private getArrayItemFormatter(item: any): (item: any) => string {
    if (item._type === 'VoucherConditionProduct') {
      return this.voucherConditionProductFormatter;
    } else if (this.isDiscount(item)) {
      return this.parseDiscount;
    } else {
      return this.noFormatting;
    }
  }

  private voucherConditionProductFormatter = (item: { values: string[] }): string => {
    return item.values
      .map(this.convert)
      .map((str) => `"${str}"`)
      .join(', ');
  };

  private objectFormatter = (item) => {
    return Object.entries(item)
      .map(([key, value]) => this.keyValueFormatter(key, value))
      .join(', ');
  };

  private isDiscount = (item) => {
    return typeof item === 'object' && item.discount_types && item.discount_rate;
  };

  private parseDiscount = (item) => {
    return `${this.convert(item.discount_types)}: ${item.discount_rate}`;
  };

  private noFormatting = (value: any): string => (value || '').toString();

  private keyValueFormatter = (key: string, value: any): string => {
    if (key === '$numberDecimal') {
      return `${value}`;
    } else {
      return `${key}:${value}`;
    }
  };
}
