import { round as lodashRound } from 'lodash-es';
import { wooId } from '../woo_services.module/shared-types';

export function round(num: number, precision: number): number {
  return lodashRound(num, precision);
}

export function roundDecimalError(num: number, precision = 10): number {
  return lodashRound(num, precision);
}

export function nullIfZero(sum: number): number | null {
  return sum ? sum : null;
}

export function distributeInteger(items: wooId[], total: number): { [key: string]: number } {
  const quotasMap = items.reduce((qMap, c) => ({ ...qMap, [c]: 0 }), {});
  const length = Object.keys(items).length;
  const quota = Math.floor(total / length);
  let remainder = total - quota * length;

  Object.keys(quotasMap).forEach((key) => {
    let q = quota;
    if (remainder > 0) {
      q += 1;
      remainder -= 1;
    }
    quotasMap[key] = q;
  });
  return quotasMap;
}
