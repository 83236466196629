<div class="campaign-card {{this.cardListing ? '' : 'list'}} {{campaign.status === 'unbooked' ? 'unbooked' : ''}}" [ngClass]="{'active': active}">
  <div *ngIf=" cardListing" class="card-top"></div>
  <special-campaign-label
    *ngIf="!cardListing && hasRoleAdminOrPlanner"
    class="absolute-top-right-corner margin-right--1x margin-top--1x"
    [campaign]="campaign"></special-campaign-label>
  <div class="campaign__content clearfix">
    <div class="top">
      <div class="absolute-top-right-corner margin-right--1x margin-top--1x ">
        <special-campaign-label
          *ngIf="cardListing && hasRoleAdminOrPlanner"
          [campaign]="campaign"></special-campaign-label>
      </div>
      <div class="display--flex justify-content--space-between align-items--center margin-top--1x">
        <div class="client ellipsis">{{campaign.customer_name || "Unknown customer"}}</div>
      </div>
      <div class="name ellipsis">{{campaign.name || "Unknown name"}}</div>
    </div>
    <div class="mid">
      <table class="table">
        <tr>
          <td class="strong">Startdatum</td>
          <td class="thin text-right">{{ campaign.start_date | date: 'yyyy-MM-dd'}}</td>
        </tr>
        <tr>
          <td class="strong">Slutdatum</td>
          <td class="thin text-right">{{ campaign.end_date | date: 'yyyy-MM-dd'}}</td>
        </tr>
        <tr *ngIf="!cardListing && expectedTrackingData()">
          <td class="strong">Levererat</td>
          <td class="thin text-right">{{ (campaign.tracking?.progress || '0') | number:0}} %</td>
        </tr>
      </table>
      <table class="table" *ngIf="campaign.status !== 'unbooked'">
        <tr>
          <td class="strong">Est. {{
            cardListing ? convertViewCurrency(campaign.view_currency) : convertViewCurrencyExtraShort(campaign.view_currency)
          }}</td>
          <td class="thin text-right">{{campaign.impressions_to_deliver | number}}</td>
        </tr>
        <tr>
          <td class="strong">Lev. {{
            cardListing ? convertViewCurrency(campaign.view_currency) : convertViewCurrencyExtraShort(campaign.view_currency)
          }}</td>
          <td class="thin text-right">{{ getTrackingValue(campaign) | number}}</td>
        </tr>
        <tr *ngIf="hasRoleAdminOrPlanner">
          <td class="strong">Justerade {{
            cardListing ? convertViewCurrencyShort(campaign.view_currency) : convertViewCurrencyExtraShort(campaign.view_currency)
          }}</td>
          <td class="thin text-right">{{(campaign.adjusted_goal_order_views || 0) + (campaign.additional_goal_impressions || 0) | number}}</td>
        </tr>
      </table>
    </div>
    <div class="bot" [ngClass]="{planner: hasRoleAdminOrPlanner}">
      <div class="status">
        <dashboard-card-campaign-status [campaignStatus]="campaign.status"></dashboard-card-campaign-status>
        <dashboard-card-ad-server-status
          *ngIf="hasRoleAdminOrPlanner"
          [adServerStatus]="campaign.ad_server_status">
        </dashboard-card-ad-server-status>
        <dashboard-card-creative-status [campaign]="campaign"></dashboard-card-creative-status>
        <div *ngIf="allowedToEditCreatives" class="edit__button clearfix">
          <button class="link"
                  [ngClass]="{'primary-1': active}"
                  (click)="$event.stopPropagation(); editCallback()">
                  <span class="icon-edit"></span> Redigera material
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div *ngIf="expectedTrackingData(); else stamp">
      Leverans:
      <span class="percentage pull-right">{{campaign.tracking?.progress || 0}} %</span>
    </div>
    <ng-template #stamp>
      <div class="hidden-xs clearfix" *ngIf="campaign.status !== 'unbooked' && campaign.status !== 'cancelled'">
        <div >
          <span class="icon-stats"></span> {{campaign.stamp}}
        </div>
      </div>
    </ng-template>
  </div>
</div>
