<div class="inner-block animate">
  <div class="row">
    <div class="block">
      <div class="col-xs-12">
        <h2>Redigera apparat</h2>
        <div class="account__card">
        <dot-loader [visibility]="loading" [large]="true"></dot-loader>
          <form *ngIf="!loading" id="form" (submit)="updateDeviceGroup()">
            <h3>Instream estimat</h3>

            <div class="input__container">
              <label for="name">Namn *</label>
              <input id="name" name="name" [(ngModel)]="deviceGroup.name" required>
            </div>
            <div class="input__container">
              <label for="description">Beskrivning *</label>
              <input id="description" name="description" [(ngModel)]="deviceGroup.description" required>
            </div>

          <table class="table table-woo table--hover width--100">
            <thead>
              <tr>
                <th [colSpan]="staticFields"></th>
                <th class="text--thin text--small capitalize--first-letter centered" colspan="2"></th>
                <th class="text--thin text--small capitalize--first-letter centered" colspan="3">
                  Estimatfördelning
                </th>
                <th class="text--thin text--small capitalize--first-letter centered" colspan="3">
                  Maxuttag
                </th>
              </tr>
              <tr>
                <th *ngFor="let header of tableHeaders" class="text--thin text--small capitalize--first-letter">
                  {{header}}
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let row of inputRows; let index = index; trackBy: trackByIndex">
              <tr>
                <td class="text--thin vertical-align--middle">
                  <span>{{translateProductFormat(row.productFormat)}}</span>
                </td>
                <td class="text--thin vertical-align--middle">
                  <span>{{row.publisher}}</span>
                </td>
                <td class="vertical-align--middle">
                  <div
                    class="input__container">
                    <input
                      name="row-{{index}}-sharesPreroll"
                      [(ngModel)]="deviceGroup.shares[row.publisherId][row.productFormatId].preroll"
                      wooPercentFormatter
                      [wooMinValidator]="0"
                      [wooMaxValidator]="1"
                      [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                      [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
                  </div>
                </td>

                <td class="vertical-align--middle">
                  <div
                    class="input__container">
                    <input
                      name="row-{{index}}-sharesMidroll"
                      [(ngModel)]="deviceGroup.shares[row.publisherId][row.productFormatId].midroll"
                      wooPercentFormatter
                      [wooMinValidator]="0"
                      [wooMaxValidator]="1"
                      [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                      [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
                  </div>
                </td>

                <td class="vertical-align--middle">
                  <div
                    class="input__container">
                    <input
                      name="row-{{index}}-sharesPostroll"
                      [(ngModel)]="deviceGroup.shares[row.publisherId][row.productFormatId].postroll"
                      wooPercentFormatter
                      [wooMinValidator]="0"
                      [wooMaxValidator]="1"
                      [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                      [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
                  </div>
                </td>

                <td class="vertical-align--middle">
                  <div
                    class="input__container">
                    <input
                      name="row-{{index}}-withdrawalLimitsPreroll"
                      [(ngModel)]="deviceGroup.withdrawal_limits[row.publisherId][row.productFormatId].preroll"
                      wooPercentFormatter
                      [wooMinValidator]="0"
                      [wooMaxValidator]="1"
                      [required]=" includedProductFormatSlotTypes[row.productFormatId].preroll"
                      [disabled]="!includedProductFormatSlotTypes[row.productFormatId].preroll">
                  </div>
                </td>

                <td class="vertical-align--middle">
                  <div
                    class="input__container">
                    <input
                      name="row-{{index}}-withdrawalLimitsMidroll"
                      [(ngModel)]="deviceGroup.withdrawal_limits[row.publisherId][row.productFormatId].midroll"
                      wooPercentFormatter
                      [wooMinValidator]="0"
                      [wooMaxValidator]="1"
                      [required]=" includedProductFormatSlotTypes[row.productFormatId].midroll"
                      [disabled]="!includedProductFormatSlotTypes[row.productFormatId].midroll">
                  </div>
                </td>

                <td class="vertical-align--middle">
                  <div
                    class="input__container">
                    <input
                      name="row-{{index}}-withdrawalLimitsPostroll"
                      [(ngModel)]="deviceGroup.withdrawal_limits[row.publisherId][row.productFormatId].postroll"
                      wooPercentFormatter
                      [wooMinValidator]="0"
                      [wooMaxValidator]="1"
                      [required]=" includedProductFormatSlotTypes[row.productFormatId].postroll"
                      [disabled]="!includedProductFormatSlotTypes[row.productFormatId].postroll">
                  </div>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>

          <h3>Outstream estimat</h3>
          <outstream-estimates-input [sites]="sites" [(shares)]="deviceGroup.outstream_shares"
          [(withdrawalLimits)]="deviceGroup.outstream_withdrawal_limits"></outstream-estimates-input>
          <div class="margin-top--2x">
            <button type="submit" class="button primary-1">Spara</button>
            <button type="button" class="link primary-1" [routerLink]="['/products', 'device_groups']">Avbryt</button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
