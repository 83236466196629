import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { scrollTo } from 'app/scripts/utils/dom';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { ExtraProductFormatField, ProductFormatService } from '../../../../woo_services.module/ProductFormatService';
import { ExtraPublisherField, PublisherService } from '../../../../woo_services.module/PublisherService';
import { ProductFormat, Publisher, SlotType, wooId } from '../../../../woo_services.module/shared-types';
import { TranslationService } from '../../../../woo_services.module/TranslationService';
import { ProductsPageUtilityService } from '../../shared/products-page-utility-service';

@Component({
  selector: 'manage-ag-withdrawal-limits',
  templateUrl: './manage-ag-withdrawal-limits.component.html',
  styleUrls: ['../../shared/table.component.scss'],
})
export class ManageAgWithdrawalLimits implements OnInit {
  @ViewChild('stickyHeader', { static: true }) stickyHeader: ElementRef;
  @ViewChild('stickyTableHeader', { static: true }) stickyTableHeader: ElementRef;
  @Input('headerPos') parentHeader: HTMLElement;
  show = {
    estimatesHaveChanged: false,
    updatingEstimates: false,
    loading: true,
    isEditing: false,
  };
  readonly tableHeaders = ['Publicist', 'Placering', 'Preroll', 'Midroll', 'Postroll'];

  readonly staticFields = 2;

  publishers: Publisher[] = [];
  selectedPublisher: Publisher;
  productFormats: ProductFormat[];
  selectedProductFormat: ProductFormat;
  agWithdrawalLimitRows: AgWithdrawalLimitRow[];
  includedProductFormatSlotTypes: Record<wooId, Record<SlotType, boolean>> = {};
  translateProductFormat: (args: any) => string;

  constructor(
    private publisherService: PublisherService,
    private productFormatService: ProductFormatService,
    private utilityService: ProductsPageUtilityService,
    public dialogService: DialogService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translateProductFormat = this.translationService.convertProductFormat;
    this.publisherService
      .getPublishers(ExtraPublisherField.enabled)
      .then((publishers) => {
        this.publishers = publishers;
        this.publishers = publishers.filter((p) => p.enabled);
      })
      .then(() => {
        this.getAndBuildAGRows();
      })
      .finally(() => {
        this.show.loading = false;
        scrollTo(this.parentHeader);
        this.utilityService.stickifyHeaders(this.stickyHeader, this.stickyTableHeader);
      });
  }

  async getAndBuildAGRows(): Promise<void> {
    this.productFormatService
      .getProductFormats(
        ExtraProductFormatField.enabled,
        ExtraProductFormatField.agWithdrawalLimits,
        ExtraProductFormatField.slotTypes,
      )
      .then((productFormats) => {
        this.productFormats = productFormats;
        this.productFormats = productFormats.filter((f) => f.enabled);
        this.includedProductFormatSlotTypes = this.utilityService.setIncludedProductFormatSlotTypes(
          this.productFormats,
        );
        this.buildAGRows();
      });
  }

  setEditing(value: boolean): void {
    this.show.isEditing = value;
  }

  cancelEditing(): void {
    this.getAndBuildAGRows();
    this.setEditing(false);
  }

  async save(): Promise<void> {
    const filterActive = this.selectedPublisher || this.selectedProductFormat;
    const textBlocks = ['Är du säker på att du vill spara dina ändringar?'];
    if (filterActive) {
      textBlocks.push('Notera att även ändringar i filtrerade rader sparas.');
    }
    this.dialogService
      .openConfirm({
        header: 'Bekräfta ändringar',
        textBlocks: textBlocks,
        confirmText: 'Spara',
        cancelText: 'Avbryt',
      })
      .then(() => {
        this.show.updatingEstimates = true;
        this.productFormatService.updateProductFormats(this.productFormats).then((productFormats) => {
          this.productFormats = productFormats;
          this.buildAGRows();
          this.show.estimatesHaveChanged = true;
        });
      })
      .catch(() => null)
      .finally(() => {
        this.setEditing(false);
        this.show.updatingEstimates = false;
        this.dialogService.closeBlocking();
      });
  }

  rowVisible(obj: AgWithdrawalLimitRow): boolean {
    const publisherFiltered = this.selectedPublisher && obj.publisherId !== this.selectedPublisher.id;
    const productFormatFiltered = this.selectedProductFormat && obj.productFormatId !== this.selectedProductFormat.id;
    return !(publisherFiltered || productFormatFiltered);
  }

  getProductFormat(productFormatId: wooId): ProductFormat {
    return this.productFormats.find((format) => format.id === productFormatId);
  }

  private buildAGRows(): void {
    let agWithdrawalLimitRow: AgWithdrawalLimitRow[] = [];

    this.publishers.map((publisher) => {
      this.utilityService.getPublisherSpecificProductFormats(publisher, this.productFormats).map((productFormat) => {
        agWithdrawalLimitRow = [
          ...agWithdrawalLimitRow,
          {
            publisher: publisher.name,
            publisherId: publisher.id,
            productFormat: productFormat.name,
            productFormatId: productFormat.id,
            withdrawalLimitsPreroll: productFormat.ag_withdrawal_limits.preroll,
            withdrawalLimitsMidroll: productFormat.ag_withdrawal_limits.midroll,
            withdrawalLimitsPostroll: productFormat.ag_withdrawal_limits.postroll,
          },
        ];
      });
    });
    this.agWithdrawalLimitRows = agWithdrawalLimitRow;
  }
}

interface AgWithdrawalLimitRow {
  publisher: string;
  publisherId?: wooId;
  productFormat: string;
  productFormatId: wooId;
  withdrawalLimitsPreroll?: number;
  withdrawalLimitsMidroll?: number;
  withdrawalLimitsPostroll?: number;
}
