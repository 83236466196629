import { Component, EventEmitter, Input, Output } from '@angular/core';
import { generateId } from '../../../utils/string';

const template = `
<div class="height--full border--all border-width--medium padding--2x" [ngClass]="{'border--info': model === value}" (click)="modelChange.emit(value)">
  <div>
    <input class="radio" id="{{getId()}}" type="radio" [(ngModel)]="model" value="{{value}}">
    <label class="radio text-align--left" for="{{getId()}}">{{headline}}</label>
    <p class="margin-top--1x" *ngIf="price">({{price}} kr per färdigspelad visning)</p>
  </div>
  <div class="margin-top--1x text--small">
    {{text}}
  </div>
  <ng-content></ng-content>
</div>
`;

@Component({
  selector: 'targeting-radio-box',
  template: template,
})
export class TargetingRadioBox {
  @Input() headline: string;
  @Input() text: string;
  @Input() price: number;
  @Input() value: any;
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();

  private id = generateId();

  getId = (): string => {
    return this.id;
  };
}
