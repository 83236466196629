import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { isArray } from 'lodash-es';

@Component({
  selector: 'context-message',
  templateUrl: './context-message.component.html',
  styleUrls: ['./context-message.component.scss'],
})
export class ContextMessage implements OnChanges {
  @Input() message: string | string[];
  @Input() messageType: MessageType;
  @Output() dismiss = new EventEmitter<void>();

  public messageLines: string[];

  ngOnChanges(event: any): void {
    if (event.message && this.message) {
      this.messageLines = isArray(this.message) ? (this.message as string[]) : [this.message as string];
    }
  }

  dismissMessage(): void {
    this.dismiss.emit();
  }
}

export const enum MessageType {
  error = 'error',
  info = 'info',
  warning = 'warning',
  success = 'success',
}
