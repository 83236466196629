import { Component, Input, OnChanges } from '@angular/core';
import { wooId } from '../../../woo_services.module/shared-types';
import { TargetingCombinationRulesService } from '../../../woo_services.module/TargetingCombinationRulesService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
  <voucher-select [targetingId]="targetingId"></voucher-select>
  <frequency-limit-targeting [targetingId]="targetingId" *ngIf="frequencyLimitCompatible"></frequency-limit-targeting>
`;

@Component({
  selector: 'rbs-targeting',
  template: template,
})
export class RBSTargeting extends SubscriptionHandler implements OnChanges {
  frequencyLimitCompatible = false;
  @Input() targetingId: wooId;

  constructor(
    private bookingStore: BookingStore,
    private targetingCombinationRulesService: TargetingCombinationRulesService,
  ) {
    super();
    this.addSubscription(bookingStore.state$.subscribe(this.initFromStore));
  }

  ngOnChanges(): void {
    this.initFromStore(this.bookingStore.state);
  }

  private initFromStore = (model: BookingModel) => {
    const targeting = model.campaign.targetings.find((t) => t.id === this.targetingId);
    if (targeting) {
      this.frequencyLimitCompatible = this.targetingCombinationRulesService.frequencyLimitCompatible(this.targetingId);
    }
  };
}
