import { Campaign, ExtendedTargetingBudget, Targeting, ViewCurrency } from '../../woo_services.module/shared-types';
import { sum } from '../array';
import {
  targetingAdditionalBudgetWithAgreementPart,
  targetingAdditionalViewsWithAgreementPart,
  targetingNetBudgetViewsWithAgreementPart,
  targetingNetBudgetWithAgreementPart,
} from './targeting_budget_helper';

const hasAggrement = (campaign: Campaign): boolean => {
  return !!campaign.agreement;
};

export const campaignTotalBudget = (campaign: Campaign): number => {
  return campaignTotalNetBudget(campaign) + campaignTotalAdditionalBudget(campaign);
};

export const campaignTotalNetBudget = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (campaign.agreement?.net_budget || 0) + allExtendedNetBudgets(campaign);
  }

  return (
    campaign.targetings?.reduce((sumNetBudget, targeting: Targeting) => {
      return sumNetBudget + targetingNetBudgetWithAgreementPart(targeting);
    }, 0) || 0
  );
};

export const campaignTotalAdditionalBudget = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (campaign.agreement?.additional_net_budget || 0) + allExtendedAdditionalBudgets(campaign);
  }

  return (
    campaign.targetings?.reduce((sumAdditionalBudget, targeting: Targeting) => {
      return sumAdditionalBudget + targetingAdditionalBudgetWithAgreementPart(targeting);
    }, 0) || 0
  );
};

export const campaignTotalNetViews = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (campaign.agreement?.net_views || 0) + allExtendedNetBudgetViews(campaign);
  }

  return (
    campaign.targetings?.reduce((sum, targeting: Targeting) => {
      return sum + targetingNetBudgetViewsWithAgreementPart(targeting);
    }, 0) || 0
  );
};

export const campaignTotalNetImpressions = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (campaign.agreement?.impressions_summary?.net_views || 0) + allExtendedNetBudgetImpressions(campaign);
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.impressions)
      ?.reduce((sum, targeting) => {
        return sum + targetingNetBudgetViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalNetPauseImpressions = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (campaign.agreement?.pause_ad_summary?.net_views || 0) + allExtendedNetBudgetPauseImpressions(campaign);
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.impressions && t.pause_ad)
      ?.reduce((sum, targeting) => {
        return sum + targetingNetBudgetViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalNetOnlineVideoImpressions = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (
      (campaign.agreement?.online_video_summary?.net_views || 0) + allExtendedNetBudgetOnlineVideoImpressions(campaign)
    );
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.impressions && !t.pause_ad)
      ?.reduce((sum, targeting) => {
        return sum + targetingNetBudgetViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalNetCompletedViews = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (campaign.agreement?.completed_views_summary?.net_views || 0) + allExtendedNetBudgetCompletedViews(campaign);
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.completedViews)
      ?.reduce((sum, targeting) => {
        return sum + targetingNetBudgetViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalNetGrossRatingViews = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (
      (campaign.agreement?.gross_rating_views_summary?.net_views || 0) + allExtendedNetBudgetGrossRatingViews(campaign)
    );
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.grossRatingViews)
      ?.reduce((sum, targeting) => {
        return sum + targetingNetBudgetViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalAdditionalViews = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (campaign.agreement?.additional_net_views || 0) + allExtendedAdditionalViews(campaign);
  }

  return (
    campaign.targetings?.reduce((sum, targeting) => {
      return sum + targetingAdditionalViewsWithAgreementPart(targeting, null);
    }, 0) || 0
  );
};

export const campaignTotalAdditionalImpressions = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (
      (campaign.agreement?.impressions_summary?.additional_net_views || 0) + allExtendedAdditionalImpressions(campaign)
    );
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.impressions)
      ?.reduce((sum, targeting) => {
        return sum + targetingAdditionalViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalAdditionalPauseImpressions = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (
      (campaign.agreement?.pause_ad_summary?.additional_net_views || 0) +
      allExtendedAdditionalPauseImpressions(campaign)
    );
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.impressions && t.pause_ad)
      ?.reduce((sum, targeting) => {
        return sum + targetingAdditionalViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalAdditionalOnlineVideoImpressions = (campaign: Campaign): number => {
  if (hasAggrement(campaign)) {
    return (
      (campaign.agreement?.online_video_summary?.additional_net_views || 0) +
      allExtendedAdditionalOnlineVideoImpressions(campaign)
    );
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.impressions && !t.pause_ad)
      ?.reduce((sum, targeting) => {
        return sum + targetingAdditionalViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalAdditionalCompletedViews = (campaign: Campaign): number => {
  if (campaign.agreement) {
    return (
      (campaign.agreement?.completed_views_summary.additional_net_views || 0) +
      allExtendedAdditionalCompletedViews(campaign)
    );
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.completedViews)
      ?.reduce((sum, targeting) => {
        return sum + targetingAdditionalViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const campaignTotalAdditionalGrossRatingViews = (campaign: Campaign): number => {
  if (campaign.agreement) {
    return (
      (campaign.agreement?.gross_rating_views_summary.additional_net_views || 0) +
      allExtendedAdditionalGrossRatingViews(campaign)
    );
  }

  return (
    campaign.targetings
      ?.filter((t) => t.view_currency === ViewCurrency.grossRatingViews)
      ?.reduce((sum, targeting) => {
        return sum + targetingAdditionalViewsWithAgreementPart(targeting, null);
      }, 0) || 0
  );
};

export const allExtendedNetBudgetViews = (campaign: Campaign): number => {
  return sum(
    campaign.targetings.map((targeting: Targeting) => {
      return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.impressions) || [0]);
    }),
  );
};

const allExtendedNetBudgets = (campaign: Campaign): number => {
  return sum(
    campaign.targetings.map((targeting: Targeting) => {
      return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.budget) || [0]);
    }),
  );
};

const allExtendedAdditionalBudgets = (campaign: Campaign): number => {
  return sum(
    campaign.targetings.map((targeting: Targeting) => {
      return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_budget) || [0]);
    }),
  );
};

const allExtendedNetBudgetImpressions = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.impressions)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.impressions) || [0]);
      }),
  );
};

const allExtendedNetBudgetPauseImpressions = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.impressions && t.pause_ad)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.impressions) || [0]);
      }),
  );
};

const allExtendedNetBudgetOnlineVideoImpressions = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.impressions && !t.pause_ad)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.impressions) || [0]);
      }),
  );
};

const allExtendedNetBudgetCompletedViews = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.completedViews)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.impressions) || [0]);
      }),
  );
};

const allExtendedNetBudgetGrossRatingViews = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.grossRatingViews)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.impressions) || [0]);
      }),
  );
};

const allExtendedAdditionalViews = (campaign: Campaign): number => {
  return sum(
    campaign.targetings.map((targeting: Targeting) => {
      return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_impressions) || [0]);
    }),
  );
};

const allExtendedAdditionalCompletedViews = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.completedViews)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_impressions) || [0]);
      }),
  );
};

const allExtendedAdditionalGrossRatingViews = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.grossRatingViews)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_impressions) || [0]);
      }),
  );
};

const allExtendedAdditionalImpressions = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.impressions)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_impressions) || [0]);
      }),
  );
};

const allExtendedAdditionalPauseImpressions = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.impressions && t.pause_ad)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_impressions) || [0]);
      }),
  );
};

const allExtendedAdditionalOnlineVideoImpressions = (campaign: Campaign): number => {
  return sum(
    campaign.targetings
      .filter((t) => t.view_currency === ViewCurrency.impressions && !t.pause_ad)
      .map((targeting: Targeting) => {
        return sum(targeting.extended_budgets?.map((eb: ExtendedTargetingBudget) => eb.additional_impressions) || [0]);
      }),
  );
};
