import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaginationService {
  getPagination = (headers: PaginationHeader | HttpHeaders): Pagination => {
    return headers instanceof HttpHeaders
      ? this.getPaginationFromHttpHeaders(headers)
      : this.getPaginationFromObject(headers);
  };

  private getPaginationFromHttpHeaders(headers: HttpHeaders): Pagination {
    return {
      total: parseInt(headers.get('Total')),
      page: parseInt(headers.get('Current-Page')),
      perPage: parseInt(headers.get('Per-Page')),
      last: parseInt(headers.get('Total-Pages')),
    };
  }

  private getPaginationFromObject(paginationHeader: PaginationHeader): Pagination {
    return {
      total: parseInt(paginationHeader.total),
      page: parseInt(paginationHeader['current-page']),
      perPage: parseInt(paginationHeader['per-page']),
      last: parseInt(paginationHeader['total-pages']),
    };
  }
}

export interface Pagination {
  total: number;
  perPage: number;
  page: number;
  last: number;
}

export interface PaginationHeader {
  total: string;
  'per-page': string;
  'total-pages': string;
  'current-page': string;
}
