<div class="padding--4x shadow-z-1 background-color--white margin-top--3x">
  <h3 class="margin-top--none">Förlänga kampanj</h3>
  <p class="text--small">
    <span class="icon-warning info text-info"></span> Förlängning av en kampanjdel innebär att befintliga mål på den
    delen som är aktiva vid delens slutdatum förlängs.
  </p>
  <div>
    <button class="button" (click)="updateCampaign()" [disabled]="disabled">Spara</button>
    <button class="button secondary" (click)="abort()">Avbryt</button>
  </div>
</div>

<div *ngFor="let data of targetingData" class="padding--3x shadow-z-1 background-color--white margin-top--3x">
  <expandable-targeting-header [targeting]="data.targeting">
    <date-select
      [firstSelectableDate]="data.minEndDate"
      [lastSelectableDate]="data.maxNewEndDate"
      [ngModel]="data.newEndDate"
      (ngModelChange)="setEndDate($event, data)"
      label="Till"
    ></date-select>
  </expandable-targeting-header>
</div>
<div class="padding--2x">
  <button class="button" (click)="updateCampaign()" [disabled]="disabled">Spara</button>
  <button class="button secondary" (click)="abort()">Avbryt</button>
</div>
