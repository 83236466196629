import { ElementRef, Injectable } from '@angular/core';
import { PriceList } from '../../../woo_services.module/PriceListService';
import {
  Category,
  PerIdEstimates,
  ProductFormat,
  Publisher,
  SlotType,
  wooId,
} from '../../../woo_services.module/shared-types';

@Injectable()
export class ProductsPageUtilityService {
  getPublisherName(publisherId: wooId, publishers: Publisher[]): string {
    return publishers?.find((publisher) => publisher.id === publisherId)?.name;
  }

  getCategoryName(categoryId: wooId, categories: Category[]): string {
    return categories?.find((category) => category.id === categoryId)?.name;
  }

  getProductFormat(productFormatId: wooId, productFormats: ProductFormat[]): ProductFormat {
    return productFormats.find((format) => format.id === productFormatId);
  }

  getPublisherSpecificProductFormats(publisher: Publisher, product_formats: ProductFormat[]): ProductFormat[] {
    return product_formats.filter((product_format) => product_format.publisher_id === publisher.id);
  }

  setIncludedPublisherSlotTypes(productFormats: ProductFormat[]): Record<wooId, Record<SlotType, boolean>> {
    const includedSlotTypes = {};
    productFormats.forEach((productFormat) => {
      includedSlotTypes[productFormat.publisher_id] = {
        [SlotType.preroll]: includedSlotTypes[productFormat.publisher_id]?.preroll
          ? true
          : productFormat.slot_types.includes(SlotType.preroll),
        [SlotType.midroll]: includedSlotTypes[productFormat.publisher_id]?.midroll
          ? true
          : productFormat.slot_types.includes(SlotType.midroll),
        [SlotType.postroll]: includedSlotTypes[productFormat.publisher_id]?.postroll
          ? true
          : productFormat.slot_types.includes(SlotType.postroll),
        [SlotType.pauseAd]: includedSlotTypes[productFormat.publisher_id]?.pauseAd
          ? true
          : productFormat.slot_types.includes(SlotType.pauseAd),
        [SlotType.prerollSticky]: includedSlotTypes[productFormat.publisher_id]?.prerollSticky
          ? true
          : productFormat.slot_types.includes(SlotType.prerollSticky),
        [SlotType.postrollSticky]: includedSlotTypes[productFormat.publisher_id]?.postrollSticky
          ? true
          : productFormat.slot_types.includes(SlotType.postrollSticky),
      };
    });
    return includedSlotTypes;
  }

  setIncludedProductFormatSlotTypes(productFormats: ProductFormat[]): Record<wooId, Record<SlotType, boolean>> {
    const includedSlotTypes = {};
    productFormats.forEach((productFormat) => {
      includedSlotTypes[productFormat.id] = {
        [SlotType.preroll]: productFormat.slot_types.includes(SlotType.preroll),
        [SlotType.midroll]: productFormat.slot_types.includes(SlotType.midroll),
        [SlotType.postroll]: productFormat.slot_types.includes(SlotType.postroll),
        [SlotType.pauseAd]: productFormat.slot_types.includes(SlotType.pauseAd),
      };
    });
    return includedSlotTypes;
  }

  buildEmptyProductFormatEstimates(
    publishers: Publisher[],
    productFormats: ProductFormat[],
  ): { [publisherId: string]: PerIdEstimates } {
    const publisherProductFormats = new Map();
    publishers.map((publisher) => {
      publisherProductFormats[publisher.id] = this.getPublisherSpecificProductFormats(publisher, productFormats);
    });
    return publishers.reduce<{ [publisherId: string]: PerIdEstimates }>(
      (publishers, publisher) => ({
        ...publishers,
        [publisher.id]: publisherProductFormats[publisher.id].reduce(
          (productFormats, productFormat) => ({
            ...productFormats,
            [productFormat.id]: productFormat.slot_types.reduce((slots, slot) => ({ ...slots, [slot]: 0 }), {}),
          }),
          {},
        ),
      }),
      {},
    );
  }

  filterDisabledPublisherPrices = (
    list: PriceList | Partial<PriceList>,
    publishers: Publisher[],
  ): PriceList | Partial<PriceList> => {
    const enabledPublisherIds = publishers.map((publisher) => publisher.id);

    list.publisher_base_prices = Object.keys(list.publisher_base_prices)
      .filter((key) => enabledPublisherIds.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: list.publisher_base_prices[key],
        };
      }, {});

    list.publisher_targeting_prices = Object.keys(list.publisher_targeting_prices)
      .filter((key) => enabledPublisherIds.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: list.publisher_targeting_prices[key],
        };
      }, {});

    list.publisher_age_modifier_prices = Object.keys(list.publisher_age_modifier_prices)
      .filter((key) => enabledPublisherIds.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: list.publisher_age_modifier_prices[key],
        };
      }, {});

    list.publisher_locality_prices = Object.keys(list.publisher_locality_prices)
      .filter((key) => enabledPublisherIds.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: list.publisher_locality_prices[key],
        };
      }, {});

    list.publisher_addon_targeting_prices = Object.keys(list.publisher_addon_targeting_prices)
      .filter((key) => enabledPublisherIds.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: list.publisher_addon_targeting_prices[key],
        };
      }, {});

    return list;
  };

  stickifyHeaders(topElement: ElementRef, tableElement = null): void {
    this.setStickyPosition(topElement, 2);
    if (tableElement) this.setStickyPosition(tableElement, 2, topElement.nativeElement.offsetHeight);
  }

  setStickyPosition(element: ElementRef, zIndex: number, additionalOffset = 0): void {
    const banner_height = 100;
    const title_and_tabs_height = 99;
    element.nativeElement.style.setProperty('z-index', String(zIndex));
    element.nativeElement.style.setProperty('position', 'sticky');
    element.nativeElement.style.setProperty(
      'top',
      String(banner_height + title_and_tabs_height + additionalOffset) + 'px',
    );
  }
}
