<div class="form-group">
  <label for="{{inputId}}">{{ labelText }}</label>
  <ng-select
    labelForId="{{inputId}}"
    [items]="items"
    bindLabel="{{bindLabel}}"
    bindValue="{{bindValue}}"
    [searchFn]="searchFn"
    [(ngModel)]="model"
    [virtualScroll]="true"
    [clearable]="clearable"
    [multiple]="multiple"
    [disabled]="disabled"
    notFoundText="{{notFoundText}}"
    [placeholder]="placeholder"
  >
    <ng-template *ngIf="labelTransform" ng-option-tmp let-item="item">
      <span>{{ labelTransform(item[bindLabel]) }}</span>
    </ng-template>
  </ng-select>
</div>
