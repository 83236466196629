<div class="tabbable__body">
  <div class="tabbable__panel section info shadow-z-1 padding--1x">
    <h3>
      <span
        class="chevron"
        (click)="toggleSection()"
        [ngClass]="{bottom: show.section}"
      >
    </span>
      {{heading}}
  </h3>
  </div>

  <div class="tabbable__panel shadow-z-1" *ngIf="show.section">
    <table class="table table-woo table--info table--hover width--100">
      <thead>
      <tr>
        <th *ngFor="let header of tableHeaders" class="text--thin capitalize--first-letter">
          {{header}}
        </th>
      </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let mapping of mappings; let index = index;">
          <tr>
            <td class="text--thin vertical-align--middle">
              <span>{{ mapping.name }}</span>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{ getTargetingType(mapping) }}</span>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{ mapping.factor }}</span>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{ mapping.start_date | date: 'yyyy-MM-dd' }}</span>
            </td>
            <td class="text--thin vertical-align--middle">
              <span>{{ mapping.created_at | date: 'yyyy-MM-dd HH:mm' }}</span>
            </td>
            <td class=" padding-top-bottom--1x">
              <button (click)="editTargetingMapping(mapping.id)" class="button margin--none">
                <span class="icon-edit icon__semi-medium "></span>
                Redigera
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
