import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { Region } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RegionService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getRegions(): Promise<Region[]> {
    return lastValueFrom(this.http.get<Region[]>(`${this.env.apiUrl}/regions`));
  }

  updateRegionsShares(regions: Region[]): Promise<Region[]> {
    return lastValueFrom(
      this.http.put<undefined>(`${this.env.apiUrl}/regions/update_shares`, { regions: regions }),
    );
  }
}
