import { Component, OnInit } from '@angular/core';
import { saveFileAs } from '../../../utils/files';
import { trackById } from '../../../utils/object';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../woo_services.module/DialogService';
import { RevenueReport, RevenueReportsService } from '../../../woo_services.module/RevenueReportsService';
import { SystemService } from '../../../woo_services.module/SystemService';

@Component({
  selector: 'revenue-reports-page',
  templateUrl: './revenue-reports-page.component.html',
  styles: ['.override-bootstrap th {padding: 8px !important; font-weight: 500;}'],
})
export class RevenueReportsPage implements OnInit {
  availableReports: RevenueReport[] = [];

  readonly trackById = trackById;

  constructor(
    private dialogService: DialogService,
    private revenueReportsService: RevenueReportsService,
    private systemService: SystemService,
  ) {}

  ngOnInit(): void {
    this.revenueReportsService.getAll().then((reports) => {
      this.availableReports = reports;
    });
  }

  downloadAgressoXlsx(report: RevenueReport): void {
    this.revenueReportsService.downloadAgressoXlsx(report.id).then((file) => {
      saveFileAs(file, `Intäktsfil ${report.month}-${report.year}.xlsx`);
    });
  }

  downloadAgressoXml(report: RevenueReport): void {
    this.revenueReportsService.downloadAgressoXml(report.id).then((file) => {
      saveFileAs(file, `Intäktsfil ${report.month}-${report.year}.xml`);
    });
  }

  downloadTeliaXlsx(report: RevenueReport): void {
    this.revenueReportsService.downloadTeliaXlsx(report.id).then((file) => {
      saveFileAs(file, `Intäktsfil Telia ${report.month}-${report.year}.xlsx`);
    });
  }

  downloadTeliaXml(report: RevenueReport): void {
    this.revenueReportsService.downloadTeliaXml(report.id).then((file) => {
      saveFileAs(file, `Intäktsfil Telia ${report.month}-${report.year}.xml`);
    });
  }

  sendToTelia(report: RevenueReport): void {
    this.dialogService.openConfirm(this.sendConfirmDialogContent('Telia')).then(
      () => {
        this.dialogService.openBlocking('Skickar...');
        this.revenueReportsService.sendToTelia(report.id).then(
          () => {
            this.revenueReportsService.get(report.id).then((newReport) => {
              const oldReport = this.availableReports.find((r) => r.id === report.id);
              Object.assign(oldReport, newReport);
            });
            this.dialogService.closeBlocking();
            this.dialogService.openSuccess('Intäktsfilen är nu skickad till Telia.');
          },
          () => {
            this.dialogService.closeBlocking();
            this.dialogService.openError('Intäktsfilen kunde tyvärr inte skickas just nu, försök igen lite senare.');
          },
        );
      },
      function () {}, // eslint-disable-line @typescript-eslint/no-empty-function
    );
  }

  private sendConfirmDialogContent(targetSystem: string): ConfirmDialogContent {
    return {
      header: `Skicka intäktsfil till ${targetSystem}`,
      textBlocks: [
        'En intäktsfil kan bara skickas en gång, du kan ladda ner en Excel-version av filen för att kontrollera innehållet i filen innan du skickar den.',
      ],
      confirmText: 'Skicka nu',
      cancelText: 'Avbryt',
    };
  }
}
