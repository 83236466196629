import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { sum } from '../../../utils/array';
import {
  campaignTotalAdditionalBudget,
  campaignTotalBudget,
  campaignTotalNetBudget,
} from '../../../utils/budget_helpers/campaign_budget_helper';
import {
  targetingAdditionalBudgetWithAgreementPart,
  targetingNetBudgetWithAgreementPart,
} from '../../../utils/budget_helpers/targeting_budget_helper';
import { isDateBefore } from '../../../utils/date';
import { SimpleChanges } from '../../../utils/types';
import { ConfirmDialogContent } from '../../../woo_components.module/dialogs/confirm-dialog.component';
import { CampaignService, ExtendTargetingBudgetRequest } from '../../../woo_services.module/CampaignService';
import { DialogService } from '../../../woo_services.module/DialogService';
import {
  Campaign,
  CampaignBookingJobResult,
  ExtendCampaignBudgetJobResult,
  ExtendedTargetingBudget,
  Targeting,
} from '../../../woo_services.module/shared-types';

@Component({
  selector: 'update-ongoing-campaign-budget',
  templateUrl: './update-ongoing-campaign-budget.component.html',
  styles: ['.label-offset { margin-top: 22.6px;}'],
})
export class UpdateOngoingCampaignBudget implements OnChanges {
  @Input() campaign: Campaign;
  @Output() onAbort = new EventEmitter<void>();
  @Output() onSuccess = new EventEmitter<void>();

  campaignTotalBudget: number;
  campaignTotalNetBudget: number;
  campaignExtraBudget: number;
  targetingData: ExtendTargetingBudget[];

  constructor(private campaignService: CampaignService, private dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges<UpdateOngoingCampaignBudget>): void {
    if (changes.campaign && this.campaign) {
      this.campaignTotalBudget = campaignTotalBudget(this.campaign);
      this.campaignTotalNetBudget = campaignTotalNetBudget(this.campaign);
      this.campaignExtraBudget = campaignTotalAdditionalBudget(this.campaign);

      const todayDate = new Date();
      this.targetingData = this.campaign.targetings.map((t: Targeting, idx) => {
        const agreementPart = this.campaign.agreement.parts[idx];

        return {
          targeting: t,
          endedTargeting: isDateBefore(t.end_date, todayDate),
          netBudget: targetingNetBudgetWithAgreementPart(t, agreementPart),
          extraBudget: targetingAdditionalBudgetWithAgreementPart(t, agreementPart),
          extendBudget: 0,
          extendBudgetMessage: '',
          extendAdditionalBudget: 0,
          extendAdditionalBudgetMessage: '',
          extended_budgets: t.extended_budgets,
        };
      });
    }
  }

  extendCampaignBudget(): void {
    const extendedBudgets = this.targetingData.map(
      (tb: ExtendTargetingBudget): ExtendTargetingBudgetRequest => {
        return {
          id: tb.targeting.id,
          extend_budget: tb.extendBudget,
          extend_budget_message: tb.extendBudgetMessage,
          extend_additional_budget: tb.extendAdditionalBudget,
          extend_additional_budget_message: tb.extendAdditionalBudgetMessage,
        };
      },
    );

    this.extendBudget(extendedBudgets)
      .then(() => {
        this.dialogService.openSuccess('Kampanjens budget är nu utökad');
        this.onSuccess.emit();
      })
      .catch((error: ExtendCampaignBudgetJobResult) => {
        if (
          error.booking_error === 'insufficient_inventory' &&
          (error.over_booked_pause > 0 || error.over_booked_video > 0)
        ) {
          this.dialogService
            .openConfirm(this.forceBudgetExtendOnFull(error))
            .then(() => {
              this.extendBudget(extendedBudgets, true)
                .then(() => {
                  this.dialogService.openSuccess('Kampanjens budget är nu med tvång utökad');
                  this.onSuccess.emit();
                })
                .catch(() => {
                  this.dialogService.openError(error.booking_error);
                  this.abort();
                });
            })
            .catch(() => null);
        } else {
          this.dialogService.openError(error.booking_error);
          this.abort();
        }
      });
  }

  abort = (): void => {
    this.onAbort.emit();
  };

  formBudgetsValid = (): boolean => {
    return sum(this.targetingData.map((data) => data.extendBudget + data.extendAdditionalBudget)) > 0;
  };

  formBudgetAndMessageInvalid = (): boolean => {
    return this.targetingData.some((data) => {
      if (data.extendBudget > 0 && !!!data.extendBudgetMessage) return true;
      if (data.extendAdditionalBudget > 0 && !!!data.extendAdditionalBudgetMessage) return true;
      return false;
    });
  };

  private extendBudget(updateParams: ExtendTargetingBudgetRequest[], force = false) {
    this.dialogService.openBlocking('Utökar kampanjens budget');
    return this.campaignService
      .extendOngoingCampaignBudget(this.campaign.id, updateParams, force)
      .then((response) => {
        this.dialogService.closeBlocking();
        return response;
      })
      .catch((error: CampaignBookingJobResult) => {
        this.dialogService.closeBlocking();
        throw error;
      });
  }

  private forceBudgetExtendOnFull(error: ExtendCampaignBudgetJobResult): ConfirmDialogContent {
    const warningTexts = new Array<string>();
    if (error.over_booked_video > 0) {
      warningTexts.push(`Videoreklamlagret kommer överbokas med ${error.over_booked_video} Bruttokontakter`);
    }
    if (error.over_booked_pause > 0) {
      warningTexts.push(`Pausreklamlagret kommer överbokas med ${error.over_booked_pause} Impressions`);
    }

    return {
      header: 'Får inte plats i lagret',
      textBlocks: ['Budget utökningen får inte plats. Vill du tvinga in de extra visningarna?'],
      warningBlocks: [
        {
          header: 'Varning!',
          textBlocks: warningTexts,
        },
      ],
      confirmText: 'Ja, boka kampanjen med utökad budget ändå.',
      cancelText: 'Avbryt',
    };
  }
}

interface ExtendTargetingBudget {
  targeting: Targeting;
  endedTargeting: boolean;
  netBudget: number;
  extraBudget: number;
  extendBudget: number;
  extendBudgetMessage: string;
  extendAdditionalBudget: number;
  extendAdditionalBudgetMessage: string;
  extended_budgets?: ExtendedTargetingBudget[];
}
