import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SimpleChanges, ToFormGroup } from '../../../utils/types';
import { validateRequired } from '../../../utils/validators';
import { FormatterService } from '../../../woo_services.module/FormatterService';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { AdServerType, Goal } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'goal-additional-impressions-form',
  templateUrl: './goal-additional-impressions-form.component.html',
  styleUrls: ['./goal-additional-impressions-form.component.scss'],
})
export class GoalAdditionalImpressionsForm implements OnChanges {
  @Input() goal: Goal;
  @Input() disabled = false;
  @Input() adserver = '';
  @Output() onValidityChanges = new EventEmitter<boolean>();
  @Output() onValueChanges = new EventEmitter<GoalAdditionalImpressionsFormData>();

  name: string;
  format: string;
  startDate: string | Date;
  endDate: string | Date;
  impressions: number;
  orderViews: number;
  totalBookedInInventory: number;
  totalSentToAdServer: number;
  additionalBudgetImpressions: number;
  mmsGainImpressions: number;
  viewCurrency: string;
  orderViewCurrency: string;
  mms_gain_view_currencies: string[];
  mms_gain_values: string[];

  form: FormGroup<ToFormGroup<GoalAdditionalImpressionsFormData>> = this.fb.group({
    additionalImpressions: new FormControl(null, validateRequired()),
  });

  private get formValues(): GoalAdditionalImpressionsFormData {
    return <GoalAdditionalImpressionsFormData>this.form?.value;
  }

  constructor(
    private fb: FormBuilder,
    private translationService: TranslationService,
    private formatterService: FormatterService,
  ) {
    this.form.valueChanges.subscribe(() => this.onValueChanges.emit(this.formValues));
    this.form.statusChanges.subscribe(() => this.onValidityChanges.emit(this.form.valid));
  }

  ngOnChanges(changes: SimpleChanges<GoalAdditionalImpressionsForm>): void {
    if (changes.goal && this.goal) {
      this.setGoal(this.goal);

      this.name = this.goal.name;
      this.format = this.translationService.goalFormatLabel(this.goal.format);
      this.startDate = this.goal.start_date;
      this.endDate = this.goal.end_date;
      this.viewCurrency = this.translationService.convertViewCurrency(this.goal.ad_server_view_currency);
      this.orderViewCurrency = this.translationService.convertViewCurrency(this.goal.view_currency);
      this.impressions =
        this.goal.impressions +
        this.goal.extended_budget_impressions +
        this.goal.extended_additional_budget_impressions;
      this.orderViews = this.goal.order_views;
      this.additionalBudgetImpressions = this.goal.additional_budget_impressions;
      this.mmsGainImpressions = this.goal.mms_gain_impressions;
      this.totalBookedInInventory =
        this.impressions +
        this.additionalBudgetImpressions +
        this.goal.additional_impressions +
        this.mmsGainImpressions;
      this.totalSentToAdServer = this.totalImpressionsSentToAdServer();
      this.mms_gain_view_currencies = this.mms_gain_view_currencies_labels;
      this.mms_gain_values = this.mms_gain_view_currencies_values;
    }

    if (changes.disabled) {
      if (this.disabled || this.goal.ended) {
        this.form.disable({ onlySelf: true, emitEvent: false });
        if (!changes.goal) {
          this.setGoal(this.goal);
        }
      } else {
        this.form.enable({ onlySelf: true, emitEvent: false });
      }
    }
  }

  totalImpressionsSentToAdServer(): number {
    if (this.adserver === AdServerType.pulse) {
      return this.totalBookedInInventory;
    } else return this.impressions + this.additionalBudgetImpressions + this.goal.additional_impressions;
  }

  setGoal = (goal: Goal): void => {
    if (!goal) {
      return;
    }
    const formValues: GoalAdditionalImpressionsFormData = {
      additionalImpressions: goal.additional_impressions,
    };

    this.form.reset(formValues, { onlySelf: true, emitEvent: false });
  };

  private get mms_gain_view_currencies_labels(): string[] {
    if (this.goal.ad_server_view_currency === this.goal.view_currency) {
      return [this.orderViewCurrency];
    }

    return [this.orderViewCurrency, this.viewCurrency];
  }

  private get mms_gain_view_currencies_values(): string[] {
    const viewValues = [this.formatterService.transformNumber(this.goal.mms_gain_order_views)];
    if (this.goal.ad_server_view_currency === this.goal.view_currency) {
      return viewValues;
    }

    return viewValues.concat([this.formatterService.transformNumber(this.goal.mms_gain_impressions)]);
  }
}

export interface GoalAdditionalImpressionsFormData {
  additionalImpressions: number;
}
