import { Component, Input } from '@angular/core';
import { WooDialogHelper } from '../../woo_components.module/dialogs/woo-dialog-helper';
import { InventoryFilterOption, InventoryService } from '../../woo_services.module/InventoryService';
import { TranslationService } from '../../woo_services.module/TranslationService';
import { ExternalCampaign, ExternalCampaignService } from '../services/ExternalCampaignService';

@Component({
  selector: 'details-dialog',
  templateUrl: './details-dialog.component.html',
})
export class DetailsDialog extends WooDialogHelper {
  @Input() loading = true;
  campaign: ExternalCampaign;
  invalidCampaigns: ExternalCampaign[];
  inventoryTree: any = null;

  filterOptions: InventoryFilterOption[] = [];

  inventoryColumnNames: any = { estimated: 'Estimerat', used: 'Utnyttjat' };
  goalFormatLabel = this.translationService.goalFormatLabel;

  constructor(
    private externalCampaignService: ExternalCampaignService,
    private inventoryService: InventoryService,
    private translationService: TranslationService,
  ) {
    super();
  }

  show(campaign: ExternalCampaign, invalidCampaigns: ExternalCampaign[]): void {
    this.campaign = campaign;
    this.invalidCampaigns = invalidCampaigns;
    if (this.shouldLoadInventory(campaign)) {
      this.loadInventory();
    }
    this.open(false);
  }

  private shouldLoadInventory(campaign: ExternalCampaign) {
    return campaign.reduce_impressions && !this.invalidCampaigns.includes(campaign) && campaign.valid_inventory;
  }

  loadInventory(): void {
    this.inventoryService.getFilterOptions().then((options) => {
      this.filterOptions = options;
    });
    this.externalCampaignService.getInventoryForCampaign(this.campaign.id).then((inventory) => {
      if (inventory?.children?.length) {
        if (inventory.ordered !== inventory.used) {
          this.inventoryColumnNames = { estimated: 'Estimerat', ordered: 'Beställda', used: 'Utnyttjat' };
        } else {
          this.inventoryColumnNames = { estimated: 'Estimerat', used: 'Utnyttjat' };
        }
      }

      this.inventoryTree = this.inventoryService.pruneZerosFromInventoryTree(
        inventory,
        Object.keys(this.inventoryColumnNames),
      );
    });
  }
}
