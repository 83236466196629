import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { setFormDirty } from '../../utils/form';
import { Editable, LayoutProfile, SimpleChanges, Visible } from '../../utils/types';
import { mergeErrors, validateArray, validateUrl, validateUrlPrefix } from '../../utils/validators';
import { CreativeData, CreativeService } from '../../woo_services.module/CreativeService';
import { FileService } from '../../woo_services.module/FileService';
import { FormatterService } from '../../woo_services.module/FormatterService';
import { Creative, ImageCreativeType, wooId } from '../../woo_services.module/shared-types';
import { RadioButtonBlockChoice } from '../radio_button_block/radio-button-block.component';

@Component({
  selector: 'pause-creative-form',
  templateUrl: './pause-creative-form.component.html',
  styleUrls: ['./pause-creative-form.component.scss'],
})
export class PauseCreativeForm implements OnChanges {
  readonly TECHNICAL_SPEC_LINK = 'Bildkrav: jpg, 1135x640 px, max 200 KB';
  readonly IMP_TRACK_URLS_VALIDATORS = mergeErrors(validateUrlPrefix('https'), validateUrl());

  @Input() creative: CreativeData = null;
  @Input() customerId: wooId = null;
  @Input() creatives: Creative[];
  @Input() layoutProfile: PauseAdCreativeFormLayoutProfile = {
    actionButtons: Editable,
    heading: Visible,
  };
  @Output() onAbort = new EventEmitter();
  @Output() onSubmit = new EventEmitter<CreativeData>();
  @Output() onValidityChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<CreativeData>();

  form: FormGroup<FormModel> = this.fb.group<FormModel>(
    {
      name: new FormControl(null),
      imageChoice: new FormControl(PauseImageChoice.One),
      selectedImage: new FormControl(null, () => this.validateSelectedImage()),
      assetUrl: new FormControl(null),
      destinationUrl: new FormControl(null, mergeErrors(validateUrlPrefix('https'), validateUrl())),
      impTrackUrls: new FormControl([], validateArray(this.IMP_TRACK_URLS_VALIDATORS)),
      customText: new FormControl(null),
    },
    { validators: () => this.validateImageChoice },
  );

  PauseImageChoice = PauseImageChoice;
  editing = false;

  getCreativeName = this.formatterService.getCreativeName;

  imageChoices: Array<RadioButtonBlockChoice<PauseImageChoice>> = [
    { value: PauseImageChoice.One, label: 'Ladda upp nu' },
    { value: PauseImageChoice.Zero, label: 'Ladda upp bild senare' },
  ];

  get imageChoice(): FormControl {
    return this.form.controls.imageChoice;
  }

  get selectedImage(): FormControl {
    return this.form.controls.selectedImage;
  }

  get imageCreatives(): Creative[] {
    return this.creatives?.filter((c) => c.creative_type === ImageCreativeType.pauseImage) || [];
  }

  constructor(
    private fb: FormBuilder,
    public fileService: FileService,
    private formatterService: FormatterService,
    private creativeService: CreativeService,
  ) {
    this.selectedImage.valueChanges.subscribe(this.setName);
    this.selectedImage.valueChanges.subscribe(this.setAssetUrl);

    this.imageChoice.valueChanges.subscribe(() => {
      this.selectedImage.updateValueAndValidity();
    });

    this.form.valueChanges.subscribe(() => this.onChange.emit(this.getCreativeData()));
    this.form.statusChanges.subscribe(() => this.onValidityChange.emit(this.form.valid));
  }

  ngOnChanges(changes: SimpleChanges<PauseCreativeForm>): void {
    if (changes.creative && this.creative) {
      this.setData(this.creative);
      this.editing = true;
    }
  }

  setName = (selectedImage?: { name: string; url: string }): void => {
    if (!this.form.value.name && selectedImage) {
      this.form.controls.name.setValue(selectedImage.name);
    }
  };

  setAssetUrl = (selectedImage?: { name: string; url: string }): void => {
    const url =
      this.form.getRawValue().imageChoice === PauseImageChoice.One && selectedImage ? selectedImage.url : null;

    this.form.controls.assetUrl.setValue(url);
    this.form.controls.assetUrl.updateValueAndValidity();
  };

  setData = (creative: CreativeData): void => {
    if (creative) {
      const formValues = {
        name: creative.custom_name,
        destinationUrl: creative.destination_url,
        impTrackUrls: creative.impression_tracking_urls,
        customText: creative.custom_text,
        imageChoice: this.getImageChoice(creative),
        selectedImage: creative.asset_url ? { url: creative.asset_url, name: creative.custom_name } : null,
        assetUrl: creative.asset_url,
      };
      this.form.patchValue(formValues, { onlySelf: true, emitEvent: false });
      this.form.markAsPristine();
    }
  };

  submit = (): void => {
    if (!this.form.valid) {
      setFormDirty(this.form);
      return;
    }
    const value = this.form.value;
    const hasImage = value.imageChoice === PauseImageChoice.One;
    const data = {
      creative_type: ImageCreativeType.pauseImage,
      custom_name: value.name,
      asset_url: hasImage ? value.selectedImage.url : null,
      destination_url: value.destinationUrl,
      impression_tracking_urls: (value.impTrackUrls as string[]).filter(Boolean),
      custom_text: value.customText,
    };
    this.onSubmit.emit(data);
    this.form.reset({ imageChoice: PauseImageChoice.One, impTrackUrls: [] });
  };

  abort = (): void => {
    this.form.reset({ imageChoice: PauseImageChoice.One, impTrackUrls: [] });
    this.onAbort.emit();
  };

  duplicateCreative(index: number): void {
    if (index) {
      const creative = this.creativeService.duplicateCreative(index, this.creatives);
      this.setData(creative);
    }
  }

  private validateImageChoice(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if (this.imageChoice.value === PauseImageChoice.Zero) {
      return null;
    }

    if (this.pauseImageChoiceOneInvalid()) {
      return { url_required: true };
    } else {
      return null;
    }
  }

  private pauseImageChoiceOneInvalid = () => {
    return this.imageChoice.value === PauseImageChoice.One && !this.selectedImage.value;
  };

  private getImageChoice(data: CreativeData): PauseImageChoice {
    if (data.asset_url) {
      return PauseImageChoice.One;
    } else {
      return PauseImageChoice.Zero;
    }
  }

  private validateSelectedImage(): ValidationErrors | null {
    if (!this.form) {
      return null;
    }

    if (this.pauseImageChoiceOneInvalid()) {
      return { required: 'Obligatoriskt' };
    }
    return null;
  }

  private getCreativeData() {
    const value = this.form.value;
    const data = {
      name: value.name,
      asset_url: value.assetUrl,
      custom_name: value.name,
      destination_url: value.destinationUrl,
      impression_tracking_urls: (value.impTrackUrls as string[]).filter(Boolean),
      custom_text: value.customText,
    };
    return data;
  }
}

enum PauseImageChoice {
  Zero = 'zero',
  One = 'one',
}

interface FormModel {
  name: FormControl<string>;
  imageChoice: FormControl<PauseImageChoice>;
  selectedImage: FormControl<{ name: string; url: string }>;
  assetUrl: FormControl<string>;
  destinationUrl: FormControl<string>;
  impTrackUrls: FormControl<string[]>;
  customText: FormControl<string>;
}

export interface PauseAdCreativeFormLayoutProfile {
  actionButtons: LayoutProfile;
  heading: LayoutProfile;
}
