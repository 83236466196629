import { Injectable } from '@angular/core';
import { sum } from '../utils/array';
import { roundDecimalError } from '../utils/math';

@Injectable({ providedIn: 'root' })
export class ChartService {
  readonly tv4Colors = [
    '#ff3334',
    '#f4f2f1',
    '#edebea',
    '#191817',
    '#808080',
    '#616161',
    '#e6e6e6',
    '#a3a3a3',
    '#e5e5e5',
    '#f5f5f5',
    '#cccccc',
    '#e1e1e1',

    '#710923',
    '#fb666a',
    '#fdafaf',
    '#da2c2d',
    '#f99191',
    '#fdafaf',
    '#fff0f0',
    '#ffeaea',
  ];

  readonly lineChartSimple = {
    options: {
      scales: {
        yAxes: [
          {
            display: false,
          },
        ],
        xAxes: [
          {
            display: false,
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0,
        },
      },
    },
    colors: [
      {
        backgroundColor: '#338BB3',
        borderColor: '#338BB3',
      },
    ],
  };

  readonly lineChartDetailed = {
    options: {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 2,
        },
        line: {
          tension: 0,
        },
      },
    },
    colors: [
      {
        backgroundColor: '#338BB3',
        borderColor: '#fefefe',
      },
    ],
  };

  readonly doughnutChart = {
    options: {
      responsive: false,
      legend: {
        display: true,
        position: 'bottom',
        fullWidth: false,
        onClick: function (e: any): void {
          e.stopPropagation();
        },
      },
      colors: this.tv4Colors,
    },
  };

  readonly doughnutChartPercent = {
    options: {
      responsive: false,
      legend: {
        display: false,
        position: 'bottom',
        fullWidth: false,
        onClick: function (e: any): void {
          e.stopPropagation();
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem: any, data: any): string => {
            return this.percentLabels(tooltipItem, data);
          },
        },
      },
      colors: this.tv4Colors,
    },
  };

  readonly doughnutChartPercentWithLegends = {
    options: {
      responsive: false,
      legend: {
        display: true,
        position: 'bottom',
        fullWidth: false,
        onClick: function (e: any): void {
          e.stopPropagation();
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem: any, data: any): string => {
            return this.percentLabels(tooltipItem, data);
          },
        },
      },
      colors: this.tv4Colors,
    },
  };

  readonly doughnutChartSimple = {
    options: {
      tooltips: {
        enabled: false,
      },
    },
    colors: ['#1fbde7', '#f7f7f7'],
  };

  readonly barChart = {
    options: {
      defaultFontSize: 20,
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              fontFamily: 'Domine Regular',
              fontSize: 18,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontFamily: 'Domine Regular',
              fontSize: window.innerWidth < 768 ? 14 : 16,
              autoSkip: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any): string {
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return 'Räckvidd' + ': ' + value + ' %';
          },
        },
      },
      colors: [
        {
          backgroundColor: [
            '#abacab',
            '#abacab',
            '#abacab',
            '#abacab',
            '#1fbde7',
            '#abacab',
            '#abacab',
            '#abacab',
            '#abacab',
            '#abacab',
            '#1fbde7',
          ],
          borderColor: [
            '#abacab',
            '#abacab',
            '#abacab',
            '#abacab',
            '#A3E2FA',
            '#abacab',
            '#abacab',
            '#abacab',
            '#abacab',
            '#abacab',
            '#A3E2FA',
          ],
        },
      ],
    },
  };

  private percentLabels(tooltipItem, data) {
    const dataset = data.datasets[tooltipItem.datasetIndex].data;
    const total = sum(dataset);
    const value = dataset[tooltipItem.index];
    const precentage = roundDecimalError((value / total) * 100, 2);
    const label = data.labels[tooltipItem.index];
    return label + ': ' + precentage + ' %';
  }
}
