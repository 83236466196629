import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEqual } from 'lodash-es';
import { debounceTime, distinctUntilChanged, distinctUntilKeyChanged, filter } from 'rxjs/operators';
import { horizontalSlide } from '../../utils/animations';
import { BookingRulesService } from '../../woo_services.module/BookingRulesService';
import { CustomerService } from '../../woo_services.module/CustomerService';
import { DefaultDataService } from '../../woo_services.module/DefaultDataService';
import { DialogService } from '../../woo_services.module/DialogService';
import { RoutingService } from '../../woo_services.module/RoutingService';
import { SystemService } from '../../woo_services.module/SystemService';
import { TargetingCombinationRulesService } from '../../woo_services.module/TargetingCombinationRulesService';
import { Campaign, wooId } from '../../woo_services.module/shared-types';
import { AdvancedCampaignService } from '../services/AdvancedCampaignService';
import { AnalyticsService } from '../services/AnalyticsService';
import { BookingValidationService } from '../services/BookingValidationService';
import { CachedAdOrganisationService } from '../services/CachedAdOrganisationService';
import { CachedAdvancedTargetGroupService } from '../services/CachedAdvancedTargetGroupService';
import { CachedBookingRulesService } from '../services/CachedBookingRulesService';
import { CachedCategoryService } from '../services/CachedCategoryService';
import { CachedCustomerService } from '../services/CachedCustomerService';
import { CachedDaypartService } from '../services/CachedDaypartService';
import { CachedDeviceGroupService } from '../services/CachedDeviceGroupService';
import { CachedFrequencyLimitService } from '../services/CachedFrequencyLimitService';
import { CachedProductFormatService } from '../services/CachedProductFormatService';
import { CachedProgramFormatService } from '../services/CachedProgramFormatService';
import { CachedPublisherService } from '../services/CachedPublisherService';
import { CachedRegionService } from '../services/CachedRegionService';
import { CachedTargetGroupService } from '../services/CachedTargetGroupService';
import { ScrollContainerService } from '../services/ScrollContainerService';
import { BOOKING_STEPS_ORDER, BookingModel, BookingStep, BookingStore } from '../stores/BookingStore';
import { BookingStoreSelectors } from '../stores/BookingStoreSelectors';
import { InvalidAgencyDialog } from './dialogs/info/invalid-agency-dialog.component';
import { InvoicesAlreadySentDialog } from './dialogs/info/invoices-already-sent-dialog.component';
import { TTVCampaignEditingDialog } from './dialogs/info/ttv-campaign-editing-dialog.component';
import { SubscriptionHandler } from './subscription-handler';

@Component({
  selector: 'advanced-campaign-booking',
  templateUrl: './advanced-campaign-booking.component.html',
  styleUrls: ['./advanced-campaign-booking.component.scss'],
  providers: [
    AdvancedCampaignService,
    AnalyticsService,
    BookingStore,
    BookingRulesService,
    BookingValidationService,
    CachedAdOrganisationService,
    CachedAdvancedTargetGroupService,
    CachedBookingRulesService,
    CachedCategoryService,
    CachedCustomerService,
    CachedDaypartService,
    CachedDeviceGroupService,
    CachedFrequencyLimitService,
    CachedPublisherService,
    CachedProductFormatService,
    CachedProgramFormatService,
    CachedRegionService,
    CachedTargetGroupService,
    ScrollContainerService,
    TargetingCombinationRulesService,
  ],
  animations: [horizontalSlide()],
})
export class AdvancedCampaignBooking extends SubscriptionHandler implements DoCheck, OnInit {
  @ViewChild('footer', { static: true }) footer: ElementRef;

  initFromCampaignId: wooId;
  initFromTemplating: boolean;

  BookingStep = BookingStep;
  footerHeight = 0;
  @ViewChild('scrollContainer', { static: true }) scrollContainerElementRef: ElementRef<HTMLElement>;

  constructor(
    selectors: BookingStoreSelectors,
    private bookingStore: BookingStore,
    private cachedCustomerService: CachedCustomerService,
    private campaignService: AdvancedCampaignService,
    private customerService: CustomerService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private trackingService: AnalyticsService,
    private scrollContainerService: ScrollContainerService,
    private systemService: SystemService,
    private defaultDataService: DefaultDataService,
  ) {
    super();
    this.addSubscription(
      this.bookingStore.state$
        .pipe(
          debounceTime(300),
          filter((model) => selectors.isValid(model)),
          distinctUntilChanged(isEqual, (model) => this.campaignService.getEstimationTriggerValues(model.campaign)),
        )
        .subscribe((model: BookingModel) => campaignService.estimate(model.campaign)),
    );
    this.addSubscription(
      this.bookingStore.state$.pipe(distinctUntilKeyChanged('waitingFor', isEqual)).subscribe(this.updateSpinners),
    );
    this.addSubscription(
      this.bookingStore.state$
        .pipe(distinctUntilChanged(isEqual, (model) => model.activeBookingStep))
        .subscribe(this.scrollToTop),
    );

    this.initFromCampaignId = this.routingService.getParam(this.route, 'id') || this.routingService.getState('id');
    this.initFromTemplating = this.routingService.getState('templating');
  }

  get activeBookingStep(): BookingStep {
    return this.bookingStore.state.activeBookingStep;
  }

  get activeBookingStepIndex(): number {
    return BOOKING_STEPS_ORDER.findIndex((step) => step === this.activeBookingStep);
  }

  ngDoCheck(): void {
    this.setFooterHeight();
  }

  scrollToTop = (): void => {
    if (this.scrollContainerElementRef) {
      this.scrollContainerElementRef.nativeElement.scrollTop = 0;
    }
  };

  async ngOnInit(): Promise<void> {
    this.scrollContainerService.scrollContainer = this.scrollContainerElementRef;
    this.bookingStore.setCampaignStartRoleRestriction(await this.systemService.getValue('min_days_until_start'));
    this.bookingStore.setMinNumberOfDaysUntilStartForCreditWarning(
      await this.systemService.getValue('low_credit_rating_min_days_until_start'),
    );
    this.bookingStore.setAgencyUserMinDaysUpdateCreative(
      await this.systemService.getValue('min_days_to_update_creatives'),
    );
    if (this.initFromCampaignId || this.initFromTemplating) {
      const campaign = await this.loadCampaign();

      this.bookingStore.removeLegacyProducts();

      await this.setAgencyFromCampaign(campaign);
      if (campaign.has_sent_invoices) {
        this.dialogService.create(InvoicesAlreadySentDialog).open();
      }
      if (campaign.ttv_campaign) {
        this.dialogService.create(TTVCampaignEditingDialog).open();
      }
    } else {
      this.bookingStore.setDefaultViewCurrency();
    }

    this.bookingStore.setDefaultTargetingData(await this.defaultDataService.getTargetingData());
    if (this.initFromCampaignId && !this.initFromTemplating) {
      this.bookingStore.setCurrentGrossRatingFactor();
    }

    this.bookingStore.state.campaign.targetings.forEach((targeting) => {
      this.campaignService.setDefaultPublisherForTargeting(targeting.id);
    });
    this.trackingService.openedBookingFlow();
  }

  updateSpinners = (model: BookingModel): void => {
    if (model.waitingFor.bookCampaign) {
      this.dialogService.openBlocking('Bokar kampanj...');
    } else if (model.waitingFor.loadCampaign) {
      this.dialogService.openBlocking('Laddar kampanj...');
    } else if (model.waitingFor.sendingCampaignToAdserver) {
      this.dialogService.openBlocking('Skickar till adservern...');
    } else if (model.waitingFor.saveCampaign) {
      this.dialogService.openBlocking('Sparar kampanj...');
    } else {
      this.dialogService.closeBlocking();
    }
  };

  setFooterHeight = (): void => {
    this.footerHeight = this.footer.nativeElement.offsetHeight;
  };

  private async setAgencyFromCampaign(campaign: Campaign): Promise<void> {
    if (!campaign.agency_id) {
      return;
    }
    const customer = await this.cachedCustomerService.getCustomer(campaign.customer_id);
    const validAgreements = this.customerService.getValidAgreements(customer.partner_agreements);
    const agreement = validAgreements.find((p) => p.agency.id === campaign.agency_id);
    if (agreement) {
      this.bookingStore.setAgency(agreement.agency, new Date(agreement.valid_from), false);
    } else {
      this.dialogService.create(InvalidAgencyDialog).open();
    }
  }

  private loadCampaign = (): Promise<Campaign> => {
    if (this.initFromTemplating) {
      return this.campaignService.template(this.initFromCampaignId);
    } else {
      return this.campaignService.load(this.initFromCampaignId);
    }
  };
}
