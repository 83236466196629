import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { sumBy } from '../utils/array';
import { formatWooDate, getMaxPeriod } from '../utils/date';
import { generateId, removeGeneratedId } from '../utils/string';
import { CreativeData } from './CreativeService';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService } from './JobService';
import { Placement } from './PlacementService';
import { TargetingService } from './TargetingService';
import {
  AdServerStatus,
  AgencySelfServiceDetails,
  Campaign,
  CampaignBookingJobResult,
  CampaignEstimation,
  CampaignStatus,
  CompactCampaign,
  Creative,
  ExtendCampaignBudgetJobResult,
  ImageCreativeType,
  Targeting,
  UpdateGoalsJobParams,
  UpdateGoalsJobResult,
  VideoCreativeType,
  ViewCurrency,
  wooId,
} from './shared-types';

@Injectable({ providedIn: 'root' })
export class CampaignService {
  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private jobService: JobService,
    private targetingService: TargetingService,
  ) {}

  get = (id: wooId): Promise<Campaign> => {
    return lastValueFrom(this.http.get<Campaign>(`${this.env.apiUrl}/campaigns/${id}`).pipe(tap(this.parseDates)));
  };

  estimate = (campaign: Campaign): Promise<CampaignEstimation> => {
    return lastValueFrom(
      this.http.post<CampaignEstimation>(`${this.env.apiUrl}/campaigns/estimated_budget_and_impressions`, {
        campaign: this.prepareCampaign(campaign, false),
      }),
    );
  };

  downloadSummary = (campaign: Campaign): Promise<HttpResponse<Blob>> => {
    return lastValueFrom(
      this.http.post(
        `${this.env.apiUrl}/campaigns/excel_summary`,
        { campaign: this.prepareCampaign(campaign, false) },
        {
          observe: 'response',
          responseType: 'blob' as 'json',
        },
      ),
    ) as Promise<HttpResponse<Blob>>;
  };

  exportBookingConfirmation = (campaign: Campaign): Promise<Blob> => {
    return lastValueFrom(
      this.http.get<Blob>(`${this.env.apiUrl}/campaigns/${campaign.id}/export_booking_confirmation`, {
        responseType: 'blob' as 'json',
      }),
    );
  };

  create = (campaign: Campaign): Promise<Campaign> => {
    return lastValueFrom(
      this.http
        .post<Campaign>(`${this.env.apiUrl}/customers/${campaign.customer_id}/campaigns`, {
          campaign: this.prepareCampaign(campaign),
        })
        .pipe(tap(this.parseDates)),
    );
  };

  update = (campaign: Campaign): Promise<Campaign> => {
    return lastValueFrom(
      this.http.patch<Campaign>(`${this.env.apiUrl}/campaigns/${campaign.id}/update_unbooked`, {
        campaign: this.prepareCampaign(campaign),
      }),
    );
  };

  createAndBook = async (campaign: Campaign, force: boolean): Promise<CampaignBookingJobResult> => {
    return this.jobService.waitForJobCompletion(
      (
        await lastValueFrom(
          this.http.post<Job>(`${this.env.apiUrl}/customers/${campaign.customer_id}/campaigns/create_and_book`, {
            campaign: this.prepareCampaign(campaign),
            force,
          }),
        )
      ).id,
    );
  };

  updateAndBook = async (campaign: Campaign, force: boolean): Promise<CampaignBookingJobResult> => {
    return this.jobService.waitForJobCompletion(
      (
        await lastValueFrom(
          this.http.patch<Job>(`${this.env.apiUrl}/campaigns/${campaign.id}/update_and_book`, {
            campaign: this.prepareCampaign(campaign),
            force,
          }),
        )
      ).id,
    );
  };

  updateBooked = async (campaign: Campaign, force: boolean): Promise<CampaignBookingJobResult> => {
    return this.jobService.waitForJobCompletion(
      (
        await lastValueFrom(
          this.http.patch<Job>(`${this.env.apiUrl}/campaigns/${campaign.id}/update_booked`, {
            campaign: this.prepareCampaign(campaign),
            force,
          }),
        )
      ).id,
    );
  };

  updateOngoingCampaignName = (campaignId: wooId, newName: string): Promise<void> => {
    return lastValueFrom(
      this.http.patch<void>(`${this.env.apiUrl}/campaigns/${campaignId}/update_ongoing_campaign_name`, {
        campaign: { name: newName },
      }),
    );
  };

  extendTargeting = (campaignId: wooId, targetingId: wooId, newEndDate: Date): Promise<Campaign> => {
    return lastValueFrom(
      this.http.patch<Campaign>(`${this.env.apiUrl}/campaigns/${campaignId}/targetings/${targetingId}/extend`, {
        new_end_date: formatWooDate(newEndDate),
      }),
    );
  };

  extendOngoingCampaignBudget = async (
    campaignId: wooId,
    extendedTargetingBudgets: ExtendTargetingBudgetRequest[],
    force: boolean,
  ): Promise<ExtendCampaignBudgetJobResult> => {
    return this.jobService.waitForJobCompletion(
      (
        await lastValueFrom(
          this.http.patch<Job>(`${this.env.apiUrl}/campaigns/${campaignId}/extend_campaign_budget`, {
            campaign: { targetings: extendedTargetingBudgets },
            force,
          }),
        )
      ).id,
    );
  };

  addCreativeToOngoingCampaign = (
    campaignId: wooId,
    creative: CreativeData,
    placements: Placement[],
  ): Promise<Campaign> => {
    return lastValueFrom(
      this.http.post<Campaign>(`${this.env.apiUrl}/campaigns/${campaignId}/add_creative_to_ongoing_campaign`, {
        creative: creative,
        placements: placements,
      }),
    );
  };

  cancel = (campaignId: wooId, comment: string): Promise<Campaign> => {
    return lastValueFrom(
      this.http.delete<Campaign>(`${this.env.apiUrl}/campaigns/${campaignId}/cancel`, {
        params: { comment },
      }),
    );
  };

  removeUnbooked = (campaignId: wooId): Promise<void> => {
    return lastValueFrom(this.http.delete<void>(`${this.env.apiUrl}/campaigns/${campaignId}`));
  };

  removeEnded = (campaignId: wooId): Promise<void> => {
    return lastValueFrom(this.http.delete<void>(`${this.env.apiUrl}/campaigns/${campaignId}/archive`));
  };

  close = (campaignId: wooId, closedMessage: string): Promise<Campaign> => {
    return lastValueFrom(
      this.http.patch<Campaign>(`${this.env.apiUrl}/campaigns/${campaignId}/close`, {
        closed_message: closedMessage,
      }),
    );
  };

  stop = (campaignId: wooId, comment: string, newOrderValue: number): Promise<Campaign> => {
    return lastValueFrom(
      this.http.delete<Campaign>(`${this.env.apiUrl}/campaigns/${campaignId}/stop`, {
        params: {
          comment: comment,
          new_order_value: newOrderValue.toString(),
        },
      }),
    );
  };

  updateCreatives = (campaignId: wooId, targetingId: wooId, creatives: Creative[]): Promise<Creative[]> => {
    return lastValueFrom(
      this.http.patch<Creative[]>(`${this.env.apiUrl}/campaigns/${campaignId}/targetings/${targetingId}/creatives`, {
        creatives: this.cleanCreatives(creatives),
      }),
    );
  };

  updateOngoingCreatives = async (campaignId: wooId, targetingId: wooId, creatives: Creative[]): Promise<void> => {
    return this.jobService.waitForJobCompletion(
      (
        await lastValueFrom(
          this.http.patch<Job>(
            `${this.env.apiUrl}/campaigns/${campaignId}/targetings/${targetingId}/creatives_ongoing`,
            {
              creatives: this.cleanCreatives(creatives),
            },
          ),
        )
      ).id,
    );
  };

  updateGoals = async (params: UpdateGoalsJobParams): Promise<UpdateGoalsJobResult> => {
    return this.jobService.waitForJobCompletion(
      (await lastValueFrom(this.http.patch<Job>(`${this.env.apiUrl}/campaigns/${params.campaign_id}/goals`, params)))
        .id,
    );
  };

  updateAgencySelfServiceCompensation = (
    campaignId: wooId,
    agencySelfServiceDetails: AgencySelfServiceDetails,
  ): Promise<Campaign> => {
    return lastValueFrom(
      this.http.patch<Campaign>(
        `${this.env.apiUrl}/campaigns/${campaignId}/agency_self_service_compensation`,
        agencySelfServiceDetails,
      ),
    );
  };

  updateCampaignDefaultFrequencyLimit = (campaignId: wooId, active: boolean): Promise<Campaign> => {
    return lastValueFrom(
      this.http.patch<Campaign>(`${this.env.apiUrl}/campaigns/${campaignId}/default_video_frequency_limit`, {
        default_video_frequency_limit: active,
      }),
    );
  };

  updateCampaignAutomaticMmsGain = async (campaignId: wooId, active: boolean): Promise<UpdateGoalsJobResult> => {
    return this.jobService.waitForJobCompletion(
      (
        await lastValueFrom(
          this.http.patch<Job>(`${this.env.apiUrl}/campaigns/${campaignId}/automatic_mms_gain`, {
            automatic_mms_gain: active,
          }),
        )
      ).id,
    );
  };

  reviewCampaign = (
    campaignId: wooId,
    reviewStatus: 'rejected' | 'approved',
    rejectMessage: string,
  ): Promise<Campaign> => {
    return lastValueFrom(
      this.http.patch<Campaign>(`${this.env.apiUrl}/campaigns/${campaignId}/review`, {
        campaign: { review_status: reviewStatus, reject_message: rejectMessage },
      }),
    );
  };

  getEmptyCampaign = (): Campaign => {
    return {
      ad_hoc_contracts: [],
      ad_organisation_external: false,
      ad_organisation_id: null,
      ad_organisation_name: '',
      ad_server_status: AdServerStatus.unsent,
      additional_goal_impressions: 0,
      additional_info: '',
      adjusted_goal_impressions: 0,
      adjusted_goal_order_views: 0,
      agency_id: null,
      agency_self_service_details: null,
      agresso_invoice_token: '',
      automatic_mms_gain: true,
      mms_tracked: null,
      booked_by: null,
      booked_date: null,
      client_invoice_reference: '',
      contracts: [],
      created_at: null,
      customer_id: '',
      customer_name: '',
      enabled: false,
      has_sent_invoices: false,
      id: generateId(),
      ttv_id: null,
      impressions_to_deliver: 0,
      low_credit_rating: false,
      mms_gain_impressions: 0,
      mms_gain_order_views: 0,
      mms_gain: 0,
      name: '',
      outcome_up_to_date: true,
      reference_number: '',
      status: CampaignStatus.unbooked,
      targetings: [],
      temporary_discounts: [],
      ttv_campaign: false,
      mema_campaign: false,
      updated_at: null,
      view_currency: ViewCurrency.completedViews,
      cash_campaign: false,
      gambling_campaign: false,
      political_campaign: false,
      inventory_only_campaign: false,
      editable: true,
      order_value: null,
      voucher_ids: [],
      new_order_value: null,
      default_video_frequency_limit: true,
      new_contracts: null,
      use_new_contract: false,
    };
  };

  stripCampaignForTemplating = (campaign: Campaign): Campaign => {
    this.stripCampaignVariblesForTemplating(campaign);
    this.stripTargetingsForTemplating(campaign);
    this.stripCreativesForTemplating(campaign);
    return campaign;
  };

  private stripCampaignVariblesForTemplating(campaign: Campaign): void {
    campaign.duplicated_id = campaign.id;
    campaign.id = generateId();
    campaign.name = '';
    campaign.status = CampaignStatus.unbooked;
    campaign.temporary_discounts = [];
    campaign.contracts = [];
    campaign.ad_hoc_contracts = [];
    campaign.has_sent_invoices = false;
    campaign.voucher_ids = [];
  }

  private stripTargetingsForTemplating(campaign: Campaign) {
    campaign.targetings.forEach(this.targetingService.stripTargeting);
  }

  private stripCreativesForTemplating(campaign: Campaign) {
    function reset(creatives: Creative[]) {
      creatives.forEach((creative) => {
        creative.segments = [];
        creative.id = generateId();
        if (
          creative.creative_type === VideoCreativeType.ag ||
          creative.creative_type === ImageCreativeType.pauseImage
        ) {
          return;
        }

        creative.asset_url = null;
      });
    }

    campaign.targetings.forEach((targeting) => reset(targeting.creatives));
  }

  getCampaignGrossRatingFactor(campaign: Campaign): number | undefined {
    const targeting = campaign.targetings.find((targeting) => !!targeting.gross_rating_factor);
    return targeting?.gross_rating_factor;
  }

  getCompactCampaign(campaign: Campaign): CompactCampaign {
    const sumTargetingsFields = (field: keyof Targeting) => (curr: number, t: Targeting) => curr + Number(t[field]);
    const campaignPeriod = this.getCampaignPeriod(campaign);

    const compactCampaign: CompactCampaign = [
      'ad_server_status',
      'additional_goal_impressions',
      'adjusted_goal_impressions',
      'adjusted_goal_order_views',
      'cash_campaign',
      'gambling_campaign',
      'customer_id',
      'customer_name',
      'has_sent_invoices',
      'id',
      'impressions_to_deliver',
      'mms_gain_impressions',
      'mms_gain_order_views',
      'name',
      'status',
      'ttv_campaign',
      'view_currency',
      'closed_message',
      'external_id',
      'status_comment',
    ].reduce((obj, field) => Object.assign(obj, { [field]: campaign[field] }), {} as CompactCampaign);

    return Object.assign(compactCampaign, {
      budget: campaign.targetings.reduce(sumTargetingsFields('budget'), 0),
      end_date: formatWooDate(campaignPeriod.end),
      start_date: formatWooDate(campaignPeriod.start),
      budgeted_impressions: campaign.targetings.reduce(sumTargetingsFields('budgeted_impressions'), 0),
      creatives: campaign.targetings.flatMap((t) => t.creatives),
    });
  }

  getBudgetFields = (campaign: Campaign, targeting: Targeting): Record<string, any> => {
    return {
      budget: sumBy(campaign.targetings, 'budget'),
      budgeted_impressions: sumBy(campaign.targetings, 'budgeted_impressions'),
      additional_budget: sumBy(campaign.targetings, 'additional_budget'),
      additional_budgeted_impressions: sumBy(campaign.targetings, 'additional_budgeted_impressions'),
      calculate_from_budget: targeting.calculate_from_budget,
      additional_budget_message: targeting.additional_budget_message,
      send_invoices: targeting.send_invoices,
      sales_order_number: targeting.sales_order_number,
      invoice_disable_message: targeting.invoice_disable_message,
      includes_linear: targeting.includes_linear,
      frequency_limit: targeting.frequency_limit,
    };
  };

  campaignHasVideoCreatives = (creatives: Creative[]): boolean => {
    return creatives.some((creative) =>
      Object.values(VideoCreativeType).includes(creative.creative_type as VideoCreativeType),
    );
  };

  cleanCreatives = (creatives: Creative[], clearGeneratedId?: boolean): Creative[] => {
    creatives.forEach((c) => this.cleanCreative(c, clearGeneratedId));
    return creatives;
  };

  cleanCreative = (creative: Creative | (Creative & Record<string, any>), clearGeneratedId?: boolean): Creative => {
    if (!creative.click_tracking_url?.length) creative.click_tracking_url = null;
    if (!creative.destination_url?.length) creative.destination_url = null;
    if (!creative.impression_tracking_urls?.length) creative.impression_tracking_urls = [];
    if (!creative.asset_url?.length) creative.asset_url = null;

    if (
      creative.creative_type === 'peach' &&
      (!creative.video_code?.length || !creative.asset_url?.includes(creative.video_code))
    ) {
      creative.asset_url = null;
      creative.video_title = null;
    }
    creative.segments.forEach((segment) => {
      segment.start_date = format(new Date(segment.start_date), 'yyyy-MM-dd');
      segment.end_date = format(new Date(segment.end_date), 'yyyy-MM-dd');
    });
    if (clearGeneratedId) {
      removeGeneratedId(creative);
    }
    return creative;
  };

  cleanTargetings = (targetings: Targeting[], clearGeneratedId?: boolean): void => {
    targetings.forEach((targeting) => {
      targeting.start_date = format(new Date(targeting.start_date), 'yyyy-MM-dd');
      targeting.end_date = format(new Date(targeting.end_date), 'yyyy-MM-dd');
      if (clearGeneratedId) {
        removeGeneratedId(targeting);
      }
    });
  };

  hasAnyStatus = (campaign: Campaign, statuses: CampaignStatus[]): boolean => statuses.includes(campaign.status);

  isFollowup = (campaign: Campaign): boolean =>
    this.hasAnyStatus(campaign, [
      CampaignStatus.ended,
      CampaignStatus.ongoing,
      CampaignStatus.closed,
      CampaignStatus.stopped,
    ]);

  private parseDates = (campaign: Campaign): Campaign => {
    campaign.targetings = campaign.targetings.map((targeting) => ({
      ...targeting,
      start_date: new Date(targeting.start_date),
      end_date: new Date(targeting.end_date),
    }));

    campaign.targetings
      .flatMap((t) => t.creatives)
      .forEach(
        (creative) =>
          (creative.segments = creative.segments.map((seg) => ({
            ...seg,
            start_date: new Date(seg.start_date),
            end_date: new Date(seg.end_date),
          }))),
      );
    return campaign;
  };

  private prepareCampaign = (campaign: Campaign, clearGeneratedId = true): Campaign => {
    const clone = cloneDeep(campaign);
    removeGeneratedId(clone); // Always remove campaign id before sending to API
    this.cleanCreatives(
      clone.targetings.flatMap((t) => t.creatives),
      clearGeneratedId,
    );
    this.cleanTargetings(clone.targetings, clearGeneratedId);
    this.addFrequencyLimitId(clone);
    return clone;
  };

  private addFrequencyLimitId(campaign: Campaign) {
    campaign.targetings.forEach((t) =>
      Object.assign(t, t.frequency_limit ? { frequency_limit_id: t.frequency_limit.id } : { frequency_limit_id: null }),
    );
  }

  getCampaignPeriod = (campaign: Campaign): { start: Date; end: Date } => getMaxPeriod(campaign.targetings);
}

export interface ExtendTargetingBudgetRequest {
  id: wooId;
  extend_budget: number;
  extend_budget_message: string;
  extend_additional_budget: number;
  extend_additional_budget_message: string;
}
