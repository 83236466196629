export function ignore422(res: { status: number }): void {
  if (res.status !== 422) {
    throw res;
  }
}

export function ignore404(res: { status: number }): void {
  if (res.status !== 404) {
    throw res;
  }
}

export function ignoreClosedDialog(obj: any): void {
  if (![null, undefined, '', '$document', '$closeButton', '$escape'].includes(obj)) {
    throw obj;
  }
}

export function calculateCompletedViews(budget: number, cpcv: number): number {
  return Math.round((budget * 1000) / cpcv);
}

export function calculateBudget(completedViews: number, cpcv: number): number {
  return (completedViews * cpcv) / 1000;
}

export function calculateCPCV(completedViews: number, budget: number): number {
  return (budget * 1000) / completedViews;
}

export function calculateTRP(views: number, universe: number): number {
  if (universe && universe > 0) {
    return (views * 100) / universe;
  } else {
    return 0;
  }
}
