import { Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { saveFileAs } from '../../../utils/files';
import { compareWith } from '../../../utils/object';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import {
  AgencySummary,
  SelfServiceCompensationReport,
  SelfServiceCompensationService,
  Status,
} from '../../../woo_services.module/SelfServiceCompensationService';
import { wooId } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'self-service-compensation',
  templateUrl: './self-service-compensation.component.html',
  styleUrls: ['./self-service-compensation.component.scss'],
})
export class SelfServiceCompensation implements OnInit {
  agencySummaries: AgencySummary[] = [];
  filteredAgencySummaries: AgencySummary[] = [];
  activeFilter: string;
  readonly isAdmin = this.authService.admin();
  readonly isAdminOrPlannerOrAccounting = this.authService.adminPlannerOrAccounting();
  readonly isAdminOrPlannerOrAccountingOrSalesManager =
    this.isAdminOrPlannerOrAccounting || this.authService.hasRole(UserRole.salesManager);
  readonly statuses = [
    { statusKey: Status.notHandled, statusValue: 'Ej hanterad' },
    { statusKey: Status.invoiced, statusValue: 'Fakturerad' },
    { statusKey: Status.writtenDown, statusValue: 'Nedskriven' },
  ];

  constructor(
    private authService: AuthService,
    private selfServiceCompensationService: SelfServiceCompensationService,
  ) {}

  ngOnInit(): void {
    this.selfServiceCompensationService.getAgencyReports().then((agencySummaries) => {
      this.agencySummaries = agencySummaries.sort(compareWith('name'));
      this.filterChange(null);
    });
  }

  setStatus(report: SelfServiceCompensationReport, status: Status): void {
    this.selfServiceCompensationService
      .updateStatus(report.id, status)
      .catch(() => (report.status = Status.notHandled));
    this.agencySummaries.flatMap((summary) => summary.reports).find((r) => r.id === report.id).status = status;
  }

  filterChange(status: Status): void {
    this.filteredAgencySummaries = cloneDeep(this.agencySummaries);
    this.filteredAgencySummaries.map((summary) => {
      summary.reports = summary.reports.filter((report) => report.status === status || status === null);
      return summary;
    });
  }

  export(id: wooId, agencyName: string, year: string | number, quarter: string | number): void {
    this.selfServiceCompensationService.getAgencyReportExport(id).then((file) => {
      saveFileAs(file, 'Administrationsersättning ' + agencyName + ' ' + year + '-' + quarter + '.xlsx');
    });
  }

  exportSummary(): void {
    this.selfServiceCompensationService.getSummaryReport().then((file) => {
      saveFileAs(file, 'Administrationsersättningsrapport.xlsx');
    });
  }

  exportCampaignList(): void {
    this.selfServiceCompensationService.getCampaignListReport().then((file) => {
      saveFileAs(file, 'Administrationsersättnings_Kampanjer.xlsx');
    });
  }
}
