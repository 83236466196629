import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StatusService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getStatus(): Promise<Status> {
    return lastValueFrom(this.http.get<Status>(`${this.env.apiUrl}/status`));
  }

  updateCRMStatus(): Promise<void> {
    return lastValueFrom(this.http.get<void>(`${this.env.apiUrl}/status/update_crm_status`));
  }
}

export interface Status {
  metrics: StatusMetric[];
}

export interface StatusMetric {
  id: string;
  name: string;
  value: number;
  description: string;
  status: 'red' | 'yellow' | 'green';
  details: string;
  admin_action?: string;
  planner_action?: string;
  task?: string;
  updated: string;
}
