import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { ProductChoice, RuleSelection, TargetingType } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class BookingRulesService {
  apiVariableNames = {
    video_ad: TargetingType.instream,
    pause_ad: TargetingType.pause,
    program_format: ProductChoice.program,
    device_group: ProductChoice.device,
    advanced_target_group: ProductChoice.advancedTargetGroup,
    gender: ProductChoice.targetGroup,
    age: ProductChoice.targetGroup,
    gender_and_age: ProductChoice.targetGroup,
  };
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  getBookingRules(): Promise<string[][]> {
    return lastValueFrom(this.http.get<string[][]>(`${this.env.apiUrl}/valid_product_list`));
  }
  async getAndConvertBookingRulesAbf(): Promise<RuleSelection[][]> {
    return (await this.getBookingRules()).map((row) =>
      row.map((value) => (value in this.apiVariableNames ? this.apiVariableNames[value] : value)),
    );
  }
}
