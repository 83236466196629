  <div *ngIf="isSingleChoiceFilter()" class="single-choice-filter">
    <label class="ui-select-label">{{translate(filterOption.filter_key)}}</label>
    <woo-dropdown
      [items] = "filterOption.values"
      [(ngModel)]="selected.singleChoiceValue"
      (ngModelChange)="notify()"
      placeholder="Filtrera..."
    ></woo-dropdown>
  </div>
  <div class="multiple-choice-filter" *ngIf="isMultiChoiceFilter()">
    <label class="ui-select-label">{{translate(filterOption.filter_key)}}</label>
    <woo-dropdown
      [items] = "filterOption.values"
      [(ngModel)]="selected.multiChoiceValue"
      (ngModelChange)="notify()"
      [multiple]="true"
      placeholder="Filtrera..."
    ></woo-dropdown>
  </div>
  <div class="" *ngIf="isSingleChoiceFilterAndRequired()">
    <div class="filter-option-label">{{translate(filterOption.filter_key)}}</div>
    <div>
      <input id="inventory-filter-radio-{{filterOption.filter_key}}-1" name="group-{{filterOption.filter_key}}" type="radio"
      [(ngModel)]="selected.singleChoiceRequiredValue" [value]="filterOption.values[0]" (change)="notify()" class="radio">
      <label for="inventory-filter-radio-{{filterOption.filter_key}}-1" class="radio">
        {{translate(filterOption.values[0].name)}}
      </label>
    </div>
    <div>
      <input id="inventory-filter-radio-{{filterOption.filter_key}}-2" name="group-{{filterOption.filter_key}}" type="radio"
      [(ngModel)]="selected.singleChoiceRequiredValue" [value]="filterOption.values[1]" (change)="notify()" class="radio">
      <label for="inventory-filter-radio-{{filterOption.filter_key}}-2" class="radio">
        {{translate(filterOption.values[1].name)}}
      </label>
    </div>
  </div>
