<div class="padding--4x shadow-z-1 background-color--white margin-top--3x">
  <h3 class="margin-top--none">Öka eller minska mål</h3>
  <campaign-mms-gain-form
    [campaign]="campaign"
    (onValidityChanges)="onMmsFormValidityChange($event)"
    (onValueChanges)="onMmsFormChange($event)"
  ></campaign-mms-gain-form>
  <div>
    <button class="button" (click)="updateCampaign()" [disabled]="!canSave">Spara</button>
    <button class="button secondary" (click)="abort()">Avbryt</button>
  </div>
</div>

<div *ngFor="let targeting of campaign.targetings" class="padding--3x shadow-z-1 background-color--white margin-top--3x">
  <expandable-targeting-header [targeting]="targeting">
    <div class="row">
      <div
        *ngFor="let goal of targeting.goals"
        class="col-xs-12 col-md-3"
      >
        <goal-additional-impressions-form
          [goal]="goal"
          [adserver]="adserver"
          (onValidityChanges)="onGoalFormValidityChange(targeting.id, goal.id, $event)"
          (onValueChanges)="onGoalFormChange(targeting.id, goal.id, $event)"
        ></goal-additional-impressions-form>
      </div>
    </div>
  </expandable-targeting-header>
</div>
<div class="padding--2x">
  <button class="button" (click)="updateCampaign()" [disabled]="!canSave">Spara</button>
  <button class="button secondary" (click)="abort()">Avbryt</button>
</div>
