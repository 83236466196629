<div class="inner-block animate">
  <dot-loader [visibility]="!user"></dot-loader>
  <div class="row">
    <div class="col-xs-12">
      <div class="account__card eq" *ngIf="user?.email">
        <h2 class="margin--none">{{user.first_name}} {{user.last_name}}</h2>
        <div class="card__info">
          <h3 *ngIf="!user.allowed_to_sign_in">Användaren är borttagen</h3>
          <h3 class="text--medium">Email</h3>
          <p>{{user.email}}</p>
          <div *ngIf="role.translatedName">
            <h3 class="text--medium">Roll</h3>
            <p>{{role.translatedName}}</p>
          </div>
          <div
            *ngIf="role.name === 'external_planner' || role.name === 'planner' || role.name === 'sales' || role.name === 'sales_manager'">
            <h3 class="text--medium">Organisation</h3>
            <p>{{user.ad_organisation.name}}</p>
          </div>
          <div *ngIf="user.allowed_to_sign_in">
            <button *ngIf="hasAnyRole([UserRole.admin, UserRole.planner, UserRole.agencyAdmin])" class="link primary-1" (click)="toggleEdit()">Redigera</button>
            <button *ngIf="hasAnyRole([UserRole.admin, UserRole.agencyAdmin])" class="link secondary" (click)="resetPassword(user.id)">Återställ lösenord</button>
            <button *ngIf="hasAnyRole([UserRole.admin, UserRole.agencyAdmin])" class="link danger" (click)="deactivateUser(user)">Ta bort användare</button>
          </div>
          <button *ngIf="canReactivateAccount()" class="link danger" (click)="reactivateUser(user)">Återaktivera användare</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="role.name === 'client_user' || role.name === 'cash_user'">
    <div class="col-xs-12">
      <div class="tabbable__panel clearfix shadow-z-1">
        <div class="col-xs-12">
          <h3>Kund</h3>
        </div>
      </div>
      <div class="account__card">
        <h4 *ngIf="clientUserCustomer">
          <a [routerLink]="['/customer', clientUserCustomer.id]">{{clientUserCustomer.name}}</a>
        </h4>
        <h4
          *ngIf="cashUserCustomer">
          <a [routerLink]="['/customer', cashUserCustomer.id]">{{cashUserCustomer.name}}</a>
        </h4>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="role.name === 'agency' && (hasAccountHandlingRole() || isAgencyAdmin(currentUser))">
    <div class="col-xs-12">
      <div class="tabbable__panel clearfix shadow-z-1">
        <div class="col-xs-12">
          <h3>Redigera kundtillhörighet</h3>
        </div>
      </div>
      <div class="account__card">
        <edit-user-customer-access [user]="user" (refresh)="refresh()"></edit-user-customer-access>
      </div>
    </div>
  </div>
  <div #editUserSection class="row">
    <div class="block">
      <div class="col-xs-12">
        <div class="account__card" *ngIf="editUser">
          <div class="card__cover"></div>
          <user-form (afterSubmit)="userUpdated()" (onAbort)="toggleEdit()" [user]="user"></user-form>
        </div>
      </div>
    </div>
  </div>
</div>
