import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './EnvironmentService';
import { FrequencyLimit, SlotType, wooId } from './shared-types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FrequencyLimitService {
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  getAll(): Promise<FrequencyLimit[]> {
    return lastValueFrom(this.http.get<FrequencyLimit[]>(`${this.env.apiUrl}/frequency_limits`));
  }

  updateWithdrawalLimitsByProductFormat(
    id: wooId,
    ProductFormatWithdrawalLimits: ProductFormatWithdrawalLimitsParams[],
  ): Promise<void> {
    return lastValueFrom(
      this.http.patch<void>(`${this.env.apiUrl}/frequency_limits/${id}/withdrawal_limits_by_product_format`, {
        product_formats: ProductFormatWithdrawalLimits,
      }),
    );
  }
}

export interface ProductFormatWithdrawalLimitsParams {
  product_format_id: wooId;
  withdrawal_limits: Array<{ slot_type: SlotType; withdrawal_limit: number }>;
}
