import { ViewChildren } from '@angular/core';

/**
 *
 * Works as @ViewChildren but it accepts multiple "read" options.
 * The result is a list of tuples where each item corresponds to a ViewChild defined by the selector
 *   and each entry in the tuples is that ViewChild run through the corresponding reader.
 *
 * @example
 * MultiViewChildren(Component, {read: [Component, ElementRef]}) props;
 * =>
 * [[comp1: Component, comp1: ElementRef], [comp2: Component, comp2: ElementRef], ...]
 *
 * @param selector working in the same way as first argument for ViewChildren
 * @param opts working in the same way as options for ViewChildren but it accepts multiple read values
 */
export function MultipleViewChildren(selector: any, opts: { read: any[] }): (target: any, name: string) => void {
  const viewChildrenDecorators = opts.read.map((x) => ViewChildren(selector, { read: x }));

  /**
   * @param target The component in which MultipleViewChildren is used.
   * @param name The name of the property on which MultipleViewChildren is used.
   */
  return function (target: any, name: string) {
    viewChildrenDecorators.forEach((decorator, i) => decorator(target, `${name}_${i}`));

    Object.defineProperty(target, name, {
      get: function () {
        const querySets = viewChildrenDecorators.map((_, i) => this[`${name}_${i}`].toArray(), this);
        return querySets[0].map((_, i) => querySets.map((set) => set[i]));
      },
    });
  };
}
