import { Injectable } from '@angular/core';
import { FrequencyLimitService } from '../../woo_services.module/FrequencyLimitService';
import { FrequencyLimit } from '../../woo_services.module/shared-types';

@Injectable()
export class CachedFrequencyLimitService implements Pick<FrequencyLimitService, 'getAll'> {
  private listCache: FrequencyLimit[] = null;

  constructor(private baseService: FrequencyLimitService) {}

  async getAll(): Promise<FrequencyLimit[]> {
    if (!this.listCache) {
      this.listCache = await this.baseService.getAll();
    }
    return this.listCache;
  }
}
