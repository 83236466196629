import { Component, Input, OnChanges } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { STANDARD_FORMATS } from '../../../utils/format-constants';
import { generateId } from '../../../utils/string';
import { Voucher, wooId } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';
import { VoucherService } from '../../../woo_services.module/VoucherService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { SubscriptionHandler } from '../subscription-handler';

const template = /* html */ `
  <div class="display--inline-block padding-right--1x" *ngFor="let voucher of vouchers">
    <div class="display--block">
      <div class="tab tab--small" (click)="!disabled(voucher) && toggle(voucher)">
        {{getName(voucher)}}: {{voucher.discount | percent: PERCENT_FORMAT}}
      </div>
    </div>
    <input class="checkbox" id="select-{{voucher.id}}-{{instanceId}}" type="checkbox" [checked]="selected.includes(voucher)" (change)="toggle(voucher)" [disabled]="disabled(voucher)">
    <label class="checkbox" for="select-{{voucher.id}}-{{instanceId}}">Använd engångsrabatt</label>
  </div>
  <div *ngIf="vouchers.length" class="divider dark margin-top-bottom--2x"></div>
`;

@Component({
  selector: 'voucher-select',
  template: template,
})
export class VoucherSelect extends SubscriptionHandler implements OnChanges {
  vouchers: Voucher[] = [];
  selected: Voucher[] = [];

  readonly instanceId = generateId();
  readonly PERCENT_FORMAT = STANDARD_FORMATS.percent;

  @Input() targetingId: wooId;

  constructor(
    private bookingStore: BookingStore,
    private translationService: TranslationService,
    private voucherService: VoucherService,
  ) {
    super();
    this.addSubscription(
      bookingStore.state$
        .pipe(distinctUntilChanged(null, (model) => model.selectedCustomer.vouchers))
        .subscribe(this.setVouchers),
    );
    this.addSubscription(
      bookingStore.state$
        .pipe(distinctUntilChanged(null, (model) => model.campaign.voucher_ids))
        .subscribe(this.setVoucher),
    );
  }

  ngOnChanges(): void {
    this.setVouchers(this.bookingStore.state);
  }

  setVouchers = (model: BookingModel): void => {
    this.vouchers = this.voucherService.filterVouchers(model.selectedCustomer.vouchers, model, this.targetingId, true);
    this.setVoucher(model);
  };

  setVoucher = (model: BookingModel): void => {
    this.selected = model.campaign.voucher_ids
      .map((voucherId) => this.vouchers.find((v) => v.id === voucherId))
      .filter((selected) => selected !== undefined);
  };

  voucherIsApplicable(voucher: Voucher): boolean {
    const allSelectedVouchersConditionValues = this.selected
      .flatMap((selectedVoucher) => selectedVoucher.conditions)
      .flatMap((condition) => condition.values);
    const voucherConditionValues = voucher.conditions.flatMap((c) => c.values);
    const similarVoucherAlreadyApplied = allSelectedVouchersConditionValues.some((value) =>
      voucherConditionValues.includes(value),
    );
    return !similarVoucherAlreadyApplied;
  }

  toggle(voucher: Voucher): void {
    this.bookingStore.setVoucher(voucher.id);
  }

  getName(voucher: Voucher): string {
    return voucher.conditions.map((c) => c.values.map(this.translationService.convertDiscount).join(' ')).join(' ');
  }

  disabled(voucher: Voucher): boolean {
    return !this.selected.includes(voucher) && !this.voucherIsApplicable(voucher);
  }
}
