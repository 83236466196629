import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '../../utils/types';
import { CreativeService } from '../../woo_services.module/CreativeService';
import { ProductFormatService } from '../../woo_services.module/ProductFormatService';
import { Targeting, ViewCurrency } from '../../woo_services.module/shared-types';
import { TargetGroupService } from '../../woo_services.module/TargetGroupService';
import { TranslationService } from '../../woo_services.module/TranslationService';

@Component({
  selector: 'targeting-tags',
  templateUrl: './targeting-tags.component.html',
})
export class TargetingTags implements OnChanges {
  @Input() targeting: Targeting;

  viewCurrency: ViewCurrency;

  constructor(
    private targetGroupService: TargetGroupService,
    private creativeService: CreativeService,
    private translationService: TranslationService,
    private productFormatService: ProductFormatService,
  ) {}

  ngOnChanges(changes: SimpleChanges<TargetingTags>): void {
    if (changes.targeting && this.targeting) {
      this.viewCurrency = this.targeting.view_currency;
    }
  }

  targetGroupNames(): Array<{ name: string }> {
    return this.targetGroupService
      .getTargetGroupNames(this.targeting.age_target_groups, this.targeting.gender_target_groups)
      .map((tg) => ({ name: tg }));
  }

  productFormats(): Array<{ name: string }> {
    const format = this.productFormatService.convertProductFormatToEnum(this.targeting.product_formats);
    return [{ name: this.translationService.convertProductFormat(format) }];
  }
}
