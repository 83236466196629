import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

@Directive({
  selector: '[wooMaxValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: WooMaxValidator,
      multi: true,
    },
  ],
})
export class WooMaxValidator implements Validator {
  @Input('wooMaxValidator') max: number;

  validate(c: AbstractControl): ValidationErrors {
    return Validators.max(this.max)(c);
  }
}
