import { Component, Input } from '@angular/core';
import { Campaign } from '../../../woo_services.module/shared-types';

const template = /* html*/ `
<div class="tabbable__panel panel-margin right section info clearfix">
  <div class="col-xs-12">
    <h2>
      Admin
    </h2>
  </div>
</div>
<div class="tabbable__body">
<automatic-mms-gain-toggle></automatic-mms-gain-toggle>
  <agency-self-service-form [campaign]="campaign"></agency-self-service-form>
  <frequency-limit [campaign]="campaign"></frequency-limit>
</div>
`;

@Component({
  selector: 'admin-follow-up',
  template: template,
})
export class AdminFollowUp {
  @Input() campaign: Campaign;
}
