import { Component, Input } from '@angular/core';
import { CreativeStatus } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'creative-status-badge',
  templateUrl: './creative-status-badge.component.html',
})
export class CreativeStatusBadge {
  readonly CreativeStatus = CreativeStatus;
  @Input() creativeStatus: CreativeStatus;
  @Input() small: boolean;

  translateCreativeStatus = this.translationService.convertCreativeStatus;

  constructor(private translationService: TranslationService) {}
}
