<div class="tabbable__body margin--none clearfix shadow-z-1">
  <div class="block">
    <h3 class="text--medium thin">
      Här listas månadsrapporterna som visar estimerat lager per månad. De sammanställs under natten den första dagen varje månad.
    </h3>
  </div>
  <table class="table--woo table table--hover table--info table--align--middle">
    <thead>
      <th>Månad</th>
      <th>Skapad</th>
      <th></th>
    </thead>
    <tbody>
      <tr *ngFor="let file of monthlyReports; trackBy: trackById">
        <td>{{file.description}}</td>
        <td>{{file.created_at | date}}</td>
        <td>
          <button (click)="downloadReport(file)" class="button primary-1 margin--none">
            <span class="text--medium">
              <span class="icon-download"></span> Ladda ner
            </span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
