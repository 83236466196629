<div class="account__card margin-top--3x">
  <dot-loader [visibility]="!invoices" [large]="true"></dot-loader>
  <div *ngIf="invoices">
    <h2>Igår</h2>
    <i *ngIf="!invoices.yesterday.length">Det finns inga fakturor som skulle ha skickats igår.</i>
    <table class="table" *ngIf="invoices.yesterday.length">
      <thead>
        <tr>
          <th>Kampanj</th>
          <th>Bokad</th>
          <th>Faktura</th>
          <th>Brutto</th>
          <th>Moms</th>
          <th>Skickad</th>
        </tr>
      </thead>
      <tbody *ngFor="let invoice of invoices.yesterday">
        <tr>
          <td>{{invoice.campaign}}</td>
          <td>{{invoice.booked | date}}</td>
          <td>{{invoice.name}}</td>
          <td>{{invoice.gross | currency : 'SEK'}}</td>
          <td>{{invoice.vat | currency : 'SEK'}}</td>
          <td>
            <span *ngIf="invoice.status === InvoiceStatus.sent">Ja</span>
            <span *ngIf="invoice.status !== InvoiceStatus.sent">Nej</span>
            <span class="text-danger strong" *ngIf="invoice.status === InvoiceStatus.cancelled">Makulerad</span>
          </td>
        </tr>
      </tbody>
    </table>
    <h2>I morse</h2>
    <i *ngIf="!invoices.today.length">Det finns inga fakturor som skulle ha skickats i morse.</i>
    <table class="table" *ngIf="invoices.today.length">
      <thead>
        <tr>
          <th>Kampanj</th>
          <th>Bokad</th>
          <th>Faktura</th>
          <th>Brutto</th>
          <th>Moms</th>
          <th>Skickad</th>
        </tr>
      </thead>
      <tbody *ngFor="let invoice of invoices.today">
        <tr>
          <td>{{invoice.campaign}}</td>
          <td>{{invoice.booked | date}}</td>
          <td>{{invoice.name}}</td>
          <td>{{invoice.gross | currency : 'SEK'}}</td>
          <td>{{invoice.vat | currency : 'SEK'}}</td>
          <td>
            <span *ngIf="invoice.status === InvoiceStatus.sent">Ja</span>
            <span *ngIf="invoice.status !== InvoiceStatus.sent">Nej</span>
            <span class="text-danger strong" *ngIf="invoice.status === InvoiceStatus.cancelled">Makulerad</span>
          </td>
        </tr>
      </tbody>
    </table>
    <h2>Kommande</h2>
    <i *ngIf="!invoices.upcoming.length">Det finns inga framtida fakturor.</i>
    <table class="table" *ngIf="invoices.upcoming.length">
      <thead>
        <tr>
          <th>Kampanj</th>
          <th>Bokad</th>
          <th>Faktura</th>
          <th>Brutto</th>
          <th>Moms</th>
          <th>Skickas</th>
        </tr>
      </thead>
      <tbody *ngFor="let invoice of invoices.upcoming">
        <tr>
          <td>{{invoice.campaign}}</td>
          <td>{{invoice.booked | date}}</td>
          <td>{{invoice.name}}</td>
          <td>{{invoice.gross | currency : 'SEK'}}</td>
          <td>{{invoice.vat | currency : 'SEK'}}</td>
          <td>{{invoice.date | date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
