import { Component, Input, OnChanges } from '@angular/core';
import { verticalExpansion } from '../../../utils/animations';
import { inPeriod, isDateBefore, max, orderByDate } from '../../../utils/date';
import { SimpleChanges } from '../../../utils/types';
import { CampaignService } from '../../../woo_services.module/CampaignService';
import { Campaign, Contract, ContractDiscount, wooId } from '../../../woo_services.module/shared-types';
import { TranslationService } from '../../../woo_services.module/TranslationService';

@Component({
  selector: 'contract-discounts-list',
  templateUrl: './contract-discounts-list.component.html',
  styleUrls: ['./contract-discounts-list.component.scss'],
  animations: [verticalExpansion(500)],
})
export class ContractDiscountsList implements OnChanges {
  @Input() contracts: Contract[] = [];
  @Input() campaign?: Campaign;
  @Input() showEnded = false;

  readonly INTERIM_PERIOD_LENGTH_MONTHS = 3;

  now = new Date();
  discountSelectDate = this.now;
  expandedDiscounts: Record<wooId, boolean> = {};
  visibleContracts: Contract[] = [];
  applicableYclis: Record<wooId, boolean> = {};

  convert = this.translationService.convertDiscount;

  constructor(private campaignService: CampaignService, private translationService: TranslationService) {}

  ngOnChanges(changes: SimpleChanges<ContractDiscountsList>): void {
    if (!this.contracts) {
      this.visibleContracts = [];
      return;
    }
    if (changes.campaign && this.campaign) {
      const campaignHasStartDate = this.campaign.targetings?.some((t) => Boolean(t.start_date));
      if (campaignHasStartDate) {
        this.discountSelectDate = this.campaignService.getCampaignPeriod(this.campaign).start;
      } else {
        this.discountSelectDate = this.now;
      }
    }
    const sortedContracts = orderByDate(this.contracts, 'end_date');
    this.includeContractIfWithinInterimPeriod(sortedContracts);
    this.visibleContracts = this.showEnded ? sortedContracts : sortedContracts.filter((contract) => !contract.ended);
    this.setApplicableYclis();
  }

  /*  Yearly contracts are valid for an interim period after its end_date
      so they should be visible within that period */
  includeContractIfWithinInterimPeriod(contracts: Contract[]): void {
    contracts.forEach((contract) => {
      const contractValidTo = new Date(contract.end_date);
      if (contract.valid_for_interim) {
        contractValidTo.setMonth(contractValidTo.getMonth() + this.INTERIM_PERIOD_LENGTH_MONTHS);
      }
      const today = new Date();
      if (contract.ended && isDateBefore(today, contractValidTo)) {
        contract.ended = false;
      }
    });
  }

  toggleExpanded(id: wooId): void {
    this.expandedDiscounts[id] ? delete this.expandedDiscounts[id] : (this.expandedDiscounts[id] = true);
  }

  setApplicableYclis(): void {
    const applicableYclis = {};
    const ycpDiscounts = this.visibleContracts.flatMap((contract) => contract.discounts);
    ycpDiscounts.forEach((ycpDiscount) => {
      const hasYclis = ycpDiscount.yclis?.length > 0;
      if (!hasYclis) {
        return;
      }
      const discountInPeriod = ycpDiscount.yclis.find((ycliDiscount) => this.discountPeriodActive(ycliDiscount));
      if (discountInPeriod) {
        applicableYclis[discountInPeriod.id] = true;
      } else {
        const mostRecentApplicableDiscount = ycpDiscount.yclis
          .filter((ycliDiscount) => isDateBefore(ycliDiscount.start_date, this.now))
          .find((ycliDiscount) => this.isMaxDate(ycliDiscount.start_date, ycpDiscount.yclis));
        if (mostRecentApplicableDiscount) {
          applicableYclis[mostRecentApplicableDiscount.id] = true;
        }
      }
    });
    this.applicableYclis = applicableYclis;
  }

  private isMaxDate(date: string | Date, ycliDiscounts: ContractDiscount[]): boolean {
    return new Date(date).getTime() === max(ycliDiscounts.map((ycli) => new Date(ycli.start_date))).getTime();
  }

  private discountPeriodActive(discount: ContractDiscount): boolean {
    return inPeriod(new Date(discount.start_date), new Date(discount.end_date), this.discountSelectDate);
  }
}
