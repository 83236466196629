import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { scrollTo } from '../../../utils/dom';
import { trackById } from '../../../utils/object';
import { UserRole } from '../../../utils/user-roles';
import { AuthService } from '../../../woo_services.module/AuthService';
import { RoutingService } from '../../../woo_services.module/RoutingService';

@Component({
  selector: 'products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['../shared/table.component.scss'],
})
export class ProductsPage implements OnInit {
  miscTabs: { id: Tab; visible: boolean; name: string }[] = [];
  inventoryTabs: { id: Tab; visible: boolean; name: string }[] = [];
  targetingTabs: { id: Tab; visible: boolean; name: string }[] = [];
  activeTabId = null;
  Tab = Tab;
  activeTabDescription: string = null;
  inventoryVisible: boolean;

  readonly trackById = trackById;
  @ViewChild('inventoryNav', { static: true }) inventoryNav: ElementRef;
  @ViewChild('targetingNav', { static: true }) targetingNav: ElementRef;
  @ViewChild('miscNav', { static: true }) miscNav: ElementRef;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
  ) {}

  ngOnInit(): void {
    this.activeTabId = this.routingService.getParam(this.route, 'tab');
    const isAdmin = this.authService.hasAnyRole([UserRole.admin]);
    const isAdminOrEstimator = this.authService.hasAnyRole([UserRole.admin, UserRole.estimator]);
    const isAdminOrPlanner = this.authService.hasAnyRole([UserRole.admin, UserRole.planner]);
    const isAdminOrEstimatorOrPlanner = this.authService.hasAnyRole([
      UserRole.admin,
      UserRole.estimator,
      UserRole.planner,
    ]);

    this.inventoryVisible = isAdminOrEstimator;

    this.inventoryTabs = [
      { id: Tab.agWithdrawalLimit, visible: isAdminOrEstimator, name: 'AG maxuttag' },
      { id: Tab.completionRates, visible: isAdminOrEstimator, name: 'Completion Rate' },
      { id: Tab.frequencyLimits, visible: isAdminOrEstimator, name: 'Frekvenstak' },
      { id: Tab.categories, visible: isAdminOrEstimator, name: 'Kategorier' },
      { id: Tab.pauseAd, visible: isAdminOrEstimator, name: 'Pausreklam' },
      { id: Tab.productFormats, visible: isAdminOrEstimator, name: 'Placering' },
      { id: Tab.gambling, visible: isAdminOrEstimator, name: 'Spelbolag' },
      { id: Tab.grossRatingFactor, visible: isAdminOrEstimator, name: 'Bruttokontakter' },
    ];

    this.targetingTabs = [
      { id: Tab.deviceGroups, visible: isAdminOrEstimator, name: 'Apparater' },
      { id: Tab.advancedTargetGroups, visible: isAdminOrEstimator, name: 'Avancerade målgrupper' },
      { id: Tab.behaviors, visible: isAdminOrEstimator, name: 'Beteendesegment' },
      { id: Tab.dayparts, visible: isAdminOrEstimator, name: 'Dayparts' },
      { id: Tab.targetGroups, visible: isAdminOrEstimator, name: 'Målgrupper' },
      { id: Tab.programFormats, visible: isAdminOrEstimatorOrPlanner, name: 'Programformat' },
      { id: Tab.regions, visible: isAdminOrEstimator, name: 'Regioner' },
      { id: Tab.ttv, visible: isAdminOrPlanner, name: 'Total-TV' },
    ];

    this.miscTabs = [
      { id: Tab.devices, visible: isAdminOrEstimator, name: 'Enheter' },
      { id: Tab.priceLists, visible: isAdmin, name: 'Prislistor' },
      { id: Tab.universe, visible: isAdminOrEstimatorOrPlanner, name: 'Universum' },
      { id: Tab.defaultFrequencyLimit, visible: isAdminOrPlanner, name: 'Standard frekvenstak' },
    ];
  }

  tabRowSelected(tabRow: string): boolean {
    if (tabRow === 'inventory' && !this.inventoryTabs.some((tab) => tab.id === this.activeTabId)) {
      return false;
    }
    if (tabRow === 'targeting' && !this.targetingTabs.some((tab) => tab.id === this.activeTabId)) {
      return false;
    }
    if (tabRow === 'misc' && !this.miscTabs.some((tab) => tab.id === this.activeTabId)) {
      return false;
    }
    return true;
  }

  setTab(tab: Tab, element: HTMLElement): void {
    if (this.activeTabId === tab) {
      this.activeTabId = Tab.noTab;
    } else {
      this.activeTabId = tab;
      this.routingService.navigate(['/products', tab]);
    }
    scrollTo(element);
  }
}

enum Tab {
  priceLists = 'price_lists',
  categories = 'categories',
  targetGroups = 'target_groups',
  advancedTargetGroups = 'advanced_target_groups',
  programFormats = 'program_formats',
  devices = 'devices',
  deviceGroups = 'device_groups',
  regions = 'regions',
  dayparts = 'dayparts',
  pauseAd = 'pause_ad',
  behaviors = 'behaviors',
  frequencyLimits = 'frequency_limits',
  agWithdrawalLimit = 'ag_withdrawal_limit',
  gambling = 'gambling',
  completionRates = 'completion_rates',
  productFormats = 'product_formats',
  ttv = 'ttv',
  universe = 'universe',
  grossRatingFactor = 'gross_rating_factor',
  defaultFrequencyLimit = 'default_frequency_limit',
  noTab = '',
}
