<div>
  <div class="padding--3x">
    <expandable-targeting-header
      [expanded]="expanded"
      (expandedChange)="expandedChange($event)"
      (onExpandEnd)="onExpandEnd()"
      [targeting]="targeting"
      [targetingMetaData]="targetingMetaData"
    >

      <p class="text--large">
        Annonstyp
        <info-badge gaName="budget">
          Välj om denna styrning ska spela upp en reklamfilm eller visa en bild, pausreklam.
        </info-badge>
      </p>

      <div class="input__container display--inline-block margin-right--4x">
        <input class="radio" id="instream-{{instanceId}}" type="radio" name="targeting-type-{{instanceId}}"
          [(ngModel)]="targetingType"
          (ngModelChange)="updateTargetingType()" [value]="TargetingType.instream" [disabled]="disabledInstreamMessage">
        <label class="radio" for="instream-{{instanceId}}">
          Reklamfilm
          <span class="icon icon-movies margin-left--1x" [ngClass]="{'text--darker-gray': !disabledInstreamMessage}">
          </span>
        </label>
      </div>
      <div class="input__container display--inline-block">
        <input class="radio" id="pause-{{instanceId}}" type="radio" name="targeting-type-{{instanceId}}"
          [(ngModel)]="targetingType"
          (ngModelChange)="updateTargetingType()" [value]="TargetingType.pause" [disabled]="disablePauseAd(targeting.id)">
        <label class="radio" for="pause-{{instanceId}}">Pausreklam
          <span class="icon icon-TV4_014_placeholder margin-left--1x" [ngClass]="{'text--darker-gray': !disablePauseAd(targeting.id)}"></span>
        </label>
      </div>
      <p *ngIf="disabledInstreamMessage || disablePauseAd(targeting.id)" class="text--gray">
        {{disabledInstreamMessage}}{{disabledPauseAdMessage}}
      </p>

      <div class="divider medium margin-top--3x margin-bottom--1x"></div>

      <p class="text--large">
        Geografi
        <info-badge gaName="geografi">
          Vill du att hela Sverige ska kunna se din kampanj eller vill du bara synas regionalt? Det väljer du här! Får du ett
          felmeddelande om att ditt urval är för snävt, så kan du prova att utöka med ytterligare en region.
          <div>
            <br>
            <br>
            Regionstyrning är en postnummerbaserad styrning.
            <ul>
              <li>Postnummer: Där tittaren bor.</li>
            </ul>
          </div>
        </info-badge>
      </p>

      <div class="input__container display--inline-block margin-right--4x">
        <input class="radio" id="national-{{instanceId}}" type="radio" name="geo-targeting-{{instanceId}}"
          [(ngModel)]="geoTargeting"
          (ngModelChange)="updateGeoTargeting()" [value]="GeoTargeting.national">
        <label class="radio" for="national-{{instanceId}}">Nationell TV4</label>
      </div>
      <div class="input__container display--inline-block">
        <input class="radio" id="regional-{{instanceId}}" type="radio" name="geo-targeting-{{instanceId}}"
          [(ngModel)]="geoTargeting"
          (ngModelChange)="updateGeoTargeting()" [value]="GeoTargeting.regional" [disabled]="disableRegional(targeting.id)">
        <label class="radio" for="regional-{{instanceId}}">Regional TV4</label>
      </div>

      <div *ngIf="geoTargeting === GeoTargeting.regional">
        <radio-button-block
          [choices]="regionalDivisionChoices"
          [(ngModel)]="regionalDivision"
          (ngModelChange)="updateRegionalDivision()"
        >
        <div class="row">
          <includes-linear class="col-xs-8" [targetingId]="targetingId" *ngIf="isAdminOrPlanner"></includes-linear>
        </div>

        <div *ngIf="isAdminOrPlanner" class="divider margin-top--2x margin-bottom--1x"></div>

        <geo-targeting [targetingId]="targetingId"></geo-targeting>
        </radio-button-block>
      </div>

      <div class="divider medium margin-top--3x margin-bottom--1x"></div>

      <p class="text--large">Budget
        <info-badge gaName="budget">
          Fyll i din nettobudget, alltså den summa som ska faktureras
          dig efter eventuell rabatt.
        </info-badge>
      </p>

      <selector *ngIf="!memaCampaign" [(ngModel)]="calculateFromBudget" (ngModelChange)="updateCalculateFromBudget()"
        [choices]="calculateFromBudgetChoices"></selector>

      <div class="row margin-top--2x">
        <div class="col-xs-9 col-sm-6 col-md-5 col-lg-3">
          <targeting-budget [targetingId]="targetingId"></targeting-budget>
        </div>
      </div>

      <div class="divider medium margin-top--3x margin-bottom--1x"></div>

      <div *ngIf="isAdminOrPlanner">
        <targeting-admin-panel  [targetingId]="targetingId">
        </targeting-admin-panel>
        <div class="divider medium margin-top--3x margin-bottom--1x"></div>
      </div>

      <p class="text--large">
        Period
        <info-badge gaName="period">
          Din kampanj får tidigast starta {{earliestCampaignStart | date: dateFormat}} och måste sträcka sig över minst
          {{minCampaignLength}} dagar.
        </info-badge>
      </p>
      <p *ngIf="isDatesChangedByEstimation">
        {{whyIsDatesChanged}}
      </p>
      <period-select
        [ngModel]="targetingPeriod"
        (ngModelChange)="periodChanged($event)"
        [firstSelectableDate]="earliestCampaignStart"
        [disabled]="disablePeriodSelect"
        [forcedStartDate]="forcedStartDate"
        [forcedEndDate]="forcedEndDate"></period-select>

      <div class="divider medium margin-top--3x margin-bottom--1x"></div>

      <product-format-select [targetingId]="targetingId"></product-format-select>

      <p class="text--large">
        Styrning
        <info-badge gaName="styrning">
          <p>Vi gör vårt bästa för att erbjuda dig valmöjligheter, men om dina val är för snäva så kanske vi inte har
            tillgängligt
            lager som matchar dina önskemål. I så fall får du ett felmeddelande. Då bör du gå tillbaka och justera dina
            val,
            till exempel välja fler regioner eller välja RBS istället för en specifik kategori.</p>

          <p *ngIf="targetingType === TargetingType.instream">För reklamfilm nationell kan du välja styrning på RBS,
            kategori, program, apparat och målgrupp.
            För regionala kampanjer kan du välja styrning på antingen kategori eller program.</p>

          <p *ngIf="targetingType === TargetingType.pause">Vid pausreklam nationell kan du välja styrning på RBS, kategori
            och program. För regionala kampanjer kan du välja
            styrning på RBS.</p>

          <p *ngIf="geoTargeting === GeoTargeting.national">
            <span class="text--strong">Universum</span> är den befolkningsmängd som ingår i den valda målgruppen. En snävare målgruppsstyrning ger lägre universum för kampanjen. Används för att få en uppfattning om den valda målgruppens storlek.
          </p>
          <p *ngIf="geoTargeting === GeoTargeting.national">
            <span class="text--strong">TRP (Target Rating Point)</span> är ett vanligt mått i tv-världen. Måttet TRP visar hur stor träffbild kampanjen har i den valda målgruppen för det aktuella antalet views. TRP uttrycks i procent, där 100 TRP (100%) i målgruppen innebär lika stort antal views som det finns befolkning i målgruppen. TRP är ett bra mått för att jämföra kampanjstorlekar mellan målgrupper med olika befolkningsmängder. (Notera att TRP inte är att likställa med räckvidd)
          </p>

          <p>Kampanjens pris påverkas av hur du styr din kampanj.</p>

        </info-badge>
      </p>
      <radio-button-block
        id="targeting-button-block-{{instanceId}}"
        [choices]="applicableProductChoices"
        [(ngModel)]="productChoice"
        (ngModelChange)="productChoiceChanged()">
        <rbs-targeting *ngIf="productChoice === ProductChoice.rbs" [targetingId]="targetingId">
        </rbs-targeting>
        <target-group-targeting *ngIf="productChoice === ProductChoice.targetGroup" [targetingId]="targetingId">
        </target-group-targeting>
        <advanced-target-group-targeting
          *ngIf="productChoice === ProductChoice.advancedTargetGroup"
          [targetingId]="targetingId">
        </advanced-target-group-targeting>
        <category-targeting *ngIf="productChoice === ProductChoice.category"
                            [targetingId]="targetingId"
                            [regionalIsSelected]="geoTargeting === GeoTargeting.regional"
                            [pauseAdIsSelected]="targetingType === TargetingType.pause">
        </category-targeting>
        <program-format-targeting *ngIf="productChoice === ProductChoice.program" [targetingId]="targetingId">
        </program-format-targeting>
        <daypart-targeting *ngIf="productChoice === ProductChoice.daypart" [targetingId]="targetingId">
        </daypart-targeting>
        <device-group-targeting *ngIf="productChoice === ProductChoice.device" [targetingId]="targetingId">
        </device-group-targeting>
      </radio-button-block>

      <div class="divider medium margin-top--3x margin-bottom--1x"></div>
      <p class="text--large">
        Tilläggsstyrning
        <info-badge gaName="styrning">
          <p>Tilläggsstyrning går att välja då du vill kombinera Apparat med Målgrupp eller Avancerad målgrupp</p>

          <p>Kampanjens pris påverkas av hur du styr din kampanj.</p>

        </info-badge>
      </p>
      <radio-button-block
        id="addon-targeting-button-block-{{instanceId}}"
        [choices]="applicableAddonProductChoices"
        [(ngModel)]="addonProductChoice"
        (ngModelChange)="addonTargetingChanged()">
        <div *ngIf="addonProductChoice === ProductChoice.noChoice"></div>
        <device-group-targeting *ngIf="addonProductChoice === ProductChoice.device" [targetingId]="targetingId" [addonTargeting]="true">
        </device-group-targeting>
        <target-group-targeting *ngIf="addonProductChoice === ProductChoice.targetGroup" [targetingId]="targetingId">
        </target-group-targeting>
        <advanced-target-group-targeting
          *ngIf="addonProductChoice === ProductChoice.advancedTargetGroup"
          [targetingId]="targetingId">
        </advanced-target-group-targeting>
      </radio-button-block>

    </expandable-targeting-header>
  </div>
</div>
