import { Component, Input } from '@angular/core';
import { ConfirmDialogContent } from '../../../../woo_components.module/dialogs/confirm-dialog.component';
import { DialogService } from '../../../../woo_services.module/DialogService';
import { GoogleTagManagerService } from '../../../../woo_services.module/GoogleTagManagerService';
import { RoutingService } from '../../../../woo_services.module/RoutingService';
import { Campaign } from '../../../../woo_services.module/shared-types';

const template = /* html */ `
  <text-button
    icon="thumbs-single-row"
    [disabled]="disabled"
    (click)="onClick()"
  >
    Duplicera
  </text-button>
`;

@Component({
  selector: 'duplicate-campaign-button',
  template,
})
export class DuplicateCampaignButton {
  @Input() campaign: Campaign;
  @Input() disabled = false;

  constructor(
    private googleTagManagerService: GoogleTagManagerService,
    private routingService: RoutingService,
    private dialogService: DialogService,
  ) {}

  onClick = (): void => {
    if (this.disabled) {
      return;
    }

    const content = this.getDialogContent();
    this.dialogService
      .openConfirm(content)
      .then(
        () => {
          this.duplicateCampaign(this.campaign);
        },
        () => null,
      )
      .catch(() => {
        const msg = 'Ajdå, nånting gick snett. Var god försök igen!';
        this.dialogService.openError(msg);
      });
  };

  private duplicateCampaign = (campaign: Campaign) => {
    this.googleTagManagerService.duplicateCampaign('specific');
    this.routingService.navigate(['/campaigns', 'new'], { state: { id: campaign.id, templating: true } });
  };

  private getDialogContent = (): ConfirmDialogContent => ({
    header: 'Duplicera kampanj',
    textBlocks: [
      'Du är nu på väg att duplicera en kampanj. Det innebär att allt kopieras från den tidigare kampanjen till den nya, förutom namn och kampanjperiod. Eventuella perioder för filmerna behöver också sättas om.',
      `Vill du fortsätta duplicera kampanjen <strong>${this.campaign.name}</strong>?`,
    ],
    confirmText: 'Duplicera kampanj',
    cancelText: 'Avbryt',
  });
}
