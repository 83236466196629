import { Component } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'input-errors',
  template: `
    <p
      *ngFor="let pair of errors | keyvalue"
      [ngClass]="{ 'position--absolute': positionAbsolute }"
      class=" input__error__text margin-top--none "
    >
      {{ pair.value }}
    </p>
  `,
})
export class InputErrors {
  public errors: ValidationErrors = null;
  public positionAbsolute = false;
}
